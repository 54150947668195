import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import { missingImage } from "../../assets";
import { Separator } from "../../components";

import {
  getVacanciesByDepartmentOrganization,
  getDepartmentsByOrganization,
  setCurrentDepartment,
  getVacanciesByDepartmentId,
  deleteDepartment
} from "../../redux/actions-exporter";

import "./company-clients-info.styles.scss";

const CompanyClientsInfo = ({
  key,
  currentCompany,

  getVacanciesByDepartmentOrganization,
  getDepartmentsByOrganization,
  typeOfCompany
}) => {
  const { id, name, logo } = currentCompany;
  const { url } = useRouteMatch();

  useEffect(() => {
    getVacanciesByDepartmentOrganization(id);
    getDepartmentsByOrganization(id);
  }, [getVacanciesByDepartmentOrganization, id, getDepartmentsByOrganization]);

  return (
    <div key={key} className="company-clients-info">
      <Separator />
      <div className="company-clients-info__companies">
        <div className="companies__inner-content">
          <div className="companies__inner-content__company">
            <div className="companies__inner-content__company__image">
              <img
                className="company-img"
                src={logo ? logo : missingImage}
                alt=""
              />
            </div>
            <div className="companies__inner-content__company__name">
              <Link
                to={`${
                  typeOfCompany === "Company"
                    ? `${url}/c/${id}`
                    : `${url}/o/${id}`
                }`}
              >
                {name}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  companyVacancies: state.vacanciesReducer.vacanciesByDepartment,
  companyDepartments: state.vacanciesReducer.departmentsByOrganization,
  vacanciesByDepartmentId: state.vacanciesReducer.vacanciesByDepartmentId
});

export default connect(mapStateToProps, {
  getVacanciesByDepartmentOrganization,
  getDepartmentsByOrganization,
  getVacanciesByDepartmentId,
  setCurrentDepartment,
  deleteDepartment
})(CompanyClientsInfo);
