export default function jobDetailsComponent({ editor, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function tabs($classPrefix) {
      const tabsEls = document.querySelectorAll(`.${$classPrefix}__tab`);
      Array.prototype.slice
        .call(tabsEls)
        .map(tabEl => {
          tabEl.addEventListener("click", e => {
            e.stopImmediatePropagation();

            Array.prototype.slice
              .call(tabsEls)
              .map(otherTabEl =>
                otherTabEl.classList.remove(`${$classPrefix}__tab--active`)
              );

            tabEl.classList.add(`${$classPrefix}__tab--active`);
            const selector = tabEl.dataset.tabSelector;
            const contentEl = document.querySelector(selector);
            contentEl && (contentEl.style.display = "block");

            tabs($classPrefix);
          });
          return tabEl;
        })
        .filter(
          tabEl => !tabEl.classList.contains(`${$classPrefix}__tab--active`)
        )
        .map(tabEl => {
          const selector = tabEl.dataset.tabSelector;
          const contentEl = document.querySelector(selector);
          return contentEl && (contentEl.style.display = "none");
        });
    }

    function makeJobDescriptionHtml(
      title,
      description,
      address,
      typeOfEmployment,
      vacancy,
      requirements,
      terms,
      date,
      translations
    ) {
      return `
      <div class="job-details">
        <div class="job-details__main">
          <div class="job-details__header">
            <h4 class="job-details__title">${title}</h4>
          </div>
          <span class="job-details__meta">
            <span class="job-details__meta-item">
              ${vacancy}
            </span>
            <span class="job-details__meta-delimiter">
              •  
            </span>
            <span class="job-details__meta-item">
              ${address}
            </span>
            <span class="job-details__meta-delimiter">
              •  
            </span>
            <span class="job-details__meta-item">
              ${typeOfEmployment}
            </span>
            <span class="job-details__meta-delimiter">
              •  
            </span>
            <span class="job-details__meta-item">
              ${date}
            </span>
          </span>
        </div>

        <div class="job-details__additional">
          <div class="job-details__tabs">
            <span
              class="job-details__tab job-details__tab--active"
              data-tab-selector=".job-details__tab-content--description"
            >
              ${translations["Description"]}
            </span>
            <span class="job-details__tab-separator">|</span>
            <span
              class="job-details__tab"
              data-tab-selector=".job-details__tab-content--requirements"
            >
              ${translations["Requirements"]}
            </span>
            <span class="job-details__tab-separator">|</span>
            <span
              class="job-details__tab"
              data-tab-selector=".job-details__tab-content--terms"
            >
              ${translations["Terms"]}
            </span>
          </div>

          <div class="job-details__tab-content job-details__tab-content--description">
            <div class="job-details__tab-header">
              <h1 class="job-details__tab-title">
                ${translations["Description"]}
              </h1>
            </div>
            <div class="job-details__tab-main">
              <div class="job-details__description">
                ${description}
              </div>
            </div>
          </div>

          <div class="job-details__tab-content job-details__tab-content--requirements">
            <div class="job-details__tab-header">
              <h1 class="job-details__tab-title">
                ${translations["Requirements"]}
              </h1>
            </div>
            <div class="job-details__tab-main">
              <div class="job-details__description">
                ${requirements}
              </div>
            </div>
          </div>

          <div class="job-details__tab-content job-details__tab-content--terms">
            <div class="job-details__tab-header">
              <h1 class="job-details__tab-title">
                ${translations["Terms"]}
              </h1>
            </div>
            <div class="job-details__tab-main">
              <div class="job-details__description">
                ${terms}
              </div>
            </div>
          </div>
  
        </div>
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".job-details-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("job-details-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("job-details-wrapper--preloader");
      }
    }

    function render(id, translations) {
      const API_ROOT = "{[ apiRoot ]}";

      setLoading(true);
      fetch(`${API_ROOT}/department/jobs/${id}/`)
        .then(response => response.json())
        .then(job => {
          const el = document.querySelector(".job-details-wrapper");

          fetch(
            `${API_ROOT}/department/vacancy/?department__organization=${id}&page_size=500`
          )
            .then(response => response.json())
            .then(staff => {
              el.innerHTML = makeJobDescriptionHtml(
                job.title,
                job.description,
                `${job.country_ext.name}, ${job.city}`,
                translations[job.type_of_employment],
                job.vacancy_ext.name,
                job.requirements,
                job.terms,
                new Date(job.created_at).toLocaleDateString(),
                translations
              );

              setLoading(false);
              tabs("job-details");
            });
        });
    }

    const params = new URLSearchParams(window.location.search);
    const id = params.get("gjs-job-id");

    if (!window.hasOwnProperty("grapesjs")) {
      tabs("job-details");
    } else {
      // Get translation && render
      const translations = getTranslate("translations", this.attributes);
      if (translations && Object.keys(translations).length) {
        render(id, translations);
      }
    }
  };

  editor.DomComponents.addType("job-details", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "job-details-wrapper",
    model: {
      defaults: {
        name: "Job Details",
        apiRoot: api.API_ROOT,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "N/A",
              "No short description...",
              "Requirements",
              "Terms",
              "Description",
              "full_time",
              "partial",
              "shifts",
              "for_students"
            ]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
