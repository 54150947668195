import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from "@material-ui/core";
import {
  Clear as ClearIcon,
  ArrowForward as ArrowForwardIcon
} from "@material-ui/icons";
import { missingImage } from "../../assets";
import {
  DefaultButton,
  DefaultIconButton,
  QuantityInputHorizontal,
  Separator
} from "../../components";

import { buttonOk } from "../../styles/buttons-styles";
import "./cart-products-by-company.styles.scss";

const CartDetails = ({ label, value, currency, currencyFirst }) => {
  return (
    <div className="cart-info-subdiv__cart-details">
      <div>{label}</div>
      {currencyFirst ? (
        <div>
          <span>{currency}</span> {value.toFixed(2)}
        </div>
      ) : (
        <div>
          {value.toFixed(2)} <span>{currency}</span>
        </div>
      )}
    </div>
  );
};

const CartProductsByCompany = ({
  id,
  logo,
  name,
  company_my_deals,
  company_cart_info,
  editCart,
  deleteDeal,
  getCartCompaniesWithTheirProductsSuccess
}) => {
  const { url } = useRouteMatch();

  const timeoutDeal = () => getCartCompaniesWithTheirProductsSuccess();

  return (
    <div className="cart-products-by-company">
      <div className="cart-products-by-company__header">
        <Link to={`/companies/${id}`}>
          <img
            className="company-image"
            src={logo ? logo : missingImage}
            alt=""
          />
        </Link>
        <Link to={`/companies/${id}`}>
          <div className="company-name">{name}</div>
        </Link>
      </div>
      <div className="cart-products-by-company__table-products">
        <Table aria-label="products table">
          <TableHead>
            <TableRow>
              <TableCell align="left">PRODUCT DETAILS</TableCell>
              <TableCell align="left">UNIT PRICE</TableCell>
              <TableCell align="left">QUANTITY</TableCell>
              <TableCell align="left">TOTAL</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {company_my_deals &&
              company_my_deals.map(({ order_items, currency }) =>
                order_items.map(
                  ({
                    id,
                    image,
                    categories,
                    name,
                    unit_price,
                    total,
                    total_discount,
                    quantity,
                    cart_id,
                    content_type,
                    additional_parameter
                  }) => (
                    <>
                      <TableRow style={{ borderBottom: "none" }} key={id}>
                        <TableCell align="left">
                          <div className="product-table-details">
                            <Link
                              to={`/product/${
                                content_type === 84 ? "b2b" : "b2c"
                              }/${id}`}
                            >
                              <img
                                className="product-table-details__image"
                                src={image ? image : missingImage}
                                alt=""
                              />
                              <div className="product-table-details__description">
                                <div className="description-category">
                                  {categories && categories[0]
                                    ? categories[0].name
                                    : "No category"}
                                </div>
                                <div className="description-name">{name}</div>
                              </div>
                            </Link>
                            {additional_parameter &&
                              !!additional_parameter.length && (
                                <div className="product-table-details__options">
                                  <div className="options-header">
                                    Selected Options
                                  </div>
                                  <div className="options-params">
                                    {additional_parameter.map(
                                      ({ title, extra_cost }) => (
                                        <div className="additional-option">
                                          {`${title} - ${extra_cost} ${currency}`}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="product-table-details__unit-price">
                            {`${unit_price} ${currency}`}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="product-table-details__quantity">
                            <QuantityInputHorizontal
                              func={editCart}
                              data={cart_id}
                              initialQuantity={quantity}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {/*TODO: !== или привестки к общим типам*/}
                          {total_discount && total_discount != total ? ( // eslint-disable-line
                            <div className="product-table-details__cost-container">
                              <div className="product-table-details__total-cost product-table-details__total-cost_new">
                                {`${total} ${currency}`}
                              </div>
                              <div className="product-table-details__total-cost product-table-details__total-cost_old">
                                {`${(unit_price * quantity).toFixed(
                                  2
                                )} ${currency}`}
                              </div>
                              <div className="product-table-details__total-cost product-table-details__total-cost_discount">
                                {`Discount: ${total_discount} ${currency}`}
                              </div>
                            </div>
                          ) : (
                            <div className="product-table-details__total-cost">
                              {`${(unit_price * quantity).toFixed(
                                2
                              )} ${currency}`}
                            </div>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <div
                            onClick={() => {
                              deleteDeal(cart_id);
                              timeoutDeal();
                            }}
                          >
                            <DefaultIconButton>
                              <ClearIcon />
                            </DefaultIconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                )
              )}
          </TableBody>
        </Table>
      </div>
      <div className="cart-products-by-company__cart-totals">
        <div className="cart-totals-company">
          TOTALS -{" "}
          <strong>
            <Link to={`/companies/${id}`}>{name}:</Link>
          </strong>
        </div>
        <div className="cart-totals-sections">
          <div className="cart-info-subdiv">
            <div className="cart-info-subdiv__title">SUBTOTAL</div>
            {company_cart_info.map(
              ({ sub_total, count_items, currency }, index) => (
                <CartDetails
                  key={index}
                  label={`${count_items} items`}
                  value={sub_total}
                  currency={currency}
                />
              )
            )}
          </div>
          <div className="cart-info-subdiv">
            <div className="cart-info-subdiv__title">SHIPPING</div>
            {company_cart_info.map(({ shipping_cost, currency }, index) => (
              <CartDetails
                key={index}
                label="Shipping"
                value={shipping_cost}
                currency={currency}
              />
            ))}
          </div>
          <div className="cart-info-subdiv">
            <div className="cart-info-subdiv__title">DISCOUNT & COUPONS</div>
            {company_cart_info.map(({ coupon_discount, currency }, index) => (
              <CartDetails
                key={index}
                label="Discount"
                value={coupon_discount}
                currency={currency}
              />
            ))}
            {company_cart_info.map(({ discount, currency }, index) => (
              <CartDetails
                key={index}
                label="Discount coupon"
                value={discount}
                currency={currency}
              />
            ))}
          </div>
        </div>
      </div>
      <Separator />
      <div className="cart-products-by-company__footer-totals">
        <div className="footer-totals-inner">
          {company_cart_info.map(
            ({ total, sub_total, discount, currency }, index) => (
              <>
                <CartDetails
                  key={index}
                  label="SUBTOTAL"
                  value={sub_total}
                  currency={currency}
                  currencyFirst={true}
                />
                <CartDetails
                  key={index}
                  label="DISCOUNT"
                  value={discount}
                  currency={currency}
                  currencyFirst={true}
                />
                <CartDetails
                  key={index}
                  label="TOTAL"
                  value={total}
                  currency={currency}
                  currencyFirst={true}
                />
              </>
            )
          )}
          <Link to={`${url}/checkout/company/${id}`}>
            <DefaultButton {...buttonOk} width="100%">
              Continue Securely <ArrowForwardIcon />
            </DefaultButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartProductsByCompany;
