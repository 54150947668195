import { axiosNoTokenInstance } from "../../../axios/axios.config";
import { ClientActionTypes } from "./clients.types";

export const getClients = company => dispatch => {
  dispatch({
    type: ClientActionTypes.GET.GET_CLIENTS_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/company_clients/?company=${company}`)
    .then(clients => {
      dispatch({
        type: ClientActionTypes.GET.GET_CLIENTS_SUCCESS,
        payload: clients.data
      });
    })
    .catch(error =>
      dispatch({
        type: ClientActionTypes.GET.GET_CLIENTS_ERROR,
        payload: error
      })
    );
};

export const clearClientsRequest = () => dispatch => {
  dispatch({
    type: ClientActionTypes.CLEAR_CLIENTS_REQUEST,
    payload: ""
  });
};
