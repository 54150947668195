import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/styles";
import { Card, CardMedia, IconButton } from "@material-ui/core";
import {
  FavoriteBorder as FavoriteBorderIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from "@material-ui/icons";

// import { getCookie } from "../../axios/axios.config";
import { IconHeart, IconChecked } from "../../assets";

import { DefaultSnackbar } from "../../components";

import {
  addToCart,
  editCart,
  getCart,
  addProductToWishList,
  getWishList,
} from "../../redux/actions-exporter";

import "./product-with-category.styles.scss";

const ProductWithCategory = ({
  appDirection,
  addToCart,
  editCart,
  navProductType,
  productId,
  imgUrl,
  name,
  description,
  price,
  currency,
  discountPercent,
  couponDiscountPercent,
  metadata,
  getCart,
  getWishList,
  cart,
  isCartPending,
  wishList,
  isWishListPending,
  addProductToWishList,
  category,
  currentProductData,
}) => {
  let b2bProductId = "";
  let b2cProductId = "";

  if (navProductType === "b2b") {
    b2bProductId = productId;
  } else if (navProductType === "b2c") {
    b2cProductId = productId;
  }

  const [quantity] = useState(1);
  const [companyId] = useState("");
  const [customerType] = useState("person");
  const [show, setshow] = useState(false);
  const handleShow = () => setshow(true);
  const handleHide = () => setshow(false);

  const [snackMessage, setSnackMessage] = useState("");
  const customMessages = [
    { id: 1, message: "Succesfully added to wishlist!" },
    { id: 2, message: "Succesfully added to cart!" },
  ];

  const [existsInCart, setExistsInCart] = useState(false);
  const [existsInWishList, setExistsInWishList] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const addToWishListHandler = () => {
    addProductToWishList(
      navProductType === "b2b" ? "item_b2b" : "item_b2c",
      currentProductData
    );
    setExistsInWishList(true);
    setSnackMessage(customMessages[0].message);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  const addToCartItemHandler = useCallback(() => {
    setExistsInCart(true);
    addToCart(customerType, companyId, b2bProductId, b2cProductId, quantity);
    setSnackMessage(customMessages[1].message);
    setShowMessage(true);
    setTimeout(() => {
      setExistsInCart(false);
      setShowMessage(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const authCookie = getCookie("Authorization");
    // if (authCookie) {
    //   if ((!wishList || !wishList.length) && !isCartPending) {
    //     getCart();
    //   }
    //   if ((!wishList || !wishList.length) && !isWishListPending) {
    //     getWishList();
    //   }
    // }

    if (wishList.find((i) => i.wish_list_item[0].item_id === productId)) {
      setExistsInWishList(true);
    } else {
      setExistsInWishList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCart,
    getWishList,
    editCart,
    addToCart,
    productId,
    addToCartItemHandler,
  ]);

  const useStyles = makeStyles({
    card: {},
    media: {
      width: 200,
      height: 200,
      margin: "13px auto 0",
      borderRadius: "3px",
    },
    actions: {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      display: "flex",
      justifyContent: "center",
      height: "12%",
      width: "60%",
      backgroundColor: "#2f2f2f",
      margin: "0 auto",
      padding: "5px 1px",
      borderRadius: "10px",

      "& svg": {
        width: "16px",
        height: "100%",
      },
    },
    action: {
      padding: "0 4px",
    },
    actionRTL: {
      padding: "0 4px",
    },
    iconAdded: {
      fontSize: "16px",
      backgroundColor: "white",
      color: "#2f2f2f",
      borderRadius: "50%",
      padding: "6px",
    },
    icon: {
      fontSize: "16px",
      backgroundColor: "white",
      color: "#2f2f2f",
      borderRadius: "50%",
      padding: "6px",
    },
  });
  const classes = useStyles();

  const makeLinkToProducts = ({ key, categoryId }) => ({
    key,
    pathname: "/products",
    state: {
      defaultProductType: "b2c",
      additionalB2CRequestParams: { categoryId },
    },
  });

  return (
    <div className="product-with-category">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="success"
          message={snackMessage}
          autoHideDuration={2000}
        />
      )}
      <Card
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        className={classes.card}
      >
        <CardMedia className={classes.media}>
          <img className="product-with-category__image" src={imgUrl} alt="" />
        </CardMedia>

        {show && (
          <>
            <div
              className={`product-with-category__actions ${classes.actions}`}
            >
              {existsInWishList ? (
                <IconButton
                  disableRipple
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <IconHeart className={classes.iconAdded} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={addToWishListHandler}
                  disableRipple
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <FavoriteBorderIcon className={classes.icon} />
                </IconButton>
              )}

              {existsInCart ? (
                <IconButton
                  disableRipple
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <IconChecked
                    style={{ fill: "green" }}
                    className={classes.iconAdded}
                  />
                </IconButton>
              ) : (
                <IconButton
                  disableRipple
                  onClick={() => addToCartItemHandler()}
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <AddCircleOutlineIcon className={classes.icon} />
                </IconButton>
              )}
            </div>
          </>
        )}
        <div className="product-with-category__product-details">
          <Link to={`/product/${navProductType}/${productId}`}>
            <div className="product-with-category__name">
              {name ? name : "No product name!"}
            </div>
            <div className="product-with-category__description">
              {description
                ? description.length > 40
                  ? description.substring(0, 40) + "..."
                  : description
                : "No product description!"}
            </div>
            <div className="product-with-category__price">
              {price && price > 0 ? `${price} ${currency}` : "No price!"}

              {(couponDiscountPercent || discountPercent) && (
                <div className="product-with-category__discount">
                  -{couponDiscountPercent || discountPercent}%
                </div>
              )}
            </div>
          </Link>
        </div>

        <Link
          to={makeLinkToProducts({
            key: category.id,
            categoryId: category.id,
          })}
        >
          <div
            className="product-with-category__footer"
            style={{ background: category.color }}
          >
            <span className="product-with-category__category">
              {category.name}
            </span>
          </div>
        </Link>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appDirection: state.appLanguage.appDirection,
  cart: state.cart.cart,
  isCartPending: state.cart.isCartPending,
  wishList: state.wishListReducer.wishList,
  isWishListPending: state.wishListReducer.isWishListPending,
});

export default connect(mapStateToProps, {
  addToCart,
  editCart,
  getCart,
  addProductToWishList,
  getWishList,
})(ProductWithCategory);
