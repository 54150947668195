import React from "react";
import Rating from "@material-ui/lab/Rating";
import StopIcon from "@material-ui/icons/Stop";

import "./input-rate-skill.styles.scss";

const RectMuiIcon = <StopIcon fontSize="large" />;

const InputRateSkill = ({ name, skillRate, onChange }) => {
  return (
    <div className="input-rate-skill-container">
      <Rating
        icon={RectMuiIcon}
        name={name}
        value={skillRate}
        onChange={e => onChange(parseInt(e.target.value))}
        size="large"
      />
    </div>
  );
};

export default InputRateSkill;
