import { axiosNoTokenInstance } from "../../../../axios/axios.config";
import { ResumeActionTypes } from "./resume.types";

export const getResumes = (page = 1) => dispatch => {
  dispatch({ type: ResumeActionTypes.GET.GET_RESUMES_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/department/resume/?page=${page}`)
    .then(resumes =>
      dispatch({
        type: ResumeActionTypes.GET.GET_RESUMES_SUCCESS,
        payload: { count: resumes.data.count, resumes: resumes.data.results }
      })
    )
    .catch(error =>
      dispatch({
        type: ResumeActionTypes.GET.GET_RESUMES_ERROR,
        payload: error
      })
    );
};

export const searchResumes = (searchQuery = "", page = 1) => dispatch => {
  dispatch({ type: ResumeActionTypes.GET.GET_RESUMES_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/department/resume/?search=${searchQuery}&page=${page}`)
    .then(resumes =>
      dispatch({
        type: ResumeActionTypes.GET.GET_RESUMES_SUCCESS,
        payload: resumes.data
      })
    )
    .catch(error =>
      dispatch({
        type: ResumeActionTypes.GET.GET_RESUMES_ERROR,
        payload: error
      })
    );
};
