export const EventInvitationsTypes = {
  GET: {
    GET_VISITORS_OF_EVENT_BY_ID_PENDING: "GET_VISITORS_OF_EVENT_BY_ID_PENDING",
    GET_VISITORS_OF_EVENT_BY_ID_SUCCESS: "GET_VISITORS_OF_EVENT_BY_ID_SUCCESS",
    GET_VISITORS_OF_EVENT_BY_ID_ERROR: "GET_VISITORS_OF_EVENT_BY_ID_ERROR",

    GET_MEETING_INVITATIONS_BY_EVENT_PENDING:
      "GET_MEETING_INVITATIONS_BY_EVENT_PENDING",
    GET_MEETING_INVITATIONS_BY_EVENT_SUCCESS:
      "GET_MEETING_INVITATIONS_BY_EVENT_SUCCESS",
    GET_MEETING_INVITATIONS_BY_EVENT_ERROR:
      "GET_MEETING_INVITATIONS_BY_EVENT_ERROR",

    SEARCH_VISITORS_BY_EMAIL_PENDING: "SEARCH_VISITORS_BY_EMAIL_PENDING",
    SEARCH_VISITORS_BY_EMAIL_SUCCESS: "SEARCH_VISITORS_BY_EMAIL_SUCCESS",
    SEARCH_VISITORS_BY_EMAIL_ERROR: "SEARCH_VISITORS_BY_EMAIL_ERROR",

    GET_MEETING_INVITATIONS_RELATED_TO_ME_PENDING:
      "GET_MEETING_INVITATIONS_RELATED_TO_ME_PENDING",
    GET_MEETING_INVITATIONS_RELATED_TO_ME_SUCCESS:
      "GET_MEETING_INVITATIONS_RELATED_TO_ME_SUCCESS",
    GET_MEETING_INVITATIONS_RELATED_TO_ME_ERROR:
      "GET_MEETING_INVITATIONS_RELATED_TO_ME_ERROR",

    CHECK_IF_USER_IS_GOING_TO_EVENT_PENDING:
      "CHECK_IF_USER_IS_GOING_TO_EVENT_PENDING",
    CHECK_IF_USER_IS_GOING_TO_EVENT_SUCCESS:
      "CHECK_IF_USER_IS_GOING_TO_EVENT_SUCCESS",
    CHECK_IF_USER_IS_GOING_TO_EVENT_ERROR:
      "CHECK_IF_USER_IS_GOING_TO_EVENT_ERROR"
  },

  POST: {
    CREATE_MEETING_INVITATION_WITH_USER_PENDING:
      "CREATE_MEETING_INVITATION_WITH_USER_PENDING",
    CREATE_MEETING_INVITATION_WITH_USER_SUCCESS:
      "CREATE_MEETING_INVITATION_WITH_USER_SUCCESS",
    CREATE_MEETING_INVITATION_WITH_USER_ERROR:
      "CREATE_MEETING_INVITATION_WITH_USER_ERROR"
  },

  PUT: {
    ADD_ME_TO_EVENT_AS_VISITOR_PENDING: "ADD_ME_TO_EVENT_AS_VISITOR_PENDING",
    ADD_ME_TO_EVENT_AS_VISITOR_SUCCESS: "ADD_ME_TO_EVENT_AS_VISITOR_SUCCESS",
    ADD_ME_TO_EVENT_AS_VISITOR_ERROR: "ADD_ME_TO_EVENT_AS_VISITOR_ERROR"
  },

  PATCH: {
    ACCEPT_MEETING_INVITE_PENDING: "ACCEPT_MEETING_INVITE_PENDING",
    ACCEPT_MEETING_INVITE_SUCCESS: "ACCEPT_MEETING_INVITE_SUCCESS",
    ACCEPT_MEETING_INVITE_ERROR: "ACCEPT_MEETING_INVITE_ERROR",

    DECLINE_MEETING_INVITE_PENDING: "DECLINE_MEETING_INVITE_PENDING",
    DECLINE_MEETING_INVITE_SUCCESS: "DECLINE_MEETING_INVITE_SUCCESS",
    DECLINE_MEETING_INVITE_ERROR: "DECLINE_MEETING_INVITE_ERROR"
  },

  CLEAR_MEETING_REQUEST_STATUS: "CLEAR_MEETING_REQUEST_STATUS"
};
