import React from "react";

import DefaultButton from "../default-button/default-button.component";
import { ReactComponent as EnvelopeIcon } from "../../assets/white-small-mail-icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/location-icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/white-phone-icon.svg";
import { ReactComponent as WWWIcon } from "../../assets/www-white-icon.svg";
import { ReactComponent as GreyChatIcon } from "../../assets/grey-chat-icon.svg";
import { ReactComponent as GreyMailIcon } from "../../assets/mail.svg";

import "./event-contacts.styles.scss";

const EventContacts = ({ site }) => {
  return (
    <div className="event-contacts">
      <div className="event-contacts__contacts">
        <h3>Contacts</h3>
        <div className="event-contacts__contacts__upper">
          <div className="event-contacts__contacts__upper__data">
            <span className="event-contacts__contacts__icon">
              <LocationIcon />
            </span>
            <div>
              <h5>Address:</h5>
              <p>Ireland, County Kerry, Milltown, Castlemaine Miltown 23</p>
            </div>
          </div>
          <div className="event-contacts__contacts__upper__data">
            <span className="event-contacts__contacts__icon">
              <EnvelopeIcon />
            </span>
            <div>
              <h5>Email:</h5>
              <p>camera@chamber.md</p>
            </div>
          </div>
          <div className="event-contacts__contacts__upper__btn">
            <DefaultButton
              displayLabel="flex"
              justifyContentLabel="space-between"
              alignItemsLabel="center"
              labelWidth="90px"
              padding="10px 20px"
              border="1px solid lightgrey"
            >
              <GreyChatIcon />
              Live Chat
            </DefaultButton>
          </div>
        </div>
        <div className="event-contacts__contacts__lower">
          <div className="event-contacts__contacts__upper">
            <div className="event-contacts__contacts__upper__data">
              <span className="event-contacts__contacts__icon">
                <PhoneIcon />
              </span>
              <div>
                <h5>Telephone number:</h5>
                <p>+ 373 22 / 22 15 52</p>
              </div>
            </div>
            {
              <div className="event-contacts__contacts__upper__data">
                <span className="event-contacts__contacts__icon">
                  <WWWIcon />
                </span>

                <div>
                  <h5>Website:</h5>
                  <p>chamber.md</p>
                </div>
              </div>
            }

            <div className="event-contacts__contacts__upper__btn">
              <DefaultButton
                displayLabel="flex"
                justifyContentLabel="space-between"
                alignItemsLabel="center"
                labelWidth="90px"
                padding="10px 20px"
                border="1px solid lightgrey"
              >
                <GreyMailIcon />
                Contact us
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="event-contacts__bank">
        <h3>Bank details</h3>
        <div className="event-contacts__bank__upper">
          <h4>SELCO Bank Details:</h4>
          <p>
            SYDBANK A/S – Københavnsvej 9 – DK-4000 Roskilde – Denmark <br />
            Account No.: 6819 1083500
            <br />
            IBAN Code: DK1468192221083499
            <br />
            SWIFT Code: SYBKDK33
            <br />
            VAT no: SE556064198123
          </p>
        </div>
        <hr />
        <div className="event-contacts__bank__lower">
          <h4>HANDELSBANKEN, TÄBY Details:</h4>
          <p>
            SYDBANK A/S – Københavnsvej 9 – DK-4000 Roskilde – Ireland <br />
            SWIFT: HANDSESS <br />
            VAT no: SE556064198601 <br />
            <br />
            IBAN SE 69 6000 0000 0005 2593 1111 (SEK) <br />
            IBAN SE 80 6000 0000 0000 4358 2222 (USD) <br />
            IBAN SE 49 6000 0000 0000 4358 3333 (GBP) <br />
            IBAN SE 52 6000 0000 0000 4358 4444 (CHF) <br />
            IBAN SE 18 6000 0000 0000 4358 5555 (EUR) <br />
          </p>
        </div>
      </div>*/}
      <div className="event-contacts__map">
        <h3>Map</h3>
        <div>
          <img src="https://via.placeholder.com/740x380" alt="" />
        </div>
      </div>
    </div>
  );
};

export default EventContacts;
