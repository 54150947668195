export const ProfileActionTypes = {
  GET: {
    GET_PROFILE_PENDING: "GET_PROFILE_PENDING",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_ERROR: "GET_PROFILE_ERROR",

    SEARCH_USER_BY_EMAIL_PENDING: "SEARCH_USER_BY_EMAIL_PENDING",
    SEARCH_USER_BY_EMAIL_SUCCESS: "SEARCH_USER_BY_EMAIL_SUCCESS",
    SEARCH_USER_BY_EMAIL_ERROR: "SEARCH_USER_BY_EMAIL_ERROR",

    BY_ID: {
      GET_PROFILE_BY_ID_PENDING: "GET_PROFILE_BY_ID_PENDING",
      GET_PROFILE_BY_ID_SUCCESS: "GET_PROFILE_BY_ID_SUCCESS",
      GET_PROFILE_BY_ID_ERROR: "GET_PROFILE_BY_ID_ERROR"
    }
  },
  GET_EMAIL: {
    GET_PROFILE_EMAIL_PENDING: "GET_PROFILE_EMAIL_PENDING",
    GET_PROFILE_EMAIL_SUCCESS: "GET_PROFILE_EMAIL_SUCCESS",
    GET_PROFILE_EMAIL_ERROR: "GET_PROFILE_EMAIL_ERROR"
  },
  POST: {
    POST_PROFILE_PENDING: "POST_PROFILE_PENDING",
    POST_PROFILE_SUCCESS: "POST_PROFILE_SUCCESS",
    POST_PROFILE_ERROR: "POST_PROFILE_ERROR"
  },
  PUT: {
    PUT_PROFILE_PENDING: "PUT_PROFILE_PENDING",
    PUT_PROFILE_SUCCESS: "PUT_PROFILE_SUCCESS",
    PUT_PROFILE_ERROR: "PUT_PROFILE_ERROR"
  },
  PATCH: {
    AVATAR: {
      PATCH_PROFILE_AVATAR_PENDING: "PATCH_PROFILE_AVATAR_PENDING",
      PATCH_PROFILE_AVATAR_SUCCESS: "PATCH_PROFILE_AVATAR_SUCCESS",
      PATCH_PROFILE_AVATAR_ERROR: "PATCH_PROFILE_AVATAR_ERROR"
    },
    IMAGE: {
      PATCH_PROFILE_IMAGE_PENDING: "PATCH_PROFILE_IMAGE_PENDING",
      PATCH_PROFILE_IMAGE_SUCCESS: "PATCH_PROFILE_IMAGE_SUCCESS",
      PATCH_PROFILE_IMAGE_ERROR: "PATCH_PROFILE_IMAGE_ERROR"
    }
  },
  CLEAR_REQUEST_STATUS: "CLEAR_REQUEST_STATUS"
};
