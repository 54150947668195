import icon from "./icon.png";

export default function authLoginFormBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style> 
    .login-form {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      margin: 0;
    }

    .login-form__input {
      background-color: hsla(0, 0%, 100%, 0.8);
      color: #000000;
      font-size: 15px;
      border: 1px solid #b9b9ba;
      padding: 10px 8px;
      border-radius: 3px;
    }

    .login-form__input + .login-form__input {
      margin-top: 10px;
    }
  
    .login-form__message {
      color: #ff3b3b;
      margin-top: 14px;
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="login-form-wrapper">
    <form class="login-form" name="login">
      <input
        class="login-form__input" name="email" required type="email" placeholder="E-mail" />
      <input class="login-form__input" name="password" required type="password" placeholder="Password" />
    </form>
    <div class="login-form__messages">
      <span class="login-form__message">
        Unable to log in with provided credentials.
      </span>
    </div>
  </div>
  `;

  blockManager.add("auth-login-form", {
    label: `
      <img src="${icon}" alt="'Login Form' block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Login Form
      </div>
    `,
    content,
    category: "B24Online Auth"
  });
}
