import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core/";

import {
  GreyChatIcon,
  MailIcon,
  Phone,
  missingImage,
  UserPlaceholder
} from "../../../assets";

import Separator from "../../../components/separator/separator.components";

import { getClients, getCompany } from "../../../redux/actions-exporter";

import "./clients-company-single.styles.scss";

const MyBusinessClientsCompanySingleInfo = ({
  getCompany,
  currentCompany,
  getClients,
  clients
}) => {
  const { typeOfComp, companyId } = useParams();

  useEffect(() => {
    if (typeOfComp === "c") {
      getCompany(companyId);
    }
    getClients(companyId);
  }, [getCompany, getClients, typeOfComp, companyId]);

  return (
    <div className="my-business-clients-company-single-info">
      <Separator />
      <div className="clients-company__inner-content">
        <div className="clients-company__image-container">
          <Link
            to={
              typeOfComp === "c"
                ? `/companies/${companyId}`
                : `/organizations/${companyId}`
            }
          >
            <img
              className="clients-company__company-img"
              src={currentCompany?.logo ? currentCompany.logo : missingImage}
              alt=""
            />
          </Link>
        </div>
        <div className="clients-company__name">
          <Link
            to={
              typeOfComp === "c"
                ? `/companies/${companyId}`
                : `/organizations/${companyId}`
            }
          >
            {currentCompany?.name}:
          </Link>
          <span style={{ fontWeight: "300", padding: "0 15px" }}>Clients</span>
        </div>
      </div>
      <Separator />
      {clients.length && !!clients.length ? (
        <div className="clients-company__table-container">
          <Table aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="center">AVATAR</TableCell>
                <TableCell align="center">NAME</TableCell>
                <TableCell align="center">EMAIL</TableCell>
                <TableCell align="center">CONTACT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map(client => (
                <TableRow key={client.id}>
                  <TableCell align="center">
                    {client.avatar ? (
                      <img
                        className="client-image"
                        src={client.avatar}
                        alt=""
                      />
                    ) : (
                      <UserPlaceholder className="client-image" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {!!(client.first_name && client.last_name)
                      ? `${client.first_name} ${client.last_name}`
                      : "No name"}
                  </TableCell>
                  <TableCell align="center">{client.user_email}</TableCell>
                  <TableCell align="center">
                    <div className="contact-links">
                      <MailIcon width="16px" height="16px" />
                      <Phone />
                      <GreyChatIcon />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div style={{ padding: "20px 15px" }}>No clients</div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isPendingCurrentCompany: state.companiesReducer.isCompanyPending,
  currentCompany: state.companiesReducer.company,
  clients: state.clientsReducer.clients
});

export default connect(mapStateToProps, {
  getCompany,
  getClients
})(MyBusinessClientsCompanySingleInfo);
