export default function recommendedProductsComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makeProductHtml(
      id,
      src,
      title,
      cost,
      currency,
      detailsPath,
      type,
      translations
    ) {
      return `
      <div class="rp-product">
        <a class="rp-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
          <img
            class="rp-product__image"
            src="${src}"
            onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
          />
        </a>
        <div class="rp-product__main">
          <h4 class="rp-product__title">
            <a class="rp-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
              ${title}
            </a>
          </h4>
          <p class="rp-product__meta rp-product__meta--cost">
          ${
            cost && currency
              ? `
            ${`
              <span class="rp-product__cost">
                ${cost}
              </span>
              <span class="rp-product__currency">
                ${translations[currency]}
              </span>
            `}
          `
              : `
            <span class="rp-product__cost">
              ${translations["N/A"]}
            </span>
          `
          }
          </p>
          <a class="rp-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
            <button class="rp-product__button">
              ${translations["VIEW"]}
            </button>
          </a>
        </div>
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".recommended-products-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("recommended-products-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("recommended-products-wrapper--preloader");
      }
    }

    function render(
      type,
      total,
      page,
      perPage,
      products,
      categories,
      detailsPath,
      loading,
      translations
    ) {
      const wrapperEl = document.querySelector(".recommended-products-wrapper");
      const isRTL = window.localStorage.getItem("direction") === "rtl";
      setLoading(loading);

      if (products.length) {
        const productsHtml = products.reduce(
          (tmp, product) =>
            tmp +
            makeProductHtml(
              product.id,
              product.image,
              product.name,
              product.cost,
              product.currency,
              detailsPath,
              type,
              translations
            ),
          ""
        );

        wrapperEl.innerHTML = `
          <div class="recommended-products ${
            isRTL ? "recommended-products_rtl" : ""
          }">
            ${productsHtml}
          </div>  
        `;
      } else {
        wrapperEl.innerHTML = `
          <p style="margin: 0">
            <span class="recommended-products__empty">
              ${translations["No recommended products..."]}
            </span>
          </p>
        `;
        return;
      }
    }

    if (!window.hasOwnProperty("recommended_products")) {
      const API_ROOT = "{[ apiRoot ]}";
      const STATIC_ROOT = "{[ staticRoot ]}";

      window.recommended_products = {
        API_ROOT: API_ROOT,
        STATIC_ROOT: STATIC_ROOT,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 10,
        type: "b2c",
        params: {},
        items: [],
        categories: [],
        loading: false,
        translations: {},
        detailsPath: "#",
        render() {
          console.group("recommended products render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.type,
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.categories,
              this.detailsPath,
              this.loading,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("recommended products render");
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", page, this.getProducts.bind(this));
        },
        setPerPage(count) {
          this.setParam("page_size", parseInt(count));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path);
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getProducts(immediately) {
          if (this.loading && !immediately) return;
          this.setLoading(true);

          const params = new URLSearchParams(
            Object.assign(
              {},
              this.params,
              { page: this.page },
              {
                categories: this.categories
                  .filter(category => category.selected)
                  .map(category => category.id)
                  .join()
              }
            )
          );
          return fetch(`${this.API_ROOT}/recommended/${this.type}/?${params}`, {
            method: "GET",
            headers: {
              "Accept-Language": localStorage.getItem("language")
            }
          })
            .then(response => response.json())
            .then(response =>
              this.setProducts(response.count, response.results)
            );
        },
        setProducts(count, items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.render();
          }
          // Set count
          this.count = count || 0;
          // Set items
          this.items = items || [];
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        getCategories(text) {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/${this.type}/category/?search=${text}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(response =>
              this.setCategories(
                this.categories
                  .filter(category => category.selected)
                  .concat(
                    response.results.map(category => ({
                      id: category.id,
                      title: category.name,
                      selected: false
                    }))
                  )
                  .reduce(
                    (tmp, category) =>
                      tmp.find(i => i.id === category.id)
                        ? tmp
                        : tmp.concat([category]),
                    []
                  )
              )
            );
        },
        setCategories(categories) {
          // Reset page
          this.page = 1;
          // Set categories
          this.categories = categories
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => (a.selected ? -1 : 1));
          // Start render cycle
          this.setLoading(false);
          this.getProducts();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty("recommended_products") &&
      window.hasOwnProperty("grapesjs")
    ) {
      if (
        !window.recommended_products.renderCycle.find(func => func === render)
      ) {
        window.recommended_products.renderCycle.push(render);
      }

      if (attributes.producttype) {
        window.recommended_products.setType(attributes.producttype.value);
      }

      if (attributes.perpage) {
        window.recommended_products.setPerPage(attributes.perpage.value);
      }

      if (attributes.detailspage) {
        window.recommended_products.setDetailsPath(
          attributes.detailspage.value
        );
      }

      const organization = "{[ organization ]}";
      window.recommended_products.setParam("company", organization);

      const translations = getTranslate("translations", attributes);
      window.recommended_products.setProp("translations", translations);

      window.recommended_products.getProducts(true);
    }
  };

  editor.DomComponents.addType("recommended-products", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "recommended-products-wrapper",
    model: {
      defaults: {
        tagName: "div",
        name: "Recomended Products",
        organization: site && site.organization,
        apiRoot: api.API_ROOT,
        staticRoot: api.STATIC_ROOT,
        script,
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "number",
            label: "Products per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["No recommended products...", "N/A", "VIEW", "ILS"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(this.model, "change:attributes:producttype", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
