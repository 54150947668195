import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createUser } from "~/redux/actions-exporter";

import { DefaultButton, DefaultSnackbar } from "@core/components";

import { colorPalette } from "../../styles/colorPalette";
import { useTranslation } from "react-i18next";

const LoginInput = ({ createUser, createUserError }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  return (
    <form onSubmit={e => e.preventDefault()}>
      <p>{t("Email")}</p>
      <input
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />
      <p>{t("Password")}</p>
      <input
        value={password}
        onChange={e => setPass(e.target.value)}
        type="password"
      />
      <p>{t("Confirm Password")}</p>
      <input
        value={confirmPass}
        onChange={e => setConfirmPass(e.target.value)}
        type="password"
      />
      {/* CAPCHA WILL GO HERE */}
      <div className="login-modal__body-column__signin-forgot-pass">
        <label>
          <input type="checkbox" />
          {t("Keep me signed in")}
        </label>
        <Link to="/password/reset">{t("Forgot password?")}</Link>
      </div>
      <DefaultButton
        onClick={() => {
          if (password === confirmPass) {
            createUser(email, password);
          } else {
            return (
              <DefaultSnackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant="error"
                message={t("Passwords do not match")}
                autoHideDuration={2000}
              />
            );
          }
        }}
        color="white"
        backgroundColor={colorPalette.colorPrimary.main}
        hover={colorPalette.colorPrimary.darker}
        width="100%"
        ButtonOptions={{
          type: "submit"
        }}
      >
        {t("Sign Up")}
      </DefaultButton>

      {createUserError && createUserError.email && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="error"
          message={createUserError.email}
          autoHideDuration={4000}
        />
      )}
    </form>
  );
};

const mapStateToProps = state => ({
  createUserError: state.authReducer.createUserError
});

export default connect(mapStateToProps, { createUser })(LoginInput);
