import { ProfileActionTypes } from "./profile.types";

export const handleEmailChange = email => ({
  type: ProfileActionTypes.EMAIL_INPUT,
  payload: email
});

export const handleLastNameChange = lastName => ({
  type: ProfileActionTypes.LAST_NAME_INPUT,
  payload: lastName
});

export const handleFirstNameChange = firstName => ({
  type: ProfileActionTypes.FIRST_NAME_INPUT,
  payload: firstName
});

export const handleAvatarChange = avatar => ({
  type: ProfileActionTypes.AVATAR_INPUT,
  payload: avatar
});

export const handleBirthdayChange = birthday => ({
  type: ProfileActionTypes.BIRTHDAY_INPUT,
  payload: birthday
});

export const handlePasswordChange = password => ({
  type: ProfileActionTypes.PASSWORD_INPUT,
  payload: password
});

export const handlePhoneNumberChange = phoneNumber => ({
  type: ProfileActionTypes.PHONE_NUMBER_INPUT,
  payload: phoneNumber
});

export const handleCountryChange = country => ({
  type: ProfileActionTypes.COUNTRY_INPUT,
  payload: country
});

export const handleWebsiteChange = website => ({
  type: ProfileActionTypes.WEBSITE_INPUT,
  payload: website
});

export const handleProfessionChange = profession => ({
  type: ProfileActionTypes.PROFESSION_INPUT,
  payload: profession
});

export const handleProfileTypeChange = profileType => ({
  type: ProfileActionTypes.PROFILE_TYPE_INPUT,
  payload: profileType
});

export const handleSexChange = sex => ({
  type: ProfileActionTypes.SEX_INPUT,
  payload: sex
});

export const handleCompanyNameChange = companyName => ({
  type: ProfileActionTypes.COMPANY_NAME_INPUT,
  payload: companyName
});

export const handleCompanySloganChange = companySlogan => ({
  type: ProfileActionTypes.COMPANY_SLOGAN_INPUT,
  payload: companySlogan
});

export const handleCompanyPhoneChange = companyPhone => ({
  type: ProfileActionTypes.COMPANY_PHONE_INPUT,
  payload: companyPhone
});

export const handleCompanyFaxChange = companyFax => ({
  type: ProfileActionTypes.COMPANY_FAX_INPUT,
  payload: companyFax
});

export const handleAddressChange = address => ({
  type: ProfileActionTypes.ADDRESS_INPUT,
  payload: address
});

export const handleCompanyDescriptionChange = companyDescription => ({
  type: ProfileActionTypes.COMPANY_DESCRIPTION_INPUT,
  payload: companyDescription
});

export const handleFacebookChange = facebook => ({
  type: ProfileActionTypes.SOCIAL_LINKS.FACEBOOK_INPUT,
  payload: facebook
});

export const handleLinkedinChange = linkedIn => ({
  type: ProfileActionTypes.SOCIAL_LINKS.LINKEDIN_INPUT,
  payload: linkedIn
});

export const handleTwitterChange = twitter => ({
  type: ProfileActionTypes.SOCIAL_LINKS.TWITTER_INPUT,
  payload: twitter
});

export const handleInstagramChange = instagram => ({
  type: ProfileActionTypes.SOCIAL_LINKS.INSTAGRAM_INPUT,
  payload: instagram
});

export const handleYoutubeChange = youtube => ({
  type: ProfileActionTypes.SOCIAL_LINKS.YOUTUBE_INPUT,
  payload: youtube
});

export const handleRedditChange = reddit => ({
  type: ProfileActionTypes.SOCIAL_LINKS.REDDIT_INPUT,
  payload: reddit
});

export const handleBusinessCardType = cardType => ({
  type: ProfileActionTypes.BUSINESS_CARD_TYPE,
  payload: cardType
});

export const handleActiveCompany = activeCompanyId => ({
  type: ProfileActionTypes.PROFILE_ACTIVE_COMPANY,
  payload: activeCompanyId
});
