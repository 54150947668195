import content from "./block-content";
import icon from "./icon.png";

export default function productCarouselBlock({ editor }) {
  const blockManager = editor.BlockManager;

  blockManager.add("product-carousel", {
    label: `
      <img src="${icon}" alt="Products Carousel block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Product Carousel
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
