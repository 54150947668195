import React from "react";
import { connect } from "react-redux";

import { getNewsByCompanyId } from "../../redux/APIs/news/news.action";

import "./event-main.styles.scss";

const EventMain = ({
  description,
  organizationId,
  getNewsByCompanyId,
  areNewsByCompanyIdPending,
  news
}) => {
  // useEffect(() => {
  //   getNewsByCompanyId(organizationId);
  // }, [getNewsByCompanyId, organizationId]);

  return (
    <div className="event-main">
      <div className="event-main__title">Description</div>
      <div className="event-main__description">{description}</div>
      {/* <div className="event-main__title">News</div>
      <div className="event-main__description">News</div> */}
    </div>
  );
};

const mapStateToProps = state => ({
  areNewsByCompanyIdPending: state.newsReducer.areNewsByCompanyIdPending,
  news: state.newsReducer.newsByCompanyId
});

export default connect(mapStateToProps, { getNewsByCompanyId })(EventMain);
