export default function wishListComponent({ editor, site, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
      <div class="wl-pagination">
        ${
          currentPage - 1
            ? `
            <button class="wl-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="wl-pagination__button" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="wl-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${currentPage - 4 > 0 ? "..." : ""}

        ${
          currentPage - 2 > 0
            ? `
            <button class="wl-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="wl-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="wl-pagination__button wl-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="wl-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${currentPage + 3 <= totalPagesCount ? "..." : ""}

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="wl-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="wl-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="wl-pagination__button" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function calculatePrice(cost = 0, discountPercent = 0, couponPercent = 0) {
      const finnalyDiscountPercent = couponPercent || discountPercent;
      if (!finnalyDiscountPercent) return parseFloat(cost);

      return parseFloat(
        cost - (parseFloat(cost) / 100) * parseFloat(finnalyDiscountPercent)
      );
    }

    function makeWishTableHtml(items, detailsPath, translations) {
      return `
      <table class="wish-list">
        <thead class="wish-list__header">
          <tr>
            <th class="wish-list__header-title wish-list__header-title--item">
              ${translations["PRODUCT"]}
            </th>
            <th class="wish-list__header-title wish-list__header-title--name">
              ${translations["NAME"]}
            </th>
            <th class="wish-list__header-title wish-list__header-title--total" colspan="3">
              ${translations["COST"]}
            </th>
          </tr>
        </thead>
        <tbody>
        ${
          !items.length
            ? `
            <tr>
              <td colspan="5" style="padding: 1rem;border-right: 1px solid #aeaeae;">
                ${translations["Your wish list is currently empty."]}
              </td>
            </tr>`
            : ""
        }
        ${items
          .map((item, index) =>
            item.wish_list_item
              .map(
                (wishItem, wishItemIndex) => `
          <tr>
            <td>
              <div class="wish-item-media">
                <img class="wish-item-media__image" src="${
                  wishItem.image
                }" alt="${wishItem.name} thumbnail" />
              </div>
            </td>
            <td>
              <div class="wish-item-main">
                <span class="wish-item-main__capture">
                  ${wishItem.categories
                    .reduce((tmp, category) => tmp.concat(category.name), [])
                    .join(", ")}
                </span>
                <h4 class="wish-item-main__title">
                  <a
                    class="wish-item-main__link"
                    href="${detailsPath}?gjs-product-id=${
                  wishItem.item_id
                }&gjs-product-type=${
                  wishItem.content_type === 84 ? "b2b" : "b2c"
                }"
                  >
                    ${wishItem.name}
                  </a>
                </h4>
              </div>          
            </td>
            <td>
              ${
                wishItem.currency &&
                wishItem.cost &&
                parseFloat(wishItem.cost) > 0
                  ? `
                    <span class="wish-list__cost">
                    ${calculatePrice(
                      wishItem.cost,
                      wishItem.discount_percent,
                      wishItem.coupon_discount
                    ).toFixed(2)}
                    </span>
                    <span class="wish-list__currency">
                      ${wishItem.currency}
                    </span>
                    ${
                      wishItem.discount_percent || wishItem.coupon_discount
                        ? `
                    <span class="wish-list__percent-discount">
                      -${(
                        wishItem.coupon_discount || wishItem.discount_percent
                      ).toFixed(2)}%
                    </span>
                    `
                        : ""
                    }
                  `
                  : `
                  <span class="wish-list__no-price">
                    ${translations["N/A"]}
                  </span>
                  `
              }
            </td>
            <td>
              <button${
                !wishItem.cost || !wishItem.currency ? " disabled " : ""
              } class="wish-list__button ${
                  !wishItem.cost || !wishItem.currency
                    ? "wish-list__button--disabled"
                    : ""
                }" type="button" data-product_id="${
                  wishItem.item_id
                }" data-product_type="${
                  wishItem.content_type === 84 ? "b2b" : "b2c"
                }">
                ${translations["Add to cart"]}
              </button>
            </td>
            <td class="wish-list__remove">
              <span class="wish-list__remove-icon" data-id="${item.id}">X</span>
            </td>
          </tr>
        `
              )
              .join("")
          )
          .join("")}
        </tbody>
      </table>
      `;
    }

    function makeWishTableSlimHtml(items, detailsPath, translations) {
      return `
      <table class="wish-list-slim" style="visibility: hidden; display: none;">
        <thead class="wish-list-slim__header">
          <tr>
            <th class="wish-list-slim__header-title wish-list-slim__header-title--item">
              ${translations["PRODUCT"]}
            </th>
            <th class="wish-list-slim__header-title wish-list-slim__header-title--name">
              ${translations["NAME"]}
            </th>
            <th class="wish-list-slim__header-title wish-list-slim__header-title--total" colspan="3">
              ${translations["COST"]}
            </th>
          </tr>
        </thead>
        <tbody>
        ${
          !items.length
            ? `
            <tr>
              <td colspan="5" style="padding: 1rem;border-right: 1px solid #aeaeae;">
                ${translations["Your wish list is currently empty."]}
              </td>
            </tr>`
            : ""
        }
        ${items
          .map((item, index) =>
            item.wish_list_item
              .map(
                (wishItem, wishItemIndex) => `
          <tr>
            <td>
              <div class="wish-item-slim-media">
                <img class="wish-item-slim-media__image" src="${
                  wishItem.image
                }" alt="${wishItem.name} thumbnail" />
              </div>
            </td>
            <td>
              <div class="wish-item-slim-main">
                <span class="wish-item-slim-main__capture">
                  ${wishItem.categories
                    .reduce((tmp, category) => tmp.concat(category.name), [])
                    .join(", ")}
                </span>
                <h4 class="wish-item-slim-main__title">
                  <a
                    class="wish-item-slim-main__link"
                    href="${detailsPath}?gjs-product-id=${
                  wishItem.item_id
                }&gjs-product-type=${
                  wishItem.content_type === 84 ? "b2b" : "b2c"
                }"
                  >
                    ${wishItem.name}
                  </a>
                </h4>
              </div>          
            </td>
            <td>
              ${
                wishItem.currency &&
                wishItem.cost &&
                parseFloat(wishItem.cost) > 0
                  ? `
                    <span class="wish-list-slim__cost">
                    ${calculatePrice(
                      wishItem.cost,
                      wishItem.discount_percent,
                      wishItem.coupon_discount
                    ).toFixed(2)}
                    </span>
                    <span class="wish-list-slim__currency">
                      ${wishItem.currency}
                    </span>
                    ${
                      wishItem.discount_percent || wishItem.coupon_discount
                        ? `
                    <span class="wish-list-slim__percent-discount">
                      -${(
                        wishItem.coupon_discount || wishItem.discount_percent
                      ).toFixed(2)}%
                    </span>
                    `
                        : ""
                    }
                  `
                  : `
                  <span class="wish-list-slim__no-price">
                    ${translations["N/A"]}
                  </span>
                  `
              }
            </td>
            <td>
              <button${
                !wishItem.cost || !wishItem.currency ? " disabled " : ""
              } class="wish-list-slim__button ${
                  !wishItem.cost || !wishItem.currency
                    ? "wish-list-slim__button--disabled"
                    : ""
                }" type="button" data-product_id="${
                  wishItem.item_id
                }" data-product_type="${
                  wishItem.content_type === 84 ? "b2b" : "b2c"
                }">
                ${translations["Add to cart"]}
              </button>
            </td>
            <td class="wish-list-slim__remove">
              <span class="wish-list-slim__remove-icon" data-id="${
                item.id
              }">X</span>
            </td>
          </tr>
        `
              )
              .join("")
          )
          .join("")}
        </tbody>
      </table>
      `;
    }

    function setLoading(wrapperEl, loading) {
      if (!wrapperEl) return;

      if (loading) {
        wrapperEl.classList.add("wish-list-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("wish-list-wrapper--preloader");
      }
    }

    function render(page, perPage, detailsPage, translations) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      const wrapperEl = this;
      let resizeInterval = null;

      setLoading(wrapperEl, true);
      fetch(
        `${API_ROOT}/wish_list/wish_list/?page=${page}&page_size=${perPage}&company=${organization}`,
        {
          method: "GET",
          headers: Object.assign(
            {"Accept-Language": localStorage.getItem("language")},
            localStorage.getItem("token")
              ? {Authorization: `Token ${localStorage.getItem("token")}`}
              : {}
          ),
          cors: "cors",
          credentials: "omit"
        }
      )
        .then(response => response.json())
        .then(json => {
          const items = json.results;
          setLoading(wrapperEl, false);

          wrapperEl.innerHTML = "";
          wrapperEl.innerHTML = `
            ${makeWishTableHtml(items, detailsPage, translations)}
            ${makeWishTableSlimHtml(items, detailsPage, translations)}
            ${makePaginationHtml(page, json.count, perPage)}  
          `;

          const wishListSlimEl = wrapperEl.querySelector(".wish-list-slim");
          const wishListEl = wrapperEl.querySelector(".wish-list");

          const checkIsSlim = () => {
            const documentWidth = document.body.clientWidth <= 992;
            const wrapperElWidth = wrapperEl.clientWidth <= 710;

            return documentWidth || wrapperElWidth;
          };

          clearInterval(resizeInterval);
          resizeInterval = setInterval(() => {
            if (checkIsSlim()) {
              wishListEl.style.visibility = "hidden";
              wishListEl.style.display = "none";
              wishListSlimEl.style.visibility = "visible";
              wishListSlimEl.style.display = "table";
            } else {
              wishListEl.style.visibility = "visible";
              wishListEl.style.display = "table";
              wishListSlimEl.style.visibility = "hidden";
              wishListSlimEl.style.display = "none";
            }
          }, 1000);

          if (!items.length) {
            setLoading(wrapperEl, true);
            return;
          }

          const paginationButtonsEls = document.querySelectorAll(
            ".wl-pagination__button:not(disabled)"
          );

          Array.prototype.slice
            .call(paginationButtonsEls)
            .map(paginationButtonEl =>
              paginationButtonEl.addEventListener("click", e => {
                const btnPage = parseInt(e.target.dataset.page);
                render.call(this, btnPage, perPage, detailsPage, translations);
              })
            );

          Array.prototype.slice
            .call(
              document.querySelectorAll(
                ".wish-list__button:not(.wish-list__button--disabled)"
              )
            )
            .map(buyButtonEl =>
              buyButtonEl.addEventListener("click", e => {
                const productId = parseInt(e.target.dataset.product_id);
                const productType = e.target.dataset.product_type;

                if (e.target.disabled) {
                  return;
                }
                fetch(`${API_ROOT}/payments/cart/`, {
                  method: "POST",
                  headers: Object.assign(
                    {
                      "Accept-Language": localStorage.getItem("language"),
                      "Content-Type": "application/json",
                    },
                    localStorage.getItem("token")
                      ? {Authorization: `Token ${localStorage.getItem("token")}`}
                      : {}
                  ),
                  body: JSON.stringify({
                    item_b2c: productType === "b2c" ? productId : "",
                    item_b2b: productType === "b2b" ? productId : "",
                    quantity: 1,
                    customer_type: "person",
                    customer_organization: ""
                  })
                }).then(() => {
                  e.target.innerText = translations["ADDED"];
                  e.target.classList.add("wish-list__button--disabled");
                  e.target.setAttribute("disabled", "true");
                });
              })
            );

          // Delete item from wish list
          Array.prototype.slice
            .call(document.querySelectorAll(".wish-list__remove-icon"))
            .map(el =>
              el.addEventListener("click", e => {
                const id = parseInt(e.target.dataset.id);

                fetch(`${API_ROOT}/wish_list/wish_list/${id}/`, {
                  method: "DELETE",
                  headers: Object.assign(
                    {"Accept-Language": localStorage.getItem("language")},
                    localStorage.getItem("token")
                      ? {Authorization: `Token ${localStorage.getItem("token")}`}
                      : {}
                  )
                }).then(() =>
                  render.call(this, 1, perPage, detailsPage, translations)
                );
              })
            );
        });
    }

    if (window.hasOwnProperty("grapesjs")) {
      // Get translation
      const translations = getTranslate("translations", this.attributes);
      const perPage = this.attributes.perpage
        ? parseInt(this.attributes.perpage.value)
        : 10;
      const detailsPage = this.attributes.detailspage
        ? this.attributes.detailspage.value
        : "#";
      // Render
      render.call(this, 1, perPage, detailsPage, translations);
    } else {
      const wrapperEl = this;
      const wishListSlimEl = wrapperEl.querySelector(".wish-list-slim");
      const wishListEl = wrapperEl.querySelector(".wish-list");

      const checkIsSlim = () => {
        const documentWidth = document.body.clientWidth <= 992;
        const wrapperElWidth = this.clientWidth <= 710;
        const isMobileEditMode = this.attributes.slimeedit
          ? this.attributes.slimeedit.value
          : false;

        return isMobileEditMode || documentWidth || wrapperElWidth;
      };

      setInterval(() => {
        if (checkIsSlim()) {
          wishListEl.style.visibility = "hidden";
          wishListEl.style.display = "none";
          wishListSlimEl.style.visibility = "visible";
          wishListSlimEl.style.display = "table";
        } else {
          wishListEl.style.visibility = "visible";
          wishListEl.style.display = "table";
          wishListSlimEl.style.visibility = "hidden";
          wishListSlimEl.style.display = "none";
        }
      }, 1000);
    }
  };

  editor.DomComponents.addType("wish-list", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "wish-list-wrapper",
    model: {
      defaults: {
        name: "Wish List",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "checkbox",
            label: "Slim edit",
            name: "slimeedit",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "number",
            label: "Products per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "PRODUCT",
              "NAME",
              "COST",
              "Your wish list is currently empty.",
              "N/A",
              "Add to cart",
              "ADDED"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
