import React from "react";
import Slider from "react-slick";

import BuilderTemplate from "../builder-template/builder-template.component";
import Separator from "../separator/separator.components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import "./builder-themes.styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BuilderThemes = ({ themes, value, onChange }) => {
  const slider = {
    arrows: true,
    prevArrow: (
      <div>
        <ChevronLeftIcon />
      </div>
    ),
    nextArrow: (
      <div>
        <ChevronRightIcon />
      </div>
    ),
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <FormControl className="builder-themes" component="fieldset">
      <RadioGroup
        className="builder-themes__radio-group"
        aria-label="position"
        name="position"
        value={`${value}`}
        onChange={onChange}
      >
        {!themes.length && (
          <Box fontSize="h6.fontSize" className="builder-themes__empty">
            No themes...
          </Box>
        )}
        {themes.map((theme, index) => (
          <div className="builder-themes__item" key={index}>
            <div className="builder-themes">
              <div className="builder-themes__header">
                <div className="builder-themes__basic-info">
                  <h3 className="builder-themes__title">{theme.title}</h3>
                  <span className="builder-themes__caption">
                    {theme.tags_names
                      ? theme.tags_names.map(tag => tag.title).join(", ")
                      : ""}
                  </span>
                </div>
                <FormControlLabel
                  value={`${theme.id}`}
                  control={<Radio color="default" />}
                  label={
                    <span style={{ fontSize: 18 }}>Choose this theme</span>
                  }
                  labelPlacement="start"
                />
              </div>
              <div className="builder-themes__main">
                <Slider {...slider}>
                  {theme.templates.map((template, index) => (
                    <div key={index}>
                      <BuilderTemplate {...template} />
                    </div>
                  ))}
                </Slider>
              </div>
              {index + 1 !== themes.length && (
                <Separator
                  style={{ display: "inline-block", marginTop: "2em" }}
                />
              )}
            </div>
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default BuilderThemes;
