import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { missingImage } from "../../../assets";

import {
  ColumnsSmLgWrapper,
  ColumnWrapperSm,
  ColumnWrapperLg,
  ParentWrapper,
  ButtonsFooterWrapper
} from "../../../layout";

import {
  MediaActions,
  DefaultButton,
  Separator,
  InputGroup,
  ColorPicker,
  CircleLoader,
  GallerySection
} from "../../../components";

import {
  addProductVariation,
  putProductVariation,
  getProductVariationById,
  clearProductVariationRequestStatus,
  deleteOneImageFromGallery,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery
} from "../../../redux/actions-exporter";

import { buttonCancel, buttonOk } from "../../../styles/buttons-styles";
import "./variation-products-edit.styles.scss";

const MyBusinessAddVariationProducts = ({
  addProductVariation,
  putProductVariation,
  getProductVariationById,
  productVariationById,
  productVariationsRequestStatus,
  clearProductVariationRequestStatus,
  deleteOneImageFromGallery,
  galleryBlob,
  galleryFile,
  isImageGalleryUploadPending,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery
}) => {
  const history = useHistory();
  const { urlProductType, variationId, productId } = useParams();

  const [productName, setProductName] = useState("");
  const [sku, setSKU] = useState("");
  const [productCost, setProductCost] = useState("");
  const [productStock, setProductStock] = useState("");
  const [productSize, setProductSize] = useState("");
  const [productValue, setProductValue] = useState("");
  const [productColor, setProductColor] = useState("");
  const [productVariationGallery, setProductVariationGallery] = useState([]);
  const [file, setFile] = useState("");
  const [blob, setBlob] = useState();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (variationId !== "create") {
      getProductVariationById(urlProductType, variationId);
    }
  }, [getProductVariationById, urlProductType, variationId]);

  useEffect(() => {
    if (variationId !== "create") {
      const {
        name,
        image,
        cost,
        stock,
        value,
        color,
        size,
        galleries,
        metadata
      } = productVariationById;
      setProductName(name);
      setProductCost(cost);
      setFile(image);
      setSKU(metadata?.stock_keeping_unit);
      setProductStock(stock);
      setProductSize(size);
      setProductValue(value);
      setProductColor(color);
      setProductVariationGallery(galleries);
    }
  }, [variationId, productVariationById]);

  useEffect(() => {
    if (
      productVariationsRequestStatus === 200 ||
      productVariationsRequestStatus === 201
    ) {
      history.push(`/my-business/products/${urlProductType}/${productId}`);
    }
  }, [history, urlProductType, productId, productVariationsRequestStatus]);

  useEffect(() => {
    setDisabled(
      productName === "" ||
        productCost === "" ||
        productStock === "" ||
        sku === ""
    );
  }, [productName, productCost, productStock]); // eslint-disable-line

  useEffect(() => () => clearProductVariationRequestStatus(), [
    clearProductVariationRequestStatus
  ]);

  const requestProductVariationsHandler = () => {
    if (variationId === "create") {
      addProductVariation(
        urlProductType,
        productSize,
        productColor,
        productValue,
        productName,
        blob,
        productStock,
        productCost,
        productId,
        sku
      );
    } else {
      putProductVariation(
        variationId,
        urlProductType,
        productSize,
        productColor,
        productValue,
        productName,
        blob,
        productStock,
        productCost,
        productId,
        sku
      );
    }
  };

  return (
    <div className="my-business-add-variation-products">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={file ? file : missingImage}
            alt={"Big-Ben"}
            height={200}
            buttons={[
              <DefaultButton
                color="gray"
                width="100%"
                border="1px solid lightgray"
              >
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                color="gray"
                width="100%"
                border="1px solid lightgray"
                onClick={() => {
                  setFile(null);
                  setBlob(missingImage);
                }}
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {variationId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadImageGallery(
                              urlProductType === "b2b" ? "b2bV" : "b2cV",
                              variationId,
                              galleryBlob
                            )
                          }
                          disabled={galleryBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <InputGroup
              label="Product name"
              name="productName"
              placeholder="Product name..."
              onChange={e => setProductName(e.target.value)}
              value={productName}
              required={true}
            />
            <InputGroup
              onChange={e => setSKU(e.target.value)}
              value={sku}
              label="SKU"
              name="sku"
              placeholder="SKU"
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="COST"
              name="productCost"
              placeholder="Cost..."
              onChange={e => setProductCost(e.target.value)}
              value={productCost}
              required={true}
            />
            <InputGroup
              label="Stock"
              name="productStock"
              placeholder="Stock..."
              onChange={e => setProductStock(e.target.value)}
              value={productStock}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="SIZE"
              name="productSize"
              placeholder="Size..."
              onChange={e => setProductSize(e.target.value)}
              value={productSize}
            />
            <InputGroup
              label="VALUE"
              name="productValue"
              placeholder="Value..."
              onChange={e => setProductValue(e.target.value)}
              value={productValue}
            />
          </ParentWrapper>
          <ParentWrapper>
            <ColorPicker
              label="Color"
              itemColor={productColor}
              setItemColor={setProductColor}
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      {productVariationGallery &&
        productVariationGallery[0] &&
        !!productVariationGallery[0].images.length && (
          <>
            <Separator />
            <GallerySection
              label="Gallery"
              gallery={productVariationGallery}
              deleteOneImageFromGallery={deleteOneImageFromGallery}
              parentId={variationId}
              contentType={urlProductType === "b2b" ? "b2bV" : "b2cV"}
            />
          </>
        )}
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            width="100%"
            {...buttonOk}
            onClick={requestProductVariationsHandler}
            disabled={disabled}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.goBack()}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  areCurrenciesPending: state.utilsReducer.areCurrenciesPending,
  productVariationById: state.productVariations.productVariationById,
  productVariationsRequestStatus:
    state.productVariations.productVariationsRequestStatus,
  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,
  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending
});

export default connect(mapStateToProps, {
  addProductVariation,
  putProductVariation,
  getProductVariationById,
  clearProductVariationRequestStatus,
  deleteOneImageFromGallery,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery
})(MyBusinessAddVariationProducts);
