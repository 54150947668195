import { ShippingActionTypes } from "./shipping.types";

const INITIAL_STATE = {
  areShippingMethodsPending: false,
  shippingMethods: null,
  shippingMethodsError: null,

  isShippingMethodByIdPending: false,
  shippingMethodById: {},
  shippingMethodByIdError: {},

  isShippingMethodsCreatePending: false,
  shippingMethodsCreated: null,
  shippingMethodsCreatedError: null,

  isShippingMethodsPuttingPending: false,
  shippingMethodPut: null,
  shippingMethodsPutError: null,

  areShippingByCompanyPending: false,
  shippingByCompany: [],
  shippingByCompanyError: {},

  isShippingMethodByIdDeleting: false,
  deletedShippingMethod: null,
  deletedShippingMethodError: null,

  deliveryRequestStatus: ""
};

const ShippingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_ME_PENDING:
      return {
        ...state,
        areShippingMethodsPending: true
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_ME_SUCCESS:
      return {
        ...state,
        shippingMethods: action.payload,
        areShippingMethodsPending: false
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_ME_ERROR:
      return {
        ...state,
        shippingMethodsError: action.payload,
        areShippingMethodsPending: false
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHOD_BY_ID_PENDING:
      return {
        ...state,
        isShippingMethodByIdPending: true
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHOD_BY_ID_SUCCESS:
      return {
        ...state,
        isShippingMethodByIdPending: false,
        shippingMethodById: action.payload
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHOD_BY_ID_ERROR:
      return {
        ...state,
        isShippingMethodByIdPending: false,
        shippingMethodByIdError: action.payload
      };

    case ShippingActionTypes.POST.CREATE_SHIPPING_METHOD_PENDING:
      return {
        ...state,
        isShippingMethodsCreatePending: true
      };

    case ShippingActionTypes.POST.CREATE_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        isShippingMethodsCreatePending: false,
        deliveryRequestStatus: action.payload.requestStatus,
        shippingMethodsCreated: action.payload
      };

    case ShippingActionTypes.POST.CREATE_SHIPPING_METHOD_ERROR:
      return {
        ...state,
        isShippingMethodsCreatePending: false
      };

    case ShippingActionTypes.PUT.PUT_SHIPPING_METHOD_PENDING:
      return {
        ...state,
        isShippingMethodsPuttingPending: true
      };

    case ShippingActionTypes.PUT.PUT_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        isShippingMethodsPuttingPending: false,
        deliveryRequestStatus: action.payload.requestStatus,
        shippingMethodPut: action.payload
      };

    case ShippingActionTypes.PUT.PUT_SHIPPING_METHOD_ERROR:
      return {
        ...state,
        isShippingMethodsPuttingPending: false,
        shippingMethodsPutError: action.payload
      };

    case ShippingActionTypes.DELETE.DELETE_SHIPPING_METHOD_BY_ID_PENDING:
      return {
        ...state,
        isShippingMethodByIdDeleting: true
      };

    case ShippingActionTypes.DELETE.DELETE_SHIPPING_METHOD_BY_ID_SUCCESS:
      return {
        ...state,
        isShippingMethodByIdDeleting: false,
        deletedShippingMethod: action.payload
      };

    case ShippingActionTypes.DELETE.DELETE_SHIPPING_METHOD_BY_ID_ERROR:
      return {
        ...state,
        isShippingMethodByIdDeleting: false,
        deletedShippingMethodError: action.payload
      };

    case ShippingActionTypes.CLEAR_DELIVERY_REQUEST_STATUS:
      return {
        ...state,
        deliveryRequestStatus: action.payload
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_COMPANY_ID_PENDING:
      return {
        ...state,
        areShippingByCompanyPending: true
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        shippingByCompany: action.payload,
        areShippingByCompanyPending: false
      };

    case ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_COMPANY_ID_ERROR:
      return {
        ...state,
        shippingByCompanyError: action.payload,
        areShippingByCompanyPending: false
      };

    default:
      return state;
  }
};

export default ShippingReducer;
