import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";
import Card from "@material-ui/core/Card";

import { withDebounce, arrToString } from "../../shared";
import { Filter } from "../../containers";
import {
  LoadingSpinner,
  EventCard,
  Pagination,
  NoDataFound
} from "../../components";

import {
  getInnovationProjects,
  setBranchesFilterStatus,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
} from "../../redux/actions-exporter";

import "./innovation-projects.styles.scss";

const InnovationProjects = ({
  areInnovationProjectsPending,
  getInnovationProjects,
  innovationProjectsCount,
  innovationProjects,

  setBranchesFilterStatus,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,

  filterCountriesArr,
  filterOrganizationsArr,
  filterBranchesArr
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [innovationPage, setInnovationPage] = useState(1);

  useEffect(() => {
    setCountriesFilterStatus(true);
    setOrganizationsFilterStatus(true);
    setBranchesFilterStatus(true);
    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
      setBranchesFilterStatus(true);
    };
  }, [
    setCountriesFilterStatus,
    setOrganizationsFilterStatus,
    setBranchesFilterStatus
  ]);

  useEffect(() => {
    getInnovationProjects(
      searchQuery,
      innovationPage,
      arrToString(filterCountriesArr),
      arrToString(filterOrganizationsArr),
      arrToString(filterBranchesArr)
    );
  }, [
    getInnovationProjects,
    searchQuery,
    innovationPage,
    filterCountriesArr,
    filterOrganizationsArr,
    filterBranchesArr
  ]);

  useEffect(() => {
    setInnovationPage(1);
  }, [searchQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [innovationPage]);

  const searchInnovationsHandler = withDebounce(e => setSearchQuery(e));

  return (
    <div className="innovation-projects">
      <Filter onChange={e => searchInnovationsHandler(e.target.value)} />
      {areInnovationProjectsPending ? (
        <LoadingSpinner />
      ) : (
        <div className="business-proposals__body">
          <Card>
            <div className="events__body__title">Innovation Projects</div>
            <div className="business-proposals__body__padding">
              {innovationProjects && !!innovationProjects.length ? (
                innovationProjects.map(
                  ({
                    id,
                    name,
                    description,
                    cost,
                    organization,
                    branches,
                    currency
                  }) => (
                    <EventCard
                      key={id}
                      // country="Belarus"
                      title={
                        <Link
                          to={`/innovation-projects/innovation-project/${id}`}
                        >
                          {name}
                        </Link>
                      }
                      description={
                        description.length > 420
                          ? parse(description.substring(0, 420) + " ...")
                          : parse(description)
                      }
                      cost={true}
                      constQuantity={cost + " " + currency}
                      company={true}
                      companyBranch={branches && branches[0].name}
                      companyOrganization={organization && organization.name}
                      publicated={true}
                      publicatedDate="01.01.2020"
                    />
                  )
                )
              ) : (
                <NoDataFound
                  noDataTitle="No innovation projects found!"
                  myBusiness={true}
                  additionalLink="/my-business/innovation-projects"
                />
              )}
            </div>
          </Card>
          <Pagination
            forcePage={innovationPage - 1}
            dataCount={innovationProjectsCount}
            selectedPage={page => setInnovationPage(page)}
            itemsPerPage={10}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areInnovationProjectsPending:
    state.innovationProjects.areInnovationProjectsPending,
  innovationProjectsCount: state.innovationProjects.innovationProjectsCount,
  innovationProjects: state.innovationProjects.innovationProjects,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr,
  filterBranchesArr: state.filterReducer.filterBranchesArr
});

export default connect(mapStateToProps, {
  getInnovationProjects,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setBranchesFilterStatus
})(InnovationProjects);
