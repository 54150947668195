import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  DefaultButton,
  InputGroup,
  DefaultTextArea,
  DefaultDropdown,
  DefaultInputTime
} from "../../../../components";

import {
  createMeetingWithUserId,
  searchUsersFromEventVisitior,
  clearMeetingRequest
} from "../../../../redux/actions-exporter";

import { withDebounce } from "../../../../shared";
import { ParentWrapper, ButtonsFooterWrapper } from "../../../../layout";

const CreateMeeting = ({
  eventId,
  createMeetingWithUserId,
  searchUsersFromEventVisitior,
  searchUsersEventVisitors,
  meetingRequestStatus,
  clearMeetingRequest
}) => {
  const history = useHistory();

  const [meetingName, setMeetingName] = useState("");
  const [searchUserValue, setSearchUserValue] = useState("");
  const [userSelectedId, setUserSelectedId] = useState("");
  const [startMeetingDate, setStartMeetingDate] = useState("");
  const [endMeetingDate, setEndMeetingDate] = useState("");

  useEffect(() => {
    searchUsersFromEventVisitior(eventId, searchUserValue);
  }, [searchUsersFromEventVisitior, eventId, searchUserValue]);

  useEffect(() => {
    if (meetingRequestStatus === 201) {
      history.push(`/my-business/event-invitations/${eventId}/meeting`);
    }
  }, [meetingRequestStatus, eventId, history]);

  useEffect(() => () => clearMeetingRequest(), [clearMeetingRequest]);

  const createMeetingEventHandler = () => {
    createMeetingWithUserId(
      eventId,
      meetingName,
      userSelectedId,
      moment(startMeetingDate).format("YYYY-MM-DDThh:mm"),
      moment(endMeetingDate).format("YYYY-MM-DDThh:mm")
    );
  };

  const searchUserInputHandler = withDebounce(e => setSearchUserValue(e));

  return (
    <div className="create-meeting">
      <ParentWrapper>
        <InputGroup
          label="Meeting name"
          name="meetingName"
          placeholder="Meeting..."
          onChange={e => setMeetingName(e.target.value)}
          value={meetingName}
          required={false}
        />
        <DefaultDropdown
          filter={true}
          label="Add user to meeting"
          style={{ width: "100%" }}
          inputFunc={e => searchUserInputHandler(e.target.value)}
          items={
            searchUsersEventVisitors && searchUsersEventVisitors.length
              ? searchUsersEventVisitors.map(({ id, user_email }) => ({
                  id: id,
                  name: user_email
                }))
              : []
          }
          returnData={setUserSelectedId}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultInputTime
          label="START MEETING"
          changeHandler={e => setStartMeetingDate(e.target.value)}
        />
        <DefaultInputTime
          label="END MEETING"
          changeHandler={e => setEndMeetingDate(e.target.value)}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultTextArea
          // value={route}
          // onChange={e => setRoute(e.target.value)}
          label="Feedback"
        />
      </ParentWrapper>
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={createMeetingEventHandler}
            color="gray"
            width="100%"
            border="1px solid lightgray"
            borderRadius={0}
            // disabled={disabled}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            color="gray"
            width="100%"
            border="1px solid lightgray"
            borderRadius={0}
            onClick={() => history.goBack()}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  searchUsersEventVisitors: state.eventInvitations.searchUsersEventVisitors,
  meetingRequestStatus: state.eventInvitations.meetingRequestStatus
});

export default connect(mapStateToProps, {
  createMeetingWithUserId,
  searchUsersFromEventVisitior,
  clearMeetingRequest
})(CreateMeeting);
