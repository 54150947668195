export default function cartSubtotalComponent({ editor, api }) {
  const script = function() {
    function makeInfoHtml(subtotal, translations) {
      const data =
        subtotal.length && !(subtotal.length === 1 && subtotal[0].total === 0)
          ? subtotal
          : [];

      return `
        <h4 class="cart-subtotal__title">${translations["SUBTOTAL"]}</h4>
        ${
          data.length === 0
            ? `
            <div class="cart-subtotal__row">
              <span class="cart-subtotal__item cart-subtotal__item--title">
                ${translations["No Items"]}
              </span>
              <span class="cart-subtotal__item cart-subtotal__item--cost">
                0.00
              </span>
            </div>
            `
            : ""
        }
        ${data
          .map(
            subtotal => `
            <div class="cart-subtotal__row">
              <span class="cart-subtotal__item cart-subtotal__item--title">
                ${subtotal.count_items} ${translations["Items"]} (${
              translations[subtotal.currency]
            })
              </span>
              <span class="cart-subtotal__item cart-subtotal__item--cost">
                ${subtotal.total.toFixed(2)} ${translations[subtotal.currency]}
              </span>
            </div>
            `
          )
          .join("")}
          ${data
            .filter(subtotal => !!subtotal.shipping_cost)
            .map(
              (subtotal, index) => `
              ${
                index === 0
                  ? `
                  <span class="cart-subtotal__separator"></span>
                  `
                  : ""
              }
              <div class="cart-subtotal__row">
                <span class="cart-subtotal__item cart-subtotal__item--title cart-subtotal__item--shipping">
                  ${translations["Shipping"]} (${
                translations[subtotal.currency]
              })
                </span>
                <span class="cart-subtotal__item cart-subtotal__item--cost cart-subtotal__item--shipping">
                  ${subtotal.shipping_cost.toFixed(2)} ${
                translations[subtotal.currency]
              }
                </span>
              </div>
              `
            )
            .join("")}
      `;
    }

    function setLoading(state) {
      const el = document.querySelector(".cart-subtotal-wrapper");
      if (!el) return;

      if (state) {
        el.classList.add("cart-subtotal-wrapper--preloader");
      } else {
        el.classList.remove("cart-subtotal-wrapper--preloader");
      }
    }

    function render(
      count,
      page,
      deals,
      subtotal,
      loading,
      perPage,
      detailsPath,
      translations
    ) {
      const el = document.querySelector(".cart-subtotal-wrapper");
      const contentEl = el.querySelector(".cart-subtotal");

      setLoading(loading);

      contentEl.innerHTML = "";
      contentEl.innerHTML = makeInfoHtml(subtotal, translations);
    }

    const intervalName = `interval${Math.random.toString().slice(-8)}`;
    window[intervalName] = setInterval(function() {
      if (window.hasOwnProperty("cart") && window.hasOwnProperty("grapesjs")) {
        clearInterval(window[intervalName]);
        if (!window.cart.renderCycle.find(func => func === render)) {
          window.cart.renderCycle.push(render);
        }
      }
    }, 100);
  };

  editor.DomComponents.addType("cart-subtotal", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "cart-subtotal-wrapper",
    model: {
      defaults: {
        name: "Cart Subtotal",
        apiRoot: api.API_ROOT,
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
