import { PaginationActionTypes } from "./pagination.types";

export const selectCompPage = data => ({
  type: PaginationActionTypes.SELECT_COMP_PAGE,
  payload: data
});

export const selectOrgPage = data => ({
  type: PaginationActionTypes.SELECT_ORG_PAGE,
  payload: data
});

export const selectB2BProductPage = data => ({
  type: PaginationActionTypes.SELECT_B2B_PRODUCT_PAGE,
  payload: data
});

export const selectB2CProductPage = data => ({
  type: PaginationActionTypes.SELECT_B2C_PRODUCT_PAGE,
  payload: data
});
