import { LeadsActionTypes } from "./leads.types";

import {
  axiosNoTokenInstance,
  axiosInstance
} from "../../../axios/axios.config";

export const getLeadsByOrganization = companyId => dispatch => {
  dispatch({ type: LeadsActionTypes.GET.GET_LEADS_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/leads/leads/?organization=${companyId}`)
    .then(leads =>
      dispatch({
        type: LeadsActionTypes.GET.GET_LEADS_SUCCESS,
        payload: leads.data.results
      })
    )
    .catch(err =>
      dispatch({
        type: LeadsActionTypes.GET.GET_LEADS_ERROR,
        payload: err
      })
    );
};

export const getLeadById = leadId => dispatch => {
  dispatch({ type: LeadsActionTypes.GET.GET_LEAD_BY_ID_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/leads/leads/${leadId}`)
    .then(lead =>
      dispatch({
        type: LeadsActionTypes.GET.GET_LEAD_BY_ID_SUCCESS,
        payload: lead.data
      })
    )
    .catch(err =>
      dispatch({
        type: LeadsActionTypes.GET.GET_LEAD_BY_ID_ERROR,
        payload: err
      })
    );
};

export const createLead = (
  realname,
  currentCompanyId,
  email,
  phone,
  message,
  urlPath
) => dispatch => {
  axiosInstance
    .post("/api/v1/leads/leads/", {
      realname,
      email,
      phone,
      message,
      url_path: urlPath,
      organization: currentCompanyId
    })
    .then(lead => {
      dispatch({
        type: LeadsActionTypes.POST.CREATE_LEAD_SUCCESS,
        payload: { lead: lead.data, requestStatus: lead.status }
      });
    })
    .catch(err => {
      dispatch({
        type: LeadsActionTypes.POST.CREATE_LEAD_ERROR,
        payload: err
      });
    });
};

export const updateLead = (
  leadId,
  realname,
  currentCompanyId,
  email,
  phone,
  message,
  urlPath = ""
) => dispatch => {
  axiosInstance
    .put(`/api/v1/leads/leads/${leadId}/`, {
      realname,
      email,
      phone,
      message,
      url_path: urlPath,
      organization: currentCompanyId
    })
    .then(update =>
      dispatch({
        type: LeadsActionTypes.PUT.UPDATE_LEAD_SUCCESS,
        payload: { update: update.data, requestStatus: update.status }
      })
    )
    .catch(err =>
      dispatch({ type: LeadsActionTypes.PUT.UPDATE_LEAD_ERROR, payload: err })
    );
};

export const deleteLeadById = leadId => dispatch => {
  axiosInstance
    .delete(`/api/v1/leads/leads/${leadId}`)
    .then(deleteLead =>
      dispatch({
        type: LeadsActionTypes.DELETE.DELETE_LEAD_SUCCESS,
        payload: { deleteLead, leadId }
      })
    )
    .catch(err =>
      dispatch({
        type: LeadsActionTypes.DELETE.DELETE_LEAD_ERROR,
        payload: err
      })
    );
};

export const clearLeadRequest = () => dispatch => {
  dispatch({
    type: LeadsActionTypes.CLEAR_LEAD_REQUEST,
    payload: ""
  });
};
