import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import {
  getCompanyB2BProducts,
  getOrganizationB2BProducts
} from "../../redux/APIs/products/b2b/b2b.products.action";
import {
  getCompanyB2CProducts,
  getOrganizationB2CProducts
} from "../../redux/APIs/products/b2c/b2c.products.action";
import {
  getNewsByCompanyId,
  getNewsByOrganizationId
} from "../../redux/APIs/news/news.action";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import BlackChatIcon from "../../assets/black-chat-icon.png";
import BlackBriefcaseIcon from "../../assets/black-briefcase-icon.png";
import BlackCartIcon from "../../assets/black-cart-icon.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DefaultButton from "../default-button/default-button.component";
import { ReactComponent as QuestionIcon } from "../../assets/help-question-icon.svg";
import ImgCard from "../img-card/img-card.components";
import { ReactComponent as EnvelopeIcon } from "../../assets/white-small-mail-icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/location-icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/white-phone-icon.svg";
import { ReactComponent as WWWIcon } from "../../assets/www-white-icon.svg";
import { ReactComponent as GreyChatIcon } from "../../assets/grey-chat-icon.svg";
import { ReactComponent as GreyMailIcon } from "../../assets/mail.svg";

import { colorPalette } from "../../styles/colorPalette";
import "./event-table-body.styles.scss";

const useStyles = makeStyles({
  noBorder: {
    border: "none"
  }
});

const EventTableBody = ({
  id,
  data,
  visitor,
  avatar,
  row2,
  row3,
  link1,
  link2,
  link3,

  description,
  metadata,
  address,

  getCompanyB2BProducts,
  companyB2BProducts,

  getOrganizationB2BProducts,
  b2bOrganizationProducts,

  getCompanyB2CProducts,
  companyB2CProducts,

  getOrganizationB2CProducts,
  b2cOrganizationProducts,

  getNewsByCompanyId,
  getNewsByOrganizationId,
  newsByCompanyId
}) => {
  const { compOrg, compOrgId } = useParams();
  const [expand, setExpand] = useState(false);
  const handleExpandOpen = () => setExpand(false);
  const handleExpandClose = () => setExpand(true);
  const classes = useStyles();

  useEffect(() => {
    if (compOrg === "company") {
      getCompanyB2BProducts(compOrgId, "", "");
      getCompanyB2CProducts(compOrgId, "", "");
      getNewsByCompanyId(compOrgId);
    } else if (compOrg === "organization") {
      getOrganizationB2BProducts(compOrgId, "", "");
      getOrganizationB2CProducts(compOrgId, "", "");
      getNewsByOrganizationId(compOrgId);
    }
  }, [
    getCompanyB2BProducts,
    getCompanyB2CProducts,
    getNewsByCompanyId,
    getOrganizationB2BProducts,
    getOrganizationB2CProducts,
    getNewsByOrganizationId,
    compOrg,
    compOrgId
  ]);

  const RenderVisitor = () => (
    <TableRow>
      <TableCell className={classes.noBorder} colSpan={5}>
        {visitor && (
          <div className="event-table-body__body">
            <div className="event-table-body__body__description">
              <h5>Descritpion</h5>
              <p>{description && parse(description)}</p>
            </div>

            <div className="event-table-body__body__separator" />

            <div className="event-table-body__body__main-content">
              <div className="event-table-body__body__main-content__products">
                <div className="event-table-body__body__main-content__header-icon">
                  <img src={BlackCartIcon} alt="" />
                  <h5>Products</h5>
                </div>
                <div className="event-table-body__body__main-content__products__imgs">
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                  <img src="https://via.placeholder.com/35" alt="" />
                </div>
              </div>
              <div className="event-table-body__body__main-content__services">
                <div className="event-table-body__body__main-content__header-icon">
                  <img src={BlackBriefcaseIcon} alt="" />
                  <h5>Services</h5>
                </div>
                <ul>
                  <li>Technology</li>
                  <li>Consulting</li>
                  <li>Financial Services</li>
                  <li>Logistics</li>
                </ul>
              </div>
              <div className="event-table-body__body__main-content__chat">
                <div className="event-table-body__body__main-content__header-icon">
                  <img src={BlackChatIcon} alt="" />
                  <h5>Chat</h5>
                </div>
                <div className="event-table-body__body__main-content__chat__btns">
                  <div className="event-table-body__body__main-content__chat__btns__btn">
                    <DefaultButton
                      padding="2px 0"
                      minWidth="110px"
                      border="1px solid lightgray"
                    >
                      Email
                    </DefaultButton>
                  </div>
                  <div className="event-table-body__body__main-content__chat__btns__btn">
                    <DefaultButton
                      padding="2px 0"
                      minWidth="110px"
                      border="1px solid lightgray"
                    >
                      Facebook
                    </DefaultButton>
                  </div>
                  <div className="event-table-body__body__main-content__chat__btns__btn">
                    <DefaultButton
                      padding="2px 0"
                      minWidth="110px"
                      border="1px solid lightgray"
                    >
                      Google+
                    </DefaultButton>
                  </div>
                  <div className="event-table-body__body__main-content__chat__btns__btn">
                    <DefaultButton
                      padding="2px 0"
                      minWidth="110px"
                      border="1px solid lightgray"
                    >
                      WeChat
                    </DefaultButton>
                  </div>
                  <div className="event-table-body__body__main-content__chat__btns__btn">
                    <DefaultButton
                      padding="2px 0"
                      minWidth="110px"
                      border="1px solid lightgray"
                    >
                      LinkedIn
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="event-table-body__body__separator" />
            <div className="event-table-body__body__meeting">
              <h5>SET A MEETING / TALKING</h5>
              <div className="event-table-body__body__meeting__date">
                <label>Date</label>
                <input type="date" />
                <label>Time</label>
                <input type="time" />
                <label>Subject</label>
                <input style={{ width: "100%" }} type="text" />
              </div>
              <div className="event-table-body__body__meeting__appoint">
                <DefaultButton
                  border={`1px solid ${colorPalette.colorPrimary.main}`}
                  color={colorPalette.colorPrimary.main}
                  minWidth="110px"
                  padding="2px 0"
                >
                  Appoint
                </DefaultButton>
                <div className="event-table-body__body__meeting__appoint__separator" />
                <input type="radio" />
                <label>Activate reminder</label>
                <QuestionIcon />
                <p>Ea nostrud aute do esse non sint.</p>
              </div>
            </div>
            <div className="event-table-body__body__separator" />
          </div>
        )}
      </TableCell>
    </TableRow>
  );

  const RenderCompany = () => (
    <TableRow>
      <TableCell className={classes.noBorder} colSpan={5}>
        {!visitor && (
          <div className="event-table-body__company">
            <div className="event-table-body__company__description">
              <h5>Descritpion</h5>
              <p>{parse(description)}</p>
            </div>
            <div className="event-table-body__body__separator" />
            <div className="event-table-body__company__offers-container">
              <h5>Offers</h5>
              <div className="event-table-body__company__offers-container__offer">
                {companyB2BProducts.results &&
                  companyB2BProducts.results.map(
                    ({ id, name, cost, currency, image }) => (
                      <ImgCard
                        key={id}
                        text={name}
                        imgSrc={image}
                        price={cost}
                        currency={currency}
                        type="products-event"
                        navLink={`/product/b2b/${id}`}
                      />
                    )
                  )}
                {companyB2CProducts.results &&
                  companyB2CProducts.results.map(
                    ({ id, name, cost, currency, image }) => (
                      <ImgCard
                        key={id}
                        text={name}
                        imgSrc={image}
                        price={cost}
                        currency={currency}
                        type="products-event"
                        navLink={`/product/b2c/${id}`}
                      />
                    )
                  )}
                {b2bOrganizationProducts.results &&
                  b2bOrganizationProducts.results.map(
                    ({ id, name, cost, currency, image }) => (
                      <ImgCard
                        key={id}
                        text={name}
                        imgSrc={image}
                        price={cost}
                        currency={currency}
                        type="products-event"
                        navLink={`/product/b2b/${id}`}
                      />
                    )
                  )}
                {b2cOrganizationProducts.results &&
                  b2cOrganizationProducts.results.map(
                    ({ id, name, cost, currency, image }) => (
                      <ImgCard
                        key={id}
                        text={name}
                        imgSrc={image}
                        price={cost}
                        currency={currency}
                        type="products-event"
                        navLink={`/product/b2c/${id}`}
                      />
                    )
                  )}
              </div>
            </div>
            {newsByCompanyId.results && newsByCompanyId.results.length > 0 && (
              <>
                <div className="event-table-body__body__separator" />

                <div className="event-table-body__company__news-container">
                  <h5>News</h5>
                  <div className="event-table-body__company__news-container__news">
                    {newsByCompanyId.results &&
                      newsByCompanyId.results.map(
                        ({ id, image, short_description, title }) => (
                          <ImgCard
                            navLink={`/news/${id}`}
                            key={id}
                            shortDescription={title}
                            text={short_description}
                            imgSrc={image}
                            type="regular"
                          />
                        )
                      )}
                  </div>
                </div>
              </>
            )}

            <div className="event-table-body__body__separator" />
            <div className="event-table-body__company__contacts">
              <h5>Contacts</h5>
              <div className="event-table-body__company__contacts__upper">
                <div className="event-table-body__company__contacts__upper__data">
                  <span className="event-table-body__company__contacts__icon">
                    <LocationIcon />
                  </span>
                  <div>
                    <h5>Address:</h5>
                    <p>{address}</p>
                  </div>
                </div>
                <div className="event-table-body__company__contacts__upper__data">
                  <span className="event-table-body__company__contacts__icon">
                    <EnvelopeIcon />
                  </span>
                  <div>
                    <h5>Email:</h5>
                    <p>{metadata.email}</p>
                  </div>
                </div>
                <div className="event-table-body__company__contacts__upper__btn">
                  <DefaultButton
                    displayLabel="flex"
                    justifyContentLabel="space-between"
                    alignItemsLabel="center"
                    labelWidth="90px"
                    padding="10px 20px"
                    border="1px solid lightgrey"
                  >
                    <GreyChatIcon />
                    Live Chat
                  </DefaultButton>
                </div>
              </div>
              <div className="event-table-body__company__contacts__lower">
                <div className="event-table-body__company__contacts__upper">
                  <div className="event-table-body__company__contacts__upper__data">
                    <span className="event-table-body__company__contacts__icon">
                      <PhoneIcon />
                    </span>
                    <div>
                      <h5>Telephone number:</h5>
                      <p>{metadata.phone}</p>
                    </div>
                  </div>
                  <div className="event-table-body__company__contacts__upper__data">
                    <span className="event-table-body__company__contacts__icon">
                      <WWWIcon />
                    </span>
                    <div>
                      <h5>Website:</h5>
                      <p>{metadata.site}</p>
                    </div>
                  </div>
                  <div className="event-table-body__company__contacts__upper__btn">
                    <DefaultButton
                      displayLabel="flex"
                      justifyContentLabel="space-between"
                      alignItemsLabel="center"
                      labelWidth="90px"
                      padding="10px 20px"
                      border="1px solid lightgrey"
                    >
                      <GreyMailIcon />
                      Contact us
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="event-table-body__body__separator" />
          </div>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <Fragment>
      <TableRow key={data.name}>
        <TableCell
          className={classes.noBorder}
          component="th"
          scope="row"
          align="left"
        >
          <img
            style={{ borderRadius: `${visitor && "50%"}` }}
            src={avatar}
            alt=""
            className="event-table-body__img"
          />
        </TableCell>
        <TableCell className={classes.noBorder} align="left">
          {row2}
        </TableCell>
        <TableCell className={classes.noBorder} align="left">
          {row3}
        </TableCell>
        <TableCell className={classes.noBorder} align="left">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <img style={{ cursor: "pointer" }} src={link1} alt="" />
            <img style={{ cursor: "pointer" }} src={link2} alt="" />
            <img style={{ cursor: "pointer" }} src={link3} alt="" />
          </div>
        </TableCell>
        <TableCell className={classes.noBorder} align="left">
          {expand ? (
            <ExpandLessIcon
              onClick={handleExpandOpen}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <ExpandMoreIcon
              onClick={handleExpandClose}
              style={{ cursor: "pointer" }}
            />
          )}
        </TableCell>
      </TableRow>
      {expand && (visitor ? <RenderVisitor /> : <RenderCompany />)}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  companyB2BProducts: state.b2BProductsReducer.companyB2BProducts,
  b2bOrganizationProducts: state.b2BProductsReducer.b2bOrganizationProducts,

  companyB2CProducts: state.b2cProductsReducer.companyB2CProducts,
  b2cOrganizationProducts: state.b2cProductsReducer.b2cOrganizationProducts,

  newsByCompanyId: state.newsReducer.newsByCompanyId
});

export default connect(mapStateToProps, {
  getCompanyB2BProducts,
  getOrganizationB2BProducts,

  getCompanyB2CProducts,
  getOrganizationB2CProducts,

  getNewsByCompanyId,
  getNewsByOrganizationId
})(EventTableBody);
