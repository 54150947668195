import icon from "./icon.png";

export default function productsTypeSwitchBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .${className} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .${className}__product-type {
    padding: 0 4px 0;
    opacity: 0.5;
  }

  .${className}__product-type--active {
    opacity: 1;
  }

  .${className}__toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }
  
  .${className}__checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
  }
  
  .${className}__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .${className}__slider:before {
    position: absolute;
    content: "";
    height: 80%;
    width: 40%;
    background-color: white;
    left: 10%;
    top: 10%;
    botton: 10%;
    border-radius: 50%;
    transition: .4s;
  }
  
  .${className}__checkbox:checked + .${className}__slider:before {
    transform: translateX(100%);
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="${className}">
    <span class="gjs-products-type-switch__product-type gjs-products-type-switch__product-type--active">
      B2C
    </span>
    <div class="${className}__toggle">
      <input class="${className}__checkbox" type="checkbox" />
      <span class="${className}__slider"></span>
    </div>
    <span class="gjs-products-type-switch__product-type">
      B2B
    </span>
  </div>
  `;

  blockManager.add("products-type-switch", {
    label: `
      <img src="${icon}" alt="Products search block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Type Switch
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
