import { SitesActionTypes } from "./sites.types";

const INITIAL_STATE = {
  sites: {},
  areSitesLoaded: false,
  isSitesPending: false,
  sitesPagination: {
    total: 10,
    currentPage: 1,
    perPage: 10
  },
  site: {},
  areSiteLoaded: false,
  isSitePending: false,
  createdSite: null,
  createdSiteError: null,
  selectedSiteTheme: null,
  updatedSite: {},
  updatedSiteError: null,
  deletedSite: {}
};

const SitesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SitesActionTypes.GET_SITE_PENDING:
      return {
        ...state,
        isSitePending: true
      };

    case SitesActionTypes.GET_SITE:
      return {
        ...state,
        site: action.payload,
        areSiteLoaded: true,
        isSitePending: false
      };

    case SitesActionTypes.GET_SITES_PENDING:
      return {
        ...state,
        isSitesPending: true
      };

    case SitesActionTypes.GET_SITES:
      const { results, count, currentPage } = action.payload;
      return {
        ...state,
        sites: results,
        sitesPagination: {
          ...state.sitesPagination,
          total: count,
          currentPage
        },
        isSitesPending: false,
        areSitesLoaded: true
      };

    case SitesActionTypes.CREATE_SITE:
      return {
        ...state,
        createdSite: action.payload,
        createdSiteError: null,
        areSitesLoaded: false
      };

    case SitesActionTypes.CREATE_SITE_ERROR:
      return {
        ...state,
        createdSite: null,
        createdSiteError: action.payload,
        areSiteLoaded: false
      };

    case SitesActionTypes.ADD_THEME_TO_SITE:
      return {
        ...state,
        isSitesPending: false,
        areSitesLoaded: false,
        isSitePending: action.payload ? true : false,
        selectedSiteTheme: action.payload || null,
        createdSite: action.payload ? state.createdSite : null
      };

    case SitesActionTypes.UPDATE_SITE:
      return {
        ...state,
        updatedSite: action.payload,
        updatedSiteError: null,
        isSitePending: false,
        areSiteLoaded: false,
        areSitesLoaded: false
      };

    case SitesActionTypes.UPDATE_SITE_ERROR:
      return {
        ...state,
        updatedSite: {},
        updatedSiteError: action.payload,
        isSitePending: false,
        areSiteLoaded: false,
        areSitesLoaded: false
      };

    case SitesActionTypes.DELETE_SITE:
      return {
        ...state,
        deletedSite: action.payload,
        areSitesLoaded: false
      };

    default:
      return state;
  }
};

export default SitesReducer;
