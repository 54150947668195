import React, { Fragment, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Card, CardContent } from "@material-ui/core";

import {
  Separator,
  DefaultButton,
  DefaultDropdown,
  LoadingSpinner,
  DefaultSnackbar
} from "../../components";

import {
  getJob,
  getResumesByCurrentUser,
  applyToJobWithResumeId,
  clearResumeRequest
} from "../../redux/actions-exporter";

import "./send-resume.styles.scss";

const SendResume = ({
  getResumesByCurrentUser,
  resumesByMe,
  getJob,
  currentJob,
  areResumeByMePending,
  isJobPending,
  applyToJobWithResumeId,
  resumeRequestStatus,
  clearResumeRequest
}) => {
  const history = useHistory();
  const { jobId } = useParams();

  const [currentCV, setCurrentCV] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [snackMessage] = useState("Successfully applied to job!");

  useEffect(() => {
    getResumesByCurrentUser();
    getJob(jobId);
  }, [getResumesByCurrentUser, getJob, jobId]);

  useEffect(() => {
    if (resumeRequestStatus === 200) {
      setShowMessage(true);
      setTimeout(() => history.goBack(), 3000);
    }
  }, [history, resumeRequestStatus]);

  useEffect(() => () => clearResumeRequest(), [clearResumeRequest]);

  const handleResumeSend = () => {
    applyToJobWithResumeId(jobId, currentCV);
  };

  return (
    <div className="send-resume-container">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="success"
          message={snackMessage}
          autoHideDuration={2000}
        />
      )}
      <Card>
        <CardContent>
          {areResumeByMePending && isJobPending ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              <h3 className="send-resume-container__title">Send resume</h3>
              <Separator />

              <div className="send-resume-container__resume-content">
                <div className="resume-content__path">
                  <div className="resume-content__path-position">
                    <h3 className="resume-content__path-position-text">
                      You send resume on position:
                    </h3>
                    <h3 className="resume-content__path-position-job">
                      {currentJob.title}
                    </h3>
                  </div>
                </div>
                {/* <div className="resume-content__add-resume">
                  <h3 className="resume-content__add-resume-new">
                    Add new resume to profile
                  </h3>
                  <div className="resume-content__add-resume-button">
                    <DefaultButton
                      // onClick={editleads}
                      color={colorPalette.colorPrimary.main}
                      width="75%"
                      border="1px solid lightgray"
                      borderRadius={0}
                      // disabled={disabledButton}
                    >
                      New resume
                    </DefaultButton>
                  </div>
                </div> */}
                <div className="resume-content__path-cvs">
                  <h3 className="resume-content__path-cvs-text">
                    Send existing resume:
                  </h3>
                  <DefaultDropdown
                    style={{ width: "100%" }}
                    items={
                      resumesByMe &&
                      resumesByMe.map(({ id, title }) => ({
                        id,
                        name: title
                      }))
                    }
                    returnData={setCurrentCV}
                  />
                </div>
              </div>
              <Separator />
              <div className="my-business-leads-edit__footer">
                <div className="my-business-leads-edit__footer-column">
                  <DefaultButton
                    onClick={handleResumeSend}
                    color="gray"
                    width="100%"
                    border="1px solid lightgray"
                    borderRadius={0}
                    disabled={currentCV === ""}
                  >
                    Send
                  </DefaultButton>
                </div>

                <div className="my-business-leads-edit__footer-column">
                  <DefaultButton
                    // onClick={cancelLead}
                    color="gray"
                    width="100%"
                    border="1px solid lightgray"
                    borderRadius={0}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </DefaultButton>
                </div>
              </div>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  areResumeByMePending: state.resumesReducer.areResumeByMePending,
  resumesByMe: state.resumesReducer.resumesByMe,
  isJobPending: state.jobsAPI.isJobPending,
  currentJob: state.jobsAPI.job,
  resumeRequestStatus: state.resumesReducer.resumeRequestStatus
});

export default connect(mapStateToProps, {
  getResumesByCurrentUser,
  getJob,
  applyToJobWithResumeId,
  clearResumeRequest
})(SendResume);
