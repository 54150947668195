import { PagesActionTypes } from "./pages.types";

const INITIAL_STATE = {
  arePagesPending: false,
  pages: [],
  pagesCount: null,
  pagesError: null,

  isPageByIdPending: false,
  pageById: [],
  pageByIdError: null,

  createdPage: {},
  updatedPage: {},
  deletedPage: {},

  pageTypesPending: false,
  pageTypes: [],
  pageTypesError: null,

  siteTypesPending: false,
  siteTypes: [],
  siteTypesError: null,

  pagesRequestStatus: ""
};

const PagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PagesActionTypes.GET_PAGES_PENDING:
      return {
        ...state,
        arePagesPending: true
      };

    case PagesActionTypes.GET_PAGES:
      return {
        ...state,
        pages: action.payload.pages,
        pagesCount: action.payload.count,
        arePagesPending: false
      };

    case PagesActionTypes.GET_PAGES_ERROR:
      return {
        ...state,
        pagesError: action.payload,
        arePagesPending: false
      };

    case PagesActionTypes.GET_PAGE_BY_ID_PENDING:
      return {
        ...state,
        isPageByIdPending: true
      };

    case PagesActionTypes.GET_PAGE_BY_ID:
      return {
        ...state,
        isPageByIdPending: false,
        pageById: action.payload,
        pageByIdError: null
      };

    case PagesActionTypes.GET_PAGE_BY_ID_ERROR:
      return {
        ...state,
        isPageByIdPending: false,
        pageByIdError: action.payload
      };

    case PagesActionTypes.CREATE_PAGE:
      return {
        ...state,
        pagesRequestStatus: action.payload.requestStatus,
        createdPage: action.payload
      };

    case PagesActionTypes.UPDATE_PAGE:
      return {
        ...state,
        pagesRequestStatus: action.payload.requestStatus,
        updatedPage: action.payload
      };

    case PagesActionTypes.DELETE_PAGE:
      return {
        ...state,
        deletedPage: action.payload
      };

    case PagesActionTypes.GET_PAGE_TYPES_PENDING:
      return {
        ...state,
        pageTypesPending: true
      };

    case PagesActionTypes.GET_PAGE_TYPES_SUCCESS:
      return {
        ...state,
        pageTypes: action.payload,
        pageTypesPending: false
      };

    case PagesActionTypes.GET_PAGE_TYPES_ERROR:
      return {
        ...state,
        pageTypesError: action.payload,
        pageTypesPending: false
      };

    case PagesActionTypes.GET_SITE_TYPES_PENDING:
      return {
        ...state,
        siteTypesPending: true
      };

    case PagesActionTypes.GET_SITE_TYPES_SUCCESS:
      return {
        ...state,
        siteTypes: action.payload,
        siteTypesPending: false
      };

    case PagesActionTypes.GET_SITE_TYPES_ERROR:
      return {
        ...state,
        siteTypesError: action.payload,
        siteTypesPending: false
      };

    case PagesActionTypes.CLEAR_PAGES_REQUEST:
      return {
        ...state,
        pagesRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default PagesReducer;
