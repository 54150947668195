import { MyBusinessActionTypes } from "./my-business.types";

const INITIAL_STATE = {
  selectedProductType: ""
};

const MyBusinessReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MyBusinessActionTypes.PRODUCTS.SELECT_ACTIVE_PRODUCT_TYPE:
      return { ...state, selectedProductType: action.payload };

    default:
      return state;
  }
};

export default MyBusinessReducer;
