export default function paymentInfoComponent({ editor, api, site }) {
  const script = function() {
    function render() {
      const wrapperEl = this;
      wrapperEl.innerHTML = `<div>Transaction id: ${window.localStorage.getItem(
        "transaction_id"
      )}</div>`;
    }

    render.call(this);
  };

  editor.DomComponents.addType("payment-info", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "payment-info-wrapper",
    model: {
      defaults: {
        name: "Payment info",
        apiRoot: api.API_ROOT,
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
