import React, { useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { GreyChatIcon, MailIcon, Phone, UserPlaceholder } from "../../assets";

import {
  getDepartmentsByOrganization,
  getVacanciesByDepartmentOrganization,
  getProfileByUserId
} from "../../redux/actions-exporter";

import "./comp-org-structure-tab.styles.scss";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  noBorder: {
    border: "none"
  }
});

const CompOrgStructureTab = ({
  companySelected,
  getVacanciesByDepartmentOrganization,
  vacanciesByDepartmentOrganization,
  getProfileByUserId,
  profileByUserId,
  companyId,
  getDepartmentsByOrganization,
  departmentsByOrganization
}) => {
  const classes = useStyles();

  useEffect(() => {
    getVacanciesByDepartmentOrganization(companyId);
    getDepartmentsByOrganization(companyId);
  }, [
    getVacanciesByDepartmentOrganization,
    getDepartmentsByOrganization,
    companyId
  ]);

  return (
    <div className="comp-org-structure-tab">
      <h3>Staff</h3>
      {vacanciesByDepartmentOrganization &&
      vacanciesByDepartmentOrganization.length ? (
        <div className="comp-org-structure-tab__table-content">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">PHOTO</TableCell>
                <TableCell align="left">NAME</TableCell>
                <TableCell align="left">DEPARTMENT</TableCell>
                <TableCell align="left">POSITION</TableCell>
                <TableCell align="left">CONTACT</TableCell>
                <TableCell align="left">STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vacanciesByDepartmentOrganization &&
                vacanciesByDepartmentOrganization.map(
                  ({ id, avatar, department, name, profile }) => (
                    <TableRow key={id}>
                      <TableCell
                        className={classes.noBorder}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {profile[0] && profile[0].avatar ? (
                          <img
                            className="structure-user_logo"
                            src={profile[0].avatar}
                            alt=""
                          />
                        ) : (
                          <UserPlaceholder className="structure-user_logo" />
                        )}
                      </TableCell>
                      <TableCell className={classes.noBorder} align="left">
                        {`${
                          profile[0] && profile[0].first_name
                            ? profile[0].first_name
                            : "No name"
                        } ${
                          profile[0] && profile[0].last_name
                            ? profile[0].last_name
                            : "No name"
                        }`}
                      </TableCell>
                      <TableCell className={classes.noBorder} align="left">
                        {department && department.name}
                      </TableCell>
                      <TableCell className={classes.noBorder} align="left">
                        {name}
                      </TableCell>
                      <TableCell className={classes.noBorder} align="left">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <MailIcon />
                          <Phone />
                          <GreyChatIcon />
                        </div>
                      </TableCell>
                      <TableCell className={classes.noBorder} align="left">
                        ONLINE
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="comp-org-structure-tab__no-data">No staff</div>
      )}

      <h3>Structure</h3>
      {departmentsByOrganization && departmentsByOrganization.length ? (
        <div className="comp-org-structure-tab__structure">
          <Table
            className={(classes.table, "table-structure")}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">COMPANY</TableCell>
                <TableCell align="left">DEPARTMENT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departmentsByOrganization &&
                departmentsByOrganization.map(({ id, name, organization }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <div className="table-structure__company">
                        <div className="image">
                          <img src={organization.logo} alt="" />
                        </div>
                        <div className="name">{organization.name}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{name}</div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="comp-org-structure-tab__no-data">No department</div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isProfileByUserIdPending: state.profileAPI.isProfileByUserIdPending,
  profileByUserId: state.profileAPI.profileByUserIdData,
  vacanciesByDepartmentOrganization:
    state.vacanciesReducer.vacanciesByDepartment,
  departmentsByOrganization: state.vacanciesReducer.departmentsByOrganization
});

export default connect(mapStateToProps, {
  getVacanciesByDepartmentOrganization,
  getProfileByUserId,
  getDepartmentsByOrganization
})(CompOrgStructureTab);
