import React from "react";
import { connect } from "react-redux";

import { missingImage } from "../../assets";
import { ProductCarouselItem, LoadingSpinner } from "../../components";

import "./products-preview-grid.styles.scss";

const ProductsPreviewGrid = ({ loading, products, props = {} }) => {
  return (
    <div className="products-preview-grid" {...props}>
      <div className="products-preview-grid__main">
        {loading ? (
          <LoadingSpinner />
        ) : (
          products &&
          products.map(product => (
            <ProductCarouselItem
              key={product.id}
              productId={product.id}
              navProductType="b2c"
              imgUrl={product.image ? product.image : missingImage}
              name={product.name}
              price={product.cost}
              currency={product.currency}
              discountPercent={product.discount_percent}
              couponDiscountPercent={product.coupon_discount_percent}
              metadata={product.metadata}
              currentProductData={product}
            />
          ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  filterCountriesArr: state.filterReducer.filterCountriesArr
});

export default connect(mapStateToProps, {})(ProductsPreviewGrid);
