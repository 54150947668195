import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { missingVideo, missingImage } from "../../../assets";
import { newsType, videoIsUploaded } from "../../../constants";

import {
  ColumnsSmLgWrapper,
  ColumnWrapperSm,
  ColumnWrapperLg,
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "../../../layout";

import {
  MediaActions,
  DefaultButton,
  Separator,
  InputGroup,
  EditorGroup,
  GallerySection,
  VideoSection,
  DefaultDropdown,
  DefaultDropdownMultiSelect,
  CircleLoader
} from "../../../components";

import {
  createNews,
  updateNews,
  getNewsById,
  clearNewsRequest,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  searchCountry,
  getNewsCategories,
  getUserOrganizationsChamber
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-news-edit.styles.scss";

const MyBusinessNewsEdit = ({
  appDirection,
  createNews,
  currentNews,
  getNewsById,
  updateNews,
  newsRequestStatus,
  clearNewsRequest,

  getUserOrganizationsChamber,
  galleryBlob,
  galleryFile,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  videoBlob,
  videoFile,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  searchCountry,
  countries,
  userOrganizationsChamber,
  getNewsCategories,
  newsCategories,
  isImageGalleryUploadPending,
  isVideoGalleryUploadPending
}) => {
  const [newTitle, setNewTitle] = useState("");
  const [prevDescription, setPrevDescription] = useState("");
  const [textContent, setTextContent] = useState("");
  const [keywords, setKeywords] = useState("");
  const [file, setFile] = useState("");
  const [blob, setBlob] = useState("");
  const [newsGallery, setNewsGallery] = useState([]);
  const [countryInput, setCountryInput] = useState("");
  const [countryId, setCountryId] = useState("");
  const [organizationInput, setOrganizationInput] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [newsCategoriesIds, setNewsCategoriesIds] = useState([]);

  const [disabledButton, setDisabledButton] = useState(true);

  const { newsId } = useParams();
  const history = useHistory();

  useEffect(() => {
    searchCountry(countryInput);
  }, [searchCountry, countryInput]);

  useEffect(() => {
    getUserOrganizationsChamber(organizationInput);
  }, [getUserOrganizationsChamber, organizationInput]);

  useEffect(() => {
    getNewsCategories();
  }, [getNewsCategories]);

  useEffect(() => {
    if (newsId !== "create") {
      getNewsById(newsId);
    }
  }, [getNewsById, newsId]);

  useEffect(() => {
    if (newsId !== "create") {
      const {
        title,
        short_description,
        content,
        keywords,
        country,
        organization,
        image,
        galleries
      } = currentNews;
      setNewTitle(title);
      setPrevDescription(short_description);
      setTextContent(content);
      setKeywords(keywords);
      setCountryId(country && country.id);
      setOrganizationId(organization && organization.id);
      setFile(image);
      setNewsGallery(galleries);
    }
  }, [newsId, currentNews]);

  useEffect(() => {
    setDisabledButton(
      newTitle === "" || prevDescription === "" || textContent === ""
    );
  }, [newTitle, prevDescription, textContent]);

  useEffect(() => {
    if (newsRequestStatus === 200 || newsRequestStatus === 201) {
      history.push("/my-business/news");
    }
  }, [history, newsRequestStatus]);

  useEffect(
    () => () => {
      loadBlobGallery("");
      loadFileGallery("");
      loadBlobVideo("");
      loadFileVideo("");
    },
    [loadBlobGallery, loadFileGallery, loadBlobVideo, loadFileVideo]
  );

  useEffect(
    () => () => {
      clearNewsRequest();
    },
    [clearNewsRequest]
  );

  const editNew = () => {
    if (newsId === "create") {
      createNews(
        newTitle,
        countryId,
        organizationId,
        prevDescription,
        textContent,
        keywords,
        newsCategoriesIds,
        blob
      );
    } else {
      updateNews(
        newsId,
        newTitle,
        countryId,
        organizationId,
        prevDescription,
        textContent,
        keywords,
        newsCategoriesIds,
        blob
      );
    }
  };

  return (
    <div className="my-business-news-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={file ? file : missingImage}
            alt={"Big-Ben"}
            height={200}
            buttons={[
              <DefaultButton width="100%" {...buttonOk}>
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setBlob({ update: e.target.files[0] });
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                width="100%"
                {...buttonCancel}
                onClick={() => {
                  setFile("");
                  setBlob({ update: "" });
                }}
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {newsId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() =>
                            uploadImageGallery(newsType, newsId, galleryBlob)
                          }
                          disabled={galleryBlob === ""}
                          width="100%"
                          {...buttonOk}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("remove");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
            </>
          )}
          <Separator />
          <MediaActions
            image={videoFile ? videoIsUploaded : missingVideo}
            alt={"missing image"}
            height={200}
            buttons={
              isVideoGalleryUploadPending
                ? [<CircleLoader />]
                : [
                    <DefaultButton width="100%" {...buttonOk}>
                      <label style={{ cursor: "pointer" }}>
                        Choose a video
                        <input
                          accept="video/*"
                          type="file"
                          onChange={e => {
                            loadFileVideo(
                              URL.createObjectURL(e.target.files[0])
                            );
                            loadBlobVideo(e.target.files[0]);
                          }}
                          style={{ display: "none", cursor: "pointer" }}
                        />
                      </label>
                    </DefaultButton>,
                    <DefaultButton
                      width="100%"
                      {...buttonOk}
                      onClick={() =>
                        uploadVideoGallery(newsType, newsId, videoBlob)
                      }
                      disabled={videoBlob === ""}
                    >
                      Upload video
                    </DefaultButton>,
                    <DefaultButton
                      onClick={() => {
                        loadBlobVideo("");
                        loadFileVideo("");
                      }}
                      width="100%"
                      {...buttonCancel}
                    >
                      Delete video
                    </DefaultButton>
                  ]
            }
          />
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="TITLE"
              name="title"
              placeholder="News title"
              onChange={e => setNewTitle(e.target.value)}
              value={newTitle}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="COUNTRY"
              items={countries.results}
              returnData={setCountryId}
              inputFunc={e => setCountryInput(e.target.value)}
              filter={true}
              customTitle={
                newsId !== "create" &&
                currentNews.country &&
                currentNews.country.name
              }
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="ORGANIZATION"
              items={userOrganizationsChamber}
              returnData={setOrganizationId}
              inputFunc={e => setOrganizationInput(e.target.value)}
              filter={true}
              customTitle={
                newsId !== "create" &&
                currentNews.organization &&
                currentNews.organization.name
              }
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdownMultiSelect
              label="CATEGORY"
              items={newsCategories}
              returnData={setNewsCategoriesIds}
              customArray={newsId !== "create" && currentNews.categories}
            />
          </ParentWrapper>
          <ParentWrapper>
            <EditorGroup
              label="PREVIEW DESCRIPTION"
              name="previewDescription"
              onChange={e => setPrevDescription(e.target.getContent())}
              value={prevDescription}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <EditorGroup
              label="CONTENT"
              name="content"
              onChange={e => setTextContent(e.target.getContent())}
              required={true}
              value={textContent}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="KEYWORDS"
              name="keywords"
              placeholder="#hashtag, #hashtag, ..., #hashtag"
              onChange={e => setKeywords(e.target.value)}
              value={keywords}
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      {newsGallery && newsGallery[0] && !!newsGallery[0].images.length && (
        <>
          <Separator />
          <GallerySection
            label="Gallery"
            gallery={newsGallery}
            deleteOneImageFromGallery={deleteOneImageFromGallery}
            parentId={newsId}
            contentType={newsType}
          />
        </>
      )}
      {newsGallery && newsGallery[0] && !!newsGallery[0].videos.length && (
        <>
          <Separator />
          <VideoSection
            label="Videos"
            videos={newsGallery}
            deleteOneVideoFromGallery={deleteOneVideoFromGallery}
            parentId={newsId}
            contentType={newsType}
          />
        </>
      )}
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={editNew}
            {...buttonOk}
            width="100%"
            disabled={disabledButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.push("/my-business/news")}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  currentNews: state.newsReducer.newsById,
  areCountriesPending: state.countriesReducer.areCountriesPending,
  countries: state.countriesReducer.countries,
  userOrganizationsChamber: state.organizationsReducer.userOrganizationsChamber,
  areUserOrganizationsPending:
    state.organizationsReducer.areUserOrganizationsPending,
  newsRequestStatus: state.newsReducer.newsRequestStatus,
  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,
  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,
  newsCategories: state.newsReducer.newsCategories,
  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  isVideoGalleryUploadPending:
    state.documentsReducer.isVideoGalleryUploadPending
});

export default connect(mapStateToProps, {
  createNews,
  getNewsById,
  updateNews,
  clearNewsRequest,
  getUserOrganizationsChamber,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  searchCountry,

  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  getNewsCategories
})(MyBusinessNewsEdit);
