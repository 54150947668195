import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  DefaultButton,
  Separator,
  InputGroup,
  EditorGroup,
  DefaultDropdown
} from "../../../components";

import {
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "../../../layout";

import {
  getEmployerTypeData,
  getVacancyById,
  createVacancy,
  updateVacancy,
  clearJobRequest,
  searchCountry,
  getUserOrganizations,
  getVacanciesCreatedByMe
} from "../../../redux/actions-exporter";

import { buttonCancel, buttonOk } from "../../../styles/buttons-styles";
import "./my-business-vacancy.edit.component.scss";

const MyBusinessVacancyEdit = ({
  createVacancy,
  employerTypeData,
  getEmployerTypeData,
  countries,
  searchCountry,
  getUserOrganizations,
  getVacanciesCreatedByMe,
  vacanciesByMe,
  getVacancyById,
  currentVacancy,
  updateVacancy,

  clearJobRequest,
  jobRequestStatus
}) => {
  const [title, setTitle] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [employerType, setEmployerType] = useState("");
  const [country, setCountryId] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [city, setCity] = useState("");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [requirements, setRequirements] = useState("");
  const [terms, setTerms] = useState("");

  const [disabledButton, setDisabledButton] = useState(true);

  const { vacancyId } = useParams();
  const history = useHistory();

  useEffect(() => {
    getEmployerTypeData();
  }, [getEmployerTypeData]);

  useEffect(() => {
    getUserOrganizations();
    getVacanciesCreatedByMe();
  }, [getUserOrganizations, getVacanciesCreatedByMe]);

  useEffect(() => {
    if (vacancyId !== "create") {
      getVacancyById(vacancyId);
    }
  }, [getVacancyById, vacancyId]);

  useEffect(() => {
    searchCountry(countryInput);
  }, [searchCountry, countryInput]);

  useEffect(() => {
    if (vacancyId !== "create") {
      const {
        title,
        vacancy_ext,
        type_of_employment,
        country,
        city,
        keywords,
        description,
        requirements,
        terms
      } = currentVacancy;
      setTitle(title);
      setVacancy(vacancy_ext && vacancy_ext.id);
      setEmployerType(type_of_employment && type_of_employment.value);
      setCountryId(country);
      setCity(city);
      setKeywords(keywords);
      setDescription(description);
      setRequirements(requirements);
      setTerms(terms);
    }
  }, [currentVacancy, vacancyId]);

  useEffect(() => {
    setDisabledButton(
      title === "" ||
        vacancy === "" ||
        employerType === "" ||
        country === "" ||
        city === "" ||
        description === "" ||
        requirements === "" ||
        terms === ""
    );
  }, [
    title,
    vacancy,
    employerType,
    country,
    city,
    keywords,
    description,
    requirements,
    terms
  ]);

  useEffect(() => {
    if (jobRequestStatus === 200 || jobRequestStatus === 201) {
      history.push("/my-business/vacancy");
    }
  }, [history, jobRequestStatus]);

  useEffect(() => () => clearJobRequest(), [clearJobRequest]);

  const editVacancy = () => {
    if (vacancyId === "create") {
      createVacancy(
        title,
        vacancy,
        employerType,
        country,
        city,
        keywords,
        description,
        requirements,
        terms
      );
    } else {
      updateVacancy(
        vacancyId,
        title,
        vacancy,
        employerType,
        country,
        city,
        keywords,
        description,
        requirements,
        terms
      );
    }
  };

  const cancelVacancy = () => history.push("/my-business/vacancy");

  return (
    <div className="my-business-vacancy-edit">
      <SectionTextHeader align="center" text="Basic Info" />
      <ParentWrapper>
        <InputGroup
          label="Vacancy"
          name="vacancy"
          placeholder="Vacancy title"
          onChange={e => setTitle(e.target.value)}
          value={title}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultDropdown
          label="VACANCY"
          items={vacanciesByMe}
          returnData={setVacancy}
          customTitle={
            vacancyId !== "create" &&
            currentVacancy.vacancy_ext &&
            currentVacancy.vacancy_ext.name
          }
          required={true}
        />
        <DefaultDropdown
          label="TYPE OF EMPLOYMENT"
          items={
            employerTypeData &&
            employerTypeData.map(({ value, lable }) => ({
              id: value,
              name: lable
            }))
          }
          returnData={setEmployerType}
          customTitle={
            vacancyId !== "create" &&
            currentVacancy.type_of_employment &&
            currentVacancy.type_of_employment.label
          }
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultDropdown
          label="COUNTRY"
          inputFunc={e => setCountryInput(e.target.value)}
          filter={true}
          items={countries.results}
          returnData={setCountryId}
          customTitle={
            vacancyId !== "create" &&
            currentVacancy.country_ext &&
            currentVacancy.country_ext.name
          }
          required={true}
        />
        <InputGroup
          label="CITY"
          name="city"
          placeholder="City"
          onChange={e => setCity(e.target.value)}
          value={city}
          required={true}
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <InputGroup
          label="KEYWORDS"
          name="keywords"
          placeholder="#hashtag, #hashtag, ..., #hashtag"
          onChange={e => setKeywords(e.target.value)}
          value={keywords}
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          label="DESCRIPTION"
          name="description"
          onChange={e => setDescription(e.target.getContent())}
          value={description}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          label="REQUIREMENTS"
          name="requirements"
          onChange={e => setRequirements(e.target.getContent())}
          value={requirements}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          label="TERMS"
          name="terms"
          onChange={e => setTerms(e.target.getContent())}
          value={terms}
          required={true}
        />
      </ParentWrapper>
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            width="100%"
            onClick={editVacancy}
            {...buttonOk}
            disabled={disabledButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={cancelVacancy}
            borderRadius={0}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  currentVacancy: state.vacanciesReducer.vacancyById,
  employerTypeData: state.vacanciesReducer.employerTypeData,
  countries: state.countriesReducer.countries,
  vacanciesByMe: state.vacanciesReducer.vacanciesByMe,
  jobRequestStatus: state.vacanciesReducer.jobRequestStatus
});

export default connect(mapStateToProps, {
  createVacancy,
  updateVacancy,
  getVacancyById,
  getVacanciesCreatedByMe,
  getEmployerTypeData,
  searchCountry,
  getUserOrganizations,
  clearJobRequest
})(MyBusinessVacancyEdit);
