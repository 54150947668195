import icon from "./icon.png";

export default function productsFilterBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .${className} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: min-content;
  }

  .${className} .${className}__button {
    align-items: center;
    min-width: 30px;
    width: auto;
    background: transparent;
    padding: 2px 4px;
    margin: 0 8px;
    cursor: pointer;
    outline: none;
    border: none;
    text-align: initial;
  }
  
  .${className} .${className}__button svg {
    width: 100%;
  }

  .${className} .${className}__trigger {
    width: min-content;
  }

  .${className} .${className}__content {
    position: absolute;
    min-width: 89%;
    box-sizing: border-box;
    background: #ffffff;
    padding: 8px 10px;
    margin: 6px 0 0;
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
    transition: all 300ms ease;
    overflow-y: auto;
  }
  
  .${className} .${className}__content-wrapper {
    display: flex;
    padding: 20px;
  }
  
  .${className} .${className}__content-column {
    padding: 10px;
    flex-basis: 25%;
  }
  
  .${className} .${className}__column-list {
    margin: 0;
    list-style: none;
    padding: 0;
  }
  
  .${className} .${className}__column-item {
    margin: 5px 0;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
  }
  
  .${className} .${className}__column-item.active {
    color: #111111;
    text-decoration: underline;
  }
  
  .${className} .${className}__column-item:hover {
    color: #cccccc;
  }
  
  .${className} .${className}__column-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  .${className} .${className}__caret {
    position: relative;
    width: 10px;
    color: black;
    padding: 0 2px;
  }

  .${className} .${className}__caret::before {
    content: "";
    position: absolute;
    margin: -2px 0;
    border-top: 7px solid currentColor;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .${className} .${className}__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .${className} .${className}__list-item {
    cursor: pointer;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="${className}">
    <div class="${className}__trigger-label">
      Filters:
    </div>
    <div class="${className}__trigger">
      <button type="button" class="${className}__button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471.8 471.8"><path d="M26.9 82.5H317a70.6 70.6 0 100-24H26.9c-6.6 0-12 5.4-12 12s5.4 12 12 12zM386.4 24a46.5 46.5 0 110 93 46.5 46.5 0 010-93zM303.9 235.3c0 6.6 5.4 12 12 12s12-5.4 12-12-5.4-12-12-12a12 12 0 00-12 12zM260.9 235.3c0 6.6 5.4 12 12 12s12-5.4 12-12-5.4-12-12-12a12 12 0 00-12 12zM346.9 235.3c0 6.6 5.4 12 12 12s12-5.4 12-12-5.4-12-12-12-12 5.4-12 12zM389.9 235.3c0 6.6 5.4 12 12 12s12-5.4 12-12-5.4-12-12-12-12 5.4-12 12zM174.9 235.3c0 6.6 5.4 12 12 12s12-5.4 12-12-5.4-12-12-12-12 5.4-12 12zM217.9 235.3c0 6.6 5.4 12 12 12s12-5.4 12-12-5.4-12-12-12a12 12 0 00-12 12zM85.4 305.8a70.5 70.5 0 100-141 70.5 70.5 0 000 141zm0-117a46.5 46.5 0 110 93 46.5 46.5 0 010-93zM386.4 330.8a70.5 70.5 0 00-69.4 58.5H26.9c-6.6 0-12 5.4-12 12s5.4 12 12 12H317a70.6 70.6 0 1069.4-82.5zm0 117a46.5 46.5 0 110-93 46.5 46.5 0 010 93z"/></svg>
      </button>
    </div>

    <div class="${className}__content">
      <div class="${className}__content-wrapper">
        <div class="${className}__content-column">
            <div class="${className}__column-title">Size</div>
            <ul class="${className}__column-list">
                <li class="${className}__column-item" data-type="size">XS</li>     
                <li class="${className}__column-item" data-type="size">S</li>     
                <li class="${className}__column-item" data-type="size">M</li>     
                <li class="${className}__column-item" data-type="size">L</li>    
                <li class="${className}__column-item" data-type="size">XL</li>    
            </ul>     
        </div>    
        <div class="${className}__content-column">
            <div class="${className}__column-title">Color</div>
            <ul class="${className}__column-list">
                <li class="${className}__column-item" data-type="color">black</li>     
                <li class="${className}__column-item" data-type="color">white</li>     
                <li class="${className}__column-item" data-type="color">yellow</li>     
                <li class="${className}__column-item" data-type="color">green</li>    
                <li class="${className}__column-item" data-type="color">red</li>    
            </ul>     
        </div>    
        <div class="${className}__content-column">
            <div class="${className}__column-title">Season</div>
            <ul class="${className}__column-list">
                <li class="${className}__column-item" data-type="season">summer</li>     
                <li class="${className}__column-item" data-type="season">autumn</li>     
            </ul>     
        </div>     
      </div>
    </div>
  </div>
  `;

  blockManager.add("products-filter", {
    label: `
      <img src="${icon}" alt="Products search block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Filter
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
