import React from "react";

import "./marker.styles.scss";

const Marker = ({ name }) => {
  return (
    <div className="marker" title={name}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/d/d1/Google_Maps_pin.svg"
        alt=""
      />
    </div>
  );
};

export default Marker;
