import React from "react";

import { PageNotFound } from "../../assets";

import "./no-match-page.styles.scss";

const NoMatchPage = () => (
  <div className="no-match">
    <img src={PageNotFound} alt="" />
  </div>
);

export default NoMatchPage;
