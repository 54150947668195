export default function jobsSearchComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };
    function render(searchEl) {
      // eslint-disable-next-line no-undef
      new autoComplete({
        data: {
          // Data src [Array, Function, Async] | (REQUIRED)
          src: () => {
            // User search query
            const query = searchEl.value;
            // Get request data
            const API_ROOT = window.jobs.API_ROOT;
            const params = new URLSearchParams(
              Object.assign({}, window.jobs.params, {
                search: query
              })
            );
            // Fetch External Data Source
            return fetch(`${API_ROOT}/department/jobs/?${params}`)
              .then(response => response.json())
              .then(response => response.results);
          },
          key: ["title", "keywords", "type_of_employment"],
          cache: false
        },
        sort: (a, b) => {
          if (a.match < b.match) return -1;
          if (a.match > b.match) return 1;
          return 0;
        },
        placeHolder: "Search...",
        selector: `.${searchEl.className}`,
        threshold: 2,
        debounce: 300,
        searchEngine: "loose", // strict | loose
        resultsList: {
          render: true,
          container: source => {
            source.removeAttribute("id");
            source.classList.add("jobs-search-list");
          },
          destination: document.querySelector(`.${searchEl.className}`),
          position: "afterend",
          element: "ul"
        },
        maxResults: 10,
        highlight: true,
        resultItem: {
          content: (data, source) => {
            source.classList.remove("autoComplete_result");
            source.classList.add("jobs-search-list__item");

            Array.prototype
              .slice(source.querySelectorAll(".autoComplete_highlighted"))
              // eslint-disable-next-line array-callback-return
              .map(el => {
                el.classList.remove("autoComplete_highlighted");
                el.classList.add("jobs-search-list__highlighted");
              });
            console.log(data, source);
            source.innerHTML = data.match;
          },
          element: "li"
        },
        // noResults: () => {
        //   const result = document.createElement("li");
        //   result.setAttribute("class", "no_result");
        //   result.setAttribute("tabindex", "1");
        //   result.innerHTML = "No Results...";
        //   document.querySelector(".jobs-search-list").appendChild(result);
        // },
        onSelection: feedback => {
          const jobName = feedback.selection.value[feedback.selection.key];
          searchEl.value = jobName;
          window.jobs.search(jobName);
        }
      });
    }
    // Get translation
    const translations = getTranslate("translations", this.attributes);
    // Get search input
    const searchEl = this.querySelector(".jobs-search");
    // Change placeholder
    if (translations && Object.keys(translations).length) {
      searchEl.setAttribute("placeholder", translations["Search..."]);
    }

    if (!window.hasOwnProperty("grapesjs")) {
      return;
    }
    this.querySelector(".jobs-search-list").remove();
    // Search on change
    searchEl.addEventListener("change", function(e) {
      if (window.hasOwnProperty("jobs") && window.hasOwnProperty("grapesjs")) {
        window.jobs.search(e.target.value);
      }
    });
    if (typeof autoComplete === "undefined") {
      // const link = document.createElement("link");
      // link.rel = "stylesheet";
      // link.href = `//cdn.jsdelivr.net/npm/@tarekraafat/autocomplete.js@7.2.0/dist/css/autoComplete.min.css`;
      // document.head.append(link);

      const script = document.createElement("script");
      script.src = `//cdn.jsdelivr.net/npm/@tarekraafat/autocomplete.js@7.2.0/dist/js/autoComplete.min.js`;
      document.body.append(script);
      script.addEventListener("load", () => render(searchEl));
    } else {
      render(searchEl);
    }
  };

  editor.DomComponents.addType("jobs-search", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "jobs-search-wrapper",
    model: {
      defaults: {
        name: "Jobs Search",
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Search..."]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });

  editor.DomComponents.addType("jobs-search__input", {
    isComponent: el => el.tagName === "INPUT" && el.className === "jobs-search",
    model: {
      defaults: {
        name: "Jobs Search Input",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("jobs-search__list", {
    isComponent: el =>
      el.tagName === "INPUT" && el.className === "jobs-search-list",
    model: {
      defaults: {
        name: "Jobs Search List",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });
}
