import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";

import { withDebounce } from "../../../shared";
import {
  LoadingSpinner,
  EventCard,
  SearchBar,
  Pagination,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import { getNewsByUserId, deleteNews } from "../../../redux/actions-exporter";

import "./my-business-news.styles.scss";

const MyBusinessNews = ({
  areUserNewsPending,
  userNews,
  userNewsCount,
  getNewsByUserId,
  deleteNews
}) => {
  const [newsSearch, setNewsSearch] = useState("");
  const [newsPage, setNewsPage] = useState(1);
  const [currentNewsId, setCurrentNewsId] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getNewsByUserId(newsSearch, newsPage);
  }, [getNewsByUserId, newsSearch, newsPage]);

  useEffect(() => {
    setNewsPage(1);
  }, [newsSearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newsPage]);

  const newsSearchHandler = withDebounce(e => setNewsSearch(e));

  return (
    <div className="my-business-news">
      <ConfirmDialog
        dialogTitle="News remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteNews(currentNewsId);
          setDialogOpen(false);
          setCurrentNewsId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => newsSearchHandler(e)} />
      </div>
      {areUserNewsPending ? (
        <LoadingSpinner />
      ) : userNews && userNews.length ? (
        userNews.map(
          ({
            id,
            organization,
            categories,
            title,
            image,
            short_description,
            country,
            created_at
          }) => (
            <EventCard
              key={id}
              img={image ? image : true}
              imgSrc={image ? image : "https://via.placeholder.com/150"}
              country={country ? country.name : "No country"}
              organization={
                organization ? organization.name : "No organization"
              }
              title={title}
              description={parse(short_description)}
              publicated={true}
              publicatedDate={moment(created_at).format("DD MMMM YYYY")}
              editorState={true}
              editLink={`/my-business/news/${id}`}
              removeFunc={() => {
                setDialogOpen(true);
                setCurrentNewsId(id);
              }}
              viewLiveUrl={`/news/${id}`}
            />
          )
        )
      ) : (
        <MyBusinessNoData noDataText="No news found!" />
      )}
      <Pagination
        forcePage={newsPage - 1}
        dataCount={userNewsCount}
        itemsPerPage={10}
        selectedPage={page => setNewsPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areUserNewsPending: state.newsReducer.areNewsByUserIdPending,
  userNews: state.newsReducer.newsByUserId,
  userNewsCount: state.newsReducer.newsByUserIdCount
});

export default connect(mapStateToProps, {
  getNewsByUserId,
  deleteNews
})(MyBusinessNews);
