import icon from "./icon.png";

export default function productsProducersBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .products-producers {}

  .products-producers.products-producers--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .products-producers.products-producers--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .products-producers__list {
    list-style: none;
    padding: 0;
    margin: 14px 0 0;
  }

  .products-producers__search {
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000000;
    font-size: 15px;
    border: 1px solid #b9b9ba;
    padding: 10px 8px;
    border-radius: 3px;
    width: 100%;
  }

  .products-producers__title {
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 21px;
  }

  .products-producers__item {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 5px;
    margin: 6px 0 0;
    border-radius: 2px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease;
  }

  .products-producers__item.products-producers__item--active {
    background: rgba(0, 0, 0, 0.08);
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="products-producers-wrapper">
    <input class="products-producers__search" type="text" placeholder="Search...">
    <div class="products-producers">
        <ul class="products-producers__list">
          <li class="products-producers__item products-producers__item--active" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
          <li class="products-producers__item products-producers__item" data-id="163584">
            Selected Producer
          </li>
        </ul>
    </div>
  </div>
  `;

  blockManager.add("products-producers", {
    label: `
      <img src="${icon}" alt="Products producers block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Producers
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
