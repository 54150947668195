import React, { useEffect, useState } from "react";

import { useParams, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import Card from "@material-ui/core/Card";

import {
  JobDetailsBrief,
  JobDetailsNav,
  JobDetailsBody
} from "../../containers";

import { LoadingSpinner } from "../../components";

import { getJob } from "../../redux/APIs/job/jobs/jobs.action";

import "./job-details.styles.scss";

const JobDetails = ({ getJob, isJobPending, job }) => {
  const { jobId } = useParams();

  useEffect(() => {
    getJob(jobId);
  }, [getJob, jobId]);

  const [tab, setTab] = useState("desc");

  const {
    title,
    created_at,
    type_of_employment,
    vacancy_ext,
    description,
    requirements,
    terms
  } = job;

  const { url } = useRouteMatch();

  return (
    <div className="job-details">
      {isJobPending ? (
        <LoadingSpinner />
      ) : (
        <Card>
          {vacancy_ext && (
            <JobDetailsBrief
              jobTitle={title}
              date={moment(created_at).format("DD MMMM YYYY")}
              company={
                vacancy_ext &&
                vacancy_ext.department_ext &&
                vacancy_ext.department_ext.organization.name
              }
              typeOfEmployment={type_of_employment && type_of_employment.label}
              currentUrl={url}
            />
          )}

          <div className="job-details__horizontal-separator" />
          <JobDetailsNav setTab={setTab} activeTab={tab} />
          {tab === "desc" && description && (
            <JobDetailsBody
              title="Description"
              data={description && parse(parse(description))}
            />
          )}
          {tab === "req" && requirements && (
            <JobDetailsBody
              title="Requirements"
              data={requirements && parse(parse(requirements))}
            />
          )}
          {tab === "term" && terms && (
            <JobDetailsBody title="Terms" data={terms && parse(parse(terms))} />
          )}
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isJobPending: state.jobsAPI.isJobPending,
  job: state.jobsAPI.job
});

export default connect(mapStateToProps, { getJob })(JobDetails);
