export const StaticPagesTypes = {
  GET: {
    GET_STATIC_PAGE_BY_ID_PENDING: "GET_STATIC_PAGE_BY_ID_PENDING",
    GET_STATIC_PAGE_BY_ID_SUCCESS: "GET_STATIC_PAGE_BY_ID_SUCCESS",
    GET_STATIC_PAGE_BY_ID_ERROR: "GET_STATIC_PAGE_BY_ID_ERROR",

    GET_HEADER_STATIC_PAGES_PENDING: "GET_HEADER_STATIC_PAGES_PENDING",
    GET_HEADER_STATIC_PAGES_SUCCESS: "GET_HEADER_STATIC_PAGES_SUCCESS",
    GET_HEADER_STATIC_PAGES_ERROR: "GET_HEADER_STATIC_PAGES_ERROR",

    GET_FOOTER_STATIC_PAGES_PENDING: "GET_FOOTER_STATIC_PAGES_PENDING",
    GET_FOOTER_STATIC_PAGES_SUCCESS: "GET_FOOTER_STATIC_PAGES_SUCCESS",
    GET_FOOTER_STATIC_PAGES_ERROR: "GET_FOOTER_STATIC_PAGES_ERROR"
  }
};
