export const LeadsActionTypes = {
  GET: {
    GET_LEADS_PENDING: "GET_LEADS_PENDING",
    GET_LEADS_SUCCESS: "GET_LEADS_SUCCESS",
    GET_LEADS_ERROR: "GET_LEADS_ERROR",

    GET_LEAD_BY_ID_PENDING: "GET_LEAD_BY_ID_PENDING",
    GET_LEAD_BY_ID_SUCCESS: "GET_LEAD_BY_ID_SUCCESS",
    GET_LEAD_BY_ID_ERROR: "GET_LEAD_BY_ID_ERROR"
  },
  POST: {
    CREATE_LEAD_SUCCESS: "CREATE_LEAD_SUCCES",
    CREATE_LEAD_ERROR: "CREATE_LEAD_ERROR"
  },
  PUT: {
    UPDATE_LEAD_SUCCESS: "UPDATE_LEAD_SUCCESS",
    UPDATE_LEAD_ERROR: "UPDATE_LEAD_ERROR"
  },
  DELETE: {
    DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
    DELETE_LEAD_ERROR: "DELETE_LEAD_ERROR"
  },
  CLEAR_LEAD_REQUEST: "CLEAR_LEAD_REQUEST"
};
