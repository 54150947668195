import React from "react";

import { Clear as ClearIcon } from "@material-ui/icons";

import { staticURL } from "../../constants";

import "./gallery-section.styles.scss";

const GallerySection = ({
  label,
  gallery,
  deleteOneImageFromGallery,
  parentId,
  contentType
}) => {
  return (
    <div className="gallery-section">
      <div className="gallery-section__header">{label}</div>
      <div className="gallery-section__images">
        {gallery &&
          gallery.map(({ images }) =>
            images.map((el, index) => (
              <div className="single-image" key={index}>
                <ClearIcon
                  onClick={() =>
                    deleteOneImageFromGallery(el.id, parentId, contentType)
                  }
                  className="single-image__clear"
                />
                <a
                  className="single-image__link"
                  href={`${staticURL}/${el.image}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={`${staticURL}/${el.image}`} alt="" />
                </a>
              </div>
            ))
          )}
      </div>
    </div>
  );
};

export default GallerySection;
