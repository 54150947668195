import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Editor } from "@core/components";

import "./category.styles.scss";

const Category = ({ typeOfCategory, category, editorState, editLink, removeFunc }) => {
  const { id, name } = category;
  const [editor, setEditor] = useState(false);

  return (
    <div
      className="category-component"
      onMouseEnter={() => setEditor(true)}
      onMouseLeave={() => setEditor(false)}
    >
      <div className="company-department-info__companies">
        <div className="companies__inner-content">
          <div className="companies__inner-content__company">
            <div className="companies__inner-content__company__name">
              <Link
                to={`${
                  typeOfCategory === "b2c"
                    ? `/my-business/categories/b2c/${id}`
                    : `/my-business/categories/b2b/${id}`
                }`}
              >
                {name}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {editorState && (
        <div className="category-component__editor">
          {editor && <Editor removeFunc={removeFunc} editLink={editLink} />}
        </div>
      )}
    </div>
  );
};

export default Category;
