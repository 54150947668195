export default function authLoginFormComponent({ editor, api }) {
  function script() {
    if (window.hasOwnProperty("grapesjs")) {
      document.querySelector(".login-form__messages").innerHTML = "";
    }
  }

  editor.DomComponents.addType("auth-login-form", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "login-form-wrapper",
    model: {
      defaults: {
        name: "Login Form",
        script
      }
    }
  });
}
