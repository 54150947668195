export const colorPalette = {
  colorPrimary: {
    main: "#ff9a44",
    darker: "#ff7f13",
    dangerRed: "#ce3131"
  },
  grayBackground: "#fafafa",
  grayText: "#999999",
  facebook: "#3b5998",
  linkedin: "#0e76a8",
  twitter: "#1da1f2"
};
