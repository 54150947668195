import React from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const ExpandArrow = ({ expand }) => {
  return <div>{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>;
};

export default ExpandArrow;
