import icon from "./icon.png";

export default function productsCategoriesLinksBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .products-categories-links {}

  .products-categories-links.products-categories-links--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .products-categories-links.products-categories-links--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .products-categories-links__list {
    list-style: none;
    padding: 0;
  }

  .products-categories-links__title {
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 21px;
  }

  .products-categories-links__list-wrapper {
    padding: 0 22px 0;
  }

  .products-categories-links__item {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 5px;
    margin: 6px 0 0;
    border-radius: 2px;
    cursor: pointer;
    transition: all 200ms ease;
  }

  .products-categories-links__item.products-categories-links__item--subcategory {
    font-size: 16px;
    padding: 4px 5px;
  }

  .products-categories-links__link {
    color: currentColor;
  }

  .products-categories-links__link:hover {
    color: currentColor;
    text-decoration-color: currentColor;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="products-categories-links">
    <ul class="products-categories-links__list">
      <li class="products-categories-links__item products-categories-links__item--primary">
        <a class="products-categories-links__link products-categories-links__link--primary" href="#?gjs-category=163583">
          Category
        </a>
      </li>
      <li class="products-categories-links__list-wrapper">
        <ul class="products-categories-links__list products-categories-links__list--subcategories">
    
          <li class="products-categories-links__item products-categories-links__item--subcategory">
            <a class="products-categories-links__link products-categories-links__link--subcategory"
              href="#?gjs-category=86648">
              Subcategory & Subcategory
            </a>
          </li>
    
          <li class="products-categories-links__item products-categories-links__item--subcategory">
            <a class="products-categories-links__link products-categories-links__link--subcategory"
              href="#?gjs-category=162756">
              Subcategory & Subcategory
            </a>
          </li>
    
          <li class="products-categories-links__item products-categories-links__item--subcategory">
            <a class="products-categories-links__link products-categories-links__link--subcategory"
              href="#?gjs-category=163304">
              Subcategory & Subcategory
            </a>
          </li>
    
          <li class="products-categories-links__item products-categories-links__item--subcategory">
            <a class="products-categories-links__link products-categories-links__link--subcategory"
              href="#?gjs-category=163305">
              Subcategory & Subcategory
            </a>
          </li>
    
          <li class="products-categories-links__item products-categories-links__item--subcategory">
            <a class="products-categories-links__link products-categories-links__link--subcategory"
              href="#?gjs-category=163734">
              Subcategory & Subcategory
            </a>
          </li>
    
          <li class="products-categories-links__item products-categories-links__item--subcategory">
            <a class="products-categories-links__link products-categories-links__link--subcategory"
              href="#?gjs-category=163974">
              Subcategory & Subcategory
            </a>
          </li>
    
        </ul>
      </li>
    </ul>
  </div>
  `;

  blockManager.add("products-categories-links", {
    label: `
      <img src="${icon}" alt="Products categories links block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Categories Links
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
