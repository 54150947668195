import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { LeftArrow, RightArrow, missingImage } from "../../assets";
import { ProductCarouselItem, Button, LoadingSpinner } from "../../components";

import "./product-carousel.styles.scss";

const ProductCarousel = ({
  icon = <></>,
  title,
  loading,
  products,
  page = 1,
  getProducts,
  navProductType,
  filterCountriesArr,
  props = {}
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isDesktop = windowWidth >= 1024;

  useEffect(() => {
    const getWindow = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", getWindow);
  }, [filterCountriesArr]);

  // eslint-disable-next-line no-unused-vars
  const [slide, setSlide] = useState(5);
  const [slideMobile, setSlideMobile] = useState(1);

  const slideLeft = () => getProducts(page - 1);
  const slideRight = () => getProducts(page + 1);

  const slideLeftMobile = () => {
    if (slideMobile <= 1) {
      getProducts(page - 1);
      setSlideMobile(products.length);
    } else {
      setSlideMobile(slideMobile - 1);
    }
  };

  const slideRightMobile = () => {
    if (slideMobile >= products.length) {
      getProducts(page + 1);
      setSlideMobile(1);
    } else {
      setSlideMobile(slideMobile + 1);
    }
  };

  return (
    <div className="product-carousel" {...props}>
      <div className="product-carousel__header">
        <p className="product-carousel__title">
          {icon}
          <span className="product-carousel__title-text">{title}</span>
        </p>
        <div>
          <Button func={isDesktop ? slideLeft : slideLeftMobile} mod="arrow">
            <LeftArrow />
          </Button>
          <Button func={isDesktop ? slideRight : slideRightMobile} mod="arrow">
            <RightArrow />
          </Button>
        </div>
      </div>
      <div className="product-carousel__main">
        {loading ? (
          <LoadingSpinner />
        ) : isDesktop ? (
          products &&
          products
            .filter((product, idx) => idx < slide && idx >= slide - 5)
            .map(product => (
              <ProductCarouselItem
                key={product.id}
                name={product.name}
                productId={product.id}
                navProductType={navProductType}
                imgUrl={product.image ? product.image : missingImage}
                price={product.cost}
                currency={product.currency}
                discountPercent={product.discount_percent}
                couponDiscountPercent={product.coupon_discount_percent}
                metadata={product.metadata}
                currentProductData={product}
              />
            ))
        ) : (
          products &&
          products
            .filter(
              (product, idx) => idx < slideMobile && idx >= slideMobile - 1
            )
            .map(product => (
              <ProductCarouselItem
                key={product.id}
                name={product.name}
                productId={product.id}
                navProductType={navProductType}
                imgUrl={product.image ? product.image : missingImage}
                price={product.cost}
                currency={product.currency}
                discountPercent={product.discount_percent}
                couponDiscountPercent={product.coupon_discount_percent}
                metadata={product.metadata}
                currentProductData={product}
              />
            ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  filterCountriesArr: state.filterReducer.filterCountriesArr
});

export default connect(mapStateToProps, {})(ProductCarousel);
