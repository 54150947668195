import { SiteBuilderActionTypes } from "./siteBuilder.types";

const INITIAL_STATE = {
  builderSitesPending: false,
  builderSites: [],
  builderSitesError: {},

  builderSiteByDomainPending: false,
  builderSiteByDomain: {},
  builderSiteByDomainError: {},

  builderSiteByIdPending: false,
  builderSiteById: {},
  builderSiteByIdError: {},

  createSitePending: false,
  createSiteCompleted: {},
  createSiteError: {},

  updatedSitePending: false,
  updatedSiteCompleted: {},
  updatedSiteError: {},

  deleteSitePending: false,
  deleteSiteCompleted: {},
  deleteSiteError: {},

  domainTypes: []
};

const SiteBuilderReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SiteBuilderActionTypes.GET.GET_SITES_PENDING:
      return {
        ...state,
        builderSitesPending: true
      };

    case SiteBuilderActionTypes.GET.GET_SITES_SUCCESS:
      return {
        ...state,
        builderSitesPending: false,
        builderSites: action.payload
      };

    case SiteBuilderActionTypes.GET.GET_SITES_ERROR:
      return {
        ...state,
        builderSitesPending: false,
        builderSitesError: action.payload
      };

    case SiteBuilderActionTypes.GET.GET_SITE_BY_DOMAIN_PENDING:
      return {
        ...state,
        builderSiteByDomainPending: true
      };

    case SiteBuilderActionTypes.GET.GET_SITE_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        builderSiteByDomainPending: false,
        builderSiteByDomain: action.payload
      };

    case SiteBuilderActionTypes.GET.GET_SITE_BY_DOMAIN_ERROR:
      return {
        ...state,
        builderSiteByDomainPending: false,
        builderSiteByDomainError: action.payload
      };

    case SiteBuilderActionTypes.GET.GET_SITE_BY_ID_PENDING:
      return {
        ...state,
        builderSiteByIdPending: true
      };

    case SiteBuilderActionTypes.GET.GET_SITE_BY_ID_SUCCESS:
      return {
        ...state,
        builderSiteByIdPending: false,
        builderSiteById: action.payload
      };

    case SiteBuilderActionTypes.GET.GET_SITE_BY_ID_ERROR:
      return {
        ...state,
        builderSiteByIdPending: false,
        builderSiteByIdError: action.payload
      };

    case SiteBuilderActionTypes.POST.CREATE_SITE_PENDING:
      return {
        ...state,
        createSitePending: true
      };

    case SiteBuilderActionTypes.POST.CREATE_SITE_SUCCESS:
      return {
        ...state,
        createSitePending: false,
        createSiteCompleted: action.payload
      };

    case SiteBuilderActionTypes.POST.CREATE_SITE_ERROR:
      return {
        ...state,
        createSitePending: false,
        createSiteError: action.payload
      };

    case SiteBuilderActionTypes.PUT.UPDATE_SITE_PENDING:
      return {
        ...state,
        updatedSitePending: true
      };

    case SiteBuilderActionTypes.PUT.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        updatedSitePending: false,
        updatedSiteCompleted: action.payload
      };

    case SiteBuilderActionTypes.PUT.UPDATE_SITE_ERROR:
      return {
        ...state,
        updatedSitePending: false,
        updatedSiteError: action.payload
      };

    case SiteBuilderActionTypes.GET.GET_DOMAIN_TYPES:
      return {
        ...state,
        domainTypes: action.payload
      };

    default:
      return state;
  }
};

export default SiteBuilderReducer;
