export default function productsSortComponent({ editor, api, className }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    const translations = getTranslate("translations", this.attributes);

    const className = this.className;
    const isAlwaysActive = this.dataset.hasOwnProperty("alwaysActive");
    const isHover = this.dataset.hasOwnProperty("hover");
    let isActive = false;
    let isBlocked = false;

    const wrapperSelector = `.${className}#${this.id}`;

    const triggerSelector = `${wrapperSelector} .${className}__trigger`;
    const getTriggerEl = () => document.querySelector(triggerSelector);
    const contentSelector = `${wrapperSelector} .${className}__content`;
    const getContentEl = () => document.querySelector(contentSelector);

    if (typeof Popper !== "object") {
      const script = document.createElement("script");
      script.src =
        "//cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js";
      document.body.append(script);

      script.onload = function() {
        const popper = new window.Popper(getTriggerEl(), getContentEl(), {
          placement: "bottom"
        });
        popper.enableEventListeners();
        popper.scheduleUpdate();
      };
    } else {
      const popper = new window.Popper(getTriggerEl(), getContentEl(), {
        placement: "bottom"
      });
      popper.enableEventListeners();
      popper.scheduleUpdate();
    }

    const isRTL = window.localStorage.getItem("direction") === "rtl";
    const getContentStyles = isActive =>
      !!isActive
        ? Object.assign(
            {},
            {
              visibility: "visible",
              opacity: "1",
              pointerEvents: "all",
              right: "auto",
              margin: "11px -14px 0"
            },
            isRTL ? { textAlign: "right", margin: "11px 16px 0" } : {}
          )
        : {
            visibility: "hidden",
            opacity: "0",
            pointerEvents: "none",
            right: "auto"
          };

    if (isAlwaysActive) {
      isActive = true;
      Object.assign(getContentEl().style, getContentStyles(isActive));
      return;
    }

    Object.assign(getContentEl().style, getContentStyles(isActive));

    if (isHover) {
      getTriggerEl().addEventListener("mouseover", e => {
        if (!isActive) {
          isActive = true;
          Object.assign(getContentEl().style, getContentStyles(isActive));
        }
      });

      getContentEl().addEventListener("mouseover", e => {
        if (isActive) {
          isBlocked = true;
        }
      });

      getContentEl().addEventListener("mouseleave", e => {
        if (isActive) {
          isActive = false;
          isBlocked = false;
          Object.assign(getContentEl().style, getContentStyles(isActive));
        }
      });

      this.addEventListener("mouseleave", e =>
        setTimeout(() => {
          if (isActive && !isBlocked) {
            isActive = false;
            Object.assign(getContentEl().style, getContentStyles(isActive));
          }
        }, 1000)
      );
    } else {
      document.body.addEventListener("click", e => {
        if (e.target.closest(triggerSelector)) {
          isActive = !isActive;
          Object.assign(getContentEl().style, getContentStyles(isActive));

          e.stopPropagation();
        } else if (
          isActive &&
          document.body.contains(e.target) &&
          !e.target.closest(wrapperSelector)
        ) {
          if (!window.hasOwnProperty("grapesjs")) {
            return;
          }

          isActive = false;
          Object.assign(getContentEl().style, getContentStyles(isActive));
        }
      });
    }

    const valueLabelSelector = `${wrapperSelector} .${className}__trigger-label`;
    const valueLableSelectorEl = document.querySelector(valueLabelSelector);

    const valueDisplaySelector = `${wrapperSelector} .${className}__value`;
    const valueDisplaySelectorEl = document.querySelector(valueDisplaySelector);

    const optionsSelector = `${wrapperSelector} .${className}__list-item`;
    const optionsNodes = document.querySelectorAll(optionsSelector);
    Array.prototype.slice.call(optionsNodes).map(optionEl =>
      optionEl.addEventListener("click", e => {
        const element = e.target.closest(`.${className}__list-item`);
        const label = element.innerText;
        const value = element.dataset.value;

        valueDisplaySelectorEl.innerText = label;

        if (
          window.hasOwnProperty("products") &&
          window.hasOwnProperty("grapesjs")
        ) {
          isActive = false;
          isBlocked = false;
          Object.assign(getContentEl().style, getContentStyles(isActive));
          window.products.sortby(value);
        }
      })
    );

    if (window.hasOwnProperty("grapesjs")) {
      valueLableSelectorEl.innerText = translations["Sort:"];
      valueDisplaySelectorEl.innerText = translations["Newest"];

      document.querySelector(
        `${wrapperSelector} .${className}__list-item[data-value="-id"]`
      ).innerText = translations["Newest"];

      document.querySelector(
        `${wrapperSelector} .${className}__list-item[data-value="id"]`
      ).innerText = translations["Oldest"];

      document.querySelector(
        `${wrapperSelector} .${className}__list-item[data-value="cost"]`
      ).innerText = translations["Price ascending"];

      document.querySelector(
        `${wrapperSelector} .${className}__list-item[data-value="-cost"]`
      ).innerText = translations["Price descending"];
    }
  };

  editor.DomComponents.addType("products-sort", {
    isComponent: el => el.tagName === "DIV" && el.className === `${className}`,
    model: {
      defaults: {
        name: "Dropdown",
        script,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "Sort:",
              "Newest",
              "Oldest",
              "Price ascending",
              "Price descending"
            ]
          },
          {
            type: "checkbox",
            label: "Always active (or editor mode)",
            name: "data-always-active",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "checkbox",
            label: "Show / Hide on hover",
            name: "data-hover",
            valueTrue: true,
            valueFalse: false
          }
        ]
      },
      view: {
        init() {
          editor.select(this.model.components().parent);
          this.listenTo(
            this.model,
            "change:attributes:translations",
            this.render
          );
        }
      }
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          "change:attributes:data-always-active",
          this.render
        );
        this.listenTo(this.model, "change:attributes:data-hover", this.render);
      }
    }
  });

  editor.DomComponents.addType("products-sort__trigger", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === `${className}__trigger`,
    model: {
      defaults: {
        name: "Products Sort Trigger",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-sort__input", {
    isComponent: el =>
      el.tagName === "INPUT" && el.className === `${className}__input`,
    model: {
      defaults: {
        name: "Products Sort Value"
      }
    }
  });

  editor.DomComponents.addType("products-sort__content", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === `${className}__content`,
    model: {
      defaults: {
        name: "Products Sort Content",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });
}
