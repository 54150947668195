import { ResumeActionTypes } from "./resume.types";

const INITIAL_STATE = {
  areResumesPending: false,
  resumes: {},
  resumesError: null
};

const ResumesAPIReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ResumeActionTypes.GET.GET_RESUMES_PENDING:
      return { ...state, areResumesPending: true };

    case ResumeActionTypes.GET.GET_RESUMES_SUCCESS:
      return {
        ...state,
        resumes: action.payload,
        areResumesPending: false
      };

    case ResumeActionTypes.GET.GET_RESUMES_ERROR:
      return {
        ...state,
        resumesError: action.payload,
        areResumesPending: false
      };

    default:
      return state;
  }
};

export default ResumesAPIReducer;
