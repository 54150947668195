import React from "react";
import { connect } from "react-redux";

import "./subscription-form-styles.scss";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DefaultInput from "../default-input/default-input.component";
import DefaultButton from "../default-button/default-button.component";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/";

const InputLabelMod = withStyles(() => ({
  focused: {
    "&$focused": {
      color: "white"
    }
  }
}))(InputLabel);

const SubscriptionForm = ({
  name,
  email,
  handleChangeName,
  handleChangeEmail,
  onSubmitForm,
  localizationData
}) => {
  const onSubmit = e => {
    e.preventDefault();
    onSubmitForm(e);
  };
  return (
    <form onSubmit={onSubmit} className="subscription-form">
      <FormControl>
        <InputLabelMod
          className="subscription-form-label"
          htmlFor="component-simple"
        >
          {localizationData["Your Name"]}
        </InputLabelMod>
        <DefaultInput value={name} onChange={handleChangeName} />
      </FormControl>
      <FormControl>
        <InputLabelMod
          className="subscription-form-label"
          htmlFor="component-simple"
        >
          {localizationData["Your E-mail"]}
        </InputLabelMod>
        <DefaultInput value={email} onChange={handleChangeEmail} />
      </FormControl>
      <div className="subscription-form-btn-container">
        <DefaultButton
          backgroundColor="white"
          hover="lightgrey"
          labelHeight="15px"
        >
          <p className="subscription-form-btn-text">
            {localizationData["Send"]}
          </p>
          <ArrowForwardIosIcon style={{ width: "15px", paddingLeft: "10px" }} />
        </DefaultButton>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  localizationData: state.appLanguage.localizationData
});

export default connect(mapStateToProps)(SubscriptionForm);
