import content from "./block-content";
import icon from "./icon.png";

export default function cartTableBlock({ editor }) {
  const blockManager = editor.BlockManager;

  blockManager.add("cart-table", {
    label: `
      <img src="${icon}" alt="cart table block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Cart Table
      </div>
    `,
    content,
    category: "B24Online Cart"
  });
}
