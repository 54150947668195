import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import {
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "../../../layout";

import {
  DefaultButton,
  Separator,
  InputGroup,
  EditorGroup,
  DefaultDropdown,
  DefaultCheckbox
} from "../../../components";

import {
  getPageById,
  createPage,
  updatePage,
  deletePage,
  getPageTypes,
  getSiteTypes,
  clearPagesRequest
} from "../../store/API/pages/pages.action";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-static-page-edit.styles.scss";

const MyBusinessPageEdit = () => {
  const pageTypes = useSelector(state => state.pagesReducer.pageTypes);
  const siteTypes = useSelector(state => state.pagesReducer.siteTypes);
  const currentPage = useSelector(state => state.pagesReducer.pageById);
  const pagesRequestStatus = useSelector(state => state.pagesReducer.pagesRequestStatus);

  const dispatch = useDispatch();


  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isOnTop, setIsOnTop] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [siteType, setSiteType] = useState("b2b");
  const [slug, setSlug] = useState("");

  const [disabledButton, setDisabledButton] = useState(true);

  const { pageId } = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPageTypes());
    dispatch(getSiteTypes());
  }, []);

  useEffect(() => {
    if (pageId !== "create") {
      dispatch(getPageById(pageId));
    }
  }, [dispatch, pageId]);

  useEffect(() => {
    if (pageId !== "create") {
      const {
        title,
        content,
        slug,
        is_on_top,
        site_type,
        page_type,
      } = currentPage;
      setTitle(title);
      setContent(content);
      setIsOnTop(is_on_top);
      setPageType(page_type);
      setSiteType(site_type);
      setSlug(slug);
    }
  }, [pageId, currentPage]);

  useEffect(() => {
    if (isOnTop) {
      setPageType(pageTypes[0]?.unit);
    }
  }, [isOnTop, setIsOnTop]);

  useEffect(() => {
    setDisabledButton(
      title === "" || slug === "" || content === "" || pageType === null
    );
  }, [title, content, slug, pageType]);

  useEffect(() => {
    if (pagesRequestStatus === 200 || pagesRequestStatus === 201) {
      history.push("/my-business/pages");
    }
  }, [history, pagesRequestStatus]);

  useEffect(
    () => () => {
      dispatch(clearPagesRequest());
    },
    [dispatch]
  );

  const editNew = () => {
    if (pageId === "create") {
      dispatch(
        createPage(
          title,
          slug,
          content,
          isOnTop,
          siteType,
          pageType
        )
      );
    } else {
      dispatch(
        updatePage(
          pageId,
          title,
          slug,
          content,
          isOnTop,
          siteType,
          pageType
        )
      );
    }
  };

  return (
    <div className="my-business-static-page-edit">
      <ParentWrapper>
        <SectionTextHeader text={t("Basic Info")} />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          label={t("Title")}
          name="title"
          placeholder={t("Title")}
          onChange={e => setTitle(e.target.value)}
          value={title}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          label={t("Slug")}
          name="slug"
          placeholder={t("Slug")}
          onChange={e => setSlug(e.target.value)}
          value={slug}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          label={t("Content")}
          name="content"
          onChange={e => setContent(e.target.getContent())}
          required={true}
          value={content}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultCheckbox
          title={t("Show on top")}
          checkboxValue={isOnTop}
          setCheckboxChange={() => {
            setIsOnTop(!isOnTop);
          }}
        />
      </ParentWrapper>
      {!isOnTop && (
        <ParentWrapper>
          <DefaultDropdown
            label={t("Page type")}
            items={
              pageTypes && pageTypes.map(type => ({
                id: type.unit,
                name: type.label
              }))
            }
            returnData={setPageType}
            customTitle={
              pageId !== "create" &&
              currentPage.page_type
            }
            required={true}
          />
        </ParentWrapper>
      )}
      <ParentWrapper>
        <DefaultDropdown
          label={t("Site type")}
          items={
            siteTypes && siteTypes.map(type => ({
              id: type.unit,
              name: type.label
            }))
          }
          returnData={setSiteType}
          customTitle={
            pageId !== "create" ? currentPage.site_type : "b2b"
          }
          required={true}
        />
      </ParentWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        {pageId !== "create" && (
          <div>
            <DefaultButton
              onClick={() => dispatch(deletePage(pageId))}
              {...buttonCancel}
              width="100%"
            >
              {t("Delete")}
            </DefaultButton>
          </div>
        )}
        <div>
          <DefaultButton
            onClick={editNew}
            {...buttonOk}
            width="100%"
            disabled={disabledButton}
          >
            {t("Save")}
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.push("/my-business/pages")}
          >
            {t("Cancel")}
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

export default MyBusinessPageEdit;
