import {
  axiosInstance,
  axiosNoTokenInstance
} from "../../../axios/axios.config";
import { ResumesActionTypes } from "./resumes.types";

export const getResumes = pageNumber => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/organization/resumes/?page=${pageNumber}`)
    .then(resumes => {
      dispatch({
        type: ResumesActionTypes.GET_RESUMES,
        payload: resumes.data
      });
    })
    .catch(error => console.log(error));
};

export const getResumesByCurrentUser = (search = "", page = 1) => dispatch => {
  dispatch({ type: ResumesActionTypes.GET_RESUMES_BY_ME_PENDING });

  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(user =>
      axiosInstance
        .get(
          `/api/v1/department/resume/?user_id=${user.data.id}&search=${search}&page=${page}`
        )
        .then(resumes => {
          dispatch({
            type: ResumesActionTypes.GET_RESUMES_BY_ME_SUCCESS,
            payload: {
              resumes: resumes.data.results,
              count: resumes.data.count
            }
          });
        })
    )
    .catch(error => console.log(error));
};

export const searchResumesByCurrentUser = (
  searchQuery,
  page = 1
) => dispatch => {
  dispatch({ type: ResumesActionTypes.GET_RESUMES_BY_ME_PENDING });

  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(user =>
      axiosInstance
        .get(
          `/api/v1/department/resume/?user_id=${user.data.id}&search=${searchQuery}&page=${page}`
        )
        .then(resume => {
          dispatch({
            type: ResumesActionTypes.GET_RESUMES_BY_ME_SUCCESS,
            payload: resume.data
          });
        })
    )
    .catch(error => console.log(error));
};

export const createResume = (
  resumeTitle,
  lastName,
  firstName,
  address,
  phoneNumber,
  nationality,
  maritalStatus,
  study_form,
  faculty,
  profession,
  startStuding,
  endStuding,
  company1,
  position1,
  startWorking1,
  endWorking1,
  company2,
  position2,
  startWorking2,
  endWorking2,
  company3,
  position3,
  startWorking3,
  endWorking3,
  languageSkill,
  languageSkillRate,
  computerSkill,
  computerSkillRate,
  salary,
  additionally,
  currentUserId,
  new_education,
  new_experience,
  new_language_skill,
  new_computer_skill
) => dispatch => {
  axiosInstance
    .post("/api/v1/department/resume/", {
      title: resumeTitle,
      first_name: firstName,
      last_name: lastName,
      telephone_number: phoneNumber,
      nationality,
      marital_status: maritalStatus,
      address,
      study_form,
      faculty,
      profession,
      study_start_date: startStuding,
      study_end_date: endStuding,
      company_exp_1: company1,
      position_exp_1: position1,
      start_date_exp_1: startWorking1,
      end_date_exp_1: endWorking1,
      company_exp_2: company2,
      position_exp_2: position2,
      start_date_exp_2: startWorking2,
      end_date_exp_2: endWorking2,
      company_exp_3: company3,
      position_exp_3: position3,
      start_date_exp_3: startWorking3,
      end_date_exp_3: endWorking3,
      language_skill: languageSkill,
      computer_skill: computerSkill,
      salary,
      additional_information: additionally,
      user: currentUserId,
      new_education,
      new_experience,
      new_language_skill,
      new_computer_skill
    })
    .then(createdResume => {
      dispatch({
        type: ResumesActionTypes.CREATE_RESUME,
        payload: {
          createdResume: createdResume.data,
          requestStatus: createdResume.status
        }
      });
    })
    .catch(error => console.log(error));
};

export const updateResume = (
  resumeId,
  resumeTitle,
  lastName,
  firstName,
  address,
  phoneNumber,
  nationality,
  maritalStatus,
  study_form,
  faculty,
  profession,
  startStuding,
  endStuding,
  company1,
  position1,
  startWorking1,
  endWorking1,
  company2,
  position2,
  startWorking2,
  endWorking2,
  company3,
  position3,
  startWorking3,
  endWorking3,
  languageSkill,
  languageSkillRate,
  computerSkill,
  computerSkillRate,
  salary,
  additionally,
  currentUserId,
  new_education,
  new_experience,
  new_language_skill,
  new_computer_skill
) => dispatch => {
  axiosInstance
    .put(`/api/v1/department/resume/${resumeId}/`, {
      title: resumeTitle,
      first_name: firstName,
      last_name: lastName,
      telephone_number: phoneNumber,
      nationality,
      marital_status: maritalStatus,
      address,
      study_form,
      faculty,
      profession,
      study_start_date: startStuding,
      study_end_date: endStuding,
      company_exp_1: company1,
      position_exp_1: position1,
      start_date_exp_1: startWorking1,
      end_date_exp_1: endWorking1,
      company_exp_2: company2,
      position_exp_2: position2,
      start_date_exp_2: startWorking2,
      end_date_exp_2: endWorking2,
      company_exp_3: company3,
      position_exp_3: position3,
      start_date_exp_3: startWorking3,
      end_date_exp_3: endWorking3,
      language_skill: languageSkill,
      computer_skill: computerSkill,
      salary,
      additional_information: additionally,
      user: currentUserId,
      new_education,
      new_experience,
      new_language_skill,
      new_computer_skill
    })
    .then(updatedResume => {
      dispatch({
        type: ResumesActionTypes.UPDATE_RESUME,
        payload: {
          updatedResume: updatedResume.data,
          requestStatus: updatedResume.status
        }
      });
    })
    .catch(error => console.log(error));
};

export const applyToJobWithResumeId = (jobId, resume) => dispatch => {
  dispatch({
    type: ResumesActionTypes.APPLY_TO_JOB_WITH_RESUME_ID_PENDING
  });
  axiosInstance
    .patch(`/api/v1/department/jobs/${jobId}/apply/`, { resume })
    .then(applyResume => {
      dispatch({
        type: ResumesActionTypes.APPLY_TO_JOB_WITH_RESUME_ID_SUCCESS,
        payload: { applyResume, status: applyResume.status }
      });
    })
    .catch(err => {
      dispatch({
        type: ResumesActionTypes.APPLY_TO_JOB_WITH_RESUME_ID_ERROR,
        payload: err
      });
    });
};

export const deleteResume = resumeId => dispatch => {
  axiosInstance
    .delete(`/api/v1/department/resume/${resumeId}/`)
    .then(deletedResume => {
      dispatch({
        type: ResumesActionTypes.DELETE_RESUME,
        payload: deletedResume
      });
    })
    .then(() => dispatch(getResumesByCurrentUser()))
    .catch(error => console.log(error));
};

export const getMaritalStatus = () => dispatch => {
  axiosNoTokenInstance
    .get("api/v1/department/resume/marital_status/")
    .then(maritalStatus => {
      dispatch({
        type: ResumesActionTypes.GET_MARITAL_STATUS,
        payload: maritalStatus.data
      });
    })
    .catch(error => console.log(error));
};

export const getResumeById = resumeId => dispatch => {
  dispatch({ type: ResumesActionTypes.GET_RESUME_BY_ID_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/department/resume/${resumeId}`)
    .then(singleResume => {
      dispatch({
        type: ResumesActionTypes.GET_RESUME_BY_ID_SUCCESS,
        payload: singleResume.data
      });
    })
    .catch(error => console.log(error));
};

export const getStudyFormTypes = () => dispatch => {
  dispatch({
    type: ResumesActionTypes.GET_STUDY_FORM_TYPES_PENDING
  });

  axiosInstance
    .get(`/api/v1/department/resume/study_forms/`)
    .then(studyForms =>
      dispatch({
        type: ResumesActionTypes.GET_STUDY_FORM_TYPES_SUCCESS,
        payload: studyForms.data
      })
    )
    .catch(err => {
      dispatch({
        type: ResumesActionTypes.GET_STUDY_FORM_TYPES_SUCCESS,
        payload: err
      });
    });
};

export const clearResumeRequest = () => dispatch => {
  dispatch({
    type: ResumesActionTypes.CLEAR_RESUME_REQUEST,
    payload: ""
  });
};
