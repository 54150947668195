import React from "react";
import { useTranslation } from "react-i18next";
import "./product-description-about.styles.scss";

const ProductDescriptionAbout = ({ productDescription }) => {
  const { t } = useTranslation();
  return (
    <div className="product-description-about">
      <h3>{t("Description")}</h3>
      <div className="product-description-about__about">
        {productDescription}
      </div>
    </div>
  );
};

export default ProductDescriptionAbout;
