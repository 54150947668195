import icon from "./icon.png";

export default function productsCategoriesBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .products-categories {}

  .products-categories.products-categories--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .products-categories.products-categories--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .products-categories__list {
    list-style: none;
    padding: 0;
  }

  .products-categories__search {
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000000;
    font-size: 15px;
    border: 1px solid #b9b9ba;
    padding: 10px 8px;
    border-radius: 3px;
    width: 100%;
  }

  .products-categories__title {
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 21px;
  }

  .products-categories__item {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 5px;
    border-radius: 2px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease;
  }

  .products-categories__item.products-categories__item--active {
    background: rgba(0, 0, 0, 0.08);
  }
  
  .products-categories__item + .products-categories__item {
    margin: 6px 0 0;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="products-categories-wrapper">
    <input class="products-categories__search" type="text" placeholder="Search...">
    <div class="products-categories">
        <ul class="products-categories__list">
          <li class="products-categories__item products-categories__item--active products-categories__item--primary " data-id="163583">
            Electronics
          </li>
          <li class="products-categories__list-wrapper">
            <ul class="products-categories__list products-categories__list--subcategories">
    
              <li class="products-categories__item products-categories__item--active products-categories__item--subcategory " data-parent-index="0"
                data-id="86648">
                Home Appliances
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="0"
                data-id="162756">
                Safe &amp; Smart Home
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="0"
                data-id="163304">
                Electronics Accessories
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="0"
                data-id="163305">
                Camera &amp; Photo
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="0"
                data-id="163734">
                Air Conditioning
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="0"
                data-id="163974">
                TV, Audio &amp; Home Theater
              </li>
    
            </ul>
          </li>
        </ul>
    
        <ul class="products-categories__list">
          <li class="products-categories__item products-categories__item--primary " data-id="163584">
            Computers &amp; Accessories
          </li>
          <li class="products-categories__list-wrapper">
            <ul class="products-categories__list products-categories__list--subcategories">
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="1"
                data-id="162712">
                Desktops
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="1"
                data-id="163297">
                Laptops
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="1"
                data-id="163931">
                Monitors
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="1"
                data-id="163932">
                Computer Accessories
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="1"
                data-id="163935">
                Printers &amp; Scanners
              </li>
    
            </ul>
          </li>
        </ul>
    
        <ul class="products-categories__list">
          <li class="products-categories__item products-categories__item--primary " data-id="163585">
            Clothing, Shoes &amp; Accessories
          </li>
          <li class="products-categories__list-wrapper">
            <ul class="products-categories__list products-categories__list--subcategories">
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="162912">
                Shoes
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163056">
                Bags
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163248">
                Women's Clothing
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163263">
                Men's Clothing
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163410">
                Accessories
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163879">
                Girls' Clothing
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163880">
                Boys' Clothing
              </li>
    
              <li class="products-categories__item products-categories__item--subcategory " data-parent-index="2"
                data-id="163881">
                Babies' Clothing
              </li>
    
            </ul>
          </li>
        </ul>
    </div>
  </div>
  `;

  blockManager.add("products-categories", {
    label: `
      <img src="${icon}" alt="Products categories block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Categories
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
