import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";

import IconButton from "@material-ui/core/IconButton";

import "./default-icon-button.styles.scss";

const DefaultIconButton = ({ children }) => {
  const useStyles = makeStyles({
    root: {}
  });
  const classes = useStyles();
  return (
    <IconButton disableRipple classes={{ root: classes.root }}>
      {children}
    </IconButton>
  );
};

export default DefaultIconButton;
