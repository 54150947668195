import React, { useState } from "react";
import { Link } from "react-router-dom";
import DefaultButton from "../default-button/default-button.component";
import DefaultIconButton from "../default-icon-button/default-icon-button.component";

import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import { colorPalette } from "../../styles/colorPalette";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import "./builder-sites-list-item.styles.scss";

// const useStyles = makeStyles(theme => ({
//   link: {
//     display: "inline-block",
//     color: "inherit",
//     transition: "all 300ms ease",
//     cursor: "pointer",
//
//     "&:hover": {
//       color: colorPalette.colorPrimary.main,
//       "text-decoration": "none"
//     }
//   },
//   editIcon: {
//     fontSize: "13px",
//     margin: "0 3px",
//     padding: "0",
//     transition: "all 300ms ease",
//     cursor: "pointer",
//
//     "& svg": {
//       fontSize: "1.2rem"
//     },
//
//     "& button": {
//       transition: "all 300ms ease"
//     },
//
//     "&:hover button": {
//       background: "transparent",
//       color: colorPalette.colorPrimary.main
//     }
//   },
//   paper: {
//     border: "1px solid",
//     padding: theme.spacing(1),
//     backgroundColor: theme.palette.background.paper
//   }
// }));

const Dialog = withStyles(theme => ({
  paper: {
    borderRadius: 4
  }
}))(MuiDialog);

const DialogTitle = withStyles(theme => ({
  root: {
    padding: "12px 24px",
    background: "rgb(250, 250, 250)"
  }
}))(MuiDialogTitle);

const BuilderSitesListItem = ({
  site,
  builderPath,
  detailsPath,
  onDeleteSite
}) => {
  const [open, setOpen] = useState(false);

  const changeDialogVisibility = visible => setOpen(visible);

  const handleDelete = siteId => e => {
    onDeleteSite(siteId)(e);
    changeDialogVisibility(false);
  };

  return (
    <div className="builder-site-item">
      <div className="builder-site-item__web-data">
        <div className="web-data__title">
          <a href={`${builderPath}${site.id}`}>{site.title}</a>
        </div>
        <div className="web-data__link">
          <a href={site.site.domain}>{site.site.domain}</a>
        </div>
      </div>
      <div className="builder-site-item__buttons">
        <div className="button-container">
          <Link to={`${detailsPath}${site.id}`}>
            <DefaultIconButton>
              <EditIcon />
            </DefaultIconButton>
          </Link>
        </div>
        <div className="button-container">
          <DefaultButton
            color={colorPalette.colorPrimary.main}
            hoverColor="white"
            fontWeight="700"
            border={`1px solid ${colorPalette.colorPrimary.main}`}
            hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
            hover={colorPalette.colorPrimary.main}
            padding="4px 19px"
            width="100%"
            transition="all 300ms ease"
            margin="0 10px 0 0"
          >
            Convert to App
          </DefaultButton>
        </div>
        <div className="button-container">
          <DefaultButton
            color="#f24941"
            hoverColor="white"
            fontWeight="700"
            border="1px solid #f24941"
            hoverBorder="1px solid #f24941"
            hover="#f24941"
            padding="4px 19px"
            width="100%"
            transition="all 300ms ease"
            onClick={() => changeDialogVisibility(true)}
          >
            Delete
          </DefaultButton>

          <Dialog
            open={open}
            onClose={() => changeDialogVisibility(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm site deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the site «{site.title}» ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <DefaultButton
                color="#ccc"
                hoverColor={colorPalette.colorPrimary.main}
                fontWeight="700"
                border="1px solid #ccc"
                hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
                hover="white"
                padding="4px 19px"
                transition="all 300ms ease"
                onClick={() => changeDialogVisibility(false)}
              >
                Cancel
              </DefaultButton>
              <DefaultButton
                autoFocus
                color="#ccc"
                hoverColor="#f24941"
                fontWeight="700"
                border="1px solid #ccc"
                hoverBorder="1px solid #f24941"
                hover="white"
                padding="4px 19px"
                transition="all 300ms ease"
                onClick={handleDelete(site.id)}
              >
                Delete
              </DefaultButton>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>

    // <ListItem
    //   key={site.title}
    //   ContainerProps={{
    //     className: "builder-sites-list__item"
    //   }}
    // >
    //   <ListItemText
    //     primary={
    //       <Box fontSize="h6.fontSize">
    //         <MuiLink
    //           classes={{ root: classes.link }}
    //           href={`${builderPath}${site.id}`}
    //         >
    //           {site.title}
    //         </MuiLink>
    //       </Box>
    //     }
    //     secondary={
    //       <Box fontSize="span.fontSize">
    //         <MuiLink classes={{ root: classes.link }} href={site.site.domain}>
    //           {site.site.domain}
    //         </MuiLink>
    //       </Box>
    //     }
    //   />
    //   <ListItemSecondaryAction>
    //     <Box display="flex" alignItems="center">
    //       <Box classes={{ root: classes.editIcon }}>
    //         <Link to={`${detailsPath}${site.id}`}>
    //           <DefaultIconButton>
    //             <EditIcon />
    //           </DefaultIconButton>
    //         </Link>
    //       </Box>

    //       <DefaultButton
    //         color={colorPalette.colorPrimary.main}
    //         hoverColor="white"
    //         fontWeight="700"
    //         border={`1px solid ${colorPalette.colorPrimary.main}`}
    //         hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
    //         hover={colorPalette.colorPrimary.main}
    //         padding="4px 19px"
    //         width="100%"
    //         transition="all 300ms ease"
    //         margin="0 10px 0 0"
    //       >
    //         Convert to App
    //       </DefaultButton>

    //       <div>
    //         <DefaultButton
    //           color="#f24941"
    //           hoverColor="white"
    //           fontWeight="700"
    //           border="1px solid #f24941"
    //           hoverBorder="1px solid #f24941"
    //           hover="#f24941"
    //           padding="4px 19px"
    //           width="100%"
    //           transition="all 300ms ease"
    //           onClick={() => changeDialogVisibility(true)}
    //         >
    //           Delete
    //         </DefaultButton>
    //       </div>
    //       <Dialog
    //         open={open}
    //         onClose={() => changeDialogVisibility(false)}
    //         aria-labelledby="alert-dialog-title"
    //         aria-describedby="alert-dialog-description"
    //       >
    //         <DialogTitle id="alert-dialog-title">
    //           {"Confirm site deletion"}
    //         </DialogTitle>
    //         <DialogContent>
    //           <DialogContentText id="alert-dialog-description">
    //             Are you sure you want to delete the site «{site.title}» ?
    //           </DialogContentText>
    //         </DialogContent>
    //         <DialogActions>
    //           <DefaultButton
    //             color="#ccc"
    //             hoverColor={colorPalette.colorPrimary.main}
    //             fontWeight="700"
    //             border="1px solid #ccc"
    //             hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
    //             hover="white"
    //             padding="4px 19px"
    //             transition="all 300ms ease"
    //             onClick={() => changeDialogVisibility(false)}
    //           >
    //             Cancel
    //           </DefaultButton>
    //           <DefaultButton
    //             autoFocus
    //             color="#ccc"
    //             hoverColor="#f24941"
    //             fontWeight="700"
    //             border="1px solid #ccc"
    //             hoverBorder="1px solid #f24941"
    //             hover="white"
    //             padding="4px 19px"
    //             transition="all 300ms ease"
    //             onClick={handleDelete(site.id)}
    //           >
    //             Delete
    //           </DefaultButton>
    //         </DialogActions>
    //       </Dialog>
    //     </Box>
    //   </ListItemSecondaryAction>
    // </ListItem>
  );
};

export default BuilderSitesListItem;
