import icon from "./icon.png";

export default function singleProductWithPaginationBlock({ editor, api }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style> 
    .product-single-wrapper {
      display: flex;
      flex-flow: row wrap;
      min-height: 34rem;
      width: 22rem;
    }

    .product-single-wrapper.product-single-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }
  
    .product-single-wrapper.product-single-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  

    .ps-product {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 20px;
    }
  
    @media screen and (max-width: 992px) {
      .ps-product {
        flex-basis: 100%;
      }
    }
  
    .ps-product__image {
      width: 100%;
      height: 150px;
      object-fit: scale-down;
    }
  
    .ps-product__title {
      color: rgb(38, 38, 38);
      font-size: 30px;
      font-weight: 700;
      margin: 16px 0;
    }
  
    .ps-product__link {
      color: inherit;
      text-decoration-color: currentColor;
    }
  
    .ps-product__link:hover {
      color: inherit;
      text-decoration-color: currentColor;
    }
  
    .ps-product__divider {
      width: 30%;
      height: 7px;
      background-color: #aeaeae;  
    }
  
    .ps-product__description {
      width: 100%;
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      word-break: break-word;
    }
  
    .ps-product__meta {}
  
    .ps-product__meta--cost {
      margin: auto 0 15px;
    }
  
    .ps-product__cost-title {
      color: rgb(38, 38, 38);
      font-size: 18px;
      font-weight: 700;
    }
  
    .ps-product__cost {
      color: #262626;
      font-size: 18px;
      font-weight: 700;
      margin: auto 0 8px;
    }
  
    .ps-product__cost.ps-product__cost--discount {
      text-decoration-color: #676262;
      text-decoration-line: line-through;
      text-decoration-style: solid;
      font-size: 15px;
    }
  
    .ps-product__discount {
      color: rgb(38, 38, 38);
      font-size: 18px;
      font-weight: 700;
      padding: 0 0 0 3px;
    }
  
    .ps-product__currency {
      color: rgb(38, 38, 38);
      font-size: 18px;
      font-weight: 700;
    }
  
    .ps-product__discount-percent {
      border: 1px solid black;
      border-radius: 3px;
      padding: 2px 4px;
     }
  
    .ps-product__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  
    .ps-product__button {
      background-color: #aeaeae;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 12px 46px;
      border: none;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }
  
    .ps-product__button:hover {
      background-color: #8c8c8c;
    }
  
    .ps-product__button--disabled {
      background-color: inherit !important;
      color: inherit !important;
      cursor: not-allowed;
    }
  
    .ps-product__wish {
      color: #737373;
      cursor: pointer;
      user-select: none;
    }
  
    .ps-product__wish--disabled {
      cursor: not-allowed;
    }
  
    .ps-coupon {
      width: 100%;
    }
  
    .ps-coupon__message {
      display: block;
      font-size: 1.32em;
    }
  
    .ps-coupon__cost {
      font-size: 1.1em;
      font-weight: 700;
    }
  
    .ps-coupon__currency {
      font-size: 1.1em;
      font-weight: 700;
    }
  
    .ps-coupon__discount-percent {
      padding: 2px 4px;
      border: 1px solid black;
      border-radius: 3px;
    }
  
    .ps-coupon__grid {
      display: flex;
      flex-flow: row;
      justify-content: space-around;
      align-items: center;
    }
    
    .ps-coupon__item {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      font-size: 1.3em;
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="product-single-wrapper">
    <div class="ps-product">
      <img class="ps-product__image" src="null">
      <h4 class="ps-product__title">
        <a class="ps-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="ps-product__divider"></span>
      <div class="ps-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="ps-product__meta ps-product__meta--cost">
        <span class="ps-product__cost-title">Cost:</span>
        <span class="ps-product__cost ps-product__cost--discount">100.00</span>
        <span class="ps-product__discount">50.00</span>
        <span class="ps-product__currency">$</span>
        <span class="ps-product__discount-percent">-50.00%</span>
      </p>
      
      <div class="ps-coupon">
        <div class="ps-coupon__header">
          <span class="ps-coupon__cost">
            200.00
          </span>
          <span class="ps-coupon__currency">
            $
          </span>
          <span class="ps-coupon__percent-discount">
            -80%
          </span>
          <span class="ps-coupon__message">
            Hurry up! Coupon Ends in
          </span>
        </div>
        <div class="ps-coupon__grid" style="visibility: none;">
          <span class="ps-coupon__item">
            <span class="ps-coupon__counter ps-coupon__counter--days">
              000
            </span>
            <span class="ps-coupon__label ps-coupon__label--days">
              Days
            </span>
          </span>
          <span class="ps-coupon__delimiter">
            :
          </span>
          <span class="ps-coupon__item">
            <span class="ps-coupon__counter ps-coupon__counter--hours">
              00
            </span>
            <span class="ps-coupon__label ps-coupon__label--hours">
              Hours
            </span>
          </span>
          <span class="ps-coupon__delimiter">
            :
          </span>
          <span class="ps-coupon__item">
            <span class="ps-coupon__counter ps-coupon__counter--minutes">
              00
            </span>
            <span class="ps-coupon__label ps-coupon__label--minutes">
              Minutes
            </span>
          </span>
          <span class="ps-coupon__delimiter">
            :
          </span>
          <span class="ps-coupon__item">
            <span class="ps-coupon__counter ps-coupon__counter--seconds">
              00
            </span>
            <span class="ps-coupon__label ps-coupon__label--seconds">
              Seconds
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  `;

  blockManager.add("product-single", {
    label: `
      <img src="${icon}" alt="product block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Product Single
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
