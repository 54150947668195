import "./gjs-translation-manager-trait.scss";

export default () =>
  function gjsTranslationManagerTrait(editor) {
    let state = {
      form: {
        string: "",
        language: "",
        translation: ""
      },
      translations: []
    };

    const preloader = ({ remove = false }) => {
      // Preloader
      const modalContentEl = document.querySelector(".gjs-mdl-content");
      modalContentEl &&
        modalContentEl.classList.add("gjs-mdl-content--preloader");
      // Remove preloader
      modalContentEl &&
        remove &&
        modalContentEl.classList.remove("gjs-mdl-content--preloader");
    };

    const render = async props => {
      const { modal, setTranslations, strings, languages } = props;
      // Set prelodaer
      preloader({ remove: false });
      // Set translations to state
      // state = {
      //   ...state,
      //   translations
      // };
      // Set modal content
      await modal.setContent(`
        <div class="gjs-translation-manager-modal__content">
          <div id="gjs-sm-background" class="gjs-translation-manager-modal__group gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
            <div class="gjs-sm-label">
                <span class="gjs-sm-icon " title="">
                    String
                </span>
                <b class="gjs-sm-clear" data-clear-style="" style="display: none;">⨯</b>
            </div>
            <div class="gjs-fields">
                <div class="gjs-field gjs-field-select gjs-translation-manager-modal__field">
                  <select id="translation_string">
                    <option value="">Select string</option>
                    ${strings
                      .map(
                        string => `
                          <option value="${string}">${string}</option>
                        `
                      )
                      .join("")}
                  </select>
                </div>
            </div>
          </div>

          <div id="gjs-sm-background" class="gjs-translation-manager-modal__group gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
            <div class="gjs-sm-label">
                <span class="gjs-sm-icon " title="">
                    Language
                </span>
                <b class="gjs-sm-clear" data-clear-style="" style="display: none;">⨯</b>
            </div>
            <div class="gjs-fields">
                <div class="gjs-field gjs-field-select gjs-translation-manager-modal__field">
                  <select id="translation_language">
                    <option value="">Select language</option>
                    ${languages
                      .map(
                        language => `
                          <option value="${language}">${language}</option>
                        `
                      )
                      .join("")}
                  </select>
                </div>
            </div>
          </div>

          <div id="gjs-sm-background" class="gjs-translation-manager-modal__group gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
            <div class="gjs-sm-label">
                <span class="gjs-sm-icon " title="">
                    Translation
                </span>
                <b class="gjs-sm-clear" data-clear-style="" style="display: none;">⨯</b>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-translation-manager-modal__field">
                <div class="gjs-input-holder">
                  <input id="string_translation" type="text" placeholder="String translation..." class="gjs-translation-manager-modal__input">
                </div>
              </div>
            </div>
          </div>

          <div id="gjs-sm-background" class="gjs-translation-manager-modal__group gjs-translation-manager-modal__group--button gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
            <button id="update_translations_button" type="button" class="gjs-translation-manager-modal__button gjs-two-color" data-id="">
              Update translations
            </button>
          </div>
        </div>
        <div class="gjs-mdl-title">
          List of translations
        </div>
          <div class="gjs-translation-manager-list">
            ${state.translations
              .map(
                ({ string, language, translation }) => `
                <div class="gjs-translation-manager-list__item">
                    <h5 class="gjs-translation-manager-list__item-text">
                      ${string}
                    </h5>
                    <h5 class="gjs-translation-manager-list__item-text">
                      ${translation}
                      <span class="gjs-translation-manager-list__item-capture">
                      [language:${language}]
                      </span>
                    </h5>
                    <div class="gls-translation-manager-list__item-actions">
                      <button
                        type="button"
                        id="delete_translation_button"
                        class="gjs-translation-manager-modal__button gjs-translation-manager-modal__button-sm gjs-two-color"
                        data-string="${string}"
                        data-language="${language}"
                      >
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                </div>
                `
              )
              .join("")}
          </div>
        </div>
      </div>
      <div class="gjs-translation-manager-modal__footer">
          
      </div>
        `);

      // Delete buttons handler
      const deleteTranslationsButtonEls = document.querySelectorAll(
        "#delete_translation_button"
      );
      deleteTranslationsButtonEls &&
        [...deleteTranslationsButtonEls].map(deleteTranslationButtonEl =>
          deleteTranslationButtonEl.addEventListener("click", e => {
            // Get target string and language
            const target =
              e.target.parentNode.nodeName === "BUTTON"
                ? e.target.parentNode
                : e.target;
            const string = target.dataset.string;
            const language = target.dataset.language;
            // Remove translation
            state = {
              ...state,
              translations: [
                ...state.translations.filter(translation =>
                  translation.string === string
                    ? translation.language !== language
                    : true
                ),
                ...(language === "EN"
                  ? [
                      {
                        string,
                        language,
                        translation: string
                      }
                    ]
                  : [])
              ]
            };
            setTranslations(state.translations);
            render(props);
          })
        );

      const onFormChange = ({ name }) => e => {
        state = {
          ...state,
          form: {
            ...state.form,
            [name]: e.target.value
          }
        };
      };

      const onFormSubmit = () => {
        // Get form data
        const data = state.form;
        // Validate form
        if (!data.string || !data.language || !data.translation) {
          return;
        }
        // Set translation
        state = {
          ...state,
          form: {},
          translations: [
            ...state.translations.filter(translation =>
              translation.string === data.string
                ? translation.language !== data.language
                : true
            ),
            data
          ]
        };
        setTranslations(state.translations);
        // Render
        render(props);
      };

      document
        .getElementById("translation_string")
        .addEventListener("change", onFormChange({ name: "string" }));

      document
        .getElementById("translation_language")
        .addEventListener("change", onFormChange({ name: "language" }));

      document
        .getElementById("string_translation")
        .addEventListener("change", onFormChange({ name: "translation" }));

      document
        .getElementById("update_translations_button")
        .addEventListener("click", onFormSubmit);

      // Remove prelodaer
      preloader({ remove: true });
    };

    editor.TraitManager.addType("translation-manager", {
      createInput({ component, trait }) {
        const el = document.createElement("div");
        // Create element input
        el.innerHTML = `
          <button type="button" class="gjs-translation-manager-button gjs-two-color">
            Edit
          </button>
        `;
        // Get name
        const name = trait.attributes.name;
        // Get value
        const getTraitValue = name => this.getComponent().getAttributes()[name];
        // Default fallback translation
        const fallback = trait.attributes.strings.map(string => ({
          string,
          language: "EN",
          translation: string
        }));
        // Set translations to element
        this.setTranslations(
          !!getTraitValue(name) ? JSON.parse(getTraitValue(name)) : fallback
        );
        // Opens modal window with translations
        const preRender = () => {
          // Get modal
          this.modal = editor.Modal;
          // Set modal title
          this.modal.setTitle(trait.attributes.modalTitle);
          // Set state
          state = {
            form: {
              string: "",
              language: "",
              translation: ""
            },
            locale: {},
            translations: !!getTraitValue(name)
              ? JSON.parse(getTraitValue(name))
              : fallback
          };
          // Set translations to element
          this.setTranslations(state.translations);
          // Start render modal content
          render({
            modal: this.modal,
            setTranslations: this.setTranslations.bind(this),
            strings: trait.attributes.strings,
            languages: ["EN", "RU", "AM", "BG", "UK", "HE", "AR", "ZH", "ES"]
          });
          // Open modal
          this.modal.open();
        };
        // Add click handler
        el.addEventListener("click", preRender);
        // Add to component
        component.translationsPreRender = preRender;
        // Return element
        return el;
      },
      setTranslations(translations, close = false) {
        // Set translations
        this.translations = translations;
        // Set close
        this.close = close;
        // Use onChange to trigger onEvent
        this.onChange();
      },
      onEvent({ component, trait, elInput }) {
        // Set component attribute
        component.addAttributes({
          [trait.attributes.name]: JSON.stringify(this.translations)
        });
        // Close translations modal
        this.close && this.modal.close();
      }
    });
  };
