import icon from "./icon.png";

export default function orderCheckBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .order-check {
    min-height: 200px;
  }
  .order-check__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="order-check-wrapper">
    <div class="order-check">
      <div class="order-check__title">Check transaction</div>
    </div>
  </div>
  `;

  blockManager.add("order-check", {
    label: `
      <img src="${icon}" alt="Order check block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Order Check
      </div>
    `,
    content,
    category: "B24Online Cart"
  });
}
