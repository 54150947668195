import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, ButtonGroup } from "@material-ui/core";
import { buttonOk } from "~/styles/buttons-styles";
import { colorPalette } from "~/styles/colorPalette";

import { DefaultButton, DefaultDropdown, InputGroup, EditorGroup } from "@core/components";
import { ParentWrapper } from "@core/layout";
import {
  createCompanySite,
  getDomainTypes
} from "@core/redux/store/siteBuilder/siteBuilder.action";
import { useTranslation } from "react-i18next";

import BCard1 from "@core/assets/BCard1.png";
import BCard2 from "@core/assets/BCard2.png";
import BCard3 from "@core/assets/BCard3.png";
import BCard4 from "@core/assets/BCard4.png";
import BCard5 from "@core/assets/BCard5.png";
import BCard6 from "@core/assets/BCard6.png";

import "./site-builder.styles.scss";

const SiteBuilderForm = ({ companyId }) => {
  const [domain, setDomain] = useState("");
  const [logo, setLogo] = useState("");
  const [settings, setSettings] = useState({});
  const [footer, setFooter] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const domainTypes = useSelector(
    state => state.siteBuilderReducer.domainTypes
  );
  const createdSite = useSelector(state => state.siteBuilderReducer.createSiteCompleted);

  const { t } = useTranslation();

  const templates = [
    { title: "Default, with your logo", img: BCard1 },
    { title: "Aqua", img: BCard2 },
    { title: "Dark Grey", img: BCard3 },
    { title: "Clementine", img: BCard4 },
    { title: "Luna", img: BCard5 },
    { title: "Windows X", img: BCard6 }
  ];

  useEffect(() => {
    setDisabledBtn(domain === "" || type === "");
  }, [domain, type]);

  useEffect(() => {
    dispatch(getDomainTypes());
  }, [dispatch]);

  const createSiteHandler = () => {
    dispatch(
      createCompanySite({
        domain,
        organization: companyId,
        settings,
        type,
        logo
      })
    );
  };

  return (
    <div>
      <h3>{t("Your site on our platform")}</h3>
      {!createdSite?.domain ? (
        <>
          <ParentWrapper>
            <DefaultDropdown
              label={t("Type")}
              items={domainTypes.map(item => ({
                name: item,
                id: item
              }))}
              returnData={setType}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setDomain(e.target.value)}
              value={domain}
              label="Your domain"
              name="your-domain"
              placeholder="Your domain"
            />
          </ParentWrapper>
          <label htmlFor="site-logo" className="template-chooser__title">
            {t("Site logo")}
          </label>
          {logo && (
            <ParentWrapper>
              <img className="builder-site__logo" src={logo} alt="" />
            </ParentWrapper>
          )}
          <ParentWrapper>
            <input
              accept="image/gif, image/jpeg, image/png"
              type="file"
              onChange={e => {
                const FR = new FileReader();
                FR.addEventListener("load", function(e) {
                  setLogo(e.target.result);
                });
                FR.readAsDataURL(e.target.files[0]);
              }}
            />
          </ParentWrapper>
          <div className="template-chooser__title">Choose template</div>
          <div className="template_chooser">
            {templates.map(t => (
              <TemplateItem {...t} />
            ))}
          </div>

          <ParentWrapper>
            <EditorGroup
              label={t("Message")}
              name="description"
              onChange={e => {
                setFooter(e.target.getContent());
                setSettings({ ...settings, footer: footer });
              }}
              value={footer}
              required={true}
            />
          </ParentWrapper>
          <DefaultButton
            border={`1px solid ${colorPalette.colorPrimary.main}`}
            width="150px"
            margin="20px 0"
            {...buttonOk}
            onClick={createSiteHandler}
            disabled={disabledBtn}
          >
            {t("Create site")}
          </DefaultButton>
        </>
      )
      : (
        <h3>{t("Site created!")}</h3>
        )
      }
    </div>
  );
};

function TemplateItem({ img }) {
  return (
    <div className="template_chooser__item">
      <div className="img" style={{ backgroundImage: `url('${img}')` }} />
      <div className="img_buttons">
        <ButtonGroup style={{ background: "#fff" }}>
          <Button variant="contained" color="primary">
            Choose
          </Button>
          <Button variant="outlined" color="primary">
            Demo
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default SiteBuilderForm;
