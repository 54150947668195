import React, { Fragment, useState } from "react";

import Collapsible from "react-collapsible";

import FilterItem from "../filter-item/filter-item.component";

import SearchIcon from "@material-ui/icons/Search";
import ExpandArrow from "../expand-arrow/expand-arrow.component";

import "./filter-accordion-item.styles.scss";

const FilterAccordionItem = ({
  heading,
  searchInputHandler,
  searchResults,
  addFilter,
  filteredData,
  deleteFilter
}) => {
  const [expand, setExpand] = useState(false);
  const onExpand = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  const addFilterItem = filterItem => {
    let found = false;
    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].name === filterItem.name) {
        found = true;
        break;
      }
    }
    if (!found) {
      addFilter(filterItem);
    }
  };

  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <div className="filter-accordion-item">
      <Collapsible
        className="filter-accordion-item__body"
        easing="none"
        triggerDisabled={true}
        open={true}
        trigger={
          <div
            onClick={() => {
              onExpand();
            }}
            className="filter-accordion-item__body__heading__button"
          >
            <div onClick={() => setOpenAccordion(!openAccordion)}>
              {heading}
            </div>
            <div
              onClick={() => {
                setOpenAccordion(openAccordion);
              }}
              className="filter-accordion-item__body__heading__button__filter-items"
            >
              {filteredData &&
                filteredData.map(({ id, name }) => (
                  <FilterItem
                    key={id}
                    itemId={id}
                    name={name}
                    removeFilterItem={deleteFilter}
                  />
                ))}
            </div>
            <div
              onClick={() => setOpenAccordion(!openAccordion)}
              className="filter-accordion-item__body__heading__button__icon"
            >
              <ExpandArrow expand={openAccordion} />
            </div>
          </div>
        }
      >
        <div
          style={{ display: `${!openAccordion ? "none" : ""}` }}
          className="filter-accordion-item__panel"
        >
          <div className="filter-accordion-item__panel__input-container">
            <input
              // value={searchInputValue}
              className="filter-accordion-item__panel__input-container__input"
              type="text"
              onChange={e => searchInputHandler(e.target.value)}
            />
            <SearchIcon
              style={{ color: "lightgray " }}
              className="filter-accordion-item__panel__input-container__icon"
            />
          </div>
          {/* {!isDataPending && ( */}
          <div
            onScroll={e => {
              if (
                e.currentTarget.scrollTop >
                parseInt(
                  (e.currentTarget.scrollHeight -
                    e.currentTarget.offsetHeight) *
                    (8 / 10)
                )
              ) {
                console.log("Scrolled 80%");
              }
            }}
            className="filter-accordion-item__panel__list"
          >
            {searchResults &&
              searchResults.map(({ id, name, children }) => (
                <Fragment key={id}>
                  <p
                    onClick={() => addFilterItem({ name: name, id: id })}
                    key={id}
                    className="filter-accordion-item__panel__list__item"
                  >
                    {name}
                  </p>
                  {children &&
                    children.map(({ id, name }) => (
                      <p
                        onClick={() => addFilterItem({ name: name, id: id })}
                        key={id}
                        className="filter-accordion-item__panel__list__item filter-accordion-item__panel__list__subitem"
                      >
                        {name}
                      </p>
                    ))}
                </Fragment>
              ))}
          </div>
          {/* // )} */}
        </div>
      </Collapsible>
    </div>
  );
};

export default FilterAccordionItem;
