import React from "react";
import "./product-description-files.styles.scss";

const ProductDescriptionFiles = ({ gallery }) => {
  return (
    <div className="product-description-files">
      {gallery.length > 0 ? (
        <>
          {gallery[0].images && gallery[0].images.length > 0 && (
            <>
              <h3>Photo gallery</h3>
              <div className="product-description-files__flex">
                {gallery[0].images.map(({ image }) => (
                  <a
                    href={`//${process.env.REACT_APP_STATIC_URL}/` + image}
                    className="product-description-files__photo-box"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`//${process.env.REACT_APP_STATIC_URL}/` + image}
                      alt="Product"
                      className="product-description-files__photo"
                    />
                  </a>
                ))}
              </div>
            </>
          )}

          {gallery[0].videos && gallery[0].videos.length > 0 && (
            <>
              <h3>Video gallery</h3>

              {gallery[0].videos &&
                gallery[0].videos.map(({ video }) => (
                  <div className="product-description-files__video-container">
                    <video
                      className="product-description-files__video"
                      controls
                    >
                      <source
                        src={`//${process.env.REACT_APP_STATIC_URL}/` + video}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
            </>
          )}
        </>
      ) : (
        <div className="product-description-files__no-files">
          No files added yet
        </div>
      )}
    </div>
  );
};

export default ProductDescriptionFiles;
