import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";

import FacebookIcon from "@material-ui/icons/Facebook";
import { facebookToken } from "../../config";

import "./login-with-fb.styles.scss";

const LoginWithFb = ({ setLoading, loginWithOauth }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [app] = useState({
    appId: facebookToken,
    provider: "facebook"
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    setLoading(true);
    loginWithOauth(accessToken, app.provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleSuccess = ({ accessToken = null } = {}) =>
    setAccessToken(accessToken);

  return (
    <div className="login-with-fb">
      <FacebookLogin
        appId={app.appId}
        cssClass="login-with-fb__fb-button"
        textButton=""
        isDisabled={false}
        icon={<FacebookIcon style={{ color: "#1976d2" }} />}
        callback={handleSuccess}
      />
    </div>
  );
};

export default LoginWithFb;
