import React, { useState } from "react";
import DatePicker from "react-date-picker";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "react-calendar/dist/Calendar.css";
import "./default-date-picker.styles.scss";

const DefaultDatePicker = ({ label, required, returnDate, name, value }) => {
  const [date, setDate] = useState(new Date());

  return (
    <div className="default-date-picker">
      {label && (
        <>
          <div style={{ display: "flex" }}>
            <div className="default-date-picker__label">{label}</div>
            {required && (
              <div className="default-date-picker__required">* required</div>
            )}
          </div>
          <br />{" "}
        </>
      )}
      <DatePicker
        name={name}
        dateFormat="yyyy/MM/dd"
        calendarIcon={<ExpandMoreIcon />}
        locale="en-GB"
        clearIcon={null}
        onChange={e => {
          setDate(e);
          e && returnDate(e);
        }}
        value={value ? value : date}
      />
    </div>
  );
};

export default DefaultDatePicker;
