import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import { withDebounce, scrollToRef } from "../../../shared";

import {
  LoadingSpinner,
  SearchBar,
  Pagination,
  Separator,
  CompanyLeadsInfo,
  MyBusinessNoData
} from "../../../components";

import {
  getUserOrganizationsChamber,
  getCompaniesByUserId,
  searchCompaniesByUserId,
  setCurrentCompany
} from "../../../redux/actions-exporter";

import "./my-business-leads.styles.scss";

const MyBusinessLeads = ({
  getCompaniesByUserId,
  getUserOrganizationsChamber,
  companiesByUserId,
  userOrganizationsChamber,
  userOrganizationsChamberCount,

  companiesByUserPending,
  organizationsByUserPending,
  setCurrentCompany,
  companiesByUserIdCount
}) => {
  const [searchCompanies, setSearchCompanies] = useState("");
  const [companiesPage, setCompaniesPage] = useState(1);
  const companyRef = useRef(null);

  const [searchOrganizations, setSearchOrganizations] = useState("");
  const [organizationsPage, setOrganizationsPage] = useState(1);
  const organizationRef = useRef(null);

  useEffect(() => {
    getCompaniesByUserId(searchCompanies, companiesPage);
  }, [getCompaniesByUserId, searchCompanies, companiesPage]);

  useEffect(() => {
    getUserOrganizationsChamber(searchOrganizations, organizationsPage);
  }, [getUserOrganizationsChamber, searchOrganizations, organizationsPage]);

  useEffect(() => {
    setCompaniesPage(1);
  }, [searchCompanies]);

  useEffect(() => {
    setOrganizationsPage(1);
  }, [searchOrganizations]);

  const searchCompaniesHandler = withDebounce(e => setSearchCompanies(e));
  const searchOrganizationsHandler = withDebounce(e =>
    setSearchOrganizations(e)
  );

  return (
    <div className="my-business-leads">
      {companiesByUserPending && organizationsByUserPending ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="my-business-companies">
            <div className="company-department-container">
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="company-department-container__title"
              >
                Leads
              </div>
              <Separator />
              <div
                ref={companyRef}
                className="company-department-container__title"
              >
                Companies
              </div>
              <div style={{ margin: 20 }}>
                <SearchBar
                  onChange={e => searchCompaniesHandler(e.target.value)}
                />
              </div>
              {companiesByUserId && companiesByUserId.length ? (
                companiesByUserId.map((company, index) => (
                  <CompanyLeadsInfo
                    typeOfCompany={"Company"}
                    key={index}
                    currentCompany={company}
                    setCurrentCompany={setCurrentCompany}
                  />
                ))
              ) : (
                <MyBusinessNoData noDataText="No companies found!" />
              )}
            </div>
            <Pagination
              forcePage={companiesPage - 1}
              dataCount={companiesByUserIdCount}
              itemsPerPage={10}
              selectedPage={page => {
                setCompaniesPage(page);
                scrollToRef(companyRef.current.offsetTop);
              }}
            />
          </div>
          <div className="my-business-organizations">
            <div className="company-department-container">
              <div
                ref={organizationRef}
                className="company-department-container__title"
              >
                Organizations
              </div>
              <div style={{ margin: 20 }}>
                <SearchBar
                  onChange={e => searchOrganizationsHandler(e.target.value)}
                />
              </div>
              {userOrganizationsChamber && userOrganizationsChamber.length ? (
                userOrganizationsChamber.map((organization, index) => {
                  return (
                    <CompanyLeadsInfo
                      typeOfCompany={"Organization"}
                      key={index}
                      currentCompany={organization}
                      setCurrentCompany={setCurrentCompany}
                    />
                  );
                })
              ) : (
                <MyBusinessNoData noDataText="No organizations found!" />
              )}
            </div>
            <Pagination
              forcePage={organizationsPage - 1}
              dataCount={userOrganizationsChamberCount}
              itemsPerPage={10}
              selectedPage={page => {
                setOrganizationsPage(page);
                scrollToRef(organizationRef.current.offsetTop);
              }}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  leads: state.leadsReducer.leads,

  companiesByUserPending: state.companiesReducer.areCompaniesByUserIdPending,
  organizationsByUserPending:
    state.organizationsReducer.areUserOrganizationsPending,
  companiesByUserId: state.companiesReducer.companiesByUserId,
  companiesByUserIdCount: state.companiesReducer.companiesByUserIdCount,
  userOrganizationsChamber: state.organizationsReducer.userOrganizationsChamber,
  userOrganizationsChamberCount:
    state.organizationsReducer.userOrganizationsChamberCount
});

export default connect(mapStateToProps, {
  getCompaniesByUserId,
  getUserOrganizationsChamber,
  setCurrentCompany,
  searchCompaniesByUserId
})(MyBusinessLeads);
