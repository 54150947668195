import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { ParentWrapper, ButtonsFooterWrapper } from "../../../layout";
import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";

import { InputGroup, EditorGroup, DefaultButton } from "../../../components";

import {
  createLead,
  updateLead,
  getLeadById,
  clearLeadRequest
} from "../../../redux/actions-exporter";

import "./my-business-leads-edit.styles.scss";

const MyBusinessLeadsEdit = ({
  createLead,
  updateLead,
  getLeadById,
  currentLead,
  currentId,
  clearLeadRequest,
  leadRequestStatus
}) => {
  const [message, setMessage] = useState("");
  const [leadData, setLeadData] = useState({
    realName: "",
    email: "",
    phone: "",
    urlPath: ""
  });

  const { realName, email, phone, urlPath } = leadData;
  const [disabledButton, setDisabledButton] = useState(true);

  const history = useHistory();
  const { companyLeadsId, leadId } = useParams();

  useEffect(() => {
    if (history.location.pathname.includes("my-business")) {
      getLeadById(leadId);
    }
  }, [history.location.pathname, leadId, getLeadById]);

  useEffect(() => {
    const { realname, email, phone, message, url_path } = currentLead;
    if (history.location.pathname.includes("my-business")) {
      setLeadData({
        realName: realname,
        email: email,
        phone: phone,
        message: message,
        urlPath: url_path
      });
      setMessage(message);
    }
  }, [history.location.pathname, leadId, setLeadData, currentLead]);

  useEffect(() => {
    if (leadRequestStatus === 200) {
      history.goBack();
    } else if (leadRequestStatus === 201) {
      history.push(`/companies/${currentId}`);
    }
  }, [leadRequestStatus, currentId, history]);

  useEffect(() => {
    setDisabledButton(
      realName === "" || email === "" || phone === "" || message === ""
    );
  }, [realName, email, phone, message]);

  useEffect(() => () => clearLeadRequest(), [clearLeadRequest]);

  const editleads = () => {
    if (history && history.location.pathname.includes("my-business")) {
      updateLead(
        leadId,
        realName,
        companyLeadsId,
        email,
        phone,
        message,
        urlPath || window.location.href
      );
    } else {
      createLead(
        realName,
        currentId,
        email,
        phone,
        message,
        urlPath || window.location.href
      );
    }
  };

  const cancelLead = () => {
    if (history && history.location.pathname.includes("my-business")) {
      history.push("/my-business/leads");
    } else {
      history.push(`/companies/${currentId}/offers`);
    }
  };

  const handleLeadInputs = e => {
    const name = e.target.name;
    const value = e.target.value;

    setLeadData({ ...leadData, [name]: value });
  };

  return (
    <div className="my-business-leads-edit">
      {currentLead && (
        <div className="my-business-leads-edit__grid">
          <h2>Contact Us</h2>
          <ParentWrapper>
            <InputGroup
              label="Real name"
              name="realName"
              placeholder="Real name"
              onChange={handleLeadInputs}
              value={realName}
            />
            <InputGroup
              label="E-mail"
              name="email"
              placeholder="E-mail"
              onChange={handleLeadInputs}
              value={email}
            />
            <InputGroup
              label="Phone"
              name="phone"
              placeholder="Phone"
              onChange={handleLeadInputs}
              value={phone}
            />
          </ParentWrapper>
          {history.location.pathname.includes("my-business") && (
            <ParentWrapper>
              <InputGroup
                label="Url Path"
                name="urlPath"
                placeholder="Url path..."
                onChange={handleLeadInputs}
                value={urlPath}
              />
            </ParentWrapper>
          )}
          <ParentWrapper>
            <EditorGroup
              label="Message"
              name="message"
              onChange={e => setMessage(e.target.getContent())}
              value={message}
            />
          </ParentWrapper>
          <ButtonsFooterWrapper>
            <div>
              <DefaultButton
                width="100%"
                {...buttonOk}
                onClick={editleads}
                disabled={disabledButton}
              >
                Save
              </DefaultButton>
            </div>
            <div>
              <DefaultButton
                width="100%"
                {...buttonCancel}
                onClick={cancelLead}
              >
                Cancel
              </DefaultButton>
            </div>
          </ButtonsFooterWrapper>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentLead: state.leadsReducer.leadById,
  leadRequestStatus: state.leadsReducer.leadRequestStatus
});

export default connect(mapStateToProps, {
  createLead,
  updateLead,
  getLeadById,
  clearLeadRequest
})(MyBusinessLeadsEdit);
