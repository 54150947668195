import icon from "./business-proposals-with-pagination-block-icon.png";

export default function businessProposalsWithPaginationBlock({ editor, api }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    @media screen and (max-width: 992px) {
      .business-proposals-block {
        flex-flow: column nowrap !important;
      }

      .business-proposals-categories {
        width: 100vw !important;
      }

      .business-proposals {
        width: 100vw !important;
      }

      .simple-pagination__button {
        padding: 5px 10px !important;
      }
    }

    .business-proposals-block {
      display: flex;
      flex-flow: row nowrap;
    }

    .business-proposals-categories {
      width: 20%;
      list-style: none;
      padding: 86px 20px;
    }

    .business-proposals-categories__title {
      color: #262626;
      font-size: 24px;
      font-weight: 700;
      line-height: 12px;
      margin: 0 0 21px;
    }

    .business-proposals-categories__item {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      padding: 8px 5px;
      border-radius: 2px;
      line-height: 12px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 200ms ease;
    }

    .business-proposals-categories__item[pressed] {
      background: rgba(0, 0, 0, 0.08);
    }
    
    .business-proposals-categories__item + .business-proposals-categories__item {
      margin: 6px 0 0;
    }

    .business-proposals {
      width: 80%;
      display: flex;
      flex-flow: row wrap;
    }

    .business-proposals__item {
      width: 100%;
      color: hsla(0, 0%, 0%, 0.96);
      flex-basis: 100%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 0 0 20px;
      text-decoration: none;
      overflow: auto;
    }

    .business-proposals__item-title {
      color: #262626;
      font-size: 30px;
      font-weight: 700;
      margin: 16px 0;
    }

    .business-proposals__item:hover .business-proposals__item-title {
      text-decoration: underline solid;
    }

    .business-proposals__item-description {
      position: relative;
      display: flex;
      flex-flow: column;
      height: 130px;
      width: 100%;
      overflow: hidden;
    }

    .business-proposals__item-description::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #00000014, transparent, transparent);
    }

    .business-proposals__item-description p {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 14px;
    }

    .business-proposals__item-meta {
      width: 100%;
      color: hsla(0, 0%, 0%, 0.86);
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .business-proposals__item-meta + .business-proposals__item-meta {
      margin: 0 0 0 8px;
    }

    .simple-pagination {
      align-self: flex-end;
      margin: 0 0 0 auto;
      padding: 20px;
    }

    .simple-pagination__button {
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      border: 2px solid #aeaeae;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .simple-pagination__button.simple-pagination__button--active {
      color: #fff;
      background: #c0c0c0;
    }

    .simple-pagination__button:disabled, .simple-pagination__button:hover {
      background: #ededed;
    }
  </style>
  `;

  const content = `
  <div class="business-proposals-block">
    ${style}
    <ul class="business-proposals-categories">
    </ul>
    <div class="business-proposals">
      
    </div>
  </div>
  `;

  const script = function() {
    const API_ROOT = "{[ apiRoot ]}";

    function makeBusinessHtml(title, city, keywords) {
      return `
      <a class="business-proposals__item" href="#">
        <h4 class="business-proposals__item-title" title="${title}">${title}</h4>
        <div class="business-proposals__item-meta">
          <span class="business-proposals__item-meta" title="${city}">
            Organization: ${city}
          </span>
          <span class="business-proposals__item-meta">
            •
          </span>
          <span class="business-proposals__item-meta" title="${keywords}">
            Keywords: ${keywords}
          </span>
        </div>
      </a>
      `;
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      console.table({ currentPage, totalPagesCount, totalCount, countPerPage });
      return `
      <div class="simple-pagination">
        ${
          currentPage - 1
            ? `
            <button class="simple-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="simple-pagination__button" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="simple-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${currentPage - 4 > 0 ? "..." : ""}

        ${
          currentPage - 2 > 0
            ? `
            <button class="simple-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="simple-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="simple-pagination__button simple-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="simple-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${currentPage + 3 <= totalPagesCount ? "..." : ""}

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="simple-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="simple-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="simple-pagination__button" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function update(params) {
      const urlParams = new URLSearchParams(
        Object.keys(params).reduce((tmp, item) => {
          if (params[item]) tmp[item] = params[item];
          return tmp;
        }, {})
      );

      fetch(`${API_ROOT}/business_proposal/business_proposals/?${urlParams}`)
        .then(response => response.json())
        .then(response => {
          const el = document.querySelector(".business-proposals");
          const businesses = response.results;

          const businessesHtml = businesses.reduce(
            (tmp, business) =>
              tmp +
              makeBusinessHtml(
                business.title,
                (business.organization && business.organization.name) || "...",
                business.keywords || "..."
              ),
            ""
          );

          const paginationHtml = makePaginationHtml(
            params.page,
            response.count,
            10
          );

          el.innerHTML = paginationHtml + businessesHtml;

          const paginationButtonsEls = document.querySelectorAll(
            ".simple-pagination__button:not(disabled)"
          );

          Array.prototype.slice
            .call(paginationButtonsEls)
            .map(paginationButtonEl =>
              paginationButtonEl.addEventListener("click", e => {
                window.businessProposalsFilters.page = parseInt(
                  e.target.dataset.page
                );
                update(window.businessProposalsFilters);
              })
            );
        });
    }

    window.businessProposalsFilters = {
      page: 1,
      categories: ""
    };

    fetch(`${API_ROOT}/business_proposal/business_proposals_categories/`)
      .then(response => response.json())
      .then(response => {
        const el = document.querySelector(".business-proposals-categories");
        const categories = response.results;

        el.innerHTML = categories.reduce(
          (tmp, category) =>
            tmp +
            `
            <li class="business-proposals-categories__item" data-id="${category.id}">
              ${category.name}
            </li>
            `,
          `<li class="business-proposals-categories__title">Categories</li>`
        );

        const categoriesEls = document.querySelectorAll(
          ".business-proposals-categories__item"
        );

        Array.prototype.slice.call(categoriesEls).map(categoriesEl =>
          categoriesEl.addEventListener("click", e => {
            e.preventDefault();

            const categories = window.businessProposalsFilters.categories
              .split(",")
              .filter(Boolean)
              .map(category => category.toString().trim());

            if (e.target.getAttribute("pressed")) {
              e.target.removeAttribute("pressed");
              window.businessProposalsFilters.categories = categories
                .filter(category => category !== e.target.dataset.id.toString())
                .join(",");
            } else {
              e.target.setAttribute("pressed", "true");
              window.businessProposalsFilters.categories = categories
                .concat(e.target.dataset.id.toString())
                .join(",");
            }

            window.businessProposalsFilters.page = 1;
            update(window.businessProposalsFilters);
          })
        );
      });

    update(window.businessProposalsFilters);
  };

  editor.DomComponents.addType(
    "business-proposals-with-pagination--component",
    {
      model: {
        defaults: {
          name: "Business Proposals",
          apiRoot: api.API_ROOT,
          script,
          content,
          traits: [
            {
              type: "string",
              label: "City",
              name: "city",
              placeholder: "City"
            },
            {
              type: "string",
              label: "Organization",
              name: "organization",
              placeholder: "Organization"
            },
            {
              type: "string",
              label: "Vacancy",
              name: "vacancy",
              placeholder: "Vacancy"
            },
            {
              type: "string",
              label: "Created By",
              name: "created_by",
              placeholder: "Author"
            }
          ]
        }
      },
      view: {
        init() {
          this.listenTo(this.model, "change:attributes:city", this.render);
          this.listenTo(
            this.model,
            "change:attributes:organization",
            this.render
          );
          this.listenTo(this.model, "change:attributes:vacancy", this.render);
          this.listenTo(
            this.model,
            "change:attributes:created_by",
            this.render
          );
        }
      }
    }
  );

  blockManager.add("business-proposals-with-pagination", {
    label: `
      <img src="${icon}" alt="Business proposals block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Business Proposals
      </div>
    `,
    content: {
      type: "business-proposals-with-pagination--component"
    },
    category: "B24Online Business"
  });
}
