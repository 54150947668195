import React from "react";

import CompanyDocument from "../company-document/company-document.component";
import "./product-description-documents.styles.scss";

const ProductDescriptionDocuments = ({ documents }) => {
  return (
    <div className="product-description-documents">
      {/* <h3>Documents</h3>
      <div className="product-description-documents__documents">
        <img src="https://via.placeholder.com/150x200" alt="" />
        <img src="https://via.placeholder.com/150x200" alt="" />
        <img src="https://via.placeholder.com/150x200" alt="" />
        <img src="https://via.placeholder.com/150x200" alt="" />
      </div> */}

      {documents.length > 0 ? (
        <>
          <h3>Documents</h3>
          <div className="comp-org-files-tab__documents-container">
            <div className="comp-org-files-tab__documents-container__documents">
              {documents.map(({ id, document, name }) => (
                <CompanyDocument
                  key={id}
                  img="https://via.placeholder.com/300x200"
                  name={name}
                  file={document}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="comp-org-files-tab__no-files">No files added yet</div>
      )}
    </div>
  );
};

export default ProductDescriptionDocuments;
