import axios from "axios";

import { CategoriesActionTypes } from "./categories.types";
import {
  axiosNoTokenInstance,
  axiosInstance,
  baseURL,
  getCookie
} from "@core/axios/axios.config";

export const getB2BCategories = (querySearch = "") => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2b/category_parent/?search=${querySearch}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_SUCCESS,
        payload: {
          // count: response.data.count,
          // next: response.data.next,
          // previous: response.data.previous,
          results: response.data.filter(category => category?.is_main)
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_ERROR,
        payload: error
      })
    );
};

export const getB2CCategories = (querySearch = "") => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/category_parent/?search=${querySearch}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_SUCCESS,
        payload: {
          // count: response.data.count,
          // next: response.data.next,
          // previous: response.data.previous,
          results: response.data.filter(category => category?.is_main)
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_ERROR,
        payload: error
      })
    );
};

export const getB2BCategoriesEditor = (page = 1) => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_EDITOR_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2b/category_parent/?page=${page}&page_size=10`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_EDITOR_SUCCESS,
        payload: {
          // count: response.data.count,
          // next: response.data.next,
          // previous: response.data.previous,
          results: response.data
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_EDITOR_ERROR,
        payload: error
      })
    );
};

export const getB2CCategoriesEditor = (querySearch = "") => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_EDITOR_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/category_parent/?search=${querySearch}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_EDITOR_SUCCESS,
        payload: {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: response.data
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_EDITOR_ERROR,
        payload: error
      })
    );
};

export const searchB2BCategoriesByCompany = (
  companyId,
  searchQuery = ""
) => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2B.SEARCH_B2B_CATEGORIES_BY_COMPANY_PENDING
  });
  axiosNoTokenInstance
    .get(
      `api/v1/b2b/category_parent/?search=${searchQuery}&company=${companyId}`
    )
    .then(categories => {
      axiosNoTokenInstance
        .get(`api/v1/b2b/category/?company=${companyId}`)
        .then(subcategories => {
          const finalArray = categories.data;
          if (finalArray.length) {
            finalArray.map(
              (category, index) =>
                (finalArray[index].children = category.children.filter(el =>
                  subcategories.data.results.find(i => i.id === el.id)
                ))
            );
          }

          dispatch({
            type:
            CategoriesActionTypes.GET.B2B
              .SEARCH_B2B_CATEGORIES_BY_COMPANY_SUCCESS,
            payload: finalArray
          });
        });
    })

    .catch(err => {
      dispatch({
        type:
        CategoriesActionTypes.GET.B2B.SEARCH_B2B_CATEGORIES_BY_COMPANY_ERROR,
        payload: err
      });
    });
};

export const searchB2CCategoriesByCompany = (
  companyId,
  searchQuery = ""
) => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2C.SEARCH_B2C_CATEGORIES_BY_COMPANY_PENDING
  });
  axiosInstance
    .get(
      `api/v1/b2c/category_parent/?search=${searchQuery}&company=${companyId}`
    )
    .then(categories => {
      axiosInstance
        .get(`api/v1/b2c/category/?company=${companyId}`)
        .then(subcategories => {
          const finalArray = categories.data;
          if (finalArray.length) {
            finalArray.map(
              (category, index) =>
                (finalArray[index].children = category.children.filter(el =>
                  subcategories.data.results.find(i => i.id === el.id)
                ))
            );
          }
          dispatch({
            type:
            CategoriesActionTypes.GET.B2C
              .SEARCH_B2C_CATEGORIES_BY_COMPANY_SUCCESS,
            payload: finalArray
          });
        });
    })

    .catch(err => {
      dispatch({
        type:
        CategoriesActionTypes.GET.B2C.SEARCH_B2C_CATEGORIES_BY_COMPANY_ERROR,
        payload: err
      });
    });
};

export const searchB2BCategory = searchQuery => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2b/category_parent/?search=${searchQuery}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_SUCCESS,
        payload: {
          // count: response.data.count,
          // next: response.data.next,
          // previous: response.data.previous,
          results: response.data
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_ERROR,
        payload: error
      })
    );
};

export const searchB2CCategory = searchQuery => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/category_parent/?search=${searchQuery}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_SUCCESS,
        payload: {
          // count: response.data.count,
          // next: response.data.next,
          // previous: response.data.previous,
          results: response.data
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_ERROR,
        payload: error
      })
    );
};

export const createCategory = (
  typeOfCategory,
  name,
  parent,
  icon,
  category_order,
  isMain,
  isActive,
  company
) => dispatch => {
  axiosInstance
    .post(`/api/v1/${typeOfCategory}/category_manageable/`, {
      name,
      parent,
      category_order,
      is_main: isMain,
      is_active: isActive,
      company
    })
    .then(category => {
      if (icon !== undefined) {
        dispatch({
          type: CategoriesActionTypes.PATCH.ICON.PATCH_CATEGORY_ICON_PENDING
        });
        const formData = new FormData();
        formData.append("icon", icon);
        axios
          .patch(
            `${baseURL}/api/v1/${typeOfCategory}/category/${category.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
              CategoriesActionTypes.PATCH.ICON.PATCH_CATEGORY_ICON_SUCCESS,
              payload: resp.data
            })
          )
          .then(_ => {
            dispatch({
              type: CategoriesActionTypes.CREATE.CREATE_CATEGORY_SUCCESS,
              payload: {
                category: category.data,
                requestStatus: category.status
              }
            });
          })
          .catch(err =>
            dispatch({
              type: CategoriesActionTypes.PATCH.ICON.PATCH_CATEGORY_ICON_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: CategoriesActionTypes.CREATE.CREATE_CATEGORY_SUCCESS,
          payload: { category: category.data, requestStatus: category.status }
        });
      }
    })
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.CREATE.CREATE_CATEGORY_ERROR,
        payload: error
      })
    );
};

export const getCategoryById = (categoryId, typeOfCategory) => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/${typeOfCategory}/category/${categoryId}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.GET_CATEGORY_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.GET_CATEGORY_ERROR,
        payload: error
      })
    );
};

export const updateCategory = (
  typeOfCategory,
  categoryId,
  name,
  parent,
  icon,
  category_order,
  isMain,
  isActive,
  company
) => dispatch => {
  axiosInstance
    .put(`/api/v1/${typeOfCategory}/category_manageable/${categoryId}/`, {
      name,
      parent,
      category_order,
      is_main: isMain,
      is_active: isActive,
      company
    })
    .then(category => {
      if (icon !== undefined) {
        dispatch({
          type: CategoriesActionTypes.PATCH.ICON.PATCH_CATEGORY_ICON_PENDING
        });
        const formData = new FormData();
        formData.append("icon", icon);
        axios
          .patch(
            `${baseURL}/api/v1/${typeOfCategory}/category_manageable/${category.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
              CategoriesActionTypes.PATCH.ICON.PATCH_CATEGORY_ICON_SUCCESS,
              payload: resp.data
            })
          )
          .then(() => {
            dispatch({
              type: CategoriesActionTypes.UPDATE.UPDATE_CATEGORY_SUCCESS,
              payload: {
                category: category.data,
                requestStatus: category.status
              }
            });
          })
          .catch(err =>
            dispatch({
              type: CategoriesActionTypes.PATCH.ICON.PATCH_CATEGORY_ICON_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: CategoriesActionTypes.UPDATE.UPDATE_CATEGORY_SUCCESS,
          payload: { category: category.data, requestStatus: category.status }
        });
      }
    })
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.UPDATE.UPDATE_CATEGORY_ERROR,
        payload: error
      })
    );
};

export const deleteCategory = (typeOfCategory, categoryId) => dispatch => {
  axiosInstance
    .delete(`/api/v1/${typeOfCategory}/category_manageable/${categoryId}/`)
    .then(category =>
      dispatch({
        type: CategoriesActionTypes.DELETE.DELETE_CATEGORY_SUCCESS,
        payload: { category: category.data, requestStatus: category.status }
      })
    )
    .then(() => {
      if (typeOfCategory === "b2c") {
        dispatch(getB2CCategoriesEditor());
      } else {
        dispatch(getB2BCategoriesEditor());
      }
    })
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.DELETE.DELETE_CATEGORY_ERROR,
        payload: error
      })
    );
};

export const getB2CCategoriesByCompany = company => dispatch => {
  dispatch({
    type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/category_parent/?company=${company}`)
    .then(response =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_SUCCESS,
        payload: {
          // count: response.data.count,
          // next: response.data.next,
          // previous: response.data.previous,
          results: response.data
        }
      })
    )
    .catch(error =>
      dispatch({
        type: CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_ERROR,
        payload: error
      })
    );
};

export const clearCategoryRequest = () => dispatch => {
  dispatch({
    type: CategoriesActionTypes.CLEAR_CATEGORY_REQUEST,
    payload: ""
  });
};
