import React from "react";
import "./button.styles.scss";

const Button = ({ children, func, mod, type }) => {
  return (
    <button type={type} className={`button--${mod}`} onClick={func}>
      {children}
    </button>
  );
};

export default Button;
