import React from "react";

import { DefaultButton, InputGroup } from "@core/components";
import { useTranslation } from "react-i18next";

import { buttonOk } from "~/styles/buttons-styles";
import "./upload-file-document.styles.scss";

const UploadFileDocument = ({
  docName,
  setDocName,
  docDescription,
  setDocDescription,
  documentFile,
  setDocumentFile,
  documentBlob,
  setDocumentBlob,
  uploadDocument,
  itemContentType,
  itemId
}) => {
  const { t } = useTranslation();

  const documentUploadHandler = () =>
    uploadDocument(
      itemId,
      docName,
      docDescription,
      itemContentType,
      documentFile
    );

  return (
    <div className="upload-file-document">
      <div className="upload-file-document__header">{t("Document Info")}</div>
      <div className="upload-file-document__section">
        <InputGroup
          label={t("Document Name")}
          name="docName"
          placeholder={t("Document name")}
          onChange={e => setDocName(e.target.value)}
          value={docName}
        />
        <InputGroup
          label={t("Description")}
          name="docDescription"
          placeholder={t("Description")}
          onChange={e => setDocDescription(e.target.value)}
          value={docDescription}
        />
      </div>
      <div className="upload-file-document__upload-doc">
        <input
          type="file"
          encType="multipart/form-data"
          label="Upload documents"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
          onChange={e => {
            setDocumentBlob(URL.createObjectURL(e.target.files[0]));
            setDocumentFile(e.target.files[0]);
          }}
        />
      </div>
      <div className="upload-file-document__footer">
        <div className="button-wrapper">
          <DefaultButton
            onClick={documentUploadHandler}
            width="100%"
            {...buttonOk}
            disabled={
              docName === "" ||
              docDescription === "" ||
              documentBlob === null ||
              documentFile === null
            }
          >
            {t("Add document")}
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default UploadFileDocument;
