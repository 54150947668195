import icon from "./icon.png";

export default function companiesBranchesBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .companies-branches {}

  .companies-branches.companies-branches--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .companies-branches.companies-branches--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .companies-branches__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .companies-branches__search {
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000000;
    font-size: 15px;
    border: 1px solid #b9b9ba;
    padding: 10px 8px;
    border-radius: 3px;
    width: 100%;
    margin: 0 0 14px;
  }

  .companies-branches__title {
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 21px;
  }

  .companies-branches__item {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 5px;
    border-radius: 2px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease;
  }

  .companies-branches__item.companies-branches__item--active {
    background: rgba(0, 0, 0, 0.08);
  }
  
  .companies-branches__item + .companies-branches__item {
    margin: 6px 0 0;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="companies-branches-wrapper">
    <input class="companies-branches__search" type="text" placeholder="Search...">
    <div class="companies-branches">
      <ul class="companies-branches__list">
        <li class="companies-branches__item companies-branches__item--active">
          Active branch
        </li>
      
        <li class="companies-branches__item companies-branches__item--active">
          Active branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      
        <li class="companies-branches__item">
          Branch
        </li>
      </ul>
    </div>
  </div>
  `;

  blockManager.add("companies-branches", {
    label: `
      <img src="${icon}" alt="Companies branches block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Companies Branches
      </div>
    `,
    content,
    category: "B24Online Companies"
  });
}
