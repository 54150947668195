import React, { useEffect } from "react";
import queryString from "query-string";
import { connect } from "react-redux";

import { LoginCard, DefaultSnackbar } from "../../components";

import { loginWithOauth } from "../../redux/actions-exporter";

import "./login.styles.scss";

const Login = ({
  location,
  token,
  logInErrorStatus,
  logInPending,
  loggedIn,
  loginWithOauth,
  isLoginWithOauthPending
}) => {
  const params = queryString.parse(location.search);
  const isNeedAuth = !!params["auth-redirect"];
  const redirect = isNeedAuth ? params["auth-redirect"] : "/";

  useEffect(() => {
    if (!token) {
      return;
    }

    const date = new Date();
    const time = date.getTime();
    const expireTime = time + 100000 * 12096;
    date.setTime(expireTime);

    document.cookie = `Authorization=Token ${token}; expires=${date.toUTCString()}`;
    window.location.assign(redirect);
  }, [token, redirect]);

  return (
    <div className="login-page">
      {isNeedAuth && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          variant="warning"
          message="Authorization required!"
          autoHideDuration={4000}
        />
      )}
      {logInErrorStatus && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="warning"
          message="Wrong Credentials!"
          autoHideDuration={4000}
        />
      )}
      <LoginCard
        redirect={redirect || "/"}
        logInPending={logInPending}
        loginWithOauth={loginWithOauth}
        isLoginWithOauthPending={isLoginWithOauthPending}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.authReducer.token,
  logInErrorStatus: state.authReducer.logInErrorStatus,
  logInPending: state.authReducer.logInPending,
  loggedIn: state.authReducer.loggedIn,
  isLoginWithOauthPending: state.authReducer.isLoginWithOauthPending
});

export default connect(mapStateToProps, { loginWithOauth })(Login);
