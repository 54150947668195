import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Pencil } from "../../assets/icons/Pencil.svg";
import { ReactComponent as Cross } from "../../assets/icons/Cross.svg";

import "./editor.styles.scss";

const Editor = ({ editLink = "#", removeLink = "#", removeFunc, editFunc }) => {
  return (
    <div className="editor">
      <div className="editor__icon">
        <Link to={editLink} onClick={editFunc}>
          <Pencil width="12px" height="12px" />
        </Link>
      </div>
      <div className="editor__icon">
        <div onClick={removeFunc}>
          <Cross width="12px" height="12px" />
        </div>
      </div>
    </div>
  );
};

export default Editor;
