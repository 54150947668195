import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// import { MyBusiness } from "../../assets";

import {
  LoadingSpinner,
  Tooltip,
  Newsletter,
  PopularNews,
} from "../../components";

import { getB2CCategories } from "../../redux/actions-exporter";

import "./navbar.styles.scss";

const NavbarMenuCategory = ({ id, icon, name, appDirection, children }) => {
  const [visibility, setVisibility] = useState(null);
  const isDesktop = window.innerWidth >= 992;

  const makeLinkToProducts = ({ key, categoryId }) => ({
    key,
    pathname: "/products",
    state: {
      defaultProductType: "b2c",
      additionalB2CRequestParams: { categoryId },
    },
  });

  const SubmenuTooltip = (
    <div
      className={`navbar-menu-item__submenu ${appDirection}`}
      dir={appDirection}
    >
      <ul className="navbar-menu-item__list">
        {children.map((category) => (
          <Link
            key={category.id}
            to={makeLinkToProducts({
              key: category.id,
              categoryId: category.id,
            })}
          >
            <li className="navbar-menu-item__list-item">{category.name}</li>
          </Link>
        ))}
      </ul>
    </div>
  );

  return (
    <Tooltip
      onVisibilityChange={setVisibility}
      tooltipShown={visibility}
      placement={
        isDesktop
          ? appDirection === "rtl"
            ? "left-start"
            : "right-start"
          : "bottom"
      }
      tooltip={children.length ? SubmenuTooltip : <></>}
      className="navbar-menu-item"
    >
      <Link
        to={makeLinkToProducts({
          key: id,
          categoryId: id,
        })}
      >
        <span key={id} className="navbar-menu-item__menu">
          {icon ? (
            <svg width="24px" height="24px">
              <image href={`${icon}`} width="100%" height="100%" />
            </svg>
          ) : (
            <svg width="24px" height="24px" />
          )}
          <div className="navbar-menu-item__name">{name}</div>
        </span>
      </Link>
    </Tooltip>
  );
};

const Navbar = ({
  appDirection,
  burgerStatus,
  localizationData,
  areB2CCategoriesPending,
  b2cCategoriesResults,
  b2cCategoriesError,
  getB2CCategories,
}) => {
  const [optionsDisplay, setOptionsDisplay] = useState("nabar--open");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!burgerStatus) {
      setOptionsDisplay();
    } else {
      setOptionsDisplay("navbar--open");
    }
  }, [burgerStatus]);

  useEffect(() => {
    if (categories.length || areB2CCategoriesPending || b2cCategoriesError) {
      return;
    }

    setCategories(b2cCategoriesResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areB2CCategoriesPending]);

  useEffect(() => {
    setCategories([]);
    getB2CCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizationData]);

  if (!categories.length) {
    return <LoadingSpinner />;
  }

  return (
    <div className={`navbar ${optionsDisplay}`}>
      <div className="navbar__section navbar__section--categories">
        {categories.map((category) => (
          <NavbarMenuCategory
            {...category}
            key={category.id}
            appDirection={appDirection}
          />
        ))}
      </div>

      <div className="navbar__section navbar__section--buttons">
        {/*

        // Hidden my business button

        <NavLink
          to="/my-business/products"
          className="navbar__link navbar__link--button"
        >
          <button
            type="button"
            className="navbar__button navbar__button--orange"
          >
            <MyBusiness width="14" height="14" />
            <span className="navbar__button-text">
              {localizationData["My Business"] || "My Business"}
            </span>
          </button>
        </NavLink> */}
        <div>
          <PopularNews />
        </div>
        <div>
          <Newsletter />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appDirection: state.appLanguage.appDirection,
  burgerStatus: state.appLanguage.burgerToggleOpen,
  localizationData: state.appLanguage.localizationData,
  areB2CCategoriesPending: state.categoriesAPI.areB2CCategoriesPending,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,
  b2cCategoriesError: state.categoriesAPI.b2cCategoriesError,
});

export default connect(mapStateToProps, {
  getB2CCategories,
})(Navbar);
