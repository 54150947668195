import { FilterActionTypes } from "./filter.types";
import { axiosNoTokenInstance } from "../../../axios/axios.config";

export const getCountriesFilter = (search = "", pageNumber = 1) => dispatch => {
  dispatch({
    type: FilterActionTypes.GET.COUNTRIES.GET_COUNTRIES_FILTER_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/countries/?search=${search}&page=${pageNumber}`)
    .then(({ data }) =>
      dispatch({
        type: FilterActionTypes.GET.COUNTRIES.GET_COUNTRIES_FILTER_SUCCESS,
        payload: {
          count: data.count,
          countries: data.results
        }
      })
    )
    .catch(error =>
      dispatch({
        type: FilterActionTypes.GET.COUNTRIES.GET_COUNTRIES_FILTER_ERROR,
        payload: error
      })
    );
};

export const getOrganizationsFilter = (
  search = "",
  countries = "",
  pageNumber = 1
) => dispatch => {
  dispatch({
    type: FilterActionTypes.GET.ORGANIZATIONS.GET_ORGANIZATIONS_FILTER_PENDING
  });
  axiosNoTokenInstance
    .get(
      `/api/v1/organization/chamber/?search=${search}&countries=${countries}&page=${pageNumber}`
    )
    .then(({ data }) =>
      dispatch({
        type:
          FilterActionTypes.GET.ORGANIZATIONS.GET_ORGANIZATIONS_FILTER_SUCCESS,
        payload: { count: data.count, organizations: data.results }
      })
    )
    .catch(error =>
      dispatch({
        type:
          FilterActionTypes.GET.ORGANIZATIONS.GET_ORGANIZATIONS_FILTER_ERROR,
        payload: error
      })
    );
};

export const getBranchesFilter = (search = "", pageNumber = 1) => dispatch => {
  dispatch({
    type: FilterActionTypes.GET.BRANCHES.GET_BRANCHES_FILTER_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/branches/?search=${search}&page=${pageNumber}`)
    .then(({ data }) =>
      dispatch({
        type: FilterActionTypes.GET.BRANCHES.GET_BRANCHES_FILTER_SUCCESS,
        payload: { count: data.count, branches: data.results }
      })
    )
    .catch(error =>
      dispatch({
        type: FilterActionTypes.GET.BRANCHES.GET_BRANCHES_FILTER_ERROR,
        payload: error
      })
    );
};

export const getB2CCategoriesFilter = (search = "") => dispatch => {
  dispatch({
    type: FilterActionTypes.GET.B2C_CATEGORIES.GET_B2C_CATEGORIES_FILTER_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/category_parent/?search=${search}`)
    .then(response =>
      dispatch({
        type:
          FilterActionTypes.GET.B2C_CATEGORIES
            .GET_B2C_CATEGORIES_FILTER_SUCCESS,
        payload: { count: response.data.count, results: response.data }
      })
    )
    .catch(error =>
      dispatch({
        type:
          FilterActionTypes.GET.B2C_CATEGORIES.GET_B2C_CATEGORIES_FILTER_ERROR,
        payload: error
      })
    );
};

export const getB2BCategoriesFilter = (search = "") => dispatch => {
  dispatch({
    type: FilterActionTypes.GET.B2B_CATEGORIES.GET_B2B_CATEGORIES_FILTER_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2b/category_parent/?search=${search}`)
    .then(response =>
      dispatch({
        type:
          FilterActionTypes.GET.B2B_CATEGORIES
            .GET_B2B_CATEGORIES_FILTER_SUCCESS,
        payload: { count: response.data.count, results: response.data }
      })
    )
    .catch(error =>
      dispatch({
        type:
          FilterActionTypes.GET.B2B_CATEGORIES.GET_B2B_CATEGORIES_FILTER_ERROR,
        payload: error
      })
    );
};
