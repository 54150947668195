import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { BusinessCard } from "../../pages";
import { missingImage, profilePhone } from "../../assets";

import { colorPalette } from "../../styles/colorPalette";
import "./profile-card-preview.styles.scss";

const ProfileCardPreview = ({ userId, cardType, businessCardType }) => {
  return (
    <div className="profile-card-preview">
      <div className="profile-card-preview__img">
        <img src={profilePhone} alt="profile-phone"></img>
        <div className="profile-card-preview__img__absolute">
          {businessCardType ? (
            <BusinessCard />
          ) : (
            <img
              className="profile-card-preview__img__absolute__img-body"
              src={missingImage}
              alt=""
            />
          )}
          <div className="profile-card-preview__transparent" />
        </div>
      </div>
      {cardType && (
        <>
          <p>Business Card Preview</p>
          <p>Your Link:</p>
          <Link to={`/business-card/${userId}`}>
            <p
              style={{
                color: `${colorPalette.colorPrimary.main}`,
                cursor: "pointer"
              }}
            >
              {`${window.location.href}/${userId}`}
            </p>
          </Link>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  businessCardType: state.profile.businessCardType
});

export default connect(mapStateToProps, null)(ProfileCardPreview);
