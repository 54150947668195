export default function companyDetailsComponent({ editor, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function tabs($classPrefix) {
      const tabsEls = document.querySelectorAll(`.${$classPrefix}__tab`);
      Array.prototype.slice
        .call(tabsEls)
        .map(tabEl => {
          tabEl.addEventListener("click", e => {
            e.stopImmediatePropagation();

            Array.prototype.slice
              .call(tabsEls)
              .map(otherTabEl =>
                otherTabEl.classList.remove(`${$classPrefix}__tab--active`)
              );

            tabEl.classList.add(`${$classPrefix}__tab--active`);
            const selector = tabEl.dataset.tabSelector;
            const contentEl = document.querySelector(selector);
            contentEl && (contentEl.style.display = "block");

            tabs($classPrefix);
          });
          return tabEl;
        })
        .filter(
          tabEl => !tabEl.classList.contains(`${$classPrefix}__tab--active`)
        )
        .map(tabEl => {
          const selector = tabEl.dataset.tabSelector;
          const contentEl = document.querySelector(selector);
          return contentEl && (contentEl.style.display = "none");
        });
    }

    function makeCompanyDescriptionHtml(
      src,
      title,
      description,
      shortDescription,
      metadata,
      address,
      slogan,
      parent,
      is_active,
      staff,
      images,
      videos,
      documents,
      pages,
      translations
    ) {
      return `
      <div class="company-details">
        <img class="company-details__image" src="${src}" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />
        <div class="company-details__main">
          <div class="company-details__header">
            <h4 class="company-details__title">${title}</h4>
          </div>
          
          ${
            slogan
              ? `
              <p class="company-details__slogan">
                ${slogan}
              </p>
              `
              : ""
          }
          
          <div class="company-details__description company-details__description--short">
          ${shortDescription || translations["No short description..."]}
          </div>

          ${
            parent && parent.name
              ? `
                <div class="company-parent">
                  ${
                    parent.logo
                      ? `
                        <img class="company-parent__logo" src="${parent.logo}" alt="${parent.name} logo">
                      `
                      : ""
                  }
                  <span class="company-parent__name">
                    ${parent.name}
                  </span>
                </div>
              `
              : ``
          }
        </div>

        <div class="company-details__additional">
          <div class="company-details__tabs">
            <span
              class="company-details__tab company-details__tab--active"
              data-tab-selector=".company-details__tab-content--about"
            >
              ${translations["About"]}
            </span>
            <span class="company-details__tab-separator">|</span>
            <span
              class="company-details__tab"
              data-tab-selector=".company-details__tab-content--products"
            >
              ${translations["Products"]}
            </span>
            <span class="company-details__tab-separator">|</span>
            <span
              class="company-details__tab"
              data-tab-selector=".company-details__tab-content--files"
            >
              ${translations["Files"]}
            </span>
            <span class="company-details__tab-separator">|</span>
            <span
              class="company-details__tab"
              data-tab-selector=".company-details__tab-content--structure"
            >
              ${translations["Structure"]}
            </span>
            <span class="company-details__tab-separator">|</span>
            <span
              class="company-details__tab"
              data-tab-selector=".company-details__tab-content--contacts"
            >
              ${translations["Contacts"]}
            </span>
          </div>

          <div class="company-details__tab-content company-details__tab-content--about">
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Description"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <div class="company-details__description">
                ${description}
              </div>
            </div>
            ${pages
              .map(
                page => `
                  <div class="company-details__tab-header">
                    <h1 class="company-details__tab-title">
                      ${page.title}
                    </h1>
                  </div>
                  <div class="company-details__tab-main">
                     <div class="company-details__description company-details__description--page">
                      ${page.content}
                    </div>
                  </div>
                `
              )
              .join("")}
          </div>

          <div class="company-details__tab-content company-details__tab-content--products">
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Products"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <div class="company-details__products">
              </div>
            </div>
          </div>

          <div class="company-details__tab-content company-details__tab-content--files">
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Photo gallery"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <div class="company-gallery">
              ${images
                .map(
                  src => `
                    <img
                      class="company-gallery__image"
                      src="${src}"
                      alt="gallery image"
                      onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                    />
                  `
                )
                .join("")}
              </div>
            </div>
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Video gallery"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <div class="company-gallery">
              ${videos
                .map(
                  src => `
                    <video class="company-gallery__video" controls>
                      <source
                        src="${src}"
                        type="video/mp4"
                      >
                      ${translations["Your browser does not support the video tag."]}
                    </video>
                  `
                )
                .join("")}
              </div>
            </div>
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Documents"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <div class="company-gallery">
              ${documents
                .map(
                  item => `
                    <a class="company-gallery__document" href="${item.document}">
                      <div class="company-gallery__document-icon">
                        <svg version="1.1" height="100%" width="100%" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 317.001 317.001" style="enable-background:new 0 0 317.001 317.001;" xml:space="preserve">
                          <path d="M270.825,70.55L212.17,3.66C210.13,1.334,207.187,0,204.093,0H55.941C49.076,0,43.51,5.566,43.51,12.431V304.57
                          c0,6.866,5.566,12.431,12.431,12.431h205.118c6.866,0,12.432-5.566,12.432-12.432V77.633
                          C273.491,75.027,272.544,72.51,270.825,70.55z M55.941,305.073V12.432H199.94v63.601c0,3.431,2.78,6.216,6.216,6.216h54.903
                          l0.006,222.824H55.941z" />
                        </svg>
                      </div>
                      <div class="company-gallery__document-name">
                        ${item.name}
                      </div>
                    </a>
                  `
                )
                .join("")}
              </div>
            </div>
          </div>
          <div class="company-details__tab-content company-details__tab-content--structure">
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Staff"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <table class="company-staff">
                <thead>
                  <tr>
                    <th class="company-staff__header">${["PHOTO"]}</th>
                    <th class="company-staff__header">${["NAME"]}</th>
                    <th class="company-staff__header">${[
                      "DEPARTMENT / POSITION"
                    ]}</th>
                  </tr>
                </thead>
                <tbody>
                ${staff
                  .filter(item => !item.is_hidden_user)
                  .map(
                    item => `
                    <tr class="company-staff__row">
                      <td>
                        <img
                          class="company-staff__image"
                          src="${item.profile.length && item.profile[0].avatar}"
                          alt="${item.name} avatar image"
                          onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=='"
                        >
                      </td>
                      <td>
                        <span class="company-staff__name">
                          ${item.profile.length && item.profile[0].first_name}
                          ${item.profile.length && item.profile[0].middle_name}
                          ${item.profile.length && item.profile[0].last_name}
                        </span>
                      </td>
                      <td>
                        <span class="company-staff__department">
                          ${item.department.name}
                        </span>
                        <span class="company-staff__department-separator">
                          /  
                        </span>
                        <span class="company-staff__position">
                          ${item.name}
                        </span>
                      </td>
                    </tr>
                    `
                  )
                  .join("")}
                </tbody>
              </table>
            </div>
          </div>

          <div class="company-details__tab-content company-details__tab-content--contacts">
            <div class="company-details__tab-header">
              <h1 class="company-details__tab-title">
                ${translations["Contacts"]}
              </h1>
            </div>
            <div class="company-details__tab-main">
              <div class="company-contacts">
                <div class="company-contacts__item">
                <img class="company-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACrUlEQVRIibWWTUtUURjHf/PiiFqKGrhxYYzlF5gpepNWBUbQqlVR0GcI6hPUl0iCti2ELIlwk5GSuhEE6QXEjRvFFxR1HGfif3huzIz3nJs5/eEwwznP+f/ufe5zXlJjY2MkKAXcAO4A14BBoNumbAA/ga/AODAFVEN22cBYGngMPDdInPqs6UGeGvwF8Bqo+EzjdB6YAV4FYHEatDkz5vFXwJvAN6B4AlCjiuYxnAS8BXwEzp0CFkken4DbPuAQ8BbINQEWKWeeQ43ADPAGONtEWKQz5p2pBT465TdLUtEq3i2LlJW+V+3t7RSLRbq6ujg6OqoLy2QybG1tMTc3x+7ubsjmGTCatUoKln6pVGJ7e5tKJXZpOZBiEiTGsIAjvoje3l6y2SzVapXV1VVSqVRsnMZ7enpc7MHBAWtraz7LEQGv+kYLhQKtra3OUG+n30Zo1KdUb25u0tHRweTkpM/yioAXfKPpdNqZqel/SHqg/f19BwzoYrpmIz6mxgIJqaWlhf7+fldEAXWHNu+6tKmFpCwkwJyydsT0+VJ6eHjI8vIye3t7XkNlQktnYGAgKfUbGv3hfRqrOgFlqgLK5XJ1TX3lcpmVlRUXqzkBfdfoNHA9LkZGam1tbeTzeTo7O2OttEYXFxddNhKA0xp9b4fnMc3OzrrqE9S36Kmp0IWFhaSUfhDws53Ux3ab9fV196tSDxnp2wqqNw1IjKm03UFehiJVoUqXmt6ktqlP21ooAyYxKtElKmPXgkLSrH/UPHBZBR3lSSv8IbDzH2DyfGCMuhN/Cbivw6GJsJJ5LkUdjZUwAdxr0pvumNdEbWdc6U3YCT1/CpjmyqMO5gNKSsEl4Anw6wQgxWqO5v5JY61C24LqfNRu0dqJ7trZmW+46guiq/474Ivvxu0E/AaFRwE/APSLewAAAABJRU5ErkJggg==">
                  <div class="company-contacts__meta">
                    <h4 class="company-contacts__title">
                      ${translations["Fax:"]}
                    </h4>
                    <p class="company-contacts__text">
                      ${
                        metadata && metadata.fax
                          ? metadata.fax
                          : translations["N/A"]
                      }
                    </p>
                  </div>
                </div>

                <div class="company-contacts__item">
                  <img class="company-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACeUlEQVRIibWWz2vTYBjHP0lDd9HDyqALDQxrtYfBDoN16NbSk8JE8ORJUfBvENxf4P4IlYFXDwOnRbzZzg1nWQ49lHZlK/113KHNZeDiIW+065K3ydZ9IZc87/P9JHnfPM+jbG1tMUYKkAUeAStACpgWsRPgEPgJbANFwJaZaZKYCrwE1gXES3FxrQCvBfwtsAmc+Zl66RawB7yXwLyUEjl7wiMQMA/8ApZCgEa1JDxy44APgG/AzBVgrmaA78BDP2Aa+AREJwBzFRWe6VFgBPgI3JwgzNUN4R0ZBr7gans2Tks4Jx4V5z9bv0aYqzeAouGcJM+jn8lk0HU9lGu322V/f98rlAJyKrDmlzw7OxsKBhCPx2XhNRW47xc9Pj4ODTw6OpKF76nAHVlyvV7HtqXlEQDbtqnVarRaLdmyuyr/C/EF5XI5+v0+pVIJy7J8XSzLolgsYlkW2WxWBpyWFW80TWNxcZFer8fOzg7pdJq5ublza9yvsLCwEGjPNZwWI91pXdeJxWKYpkmv12N+fh6ASqVCJBIhn88TjQYqUCcaUB8HBJiammJ5eZl2u83BwQGKopBMJkkkEkFArmoasAusBs0wDAPDMMJAhrWrAl8um30JfdWAHzid+kK1aTab6LqOoiiB3GzbptPp+IUPgaKGM4NsAO9GV5imiWmawZ59vDaAM7dbbAK/J+XsobJg/GtPf4DnwOAaYAPgmWCc6/hV4ClwOkHYqfCsujdGZ5oC8ITJvOlAeBWGb3pNbQWcDl2+AqwsPAqjAb+5tApkgFdAIwSoIXIyDH3GYcmK9xnwAed0rQKPcXrnbc6P+g2cUf8zUMJn4nb1F1/SousFBVc6AAAAAElFTkSuQmCC">
                  <div class="company-contacts__meta">
                    <h4 class="company-contacts__title">
                      ${translations["Phone:"]}
                    </h4>
                    ${
                      metadata.phone
                        ? `
                          <a class="company-contacts__link" href="tel:${metadata.phone}">
                            ${metadata.phone}
                          </a>
                          `
                        : `<p class="company-contacts__text">${translations["N/A"]}</p>`
                    }
                  </div>
                </div>

                <div class="company-contacts__item">
                  <img class="company-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACxUlEQVRIia2WTUsbURSGn/lgVDKBBAOBIMUaEzcjCqmW1kS7asFS2lVx0dJCf0Oh/oL6I2wpdNuF0I9QuquxSlC6cKGLiChIooJxkU2iMV3MjWbS+YhOXgjkzD33febO3Dn3SEtLS3hIAjLAY2AKGAbCYqwMFIA/wDdgGWi4makuYzLwGpgXEDtFxW8KeCvg74FPwIWTqZ1uA2vABxeYnYbFnDXh0RHwAZAHJq4BateE8Jj2Aj4EfgIRH7CmIsAv4JETcAT4AmhdgDWlCc+RdqACfAaCXYQ1pQtvpRX4Cpt3JkkS8XgcwzBQVbcN7akJzB2PjPmdzdtlJRIJDMMgHo8zMzNDb2+vH+g7QJIxd5Lt1h8cHLz8r+s66XQaTbvxKx4GpmVg1imjr6/PEgcCAfr7+28KBJiVgftOo+fn55b48PCQUqnkB3hPBhJOo8fHx5Z4a2uLRsO1VHopKXNViP9ToVCwxGNjY0iS5AcYdqqlAJycnLC3t3eVHQ5jGIYfIDLmEeOozc1NTk9PL+OhoSHGx8dRFAUATdNIJpPout4Jr6zMzc09BW45ZTQaDYrFItFolJ6eHgBCoRCxWIyzszNSqRSxWIyBgQGOjo6oVqtuwL8ysOp1W7VajZWVFctKdV0nlUoRCAQuV5rJZAiFQm5WqzLw3QvYhOZyOQ4ODhxzVFVldHTUzeaHDPzGPKk9Va/XWV9fJ5/PU6lUbHP29/edpheAZRWzB1kAFjuBAhSLRUqlEpFIhGg0SjAYpFqtsru7S7nsuAcXgAtJNFEKZltwp1PoNbUB3AXqze+wDrwE7J+TP1WAF4JhOfG3gedArYuwmvDcbl5orzRZ4BndWWlFeGVbL9qVtizmCb3hA7YhPLLtA061dBuYBN4AO9cA7Yg5k7Q8xla5NSoXwEfMLjoNPME8O+NYW/0dzFb/K5DDoeNu6h+jgbD8/nS84QAAAABJRU5ErkJggg==">
                  <div class="company-contacts__meta">
                    <h4 class="company-contacts__title">
                      ${translations["E-mail:"]}
                    </h4>
                    ${
                      metadata.email
                        ? `
                          <a class="company-contacts__link" href="mailto:${metadata.email}">
                            ${metadata.email}
                          </a>
                          `
                        : `<p class="company-contacts__text">${translations["N/A"]}</p>`
                    }
                  </div>
                </div>

                <div class="company-contacts__item">
                  <img class="company-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAD9klEQVRIibWWW2tidxTFf8bjJGpMjlFjEBRzaSdINITUKalTaQi0MKVlnkoeWlroZ2jpfIJ+iimFvvZB6CU0fashl5aUiOZCGxMDQiKaY8SoIRy1D+eYyeX8nbZpF/jg3mevdS77v/Y2JRIJXgIT8CbwLhADJgCnnisD+8Aq8D2QBNrdyKQuuR7gE+CZLmIEr/6LAZ/p4l8CXwMtEakRRoF14HkXMSNM6DXrOsffEnwL+BWI/gOh24jqHPGXCb4N/AS47yHWgRv4GXhHJPgQ+BZ48B+IdfBA53zYCXSaxgx8AzhElQMDAwSDQVwuFzabjXa7Tb1ep1QqkcvlOD8/F5X269xzQLMj+DGCb2Y2mwmHwwQCARRFweFwkM/nkSSJkZERms0mY2NjHB4ekslkaLcNT0UUreOf96Cds2cisVgshsvlYmVlhf39fZrNJqlUiq2tLdrtNru7u6yurjIyMsLc3Bwmk0n0pF8Aph60TjJs/enpaSwWC8lkEkVR8Hq9FItFms0ml5eXKIrC8PAwpVKJZDKJ3W4nHA6LBCeAeA/wxCgryzJ+v590Ok2r1UKSJGRZplKpIEkSkiRxdnbG0NAQkiShqiqZTIbR0VH6+/tFok9MiUQiCTy+nZmZmSEQCIgKu+Lg4IB0Om2USkrAK0YZt9tNOp3m+PgY0L7nwsICa2trVKtVAJxOJ9FolOXl5as6v9+Pz+cT3curEi+M+AasViuKotBoNACwWCwAVKvVO7GLi4ur7iyXy0xMCN3QKfLS/w0S2ojx3k40Gg08Hg+tlmb6ZrMZ0F5j58lkWQbA4XjhF263m3q9LtIrS8CfRoKlUolQKEQoFLoRj0bv+sP8/PyN/9lsViT4hwSsYdClBwcHBAIB1tfXOT09BSAWi3FycnJFODk5yeDgIBsbGwB4vV5mZ2c5PDwUCa71AD8YZSqVCkdHR0QiEcxmM6qqUi6XkWUZVVVRVRVZllEUBVVVsVgsTE1Nkc1mqdVqIsEfe4Bf0Cb1HaRSKer1OvF4HI/HQ6FQwOPxIEkSvb29OJ1OCoUCXq+XeDxOpVJhZ2dHJLYPJM2Li4sAdeB9o6vy+TxWq5VIJILD4cBms2G32/H5fNjtdmRZZnx8nFwud+WvAnwO/G7Slygz2lrwmuhqu91OMBjE7XbT19cHaJ1cLBbJ5XJXZ1OATeB1ro2nJvAR8Bva/LqDWq3G9vZ2N1IRzoEPdY0bE38P+AC4/DesAlzqnHudwG2nWQKe6nd1X5zrXEvXg0bWtoQ2oTfvIbapcyzdToi8dA94BHwKCG3DAFm95hHXXuN1dNu8W8BXaFv0Y+A94A1gnJurfhZt1f8OWEGwcXfwF986UaqqFoh4AAAAAElFTkSuQmCC">
                  <div class="company-contacts__meta">
                    <h4 class="company-contacts__title">
                     ${translations["Site:"]}
                    </h4>
                    ${
                      metadata.site
                        ? `
                          <a class="company-contacts__link" href="${metadata.site}">
                            ${metadata.site}
                          </a>
                          `
                        : `<p class="company-contacts__text">${translations["N/A"]}</p>`
                    }
                  </div>
                </div>
              </div>

              <div class="company-map">
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?q=${
                  metadata.location
                }&z=14&amp;output=embed"
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".company-details-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("company-details-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("company-details-wrapper--preloader");
      }
    }

    function render(id, translations) {
      const API_ROOT = "{[ apiRoot ]}";
      const STATIC_ROOT = "{[ staticRoot ]}";

      setLoading(true);
      fetch(`${API_ROOT}/organization/company/${id}/`)
        .then(response => response.json())
        .then(company => {
          const el = document.querySelector(".company-details-wrapper");
          const productsTypeSwitcherEl = el.querySelector(
            ".gjs-products-type-switch"
          );
          const productsEl = el.querySelector(
            ".products-with-pagination-wrapper"
          );

          fetch(
            `${API_ROOT}/department/vacancy/?department__organization=${id}&page_size=500`
          )
            .then(response => response.json())
            .then(staff => {
              el.innerHTML = makeCompanyDescriptionHtml(
                company.logo,
                company.name,
                company.description,
                company.short_description,
                company.metadata,
                company.address,
                company.slogan,
                company.parent,
                company.is_active,
                staff && staff.results ? staff.results : [],
                company.galleries
                  ? company.galleries.reduce(
                      (tmp, item) =>
                        tmp.concat(
                          item.images && item.images.length
                            ? item.images.map(
                                item => `${STATIC_ROOT}/${item.image}`
                              )
                            : []
                        ),
                      []
                    )
                  : [],
                company.galleries
                  ? company.galleries.reduce(
                      (tmp, item) =>
                        tmp.concat(
                          item.videos && item.videos.length
                            ? item.videos.map(
                                item => `${STATIC_ROOT}/${item.video}`
                              )
                            : []
                        ),
                      []
                    )
                  : [],
                company.documents && company.documents.length
                  ? company.documents
                  : [],
                company.additional_pages && company.additional_pages.length
                  ? company.additional_pages
                  : [],
                translations
              );

              if (productsEl) {
                const tabContent = el.querySelector(
                  ".company-details__tab-content--products .company-details__tab-main"
                );
                const productsTabContent = tabContent.querySelector(
                  ".company-details__products"
                );

                productsEl.setAttribute("organization", company.id);
                productsTabContent.innerHTML = "";
                productsTabContent.append(productsEl);
                tabContent.prepend(productsTypeSwitcherEl);

                if (company.id !== window.products.organization) {
                  window.products.setProp("organization", company.id);
                  window.products.setParam("company__parent", "");
                  window.products.setParam("company", company.id);
                  window.products.getProducts();
                }
              }

              setLoading(false);

              tabs("company-details");
            });
        });
    }

    const params = new URLSearchParams(window.location.search);
    const id = params.get("gjs-company-id");

    const intervalRand = Math.random()
      .toString()
      .slice(-8);
    const intervalName = `waitForProductsInterval${intervalRand}`;
    window[intervalName] = setInterval(() => {
      if (
        window.hasOwnProperty("products") &&
        window.hasOwnProperty("grapesjs")
      ) {
        // Get translation && render
        const translations = getTranslate("translations", this.attributes);
        if (translations && Object.keys(translations).length) {
          render(id, translations);
        }
        clearInterval(window[intervalName]);
      }
    }, 100);

    if (!window.hasOwnProperty("grapesjs")) {
      tabs("company-details");
    }
  };

  editor.DomComponents.addType("company-details", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "company-details-wrapper",
    model: {
      defaults: {
        name: "Company Details",
        apiRoot: api.API_ROOT,
        staticRoot: api.STATIC_ROOT,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "N/A",
              "No short description...",
              "About",
              "Files",
              "Contacts",
              "Structure",
              "Description",
              "Photo gallery",
              "Video gallery",
              "Your browser does not support the video tag.",
              "Products",
              "Documents",
              "Staff",
              "PHOTO",
              "NAME",
              "DEPARTMENT / POSITION",
              "Fax:",
              "Phone:",
              "E-mail:",
              "Site:"
            ]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
