export const EventsActionTypes = {
  GET: {
    GET_EVENTS_PENDING: "GET_EVENTS_PENDING",
    GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
    GET_EVENTS_ERROR: "GET_EVENTS_ERROR",

    GET_EVENTS_BY_ME_PENDING: "GET_EVENTS_BY_ME_PENDING",
    GET_EVENTS_BY_ME_SUCCESS: "GET_EVENTS_BY_ME_SUCCESS",
    GET_EVENTS_BY_ME_ERROR: "GET_EVENTS_BY_ME_ERROR",

    SEARCH_EVENTS_BY_ME_PENDING: "SEARCH_EVENTS_BY_ME_PENDING",
    SEARCH_EVENTS_BY_ME_SUCCESS: "SEARCH_EVENTS_BY_ME_SUCCESS",
    SEARCH_EVENTS_BY_ME_ERROR: "SEARCH_EVENTS_BY_ME_ERROR",

    BY_ID: {
      GET_EVENT_BY_ID_PENDING: "GET_EVENT_BY_ID_PENDING",
      GET_EVENT_BY_ID_SUCCESS: "GET_EVENT_BY_ID_SUCCESS",
      GET_EVENT_BY_ID_ERROR: "GET_EVENT_BY_ID_ERROR"
    }
  },
  POST: {
    POST_EVENT_PENDING: "POST_EVENT_PENDING",
    POST_EVENT_SUCCESS: "POST_EVENT_SUCCESS",
    POST_EVENT_ERROR: "POST_EVENT_ERROR"
  },
  PUT: {
    PUT_EVENT_PENDING: "PUT_EVENT_PENDING",
    PUT_EVENT_SUCCESS: "PUT_EVENT_SUCCESS",
    PUT_EVENT_ERROR: "PUT_EVENT_ERROR"
  },
  DELETE: {
    DELETE_EVENT_BY_ID_PENDING: "DELETE_EVENT_BY_ID_PENDING",
    DELETE_EVENT_BY_ID_SUCCESS: "DELETE_EVENT_BY_ID_SUCCESS",
    DELETE_EVENT_BY_ID_ERROR: "DELETE_EVENT_BY_ID_ERROR"
  },
  CLEAR_EVENT_REQUEST: "CLEAR_EVENT_REQUEST"
};
