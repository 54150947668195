import React from "react";
import "./comp-org-main-tab.styles.scss";

import OrganizationDescription from "../organization-description/organizations-description.component";
import ImgCard from "../img-card/img-card.components";

const CompOrgMainTab = ({ companySelected, news }) => {
  return (
    <div className="comp-org-main-tab">
      <OrganizationDescription companySelected={companySelected} />
      {news.length > 0 && (
        <div
          style={{ textAlign: "center", margin: "20px 0", fontSize: "20px" }}
        >
          News
        </div>
      )}

      {news.map(({ id, image, short_description, title }) => (
        <ImgCard
          navLink={`/news/${id}`}
          key={id}
          shortDescription={title}
          text={short_description}
          imgSrc={image}
          type="regular"
        />
      ))}
    </div>
  );
};

export default CompOrgMainTab;
