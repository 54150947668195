import icon from "./icon.png";

export default function privatePageBlock({ editor, api }) {
  const blockManager = editor.BlockManager;

  blockManager.add("private-page", {
    label: `
      <img src="${icon}" alt="Private page block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Private Page
      </div>
    `,
    content: {
      type: "private-page"
    },
    category: "B24Online Auth"
  });
}
