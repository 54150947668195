export const OrdersActionTypes = {
  GET_ORDERS_PENDING: "GET_ORDERS_PENDING",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_ERROR: "GET_ORDERS_ERROR",

  GET_ORDERS_BY_COMPANY_PENDING: "GET_ORDERS_BY_COMPANY_PENDING",
  GET_ORDERS_BY_COMPANY_SUCCESS: "GET_ORDERS_BY_COMPANY_SUCCESS",
  GET_ORDERS_BY_COMPANY_ERROR: "GET_ORDERS_BY_COMPANY_ERROR",

  DELETE_DEAL_BY_ID_PENDING: "DELETE_DEAL_BY_ID_PENDING",
  DELETE_DEAL_BY_ID_SUCCESS: "DELETE_DEAL_BY_ID_SUCCESS",
  DELETE_DEAL_BY_ID_ERROR: "DELETE_DEAL_BY_ID_ERROR"
};
