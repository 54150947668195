import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

import { missingImage } from "../../../assets";
import { withDebounce } from "../../../shared";

import {
  MyBusinessNoData,
  SearchBar,
  LoadingSpinner,
  CompanySmallCard,
  Pagination,
  ConfirmDialog
} from "../../../components";

import {
  getUserOrganizationsChamber,
  deleteOrganization
} from "../../../redux/actions-exporter";

import "./my-business-organizations.styles.scss";

const MyBusinessOrganizations = ({
  appDirection,
  userOrganizationsChamber,
  userOrganizationsChamberCount,
  getUserOrganizationsChamber,
  areUserOrganizationsPending,
  deleteOrganization
}) => {
  const [searchOrganization, setSearchOrganization] = useState("");
  const [organizationsPage, setOrganizationsPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(0);

  useEffect(() => {
    getUserOrganizationsChamber(searchOrganization, organizationsPage);
  }, [getUserOrganizationsChamber, searchOrganization, organizationsPage]);

  useEffect(() => {
    setOrganizationsPage(1);
  }, [searchOrganization]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [organizationsPage]);

  const searchOrganizationsHandler = withDebounce(e =>
    setSearchOrganization(e)
  );

  return (
    <div className="my-business-companies">
      <ConfirmDialog
        dialogTitle="Organization remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteOrganization(currentOrganization);
          setDialogOpen(false);
          setCurrentOrganization(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => searchOrganizationsHandler(e.target.value)} />
      </div>

      {areUserOrganizationsPending ? (
        <LoadingSpinner />
      ) : (
        <>
          {userOrganizationsChamber && userOrganizationsChamber.length ? (
            userOrganizationsChamber.map(
              ({ id, name, short_description, logo, metadata, countries }) => (
                <CompanySmallCard
                  key={id}
                  name={name}
                  logo={logo ? logo : missingImage}
                  description={parse(short_description)}
                  location={countries[0] && countries[0].name}
                  phone={metadata && metadata.phone}
                  website={metadata && metadata.site}
                  editorState={true}
                  editLink={`/my-business/organizations/${id}`}
                  removeFunc={() => {
                    setDialogOpen(true);
                    setCurrentOrganization(id);
                  }}
                  scaleDown={true}
                  appDirection={appDirection}
                  id={id}
                />
              )
            )
          ) : (
            <MyBusinessNoData noDataText="No organizations found!" />
          )}

          <Pagination
            forcePage={organizationsPage - 1}
            dataCount={userOrganizationsChamberCount}
            itemsPerPage={10}
            selectedPage={page => setOrganizationsPage(page)}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  userOrganizationsChamber: state.organizationsReducer.userOrganizationsChamber,
  userOrganizationsChamberCount:
    state.organizationsReducer.userOrganizationsChamberCount,
  areUserOrganizationsPending:
    state.organizationsReducer.areUserOrganizationsPending
});

export default connect(mapStateToProps, {
  getUserOrganizationsChamber,
  deleteOrganization
})(MyBusinessOrganizations);
