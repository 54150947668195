import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";

import { LoadingSpinner, CompanyDocument, Separator } from "../../components";

import { getResumeById } from "../../redux/actions-exporter";

import "./resume.details.styles.scss";

const ResumeDetails = ({
  getResumeById,
  isResumeByIdPending,
  resumeById: {
    title,
    first_name,
    last_name,
    marital_status,
    nationality,
    telephone_number,
    address,
    faculty,
    profession,
    study_start_date,
    study_end_date,
    study_form,
    company_exp_1,
    position_exp_1,
    start_date_exp_1,
    end_date_exp_1,
    company_exp_2,
    position_exp_2,
    start_date_exp_2,
    end_date_exp_2,
    company_exp_3,
    position_exp_3,
    start_date_exp_3,
    end_date_exp_3,
    new_experience,
    new_education,
    additional_study,
    language_skill,
    computer_skill,
    additional_skill,
    salary,
    additional_information,
    institution,
    documents
  }
}) => {
  const { resumeId } = useParams();

  useEffect(() => {
    getResumeById(resumeId);
  }, [getResumeById, resumeId]);

  return (
    <div className="resume-details">
      {isResumeByIdPending ? (
        <LoadingSpinner />
      ) : (
        <Card>
          <CardContent>
            <h2 className="resume-title">{title}</h2>

            {first_name && (
              <div className="resume-details__row">
                First name: <strong>{first_name}</strong>
              </div>
            )}
            {last_name && (
              <div className="resume-details__row">
                Last name: <strong>{last_name}</strong>
              </div>
            )}
            {marital_status && (
              <div className="resume-details__row">
                Marital status:{" "}
                <strong>{marital_status && marital_status.label}</strong>
              </div>
            )}
            {nationality && (
              <div className="resume-details__row">
                Nationality: <strong>{nationality}</strong>
              </div>
            )}
            {telephone_number && (
              <div className="resume-details__row">
                Telephone number: <strong>{telephone_number}</strong>
              </div>
            )}
            {address && (
              <div className="resume-details__row">
                Address: <strong>{address}</strong>
              </div>
            )}
            {faculty && (
              <div className="resume-details__row">
                Faculty: <strong>{faculty}</strong>
              </div>
            )}
            {profession && (
              <div className="resume-details__row">
                Profession: <strong>{profession}</strong>
              </div>
            )}
            {study_start_date && (
              <div className="resume-details__row">
                Study start: <strong>{study_start_date}</strong>
              </div>
            )}
            {study_end_date && (
              <div className="resume-details__row">
                Study end: <strong>{study_end_date}</strong>
              </div>
            )}
            {study_form && (
              <div className="resume-details__row">
                Study form: <strong>{study_form && study_form.label}</strong>
              </div>
            )}
            {company_exp_1 && (
              <div className="resume-details__row">
                Company 1: <strong>{company_exp_1}</strong>
              </div>
            )}
            {position_exp_1 && (
              <div className="resume-details__row">
                Position 1: <strong>{position_exp_1}</strong>
              </div>
            )}
            {start_date_exp_1 && (
              <div className="resume-details__row">
                Start date 1: <strong>{start_date_exp_1}</strong>
              </div>
            )}
            {end_date_exp_1 && (
              <div className="resume-details__row">
                End date 1: <strong>{end_date_exp_1}</strong>
              </div>
            )}
            {company_exp_2 && (
              <div className="resume-details__row">
                Company 2: <strong>{company_exp_2}</strong>
              </div>
            )}
            {position_exp_2 && (
              <div className="resume-details__row">
                Position 2: <strong>{position_exp_2}</strong>
              </div>
            )}
            {start_date_exp_2 && (
              <div className="resume-details__row">
                Start date 2: <strong>{start_date_exp_2}</strong>
              </div>
            )}
            {end_date_exp_2 && (
              <div className="resume-details__row">
                End date 2: <strong>{end_date_exp_2}</strong>
              </div>
            )}
            {company_exp_3 && (
              <div className="resume-details__row">
                Company 3: <strong>{company_exp_3}</strong>
              </div>
            )}
            {position_exp_3 && (
              <div className="resume-details__row">
                Position 3: <strong>{position_exp_3}</strong>
              </div>
            )}

            {start_date_exp_3 && (
              <div className="resume-details__row">
                Start date 3: <strong>{start_date_exp_3}</strong>
              </div>
            )}
            {end_date_exp_3 && (
              <div className="resume-details__row">
                End date 3: <strong>{end_date_exp_3}</strong>
              </div>
            )}
            {new_experience && !!new_experience.length && (
              <>
                <Separator />
                {new_experience.map(
                  ({ company, position, date_start, date_end }, index) => (
                    <Fragment key={index}>
                      <div className="resume-details__row">
                        Company: <strong>{company}</strong>
                      </div>
                      <div className="resume-details__row">
                        Position: <strong>{position}</strong>
                      </div>
                      <div className="resume-details__row">
                        Date start: <strong>{date_start}</strong>
                      </div>
                      <div className="resume-details__row">
                        Date end: <strong>{date_end}</strong>
                      </div>
                      {new_experience.length - 1 !== index && <Separator />}
                    </Fragment>
                  )
                )}
              </>
            )}
            {new_education && !!new_education.length && (
              <>
                <Separator />
                {new_education.map(
                  (
                    {
                      faculty,
                      profession,
                      study_start_date,
                      study_end_date,
                      study_form
                    },
                    index
                  ) => (
                    <Fragment key={index}>
                      <div className="resume-details__row">
                        Faculty: <strong>{faculty}</strong>
                      </div>
                      <div className="resume-details__row">
                        Proffesion: <strong>{profession}</strong>
                      </div>
                      <div className="resume-details__row">
                        Date start: <strong>{study_start_date}</strong>
                      </div>
                      <div className="resume-details__row">
                        Date end: <strong>{study_end_date}</strong>
                      </div>
                      <div className="resume-details__row">
                        Study form:{" "}
                        <strong>{study_form && study_form.label}</strong>
                      </div>
                      {new_education.length - 1 !== index && <Separator />}
                    </Fragment>
                  )
                )}
              </>
            )}
            <Separator />
            {additional_study && (
              <div className="resume-details__row">
                Additional study: <strong>{additional_study}</strong>
              </div>
            )}
            {additional_skill && (
              <div className="resume-details__row">
                Additional skill: <strong>{additional_skill}</strong>
              </div>
            )}
            {language_skill && (
              <div className="resume-details__row">
                Language skill: <strong>{language_skill}</strong>
              </div>
            )}
            {computer_skill && (
              <div className="resume-details__row">
                Computer skill: <strong>{computer_skill}</strong>
              </div>
            )}
            {salary && (
              <div className="resume-details__row">
                Salary expected: <strong>{salary}</strong>
              </div>
            )}
            {additional_information && (
              <div className="resume-details__row">
                Additional information:{" "}
                <strong>{additional_information}</strong>
              </div>
            )}
            {institution && (
              <div className="resume-details__row">
                Institution: <strong>{institution}</strong>
              </div>
            )}
            <Separator />
            {documents && documents.document ? (
              <>
                <h3>CV</h3>
                <div className="comp-org-files-tab__documents-container">
                  <div className="comp-org-files-tab__documents-container__documents">
                    <CompanyDocument
                      key={document.id}
                      img="https://via.placeholder.com/300x200"
                      name={documents.name}
                      file={documents.document}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="comp-org-files-tab__no-files">
                No CV added yet
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  resumeById: state.resumesReducer.resumeById,
  isResumeByIdPending: state.resumesReducer.isResumeByIdPending
});

export default connect(mapStateToProps, { getResumeById })(ResumeDetails);
