export const b24URL = `//${process.env.REACT_APP_PORTAL_URL}`;
export const staticURL = `//${process.env.REACT_APP_STATIC_URL}/`;
export const _skip = undefined;
export const contentTypes = {
  organizationType: 76,
  companyType: 77,
  b2bProductType: 84,
  leadsType: 237,
  taskType: 239,
  subTaskType: 238,
  newsType: 88,
  eventType: 91,
  resumeType: 120,
  b2cProductType: 122
};
export const videoIsUploaded =
  "https://cdn.pixabay.com/photo/2016/08/20/06/09/video-1606945_960_720.png";

export const {
  organizationType,
  companyType,
  b2bProductType,
  newsType,
  eventType,
  resumeType,
  b2cProductType,
  leadsType,
  taskType,
  subTaskType
} = contentTypes;
