import "./gjs-products-category-trait.scss";
import ApiService from "../../../services/api";

export default ({ token, site }) =>
  function gjsProductsCategoryTrait(editor) {
    let state = {
      site,
      category: {},
      categories: []
    };

    const api = new ApiService({ token });

    const preloader = ({ remove = false }) => {
      // Preloader
      const modalContentEl = document.querySelector(".gjs-mdl-content");
      modalContentEl &&
        modalContentEl.classList.add("gjs-mdl-content--preloader");
      // Remove preloader
      modalContentEl &&
        remove &&
        modalContentEl.classList.remove("gjs-mdl-content--preloader");
    };

    const render = async props => {
      const { modal, productType, selectCategory } = props;
      // Set prelodaer
      preloader({ remove: false });
      // Get site categories
      const categories = await api.getProductsCategories({
        type: productType,
        organization: site.organization
      });
      // Get site subcategories
      const subcategories = await api.getProductsSubcategories({
        type: productType,
        organization: site.organization
      });
      // Create organization categories array
      const organizationCategories = categories.map(category => ({
        id: category.id,
        name: category.name,
        parent: null,
        children: subcategories.length
          ? subcategories
              .filter(subcategory => subcategory.parent === category.id)
              .map(subcategory => ({
                id: subcategory.id,
                name: subcategory.name,
                parent: category.id
              }))
          : []
      }));

      // Set categories to state
      state = {
        ...state,
        categories: organizationCategories
      };
      // Category html fabric
      const categoryItemHtml = ({ id, name, parent, isSubcategory }) => `
      <div class="gjs-products-category-trait-list__item">
        ${
          parent
            ? `
            <span class="gjs-products-category-trait-list__item-empty">
            </span>
            `
            : ""
        }
        <h5 class="gjs-products-category-trait-list__item-text" data-id="${id}" data-name="${name}">
          ${name}
          <span class="gjs-products-category-trait-list__item-capture">
          [id:${id}]
          </span>
        </h5>
        <div class="gls-category-trait-list__item-actions">
          <button
            type="button"
            id="select_category_button"
            class="gjs-products-category-trait-modal__button gjs-products-category-trait-modal__button-sm gjs-two-color"
            data-id="${id}"
            data-name="${name}"
            data-parent="${parent}"
          >
            <i class="fa fa-check"></i>
          </button>
        </div>
      </div>
      `;
      // Set modal content
      await modal.setContent(`
        <div class="gjs-products-category-trait-modal__content">
          <div class="gjs-mdl-title">
            List of categories
          </div>
          <div class="gjs-products-category-trait-list">
            ${
              !state.categories.length
                ? `<div class="gjs-mdl-title" style="margin-top: 15px;">No categories</div>`
                : ""
            }
            ${state.categories
              .map(
                ({ id, name, children }) =>
                  `
                  ${categoryItemHtml({ id, name, parent: null })}
                  ${children
                    .map(subcategory =>
                      categoryItemHtml({
                        ...subcategory,
                        parent: id
                      })
                    )
                    .join("")}
                  `
              )
              .join("")}
          </div>
        </div>
      `);

      // Delete buttons handler
      const selectCategoryButtonEls = document.querySelectorAll(
        "#select_category_button"
      );
      selectCategoryButtonEls &&
        [...selectCategoryButtonEls].map(selectCategoryButtonEl =>
          selectCategoryButtonEl.addEventListener("click", async e => {
            // Remove prelodaer
            preloader({ remove: false });
            // Get target id and set selected category
            const target =
              e.target.parentNode.nodeName === "BUTTON"
                ? e.target.parentNode
                : e.target;
            const id = parseInt(target.dataset.id);
            const name = target.dataset.name;
            const parent = target.dataset.parent;

            state = {
              ...state,
              category: { id, name, parent }
            };

            selectCategory(state.category);
          })
        );

      const categoryButtonEls = document.querySelectorAll(
        ".gjs-products-category-trait-list__item-text"
      );
      categoryButtonEls &&
        [...categoryButtonEls].map(categoryButtonEl =>
          categoryButtonEl.addEventListener("click", async e =>
            selectCategory({
              id: e.target.dataset.id,
              name: e.target.dataset.name
            })
          )
        );
      // Remove prelodaer
      preloader({ remove: true });
    };

    editor.TraitManager.addType("products-category-selector", {
      createInput({ component, trait }) {
        const el = document.createElement("div");
        const name = trait.attributes.name;
        const hasValue = component.attributes.attributes.hasOwnProperty(name);
        const currentValue = hasValue
          ? JSON.parse(component.attributes.attributes[name])
          : "";
        // Create element input
        el.innerHTML = `
          <input
            class="products-category-selector"
            placeholder="Select category"
            value="${
              currentValue.hasOwnProperty("name") ? currentValue.name : ""
            }"
          />
        `;
        // Opens modal window with categories after clicking on element
        el.addEventListener("click", () => {
          // Get modal
          this.modal = editor.Modal;
          // Set modal title
          this.modal.setTitle(trait.attributes.modalTitle);
          // Reset state
          state = {
            site,
            category: {},
            categories: []
          };
          // Start render modal content
          render({
            modal: this.modal,
            productType: component.attributes.attributes.hasOwnProperty(
              "producttype"
            )
              ? component.attributes.attributes["producttype"]
              : "b2c",
            selectCategory: this.selectCategory.bind(this)
          });
          // Open modal
          this.modal.open();
        });
        // Return element
        return el;
      },
      selectCategory(category) {
        // Set category
        this.category = category;
        // Use onChange to trigger onEvent
        this.onChange();
      },
      onEvent({ component, trait, elInput }) {
        // Set component attribute
        component.addAttributes({
          [trait.attributes.name]: JSON.stringify(this.category)
        });
        // Set element value
        elInput.querySelector("input").value = this.category.name;
        // Close categories modal
        this.modal.close();
      }
    });
  };
