import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import {
  LoadingSpinner,
  MyBusinessNoData,
  SearchBar,
  Pagination
} from "../../../components";

import { getEventsByMe } from "../../../redux/actions-exporter";

import "./my-business-event-invitations.styles.scss";
import { withDebounce } from "../../../shared";

const MyBusinessEventInvitations = ({
  eventsByMe,
  areEventsByMePending,
  getEventsByMe,
  eventsByMeCount
}) => {
  const [searchEvent, setSearchEvent] = useState("");
  const [eventPage, setEventPage] = useState(1);
  const { url } = useRouteMatch();

  useEffect(() => {
    getEventsByMe(searchEvent, eventPage);
  }, [getEventsByMe, searchEvent, eventPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [eventPage]);

  const eventSearchHandler = withDebounce(e => setSearchEvent(e));

  return (
    <div className="my-business-event-invitations">
      <div style={{ margin: "20px 0" }}>
        <SearchBar onChange={e => eventSearchHandler(e.target.value)} />
      </div>
      {areEventsByMePending ? (
        <LoadingSpinner />
      ) : eventsByMe && eventsByMe.length ? (
        eventsByMe.map(({ id, title, companies }) => (
          <div key={id} className="my-business-event-invitations__event-data">
            <div className="event-image">
              <Link to={`${url}/${id}`}>
                <img src={companies[0] && companies[0].logo} alt="" />
              </Link>
            </div>
            <div className="event-title">
              <Link to={`${url}/${id}`}>{title}</Link>
            </div>
          </div>
        ))
      ) : (
        <MyBusinessNoData noDataText="No events found!" />
      )}
      <Pagination
        forcePage={eventPage - 1}
        dataCount={eventsByMeCount}
        itemsPerPage={10}
        selectedPage={page => setEventPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areEventsByMePending: state.events.areEventsByMePending,
  eventsByMe: state.events.eventsByMe,
  eventsByMeCount: state.events.eventsByMeCount
});

export default connect(mapStateToProps, { getEventsByMe })(
  MyBusinessEventInvitations
);
