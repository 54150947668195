import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import {
  NavLink,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Card from "@material-ui/core/Card";

import { getCookie } from "../../axios/axios.config";
import {
  EventMain,
  EventDetailsCard,
  EventProducts,
  EventVisitors,
  EventCompany,
  EventMeeting,
  EventFiles,
  EventContacts,
  LoadingSpinner
} from "../../components";

import {
  getEventById,
  getInnovationProjectById,
  getBusinessProposalById,
  getNewsByCompanyId,
  addMeToEventById,
  getMeetingInvitationsByEvent,
  checkIsUserGoingToEventId
} from "../../redux/actions-exporter";

import "./event.styles.scss";

const Event = ({
  getEventById,
  isEventByIdPending,
  eventById,

  getBusinessProposalById,
  businessProposal,

  getInnovationProjectById,
  innovationProject,

  addMeToEventById,
  checkIsUserGoingToEventId,
  userEventGoingCheckStatus,
  getMeetingInvitationsByEvent,

  meetingInvitationsByEvent
}) => {
  const { path, url, params } = useRouteMatch();
  const eventId = params.eventTypeId;
  const eventType = params.eventType;

  const { eventTypeId } = useParams();

  useEffect(() => {
    if (eventType === "event") {
      getEventById(eventId);
      getMeetingInvitationsByEvent(eventId);
    } else if (eventType === "business-proposal") {
      getBusinessProposalById(eventId);
    } else if (eventType === "innovation-project") {
      getInnovationProjectById(eventId);
    }
    // eslint-disable-next-line
  }, [
    getEventById,
    getBusinessProposalById,
    getInnovationProjectById,
    eventType,
    eventId
  ]);

  useEffect(() => {
    if (getCookie("Authorization")) {
      checkIsUserGoingToEventId(eventId);
    }
  }, [eventId]); // eslint-disable-line

  return (
    <div className="event">
      {isEventByIdPending ? (
        <LoadingSpinner />
      ) : (
        <Card>
          {eventById.description && eventType === "event" && (
            <EventDetailsCard
              eventName={eventById.title}
              eventDescription={parse(eventById.description)}
              orgName={eventById.organization.name}
              orgId={eventById.organization.id}
              img={eventById.organization.logo}
              typeOfCompany={params.compOrg}
              addMeToEventById={addMeToEventById}
              eventDetailsId={eventTypeId}
              eventUserStatus={userEventGoingCheckStatus}
            />
          )}

          {businessProposal.description &&
            eventType === "business-proposal" && (
              <EventDetailsCard
                eventName={businessProposal.title}
                eventDescription={
                  businessProposal.description.length > 420
                    ? parse(
                        businessProposal.description.substring(0, 420) + " ..."
                      )
                    : parse(businessProposal.description)
                }
                orgName={businessProposal.organization.name}
                orgId={businessProposal.organization.id}
                img={businessProposal.organization.logo}
                going={false}
                typeOfCompany={params.compOrg}
              />
            )}

          {innovationProject.description &&
            eventType === "innovation-project" && (
              <EventDetailsCard
                eventName={innovationProject.name}
                eventDescription={
                  innovationProject.description.length > 420
                    ? parse(
                        innovationProject.description.substring(0, 420) + " ..."
                      )
                    : parse(innovationProject.description)
                }
                orgName={innovationProject.organization.name}
                img={innovationProject.organization.logo}
                orgId={innovationProject.organization.id}
                going={false}
                typeOfCompany={params.compOrg}
              />
            )}

          <div className="event__separator" />
          <div className="event__nav">
            <NavLink
              activeClassName="event__nav__selected"
              to={`${url}/main`}
              className="event__nav__link"
            >
              Main
            </NavLink>
            <div className="event__nav__separator" />
            {eventType === "event" && (
              <>
                <NavLink
                  activeClassName="event__nav__selected"
                  to={`${url}/offers`}
                  className="event__nav__link"
                >
                  Offers
                </NavLink>
                <div className="event__nav__separator" />
                <NavLink
                  activeClassName="event__nav__selected"
                  to={`${url}/visitors`}
                  className="event__nav__link"
                >
                  Visitors
                </NavLink>
                <div className="event__nav__separator" />
                <NavLink
                  activeClassName="event__nav__selected"
                  to={`${url}/company`}
                  className="event__nav__link"
                >
                  Company
                </NavLink>
                <div className="event__nav__separator" />
                <NavLink
                  activeClassName="event__nav__selected"
                  to={`${url}/meeting`}
                  className="event__nav__link"
                >
                  Meeting
                </NavLink>
                <div className="event__nav__separator" />
              </>
            )}

            <NavLink
              activeClassName="event__nav__selected"
              to={`${url}/files`}
              className="event__nav__link"
            >
              Files
            </NavLink>
            <div className="event__nav__separator" />
            <NavLink
              activeClassName="event__nav__selected"
              to={`${url}/contacts`}
              className="event__nav__link"
            >
              Contacts
            </NavLink>
          </div>

          {eventById.additional_pages && eventById.additional_pages.length ? (
            <div className="event__nav event__nav__nav-additional">
              {eventById.additional_pages.map(page => {
                return (
                  <Fragment>
                    <NavLink
                      activeClassName="event__nav__selected"
                      to={`${url}/additional-${page.id}`}
                      className="event__nav__link"
                    >
                      {page.title.length > 20
                        ? page.title.substring(0, 20) + "..."
                        : page.title}
                    </NavLink>
                    <div className="event__nav__separator" />
                  </Fragment>
                );
              })}
            </div>
          ) : null}

          <div className="event__separator event__separator-margin-0" />

          {eventType === "event" && eventById.description && (
            <Route
              path={`${path}/main`}
              component={() => (
                <EventMain
                  description={parse(eventById.description)}
                  organizationId={eventById.organization.id}
                />
              )}
            />
          )}
          {businessProposal.description &&
            eventType === "business-proposal" && (
              <Route
                path={`${path}/main`}
                component={() => (
                  <EventMain
                    description={parse(businessProposal.description)}
                    organizationId={businessProposal.organization.id}
                  />
                )}
              />
            )}
          {innovationProject.description &&
            eventType === "innovation-project" && (
              <Route
                path={`${path}/main`}
                component={() => (
                  <EventMain
                    description={parse(innovationProject.description)}
                    organizationId={innovationProject.organization.id}
                  />
                )}
              />
            )}

          <Route path={`${path}/offers`} component={EventProducts} />
          <Route path={`${path}/visitors`}>
            <EventVisitors visitors={eventById.visitors} />
          </Route>
          <Route path={`${path}/company`} component={EventCompany} />
          <Route path={`${path}/meeting`}>
            <EventMeeting meetings={meetingInvitationsByEvent} />
          </Route>
          <Route path={`${path}/files`} component={EventFiles} />
          <Route path={`${path}/contacts`} component={EventContacts} />

          {eventById.additional_pages && eventById.additional_pages.length
            ? eventById.additional_pages.map(additionalRoutes => (
                <Route
                  path={`${path}/additional-${additionalRoutes.id}`}
                  component={() => (
                    <div className="company__additional-tab">
                      <h3>
                        {additionalRoutes.title.length > 40
                          ? additionalRoutes.title.substring(0, 40) + "..."
                          : additionalRoutes.title}
                      </h3>
                      <div className="company__additional-content">
                        {parse(additionalRoutes.content)}
                      </div>
                    </div>
                  )}
                />
              ))
            : null}

          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/main`} />}
          />
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isEventByIdPending: state.events.isEventByIdPending,
  eventById: state.events.eventById,

  isInnovationProjectByIdPending: state.innovationProjects.isEventByIdPending,
  innovationProject: state.innovationProjects.innovationProject,

  isBusinessProposalPending: state.businessProposals.isBusinessProposalPending,
  businessProposal: state.businessProposals.businessProposal,

  areNewsByCompanyIdPending: state.newsReducer.areNewsByCompanyIdPending,
  newsByCompanyId: state.newsReducer.newsByCompanyId,
  userEventGoingCheckStatus: state.eventInvitations.userEventGoingCheckStatus,

  areMeetingInvitationsByEventPending:
    state.eventInvitations.areMeetingInvitationsByEventPending,
  meetingInvitationsByEvent: state.eventInvitations.meetingInvitationsByEvent
});

export default connect(mapStateToProps, {
  getEventById,
  getBusinessProposalById,
  getInnovationProjectById,
  getNewsByCompanyId,
  addMeToEventById,
  checkIsUserGoingToEventId,
  getMeetingInvitationsByEvent
})(Event);
