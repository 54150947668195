import icon from "./icon.png";

export default function contactFormBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style> 
    @media screen and (max-width: 992px) {
      .contact-form__grid {
        flex-flow: column;
      }

      .contact-form__input {
        margin: 10px 0 0;
      }
    }

    .contact-form {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      margin: 0;
    }

    .contact-form__grid {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .contact-form__input {
      background-color: hsla(0, 0%, 100%, 0.8);
      color: #000000;
      font-size: 15px;
      border: 1px solid #b9b9ba;
      padding: 10px 8px;
      border-radius: 3px;
    }

    .contact-form__input.contact-form__input--textarea {
      height: 200px;
      margin: 25px 0 10px;
    }

    .contact-form__messages {
      display: flex;
      flex-flow: column;
    }
  
    .contact-form__message {
      font-size: 1.1em;
    }

    .contact-form__message.contact-form__message--validation {
      color: #ff7700;
    }

    .contact-form__message.contact-form__message--error {
      color: #ff3b3b;
    }

    .contact-form__message.contact-form__message--success {
      color: #26ca4a;
    }

    .contact-form__submit {
      align-self: flex-end;
      min-width: 100px;
      background-color: #aeaeae;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      line-height: 12px;
      padding: 13px 17px;
      margin: 10px 0 0;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      outline: none;
      transition: all 300ms ease;
    }

    .contact-form__submit:hover {
      background-color: #a9a9a9
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="contact-form-wrapper">
    <form class="contact-form">
      <div class="contact-form__grid">
        <input
          required
          class="contact-form__input contact-form__input--name"
          name="name"
          type="text"
          placeholder="Your name"
        />
        <input
          required
          class="contact-form__input contact-form__input--email"
          name="email"
          type="email"
          placeholder="E-mail"
        />
        <input
          required
          class="contact-form__input contact-form__input--phone"
          name="phone"
          type="tel"
          placeholder="Phone"
        />
      </div>
      <textarea
        required
        class="contact-form__input contact-form__input--textarea"
        name="message"
        placeholder="Your Message"
      ></textarea>

      <div class="contact-form__messages">
        <span class="contact-form__message contact-form__message--validation">
          Please enter a valid name.
        </span>
        <span class="contact-form__message contact-form__message--error">
          Something went wrong try again later.
        </span>
        <span class="contact-form__message contact-form__message--success">
          Your message was send successfully. Thanks!
        </span>
      </div>

      <button class="contact-form__submit" type="button">
        SEND
      </button>
    </form>
  </div>
  `;

  blockManager.add("contact-form", {
    label: `
      <img src="${icon}" alt="'Contact Form' block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Contact Form
      </div>
    `,
    content,
    category: "B24Online Contacts"
  });
}
