import React, { Fragment, useState, useEffect } from "react";
import { NavLink, Route, Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";

import Card from "@material-ui/core/Card";

import { getCookie } from "../../axios/axios.config";
import { SimilarProducts } from "../../containers";
import { NoMatchPage } from "../../pages";
import {
  ProductDescriptionCard,
  ProductDescriptionAbout,
  ProductSpecificationAbout,
  ProductDescriptionFiles,
  ProductDescriptionDocuments
} from "../../components";

import { LoadingSpinner, ProductRequestForm } from "@core/components";

import {
  getB2BProduct,
  getB2CProduct,
  getCompaniesByUserId,
  addProductToWishList,
  getWishList,
  addToCart,
  getCart,
  getShippingMethodsByCompanyId,
  getProductVariationsByParentId,
  setProductVariation
} from "../../redux/actions-exporter";

import "./product-description.styles.scss";

const ProductDescription = ({
  getB2BProduct,
  getB2CProduct,
  isActiveProductPending,
  activeProduct,
  match,

  isProfilePending,

  getCompaniesByUserId,
  companiesByUserId,

  addToCart,
  addProductToWishList,
  cart,
  wishList,
  getShippingMethodsByCompanyId,
  shippingByCompany,

  getCart,
  getWishList,

  getProductVariationsByParentId,
  productVariationsByParent,
  setProductVariation
}) => {
  let url = match.url;
  let path = match.path;
  let { productType, productId } = useParams();

  const [existsInCart, setExistsInCart] = useState(false);
  const [existsInWishList, setExistsInWishList] = useState(false);

  const [appliedMethod, setAppliedMethod] = useState("");

  const [specifications, setSpecifications] = useState(null);
  const [specificationsWeb, setSpecificationsWeb] = useState(null);

  const [tabProgram, setTabProgram] = useState("");
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    getProductVariationsByParentId(productType, productId);
  }, [productType, productId]); // eslint-disable-line

  useEffect(() => {
    const authCookie = getCookie("Authorization");
    if (authCookie) {
      getCart();
      getWishList();
    }
    if (productType === "b2b") {
      getB2BProduct(productId);
    } else if (productType === "b2c") {
      getB2CProduct(productId);
    }

    if (wishList.find(i => i.wish_list_item[0].item_id === Number(productId))) {
      setExistsInWishList(true);
    } else {
      setExistsInWishList(false);
    }

    if (!isProfilePending) {
      getCompaniesByUserId();
    }
    // eslint-disable-next-line
  }, [
    getCart,
    getWishList,
    productType,
    productId,
    getB2BProduct,
    getB2CProduct,
    getCompaniesByUserId,
    isProfilePending
  ]);

  useEffect(() => {
    // TODO: !== или привести к общим типам
    // eslint-disable-next-line
    if (productVariationsByParent.count && activeProduct.id === productId) {
      setProductVariation(productVariationsByParent.results[0]);
    }
  }, [productVariationsByParent, activeProduct, productId]); // eslint-disable-line

  useEffect(() => {
    if (activeProduct && activeProduct.company && activeProduct.company.id) {
      getShippingMethodsByCompanyId(activeProduct.company.id);
    }
  }, [activeProduct, getShippingMethodsByCompanyId]);

  useEffect(() => {
    if (
      activeProduct?.company?.metadata?.dynamic_attributes_keys &&
      activeProduct?.metadata?.dynamic_attributes_values
    ) {
      let dynamicAttrKeys = activeProduct.company.metadata.dynamic_attributes_keys
        .replace(/[\][]/g, "")
        .replace(/'/g, '"');

      dynamicAttrKeys = JSON.parse(dynamicAttrKeys);

      let dynamicAttrValues = activeProduct.metadata.dynamic_attributes_values
        .replace(/[\][' ]/g, "")
        .split(",");

      setSpecifications(
        Object.fromEntries(
          Object.keys(dynamicAttrKeys).map(key => [
            dynamicAttrKeys[key],
            dynamicAttrValues[key]
          ])
        )
      );
    }

    if (
      activeProduct?.company?.metadata?.dynamic_attributes_web_keys &&
      activeProduct?.metadata?.dynamic_attributes_web_keys
    ) {
      let dynamicAttrWebKeys = activeProduct.company.metadata.dynamic_attributes_web_keys
        .replace(/[\][]/g, "")
        .replace(/'/g, '"');

      dynamicAttrWebKeys = JSON.parse(dynamicAttrWebKeys);

      let dynamicAttrWebValues = activeProduct.metadata.dynamic_attributes_web_keys
        .replace(/[\][' ]/g, "")
        .split(",");

      setSpecificationsWeb(
        Object.fromEntries(
          Object.keys(dynamicAttrWebKeys).map(key => [
            dynamicAttrWebKeys[key],
            dynamicAttrWebValues[key]
          ])
        )
      );
    }
  }, [activeProduct]);

  if (!activeProduct) {
    return <NoMatchPage />;
  }

  return (
    <div className="product-description">
      {isActiveProductPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <Card>
            <ProductDescriptionCard
              activeProduct={activeProduct}
              productId={productId}
              isProfilePending={isProfilePending}
              companiesByUserId={companiesByUserId}
              currentProductData={activeProduct}
              productImage={activeProduct.image}
              productPrice={activeProduct.cost}
              productPriceDiscount={activeProduct?.price_discount}
              rules={activeProduct?.rules}
              productCurrency={activeProduct.currency}
              productName={activeProduct.name}
              isDigitalProduct={!!activeProduct.is_digital_product}
              productShortDescription={parse(activeProduct.short_description)}
              companyName={activeProduct.company.name}
              productCompanyId={activeProduct.company.id}
              addToCart={addToCart}
              addProductToWishList={addProductToWishList}
              cart={cart}
              wishList={wishList}
              existsInCart={existsInCart}
              existsInWishList={existsInWishList}
              setExistsInCart={setExistsInCart}
              setExistsInWishList={setExistsInWishList}
              itemSizes={activeProduct.item_sizes}
              shippingByCompany={shippingByCompany}
              appliedMethod={appliedMethod}
              setAppliedMethod={setAppliedMethod}
              productVariationsByParent={productVariationsByParent}
              setProductVariation={setProductVariation}
              getParent={productType === "b2b" ? getB2BProduct : getB2CProduct}
            />
            {activeProduct.is_digital_product && (
              <div className="product__form">
                <ProductRequestForm
                  product={activeProduct}
                  tabProgram={tabProgram}
                  formOpen={formOpen}
                  leadFormDefault={process.env.LEAD_FORM_ID_DEFAULT}
                />
              </div>
            )}
            <div className="nav">
              <NavLink
                activeClassName="nav-link-selected"
                to={`${url}/about`}
                className="nav-link"
              >
                About
              </NavLink>
              <div className="nav-link__separator" />
              <NavLink
                activeClassName="nav-link-selected"
                to={`${url}/files`}
                className="nav-link"
              >
                Files
              </NavLink>
              <div className="nav-link__separator" />
              <NavLink
                activeClassName="nav-link-selected"
                to={`${url}/documents`}
                className="nav-link"
              >
                Documents
              </NavLink>
            </div>

            {activeProduct && activeProduct.additional_pages.length ? (
              <div className="nav nav-additional">
                {activeProduct.additional_pages.map(page => {
                  return (
                    <Fragment>
                      <NavLink
                        activeClassName="nav-link-selected"
                        to={`${url}/additional-${page.id}`}
                        className="nav-link"
                      >
                        {page.title.length > 20
                          ? page.title.substring(0, 20) + "..."
                          : page.title}
                      </NavLink>
                      <div className="nav-link__separator" />
                    </Fragment>
                  );
                })}
              </div>
            ) : null}

            {activeProduct && activeProduct.additional_pages.length
              ? activeProduct.additional_pages.map(additionalRoutes => (
                  <Route
                    exact
                    path={`${path}/additional-${additionalRoutes.id}`}
                    component={() => (
                      <div className="product-description__additional-tab">
                        <h3>
                          {additionalRoutes.title.length > 40
                            ? additionalRoutes.title.substring(0, 40) + "..."
                            : additionalRoutes.title}
                        </h3>
                        <div className="product-description__additional-content">
                          {parse(additionalRoutes.content)}
                        </div>
                      </div>
                    )}
                  />
                ))
              : null}

            <Route
              path={`${path}/about`}
              component={() => (
                <>
                  <ProductDescriptionAbout
                    productDescription={parse(activeProduct.description)}
                    setTabProgram={setTabProgram}
                    setFormOpen={setFormOpen}
                  />
                  {(specifications || specificationsWeb) && (
                    <ProductSpecificationAbout
                      specifications={specifications}
                      specificationsWeb={specificationsWeb}
                    />
                  )}
                </>
              )}
            />
            <Route
              path={`${path}/files`}
              component={() => (
                <ProductDescriptionFiles gallery={activeProduct.galleries} />
              )}
            />
            <Route
              path={`${path}/documents`}
              component={() => (
                <ProductDescriptionDocuments
                  documents={activeProduct.documents}
                />
              )}
            />
            <div className="product-description__horizontal-separator" />
            <div style={{ margin: "0 20px 30px" }}>
              <SimilarProducts
                productId={productId}
                productType={productType}
                productCategoryId={
                  activeProduct.categories.length &&
                  activeProduct.categories[0].id
                }
              />
            </div>
          </Card>
          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/about`} />}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart.cart,
  wishList: state.wishListReducer.wishList,

  isActiveProductPending: state.activeProduct.isActiveProductPending,
  activeProduct: state.activeProduct.activeProduct,

  isProfilePending: state.profileAPI.isProfilePending,

  shippingByCompany: state.shipping.shippingByCompany,
  companiesByUserId: state.companiesReducer.companiesByUserId,

  productVariationsByParent: state.productVariations.productVariationsByParent
});

const mapDispatchToProps = {
  getB2BProduct,
  getB2CProduct,
  getCompaniesByUserId,
  addProductToWishList,
  addToCart,
  getCart,
  getShippingMethodsByCompanyId,
  getWishList,
  getProductVariationsByParentId,
  setProductVariation
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescription);
