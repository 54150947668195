import icon from "./icon.png";

export default function languageSelectorBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .${className} {
    display: inline-block;
    width: min-content;
  }

  .${className}__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
    width: auto;
    background: #e4e4e4;
    padding: 2px 4px;
    margin: 0 8px;
    border: 2px solid #e4e4e4;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    text-align: initial;
    white-space: nowrap;
  }

  .${className}__trigger {
    width: min-content;
  }

  .${className}__content {
    position: absolute;
    width: 140px;
    background: #ffffff;
    padding: 8px 10px;
    margin: 6px 0 0;
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
    transition: all 300ms ease;
    overflow-y: auto;
  }

  .${className}__caret {
    position: relative;
    width: 10px;
    color: inherit;
    padding: 0 2px;
  }

  .${className}__caret::before {
    content: "";
    position: absolute;
    margin: -2px 0;
    border-top: 7px solid currentColor;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .${className}__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .${className}__list-item {
    cursor: pointer;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="${className}">
    <div class="${className}__trigger">
      <button type="button" class="${className}__button">
        <span class="${className}__value">
          EN
        </span>
        <span class="${className}__caret"></span>
      </button>
    </div>

    <div class="${className}__content">
      <ul class="${className}__list">
        <li class="${className}__list-item" data-value="EN" data-dir="ltr">
          EN
        </li>
        <li class="${className}__list-item" data-value="RU" data-dir="ltr">
          RU
        </li>
        <li class="${className}__list-item" data-value="BG" data-dir="ltr">
          BG
        </li>
        <li class="${className}__list-item" data-value="UK" data-dir="ltr">
          UK
        </li>
        <li class="${className}__list-item" data-value="HE" data-dir="rtl">
          HE
        </li>
        <li class="${className}__list-item" data-value="AR" data-dir="rtl">
          AR
        </li>
        <li class="${className}__list-item" data-value="ZH" data-dir="ltr">
          ZH
        </li>
        <li class="${className}__list-item" data-value="ES" data-dir="ltr">
          ES
        </li>
      </ul>
    </div>
  </div>
  `;

  blockManager.add("language-selector", {
    label: `
      <img src="${icon}" alt="Language selector block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Language Selector
      </div>
    `,
    content,
    category: "B24Online Translations"
  });
}
