import React from "react";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

import {
  SearchBar,
  LoadingSpinner,
  MyBusinessNoData
} from "../../../../components";
import { UserPlaceholder } from "../../../../assets";

const PersonSection = ({ isEventByIdPending, visitors }) => {
  return (
    <>
      <div className="my-business-event-invitations-inner-info__search">
        <SearchBar
        // onChange={e => {
        //   if (e.target.value) {
        //     searchResumesByCurrentUser(e.target.value);
        //   } else if (!e.target.value) {
        //     getResumesByCurrentUser();
        //   }
        // }}
        />
      </div>
      <div className="my-business-event-invitations-inner-info__table-data">
        {isEventByIdPending ? (
          <LoadingSpinner />
        ) : visitors && visitors.length ? (
          <Table className="events-invitations-table" aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="left">NAME</TableCell>
                <TableCell align="left">EMAIL</TableCell>
                <TableCell align="left">PHONE</TableCell>
                <TableCell align="left">COMMENT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors &&
                visitors.map(
                  ({
                    id,
                    first_name,
                    last_name,
                    avatar,
                    user_email,
                    mobile_number,
                    comment = "-"
                  }) => (
                    <TableRow key={id}>
                      <TableCell>
                        <div className="events-invitations-table__person-image">
                          <div className="user-image">
                            {avatar ? (
                              <img src={avatar} alt="" />
                            ) : (
                              <UserPlaceholder />
                            )}
                          </div>
                          <div className="user-name">{`${first_name ||
                            "No name"} ${last_name || "No name"}`}</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="events-invitations-table__email">
                          {user_email || "No email!"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="events-invitations-table__phone">
                          {mobile_number}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="events-invitations-table__comment">
                          {comment}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        ) : (
          <MyBusinessNoData noDataText="No events invitation!" />
        )}
      </div>
    </>
  );
};

export default PersonSection;
