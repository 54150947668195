import axios from "axios";
import { OrganizationsActionTypes } from "./organizations.types";
import {
  axiosInstance,
  axiosNoTokenInstance,
  getCookie,
  baseURL
} from "../../../axios/axios.config";

export const getOrganizationTypes = () => dispatch => {
  axiosNoTokenInstance
    .get("/api/v1/organization/chamber_types/")
    .then(organizationTypes =>
      dispatch({
        type: OrganizationsActionTypes.GET_ORGANIZATION_TYPES,
        payload: organizationTypes
      })
    );
};

export const getOrganizations = (countryId = 5, pageNumber = 1) => dispatch => {
  axiosNoTokenInstance
    .get(
      `/api/v1/organization/chamber/?id=&countries=${countryId}&page=${pageNumber}`
    )
    .then(organizations =>
      dispatch({
        type: OrganizationsActionTypes.GET_ORGANIZATIONS,
        payload: organizations.data
      })
    )
    .catch(error => console.log(error));
};

export const getOrganization = organizationId => dispatch => {
  dispatch({ type: OrganizationsActionTypes.GET_ORGANIZATION_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/organization/chamber/?id=${organizationId}`)
    .then(organization => {
      if (window?.B24Chat?.instance) {
        window.B24Chat.instance.loadWidget({
          block: "organization-script",
          token: organization.data.results[0].bot_token
        });
      }

      dispatch({
        type: OrganizationsActionTypes.GET_ORGANIZATION,
        payload: organization.data.results[0]
      });
    })
    .catch(error => console.log(error));
};

export const getUserOrganizations = (
  search = "",
  pageNumber = 1,
  pageSize = 10
) => dispatch => {
  dispatch({ type: OrganizationsActionTypes.GET_USER_ORGANIZATIONS_PENDING });
  return axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(({ data: { id: userId } }) => {
      axiosInstance
        .get(
          `/api/v1/organization/company/?search=${search}created_by=${userId}&page=${pageNumber}&page_size=${pageSize}`
        )
        .then(organizations =>
          dispatch({
            type: OrganizationsActionTypes.GET_USER_ORGANIZATIONS,
            payload: organizations.data
          })
        );
    })
    .catch(error => console.log(error));
};

export const getUserOrganizationsChamber = (
  search = "",
  pageNumber = 1,
  pageSize = 10
) => dispatch => {
  dispatch({
    type: OrganizationsActionTypes.GET.GET_USER_ORGANIZATIONS_CHAMBER_PENDING
  });
  return axiosInstance
    .get(
      `/api/v1/organization/chamber_manageable/?search=${search}&page=${pageNumber}&page_size=${pageSize}`
    )
    .then(organizations =>
      dispatch({
        type:
          OrganizationsActionTypes.GET.GET_USER_ORGANIZATIONS_CHAMBER_SUCCESS,
        payload: {
          count: organizations.data.count,
          organizations: organizations.data.results
        }
      })
    )
    .catch(error => {
      dispatch({
        type: OrganizationsActionTypes.GET.GET_USER_ORGANIZATIONS_CHAMBER_ERROR,
        payload: error
      });
    });
};

export const createOrganization = (
  name,
  country,
  orgTypeId,
  branch,
  vatin,
  url,
  dirName,
  email,
  phone,
  fax,
  address,
  slogan,
  keywords,
  shortDesc,
  script,
  desc,
  blob
) => dispatch => {
  axiosInstance
    .post("/api/v1/organization/chamber_manageable/", {
      name: name,
      countries: country,
      org_type: orgTypeId,
      branches: branch,
      description: desc,
      short_description: shortDesc,
      address,
      director: dirName,
      slug: slogan,
      keywords,
      metadata: {
        email,
        phone,
        fax,
        site: url,
        script,
        vat_identification_number: vatin
      },
      blob
    })
    .then(organization => {
      if (blob) {
        dispatch({
          type:
            OrganizationsActionTypes.PATCH.IMAGE
              .PATCH_ORGANIZATION_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("logo", blob);
        axios
          .patch(
            `${baseURL}/api/v1/organization/chamber_manageable/${organization.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
                OrganizationsActionTypes.PATCH.IMAGE
                  .PATCH_ORGANIZATION_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(_ => {
            dispatch({
              type: OrganizationsActionTypes.CREATE_ORGANIZATION,
              payload: {
                organization: organization.data,
                requestStatus: organization.status
              }
            });
          })
          .catch(err =>
            dispatch({
              type:
                OrganizationsActionTypes.PATCH.IMAGE
                  .PATCH_ORGANIZATION_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: OrganizationsActionTypes.CREATE_ORGANIZATION,
          payload: {
            organization: organization.data,
            requestStatus: organization.status
          }
        });
      }
    })
    .catch(error => console.log(error));
};

export const updateOrganization = (
  organizationId,
  name,
  country,
  orgTypeId,
  branch,
  vatin,
  url,
  dirName,
  email,
  phone,
  fax,
  address,
  slogan,
  keywords,
  shortDesc,
  script,
  desc,
  blob
) => dispatch => {
  axiosInstance
    .put(`/api/v1/organization/chamber_manageable/${organizationId}/`, {
      name: name,
      countries: country,
      org_type: orgTypeId,
      branches: branch,
      description: desc,
      short_description: shortDesc,
      address,
      director: dirName,
      slug: slogan,
      keywords,
      metadata: {
        email,
        phone,
        fax,
        site: url,
        vat_identification_number: vatin,
        script
      },
      blob
    })
    .then(organization => {
      if (blob !== undefined) {
        dispatch({
          type:
            OrganizationsActionTypes.PATCH.IMAGE
              .PATCH_ORGANIZATION_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("logo", blob);
        axios
          .patch(
            `${baseURL}/api/v1/organization/chamber_manageable/${organization.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
                OrganizationsActionTypes.PATCH.IMAGE
                  .PATCH_ORGANIZATION_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(_ => {
            dispatch({
              type: OrganizationsActionTypes.UPDATE_ORGANIZATION,
              payload: {
                organization: organization.data,
                requestStatus: organization.status
              }
            });
          })
          .catch(err =>
            dispatch({
              type:
                OrganizationsActionTypes.PATCH.IMAGE
                  .PATCH_ORGANIZATION_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: OrganizationsActionTypes.UPDATE_ORGANIZATION,
          payload: {
            organization: organization.data,
            requestStatus: organization.status
          }
        });
      }
    })
    .catch(error => console.log(error));
};

export const deleteOrganization = organizationId => dispatch => {
  axiosInstance
    .delete(`/api/v1/organization/chamber_manageable/${organizationId}`)
    .then(organization =>
      dispatch({
        type: OrganizationsActionTypes.DELETE_ORGANIZATION,
        payload: organization
      })
    )
    .then(() => dispatch(getUserOrganizationsChamber()))
    .catch(error => console.log(error));
};

export const searchOrganizations = (
  searchQuery = "",
  country = "",
  page = 1,
  pageSize = 10
) => dispatch => {
  dispatch({
    type: OrganizationsActionTypes.GET.SEARCH.SEARCH_ORGANIZATIONS_PENDING
  });
  axiosNoTokenInstance
    .get(
      `/api/v1/organization/chamber/?search=${searchQuery}&countries=${country}&page=${page}&page_size=${pageSize}`
    )
    .then(organizations => {
      dispatch({
        type: OrganizationsActionTypes.GET.SEARCH.SEARCH_ORGANIZATIONS_SUCCESS,
        payload: {
          organizations: organizations.data.results,
          count: organizations.data.count
        }
      });
    })
    .catch(err => {
      dispatch({
        type: OrganizationsActionTypes.GET.SEARCH.SEARCH_ORGANIZATIONS_ERROR,
        payload: err
      });
    });
};

export const getFilteredOrganizations = (filterQuery, page = 1) => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/organization/chamber/${filterQuery}&page=${page}`)
    .then(organizations =>
      dispatch({
        type: OrganizationsActionTypes.GET_ORGANIZATIONS,
        payload: organizations.data
      })
    )
    .catch(error => console.log(error));
};

export const getChamberTypes = () => dispatch => {
  dispatch({
    type: OrganizationsActionTypes.GET.GET_CHAMBER_TYPES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/chamber/chamber_types/`)
    .then(chamberTypes => {
      dispatch({
        type: OrganizationsActionTypes.GET.GET_CHAMBER_TYPES_SUCCESS,
        payload: chamberTypes.data
      });
    })
    .catch(err => {
      dispatch({
        type: OrganizationsActionTypes.GET.GET_CHAMBER_TYPES_ERROR,
        payload: err
      });
    });
};

export const clearOrganizationRequest = () => dispatch => {
  dispatch({
    type: OrganizationsActionTypes.CLEAR_ORGANIZATION_REQUEST,
    payload: ""
  });
};
