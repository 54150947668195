import icon from "./icon.png";

export default function profileTextBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const content = `
  <span class="${className}"></span>
  `;

  blockManager.add("profile-text", {
    label: `
      <img src="${icon}" alt="Avatar block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Profile Text
      </div>
    `,
    content,
    category: "B24Online Profile"
  });

  // Make profile avatar classes private
  const profileTextClasses = [`.${className}`, `.${className}__image`];
  editor.on(
    "selector:add",
    selector =>
      profileTextClasses.indexOf(selector.getFullName()) >= 0 &&
      selector.set("private", 1)
  );
}
