import style from "./block-style";

const wishListContent = `
${style}
<div
  translations='[{"string":"PRODUCT","language":"EN","translation":"PRODUCT"},{"string":"NAME","language":"EN","translation":"NAME"},{"string":"COST","language":"EN","translation":"COST"},{"string":"Your wish list is currently empty.","language":"EN","translation":"Your wish list is currently empty."},{"string":"N/A","language":"EN","translation":"N/A"},{"string":"Add to cart","language":"EN","translation":"Add to cart"},{"string":"ADDED","language":"EN","translation":"ADDED"}]'
  class="wish-list-wrapper"
>
    <table class="wish-list">
      <thead class="wish-list__header">
        <tr>
          <th class="wish-list__header-title wish-list__header-title--item">
            PRODUCT
          </th>
          <th class="wish-list__header-title wish-list__header-title--name">
            NAME
          </th>
          <th
            class="wish-list__header-title wish-list__header-title--total"
            colspan="3"
          >
            COST
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              9.99
            </span>
            <span class="wish-list__currency">
              USD
            </span>
            <span class="wish-list__percent-discount">
              -12.50%
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              25.00
            </span>
            <span class="wish-list__currency">
              USD
            </span>
            <span class="wish-list__percent-discount">
              -12.50%
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              29.99
            </span>
            <span class="wish-list__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              26.75
            </span>
            <span class="wish-list__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              39.99
            </span>
            <span class="wish-list__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              26.00
            </span>
            <span class="wish-list__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-media">
              <img
                class="wish-item-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-main">
              <span class="wish-item-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-main__title">
                <a class="wish-item-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              1.00
            </span>
            <span class="wish-list__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list__remove">
            <span class="wish-list__remove-icon">
              X
            </span>
          </td>
        </tr>
      </tbody>
    </table>



    <table class="wish-list-slim">
      <thead class="wish-list-slim__header">
        <tr>
          <th class="wish-list-slim__header-title wish-list-slim__header-title--item">
            PRODUCT
          </th>
          <th class="wish-list-slim__header-title wish-list-slim__header-title--name">
            NAME
          </th>
          <th
            class="wish-list-slim__header-title wish-list-slim__header-title--total"
            colspan="3"
          >
            COST
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list-slim__cost">
              9.99
            </span>
            <span class="wish-list-slim__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list-slim__cost">
              25.00
            </span>
            <span class="wish-list-slim__currency">
              USD
            </span>
            <span class="wish-list-slim__percent-discount">
              -12.50%
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list-slim__cost">
              29.99
            </span>
            <span class="wish-list-slim__currency">
              USD
            </span>
            <span class="wish-list-slim__percent-discount">
              -12.50%
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list-slim__cost">
              26.75
            </span>
            <span class="wish-list-slim__currency">
              USD
            </span>
            <span class="wish-list-slim__percent-discount">
              -12.50%
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list-slim__cost">
              39.99
            </span>
            <span class="wish-list-slim__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list-slim__cost">
              260.00
            </span>
            <span class="wish-list-slim__currency">
              ILS
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="wish-item-slim-media">
              <img
                class="wish-item-slim-media__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="No thumbnail"
              />
            </div>
          </td>
          <td>
            <div class="wish-item-slim-main">
              <span class="wish-item-slim-main__capture">
                Category, Category, Category &amp; Category
              </span>
              <h4 class="wish-item-slim-main__title">
                <a class="wish-item-slim-main__link" href="#">
                  Product Title
                </a>
              </h4>
            </div>
          </td>
          <td>
            <span class="wish-list__cost">
              1.00
            </span>
            <span class="wish-list__currency">
              USD
            </span>
          </td>
          <td>
            <button class="wish-list-slim__button" type="button">
              Add to cart
            </button>
          </td>
          <td class="wish-list-slim__remove">
            <span class="wish-list-slim__remove-icon">
              X
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="wl-pagination">
        <button class="wl-pagination__button" disabled="">
          &lt;
        </button>
  
        <button
          class="wl-pagination__button wl-pagination__button--active"
          data-page="1"
        >
          1
        </button>
  
        <button class="wl-pagination__button" disabled="">
          &gt;
        </button>
    </div>
</div>
`;

export default wishListContent;
