import { UserActionTypes } from "./user.types";
import { axiosInstance } from "../../../axios/axios.config";

export const getUser = () => dispatch => {
  dispatch({ type: UserActionTypes.GET.GET_USER_PENDING });
  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(resp =>
      dispatch({
        type: UserActionTypes.GET.GET_USER_SUCCESS,
        payload: resp.data
      })
    )
    .catch(err =>
      dispatch({ type: UserActionTypes.GET.GET_USER_ERROR, payload: err })
    );
};
