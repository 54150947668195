import React, { useState, useEffect } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import { linkedinToken } from "../../config";

import "./login-with-linkedin.styles.scss";

const LoginWithLinkedin = ({ setLoading, loginWithOauth }) => {
  const [code, setCode] = useState(null);
  const [app] = useState({
    scope: "r_liteprofile r_emailaddress",
    provider: "linkedin-oauth2",
    clientId: linkedinToken,
    redirectUri: "https://b24online.com/linkedin"
  });

  useEffect(() => {
    if (!code) {
      return;
    }

    setLoading(true);
    loginWithOauth(code, app.provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleCode = ({ code = null } = {}) => setCode(code);

  return (
    <div className="login-with-linkedin">
      <LinkedIn
        scope={app.scope}
        clientId={app.clientId}
        onFailure={handleCode}
        onSuccess={handleCode}
        redirectUri={app.redirectUri}
      >
        <LinkedInIcon style={{ color: "#1976d2" }} />
      </LinkedIn>
    </div>
  );
};

export default LoginWithLinkedin;
