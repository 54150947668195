import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";

import { DefaultSnackbar, Orders, Pagination } from "../../../components";
import {
  getOrderedItems,
  removeDealById,
  deleteDeal
} from "../../../redux/actions-exporter";

import "./my-organization-orders.styles.scss";

const MyOrders = ({
  orderDeals,
  removeDealById,
  getOrderedItems,
  deleteDeal,
  cart,
  orderCount
}) => {
  const history = useHistory();
  const [showMessage, setShowMessage] = useState(false);
  const [ordersPage, setOrdersPage] = useState(1);

  const removeDealHandler = dealId => {
    removeDealById(dealId);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      history.push("orders");
    }, 4000);
  };

  const removeItemHandler = cart_id => {
    deleteDeal(cart_id);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      history.push("/my-business/orders");
    }, 4000);
  };

  useEffect(() => {
    getOrderedItems("", ordersPage);
  }, [getOrderedItems, removeDealById, ordersPage]);

  return (
    <div className="my-person-orders">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          variant="success"
          message="Succesfuly removed item!"
          autoHideDuration={2000}
        />
      )}
      <Card>
        <div className="deals-container">
          <Orders
            orderDeals={orderDeals}
            removeDealHandler={removeDealHandler}
            removeItemHandler={removeItemHandler}
            cart={cart}
            isLastOrders={false}
          />
          <Pagination
            forcePage={ordersPage - 1}
            dataCount={orderCount}
            itemsPerPage={10}
            selectedPage={page => setOrdersPage(page)}
          />
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart.cart,
  areOrderDealsPending: state.ordersReducer.areOrdersPending,
  orderDeals: state.ordersReducer.ordersItems,
  orderCount: state.ordersReducer.ordersCount
});

export default connect(mapStateToProps, {
  getOrderedItems,
  removeDealById,
  deleteDeal
})(MyOrders);
