export const PagesActionTypes = {
  GET_PAGES: "GET_PAGES",
  GET_PAGES_PENDING: "GET_PAGES_PENDING",
  GET_PAGES_ERROR: "GET_PAGES_ERROR",
  GET_PAGE_BY_ID: "GET_PAGE_BY_ID",
  GET_PAGE_BY_ID_PENDING: "GET_PAGE_BY_ID_PENDING",
  GET_PAGE_BY_ID_ERROR: "GET_PAGE_BY_ID_ERROR",
  CREATE_PAGE: "CREATE_PAGE",
  UPDATE_PAGE: "UPDATE_PAGE",
  DELETE_PAGE: "DELETE_PAGE",
  CLEAR_PAGES_REQUEST: "CLEAR_PAGES_REQUEST",
  GET_PAGE_TYPES_PENDING: "GET_PAGE_TYPES_PENDING",
  GET_PAGE_TYPES_SUCCESS: "GET_PAGE_TYPES_SUCCESS",
  GET_PAGE_TYPES_ERROR: "GET_PAGE_TYPES_ERROR",
  GET_SITE_TYPES_PENDING: "GET_SITE_TYPES_PENDING",
  GET_SITE_TYPES_SUCCESS: "GET_SITE_TYPES_SUCCESS",
  GET_SITE_TYPES_ERROR: "GET_SITE_TYPES_ERROR"
};
