import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { MyBusinessNoData } from "../../components";

import "./event-visitors.styles.scss";
import { UserPlaceholder } from "../../assets";

const useStyles = makeStyles({
  noBorder: {
    border: "none"
  }
});

const EventVisitors = ({ visitors }) => {
  const classes = useStyles();

  return (
    <div className="event-visitors">
      <div className="event-visitors__table">
        {visitors && visitors.length ? (
          <Table className="visitors-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.noBorder} align="left">
                  NAME
                </TableCell>
                <TableCell className={classes.noBorder} align="left">
                  EMAIL
                </TableCell>
                <TableCell className={classes.noBorder} align="left">
                  PHONE
                </TableCell>
                <TableCell className={classes.noBorder} align="left">
                  COMMENT
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors.map(
                ({
                  id,
                  first_name,
                  last_name,
                  avatar,
                  user_email,
                  mobile_number,
                  comment
                }) => (
                  <TableRow key={id}>
                    <TableCell className={classes.noBorder}>
                      <div className="visitors-table__name">
                        {avatar ? (
                          <img className="user-image" src={avatar} alt="" />
                        ) : (
                          <UserPlaceholder />
                        )}
                        <div className="user-name">
                          {`${first_name || "No name"} ${last_name ||
                            "No name"}`}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.noBorder}>
                      <div className="visitors-table__email">{user_email}</div>
                    </TableCell>

                    <TableCell className={classes.noBorder}>
                      <div className="visitors-table__phone">
                        {mobile_number}
                      </div>
                    </TableCell>
                    <TableCell className={classes.noBorder}>
                      <div className="visitors-table__comment">{comment}</div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        ) : (
          <MyBusinessNoData noDataText="No event visitors!" />
        )}
      </div>
    </div>
  );
};

export default EventVisitors;
