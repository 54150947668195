import {
  axiosNoTokenInstance,
  axiosInstance
} from "../../../../axios/axios.config";
import { JobsActionTypes } from "./jobs.types";

export const getJobs = (country = 5, page = 1) => dispatch => {
  dispatch({ type: JobsActionTypes.GET.GET_JOBS_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/department/jobs/?country=${country}&page=${page}`)
    .then(jobs =>
      dispatch({
        type: JobsActionTypes.GET.GET_JOBS_SUCCESS,
        payload: { count: jobs.data.count, jobs: jobs.data.results }
      })
    )
    .catch(error =>
      dispatch({ type: JobsActionTypes.GET.GET_JOBS_ERROR, payload: error })
    );
};

export const getJob = id => dispatch => {
  dispatch({ type: JobsActionTypes.GET.GET_JOB_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/department/jobs/${id}/`)
    .then(job =>
      dispatch({ type: JobsActionTypes.GET.GET_JOB_SUCCESS, payload: job.data })
    )
    .catch(error =>
      dispatch({ type: JobsActionTypes.GET.GET_JOB_ERROR, payload: error })
    );
};

export const getFilterJobs = (filterQuery, page = 1) => dispatch => {
  dispatch({ type: JobsActionTypes.GET.GET_JOBS_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/department/jobs/${filterQuery}&page=${page}`)
    .then(jobs =>
      dispatch({
        type: JobsActionTypes.GET.GET_JOBS_SUCCESS,
        payload: { count: jobs.data.count, jobs: jobs.data.results }
      })
    )
    .catch(error =>
      dispatch({ type: JobsActionTypes.GET.GET_JOBS_ERROR, payload: error })
    );
};

export const searchJobs = (
  searchQuery = "",
  page = 1,
  countries = "",
  organizations = ""
) => dispatch => {
  dispatch({ type: JobsActionTypes.GET.GET_JOBS_PENDING });
  axiosNoTokenInstance
    .get(
      `/api/v1/department/jobs/?search=${searchQuery}&country=${countries}&vacancy__department__organization=${organizations}&page=${page}`
    )
    .then(jobs => {
      dispatch({
        type: JobsActionTypes.GET.GET_JOBS_SUCCESS,
        payload: { count: jobs.data.count, jobs: jobs.data.results }
      });
    })
    .catch(error => {
      dispatch({ type: JobsActionTypes.GET.GET_JOBS_ERROR, payload: error });
    });
};

export const getJobsCreatedByMe = (search = "", page = 1) => dispatch => {
  dispatch({ type: JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_PENDING });

  axiosInstance
    .get(`/api/v1/auth/users/me/`)
    .then(user =>
      axiosInstance
        .get(
          `/api/v1/department/jobs/?search=${search}&created_by=${user.data.id}&page=${page}`
        )
        .then(jobs =>
          dispatch({
            type: JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_SUCCESS,
            payload: { count: jobs.data.count, jobs: jobs.data.results }
          })
        )
    )
    .catch(error =>
      dispatch({
        type: JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_ERROR,
        payload: error
      })
    );
};

export const searchJobsCreatedByMe = (searchQuery, page = 1) => dispatch => {
  dispatch({ type: JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_PENDING });

  axiosInstance
    .get(`/api/v1/auth/users/me/`)
    .then(user =>
      axiosInstance
        .get(
          `/api/v1/department/jobs/?created_by=${user.data.id}&search=${searchQuery}&page=${page}`
        )
        .then(jobs =>
          dispatch({
            type: JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_SUCCESS,
            payload: jobs.data.results
          })
        )
    )
    .catch(error =>
      dispatch({
        type: JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_ERROR,
        payload: error
      })
    );
};
