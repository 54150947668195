export default function productsCategoriesComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };
    // Get translation
    const translations = getTranslate("translations", this.attributes);
    // Get search input
    const searchEl = document.querySelector(".products-categories__search");
    // Change placeholder
    if (translations && Object.keys(translations).length) {
      searchEl.setAttribute("placeholder", translations["Search..."]);
    }

    const isRTL = window.localStorage.getItem("direction") === "rtl";

    function setLoading(state) {
      const wrapperEl = document.querySelector(".products-categories");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("products-categories--preloader");
      } else {
        wrapperEl.classList.remove("products-categories--preloader");
      }
    }

    function render(
      type,
      total,
      page,
      perPage,
      products,
      categories,
      producers,
      detailsPath,
      loading
    ) {
      const el = document.querySelector(".products-categories");
      setLoading(loading);

      Object.assign(el.style, isRTL ? { textAlign: "right" } : {});

      el.innerHTML = categories.reduce(
        (tmp, category, categoryIndex) =>
          `${tmp}
          ${
            category && category.id
              ? `<ul class="products-categories__list">
            <li
              class="products-categories__item products-categories__item--primary ${
                category.selected ? " products-categories__item--active" : ""
              }"
              data-id="${category.id}"
            >
              ${category.title}
            </li>
            <li class="products-categories__list-wrapper">
              <ul class="products-categories__list products-categories__list--subcategories">
              ${category.children.reduce(
                (tmp, item) =>
                  tmp +
                  `
                  <li
                    class="products-categories__item products-categories__item--subcategory ${
                      item.selected ? " products-categories__item--active" : ""
                    }"
                    data-parent-index="${categoryIndex}"
                    data-id="${item.id}"
                  >
                    ${item.title}
                  </li>
                `,
                ""
              )}
              </ul>
            </li>
          </ul>
          `
              : ""
          }`,
        ""
      );

      const categoriesEls = document.querySelectorAll(
        ".products-categories__item"
      );

      Array.prototype.slice.call(categoriesEls).map(categoriesEl =>
        categoriesEl.addEventListener("click", e => {
          e.preventDefault();
          const isPressed = e.target.classList.contains(
            "products-categories__item--active"
          );
          const categoryId = parseInt(e.target.dataset.id);
          const parentIndex = parseInt(e.target.dataset.parentIndex);

          const mapSelectedCategories = state => (category, index) => {
            const isSubcategory = !isNaN(parentIndex);
            if (isSubcategory) {
              const subcategory = category.children.find(
                subcategory => subcategory.id === categoryId
              );

              if (index === parentIndex && subcategory) {
                return Object.assign({}, category, {
                  children: category.children
                    .filter(subcategory => subcategory.id !== categoryId)
                    .concat([
                      Object.assign({}, subcategory, { selected: state })
                    ])
                });
              } else {
                return category;
              }
            } else if (!isSubcategory) {
              return category.id === categoryId
                ? Object.assign({}, category, { selected: state })
                : category;
            }
          };

          if (isPressed) {
            e.target.classList.remove("products-categories__item--active");
            window.products.setCategories(
              window.products.categories.map(mapSelectedCategories(false))
            );
          } else {
            e.target.classList.add("products-categories__item--active");
            window.products.setCategories(
              window.products.categories.map(mapSelectedCategories(true))
            );
          }
        })
      );
    }

    window.waitForProductsInterval = setInterval(function() {
      if (
        window.hasOwnProperty("products") &&
        window.hasOwnProperty("grapesjs")
      ) {
        clearInterval(window.waitForProductsInterval);
        if (!window.products.renderCycle.find(func => func === render)) {
          window.products.renderCycle.push(render);
          window.products.loading = false;
          window.products.getCategories("");
        }
      }
    }, 100);

    this.closest(".products-categories-wrapper")
      .querySelector(".products-categories__search")
      .addEventListener("change", function(e) {
        if (
          window.hasOwnProperty("products") &&
          window.hasOwnProperty("grapesjs")
        ) {
          window.products.getCategories(e.target.value);
        }
      });
  };

  editor.DomComponents.addType("products-categories", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-categories-wrapper",
    model: {
      defaults: {
        name: "Products Categories",
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Search..."]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });

  editor.DomComponents.addType("products-categories__input", {
    isComponent: el =>
      el.tagName === "INPUT" && el.className === "products-categories__search",
    model: {
      defaults: {
        name: "Products Categories Input",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-categories__grid", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-categories",
    model: {
      defaults: {
        name: "Products Categories Lists Wrapper",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-categories__list", {
    isComponent: el =>
      el.tagName === "UL" && el.className === "products-categories__list",
    model: {
      defaults: {
        name: "Products Categories List",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });
}
