import React from "react";

const CheckoutPaypal = ({ currency, total }) => {
  const backToWeb = window.location.origin;

  return (
    <div>
      <h3>Currency: {currency}</h3>
      <form
        className="paypal__form"
        action="https://www.sandbox.paypal.com/cgi-bin/webscr"
        method="post"
      >
        <input type="hidden" name="cmd" value="_xclick" id="id_cmd" />
        <input type="hidden" name="charset" value="utf-8" id="id_charset" />
        <input
          type="hidden"
          name="currency_code"
          value={currency}
          id="id_currency_code"
        />
        <input type="hidden" name="no_shipping" value="0" id="id_no_shipping" />
        <input
          type="hidden"
          name="business"
          value="recipient@gmail.com"
          id="id_business"
        />
        <input type="hidden" name="amount" value={total} id="id_amount" />
        <input
          type="hidden"
          name="notify_url"
          value={`${backToWeb}/cart`}
          id="id_notify_backToWeb"
        />
        <input type="hidden" name="cancel_return" value={`${backToWeb}/cart`} />
        <input type="hidden" name="item_number" value="1" id="id_item_number" />
        <input
          type="hidden"
          name="item_name"
          value="Item Name"
          id="id_item_name"
        />
        <input type="hidden" name="quantity" value="1" id="id_quantity" />
        <input
          type="hidden"
          name="return"
          value={`${backToWeb}/cart`}
          id="id_return"
        />
        <input
          type="image"
          src="https://www.paypal.com/en_US/i/btn/btn_buynowCC_LG.gif"
          border="0"
          name="submit"
          alt="Buy it Now"
        />
      </form>
    </div>
  );
};

export default CheckoutPaypal;
