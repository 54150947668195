import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { handleBusinessCardType } from "../../redux/profile/profile.action";

import DefaultButton from "../default-button/default-button.component";
import DefaultSnackbar from "../../components/default-snackbar/default-snackbar.component";

import BCard1 from "../../assets/BCard1.png";
import BCard2 from "../../assets/BCard2.png";
import BCard3 from "../../assets/BCard3.png";
import BCard4 from "../../assets/BCard4.png";
import BCard5 from "../../assets/BCard5.png";
import BCard6 from "../../assets/BCard6.png";

import { colorPalette } from "../../styles/colorPalette";
import "./profile-business-card.styles.scss";

const ProfileBusinessCard = ({ handleBusinessCardType, profile }) => {
  const [color, setColor] = useState(false);
  const [color2, setColor2] = useState(false);
  const [color3, setColor3] = useState(false);
  const [color4, setColor4] = useState(false);
  const [color5, setColor5] = useState(false);
  const [color6, setColor6] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [useAPI, setUseAPI] = useState(true);

  useEffect(() => {
    if (useAPI) {
      handleBusinessCardType(
        profile && profile.metadata && profile.metadata.business_card
      );
    }

    if (showMessage) {
      setTimeout(() => setShowMessage(false), 1000);
    }
  }, [handleBusinessCardType, profile, showMessage, useAPI]);

  return (
    <div className="profile-business-cards">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          variant="success"
          message="Succesfuly selected Business Card!"
          autoHideDuration={2000}
        />
      )}
      <h2>Business Card</h2>
      <p>Choose Your Design</p>
      <div className="profile-business-cards__container">
        <div
          onMouseLeave={() => setColor(false)}
          onMouseEnter={() => setColor(true)}
          className="profile-business-cards__container__card"
        >
          {color && (
            <>
              <div
                onMouseLeave={() => setColor(false)}
                onMouseEnter={() => setColor(true)}
                className="profile-business-cards__container__card__hover"
              />
              <div
                onMouseLeave={() => setColor(false)}
                onMouseEnter={() => setColor(true)}
                className="profile-business-cards__container__card__btn"
              >
                <DefaultButton
                  color="white"
                  hover={colorPalette.colorPrimary.darker}
                  backgroundColor={colorPalette.colorPrimary.main}
                  onClick={() => {
                    handleBusinessCardType(1);
                    setUseAPI(false);
                    setShowMessage(true);
                  }}
                >
                  Select
                </DefaultButton>
              </div>
            </>
          )}

          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={BCard1}
            alt="business-card-1"
          />
        </div>
        <div
          onMouseLeave={() => setColor2(false)}
          onMouseEnter={() => setColor2(true)}
          className="profile-business-cards__container__card"
        >
          {color2 && (
            <>
              <div
                onMouseLeave={() => setColor2(false)}
                onMouseEnter={() => setColor2(true)}
                className="profile-business-cards__container__card__hover"
              />
              <div
                onMouseLeave={() => setColor2(false)}
                onMouseEnter={() => setColor2(true)}
                className="profile-business-cards__container__card__btn"
              >
                <DefaultButton
                  color="white"
                  hover={colorPalette.colorPrimary.darker}
                  backgroundColor={colorPalette.colorPrimary.main}
                  onClick={() => {
                    handleBusinessCardType(2);
                    setUseAPI(false);
                    setShowMessage(true);
                  }}
                >
                  Select
                </DefaultButton>
              </div>
            </>
          )}

          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={BCard2}
            alt="business-card-2"
          />
        </div>
        <div
          onMouseLeave={() => setColor3(false)}
          onMouseEnter={() => setColor3(true)}
          className="profile-business-cards__container__card"
        >
          {color3 && (
            <>
              <div
                onMouseLeave={() => setColor3(false)}
                onMouseEnter={() => setColor3(true)}
                className="profile-business-cards__container__card__hover"
              />
              <div
                onMouseLeave={() => setColor3(false)}
                onMouseEnter={() => setColor3(true)}
                className="profile-business-cards__container__card__btn"
              >
                <DefaultButton
                  color="white"
                  hover={colorPalette.colorPrimary.darker}
                  backgroundColor={colorPalette.colorPrimary.main}
                  onClick={() => {
                    handleBusinessCardType(3);
                    setUseAPI(false);
                    setShowMessage(true);
                  }}
                >
                  Select
                </DefaultButton>
              </div>
            </>
          )}

          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={BCard3}
            alt="business-card-3"
          />
        </div>
        <div
          onMouseLeave={() => setColor4(false)}
          onMouseEnter={() => setColor4(true)}
          className="profile-business-cards__container__card"
        >
          {color4 && (
            <>
              <div
                onMouseLeave={() => setColor4(false)}
                onMouseEnter={() => setColor4(true)}
                className="profile-business-cards__container__card__hover"
              />
              <div
                onMouseLeave={() => setColor4(false)}
                onMouseEnter={() => setColor4(true)}
                className="profile-business-cards__container__card__btn"
              >
                <DefaultButton
                  color="white"
                  hover={colorPalette.colorPrimary.darker}
                  backgroundColor={colorPalette.colorPrimary.main}
                  onClick={() => {
                    handleBusinessCardType(4);
                    setUseAPI(false);
                    setShowMessage(true);
                  }}
                >
                  Select
                </DefaultButton>
              </div>
            </>
          )}

          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={BCard4}
            alt="business-card-4"
          />
        </div>
        <div
          onMouseLeave={() => setColor5(false)}
          onMouseEnter={() => setColor5(true)}
          className="profile-business-cards__container__card"
        >
          {color5 && (
            <>
              <div
                onMouseLeave={() => setColor5(false)}
                onMouseEnter={() => setColor5(true)}
                className="profile-business-cards__container__card__hover"
              />
              <div
                onMouseLeave={() => setColor5(false)}
                onMouseEnter={() => setColor5(true)}
                className="profile-business-cards__container__card__btn"
              >
                <DefaultButton
                  color="white"
                  hover={colorPalette.colorPrimary.darker}
                  backgroundColor={colorPalette.colorPrimary.main}
                  onClick={() => {
                    handleBusinessCardType(5);
                    setUseAPI(false);
                    setShowMessage(true);
                  }}
                >
                  Select
                </DefaultButton>
              </div>
            </>
          )}

          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={BCard5}
            alt="business-card-5"
          />
        </div>
        <div
          onMouseLeave={() => setColor6(false)}
          onMouseEnter={() => setColor6(true)}
          className="profile-business-cards__container__card"
        >
          {color6 && (
            <>
              <div
                onMouseLeave={() => setColor6(false)}
                onMouseEnter={() => setColor6(true)}
                className="profile-business-cards__container__card__hover"
              />
              <div
                onMouseLeave={() => setColor6(false)}
                onMouseEnter={() => setColor6(true)}
                className="profile-business-cards__container__card__btn"
              >
                <DefaultButton
                  color="white"
                  hover={colorPalette.colorPrimary.darker}
                  backgroundColor={colorPalette.colorPrimary.main}
                  onClick={() => {
                    handleBusinessCardType(6);
                    setUseAPI(false);
                    setShowMessage(true);
                  }}
                >
                  Select
                </DefaultButton>
              </div>
            </>
          )}

          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={BCard6}
            alt="business-card-6"
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, { handleBusinessCardType })(ProfileBusinessCard);
