import React from "react";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import "./builder-sites-search.scss";

const BuilderSitesSearch = ({ onSearch, value = null }) => (
  <TextField
    className="builder-sites-search"
    variant="outlined"
    placeholder="Search"
    fullWidth
    value={value || ""}
    InputProps={{
      className: "builder-sites-search__wrapper",
      inputProps: {
        className: "builder-sites-search__input"
      },
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      )
    }}
    onChange={onSearch}
  />
);

export default BuilderSitesSearch;
