import React from "react";

import "./section-text-header.styles.scss";

const SectionTextHeader = ({ align, text }) => (
  <div className="section-text-header" style={{ textAlign: align }}>
    {text}
  </div>
);

export default SectionTextHeader;
