import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  isUserPending: false,
  user: {},
  userError: null
};

const UserReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UserActionTypes.GET.GET_USER_PENDING:
      return { ...state, isUserPending: true };

    case UserActionTypes.GET.GET_USER_SUCCESS:
      return {
        ...state,
        isUserPending: false,
        user: action.payload,
        userError: null
      };

    case UserActionTypes.GET.GET_USER_ERROR:
      return {
        ...state,
        isUserPending: false,
        userError: action.payload
      };

    default:
      return state;
  }
};

export default UserReducer;
