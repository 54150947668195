export const ShippingActionTypes = {
  GET: {
    GET_SHIPPING_METHODS_BY_ME_PENDING: "GET_SHIPPING_METHODS_BY_ME_PENDING",
    GET_SHIPPING_METHODS_BY_ME_SUCCESS: "GET_SHIPPING_METHODS_BY_ME_SUCCESS",
    GET_SHIPPING_METHODS_BY_ME_ERROR: "GET_SHIPPING_METHODS_BY_ME_ERROR",

    GET_SHIPPING_METHOD_BY_ID_PENDING: "GET_SHIPPING_METHOD_BY_ID_PENDING",
    GET_SHIPPING_METHOD_BY_ID_SUCCESS: "GET_SHIPPING_METHOD_BY_ID_SUCCESS",
    GET_SHIPPING_METHOD_BY_ID_ERROR: "GET_SHIPPING_METHOD_BY_ID_ERROR",

    GET_SHIPPING_METHODS_BY_COMPANY_ID_PENDING:
      "GET_SHIPPING_METHODS_BY_COMPANY_ID_PENDING",
    GET_SHIPPING_METHODS_BY_COMPANY_ID_SUCCESS:
      "GET_SHIPPING_METHODS_BY_COMPANY_ID_SUCCESS",
    GET_SHIPPING_METHODS_BY_COMPANY_ID_ERROR:
      "GET_SHIPPING_METHODS_BY_COMPANY_ID_ERROR"
  },
  POST: {
    CREATE_SHIPPING_METHOD_PENDING: "CREATE_SHIPPING_METHOD_PENDING",
    CREATE_SHIPPING_METHOD_SUCCESS: "CREATE_SHIPPING_METHOD_SUCCESS",
    CREATE_SHIPPING_METHOD_ERROR: "CREATE_SHIPPING_METHOD_ERROR"
  },
  PUT: {
    PUT_SHIPPING_METHOD_PENDING: "PUT_SHIPPING_METHOD_PENDING",
    PUT_SHIPPING_METHOD_SUCCESS: "PUT_SHIPPING_METHOD_SUCCESS",
    PUT_SHIPPING_METHOD_ERROR: "PUT_SHIPPING_METHOD_ERROR"
  },
  DELETE: {
    DELETE_SHIPPING_METHOD_BY_ID_PENDING:
      "DELETE_SHIPPING_METHOD_BY_ID_PENDING",
    DELETE_SHIPPING_METHOD_BY_ID_SUCCESS:
      "DELETE_SHIPPING_METHOD_BY_ID_SUCCESS",
    DELETE_SHIPPING_METHOD_BY_ID_ERROR: "DELETE_SHIPPING_METHOD_BY_ID_ERROR"
  },
  CLEAR_DELIVERY_REQUEST_STATUS: "CLEAR_DELIVERY_REQUEST_STATUS"
};
