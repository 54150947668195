import icon from "./edea-products-with-pagination-block-icon.png";

export default function edeaProductsWithPaginationBlock({ editor, api }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style> 
    .edea-products-with-pagination {
      display: flex;
      flex-flow: row wrap;
    }

    .edea-product {
      flex-basis: 33.33%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 20px;
    }

    @media screen and (max-width: 992px) {
      .edea-product {
        flex-basis: 100%;
      }
    }

    .edea-product__image {
      width: 100%;
      height: 150px;
      object-fit: scale-down;
    }

    .edea-product__title {
      color: #262626;
      font-size: 30px;
      font-weight: 700;
      margin: 16px 0;
    }

    .edea-product__divider {
      width: 114px;
      height: 7px;
      background-color: #aeaeae;  
    }

    .edea-product__description {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
    }

    .edea-product__cost {
      color: #262626;
      font-size: 18px;
      font-weight: 700;
      margin: auto 0 8px;
    }

    .edea-product__button {
      background-color: #aeaeae;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 12px 46px;
      border: none;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .edea-product__button:hover {
      background-color: #8c8c8c;
    }

    .simple-pagination {
      align-self: flex-end;
      margin: 0 0 0 auto;
      padding: 20px;
    }

    .simple-pagination__button {
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      border: 2px solid #aeaeae;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .simple-pagination__button.simple-pagination__button--active {
      color: #fff;
      background: #c0c0c0;
    }

    .simple-pagination__button:disabled, .simple-pagination__button:hover {
      background: #ededed;
    }
  </style>
  `;

  const content = `
  <div class="edea-products-with-pagination-block">
    ${style}
    <div class="edea-products-with-pagination">
      
    </div>
  </div>
  `;

  const script = function() {
    function makeProductHtml(src, title, description, cost, currency) {
      return `
      <div class="edea-product">
        <img class="edea-product__image" src="${src}" />
        <h4 class="edea-product__title">${title}</h4>
        <span class="edea-product__divider"></span>
        <p class="edea-product__description">
        ${
          description.length <= 300
            ? description
            : `${description.slice(300)}...`
        }
        </p>
        <b class="edea-product__cost">Cost: ${
          cost ? cost : "N/A"
        } ${currency}</b>
        <button class="edea-product__button">BUY</button>
      </div>
      `;
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      console.table({ currentPage, totalPagesCount, totalCount, countPerPage });
      return `
      <div class="simple-pagination">
        ${
          currentPage - 1
            ? `
            <button class="simple-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="simple-pagination__button" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="simple-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${currentPage - 4 > 0 ? "..." : ""}

        ${
          currentPage - 2 > 0
            ? `
            <button class="simple-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="simple-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="simple-pagination__button simple-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="simple-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${currentPage + 3 <= totalPagesCount ? "..." : ""}

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="simple-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="simple-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="simple-pagination__button" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function update(page, UniquePOSIdentifier) {
      const API_BASE_PATH = "https://qaapi.edea.co.il/JREdeaRetailAPI.2.1.2.13";

      // const date = new Date();
      // date.setMonth(date.getMonth() - 1);
      // const datetime = date.toISOString();

      fetch(`${API_BASE_PATH}/api/POSRegistration/RegisterPOS`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({ UniquePOSIdentifier: UniquePOSIdentifier }),
        mode: "cors"
      }).then(() => {
        fetch(`${API_BASE_PATH}/api/Items/SearchItems`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            UniquePOSIdentifier,
            ChunkNumber: page
            // FromDateTime: datetime
          }),
          mode: "cors"
        })
          .then(response => response.json())
          .then(json => json.ItemSearchDetail)
          .then(products => {
            const el = document.querySelector(".edea-products-with-pagination");

            const productsHtml = products.reduce(
              (tmp, product) =>
                tmp +
                makeProductHtml(
                  product.image,
                  product.name,
                  product.Description,
                  product.CalculatedPriceDetail.PriceAfterVAT,
                  "EUR"
                ),
              ""
            );

            const paginationHtml = makePaginationHtml(
              page,
              999,
              products.length
            );

            el.innerHTML = productsHtml + paginationHtml;

            const paginationButtonsEls = document.querySelectorAll(
              ".simple-pagination__button:not(disabled)"
            );

            [...paginationButtonsEls].map(paginationButtonEl =>
              paginationButtonEl.addEventListener("click", e => {
                const btnPage = parseInt(e.target.dataset.page);
                update(btnPage, UniquePOSIdentifier);
              })
            );
          });
      });
    }

    const BranchNumber =
      this.attributes.branch_number &&
      (this.attributes.branch_number.value || this.attributes.branch_number);
    const POSNumber =
      this.attributes.pos_number &&
      (this.attributes.pos_number.value || this.attributes.pos_number);
    const UniqueIdentifier =
      this.attributes.unique_identifier &&
      (this.attributes.unique_identifier.value ||
        this.attributes.unique_identifier);

    if (BranchNumber && POSNumber && UniqueIdentifier) {
      update(1, {
        BranchNumber,
        POSNumber,
        UniqueIdentifier
      });
    }
  };

  editor.DomComponents.addType("edea-products-with-pagination--component", {
    model: {
      defaults: {
        name: "EDEA Products With Pagination",
        script,
        content,
        traits: [
          {
            type: "string",
            label: "Branch Number",
            name: "branch_number",
            placeholder: "000"
          },
          {
            type: "string",
            label: "POS Number",
            name: "pos_number",
            placeholder: "0"
          },
          {
            type: "string",
            label: "Unique Identifier",
            name: "unique_identifier",
            placeholder: "SomeIdentifier"
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:branch_number",
          this.render
        );
        this.listenTo(this.model, "change:attributes:pos_number", this.render);
        this.listenTo(
          this.model,
          "change:attributes:unique_identifier",
          this.render
        );
      }
    }
  });

  blockManager.add("edea-products-with-pagination", {
    label: `
      <img src="${icon}" alt="'EDEA Products with pagination' block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        EDEA Products With Pagination
      </div>
    `,
    content: {
      type: "edea-products-with-pagination--component"
    },
    category: "EDEA Products"
  });
}
