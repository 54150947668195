import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";

import { getCompaniesByOrganizationId } from "../../redux/APIs/companies/companies.action";

import LoadingSpinner from "../loading-spinner/loading-spinner.component";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "../pagination/pagination.component";

import missingImage from "../../assets/missing-image.png";
import { ReactComponent as MailIcon } from "../../assets/mail.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";

import "./org-comp-tab.styles.scss";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    width: "100%"
  },
  noBorder: {
    border: "none"
  }
});

const OrgCompTab = ({
  getCompaniesByOrganizationId,
  areCompaniesByOrganizationIdPending,
  companiesByOrganizationIdData,
  companiesByOrganizationIdResults
}) => {
  const classes = useStyles();
  const { organizationId } = useParams();
  const getCompaniesByOrganizationIdPage = pageNumber => {
    getCompaniesByOrganizationId(organizationId, pageNumber);
  };

  useEffect(() => {
    getCompaniesByOrganizationId(organizationId);
  }, [getCompaniesByOrganizationId, organizationId]);

  return (
    <>
      {areCompaniesByOrganizationIdPending ? (
        <LoadingSpinner />
      ) : companiesByOrganizationIdResults.length > 0 ? (
        <div className="companies-content-table">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="justify">LOGO</TableCell>
                <TableCell align="justify">NAME</TableCell>
                <TableCell align="left">DESCRIPTION</TableCell>
                <TableCell align="right">FEEDBACK</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companiesByOrganizationIdResults.map(
                ({
                  id,
                  logo,
                  name,
                  short_description,
                  metadata: { phone, email }
                }) => (
                  <TableRow component={Link} to={`/companies/${id}`} key={id}>
                    <TableCell className={classes.noBorder}>
                      <div>
                        <img
                          className="org-comp-tab__logo"
                          src={logo ? logo : missingImage}
                          alt="Company logo"
                        />
                      </div>
                    </TableCell>
                    <TableCell className={classes.noBorder} align="justify">
                      <div>{name}</div>
                    </TableCell>
                    <TableCell className={classes.noBorder} align="left">
                      <div className="inner-description">
                        {parse(short_description)}
                      </div>
                    </TableCell>
                    <TableCell className={classes.noBorder} align="right">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        {/* <GreyChatIcon /> */}
                        {phone && (
                          <div
                            onClick={e => e.stopPropagation()}
                            className="org-comp-tab__icon"
                          >
                            <Phone />
                            <a
                              href={`tel:${phone}`}
                              className="org-comp-tab__icon-tooltip"
                            >
                              {phone}
                            </a>
                          </div>
                        )}
                        {email && (
                          <div
                            onClick={e => e.stopPropagation()}
                            className="org-comp-tab__icon"
                          >
                            <MailIcon />
                            <a
                              href={`mailto:${email}`}
                              className="org-comp-tab__icon-tooltip"
                            >
                              {email}
                            </a>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="org-comp-tab__no-companies">
          No companies to display
        </div>
      )}
      {companiesByOrganizationIdResults.length > 0 && (
        <Pagination
          selectedPage={getCompaniesByOrganizationIdPage}
          dataCount={companiesByOrganizationIdData.count}
          itemsPerPage={10}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  areCompaniesByOrganizationIdPending:
    state.companiesReducer.areCompaniesByOrganizationIdPending,
  companiesByOrganizationIdData:
    state.companiesReducer.companiesByOrganizationIdData,
  companiesByOrganizationIdResults:
    state.companiesReducer.companiesByOrganizationIdResults
});

export default connect(mapStateToProps, { getCompaniesByOrganizationId })(
  OrgCompTab
);
