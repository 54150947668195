import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { UserPlaceholder } from "../../assets";

import { handleAvatarChange } from "../../redux/profile/profile.action";
import DefaultButton from "../default-button/default-button.component";

import { colorPalette } from "../../styles/colorPalette";
import "./profile-media.styles.scss";

const ProfileMedia = ({ handleAvatarChange, profileAPI }) => {
  const [file, setFile] = useState("");

  useEffect(() => {
    if (profileAPI) {
      setFile(profileAPI.avatar);
    }
  }, [profileAPI]);

  return (
    <div className="profile-media">
      {file ? (
        <img className="profile-media__avatar" src={file} alt="avatar" />
      ) : (
        <UserPlaceholder className="profile-media__avatar" />
      )}
      <h2>
        {profileAPI && profileAPI.lastName} {profileAPI && profileAPI.firstName}
      </h2>
      <p>{profileAPI && profileAPI.profession}</p>
      <div className="profile-media__btn">
        <DefaultButton
          color={colorPalette.colorPrimary.main}
          hover={colorPalette.colorPrimary.main}
          hoverColor="white"
          width="100%"
          border={`1px solid ${colorPalette.colorPrimary.main}`}
        >
          <label style={{ cursor: "pointer" }}>
            Upload new avatar
            <input
              accept="image/gif, image/jpeg, image/png"
              type="file"
              onChange={e => {
                setFile(URL.createObjectURL(e.target.files[0]));
                handleAvatarChange(e.target.files[0]);
              }}
              style={{ display: "none", cursor: "pointer" }}
            />
          </label>
        </DefaultButton>
      </div>
      <div className="profile-media__btn">
        <DefaultButton
          color={colorPalette.colorPrimary.dangerRed}
          hover={colorPalette.colorPrimary.dangerRed}
          hoverColor="white"
          width="100%"
          border={`1px solid ${colorPalette.colorPrimary.dangerRed}`}
          onClick={() => {
            setFile("");
            handleAvatarChange("");
          }}
        >
          Delete
        </DefaultButton>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  profileAPI: state.profileAPI.profile
});

export default connect(mapStateToProps, { handleAvatarChange })(ProfileMedia);
