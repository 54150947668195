export const SitesActionTypes = {
  GET_SITES: "GET_SITES",
  GET_SITES_PENDING: "GET_SITES_PENDING",
  GET_SITE: "GET_SITE",
  GET_SITE_PENDING: "GET_SITE_PENDING",
  CREATE_SITE: "CREATE_SITE",
  CREATE_SITE_ERROR: "CREATE_SITE_ERROR",
  ADD_THEME_TO_SITE: "ADD_THEME_TO_SITE",
  UPDATE_SITE: "UPDATE_SITE",
  UPDATE_SITE_ERROR: "UPDATE_SITE_ERROR",
  DELETE_SITE: "DELETE_SITE"
};
