import React, { useEffect, Fragment } from "react";

import { Link, useRouteMatch } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core/";
import { Edit as EditIcon, Clear as ClearIcon } from "@material-ui/icons/";
import {
  GreyChatIcon,
  MailIcon,
  Phone,
  missingImage,
  UserPlaceholder
} from "../../../assets";

import { MyBusinessAddBar } from "../../../containers/my-business/";
import {
  LoadingSpinner,
  DefaultButton,
  DepartmentSingleData
} from "../../../components";

import "./company-type-of-data.styles.scss";

const CompanyTypeOfData = ({
  currentTypeCompany,
  companyVacancies,
  companyDepartments,
  getVacanciesByDepartmentId,
  getMultipleVacanciesDepartmentCompany,
  vacanciesByDepartments,
  setCurrentDepartment,
  deleteDepartment,
  deleteDepartmentVacancy,
  clearPositionVacancy,
  isPendingCompany
}) => {
  useEffect(() => {
    getMultipleVacanciesDepartmentCompany(companyDepartments);
  }, [getMultipleVacanciesDepartmentCompany, companyDepartments]);

  const { url, params } = useRouteMatch();

  return isPendingCompany ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <div className="company-department-info__companies">
        <div className="companies__inner-content">
          <div className="companies__inner-content__company">
            <div className="companies__inner-content__company__image">
              <Link
                to={
                  params.typeOfComp === "c"
                    ? `/companies/${params.companyId}`
                    : `/organizations/${params.companyId}`
                }
              >
                <img
                  className="company-img"
                  src={
                    currentTypeCompany && currentTypeCompany.logo
                      ? currentTypeCompany.logo
                      : missingImage
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="companies__inner-content__company__name">
              <Link
                to={
                  params.typeOfComp === "c"
                    ? `/companies/${params.companyId}`
                    : `/organizations/${params.companyId}`
                }
              >
                {currentTypeCompany && currentTypeCompany.name}:
              </Link>
              <span style={{ fontWeight: "300", padding: "0 15px" }}>
                Department
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="company-department-info__department">
        <MyBusinessAddBar
          title="Department"
          link={`${url}/depart/add-department`}
        />
        <div className="department-inner-content">
          {companyDepartments.length ? (
            companyDepartments.map((department, index) => (
              <DepartmentSingleData
                key={department.id}
                setCurrentDepartment={setCurrentDepartment}
                vacancies={
                  vacanciesByDepartments && vacanciesByDepartments[index]
                }
                url={url}
                department={department}
                getVacanciesByDepartmentId={getVacanciesByDepartmentId}
                deleteDepartmentVacancy={deleteDepartmentVacancy}
                deleteDepartment={deleteDepartment}
              />
            ))
          ) : (
            <h3>No departments</h3>
          )}
        </div>
      </div>

      <div className="company-department-info__staff">
        <MyBusinessAddBar title="Staff" link={`${url}/staff/create`} />
        <div className="company-department-info__staff__inner-content">
          {companyVacancies.length &&
          !!companyVacancies.filter(user => user.profile.length > 0).length ? (
            <Table aria-label="items table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">PHOTO</TableCell>
                  <TableCell align="center">NAME</TableCell>
                  <TableCell align="center">DEPARTMENT</TableCell>
                  <TableCell align="center">POSITION</TableCell>
                  <TableCell align="center">CONTACT</TableCell>
                  <TableCell align="center">STATUS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyVacancies
                  .filter(({ user }) => user !== null)
                  .map(vacancy => (
                    <TableRow key={vacancy.id}>
                      <TableCell align="center">
                        {vacancy.profile[0] && vacancy.profile[0].avatar ? (
                          <img
                            className="staff-image"
                            src={
                              vacancy.profile.length &&
                              vacancy.profile[0].avatar
                            }
                            alt=""
                          />
                        ) : (
                          <UserPlaceholder className="staff-image" />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {vacancy.profile.length &&
                          `${vacancy.profile[0].first_name ||
                            "No name"}  ${vacancy.profile[0].last_name ||
                            "No name"}`}
                      </TableCell>
                      <TableCell align="center">
                        {vacancy.department && vacancy.department.name}
                      </TableCell>
                      <TableCell align="center">{vacancy.name}</TableCell>
                      <TableCell align="center">
                        <div className="contact-links">
                          <MailIcon width="16px" height="16px" />
                          <Phone />
                          <GreyChatIcon />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="staff-links">
                          <Link to={`${url}/staff/${vacancy.id}`}>
                            <DefaultButton className="btn-edit">
                              <EditIcon style={{ fontSize: 20 }} />
                            </DefaultButton>
                          </Link>

                          <DefaultButton
                            onClick={() =>
                              clearPositionVacancy(vacancy.id, params.companyId)
                            }
                            className="btn-remove"
                          >
                            <ClearIcon style={{ fontSize: 24 }} />
                          </DefaultButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <div style={{ padding: "20px 15px" }}>No staff...</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CompanyTypeOfData;
