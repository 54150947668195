import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import Editor from "../editor/editor.component";

import LanguageIcon from "@material-ui/icons/Language";
import { ReactComponent as Pin } from "../../assets/icons/Pin.svg";
import { ReactComponent as Phone } from "../../assets/icons/Phone.svg";

import "./company-small-card.styles.scss";

const CompanySmallCard = ({
  name,
  description,
  location,
  phone,
  website,
  editorState,
  editLink,
  logo,
  removeFunc,
  scaleDown,
  appDirection,
  id
}) => {
  const [editor, setEditor] = useState(false);

  const { url } = useRouteMatch();

  return (
    <div
      onMouseEnter={() => setEditor(true)}
      onMouseLeave={() => setEditor(false)}
      className={`company-small-card ${appDirection}`}
    >
      <div className="company-small-card__img">
        <img
          src={logo}
          alt="placeholder"
          style={scaleDown && { objectFit: "scale-down" }}
        />
      </div>
      <div className="company-small-card__body">
        <div className="company-small-card__body__name">
          <Link
            to={
              url.includes("/companies")
                ? `/companies/${id}`
                : `/organizations/${id}`
            }
          >
            {name}
          </Link>
        </div>
        <div className="company-small-card__body__description">
          {description}
        </div>
        <div className="company-small-card__body__contacts">
          <div className="company-small-card__body__contacts__contact">
            <div className="company-small-card__body__contacts__contact__icon">
              <Pin width="20px" height="20px" />
            </div>
            <div className="company-small-card__body__contacts__contact__data">
              {location}
            </div>
          </div>
          <div className="company-small-card__body__contacts__contact">
            <div className="company-small-card__body__contacts__contact__icon">
              <Phone width="20px" height="20px" />
            </div>
            <div className="company-small-card__body__contacts__contact__data">
              {phone && phone.length > 12 ? phone.substring(0, 12) : phone}
            </div>
          </div>
          <div className="company-small-card__body__contacts__contact">
            <div className="company-small-card__body__contacts__contact__icon">
              <LanguageIcon />
            </div>
            <div className="company-small-card__body__contacts__contact__data">
              {website}
            </div>
          </div>
        </div>
      </div>
      {editorState && (
        <div className="company-small-card__editor">
          {editor && <Editor removeFunc={removeFunc} editLink={editLink} />}
        </div>
      )}
    </div>
  );
};

export default CompanySmallCard;
