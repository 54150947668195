import { axiosInstance } from "@core/axios/axios.config";
import { IntegrationsActionTypes } from "./integrations.types";

export const getIntegrations = organization => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.GET.GET_INTEGRATIONS_PENDING
  });

  axiosInstance
    .get(`/api/v1/integrations/?organization=${organization}`)
    .then(integrations => {
      dispatch({
        type: IntegrationsActionTypes.GET.GET_INTEGRATIONS_SUCCESS,
        payload: integrations.data
      });
    })
    .catch(err =>
      dispatch({
        type: IntegrationsActionTypes.GET.GET_INTEGRATIONS_ERROR,
        payload: err
      })
    );
};

export const getIntegrationById = id => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.GET.GET_INTEGRATION_BY_ID_PENDING
  });

  axiosInstance
    .get(`/api/v1/integrations/${id}/`)
    .then(integration => {
      dispatch({
        type: IntegrationsActionTypes.GET.GET_INTEGRATION_BY_ID_SUCCESS,
        payload: integration.data
      });
    })
    .catch(err =>
      dispatch({
        type: IntegrationsActionTypes.GET.GET_INTEGRATION_BY_ID_ERROR,
        payload: err
      })
    );
};

export const getIntegrationLogsById = id => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.GET.GET_INTEGRATION_LOG_BY_ID_PENDING
  });

  axiosInstance
    .get(`/api/v1/integrations/${id}/log/`)
    .then(logs => {
      dispatch({
        type: IntegrationsActionTypes.GET.GET_INTEGRATION_LOG_BY_ID_SUCCESS,
        payload: logs.data
      });
    })
    .catch(err =>
      dispatch({
        type: IntegrationsActionTypes.GET.GET_INTEGRATION_LOG_BY_ID_ERROR,
        payload: err
      })
    );
};

export const createIntegration = ({
  branchNumber,
  POSNumber,
  uniqueIdentifier,
  companyId,
  isSync,
  syncInterval
}) => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.POST.CREATE_INTEGRATION_PENDING
  });

  axiosInstance
    .post(`/api/v1/integrations/`, {
      system: "edea",
      config: {
        BranchNumber: branchNumber,
        POSNumber,
        UniqueIdentifier: uniqueIdentifier
      },
      organization: companyId,
      is_sync: isSync,
      interval: syncInterval
    })
    .then(integration => {
      dispatch({
        type: IntegrationsActionTypes.POST.CREATE_INTEGRATION_SUCCESS,
        payload: integration.data
      });
      dispatch(getIntegrations(companyId));
    })
    .catch(err =>
      dispatch({
        type: IntegrationsActionTypes.POST.CREATE_INTEGRATION_ERROR,
        payload: err
      })
    );
};

export const updateIntegrationById = ({
  id,
  branchNumber,
  POSNumber,
  uniqueId,
  organization,
  is_sync,
  interval
}) => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.PATCH.UPDATE_INTEGRATION_PENDING
  });

  axiosInstance
    .patch(`/api/v1/integrations/${id}/`, {
      system: "edea",
      config: {
        BranchNumber: branchNumber,
        POSNumber,
        UniqueIdentifier: uniqueId
      },
      organization,
      is_sync,
      interval
    })
    .then(integration => {
      dispatch({
        type: IntegrationsActionTypes.PATCH.UPDATE_INTEGRATION_SUCCESS,
        payload: integration.data
      });
    })
    .catch(err =>
      dispatch({
        type: IntegrationsActionTypes.PATCH.UPDATE_INTEGRATION_ERROR,
        payload: err
      })
    );
};

export const deleteIntegration = id => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.DELETE.DELETE_INTEGRATION_PENDING
  });

  axiosInstance
    .delete(`/api/v1/integrations/${id}/`)
    .then(integration => {
      dispatch({
        type: IntegrationsActionTypes.DELETE.DELETE_INTEGRATION_SUCCESS,
        payload: integration.data
      });
    })
    .catch(err =>
      dispatch({
        type: IntegrationsActionTypes.DELETE.DELETE_INTEGRATION_ERROR,
        payload: err
      })
    );
};
