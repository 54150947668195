import React from "react";

import "./default-input-time-only.styles.scss";

const DefaultInputTimeOnly = ({ label, changeHandler }) => {
  return (
    <div className="default-input-time">
      <label>{label}</label>
      <input type="time" onChange={changeHandler} />
    </div>
  );
};

export default DefaultInputTimeOnly;
