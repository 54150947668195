import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";

import "./product-switch.styles.scss";

const ProductSwitch = ({
  isToggled,
  onToggle,
  localizationData,
  style = {}
}) => {
  const withStyles = makeStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      margin: "0 12px",
      display: "flex"
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#dcdcdc",
          borderColor: "#dcdcdc"
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
      backgroundColor: "orange"
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#dcdcdc",
      borderColor: "#dcdcdc",
      height: 14
    },
    checked: {}
  }));
  const classes = withStyles();
  return (
    <div className="product-switch" style={style}>
      <p className={!isToggled ? "product-switch__bold" : undefined}>
        {localizationData["B2B Products"]}
      </p>
      <Switch
        onChange={onToggle}
        checked={isToggled}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
      />
      <p className={isToggled ? "product-switch__bold" : undefined}>
        {localizationData["B2C Products"]}
      </p>
    </div>
  );
};

const mapStateToProps = state => ({
  localizationData: state.appLanguage.localizationData
});

export default connect(mapStateToProps, null)(ProductSwitch);
