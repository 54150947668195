import { axiosNoTokenInstance } from "../../axios/axios.config";
import { LocalizationActionTypes } from "../localization/localization.types";

export const setApplicationDirection = language => dispatch => {
  const langIncludes = language.includes("HE") || language.includes("AR");

  dispatch({
    type: LocalizationActionTypes.SET_APPLICATION_DIRECTION,
    payload: {
      direction: langIncludes ? "rtl" : "ltr",
      rtlStatus: langIncludes
    }
  });
};

export const setApplicationLanguage = language => dispatch => {
  dispatch({ type: LocalizationActionTypes.SET_APPLICATION_LANGUAGE_PENDING });
  dispatch({
    type: LocalizationActionTypes.SET_APPLICATION_LANGUAGE_SUCCESS,
    payload: language
  });
};

export const getLocalizationData = dataLanguage => dispatch => {
  let lang = dataLanguage.includes("en") ? "eng" : dataLanguage;
  dispatch({ type: LocalizationActionTypes.GET_LOCALIZATION_DATA_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/localization/?iso=${lang}`)
    .then(res =>
      dispatch({
        type: LocalizationActionTypes.GET_LOCALIZATION_DATA_SUCCESS,
        payload: res.data
      })
    )
    .catch(error => {
      dispatch({
        type: LocalizationActionTypes.GET_LOCALIZATION_DATA_ERROR,
        payload: error
      });
      dispatch(getLocalizationData("eng"));
    });
};

export const setBurgerToggleOpen = status => ({
  type: LocalizationActionTypes.SET_BURGER_TOGGLE_OPEN,
  payload: status
});
