import productsFilterBlock from "./block";
import productsFilterComponent from "./component";

export default ({
  editor,
  api,
  site,
  className = "products-filter-select"
}) => {
  // Init block
  productsFilterBlock({ editor, className });
  // Init component
  productsFilterComponent({ editor, api, site, className });
};
