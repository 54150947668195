import React from "react";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./integration-monitor.styles.scss";
import { useTranslation } from "react-i18next";

const IntegrationMonitor = ({ integrationLogs }) => {
  const { t } = useTranslation();
  const appDirection = useSelector(state => state.appLanguage.appDirection);
  return (
    <div className={`my-business__integration-monitor ${appDirection}`}>
      <h3 style={{ margin: 0 }}>{t("Integration logs")}</h3>
      <div className="integration-monitor__container">
        {integrationLogs.length > 0 &&
          integrationLogs.map(item => {
            const { error, is_completed, display_error, created, name } = item;
            return (
              <Accordion>
                <AccordionSummary
                  className={`integration-monitor__item ${error && "error"}`}
                  expandIcon={<ExpandMoreIcon />}
                >
                  {name}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="itegration-monitor__item-content">
                    <div className="integration-monitor__item-status">
                      {is_completed ? "Completed" : "Not completed"}
                    </div>
                    {(display_error || error) && (
                      <div className="integration-monitor__item-error">
                        {display_error && (
                          <span className="integration-monitor__error-title">
                            {display_error}:
                          </span>
                        )}
                        {error && (
                          <span className="integration-monitor__error-text">
                            {error}
                          </span>
                        )}
                      </div>
                    )}
                    <div className="integration-monitor__item-time">
                      {created && new Date(created).toUTCString()}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
};

export default IntegrationMonitor;
