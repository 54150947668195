import React from "react";

import { useParams } from "react-router-dom";

import CompOrgContactsContact from "../comp-org-contacts-contact/comp-org-contacts-contact.component";
import CompOrgContactsBank from "../comp-org-contacts-bank/comp-org-contacts-bank.component";
import CompOrgContactsMap from "../comp-org-contacts-map/comp-org-contacts-map.component";
import MyBusinessLeadsEditComponent from "../../containers/my-business/leads-edit/my-business-leads-edit.component";

import "./comp-org-contacts-tab.styles.scss";

const CompOrgContactsTab = ({
  organizationName,
  metadata,
  companySelected
}) => {
  const { companyId } = useParams();

  return (
    <div className="comp-org-contacts-tab">
      <CompOrgContactsContact companySelected={companySelected} />
      <CompOrgContactsBank companySelected={companySelected} />
      <CompOrgContactsMap
        organizationName={organizationName}
        companySelected={companySelected}
        location={metadata && metadata.location}
      />
      <MyBusinessLeadsEditComponent currentId={companyId} />
    </div>
  );
};

export default CompOrgContactsTab;
