export const SiteBuilderActionTypes = {
  POST: {
    CREATE_SITE_PENDING: "CREATE_SITE_PENDING",
    CREATE_SITE_SUCCESS: "CREATE_SITE_SUCCESS",
    CREATE_SITE_ERROR: "CREATE_SITE_ERROR"
  },
  GET: {
    GET_SITES_PENDING: "GET_SITES_PENDING",
    GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
    GET_SITES_ERROR: "GET_SITES_ERROR",

    GET_SITE_BY_DOMAIN_PENDING: "GET_SITE_BY_DOMAIN_PENDING",
    GET_SITE_BY_DOMAIN_SUCCESS: "GET_SITE_BY_DOMAIN_SUCCESS",
    GET_SITE_BY_DOMAIN_ERROR: "GET_SITE_BY_DOMAIN_ERROR",

    GET_SITE_BY_ID_PENDING: "GET_SITE_BY_ID_PENDING",
    GET_SITE_BY_ID_SUCCESS: "GET_SITE_BY_ID_SUCCESS",
    GET_SITE_BY_ID_ERROR: "GET_SITE_BY_ID_ERROR",

    GET_DOMAIN_TYPES: "GET_DOMAIN_TYPES"
  },
  PUT: {
    UPDATE_SITE_PENDING: "UPDATE_SITE_PENDING",
    UPDATE_SITE_SUCCESS: "UPDATE_SITE_SUCCESS",
    UPDATE_SITE_ERROR: "UPDATE_SITE_ERROR"
  },
  DELETE: {
    DELETE_SITE_PENDING: "DELETE_SITE_PENDING",
    DELETE_SITE_SUCCESS: "DELETE_SITE_SUCCESS",
    DELETE_SITE_ERROR: "DELETE_SITE_ERROR"
  }
};
