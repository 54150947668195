export const OrganizationsActionTypes = {
  GET_ORGANIZATION_TYPES: "GET_ORGANIZATION_TYPES",
  GET_ORGANIZATIONS: "GET_ORGANIZATIONS",
  GET_ORGANIZATION: "GET_ORGANIZATION",
  GET_ORGANIZATION_PENDING: "GET_ORGANIZATION_PENDING",
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  UPDATE_ORGANIZATION: "UPDATE_ORGANIZATION",
  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  GET_USER_ORGANIZATIONS: "GET_USER_ORGANIZATIONS",
  GET_USER_ORGANIZATIONS_PENDING: "GET_USER_ORGANIZATIONS_PENDING",

  GET: {
    GET_USER_ORGANIZATIONS_CHAMBER_PENDING:
      "GET_USER_ORGANIZATIONS_CHAMBER_PENDING",
    GET_USER_ORGANIZATIONS_CHAMBER_SUCCESS:
      "GET_USER_ORGANIZATIONS_CHAMBER_SUCCESS",
    GET_USER_ORGANIZATIONS_CHAMBER_ERROR:
      "GET_USER_ORGANIZATIONS_CHAMBER_ERROR",
    SEARCH: {
      SEARCH_ORGANIZATIONS_PENDING: "SEARCH_ORGANIZATIONS_PENDING",
      SEARCH_ORGANIZATIONS_SUCCESS: "SEARCH_ORGANIZATIONS_SUCCESS",
      SEARCH_ORGANIZATIONS_ERROR: "SEARCH_ORGANIZATIONS_ERROR"
    },
    GET_CHAMBER_TYPES_PENDING: "GET_CHAMBER_TYPES_PENDING",
    GET_CHAMBER_TYPES_SUCCESS: "GET_CHAMBER_TYPES_SUCCESS",
    GET_CHAMBER_TYPES_ERROR: "GET_CHAMBER_TYPES_ERROR"
  },
  PATCH: {
    IMAGE: {
      PATCH_ORGANIZATION_IMAGE_PENDING: "PATCH_ORGANIZATION_IMAGE_PENDING",
      PATCH_ORGANIZATION_IMAGE_SUCCESS: "PATCH_ORGANIZATION_IMAGE_SUCCESS",
      PATCH_ORGANIZATION_IMAGE_ERROR: "PATCH_ORGANIZATION_IMAGE_ERROR"
    }
  },
  CLEAR_ORGANIZATION_REQUEST: "CLEAR_ORGANIZATION_REQUEST"
};
