export default function authContainerBlock({ editor }) {
  const script = function() {
    if (!window.hasOwnProperty("grapesjs")) return;

    const isAuthenticated = window.localStorage.hasOwnProperty("token");
    const wrapperId = this.id;
    const wrapperEl = document.getElementById(wrapperId);
    const validator = this.dataset.hasOwnProperty("hideIfAuth")
      ? isAuthenticated
      : !isAuthenticated;

    if (validator) {
      Object.assign(wrapperEl.style, {
        opacity: 0,
        display: "none",
        visibility: "hidden",
        pointerEvents: "none"
      });
    }
  };

  editor.DomComponents.addType("auth-container", {
    model: {
      defaults: {
        tagName: "div",
        name: "Auth Container",
        script,
        traits: [
          {
            type: "checkbox",
            label: "Hide from authorized user",
            name: "data-hide-if-auth"
          }
        ]
      }
    }
  });
}
