import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { Clear as ClearIcon } from "@material-ui/icons";

import { missingImage } from "../../assets";

import { deleteAllDeal } from "../../redux/APIs/cart/cart.action";

import DefaultIconButton from "../default-icon-button/default-icon-button.component";
import QuantityInputHorizontal from "../quntity-input-horizontal/quntity-input-horizontal.component";
// import Pagination from "../pagination/pagination.component";

import "./cart-products-table.styles.scss";

const CartProductsTable = ({
  appDirection,
  cartData,
  contentType,
  discount = false,
  deleteDeal,
  editCart,
  getDeal,
  getCart,
  cart,
  setGroupedArray,
  setFilteredDealObject,
  deleteAllDeal
}) => {
  useEffect(() => {
    const groupBy = (xs, key) => {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let filteredData = [];
    let filteredDeal = [];

    cartData.map(({ order_items, currency }) => {
      return order_items
        .filter(({ content_type }) => content_type === contentType)
        .map(data => {
          data.currency = currency;
          return filteredData.push(data);
        });
    });

    cartData.map(({ order_items, currency }) => {
      return order_items.map(data => {
        data.currency = currency;
        return filteredDeal.push(data);
      });
    });

    setGroupedArray(groupBy(filteredData, "currency"));
    setFilteredDealObject(groupBy(filteredDeal, "currency"));
  }, [
    cart,
    contentType,
    cartData,
    setGroupedArray,
    setFilteredDealObject,
    deleteAllDeal
  ]);

  const timeoutDeal = () => {
    setTimeout(function() {
      getDeal();
      getCart();
    }, 1000);
  };

  return (
    <div className={`cart-products-table ${appDirection}`}>
      <Table aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell align="left">PRODUCT DETAILS</TableCell>
            <TableCell align="left">UNIT PRICE</TableCell>
            <TableCell align="left">QUANTITY</TableCell>
            <TableCell align="left">TOTAL</TableCell>
            <TableCell
            // className="remove-items"
            // // onClick={() => removeFromCartHandler()}
            // align="left"
            >
              {/* REMOVE ALL ITEMS */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartData.map(({ order_items, currency }) => {
            return order_items
              .filter(({ content_type }) => content_type === contentType)
              .map(
                ({ id, image, name, quantity, cost, categories, cart_id }) => (
                  <TableRow key={id}>
                    <TableCell align="left">
                      <Link
                        to={
                          contentType === 84
                            ? `/product/b2b/${id}/`
                            : `/product/b2c/${id}/`
                        }
                      >
                        <div className="cart-products-table__product-details">
                          <div className="cart-products-table__product-details__image">
                            <img src={image ? image : missingImage} alt="" />
                          </div>
                          <div className="cart-products-table__product-details__text">
                            <div className="cart-products-table__product-details__text__title">
                              {categories[0] && categories[0].name}
                            </div>
                            <div className="cart-products-table__product-details__text__description">
                              {name}
                            </div>
                            {discount && (
                              <div className="cart-products-table__product-details__text__offer">
                                30%
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <div className="cart-products-table__price">
                        <div className="cart-products-table__price__regular">
                          <span>{cost}</span>
                          <span>{` ${currency}`}</span>
                        </div>
                        {discount && (
                          <div className="cart-products-table__price__previous">
                            $20.00
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="cart-products-table__quantity">
                        <QuantityInputHorizontal
                          func={editCart}
                          data={cart_id}
                          initialQuantity={quantity}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="cart-products-table__price-total">
                        {`${(cost * quantity).toFixed(2)} ${currency}`}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        onClick={() => {
                          deleteDeal(cart_id);
                          timeoutDeal();
                        }}
                      >
                        <DefaultIconButton>
                          <ClearIcon />
                        </DefaultIconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection
});

export default connect(mapStateToProps, { deleteAllDeal })(CartProductsTable);
