import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams } from "react-router-dom";

import {
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog
} from "@core/components";

import Category from "@core/categories/components/category/category.component";

import {
  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany,
  deleteCategory
} from "@core/categories/store/API/categories/categories.action";

import { MyBusinessAddBar } from "@core/containers";

import "./my-business-categories-by-company.styles.scss";
import { useTranslation } from "react-i18next";

const ChildLevel = ({
                      children,
                      categoryType,
                      setDialogOpen,
                      setCurrentCategoryId,
                      setCurrentCategoryType
                    }) => (
  <div className="categories__child-container">
    {children.map((subCategory, index) => (
      <>
        <Category
          typeOfCategory={categoryType}
          key={`sub_${categoryType}_${index}`}
          category={subCategory}
          editorState={true}
          editLink={`/my-business/categories/${categoryType}/${subCategory.id}`}
          removeFunc={() => {
            setDialogOpen(true);
            setCurrentCategoryId(subCategory.id);
            setCurrentCategoryType(categoryType);
          }}
        />
        {subCategory?.children?.length > 0 &&
        <ChildLevel
          children={subCategory.children}
          setDialogOpen={setDialogOpen}
          categoryType={categoryType}
          setCurrentCategoryId={setCurrentCategoryId}
          setCurrentCategoryType={setCurrentCategoryType}
        />
        }
      </>
    ))}
  </div>
);

const MyBusinessCategoriesByCompany = ({
                                         searchB2BCategoriesByCompany,
                                         searchB2CCategoriesByCompany,
                                         isSearchB2BCategoriesPending,
                                         searchedB2BCategories,
                                         isSearchB2CCategoriesPending,
                                         searchedB2CCategories,
                                         categoriesType,
                                         deleteCategory
                                       }) => {
  const { url } = useRouteMatch();
  const { compId } = useParams();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentCategoryType, setCurrentCategoryType] = useState(null);

  useEffect(() => {
    searchB2CCategoriesByCompany(compId);
    searchB2BCategoriesByCompany(compId);
  }, [compId]); // eslint-disable-line

  return (
    <div className="my-business-tasks">
      <ConfirmDialog
        dialogTitle={t("Remove category")}
        dialogText={t(
          "Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        )}
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteCategory(currentCategoryType, currentCategoryId);
          setDialogOpen(false);
          setCurrentCategoryId(null);
          setCurrentCategoryType(null);
        }}
      />
      {isSearchB2BCategoriesPending && isSearchB2CCategoriesPending ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="my-business-companies">
            <div className="company-department-container">
              <MyBusinessAddBar
                title={t("B2C Categories")}
                link={"/my-business/categories/b2c/create"}
              />
              {searchedB2CCategories && searchedB2CCategories.length ? (
                searchedB2CCategories.map((category, index) => (
                  <>
                    <Category
                      typeOfCategory={"b2c"}
                      key={index}
                      category={category}
                      editorState={true}
                      editLink={`/my-business/categories/b2c/${category.id}`}
                      removeFunc={() => {
                        setDialogOpen(true);
                        setCurrentCategoryId(category.id);
                        setCurrentCategoryType("b2c");
                      }}
                    />
                    {!!category?.children.length &&
                    <ChildLevel
                      children={category.children}
                      setDialogOpen={setDialogOpen}
                      categoryType="b2c"
                      setCurrentCategoryId={setCurrentCategoryId}
                      setCurrentCategoryType={setCurrentCategoryType}
                    />
                    }
                  </>
                ))
              ) : (
                <MyBusinessNoData noDataText="No B2C Categories found!" />
              )}
            </div>
          </div>
          <br />
          <div className="my-business-companies">
            <div className="company-department-container">
              <MyBusinessAddBar
                title={t("B2B Categories")}
                link={"/my-business/categories/b2b/create"}
              />
              {searchedB2BCategories && searchedB2BCategories.length ? (
                searchedB2BCategories.map((category, index) => (
                  <>
                    <Category
                      typeOfCategory={"b2b"}
                      key={index}
                      category={category}
                      editorState={true}
                      editLink={`/my-business/categories/b2b/${category.id}`}
                      removeFunc={() => {
                        setDialogOpen(true);
                        setCurrentCategoryId(category.id);
                        setCurrentCategoryType("b2b");
                      }}
                    />
                    {!!category?.children.length &&
                    <ChildLevel
                      children={category.children}
                      setDialogOpen={setDialogOpen}
                      categoryType="b2b"
                      setCurrentCategoryId={setCurrentCategoryId}
                      setCurrentCategoryType={setCurrentCategoryType}
                    />
                    }
                  </>
                ))
              ) : (
                <MyBusinessNoData noDataText={t("No B2B Categories found!")} />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isSearchB2BCategoriesPending: state.categoriesAPI.isSearchB2BCategoriesPending,
  searchedB2BCategories: state.categoriesAPI.searchedB2BCategories,
  isSearchB2CCategoriesPending: state.categoriesAPI.isSearchB2CCategoriesPending,
  searchedB2CCategories: state.categoriesAPI.searchedB2CCategories
});

export default connect(mapStateToProps, {
  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany,
  deleteCategory
})(MyBusinessCategoriesByCompany);
