import React from "react";

import "./input-group.styles.scss";

const InputGroup = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  style,
  required,
  type = "text",
  disabled
}) => {
  return (
    <div className="input-group" style={style}>
      <>
        <div style={{ display: "flex" }}>
          <label className="input-group__label" htmlFor={name}>
            {label}
          </label>

          {required && (
            <div className="input-group__input--required">* required</div>
          )}
        </div>

        <br />
      </>

      <input
        className="input-group__input"
        onChange={onChange}
        type={type}
        // id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export default InputGroup;
