import { axiosNoTokenInstance } from "../../../axios/axios.config";
import { ProducersActionTypes } from "./producers.types";

export const searchProducers = (searchQuery, page = 1) => dispatch => {
  dispatch({ type: ProducersActionTypes.SEARCH.SEARCH_PRODUCERS_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/producer/?search=${searchQuery}&page=${page}`)
    .then(resp =>
      dispatch({
        type: ProducersActionTypes.SEARCH.SEARCH_PRODUCERS_SUCCESS,
        payload: { data: resp.data, results: resp.data.results }
      })
    )
    .catch(err =>
      dispatch({
        type: ProducersActionTypes.SEARCH.SEARCH_PRODUCERS_ERROR,
        payload: err
      })
    );
};

export const getB2CProducers = (pageNumber = 1) => dispatch => {
  dispatch({
    type: ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/producer/?page=${pageNumber}`)
    .then(B2CProducers =>
      dispatch({
        type: ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_SUCCESS,
        payload: B2CProducers.data
      })
    )
    .catch(error =>
      dispatch({
        type: ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_ERROR,
        payload: error
      })
    );
};

export const searchB2CProducers = (searchQuery, page = 1) => dispatch => {
  dispatch({
    type: ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/producer/?search=${searchQuery}&page=${page}`)
    .then(B2CProducer =>
      dispatch({
        type: ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_SUCCESS,
        payload: B2CProducer.data
      })
    )
    .catch(error =>
      dispatch({
        type: ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_ERROR,
        payload: error
      })
    );
};
