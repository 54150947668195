export default function productDetailsComponent({ editor, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function tabs($classPrefix) {
      const tabsEls = document.querySelectorAll(`.${$classPrefix}__tab`);
      Array.prototype.slice
        .call(tabsEls)
        .map(tabEl => {
          tabEl.addEventListener("click", e => {
            e.stopImmediatePropagation();

            Array.prototype.slice
              .call(tabsEls)
              .map(otherTabEl =>
                otherTabEl.classList.remove(`${$classPrefix}__tab--active`)
              );

            tabEl.classList.add(`${$classPrefix}__tab--active`);
            const selector = tabEl.dataset.tabSelector;
            const contentEl = document.querySelector(selector);
            contentEl && (contentEl.style.display = "block");

            tabs($classPrefix);
          });
          return tabEl;
        })
        .filter(
          tabEl => !tabEl.classList.contains(`${$classPrefix}__tab--active`)
        )
        .map(tabEl => {
          const selector = tabEl.dataset.tabSelector;
          const contentEl = document.querySelector(selector);
          return contentEl && (contentEl.style.display = "none");
        });
    }

    function carousel(selector, onInit) {
      // eslint-disable-next-line no-undef
      new Siema({
        selector: selector,
        duration: 200,
        easing: "ease-out",
        perPage: 3,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: false,
        rtl: localStorage.getItem("direction") === "rtl",
        onInit: onInit || (() => {}),
        onChange: () => {}
      });
    }

    function zoom(selector) {
      // eslint-disable-next-line no-undef
      new Drift(document.querySelector(selector), {
        inlinePane: true,
        containInline: true,
        zoomFactor: 1.5
      });
    }

    function calculatePrice(cost = 0, discountPercent = 0, couponPercent = 0) {
      const finnalyDiscountPercent = couponPercent || discountPercent;
      if (!finnalyDiscountPercent) return parseFloat(cost);

      return parseFloat(
        cost - (parseFloat(cost) / 100) * parseFloat(finnalyDiscountPercent)
      );
    }

    function isCouponValid(coupon) {
      return (
        coupon &&
        coupon.date.start &&
        new Date().getTime() - new Date(coupon.date.start).getTime() >= 0 &&
        coupon.date.end &&
        new Date(coupon.date.end).getTime() - new Date().getTime() >= 0
      );
    }

    function makeProductDescriptionHtml(
      id,
      src,
      title,
      description,
      shortDescription,
      cost,
      priceDiscount,
      discountPercent,
      coupon,
      currency,
      company,
      is_active,
      images,
      videos,
      documents,
      pages,
      variations,
      stock,
      producer,
      additionalParams,
      detailsPath,
      translations,
      sku,
      dynamicAttributesKeys,
      dynamicAttributesValues
    ) {
      let specifications;
      try {
        let dynamicAttrKeys = dynamicAttributesKeys
          .replace(/[\][]/g, "")
          .replace(/'/g, '"');

        dynamicAttrKeys = JSON.parse(dynamicAttrKeys);

        let dynamicAttrValues = dynamicAttributesValues
          .replace(/[\][' ]/g, "")
          .split(",");

        specifications = Object.fromEntries(
          Object.keys(dynamicAttrKeys).map(key => [
            dynamicAttrKeys[key],
            dynamicAttrValues[key]
          ])
        );
      } catch (error) {
        console.error(error);
      }

      const isRTL = window.localStorage.getItem("direction") === "rtl";

      return `
      <div class="product-details">           
        <div class="product-details__media-header">
          <img 
            class="product-details__image" 
            src="${src}"
            data-zoom="${src}"
            onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" 
          />

          <div class="product-gallery-carousel">
          ${images
            .map(
              src => `
                <img
                  class="product-gallery-carousel__image"
                  src="${src}"
                  alt="carousel image"
                  loading="lazy"
                  onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                />
              `
            )
            .join("")}
          </div>
        </div>
        <div class="product-details__main" style="${!!isRTL &&
          "text-align: right"}">
          <div class="product-details__header">
            <h4 class="product-details__title">${title}</h4>
          </div>
          <div class="product-details__meta">
            <span class="product-details__meta-item">
              <span class="product-details__caption product-details__caption--product">
                ${translations["PRODUCT PRICE:"]} 
              </span>
              ${
                cost && currency
                  ? !discountPercent && !priceDiscount
                    ? `
                  <span class="product-details__cost">
                    ${cost}
                  </span>
                  <span class="product-details__currency">
                    ${translations[currency]}
                  </span>
                  `
                    : discountPercent
                    ? `
                  <span class="product-details__cost product-details__cost--discount">
                    ${cost}
                  </span>
                  <span class="product-details__cost">
                    ${calculatePrice(cost, discountPercent).toFixed(2)}
                  </span>
                  <span class="product-details__currency">
                    ${translations[currency]}
                  </span>
                  <span class="product-details__discount">
                    -${discountPercent.toFixed(2)}%
                  </span>
                  `
                    : priceDiscount > 0
                    ? `
                    <span class="product-details__cost">
                      <div class="product-details__cost-new">${priceDiscount} <span class="product-details__currency">
                      ${translations[currency]}
                    </span></div>
                      <div class="product-details__cost-old" style="text-decoration: line-through;">${cost} <span class="product-details__currency">
                      ${translations[currency]}
                    </span></div>
                    </span>
                    `
                    : ""
                  : `${translations["N/A"]}`
              }
            </span>

            <span class="product-details__meta-delimiter">•</span>

            <span class="product-details__meta-item">
              <span class="product-details__caption product-details__caption--stock">
                ${
                  stock
                    ? translations["IN STOCK:"]
                    : translations["OUT OF STOCK"]
                }
              </span>
            </span>

            ${
              producer
                ? `
                <span class="product-details__meta-delimiter">•</span>

                <span class="product-details__meta-item">
                  <span class="product-details__caption product-details__caption--stock">
                   ${translations["PRODUCER:"]}
                  </span>
                  <span class="product-details__producer">
                    ${producer}
                  </span>
                </span>
                `
                : ""
            }
            
            ${
              sku
                ? `
          <span class="product-details__meta-delimiter">•</span>

          <span class="product-details__meta-item">
            <span class="product-details__caption product-details__caption-sku">
               SKU:
            </span>

            <span class="product-details__sku">
              ${sku}
            </span>
          </span>
                `
                : ""
            }
          </div>

          ${
            variations && variations.length
              ? `
              <div class="product-details__colors">
                <span class="product-details__colors-label">
                  ${translations["Select color:"]} 
                </span>
                <div class="product-details__colors-list">
                ${variations
                  .reduce((acc, current) => {
                    const x = acc.find(item => item.color === current.color);
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, [])
                  .map(
                    (size, index) => `
                    <span
                      class="product-details__color ${
                        index === 0 ? "product-details__color--active" : ""
                      }"
                      style="background: ${size.color};"
                      data-id="${size.id}"
                    >
                    </span>
                    `
                  )
                  .join("")}
                </div>
              </div>
    
               <div class="product-details__sizes">
                <span class="product-details__sizes-label">
                  ${translations["Select size:"]} 
                </span>
                <div class="product-details__sizes-list">
                ${variations
                  .concat(
                    variations.filter(
                      item => item.color === variations[0].color
                    )
                  )
                  .concat(
                    variations.filter(
                      item => item.color !== variations[0].color
                    )
                  )
                  .reduce((acc, current) => {
                    const x = acc.find(
                      item =>
                        item.size === current.size &&
                        item.value === current.value
                    );
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, [])
                  .sort((a, b) => (a.size > b.size ? 1 : -1))
                  .sort((a, b) =>
                    a.size > b.size.replace(/[0-9]/g, "") ? 1 : -1
                  )
                  .map(
                    (item, index) => `
                    <span
                      class="product-details__size
                      ${index === 0 ? " product-details__size--active" : ""}
                      ${
                        item.color !== variations[0].color
                          ? " product-details__size--disabled"
                          : ""
                      }"
                      data-id="${item.id}"
                    >
                      ${item.size} ${item.value ? " / " + item.value : ""}
                    </span>
                    `
                  )
                  .join("")}
                </div>
              </div>
              `
              : ""
          }

          <ul class="product-details__additional-params">
          ${
            additionalParams && additionalParams.length
              ? additionalParams
                  .map(
                    param => `
              <li class="product-details__additional-param">
                <input
                  type="checkbox"
                  id="param${param.id}"
                  name="param${param.id}"
                  value="${param.id}"
                  class="product-details__addition-param-input"
                >
                <label
                  for="param${param.id}"
                  class="product-details__addition-param-label"
                >
                  <span class="product-details__addition-param-title">
                    ${param.title}
                  </span>
                  ${
                    param.extra_cost
                      ? `
                      <span class="product-details__addition-param-price">
                        +${parseFloat(param.extra_cost).toFixed(2)} ${currency}
                      </span>
                      `
                      : ""
                  }
                  <span class="product-details__addition-param-description">
                    ${param.description}
                  </span>
                </label>
              </li>
              `
                  )
                  .join("")
              : ""
          }
          </ul>

          <div class="product-details__shipping-methods" style="visibility: hidden;">
            <span class="product-details__shipping-label">
              ${translations["Choose shipping method:"]} 
            </span>
            <select class="product-details__shipping-list">
            </select>
          </div>

          <div class="product-details__action product-details__action--basic">
            <div class="product-details__quantity">
              <button class="product-details__quantity-button product-details__quantity-button--minus">-</button>
              <input class="product-details__quantity-input" type="number" name="quantity" placeholder="N" min="1" max="999" value="1" />
              <button class="product-details__quantity-button product-details__quantity-button--plus">+</button>
            </div>
            <button
              type="button" ${
                cost &&
                currency &&
                is_active &&
                stock > 0 &&
                !!window.localStorage.getItem("token")
                  ? `class="product-details__button"`
                  : `class="product-details__button product-details__button--disabled" disabled`
              }
              data-id=${id}
            >
             ${translations["To basket"]}
            </button>
            ${
              company &&
              company.company_paypal_account &&
              cost &&
              currency &&
              is_active
                ? `
                <div class="paypal">
                  <form class="paypal__form" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                    <input type="hidden" name="cmd" value="_xclick" id="id_cmd">
                    <input type="hidden" name="charset" value="utf-8" id="id_charset">
                    <input type="hidden" name="currency_code" value="${currency}" id="id_currency_code">
                    <input type="hidden" name="no_shipping" value="0" id="id_no_shipping">
                    <input type="hidden" name="business" value="${
                      company.company_paypal_account
                    }" id="id_business">
                    <input type="hidden" name="amount" value="${calculatePrice(
                      cost,
                      discountPercent,
                      isCouponValid(coupon) ? coupon.discountPercent : 0
                    )}" id="id_amount">
                    <input type="hidden" name="notify_url" value="${
                      window.location
                    }" id="id_notify_url">
                    <input type="hidden" name="cancel_return" value="${
                      window.location
                    }" id="id_cancel_return">
                    <input type="hidden" name="item_number" value="${id}" id="id_item_number">
                    <input type="hidden" name="item_name" value="${title}" id="id_item_name">
                    <input type="hidden" name="quantity" value="1" id="id_quantity">
                    <input type="hidden" name="return" value="${
                      window.location
                    }" id="id_return">
                    <input type="image" src="https://www.paypal.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="Buy it Now">
                  </form>
                </div>
              `
                : ""
            }
            <button type="button" class="product-details__wish" data-product-id="${id}">
              <svg fill="currentColor" width="100%" height="100%" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z">
                </path>
              </svg>
            </button>
          </div>

          
          ${
            !window.localStorage.getItem("token")
              ? `
              <p class="product-details__auth-message">
                ${translations["You must login to be able to order this item"]}
              </p>
              `
              : ""
          }

          ${
            isCouponValid(coupon)
              ? `
              <div
                class="pd-coupon"
                data-data-end="${coupon.date.end}"
                data-data-start="${coupon.date.start}"
              >
                <div class="pd-coupon__header">
                  <span class="pd-coupon__cost">
                    ${(cost - (cost / 100) * coupon.discountPercent).toFixed(2)}
                  </span>
                  <span class="pd-coupon__currency">
                    ${translations[currency]}
                  </span>
                  <span class="pd-coupon__percent-discount">
                    -${coupon.discountPercent}%
                  </span>
                  <span class="pd-coupon__message">
                    ${translations["Hurry up! Coupon Ends in"]}
                  </span>
                </div>
                <div class="pd-coupon__grid" >
                  <span class="pd-coupon__item">
                    <span class="pd-coupon__counter pd-coupon__counter--days">
                      000
                    </span>
                    <span class="pd-coupon__label pd-coupon__label--days">
                      Days
                    </span>
                  </span>
                  <span class="pd-coupon__delimiter">
                    :
                  </span>
                  <span class="pd-coupon__item">
                    <span class="pd-coupon__counter pd-coupon__counter--hours">
                      00
                    </span>
                    <span class="pd-coupon__label pd-coupon__label--hours">
                      Hours
                    </span>
                  </span>
                  <span class="pd-coupon__delimiter">
                    :
                  </span>
                  <span class="pd-coupon__item">
                    <span class="pd-coupon__counter pd-coupon__counter--minutes">
                      00
                    </span>
                    <span class="pd-coupon__label pd-coupon__label--minutes">
                      Minutes
                    </span>
                  </span>
                  <span class="pd-coupon__delimiter">
                    :
                  </span>
                  <span class="pd-coupon__item">
                    <span class="pd-coupon__counter pd-coupon__counter--seconds">
                      00
                    </span>
                    <span class="pd-coupon__label pd-coupon__label--seconds">
                      Seconds
                    </span>
                  </span>
                </div>
              </div>
              `
              : ""
          }
          
          <div class="product-details__description product-details__description--short">
          ${shortDescription || translations["No short description..."]}
          </div>
          <div class="product-details__additional">
          <div class="product-details__tabs" style="display: none;">
            <span
              class="product-details__tab product-details__tab--active"
              data-tab-selector=".product-details__tab-content--about"
            >
              ${translations["About"]}
            </span>
            <span class="product-details__tab-separator">|</span>
            <span
              class="product-details__tab"
              data-tab-selector=".product-details__tab-content--files"
            >
              ${translations["Files"]}
            </span>
            <span class="product-details__tab-separator">|</span>
            <span
              class="product-details__tab"
              data-tab-selector=".product-details__tab-content--contacts"
            >
              ${translations["Contacts"]}
            </span>
          </div>

          <div class="product-details__tab-content product-details__tab-content--about">
            <div class="product-details__tab-header">
              <h1 class="product-details__tab-title">
                ${translations["Description"]}
              </h1>
            </div>
            <div class="product-details__tab-main" style="${!!isRTL &&
              "text-align: right"}">
              <div class="product-details__description">
                ${description}
              </div>
            </div>
            
            ${
              specifications
                ? `
            <div class="product-details__tab-header">
              <h2 class="product-details__tab-title">
                Specifications
              </h2>
            </div>
            <div class="product-details__tab-main" style="${!!isRTL &&
              "text-align: right"}">
              <div class="product-details__specification">
                      ${Object.keys(specifications).map(key =>
                        key && specifications[key]
                          ? `<div class="product-details__specification-row">
              <div class="product-details__specification-label">
                ${key}
              </div>
              <div class="product-details__specification-value">
                ${specifications[key]}
              </div>
            </div>`
                          : ""
                      )}
              </div>
            </div>`
                : ""
            }
            
            ${pages
              .map(
                page => `
                  <div class="product-details__tab-header">
                    <h1 class="product-details__tab-title">
                      ${page.title}
                    </h1>
                  </div>
                  <div class="product-details__tab-main">
                     <div class="product-details__description product-details__description--page">
                      ${page.content}
                    </div>
                  </div>
                `
              )
              .join("")}    
          </div>
          <div class="product-details__tab-content product-details__tab-content--files">
            <div class="product-details__tab-header">
              <h1 class="product-details__tab-title">
                ${translations["Photo gallery"]}
              </h1>
            </div>
            <div class="product-details__tab-main">
              <div class="product-gallery">
              ${images
                .map(
                  src => `
                    <img
                      class="product-gallery__image"
                      src="${src}"
                      alt="gallery image"
                      loading="lazy"
                      onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                    />
                  `
                )
                .join("")}
              </div>
            </div>
            <div class="product-details__tab-header">
              <h1 class="product-details__tab-title">
                ${translations["Video gallery"]}
              </h1>
            </div>
            <div class="product-details__tab-main">
              <div class="product-gallery">
              ${videos
                .map(
                  src => `
                    <video class="product-gallery__video" controls>
                      <source
                        src="${src}"
                        type="video/mp4"
                      >
                      ${translations["Your browser does not support the video tag."]}
                    </video>
                  `
                )
                .join("")}
              </div>
            </div>
            <div class="product-details__tab-header">
              <h1 class="product-details__tab-title">
                ${translations["Documents"]}
              </h1>
            </div>
            <div class="product-details__tab-main">
              <div class="product-gallery">
              ${documents
                .map(
                  item => `
                    <a class="product-gallery__document" href="${item.document}">
                      <div class="product-gallery__document-icon">
                        <svg version="1.1" height="100%" width="100%" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 317.001 317.001" style="enable-background:new 0 0 317.001 317.001;" xml:space="preserve">
                          <path d="M270.825,70.55L212.17,3.66C210.13,1.334,207.187,0,204.093,0H55.941C49.076,0,43.51,5.566,43.51,12.431V304.57
                          c0,6.866,5.566,12.431,12.431,12.431h205.118c6.866,0,12.432-5.566,12.432-12.432V77.633
                          C273.491,75.027,272.544,72.51,270.825,70.55z M55.941,305.073V12.432H199.94v63.601c0,3.431,2.78,6.216,6.216,6.216h54.903
                          l0.006,222.824H55.941z" />
                        </svg>
                      </div>
                      <div class="product-gallery__document-name">
                        ${item.name}
                      </div>
                    </a>
                  `
                )
                .join("")}
              </div>
            </div>
          </div>
          <div class="product-details__tab-content product-details__tab-content--contacts">
            <div class="product-details__tab-header">
              <h1 class="product-details__tab-title">
                ${translations["Contacts"]}
              </h1>
            </div>
            <div class="product-details__tab-main">
              <div class="product-contacts">
                <div class="product-contacts__item">
                <img class="product-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACrUlEQVRIibWWTUtUURjHf/PiiFqKGrhxYYzlF5gpepNWBUbQqlVR0GcI6hPUl0iCti2ELIlwk5GSuhEE6QXEjRvFFxR1HGfif3huzIz3nJs5/eEwwznP+f/ufe5zXlJjY2MkKAXcAO4A14BBoNumbAA/ga/AODAFVEN22cBYGngMPDdInPqs6UGeGvwF8Bqo+EzjdB6YAV4FYHEatDkz5vFXwJvAN6B4AlCjiuYxnAS8BXwEzp0CFkken4DbPuAQ8BbINQEWKWeeQ43ADPAGONtEWKQz5p2pBT465TdLUtEq3i2LlJW+V+3t7RSLRbq6ujg6OqoLy2QybG1tMTc3x+7ubsjmGTCatUoKln6pVGJ7e5tKJXZpOZBiEiTGsIAjvoje3l6y2SzVapXV1VVSqVRsnMZ7enpc7MHBAWtraz7LEQGv+kYLhQKtra3OUG+n30Zo1KdUb25u0tHRweTkpM/yioAXfKPpdNqZqel/SHqg/f19BwzoYrpmIz6mxgIJqaWlhf7+fldEAXWHNu+6tKmFpCwkwJyydsT0+VJ6eHjI8vIye3t7XkNlQktnYGAgKfUbGv3hfRqrOgFlqgLK5XJ1TX3lcpmVlRUXqzkBfdfoNHA9LkZGam1tbeTzeTo7O2OttEYXFxddNhKA0xp9b4fnMc3OzrrqE9S36Kmp0IWFhaSUfhDws53Ux3ab9fV196tSDxnp2wqqNw1IjKm03UFehiJVoUqXmt6ktqlP21ooAyYxKtElKmPXgkLSrH/UPHBZBR3lSSv8IbDzH2DyfGCMuhN/Cbivw6GJsJJ5LkUdjZUwAdxr0pvumNdEbWdc6U3YCT1/CpjmyqMO5gNKSsEl4Anw6wQgxWqO5v5JY61C24LqfNRu0dqJ7trZmW+46guiq/474Ivvxu0E/AaFRwE/APSLewAAAABJRU5ErkJggg==">
                  <div class="product-contacts__meta">
                    <h4 class="product-contacts__title">
                      ${translations["Fax:"]}
                    </h4>
                    <p class="product-contacts__text">
                      ${
                        company && company.metadata && company.metadata.fax
                          ? company.metadata.fax
                          : `${translations["N/A"]}`
                      }
                    </p>
                  </div>
                </div>

                <div class="product-contacts__item">
                  <img class="product-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACeUlEQVRIibWWz2vTYBjHP0lDd9HDyqALDQxrtYfBDoN16NbSk8JE8ORJUfBvENxf4P4IlYFXDwOnRbzZzg1nWQ49lHZlK/113KHNZeDiIW+065K3ydZ9IZc87/P9JHnfPM+jbG1tMUYKkAUeAStACpgWsRPgEPgJbANFwJaZaZKYCrwE1gXES3FxrQCvBfwtsAmc+Zl66RawB7yXwLyUEjl7wiMQMA/8ApZCgEa1JDxy44APgG/AzBVgrmaA78BDP2Aa+AREJwBzFRWe6VFgBPgI3JwgzNUN4R0ZBr7gans2Tks4Jx4V5z9bv0aYqzeAouGcJM+jn8lk0HU9lGu322V/f98rlAJyKrDmlzw7OxsKBhCPx2XhNRW47xc9Pj4ODTw6OpKF76nAHVlyvV7HtqXlEQDbtqnVarRaLdmyuyr/C/EF5XI5+v0+pVIJy7J8XSzLolgsYlkW2WxWBpyWFW80TWNxcZFer8fOzg7pdJq5ublza9yvsLCwEGjPNZwWI91pXdeJxWKYpkmv12N+fh6ASqVCJBIhn88TjQYqUCcaUB8HBJiammJ5eZl2u83BwQGKopBMJkkkEkFArmoasAusBs0wDAPDMMJAhrWrAl8um30JfdWAHzid+kK1aTab6LqOoiiB3GzbptPp+IUPgaKGM4NsAO9GV5imiWmawZ59vDaAM7dbbAK/J+XsobJg/GtPf4DnwOAaYAPgmWCc6/hV4ClwOkHYqfCsujdGZ5oC8ITJvOlAeBWGb3pNbQWcDl2+AqwsPAqjAb+5tApkgFdAIwSoIXIyDH3GYcmK9xnwAed0rQKPcXrnbc6P+g2cUf8zUMJn4nb1F1/SousFBVc6AAAAAElFTkSuQmCC">
                  <div class="product-contacts__meta">
                    <h4 class="product-contacts__title">
                      ${translations["Phone:"]}
                    </h4>
                    ${
                      company && company.metadata && company.metadata.phone
                        ? `
                                          <a class="product-contacts__link" href="tel:${company.metadata.phone}">
                                            ${company.metadata.phone}
                                          </a>
                                          `
                        : `<p class="product-contacts__text">${translations["N/A"]}</p>`
                    }
                  </div>
                </div>

                <div class="product-contacts__item">
                  <img class="product-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACxUlEQVRIia2WTUsbURSGn/lgVDKBBAOBIMUaEzcjCqmW1kS7asFS2lVx0dJCf0Oh/oL6I2wpdNuF0I9QuquxSlC6cKGLiChIooJxkU2iMV3MjWbS+YhOXgjkzD33febO3Dn3SEtLS3hIAjLAY2AKGAbCYqwMFIA/wDdgGWi4makuYzLwGpgXEDtFxW8KeCvg74FPwIWTqZ1uA2vABxeYnYbFnDXh0RHwAZAHJq4BateE8Jj2Aj4EfgIRH7CmIsAv4JETcAT4AmhdgDWlCc+RdqACfAaCXYQ1pQtvpRX4Cpt3JkkS8XgcwzBQVbcN7akJzB2PjPmdzdtlJRIJDMMgHo8zMzNDb2+vH+g7QJIxd5Lt1h8cHLz8r+s66XQaTbvxKx4GpmVg1imjr6/PEgcCAfr7+28KBJiVgftOo+fn55b48PCQUqnkB3hPBhJOo8fHx5Z4a2uLRsO1VHopKXNViP9ToVCwxGNjY0iS5AcYdqqlAJycnLC3t3eVHQ5jGIYfIDLmEeOozc1NTk9PL+OhoSHGx8dRFAUATdNIJpPout4Jr6zMzc09BW45ZTQaDYrFItFolJ6eHgBCoRCxWIyzszNSqRSxWIyBgQGOjo6oVqtuwL8ysOp1W7VajZWVFctKdV0nlUoRCAQuV5rJZAiFQm5WqzLw3QvYhOZyOQ4ODhxzVFVldHTUzeaHDPzGPKk9Va/XWV9fJ5/PU6lUbHP29/edpheAZRWzB1kAFjuBAhSLRUqlEpFIhGg0SjAYpFqtsru7S7nsuAcXgAtJNFEKZltwp1PoNbUB3AXqze+wDrwE7J+TP1WAF4JhOfG3gedArYuwmvDcbl5orzRZ4BndWWlFeGVbL9qVtizmCb3hA7YhPLLtA061dBuYBN4AO9cA7Yg5k7Q8xla5NSoXwEfMLjoNPME8O+NYW/0dzFb/K5DDoeNu6h+jgbD8/nS84QAAAABJRU5ErkJggg==">
                  <div class="product-contacts__meta">
                    <h4 class="product-contacts__title">
                      ${translations["E-mail:"]}
                    </h4>
                    ${
                      company && company.metadata && company.metadata.email
                        ? `
                                        <a class="product-contacts__link" href="mailto:${company.metadata.email}">
                                          ${company.metadata.email}
                                        </a>
                                        `
                        : `<p class="product-contacts__text">${translations["N/A"]}</p>`
                    }
                  </div>
                </div>

                <div class="product-contacts__item">
                  <img class="product-contacts__icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAD9klEQVRIibWWW2tidxTFf8bjJGpMjlFjEBRzaSdINITUKalTaQi0MKVlnkoeWlroZ2jpfIJ+iimFvvZB6CU0fashl5aUiOZCGxMDQiKaY8SoIRy1D+eYyeX8nbZpF/jg3mevdS77v/Y2JRIJXgIT8CbwLhADJgCnnisD+8Aq8D2QBNrdyKQuuR7gE+CZLmIEr/6LAZ/p4l8CXwMtEakRRoF14HkXMSNM6DXrOsffEnwL+BWI/gOh24jqHPGXCb4N/AS47yHWgRv4GXhHJPgQ+BZ48B+IdfBA53zYCXSaxgx8AzhElQMDAwSDQVwuFzabjXa7Tb1ep1QqkcvlOD8/F5X269xzQLMj+DGCb2Y2mwmHwwQCARRFweFwkM/nkSSJkZERms0mY2NjHB4ekslkaLcNT0UUreOf96Cds2cisVgshsvlYmVlhf39fZrNJqlUiq2tLdrtNru7u6yurjIyMsLc3Bwmk0n0pF8Aph60TjJs/enpaSwWC8lkEkVR8Hq9FItFms0ml5eXKIrC8PAwpVKJZDKJ3W4nHA6LBCeAeA/wxCgryzJ+v590Ok2r1UKSJGRZplKpIEkSkiRxdnbG0NAQkiShqiqZTIbR0VH6+/tFok9MiUQiCTy+nZmZmSEQCIgKu+Lg4IB0Om2USkrAK0YZt9tNOp3m+PgY0L7nwsICa2trVKtVAJxOJ9FolOXl5as6v9+Pz+cT3curEi+M+AasViuKotBoNACwWCwAVKvVO7GLi4ur7iyXy0xMCN3QKfLS/w0S2ojx3k40Gg08Hg+tlmb6ZrMZ0F5j58lkWQbA4XjhF263m3q9LtIrS8CfRoKlUolQKEQoFLoRj0bv+sP8/PyN/9lsViT4hwSsYdClBwcHBAIB1tfXOT09BSAWi3FycnJFODk5yeDgIBsbGwB4vV5mZ2c5PDwUCa71AD8YZSqVCkdHR0QiEcxmM6qqUi6XkWUZVVVRVRVZllEUBVVVsVgsTE1Nkc1mqdVqIsEfe4Bf0Cb1HaRSKer1OvF4HI/HQ6FQwOPxIEkSvb29OJ1OCoUCXq+XeDxOpVJhZ2dHJLYPJM2Li4sAdeB9o6vy+TxWq5VIJILD4cBms2G32/H5fNjtdmRZZnx8nFwud+WvAnwO/G7Slygz2lrwmuhqu91OMBjE7XbT19cHaJ1cLBbJ5XJXZ1OATeB1ro2nJvAR8Bva/LqDWq3G9vZ2N1IRzoEPdY0bE38P+AC4/DesAlzqnHudwG2nWQKe6nd1X5zrXEvXg0bWtoQ2oTfvIbapcyzdToi8dA94BHwKCG3DAFm95hHXXuN1dNu8W8BXaFv0Y+A94A1gnJurfhZt1f8OWEGwcXfwF986UaqqFoh4AAAAAElFTkSuQmCC">
                  <div class="product-contacts__meta">
                    <h4 class="product-contacts__title">
                      ${translations["Site:"]}
                    </h4>
                    ${
                      company && company.metadata && company.metadata.site
                        ? `
                                          <a class="product-contacts__link" href="${company.metadata.site}">
                                            ${company.metadata.site}
                                          </a>
                                          `
                        : `<p class="product-contacts__text">${translations["N/A"]}</p>`
                    }
                  </div>
                </div>
              </div>

              <div class="product-map">
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?q=${
                  company.metadata.location
                }&z=14&amp;output=embed"
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".product-details-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("product-details-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("product-details-wrapper--preloader");
      }
    }

    function render(id, type, translations, detailsPath) {
      const API_ROOT = "{[ apiRoot ]}";
      const STATIC_ROOT = "{[ staticRoot ]}";

      setLoading(true);
      fetch(`${API_ROOT}/${type}/product/${id}/`)
        .then(response => response.json())
        .then(product =>
          fetch(`${API_ROOT}/${type}/product_variation/?parent=${id}`)
            .then(response => response.json())
            .then(response => response.results)
            .then(variations => ({
              product: product,
              variations: variations
            }))
        )
        .then(object => {
          const el = document.querySelector(".product-details-wrapper");
          const product = object.product;
          const variations = object.variations;

          el.innerHTML = makeProductDescriptionHtml(
            product.id,
            product.image,
            product.name,
            product.description,
            product.short_description,
            product.cost,
            product.price_discount,
            product.discount_percent,
            product.coupon_discount_percent
              ? {
                  discountPercent: product.coupon_discount_percent,
                  date: {
                    start: product.metadata.coupon_start_date,
                    end: product.metadata.coupon_end_date
                  }
                }
              : null,
            product.currency,
            product.company,
            product.is_active,
            product.galleries
              ? product.galleries.reduce(
                  (tmp, item) =>
                    tmp.concat(
                      item.images && item.images.length
                        ? item.images.map(
                            item => `${STATIC_ROOT}/${item.image}`
                          )
                        : []
                    ),
                  []
                )
              : [],
            product.galleries
              ? product.galleries.reduce(
                  (tmp, item) =>
                    tmp.concat(
                      item.videos && item.videos.length
                        ? item.videos.map(
                            item => `${STATIC_ROOT}/${item.video}`
                          )
                        : []
                    ),
                  []
                )
              : [],
            product.documents && product.documents.length
              ? product.documents
              : [],
            product.additional_pages && product.additional_pages.length
              ? product.additional_pages
              : [],
            variations && variations.length ? variations : [],
            product.stock || 0,
            product.producer && product.producer.name,
            product.additional_parameters &&
              product.additional_parameters.length
              ? product.additional_parameters
              : [],
            detailsPath,
            translations,
            product.metadata.stock_keeping_unit || "",
            product.company.metadata.dynamic_attributes_keys || [],
            product.metadata.dynamic_attributes_values || []
          );

          // For similar products
          window.gjs_product_details = Object.assign({}, product, {
            type: type
          });

          setLoading(false);

          const shippingMethodsEl = document.querySelector(
            ".product-details__shipping-methods"
          );
          const shippingSelectEl = document.querySelector(
            ".product-details__shipping-list"
          );

          const quantityInputEl = document.querySelector(
            ".product-details__quantity-input"
          );
          const quantityPaypalEl = document.querySelector(
            ".paypal__form input[name='quantity']#id_quantity"
          );

          const buyButtonsEls = document.querySelectorAll(
            ".product-details-wrapper .product-details__button"
          );
          Array.prototype.slice.call(buyButtonsEls).map(buyButtonEl =>
            buyButtonEl.addEventListener("click", e => {
              if (e.target.disabled) return;
              const buttonEl = e.target.closest(".product-details__button");
              const id = parseInt(buttonEl.dataset.id);

              const optionEls = document.querySelectorAll(
                ".product-details-wrapper input:checked"
              );
              const options = Array.prototype.slice
                .call(optionEls)
                .map(optionEl => parseInt(optionEl.value));

              setLoading(true);
              fetch(`${API_ROOT}/payments/cart/`, {
                method: "POST",
                headers: Object.assign(
                  {
                    "Accept-Language": localStorage.getItem("language"),
                    "Content-Type": "application/json"
                  },
                  localStorage.getItem("token")
                    ? {
                        Authorization: `Token ${localStorage.getItem("token")}`
                      }
                    : {}
                ),
                body: JSON.stringify({
                  item_b2c: type === "b2c" ? id : "",
                  item_b2b: type === "b2b" ? id : "",
                  quantity: parseInt(quantityInputEl.value),
                  customer_type: "person",
                  customer_organization: "",
                  shipping_method: parseInt(shippingSelectEl.value) || "",
                  additional_parameters: options
                })
              }).then(() => {
                e.target.innerText = translations["ADDED"];
                e.target.setAttribute("disabled", "true");
                e.target.classList.add("product-details__button--disabled");
                setLoading(false);
              });
            })
          );

          document
            .querySelector(".product-details__quantity-button--minus")
            .addEventListener("click", e => {
              const value = parseInt(quantityInputEl.value);
              const quantity =
                value - 1 <= 999 && value - 1 >= 1 ? value - 1 : value;
              quantityInputEl.value = quantity;
              if (quantityPaypalEl) {
                quantityPaypalEl.value = quantity;
              }
            });

          document
            .querySelector(".product-details__quantity-button--plus")
            .addEventListener("click", e => {
              const value = parseInt(quantityInputEl.value);
              const quantity =
                value + 1 <= 999 && value + 1 >= 1 ? value + 1 : value;
              quantityInputEl.value = quantity;
              if (quantityPaypalEl) {
                quantityPaypalEl.value = quantity;
              }
            });

          quantityInputEl.addEventListener("change", e => {
            if (quantityPaypalEl) {
              quantityPaypalEl.value = parseInt(quantityInputEl.value);
            }
          });

          document
            .querySelector(
              ".product-details__wish:not(.product-details__wish--disabled)"
            )
            .addEventListener("click", e => {
              const target = e.target.closest(".product-details__wish");
              const productId = parseInt(target.dataset.productId);

              fetch(`${API_ROOT}/wish_list/wish_list/`, {
                method: "POST",
                headers: Object.assign(
                  {
                    "Accept-Language": localStorage.getItem("language"),
                    "Content-Type": "application/json"
                  },
                  localStorage.getItem("token")
                    ? {
                        Authorization: `Token ${localStorage.getItem("token")}`
                      }
                    : {}
                ),
                cors: "cors",
                credentials: "omit",
                body: JSON.stringify({
                  item_b2c: type === "b2c" ? productId : "",
                  item_b2b: type === "b2b" ? productId : ""
                })
              }).then(() =>
                target.classList.add("product-details__wish--disabled")
              );
            });

          setInterval(
            () =>
              Array.prototype.slice
                .call(document.querySelectorAll(".pd-coupon"))
                .map(couponEl => {
                  const endDate = couponEl.dataset.dataEnd;
                  if (!endDate) return false;

                  const second = 1000;
                  const minute = second * 60;
                  const hour = minute * 60;
                  const day = hour * 24;

                  const nowDateObj = new Date();
                  const endDateObj = new Date(endDate);
                  const distance = endDateObj.getTime() - nowDateObj.getTime();

                  const secondsCounterEl = couponEl.querySelector(
                    ".pd-coupon__counter--seconds"
                  );
                  const minutesCounterEl = couponEl.querySelector(
                    ".pd-coupon__counter--minutes"
                  );
                  const hoursCounterEl = couponEl.querySelector(
                    ".pd-coupon__counter--hours"
                  );
                  const daysCounterEl = couponEl.querySelector(
                    ".pd-coupon__counter--days"
                  );

                  const counters = {
                    days: Math.floor(distance / day),
                    hours: Math.floor((distance % day) / hour),
                    minutes: Math.floor((distance % hour) / minute),
                    seconds: Math.floor((distance % minute) / second)
                  };
                  // (1, 2) => "01", (52, 2) => "52"
                  const numberToStringWithZeros = (number, count) =>
                    Math.pow(10, count)
                      .toString()
                      .slice(1)
                      .concat(number)
                      .slice(-count);

                  secondsCounterEl.innerText = numberToStringWithZeros(
                    counters.seconds,
                    2
                  );
                  minutesCounterEl.innerText = numberToStringWithZeros(
                    counters.minutes,
                    2
                  );
                  hoursCounterEl.innerText = numberToStringWithZeros(
                    counters.hours,
                    2
                  );
                  daysCounterEl.innerText = numberToStringWithZeros(
                    counters.days,
                    3
                  );

                  return counters;
                }),
            980
          );

          tabs("product-details");

          if (typeof Siema === "undefined") {
            const carouselOnInit = () => {
              const primaryImage = document.querySelector(
                ".product-details__image"
              );
              const images = document.querySelectorAll(
                ".product-gallery-carousel__image"
              );

              Array.prototype.slice.call(images).map(
                image =>
                  (image.onclick = () => {
                    primaryImage.src = image.src;
                    primaryImage.setAttribute("data-zoom", image.src);
                    zoom(".product-details__image");
                  })
              );
            };

            const script = document.createElement("script");
            script.src = `//cdn.jsdelivr.net/npm/siema@1.5.1/dist/siema.min.js`;
            script.onload = () =>
              carousel(".product-gallery-carousel", carouselOnInit);

            document.body.append(script);
          }

          if (typeof Drift === "undefined") {
            const script = document.createElement("script");
            script.src = `//cdn.jsdelivr.net/npm/drift-zoom@1.4.0/dist/Drift.min.js`;
            script.onload = () => zoom(".product-details__image");

            const link = document.createElement("link");
            link.href = `//cdn.jsdelivr.net/npm/drift-zoom@1.4.0/dist/drift-basic.min.css`;
            link.rel = "stylesheet";

            document.head.append(link);
            document.body.append(script);
          }

          const changeVariation = (product, variation) => {
            const isActive =
              variation.cost &&
              variation.currency &&
              variation.is_active &&
              variation.stock > 0 &&
              !!window.localStorage.getItem("token");

            const primaryImageEl = document.querySelector(
              `.product-details__image`
            );
            primaryImageEl.setAttribute("src", variation.image);
            primaryImageEl.setAttribute("data-zoom", variation.image);
            primaryImageEl.setAttribute(
              "alt",
              `${variation.title} primary image`
            );

            const titleEl = document.querySelector(`.product-details__title`);
            titleEl.innerText = product.name || product.title;

            const stockMetaEl = document.querySelector(`
              .product-details__caption.product-details__caption--stock
            `).parentElement;
            stockMetaEl.innerHTML = `
            <span class="product-details__caption product-details__caption--stock">
                ${
                  variation.stock
                    ? translations["IN STOCK:"]
                    : translations["OUT OF STOCK"]
                }
              </span>
            </span>
            `;

            const priceWrapperEl = document
              .querySelector(`.product-details__cost`)
              .closest(".product-details__meta-item");
            priceWrapperEl.innerHTML = `
            <span class="product-details__caption product-details__caption--product">
              ${translations["PRODUCT PRICE:"]} 
            </span>
            ${
              variation.cost && variation.currency
                ? !variation.discount_percent && !variation.price_discount
                  ? `
                <span class="product-details__cost">
                  ${variation.cost}
                </span>
                <span class="product-details__currency">
                  ${translations[variation.currency]}
                </span>
                `
                  : variation.discount_percent
                  ? `
                <span class="product-details__cost product-details__cost--discount">
                  ${variation.cost}
                </span>
                <span class="product-details__cost">
                  ${calculatePrice(
                    variation.cost,
                    variation.discount_percent
                  ).toFixed(2)}
                </span>
                <span class="product-details__currency">
                  ${translations[variation.currency]}
                </span>
                <span class="product-details__discount">
                  -${variation.discount_percent.toFixed(2)}%
                </span>
                `
                  : variation.price_discount > 0
                  ? `
                  <span class="product-details__cost">
                    <div class="product-details__cost-new">${
                      variation.price_discount
                    }<span class="product-details__currency">
                    ${translations[variation.currency]}
                  </span></div>
                    <div class="product-details__cost-old" style="text-decoration: line-through">${
                      variation.cost
                    }<span class="product-details__currency">
                    ${translations[variation.currency]}
                  </span></div>
                  </span>
`
                  : ""
                : `${translations["N/A"]}`
            }
            `;

            const buttonEl = document.querySelector(".product-details__button");
            buttonEl.setAttribute("data-id", variation.id);
            if (isActive) {
              buttonEl.removeAttribute("disabled");
              buttonEl.classList.remove("product-details__button--disabled");
            } else {
              buttonEl.setAttribute("disabled", "true");
              buttonEl.classList.add("product-details__button--disabled");
            }

            const couponEl = document.querySelector(".pd-coupon");
            const basicActionEl = document.querySelector(
              ".product-details__action.product-details__action--basic"
            );

            const coupon = product.coupon_discount_percent
              ? {
                  discountPercent: product.coupon_discount_percent,
                  date: {
                    start: product.metadata.coupon_start_date,
                    end: product.metadata.coupon_end_date
                  }
                }
              : null;

            if (couponEl) {
              couponEl.remove();
            }

            basicActionEl.insertAdjacentHTML(
              "afterend",
              isCouponValid(coupon)
                ? `
                <div
                  class="pd-coupon"
                  data-data-end="${coupon.date.end}"
                  data-data-start="${coupon.date.start}"
                >
                  <div class="pd-coupon__header">
                    <span class="pd-coupon__cost">
                      ${calculatePrice(
                        variation.cost,
                        product.discount_percent,
                        coupon.discountPercent
                      )}
                    </span>
                    <span class="pd-coupon__currency">
                      ${translations[variation.currency]}
                    </span>
                    <span class="pd-coupon__percent-discount">
                      -${coupon.discountPercent}%
                    </span>
                    <span class="pd-coupon__message">
                      ${translations["Hurry up! Coupon Ends in"]}
                    </span>
                  </div>
                  <div class="pd-coupon__grid" >
                    <span class="pd-coupon__item">
                      <span class="pd-coupon__counter pd-coupon__counter--days">
                        000
                      </span>
                      <span class="pd-coupon__label pd-coupon__label--days">
                        Days
                      </span>
                    </span>
                    <span class="pd-coupon__delimiter">
                      :
                    </span>
                    <span class="pd-coupon__item">
                      <span class="pd-coupon__counter pd-coupon__counter--hours">
                        00
                      </span>
                      <span class="pd-coupon__label pd-coupon__label--hours">
                        Hours
                      </span>
                    </span>
                    <span class="pd-coupon__delimiter">
                      :
                    </span>
                    <span class="pd-coupon__item">
                      <span class="pd-coupon__counter pd-coupon__counter--minutes">
                        00
                      </span>
                      <span class="pd-coupon__label pd-coupon__label--minutes">
                        Minutes
                      </span>
                    </span>
                    <span class="pd-coupon__delimiter">
                      :
                    </span>
                    <span class="pd-coupon__item">
                      <span class="pd-coupon__counter pd-coupon__counter--seconds">
                        00
                      </span>
                      <span class="pd-coupon__label pd-coupon__label--seconds">
                        Seconds
                      </span>
                    </span>
                  </div>
                </div>
                `
                : ""
            );
          };

          const currentCulorEl = document.querySelector(
            ".product-details__color.product-details__color--active"
          );
          if (currentCulorEl) {
            const variationId = parseInt(currentCulorEl.dataset.id);
            const variation = variations.find(item => item.id === variationId);
            changeVariation(product, variation);
          }

          const sizesListEl = document.querySelector(
            ".product-details__sizes-list"
          );
          const colorsEls = document.querySelectorAll(
            ".product-details__color"
          );
          Array.prototype.slice.call(colorsEls).map((colorEl, index) => {
            colorEl.addEventListener("click", e => {
              const colorEl = e.target;
              const variationId = parseInt(colorEl.dataset.id);
              const variation = variations.find(
                item => item.id === variationId
              );

              changeVariation(product, variation);

              Array.prototype.slice
                .call(colorsEls)
                .map(colorEl =>
                  colorEl.classList.remove("product-details__color--active")
                );
              colorEl.classList.add("product-details__color--active");

              const sizes = []
                .concat(
                  variations.filter(item => item.color === variation.color)
                )
                .concat(
                  variations.filter(item => item.color !== variation.color)
                )
                .reduce((acc, current) => {
                  const x = acc.find(
                    item =>
                      item.size === current.size && item.value === current.value
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, [])
                .sort((a, b) => (a.size > b.size ? 1 : -1))
                .sort((a, b) =>
                  a.size > b.size.replace(/[0-9]/g, "") ? 1 : -1
                );

              sizesListEl.innerHTML = sizes
                .map(
                  (size, index) => `
                  <span
                    class="product-details__size
                    ${
                      size.id === variation.id
                        ? " product-details__size--active"
                        : ""
                    }
                    ${
                      size.color !== variation.color
                        ? " product-details__size--disabled"
                        : ""
                    }"
                    data-id="${size.id}"
                  >
                    ${size.size}${size.value ? " / " + size.value : ""}
                  </span>
                `
                )
                .join("");

              const sizesEls = document.querySelectorAll(
                ".product-details__size:not(.product-details__size--disabled)"
              );
              Array.prototype.slice.call(sizesEls).map(sizeEl =>
                sizeEl.addEventListener("click", e => {
                  const sizeEl = e.target;
                  changeVariation(
                    product,
                    variations.find(
                      item => item.id === parseInt(sizeEl.dataset.id)
                    )
                  );

                  Array.prototype.slice
                    .call(sizesEls)
                    .map(sizeEl =>
                      sizeEl.classList.remove("product-details__size--active")
                    );
                  sizeEl.classList.add("product-details__size--active");
                })
              );
            });

            if (index === 0) {
              colorEl.click();
            }

            return colorEl;
          });

          const sizesEls = document.querySelectorAll(
            ".product-details__size:not(.product-details__size--disabled)"
          );
          Array.prototype.slice.call(sizesEls).map(sizeEl =>
            sizeEl.addEventListener("click", e => {
              const sizeEl = e.target;

              Array.prototype.slice
                .call(sizesEls)
                .map(sizeEl =>
                  sizeEl.classList.remove("product-details__size--active")
                );
              sizeEl.classList.add("product-details__size--active");
            })
          );

          if (!localStorage.hasOwnProperty("token")) {
            return;
          }

          fetch(`${API_ROOT}/shipping/method/?company=${product.company.id}`, {
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            )
          })
            .then(response => response.json())
            .then(shippingMethods => {
              if (
                !shippingMethods ||
                !shippingMethods.hasOwnProperty("results") ||
                !shippingMethods.results.length
              ) {
                return;
              }

              shippingMethodsEl.style.visibility = "visible";
              shippingSelectEl.innerHTML = shippingMethods.results
                .map(
                  shipping => `
                  <option
                    class="product-details__shipping-method"
                    value="${shipping.id}"
                    title="${shipping.description}"
                  >
                    ${shipping.name}

                    ${
                      shipping.flat_fee
                        ? `(${shipping.flat_fee})`
                        : shipping.percentage_fee
                        ? `(${shipping.percentage_fee}%)`
                        : ""
                    }

                    ${shipping.currency ? ` ${shipping.currency} ` : ""}

                    ${
                      shipping.free_delivery_from
                        ? ` (Free delivery from: ${shipping.free_delivery_from}) `
                        : ""
                    }
                  </option>
                  `
                )
                .join("");
            });
        });
    }

    const params = new URLSearchParams(window.location.search);
    const id = params.get("gjs-product-id");
    const type = params.get("gjs-product-type");

    // if (typeof lory !== "function") {
    //   const script = document.createElement("SCRIPT");
    //   script.src = `//cdnjs.cloudflare.com/ajax/libs/lory.js/2.5.3/lory.min.js`;
    //   document.body.append(script);
    // }

    if (window.hasOwnProperty("grapesjs")) {
      // Get translation && render
      const translations = getTranslate("translations", this.attributes);
      if (translations && Object.keys(translations).length) {
        render(id, type, translations, this.dataset.detailsPage || "#");
      }
    } else {
      tabs("product-details");
    }
  };

  editor.DomComponents.addType("product-details", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "product-details-wrapper",
    model: {
      defaults: {
        name: "Product Details",
        apiRoot: api.API_ROOT,
        staticRoot: api.STATIC_ROOT,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "PRODUCT PRICE:",
              "IN STOCK:",
              "OUT OF STOCK",
              "PRODUCER:",
              "SKU:",
              "Select color:",
              "Select size:",
              "Choose shipping method:",
              "ADDED",
              "N/A",
              "To basket",
              "You must login to be able to order this item",
              "Hurry up! Coupon Ends in",
              "No short description...",
              "About",
              "Files",
              "Contacts",
              "Description",
              "Photo gallery",
              "Video gallery",
              "Your browser does not support the video tag.",
              "Documents",
              "Fax:",
              "Phone:",
              "E-mail:",
              "Site:",
              "ILS"
            ]
          },
          {
            type: "page-selector",
            label: "Company Details",
            name: "data-details-page",
            modalTitle: "Select company details page"
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
