export const CompaniesActionTypes = {
  GET_COMPANY_TYPES: {
    GET_COMPANY_TYPES_PENDING: "GET_COMPANY_TYPES_PENDING",
    GET_COMPANY_TYPES_SUCCESS: "GET_COMPANY_TYPES_SUCCESS",
    GET_COMPANY_TYPES_ERROR: "GET_COMPANY_TYPES_ERROR"
  },
  GET_COMPANY_TYPE: "GET_COMPANY_TYPE",
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_PENDING: "GET_COMPANIES_PENDING",
  GET_COMPANY_PENDING: "GET_COMPANY_PENDING",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_ERROR: "GET_COMPANY_ERROR",
  CREATE_COMPANY: "CREATE_COMPANY",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",

  GET_BY_USER: {
    GET_COMPANIES_BY_USER_ID_PENDING: "GET_COMPANIES_BY_USER_ID_PENDING",
    GET_COMPANIES_BY_USER_ID_SUCCESS: "GET_COMPANIES_BY_USER_ID_SUCCESS",
    GET_COMPANIES_BY_USER_ID_ERROR: "GET_COMPANIES_BY_USER_ID_ERROR"
  },
  PATCH: {
    IMAGE: {
      PATCH_COMPANY_IMAGE_PENDING: "PATCH_COMPANY_IMAGE_PENDING",
      PATCH_COMPANY_IMAGE_SUCCESS: "PATCH_COMPANY_IMAGE_SUCCESS",
      PATCH_COMPANY_IMAGE_ERROR: "PATCH_COMPANY_IMAGE_ERROR"
    }
  },
  POST: {
    UPLOAD_DOCUMENT_PENDING: "UPLOAD_DOCUMENT_PENDING",
    UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
    UPLOAD_DOCUMENT_ERROR: "UPLOAD_DOCUMENT_ERROR"
  },
  GET: {
    BY_ORGANIZATION: {
      GET_COMPANIES_BY_ORGANIZATION_ID_PENDING:
        "GET_COMPANIES_BY_ORGANIZATION_ID_PENDING",
      GET_COMPANIES_BY_ORGANIZATION_ID_SUCCESS:
        "GET_COMPANIES_BY_ORGANIZATION_ID_SUCCESS",
      GET_COMPANIES_BY_ORGANIZATION_ID_ERROR:
        "GET_COMPANIES_BY_ORGANIZATION_ID_ERROR"
    }
  },
  CLEAR_COMPANY_REQUEST: "CLEAR_COMPANY_REQUEST"
};
