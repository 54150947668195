import icon from "./icon.png";

export default function jobDetailsBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .job-details-wrapper {}

    .job-details-wrapper.job-details-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .job-details-wrapper.job-details-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .job-details {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      padding: 16px 20px;
    }

    @media screen and (max-width: 992px) {
      .job-details {
        flex-flow: column !important;
      }

      .job-details__image {
        width: 90%;
        margin: 0 auto;
      }

      .job-details__header {
        flex-flow: column!important;
        align-items: flex-start !important;
        margin: 0 0 16px;
      }

      .job-details__button {
        margin: 0 !important;
      }

      .job-details__action.job-details__action--basicc {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
      }

      .job-details__wish {
        margin: 20px 0 10px;
      }

      .job-details__quantity {
        flex: 100%;
        justify-content: center;
        margin: 0 0 30px;
      }

      .job-details__tab-main {
        padding: 1% 0%;
      }

      .job-contacts {
        flex-flow: column;
      }

      .job-gallery {
        flex-flow: column nowrap;
      }

      .job-gallery__video {
        width: 100%;
      }

      .job-gallery__image {
        width: 100%;
      }

      .job-gallery__document {
        width: 100%;
      }
    }

    .job-details__image {
      width: 340px;
      height: 300px;
      object-fit: scale-down;
      padding: 0 5%;
    }

    .job-details__main {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin: 0 5px 0;
      flex: 1;
    }
    
    .job-details__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .job-details__title {
      width: 90%;
      color: #262626;
      font-size: 30px;
      font-weight: 700;
      margin: 10px 0;
    }

    .job-details__divider {
      width: 114px;
      height: 7px;
      background-color: #aeaeae;  
    }

    .job-details__description {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin: 16px 0;
    }

    .job-details__description * {
      max-width: 100%;
    }

    .job-details__cost-wrapper {
      margin: 0 0 22px;
    }

    .job-details__caption {
      font-size: 18px;
      color: #4b4b4b;
    }

    .job-details__cost {
      color: #262626;
      font-size: 18px;
      font-weight: 700;
      margin: auto 0 8px;
    }

    .job-details__cost.job-details__cost--discount {
      text-decoration-color: rgb(103, 98, 98);
      text-decoration-line: line-through;
      text-decoration-style: solid;
      font-size: 15px;
    }

    .job-details__currency {
      color: rgb(38, 38, 38);
      font-weight: 700;
      font-size: 18px;
    }

    .job-details__discount {
      color: #060606;
      font-weight: 600;
      font-size: 14px;
      padding: 1px 4px;
      border: 2px solid #aeaeae;
      border-radius: 4px;
    }

    .job-details__action.job-details__action--basic {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .job-details__quantity {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
    }

    .job-details__quantity-button {
      min-width: 30px;
      background: #f6f6f6;
      color: #212121;
      font-size: 24px;
      border: 1px solid #8b8b8b;
      padding: 4px 9px;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .job-details__quantity-button:hover {
      background: #eaeaea;
    }

    .job-details__quantity-input {
      width: 82px;
      font-size: 16px;
      text-align: right;
      border: 1px solid #8b8b8b;
      border-left: 0;
      border-radius: 0;
    }

    .job-details__wish {
      background: transparent;
      color: #757575;
      height: 30px;
      width: 36px;
      border: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .job-details__wish.job-details__wish--disabled {
      color: #262626;
      cursor: not-allowed;
    }

    .job-details__wish:not(.job-details__wish--disabled):focus {
      transform: scale(1.1);
    }

    .job-details__button {
      min-width: 160px;
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      margin: 0 12px 0;
      border: 2px solid #aeaeae;
      border-radius: 0;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .job-details__button:hover {
      background-color: #f1f1f1;
      color: #aba8a8;
    }

    .job-details__button.job-details__button--disabled {
      background-color: #dadada;
      color: #848484;
      cursor: not-allowed;
    }

    .job-details__slogan {
      color: #262626;
      font-weight: 400;
      font-style: italic;
      margin: 0 0 10px;
    }

    .job-parent {
      display: flex;
      align-items: center;
    }

    .job-parent__logo {
      height: 45px;
      width: 45px;
      object-fit: contain;
    }

    .job-parent__name {
      font-weight: 600;
      margin: 3px 8px 0;
    }

    .job-details__additional {
      flex: 100%;
      width: 100%;
      padding: 20px 0 0;
    }

    .job-details__tabs {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
    }

    .job-details__tab {
      color: #262626;
      cursor: pointer;
    }

    .job-details__tab-separator {
      color: #e8e8e8;
      font-size: 26px;
      margin: -8px 0 0;
    }

    .job-details__tab--active {
      font-weight: 700;
    }

    .job-details__tab-header {
      background: #f8f8f8;
      text-align: center;
      margin: 24px 0 0;
      padding: 12px 0;
    }

    .job-details__tab-title {
      margin: 0;
    }

    .job-details__tab-main {
      padding: 1% 5%;
    }

    .job-contacts {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .job-contacts__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      margin: 0 0 20px;
    }

    .job-contacts__meta {
      padding: 0 12px 0;
    }

    .job-contacts__title {
      margin: 0;
      font-weight: 700;
    }

    .job-contacts__text {
      margin: 6px 0 0;
    }

    .job-contacts__link {
      color: initial;
      text-decoration: none;
      cursor: pointer;
    }

    .job-gallery {
      display: flex;
      flex-flow: row wrap;
    }

    .job-gallery__video {
      width: 33.33%;
      padding: 6px;
      border-radius: 13px;
    }

    .job-gallery__image {
      width: 33.33%;
      height: 300px;
      padding: 6px;
      border-radius: 3px;
      object-fit: scale-down;
    }

    .job-gallery__document {
      width: 20%;
      padding: 6px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: stretch;
      color: black;
      text-decoration: none;
    }

    .job-gallery__document-icon {
      height: 140px;
      width: 100%;
      padding: 20px;
    }

    .job-gallery__document-name {
      width: 100%;
      text-align: center;
      background: #f3f3f3;
      padding: 12px 0;
    }

    .job-staff {
      width: 100%;
    }

    .job-staff__header {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      padding: 20px 0;
    }

    .job-staff__row {
      color: #262626;
      text-align: center;
    }

    .job-staff__image {
      height: 64px;
      width: 64px;
      border-radius: 50px;
      margin: 20px 0 0;
      object-fit: cover;
    }

    .gjs-products-type-switch {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 10px;
    }
  
    .gjs-products-type-switch__product-type {
      padding: 0 4px 0;
      opacity: 0.5;
    }
  
    .gjs-products-type-switch__product-type--active {
      opacity: 1;
    }
  
    .gjs-products-type-switch__toggle {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 30px;
    }
    
    .gjs-products-type-switch__checkbox {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 10;
    }
    
    .gjs-products-type-switch__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #ccc;
      transition: .4s;
      border-radius: 34px;
    }
    
    .gjs-products-type-switch__slider:before {
      position: absolute;
      content: "";
      height: 80%;
      width: 40%;
      background-color: white;
      left: 10%;
      top: 10%;
      botton: 10%;
      border-radius: 50%;
      transition: .4s;
    }
    
    .gjs-products-type-switch__checkbox:checked + .gjs-products-type-switch__slider:before {
      transform: translateX(100%);
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="job-details-wrapper">
    <div class="job-details">
      <div class="job-details__main">
        <div class="job-details__header">
          <h4 class="job-details__title">Job Title</h4>
        </div>
        <span class="job-details__meta">
          <span class="job-details__meta-item">
            Vacancy
          </span>
          <span class="job-details__meta-delimiter">
            •
          </span>
          <span class="job-details__meta-item">
            Country, City
          </span>
          <span class="job-details__meta-delimiter">
            •
          </span>
          <span class="job-details__meta-item">
            Full-Time
          </span>
          <span class="job-details__meta-delimiter">
            •
          </span>
          <span class="job-details__meta-item">
            5/1/2014
          </span>
        </span>
      </div>
  
      <div class="job-details__additional">
        <div class="job-details__tabs">
          <span class="job-details__tab job-details__tab--active"
            data-tab-selector=".job-details__tab-content--description">
            Description
          </span>
          <span class="job-details__tab-separator">|</span>
          <span class="job-details__tab" data-tab-selector=".job-details__tab-content--requirements">
            Requirements
          </span>
          <span class="job-details__tab-separator">|</span>
          <span class="job-details__tab" data-tab-selector=".job-details__tab-content--terms">
            Terms
          </span>
        </div>
  
        <div class="job-details__tab-content job-details__tab-content--description" style="display: block;">
          <div class="job-details__tab-header">
            <h1 class="job-details__tab-title">
              Description
            </h1>
          </div>
          <div class="job-details__tab-main">
            <div class="job-details__description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nunc ac porttitor nibh, nec consequat purus. Curabitur ut dui
              ut metus porta finibus ut nec felis.
              Proin sed mi ullamcorper, rutrum augue volutpat, tempor nisi.
              Quisque eleifend erat eu nisi laoreet euismod.
              Mauris metus erat, molestie ut eleifend at, accumsan vel dui.
              Proin et diam tempus, scelerisque metus non, laoreet lacus.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas.
              Maecenas leo elit, ullamcorper eu orci id, suscipit finibus mi.
              Mauris purus lectus, fermentum vel ex sed, hendrerit aliquet tellus.
            </div>
          </div>
        </div>
  
        <div class="job-details__tab-content job-details__tab-content--requirements" style="display: none;">
          <div class="job-details__tab-header">
            <h1 class="job-details__tab-title">
              Requirements
            </h1>
          </div>
          <div class="job-details__tab-main">
            <div class="job-details__description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nunc ac porttitor nibh, nec consequat purus. Curabitur ut dui
              ut metus porta finibus ut nec felis.
              Proin sed mi ullamcorper, rutrum augue volutpat, tempor nisi.
              Quisque eleifend erat eu nisi laoreet euismod.
              Mauris metus erat, molestie ut eleifend at, accumsan vel dui.
              Proin et diam tempus, scelerisque metus non, laoreet lacus.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas.
              Maecenas leo elit, ullamcorper eu orci id, suscipit finibus mi.
              Mauris purus lectus, fermentum vel ex sed, hendrerit aliquet tellus.
            </div>
          </div>
        </div>
  
        <div class="job-details__tab-content job-details__tab-content--terms" style="display: none;">
          <div class="job-details__tab-header">
            <h1 class="job-details__tab-title">
              Terms
            </h1>
          </div>
          <div class="job-details__tab-main">
            <div class="job-details__description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nunc ac porttitor nibh, nec consequat purus. Curabitur ut dui
              ut metus porta finibus ut nec felis.
              Proin sed mi ullamcorper, rutrum augue volutpat, tempor nisi.
              Quisque eleifend erat eu nisi laoreet euismod.
              Mauris metus erat, molestie ut eleifend at, accumsan vel dui.
              Proin et diam tempus, scelerisque metus non, laoreet lacus.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas.
              Maecenas leo elit, ullamcorper eu orci id, suscipit finibus mi.
              Mauris purus lectus, fermentum vel ex sed, hendrerit aliquet tellus.
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  `;

  blockManager.add("job-details", {
    label: `
      <img src="${icon}" alt="Job description block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Job Details
      </div>
    `,
    content,
    category: "B24Online Jobs"
  });
}
