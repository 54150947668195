import { axiosNoTokenInstance } from "../../../axios/axios.config";

import { StaticPagesTypes } from "./static-pages.types";

export const getStaticPageById = staticId => dispatch => {
  dispatch({
    type: StaticPagesTypes.GET.GET_STATIC_PAGE_BY_ID_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/static_pages/static_page/${staticId}`)
    .then(staticID => {
      dispatch({
        type: StaticPagesTypes.GET.GET_STATIC_PAGE_BY_ID_SUCCESS,
        payload: staticID.data
      });
    })
    .catch(err => {
      dispatch({
        type: StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_ERROR,
        payload: err
      });
    });
};

export const getHeaderStaticPages = () => dispatch => {
  dispatch({
    type: StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/static_pages/static_page/?is_on_top=true&page_size=50`)
    .then(headerStatic => {
      dispatch({
        type: StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_SUCCESS,
        payload: {
          count: headerStatic.data.count,
          pages: headerStatic.data.results
        }
      });
    })
    .catch(err => {
      dispatch({
        type: StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_ERROR,
        payload: err
      });
    });
};

export const getFooterStaticPages = () => dispatch => {
  dispatch({
    type: StaticPagesTypes.GET.GET_FOOTER_STATIC_PAGES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/static_pages/static_page/?is_on_top=false&page_size=50`)
    .then(footerStatic => {
      dispatch({
        type: StaticPagesTypes.GET.GET_FOOTER_STATIC_PAGES_SUCCESS,
        payload: {
          count: footerStatic.data.count,
          pages: footerStatic.data.results
        }
      });
    })
    .catch(err => {
      dispatch({
        type: StaticPagesTypes.GET.GET_FOOTER_STATIC_PAGES_ERROR,
        payload: err
      });
    });
};
