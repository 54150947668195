import { ShippingActionTypes } from "./shipping.types";
import {
  axiosInstance,
  axiosNoTokenInstance
} from "../../../axios/axios.config";

export const getShippingMethodsByMe = (
  searchQuery = "",
  page = 1
) => dispatch => {
  dispatch({
    type: ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_ME_PENDING
  });

  axiosInstance.get(`/api/v1/auth/users/me/`).then(user =>
    axiosInstance
      .get(
        `/api/v1/shipping/method/?search=${searchQuery}&created_by=${user.data.id}&page=${page}`
      )
      .then(shippingMethods => {
        dispatch({
          type: ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_ME_SUCCESS,
          payload: shippingMethods.data
        });
      })
      .catch(err => {
        dispatch({
          type: ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_ME_ERROR,
          payload: err
        });
      })
  );
};

export const getShippingMethodsById = shippingId => dispatch => {
  dispatch({
    type: ShippingActionTypes.GET.GET_SHIPPING_METHOD_BY_ID_PENDING
  });
  axiosInstance
    .get(`/api/v1/shipping/method/${shippingId}/`)
    .then(shipping => {
      dispatch({
        type: ShippingActionTypes.GET.GET_SHIPPING_METHOD_BY_ID_SUCCESS,
        payload: shipping.data
      });
    })
    .catch(err => {
      dispatch({
        type: ShippingActionTypes.GET.GET_SHIPPING_METHOD_BY_ID_ERROR,
        payload: err
      });
    });
};

export const getShippingMethodsByCompanyId = companyId => dispatch => {
  dispatch({
    type: ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_COMPANY_ID_PENDING
  });

  axiosNoTokenInstance
    .get(`api/v1/shipping/method/?company=${companyId}`)
    .then(shippings => {
      dispatch({
        type:
          ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_COMPANY_ID_SUCCESS,
        payload: shippings.data
      });
    })
    .catch(err => {
      dispatch({
        type:
          ShippingActionTypes.GET.GET_SHIPPING_METHODS_BY_COMPANY_ID_SUCCESS,
        payload: err
      });
    });
};

export const createShippingMethod = (
  name,
  currency,
  free_delivery_from,
  flat_fee,
  percentage_fee,
  description,
  company
) => dispatch => {
  dispatch({
    type: ShippingActionTypes.POST.CREATE_SHIPPING_METHOD_PENDING
  });
  axiosInstance
    .post(`/api/v1/shipping/method/`, {
      name,
      currency,
      free_delivery_from,
      flat_fee,
      percentage_fee,
      description,
      company
    })
    .then(postShipping => {
      dispatch({
        type: ShippingActionTypes.POST.CREATE_SHIPPING_METHOD_SUCCESS,
        payload: {
          shippingMethod: postShipping,
          requestStatus: postShipping.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: ShippingActionTypes.POST.CREATE_SHIPPING_METHOD_ERROR,
        payload: err
      });
    });
};

export const putShippingMethod = (
  deliveryId,
  name,
  currency,
  free_delivery_from,
  flat_fee,
  percentage_fee,
  description,
  company
) => dispatch => {
  dispatch({
    type: ShippingActionTypes.PUT.PUT_SHIPPING_METHOD_PENDING
  });
  axiosInstance
    .put(`/api/v1/shipping/method/${deliveryId}/`, {
      name,
      currency,
      free_delivery_from,
      flat_fee,
      percentage_fee,
      description,
      company
    })
    .then(putShipping => {
      dispatch({
        type: ShippingActionTypes.PUT.PUT_SHIPPING_METHOD_SUCCESS,
        payload: {
          shippingMethod: putShipping,
          requestStatus: putShipping.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: ShippingActionTypes.PUT.PUT_SHIPPING_METHOD_ERROR,
        payload: err
      });
    });
};

export const deleteShippingMethodById = deliveryId => dispatch => {
  dispatch({
    type: ShippingActionTypes.DELETE.DELETE_SHIPPING_METHOD_BY_ID_PENDING
  });

  axiosInstance
    .delete(`/api/v1/shipping/method/${deliveryId}/`)
    .then(delivery => {
      dispatch({
        type: ShippingActionTypes.DELETE.DELETE_SHIPPING_METHOD_BY_ID_SUCCESS,
        payload: delivery.data
      });
      dispatch(getShippingMethodsByMe());
    })
    .catch(err => {
      dispatch({
        type: ShippingActionTypes.DELETE.DELETE_SHIPPING_METHOD_BY_ID_ERROR,
        payload: err
      });
    });
};

export const clearDeliveryRequest = () => dispatch => {
  dispatch({
    type: ShippingActionTypes.CLEAR_DELIVERY_REQUEST_STATUS,
    payload: ""
  });
};
