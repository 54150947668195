import { ProfileActionTypes } from "./profileAPI.types";
import axios from "axios";
import {
  axiosInstance,
  axiosNoTokenInstance,
  getCookie,
  baseURL
} from "@core/axios/axios.config";

export const getProfile = () => dispatch => {
  dispatch({ type: ProfileActionTypes.GET.GET_PROFILE_PENDING });
  dispatch({ type: ProfileActionTypes.GET_EMAIL.GET_PROFILE_EMAIL_PENDING });

  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(userId => {
      let customer = {
        id: userId?.data?.id || 0,
        email: userId?.data?.email || ""
      };

      axiosInstance
        .get(`/api/v1/account/profile/?user_id=${userId.data.id}`)
        .then(profile => {
          const userProfile = profile.data.results[0];
          if (userProfile) {
            if (userProfile.first_name || userProfile.last_name) {
              customer.name = userProfile.first_name
                ? userProfile.first_name
                : "";

              if (userProfile.last_name) {
                customer.name +=
                  (customer.name ? " " : "") + userProfile.last_name;
              }
            }

            if (userProfile.mobile_number) {
              customer.phone = userProfile.mobile_number;
            }

            if (userProfile.birthday) {
              customer.birthday = userProfile.birthday;
            }

            if (userProfile.sex) {
              customer.sex = userProfile.sex;
            }
          }

          if (!window.B24Chat) {
            window.B24Chat = {};
          }

          window.B24Chat.customer = customer;

          dispatch({
            type: ProfileActionTypes.GET.GET_PROFILE_SUCCESS,
            payload: userProfile
          });
        })
        .catch(error =>
          dispatch({
            type: ProfileActionTypes.GET.GET_PROFILE_ERROR,
            payload: error
          })
        );

      dispatch({
        type: ProfileActionTypes.GET_EMAIL.GET_PROFILE_EMAIL_SUCCESS,
        payload: userId.data.email
      });
    })
    .catch(error =>
      dispatch({
        type: ProfileActionTypes.GET_EMAIL.GET_PROFILE_EMAIL_ERROR,
        payload: error
      })
    );
};

export const getProfileByUserId = userId => dispatch => {
  dispatch({ type: ProfileActionTypes.GET.GET_PROFILE_BY_USER_ID_PENDING });
  axiosNoTokenInstance
    .get(`api/v1/account/profile/?user_id=${userId}`)
    .then(userById =>
      dispatch({
        type: ProfileActionTypes.GET.GET_PROFILE_BY_USER_ID_SUCCESS,
        payload: userById.data.results
      })
    )
    .catch(error =>
      dispatch({
        type: ProfileActionTypes.GET.GET_PROFILE_BY_USER_ID_ERROR,
        payload: error
      })
    );
};

export const postProfile = (
  firstName,
  lastName,
  phoneNumber,
  website,
  profession,
  country,
  birthday,
  sex,
  userType,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit,
  businessCardType,
  activeCompany,
  avatar
) => dispatch => {
  dispatch({ type: ProfileActionTypes.POST.POST_PROFILE_PENDING });
  axiosInstance
    .post(`/api/v1/account/profile/`, {
      first_name: firstName,
      last_name: lastName,
      mobile_number: phoneNumber,
      site: website,
      profession,
      country,
      birthday,
      sex,
      user_type: userType,
      metadata: {
        facebook,
        linkedin,
        twitter,
        instagram,
        youtube,
        reddit,
        business_card: businessCardType,
        active_company: activeCompany
      }
    })
    .then(profile => {
      dispatch({
        type: ProfileActionTypes.POST.POST_PROFILE_SUCCESS,
        payload: profile
      });

      if (avatar) {
        dispatch({
          type: ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_PENDING
        });
        const formData = new FormData();
        formData.append("avatar", avatar);
        axios
          .patch(
            `//${process.env.REACT_APP_API_URL}/api/v1/account/profile/${profile.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
                ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_SUCCESS,
              payload: resp.data
            })
          )
          .catch(err =>
            dispatch({
              type: ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_ERROR,
              payload: err
            })
          );
      }
    })
    .catch(error =>
      dispatch({
        type: ProfileActionTypes.POST.POST_PROFILE_ERROR,
        payload: error
      })
    );
};

export const putProfile = (
  profileId,
  firstName,
  lastName,
  phoneNumber,
  website,
  profession,
  country,
  birthday,
  sex,
  userType,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit,
  businessCardType,
  activeCompany,
  avatar
) => dispatch => {
  dispatch({ type: ProfileActionTypes.PUT.PUT_PROFILE_PENDING });
  axiosInstance
    .put(`/api/v1/account/profile/${profileId}/`, {
      first_name: firstName,
      last_name: lastName,
      mobile_number: phoneNumber,
      site: website,
      profession,
      country,
      birthday,
      sex,
      user_type: userType,
      metadata: {
        facebook,
        linkedin,
        twitter,
        instagram,
        youtube,
        reddit,
        business_card: businessCardType,
        active_company: activeCompany
      }
    })
    .then(profile => {
      if (avatar !== null) {
        dispatch({
          type: ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_PENDING
        });
        const formData = new FormData();
        formData.append("avatar", avatar);
        axios
          .patch(
            `${baseURL}/api/v1/account/profile/${profile.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
                ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_SUCCESS,
              payload: resp.data
            })
          )
          .then(() => {
            dispatch({
              type: ProfileActionTypes.PUT.PUT_PROFILE_SUCCESS,
              payload: { profile, requestStatus: profile.status }
            });
          })
          .catch(err =>
            dispatch({
              type: ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_ERROR,
              payload: err
            })
          );
      }
    })
    .catch(error =>
      dispatch({
        type: ProfileActionTypes.PUT.PUT_PROFILE_ERROR,
        payload: error
      })
    );
};

export const getProfileById = profileId => dispatch => {
  dispatch({ type: ProfileActionTypes.GET.BY_ID.GET_PROFILE_BY_ID_PENDING });
  axiosInstance
    .get(`/api/v1/account/profile/?user_id=${profileId}`)
    .then(resp => {
      dispatch({
        type: ProfileActionTypes.GET.BY_ID.GET_PROFILE_BY_ID_SUCCESS,
        payload: resp.data.results[0]
      });
    })
    .catch(error =>
      dispatch({
        type: ProfileActionTypes.GET.BY_ID.GET_PROFILE_BY_ID_ERROR,
        payload: error
      })
    );
};

export const searchUserByEmail = (search = "") => dispatch => {
  dispatch({
    type: ProfileActionTypes.GET.SEARCH_USER_BY_EMAIL_PENDING
  });
  axiosInstance
    .get(`/api/v1/account/profile/?search=${search}`)
    .then(users =>
      dispatch({
        type: ProfileActionTypes.GET.SEARCH_USER_BY_EMAIL_SUCCESS,
        payload: users.data
      })
    )
    .catch(err => {
      dispatch({
        type: ProfileActionTypes.GET.SEARCH_USER_BY_EMAIL_ERROR,
        payload: err
      });
    });
};

export const clearProfileRequestStatus = () => dispatch => {
  dispatch({
    type: ProfileActionTypes.CLEAR_REQUEST_STATUS,
    payload: ""
  });
};
