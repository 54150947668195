export const VacanciesActionTypes = {
  SET_TYPE_OF_ORGANIZATION: "SET_TYPE_OF_ORGANIZATION",
  SET_INPUT_VACANCY_DATA: "SET_INPUT_VACANCY_DATA",
  GET: {
    GET_VACANCIES_PENDING: "GET_VACANCIES_PENDING",
    GET_VACANCIES_SUCCESS: "GET_VACANCIES_SUCCESS",
    GET_VACANCIES_ERROR: "GET_VACANCIES_ERROR",

    GET_EMPLOYER_TYPE_PENDING: "GET_EMPLOYER_TYPE_PENDING",
    GET_EMPLOYER_TYPE_SUCCESS: "GET_EMPLOYER_TYPE_SUCCESS",
    GET_EMPLOYER_TYPE_ERROR: "GET_EMPLOYER_TYPE_ERROR",

    GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_PENDING:
      "GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_PENDING",
    GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_SUCCESS:
      "GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_SUCCESS",
    GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_ERROR:
      "GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_ERROR",

    GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_PENDING:
      "GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_PENDING",
    GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_SUCCESS:
      "GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_SUCCESS",
    GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_ERROR:
      "GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_ERROR",

    GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_PENDING:
      "GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_PENDING",
    GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_SUCCESS:
      "GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_SUCCESS",
    GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_ERROR:
      "GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_ERROR",

    GET_VACANCIES_BY_ME_PENDING: "GET_VACANCIES_BY_ME_PENDING",
    GET_VACANCIES_BY_ME_SUCCESS: "GET_VACANCIES_BY_ME_SUCCESS",
    GET_VACANCIES_BY_ME_ERROR: "GET_VACANCIES_BY_ME_ERROR",

    GET_VACANCY_BY_ID_PENDING: "GET_VACANCY_BY_ID_PENDING",
    GET_VACANCY_BY_ID_SUCCESS: "GET_VACANCY_BY_ID_SUCCESS",
    GET_VACANCY_BY_ID_ERROR: "GET_VACANCY_BY_ID_ERROR",

    GET_DEPARTMENTS_BY_ORGANIZATION_PENDING:
      "GET_DEPARTMENTS_BY_ORGANIZATION_PENDING",
    GET_DEPARTMENTS_BY_ORGANIZATION_SUCCESS:
      "GET_DEPARTMENTS_BY_ORGANIZATION_SUCCES",
    GET_DEPARTMENTS_BY_ORGANIZATION_ERROR:
      "GET_DEPARTMENTS_BY_ORGANIZATION_ERROR",

    GET_VACANCIES_BY_DEPARTMENT_ID_PENDING:
      "GET_VACANCIES_BY_DEPARTMENT_ID_PENDING",
    GET_VACANCIES_BY_DEPARTMENT_ID_SUCCESS:
      "GET_VACANCIES_BY_DEPARTMENT_ID_SUCCESS",
    GET_VACANCIES_BY_DEPARTMENT_ID_ERROR:
      "GET_VACANCIES_BY_DEPARTMENT_ID_ERROR",

    GET_DEPARTMENT_BY_ID_PENDING: "GET_DEPARTMENT_BY_ID_PENDING",
    GET_DEPARTMENT_BY_ID_SUCCESS: "GET_DEPARTMENT_BY_ID_SUCCESS",
    GET_DEPARTMENT_BY_ID_ERROR: "GET_DEPARTMENT_BY_ID_ERROR",

    GET_STAFF_GROUP_PENDING: "GET_STAFF_GROUP_PENDING",
    GET_STAFF_GROUP_SUCCESS: "GET_STAFF_GROUP_SUCCESS",
    GET_STAFF_GROUP_ERROR: "GET_STAFF_GROUP_ERROR",

    GET_PERMISSIONS_GROUP_PENDING: "GET_PERMISSIONS_GROUP_PENDING",
    GET_PERMISSIONS_GROUP_SUCCESS: "GET_PERMISSIONS_GROUP_SUCCESS",
    GET_PERMISSIONS_GROUP_ERROR: "GET_PERMISSIONS_GROUP_ERROR",

    GET_USER_BY_EMAIL_PENDING: "GET_USER_BY_EMAIL_PENDING",
    GET_USER_BY_EMAIL_SUCCESS: "GET_USER_BY_EMAIL_SUCCESS",
    GET_USER_BY_EMAIL_ERROR: "GET_USER_BY_EMAIL_ERROR",

    GET_DEPARTMENT_VACANCY_BY_ID_PENDING:
      "GET_DEPARTMENT_VACANCY_BY_ID_PENDING",
    GET_DEPARTMENT_VACANCY_BY_ID_SUCCESS:
      "GET_DEPARTMENT_VACANCY_BY_ID_SUCCESS",
    GET_DEPARTMENT_VACANCY_BY_ID_ERROR: "GET_DEPARTMENT_VACANCY_BY_ID_ERROR"
  },
  POST: {
    CREATE_DEPARTMENT_SUCCESS: "CREATE_DEPARTMENT_SUCCESS",
    CREATE_DEPARTMENT_ERROR: "CREATE_DEPARTMENT_ERROR",

    CREATE_DEPARTMENT_VACANCY_PENDING: "CREATE_DEPARTMENT_VACANCY_PENDING",
    CREATE_DEPARTMENT_VACANCY_SUCCESS: "CREATE_DEPARTMENT_VACANCY_SUCCESS",
    CREATE_DEPARTMENT_VACANCY_ERROR: "CREATE_DEPARTMENT_VACANCY_ERROR",

    ADD_DEPARTMENT_STAFF_PENDING: "ADD_DEPARTMENT_STAFF_PENDING",
    ADD_DEPARTMENT_STAFF_SUCCESS: "ADD_DEPARTMENT_STAFF_SUCCESS",
    ADD_DEPARTMENT_STAFF_ERROR: "ADD_DEPARTMENT_STAFF_ERROR"
  },

  PUT: {
    EDIT_DEPARTMENT_SUCCESS: "EDIT_DEPARTMENT_SUCCESS",
    EDIT_DEPARTMENT_ERROR: "EDIT_DEPARTMENT_ERROR",
    PUT_DEPARTMENT_VACANCY_PENDING: "PUT_DEPARTMENT_VACANCY_PENDING",
    PUT_DEPARTMENT_VACANCY_SUCCESS: "PUT_DEPARTMENT_VACANCY_SUCCESS",
    PUT_DEPARTMENT_VACANCY_ERROR: "PUT_DEPARTMENT_VACANCY_ERROR"
  },

  PATCH: {
    CLEAR_POSITION_VACANCY_ID_PENDING: "CLEAR_POSITION_VACANCY_ID_PENDING",
    CLEAR_POSITION_VACANCY_ID_SUCCESS: "CLEAR_POSITION_VACANCY_ID_SUCCESS",
    CLEAR_POSITION_VACANCY_ID_ERROR: "CLEAR_POSITION_VACANCY_ID_ERROR"
  },

  DELETE: {
    DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
    DELETE_DEPARTMENT_ERROR: "DELETE_DEPARTMENT_ERROR",

    DELETE_DEPARTMENT_VACANCY_SUCCESS: "DELETE_DEPARTMENT_VACANCY_SUCCESS",
    DELETE_DEPARTMENT_VACANCY_ERROR: "DELETE_DEPARTMENT_VACANCY_ERROR"
  },

  SET_CURRENT_COMPANY: "SET_CURRENT_COMPANY",
  SET_CURRENT_DEPARTMENT: "SET_CURRENT_DEPARTMENT",
  CREATE_VACANCY: "CREATE_VACANCY",
  UPDATE_VACANCY: "UPDATE_VACANCY",
  DELETE_VACANCY: "DELETE_VACANCY",

  CLEAR_DEPARTMENT_VACANCY: "CLEAR_DEPARTMENT_VACANCY",
  CLEAR_JOB_REQUEST: "CLEAR_JOB_REQUEST",
  CLEAR_DEPARTMENT_CREATE: "CLEAR_DEPARTMENT_CREATE"
};
