import { colorPalette } from "./colorPalette";

export const buttonOk = {
  color: colorPalette.colorPrimary.main,
  hover: colorPalette.colorPrimary.main,
  hoverColor: "white",
  border: `1px solid ${colorPalette.colorPrimary.main}`
};

export const buttonCancel = {
  color: colorPalette.colorPrimary.dangerRed,
  hover: colorPalette.colorPrimary.dangerRed,
  hoverColor: "white",
  border: `1px solid ${colorPalette.colorPrimary.dangerRed}`
};
