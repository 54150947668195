export default function newsWithPaginationComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makeNewsHtml(
      translations,
      id,
      src,
      title,
      description,
      organization,
      date,
      detailsPath
    ) {
      return `
      <div class="nwp-news">
        ${
          src
            ? `<img class="nwp-news__media" src="${src}" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />`
            : `<img class="nwp-news__media" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" />`
        }
        
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="${detailsPath}?gjs-news-id=${id}">
              ${title}
            </a>
          </h4>
          <div class="nwp-news__description">
          ${description ? description : translations["No description"]}
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                ${
                  organization && organization.name
                    ? organization.name
                    : translations["N/A"]
                }
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                ${
                  date
                    ? new Date(date).toLocaleDateString()
                    : translations["N/A"]
                }
              </span>
            </div>
          </div>
        </div>
      </div>
      `;
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
      <div class="nwp-pagination">
        ${
          currentPage - 1
            ? `
            <button class="nwp-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="nwp-pagination__button nwp-pagination__button--disabled" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="nwp-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${
          currentPage - 4 > 0
            ? `<span class="nwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage - 2 > 0
            ? `
            <button class="nwp-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="nwp-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="nwp-pagination__button nwp-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="nwp-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${
          currentPage + 3 <= totalPagesCount
            ? `<span class="nwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="nwp-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="nwp-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="nwp-pagination__button nwp-pagination__button--disabled" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".news-with-pagination-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("news-with-pagination-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("news-with-pagination-wrapper--preloader");
      }
    }

    function render(
      total,
      page,
      perPage,
      news,
      categories,
      loading,
      detailsPath,
      translations
    ) {
      const wrapperEl = document.querySelector(".news-with-pagination-wrapper");
      setLoading(loading);

      if (news.length) {
        const newsHtml = news.reduce(
          (tmp, news) =>
            tmp +
            makeNewsHtml(
              translations,
              news.id,
              news.image,
              news.title,
              news.short_description,
              news.organization,
              news.created_at,
              detailsPath
            ),
          ""
        );
        const paginationHtml = makePaginationHtml(page, total, perPage);

        wrapperEl.innerHTML = `
          <div class="news-with-pagination">
            ${newsHtml}
            ${paginationHtml}
          </div>  
        `;
      } else {
        wrapperEl.innerHTML = `
          <p style="margin: 0">
            <span class="news-with-pagination__empty">
            ${translations["No news..."]}
            </span>
          </p>
        `;
        return;
      }

      const paginationButtonsEls = document.querySelectorAll(
        ".nwp-pagination__button:not(.nwp-pagination__button--disabled)"
      );
      Array.prototype.slice.call(paginationButtonsEls).map(paginationButtonEl =>
        paginationButtonEl.addEventListener("click", e => {
          const btnPage = parseInt(e.target.dataset.page);
          window.news.setPage(btnPage);
        })
      );
    }

    if (!window.hasOwnProperty("news")) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window.news = {
        API_ROOT: API_ROOT,
        organization: organization,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 10,
        params: {},
        items: [],
        categories: [],
        loading: false,
        translations: {},
        detailsPath: "#",
        render() {
          console.group("news render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.categories,
              this.loading,
              this.detailsPath,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("render");
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", parseInt(page), this.getNews.bind(this));
        },
        setPerPage(count) {
          this.setProp("perPage", parseInt(count), this.getNews.bind(this));
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path, this.getNews.bind(this));
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getNews() {
          if (this.loading) return;
          this.setLoading(true);

          const params = new URLSearchParams(
            Object.assign(
              {},
              this.params,
              { page: this.page, page_size: this.perPage },
              {
                categories: this.categories
                  .filter(category => category.selected)
                  .map(category => category.id)
                  .join()
              },
              {
                company: this.organization
              }
            )
          );
          return fetch(`${this.API_ROOT}/news/news/?${params}`, {
            method: "GET"
          })
            .then(response => response.json())
            .then(response => this.setNews(response.count, response.results));
        },
        setNews(count, items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.render();
          }
          // Set count
          this.count = count;
          // Set items
          this.items = items;
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        getCategories(text) {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/news/news_categories/?search=${text}&company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(response =>
              this.setCategories(
                this.categories
                  .filter(category => category.selected)
                  .concat(
                    response.map(category => ({
                      id: category.id,
                      title: category.name,
                      selected: false
                    }))
                  )
                  .reduce(
                    (tmp, category) =>
                      tmp.find(i => i.id === category.id)
                        ? tmp
                        : tmp.concat([category]),
                    []
                  )
              )
            );
        },
        setCategories(categories) {
          // Reset page
          this.page = 1;
          // Set categories
          this.categories = categories
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => (a.selected ? -1 : 1));
          // Start render cycle
          this.setLoading(false);
          this.getNews();
        },
        search(text) {
          if (this.loading) return;

          // Reset page
          this.page = 1;
          // Set search=text param in b2c/b2b news request
          this.setParam("search", text);
          // Get news
          this.getNews();
        }
      };
    }

    if (window.hasOwnProperty("news") && window.hasOwnProperty("grapesjs")) {
      if (!window.news.renderCycle.find(func => func === render)) {
        window.news.renderCycle.push(render);
      }

      if (this.attributes.perpage) {
        window.news.setPerPage(this.attributes.perpage.value);
      }

      if (this.attributes.detailspage) {
        window.news.setDetailsPath(this.attributes.detailspage.value);
      }
      const translations = getTranslate("translations", this.attributes);
      window.news.setProp("translations", translations);

      window.news.getNews();
    }
  };

  editor.DomComponents.addType("news-with-pagination", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "news-with-pagination-wrapper",
    model: {
      defaults: {
        tagName: "div",
        name: "News With Pagination",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "number",
            label: "News per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "page-selector",
            label: "News Details",
            name: "detailspage",
            modalTitle: "Select news details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["No news...", "No description", "N/A"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
