import React from "react";

import DefaultButton from "../default-button/default-button.component";

import { ReactComponent as AddPhotoIcon } from "../../assets/add-photo-icon.svg";
import { ReactComponent as AddVideoIcon } from "../../assets/add-video-icon.svg";
import { ReactComponent as AddDocumentIcon } from "../../assets/add-document-icon.svg";

import "./event-files.styles.scss";

const EventFiles = () => {
  return (
    <div className="event-files">
      <h3>Photo gallery</h3>
      <div className="event-files__photo-container event-files__padding">
        <div className="event-files__photo-container__photos">
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
          <img src="https://via.placeholder.com/150" alt="" />
        </div>
        <DefaultButton>
          {" "}
          <AddPhotoIcon /> Add photos
        </DefaultButton>
      </div>
      <h3>Video gallery</h3>
      <div className="event-files__video-container event-files__padding">
        <div className="event-files__video-container__video">
          <img src="https://via.placeholder.com/330x180" alt="" />
          <img src="https://via.placeholder.com/330x180" alt="" />
        </div>
        <DefaultButton>
          <AddVideoIcon /> Add video
        </DefaultButton>
      </div>
      <h3>Documents</h3>
      <div className="event-files__documents-container event-files__padding">
        <div className="event-files__documents-container__documents">
          <img src="https://via.placeholder.com/150x200" alt="" />
          <img src="https://via.placeholder.com/150x200" alt="" />
          <img src="https://via.placeholder.com/150x200" alt="" />
          <img src="https://via.placeholder.com/150x200" alt="" />
        </div>
        <DefaultButton>
          <AddDocumentIcon />
          Add documents
        </DefaultButton>
      </div>
    </div>
  );
};

export default EventFiles;
