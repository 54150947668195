import { RecommendedActionTypes } from "./recommended.types";

const INITIAL_STATE = {
  isRecommendedChamberPending: false,
  recommenedChamber: [],
  recommenedChamberCount: 0,
  recommmendedChamberError: {},

  isRecommendedCompanyPending: false,
  recommenedCompany: [],
  recommenedCompanyCount: 0,
  recommmendedCompanyError: {},

  isRecommendedExhibitionPending: false,
  recommenedExhibition: [],
  recommenedExhibitionCount: 0,
  recommmendedExhibitionError: {},

  isRecommendedNewsPending: false,
  recommenedNews: [],
  recommenedNewsCount: 0,
  recommmendedNewsError: {}
};

const RecommendedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RecommendedActionTypes.GET.GET_RECOMMENDED_CHAMBER_PENDING:
      return {
        ...state,
        isRecommendedChamberPending: true
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_CHAMBER_SUCCESS:
      return {
        ...state,
        recommenedChamber: action.payload.chamber,
        recommenedChamberCount: action.payload.count,
        isRecommendedChamberPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_CHAMBER_ERROR:
      return {
        ...state,
        recommmendedChamberError: action.payload,
        isRecommendedChamberPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_COMPANY_PENDING:
      return {
        ...state,
        isRecommendedCompanyPending: true
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_COMPANY_SUCCESS:
      return {
        ...state,
        recommenedCompany: action.payload.company,
        recommenedCompanyCount: action.payload.count,
        isRecommendedCompanyPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_COMPANY_ERROR:
      return {
        ...state,
        recommmendedCompanyError: action.payload,
        isRecommendedCompanyPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_EXHIBITION_PENDING:
      return {
        ...state,
        isRecommendedExhibitionPending: true
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_EXHIBITION_SUCCESS:
      return {
        ...state,
        recommenedExhibition: action.payload.exhibition,
        recommenedExhibitionCount: action.payload.count,
        isRecommendedExhibitionPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_EXHIBITION_ERROR:
      return {
        ...state,
        recommmendedExhibitionError: action.payload,
        isRecommendedExhibitionPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_NEWS_PENDING:
      return {
        ...state,
        isRecommendedNewsPending: true
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_NEWS_SUCCESS:
      return {
        ...state,
        recommenedNews: action.payload.news,
        recommenedNewsCount: action.payload.count,
        isRecommendedNewsPending: false
      };

    case RecommendedActionTypes.GET.GET_RECOMMENDED_NEWS_ERROR:
      return {
        ...state,
        recommmendedNewsError: action.payload,
        isRecommendedNewsPending: false
      };

    default:
      return state;
  }
};

export default RecommendedReducer;
