import React from "react";

import "./default-input-time.styles.scss";

const DefaultInputTime = ({ label, changeHandler }) => {
  return (
    <div className="default-input-time">
      <label>{label}</label>
      <input type="date" onChange={changeHandler} />
      <input type="time" />
    </div>
  );
};

export default DefaultInputTime;
