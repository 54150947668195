import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { missingImage } from "../../assets";

import {
  InputGroup,
  DefaultButton,
  DefaultDropdown,
  DefaultInputRadio,
  CheckoutPaypal,
  Separator
} from "../../components";

import PelecardBtn from "../../components/pelecard/button";

import {
  getDeal,
  patchDeal,
  getCountries,
  getOneCartCompanyWithProducts,
  getCompany,
  getProfile
} from "../../redux/actions-exporter";

import { colorPalette } from "../../styles/colorPalette";
import "./checkout-email.styles.scss";

const CheckoutEmail = ({
  discount,

  getDeal,
  areDealsPending,
  deals,

  patchDeal,

  getCountries,
  countries,
  getCompany,
  getOneCartCompanyWithProducts,
  companyWithProducts,
  company,
  getProfile,
  profile,
  pelecardData
}) => {
  const { companyId } = useParams();
  const history = useHistory();
  const { company_products, company_cart_info } = companyWithProducts;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [orderMethod, setOrderMethod] = useState("email");
  const [showMessage, setShowMessage] = useState(false); // eslint-disable-line

  useEffect(() => {
    getDeal();
    getProfile();
    getCountries();
    getCompany(companyId);
  }, [getDeal, getCountries]); // eslint-disable-line

  useEffect(() => {
    getOneCartCompanyWithProducts(companyId);
  }, [companyId]); // eslint-disable-line

  useEffect(() => {
    setDisabled(
      firstName === "" ||
        lastName === "" ||
        phoneNumber === "" ||
        country === "" ||
        email === "" ||
        !email.includes("@") ||
        address === "" ||
        !agreement
    );
  }, [firstName, lastName, phoneNumber, country, email, address, agreement]);

  const patchDeals = () => {
    company_products.map(({ id, deal_order }) => {
      patchDeal(
        id,
        firstName,
        lastName,
        phoneNumber,
        country,
        email,
        address,
        deal_order && deal_order.customer_type,
        agreement
      );

      return { id, deal_order };
    });
  };

  const orderByEmailHandler = () => {
    patchDeals();
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      history.push("/cart");
    }, 3000);
  };

  return (
    <Card>
      <div className="checkout-email">
        <div className="checkout-email__company-selected">
          <div className="company-image">
            <img src={company.logo} alt="" />
          </div>
          <div className="company-name">{company.name}</div>
        </div>
        {company_products &&
          company_products.map(
            ({
              id,
              total_cost,
              created_at,
              order_items,
              currency,
              supplier_organization_info
            }) => (
              <Fragment key={id}>
                <div className="checkout-email__deal-details">
                  <div className="checkout-email__primary-title">
                    Deal from{" "}
                    {moment(created_at).format("MMMM Do YYYY, h:mm:ss a")}
                  </div>
                  <div className="checkout-email__separator" />
                  <div className="checkout-email__tertiary-title">
                    Customer:{" "}
                    <span>
                      {profile && `${profile.first_name} ${profile.last_name}`}
                    </span>
                  </div>
                  <div className="checkout-email__tertiary-title">
                    Supplier: <span>{supplier_organization_info.name}</span>
                  </div>
                  <div className="checkout-email__tertiary-title">
                    Total deal cost: <span>{total_cost}</span>
                    <span> {currency}</span>
                  </div>
                  <div className="checkout-email__separator" />
                </div>
                <div className="checkout-email__product-list">
                  <div className="checkout-email__secondary-title">
                    Product list
                  </div>
                  <Table aria-label="products table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ paddingLeft: "0", paddingRight: "0" }}
                          align="left"
                        >
                          <span className="checkout-email__color-gray">
                            PRODUCT DETAILS
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0", paddingRight: "0" }}
                          align="left"
                        >
                          <span className="checkout-email__color-gray">
                            PRODUCT SUPPLIER
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0", paddingRight: "0" }}
                          align="left"
                        >
                          <span className="checkout-email__color-gray">
                            QUANTITY
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0", paddingRight: "0" }}
                          align="left"
                        >
                          <span className="checkout-email__color-gray">
                            TOTAL
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order_items &&
                        order_items.map(
                          ({
                            id,
                            name,
                            categories,
                            image,
                            total,
                            unit_price,
                            total_discount,
                            quantity
                          }) => (
                            <TableRow key={id}>
                              <TableCell
                                style={{
                                  paddingLeft: "0",
                                  paddingRight: "0"
                                }}
                                align="left"
                              >
                                <div className="checkout-products-table__product-details">
                                  <div className="checkout-products-table__product-details__image">
                                    <img
                                      src={image ? image : missingImage}
                                      alt={name}
                                    />
                                  </div>
                                  <div className="checkout-products-table__product-details__text">
                                    <div className="checkout-products-table__product-details__text__title">
                                      {categories[0] && categories[0].name}
                                    </div>
                                    <div className="checkout-products-table__product-details__text__description">
                                      {name}
                                    </div>
                                    {discount && (
                                      <div className="checkout-products-table__product-details__text__offer">
                                        30%
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  paddingLeft: "0",
                                  paddingRight: "0"
                                }}
                                align="left"
                              >
                                <div className="checkout-products-table__price">
                                  <div>
                                    <span>
                                      {supplier_organization_info.name}
                                    </span>
                                  </div>
                                  {discount && (
                                    <div className="checkout-products-table__price__previous">
                                      $20.00
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  paddingLeft: "0",
                                  paddingRight: "0"
                                }}
                                align="left"
                              >
                                <div className="checkout-products-table__quantity">
                                  {quantity}
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  paddingLeft: "0",
                                  paddingRight: "0"
                                }}
                                align="left"
                              >
                                {total_discount &&
                                total_discount !== parseInt(total) ? (
                                  <div className="checkout-products-table__price-container">
                                    <div className="checkout-products-table__price-total checkout-products-table__price-total_new">
                                      {`${total} ${currency}`}
                                    </div>
                                    <div className="checkout-products-table__price-total checkout-products-table__price-total_old">
                                      {`${(unit_price * quantity).toFixed(
                                        2
                                      )} ${currency}`}
                                    </div>
                                    <div className="checkout-products-table__price-total checkout-products-table__price-total_discount">
                                      {`Discount: ${total_discount} ${currency}`}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="checkout-products-table__price-total">
                                    {`${(unit_price * quantity).toFixed(
                                      2
                                    )} ${currency}`}
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </div>
              </Fragment>
            )
          )}
        <div className="checkout-email__company-totals">
          <div className="totals-title">{company.name} - Checkout Totals:</div>
          {company_cart_info &&
            company_cart_info.map(({ coupon_discount, currency }, index) => (
              <div key={index} className="final-total">
                <div className="final-total__title">
                  Coupon discount: {currency}
                </div>
                <div className="final-title__value">{coupon_discount}</div>
              </div>
            ))}
          {company_cart_info &&
            company_cart_info.map(({ discount, currency }, index) => (
              <div key={index} className="final-total">
                <div className="final-total__title">Discount: {currency}</div>
                <div className="final-title__value">{discount}</div>
              </div>
            ))}
          {company_cart_info &&
            company_cart_info.map(({ shipping_cost, currency }, index) => (
              <div key={index} className="final-total">
                <div className="final-total__title">
                  Shipping cost: {currency}
                </div>
                <div className="final-title__value">{shipping_cost}</div>
              </div>
            ))}
          {company_cart_info &&
            company_cart_info.map(({ sub_total, currency }, index) => (
              <div key={index} className="final-total">
                <div className="final-total__title">Sub total: {currency}</div>
                <div className="final-title__value">{sub_total}</div>
              </div>
            ))}
          <Separator />
          {company_cart_info &&
            company_cart_info.map(({ total, currency }, index) => (
              <div key={index} className="final-total">
                <div className="final-total__title">
                  <strong>TOTAL</strong>:
                </div>
                <div className="final-title__value">
                  <strong>{`${total} ${currency}`}</strong>
                </div>
              </div>
            ))}
        </div>
        <Separator />
        <div className="checkout-email__customer-info">
          <div className="checkout-email__secondary-title">
            Deal Order Details
          </div>
          <div>Choose a method of payment</div>

          <div className="checkout-email__type-of-payment">
            <DefaultInputRadio
              id="1"
              labelText="Order by Email"
              name="email"
              onChange={e => setOrderMethod(e.target.name)}
              checked={orderMethod === "email"}
            />
            <DefaultInputRadio
              id="2"
              labelText="Paypal"
              name="paypal"
              onChange={e => setOrderMethod(e.target.name)}
              checked={orderMethod === "paypal"}
            />
            <DefaultInputRadio
              id="4"
              labelText="Credit card payment"
              name="pelecard"
              onChange={e => setOrderMethod(e.target.name)}
              checked={orderMethod === "pelecard"}
            />
          </div>
          <div className="checkout-email__separator" />
          {orderMethod === "email" && (
            <>
              <div className="checkout-email__secondary-title">
                Order by Email
              </div>
              <div>Enter customer personal info</div>
              <div className="checkout-email__flex-container">
                <div className="checkout-email__flex-item">
                  <InputGroup
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                    label="First Name"
                    name="first-name"
                    placeholder="First Name"
                  />
                </div>
                <div className="checkout-email__flex-item">
                  <InputGroup
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                    label="Last Name"
                    name="last-name"
                    placeholder="Last Name"
                  />
                </div>
                <div className="checkout-email__flex-item">
                  <InputGroup
                    onChange={e => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    label="Phone Number"
                    name="phone"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className="checkout-email__flex-container">
                <div className="checkout-email__flex-item">
                  <DefaultDropdown
                    label="COUNTRY"
                    items={countries.results}
                    returnData={setCountry}
                  />
                </div>
                <div className="checkout-email__flex-item">
                  <InputGroup
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    label="Email"
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div className="checkout-email__flex-item">
                  <InputGroup
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                    label="Address"
                    name="address"
                    placeholder="Address"
                  />
                </div>
              </div>
            </>
          )}
          {orderMethod === "paypal" &&
            company_cart_info.map(({ currency, total }, index) => (
              <CheckoutPaypal key={index} currency={currency} total={total} />
            ))}

          {orderMethod === "pelecard" && (
            <>
              <div className="checkout-email__secondary-title">
                Credit card payment
              </div>
              <div>
                <PelecardBtn company={company.id} />
              </div>
              {pelecardData?.url && (
                <iframe
                  title="Pelecard"
                  src={pelecardData.url}
                  frameBorder="0"
                  width="100%"
                  height="600px"
                />
              )}
            </>
          )}
        </div>
        {orderMethod === "email" && (
          <div className="checkout-email__confirm checkout-email__flex-container">
            <DefaultInputRadio
              readOnly={true}
              name="agreement"
              value="agreement"
              id="agreement"
              type="radio"
              checked={agreement}
              onClick={() => setAgreement(!agreement)}
              labelText={
                <label htmlFor="agreement">
                  Agree with{" "}
                  <span className="checkout-email__color-primary">
                    licence conditions
                  </span>
                </label>
              }
            />

            <div className="checkout-email__btn">
              <DefaultButton
                border={`1px solid ${colorPalette.colorPrimary.main}`}
                color={colorPalette.colorPrimary.main}
                width="100%"
                disabled={disabled}
                onClick={() => orderByEmailHandler()}
              >
                Order by email
              </DefaultButton>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = state => ({
  areDealsPending: state.cart.isDealPending,
  deals: state.cart.deal,
  countries: state.countriesReducer.countries,
  companyWithProducts: state.cart.companyWithProducts,
  company: state.companiesReducer.company,
  pelecardData: state.cart.pelecardData,
  profile: state.profileAPI.profile
});

export default connect(mapStateToProps, {
  getDeal,
  patchDeal,
  getCountries,
  getOneCartCompanyWithProducts,
  getCompany,
  getProfile
})(CheckoutEmail);
