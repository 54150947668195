import React, { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import countryTelData from "country-telephone-data";

import { missingImage, missingVideo } from "../../../assets";

import { companyType, videoIsUploaded } from "../../../constants";

import {
  MediaActions,
  GallerySection,
  VideoSection,
  EdeaIntegration,
  DynamicInput
} from "../../../components";

import {
  UploadFileDocument,
  DocumentsTable,
  AdditionalPagesUpload,
  AdditionalTable,
  DefaultButton,
  Separator,
  InputGroup,
  EditorGroup,
  DefaultDropdown,
  DefaultDropdownMultiSelect,
  DefaultSelectInput,
  SocialLinksGroup,
  DefaultTextArea,
  CircleLoader,
  DefaultCheckbox
} from "@core/components";

import {
  searchCountry,
  getCompanyTypes,
  searchBranch,
  searchOrganizations,
  createCompany,
  updateCompany,
  getCompany,
  clearCompanyRequest,
  uploadDocument,
  deleteDocumentById,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  uploadImageGallery,
  loadBlobGallery,
  loadFileGallery,
  deleteOneImageFromGallery,
  deleteOneVideoFromGallery,
  uploadVideoGallery,
  loadFileVideo,
  loadBlobVideo
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-companies-edit.styles.scss";
import {
  ColumnsSmLgWrapper,
  ColumnWrapperSm,
  ColumnWrapperLg,
  SectionTextHeader,
  ParentWrapper,
  ButtonsFooterWrapper
} from "@core/layout";
import { withDebounce } from "@core/shared";

const MyBusinessCompaniesEdit = ({
  searchCountry,
  countries,

  getCompanyTypes,
  companyTypes,

  getCompany,
  activeCompany,

  searchBranch,
  branches,

  searchOrganizations,
  searchedOrganizations,

  createCompany,
  updateCompany,
  uploadDocument,
  deleteDocumentById,

  uploadAdditionalPage,
  deleteAdditionalPageById,

  clearCompanyRequest,
  companyRequestStatus,
  uploadImageGallery,
  loadBlobGallery,
  loadFileGallery,
  galleryBlob,
  galleryFile,
  videoBlob,
  videoFile,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneImageFromGallery,
  deleteOneVideoFromGallery,

  isImageGalleryUploadPending,
  isVideoGalleryUploadPending
}) => {
  const { compId } = useParams();

  const countryCodes = countryTelData.allCountries.map(
    ({ iso2, dialCode }) => ({ value: iso2, label: "+ " + dialCode })
  );

  const history = useHistory();

  const [name, setName] = useState("");
  const [country, setCountryId] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [compTypeId, setCompTypeId] = useState("");
  const [vatin, setVATIN] = useState("");
  const [url, setURL] = useState("");
  const [dirName, setDirName] = useState("");
  const [email, setEmail] = useState("");
  const [userPaypal, setUserPaypal] = useState("");
  const [phoneCodeId, setPhoneCodeId] = useState("");
  const [phone, setPhone] = useState("");
  const [, setFullPhoneCode] = useState();
  const [faxCodeID, setFaxCodeId] = useState("");
  const [, setFullFaxCode] = useState("");
  const [fax, setFax] = useState("");
  const [address, setAddress] = useState("");
  const [slogan, setSlogan] = useState("");
  const [keywords, setKeywords] = useState("");
  const [branchInput, setBranchInput] = useState("");
  const [branch, setBranch] = useState([]);
  const [chamber, setChamber] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [desc, setDesc] = useState("");
  const [script, setScript] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [reddit, setReddit] = useState("");
  const [file, setFile] = useState("");
  const [blob, setBlob] = useState();
  const [documents, setDocuments] = useState(null);
  const [additionalPages, setAdditionalPages] = useState(null);
  const [companyGallery, setCompanyGallery] = useState([]);
  const [botToken, setBotToken] = useState("");

  const [searchOrganizationInput, setSearchOrganizationInput] = useState("");
  const [docName, setDocName] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [documentBlob, setDocumentBlob] = useState(null);

  const [additionalPageTitle, setAdditionalPageTitle] = useState("");
  const [additionalPageEditor, setAdditionalPageEditor] = useState("");

  const [disabledButton, setDisableButton] = useState(true);

  const [edeaSync, setEdeaSync] = useState(false);
  const [branchNumber, setBranchNumber] = useState("");
  const [pelecardUser, setPelecardUser] = useState("");
  const [pelecardPassword, setPelecardPassword] = useState("");
  const [pelecardTerminal, setPelecardTerminal] = useState("");
  const [POSNumber, setPOSNumber] = useState(null);
  const [uniqueIdentifier, setUniqueIdentifier] = useState("");
  const [syncInterval, setSyncInterval] = useState(""); // eslint-disable-line
  const [edeaFields, setEdeaFields] = useState([]);
  const [edeaFieldsWeb, setEdeaFieldsWeb] = useState([]);

  useEffect(() => {
    searchOrganizations(searchOrganizationInput);
  }, [searchOrganizations, searchOrganizationInput]);

  useEffect(() => {
    searchCountry(countryInput);
    searchBranch(branchInput);
    getCompanyTypes();

    if (compId !== "create") {
      getCompany(compId);
    }
  }, [
    countryInput,
    branchInput,
    searchCountry,
    getCompanyTypes,
    searchBranch,
    compId,
    getCompany
  ]);

  useEffect(() => {
    if (compId !== "create") {
      const {
        name,
        metadata,
        countries,
        parent,
        short_description,
        company_paypal_account,
        description,
        logo,
        keywords,
        director,
        address,
        slogan,
        typeofcompany,
        additional_pages,
        galleries,
        documents,
        is_edea_sync,
        edea_config,
        payments_data,
        bot_token
      } = activeCompany;
      setName(name);
      setDesc(description);
      setShortDesc(short_description);
      setKeywords(keywords);
      setDirName(director);
      setUserPaypal(company_paypal_account);
      setAddress(address);
      setSlogan(slogan);
      setFile(logo);
      setEmail(metadata && metadata.email);
      setURL(metadata && metadata.site);
      setChamber(parent && parent.id);
      setVATIN(metadata && metadata.vat_identification_number);
      setScript(metadata && metadata.script);
      setCountryId(countries && countries[0] && countries[0].id);
      setCompTypeId(typeofcompany);
      setPhone(metadata && metadata.phone);
      setFax(metadata && metadata.fax);
      setAdditionalPages(additional_pages);
      setDocuments(documents);
      setFullPhoneCode(phoneCodeId + phone);
      setFullFaxCode(faxCodeID, fax);
      setCompanyGallery(galleries);
      setEdeaSync(is_edea_sync);
      setBranchNumber(edea_config && edea_config.BranchNumber);
      setPOSNumber(edea_config && edea_config.POSNumber);
      setUniqueIdentifier(edea_config && edea_config.UniqueIdentifier);
      setPelecardUser(payments_data?.pelecard?.user);
      setPelecardPassword(payments_data?.pelecard?.password);
      setPelecardTerminal(payments_data?.pelecard?.terminal);
      setBotToken(bot_token);

      if (metadata?.dynamic_attributes_keys) {
        let dynamicAttrKeys = metadata?.dynamic_attributes_keys
          .replace(/[\][]/g, "")
          .replace(/'/g, '"');
        if (dynamicAttrKeys) {
          setEdeaFields(JSON.parse(dynamicAttrKeys));
        }
      }

      if (metadata?.dynamic_attributes_web_keys) {
        let dynamicAttrWebKeys = metadata?.dynamic_attributes_web_keys
          .replace(/[\][]/g, "")
          .replace(/'/g, '"');
        if (dynamicAttrWebKeys) {
          setEdeaFieldsWeb(JSON.parse(dynamicAttrWebKeys));
        }
      }
    }
  }, [activeCompany, compId]); // eslint-disable-line

  useEffect(() => {
    setDisableButton(
      name === "" ||
        desc === "" ||
        country === "" ||
        branch.length < 1 ||
        (edeaSync && branchNumber === "") ||
        (edeaSync && POSNumber === null) ||
        (edeaSync && uniqueIdentifier === "")
    );
  }, [
    name,
    desc,
    country,
    branch,
    edeaSync,
    branchNumber,
    POSNumber,
    uniqueIdentifier
  ]);

  useEffect(() => {
    if (companyRequestStatus === 200 || companyRequestStatus === 201) {
      history.push("/my-business/companies");
    }
  }, [companyRequestStatus]); // eslint-disable-line

  useEffect(
    () => () => {
      clearCompanyRequest();
      loadFileGallery("");
      loadBlobGallery("");
      loadBlobVideo("");
      loadFileVideo("");
    },
    [] // eslint-disable-line
  );

  const editCompany = () => {
    if (compId === "create") {
      createCompany(
        name,
        [country],
        compTypeId,
        vatin,
        url,
        userPaypal,
        dirName,
        email,
        phone,
        fax,
        address,
        slogan,
        keywords,
        branch,
        chamber,
        shortDesc,
        desc,
        "<script>" + script + "</script>",
        facebook,
        linkedIn,
        twitter,
        instagram,
        youtube,
        reddit,
        blob,
        edeaSync,
        branchNumber,
        POSNumber,
        uniqueIdentifier,
        [edeaFields],
        [edeaFieldsWeb],
        pelecardUser,
        pelecardPassword,
        pelecardTerminal,
        botToken
      );
    } else {
      updateCompany(
        compId,
        name,
        [country],
        compTypeId,
        vatin,
        url,
        userPaypal,
        dirName,
        email,
        phone,
        fax,
        address,
        slogan,
        keywords,
        branch,
        chamber,
        shortDesc,
        desc,
        script,
        facebook,
        linkedIn,
        twitter,
        instagram,
        youtube,
        reddit,
        blob,
        edeaSync,
        branchNumber,
        POSNumber,
        uniqueIdentifier,
        [edeaFields],
        [edeaFieldsWeb],
        pelecardUser,
        pelecardPassword,
        pelecardTerminal,
        botToken
      );
    }
  };

  const searchOrganizationsHandler = withDebounce(e =>
    setSearchOrganizationInput(e)
  );

  return (
    <div className="my-business-companies-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={file ? file : missingImage}
            alt={"company-picture"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton {...buttonOk} width="100%">
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                {...buttonCancel}
                width="100%"
                onClick={() => {
                  setFile("");
                  setBlob("");
                }}
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {compId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadImageGallery(companyType, compId, galleryBlob)
                          }
                          disabled={galleryBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
              <Separator />
              <MediaActions
                image={videoFile ? videoIsUploaded : missingVideo}
                alt={"missing image"}
                height={200}
                buttons={
                  isVideoGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="video/*"
                              type="file"
                              onChange={e => {
                                loadFileVideo(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobVideo(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadVideoGallery(companyType, compId, videoBlob)
                          }
                          disabled={videoBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobVideo("");
                            loadFileVideo("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setName(e.target.value)}
              value={name}
              label="NAME"
              name="name"
              placeholder="Name"
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="COUNTRY"
              items={countries.results}
              returnData={setCountryId}
              inputFunc={e => setCountryInput(e.target.value)}
              filter={true}
              customTitle={
                compId !== "create" &&
                activeCompany.countries &&
                activeCompany.countries[0] &&
                activeCompany.countries[0].name
              }
              required={true}
            />
            <DefaultDropdown
              label="COMPANY TYPE"
              items={companyTypes}
              returnData={setCompTypeId}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setVATIN(e.target.value)}
              value={vatin}
              label="VATIN"
              name="vatin"
              placeholder="VATIN"
            />
            <InputGroup
              onChange={e => setURL(e.target.value)}
              value={url}
              label="URL"
              name="url"
              placeholder="URL"
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setUserPaypal(e.target.value)}
              value={userPaypal}
              label="PAYPAL ACCOUNT"
              name="paypal"
              placeholder="Paypal Account"
            />
          </ParentWrapper>
          <ParentWrapper>
            <SectionTextHeader text="Contact Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setDirName(e.target.value)}
              value={dirName}
              label="DIRECTOR NAME"
              name="director-name"
              placeholder="Director Name"
            />
            <InputGroup
              onChange={e => setEmail(e.target.value)}
              value={email}
              label="EMAIL"
              name="email"
              placeholder="Email"
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultSelectInput
              value={phone}
              onChange={e => setPhone(e.target.value)}
              title="Telephone Number"
              options={countryCodes}
              width="100px"
              returnData={setPhoneCodeId}
            />
            <DefaultSelectInput
              value={fax}
              onChange={e => setFax(e.target.value)}
              title="Fax Number"
              options={countryCodes}
              width="100px"
              returnData={setFaxCodeId}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setAddress(e.target.value)}
              value={address}
              label="ADDRESS"
              name="address"
              placeholder="Address"
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>

      {companyGallery &&
        companyGallery[0] &&
        !!companyGallery[0].images.length && (
          <>
            <Separator />
            <GallerySection
              label="Gallery"
              gallery={companyGallery}
              deleteOneImageFromGallery={deleteOneImageFromGallery}
              parentId={compId}
              contentType={companyType}
            />
          </>
        )}
      {companyGallery &&
        companyGallery[0] &&
        !!companyGallery[0].videos.length && (
          <>
            <Separator />
            <VideoSection
              label="Videos"
              videos={companyGallery}
              deleteOneVideoFromGallery={deleteOneVideoFromGallery}
              parentId={compId}
              contentType={companyType}
            />
          </>
        )}

      <Separator />
      <ParentWrapper>
        <InputGroup
          onChange={e => setSlogan(e.target.value)}
          value={slogan}
          label="SLOGAN"
          name="slogan"
          placeholder="Slogan"
        />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => setKeywords(e.target.value)}
          value={keywords}
          label="KEYWORDS"
          name="keywords"
          placeholder="#hashtag, #hashtag, ..., #hashtag"
        />
      </ParentWrapper>
      <div style={{ marginTop: 18 }} className="my-business-category-picker">
        <div style={{ display: "flex" }}>
          <div className="my-business-category-picker__title">Branch</div>
          <div className="my-business-category-picker__required">
            * required
          </div>
        </div>
        <br />
        <div className="my-business-category-picker__flex-container">
          <DefaultDropdownMultiSelect
            style={{ width: "100%" }}
            items={branches.results}
            returnData={setBranch}
            customArray={compId !== "create" && activeCompany.branches}
            filter={true}
            categoryInputValue={branchInput}
            setCategoryInputValue={setBranchInput}
          />
        </div>
      </div>
      <ParentWrapper>
        <DefaultDropdown
          filter={true}
          label="CHAMBER OF COMMERCE"
          items={searchedOrganizations}
          returnData={setChamber}
          inputFunc={e => searchOrganizationsHandler(e.target.value)}
          customTitle={
            compId !== "create" &&
            activeCompany.parent &&
            activeCompany.parent.name
          }
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          onChange={e => setShortDesc(e.target.getContent())}
          value={shortDesc}
          label="PREVIEW DESCRIPTION"
          name="previewDescription"
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          onChange={e => setDesc(e.target.getContent())}
          value={desc}
          label="DETAIL TEXT"
          name="detailText"
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultTextArea
          onChange={e => setScript(e.target.value)}
          value={script}
          label="Custom script"
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <InputGroup
          onChange={e => setBotToken(e.target.value)}
          value={botToken}
          label="Bot token"
          name="bot-token"
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <SectionTextHeader text="Edea Integration" />
      </ParentWrapper>
      {compId !== "create" && (
        <>
          <ParentWrapper>
            <div className="my-business-category-picker__input">
              <DefaultCheckbox
                title="Edea Sync"
                checkboxValue={edeaSync}
                setCheckboxChange={setEdeaSync}
              />
            </div>
          </ParentWrapper>
          {edeaSync && (
            <>
              <ParentWrapper>
                <DynamicInput
                  title="ADDITIONAL FIELDS"
                  values={edeaFields}
                  onChange={fields => setEdeaFields(fields)}
                />
              </ParentWrapper>
              <ParentWrapper>
                <DynamicInput
                  title="ADDITIONAL FIELDS FOR WEB"
                  values={edeaFieldsWeb}
                  onChange={fields => setEdeaFieldsWeb(fields)}
                />
              </ParentWrapper>
              <EdeaIntegration companyId={compId} isSync={edeaSync} />
            </>
          )}
          <Separator />
        </>
      )}
      <ParentWrapper>
        <SectionTextHeader text="Payment Pelecard" />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => setPelecardUser(e.target.value)}
          value={pelecardUser}
          label="PELECARD USER"
          name="pelecardUser"
        />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => setPelecardPassword(e.target.value)}
          value={pelecardPassword}
          type="password"
          label="PELECARD PASSWORD"
          name="pelecardPassword"
        />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => setPelecardTerminal(e.target.value)}
          value={pelecardTerminal}
          label="PELECARD TERMINAL"
          name="pelecardTerminal"
        />
      </ParentWrapper>
      <Separator />
      {compId !== "create" && (
        <Fragment>
          <DocumentsTable
            tableName={"COMPANY DOCUMENTS"}
            documents={documents}
            deleteDocumentById={deleteDocumentById}
          />
          <Separator />
          <UploadFileDocument
            docName={docName}
            setDocName={setDocName}
            docDescription={docDescription}
            setDocDescription={setDocDescription}
            documentFile={documentFile}
            setDocumentFile={setDocumentFile}
            documentBlob={documentBlob}
            setDocumentBlob={setDocumentBlob}
            uploadDocument={uploadDocument}
            itemContentType={companyType}
            itemId={compId}
          />
          <Separator />
          <AdditionalTable
            tableName={"COMPANY ADDITIONAL PAGES"}
            compId={compId}
            urlType="companies"
            additionalPages={additionalPages}
            deleteAdditional={deleteAdditionalPageById}
          />
          <Separator />
          <AdditionalPagesUpload
            setAdditionalPageTitle={setAdditionalPageTitle}
            additionalPageTitle={additionalPageTitle}
            setAdditionalPageEditor={setAdditionalPageEditor}
            additionalPageEditor={additionalPageEditor}
            uploadAdditionalPage={uploadAdditionalPage}
            itemContentType={companyType}
            itemId={compId}
          />
          <Separator />
        </Fragment>
      )}
      <ParentWrapper>
        <SocialLinksGroup
          handleFacebookChange={e => setFacebook(e.target.value)}
          facebook={facebook}
          handleLinkedinChange={e => setLinkedIn(e.target.value)}
          linkedin={linkedIn}
          handleTwitterChange={e => setTwitter(e.target.value)}
          twitter={twitter}
          handleInstagramChange={e => setInstagram(e.target.value)}
          instagram={instagram}
          handleYoutubeChange={e => setYoutube(e.target.value)}
          youtube={youtube}
          handleRedditChange={e => setReddit(e.target.value)}
          reddit={reddit}
        />
      </ParentWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            width="100%"
            {...buttonOk}
            onClick={editCompany}
            disabled={disabledButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.push("/my-business/companies")}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  countries: state.countriesReducer.countries,
  companyTypes: state.companiesReducer.companyTypes,

  branches: state.branchesReducer.branches,

  areUserOrganizationsPending:
    state.organizationsReducer.areUserOrganizationsPending,
  searchedOrganizations: state.organizationsReducer.searchedOrganizations,

  activeCompany: state.companiesReducer.company,
  companyRequestStatus: state.companiesReducer.companyRequestStatus,

  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,

  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,

  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  isVideoGalleryUploadPending:
    state.documentsReducer.isVideoGalleryUploadPending
});

export default connect(mapStateToProps, {
  searchCountry,
  getCompanyTypes,
  searchBranch,
  searchOrganizations,
  createCompany,
  updateCompany,
  getCompany,
  uploadDocument,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  deleteDocumentById,
  uploadImageGallery,
  uploadVideoGallery,
  loadBlobGallery,
  loadFileGallery,
  clearCompanyRequest,
  loadBlobVideo,
  loadFileVideo,
  deleteOneImageFromGallery,
  deleteOneVideoFromGallery
})(MyBusinessCompaniesEdit);
