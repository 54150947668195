export default function gjsContainerRTL(editor) {
  const containerEl = editor.getComponents().parent;

  containerEl.addTrait({
    type: "checkbox",
    label: "RTL",
    name: "dir",
    valueTrue: "rtl",
    valueFalse: "ltr"
  });

  const getDirectionStyles = isRTL =>
    isRTL
      ? { "text-align": "right", direction: "rtl" }
      : { "text-align": "initial", direction: "ltr" };

  editor.on("component:update", component => {
    if (
      component.changed &&
      component.changed.attributes &&
      component.changed.attributes.dir
    ) {
      const isRTL = component.changed.attributes.dir === "rtl";
      component.setStyle(
        Object.assign({}, component.getStyle(), getDirectionStyles(isRTL))
      );
    }
  });

  editor.on("load", () => {
    const isRTL = containerEl.getStyle("direction").direction === "rtl";

    containerEl.setStyle(
      Object.assign({}, containerEl.getStyle(), getDirectionStyles(isRTL))
    );
    containerEl.getTrait("dir").setTargetValue(isRTL ? "rtl" : "ltr");
  });
}
