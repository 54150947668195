export const WishListTypes = {
  GET: {
    GET_WISH_LIST_PENDING: "GET_WISH_LIST_PENDING",
    GET_WISH_LIST_SUCCESS: "GET_WISH_LIST_SUCCESS",
    GET_WISH_LIST_ERROR: "GET_WISH_LIST_ERROR"
  },
  POST: {
    ADD_PRODUCT_TO_WISH_LIST_PENDING: "ADD_PRODUCT_TO_WISH_LIST_PENDING",
    ADD_PRODUCT_TO_WISH_LIST_SUCCESS: "ADD_PRODUCT_TO_WISH_LIST_SUCCESS",
    ADD_PRODUCT_TO_WISH_LIST_ERROR: "ADD_PRODUCT_TO_WISH_LIST_ERROR"
  },
  DELETE: {
    DELETE_PRODUCT_FROM_WISH_LIST_PENDING:
      "DELETE_PRODUCT_FROM_WISH_LIST_PENDING:",
    DELETE_PRODUCT_FROM_WISH_LIST_SUCCESS:
      "DELETE_PRODUCT_FROM_WISH_LIST_SUCCESS:",
    DELETE_PRODUCT_FROM_WISH_LIST_ERROR: "DELETE_PRODUCT_FROM_WISH_LIST_ERROR:"
  }
};
