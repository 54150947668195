import style from "./block-style";

const productCarouselContent = `
${style}
<div class="product-carousel-wrapper">
  <div class="product-carousel">
    <div class="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-autoheight">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 850.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 600.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 890.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 115.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 80.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 455.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 75.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 150.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 235.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 340.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 25.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 600.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 80.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 240.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 1345.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 565.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 250.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 550.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 3000.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 10.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 10.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 95.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2500.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 800.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 5500.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 75.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 7500.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 35.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 15.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 985.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 990.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 1759.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 125.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 73.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 435.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 350.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 50.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__no-price"> N/A </span> </p> <a
                  class="pc-product__link" href="#"> <button class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__no-price"> N/A </span> </p> <a
                  class="pc-product__link" href="#"> <button class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__no-price"> N/A </span> </p> <a
                  class="pc-product__link" href="#"> <button class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2.50 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 35.00 </span> <span
                    class="pc-product__currency"> UAH </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 28.00 </span> <span
                    class="pc-product__currency"> UAH </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 30.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 100.00 </span> <span
                    class="pc-product__currency"> UAH </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__no-price"> N/A </span> </p> <a
                  class="pc-product__link" href="#"> <button class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 340.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 260.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide swiper-slide-prev">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 220.00 </span> <span
                    class="pc-product__currency"> USD </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 236.00 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 39.90 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2.00 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2.00 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2.20 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide swiper-slide-active">
          <div class="product-carousel__grid">
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2.50 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 2.00 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 0.75 </span> <span
                    class="pc-product__currency"> ILS </span> <span class="pc-product__percent-discount"> -50.00% </span>
                </p> <a class="pc-product__link" href="#"> <button class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 5.00 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 4.50 </span> <span
                    class="pc-product__currency"> ILS </span> </p> <a class="pc-product__link" href="#"> <button
                    class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
            <div class="pc-product"> <img class="pc-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
              <div class="pc-product__main">
                <h4 class="pc-product__title"> <a class="pc-product__link" href="#">Lorem ipsum dolor sit amet</a> </h4>
                <p class="pc-product__meta pc-product__meta--cost"> <span class="pc-product__cost"> 115.00 </span> <span
                    class="pc-product__currency"> ILS </span> <span class="pc-product__percent-discount"> -50.00% </span>
                </p> <a class="pc-product__link" href="#"> <button class="pc-product__button"> VIEW </button> </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 5"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 6"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 7"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 8"></span><span
          class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 9"></span><span
          class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button"
          aria-label="Go to slide 10"></span></div>
      <div class="swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="false"></div>
      <div class="swiper-button-next swiper-button-disabled" tabindex="0" role="button" aria-label="Next slide"
        aria-disabled="true"></div><span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
    </div>
  </div>
</div>
`;

export default productCarouselContent;
