import React from "react";
import parse from "html-react-parser";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import {
  Language as LanguageIcon,
  Clear as ClearIcon
} from "@material-ui/icons";

import { DefaultButton } from "../../components";

import "./additional-table.styles.scss";

const AdditionalTable = ({
  tableName,
  additionalPages,
  urlType,
  deleteAdditional,
  compId = "",
  companyId = ""
}) => {
  return (
    <div className="my-business__additional-table">
      <h3 style={{ margin: 0 }}>{tableName} </h3>
      {additionalPages && additionalPages.length ? (
        <Table aria-label="items table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Content</TableCell>
              <TableCell align="center">Visualize</TableCell>
              {/* <TableCell align="center">Edit</TableCell> */}
              <TableCell align="center">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {additionalPages &&
              additionalPages.map(page => (
                <TableRow key={page.id}>
                  <TableCell align="center">
                    <div className="table-div-content">
                      {page.title.length > 40
                        ? page.title.substring(0, 40) + "..."
                        : page.title}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="table-div-content">
                      {page.content.length > 40
                        ? parse(page.content.substring(0, 40)) + "..."
                        : parse(page.content)}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {urlType === "events" && (
                      <a
                        href={`/${urlType}/event/${compId}/company/${companyId}/additional-${page.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LanguageIcon />
                      </a>
                    )}
                    {(urlType === "companies" ||
                      urlType === "organizations") && (
                      <a
                        href={`/${urlType}/${compId}/additional-${page.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LanguageIcon />
                      </a>
                    )}
                    {urlType === "b2b" && (
                      <a
                        href={`/product/${urlType}/${compId}/additional-${page.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LanguageIcon />
                      </a>
                    )}
                    {urlType === "b2c" && (
                      <a
                        href={`/product/${urlType}/${compId}/additional-${page.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LanguageIcon />
                      </a>
                    )}
                  </TableCell>
                  {/* <TableCell align="center">
                  <Link to="/">
                    <DefaultIconButton>
                      <EditIcon />
                    </DefaultIconButton>
                  </Link>
                </TableCell> */}
                  <TableCell align="center">
                    <DefaultButton onClick={() => deleteAdditional(page)}>
                      <ClearIcon />
                    </DefaultButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <h3 style={{ fontWeight: 500 }}>No additional pages...</h3>
      )}
    </div>
  );
};
export default AdditionalTable;
