import icon from "./icon.png";

export default function popupBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(23, 22, 22, 0.24);
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    transition: all 300ms linear;
  }

  .popup.popup--active {
    visibility: visible;
    opacity: 1;
  }

  .popup__content {
    width: 50%;
    height: 40%;
    background: #fff;
    border-radius: 2px;
    z-index: 1000;
  }
  </style>
  `;

  const content = `
  ${style}
  <div data-gjs-type="popup" draggable="true" data-highlightable="1" class="popup">
    <div class="popup__content">
      <h1 style="text-align: center;">Pop-up content</h1>
      <button class="popup__close-button" type="button">Close</button>
    </div>
  </div>
  `;

  blockManager.add("popup", {
    label: `
      <img src="${icon}" alt="popup block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Pop-up
      </div>
    `,
    content,
    category: "B24Online Extra"
  });

  blockManager.add("popup--close-button", {
    label: `
      <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
        <rect class="gjs-block-svg-path" x="4" y="11.5" width="16" height="1"></rect>
      </svg>
      <div class="gjs-block-label" style="margin-top: 5px">
        Pop-up close button
      </div>
    `,
    content,
    category: "B24Online Extra"
  });
}
