import content from "./block-content";
import icon from "./icon.png";

export default function productWithPaginationBlock({ editor }) {
  const blockManager = editor.BlockManager;

  blockManager.add("products-with-pagination", {
    label: `
      <img src="${icon}" alt="Products with pagination block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products With Pagination
      </div>
    `,
    content: content(),
    category: "B24Online Products"
  });
}
