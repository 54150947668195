import icon from "./icon.png";

export default function profileEditBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .${className} {
      display: flex;
      flex-flow: row;
    }

    .${className}.${className}--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .${className}.${className}--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .${className}__media {
      display: flex;
      flex-flow: column;
      align-items: center;
      flex-basis: 19%;
      flex-grow: 1;
      padding: 0 24px;
      margin: 14px 20px;
      border-right: 1px solid #dddddd;
    }

    .${className}__avatar {
      width: 8em;
      height: 8em;
      border-radius: 150px;
      border: 1px solid gray;
      padding: 0.2em;
      object-fit: cover;
    }

    .${className}__name {
      color: #fd8700;
      font-size: 1.24em;
      font-weight: 600;
      margin: 10px 0 5px;
    }

    .${className}__profession {
      font-size: 1.34em;
      font-weight: 600;
      margin: 0px 0 10px;
    }

    .${className}__contact-link {
      color: #fd8700;
      margin: 10px 0;
    }

    .${className}__button {
      min-width: 200px;
      color: #fff;
      font-size: 0.96em;
      padding: 8px 10px;
      margin: 10px 0 0;
      border-radius: 2px;
      transition: all .2s ease;
      cursor: pointer;
    }

    .${className}__button--filled {
      background: #aeaeae;
      border: 1px solid #aeaeae;
    }

    .${className}__button--filled:hover {
      background: #9a9a9a;
    }

    .${className}__button--bordered {
      background: transparent;
      color: #9a9a9a;
      border: 1px solid #aeaeae;
    }

    .${className}__button--bordered:hover {
      background: #aeaeae;
      color: #fff;
    }

    .${className}__button--upload {
      position: relative;
    }

    .${className}__button--spacing {
      margin: 8px;
    }

    .${className}__upload-avatar-file {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 999;
      cursor: pointer;
    }

    .${className}__content {
      display: flex;
      flex-flow: column;
      flex-basis: 80%;
      flex-grow: 1;
    }

    .${className}__title {
      margin: 13px 5px 10px;
      font-size: 1.3em;
    }

    .${className}__grid {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
    }

    .${className}__grid--center {
      justify-content: center;
    }

    .${className}__row {
      display: flex;
    }

    .${className}__row--25 {
      flex-basis: 25%;
    }

    .${className}__row--33 {
      flex-basis: 33.33%;
    }

    .${className}__row--40 {
      flex-basis: 40%;
    }

    .${className}__row--50 {
      flex-basis: 50%;
    }

    .${className}__row--60 {
      flex-basis: 60%;
    }

    .${className}__row--66 {
      flex-basis: 66.66%;
    }

    .${className}__row--75 {
      flex-basis: 75%;
    }

    .${className}__row--100 {
      flex-basis: 100%;
    }

    .${className}__group {
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 10px 5px;
    }

    .${className}__input {
      padding: 10px 9px;
      border-radius: 2px;
      border: 1px solid #dfdfdf;
      line-height: initial;
    }

    .${className}__input--date {
      font-size: 0.9em;
      padding: 9px 9px;
    }

    .${className}__select {
      width: 100%;
      background: #fff;
      padding: 9px 9px;
      border-radius: 2px;
      border: 1px solid #dfdfdf;
    }

    .${className}__select-option {
      background: #fff;
    }

    .${className}__label {
      margin: 0 0 10px;
    }

    .${className}__label--radio {
      font-size: 0.9em;
      margin: 0 14px 8px;
    }

    .${className}__fieldset {
      display: flex;
      flex-flow: column;
      width: 100%;
      border-radius: 2px;
      border: 1px solid #dfdfdf;
      margin: 10px 5px;
    }

    .${className}__legend {
      width: auto;
      font-size: initial;
      margin: 0 10px 10px;
    }

    .${className}__delimiter {
      width: 100%;
      height: 1px;
      background: #dddddd;
    }

    .${className}__delimiter--small {
      width: 200px;
      margin: 14px 0 0;
    }

    @media screen and (max-width: 992px) {

      .${className} {
        flex-flow: column;
      }

      .${className}__media {
        border: none;
      }

      .${className}__row {
        display: flex;
        flex-flow: column;
        flex-basis: 100%;
      }

      .${className}__fieldset {
        width: auto;
        margin: 5px 0;
      }

    }
  </style>
  `;

  const content = `
  <div class="${className}">
    ${style}
    <div class="${className}__media">
      <img class="${className}__avatar"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
        alt="avatar image" />
      <h4 class="${className}__name"></h4>
      <span class="${className}__profession"></span>

      <button type="button" id="${className}__upload-avatar"
        class="${className}__button ${className}__button--filled ${className}__button--upload">
        <input type="file" class="${className}__upload-avatar-file" id="${className}__upload-avatar-file" />
        Upload new avatar
      </button>
      <button type="button" id="${className}__delete-avatar"
        class="${className}__button ${className}__button--bordered ${className}__button--delete">
        Delete
      </button>

      <span class="${className}__delimiter ${className}__delimiter--small"></span>

      <a class="${className}__contact-link" href="#" target="_blank">
        Contact Card
      </a>
    </div>
    <form class="${className}__content">
      <h4 class="${className}__title">
        Basic info
      </h4>

      <div class="${className}__grid">
        <div class="${className}__row ${className}__row--66">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__email">
              E-MAIL
            </label>
            <input required class="${className}__input" id="${className}__email" name="${className}__email" type="email"
              placeholder="Enter e-mail" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__birthday">
              BIRTHDAY
            </label>
            <input class="${className}__input ${className}__input--date" id="${className}__birthday" name="${className}__birthday" type="date" />
          </div>
        </div>
      </div>

      <div class="${className}__grid">
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__first-name">
              FIRST NAME
            </label>
            <input required class="${className}__input" id="${className}__first-name" name="${className}__first-name"
              type="text" placeholder="Enter first name" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__middle-name">
              MIDDLE NAME
            </label>
            <input class="${className}__input" id="${className}__middle-name" name="${className}__middle-name"
              type="text" placeholder="Enter middle name" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__last-name">
              LAST NAME
            </label>
            <input required class="${className}__input" id="${className}__last-name" name="${className}__last-name"
              type="text" placeholder="Enter last name" />
          </div>
        </div>
      </div>

      <div class="${className}__grid">
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__profession">
              PROFESSION
            </label>
            <input class="${className}__input" id="${className}__profession" name="${className}__profession" type="text"
              placeholder="Enter profession" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__phone">
              PHONE NUMBER
            </label>
            <input class="${className}__input" id="${className}__phone" name="${className}__phone" type="tel"
              placeholder="Enter phone" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__country">
              COUNTRY
            </label>
            <select class="${className}__select" id="${className}__country" name="${className}__country">
              <option class="${className}__select-option" value="">Select country</option>
              <option class="${className}__select-option" value="142531">
                Afghanistan
              </option>
              <option class="${className}__select-option" value="142556">
                Albania
              </option>
              <option class="${className}__select-option" value="142484">
                Algeria
              </option>
              <option class="${className}__select-option" value="86435">
                Andorra
              </option>
              <option class="${className}__select-option" value="86381">
                Angola
              </option>
              <option class="${className}__select-option" value="86432">
                Antigua and Barbuda
              </option>
              <option class="${className}__select-option" value="142475">
                Argentina
              </option>
              <option class="${className}__select-option" value="2">
                Armenia
              </option>
              <option class="${className}__select-option" value="142515">
                Australia
              </option>
              <option class="${className}__select-option" value="86373">
                Austria
              </option>
              <option class="${className}__select-option" value="1">
                Azerbaydjan
              </option>
              <option class="${className}__select-option" value="86393">
                Bahamas
              </option>
              <option class="${className}__select-option" value="86433">
                Bahrain
              </option>
              <option class="${className}__select-option" value="86421">
                Bangladesh
              </option>
              <option class="${className}__select-option" value="86414">
                Barbados
              </option>
              <option class="${className}__select-option" value="3">
                Belarus
              </option>
              <option class="${className}__select-option" value="142522">
                Belgium
              </option>
              <option class="${className}__select-option" value="86406">
                Belize
              </option>
              <option class="${className}__select-option" value="86390">
                Benin
              </option>
              <option class="${className}__select-option" value="86434">
                Bhutan
              </option>
              <option class="${className}__select-option" value="142493">
                Bolivia
              </option>
              <option class="${className}__select-option" value="86417">
                Bosnia and Herzegovina
              </option>
              <option class="${className}__select-option" value="86424">
                Botswana
              </option>
              <option class="${className}__select-option" value="142495">
                Brazil
              </option>
              <option class="${className}__select-option" value="142469">
                Brunei
              </option>
              <option class="${className}__select-option" value="142476">
                Bulgaria
              </option>
              <option class="${className}__select-option" value="142518">
                Burkina Faso
              </option>
              <option class="${className}__select-option" value="86385">
                Burundi
              </option>
              <option class="${className}__select-option" value="142514">
                Cambodia
              </option>
              <option class="${className}__select-option" value="86428">
                Cameroon
              </option>
              <option class="${className}__select-option" value="142554">
                Canada
              </option>
              <option class="${className}__select-option" value="86374">
                Cape Verde
              </option>
              <option class="${className}__select-option" value="86444">
                Central African Republic
              </option>
              <option class="${className}__select-option" value="86409">
                Chad
              </option>
              <option class="${className}__select-option" value="142527">
                Chile
              </option>
              <option class="${className}__select-option" value="86404">
                China
              </option>
              <option class="${className}__select-option" value="86388">
                Columbia
              </option>
              <option class="${className}__select-option" value="86364">
                Comoros
              </option>
              <option class="${className}__select-option" value="142526">
                Congo Republic
              </option>
              <option class="${className}__select-option" value="86402">
                Cook Island
              </option>
              <option class="${className}__select-option" value="142517">
                Costa Rica
              </option>
              <option class="${className}__select-option" value="142467">
                Cote D'ivoire
              </option>
              <option class="${className}__select-option" value="142558">
                Croatia
              </option>
              <option class="${className}__select-option" value="142506">
                Cuba
              </option>
              <option class="${className}__select-option" value="142536">
                Cyprus
              </option>
              <option class="${className}__select-option" value="142477">
                Czech Republic
              </option>
              <option class="${className}__select-option" value="86408">
                Democratic Republic of Congo
              </option>
              <option class="${className}__select-option" value="86386">
                Denmark
              </option>
              <option class="${className}__select-option" value="86391">
                Djibouti
              </option>
              <option class="${className}__select-option" value="142520">
                Dominica
              </option>
              <option class="${className}__select-option" value="142494">
                Dominican Republic
              </option>
              <option class="${className}__select-option" value="142546">
                East Timor
              </option>
              <option class="${className}__select-option" value="142473">
                Ecuador
              </option>
              <option class="${className}__select-option" value="142489">
                Egypt
              </option>
              <option class="${className}__select-option" value="142502">
                El Salvador
              </option>
              <option class="${className}__select-option" value="86366">
                Equatorial Guinea
              </option>
              <option class="${className}__select-option" value="86427">
                Eritrea
              </option>
              <option class="${className}__select-option" value="16">
                Estonia
              </option>
              <option class="${className}__select-option" value="142521">
                Ethiopia
              </option>
              <option class="${className}__select-option" value="86377">
                Federated State of Micronesia
              </option>
              <option class="${className}__select-option" value="86383">
                Fiji
              </option>
              <option class="${className}__select-option" value="86410">
                Finland
              </option>
              <option class="${className}__select-option" value="86394">
                France
              </option>
              <option class="${className}__select-option" value="142471">
                Gabon
              </option>
              <option class="${className}__select-option" value="142535">
                Gambia
              </option>
              <option class="${className}__select-option" value="4">
                Georgia
              </option>
              <option class="${className}__select-option" value="142464">
                Germany
              </option>
              <option class="${className}__select-option" value="142539">
                Ghana
              </option>
              <option class="${className}__select-option" value="86365">
                Greece
              </option>
              <option class="${className}__select-option" value="142534">
                Grenada
              </option>
              <option class="${className}__select-option" value="86442">
                Guatemala
              </option>
              <option class="${className}__select-option" value="142496">
                Guinea
              </option>
              <option class="${className}__select-option" value="142483">
                Guinea Bissau
              </option>
              <option class="${className}__select-option" value="142492">
                Guyana
              </option>
              <option class="${className}__select-option" value="86416">
                Haiti
              </option>
              <option class="${className}__select-option" value="142538">
                Honduras
              </option>
              <option class="${className}__select-option" value="142498">
                Hungary
              </option>
              <option class="${className}__select-option" value="86415">
                Iceland
              </option>
              <option class="${className}__select-option" value="142480">
                India
              </option>
              <option class="${className}__select-option" value="142540">
                Indonesia
              </option>
              <option class="${className}__select-option" value="142488">
                Iran
              </option>
              <option class="${className}__select-option" value="142485">
                Iraq
              </option>
              <option class="${className}__select-option" value="142547">
                Ireland
              </option>
              <option class="${className}__select-option" value="5">
                Israel
              </option>
              <option class="${className}__select-option" value="142528">
                Italy
              </option>
              <option class="${className}__select-option" value="86376">
                Jamaica
              </option>
              <option class="${className}__select-option" value="86379">
                Japan
              </option>
              <option class="${className}__select-option" value="142533">
                Jordan
              </option>
              <option class="${className}__select-option" value="6">
                Kazakhstan
              </option>
              <option class="${className}__select-option" value="142543">
                Kenya
              </option>
              <option class="${className}__select-option" value="86437">
                Kiribati
              </option>
              <option class="${className}__select-option" value="142487">
                Kuwait
              </option>
              <option class="${className}__select-option" value="7">
                Kyrgyzstan
              </option>
              <option class="${className}__select-option" value="142501">
                Laos
              </option>
              <option class="${className}__select-option" value="8">
                Latvia
              </option>
              <option class="${className}__select-option" value="86382">
                Lebanon
              </option>
              <option class="${className}__select-option" value="86441">
                Lesotho
              </option>
              <option class="${className}__select-option" value="142465">
                Liberia
              </option>
              <option class="${className}__select-option" value="142508">
                Libya
              </option>
              <option class="${className}__select-option" value="142466">
                Liechtenstein
              </option>
              <option class="${className}__select-option" value="9">
                Lithuania
              </option>
              <option class="${className}__select-option" value="142532">
                Luxembourg
              </option>
              <option class="${className}__select-option" value="86425">
                Macedonia
              </option>
              <option class="${className}__select-option" value="142553">
                Madagascar
              </option>
              <option class="${className}__select-option" value="86392">
                Malawi
              </option>
              <option class="${className}__select-option" value="142511">
                Malaysia
              </option>
              <option class="${className}__select-option" value="142548">
                Maldives
              </option>
              <option class="${className}__select-option" value="86405">
                Mali
              </option>
              <option class="${className}__select-option" value="142472">
                Malta
              </option>
              <option class="${className}__select-option" value="142559">
                Marshall Islands
              </option>
              <option class="${className}__select-option" value="142461">
                Mauretania
              </option>
              <option class="${className}__select-option" value="142463">
                Mauritius
              </option>
              <option class="${className}__select-option" value="86397">
                Mexico
              </option>
              <option class="${className}__select-option" value="10">
                Moldova
              </option>
              <option class="${className}__select-option" value="142490">
                Monaco
              </option>
              <option class="${className}__select-option" value="142507">
                Mongolia
              </option>
              <option class="${className}__select-option" value="142499">
                Montenegro
              </option>
              <option class="${className}__select-option" value="86389">
                Morocco
              </option>
              <option class="${className}__select-option" value="142529">
                Mozambique
              </option>
              <option class="${className}__select-option" value="142557">
                Myanmar
              </option>
              <option class="${className}__select-option" value="86380">
                Namibia
              </option>
              <option class="${className}__select-option" value="86440">
                Nauru
              </option>
              <option class="${className}__select-option" value="142537">
                Nepal
              </option>
              <option class="${className}__select-option" value="86419">
                Netherlands
              </option>
              <option class="${className}__select-option" value="86396">
                New Zealand
              </option>
              <option class="${className}__select-option" value="86403">
                Nicaragua
              </option>
              <option class="${className}__select-option" value="86418">
                Niger
              </option>
              <option class="${className}__select-option" value="86400">
                Nigeria
              </option>
              <option class="${className}__select-option" value="86369">
                Niue
              </option>
              <option class="${className}__select-option" value="86395">
                North Korea
              </option>
              <option class="${className}__select-option" value="142523">
                Norway
              </option>
              <option class="${className}__select-option" value="142541">
                Oman
              </option>
              <option class="${className}__select-option" value="86431">
                Pakistan
              </option>
              <option class="${className}__select-option" value="142555">
                Palau
              </option>
              <option class="${className}__select-option" value="142512">
                Palestine
              </option>
              <option class="${className}__select-option" value="86443">
                Panama
              </option>
              <option class="${className}__select-option" value="142486">
                Papua New Guinea
              </option>
              <option class="${className}__select-option" value="142468">
                Paraguay
              </option>
              <option class="${className}__select-option" value="86430">
                Peru
              </option>
              <option class="${className}__select-option" value="86411">
                Philippines
              </option>
              <option class="${className}__select-option" value="86426">
                Poland
              </option>
              <option class="${className}__select-option" value="86439">
                Portugal
              </option>
              <option class="${className}__select-option" value="142513">
                Qatar
              </option>
              <option class="${className}__select-option" value="142516">
                Romania
              </option>
              <option class="${className}__select-option" value="11">
                Russia
              </option>
              <option class="${className}__select-option" value="142500">
                Rwanda
              </option>
              <option class="${className}__select-option" value="86436">
                Saint Kitts and Nevis
              </option>
              <option class="${className}__select-option" value="86422">
                Saint Lucia
              </option>
              <option class="${className}__select-option" value="142470">
                Saint Vincent and the Grenadines
              </option>
              <option class="${className}__select-option" value="142525">
                Samoa
              </option>
              <option class="${className}__select-option" value="142510">
                San Marino
              </option>
              <option class="${className}__select-option" value="142460">
                Sao Tome and Principe
              </option>
              <option class="${className}__select-option" value="86375">
                Saudi Arabia
              </option>
              <option class="${className}__select-option" value="142552">
                Senegal
              </option>
              <option class="${className}__select-option" value="86438">
                Serbia
              </option>
              <option class="${className}__select-option" value="86372">
                Seychelles
              </option>
              <option class="${className}__select-option" value="142504">
                Sierra Leone
              </option>
              <option class="${className}__select-option" value="86387">
                Singapore
              </option>
              <option class="${className}__select-option" value="142478">
                Slovakia
              </option>
              <option class="${className}__select-option" value="86371">
                Slovenia
              </option>
              <option class="${className}__select-option" value="86378">
                Solomon Islands
              </option>
              <option class="${className}__select-option" value="142482">
                Somalia
              </option>
              <option class="${className}__select-option" value="86420">
                South Africa
              </option>
              <option class="${className}__select-option" value="86423">
                South Korea
              </option>
              <option class="${className}__select-option" value="142503">
                South Sudan
              </option>
              <option class="${className}__select-option" value="86412">
                Spain
              </option>
              <option class="${className}__select-option" value="142519">
                Sri Lanka
              </option>
              <option class="${className}__select-option" value="142481">
                Sudan
              </option>
              <option class="${className}__select-option" value="86370">
                Suriname
              </option>
              <option class="${className}__select-option" value="142545">
                Swaziland
              </option>
              <option class="${className}__select-option" value="142497">
                Sweden
              </option>
              <option class="${className}__select-option" value="86398">
                Switzerland
              </option>
              <option class="${className}__select-option" value="86384">
                Syria
              </option>
              <option class="${className}__select-option" value="12">
                Tajikistan
              </option>
              <option class="${className}__select-option" value="142550">
                Tanzania
              </option>
              <option class="${className}__select-option" value="86413">
                Thailand
              </option>
              <option class="${className}__select-option" value="142551">
                Togo
              </option>
              <option class="${className}__select-option" value="142505">
                Tonga
              </option>
              <option class="${className}__select-option" value="86367">
                Trinidad and Tobago
              </option>
              <option class="${className}__select-option" value="142509">
                Tunisia
              </option>
              <option class="${className}__select-option" value="86429">
                Turkey
              </option>
              <option class="${className}__select-option" value="13">
                Turkmenistan
              </option>
              <option class="${className}__select-option" value="142474">
                Tuvalu
              </option>
              <option class="${className}__select-option" value="142479">
                USA
              </option>
              <option class="${className}__select-option" value="142491">
                Uganda
              </option>
              <option class="${className}__select-option" value="15">
                Ukraine
              </option>
              <option class="${className}__select-option" value="142544">
                United Arab Emirates
              </option>
              <option class="${className}__select-option" value="86399">
                United Kingdom
              </option>
              <option class="${className}__select-option" value="86368">
                Uruguay
              </option>
              <option class="${className}__select-option" value="14">
                Uzbekistan
              </option>
              <option class="${className}__select-option" value="142542">
                Vanuatu
              </option>
              <option class="${className}__select-option" value="142549">
                Vatican City
              </option>
              <option class="${className}__select-option" value="86401">
                Venezuela
              </option>
              <option class="${className}__select-option" value="86407">
                Vietnam
              </option>
              <option class="${className}__select-option" value="142530">
                Yemen
              </option>
              <option class="${className}__select-option" value="142462">
                Zambia
              </option>
              <option class="${className}__select-option" value="142524">
                Zimbabwe
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="${className}__grid">
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__website">
              WEBSITE
            </label>
            <input class="${className}__input" id="${className}__website" name="${className}__website" type="url"
              placeholder="Enter website" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__contacts">
              CONTACTS
            </label>
            <input class="${className}__input" id="${className}__contacts" name="${className}__contacts" type="text"
              placeholder="Enter contacts" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__grid">
            <div class="${className}__row ${className}__row--60">
              <fieldset class="${className}__fieldset ${className}__fieldset--profile-type">
                <legend class="${className}__legend">
                  PROFILE TYPE
                </legend>

                <label class="${className}__label ${className}__label--radio">
                  <input class="${className}__input" name="${className}__profile-type" type="radio"
                    value="businessman" />
                  Businessman
                </label>

                <label class="${className}__label ${className}__label--radio">
                  <input class="${className}__input" name="${className}__profile-type" type="radio"
                    value="individual" />
                  Individual
                </label>
              </fieldset>
            </div>
            <div class="${className}__row ${className}__row--40">
              <fieldset class="${className}__fieldset ${className}__fieldset--gender">
                <legend class="${className}__legend">
                  SEX
                </legend>

                <label class="${className}__label ${className}__label--radio">
                  <input class="${className}__input" name="${className}__gender" type="radio" value="male" />
                  Male
                </label>

                <label class="${className}__label ${className}__label--radio">
                  <input class="${className}__input" name="${className}__gender" type="radio" value="female" />
                  Female
                </label>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div class="${className}__grid">
        <div class="${className}__row ${className}__row--100">
          <div class="${className}__group">
            <span class="${className}__delimiter"></span>
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__facebook">
              FACEBOOK
            </label>
            <input class="${className}__input" id="${className}__facebook" name="${className}__facebook" type="url"
              placeholder="Enter facebook url" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__linkedin">
              LINKEDIN
            </label>
            <input class="${className}__input" id="${className}__linkedin" name="${className}__linkedin" type="url"
              placeholder="Enter linkedin url" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__twitter">
              TWITTER
            </label>
            <input class="${className}__input" id="${className}__twitter" name="${className}__twitter" type="url"
              placeholder="Enter twitter url" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__instagram">
              INSTAGRAM
            </label>
            <input class="${className}__input" id="${className}__instagram" name="${className}__instagram" type="url"
              placeholder="Enter instagram url" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__youtube">
              YOUTUBE
            </label>
            <input class="${className}__input" id="${className}__youtube" name="${className}__youtube" type="url"
              placeholder="Enter youtube url" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--33">
          <div class="${className}__group">
            <label class="${className}__label" for="${className}__reddit">
              REDDIT
            </label>
            <input class="${className}__input" id="${className}__reddit" name="${className}__reddit" type="url"
              placeholder="Enter reddit url" />
          </div>
        </div>
        <div class="${className}__row ${className}__row--100">
          <div class="${className}__group">
            <span class="${className}__delimiter"></span>
          </div>
        </div>
      </div>

      <div class="${className}__grid ${className}__grid--center">
        <div class="${className}__row ${className}__row--33">
          <button type="submit" class="${className}__button ${className}__button--filled ${className}__button--submit ${className}__button--spacing">
            SUBMIT
          </button>
          <button type="reset"
            class="${className}__button ${className}__button--bordered ${className}__button--reset ${className}__button--spacing">
            RESET
          </button>
        </div>
      </div>

    </form>
  </div>
  `;

  blockManager.add("profile-edit", {
    label: `
      <img src="${icon}" alt="Profile Edit block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Profile Edit
      </div>
    `,
    content,
    category: "B24Online Profile"
  });

  // // Make profile avatar classes private
  // const profileEditClasses = [`.${className}`, `.${className}__image`];
  // editor.on(
  //   "selector:add",
  //   selector =>
  //     profileEditClasses.indexOf(selector.getFullName()) >= 0 &&
  //     selector.set("private", 1)
  // );
}
