import React from "react";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import "./default-button.styles.scss";

const DefaultButton = ({
  onClick,
  children,
  backgroundColor,
  color,
  hover,
  opacity,
  transition,
  hoverColor,
  labelHeight,
  labelWidth,
  width,
  height,
  margin,
  labelPadding,
  justifyContent,
  border,
  hoverBorder,
  borderRadius = 0,
  fontSize,
  marginLabel,
  displayLabel,
  justifyContentLabel,
  alignItemsLabel,
  fontWeight,
  padding,
  minWidth,
  ButtonOptions = {},
  disabled
}) => {
  const useStyles = makeStyles({
    root: {
      display: "flex",
      alignItems: "center",
      backgroundColor,
      color,
      width,
      height,
      border,
      borderRadius,
      padding,
      margin,
      justifyContent,
      transition,
      minWidth,

      "&:hover": {
        backgroundColor: hover,
        color: hoverColor,
        border: hoverBorder,
        opacity
      }
    },
    label: {
      display: displayLabel,
      justifyContent: justifyContentLabel,
      alignItems: alignItemsLabel,
      height: labelHeight,
      width: labelWidth,
      textTransform: "none",
      margin: marginLabel,
      padding: labelPadding,
      fontSize,
      fontWeight
    }
  });

  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      disableRipple
      classes={{ root: classes.root, label: classes.label }}
      {...ButtonOptions}
    >
      {children}
    </Button>
  );
};

export default DefaultButton;
