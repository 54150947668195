import React from "react";
import RegisterCard from "../../components/register-card/register-card.component";

import "./registration.styles.scss";

const Registration = () => {
  return (
    <div className="registration-page">
      <RegisterCard />
    </div>
  );
};

export default Registration;
