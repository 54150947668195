import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Card, CardHeader, CardContent, Box } from "@material-ui/core";

import {
  RegisterForm,
  LoginWithFb,
  LoginWithLinkedin,
  LoginWithGoogle,
  LoadingSpinner
} from "../../components";

import { loginWithOauth } from "../../redux/actions-exporter";

import "./register-card.styles.scss";

const RegisterCard = ({
  appDirection,
  token,
  logInPending,
  loginWithOauth,
  isLoginWithOauthPending
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(logInPending || isLoginWithOauthPending);
  }, [logInPending, isLoginWithOauthPending]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const date = new Date();
    const time = date.getTime();
    const expireTime = time + 100000 * 12096;
    date.setTime(expireTime);

    document.cookie = `Authorization=Token ${token}; expires=${date.toUTCString()}`;
    window.location.assign("/");
  }, [token]);

  return (
    <Card className={`register-card ${appDirection}`}>
      <CardHeader title={<Box textAlign="center">Sign Up</Box>} />
      <CardContent className="register-card__main">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="register-card__body">
              <div
                style={{ paddingRight: "45px" }}
                className="register-card__body-column"
              >
                <RegisterForm />
              </div>

              <div className="register-card__body-column">
                <p>Sign in via social media</p>
                <ul>
                  <li>
                    <div className="login-card__body-column__social-media">
                      <LoginWithFb
                        setLoading={setLoading}
                        loginWithOauth={loginWithOauth}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="login-card__body-column__social-media">
                      <LoginWithLinkedin
                        setLoading={setLoading}
                        loginWithOauth={loginWithOauth}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="register-card__body-column__social-media">
                      <LoginWithGoogle
                        setLoading={setLoading}
                        loginWithOauth={loginWithOauth}
                      />
                    </div>
                  </li>
                </ul>
                <p className="login-card__body-column__agreement">
                  By clicking the Sign in button, you accept the terms of the{" "}
                  <Link to="/">User agreement</Link>
                </p>
              </div>
            </div>
            <div className="register-card__footer">
              <Link to="/signin">I'm already registered</Link>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  token: state.authReducer.token,
  logInPending: state.authReducer.logInPending,
  isLoginWithOauthPending: state.authReducer.isLoginWithOauthPending
});

export default connect(mapStateToProps, {
  loginWithOauth
})(RegisterCard);
