import { CountriesActionTypes } from "./countries.types";

const INITIAL_STATE = {
  areCountriesPending: true,
  countries: {},
  countriesError: null,

  isCountryPending: false,
  country: [],
  countryError: null,

  isCountryIdPending: false,
  countryId: 5,
  countryIdError: null
};

const CountriesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CountriesActionTypes.COUNTRIES.GET_COUNTRIES_PENDING:
      return {
        ...state,
        areCountriesPending: true
      };

    case CountriesActionTypes.COUNTRIES.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        areCountriesPending: false,
        countries: action.payload,
        countriesError: null
      };

    case CountriesActionTypes.COUNTRIES.GET_COUNTRIES_ERROR:
      return {
        ...state,
        areCountriesPending: false,
        countriesError: action.payload
      };

    case CountriesActionTypes.GET_COUNTRY.GET_COUNTRY_PENDING:
      return {
        ...state,
        isCountryPending: true
      };

    case CountriesActionTypes.GET_COUNTRY.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isCountryPending: false,
        country: action.payload,
        countryError: null
      };

    case CountriesActionTypes.GET_COUNTRY.GET_COUNTRY_ERROR:
      return {
        ...state,
        isCountryPending: false,
        countryError: action.payload
      };

    case CountriesActionTypes.GET_COUNTRY_ID.GET_COUNTRY_ID_PENDING:
      return {
        ...state,
        isCountryIdPending: true
      };

    case CountriesActionTypes.GET_COUNTRY_ID.GET_COUNTRY_ID_SUCCESS:
      return {
        ...state,
        isCountryIdPending: false,
        countryId: action.payload,
        countryIdError: null
      };

    case CountriesActionTypes.GET_COUNTRY_ID.GET_COUNTRY_ID_ERROR:
      return {
        ...state,
        isCountryIdPending: false,
        countryIdError: action.payload
      };

    default:
      return state;
  }
};

export default CountriesReducer;
