import React from "react";

import ProductWallCard from "../../components/product-wall-card/product-wall-card.component";

const Recomendation = () => {
  return (
    <div className="recommendation-container">
      <h4>Recomendation:</h4>
      <ProductWallCard />
    </div>
  );
};
export default Recomendation;
