import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";

import { EventView1, EventView2 } from "../../assets";
import { Filter } from "../../containers";

import {
  LoadingSpinner,
  CompanyOrganizationCard,
  Pagination,
  NoDataFound
} from "../../components";

import { withDebounce, arrToString } from "../../shared";

import {
  searchOrganizations,
  setCountriesFilterStatus
} from "../../redux/actions-exporter";

import "./organizations.styles.scss";

const Organizations = ({
  isSearchOrganizationsPending,
  searchedOrganizations,
  searchOrganizations,
  searchedOrganizationsCount,
  setCountriesFilterStatus,
  filterCountriesArr
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [organizationsPage, setOrganizationsPage] = useState(1);

  useEffect(() => {
    setCountriesFilterStatus(true);
    return () => {
      setCountriesFilterStatus(false);
    };
  }, [setCountriesFilterStatus]);

  useEffect(() => {
    searchOrganizations(
      searchQuery,
      arrToString(filterCountriesArr),
      organizationsPage
    );
  }, [searchQuery, searchOrganizations, filterCountriesArr, organizationsPage]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [organizationsPage]);

  useEffect(() => {
    setOrganizationsPage(1);
  }, [searchQuery, filterCountriesArr]);

  const organizationsOnFilterChange = withDebounce(e => {
    setSearchQuery(e);
  });

  return (
    <div className="companies">
      <Filter onChange={e => organizationsOnFilterChange(e.target.value)} />
      {isSearchOrganizationsPending ? (
        <LoadingSpinner />
      ) : (
        <Card>
          <div className="companies__cards">
            <div>
              <div className="events__body__title">Organizations</div>
            </div>
            <div className="events__body__padding">
              <div className="events__body__view">
                <p>View:</p>
                <EventView1 className="events__body__view__block" />
                <EventView2 />
              </div>
              {searchedOrganizations &&
                searchedOrganizations.map(
                  ({
                    id,
                    logo,
                    name,
                    short_description,
                    countries,
                    metadata
                  }) => (
                    <CompanyOrganizationCard
                      key={id}
                      img={logo}
                      title={name}
                      bodyText={short_description}
                      location={countries[0].name}
                      number={metadata.phone}
                      website={metadata.site}
                      linkTo={`/organizations/${id}`}
                    />
                  )
                )}
            </div>
          </div>
          {searchedOrganizations && !searchedOrganizations.length && (
            <NoDataFound
              noDataTitle="No organizations found!"
              myBusiness={true}
              additionalLink="/my-business/organizations"
            />
          )}
        </Card>
      )}

      <Pagination
        forcePage={organizationsPage - 1}
        selectedPage={page => setOrganizationsPage(page)}
        dataCount={searchedOrganizationsCount}
        itemsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  isSearchOrganizationsPending:
    state.organizationsReducer.isSearchOrganizationsPending,
  searchedOrganizations: state.organizationsReducer.searchedOrganizations,
  searchedOrganizationsCount:
    state.organizationsReducer.searchedOrganizationsCount,
  filterCountriesArr: state.filterReducer.filterCountriesArr
});

export default connect(mapStateToProps, {
  setCountriesFilterStatus,
  searchOrganizations
})(Organizations);
