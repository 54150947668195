import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { getInnovationProjects } from "../../redux/actions-exporter";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./comp-org-activity-projects.styles.scss";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    // minWidth: 650
  },
  noBorder: {
    border: "none"
  }
});

const CompOrgActivityProjects = ({ getInnovationProjects, projects }) => {
  const activeId = useParams();
  const classes = useStyles();

  useEffect(() => {
    const getActiveData = () => {
      if (activeId.companyId) {
        getInnovationProjects("", 1, activeId.companyId);
      } else if (activeId.organizationId) {
        getInnovationProjects("", 1, activeId.organizationId);
      }
    };
    getActiveData();
  }, [activeId, getInnovationProjects]);

  return (
    <div className="comp-org-activity-projects">
      <h3>Innovation Projects</h3>
      {projects.length > 0 ? (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">LOGO</TableCell>
              <TableCell align="left">NAME</TableCell>
              <TableCell align="left">DATE</TableCell>
              <TableCell align="left">PROJECT COST</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(
              ({ id, name, cost, currency, organization, metadata }) => (
                <TableRow
                  component={Link}
                  to={`/events/innovation-project/${id}`}
                  key={id}
                >
                  <TableCell className={classes.noBorder} align="left">
                    <img
                      style={{ width: "100px" }}
                      src={organization && organization.logo}
                      alt=""
                    />
                  </TableCell>
                  <TableCell
                    style={{ width: "40%" }}
                    className={classes.noBorder}
                    align="left"
                  >
                    {name}
                  </TableCell>
                  <TableCell className={classes.noBorder} align="left">
                    {metadata && metadata.release_date}
                  </TableCell>
                  <TableCell className={classes.noBorder} align="left">
                    {cost + " " + currency}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      ) : (
        <div className="comp-org-activity-projects__no-projects">
          No innovation projects to display
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areProjectsPending: state.innovationProjects.areInnovationProjectsPending,
  projectsCount: state.innovationProjects.innovationProjectsCount,
  projects: state.innovationProjects.innovationProjects
});

export default connect(mapStateToProps, { getInnovationProjects })(
  CompOrgActivityProjects
);
