import React, { useState, useEffect } from "react";

import { ReactComponent as QuestionIcon } from "../../assets/help-question-icon.svg";
import { Clear as ClearIcon } from "@material-ui/icons";

import "./default-multi-range-select.styles.scss";

const DefaultMultiRangeSelect = ({
  title,
  info,
  data,
  returnData,
  returnCustomData,
  customValue,
  onClearHandle
}) => {
  const [, setActiveSelect] = useState();
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    if (customValue) {
      setPercentage(`${customValue}%`);
    }
  }, [customValue]);

  return (
    <div className="default-multi-range-select">
      <div
        onClick={() => {
          setPercentage("");
          onClearHandle(null);
        }}
        className="default-multi-range-select__remove-selection"
      >
        <ClearIcon />
      </div>
      <div className="default-multi-range-select__title">{title}</div>
      <div className="default-multi-range-select__flex-container">
        <div className="default-multi-range-select__icon">
          <QuestionIcon />
        </div>
        <p className="default-multi-range-select__info">{info}</p>
      </div>
      <div className="default-multi-range-select__flex-container">
        {data.map(({ value }, i) => (
          <div
            key={value}
            onClick={e => {
              setPercentage(e.currentTarget.textContent);
              setActiveSelect(i);
              returnData && returnData(e.currentTarget.textContent);
            }}
            className={`default-multi-range-select__item ${
              percentage === value
                ? "default-multi-range-select__item--active"
                : undefined
            }`}
          >
            {value}
          </div>
        ))}
        {/* <input
          onChange={e => {
            setPercentage(e.target.value);
            returnCustomData(e.target.value);
          }}
          onClick={() => setActiveSelect(null)}
          value={percentage}
          type="number"
          placeholder="Other"
          className="default-multi-range-select__item default-multi-range-select__input"
        /> */}
      </div>
    </div>
  );
};

export default DefaultMultiRangeSelect;
