export default function cartTableComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function calculatePrice(cost = 0, discountPercent = 0, couponPercent = 0) {
      const finnalyDiscountPercent = couponPercent || discountPercent;
      if (!finnalyDiscountPercent) return parseFloat(cost);

      return parseFloat(
        cost - (parseFloat(cost) / 100) * parseFloat(finnalyDiscountPercent)
      );
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
          <div class="ct-pagination">
            ${
              currentPage - 1
                ? `
                <button class="ct-pagination__button" data-page="${currentPage -
                  1}">
                <
                </button>
                `
                : `
                <button class="ct-pagination__button ct-pagination__button--disabled" disabled>
                <
                </button>
                `
            }
    
            ${
              currentPage - 3 > 0
                ? `
                <button class="ct-pagination__button" data-page="1">
                1
                </button>
                `
                : ""
            }
    
            ${
              currentPage - 4 > 0
                ? `<span class="ct-pagination__separator">...</span>`
                : ""
            }
    
            ${
              currentPage - 2 > 0
                ? `
                <button class="ct-pagination__button" data-page="${currentPage -
                  2}">
                ${currentPage - 2}
                </button>
                `
                : ""
            }
    
            ${
              currentPage - 1 > 0
                ? `
                <button class="ct-pagination__button" data-page="${currentPage -
                  1}">
                ${currentPage - 1}
                </button>
                `
                : ""
            }
    
            ${`
                <button class="ct-pagination__button ct-pagination__button--active" data-page="${currentPage}">
                ${currentPage}
                </button>
            `}
    
            ${
              currentPage + 1 <= totalPagesCount
                ? `
                <button class="ct-pagination__button" data-page="${currentPage +
                  1}">
                ${currentPage + 1}
                </button>
                `
                : ""
            }
    
            ${
              currentPage + 3 <= totalPagesCount
                ? `<span class="ct-pagination__separator">...</span>`
                : ""
            }
    
            ${
              currentPage + 2 <= totalPagesCount
                ? `
                <button class="ct-pagination__button" data-page="${totalPagesCount}">
                ${totalPagesCount}
                </button>
                `
                : ""
            }
    
            ${
              currentPage + 1 <= totalPagesCount
                ? `
                <button class="ct-pagination__button" data-page="${currentPage +
                  1}">
                >
                </button>
                `
                : `
                <button class="ct-pagination__button ct-pagination__button--disabled" disabled>
                >
                </button>
                `
            }
          </div>
          `;
    }

    function makeCartTableProductsRow(
      deal,
      dealIndex,
      product,
      productIndex,
      detailsPath,
      translations
    ) {
      return `
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image" src="${product.image}" alt="${
        product.name
      } thumbnail" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
              ${product.categories
                .reduce((tmp, category) => tmp.concat(category.name), [])
                .join()}
              </span>
              <h4 class="cart-product__title">
                <a
                  class="cart-product__link"
                  href="${detailsPath}?gjs-product-id=${
        product.id
      }&gjs-product-type=${product.content_type === 84 ? "b2b" : "b2c"}"
                >
                  ${product.name}
                </a>
              </h4>
            </div>          
          </div>
        </td>
        <td>
        ${
          deal.currency &&
          product.unit_price &&
          parseFloat(product.unit_price) > 0
            ? `
              <span class="cart-product__cost">
              ${calculatePrice(
                product.unit_price +
                  parseFloat(product.additional_parameters_cost || 0),
                product.discount_percent,
                product.coupon_discount_percent
              ).toFixed(2)}
              </span>
              <span class="cart-product__currency">
                ${translations[deal.currency]}
              </span>
              ${
                product.discount_percent ||
                (product.coupon_discount_percent &&
                  product.metadata.coupon_start_date &&
                  new Date().getTime() -
                    new Date(product.metadata.coupon_start_date).getTime() >=
                    0 &&
                  product.metadata.coupon_end_date &&
                  new Date(product.metadata.coupon_end_date).getTime() -
                    new Date().getTime() >=
                    0)
                  ? `
              <span class="cart-product__percent-discount">
                -${(
                  product.coupon_discount_percent || product.discount_percent
                ).toFixed(2)}%
              </span>
              `
                  : ""
              }
            `
            : `
            <span class="cart-product__no-price">
              ${translations["N/A"]}
            </span>
            `
        }
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button" data-product_id="${
              product.id
            }" data-deal_index="${dealIndex}">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="${
              product.quantity
            }" data-product_id="${product.id}" data-deal_index="${dealIndex}" />
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button" data-product_id="${
              product.id
            }" data-deal_index="${dealIndex}">+</button>
          </form>
        </td>
        <td>
        ${
          deal.currency &&
          product.unit_price &&
          parseFloat(product.unit_price) > 0
            ? // TODO: !== или привестки к общим типам
              product.total_discount !== 0
              ? `
               <div class="cart-product__cost-container">
                  <span class="cart-product__cost cart-product__cost--total cart-product__cost--total-new">
                    ${product.total}
                    ${translations[deal.currency]}
                  </span>
                 <span class="cart-product__cost cart-product__cost--total cart-product__cost--total-old" style="text-decoration: line-through;">
                    ${(
                      calculatePrice(
                        product.unit_price,
                        product.discount_percent,
                        product.coupon_discount_percent
                      ) * parseInt(product.quantity)
                    ).toFixed(2)}
                    ${translations[deal.currency]}
                  </span>
                  <span class="cart-product__cost cart-product__cost--total cart-product__cost--total-discount">
                  Discount:
                    ${product.total_discount}
                    ${translations[deal.currency]}
                  </span>
               </div>
              `
              : `
                  <span class="cart-product__cost cart-product__cost--total">
                  ${(
                    calculatePrice(
                      product.unit_price,
                      product.discount_percent,
                      product.coupon_discount_percent
                    ) * parseInt(product.quantity)
                  ).toFixed(2)}
                  </span>
                  <span class="cart-product__currency cart-product__currency--total">
                    ${translations[deal.currency]}
                  </span>
                `
            : `
              <span class="cart-product__no-price cart-product__no-price--total">
                ${translations["N/A"]}
              </span>
              `
        }
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="${
            product.id
          }" data-deal_index="${dealIndex}">X</span>
        </td>
      </tr>
    `;
    }

    function makeCartTableHtml(
      deals,
      page,
      total,
      perPage,
      detailsPath,
      translations
    ) {
      return `
      <table class="cart-table">
        <thead class="cart-table__header">
          <tr>
            <th class="cart-table__header-title cart-table__header-title--product">
              ${translations["PRODUCT"]}
            </th>
            <th class="cart-table__header-title cart-table__header-title--price">
              ${translations["PRICE"]}
            </th>
            <th class="cart-table__header-title cart-table__header-title--quantity">
              ${translations["QUANTITY"]}
            </th>
            <th class="cart-table__header-title cart-table__header-title--total" colspan="2">
              ${translations["TOTAL"]}
            </th>
          </tr>
        </thead>
        <tbody>
        ${
          !deals.length
            ? `
            <tr>
              <td colspan="6">
                <span class="cart-table__empty">
                  ${translations["Your cart is currently empty."]}
                </span>
              </td>
            </tr>`
            : ""
        }
        ${deals
          .map((deal, dealIndex) =>
            deal.order_items
              .map((product, productIndex) =>
                makeCartTableProductsRow(
                  deal,
                  dealIndex,
                  product,
                  productIndex,
                  detailsPath,
                  translations
                )
              )
              .join("")
          )
          .join("")}
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              ${makePaginationHtml(page, total, perPage)}
            </td>
          </tr>
        </tfoot>
      </table>
      `;
    }

    function makeCartTableSlimHtml(
      deals,
      page,
      total,
      perPage,
      detailsPath,
      translations
    ) {
      return `
      <table class="cart-table-slim">
        <thead class="cart-table-slim__header">
          <tr>
            <th class="cart-table-slim__header-title">
              ${translations["Your cart"]}
            </th>
          </tr>
        </thead>
        <tbody>
        ${
          !deals.length
            ? `
            <tr>
              <td>
                <span class="cart-table-slim__empty">
                  ${translations["Your cart is currently empty."]}
                </span>
              </td>
            </tr>`
            : ""
        }
        ${deals
          .map((deal, index) =>
            deal.order_items
              .map(
                (product, productIndex) => `
          <tr class="cart-table-slim__row">
            <td>
              <div class="cart-table-slim__product-media">
                <img
                  class="cart-table-slim__product-image"
                  src="${product.image}"
                  alt="${product.name} thumbnail"
                  onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
                />
              </div>
            </td>
            <td>
              <h4 class="cart-table-slim__title">
                <a
                  class="cart-table-slim__link"
                  href="${detailsPath}?gjs-product-id=${
                  product.id
                }&gjs-product-type=${
                  product.content_type === 84 ? "b2b" : "b2c"
                }"
                >
                  ${product.name}
                </a>
              </h4>
              ${
                deal.currency &&
                product.unit_price &&
                parseFloat(product.unit_price) > 0
                  ? `
                    <span class="cart-product__cost">
                    ${calculatePrice(
                      product.unit_price,
                      product.discount_percent,
                      product.coupon_discount_percent
                    ).toFixed(2)}
                    </span>
                    <span class="cart-product__currency">
                      ${translations[deal.currency]}
                    </span>
                    ${
                      product.discount_percent ||
                      product.coupon_discount_percent
                        ? `
                    <span class="cart-product__percent-discount">
                      -${(
                        product.discount_percent ||
                        product.coupon_discount_percent
                      ).toFixed(2)}%
                    </span>
                    `
                        : ""
                    }
                  `
                  : `
                  <span class="cart-product__no-price">
                    ${translations["N/A"]}
                  </span>
                  `
              }
              <form class="cart-quantity-form cart-quantity-form--slim">
                <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button" data-product_id="${
                  product.id
                }" data-deal_index="${index}">-</button>
                <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="${
                  product.quantity
                }" data-product_id="${product.id}" data-deal_index="${index}" />
                <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button" data-product_id="${
                  product.id
                }" data-deal_index="${index}">+</button>
              </form>
            </td>
            <td class="cart-table-slim__actions">
              <div class="cart-table-slim__remove">
                <span class="cart-table-slim__remove-icon" data-product_id="${
                  product.id
                }" data-deal_index="${index}">X</span>
              </div>
              ${
                deal.currency &&
                product.unit_price &&
                parseFloat(product.unit_price) > 0
                  ? `
                    <span class="cart-product__cost cart-product__cost--total">
                    ${(
                      calculatePrice(
                        product.unit_price +
                          parseFloat(product.additional_parameters_cost || 0),
                        product.discount_percent,
                        product.coupon_discount_percent
                      ) * parseInt(product.quantity)
                    ).toFixed(2)}
                    </span>
                    <span class="cart-product__currency cart-product__currency--total">
                      ${translations[deal.currency]}
                    </span>
                  `
                  : `
                  <span class="cart-product__no-price cart-product__no-price--total">
                    ${translations["N/A"]}
                  </span>
                  `
              }
            </td>
          </tr>
        `
              )
              .join("")
          )
          .join("")}
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              ${makePaginationHtml(page, total, perPage)}
            </td>
          </tr>
        </tfoot>
      </table>
      `;
    }

    function setLoading(state) {
      const el = document.querySelector(".cart-table-wrapper");
      if (!el) return;

      if (state) {
        el.classList.add("cart-table-wrapper--preloader");
      } else {
        el.classList.remove("cart-table-wrapper--preloader");
      }
    }

    function render(
      total,
      page,
      deals,
      subtotal,
      loading,
      perPage,
      detailsPath,
      translations
    ) {
      const contentEl = document.querySelector(".cart-table-wrapper");
      setLoading(loading);

      if (!deals.length) {
        contentEl.innerHTML = makeCartTableHtml(
          [],
          1,
          0,
          10,
          detailsPath,
          translations
        );
        return;
      }

      const checkIsSlim = () =>
        document.body.clientWidth <= 992 || contentEl.clientWidth <= 710;
      const isSlim = checkIsSlim();

      contentEl.innerHTML = "";
      contentEl.innerHTML = isSlim
        ? makeCartTableSlimHtml(
            deals,
            page,
            total,
            perPage,
            detailsPath,
            translations
          )
        : makeCartTableHtml(
            deals,
            page,
            total,
            perPage,
            detailsPath,
            translations
          );

      const resizeInterval = setInterval(() => {
        if (checkIsSlim() !== isSlim) {
          window.cart.render();
          clearInterval(resizeInterval);
        }
      }, 600);

      // Decrease quanity of product by input element in cart
      Array.prototype.slice
        .call(document.querySelectorAll(".cart-quantity-form__button--minus"))
        .map(el =>
          el.addEventListener("click", e => {
            const productId = parseInt(e.target.dataset.product_id);
            const dealIndex = parseInt(e.target.dataset.deal_index);
            const product = deals[dealIndex].order_items.find(
              product => product.id === productId
            );
            const quantity =
              parseInt(product.quantity) - 1
                ? parseInt(product.quantity) - 1
                : 1;

            window.cart.changeProductQuantity(product.cart_id, quantity);
          })
        );
      // Increase quanity of product by input element in cart
      Array.prototype.slice
        .call(document.querySelectorAll(".cart-quantity-form__button--plus"))
        .map(el =>
          el.addEventListener("click", e => {
            const productId = parseInt(e.target.dataset.product_id);
            const dealIndex = parseInt(e.target.dataset.deal_index);
            const product = deals[dealIndex].order_items.find(
              product => product.id === productId
            );
            const quantity = parseInt(product.quantity) + 1;

            window.cart.changeProductQuantity(product.cart_id, quantity);
          })
        );
      // Change quanity of product by input element in cart
      Array.prototype.slice
        .call(document.querySelectorAll(".cart-quantity-form__input"))
        .map(el =>
          el.addEventListener("change", e => {
            const productId = parseInt(e.target.dataset.product_id);
            const dealIndex = parseInt(e.target.dataset.deal_index);
            const product = deals[dealIndex].order_items.find(
              product => product.id === productId
            );
            const quantity =
              parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1;

            window.cart.changeProductQuantity(product.cart_id, quantity);
          })
        );
      // Delete product from cart
      Array.prototype.slice
        .call(
          document.querySelectorAll(
            ".cart-table__remove-icon, .cart-table-slim__remove-icon"
          )
        )
        .map(el =>
          el.addEventListener("click", e => {
            const productId = parseInt(e.target.dataset.product_id);
            const dealIndex = parseInt(e.target.dataset.deal_index);
            const product = deals[dealIndex].order_items.find(
              product => product.id === productId
            );

            window.cart.deleteProduct(product.cart_id);
          })
        );

      const paginationButtonsEls = document.querySelectorAll(
        ".ct-pagination__button:not(.ct-pagination__button--disabled)"
      );

      Array.prototype.slice.call(paginationButtonsEls).map(paginationButtonEl =>
        paginationButtonEl.addEventListener("click", e => {
          const btnPage = parseInt(e.target.dataset.page);
          window.cart.setPage(btnPage);
        })
      );
    }

    if (!window.hasOwnProperty("cart")) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window.cart = {
        API_ROOT: API_ROOT,
        organization: organization,
        renderCycle: [],
        perPage: 10,
        count: 10,
        page: 1,
        detailsPath: "#",
        deals: [],
        subtotal: [],
        translations: {},
        loading: false,
        render() {
          console.group("cart render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.count,
              this.page,
              this.deals,
              this.subtotal,
              this.loading,
              this.perPage,
              this.detailsPath,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("render");
        },
        setDetailsPath(path) {
          if (path !== this.detailsPath) {
            this.detailsPath = path;
          }
        },
        setLoading(state) {
          if (this.loading !== state) {
            this.loading = state;
            this.render();
          }
        },
        setPage(page) {
          if (page !== this.page) {
            this.page = page;
            this.getDeals();
          }
        },
        setPerPage(count) {
          if (this.perPage !== count) {
            this.perPage = parseInt(count);
            this.getDeals().bind(this);
          }
        },
        getDeals() {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/payments/deal/?status=draft&page=${this.page}&page_size=${this.perPage}&company=${this.organization}`,
            {
              method: "GET",
              headers: Object.assign(
                { "Accept-Language": localStorage.getItem("language") },
                localStorage.getItem("token")
                  ? { Authorization: `Token ${localStorage.getItem("token")}` }
                  : {}
              ),
              cors: "cors",
              credentials: "omit"
            }
          )
            .then(response => response.json())
            .then(response => this.setDeals(response.count, response.results));
        },
        setDeals(count, deals) {
          // Compare deals
          if (JSON.stringify(this.deals) !== JSON.stringify(deals)) {
            this.render();
          }
          // Set count
          this.count = count;
          // Set deals sorted by ID
          this.deals = deals.sort((a, b) => a.id - b.id);
          localStorage.setItem("deals", JSON.stringify(this.deals));
          // Get subtotal
          this.getSubtotal(true);
        },
        getSubtotal(immediately) {
          if (this.loading && !immediately) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/payments/deal/cart_info/?status=draft&company=${this.organization}`,
            {
              method: "GET",
              headers: Object.assign(
                { "Accept-Language": localStorage.getItem("language") },
                localStorage.getItem("token")
                  ? { Authorization: `Token ${localStorage.getItem("token")}` }
                  : {}
              ),
              cors: "cors",
              credentials: "omit"
            }
          )
            .then(response => response.json())
            .then(response => this.setSubtotal(response || []))
            .catch(error => this.setSubtotal([]));
        },
        setSubtotal(subtotal) {
          // Compare deals
          if (JSON.stringify(this.subtotal) !== JSON.stringify(subtotal)) {
            this.render();
          }
          this.subtotal = subtotal;
          localStorage.setItem("subtotal", JSON.stringify(this.subtotal));
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        changeProductQuantity(cart_id, quantity) {
          if (this.loading) return;
          this.setLoading(true);

          fetch(`${this.API_ROOT}/payments/cart/${cart_id}/`, {
            method: "PATCH",
            headers: Object.assign(
              {
                "Accept-Language": localStorage.getItem("language"),
                "Content-Type": "application/json"
              },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            body: JSON.stringify({ quantity: quantity })
          }).then(() => {
            this.loading = false; // without new render cycle
            this.getDeals();
          });
        },
        deleteProduct(cart_id) {
          if (this.loading) return;
          this.setLoading(true);

          fetch(`${this.API_ROOT}/payments/cart/${cart_id}/`, {
            method: "DELETE",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            )
          }).then(() => {
            this.loading = false; // without new render cycle
            this.getDeals();
          });
        }
      };
    }

    if (window.hasOwnProperty("cart") && window.hasOwnProperty("grapesjs")) {
      const translations = getTranslate("translations", this.attributes);
      window.cart.translations = translations;

      if (!window.cart.renderCycle.find(func => func === render)) {
        window.cart.renderCycle.push(render);
      }

      if (this.attributes.perpage) {
        window.cart.setPerPage(this.attributes.perpage.value);
      }

      if (this.attributes.detailspage) {
        window.cart.setDetailsPath(this.attributes.detailspage.value);
      }

      window.cart.getDeals().then(() => {
        window.cart.render();
        setInterval(() => {
          fetch(
            `${window.cart.API_ROOT}/payments/deal/?status=draft&page=${window.cart.page}&page_size=${window.cart.perPage}&company=${window.cart.organization}`,
            {
              method: "GET",
              headers: Object.assign(
                { "Accept-Language": localStorage.getItem("language") },
                localStorage.getItem("token")
                  ? { Authorization: `Token ${localStorage.getItem("token")}` }
                  : {}
              ),
              cors: "cors",
              credentials: "omit"
            }
          )
            .then(response => response.json())
            .then(response => {
              const count = response.count;
              const deals = response.results;

              if (
                JSON.stringify(
                  window.cart.deals.sort((a, b) => a.id - b.id)
                ) !== JSON.stringify(deals.sort((a, b) => a.id - b.id))
              ) {
                // Set count
                window.cart.count = count;
                // Set deals sorted by ID
                window.cart.deals = deals.sort((a, b) => a.id - b.id);
                localStorage.setItem("deals", JSON.stringify(deals));
                window.cart.getSubtotal(true);
              }
            });
        }, 3800);
      });
    } else {
      const wrapperEl = this;
      const cartTableSlimEl = wrapperEl.querySelector(".cart-table-slim");
      const cartTableEl = wrapperEl.querySelector(".cart-table");

      const checkIsSlim = () => {
        const documentWidth = document.body.clientWidth <= 992;
        const wrapperElWidth = this.clientWidth <= 710;
        const isMobileEditMode = this.attributes.slimeedit
          ? this.attributes.slimeedit.value
          : false;

        return isMobileEditMode || documentWidth || wrapperElWidth;
      };

      setInterval(() => {
        if (checkIsSlim()) {
          cartTableEl.style.visibility = "hidden";
          cartTableEl.style.display = "none";
          cartTableSlimEl.style.visibility = "visible";
          cartTableSlimEl.style.display = "table";
        } else {
          cartTableEl.style.visibility = "visible";
          cartTableEl.style.display = "table";
          cartTableSlimEl.style.visibility = "hidden";
          cartTableSlimEl.style.display = "none";
        }
      }, 1000);
    }
  };

  editor.DomComponents.addType("cart-table", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "cart-table-wrapper",
    model: {
      defaults: {
        name: "Cart Table",
        apiRoot: api.API_ROOT,
        organization: (site && site.organization) || "",
        traits: [
          {
            type: "checkbox",
            label: "Slim edit",
            name: "slimeedit",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "number",
            label: "Deals per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "N",
              "PRODUCT",
              "PRICE",
              "QUANTITY",
              "TOTAL",
              "Your cart",
              "Your cart is currently empty.",
              "SUBTOTAL",
              "Items",
              "No Items",
              "Shipping",
              "N/A",
              "ILS"
            ]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:slimeedit", this.render);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
