import React from "react";
import PrivateRoute from "./PrivateRoute";

import Grapes from "./pages/grapes/grapes.component";

const GrapesApp = () => {
  return (
    <div className="grapes-container">
      <PrivateRoute
        exact
        path="/newbuilder/site/:siteId/template/:templateId"
        component={Grapes}
      />

      <PrivateRoute
        exact
        path="/newbuilder/site/:siteId/page/:pageId"
        component={Grapes}
      />
      <PrivateRoute exact path="/newbuilder/site/:siteId" component={Grapes} />
    </div>
  );
};

export default GrapesApp;
