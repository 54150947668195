import { IntegrationsActionTypes } from "./integrations.types";

const INITIAL_STATE = {
  areIntegrationsPending: false,
  integrations: [],
  integrationsError: {},

  isIntegrationByIdPending: false,
  integrationById: {},
  integrationByIdError: {},

  isIntegrationLogsByIdPending: false,
  integrationLogsById: {},
  integrationLogsByIdError: {},

  createIntegrationPending: false,
  createIntegrationSuccess: {},
  createIntegrationError: {},

  isIntegrationDeleted: false,
  deletedIntegration: {},
  integrationDeleteError: {}
};

const IntegrationsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case IntegrationsActionTypes.GET.GET_INTEGRATIONS_PENDING:
      return {
        ...state,
        areIntegrationsPending: true
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        areIntegrationsPending: false,
        integrations: action.payload.results
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATIONS_ERROR:
      return {
        ...state,
        areIntegrationsPending: false,
        integrationsError: action.payload
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATION_BY_ID_PENDING:
      return {
        ...state,
        isIntegrationByIdPending: true
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATION_BY_ID_SUCCESS:
      return {
        ...state,
        isIntegrationByIdPending: false,
        integrationById: action.payload
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATION_BY_ID_ERROR:
      return {
        ...state,
        isIntegrationByIdPending: false,
        integrationByIdError: action.payload
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATION_LOG_BY_ID_PENDING:
      return {
        ...state,
        isIntegrationLogsByIdPending: true
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATION_LOG_BY_ID_SUCCESS:
      return {
        ...state,
        isIntegrationLogsByIdPending: false,
        integrationLogsById: action.payload
      };

    case IntegrationsActionTypes.GET.GET_INTEGRATION_LOG_BY_ID_ERROR:
      return {
        ...state,
        isIntegrationLogsByIdPending: false,
        integrationLogsByIdError: action.payload
      };

    case IntegrationsActionTypes.POST.CREATE_INTEGRATION_PENDING:
      return {
        ...state,
        createIntegrationPending: true
      };

    case IntegrationsActionTypes.POST.CREATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        createIntegrationPending: false,
        createIntegrationSuccess: action.payload
      };

    case IntegrationsActionTypes.POST.CREATE_INTEGRATION_ERROR:
      return {
        ...state,
        createIntegrationPending: false,
        createIntegrationError: action.payload
      };

    case IntegrationsActionTypes.PATCH.UPDATE_INTEGRATION_PENDING:
      return {
        ...state,
        isIntegrationByIdPending: true
      };

    case IntegrationsActionTypes.PATCH.UPDATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        isIntegrationByIdPending: false,
        integrationById: action.payload
      };

    case IntegrationsActionTypes.PATCH.UPDATE_INTEGRATION_ERROR:
      return {
        ...state,
        isIntegrationByIdPending: false,
        integrationByIdError: action.payload
      };

    case IntegrationsActionTypes.DELETE.DELETE_INTEGRATION_PENDING:
      return {
        ...state,
        isIntegrationDeleted: true
      };

    case IntegrationsActionTypes.DELETE.DELETE_INTEGRATION_SUCCESS:
      return {
        ...state,
        isIntegrationDeleted: false,
        deletedIntegration: action.payload
      };

    case IntegrationsActionTypes.DELETE.DELETE_INTEGRATION_ERROR:
      return {
        ...state,
        isIntegrationDeleted: false,
        integrationDeleteError: action.payload
      };

    default:
      return state;
  }
};

export default IntegrationsReducer;
