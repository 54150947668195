import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../axios/axios.config";

import {
  Wall,
  Logo,
  User,
  IconCart,
  IconMail,
  IconNotification,
  IconProfile,
  IconOrders,
  Resume,
  IconHelp,
  UserPlaceholder,
  Companies,
  CardMachine,
  ChatBot,
} from "../../assets";

import {
  DefaultButton,
  Tooltip,
  LanguageSwitcher,
  LoginModal,
  RegisterModal,
  ExpandArrow,
} from "../../components";

import {
  getCart,
  logOut,
  setBurgerToggleOpen,
  getHeaderStaticPages,
} from "../../redux/actions-exporter";

import { colorPalette } from "../../styles/colorPalette";
import "./header.styles.scss";

const Header = ({
  appDirection,
  loggedIn,
  logOut,
  profile,
  userEmail,
  localizationData,
  avatar,
  setBurgerToggleOpen,
  burgerStatus,
  cartCount,
  getCart,
  headerPages,
  getHeaderStaticPages,
}) => {
  const [tooltip, setTooltip] = useState({});
  const [open, setOpen] = useState(false);
  const [openRegModal, setOpenRegModal] = useState(false);

  const b2bLinks = [
    {
      to: "/organizations",
      name: "Organizations",
    },
    {
      to: "/companies",
      name: "Companies",
    },
    {
      to: "/job/resumes",
      name: "Jobs",
    },
    {
      to: "/news",
      name: "News",
    },
    {
      to: "/events",
      name: "Exhibitions / Forums",
    },
    {
      to: "/business-proposals",
      name: "Business Proposals",
    },
    {
      to: "/innovation-projects",
      name: "Innovation Projects",
    },
    {
      to: "/builder/sites",
      name: "Website Builder",
    },
    {
      to: "/chat-bot",
      name: "Chat Bot",
    },
    {
      to: "/my-business/pos",
      name: "POS",
    },
  ];

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setBurgerToggleOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHeaderStaticPages();
  }, [getHeaderStaticPages]);

  useEffect(() => {
    const authCookie = getCookie("Authorization");
    if (authCookie) {
      getCart();
    }
  }, [getCart]);

  const handleOpen = () => {
    setOpen(true);
    setTooltip({});
  };
  const handleClose = () => setOpen(false);

  const handleCloseRegModal = () => setOpenRegModal(false);

  const handleTooltipNamespace = (namespace) => (visibility) =>
    setTooltip({
      ...tooltip,
      [namespace]: visibility,
    });

  const LoginTooltip = (
    <div className={`login-tooltip ${appDirection}`}>
      <div className="login-tooltip__padding">
        <h3>{localizationData["Welcome"]}</h3>
        <p style={{ marginBottom: 10 }}>
          {localizationData["Sign in"] || "Sign in"} - B24Online
        </p>
        <NavLink className="login-tooltip__login-btn" to="/signin">
          <DefaultButton
            color={colorPalette.colorPrimary.main}
            width="100%"
            border={`2px solid ${colorPalette.colorPrimary.main}`}
          >
            {localizationData["Login"] || "Login"}
          </DefaultButton>
        </NavLink>
      </div>
      <div className="login-tooltip__separator" />
      <div className="login-tooltip__padding">
        <p className="login-tooltip__footer">
          Don't have an account?{" "}
          <NavLink className="login-tooltip__footer__signup" to="/signup">
            {localizationData["Sign up" || "Sign up"]}
          </NavLink>
        </p>
      </div>
    </div>
  );

  const ProfileTooltip = (
    <div className={`profile-tooltip ${appDirection}`}>
      <div className="profile-tooltip__header">
        {profile && profile.avatar ? (
          <div>
            <img src={profile.avatar} alt="profile" />
          </div>
        ) : (
          <div className="profile-tooltip__header__placeholder">
            <UserPlaceholder />
          </div>
        )}
        <div className="profile-tooltip__header__details">
          <h3>
            {profile && profile.first_name !== undefined && profile.first_name}{" "}
            {profile && profile.last_name !== undefined && profile.last_name}
          </h3>
          <p>{userEmail}</p>
        </div>
      </div>
      <div className="profile-tooltip__separator" />
      <div className="profile-tooltip__body">
        <NavLink className="profile-tooltip__link" to="/profile">
          <IconProfile />
          {localizationData["My Profile"] || "My Profile"}
        </NavLink>
        <NavLink className="profile-tooltip__link" to="/cart/last-orders">
          <IconOrders />
          Last orders
        </NavLink>
        <NavLink
          className="profile-tooltip__link business"
          to="/my-business/products"
        >
          <Companies />
          My business
        </NavLink>
        <NavLink className="profile-tooltip__link pos" to="/my-business/pos">
          <CardMachine />
          My POS
        </NavLink>
        <NavLink className="profile-tooltip__link smartbot" to="/chat-bot">
          <ChatBot />
          My SmartBot
        </NavLink>
        <NavLink className="profile-tooltip__link" to="/my-business/resume">
          <Resume />
          My resumes
        </NavLink>
        <NavLink className="profile-tooltip__link" to="/">
          <IconHelp />
          Help
        </NavLink>
      </div>
      <div className="profile-tooltip__separator" />
      <div onClick={logOut} className="profile-tooltip__footer">
        <NavLink className="profile-tooltip__link" to="/">
          Exit
        </NavLink>
      </div>
    </div>
  );

  const NavLinksTooltip = (links) => (
    <div className={`nav-links-tooltip ${appDirection}`} dir={appDirection}>
      <div className="nav-links-tooltip__content">
        <ul className="nav-links-tooltip__list">
          {links.map((link, index) => (
            <li className="nav-links-tooltip__list-item" key={index}>
              <NavLink to={link.to} className="nav-links-tooltip__link">
                {localizationData[link.name] || link.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <div className="header">
      <div className="upper-header">
        <div className="header-logo-wrapper">
          <NavLink to="/" className="logo-container">
            <Logo className="logo" />
          </NavLink>
          <Wall
            className="burger-menu"
            onClick={() => setBurgerToggleOpen(!burgerStatus)}
            width="32px"
            height="42px"
          />
        </div>

        <div className={`options`}>
          <NavLink to={`/`} className="option">
            {localizationData["Wall"] || "Wall"}
          </NavLink>
          <NavLink to={`/products`} className="option">
            {localizationData["Products"] || "Products"}
          </NavLink>

          <Tooltip
            onVisibilityChange={handleTooltipNamespace("b2bPortal")}
            tooltipShown={tooltip.b2bPortal}
            placement="bottom"
            tooltip={NavLinksTooltip(b2bLinks)}
          >
            <span className="option">
              {localizationData["B2B Portal"] || "B2B Portal"}
              <ExpandArrow />
            </span>
          </Tooltip>

          {headerPages && !!headerPages.length ? (
            <Tooltip
              onVisibilityChange={handleTooltipNamespace("staticPages")}
              tooltipShown={headerPages.length > 1 && tooltip.staticPages}
              placement="bottom"
              tooltip={
                headerPages.length > 1 ? (
                  NavLinksTooltip(
                    headerPages.slice(1).map(({ id, title }) => ({
                      to: `/static/${id}/${title
                        .toLowerCase()
                        .split(" ")
                        .join("-")}`,
                      name: title,
                    }))
                  )
                ) : (
                  <></>
                )
              }
            >
              <NavLink
                to={`/static/${headerPages[0].id}/${headerPages[0].title
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
                className="option"
              >
                {headerPages[0].title}
                {headerPages.length > 1 ? <ExpandArrow /> : ""}
              </NavLink>
            </Tooltip>
          ) : (
            ""
          )}
        </div>

        <div className={`icons`}>
          <div id="widget-message" className="icon">
            <IconMail width="27px" height="27px" />
          </div>
          <div id="widget-notify" className="icon">
            <IconNotification width="22px" height="22px" />
          </div>
          {/*<div className="icon icon_language">EN</div>*/}
          <LanguageSwitcher
            languages={["ENG", "RU", "AM", "BG", "UK", "HE", "AR", "ZH", "ES"]}
            className="icon"
          />
          <NavLink to="/cart" className="icon header__icon">
            <IconCart width="26px" height="26px" />
            {cartCount > 0 && (
              <div className="header__count-box">
                <div className="header__count">{cartCount}</div>
              </div>
            )}
          </NavLink>
          <Tooltip
            onVisibilityChange={handleTooltipNamespace("profileTooltip")}
            tooltipShown={tooltip.profileTooltip}
            placement="bottom"
            tooltip={loggedIn ? ProfileTooltip : LoginTooltip}
          >
            <div className="icon user">
              <div className="header__user-logo-box">
                {profile && profile.avatar ? (
                  <img
                    src={profile.avatar}
                    alt="Profile avatar"
                    className="header__user-logo"
                  />
                ) : (
                  <div className="user">
                    <User />
                  </div>
                )}
              </div>
            </div>
          </Tooltip>

          <LoginModal
            open={open}
            handleClose={handleClose}
            handleOpen={handleOpen}
          />
          <RegisterModal
            openRegModal={openRegModal}
            handleCloseRegModal={handleCloseRegModal}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appDirection: state.appLanguage.appDirection,
  burgerStatus: state.appLanguage.burgerToggleOpen,
  loggedIn: state.authReducer.loggedIn,
  profile: state.profileAPI.profile,
  userEmail: state.profileAPI.userEmail,
  localizationData: state.appLanguage.localizationData,
  cartCount: state.cart.cartCount,
  headerPages: state.staticPagesReducer.headerPages,
});

export default connect(mapStateToProps, {
  logOut,
  setBurgerToggleOpen,
  getCart,
  getHeaderStaticPages,
})(Header);
