import React, { useState } from "react";
import { connect } from "react-redux";

import { FilterIcon, IconCustomization } from "../../assets";
import {
  SearchBar,
  FilterAccordion,
  WallCustomization,
} from "../../components";

import "./filter.styles.scss";

const Filter = ({
  onChange,
  direction,
  localizationData,
  createCustomizationHandler,
  customization,
}) => {
  const [expand, setExpand] = useState(false);

  const handleExpand = () => setExpand(!expand ? true : false);

  return (
    <div className="filter" dir={direction}>
      <div className="filter__header">
        <div className="icon" onClick={handleExpand}>
          <FilterIcon />
        </div>
        <div className="filter__search-bar">
          <SearchBar onChange={onChange} />
        </div>
        {customization ? (
          <div className="icon">
            <WallCustomization
              icon={<IconCustomization />}
              label={localizationData["Customization"] || "Customization"}
              list={Object.entries(customization).map(([key, item]) => ({
                ...item,
                key,
                label: localizationData[item.label] || item.label,
                onToggle: createCustomizationHandler(key),
              }))}
            />
          </div>
        ) : null}
      </div>
      {expand && (
        <div className="filter__body">
          <FilterAccordion />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  localizationData: state.appLanguage.localizationData,
});

export default connect(mapStateToProps)(Filter);
