import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  ColumnsSmLgWrapper,
  ColumnWrapperSm,
  ColumnWrapperLg,
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "../../../layout";

import { videoIsUploaded } from "../../../constants";
import { missingImage, missingVideo } from "../../../assets";
import {
  MediaActions,
  DefaultButton,
  InputGroup,
  DefaultDropdown,
  DefaultDropdownMultiSelect,
  Separator,
  EditorGroup,
  CircleLoader,
  DefaultSelectInput,
  DefaultDatePicker
} from "../../../components";

import {
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  postInnovationProjects,
  putInnovationProjects,
  searchCountry,
  searchBranch,
  getBusinessProposalsCategories,
  getCompaniesByUserId,
  getInnovationProjectById,
  clearInnovationProjectsRequestStatus,
  getCurrencies
} from "../../../redux/actions-exporter";

import { withDebounce } from "../../../shared";
import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./innovation-projects-edit.styles.scss";

const MyBusinessInnovationProjectsEdit = ({
  galleryBlob,
  galleryFile,
  videoBlob,
  videoFile,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  isImageGalleryUploadPending,
  postInnovationProjects,
  putInnovationProjects,
  searchCountry,
  countries,
  areBranchesPending,
  branchesAPI,
  searchBranch,
  businessProposalsCategories,
  getBusinessProposalsCategories,
  getCompaniesByUserId,
  companiesByUserId,
  getInnovationProjectById,
  businessProposal,
  clearInnovationProjectsRequestStatus,
  innovationProjectsRequestStatus,
  getCurrencies,
  currencies,
  innovationProject
}) => {
  const { innovationProjectsId } = useParams();
  const history = useHistory();

  const [branchInput, setBranchInput] = useState("");
  const [companyInput, setCompanyInput] = useState("");

  const [innovationProjectsData, setInnovationProjectData] = useState({
    ipName: "",
    ipProductName: "",
    ipCurrency: null,
    ipCost: null,
    ipCompany: "",
    ipKeywords: "",
    ipDescription: "",
    ipBusinessPlan: "",
    ipFile: "",
    ipBlob: "",
    ipSite: "",
    ipReleaseDate: ""
  });
  const {
    ipName,
    ipProductName,
    ipCurrency,
    ipCost,
    ipCompany,
    ipKeywords,
    ipDescription,
    ipBusinessPlan,
    ipFile,
    ipSite,
    ipReleaseDate
  } = innovationProjectsData;

  const [ipBranches, setIpBranches] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  useEffect(() => {
    getCompaniesByUserId();
  }, [getCompaniesByUserId, companyInput]);

  useEffect(() => {
    searchBranch(branchInput);
  }, [searchBranch, branchInput]);

  useEffect(() => {
    if (innovationProjectsId !== "create") {
      getInnovationProjectById(innovationProjectsId);
    }
  }, [getInnovationProjectById, innovationProjectsId]);

  useEffect(() => {
    if (innovationProjectsId !== "create") {
      const {
        name,
        description,
        product_name,
        business_plan,
        currency,
        cost,
        keywords,
        organization,
        branches,
        metadata
      } = innovationProject;

      setInnovationProjectData(innovationProjectsData => ({
        ...innovationProjectsData,
        ipName: name,
        ipProductName: product_name,
        ipBusinessPlan: business_plan,
        ipCurrency: currency,
        ipCost: cost,
        ipDescription: description,
        ipCompany: organization && organization.id,
        ipKeywords: keywords,
        ipSite: metadata && metadata.site,
        ipReleaseDate: metadata && metadata.release_date
      }));

      setIpBranches(branches);
    }
  }, [innovationProjectsId, innovationProject]);

  useEffect(() => {
    setButtonDisabled(
      ipName === "" ||
        ipDescription === "" ||
        ipProductName === "" ||
        ipBusinessPlan === ""
    );
  }, [ipName, ipDescription, ipProductName, ipBusinessPlan]);

  useEffect(() => {
    if (
      innovationProjectsRequestStatus === 200 ||
      innovationProjectsRequestStatus === 201
    ) {
      history.push("/my-business/innovation-projects");
    }
  }, [innovationProjectsRequestStatus, history]);

  useEffect(() => () => clearInnovationProjectsRequestStatus(), [
    clearInnovationProjectsRequestStatus
  ]);

  const innovationProjectsInputHandler = (name, value) =>
    setInnovationProjectData({ ...innovationProjectsData, [name]: value });

  const innovationProjectsMediaHandler = data => {
    setInnovationProjectData({ ...innovationProjectsData, ...data });
  };

  const innovationProjectsRequest = () => {
    if (innovationProjectsId === "create") {
      postInnovationProjects(
        ipName,
        ipDescription,
        ipProductName,
        ipBusinessPlan,
        ipBranches,
        ipCompany,
        ipCost,
        ipCurrency,
        ipKeywords,
        ipSite,
        ipReleaseDate
      );
    } else {
      putInnovationProjects(
        innovationProjectsId,
        ipName,
        ipDescription,
        ipProductName,
        ipBusinessPlan,
        ipBranches,
        ipCompany,
        ipCost,
        ipCurrency,
        ipKeywords,
        ipSite,
        ipReleaseDate
      );
    }
  };

  const setBranchInputHandler = withDebounce(e => setBranchInput(e));
  const setCompanyInputHandler = withDebounce(e => setCompanyInput(e));

  return (
    <div className="my-business-innovation-projects-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={ipFile ? ipFile : missingImage}
            alt={"company-picture"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton {...buttonOk} width="100%">
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      innovationProjectsMediaHandler({
                        bpFile: URL.createObjectURL(e.target.files[0]),
                        bpBlob: e.target.files[0]
                      });
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                {...buttonCancel}
                width="100%"
                onClick={() =>
                  innovationProjectsMediaHandler({
                    businessFile: "",
                    businessBlob: ""
                  })
                }
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {innovationProjectsId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          // onClick={() =>
                          //   uploadImageGallery(newsType, newsId, galleryBlob)
                          // }
                          disabled={galleryBlob === ""}
                          width="100%"
                          {...buttonOk}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("remove");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
              <Separator />
              <MediaActions
                image={videoFile ? videoIsUploaded : missingVideo}
                alt={"missing image"}
                height={200}
                buttons={[
                  <DefaultButton width="100%" {...buttonOk}>
                    <label style={{ cursor: "pointer" }}>
                      Choose a video for gallery
                      <input
                        accept="video/*"
                        type="file"
                        onChange={e => {
                          loadFileVideo(URL.createObjectURL(e.target.files[0]));
                          loadBlobVideo(e.target.files[0]);
                        }}
                        style={{ display: "none", cursor: "pointer" }}
                      />
                    </label>
                  </DefaultButton>,
                  <DefaultButton
                    width="100%"
                    {...buttonOk}
                    // onClick={() => uploadVideoGallery(newsType, newsId, videoBlob)}
                    disabled={videoBlob === ""}
                  >
                    Upload new gallery
                  </DefaultButton>,
                  <DefaultButton
                    onClick={() => {
                      loadBlobVideo("");
                      loadFileVideo("");
                    }}
                    width="100%"
                    {...buttonCancel}
                  >
                    Delete gallery
                  </DefaultButton>
                ]}
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="Name"
              placeholder="Name"
              name="ipName"
              value={ipName || ""}
              onChange={({ target: { name, value } }) =>
                innovationProjectsInputHandler(name, value)
              }
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="Product Name"
              placeholder="Product Name..."
              name="ipProductName"
              value={ipProductName || ""}
              onChange={({ target: { name, value } }) =>
                innovationProjectsInputHandler(name, value)
              }
              required={true}
            />
            <DefaultSelectInput
              returnData={e => innovationProjectsInputHandler("ipCurrency", e)}
              customData={
                innovationProjectsId !== "create" &&
                innovationProject &&
                innovationProject.currency
              }
              value={ipCost}
              onChange={e =>
                innovationProjectsInputHandler("ipCost", e.target.value)
              }
              title="Cost"
              options={currencies.map(currency => ({
                label: currency.unit,
                value: currency.label
              }))}
              width="80px"
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdownMultiSelect
              items={branchesAPI.results}
              returnData={setIpBranches}
              label="Branches"
              filter={true}
              setCategoryInputValue={e => setBranchInputHandler(e.target.value)}
              customArray={
                innovationProjectsId !== "create" && innovationProject.branches
              }
            />

            <DefaultDropdown
              label="COMPANY"
              items={companiesByUserId}
              returnData={e => innovationProjectsInputHandler("ipCompany", e)}
              inputFunc={e => setCompanyInputHandler(e.target.value)}
              filter={true}
              customTitle={
                innovationProjectsId !== "create" &&
                innovationProject.organization &&
                innovationProject.organization.name
              }
            />
          </ParentWrapper>
          <ParentWrapper>
            <EditorGroup
              label="Business Plan"
              name="businessPlan"
              onChange={e =>
                innovationProjectsInputHandler(
                  "ipBusinessPlan",
                  e.target.getContent()
                )
              }
              value={ipBusinessPlan}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={({ target: { value } }) =>
                innovationProjectsInputHandler("ipKeywords", value)
              }
              value={ipKeywords}
              label="KEYWORDS"
              name="keywords"
              placeholder="#hashtag, #hashtag, ..., #hashtag"
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      <Separator />
      <ParentWrapper>
        <DefaultDatePicker
          label="RELEASE DATE"
          value={ipReleaseDate}
          returnDate={e => innovationProjectsInputHandler("ipReleaseDate", e)}
        />
        <InputGroup
          onChange={({ target: { value } }) =>
            innovationProjectsInputHandler("ipSite", value)
          }
          value={ipSite}
          label="Site"
          name="ipSite"
          placeholder="Site..."
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          label="DESCRIPTION"
          name="Description"
          onChange={e =>
            innovationProjectsInputHandler(
              "ipDescription",
              e.target.getContent()
            )
          }
          value={ipDescription}
          required={true}
        />
      </ParentWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={innovationProjectsRequest}
            {...buttonOk}
            width="100%"
            disabled={buttonDisabled}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.push("/my-business/innovation-projects")}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  areCountriesPending: state.countriesReducer.areCountriesPending,
  countries: state.countriesReducer.countries,

  areBranchesPending: state.branchesReducer.areBranchesPending,
  branchesAPI: state.branchesReducer.branches,
  companiesByUserId: state.companiesReducer.companiesByUserId,

  innovationProject: state.innovationProjects.innovationProject,

  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,
  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,
  innovationProjectsRequestStatus:
    state.innovationProjects.innovationProjectsRequestStatus,
  currencies: state.utilsReducer.currencies
});

export default connect(mapStateToProps, {
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  postInnovationProjects,
  putInnovationProjects,
  searchCountry,
  searchBranch,
  getBusinessProposalsCategories,
  getCompaniesByUserId,
  getInnovationProjectById,
  clearInnovationProjectsRequestStatus,
  getCurrencies
})(MyBusinessInnovationProjectsEdit);
