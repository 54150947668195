import { FilterActionTypes } from "./filter.types";

const INITIAL_STATE = {
  isCountriesFilterPending: false,
  countriesFilter: [],
  countriesFilterError: {},

  isOrganizationsFilterPending: false,
  organizationsFilter: [],
  organizationsFilterError: {},

  isBranchesFilterPending: false,
  branchesFilter: [],
  branchesFilterError: {},

  isCategoriesFilterPending: false,
  b2cCategoriesFilter: [],
  b2bCategoriesFilter: [],
  categoriesFilterError: {}
};

const FilterAPIReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilterActionTypes.GET.COUNTRIES.GET_COUNTRIES_FILTER_PENDING:
      return { ...state, isCountriesFilterPending: true };

    case FilterActionTypes.GET.COUNTRIES.GET_COUNTRIES_FILTER_SUCCESS:
      return {
        ...state,
        countriesFilter: action.payload.countries,
        isCountriesFilterPending: false
      };

    case FilterActionTypes.GET.COUNTRIES.GET_COUNTRIES_FILTER_ERROR:
      return {
        ...state,
        isCountriesFilterPending: false,
        countriesFilterError: action.payload
      };

    case FilterActionTypes.GET.ORGANIZATIONS.GET_ORGANIZATIONS_FILTER_PENDING:
      return { ...state, isOrganizationsFilterPending: true };

    case FilterActionTypes.GET.ORGANIZATIONS.GET_ORGANIZATIONS_FILTER_SUCCESS:
      return {
        ...state,
        organizationsFilter: action.payload.organizations,
        isOrganizationsFilterPending: false
      };

    case FilterActionTypes.GET.ORGANIZATIONS.GET_ORGANIZATIONS_FILTER_ERROR:
      return {
        ...state,
        organizationsFilterError: action.payload,
        isOrganizationsFilterPending: false
      };

    case FilterActionTypes.GET.BRANCHES.GET_BRANCHES_FILTER_PENDING:
      return { ...state, isBranchesFilterPending: true };

    case FilterActionTypes.GET.BRANCHES.GET_BRANCHES_FILTER_SUCCESS:
      return {
        ...state,
        branchesFilter: action.payload.branches,
        isBranchesFilterPending: false
      };

    case FilterActionTypes.GET.BRANCHES.GET_BRANCHES_FILTER_ERROR:
      return {
        ...state,
        branchesSearchResultError: action.payload,
        isBranchesFilterPending: false
      };

    case FilterActionTypes.GET.B2C_CATEGORIES.GET_B2C_CATEGORIES_FILTER_PENDING:
      return { ...state, isCategoriesFilterPending: true };

    case FilterActionTypes.GET.B2C_CATEGORIES.GET_B2C_CATEGORIES_FILTER_SUCCESS:
      return {
        ...state,
        b2cCategoriesFilter: action.payload.results,
        isCategoriesFilterPending: false
      };

    case FilterActionTypes.GET.B2C_CATEGORIES.GET_B2C_CATEGORIES_FILTER_ERROR:
      return {
        ...state,
        categoriesFilterError: action.payload,
        isCategoriesFilterPending: false
      };

    case FilterActionTypes.GET.B2B_CATEGORIES.GET_B2B_CATEGORIES_FILTER_PENDING:
      return { ...state, isCategoriesFilterPending: true };

    case FilterActionTypes.GET.B2B_CATEGORIES.GET_B2B_CATEGORIES_FILTER_SUCCESS:
      return {
        ...state,
        b2bCategoriesFilter: action.payload.results,
        isCategoriesFilterPending: false
      };

    case FilterActionTypes.GET.B2B_CATEGORIES.GET_B2B_CATEGORIES_FILTER_ERROR:
      return {
        ...state,
        categoriesFilterError: action.payload,
        isCategoriesFilterPending: false
      };

    default:
      return state;
  }
};

export default FilterAPIReducer;
