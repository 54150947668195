import React, { useState } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";

import { ButtonsFooterWrapper } from "../../layout";
import { DefaultButton, CustomCheckbox } from "../../components";

import { buttonOk } from "../../styles/buttons-styles";
import "./shipping-selector.styles.scss";

const DropdownShipping = ({
  shippingByCompany,
  appliedMethod,
  setAppliedMethod
}) => {
  const [shippingOpened, setShippingOpened] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);

  return (
    <>
      <div className="shipping-selector-data">
        {appliedMethod && (
          <>
            <div>
              Delivery by: <strong>{appliedMethod.name}</strong>
            </div>
            <div>
              Currency: <strong>{appliedMethod.currency}</strong>
            </div>
            <div>
              Free delivery starting from:
              <strong>{appliedMethod.free_delivery_from}</strong>
            </div>
            <div>
              Flat fee: <strong>{appliedMethod.flat_fee}</strong>
            </div>
            <div>
              Percentage fee: <strong>{appliedMethod.percentage_fee}</strong>
            </div>
          </>
        )}
      </div>
      <div className="shipping-methods">
        <div className="shipping-methods__header">
          <div className="header-title">Shipping:</div>
          <div
            className={`header-button ${shippingOpened &&
              `header-button-open`}`}
            onClick={() => setShippingOpened(!shippingOpened)}
            unselectable="on"
          >
            {selectedMethod ? selectedMethod.name : "Select shipping:"}
          </div>
        </div>
        <div
          className={`shipping-methods__content ${
            shippingOpened ? "content-open" : null
          }`}
        >
          <div className="content-header">
            <div className="content-header__title">SHIPPING METHODS</div>
            <div
              className="content-header__clear"
              onClick={() => setShippingOpened(false)}
            >
              <Button>
                <ClearIcon />
              </Button>
            </div>
          </div>
          <div className="content-table">
            {shippingByCompany.results instanceof Array &&
            shippingByCompany.results?.length ? (
              <>
                <Table className="shipping-table" aria-label="items table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" />
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Currency</TableCell>
                      <TableCell align="center">Free delivery from</TableCell>
                      <TableCell align="center">Flat fee</TableCell>
                      <TableCell align="center">Percentage fee</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shippingByCompany.results &&
                      shippingByCompany.results.map((item, status) => {
                        const {
                          id,
                          name,
                          description,
                          currency,
                          free_delivery_from,
                          flat_fee,
                          percentage_fee
                        } = item;
                        return (
                          <TableRow key={id}>
                            <TableCell align="center">
                              <CustomCheckbox
                                status={status}
                                onCheckboxChange={() => setSelectedMethod(item)}
                              />
                            </TableCell>
                            <TableCell align="center">{name}</TableCell>
                            <TableCell align="center">{description}</TableCell>
                            <TableCell align="center">{currency}</TableCell>
                            <TableCell align="center">
                              {free_delivery_from}
                            </TableCell>
                            <TableCell align="center">{flat_fee}</TableCell>
                            <TableCell align="center">
                              {percentage_fee}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <ButtonsFooterWrapper>
                  <div>
                    <DefaultButton
                      width="100%"
                      onClick={() =>
                        selectedMethod && setAppliedMethod(selectedMethod)
                      }
                      {...buttonOk}
                      disabled={selectedMethod === null}
                    >
                      Apply
                    </DefaultButton>
                  </div>
                </ButtonsFooterWrapper>
              </>
            ) : (
              <div className="no-shipping">No shipping methods!</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownShipping;
