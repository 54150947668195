import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";

// import BusinessProposals from "./pages/business-proposals/business-proposals.component";
// import InnovationProjects from "./pages/innovation-projects/innovation-projects.component";
// import MyOrganizationOrders from "./containers/my-business/my-organization-orders/my-organization-orders.components";
// import MyPersonOrders from "./pages/my-person-orders/my-person-orders.component";

import {
  News,
  Wall,
  SelectedNews,
  Organization,
  Organizations,
  Products,
  Job,
  Companies,
  Company,
  Events,
  Profile,
  ProductDescription,
  Event,
  Cart,
  BuilderSites,
  BuilderSite,
  BuilderCreateSite,
  MyBusiness,
  Login,
  Registration,
  ChatBotPage,
  NoMatchPage,
  StaticPage,
  BusinessProposals,
  InnovationProjects
} from "../pages";

import { ForgotPassword, ResetPassword } from "@core/pages";

import PelecardCheck from "../components/pelecard/pages/check";

import { LinkedInPopUp } from "react-linkedin-login-oauth2";

export const generalAppRoutes = [
  { path: "/", component: Wall, exact: true },
  { path: "/chat-bot", component: ChatBotPage, exact: true },
  { path: "/signin", component: Login, exact: true },
  { path: "/signup", component: Registration, exact: true },
  { path: "/linkedin", component: LinkedInPopUp, exact: true },
  { path: "/news", component: News, exact: true },
  { path: "/news/:newsId", component: SelectedNews, exact: false },
  { path: "/companies", component: Companies, exact: true },
  { path: "/companies/:companyId", component: Company, exact: false },
  { path: "/password/reset", component: ForgotPassword, exact: true },
  { path: "/password/reset/confirm", component: ResetPassword, exact: true },
  {
    path: "/organizations",
    component: Organizations,
    exact: true
  },
  {
    path: "/organizations/:organizationId",
    component: Organization,
    exact: false
  },
  { path: "/products", component: Products, exact: true },
  { path: "/cart", component: Cart, exact: false },
  { path: "/job", component: Job, exact: false },
  // {
  //   path: "/job/resumes/:resumeId",
  //   component: ResumeDetails,
  //   exact: true
  // },
  // {
  //   path: "/job/find-jobs/:jobId",
  //   component: JobDetails,
  //   exact: true
  // },
  // {
  //   path: "/job/find-jobs/:jobId/send-resume",
  //   component: SendResume,
  //   exact: true
  // },
  {
    path: "/product/:productType/:productId",
    component: ProductDescription,
    exact: false
  },
  { path: "/event", component: Event, exact: true },
  { path: "/events", component: Events, exact: true },

  {
    exact: true,
    path: "/business-proposals",
    component: BusinessProposals
  },
  {
    exact: true,
    path: "/innovation-projects",
    component: InnovationProjects
  },
  {
    path: "/events/:eventType/:eventTypeId/:compOrg/:compOrgId",
    component: Event,
    exact: false
  },
  {
    path: "/business-proposals/:eventType/:eventTypeId",
    component: Event,
    exact: false
  },
  {
    path: "/innovation-projects/:eventType/:eventTypeId",
    component: Event,
    exact: false
  },

  {
    path: "/builder/sites/create",
    component: BuilderCreateSite,
    exact: true
  },
  {
    path: "/builder/sites/id/:siteId",
    component: BuilderSite,
    exact: true
  },

  { path: "/my-business", component: MyBusiness, exact: false },
  {
    path: "/static/:staticId/:staticTitle",
    component: StaticPage,
    exact: true
  }
];

export const appRoutes = (
  <>
    <Switch>
      {generalAppRoutes.map(({ path, component, exact }, index) => (
        <Route key={index} path={path} component={component} exact={exact} />
      ))}
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/builder/sites/" component={BuilderSites} />
      <PrivateRoute exact path="/order_check" component={PelecardCheck} />
      <Route component={NoMatchPage} />
    </Switch>
  </>
);
