import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { getBusinessProposals } from "../../redux/actions-exporter";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./comp-org-activity-tender.styles.scss";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    // minWidth: 650
  },
  noBorder: {
    border: "none"
  }
});

const CompOrgActivityTender = ({ getBusinessProposals, proposals }) => {
  const activeId = useParams();
  const classes = useStyles();

  useEffect(() => {
    const getActiveData = () => {
      if (activeId.companyId) {
        getBusinessProposals("", 1, activeId.companyId);
      } else if (activeId.organizationId) {
        getBusinessProposals("", 1, activeId.organizationId);
      }
    };
    getActiveData();
  }, [activeId, getBusinessProposals]);

  return (
    <div className="comp-org-activity-tender">
      <h3>Business Proposals</h3>
      {proposals.length > 0 ? (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="justify">LOGO</TableCell>
              <TableCell align="justify">NAME</TableCell>
              <TableCell align="justify">COUNTRY</TableCell>
              <TableCell align="justify">DATE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposals.map(
              ({ id, name, organization, country, created_at }) => (
                <TableRow
                  component={Link}
                  to={`/events/business-proposal/${id}`}
                  key={id}
                >
                  <TableCell className={classes.noBorder} align="left">
                    <img
                      style={{ width: "100px" }}
                      src={organization && organization.logo}
                      alt="Business proposal"
                    />
                  </TableCell>
                  <TableCell
                    style={{ width: "40%" }}
                    className={classes.noBorder}
                    align="left"
                  >
                    {name}
                  </TableCell>
                  <TableCell className={classes.noBorder} align="left">
                    {country && country.name}
                  </TableCell>
                  <TableCell className={classes.noBorder} align="left">
                    {moment(created_at).format("DD MMMM YYYY")}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      ) : (
        <div className="comp-org-activity-tender__no-proposals">
          No business proposals to display
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areProposalsPending: state.businessProposals.areBusinessProposalsPending,
  proposalsCount: state.businessProposals.businessProposalsCount,
  proposals: state.businessProposals.businessProposals
});

export default connect(mapStateToProps, { getBusinessProposals })(
  CompOrgActivityTender
);
