import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/styles";
import { Card, CardMedia, IconButton } from "@material-ui/core";
import {
  FavoriteBorder as FavoriteBorderIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from "@material-ui/icons";

import { getCookie } from "../../axios/axios.config";
import { IconHeart, IconChecked } from "../../assets";

import {
  returnDays,
  returnHours,
  returnMinutes,
  returnSeconds
} from "../../shared";
import { DefaultSnackbar } from "../../components";

import {
  addToCart,
  editCart,
  getCart,
  addProductToWishList,
  getWishList
} from "../../redux/actions-exporter";

import "./product-card.styles.scss";

const ProductCard = ({
  appDirection,
  addToCart,
  editCart,
  navProductType,
  productId,
  imgUrl,
  name,
  description,
  price,
  priceDiscount = 0,
  currency,
  discountPercent,
  couponDiscountPercent,
  metadata,
  getCart,
  getWishList,
  cart,
  wishList,
  addProductToWishList,
  currentProductData
}) => {
  let b2bProductId = "";
  let b2cProductId = "";

  if (navProductType === "b2b") {
    b2bProductId = productId;
  } else if (navProductType === "b2c") {
    b2cProductId = productId;
  }

  const [quantity] = useState(1);
  const [companyId] = useState("");
  const [customerType] = useState("person");
  const [show, setshow] = useState(false);
  const handleShow = () => setshow(true);
  const handleHide = () => setshow(false);

  const [snackMessage, setSnackMessage] = useState("");
  const customMessages = [
    { id: 1, message: "Succesfully added to wishlist!" },
    { id: 2, message: "Succesfully added to cart!" }
  ];

  const [existsInCart, setExistsInCart] = useState(false);
  const [existsInWishList, setExistsInWishList] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const addToWishListHandler = () => {
    addProductToWishList(
      navProductType === "b2b" ? "item_b2b" : "item_b2c",
      currentProductData
    );
    setExistsInWishList(true);
    setSnackMessage(customMessages[0].message);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  const addToCartItemHandler = useCallback(() => {
    setExistsInCart(true);
    addToCart(customerType, companyId, b2bProductId, b2cProductId, quantity);
    setSnackMessage(customMessages[1].message);
    setShowMessage(true);
    setTimeout(() => {
      setExistsInCart(false);
      setShowMessage(false);
    }, 2000);
  }, []); // eslint-disable-line

  useEffect(() => {
    const authCookie = getCookie("Authorization");
    if (authCookie) {
      getCart();
      getWishList();
    }

    if (wishList.find(i => i.wish_list_item[0].item_id === productId)) {
      setExistsInWishList(true);
    } else {
      setExistsInWishList(false);
    }
    // eslint-disable-next-line
  }, [
    getCart,
    getWishList,
    editCart,
    addToCart,
    productId,
    addToCartItemHandler
  ]);

  const useStyles = makeStyles({
    card: {
      maxWidth: 200
    },
    media: {
      width: 200,
      height: 200
    },
    actions: {
      position: "relative",
      height: "0px"
    },
    action: {
      top: "-140px",
      left: "20px"
    },
    actionRTL: {
      top: "-140px",
      left: "20px"
    },
    iconAdded: {
      width: "30px",
      height: "30px",
      fontSize: "30px",
      backgroundColor: "white",
      borderRadius: "50%",
      padding: "12px"
    },
    icon: {
      fontSize: "30px",
      backgroundColor: "white",
      borderRadius: "50%",
      padding: "12px"
    }
  });
  const classes = useStyles();

  return (
    <div className="product-card">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="success"
          message={snackMessage}
          autoHideDuration={2000}
        />
      )}
      <Card
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        className={classes.card}
      >
        <CardMedia className={classes.media}>
          <div className="discounts">
            {!!discountPercent && (
              <div className="discounts__discount-percent">{`${discountPercent}%`}</div>
            )}
            {!!couponDiscountPercent && (
              <div className="discounts__coupon-discount-percent">
                {`${couponDiscountPercent}%`}
              </div>
            )}
          </div>
          <img className="product-card__image" src={imgUrl} alt="" />
        </CardMedia>
        {metadata && metadata.coupon_end_date && metadata.coupon_start_date && (
          <div className="coupon-expires">
            <div className="coupon-expires__days">
              {metadata &&
                metadata.coupon_end_date &&
                metadata.coupon_start_date &&
                returnDays(metadata.coupon_end_date)}
              <div className="subtext">days</div>
            </div>
            <div className="coupon-expires__hours">
              {metadata &&
                metadata.coupon_end_date &&
                metadata.coupon_start_date &&
                returnHours(metadata.coupon_end_date)}
              <div className="subtext">hour</div>
            </div>
            <div className="coupon-expires__minutes">
              {metadata &&
                metadata.coupon_end_date &&
                metadata.coupon_start_date &&
                returnMinutes(metadata.coupon_end_date)}
              <div className="subtext">min</div>
            </div>
            <div className="coupon-expires__days">
              {metadata &&
                metadata.coupon_end_date &&
                metadata.coupon_start_date &&
                returnSeconds(metadata.coupon_end_date)}
              <div className="subtext">sec</div>
            </div>
          </div>
        )}

        {show && (
          <>
            <div className={classes.actions}>
              {existsInWishList ? (
                <IconButton
                  disableRipple
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <IconHeart className={classes.iconAdded} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={addToWishListHandler}
                  disableRipple
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <FavoriteBorderIcon className={classes.icon} />
                </IconButton>
              )}

              {existsInCart ? (
                <IconButton
                  disableRipple
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <IconChecked
                    style={{ fill: "green" }}
                    className={classes.iconAdded}
                  />
                </IconButton>
              ) : (
                <IconButton
                  disableRipple
                  onClick={() => addToCartItemHandler()}
                  className={appDirection ? classes.actionRTL : classes.action}
                >
                  <AddCircleOutlineIcon className={classes.icon} />
                </IconButton>
              )}
            </div>
          </>
        )}
        <div className="product-card__product-details">
          <Link to={`/product/${navProductType}/${productId}`}>
            <div className="product-name">
              {name ? name : "No product name!"}
            </div>
            <div className="product-description">
              {description
                ? description.length > 40
                  ? description.substring(0, 40) + "..."
                  : description
                : "No product description!"}
            </div>
            {price && price > 0 && (
              <div className="product-price">
                {priceDiscount && priceDiscount > 0 ? (
                  <div className="product-price__container">
                    <div className="product-price__value">
                      {priceDiscount + " " + currency}
                    </div>
                    <div className="product-price__value_old">
                      {price ? `${price} ${currency}` : "No price!"}
                    </div>
                  </div>
                ) : (
                  price + " " + currency
                )}
              </div>
            )}
          </Link>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  cart: state.cart.cart,
  wishList: state.wishListReducer.wishList
});

export default connect(mapStateToProps, {
  addToCart,
  editCart,
  getCart,
  addProductToWishList,
  getWishList
})(ProductCard);
