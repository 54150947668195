import React from "react";

import "./my-business-header-bar.styles.scss";

const MyBusinessHeaderBar = ({ title }) => {
  return (
    <div className="my-business-header-bar">
      <div className="my-business-header-bar__text">{title}</div>
    </div>
  );
};

export default MyBusinessHeaderBar;
