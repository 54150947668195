export const B2BProductsActionTypes = {
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  CREATE_CATEGORY: "CREATE_CATEGORY_LIST",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  GET_PRODUCT_LIST_PENDING: "GET_PRODUCT_LIST_PENDING",
  GET_PRODUCT_LIST_ERROR: "GET_PRODUCT_LIST_ERROR",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",

  GET: {
    GET_B2B_PRODUCTS_PENDING: "GET_B2B_PRODUCTS_PENDING",
    GET_B2B_PRODUCTS_SUCCESS: "GET_B2B_PRODUCTS_SUCCESS",
    GET_B2B_PRODUCTS_ERROR: "GET_B2B_PRODUCTS_ERROR",
    USER: {
      GET_USER_B2B_PRODUCTS_PENDING: "GET_USER_B2B_PRODUCTS_PENDING",
      GET_USER_B2B_PRODUCTS_SUCCESS: "GET_USER_B2B_PRODUCTS_SUCCESS",
      GET_USER_B2B_PRODUCTS_ERROR: "GET_USER_B2B_PRODUCTS_ERROR"
    },
    RECOMMENDED: {
      GET_RECOMMENDED_B2B_PRODUCTS_PENDING:
        "GET_RECOMMENDED_B2B_PRODUCTS_PENDING",
      GET_RECOMMENDED_B2B_PRODUCTS_SUCCESS:
        "GET_RECOMMENDED_B2B_PRODUCTS_SUCCESS",
      GET_RECOMMENDED_B2B_PRODUCTS_ERROR: "GET_RECOMMENDED_B2B_PRODUCTS_ERROR"
    },
    SIMILAR: {
      GET_SIMILAR_B2B_PRODUCTS_PENDING: "GET_SIMILAR_B2B_PRODUCTS_PENDING",
      GET_SIMILAR_B2B_PRODUCTS_SUCCESS: "GET_SIMILAR_B2B_PRODUCTS_SUCCESS",
      GET_SIMILAR_B2B_PRODUCTS_ERROR: "GET_SIMILAR_B2B_PRODUCTS_ERROR"
    }
  },
  COMPANY: {
    GET_COMPANY_B2B_PRODUCTS_PENDING: "GET_COMPANY_B2B_PRODUCTS_PENDING",
    GET_COMPANY_B2B_PRODUCTS_SUCCESS: "GET_COMPANY_B2B_PRODUCTS_SUCCESS",
    GET_COMPANY_B2B_PRODUCTS_FAIL: "GET_COMPANY_B2B_PRODUCTS_FAIL"
  },
  ORGANIZATION: {
    GET_ORGANIZATION_B2B_PRODUCTS_PENDING:
      "GET_ORGANIZATION_B2B_PRODUCTS_PENDING",
    GET_ORGANIZATION_B2B_PRODUCTS_SUCCESS:
      "GET_ORGANIZATION_B2B_PRODUCTS_SUCCESS",
    GET_ORGANIZATION_B2B_PRODUCTS_FAIL: "GET_ORGANIZATION_B2B_PRODUCTS_FAIL"
  },

  PATCH: {
    IMAGE: {
      PATCH_B2B_IMAGE_PENDING: "PATCH_B2B_IMAGE_PENDING",
      PATCH_B2B_IMAGE_SUCCESS: "PATCH_B2B_IMAGE_SUCCESS",
      PATCH_B2B_IMAGE_ERROR: "PATCH_B2B_IMAGE_ERROR"
    }
  },
  CLEAR_B2B_PRODUCTS_REQUEST: "CLEAR_B2B_PRODUCTS_REQUEST"
};
