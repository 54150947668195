import React, { useEffect } from "react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { Play } from "../../assets";
import { LoadingSpinner } from "../../components";

import { getStaticPageById } from "../../redux/actions-exporter";

import "./static-page.styles.scss";

const StaticPage = ({
  getStaticPageById,
  isStaticPagePending,
  staticPageById
}) => {
  const { staticId } = useParams();

  useEffect(() => {
    getStaticPageById(staticId);
  }, [staticId, getStaticPageById]);

  return (
    <div className="static-page">
      {isStaticPagePending ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="static-page__header">
            <Play />
            <div className="static-header-title">{staticPageById.title}</div>
          </div>
          <div className="static-page__content">
            {staticPageById.content && parse(staticPageById.content)}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isStaticPagePending: state.staticPagesReducer.isStaticPagePending,
  staticPageById: state.staticPagesReducer.staticPageById
});

export default connect(mapStateToProps, { getStaticPageById })(StaticPage);
