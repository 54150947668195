import { B2BProductsActionTypes } from "./b2b.products.types";

const INITIAL_STATE = {
  categoryList: {},

  isB2BProductsPending: false,
  b2bProductsNamespaces: {},
  b2bProducts: [],
  b2bProductsCount: 0,
  b2bProductsError: null,

  createdProduct: {},
  updatedProductByID: {},
  deletedProduct: {},
  isDeleted: false,

  areCompanyB2BProductsPending: false,
  companyB2BProducts: [],
  companyB2BProductsCount: 0,
  companyB2BProductsError: null,

  areUserB2BProductsPending: false,
  userB2BProductsCount: 0,
  userB2BProducts: [],
  userB2BProductsError: null,

  isB2BImagePatchPending: false,
  b2bImagePatchData: {},
  b2bImagePathError: null,

  areRecommendedB2BProductsPending: false,
  recommendedB2BProducts: [],
  recommendedB2BProductsCount: 0,
  recommendedB2BProductsError: null,

  areSimilarB2BProductsPending: false,
  similarB2BProducts: [],
  similarB2BProductsCount: 0,
  similarB2BProductsError: null,

  areB2BOrganizationProductsPending: false,
  b2bOrganizationProducts: {},
  b2bOrganizationProductsError: null,

  isB2BCustomParametersPending: false,
  B2BCustomParameters: [],
  B2BCustomParametersError: {},

  b2bRequestStatus: ""
};

const B2BProductsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case B2BProductsActionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload
      };

    case B2BProductsActionTypes.GET.GET_B2B_PRODUCTS_PENDING:
      return {
        ...state,
        isB2BProductsPending: true
      };

    case B2BProductsActionTypes.GET.GET_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...(action.payload.namespace
          ? {
              b2bProductsNamespaces: {
                ...state.b2bProductsNamespaces,
                [action.payload.namespace]: {
                  ...(action.payload.additional || {}),
                  products: action.payload.products
                }
              }
            }
          : {}),
        b2bProducts: action.payload.products,
        b2bProductsCount: action.payload.count,
        isB2BProductsPending: false
      };

    case B2BProductsActionTypes.GET.GET_B2B_PRODUCTS_ERROR:
      return {
        ...state,
        b2bProductsError: action.payload,
        isB2BProductsPending: false
      };

    case B2BProductsActionTypes.CREATE_PRODUCT:
      return {
        ...state,
        b2bRequestStatus: action.payload.requestStatus,
        createdProduct: action.payload
      };

    case B2BProductsActionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        b2bRequestStatus: action.payload.requestStatus,
        updatedProductById: action.payload
      };

    case B2BProductsActionTypes.DELETE_PRODUCT:
      return {
        ...state,
        deletedProduct: action.payload,
        isDeleted: true
      };

    case B2BProductsActionTypes.COMPANY.GET_COMPANY_B2B_PRODUCTS_PENDING:
      return { ...state, areCompanyB2BProductsPending: true };

    case B2BProductsActionTypes.COMPANY.GET_COMPANY_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        companyB2BProducts: action.payload.products,
        companyB2BProductsCount: action.payload.count,
        areCompanyB2BProductsPending: false
      };

    case B2BProductsActionTypes.COMPANY.GET_COMPANY_B2B_PRODUCTS_FAIL:
      return {
        ...state,
        companyB2BProductsError: action.payload,
        areCompanyB2BProductsPending: false
      };

    case B2BProductsActionTypes.ORGANIZATION
      .GET_ORGANIZATION_B2B_PRODUCTS_PENDING:
      return { ...state, areB2BOrganizationProductsPending: true };

    case B2BProductsActionTypes.ORGANIZATION
      .GET_ORGANIZATION_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        areB2BOrganizationProductsPending: false,
        b2bOrganizationProducts: action.payload,
        b2bOrganizationProductsError: null
      };

    case B2BProductsActionTypes.ORGANIZATION.GET_RECOMMENDED_B2B_PRODUCTS_ERROR:
      return {
        ...state,
        areB2BOrganizationProductsPending: false,
        b2bOrganizationProductsError: action.payload
      };

    case B2BProductsActionTypes.GET.USER.GET_USER_B2B_PRODUCTS_PENDING:
      return { ...state, areUserB2BProductsPending: true };

    case B2BProductsActionTypes.GET.USER.GET_USER_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        userB2BProductsCount: action.payload.count,
        userB2BProducts: action.payload.products,
        areUserB2BProductsPending: false
      };

    case B2BProductsActionTypes.GET.USER.GET_USER_B2B_PRODUCTS_ERROR:
      return {
        ...state,
        userB2BProductsError: action.payload,
        areUserB2BProductsPending: false
      };

    case B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_PENDING:
      return { ...state, isB2BImagePatchPending: true };

    case B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_SUCCESS:
      return {
        ...state,
        isB2BImagePatchPending: false,
        b2bImagePatchData: action.payload,
        b2bImagePathError: null
      };

    case B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_ERROR:
      return {
        ...state,
        isB2BImagePatchPending: false,
        b2bImagePathError: action.payload
      };

    case B2BProductsActionTypes.GET.RECOMMENDED
      .GET_RECOMMENDED_B2B_PRODUCTS_PENDING:
      return { ...state, areRecommendedB2BProductsPending: true };

    case B2BProductsActionTypes.GET.RECOMMENDED
      .GET_RECOMMENDED_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        recommendedB2BProducts: action.payload.products,
        recommendedB2BProductsCount: action.payload.count,
        areRecommendedB2BProductsPending: false
      };

    case B2BProductsActionTypes.GET.RECOMMENDED
      .GET_RECOMMENDED_B2B_PRODUCTS_ERROR:
      return {
        ...state,
        recommendedB2BProductsError: action.payload
      };

    case B2BProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2B_PRODUCTS_PENDING:
      return { ...state, areSimilarB2BProductsPending: true };

    case B2BProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        similarB2BProducts: action.payload.products,
        similarB2BProductsCount: action.payload.count,
        areSimilarB2BProductsPending: false
      };

    case B2BProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2B_PRODUCTS_ERROR:
      return {
        ...state,
        similarB2BProductsError: action.payload,
        areSimilarB2BProductsPending: false
      };

    case B2BProductsActionTypes.CLEAR_B2B_PRODUCTS_REQUEST: {
      return {
        ...state,
        b2bRequestStatus: action.payload
      };
    }

    default:
      return state;
  }
};

export default B2BProductsReducer;
