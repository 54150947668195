import icon from "./icon.png";

export default function productsCategoriesMultiLevelMenuBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .products-categories-new-menu {}

  .products-categories-new-menu.products-categories-new-menu--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .products-categories-new-menu.products-categories-new-menu--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .products-categories-new-menu__list {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .products-categories-new-menu__item {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 5px;
    margin: 6px 0 0;
    border-radius: 2px;
    cursor: pointer;
    transition: all 200ms ease;
    border-bottom-color: #da6f5b;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    text-decoration: none;
  }

  .products-categories-new-menu__link {
    color: currentColor;
    text-decoration: none;
  }

  .products-categories-new-menu__link:hover {
    color: currentColor;
  }
  
  .products-categories-new-menu__list {
    position: relative;
  }  
  
  .products-categories-new__submenu {
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 0 7px -1px rgba(0,0,0,0.15);
  }
  
  .products-categories-new__submenu-link {
    width: 100%;
    display: block;
  }
  
  .products-categories-new__submenu-link.submenu-level-1 {
    font-weight: 600;
    color: #000000;
    text-decoration: underline;
    margin: 0 0 6px;
  }
  
  .products-categories-new__submenu-link.submenu-level-2 {
    display:flex;
    justify-content: flex-end;
  }
  
  .products-categories-new__submenu-btn {
    display: none;
  }
  
  .products-categories-new__extramenu {
    position: absolute;
    left: -108%;
    right: 0;
    background: #ffffff;
    top: 50%;
    transform: translate(0, -50%);
    width: 250px;
    opacity: 0;
    visibility: hidden;
    transition: .2s;
    box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.15);
    padding: 10px;
    z-index: -1;
  }
  
  .products-categories-new__submenu-item.level-2:hover > .products-categories-new__extramenu {
    visibility: visible;
    opacity: 1;
    z-index: 10;
  }
  
  .products-categories-new-menu__item--primary:hover > .products-categories-new__submenu {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }  
  
  @media (min-width: 1024px) {
    .products-categories-new-menu__list {
      justify-content: center;
      position: relative;
    }
    
    .products-categories-new__submenu {
      left: 0;
      position: absolute;
      padding: 40px 40px;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      min-height: 350px;
      border-top: 4px solid red;
      box-sizing: border-box;
      max-height: 100vh;
      transition: .2s;
      display: flex;
      justify-content: center;
    }
              
    .products-categories-new__submenu-item {
      text-align: right;
      margin-bottom: 5px;
    }
    
    .products-categories-new__submenu-item.level-1 {
      margin: 0 16px;
    }
    
    .products-categories-new__submenu-item.level-2:hover > .products-categories-new__extramenu {
      visibility: visible;
      opacity: 1;
    }
  }
  
  @media (max-width: 1023px) {
    .products-categories-new__submenu {
      padding: 10px 0;  
      display: none;  
    }
    
    .products-categories-new__submenu-link {
      padding: 0 10px;
    }
    
    .products-categories-new__innermenu {
      display: none;
      width: 100%;
      transition: .2s;
      background: #f6f6f6;
      padding: 10px;
      margin: 5px 0;
    }
    
    .products-categories-new-menu__item.menu-item.opened > .products-categories-new-menu__link ~ .products-categories-new__submenu {
      display: block;
    }
    
    .products-categories-new__submenu-item.menu-item.opened > .products-categories-new__submenu-link ~ .products-categories-new__innermenu {
      display: block;
    }
  }
</style>
  `;

  const content = `
  ${style}
  <div class="products-categories-new-multi-level-menu">
    <ul class="products-categories-new-menu__list">
      <li class="products-categories-new-menu__item products-categories-new-menu__item--primary">
        <a class="products-categories-new-menu__link products-categories-new-menu__link--primary" href="">
          Category
        </a>
        <div class="products-categories-new__submenu">
          <div class="products-categories-new__submenu-item">
            <a href="" class="products-categories-new__submenu-link">Subcategory</a>  
            <div class="products-categories-new__innermenu">
              <div class="products-categories-new__submenu-item">
                <a href="products-categories-new__submenu-link">
                  Subcategory level 2
                </a>
              </div>          
            </div> 
          </div>  
        </div>
      </li>
      <li class="products-categories-new-menu__item products-categories-new-menu__item--primary">
        <a class="products-categories-new-menu__link products-categories-new-menu__link--primary" href="">
          Category
        </a>
        <div class="products-categories-new__submenu">
          <div class="products-categories-new__submenu-item">
            <a href="" class="products-categories-new__submenu-link">Subcategory</a>  
          </div>  
        </div>
      </li>
      <li class="products-categories-new-menu__item products-categories-new-menu__item--primary">
        <a class="products-categories-new-menu__link products-categories-new-menu__link--primary" href="">
          Category
        </a>
      </li>
      <li class="products-categories-new-menu__item products-categories-new-menu__item--primary">
        <a class="products-categories-new-menu__link products-categories-new-menu__link--primary" href="">
          Category
        </a>
      </li>
    </ul>
  </div>
  `;

  blockManager.add("products-categories-new-multi-level-menu", {
    label: `
      <img src="${icon}" alt="Products categories menu block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Categories Multi-level Menu
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
