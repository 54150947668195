import React from "react";

import {
  Companies,
  Products,
  Events,
  Job,
  Resume,
  CompanyDepartment,
  Leads,
  Analytic,
  Organizations,
  Delivery,
  News,
  IconInvite,
  IconMeeting,
  IconUser,
  BusinessProposals,
  InnovationProjects
} from "../../assets";

export const navItems = [
  {
    id: 1,
    tabPath: "products",
    tabIcon: <Products width="16px" height="16px" />,
    tabName: "Products"
  },
  {
    id: 2,
    tabPath: "companies",
    tabIcon: <Companies width="16px" height="16px" />,
    tabName: "Companies"
  },
  {
    id: 3,
    tabPath: "organizations",
    tabIcon: <Organizations width="16px" height="16px" />,
    tabName: "Organizations"
  },
  {
    id: 4,
    tabPath: "department",
    tabIcon: <CompanyDepartment width="16px" height="16px" />,
    tabName: "Department"
  },
  {
    id: 5,
    tabPath: "events",
    tabIcon: <Events width="16px" height="16px" />,
    tabName: "Events"
  },
  {
    id: 6,
    tabPath: "leads",
    tabIcon: <Leads width="16px" height="16px" />,
    tabName: "Leads"
  },
  {
    id: 7,
    tabPath: "business-proposals",
    tabIcon: <BusinessProposals width="16px" height="16px" />,
    tabName: "Business Proposals"
  },
  {
    id: 8,
    tabPath: "innovation-projects",
    tabIcon: <InnovationProjects width="16px" height="16px" />,
    tabName: "Innovation Projects"
  },
  {
    id: 9,
    tabPath: "news",
    tabIcon: <News width="16px" height="16px" />,
    tabName: "News"
  },
  {
    id: 10,
    tabPath: "vacancy",
    tabIcon: <Job width="16px" height="16px" />,
    tabName: "Vacancy"
  },
  {
    id: 11,
    tabPath: "resume",
    tabIcon: <Resume width="16px" height="16px" />,
    tabName: "Resume"
  },
  {
    id: 12,
    tabPath: "statistics",
    tabIcon: <Analytic width="16px" height="16px" />,
    tabName: "Analytic"
  },
  {
    id: 13,
    tabPath: "delivery",
    tabIcon: <Delivery width="16px" height="16px" />,
    tabName: "Delivery"
  },
  {
    id: 14,
    tabPath: "event-invitations",
    tabIcon: <IconInvite width="16px" height="16px" />,
    tabName: "Event Invitations"
  },
  {
    id: 15,
    tabPath: "meeting-invitations",
    tabIcon: <IconMeeting width="16px" height="16px" />,
    tabName: "Meeting invitations"
  },
  {
    id: 16,
    tabPath: "categories",
    tabIcon: <IconMeeting width="16px" height="16px" />,
    tabName: "Categories"
  },
  {
    id: 17,
    tabPath: "clients",
    tabIcon: <IconUser width="16px" height="16px" />,
    tabName: "Clients"
  },
  {
    id: 18,
    tabPath: "pages",
    tabIcon: <IconMeeting width="16px" height="16px" />,
    tabName: "Pages"
  }
];
