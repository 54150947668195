export const FilterActionTypes = {
  COUNTRIES: {
    ADD_COUNTRY: "ADD_COUNTRY",
    DELETE_COUNTRY: "DELETE_COUNTRY",
    SET_COUNTRIES_FILTER_QUERY: "SET_COUNTRIES_FILTER_QUERY",
    SET_COUNTRIES_FILTER_ACTIVE: "SET_COUNTRIES_FILTER_ACTIVE"
  },
  ORGANIZATIONS: {
    ADD_ORGANIZATION: "ADD_ORGANIZATION",
    DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
    SET_ORGANIZATIONS_FILTER_ACTIVE: "SET_ORGANIZATIONS_FILTER_ACTIVE"
  },
  PRODUCERS: {
    SET_B2B_PRODUCERS_FILTER_ACTIVE: "SET_B2B_PRODUCERS_FILTER_ACTIVE",

    SET_B2C_PRODUCERS_FILTER_ACTIVE: "SET_B2C_PRODUCERS_FILTER_ACTIVE",
    ADD_B2C_PRODUCER: "ADD_B2C_PRODUCER",
    DELETE_B2C_PRODUCER: "DELETE_B2C_PRODUCER"
  },
  BRANCHES: {
    ADD_BRANCH: "ADD_BRANCH",
    DELETE_BRANCH: "DELETE_BRANCH",
    SET_BRANCHES_FILTER_ACTIVE: "SET_BRANCHES_FILTER_ACTIVE"
  },
  CATEGORIES_B2C: {
    ADD_B2C_CATEGORY: "ADD_B2C_CATEGORY",
    DELETE_B2C_CATEGORY: "DELETE_B2C_CATEGORY",
    SET_B2C_CATEGORIES_FILTER_ACTIVE: "SET_B2C_CATEGORIES_FILTER_ACTIVE"
  },
  CATEGORIES_B2B: {
    ADD_B2B_CATEGORY: "ADD_B2B_CATEGORY",
    DELETE_B2B_CATEGORY: "DELETE_B2B_CATEGORY",
    SET_B2B_CATEGORIES_FILTER_ACTIVE: "SET_B2B_CATEGORIES_FILTER_ACTIVE"
  },
  RESET_FILTER: "RESET_FILTER"
};
