import React, { useState } from "react";
import { Link } from "react-router-dom";

import Editor from "../editor/editor.component";

import "./event-card.styles.scss";

const EventCard = ({
  country,
  organization,
  title,
  description,
  publicated = false,
  publicatedDate,
  publicatedCompany,
  company = false,
  companyBranch,
  companyOrganization,
  cost = false,
  constQuantity,
  img = false,
  imgSrc,
  editorState,
  editLink,
  removeFunc,
  viewLiveUrl = "#"
}) => {
  const [editor, setEditor] = useState(false);

  return (
    <div
      onMouseEnter={() => setEditor(true)}
      onMouseLeave={() => setEditor(false)}
      className="event-card"
    >
      {img && (
        <div className="event-card__img">
          <div>
            <img src={imgSrc} alt="event" />
          </div>
        </div>
      )}
      <div className="event-card__line-height">
        {country && <span className="event-card__country">{country}</span>}
        {organization && (
          <span className="event-card__country">{organization}</span>
        )}
        <div className="event-card__title">
          <Link to={viewLiveUrl}>{title}</Link>
        </div>
        <div className="event-card__description">{description}</div>
        {cost && (
          <div className="event-card__project-cost">
            Project cost: <span>{constQuantity}</span>
          </div>
        )}

        {company && (
          <div className="event-card__comp">
            <span className="event-card__comp__branch">
              Branch: <span>{companyBranch}</span>
            </span>
            <span className="event-card__comp__organization">
              Organization: <span>{companyOrganization}</span>
            </span>
          </div>
        )}

        {publicated && (
          <div className="event-card__publish">
            <span className="event-card__publish__date">
              Publicated: {publicatedDate}
            </span>
            {!company && publicatedCompany && (
              <span>
                Organization:{" "}
                <span className="event-card__publish__company">
                  {publicatedCompany}
                </span>
              </span>
            )}
          </div>
        )}
      </div>
      {editorState && (
        <div className="event-card__editor">
          {editor && <Editor editLink={editLink} removeFunc={removeFunc} />}
        </div>
      )}
    </div>
  );
};

export default EventCard;
