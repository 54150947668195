export default function productsCategoriesLinksComponent({
  editor,
  api,
  site
}) {
  const script = function() {
    function setLoading(wrapperEl, state) {
      if (state) {
        wrapperEl.classList.add("products-categories-links--preloader");
      } else {
        wrapperEl.classList.remove("products-categories-links--preloader");
      }
    }

    function render(type, category, productsPath, loading) {
      const wrapperEl = this;
      setLoading(wrapperEl, loading);

      wrapperEl.innerHTML = `
        <ul class="products-categories-links__list">
            <li
              class="products-categories-links__item products-categories-links__item--primary"
            >
              <a
                class="products-categories-links__link products-categories-links__link--primary"
                href="${productsPath}?gjs-products-category=${
        category.id
      }&gjs-products-category-parent=${category.parent || category.id}"
              >
                ${category.title}
              </a>
            </li>
            ${
              !category.parent
                ? `
            <li class="products-categories-links__list-wrapper">
              <ul class="products-categories-links__list products-categories-links__list--subcategories">
              
                ${category.children &&
                  category.children.reduce(
                    (tmp, item) =>
                      tmp +
                      `
                  <li
                    class="products-categories-links__item products-categories-links__item--subcategory"
                  >
                    <a
                      class="products-categories-links__link products-categories-links__link--subcategory"
                      href="${productsPath}?gjs-products-category=${item.id}&gjs-products-category-parent=${category.id}"
                    >
                      ${item.title}
                    </a>
                  </li>
                `,
                    ""
                  )}

              </ul>
            </li>
          </ul>`
                : ""
            }
      `;
    }

    const rand = Math.random()
      .toString()
      .slice(-10);
    const globalPropertyName = `gjs_products_categories_links_id${rand}`;
    if (!window.hasOwnProperty(globalPropertyName)) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window[globalPropertyName] = {
        id: rand,
        API_ROOT: API_ROOT,
        renderCycle: [],
        type: "b2c",
        params: {},
        organization: organization,
        categoryTrait: {},
        category: {},
        loading: false,
        productsPath: "#",
        render() {
          console.group(`[id: ${this.id}] products categories links render`);
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(this.type, this.category, this.productsPath, this.loading)
          );
          console.log("render after new cycle", this);
          console.groupEnd(`[id: ${this.id}] products categories links render`);
        },
        isFuncInRenderCycle(newFunc) {
          return !this.renderCycle.find(func => func === newFunc);
        },
        appendFuncToRenderCycle(newFunc) {
          if (this.isFuncInRenderCycle(newFunc)) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setProductsPath(path) {
          this.setProp("productsPath", path);
        },
        setCategoryTrait(categoryTrait) {
          const category = JSON.parse(categoryTrait);
          this.setProp(
            "categoryTrait",
            Object.assign({}, category, {
              parent: parseInt(category.parent) || null
            })
          );
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getCategory() {
          if (this.loading) return;
          this.setLoading(true);

          console.log("categoryParent:", this);

          return fetch(
            `${this.API_ROOT}/${this.type}/category_parent/?company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(categories => {
              return fetch(
                `${this.API_ROOT}/${this.type}/category/?company=${this.organization}`,
                {
                  method: "GET",
                  headers: {
                    "Accept-Language": localStorage.getItem("language")
                  }
                }
              )
                .then(response => response.json())
                .then(subcategories =>
                  categories
                    .map(category => ({
                      id: category.id,
                      title: category.name,
                      parent: null,
                      children: subcategories
                        .filter(
                          subcategory => subcategory.parent === category.id
                        )
                        .map(subcategory => ({
                          id: subcategory.id,
                          title: subcategory.name,
                          parent: subcategory.parent
                        }))
                    }))
                    .find(category => category.id === this.categoryTrait.id)
                )
                .then(category => this.setCategory(category));
            });
        },
        getSubcategory() {
          return fetch(
            `${this.API_ROOT}/${this.type}/category/?company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(subcategories =>
              subcategories
                .map(subcategory => ({
                  id: subcategory.id,
                  title: subcategory.name,
                  parent: subcategory.parent
                }))
                .find(subcategory => subcategory.id === this.categoryTrait.id)
            )
            .then(subcategory => this.setCategory(subcategory));
        },
        setCategory(category) {
          // Reset page
          this.page = 1;
          // Set categories
          const sortByAlphabet = field => (a, b) =>
            a[field] > b[field] ? 1 : -1;
          this.category =
            category && category.hasOwnProperty("parent") && category.parent
              ? category
              : Object.assign({}, category, {
                  children:
                    category &&
                    category.children &&
                    Object.keys(category.children).length
                      ? category.children.sort(sortByAlphabet("title"))
                      : {}
                });
          // Start render cycle
          this.setLoading(false);
          this.render();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty(globalPropertyName) &&
      window.hasOwnProperty("grapesjs")
    ) {
      window[globalPropertyName].appendFuncToRenderCycle(render.bind(this));

      if (attributes.producttype) {
        window[globalPropertyName].setType(attributes.producttype.value);
      }

      if (attributes.hasOwnProperty("organizationonly")) {
        window[globalPropertyName].setParam("company", "{[ organization ]}");
      }

      if (attributes.perpage) {
        window[globalPropertyName].setPerPage(attributes.perpage.value);
      }

      if (attributes.productspage) {
        window[globalPropertyName].setProductsPath(
          attributes.productspage.value
        );
      }

      if (attributes.categorytrait) {
        window[globalPropertyName].setCategoryTrait(
          attributes.categorytrait.value
        );

        window[globalPropertyName].categoryTrait.parent
          ? window[globalPropertyName].getSubcategory()
          : window[globalPropertyName].getCategory();
      }
    }
  };

  editor.DomComponents.addType("products-categories-links", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-categories-links",
    model: {
      defaults: {
        name: "Products Categories Links",
        apiRoot: api.API_ROOT,
        organization: (site && site.organization) || "",
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Products With Pagination Page",
            name: "productspage",
            modalTitle: "Select products list page"
          },
          {
            type: "products-category-selector",
            label: "Category",
            name: "categorytrait",
            modalTitle: "Select category"
          }
        ],
        script
      }
    }
  });
}
