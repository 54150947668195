export default function productCarouselComponent({ editor, api, site }) {
  const script = function() {
    const rand = Math.random()
      .toString()
      .slice(-10);
    const globalPropertyName = `gjs_products_categories_links_id${rand}`;

    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function calculatePrice(cost = 0, discountPercent = 0, couponPercent = 0) {
      const finnalyDiscountPercent = couponPercent || discountPercent;
      if (!finnalyDiscountPercent) return parseFloat(cost);

      return parseFloat(
        cost - (parseFloat(cost) / 100) * parseFloat(finnalyDiscountPercent)
      );
    }

    function makeProductHtml(
      id,
      src,
      title,
      cost,
      currency,
      discount_percent,
      coupon_discount_percent,
      detailsPath,
      type,
      translations
    ) {
      return `
      <div class="pc-product">
        <a class="pc-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
          <img
            class="pc-product__image"
            src="${src}"
            loading="lazy"
            onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
          />
        </a>
        <div class="pc-product__main">
          <h4 class="pc-product__title">
            <a class="pc-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
              ${title}
            </a>
          </h4>
          <p class="pc-product__meta pc-product__meta--cost">
          ${
            currency && cost && parseFloat(cost) > 0
              ? `
                <span class="pc-product__cost">
                ${calculatePrice(
                  cost,
                  discount_percent,
                  coupon_discount_percent
                ).toFixed(2)}
                </span>
                <span class="pc-product__currency">
                  ${translations[currency]}
                </span>
                ${
                  discount_percent || coupon_discount_percent
                    ? `
                <span class="pc-product__percent-discount">
                  -${(discount_percent || coupon_discount_percent).toFixed(2)}%
                </span>
                `
                    : ""
                }
              `
              : `
              <span class="pc-product__no-price">
                ${translations["N/A"]}
              </span>
              `
          }
          </p>
          <a class="pc-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
            <button class="pc-product__button">
              ${translations["VIEW"]}
            </button>
          </a>
        </div>
      </div>
      `;
    }

    function setLoading(wrapperEl, state) {
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("product-carousel-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("product-carousel-wrapper--preloader");
      }
    }

    function render(
      type,
      total,
      page,
      perPage,
      products,
      detailsPath,
      loading,
      sliderParams,
      translations
    ) {
      const wrapperEl = this;
      setLoading(wrapperEl, loading);

      const id = this.id;
      const className = this.className;
      const wrapperSelector = `.${className.split(" ").join(".")}#${id}`;

      if (window[globalPropertyName].slider) {
        return;
      }

      const swiperSlidersEl = wrapperEl.querySelector(".swiper-wrapper");
      if (products.length) {
        const slidesHtml = products
          .reduce((tmp, item, index) => {
            const chunk = Math.floor(index / perPage);
            tmp[chunk] = [].concat(tmp[chunk] || [], item);
            return tmp;
          }, [])
          .reduce(
            (tmp, products) => `
            ${tmp}
            <div class="swiper-slide">
              <div class="product-carousel__grid" style="width: 100%;">
              ${products
                .map(product =>
                  makeProductHtml(
                    product.id,
                    product.image,
                    product.name,
                    product.cost,
                    product.currency,
                    product.discount_percent,
                    product.coupon_discount_percent,
                    detailsPath,
                    type,
                    translations
                  )
                )
                .join("")}
              </div>
            </div>
          `,
            ""
          );

        swiperSlidersEl.innerHTML = slidesHtml;
      } else {
        swiperSlidersEl.innerHTML = `
          <div class="swiper-slide">
            <div class="product-carousel__grid">
              <p style="margin: 0">
                <span class="product-carousel__empty">
                  ${translations["No products..."]}
                </span>
              </p>
            </div>
          </div>
        `;
        return;
      }
      // eslint-disable-next-line no-undef
      window[globalPropertyName].slider = new Swiper(
        `${wrapperSelector} .swiper-container`,
        Object.assign(
          {},
          {
            autoHeight: true,
            slidesPerView: "auto",
            pagination: {
              el: `${wrapperSelector} .swiper-pagination`,
              clickable: true
            },
            navigation: {
              nextEl: `${wrapperSelector} .swiper-button-next`,
              prevEl: `${wrapperSelector} .swiper-button-prev`
            }
          },
          sliderParams
        )
      );
    }

    if (!window.hasOwnProperty(globalPropertyName)) {
      const API_ROOT = "{[ apiRoot ]}";
      const STATIC_ROOT = "{[ staticRoot ]}";
      const organization = "{[ organization ]}";

      window[globalPropertyName] = {
        id: rand,
        API_ROOT: API_ROOT,
        STATIC_ROOT: STATIC_ROOT,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 6,
        type: "b2c",
        params: {
          company: organization
        },
        items: [],
        loading: false,
        translations: {},
        detailsPath: "#",
        slider: null,
        sliderParams: {},
        render() {
          const groupName = `[id: ${this.id}] product carousel render`;
          console.group(groupName);
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.type,
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.detailsPath,
              this.loading,
              this.sliderParams,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd(groupName);
        },
        isFuncInRenderCycle(newFunc) {
          return !this.renderCycle.find(func => func === newFunc);
        },
        appendFuncToRenderCycle(newFunc) {
          if (this.isFuncInRenderCycle(newFunc)) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setSliderParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp(
            "sliderParams",
            Object.assign({}, this.sliderParams, object)
          );
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", page);
        },
        setCount(count) {
          this.setProp("count", parseInt(count));
        },
        setPerPage(count) {
          this.setProp("perPage", parseInt(count));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path);
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getProducts(immidiately) {
          if (this.loading && !immidiately) return;
          this.setLoading(true);

          const params = new URLSearchParams(this.params);

          return fetch(`${this.API_ROOT}/${this.type}/product/?${params}`, {
            method: "GET",
            credentials: "omit",
            headers: {
              "Accept-Language": localStorage.getItem("language")
            },
            mode: "cors"
          })
            .then(response => response.json())
            .then(response => this.setProducts(response.results));
        },
        setProducts(items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.setLoading(false);
            this.render();
          }
          // Set items
          this.items = items;
          // Start render cycle
          this.setLoading(false);
          this.render();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty(globalPropertyName) &&
      window.hasOwnProperty("grapesjs")
    ) {
      const appendFunc = () => {
        if (attributes.producttype) {
          window[globalPropertyName].setType(attributes.producttype.value);
        }

        if (attributes.perpage) {
          window[globalPropertyName].setPerPage(attributes.perpage.value);
        }

        if (attributes.detailspage) {
          window[globalPropertyName].setDetailsPath(
            attributes.detailspage.value
          );
        }

        if (attributes.werecommended) {
          window[globalPropertyName].setParam("is_recommended", "True");
        }

        if (attributes.withdiscount) {
          window[globalPropertyName].setParam("discount_percent__gt", 0);
        }

        if (attributes.withcoupon) {
          window[globalPropertyName].setParam("coupon_discount_percent__gt", 0);
        }

        if (attributes.categorytrait) {
          const selectedCategory = JSON.parse(attributes.categorytrait.value);
          window[globalPropertyName].setParam(
            "categories",
            selectedCategory.id
          );
        }

        if (attributes.ordering) {
          window[globalPropertyName].setParam(
            "ordering",
            attributes.ordering.value
          );
        }

        if (attributes.autoplay) {
          window[globalPropertyName].setSliderParam("autoplay", true);
        }

        if (attributes.loop) {
          window[globalPropertyName].setSliderParam("loop", true);
        }

        if (attributes.effect) {
          window[globalPropertyName].setSliderParam(
            "effect",
            attributes.effect.value || "slide"
          );
        }

        const count = attributes.count ? attributes.count.value : 60;
        window[globalPropertyName].setCount(count);
        window[globalPropertyName].setParam("page_size", count);

        const translations = getTranslate("translations", attributes);
        window[globalPropertyName].setProp("translations", translations);

        window[globalPropertyName].appendFuncToRenderCycle(render.bind(this));
        window[globalPropertyName].getProducts(true);
      };

      if (typeof Swiper === "undefined") {
        const script = document.createElement("script");
        script.src = `//b24online.com/libs/swiper/swiper-bundle.min.js`;
        document.body.append(script);
        script.addEventListener("load", appendFunc);
      }
    }
  };

  editor.DomComponents.addType("product-carousel", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "product-carousel-wrapper",
    model: {
      defaults: {
        tagName: "div",
        name: "Product Carousel",
        apiRoot: api.API_ROOT,
        staticRoot: api.STATIC_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "number",
            label: "Products per page",
            name: "perpage",
            placeholder: "6"
          },
          {
            type: "number",
            label: "Total products in slider",
            name: "count",
            placeholder: "60"
          },
          {
            type: "products-category-selector",
            label: "Category",
            name: "categorytrait",
            modalTitle: "Select category"
          },
          {
            type: "select",
            label: "Order by",
            name: "ordering",
            options: [
              { id: "-id", name: "Newest" },
              { id: "id", name: "Oldest" },
              { id: "cost", name: "Price ascending" },
              { id: "-cost", name: "Price descending" }
            ]
          },
          {
            type: "checkbox",
            label: "Show recommended",
            name: "werecommended",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "checkbox",
            label: "With discount",
            name: "withdiscount",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "checkbox",
            label: "With coupon",
            name: "withcoupon",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "checkbox",
            label: "Autoplay",
            name: "autoplay",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "checkbox",
            label: "Loop",
            name: "loop",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "select",
            label: "Effect",
            name: "effect",
            options: [
              { id: "slide", name: "Slide" },
              { id: "cube", name: "Cube" },
              { id: "flip", name: "Flip" }
            ]
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["No products...", "N/A", "VIEW", "ILS"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(this.model, "change:attributes:producttype", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });

  // Make product carousel classes private
  const productCarouselClasses = [
    ".product-carousel-wrapper",
    ".product-carousel__grid"
  ];
  editor.on(
    "selector:add",
    selector =>
      productCarouselClasses.indexOf(selector.getFullName()) >= 0 &&
      selector.set("private", 1)
  );
}
