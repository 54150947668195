import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardContent, Box } from "@material-ui/core";

import { LoadingSpinner, ForgotPasswordForm } from "@core/components";

import "./forgot-password-card.styles.scss";

const ForgotPasswordCard = () => {
  const appDirection = useSelector(state => state.appLanguage.appDirection);
  const resetPasswordPending = useSelector(state => state.authReducer.resetPasswordPending);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(resetPasswordPending);
  }, [resetPasswordPending]);

  return (
    <Card className={`forgot-password-card ${appDirection}`}>
      <CardHeader
        title={<Box textAlign="center">{t("Forgot password?")}</Box>}
      />
      <CardContent className="forgot-password-card__main">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="forgot-password-card__body">
              <div className="forgot-password-card__body-column">
                <ForgotPasswordForm />
              </div>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ForgotPasswordCard;