import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Collapsible from "react-collapsible";
import ExpandArrow from "../expand-arrow/expand-arrow.component";
import { useTranslation } from "react-i18next"
import { scrollToRef } from "@core/shared";
import IframeResizer from "iframe-resizer-react";

import "./product-request-form-styles.scss";

const DropdownFormTrigger = ({
  triggerTitle,
  dropdownState,
  onClickHandle
}) => {
  return (
    <div className="form-dropdown__trigger" onClick={onClickHandle}>
      <div className="form-dropdown__trigger-title">{triggerTitle}</div>
      <div className="form-dropdown__trigger-icon">
        <ExpandArrow expand={dropdownState} />
      </div>
    </div>
  );
};

const ProductRequestForm = ({ product, formOpen, defaultLeadForm }) => {
  const profile = useSelector(state => state.profileAPI.profile);

  // eslint-disable-next-line
  const [email, setEmail] = useState(
    profile?.user_email ? profile.user_email : ""
  );

  const leadFormId = product?.metadata?.lead_form_id;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const formRef = useRef(null);
  const iframeRef = useRef(null);
    const {t} = useTranslation();
  useEffect(() => {
    if (formOpen) {
      scrollToRef(formRef.current.offsetTop);
      setDropdownOpen(true);
    }
  }, [formOpen]);

  let query = `?source=portal&url=${window.location.href}`;
  query += email ? `&user=${email}` : "";
  query +=
    localStorage && localStorage?.getItem("i18nextLng")
      ? `&lang=${localStorage.getItem("i18nextLng").toLowerCase()}`
      : "";

  if (product?.id) {
    query += product?.id ? `&productId=${product?.id}` : "";
    query += product?.name ? `&productName=${encodeURIComponent(product?.name)}` : "";
  }

  if (product?.categories && product?.categories?.length) {
    query += `&categoryId=${product.categories
      .map(category => category.id)
      .join(",")}`;

    query += `&categoryName=${encodeURIComponent(product.categories
      .map(category => category.name)
      .join(","))}`;
  }

  if (product?.company || product?.organization) {
    query += `&merchantId=${product?.company?.id || product?.organization?.id}`;
    query += `&merchantName=${encodeURIComponent(product?.company?.name || product?.organization?.name)}`;
  }

  if (process.env.REACT_APP_WIDGET_TOKEN) {
    query += `&token=${process.env.REACT_APP_WIDGET_TOKEN}`
  }

  return (
    <div className="product-form__wrapper-collapsible" ref={formRef}>
      <Collapsible
        easing="none"
        triggerDisabled={true}
        open={true}
        trigger={
          <DropdownFormTrigger
            dropdownState={dropdownOpen}
            triggerTitle={t("Send a request")}
            onClickHandle={() => setDropdownOpen(!dropdownOpen)}
          />
        }
      >
        {dropdownOpen && (
          <div className="productrequest-form__wrapper">
            <div className="productrequest-form__container">
              <IframeResizer
                id="lead_iframe"
                forwardRef={iframeRef}
                heightCalculationMethod="lowestElement"
                inPageLinks
                src={`//share.b24chat.com/lead/${leadFormId || defaultLeadForm}${query}`}
                style={{ width: "1px", minWidth: "100%", border: "none" }}
              />
            </div>
          </div>
        )}
      </Collapsible>
    </div>
  );
};

export default ProductRequestForm;
