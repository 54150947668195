import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

import { b24URL } from "../../constants";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";

import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Favorite as FavoriteIcon
} from "@material-ui/icons";

import { missingImage, missingProductImageBigger } from "../../assets";
import { handleMouseMove } from "../../shared";

import {
  DropdownShipping,
  DefaultButton,
  QunatityInput,
  DefaultSnackbar,
  ExpandArrow,
  DefaultInputRadio
} from "../../components";

import { colorPalette } from "../../styles/colorPalette";
import "./product-description-card.styles.scss";

const ProductDescriptionCard = ({
  activeProduct,
  productId,
  currentProductData,
  companiesByUserId,
  productImage,
  productName,
  productShortDescription,
  productPrice,
  productPriceDiscount,
  rules,
  productCurrency,
  companyName,
  isDigitalProduct,
  areMoreImages = false,
  isProfilePending,
  productCompanyId,
  addToCart,
  addProductToWishList,
  existsInCart,
  existsInWishList,
  setExistsInCart,
  setExistsInWishList,
  itemSizes,
  shippingByCompany,
  appliedMethod,
  setAppliedMethod,
  productVariationsByParent,
  setProductVariation,
  getParent
}) => {
  const [productOptions, setProductOptions] = useState([]);
  const { productType } = useParams();
  let b2bProductId = "";
  let b2cProductId = "";

  if (productType === "b2b") {
    b2bProductId = productId;
  } else if (productType === "b2c") {
    b2cProductId = productId;
  }

  const { pathname } = useLocation();

  const [expand, setExpand] = useState(false);

  const onExpand = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  const [showCompany, setShowCompanies] = useState(false);
  const [customerType, setCustomerType] = useState("person");
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();
  const customProductMessages = [
    { id: 1, message: "Succesfuly added to cart!" },
    { id: 2, message: "Succesfuly added to wishlist!" }
  ];

  const [organizationName, setOrganizationName] = useState(
    "Choose Organization"
  );

  const [quantity, setQuantity] = useState(1);
  const [companyId, setCompanyId] = useState("");
  const [snackMessage, setSnackMessage] = useState("");

  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");

  const addToCartHandler = () => {
    const args = [
      customerType,
      companyId,
      b2bProductId,
      b2cProductId,
      quantity,
      productOptions
    ];

    appliedMethod.id
      ? addToCart(...args, appliedMethod.id)
      : addToCart(...args);

    setSnackMessage(customProductMessages[0].message);
    setShowMessage(true);
    setExistsInCart(true);
    setTimeout(() => {
      setShowMessage(false);
      setExistsInCart(false);
    }, 2000);
  };

  const addToWishListHandler = () => {
    addProductToWishList(
      productType === "b2b" ? "item_b2b" : "item_b2c",
      currentProductData
    );
    setExistsInWishList(true);
    setSnackMessage(customProductMessages[1].message);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  const imgStyles = {
    backgroundPosition,
    backgroundImage: `url(${productImage || missingImage})`,
    pointerEvents: !productImage && "none"
  };

  const addOptionsHandler = option => {
    productOptions.find(item => item === option)
      ? setProductOptions(productOptions.filter(item => item !== option))
      : setProductOptions([...productOptions, option]);
  };

  const { discount_percent, coupon_discount_percent, metadata } = activeProduct;

  return (
    <div className="product-description-card">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="success"
          message={snackMessage}
          autoHideDuration={2000}
        />
      )}
      {!!(coupon_discount_percent || discount_percent) && (
        <div className="product-description-card__discount">
          -{coupon_discount_percent || discount_percent}%
        </div>
      )}
      <div className="product-description-card__img">
        <div className="product-description-card__img__large">
          <figure
            style={imgStyles}
            onMouseMove={e => handleMouseMove(e, setBackgroundPosition)}
          >
            <img src={productImage || missingImage} alt={productName} />
          </figure>
        </div>

        {currentProductData && currentProductData.id !== Number(productId) ? (
          <div
            onClick={() => getParent(productId)}
            className="product-description-card__img__return-parent"
          >
            return to parent product
          </div>
        ) : null}

        <div className="product-description-card__img__flex">
          {productVariationsByParent.results &&
            productVariationsByParent.results.map((variation, index) => {
              const { image } = variation;
              return (
                <div
                  key={index}
                  onClick={() => setProductVariation(variation)}
                  className="product-description-card__img__small"
                >
                  <img
                    src={image ? image : missingProductImageBigger}
                    alt={productName}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="product-description-card__separator" />
      <div className="product-description-card__description">
        <div className="product-description-card__description__title">
          {productName}
        </div>
        {productPrice && productPrice > 0 && (
          <div className="product-description-card__description__price">
            product price:{" "}
            {productPriceDiscount > 0 ? (
              <span className="product-description-card__description__price__quantity product-description-card__description__price__quantity_special">
                <span className="product-description-card__price">
                  {productPriceDiscount + " " + productCurrency}
                </span>
                <span className="product-description-card__price_old">
                  {productPrice + " " + productCurrency}
                </span>
              </span>
            ) : (
              <span className="product-description-card__description__price__quantity">
                {productPrice + " " + productCurrency}
              </span>
            )}
          </div>
        )}

        {rules &&
          !!rules.length &&
          rules.map(rule => (
            <div className="product-description-card__rule">
              {rule?.condition?.type === "discount"
                ? `Buy this product with ${rule.condition?.value}% discount`
                : rule?.condition?.type === "price"
                ? `Buy ${rule.condition?.amount} for ${rule.condition?.value} ${productCurrency}`
                : ""}
            </div>
          ))}
        {metadata?.stock_keeping_unit && (
          <div className="product-description-card__description__sku">
            sku:{" "}
            <span className="product-description-card__description__sku__value">
              {metadata.stock_keeping_unit}
            </span>
          </div>
        )}

        {companiesByUserId.length > 0 && productType === "b2b" && (
          <div className="product-description-card__description__customer-type">
            <div className="product-description-card__description__customer-type__radios">
              <div className="product-description-card__description__title">
                Customer type:{" "}
              </div>
              <DefaultInputRadio
                onChange={e => {
                  setShowCompanies(false);
                  setCustomerType(e.target.value);
                }}
                id="customer-type-person"
                name="customer-type"
                value="person"
                checked={customerType === "person"}
                labelText="Person"
              />

              <DefaultInputRadio
                onChange={e => {
                  setShowCompanies(true);
                  setCustomerType(e.target.value);
                }}
                type="radio"
                id="customer-type-organization"
                name="customer-type"
                value="organization"
                labelText="Company"
              />
            </div>
            {showCompany && (
              <Accordion
                allowZeroExpanded
                className="product-description-card__accordion"
              >
                <AccordionItem className="product-description-card__accordion__item">
                  <AccordionItemHeading
                    onClick={onExpand}
                    className="product-description-card__accordion__item__heading"
                  >
                    <AccordionItemButton className="product-description-card__accordion__item__heading__button">
                      <div>{organizationName}</div>
                      <div className="product-description-card__accordion__item__heading__button__icon">
                        <ExpandArrow expand={expand} />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="product-description-card__accordion__item__panel">
                    {companiesByUserId.map(({ name, id }) => (
                      <div
                        key={id}
                        onClick={e => {
                          setOrganizationName(e.currentTarget.innerHTML);
                          setCompanyId(id);
                        }}
                        className="product-description-card__accordion__item__panel__organization"
                      >
                        {name}
                      </div>
                    ))}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        )}

        <div className="product-description-card__description__control">
          <div className="product-description-card__description__control__quantity">
            <QunatityInput setQuantityFunc={setQuantity} />
          </div>
          <div className="product-description-card__description__control__basket">
            <DefaultButton
              border={`1px solid ${colorPalette.colorPrimary.main}`}
              color={colorPalette.colorPrimary.main}
              fontWeight="bold"
              padding="10px 30px"
              {...(existsInCart
                ? {
                    disabled: true
                  }
                : {
                    onClick: addToCartHandler,
                    ButtonOptions: {
                      disabled: isProfilePending
                    }
                  })}
            >
              {existsInCart ? t("Added to cart") : t("Add to cart")}
            </DefaultButton>
          </div>

          <div className="product-description-card__description__control__like">
            <DefaultButton
              borderRadius="50%"
              {...(!existsInWishList && { onClick: addToWishListHandler })}
            >
              <FavoriteIcon
                style={{
                  fill: existsInWishList
                    ? colorPalette.colorPrimary.main
                    : "#b2b2b2"
                }}
              />
            </DefaultButton>
          </div>
        </div>
        <div style={{ color: "#ff7700" }}>
          {isProfilePending &&
            t("You must login to be able to order this item")}
        </div>
        {!isDigitalProduct && shippingByCompany.count > 0 && (
          <DropdownShipping
            shippingByCompany={shippingByCompany}
            appliedMethod={appliedMethod}
            setAppliedMethod={setAppliedMethod}
          />
        )}

        {currentProductData && productVariationsByParent.results.length ? (
          <div className="product-variations-items">
            <div className="product-variations-items__title">Color:</div>
            {productVariationsByParent.results &&
              productVariationsByParent.results.map((variation, index) => {
                const { color } = variation;
                const currentColor =
                  currentProductData.color === color
                    ? " product-variations-items__current"
                    : "";
                return (
                  <div
                    key={index}
                    className={`product-variations-items__single-color${currentColor}`}
                    onClick={() => setProductVariation(variation)}
                    style={{
                      backgroundColor: color
                    }}
                  />
                );
              })}
          </div>
        ) : null}

        {currentProductData && productVariationsByParent.results.length ? (
          <div className="product-variations-items">
            <div className="product-variations-items__title">Size:</div>
            {productVariationsByParent.results &&
              productVariationsByParent.results.map((variation, index) => {
                const { size } = variation;
                const currentSize =
                  currentProductData.size === size
                    ? " product-variations-items__current"
                    : "";
                return (
                  <div
                    key={index}
                    className={`product-variations-items__single-size-border${currentSize}`}
                    onClick={() => setProductVariation(variation)}
                  >
                    {size || "missing size"}
                  </div>
                );
              })}
          </div>
        ) : null}

        {currentProductData.additional_parameters && (
          <div className="product-description-card__product-options">
            {currentProductData.additional_parameters.map((el, index) => {
              let productExist = productOptions.find(
                option => option === el.id
              );
              return (
                <div
                  key={index}
                  onClick={() => addOptionsHandler(el.id)}
                  className="product-option"
                >
                  <div className="product-option__check">
                    <div
                      className={`check-div ${
                        productExist ? "checked-yes" : "checked-not"
                      }`}
                    />
                  </div>
                  <div className="product-option__name">{el.title}</div>
                  <div className="product-option__price">{`+ ${el.extra_cost} ${currentProductData.currency}`}</div>
                </div>
              );
            })}
          </div>
        )}

        <div className="product-description-card__description__about">
          {productShortDescription}
        </div>
        <div className="product-description-card__description__company">
          Company:{" "}
          <span className="product-description-card__description__company__name">
            <Link to={`/companies/${productCompanyId}`}>{companyName}</Link>
          </span>
        </div>
        <div className="product-multiple-share">
          <LinkedinShareButton url={`${b24URL}${pathname}`}>
            <TwitterIcon className="product-multiple-share__twitter" />
          </LinkedinShareButton>
          <FacebookShareButton url={`${b24URL}${pathname}`}>
            <FacebookIcon
              className="product-multiple-share__facebook"
              color="#3b5998"
            />
          </FacebookShareButton>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionCard;
