import React from "react";

import { randomInt } from "../../shared";

import "./default-checkbox.styles.scss";

const DefaultCheckbox = ({ title, checkboxValue, setCheckboxChange }) => {
  const id = `styled-checkbox-${randomInt().toString()}`;

  return (
    <div className="default-checkbox-container">
      <input
        id={id}
        type="checkbox"
        className="styled-checkbox"
        checked={checkboxValue}
        onChange={() => setCheckboxChange(!checkboxValue)}
      />
      <label htmlFor={id} />
      <div className="default-checkbox-container__title">{title}</div>
    </div>
  );
};

export default DefaultCheckbox;
