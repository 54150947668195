import { ProducersActionTypes } from "./producers.types";

const INITIAL_STATE = {
  isProducersSearchPending: false,
  producersSearchData: {},
  producersSearchResults: [],
  producersSearchError: null,

  areB2CProducersPending: false,
  B2CProducers: [],
  B2CProducersError: {}
};

const ProducersReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProducersActionTypes.SEARCH.SEARCH_PRODUCERS_PENDING:
      return { ...state, isProducersSearchPending: true };

    case ProducersActionTypes.SEARCH.SEARCH_PRODUCERS_SUCCESS:
      return {
        ...state,
        isProducersSearchPending: false,
        producersSearchData: action.payload.data,
        producersSearchResults: action.payload.data,
        producersSearchError: null
      };

    case ProducersActionTypes.SEARCH.SEARCH_PRODUCERS_ERROR:
      return {
        ...state,
        isProducersSearchPending: false,
        producersSearchError: action.payload
      };

    case ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_PENDING:
      return {
        ...state,
        areB2CProducersPending: true
      };

    case ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_SUCCESS:
      return {
        ...state,
        B2CProducers: action.payload,
        areB2CProducersPending: false
      };

    case ProducersActionTypes.GET.PRODUCERS.GET_B2C_PRODUCERS_ERROR:
      return {
        ...state,
        B2CProducersError: action.payload,
        areB2CProducersPending: false
      };
    default:
      return state;
  }
};

export default ProducersReducer;
