import { UtilsActionTypes } from "./utils.types";

const INITIAL_STATE = {
  areCurrenciesPending: false,
  currencies: [],
  currenciesError: null,

  areMeasurementsPending: false,
  measurements: [],
  measurementsError: null
};

const UtilsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UtilsActionTypes.GET.CURRENCIES.GET_CURRENCIES_PENDING:
      return { ...state, areCurrenciesPending: true };

    case UtilsActionTypes.GET.CURRENCIES.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        areCurrenciesPending: false,
        currencies: action.payload,
        currenciesError: null
      };

    case UtilsActionTypes.GET.CURRENCIES.GET_CURRENCIES_ERROR:
      return {
        ...state,
        areCurrenciesPending: false,
        currenciesError: action.payload
      };

    case UtilsActionTypes.GET.MEASUREMENTS.GET_MEASUREMENTS_PENDING:
      return { ...state, areMeasurementsPending: true };

    case UtilsActionTypes.GET.MEASUREMENTS.GET_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        areMeasurementsPending: false,
        measurements: action.payload,
        measurementsError: null
      };

    case UtilsActionTypes.GET.MEASUREMENTS.GET_MEASUREMENTS_ERROR:
      return {
        ...state,
        areMeasurementsPending: false,
        measurementsError: action.payload
      };

    default:
      return state;
  }
};

export default UtilsReducer;
