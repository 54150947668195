import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { DefaultSnackbar, Orders, Pagination } from "../../components";

import {
  addToCart,
  deleteDeal,
  getOrderedItems,
  removeDealById
} from "../../redux/actions-exporter";

import "./my-person-orders.styles.scss";

const MyPersonOrders = ({
  getOrderedItems,
  orderDeals,
  orderCount,
  addToCart,
  cart,
  areOrderDealsPending
}) => {
  const [ordersPage, setOrdersPage] = useState(1);
  const [showMessage, setShowMessage] = useState(false);

  const addToCartRepeat = (id, contentType) => {
    let b2bProductId = "";
    let b2cProductId = "";
    if (contentType === 84) {
      b2bProductId = id;
    } else {
      b2cProductId = id;
    }
    addToCart("person", "", b2bProductId, b2cProductId, "1");
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  useEffect(() => {
    getOrderedItems("person", ordersPage);
  }, [getOrderedItems, ordersPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ordersPage]);

  return (
    <div className="my-person-orders">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="success"
          message="Succesfully added to cart!"
          autoHideDuration={2000}
        />
      )}
      <div className="deals-container">
        <Orders
          areOrderDealsPending={areOrderDealsPending}
          orderDeals={orderDeals}
          addToCartRepeat={addToCartRepeat}
          cart={cart}
        />
        <Pagination
          forcePage={ordersPage - 1}
          selectedPage={page => setOrdersPage(page)}
          dataCount={orderCount}
          itemsPerPage={10}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart.cart,
  areOrderDealsPending: state.ordersReducer.areOrdersPending,
  orderDeals: state.ordersReducer.ordersItems,
  orderCount: state.ordersReducer.ordersCount
});

export default connect(mapStateToProps, {
  addToCart,
  getOrderedItems,
  removeDealById,
  deleteDeal
})(MyPersonOrders);
