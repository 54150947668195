export default function productsCategoriesMultiLevelMenuComponent({
  editor,
  api,
  site
}) {
  const script = function() {
    function setLoading(wrapperEl, state) {
      if (state) {
        wrapperEl.classList.add("products-categories-new-menu--preloader");
      } else {
        wrapperEl.classList.remove("products-categories-new-menu--preloader");
      }
    }

    function render(type, categories, productsPath, loading) {
      const wrapperEl = this;
      setLoading(wrapperEl, loading);

      wrapperEl.innerHTML = `
        <style>
          .products-categories-new__submenu-btn {
            display: none;
          }
          .products-categories-new__submenu-item {
            text-align: right;
            margin-bottom: 5px;
          }
          .products-categories-new__submenu-link.submenu-level-1 {
            text-align: right;
          }
          .products-categories-new__innermenu {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          
          .products-categories-new__submenu-btn:before {
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 6px solid #a19898;
            content: "";
            display: block;
          }
          .products-categories-new__extramenu {
            position: absolute;
            right: 108%;
            background: #ffffff;
            top: 0;
            width: 250px;
            opacity: 0;
            visibility: hidden;
            transition: .2s;
            box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.15);
            padding: 10px;
            z-index: -1;
          }
          
          .products-categories-new__submenu-link.submenu-level-2, .products-categories-new__submenu-link.submenu-level-3 {
            color: #333333;
            font-size: 16px;
          }
          
          .products-categories-new__submenu-item.submenu-level-2:hover > .products-categories-new__extramenu {
            visibility: visible;
            opacity: 1;
            z-index: 10;
          }
          
          .products-categories-new__mobile-btn, .products-categories-new__close-btn {
            display: none;
          }
          
          @media (min-width: 1024px) {
            .products-categories-new__submenu-item.submenu-level-2 {
              position: relative;
              padding: 0 0 0 12px;
            }
            
            .products-categories-new__submenu-item.submenu-level-2 .products-categories-new__submenu-btn {
              display: block;
              position: absolute;
              left: 0;
              top: 9px;
            }
          }
          
          @media (max-width: 1023px) {
            .products-categories-new-menu__list {
              display: none;
              text-align: right;
            }
            
            .products-categories-new__innermenu {
              display: block;
            }
            
            .products-categories-new__mobile-btn {
              display: block;
              width: 30px;
              height: 30px;
            }
            
            .products-categories-new__close-btn {
              display: block;
              width: 20px;
              height: 20px;
              position: absolute;
              top: 15px;
              left: 15px;
            }
            
            .products-categories-new-menu__list.opened {
              display: block;
              position: fixed;
              width: 100%;
              height: 100vh;
              z-index: 1000;
              top: 0;
              background: #ffffff;
              left: 0;
              overflow: scroll;
              padding: 20px;
            }
            
            .products-categories-new__submenu {
              display: block;
              box-shadow: none;
              background: #ededed;
              margin: 5px 0;
            }
            
            .products-categories-new__extramenu {
              position: static;
              width: 100%;
              box-shadow: none;
              background: #ffffff;
              margin: 10px 0;
              display: none;
            }
            
            .products-categories-new__submenu-link.submenu-level-2 .products-categories-new__submenu-btn {
              margin: 0 auto 0 0;
            }
            
            .products-categories-new__submenu-btn:before {
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 13px solid #a19898;
              border-bottom: none;
            }
            
            .products-categories-new-menu__item {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              justify-content: flex-end;
              flex-wrap: wrap;
            }
            
            .products-categories-new-menu__item .products-categories-new__submenu-btn {
              display: block;
              margin: 0 auto 0 0;
            }
            
            .products-categories-new__submenu-item.submenu-level-2 {
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              flex-wrap: wrap;
            }
            
            .products-categories-new__submenu-link.submenu-level-2 {
              display: inline;
            }
            
            .products-categories-new-menu__item.menu-item.opened > .products-categories-new__submenu, .products-categories-new__submenu-item.menu-item.opened > .products-categories-new__extramenu {
              display: block;
              visibility: visible;
              opacity: 1;
              z-index: 1;
            }
          }
        </style>
        <span class="products-categories-new__mobile-btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 385 385"><path d="M12 84.2h361a12 12 0 000-24H12a12 12 0 000 24zM373 180.5H12a12 12 0 000 24h361a12 12 0 000-24zM373 300.8H12a12 12 0 000 24h361a12 12 0 000-24z"/></svg>
        </span>
        <ul class="products-categories-new-menu__list">
          <span class="products-categories-new__close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.2 241.2"><path d="M138.1 120.8l99.2-98.6a12 12 0 000-17 12.2 12.2 0 00-17.2 0l-99 98.5L21 3.7a12.1 12.1 0 00-17.2 0 12.2 12.2 0 000 17.2l99.9 100L3.6 220.4a12 12 0 000 17 12.2 12.2 0 0017.1 0L121 138l99.5 99.6a12.1 12.1 0 0017.2 0 12.2 12.2 0 000-17.3l-99.5-99.4z"/></svg>          
          </span>
        ${categories
          .map(
            category => `
            <li
              class="products-categories-new-menu__item products-categories-new-menu__item--primary menu-item"
            >
            ${
              category?.children?.length > 0
                ? `<span class="products-categories-new__submenu-btn"></span>`
                : ""
            }
              <a
                class="products-categories-new-menu__link products-categories-new-menu__link--primary"
                href="${productsPath}?gjs-products-category=${
              category.id
            }&gjs-products-category-parent=${category.parent || category.id}"
              >
              ${category.name}
              </a>
              ${
                category?.children?.length > 0
                  ? `<div class="products-categories-new__submenu mobile-menu-child">
                          ${category.children.reduce(
                            (tmp, category) =>
                              tmp +
                              `<div class="products-categories-new__submenu-item level-1 menu-item">
                                  <a class="products-categories-new__submenu-link submenu-level-1" href="${productsPath}?gjs-products-category=${
                                category.id
                              }&gjs-products-category-parent=${category.parent ||
                                category.id}">
                                    ${category.name}
                                  </a>
                                  ${
                                    category?.children?.length > 0
                                      ? `<div class="products-categories-new__innermenu mobile-menu-child">
                                          ${category.children.reduce(
                                            (tmp, category) =>
                                              tmp +
                                              `<div class="products-categories-new__submenu-item submenu-level-2 menu-item">
                                                ${
                                                  category?.children?.length > 0
                                                    ? `<span class="products-categories-new__submenu-btn"></span>`
                                                    : ""
                                                }
                                                <a class="products-categories-new__submenu-link submenu-level-2" href="${productsPath}?gjs-products-category=${
                                                category.id
                                              }&gjs-products-category-parent=${category.parent ||
                                                category.id}">
                                                    ${category.name}
                                                </a>
                                                ${
                                                  category?.children?.length > 0
                                                    ? `<div class="products-categories-new__extramenu mobile-menu-child">
                                                        ${category.children.reduce(
                                                          (tmp, category) =>
                                                            tmp +
                                                            `<div class="products-categories-new__submenu-item">
                                                              <a class="products-categories-new__submenu-link submenu-level-3" href="${productsPath}?gjs-products-category=${
                                                              category.id
                                                            }&gjs-products-category-parent=${category.parent ||
                                                              category.id}">
                                                                  ${
                                                                    category.name
                                                                  }
                                                              </a>
                                                            </div>`,
                                                          ""
                                                        )}
                                                        </div>`
                                                    : ""
                                                }
                                              </div>`,
                                            ""
                                          )}
                                          </div>`
                                      : ""
                                  }
                                </div>`,
                            ""
                          )}
                         </div>`
                  : ""
              }
            </li>`
          )
          .join("")}
          </ul>
      `;

      const mobileExpandBtns = document.querySelectorAll(
        ".products-categories-new__submenu-btn"
      );

      const mobileMenuBtn = document.querySelector(
        ".products-categories-new__mobile-btn"
      );

      const mobileMenuCloseBtn = document.querySelector(
        ".products-categories-new__close-btn"
      );

      mobileMenuBtn.addEventListener("click", function() {
        const menu = document.querySelector(
          ".products-categories-new-menu__list"
        );
        menu.classList.toggle("opened");
      });

      mobileMenuCloseBtn.addEventListener("click", function() {
        const menu = document.querySelector(
          ".products-categories-new-menu__list"
        );
        menu.classList.remove("opened");
      });

      Array.prototype.slice.call(mobileExpandBtns).map(btn => {
        btn.addEventListener("click", function() {
          btn.closest(".menu-item").classList.toggle("opened");
        });

        return btn;
      });
    }

    const rand = Math.random()
      .toString()
      .slice(-10);
    const globalPropertyName = `gjs_products_categories_menu_id${rand}`;
    if (!window.hasOwnProperty(globalPropertyName)) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window[globalPropertyName] = {
        id: rand,
        API_ROOT: API_ROOT,
        renderCycle: [],
        type: "b2c",
        params: {},
        organization: organization,
        categories: [],
        loading: false,
        productsPath: "#",
        render() {
          console.group(`[id: ${this.id}] products categories menu render`);
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(this.type, this.categories, this.productsPath, this.loading)
          );
          console.log("render after new cycle", this);
          console.groupEnd(`[id: ${this.id}] products categories menu render`);
        },
        isFuncInRenderCycle(newFunc) {
          return !this.renderCycle.find(func => func === newFunc);
        },
        appendFuncToRenderCycle(newFunc) {
          if (this.isFuncInRenderCycle(newFunc)) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setProductsPath(path) {
          this.setProp("productsPath", path);
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getCategories() {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/${this.type}/category_parent/?company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(categories => this.setCategories(categories));
        },
        setCategories(categories) {
          this.categories = categories || [];

          // Start render cycle
          this.setLoading(false);
          this.render();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty(globalPropertyName) &&
      window.hasOwnProperty("grapesjs")
    ) {
      window[globalPropertyName].appendFuncToRenderCycle(render.bind(this));

      if (attributes.producttype) {
        window[globalPropertyName].setType(attributes.producttype.value);
      }

      if (attributes.hasOwnProperty("organizationonly")) {
        window[globalPropertyName].setParam("company", "{[ organization ]}");
      }

      if (attributes.perpage) {
        window[globalPropertyName].setPerPage(attributes.perpage.value);
      }

      if (attributes.productspage) {
        window[globalPropertyName].setProductsPath(
          attributes.productspage.value
        );
      }

      window[globalPropertyName].getCategories();
    }
  };

  editor.DomComponents.addType("products-categories-new-multi-level-menu", {
    isComponent: el =>
      el.tagName === "DIV" &&
      el.className === "products-categories-new-multi-level-menu",
    model: {
      defaults: {
        name: "Products Categories Multi Level Menu",
        apiRoot: api.API_ROOT,
        organization: (site && site.organization) || "",
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Products With Pagination Page",
            name: "productspage",
            modalTitle: "Select products list page"
          }
        ],
        script
      }
    }
  });
}
