import React, { useEffect, useState, useRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import { useTranslation } from "react-i18next";
import "./search-bar.styles.scss";

const SearchBar = ({ onChange, direction }) => {
  const [recognition, setRecognition] = useState(null);
  const [isRecognition, setIsRecognition] = useState(false);
  const refInput = useRef(null);
  const { t } = useTranslation();
  const placeHolder =
    window.innerWidth > 980
      ? t(
          "Search by product name, category, manufacturer, brand, model, attribute..."
        )
      : t("Search...");

  useEffect(() => {
    if ("webkitSpeechRecognition" in window && !recognition) {
      // eslint-disable-next-line no-undef
      const recognitionInit = new webkitSpeechRecognition();
      recognitionInit.continuous = true;
      recognitionInit.interimResults = true;
      // recognitionInit.lang = 'ru-RU' TODO: детектить язык

      recognitionInit.addEventListener("result", e => {
        if (!e?.results) {
          recognition.stop();
          setIsRecognition(false);
        }

        let result = "";
        for (let i = e.resultIndex; i < e.results.length; ++i) {
          if (e.results[i].isFinal) {
            result += e.results[i][0].transcript;
            try {
              recognitionInit.stop();
            } catch (e) {}
            setIsRecognition(false);
          } else {
            result += e.results[i][0].transcript;
          }
        }

        refInput.current.value = result;
      });

      recognitionInit.addEventListener("error", e => {
        try {
          recognition.stop();
        } catch (e) {}

        setIsRecognition(false);

        if (e.error === "not-allowed") {
          navigator.mediaDevices.getUserMedia({ audio: true });
        }
      });

      setRecognition(recognitionInit);
    }
  }, [recognition, setRecognition]);

  return (
    <div
      onClick={e => e.stopPropagation()}
      className="search-bar"
      dir={direction}
    >
      <input
        ref={refInput}
        onChange={onChange}
        placeholder={placeHolder}
        className="search-bar__input"
        type="text"
      />
      {recognition && (
        <>
          {isRecognition ? (
            <MicOffIcon
              className="search-bar__icon microphone"
              style={{ fill: "green" }}
              onClick={() => {
                setIsRecognition(false);

                try {
                  recognition.stop();
                } catch (e) {}
              }}
            />
          ) : (
            <MicIcon
              className="search-bar__icon microphone"
              onClick={() => {
                setIsRecognition(true);

                try {
                  recognition.start();
                } catch (e) {}
              }}
            />
          )}
        </>
      )}
      <SearchIcon
        style={{ color: "lightgray " }}
        className="search-bar__icon"
      />
    </div>
  );
};

export default SearchBar;
