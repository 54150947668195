import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from "@material-ui/core/Card";

import { EventView1, EventView2 } from "../../assets/";
import { withDebounce, arrToString } from "../../shared";

import { Filter } from "../../containers";

import {
  EventCard,
  Pagination,
  LoadingSpinner,
  NoDataFound
} from "../../components";

import {
  getEvents,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
} from "../../redux/actions-exporter";

import "./events.styles.scss";

const Events = ({
  areEventsPending,
  events,
  eventsCount,

  getEvents,
  filterCountriesArr,
  filterOrganizationsArr,

  setCountriesFilterStatus,
  setOrganizationsFilterStatus
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [eventsPage, setEventsPage] = useState(1);

  useEffect(() => {
    setCountriesFilterStatus(true);
    setOrganizationsFilterStatus(true);
    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
    };
  }, [setCountriesFilterStatus, setOrganizationsFilterStatus]);

  useEffect(() => {
    getEvents(
      searchQuery,
      eventsPage,
      arrToString(filterCountriesArr),
      arrToString(filterOrganizationsArr)
    );
  }, [
    getEvents,
    searchQuery,
    eventsPage,
    filterCountriesArr,
    filterOrganizationsArr
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [eventsPage]);

  useEffect(() => {
    setEventsPage(1);
  }, [searchQuery, filterCountriesArr, filterOrganizationsArr]);

  const eventsFilterOnChange = withDebounce(e => setSearchQuery(e));

  return (
    <div className="events">
      <Filter onChange={e => eventsFilterOnChange(e.target.value)} />
      <div className="events__body">
        {areEventsPending ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <div>
              <div className="events__body__title">Events</div>
            </div>
            <div className="events__body__padding">
              <div className="events__body__view">
                <p>View:</p>
                <EventView1 className="events__body__view__block" />
                <EventView2 />
              </div>
              {events &&
                events.map(
                  ({
                    id,
                    title,
                    description,
                    country,
                    organization,
                    created_at
                  }) => (
                    <EventCard
                      key={id}
                      img={true}
                      imgSrc={organization.logo}
                      country={country.name}
                      title={
                        <Link
                          to={`/events/event/${id}/${
                            organization.parent ? "company" : "organization"
                          }/${organization.id}`}
                        >
                          {title}
                        </Link>
                      }
                      description={parse(description)}
                      publicated={true}
                      publicatedDate={moment(created_at).format("DD MMMM YYYY")}
                      publicatedCompany={organization.name}
                      style={{ cursor: "text" }}
                    />
                  )
                )}
            </div>
            {events && !events.length && (
              <NoDataFound
                noDataTitle="No events found!"
                myBusiness={true}
                additionalLink="/my-business/events"
              />
            )}
          </Card>
        )}
        <Pagination
          forcePage={eventsPage - 1}
          selectedPage={page => setEventsPage(page)}
          dataCount={eventsCount}
          itemsPerPage={10}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  areEventsPending: state.events.areEventsPending,
  events: state.events.events,
  eventsCount: state.events.eventsCount,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr
});

export default connect(mapStateToProps, {
  getEvents,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
})(Events);
