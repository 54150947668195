import React from "react";
import SkewLoader from "react-spinners/SkewLoader";

import { colorPalette } from "~/styles/colorPalette";

import "./loading-spinner.styles.scss";

const LoadingSpinner = ({ loadingState = true }) => {
  return (
    <div className={loadingState ? "loading-spinner" : undefined}>
      <SkewLoader
        sizeUnit={"px"}
        size={40}
        color={colorPalette.colorPrimary.main}
        loading={loadingState}
      />
    </div>
  );
};

export default LoadingSpinner;
