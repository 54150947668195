import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { CategoriesProductGrid } from "../../../components";

import {
  getB2CProducts,
  getB2CCategories
} from "../../../redux/actions-exporter";

import "./wall-categories-product-grid.styles.scss";

const WallCategoriesProductGrid = ({
  getB2CProducts,
  areB2CProductsPending,
  b2cProductsNamespaces,
  b2cProductsError,
  getB2CCategories,
  areB2CCategoriesPending,
  b2cCategoriesResults,
  b2cCategoriesError,
  countryId,
  props
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTwo, setLoadingTwo] = useState(false);
  const [data, setData] = useState({
    loading: true,
    params: {
      perPage: 10,
      countryId,
      random: true
    },
    categories: [],
    products: []
  });
  const { categories, products, params, loading } = data;
  const namespace = "wallRandomB2CCategoriesProduct";

  useEffect(() => {
    if (
      categories?.length ||
      areB2CCategoriesPending ||
      b2cCategoriesError ||
      isLoading
    ) {
      return;
    }
    if (!b2cCategoriesResults.length) {
      getB2CCategories();
    } else {
      const randomCategories = [...b2cCategoriesResults]
        .sort(() => 0.5 - Math.random())
        .map(category => category.id)
        .slice(0, params.perPage);

      setData({
        ...data,
        categories: randomCategories,
        params: {
          ...params,
          categoriesIn: randomCategories
          // discountPercentGt: 0,
          // couponDiscountPercentGt: 0
        }
      });

      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, areB2CCategoriesPending, isLoading]);

  useEffect(() => {
    if (
      !categories.length ||
      products.length ||
      areB2CProductsPending ||
      b2cProductsError ||
      isLoadingTwo
    ) {
      return;
    }

    const isValidProducts =
      b2cProductsNamespaces[namespace] &&
      JSON.stringify(b2cProductsNamespaces[namespace].params) ===
        JSON.stringify(params);

    setData({
      ...data,
      products: isValidProducts
        ? b2cProductsNamespaces[namespace].products
        : [],
      loading: !isValidProducts
    });

    if (!isValidProducts) {
      getB2CProducts({ ...params, namespace, additional: { params } });
    } else {
      setLoadingTwo(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, areB2CProductsPending, isLoadingTwo]);

  return (
    <CategoriesProductGrid
      props={props}
      products={products}
      loading={loading}
    />
  );
};

const mapStateToProps = state => ({
  countryId: state.countriesReducer.countryId,
  b2cProductsNamespaces: state.b2cProductsReducer.b2cProductsNamespaces,
  areB2CProductsPending: state.b2cProductsReducer.areB2CProductsPending,
  b2cProductsError: state.b2cProductsReducer.b2cProductsError,
  areB2CCategoriesPending: state.categoriesAPI.areB2CCategoriesPending,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,
  b2cCategoriesError: state.categoriesAPI.b2cCategoriesError
});

export default connect(mapStateToProps, {
  getB2CProducts,
  getB2CCategories
})(WallCategoriesProductGrid);
