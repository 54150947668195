import { B2BProductsActionTypes } from "./b2b.products.types";

import axios from "axios";

import {
  axiosInstance,
  axiosNoTokenInstance,
  getCookie,
  baseURL
} from "../../../../axios/axios.config";

export const getB2BProducts = ({
  searchQuery = "",
  page = 1,
  countries = "",
  companies = "",
  branches = "",
  categories = "",
  ordering = "-id",
  perPage = 10,
  namespace = undefined,
  additional = {}
}) => dispatch => {
  dispatch({ type: B2BProductsActionTypes.GET.GET_B2B_PRODUCTS_PENDING });

  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries({
        search: searchQuery,
        company__countries: countries,
        company__parent: companies,
        categories,
        branches,
        page,
        page_size: perPage,
        ordering
      }).filter(([key, value]) => typeof value !== "undefined" && value !== "")
    )
  );
  axiosInstance
    .get(`/api/v1/b2b/product/?${params}`)
    .then(productsList => {
      dispatch({
        type: B2BProductsActionTypes.GET.GET_B2B_PRODUCTS_SUCCESS,
        payload: {
          namespace,
          additional,
          count: productsList.data.count,
          products: productsList.data.results
        }
      });
    })
    .catch(err =>
      dispatch({
        type: B2BProductsActionTypes.GET.GET_B2B_PRODUCTS_ERROR,
        payload: err
      })
    );
};

export const getCategoryList = pageNumber => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/b2b/category/?page=${pageNumber}`)
    .then(categoryList => {
      dispatch({
        type: B2BProductsActionTypes.GET_CATEGORY_LIST,
        payload: categoryList.data
      });
    })
    .catch(error => console.log(error));
};

export const createProduct = (
  name,
  sku,
  shortDescription,
  description,
  company,
  branches,
  categories,
  stock,
  isActive,
  showOnMain,
  isDigitalProduct,
  organization,
  keywords,
  cost,
  producer,
  currency,
  measurementUnit,
  image,
  leadFormId
) => dispatch => {
  axiosInstance
    .post(`${baseURL}/api/v1/b2b/product_manager/`, {
      name,
      metadata: {
        sku,
        lead_form_id: leadFormId
      },
      short_description: shortDescription,
      description,
      company,
      branches,
      categories,
      stock,
      is_active: isActive,
      show_on_main: showOnMain,
      keywords,
      cost,
      producer,
      currency,
      measurement_unit: measurementUnit
    })
    .then(product => {
      dispatch({
        type: B2BProductsActionTypes.CREATE_PRODUCT,
        payload: { product: product.data, requestStatus: product.status }
      });

      if (image) {
        dispatch({
          type: B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", image);
        axios
          .patch(
            `${baseURL}/api/v1/b2b/product_manager/${product.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type: B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .catch(err =>
            dispatch({
              type: B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_ERROR,
              payload: err
            })
          );
      }
    })
    .catch(error => console.log(error));
};

export const updateProductById = (
  productId,
  name,
  sku,
  shortDescription,
  description,
  company,
  branches,
  categories,
  stock,
  isActive,
  showOnMain,
  isDigitalProduct,
  organization,
  keywords,
  cost,
  producer,
  currency,
  measurementUnit,
  image,
  leadFormId
) => dispatch => {
  axiosInstance
    .put(`/api/v1/b2b/product_manager/${productId}/`, {
      name,
      short_description: shortDescription,
      description,
      company,
      keywords,
      currency,
      stock,
      measurement_unit: measurementUnit,
      cost,
      producer,
      show_on_main: showOnMain,
      is_active: isActive,
      metadata: {
        sku,
        lead_form_id: leadFormId
      },
      branches,
      categories
    })
    .then(product => {
      if (image !== undefined) {
        dispatch({
          type: B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", image);
        axios
          .patch(
            `${baseURL}/api/v1/b2b/product_manager/${product.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type: B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(() => {
            dispatch({
              type: B2BProductsActionTypes.UPDATE_PRODUCT,
              payload: { product: product.data, requestStatus: product.status }
            });
          })
          .catch(err =>
            dispatch({
              type: B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: B2BProductsActionTypes.UPDATE_PRODUCT,
          payload: { product: product.data, requestStatus: product.status }
        });
      }
    })
    .catch(error => console.log(error));
};

export const deleteProduct = productId => dispatch => {
  axiosInstance
    .delete(`/api/v1/b2b/product_manager/${productId}/`)
    .then(deletedProduct => {
      dispatch({
        type: B2BProductsActionTypes.DELETE_PRODUCT,
        payload: deletedProduct
      });
    })
    .then(() => dispatch(getUserB2BProducts()))
    .catch(error => console.log(error));
};

export const getCompanyB2BProducts = (
  companyId,
  categories = "",
  ordering,
  pageNumber = 1
) => dispatch => {
  dispatch({
    type: B2BProductsActionTypes.COMPANY.GET_COMPANY_B2B_PRODUCTS_PENDING
  });
  axiosInstance
    .get(
      `api/v1/b2b/product/?company=${companyId}&categories=${categories}&ordering=${ordering}&page=${pageNumber}`
    )
    .then(companyB2BProducts =>
      dispatch({
        type: B2BProductsActionTypes.COMPANY.GET_COMPANY_B2B_PRODUCTS_SUCCESS,
        payload: {
          count: companyB2BProducts.data.count,
          products: companyB2BProducts.data.results
        }
      })
    )
    .catch(error =>
      dispatch({
        type: B2BProductsActionTypes.COMPANY.GET_COMPANY_B2B_PRODUCTS_FAIL,
        payload: error
      })
    );
};

export const getOrganizationB2BProducts = (
  organizationId,
  categories = "",
  ordering,
  page = 1
) => dispatch => {
  dispatch({
    type:
      B2BProductsActionTypes.ORGANIZATION.GET_ORGANIZATION_B2B_PRODUCTS_PENDING
  });
  axiosInstance(
    `/api/v1/b2b/product/?company__parent=${organizationId}&categories=${categories}&ordering=${ordering}&page=${page}`
  )
    .then(resp =>
      dispatch({
        type:
          B2BProductsActionTypes.ORGANIZATION
            .GET_ORGANIZATION_B2B_PRODUCTS_SUCCESS,
        payload: resp.data
      })
    )
    .catch(err =>
      dispatch({
        type:
          B2BProductsActionTypes.ORGANIZATION
            .GET_ORGANIZATION_B2B_PRODUCTS_FAIL,
        payload: err
      })
    );
};

export const getUserB2BProducts = (
  searchQuery = "",
  page = 1,
  pageSize = 10
) => dispatch => {
  dispatch({
    type: B2BProductsActionTypes.GET.USER.GET_USER_B2B_PRODUCTS_PENDING
  });
  axiosInstance
    .get(
      `api/v1/b2b/product_manager/?search=${searchQuery}&page_size=${pageSize}&page=${page}`
    )
    .then(resp =>
      dispatch({
        type: B2BProductsActionTypes.GET.USER.GET_USER_B2B_PRODUCTS_SUCCESS,
        payload: { count: resp.data.count, products: resp.data.results }
      })
    )
    .catch(error =>
      dispatch({
        type: B2BProductsActionTypes.GET.USER.GET_USER_B2B_PRODUCTS_ERROR,
        payload: error
      })
    );
};

export const getFilterB2BProducts = (filterQuery, page = 1) => dispatch => {
  dispatch({ type: B2BProductsActionTypes.GET_PRODUCT_LIST_PENDING });
  axiosInstance
    .get(`/api/v1/b2b/product_manager/${filterQuery}&page=${page}`)
    .then(productsList => {
      dispatch({
        type: B2BProductsActionTypes.GET_PRODUCT_LIST,
        payload: {
          productList: productsList.data,
          results: productsList.data.results
        }
      });
    })
    .catch(error => console.log(error));
};

export const getRecommendedB2BProducts = () => dispatch => {
  dispatch({
    type:
      B2BProductsActionTypes.GET.RECOMMENDED
        .GET_RECOMMENDED_B2B_PRODUCTS_PENDING
  });
  axiosNoTokenInstance
    .get(`${baseURL}/api/v1/recommended/b2b/`)
    .then(resp =>
      dispatch({
        type:
          B2BProductsActionTypes.GET.RECOMMENDED
            .GET_RECOMMENDED_B2B_PRODUCTS_SUCCESS,
        payload: { products: resp.data.results, count: resp.data.count }
      })
    )
    .catch(err =>
      dispatch({
        type:
          B2BProductsActionTypes.GET.RECOMMENDED
            .GET_RECOMMENDED_B2B_PRODUCTS_ERROR,
        payload: err
      })
    );
};

export const getSimilarB2BProducts = productId => dispatch => {
  dispatch({
    type: B2BProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2B_PRODUCTS_PENDING
  });
  axiosInstance
    .get(`api/v1/similar/b2b/?products=${productId}`)
    .then(resp =>
      dispatch({
        type:
          B2BProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2B_PRODUCTS_SUCCESS,
        payload: { count: resp.data.count, products: resp.data.results }
      })
    )
    .catch(err =>
      dispatch({
        type: B2BProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2B_PRODUCTS_ERROR,
        payload: err
      })
    );
};

export const getB2BProductsByCategory = (
  categoryId,
  sortBy,
  page = 1
) => dispatch => {
  dispatch({ type: B2BProductsActionTypes.GET_PRODUCT_LIST_PENDING });
  axiosInstance
    .get(
      `/api/v1/b2b/product_manager/?categories=${categoryId}&ordering=${sortBy}&page=${page}`
    )
    .then(productsList => {
      dispatch({
        type: B2BProductsActionTypes.GET_PRODUCT_LIST,
        payload: {
          productList: productsList.data,
          results: productsList.data.results
        }
      });
    })
    .catch(err =>
      dispatch({
        type: B2BProductsActionTypes.GET_PRODUCT_LIST_ERROR,
        payload: err
      })
    );
};

export const clearB2BRequest = () => dispatch => {
  dispatch({
    type: B2BProductsActionTypes.CLEAR_B2B_PRODUCTS_REQUEST,
    payload: ""
  });
};
