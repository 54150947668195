import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

import { missingImage } from "../../../assets";
import { withDebounce } from "../../../shared";

import {
  SearchBar,
  LoadingSpinner,
  CompanySmallCard,
  MyBusinessNoData,
  Pagination,
  ConfirmDialog
} from "../../../components";

import {
  getCompaniesByUserId,
  deleteCompany
} from "../../../redux/actions-exporter";

import "./my-business-companies.styles.scss";

const MyBusinessCommpanies = ({
  appDirection,
  getCompaniesByUserId,
  areUserCompaniesPending,
  companiesByUserIdCount,
  companiesByUserId,
  deleteCompany
}) => {
  const [companySearch, setCompanySearch] = useState("");
  const [companyPage, setCompanyPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(0);

  useEffect(() => {
    getCompaniesByUserId(companySearch, companyPage);
  }, [getCompaniesByUserId, companySearch, companyPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [companyPage]);

  useEffect(() => {
    setCompanyPage(1);
  }, [companySearch]);

  const searchCompanyHandler = withDebounce(e => setCompanySearch(e));

  return (
    <div className="my-business-companies">
      <ConfirmDialog
        dialogTitle="Company remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteCompany(currentCompanyId);
          setDialogOpen(false);
          setCurrentCompanyId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => searchCompanyHandler(e.target.value)} />
      </div>
      {areUserCompaniesPending ? (
        <LoadingSpinner />
      ) : (
        <>
          {companiesByUserId && companiesByUserId.length ? (
            companiesByUserId.map(
              ({ id, name, short_description, logo, metadata, countries }) => (
                <CompanySmallCard
                  key={id}
                  name={name}
                  logo={logo ? logo : missingImage}
                  description={parse(short_description)}
                  location={countries[0] && countries[0].name}
                  phone={metadata && metadata.phone}
                  website={metadata && metadata.site}
                  editorState={true}
                  editLink={`/my-business/companies/${id}`}
                  removeFunc={() => {
                    setDialogOpen(true);
                    setCurrentCompanyId(id);
                  }}
                  scaleDown={true}
                  appDirection={appDirection}
                  id={id}
                />
              )
            )
          ) : (
            <MyBusinessNoData noDataText="No companies found!" />
          )}
        </>
      )}
      <Pagination
        forcePage={companyPage - 1}
        dataCount={companiesByUserIdCount}
        itemsPerPage={10}
        selectedPage={page => setCompanyPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  areUserCompaniesPending: state.companiesReducer.areCompaniesByUserIdPending,
  companiesByUserId: state.companiesReducer.companiesByUserId,
  companiesByUserIdCount: state.companiesReducer.companiesByUserIdCount
});

export default connect(mapStateToProps, {
  getCompaniesByUserId,
  deleteCompany
})(MyBusinessCommpanies);
