import ApiService from "../../../services/api";
// Products Blocks
import productsWithPaginationBlock from "../../blocks/products-with-pagination";
import productsSearchBlock from "../../blocks/products-search";
import productsSortBlock from "../../blocks/products-sort";
import productsFilterBlock from "../../blocks/products-filter";
import productsProducersBlock from "../../blocks/products-producers";
import productsCategoriesBlock from "../../blocks/products-categories";
import productsCategoriesLinksBlock from "../../blocks/products-categories-links";
import productsCategoriesMenuBlock from "../../blocks/products-categories-menu";
import productsCategoriesMultiLevelMenuBlock from "../../blocks/products-categories-menu-multilevel";
import productsCategoriesContainerLinkBlock from "../../blocks/products-categories-container-link";
import recommendedProductsBlock from "../../blocks/recommended-products";
import similarProductsBlock from "../../blocks/similar-products";
import productCarouselBlock from "../../blocks/product-carousel";
import singleProductBlock from "../../blocks/single-product";
import productDetailsBlock from "../../blocks/product-details";
import productsTypeSwitchBlock from "../../blocks/products-type-switch";
// News blocks
import newsWithPaginationBlock from "../../blocks/news-with-pagination";
import newsSearchBlock from "../../blocks/news-search";
import newsCategoriesBlock from "../../blocks/news-categories";
import newsDescriptionBlock from "../../blocks/news-description";
// Companies blocks
import companiesWithPaginationBlock from "../../blocks/companies-with-pagination";
import companiesSearchBlock from "../../blocks/companies-search";
import companiesBranchesBlock from "../../blocks/companies-branches";
import companiesDescriptionBlock from "../../blocks/company-description";
// EDEA blocks
import edeaSingleProductWithPaginationBlock from "../../blocks/edea-single-product";
import edeaProductsWithPaginationBlock from "../../blocks/edea-products-with-pagination";
// Jobs blocks
import jobsWithPaginationBlock from "../../blocks/jobs-with-pagination";
import jobsSearchBlock from "../../blocks/jobs-search";
import jobDetailsBlock from "../../blocks/job-details";
// Business blocks
import businessProposalsWithPaginationBlock from "../../blocks/business-proposals-with-pagination";
// Cart blocks
import cartTableBlock from "../../blocks/cart-table";
import cartSubmitBlock from "../../blocks/cart-submit";
import cartCheckoutBlock from "../../blocks/cart-checkout";
import cartSubtotalBlock from "../../blocks/cart-subtotal";
import orderCheckBlock from "../../blocks/order-check";
import paymentInfoBlock from "../../blocks/payment-info";
// Auth blocks
import authLoginFormBlock from "../../blocks/auth-login-form";
import authLoginSubmitBlock from "../../blocks/auth-login-submit";
import authRegistrationFormBlock from "../../blocks/auth-registration-form";
import authRegistrationSubmitBlock from "../../blocks/auth-registration-submit";
import authStateButtonBlock from "../../blocks/auth-state-button";
import privatePageBlock from "../../blocks/private-page";
import authContainerBlock from "../../blocks/auth-container";
// Profile blocks
import profileAvatarBlock from "../../blocks/profile-avatar";
import profileTextBlock from "../../blocks/profile-text";
import wishListBlock from "../../blocks/wish-list";
import orderListBlock from "../../blocks/order-list";
import profileEditBlock from "../../blocks/profile-edit";
// Extra blocks
import chatBot from "../../blocks/chat-bot";
import popupBlock from "../../blocks/popup";
import dropdownBlock from "../../blocks/dropdown";
import preloaderBlock from "../../blocks/preloader";
import preloaderNewBlock from "../../blocks/preloader-new";
import accessibilityWidgetBlock from "../../blocks/accessibility-widget";
// Translations blocks
import languageSelectorBlock from "../../blocks/language-selector";
import translationsBaseBlock from "../../blocks/translations-base";
import multiLanguageTextBlock from "../../blocks/multi-language-text";
// Contact blocks
import contactFormBlock from "../../blocks/contact-form";
// Basic blocks
import videoBlock from "../../blocks/video";
import tabsBlock from "../../blocks/tabs";

import "./gjs-custom-blocks.scss";

export default ({ token, site }) =>
  function gjsCustomBlocks(editor) {
    // Init API without token
    const api = new ApiService();
    console.log("init gjs-custom-blocks.js", "api:", api);
    const blockProps = { editor, api, site };
    /** Basic blocks **/
    videoBlock(blockProps);
    tabsBlock(blockProps);
    /** Products blocks **/
    // Init "Products with pagination" block
    productsWithPaginationBlock(blockProps);
    // Init "Product Single" block
    singleProductBlock(blockProps);
    // Init "Product Type Switch" block
    productsTypeSwitchBlock(blockProps);
    // Init "Products Search" block
    productsSearchBlock(blockProps);
    // Init "Products Sort" block
    productsSortBlock(blockProps);
    // Init "Products Filter" block
    productsFilterBlock(blockProps);
    // Init "Products Producers" block
    productsProducersBlock(blockProps);
    // Init "Product Description" block
    productDetailsBlock(blockProps);
    // Init "Products Categories" block
    productsCategoriesBlock(blockProps);
    // Init "Products Categories Container Link" block
    productsCategoriesContainerLinkBlock(blockProps);
    // Init "Products Categories Links" block
    productsCategoriesLinksBlock(blockProps);
    // Init "Products Categories Menu" block
    productsCategoriesMenuBlock(blockProps);
    // Init "Products Categories Menu" block
    productsCategoriesMultiLevelMenuBlock(blockProps);
    // Init "Recommended Products" block
    recommendedProductsBlock(blockProps);
    // Init "Similar Products" block
    similarProductsBlock(blockProps);
    // Init "Product Recommended" block
    productCarouselBlock(blockProps);

    /** News blocks **/
    // Init "News with pagination" block
    newsWithPaginationBlock(blockProps);
    // Init "News Search" block
    newsSearchBlock(blockProps);
    // Init "News Categories" block
    newsCategoriesBlock(blockProps);
    // Init "News Description" block
    newsDescriptionBlock(blockProps);

    /** Jobs blocks **/
    // Init "Jobs with pagination" block
    jobsWithPaginationBlock(blockProps);
    // Init "Jobs search" block
    jobsSearchBlock(blockProps);
    // Init "Job details" block
    jobDetailsBlock(blockProps);

    /** Business blocks **/
    // Init "Business Proposals" block
    businessProposalsWithPaginationBlock(blockProps);

    /** Companies blocks **/
    // Init "Companies with pagination" block
    companiesWithPaginationBlock(blockProps);
    // Init "Companies Search" block
    companiesSearchBlock(blockProps);
    // Init "Companies Branches" block
    companiesBranchesBlock(blockProps);
    // Init "Company Description" block
    companiesDescriptionBlock(blockProps);

    /** Auth blocks **/
    // Init "Login Form" block
    authLoginFormBlock(blockProps);
    // Init "Login Submit" block
    authLoginSubmitBlock(blockProps);
    // Init "Registration Form" block
    authRegistrationFormBlock(blockProps);
    // Init "Registration Submit" block
    authRegistrationSubmitBlock(blockProps);
    // Init "State Button" block
    authStateButtonBlock(blockProps);
    // Init "Private Page" block
    privatePageBlock(blockProps);
    // Init "Auth Container" block
    authContainerBlock(blockProps);

    /** Cart blocks **/
    // Init "Cart Table" block
    cartTableBlock(blockProps);
    // Init "Cart Info" block
    cartSubtotalBlock(blockProps);
    // Init "Cart Submit" block
    cartSubmitBlock(blockProps);
    // Init "Cart Checkout" block
    cartCheckoutBlock(blockProps);
    orderCheckBlock(blockProps);
    paymentInfoBlock(blockProps);

    /** Extra blocks **/
    // Init "Chat Bot" block
    chatBot(editor);
    // Init "Pop-up" Block
    popupBlock(blockProps);
    // Init "Dropdown" block
    dropdownBlock(blockProps);
    // Init "Preloader" block
    preloaderBlock(blockProps);
    // Init "Preloader New" block
    preloaderNewBlock(blockProps);
    // Init "Accessibility Widget" block
    accessibilityWidgetBlock(blockProps);

    /** Profile blocks **/
    // Init "Avatar" Block
    profileTextBlock(blockProps);
    // Init "Profile Text" Block
    profileAvatarBlock(blockProps);
    // Init "Wish List" Block
    wishListBlock(blockProps);
    // Init "Order List" Block
    orderListBlock(blockProps);
    // Init "Profile Edit" Block
    profileEditBlock(blockProps);

    /** Translations blocks **/
    // Init "Language Selector" Block
    languageSelectorBlock(blockProps);
    // Init "Translations Base" Block
    translationsBaseBlock(blockProps);
    // Init "Multi-language text" Block
    multiLanguageTextBlock(blockProps);

    /** Contact blocks **/
    contactFormBlock(blockProps);

    /** EDEA blocks **/
    // Init "EDEA Product Single" block
    edeaSingleProductWithPaginationBlock(blockProps);
    // Init "Products with pagination" block
    edeaProductsWithPaginationBlock(blockProps);
  };
