import { EventsActionTypes } from "./events.types";
import {
  axiosNoTokenInstance,
  axiosInstance
} from "../../../axios/axios.config";

export const getEventById = eventId => dispatch => {
  dispatch({
    type: EventsActionTypes.GET.BY_ID.GET_EVENT_BY_ID_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/exhibition/${eventId}/`)
    .then(eventById =>
      dispatch({
        type: EventsActionTypes.GET.BY_ID.GET_EVENT_BY_ID_SUCCESS,
        payload: eventById.data
      })
    )
    .catch(error =>
      dispatch({
        type: EventsActionTypes.GET.BY_ID.GET_EVENT_BY_ID_ERROR,
        payload: error
      })
    );
};

export const getEventsByMe = (search = "", page = 1) => dispatch => {
  dispatch({
    type: EventsActionTypes.GET.GET_EVENTS_BY_ME_PENDING
  });

  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(user =>
      axiosInstance
        .get(
          `/api/v1/organization/exhibition/?created_by=${user.data.id}&search=${search}&page=${page}`
        )
        .then(events =>
          dispatch({
            type: EventsActionTypes.GET.GET_EVENTS_BY_ME_SUCCESS,
            payload: { events: events.data.results, count: events.data.count }
          })
        )
    )
    .catch(err =>
      dispatch({
        type: EventsActionTypes.GET.GET_EVENTS_BY_ME_ERROR,
        payload: err
      })
    );
};

export const getEvents = (
  searchQuery = "",
  page = 1,
  countries = "",
  organizations = ""
) => dispatch => {
  dispatch({
    type: EventsActionTypes.GET.GET_EVENTS_PENDING
  });
  axiosNoTokenInstance
    .get(
      `/api/v1/organization/exhibition/?search=${searchQuery}&country=${countries}&organization=${organizations}&page=${page}`
    )
    .then(events =>
      dispatch({
        type: EventsActionTypes.GET.GET_EVENTS_SUCCESS,
        payload: { count: events.data.count, events: events.data.results }
      })
    )
    .catch(error =>
      dispatch({
        type: EventsActionTypes.GET.GET_EVENTS_ERROR,
        payload: error
      })
    );
};

export const postEvent = (
  title,
  organization,
  country,
  city,
  startDate,
  endDate,
  keywords,
  branches,
  route,
  description,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit
) => dispatch => {
  dispatch({
    type: EventsActionTypes.POST.POST_EVENT_PENDING
  });
  axiosInstance
    .post("/api/v1/organization/exhibition/", {
      title,
      description,
      companies: [organization],
      keywords,
      city,
      route,
      country,
      branches,
      metadata: {
        facebook,
        linkedin,
        twitter,
        instagram,
        youtube,
        reddit
      },
      start_date: startDate,
      end_date: endDate,
      organization
    })
    .then(resp => {
      dispatch({
        type: EventsActionTypes.POST.POST_EVENT_SUCCESS,
        payload: { event: resp.data, requestStatus: resp.status }
      });
    })
    .catch(err =>
      dispatch({
        type: EventsActionTypes.POST.POST_EVENT_ERROR,
        payload: err
      })
    );
};

export const putEvent = (
  eventId,
  title,
  organization,
  country,
  city,
  startDate,
  endDate,
  keywords,
  branches,
  route,
  description,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit
) => dispatch => {
  dispatch({ type: EventsActionTypes.PUT.PUT_EVENT_PENDING });
  axiosInstance
    .put(`/api/v1/organization/exhibition/${eventId}/`, {
      title,
      description,
      companies: [organization],
      keywords,
      city,
      route,
      country,
      branches,
      metadata: {
        facebook,
        linkedin,
        twitter,
        instagram,
        youtube,
        reddit
      },
      start_date: startDate,
      end_date: endDate,
      organization
    })
    .then(resp => {
      dispatch({
        type: EventsActionTypes.PUT.PUT_EVENT_SUCCESS,
        payload: { event: resp.data, requestStatus: resp.status }
      });
    })
    .catch(err =>
      dispatch({ type: EventsActionTypes.PUT_EVENT_ERROR, payload: err })
    );
};

export const deleteEvent = eventId => dispatch => {
  dispatch({
    type: EventsActionTypes.DELETE.DELETE_EVENT_BY_ID_PENDING
  });
  axiosInstance
    .delete(`/api/v1/organization/exhibition/${eventId}`)
    .then(event => {
      dispatch({
        type: EventsActionTypes.DELETE.DELETE_EVENT_BY_ID_SUCCESS,
        payload: {
          id: eventId,
          event
        }
      });
    })
    .then(() => dispatch(getEventsByMe()))
    .catch(err => {
      dispatch({
        type: EventsActionTypes.DELETE.DELETE_EVENT_BY_ID_ERROR,
        payload: err
      });
    });
};

export const clearEventRequest = () => dispatch => {
  dispatch({
    type: EventsActionTypes.CLEAR_EVENT_REQUEST,
    payload: ""
  });
};
