import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";

import { googleToken } from "../../config";

import "./login-with-google.styles.scss";

const LoginWithGoogle = ({ setLoading, loginWithOauth }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [app] = useState({
    clientId: googleToken,
    provider: "google-oauth2",
    cookiePolicy: "single_host_origin"
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    setLoading(true);
    loginWithOauth(accessToken, app.provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleSuccess = response => setAccessToken(response?.accessToken);

  return (
    <div className="login-with-google">
      <GoogleLogin
        clientId={app.clientId}
        buttonText={null}
        onSuccess={handleSuccess}
        onFailure={e => console.log(e)}
        cookiePolicy={app.cookiePolicy}
      />
    </div>
  );
};

export default LoginWithGoogle;
