import { axiosInstance } from "../../../axios/axios.config";

import { OrdersActionTypes } from "./orders.types";

export const getOrderedItems = (customerType, page = 1) => dispatch => {
  dispatch({ type: OrdersActionTypes.GET_ORDERS_PENDING });
  axiosInstance
    .get(
      `/api/v1/payments/deal/?status=ordered&deal_order__customer_type=${customerType}&page=${page}`
    )
    .then(orderedItems => {
      dispatch({
        type: OrdersActionTypes.GET_ORDERS_SUCCESS,
        payload: {
          orders: orderedItems.data.results,
          count: orderedItems.data.count
        }
      });
    })
    .catch(error => {
      dispatch({
        type: OrdersActionTypes.GET_ORDERS_ERROR,
        payload: error
      });
    });
};

export const removeDealById = dealId => dispatch => {
  dispatch({ type: OrdersActionTypes.DELETE_DEAL_BY_ID_PENDING });
  axiosInstance
    .delete(`/api/v1/payments/deal/${dealId}`)
    .then(() => {
      dispatch({
        type: OrdersActionTypes.DELETE_DEAL_BY_ID_SUCCESS
      });
    })
    .catch(error => {
      dispatch({
        type: OrdersActionTypes.DELETE_DEAL_BY_ID_ERROR,
        payload: error
      });
    });
};

export const getOrdersByCompany = company => dispatch => {
  dispatch({ type: OrdersActionTypes.GET_ORDERS_BY_COMPANY_PENDING });
  axiosInstance
    .get(`/api/v1/payments/deal/?status=ordered&supplier_company=${company}`)
    .then(response => {
      dispatch({
        type: OrdersActionTypes.GET_ORDERS_BY_COMPANY_SUCCESS,
        payload: {
          orders: response.data.results,
          count: response.data.count
        }
      });
    })
    .catch(error => {
      dispatch({
        type: OrdersActionTypes.GET_ORDERS_BY_COMPANY_ERROR,
        payload: error
      });
    });
};
