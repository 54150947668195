import React from "react";
import GoogleMapReact from "google-map-react";
import { googleMapsToken } from "../../config";
import Marker from "./marker/marker.component";

import "./comp-org-contacts-map.styles.scss";

const CompOrgContactsMap = ({ organizationName, location }) => {
  const coords = location ? location.split(",") : "";
  const companyData = coords && {
    lat: parseFloat(coords[0]),
    lng: parseFloat(coords[1])
  };
  const mapZoom = 11;

  return (
    <div className="comp-org-contacts-map">
      <h3>Map</h3>
      {companyData.lat && companyData.lng ? (
        <div className="comp-org-contacts-map__google-maps-iframe">
          <div style={{ height: "500px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: googleMapsToken
              }}
              defaultCenter={companyData}
              defaultZoom={mapZoom}
            >
              <Marker
                lat={companyData.lat && companyData.lat}
                lng={companyData.lng && companyData.lng}
                name={organizationName}
              />
            </GoogleMapReact>
          </div>
        </div>
      ) : (
        <h3>No coords to display map!</h3>
      )}
    </div>
  );
};

export default CompOrgContactsMap;
