import icon from "./icon.png";

export default function authRegistrationBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style> 
    .registration-form {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      margin: 0;
    }

    .registration-form__input {
      background-color: hsla(0, 0%, 100%, 0.8);
      color: #000000;
      font-size: 15px;
      border: 1px solid #b9b9ba;
      padding: 10px 8px;
      border-radius: 3px;
    }

    .registration-form__input + .registration-form__input {
      margin-top: 10px;
    }
  
    .registration-form__message {
      color: #ff3b3b;
      margin-top: 14px;
    }
  </style>
  `;

  // TODO: подкинуть динамический company_id
  const content = `
  ${style}
  <div class="registration-form-wrapper">
    <form class="registration-form" name="registration">
      <input class="registration-form__input" name="email" required type="email" placeholder="E-mail" />
      <input class="registration-form__input" name="password" required type="password" placeholder="Password" />
      <input class="registration-form__input" name="confirm_password" required type="password" placeholder="Confirm password" />
<!--      <input name="company_id" id="company_id" type="hidden" value="" />-->
    </form>
    <div class="registration-form__messages">
      <span class="registration-form__message">
        Unable to register with provided credentials.
      </span>
    </div>
  </div>
  `;

  blockManager.add("auth-registration-form", {
    label: `
      <img src="${icon}" alt="'Registration Form' block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Registration Form
      </div>
    `,
    content,
    category: "B24Online Auth"
  });
}
