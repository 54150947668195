import { axiosNoTokenInstance } from "../../../axios/axios.config";
import { UtilsActionTypes } from "./utils.types";

export const getCurrencies = () => dispatch => {
  dispatch({ type: UtilsActionTypes.GET.CURRENCIES.GET_CURRENCIES_PENDING });
  axiosNoTokenInstance
    .get("/api/v1/b2b/product/currencies")
    .then(currencies =>
      dispatch({
        type: UtilsActionTypes.GET.CURRENCIES.GET_CURRENCIES_SUCCESS,
        payload: currencies.data
      })
    )
    .catch(err =>
      dispatch({
        type: UtilsActionTypes.GET.CURRENCIES.GET_CURRENCIES_ERROR,
        payload: err
      })
    );
};

export const getMeasurements = () => dispatch => {
  dispatch({
    type: UtilsActionTypes.GET.MEASUREMENTS.GET_MEASUREMENTS_PENDING
  });
  axiosNoTokenInstance
    .get("/api/v1/b2b/product/munits")
    .then(measurements =>
      dispatch({
        type: UtilsActionTypes.GET.MEASUREMENTS.GET_MEASUREMENTS_SUCCESS,
        payload: measurements.data
      })
    )
    .catch(err =>
      dispatch({
        type: UtilsActionTypes.GET.MEASUREMENTS.GET_MEASUREMENTS_ERROR,
        payload: err
      })
    );
};
