import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";

import {
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog,
  DefaultButton
} from "@core/components";

import Category from "@core/categories/components/category/category.component";

import {
  getB2BCategoriesEditor,
  getB2CCategoriesEditor,
  deleteCategory
} from "@core/categories/store/API/categories/categories.action";

import { MyBusinessAddBar } from "@core/containers";

import "./my-business-categories.styles.scss";
import { useTranslation } from "react-i18next";
import { colorPalette } from "~/styles/colorPalette";

const ChildLevel = ({
                      children,
                      categoryType,
                      setDialogOpen,
                      setCurrentCategoryId,
                      setCurrentCategoryType
                    }) => (
  <div className="categories__child-container">
    {children.map((subCategory, index) => (
      <>
        <Category
          typeOfCategory={categoryType}
          key={`sub_${categoryType}_${index}`}
          category={subCategory}
          editorState={true}
          editLink={`/my-business/categories/${categoryType}/${subCategory.id}`}
          removeFunc={() => {
            setDialogOpen(true);
            setCurrentCategoryId(subCategory.id);
            setCurrentCategoryType(categoryType);
          }}
        />
        {subCategory?.children?.length > 0 &&
        <ChildLevel
          children={subCategory.children}
          setDialogOpen={setDialogOpen}
          categoryType={categoryType}
          setCurrentCategoryId={setCurrentCategoryId}
          setCurrentCategoryType={setCurrentCategoryType}
        />
        }
      </>
    ))}
  </div>
);

const MyBusinessCategories = ({
                                getB2BCategoriesEditor,
                                getB2CCategoriesEditor,
                                b2bCategoriesEditor,
                                b2cCategoriesEditor,
                                areB2BCategoriesEditorPending,
                                areB2CCategoriesEditorPending,
                                deleteCategory
                              }) => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentCategoryType, setCurrentCategoryType] = useState(null);

  useEffect(() => {
    getB2CCategoriesEditor();
  }, []); // eslint-disable-line

  useEffect(() => {
    getB2BCategoriesEditor();
  }, []); // eslint-disable-line

  return (
    <div className="my-business-tasks">
      <ConfirmDialog
        dialogTitle={t("Remove category")}
        dialogText={t(
          "Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        )}
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteCategory(currentCategoryType, currentCategoryId);
          setDialogOpen(false);
          setCurrentCategoryId(null);
          setCurrentCategoryType(null);
        }}
      />
      {areB2BCategoriesEditorPending && areB2CCategoriesEditorPending ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="my-business-companies">
            <div className="company-department-container">
              <MyBusinessAddBar
                title={t("B2C Categories")}
                link={"/my-business/categories/b2c/create"}
              />
              <div className="company-department-container__title">
                <Link to="/my-business/categories/companies-view">
                  <DefaultButton
                    color="white"
                    backgroundColor={colorPalette.colorPrimary.main}
                    hover={colorPalette.colorPrimary.darker}
                    fontSize="15px"
                    labelHeight="20px"
                    height="100%"
                    width="100%"
                  >
                    {t("Categories by Companies")}
                  </DefaultButton>
                </Link>
              </div>
              {b2cCategoriesEditor && b2cCategoriesEditor.length ? (
                b2cCategoriesEditor.map((category, index) => (
                  <>
                    <Category
                      typeOfCategory={"b2c"}
                      key={index}
                      category={category}
                      editorState={true}
                      editLink={`/my-business/categories/b2c/${category.id}`}
                      removeFunc={() => {
                        setDialogOpen(true);
                        setCurrentCategoryId(category.id);
                        setCurrentCategoryType("b2c");
                      }}
                    />
                    {!!category?.children.length &&
                    <ChildLevel
                      children={category.children}
                      setDialogOpen={setDialogOpen}
                      categoryType="b2c"
                      setCurrentCategoryId={setCurrentCategoryId}
                      setCurrentCategoryType={setCurrentCategoryType}
                    />
                    }
                  </>
                ))
              ) : (
                <MyBusinessNoData noDataText="No B2C Categories found!" />
              )}
            </div>
          </div>
          <br />
          <div className="my-business-companies">
            <div className="company-department-container">
              <MyBusinessAddBar
                title={t("B2B Categories")}
                link={"/my-business/categories/b2b/create"}
              />
              {b2bCategoriesEditor && b2bCategoriesEditor.length ? (
                b2bCategoriesEditor.map((category, index) => (
                  <>
                    <Category
                      typeOfCategory={"b2b"}
                      key={index}
                      category={category}
                      editorState={true}
                      editLink={`/my-business/categories/b2b/${category.id}`}
                      removeFunc={() => {
                        setDialogOpen(true);
                        setCurrentCategoryId(category.id);
                        setCurrentCategoryType("b2b");
                      }}
                    />
                    {!!category?.children.length &&
                    <ChildLevel
                      children={category.children}
                      setDialogOpen={setDialogOpen}
                      categoryType="b2b"
                      setCurrentCategoryId={setCurrentCategoryId}
                      setCurrentCategoryType={setCurrentCategoryType}
                    />
                    }
                  </>
                ))
              ) : (
                <MyBusinessNoData noDataText={t("No B2B Categories found!")} />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areB2BCategoriesEditorPending: state.categoriesAPI.areB2BCategoriesEditorPending,
  areB2CCategoriesEditorPending: state.categoriesAPI.areB2CCategoriesEditorPending,
  b2bCategoriesEditor: state.categoriesAPI.b2bCategoriesEditorResults,
  b2cCategoriesEditor: state.categoriesAPI.b2cCategoriesEditorResults
});

export default connect(mapStateToProps, {
  getB2BCategoriesEditor,
  getB2CCategoriesEditor,
  deleteCategory
})(MyBusinessCategories);
