import React from "react";

import {
  LoadingSpinner,
  Pagination,
  JobCard,
  NoDataFound
} from "../../../components";

import "./job-resume.styles.scss";

const JobResume = ({
  areResumesPending,
  resumes,
  resumesPage,
  setResumesPage
}) => {
  return (
    <div>
      {areResumesPending ? (
        <LoadingSpinner />
      ) : resumes.results && resumes.results.length ? (
        resumes.results.map(({ id, title, profession, address }) => (
          <JobCard
            key={id}
            jobId={id}
            jobTitle={title}
            jobDescription={profession}
            jobLocation={address}
          />
        ))
      ) : (
        <NoDataFound
          noDataTitle="No resumes found!"
          myBusiness={true}
          additionalLink="/my-business/resume"
        />
      )}

      <Pagination
        forcePage={resumesPage - 1}
        selectedPage={page => setResumesPage(page)}
        dataCount={resumes.count}
        itemsPerPage={10}
      />
    </div>
  );
};

export default JobResume;
