import { EventsActionTypes } from "./events.types";

const INITIAL_STATE = {
  areEventsPending: false,
  events: [],
  eventsCount: 0,
  eventsError: null,

  isEventByIdPending: false,
  eventById: {},
  eventByIdError: null,

  isEventPOSTPending: false,
  eventPOST: {},
  eventPOSTError: null,

  isEventPUTPending: false,
  eventPUT: {},
  eventPUTError: null,

  areEventsByMePending: false,
  eventsByMe: [],
  eventsByMeCount: 0,
  eventsByMeError: {},

  isDeletingEventPending: false,
  deleteEvent: null,
  deleteEventError: null,

  eventRequestStatus: ""
};

const EventsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case EventsActionTypes.GET.GET_EVENTS_PENDING:
      return {
        ...state,
        areEventsPending: true
      };

    case EventsActionTypes.GET.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.events,
        eventsCount: action.payload.count,
        areEventsPending: false
      };

    case EventsActionTypes.GET.GET_EVENTS_ERROR:
      return {
        ...state,
        eventsError: action.payload,
        areEventsPending: false
      };

    case EventsActionTypes.GET.BY_ID.GET_EVENT_BY_ID_PENDING:
      return {
        ...state,
        isEventByIdPending: true
      };

    case EventsActionTypes.GET.BY_ID.GET_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        isEventByIdPending: false,
        eventById: action.payload,
        eventByIdError: null
      };

    case EventsActionTypes.GET.BY_ID.GET_EVENT_BY_ID_ERROR:
      return {
        ...state,
        isEventByIdPending: false,
        eventByIdError: action.payload
      };

    case EventsActionTypes.GET.GET_EVENTS_BY_ME_PENDING:
      return {
        ...state,
        areEventsByMePending: true
      };
    case EventsActionTypes.GET.GET_EVENTS_BY_ME_SUCCESS:
      return {
        ...state,
        eventsByMe: action.payload.events,
        eventsByMeCount: action.payload.count,
        areEventsByMePending: false
      };
    case EventsActionTypes.GET.GET_EVENTS_BY_ME_ERROR:
      return {
        ...state,
        eventsByMeError: action.payload,
        areEventsByMePending: false
      };

    case EventsActionTypes.GET.SEARCH_EVENTS_BY_ME_PENDING:
      return {
        ...state,
        areEventsByMePending: true
      };
    case EventsActionTypes.GET.SEARCH_EVENTS_BY_ME_SUCCESS:
      return {
        ...state,
        areEventsByMePending: false,
        eventsByMe: action.payload
      };
    case EventsActionTypes.GET.SEARCH_EVENTS_BY_ME_ERROR:
      return {
        ...state,
        areEventsByMePending: false,
        eventsByMeError: action.payload
      };

    case EventsActionTypes.POST.POST_EVENT_PENDING:
      return { ...state, isEventPOSTPending: true };

    case EventsActionTypes.POST.POST_EVENT_SUCCESS:
      return {
        ...state,
        isEventPOSTPending: false,
        eventPOST: action.payload,
        eventRequestStatus: action.payload.requestStatus,
        eventPOSTError: null
      };

    case EventsActionTypes.POST.ERROR:
      return {
        ...state,
        isEventPOSTPending: false,
        eventPOSTError: action.payload
      };

    case EventsActionTypes.PUT.PUT_EVENT_PENDING:
      return { ...state, isEventPUTPending: true };

    case EventsActionTypes.PUT.PUT_EVENT_SUCCESS:
      return {
        ...state,
        isEventPUTPending: false,
        eventPUT: action.payload,
        eventRequestStatus: action.payload.requestStatus,
        eventPUTError: null
      };

    case EventsActionTypes.PUT.PUT_EVENT_ERROR:
      return {
        ...state,
        isEventPUTPending: false,
        eventPUTError: action.payload
      };

    case EventsActionTypes.DELETE.DELETE_EVENT_BY_ID_PENDING:
      return {
        ...state,
        isDeletingEventPending: true
      };

    case EventsActionTypes.DELETE.DELETE_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        deleteEvent: action.payload,
        eventsByMe: action.payload,
        isDeletingEventPending: false
      };

    case EventsActionTypes.DELETE.DELETE_EVENT_BY_ID_ERROR:
      return {
        ...state,
        eventDelete: action.payload,
        isEventDeletePending: false
      };

    case EventsActionTypes.CLEAR_EVENT_REQUEST:
      return {
        ...state,
        eventRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default EventsReducer;
