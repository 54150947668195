export default function gjsCustomJSGenerator(editor) {
  const codeManager = editor.CodeManager;

  codeManager.addGenerator("js", {
    mapModel(model) {
      let code = "";
      let script = model.get("script-export") || model.get("script");
      let type = model.get("type");
      let comps = model.get("components");
      let id = model.getId();

      if (script) {
        // If the component has scripts we need to expose his ID
        let attr = model.get("attributes");
        attr = Object.assign({}, attr, { id });
        model.set("attributes", attr, { silent: 1 });
        let scrStr = model.getScriptString(script);

        // If the script was updated, I'll put its code in a separate container
        if (model.get("scriptUpdated")) {
          this.mapJs[type + "-" + id] = { ids: [id], code: scrStr };
        } else {
          let mapType = this.mapJs[type];

          if (mapType) {
            mapType.ids.push(id);
          } else {
            this.mapJs[type] = { ids: [id], code: scrStr };
          }
        }
      }

      comps.each(function(model) {
        code += this.mapModel(model);
      }, this);

      return code;
    },

    build(model) {
      this.mapJs = {};
      this.mapModel(model);

      const primary = ["translations-base", "multi-language-text"];
      const components = Object.fromEntries(
        Object.entries(this.mapJs).sort(
          (a, b) => primary.indexOf(b[0]) - primary.reverse().indexOf(a[0])
        )
      );

      console.log("generate js from components:", components);
      return Object.keys(components).reduce((tmp, index) => {
        const component = components[index];
        const ids = "#" + component.ids.join(", #");

        return `
          ${tmp}
          var items = document.querySelectorAll('${ids}');
          for (var i = 0, len = items.length; i < len; i++) {
            (function() {
              try {
                ${component.code}
              } catch (error) {
                console.log("gjs id${ids} error", error);
              }
            }.bind(items[i]))();
          }
        `;
      }, "");
    }
  });
}
