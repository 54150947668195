export default function companiesWithPaginationComponent({
  editor,
  api,
  site
}) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makePhoneSvg() {
      return `
        <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
          <g>
            <g>
              <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
		         	c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
			        c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
			        C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z" />
		        </g>
	        </g>
        </svg>
      `;
    }

    function makePointSvg() {
      return `
      <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713" style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
        <g>
          <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
		        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
		        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
		        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
            C67.542,46.276,59.159,54.659,48.855,54.659z" />
        </g>
      </svg>
      `;
    }

    function makeCompanyHtml(
      id,
      src,
      title,
      description,
      address,
      phone,
      email,
      detailsPath,
      translations
    ) {
      return `
      <div class="cwp-company">
        ${
          src
            ? `<img class="cwp-company__media" src="${src}" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />`
            : `<img class="cwp-company__media" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" />`
        }
        
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="${detailsPath}?gjs-company-id=${id}">
              ${title}
            </a>
          </h4>
          <div class="cwp-company__description">
          ${description ? description : translations["No description"]}
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              ${makePointSvg()}
              <span class="cwp-company__address">
                ${address ? address : translations["N/A"]}
              </span>
            </div>
            <div class="cwp-company__meta-item">
              ${makePhoneSvg()}
              <span class="cwp-company__phone">
                ${phone ? phone : translations["N/A"]}
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                ${email ? email : translations["N/A"]}
              </span>
            </div>
          </div>
        </div>
      </div>
      `;
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
      <div class="cwp-pagination">
        ${
          currentPage - 1
            ? `
            <button class="cwp-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="cwp-pagination__button cwp-pagination__button--disabled" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="cwp-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${
          currentPage - 4 > 0
            ? `<span class="cwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage - 2 > 0
            ? `
            <button class="cwp-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="cwp-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="cwp-pagination__button cwp-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="cwp-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${
          currentPage + 3 <= totalPagesCount
            ? `<span class="cwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="cwp-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="cwp-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="cwp-pagination__button cwp-pagination__button--disabled" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(
        ".companies-with-pagination-wrapper"
      );
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("companies-with-pagination-wrapper--preloader");
      } else {
        wrapperEl.classList.remove(
          "companies-with-pagination-wrapper--preloader"
        );
      }
    }

    function render(
      total,
      page,
      perPage,
      companies,
      branches,
      loading,
      detailsPath,
      translations
    ) {
      const wrapperEl = document.querySelector(
        ".companies-with-pagination-wrapper"
      );
      setLoading(loading);

      if (companies.length) {
        const companiesHtml = companies.reduce(
          (tmp, company) =>
            tmp +
            makeCompanyHtml(
              company.id,
              company.logo,
              company.name,
              company.short_description,
              company.address,
              company.metadata.phone,
              company.metadata.email,
              detailsPath,
              translations
            ),
          ""
        );
        const paginationHtml = makePaginationHtml(page, total, perPage);

        wrapperEl.innerHTML = `
          <div class="companies-with-pagination">
            ${companiesHtml}
            ${paginationHtml}
          </div>  
        `;
      } else {
        wrapperEl.innerHTML = `
          <p style="margin: 0">
            <span class="companies-with-pagination__empty">
              ${translations["No companies..."]}
            </span>
          </p>
        `;
        return;
      }

      const paginationButtonsEls = document.querySelectorAll(
        ".cwp-pagination__button:not(.cwp-pagination__button--disabled)"
      );
      Array.prototype.slice.call(paginationButtonsEls).map(paginationButtonEl =>
        paginationButtonEl.addEventListener("click", e => {
          const btnPage = parseInt(e.target.dataset.page);
          window.companies.setPage(btnPage);
        })
      );
    }

    if (!window.hasOwnProperty("companies")) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window.companies = {
        API_ROOT: API_ROOT,
        organization: organization,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 10,
        params: {},
        items: [],
        branches: [],
        translations: {},
        loading: false,
        detailsPath: "#",
        render() {
          console.group("companies render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.branches,
              this.loading,
              this.detailsPath,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("render");
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", parseInt(page), this.getCompanies.bind(this));
        },
        setPerPage(count) {
          this.setProp(
            "perPage",
            parseInt(count),
            this.getCompanies.bind(this)
          );
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path);
        },
        getCompanies() {
          if (this.loading) return;
          this.setLoading(true);

          const params = new URLSearchParams(
            Object.assign(
              {},
              this.params,
              { page: this.page, page_size: this.perPage },
              {
                branches: this.branches
                  .filter(branch => branch.selected)
                  .map(branch => branch.id)
                  .join()
              }
            )
          );
          return fetch(`${this.API_ROOT}/organization/company/?${params}`, {
            method: "GET"
          })
            .then(response => response.json())
            .then(response =>
              this.setCompanies(response.count, response.results)
            );
        },
        setCompanies(count, items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.render();
          }
          // Set count
          this.count = count;
          // Set items
          this.items = items;
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        getBranches(text) {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/organization/branches/?search=${text}&company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(response =>
              this.setBranches(
                this.branches
                  .filter(branch => branch.selected)
                  .concat(
                    response.results.map(branch => ({
                      id: branch.id,
                      title: branch.name,
                      selected: false
                    }))
                  )
                  .reduce(
                    (tmp, branch) =>
                      tmp.find(i => i.id === branch.id)
                        ? tmp
                        : tmp.concat([branch]),
                    []
                  )
              )
            );
        },
        setBranches(branches) {
          // Reset page
          this.page = 1;
          // Set branches
          this.branches = branches
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => (a.selected ? -1 : 1));
          // Start render cycle
          this.setLoading(false);
          this.getCompanies();
        },
        search(text) {
          if (this.loading) return;

          // Reset page
          this.page = 1;
          // Set search=text param in b2c/b2b companies request
          this.setParam("search", text);
          // Get companies
          this.getCompanies();
        }
      };
    }

    if (
      window.hasOwnProperty("companies") &&
      window.hasOwnProperty("grapesjs")
    ) {
      if (!window.companies.renderCycle.find(func => func === render)) {
        window.companies.renderCycle.push(render);
      }

      if (this.attributes.perpage) {
        window.companies.setPerPage(this.attributes.perpage.value);
      }

      window.companies.setParam("parent", "{[ organization ]}");

      if (this.attributes.detailspage) {
        window.companies.setDetailsPath(this.attributes.detailspage.value);
      }

      const translations = getTranslate("translations", this.attributes);
      window.companies.setProp("translations", translations);

      window.companies.getCompanies().then(() => window.companies.render());
    }
  };

  editor.DomComponents.addType("companies-with-pagination", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "companies-with-pagination",
    model: {
      defaults: {
        tagName: "div",
        name: "Companies With Pagination",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "number",
            label: "Companies per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "page-selector",
            label: "Company Details",
            name: "detailspage",
            modalTitle: "Select company details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["No companies...", "No description", "N/A"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
      }
    }
  });
}
