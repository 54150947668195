import React, { useState } from "react";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(theme => ({
  card: {
    background: "#fff"
  },
  success: {
    color: green[600],
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.dark,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  info: {
    color: theme.palette.primary.main,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  warning: {
    color: amber[700],
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  icon: {
    color: "hsla(0, 0%, 0%, 0.96)"
  },
  message: {
    display: "flex",
    alignItems: "center",
    color: "hsla(0, 0%, 0%, 0.96)"
  }
}));

const DefaultSnackbar = ({
  className,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left"
  },
  message = "...",
  onClose,
  variant = "info",
  open = true,
  autoHideDuration = undefined,
  ...other
} = {}) => {
  const [visible, setVisible] = useState(open);
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      {...{
        anchorOrigin,
        open: visible,
        // autoHideDuration,
        onClose: () => setVisible(false)
      }}
    >
      <SnackbarContent
        className={clsx(classes.card, className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes[variant], className)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setVisible(false)}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    </Snackbar>
  );
};

export default DefaultSnackbar;
