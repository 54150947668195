import React from "react";
import { connect } from "react-redux";

import "./newsletter.styles.scss";

import { messenger, telegram, whatsApp } from "../../assets";

import SubscriptionForm from "../subscription-form/subscription-form.component";

const Newsletter = ({ localizationData, appDirection }) => {
  const onSubmitForm = (input) => {
    console.log(input);
  };

  return (
    <div className={`newsletter ${appDirection}`}>
      <div className="newsletter-form">
        <p className="newsletter-p">
          {localizationData["Subscribe to newsletter"]}
        </p>
        <SubscriptionForm onSubmitForm={onSubmitForm} />
        <div className="social_links">
          <img src={messenger} alt="Messenger" />
          <img src={telegram} alt="Telegram" />
          <img src={whatsApp} alt="WhatsApp" />
        </div>
      </div>
      <div className="newsletter-image" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  appDirection: state.appLanguage.appDirection,
  localizationData: state.appLanguage.localizationData,
});

export default connect(mapStateToProps)(Newsletter);
