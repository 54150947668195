import React from "react";
import { Link } from "react-router-dom";

import BuilderThemes from "../builder-themes/builder-themes.component";
import Separator from "../separator/separator.components";
import DefaultButton from "../default-button/default-button.component";
import BuilderSitesSearch from "../builder-sites-search/builder-sites-search.component";
import Pagination from "../pagination/pagination.component";
import LoadingSpinner from "../loading-spinner/loading-spinner.component";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { colorPalette } from "../../styles/colorPalette";

import { DefaultDropdown } from "../../components";
import "./builder-site-card.styles.scss";

const BuilderSiteCard = ({
  site,
  organizations,
  setSearchOrganizationsHandler,
  themes,
  onSave,
  returnPath,
  create = false,
  isThemesPending,
  themeSearch,
  setThemeSearch,
  themePage,
  setThemePage,
  themesPagination,
  themeId,
  setThemeId,
  schemas,
  schemaId,
  setSchemaId,
  themeCategories,
  themeCategoryId,
  setThemeCategoryId,
  title,
  isTitleValid,
  setTitle,
  domain,
  isDomainValid,
  setDomain,
  domainError,
  organizationId,
  setOrganizationId
}) => (
  <Card className="builder-site-card">
    <CardHeader
      style={{ background: "#fafafa" }}
      title={
        <Box display="flex">
          <Box justifySelf="left" style={{ position: "absolute" }}>
            <Link to={returnPath}>
              <ChevronLeftIcon className="builder-site-card__return-icon" />
            </Link>
          </Box>
          <Box flexGrow="1" textAlign="center">
            {create ? "Create new site" : title}
          </Box>
        </Box>
      }
    />
    <CardContent className="builder-site-card__main">
      <Box
        className="builder-header"
        display="flex"
        justifyContent="space-between"
      >
        <TextField
          variant="outlined"
          placeholder={create ? "Site title" : site.title}
          fullWidth
          InputProps={{
            className: "builder-site-card__input-wrapper",
            inputProps: {
              className: "builder-site-card__input"
            }
          }}
          onChange={e => setTitle(e.target.value)}
          style={{ width: "49.5%" }}
          value={title}
          error={!!title && !isTitleValid}
          helperText={!isTitleValid && "Enter title ( a-zA-Z-_0-9 )"}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ width: "49.5%" }}
        >
          <FormControl variant="outlined" style={{ width: "27%" }}>
            <Select
              value={schemaId}
              displayEmpty
              onChange={e => setSchemaId(parseInt(e.target.value))}
              className="builder-site-card__select-wrapper"
            >
              {schemas.map(schema => (
                <MenuItem value={schema.id} key={schema.id}>
                  {schema.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            placeholder={create ? "Title or your domain" : site.domain}
            style={{ width: "72.5%" }}
            InputProps={{
              className: "builder-site-card__input-wrapper",
              inputProps: {
                className: "builder-site-card__input"
              }
            }}
            onChange={e => setDomain(e.target.value)}
            value={domain}
            error={!!domain && !isDomainValid}
            helperText={
              isDomainValid ? (
                <span style={{ lineHeight: "1.2em" }}>
                  {`
                    Please visit your cPanel in your domain provider's portal and set
                    your A-record to [IP OF THE SERVER]", enter domain name here in
                    the format your.domain . Otherwise, indicate your company
                    name (site will be hosted under our domain)
                  `}
                </span>
              ) : (
                domainError && (
                  <span style={{ lineHeight: "1.2em" }}>
                    {`Sorry, we aleady host a site with this name`}
                  </span>
                )
              )
            }
          />
        </Box>
      </Box>
      <FormControl variant="outlined" style={{ width: "100%", marginTop: 10 }}>
        {create ? (
          <DefaultDropdown
            styleTrigger={{ borderRadius: "5px" }}
            filter={true}
            inputFunc={e => setSearchOrganizationsHandler(e.target.value)}
            returnData={e => setOrganizationId(parseInt(e))}
            items={organizations}
            customTitle={"Select company/organization"}
          />
        ) : (
          <Select
            enableSearch
            value={organizationId}
            displayEmpty
            onChange={e => setOrganizationId(parseInt(e.target.value))}
            className="builder-site-card__select-wrapper"
            disabled={!create}
          >
            <MenuItem value="" disabled>
              Without organization...
            </MenuItem>
            {organizations.map(organization => (
              <MenuItem value={parseInt(organization.id)} key={organization.id}>
                {organization.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <Separator />
      {create && (
        <>
          <Box display="flex" justifyContent="space-between">
            <FormControl variant="outlined" style={{ width: "49.5%" }}>
              <Select
                value={themeCategoryId}
                displayEmpty
                onChange={setThemeCategoryId}
                className="builder-site-card__select-wrapper"
                disabled={!themeCategories.length}
              >
                <MenuItem value="">
                  {!themeCategoryId
                    ? create
                      ? "Select theme category"
                      : "No theme categories..."
                    : "All"}
                </MenuItem>
                {themeCategories.map(category => (
                  <MenuItem value={parseInt(category.id)} key={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box style={{ width: "49.5%" }}>
              <BuilderSitesSearch
                value={themeSearch}
                onSearch={setThemeSearch}
              />
            </Box>
          </Box>

          {!isThemesPending ? (
            <>
              <BuilderThemes
                themes={themes}
                value={themeId}
                onChange={e => setThemeId(parseInt(e.target.value))}
              />
              <Box style={{ marginTop: "2em" }}>
                <Pagination
                  initialPage={themesPagination.currentPage - 1}
                  selectedPage={setThemePage}
                  dataCount={themesPagination.total}
                  itemsPerPage={themesPagination.perPage}
                />
              </Box>
            </>
          ) : (
            <LoadingSpinner loadingState />
          )}
          <Separator />
        </>
      )}
    </CardContent>
    <CardActions className="builder-site-card__footer">
      <Box display="flex" justifyContent="flex-end" width="100%">
        <DefaultButton
          color="#ccc"
          hoverColor={colorPalette.colorPrimary.main}
          fontWeight="700"
          border="1px solid #ccc"
          hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
          hover="white"
          padding="7px 40px"
          transition="all 300ms ease"
          onClick={e =>
            onSave({
              id: site && site.id,
              title,
              schemaId,
              domain,
              organizationId,
              theme: create
                ? themes.find(theme => theme.id === themeId)
                : undefined
            })
          }
          ButtonOptions={{
            disabled:
              !isTitleValid || !isDomainValid || (create && !organizationId)
          }}
        >
          {create ? "Create site" : "Save site"}
        </DefaultButton>
      </Box>
    </CardActions>
  </Card>
);

export default BuilderSiteCard;
