import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core/";

import {
  Companies,
  Products,
  Events,
  News,
  BusinessProposals,
  InnovationProjects
} from "../../../assets";
import { LoadingSpinner } from "../../../components";

import { getStatistics } from "../../../redux/actions-exporter";

import "./statistics.styles.scss";

const statIcons = [
  <Products />,
  <Products />,
  <BusinessProposals />,
  <Events />,
  <Companies />,
  <News />,
  <InnovationProjects />
];

const StatisticCard = ({ icon, model, clicks, unique_clicks }) => (
  <div className="statistic-card">
    <div className="statistic-card__header">
      <div className="header-icon">{icon}</div>
      <div className="header-name">{model}</div>
    </div>
    <div className="statistic-card__field">
      <div className="field-title">Click:</div>
      <div className="field-data">
        <div>
          Unique: <span className="field-data__unique">{unique_clicks}</span>
        </div>
        <div>Total: {clicks}</div>
      </div>
    </div>
  </div>
);

const MyBusinessStatistics = ({
  getStatistics,
  areStatisticsPending,
  statistics,
  appDirection
}) => {
  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  return (
    <div className="statistics">
      {areStatisticsPending ? (
        <LoadingSpinner />
      ) : (
        <div className="statistics__cards">
          {statistics &&
            statistics.map(({ model, clicks, unique_clicks }, index) => (
              <StatisticCard
                icon={statIcons[index]}
                key={index}
                model={model}
                clicks={clicks}
                unique_clicks={unique_clicks}
              />
            ))}
        </div>
      )}
      <div className="statistics__table">
        <Table className="stats-table-data" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align={appDirection === "ltr" ? "left" : "right"}>
                Section Name
              </TableCell>
              <TableCell align="center">Unique</TableCell>
              <TableCell align="center">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statistics &&
              statistics.map(({ model, clicks, unique_clicks }, index) => (
                <TableRow key={index}>
                  <TableCell align={appDirection === "ltr" ? "left" : "right"}>
                    <div className="stats-table-data__name">{model}</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="stats-table-data__unique-clicks">
                      {unique_clicks}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="stats-table-data__total-clicks">
                      {clicks}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  areStatisticsPending: state.statistics.areStatisticsPending,
  statistics: state.statistics.statistics
});

export default connect(mapStateToProps, { getStatistics })(
  MyBusinessStatistics
);
