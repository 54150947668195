import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Clear as ClearIcon
} from "@material-ui/icons";

import { DefaultButton } from "../../components";

const DepartmentSingleData = ({
  department,
  url,
  vacancies,

  deleteDepartment,
  deleteDepartmentVacancy
}) => {
  const [
    openDepartmentVacanciesToggle,
    setOpenDepartmentVacanciesToggle
  ] = useState(false);

  return (
    <div className="department-inner-content__department-role">
      <div className="department-inner-content__department-role__header">
        <div
          onClick={() => {
            setOpenDepartmentVacanciesToggle(!openDepartmentVacanciesToggle);
          }}
          className="department-inner-content__department-role__header__header-title"
        >
          {department.name}
        </div>
        <div className="department-inner-content__department-role__header__header-crud-links">
          <Link to={`${url}/d-${department.id}/vacancy/create`}>
            <DefaultButton border="1px solid #ccc" alignItemsLabel="center">
              <AddIcon fontSize="small" style={{ padding: 2 }} />{" "}
              <span>Add vacancy</span>
            </DefaultButton>
          </Link>
          <Link to={`${url}/depart/${department.id}`}>
            <DefaultButton className="btn-edit">
              <EditIcon />
            </DefaultButton>
          </Link>
          <DefaultButton
            onClick={() =>
              deleteDepartment(
                department.id,
                department.organization && department.organization.id
              )
            }
            className="btn-remove"
          >
            <ClearIcon style={{ fontSize: 30 }} />
          </DefaultButton>
        </div>
      </div>
      <div className={`department-inner-content__department-role__vacancies `}>
        {vacancies &&
          vacancies.map(vacancy => (
            <div
              key={vacancy.id}
              className="department-inner-content__department-role__vacancies__vacancy"
            >
              <div className="department-inner-content__department-role__vacancies__vacancy__title">
                {vacancy.name}
              </div>
              <div className="department-inner-content__department-role__vacancies__vacancy__links">
                <Link to={`${url}/d-${department.id}/vacancy/${vacancy.id}`}>
                  <DefaultButton className="department-inner-content__vacancy-row-edit">
                    Edit
                  </DefaultButton>
                </Link>
                <DefaultButton
                  onClick={() =>
                    deleteDepartmentVacancy(
                      vacancy.id,
                      department.organization && department.organization.id
                    )
                  }
                  className="department-inner-content__vacancy-row-remove"
                >
                  Remove
                </DefaultButton>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DepartmentSingleData;
