import React from "react";

import CompOrgActivityExhibitions from "../comp-org-activity-exhibitions/comp-org-activity-exhibitions.component";
import CompOrgActivityProjects from "../comp-org-activity-projects/comp-org-activity-projects.component";
import CompOrgActivityTender from "../comp-org-activity-tender/comp-org-activity-tender.component";

import "./comp-org-activity-tab.styles.scss";

const CompOrgActivity = () => {
  return (
    <div className="comp-org-activity">
      <CompOrgActivityExhibitions />
      <CompOrgActivityProjects />
      <CompOrgActivityTender />
    </div>
  );
};

export default CompOrgActivity;
