const productWithPaginationStyle = `
<style> 
  .products-with-pagination-wrapper {}

  .products-with-pagination-wrapper.products-with-pagination-wrapper--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .products-with-pagination-wrapper.products-with-pagination-wrapper--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .products-with-pagination {
    display: flex;
    flex-flow: row wrap;
  }

  .pwp-product {
    min-height: 34rem;
    width: 33.33%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 20px;
  }

  @media screen and (max-width: 992px) {
    .pwp-product {
      flex-basis: 100%;
    }
  }

  .pwp-product__image {
    width: 100%;
    height: 150px;
    object-fit: scale-down;
  }

  .pwp-product__title {
    color: rgb(38, 38, 38);
    font-size: 30px;
    font-weight: 700;
    margin: 16px 0;
  }

  .pwp-product__link {
    color: inherit;
    text-decoration-color: currentColor;
  }

  .pwp-product__link:hover {
    color: inherit;
    text-decoration-color: currentColor;
  }

  .pwp-product__divider {
    width: 30%;
    height: 7px;
    background-color: #aeaeae;  
  }

  .pwp-product__description {
    width: 100%;
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    word-break: break-word;
  }

  .pwp-product__meta {}

  .pwp-product__meta--cost {
    margin: auto 0 15px;
  }

  .pwp-product__cost-title {
    color: rgb(38, 38, 38);
    font-size: 18px;
    font-weight: 700;
  }

  .pwp-product__cost {
    color: #262626;
    font-size: 18px;
    font-weight: 700;
    margin: auto 0 8px;
  }
  
  .pwp-product__cost.pwp-product__cost--special {
    display: flex;
    flex-direction: column;
  }
  
  .pwp-product__cost-old {
    text-decoration: line-through;
    font-size: 14px;
    color: #cccccc;
  }

  .pwp-product__cost.pwp-product__cost--discount {
    text-decoration-color: #676262;
    text-decoration-line: line-through;
    text-decoration-style: solid;
    font-size: 15px;
  }

  .pwp-product__discount {
    color: rgb(38, 38, 38);
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 3px;
  }

  .pwp-product__currency {
    color: rgb(38, 38, 38);
    font-size: 18px;
    font-weight: 700;
  }

  .pwp-product__discount-percent {
    border: 1px solid black;
    border-radius: 3px;
    padding: 2px 4px;
   }

  .pwp-product__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .pwp-product__button {
    background-color: #aeaeae;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 46px;
    border: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: all 300ms ease;
  }

  .pwp-product__button:hover {
    background-color: #8c8c8c;
  }

  .pwp-product__button--disabled {
    background-color: inherit !important;
    color: inherit !important;
    cursor: not-allowed;
  }

  .pwp-product__wish {
    color: #737373;
    cursor: pointer;
    user-select: none;
  }

  .pwp-product__wish--disabled {
    cursor: not-allowed;
  }

  .pwp-coupon {
    width: 100%;
  }

  .pwp-coupon__message {
    display: block;
    font-size: 1.32em;
  }

  .pwp-coupon__cost {
    font-size: 1.1em;
    font-weight: 700;
  }

  .pwp-coupon__currency {
    font-size: 1.1em;
    font-weight: 700;
  }

  .pwp-coupon__discount-percent {
    padding: 2px 4px;
    border: 1px solid black;
    border-radius: 3px;
  }

  .pwp-coupon__grid {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
  }
  
  .pwp-coupon__item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
  }

  .pwp-pagination {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    align-self: flex-end;
    margin: 0 0 0 auto;
    padding: 20px;
  }

  .pwp-pagination__separator {
    color: #aeaeae;
    font-size: 16px;
    font-weight: 800;
    padding: 0px 10px;
    margin: 0 4px;
    outline: none;
    cursor: pointer;
    vertical-align: bottom;
  }

  .pwp-pagination__button {
    background: #fff;
    color: #aeaeae;
    font-size: 16px;
    font-weight: 800;
    padding: 8px 16px;
    margin: 0 4px;
    border: 2px solid #aeaeae;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: all 300ms ease;
  }

  .pwp-pagination__button.pwp-pagination__button--active {
    color: #fff;
    background: #c0c0c0;
  }

  .pwp-pagination__button--disabled, .pwp-pagination__button:hover {
    background: #ededed;
  }
</style>
`;

export default productWithPaginationStyle;
