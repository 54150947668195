import React from "react";

import "./job-details-nav.styles.scss";

const JobDetailsNav = ({ setTab, activeTab }) => {
  return (
    <div className="job-details-nav">
      <div
        className={`job-details-nav__link ${
          activeTab === "desc" ? "job-details-nav__link--selected" : undefined
        }`}
        onClick={() => setTab("desc")}
      >
        Description
      </div>
      <div className="job-details-nav__vertical-separator" />
      <div
        className={`job-details-nav__link ${
          activeTab === "req" ? "job-details-nav__link--selected" : undefined
        }`}
        onClick={() => setTab("req")}
      >
        Requirements
      </div>
      <div className="job-details-nav__vertical-separator" />
      <div
        className={`job-details-nav__link ${
          activeTab === "term" ? "job-details-nav__link--selected" : undefined
        }`}
        onClick={() => setTab("term")}
      >
        Terms
      </div>
    </div>
  );
};

export default JobDetailsNav;
