import React, { useEffect } from "react";
import { connect } from "react-redux";

import missingImage from "../../assets/missing-image.png";
import { LoadingSpinner, ImgCard, ProductSwitch } from "../../components";

import {
  getB2BProducts,
  getRecommendedB2BProducts,
  getB2CProducts,
  getRecommendedB2CProducts,
  toggleWallProductSwitch
} from "../../redux/actions-exporter";

import "./product-wall-card.styles.scss";

const ProductWallCard = ({
  b2bProducts,
  isProductsPending,
  getB2BProducts,
  getB2CProducts,
  areB2CProductsPending,
  b2cProducts,
  wallToggled,
  toggleWallProductSwitch,
  countryId,

  getRecommendedB2BProducts,
  areRecommendedB2BProductsPending,
  recommendedB2BProducts,

  getRecommendedB2CProducts,
  areRecommendedB2CProductsPending,
  recommendedB2CProducts
}) => {
  useEffect(() => {
    getB2BProducts({ countryId });
    getB2CProducts({ countryId });

    getRecommendedB2BProducts();
    getRecommendedB2CProducts();
  }, [
    getB2BProducts,
    getB2CProducts,
    countryId,
    getRecommendedB2BProducts,
    getRecommendedB2CProducts
  ]);

  return (
    <div className="product">
      <div className="product-switch">
        <div className="product-swith-btn">
          <ProductSwitch
            isToggled={wallToggled}
            onToggle={toggleWallProductSwitch}
          />
        </div>
      </div>

      <div className="product-cards">
        {!wallToggled ? (
          isProductsPending || areRecommendedB2BProductsPending ? (
            <LoadingSpinner />
          ) : recommendedB2BProducts.length > 0 ? (
            recommendedB2BProducts
              .filter((product, idx) => idx < 6)
              .map(({ id, name, image, cost, currency }) => (
                <ImgCard
                  navLink={`/product/b2b/${id}`}
                  key={id}
                  text={name}
                  imgSrc={image ? image : missingImage}
                  price={cost}
                  currency={currency}
                  type="products"
                  scaleDown={true}
                />
              ))
          ) : (
            b2bProducts &&
            b2bProducts
              .filter((product, idx) => idx < 6)
              .map(({ id, name, image, cost, currency, categories }) => (
                <ImgCard
                  shortDescription={categories[0] && categories[0].name}
                  navLink={`/product/b2b/${id}`}
                  key={id}
                  text={name}
                  imgSrc={image ? image : missingImage}
                  price={cost}
                  currency={currency}
                  type="products"
                  scaleDown={true}
                />
              ))
          )
        ) : (!areB2CProductsPending || areRecommendedB2CProductsPending) &&
          recommendedB2CProducts.length > 0 ? (
          recommendedB2CProducts
            .filter((b2cProduct, idx) => idx < 6)
            .map(({ id, name, image, cost, currency }) => (
              <ImgCard
                navLink={`/product/b2c/${id}`}
                key={id}
                text={name}
                imgSrc={image ? image : missingImage}
                price={cost}
                currency={currency}
                type="products"
                scaleDown={true}
              />
            ))
        ) : (
          b2cProducts &&
          b2cProducts
            .filter((b2cProduct, idx) => idx < 6)
            .map(({ id, name, image, cost, currency, categories }) => (
              <ImgCard
                shortDescription={categories[0] && categories[0].name}
                navLink={`/product/b2c/${id}`}
                key={id}
                text={name}
                imgSrc={image ? image : missingImage}
                price={cost}
                currency={currency}
                type="products"
                scaleDown={true}
              />
            ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  b2bProducts: state.b2BProductsReducer.b2bProducts,
  areB2CProductsPending: state.b2cProductsReducer.areB2CProductsPending,
  b2cProducts: state.b2cProductsReducer.b2cProducts,
  wallToggled: state.productSwitch.wallToggled,
  countryId: state.countriesReducer.countryId,

  areRecommendedB2BProductsPending:
    state.b2BProductsReducer.areRecommendedB2BProductsPending,
  recommendedB2BProducts: state.b2BProductsReducer.recommendedB2BProducts,

  areRecommendedB2CProductsPending:
    state.b2cProductsReducer.areRecommendedB2CProductsPending,
  recommendedB2CProducts: state.b2cProductsReducer.recommendedB2CProducts
});

export default connect(mapStateToProps, {
  getB2BProducts,
  getB2CProducts,
  toggleWallProductSwitch,
  getRecommendedB2BProducts,
  getRecommendedB2CProducts
})(ProductWallCard);
