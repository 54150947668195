import React from "react";

import { useHistory } from "react-router-dom";
import DefaultButton from "../default-button/default-button.component";

import "./job-filter.styles.scss";

const JobFilter = ({ setCurrentTab, activeTab }) => {
  const history = useHistory();

  return (
    <div className="job-filter">
      <div className="job-filter__upper-btns">
        <div
          className={`job-filter__upper-btns__nav ${
            activeTab === "resume"
              ? "job-filter__upper-btns__nav-active"
              : undefined
          }`}
          onClick={() => {
            setCurrentTab("resume");
            history.push("/job/resumes");
          }}
        >
          <DefaultButton width="100%" fontSize="20px">
            Resume
          </DefaultButton>
        </div>
        <div className="job-filter__upper-btns__separator" />
        <div
          className={`job-filter__upper-btns__nav ${
            activeTab === "findJob"
              ? "job-filter__upper-btns__nav-active"
              : undefined
          }`}
          onClick={() => {
            setCurrentTab("findJob");
            history.push("/job/find-jobs");
          }}
        >
          <DefaultButton width="100%" fontSize="20px">
            Find job
          </DefaultButton>
        </div>
        <div />
      </div>
    </div>
  );
};

export default JobFilter;
