import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  ViewModule as ViewModuleIcon,
  ViewHeadline as ViewHeadlineIcon
} from "@material-ui/icons";

import { missingImage } from "../../assets";
import { withDebounce, arrToString } from "../../shared";

import { Filter } from "../../containers";

import {
  LoadingSpinner,
  ProductCard,
  ProductSwitch,
  DefaultIconButton,
  Pagination,
  DefaultDropdown,
  DefaultMultiLevelDropdown,
  NoDataFound
} from "../../components";

import {
  selectB2BProductPage,
  selectB2CProductPage,
  getFilterB2BProducts,
  getB2BProducts,
  getB2CProducts,
  toggleAllProductsSwitch,
  getB2BCategories,
  getB2CCategories,
  searchB2BCategory,
  searchB2CCategory,
  addProductToWishList,
  getCart,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setB2CCategoriesFilterStatus,
  setB2BCategoriesFilterStatus
} from "../../redux/actions-exporter";

import "./products.styles.scss";

const Products = ({
  appLanguage,

  location: { state: locationState },

  isAllProductsSwitchToggled,
  toggleAllProductsSwitch,

  b2bProductsNamespaces,
  isB2BProductsPending,
  b2bProductsError,
  b2bProductsCount,

  areB2CProductsPending,
  b2cProductsError,
  b2cProductsNamespaces,
  b2cProductsCount,

  getB2BCategories,
  getB2CCategories,

  b2bCategoriesResults,
  b2cCategoriesResults,

  getB2BProducts,
  getB2CProducts,

  searchB2BCategory,
  searchB2CCategory,

  setCountriesFilterStatus,
  setOrganizationsFilterStatus,

  filterCountriesArr,
  filterOrganizationsArr,
  filterB2CProducersArr,
  filterBranchesArr,

  filterB2BCategoriesArr,
  filterB2CCategoriesArr,

  setB2BCategoriesFilterStatus,
  setB2CCategoriesFilterStatus
}) => {
  const [category, setCategory] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [b2bSearchQuery, setB2BSearchQuery] = useState("");
  const [b2cSearchQuery, setB2CSearchQuery] = useState("");
  const [b2bProductsPage, setB2BProductsPage] = useState(1);
  const [b2cProductsPage, setB2CProductsPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [namespace] = useState("productsMainPage");

  const productFilterOnChange = withDebounce(e =>
    !isAllProductsSwitchToggled ? setB2BSearchQuery(e) : setB2CSearchQuery(e)
  );

  useEffect(() => {
    if (!isAllProductsSwitchToggled) {
      setCountriesFilterStatus(true);
      setOrganizationsFilterStatus(true);
      setB2CCategoriesFilterStatus(false);
      setB2BCategoriesFilterStatus(true);
    } else {
      setCountriesFilterStatus(true);
      setOrganizationsFilterStatus(true);
      setB2CCategoriesFilterStatus(true);
      setB2BCategoriesFilterStatus(false);
    }

    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
      setB2BCategoriesFilterStatus(false);
      setB2CCategoriesFilterStatus(false);
    };
  }, [
    isAllProductsSwitchToggled,
    locationState,
    setCountriesFilterStatus,
    setOrganizationsFilterStatus,
    setB2CCategoriesFilterStatus,
    setB2BCategoriesFilterStatus
  ]);

  useEffect(() => {
    setB2BProductsPage(1);
    setB2CProductsPage(1);
  }, [
    isAllProductsSwitchToggled,
    locationState,
    b2bSearchQuery,
    b2cSearchQuery,
    filterCountriesArr,
    filterOrganizationsArr,
    filterBranchesArr,
    filterB2CProducersArr,
    category
  ]);

  useEffect(() => {
    getB2BCategories();
    getB2CCategories();
  }, [getB2BCategories, getB2CCategories]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [b2bProductsPage, b2cProductsPage]);

  useEffect(() => {
    if (!locationState) {
      return;
    }

    const {
      defaultProductType = "b2b",
      additionalB2CRequestParams = {}
    } = locationState;
    const { categoryId } = additionalB2CRequestParams;
    const toggleDefaultProductType = defaultProductType === "b2c";

    setB2BSearchQuery("");
    setB2CSearchQuery("");

    if (toggleDefaultProductType) {
      toggleAllProductsSwitch(toggleDefaultProductType);
    }

    if (categoryId) {
      setCategory(categoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  useEffect(() => {
    filterB2CCategoriesArr.length &&
      setCategory(arrToString(filterB2CCategoriesArr));
  }, [filterB2CCategoriesArr]);

  useEffect(() => {
    filterB2BCategoriesArr.length &&
      setCategory(arrToString(filterB2BCategoriesArr));
  }, [filterB2BCategoriesArr]);

  useEffect(() => {
    const isB2B = !isAllProductsSwitchToggled;

    const requestPending = isB2B ? isB2BProductsPending : areB2CProductsPending;
    const requestError = isB2B ? b2bProductsError : b2cProductsError;
    if (requestPending || requestError) {
      return;
    }

    const method = isB2B ? getB2BProducts : getB2CProducts;
    const params = isB2B
      ? {
          searchQuery: b2bSearchQuery,
          page: b2bProductsPage,
          countries: arrToString(filterCountriesArr),
          companies: arrToString(filterOrganizationsArr),
          producers: arrToString(filterBranchesArr),
          categories: category || "",
          ordering: sortBy,
          perPage: itemsPerPage
        }
      : {
          searchQuery: b2cSearchQuery,
          page: b2cProductsPage,
          countries: arrToString(filterCountriesArr),
          companies: arrToString(filterOrganizationsArr),
          producers: arrToString(filterB2CProducersArr),
          categories: category || "",
          ordering: sortBy,
          perPage: itemsPerPage
        };

    const additional = { validate: { params, isB2B } };
    const namespaces = isB2B ? b2bProductsNamespaces : b2cProductsNamespaces;
    const isValidProducts =
      namespaces[namespace] &&
      JSON.stringify(namespaces[namespace].validate) ===
        JSON.stringify(additional.validate);

    setLoading(!isValidProducts);
    setProducts(isValidProducts ? namespaces[namespace].products : []);

    if (!isValidProducts) {
      method({ ...params, namespace, additional });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllProductsSwitchToggled,
    locationState,
    isB2BProductsPending,
    b2bSearchQuery,
    areB2CProductsPending,
    b2bProductsPage,
    b2cSearchQuery,
    b2cProductsPage,
    filterCountriesArr,
    filterOrganizationsArr,
    filterB2CCategoriesArr,
    filterB2BCategoriesArr,
    filterBranchesArr,
    filterB2CProducersArr,
    sortBy,
    category
  ]);

  const onToggleAllProductsSwitch = () => {
    setCategory("");
    setB2BSearchQuery("");
    setB2CSearchQuery("");
    toggleAllProductsSwitch(!isAllProductsSwitchToggled);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     isAllProductsSwitchToggled &&
  //       b2cProducts.results &&
  //       !b2cProducts.results.length &&
  //       toggleAllProductsSwitch(false);
  //   }, 3000);
  // }, [isAllProductsSwitchToggled, b2cProducts]);

  const isB2B = !isAllProductsSwitchToggled;
  const categories = isB2B ? b2bCategoriesResults : b2cCategoriesResults;
  return (
    <div className="products-inner">
      <Filter onChange={e => productFilterOnChange(e.target.value)} />
      <div style={{ width: "100%" }} className="events__body__title">
        Products
      </div>
      <div className="products">
        <div className="products__sort-upper">
          <div>
            <p>View:</p>
            <div>
              <DefaultIconButton>
                <ViewModuleIcon />
              </DefaultIconButton>

              <DefaultIconButton>
                <ViewHeadlineIcon />
              </DefaultIconButton>
            </div>
          </div>
        </div>
        <div className="products__sort-bottom">
          <div className={"products__sort-bottom__selector"}>
            <DefaultMultiLevelDropdown
              label="Category"
              inputFunc={e =>
                isB2B
                  ? searchB2BCategory(e.target.value)
                  : searchB2CCategory(e.target.value)
              }
              filter={true}
              style={{ width: "100%" }}
              items={[{ id: 0, name: "Reset category" }, ...categories]}
              customTitle={(() => {
                if (!category || !categories) {
                  return undefined;
                }

                const parentResult = categories.find(i => i.id === category);
                const childResult = categories
                  .filter(i => i.children)
                  .reduce((tmp, i) => [...tmp, ...i.children], [])
                  .find(child => child.id === category);
                const result = parentResult || childResult;
                return result ? result.name : undefined;
              })()}
              returnData={setCategory}
            />
          </div>
          <div className={"products__sort-bottom__selector"}>
            <DefaultDropdown
              label="Sort by"
              style={{ width: "100%" }}
              items={[
                { id: "-id", name: "Newest" },
                { id: "id", name: "Oldest" },
                { id: "cost", name: "Price ascending" },
                { id: "-cost", name: "Price descending" }
              ]}
              returnData={setSortBy}
            />
          </div>
        </div>

        <ProductSwitch
          isToggled={isAllProductsSwitchToggled}
          onToggle={onToggleAllProductsSwitch}
        />
        <div className="products__cards">
          {isB2B ? (
            loading ? (
              <LoadingSpinner />
            ) : products.length ? (
              products.map(product => {
                const {
                  id,
                  image,
                  name,
                  cost,
                  currency,
                  categories,
                  metadata
                } = product;
                return (
                  <ProductCard
                    navProductType="b2b"
                    productId={id}
                    key={id}
                    imgUrl={image ? image : missingImage}
                    name={categories[0] && categories[0].name}
                    description={name}
                    currency={currency}
                    price={cost}
                    currentProductData={product}
                    metadata={metadata}
                  />
                );
              })
            ) : (
              <NoDataFound
                noDataTitle="No B2B Products Found!"
                myBusiness={true}
                additionalLink="/my-business/products"
              />
            )
          ) : loading ? (
            <LoadingSpinner />
          ) : products.length ? (
            products.map(product => {
              const {
                id,
                image,
                name,
                cost,
                currency,
                categories,
                discount_percent,
                coupon_discount_percent,
                metadata
              } = product;
              return (
                <ProductCard
                  navProductType="b2c"
                  productId={id}
                  key={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={name}
                  currency={currency}
                  price={cost}
                  currentProductData={product}
                  discountPercent={discount_percent}
                  couponDiscountPercent={coupon_discount_percent}
                  metadata={metadata}
                />
              );
            })
          ) : (
            <NoDataFound
              noDataTitle="No B2C Products Found!"
              myBusiness={true}
              additionalLink="/my-business/products"
            />
          )}
        </div>
        <Pagination
          forcePage={isB2B ? b2bProductsPage - 1 : b2cProductsPage - 1}
          selectedPage={page =>
            isB2B ? setB2BProductsPage(page) : setB2CProductsPage(page)
          }
          dataCount={isB2B ? b2bProductsCount : b2cProductsCount}
          itemsPerPage={10}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  appLanguage: state.appLanguage.appLanguage,
  cart: state.cart.cart,
  isAllProductsSwitchToggled: state.productSwitch.isAllProductsSwitchToggled,

  b2bCategoriesResults: state.categoriesAPI.b2bCategoriesResults,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,

  isB2BProductsPending: state.b2BProductsReducer.isB2BProductsPending,
  b2bProductsError: state.b2BProductsReducer.b2bProductsError,
  b2bProductsNamespaces: state.b2BProductsReducer.b2bProductsNamespaces,
  b2bProductsCount: state.b2BProductsReducer.b2bProductsCount,

  areB2CProductsPending: state.b2cProductsReducer.areB2CProductsPending,
  b2cProductsError: state.b2cProductsReducer.b2cProductsError,
  b2cProductsNamespaces: state.b2cProductsReducer.b2cProductsNamespaces,
  b2cProductsCount: state.b2cProductsReducer.b2cProductsCount,

  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr,
  filterB2CProducersArr: state.filterReducer.filterB2CProducersArr,
  filterBranchesArr: state.filterReducer.filterBranchesArr,
  filterB2BCategoriesArr: state.filterReducer.filterB2BCategoriesArr,
  filterB2CCategoriesArr: state.filterReducer.filterB2CCategoriesArr
});

export default connect(mapStateToProps, {
  getCart,
  selectB2BProductPage,
  selectB2CProductPage,
  getB2CProducts,
  toggleAllProductsSwitch,
  getFilterB2BProducts,
  getB2BCategories,
  getB2CCategories,
  getB2BProducts,
  searchB2BCategory,
  searchB2CCategory,
  addProductToWishList,

  setCountriesFilterStatus,
  setOrganizationsFilterStatus,

  setB2CCategoriesFilterStatus,
  setB2BCategoriesFilterStatus
})(Products);
