import { ProductVariationsTypes } from "./product-variations.types";

const INITIAL_STATE = {
  areProductVariationsByParentPending: false,
  productVariationsByParent: false,
  productVariationsByParentError: false,

  isProductVariationByIdPending: false,
  productVariationById: {},
  productVariationByIdError: {},

  isProductVariationAdding: false,
  productVariationAdded: null,
  productVariationAddedError: null,

  isProductVariationPutting: false,
  productVariationPutted: null,
  productVariationPuttedError: null,

  productVariationsRequestStatus: ""
};

const ProductVariationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductVariationsTypes.GET.GET_PRODUCT_VARIATIONS_BY_PARENT_ID_PENDING:
      return {
        ...state,
        areProductVariationsByParentPending: true
      };

    case ProductVariationsTypes.GET.GET_PRODUCT_VARIATIONS_BY_PARENT_ID_SUCCESS:
      return {
        ...state,
        areProductVariationsByParentPending: false,
        productVariationsByParent: action.payload
      };

    case ProductVariationsTypes.GET.GET_PRODUCT_VARIATIONS_BY_PARENT_ID_ERROR:
      return {
        ...state,
        areProductVariationsByParentPending: false,
        productVariationsByParentError: action.payload
      };

    case ProductVariationsTypes.GET.GET_PRODUCT_VARIATION_BY_ID_PENDING:
      return {
        ...state,
        isProductVariationByIdPending: true
      };

    case ProductVariationsTypes.GET.GET_PRODUCT_VARIATION_BY_ID_SUCCESS:
      return {
        ...state,
        isProductVariationByIdPending: false,
        productVariationById: action.payload
      };

    case ProductVariationsTypes.GET.GET_PRODUCT_VARIATION_BY_ID_ERROR:
      return {
        ...state,
        isProductVariationByIdPending: false,
        productVariationByIdError: action.payload
      };

    case ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_PENDING:
      return {
        ...state,
        isProductVariationAdding: true
      };

    case ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_SUCCESS:
      return {
        ...state,
        isProductVariationAdding: false,
        productVariationsRequestStatus: action.payload.requestStatus,
        productVariationAdded: action.payload
      };

    case ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_ERROR:
      return {
        ...state,
        isProductVariationAdding: false,
        productVariationAddedError: action.payload
      };

    case ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_PENDING:
      return {
        ...state,
        isProductVariationPutting: true
      };

    case ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_SUCCESS:
      return {
        ...state,
        isProductVariationPutting: false,
        productVariationsRequestStatus: action.payload.requestStatus,
        productVariationPutted: action.payload
      };

    case ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_ERROR:
      return {
        ...state,
        isProductVariationPutting: false,
        productVariationPuttedError: action.payload
      };

    case ProductVariationsTypes.CLEAR_PRODUCT_VARIATION_REQUEST_STATUS:
      return {
        ...state,
        productVariationsRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default ProductVariationsReducer;
