export const LocalizationActionTypes = {
  SET_APPLICATION_DIRECTION: "SET_APPLICATION_DIRECTION",
  SET_APPLICATION_LANGUAGE_PENDING: "SET_APPLICATION_LANGUAGE_PENDING",
  SET_APPLICATION_LANGUAGE_SUCCESS: "SET_APPLICATION_LANGUAGE_SUCCESS",

  GET_LOCALIZATION_DATA_PENDING: "GET_LOCALIZATION_DATA_PENDING",
  GET_LOCALIZATION_DATA_SUCCESS: "GET_LOCALIZATION_DATA_SUCCESS",
  GET_LOCALIZATION_DATA_ERROR: "GET_LOCALIZATION_DATA_ERROR",

  SET_BURGER_TOGGLE_OPEN: "SET_BURGER_TOGGLE_OPEN"
};
