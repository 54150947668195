import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import {
  NavLink,
  Route,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import parse from "html-react-parser";

import { getCookie } from "../../axios/axios.config";
import {
  LoadingSpinner,
  OrganizationCard,
  CompOrgMainTab,
  OrgCompTab,
  CompOrgFilesTab,
  CompOrgActivityTab,
  CompOrgOffersTab,
  CompOrgStructureTab,
  CompOrgContactsTab
} from "../../components";
import {
  getOrganization,
  getNewsByCompanyId,
  getWishList
} from "../../redux/actions-exporter";

import "./organization.styles.scss";

const Organization = ({
  getOrganization,
  isOrganizationPending,
  organization,

  getNewsByCompanyId,
  areNewsByCompanyIdPending,
  newsByCompanyId,

  getWishList
}) => {
  let { organizationId } = useParams();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    const authCookie = getCookie("Authorization");
    getOrganization(organizationId);
    getNewsByCompanyId(organizationId);
    if (authCookie) {
      getWishList();
    }
  }, [getOrganization, organizationId, getNewsByCompanyId, getWishList]);

  useEffect(() => {
    const { metadata } = organization;

    if (metadata && metadata.script) {
      let parser = new DOMParser(),
        API;
      const parsedData = parser.parseFromString(metadata.script, "text/html");
      const allScriptTags = parsedData.head.getElementsByTagName("script");
      for (let i = 0; i < allScriptTags.length; i++) {
        const script = document.createElement("script");
        if (allScriptTags[i].src) {
          script.src = allScriptTags[i].src;
          API = allScriptTags[i].getAttribute("api");
          API = API ? JSON.parse(API) : false;
          if (API && Array.isArray(API.widgets)) {
            API.widgets.map((widget, index) => {
              widget.block = "organization-script";
              return widget;
            });
            let liveChatWidget = { ...API.widgets[0] };
            liveChatWidget.type = "message";
            liveChatWidget.block = "widget-live";
            liveChatWidget.height = 350;
            liveChatWidget.width = 350;
            API.widgets.push(liveChatWidget);
          } else if (API) {
            API.widgets.block = "organization-script";
            API.widgets = [{ ...API.widgets }, { ...API.widgets }];
            API.widgets[1].type = "message";
            API.widgets[1].block = "widget-live";
            API.widgets[1].height = 350;
            API.widgets[1].width = 350;
          }
          script.setAttribute("api", JSON.stringify(API));
        } else script.innerHTML = allScriptTags[i].innerHTML;
        script.setAttribute("id", `organizationScript${i}`);
        if (!document.getElementById(`organizationScript${i}`))
          document.getElementById("organization-script").appendChild(script);
      }
    }
  }, [isOrganizationPending, organization]);

  return (
    <div id="organization-script" className="organization">
      {isOrganizationPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <OrganizationCard
            title={organization.name}
            shortDescription={parse(organization.short_description)}
            logo={organization.logo}
            isMember={false}
          />

          <div className="nav">
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/main`}
              className="nav-link"
            >
              Main
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/companies`}
              className="nav-link"
            >
              Companies
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/files`}
              className="nav-link"
            >
              Files
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/activity`}
              className="nav-link"
            >
              Activity
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/offers`}
              className="nav-link"
            >
              Offers
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/structure`}
              className="nav-link"
            >
              Structure
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/contacts`}
              className="nav-link"
            >
              Contacts
            </NavLink>
          </div>

          {organization && organization.additional_pages.length ? (
            <div className="nav nav-additional">
              {organization.additional_pages.map(page => {
                return (
                  <Fragment>
                    <NavLink
                      activeClassName="nav-link-selected"
                      to={`${url}/additional-${page.id}`}
                      className="nav-link"
                    >
                      {page.title.length > 20
                        ? page.title.substring(0, 20) + "..."
                        : page.title}
                    </NavLink>
                    <div className="nav-link__separator" />
                  </Fragment>
                );
              })}
            </div>
          ) : null}

          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/offers`} />}
          />
          {!areNewsByCompanyIdPending && (
            <Route
              exact
              strict
              path={`${path}/main`}
              component={() => (
                <CompOrgMainTab
                  news={newsByCompanyId}
                  companySelected={false}
                />
              )}
            />
          )}

          <Route path={`${path}/companies`} component={() => <OrgCompTab />} />
          <Route
            path={`${path}/files`}
            component={() => (
              <CompOrgFilesTab
                photos={organization.galleries}
                documents={organization.documents}
                companySelected={false}
              />
            )}
          />
          <Route
            path={`${path}/activity`}
            component={() => <CompOrgActivityTab companySelected={false} />}
          />
          <Route
            path={`${path}/offers`}
            component={() => (
              <CompOrgOffersTab
                routeParam={organizationId}
                productsType="organization"
                companyBranches={organization && organization.branches}
                // companySelected={false}
              />
            )}
          />
          <Route
            path={`${path}/structure`}
            component={() => (
              <CompOrgStructureTab
                companyId={organizationId}
                companySelected={false}
              />
            )}
          />
          <Route
            path={`${path}/contacts`}
            component={() => (
              <CompOrgContactsTab
                organizationName={organization && organization.name}
                metadata={organization && organization.metadata}
                companySelected={false}
              />
            )}
          />

          {organization && organization.additional_pages.length
            ? organization.additional_pages.map(additionalRoutes => (
                <Route
                  path={`${path}/additional-${additionalRoutes.id}`}
                  component={() => (
                    <div className="organization__additional-tab">
                      <h3>
                        {additionalRoutes.title.length > 40
                          ? additionalRoutes.title.substring(0, 40) + "..."
                          : additionalRoutes.title}
                      </h3>
                      <div className="organization__additional-content">
                        {parse(additionalRoutes.content)}
                      </div>
                    </div>
                  )}
                />
              ))
            : null}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isOrganizationPending: state.organizationsReducer.isOrganizationPending,
  organization: state.organizationsReducer.organization,

  areNewsByCompanyIdPending: state.newsReducer.areNewsByCompanyIdPending,
  newsByCompanyId: state.newsReducer.newsByCompanyId.results
});

export default connect(mapStateToProps, {
  getOrganization,
  getNewsByCompanyId,
  getWishList
})(Organization);
