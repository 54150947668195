import React from "react";
// import { css } from "@emotion/core";
import SkewLoader from "react-spinners/SkewLoader";

import { colorPalette } from "../../styles/colorPalette";

import "./loading-spinner.styles.scss";

// const override = css`
//   transform: translate(-50%, -50%);
// `;

const LoadingSpinner = ({ loadingState = true }) => {
  return (
    <div className={loadingState ? "loading-spinner" : undefined}>
      <SkewLoader
        // css={override}
        sizeUnit={"px"}
        size={40}
        color={colorPalette.colorPrimary.main}
        loading={loadingState}
      />
    </div>
  );
};

export default LoadingSpinner;
