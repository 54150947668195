import icon from "./icon.png";

export default function preloaderBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .preloader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    transition: all 300ms linear;
  }

  .preloader.preloader--active {
    visibility: visible;
    opacity: 1;
  }

  .preloader__additional-content {
    min-height: 80px;
    min-width: 80px;
  }

  .preloader__loader {
    color: #ff9a44;
    z-index: 1000;
  }

  /** CIRCLE **/
  .preloader__loader.preloader__loader--circle {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: currentColor;
    animation: preloader-animation-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  @keyframes preloader-animation-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

  /** DUAL RING **/
  .preloader__loader.preloader__loader--dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: preloader-animation-dual-ring 1.2s linear infinite;
  }

  @keyframes preloader-animation-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .preloader__loader.preloader__loader--facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: currentColor;
    animation: preloader-animation-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  .preloader__loader.preloader__loader--facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .preloader__loader.preloader__loader--facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .preloader__loader.preloader__loader--facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }

  @keyframes preloader-animation-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
  /** HEART **/
  .preloader__loader.preloader__loader--heart {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
  }

  .preloader__loader.preloader__loader--heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: currentColor;
    animation: preloader-animation-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .preloader__loader.preloader__loader--heart div:after,
  .preloader__loader.preloader__loader--heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: currentColor;
  }

  .preloader__loader.preloader__loader--heart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }

  .preloader__loader.preloader__loader--heart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }

  @keyframes preloader-animation-heart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }

  /** RING **/
  .preloader__loader.preloader__loader--ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid currentColor;
    border-radius: 50%;
    animation: preloader-animation-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }

  .preloader__loader.preloader__loader--ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .preloader__loader.preloader__loader--ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .preloader__loader.preloader__loader--ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes preloader-animation-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /** ROLLER **/
  .preloader__loader.preloader__loader--roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--roller div {
    animation: preloader-animation-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }

  .preloader__loader.preloader__loader--roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: currentColor;
    margin: -4px 0 0 -4px;
  }

  .preloader__loader.preloader__loader--roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .preloader__loader.preloader__loader--roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }

  @keyframes preloader-animation-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /** DEFAULT **/
  .preloader__loader.preloader__loader--default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: currentColor;
    border-radius: 50%;
    animation: preloader-animation-default 1.2s linear infinite;
  }

  .preloader__loader.preloader__loader--default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .preloader__loader.preloader__loader--default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }

  @keyframes preloader-animation-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

  /** ELLIPSIS **/
  .preloader__loader.preloader__loader--ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .preloader__loader.preloader__loader--ellipsis div:nth-child(1) {
    left: 8px;
    animation: preloader-animation-ellipsis1 0.6s infinite;
  }
  .preloader__loader.preloader__loader--ellipsis div:nth-child(2) {
    left: 8px;
    animation: preloader-animation-ellipsis2 0.6s infinite;
  }
  .preloader__loader.preloader__loader--ellipsis div:nth-child(3) {
    left: 32px;
    animation: preloader-animation-ellipsis2 0.6s infinite;
  }
  .preloader__loader.preloader__loader--ellipsis div:nth-child(4) {
    left: 56px;
    animation: preloader-animation-ellipsis3 0.6s infinite;
  }

  @keyframes preloader-animation-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes preloader-animation-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes preloader-animation-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  /** GRID **/
  .preloader__loader.preloader__loader--grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: currentColor;
    animation: preloader-animation-grid 1.2s linear infinite;
  }

  .preloader__loader.preloader__loader--grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .preloader__loader.preloader__loader--grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }

  @keyframes preloader-animation-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  /** HOURGLASS **/
  .preloader__loader.preloader__loader--hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: preloader-animation-hourglass 1.2s infinite;
  }

  @keyframes preloader-animation-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

  /** RIPPLE **/
  .preloader__loader.preloader__loader--ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--ripple div {
    position: absolute;
    border: 4px solid currentColor;
    opacity: 1;
    border-radius: 50%;
    animation: preloader-animation-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .preloader__loader.preloader__loader--ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes preloader-animation-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

  /** SPINNER **/
  .preloader__loader.preloader__loader--spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .preloader__loader.preloader__loader--spinner div {
    transform-origin: 40px 40px;
    animation: preloader-animation-spinner 1.2s linear infinite;
  }

  .preloader__loader.preloader__loader--spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: currentColor;
  }

  .preloader__loader.preloader__loader--spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .preloader__loader.preloader__loader--spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }

  @keyframes preloader-animation-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }  
  </style>
  `;

  const content = `
  ${style}
  <div class="preloader">
    <div class="preloader__additional-content preloader__additional-content--top">
    </div>

    <div class="preloader__loader preloader__loader--default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="preloader__additional-content preloader__additional-content--bottom">
    </div>
  </div>
  `;

  blockManager.add("preloader", {
    label: `
      <img src="${icon}" alt="preloader block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Preloader
      </div>
    `,
    content,
    category: "B24Online Extra"
  });
}
