export const JobsActionTypes = {
  GET: {
    GET_JOBS_PENDING: "GET_JOBS_PENDING",
    GET_JOBS_SUCCESS: "GET_JOBS_SUCCESS",
    GET_JOBS_ERROR: "GET_JOBS_ERROR",

    GET_JOB_PENDING: "GET_JOB_PENDING",
    GET_JOB_SUCCESS: "GET_JOB_SUCCESS",
    GET_JOB_ERROR: "GET_JOB_ERROR",

    GET_JOBS_CREATED_BY_ID_PENDING: "GET_JOBS_CREATED_BY_ID_PENDING",
    GET_JOBS_CREATED_BY_ID_SUCCESS: "GET_JOBS_CREATED_BY_ID_SUCCESS",
    GET_JOBS_CREATED_BY_ID_ERROR: "GET_JOBS_CREATED_BY_ID_ERROR"
  }
};
