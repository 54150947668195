import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { FilterAccordionItem } from "../../components";
import { withDebounce, arrToString } from "../../shared";

import {
  searchCountry,
  addCountryFilter,
  deleteCountryFilter,
  searchOrganizations,
  addOrganizationFilter,
  deleteOrganizationFilter,
  getCountriesFilter,
  getOrganizationsFilter,
  getB2CCategoriesFilter,
  getB2BCategoriesFilter,
  addB2CCategoryFilter,
  addB2BCategoryFilter,
  deleteB2CCategoryFilter,
  deleteB2BCategoryFilter,
  searchB2BCategory,
  searchB2CCategory
} from "../../redux/actions-exporter";

import "./filter-accordion.styles.scss";

const FilterAccordion = ({
  appDirection,

  countriesFilterActive,
  getCountriesFilter,
  countriesFilter,
  addCountryFilter,
  deleteCountryFilter,
  filterCountriesArr,

  organizationsFilterActive,
  addOrganizationFilter,
  deleteOrganizationFilter,
  getOrganizationsFilter,
  organizationsFilter,
  filterOrganizationsArr,

  getB2CCategoriesFilter,
  getB2BCategoriesFilter,
  b2bCategoriesFilter,
  b2cCategoriesFilter,
  categoriesB2BFilterActive,
  categoriesB2CFilterActive,
  filterB2CCategoriesArr,
  filterB2BCategoriesArr,
  addB2CCategoryFilter,
  addB2BCategoryFilter,
  deleteB2CCategoryFilter,
  deleteB2BCategoryFilter
}) => {
  const [countrySearchInput, setCountrySearchInput] = useState("");
  const [organizationSearchInput, setOrganizationSearchInput] = useState("");
  const [b2cCategorySearchInput, setB2CCategorySearchInput] = useState("");
  const [b2bCategorySearchInput, setB2BCategorySearchInput] = useState("");

  useEffect(() => {
    getCountriesFilter(countrySearchInput);
  }, [getCountriesFilter, countrySearchInput]);

  useEffect(() => {
    getOrganizationsFilter(
      organizationSearchInput,
      arrToString(filterCountriesArr)
    );
  }, [getOrganizationsFilter, filterCountriesArr, organizationSearchInput]);

  useEffect(() => {
    getB2CCategoriesFilter(
      b2cCategorySearchInput,
      arrToString(filterB2CCategoriesArr)
    );
    getB2BCategoriesFilter(
      b2bCategorySearchInput,
      arrToString(filterB2BCategoriesArr)
    );
  }, [
    getB2CCategoriesFilter,
    getB2BCategoriesFilter,
    b2cCategorySearchInput,
    b2bCategorySearchInput,
    filterB2CCategoriesArr,
    filterB2BCategoriesArr
  ]);

  const setCountrySearchInputDebounced = withDebounce(query =>
    setCountrySearchInput(query)
  );
  const setOrganizationSearchInputDebounced = withDebounce(query =>
    setOrganizationSearchInput(query)
  );

  const setB2CCategorySearchInputDebounced = withDebounce(query =>
    setB2CCategorySearchInput(query)
  );

  const setB2BCategorySearchInputDebounced = withDebounce(query =>
    setB2BCategorySearchInput(query)
  );

  return (
    <div className="filter-accordion" dir={appDirection}>
      {countriesFilterActive && (
        <FilterAccordionItem
          heading="Countries"
          // isDataPending={areCountriesPending}
          searchInputValue={countrySearchInput}
          searchInputHandler={setCountrySearchInputDebounced}
          searchResults={countriesFilter}
          addFilter={addCountryFilter}
          deleteFilter={deleteCountryFilter}
          filteredData={filterCountriesArr}
        />
      )}

      {organizationsFilterActive && (
        <FilterAccordionItem
          heading="Партнеры"
          // isDataPending={isOrganizationsSearchResultPending}
          searchInputValue={organizationSearchInput}
          searchInputHandler={setOrganizationSearchInputDebounced}
          searchResults={organizationsFilter}
          addFilter={addOrganizationFilter}
          deleteFilter={deleteOrganizationFilter}
          filteredData={filterOrganizationsArr}
        />
      )}

      {categoriesB2CFilterActive && (
        <FilterAccordionItem
          heading="Категории"
          searchInputValue={b2cCategorySearchInput}
          searchInputHandler={setB2CCategorySearchInputDebounced}
          searchResults={b2cCategoriesFilter}
          addFilter={addB2CCategoryFilter}
          deleteFilter={deleteB2CCategoryFilter}
          filteredData={filterB2CCategoriesArr}
        />
      )}

      {categoriesB2BFilterActive && (
        <FilterAccordionItem
          heading="Категории"
          searchInputValue={b2bCategorySearchInput}
          searchInputHandler={setB2BCategorySearchInputDebounced}
          searchResults={b2bCategoriesFilter}
          addFilter={addB2BCategoryFilter}
          deleteFilter={deleteB2BCategoryFilter}
          filteredData={filterB2BCategoriesArr}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,

  countriesFilter: state.filterAPI.countriesFilter,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  countriesFilterActive: state.filterReducer.countriesFilterActive,

  organizationsFilter: state.filterAPI.organizationsFilter,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr,
  organizationsFilterActive: state.filterReducer.organizationsFilterActive,

  b2cCategoriesFilter: state.filterAPI.b2cCategoriesFilter,
  b2bCategoriesFilter: state.filterAPI.b2bCategoriesFilter,
  categoriesB2CFilterActive: state.filterReducer.categoriesB2CFilterActive,
  categoriesB2BFilterActive: state.filterReducer.categoriesB2BFilterActive,
  filterB2BCategoriesArr: state.filterReducer.filterB2BCategoriesArr,
  filterB2CCategoriesArr: state.filterReducer.filterB2CCategoriesArr
});

export default connect(mapStateToProps, {
  searchCountry,
  addCountryFilter,
  deleteCountryFilter,

  searchOrganizations,
  addOrganizationFilter,
  deleteOrganizationFilter,

  getCountriesFilter,
  getOrganizationsFilter,

  searchB2BCategory,
  getB2CCategoriesFilter,
  getB2BCategoriesFilter,
  searchB2CCategory,
  addB2CCategoryFilter,
  addB2BCategoryFilter,
  deleteB2BCategoryFilter,
  deleteB2CCategoryFilter
})(FilterAccordion);
