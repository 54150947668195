import { JobsActionTypes } from "./jobs.types";

const INITIAL_STATE = {
  areJobsPending: false,
  jobsCount: 0,
  jobs: [],
  jobsError: null,

  isJobPending: false,
  job: {},
  jobError: null,

  areJobCreatedByIdPending: false,
  jobsCreatedByMe: [],
  jobsCreatedByMeCount: 0,
  jobsCreatedByMeError: {}
};

const JobsAPIReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case JobsActionTypes.GET.GET_JOBS_PENDING:
      return { ...state, areJobsPending: true };

    case JobsActionTypes.GET.GET_JOBS_SUCCESS:
      return {
        ...state,
        jobsCount: action.payload.count,
        jobs: action.payload.jobs,
        areJobsPending: false
      };

    case JobsActionTypes.GET.GET_JOBS_ERROR:
      return {
        ...state,
        jobsError: action.payload,
        areJobsPending: false
      };

    case JobsActionTypes.GET.GET_JOB_PENDING:
      return { ...state, isJobPending: true };

    case JobsActionTypes.GET.GET_JOB_SUCCESS:
      return {
        ...state,
        isJobPending: false,
        job: action.payload,
        jobError: null
      };

    case JobsActionTypes.GET.GET_JOB_ERROR:
      return {
        ...state,
        isJobPending: false,
        jobError: action.payload
      };

    case JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_PENDING:
      return {
        ...state,
        areJobCreatedByIdPending: true
      };

    case JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_SUCCESS:
      return {
        ...state,
        jobsCreatedByMe: action.payload.jobs,
        jobsCreatedByMeCount: action.payload.count,
        areJobCreatedByIdPending: false
      };

    case JobsActionTypes.GET.GET_JOBS_CREATED_BY_ID_ERROR:
      return {
        ...state,
        jobsCreatedByMeError: action.payload,
        areJobCreatedByIdPending: false
      };

    default:
      return state;
  }
};

export default JobsAPIReducer;
