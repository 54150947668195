import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

import { UserPlaceholder } from "../../../../assets";
import {
  MyBusinessNoData,
  DefaultButton,
  Separator
} from "../../../../components";

import { getMeetingInvitationsByEvent } from "../../../../redux/actions-exporter";

import { colorPalette } from "../../../../styles/colorPalette";

const MeetingSection = ({
  eventId,
  getMeetingInvitationsByEvent,
  meetingInvitationsByEvent
}) => {
  const { url } = useRouteMatch();

  useEffect(() => {
    getMeetingInvitationsByEvent(eventId);
  }, [getMeetingInvitationsByEvent, eventId]);

  return (
    <div className="my-business-event-invitations-inner-info__meeting-section">
      <div className="meeting-add-user">
        <Link to={`${url}/create`}>
          <DefaultButton
            color={colorPalette.colorPrimary.main}
            hoverColor="white"
            fontWeight="700"
            border={`1px solid ${colorPalette.colorPrimary.main}`}
            hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
            hover={colorPalette.colorPrimary.main}
            padding="4px 19px"
            width="100%"
            transition="all 300ms ease"
            margin="0 10px 0 0"
          >
            Add user
          </DefaultButton>
        </Link>
      </div>
      <Separator />
      <div className="meeting-list">
        {meetingInvitationsByEvent.results &&
        meetingInvitationsByEvent.results.length ? (
          <Table className="meeting-table" aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="left">TITLE</TableCell>
                <TableCell align="left">START/END MEETING</TableCell>
                <TableCell align="left">VISITOR</TableCell>
                <TableCell align="left">MEETING RESPONSE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetingInvitationsByEvent.results &&
                meetingInvitationsByEvent.results.map(
                  ({
                    id,
                    title,
                    start_date,
                    end_date,
                    visitor: { avatar, user_email },
                    meeting_response
                  }) => (
                    <TableRow key={id}>
                      <TableCell align="left">
                        <div className="meeting-table__name">
                          {title.length < 15
                            ? title
                            : title.substring(0, 15) + "..."}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="meeting-table__date">
                          <div className="meet-start">
                            {start_date &&
                              moment(start_date).format("MMMM Do YYYY, h:mm")}
                          </div>
                          <div className="meet-end">
                            {end_date &&
                              moment(end_date).format("MMMM Do YYYY, h:mm")}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="meeting-table__visitor">
                          {avatar ? (
                            <img className="visitor-img" src={avatar} alt="" />
                          ) : (
                            <UserPlaceholder className="visitor-img" />
                          )}
                          <div className="visitor-email">{user_email}</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="meeting-table__response">
                          {meeting_response}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        ) : (
          <MyBusinessNoData noDataText="No event invitations!" />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  areMeetingInvitationsByEventPending:
    state.eventInvitations.areMeetingInvitationsByEventPending,
  meetingInvitationsByEvent: state.eventInvitations.meetingInvitationsByEvent
});

export default connect(mapStateToProps, { getMeetingInvitationsByEvent })(
  MeetingSection
);
