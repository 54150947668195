import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  InputGroup,
  DefaultButton,
  DefaultDropdown,
  DefaultCheckbox
} from "../../../components";

import {
  createShippingMethod,
  clearDeliveryRequest,
  putShippingMethod,
  getShippingMethodsById,
  getCompaniesByUserId,
  getCurrencies
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-delivery-edit.styles.scss";

const MyBusinessDeliveryEdit = ({
  createShippingMethod,
  getCompaniesByUserId,
  currencies,
  userCompanies,
  getCurrencies,
  deliveryRequestStatus,
  putShippingMethod,
  getShippingMethodsById,
  shippingMethodById,
  clearDeliveryRequest,
  appDirection
}) => {
  const [shippingName, setShippingName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [deliveryCurrency, setDeliveryCurrency] = useState("");
  const [freeDeliveryStarting, setFreeDeliveryStarting] = useState("");
  const [flatFee, setFlatFee] = useState("");
  const [percentageFee, setPercentageFee] = useState("");
  const [description, setDescription] = useState("");
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const history = useHistory();
  const { deliveryId } = useParams();

  useEffect(() => {
    getCompaniesByUserId();
    getCurrencies();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (deliveryId !== "create") {
      getShippingMethodsById(deliveryId);
    }
  }, [deliveryId, getShippingMethodsById]); // eslint-disable-line

  useEffect(() => {
    if (deliveryId !== "create") {
      const {
        name,
        description,
        currency,
        company,
        free_delivery_from,
        flat_fee,
        percentage_fee
      } = shippingMethodById;

      setShippingName(name);
      setCompanyId(company && company.id);
      setDeliveryCurrency(currency);
      setFreeDeliveryStarting(free_delivery_from);
      setFlatFee(flat_fee);
      setPercentageFee(percentage_fee);
      setDescription(description);
    }
  }, [deliveryId, shippingMethodById]);

  useEffect(() => {
    if (deliveryRequestStatus === 200 || deliveryRequestStatus === 201) {
      history.push("/my-business/delivery");
    }
    setButtonDisabled(
      shippingName === "" ||
        companyId === "" ||
        description === "" ||
        deliveryCurrency === ""
    );
    // eslint-disable-next-line
  }, [
    deliveryRequestStatus,
    deliveryCurrency,
    shippingName,
    companyId,
    description
  ]);

  useEffect(() => () => clearDeliveryRequest(), []); // eslint-disable-line

  const cancelDelivery = () => {
    history.push("/my-business/delivery");
  };

  const editDelivery = () => {
    if (deliveryId === "create") {
      createShippingMethod(
        shippingName,
        deliveryCurrency,
        parseInt(freeDeliveryStarting),
        parseInt(flatFee),
        parseInt(percentageFee),
        description,
        companyId
      );
    } else {
      putShippingMethod(
        deliveryId,
        shippingName,
        deliveryCurrency,
        parseInt(freeDeliveryStarting),
        parseInt(flatFee),
        parseInt(percentageFee),
        description,
        companyId
      );
    }
  };

  return (
    <div dir={appDirection} className="my-business-delivery-edit">
      <div className="my-business-delivery-edit__row">
        <InputGroup
          label="SHIPPING METHODS NAME"
          name="shippingName"
          placeholder="Shipping name..."
          onChange={e => setShippingName(e.target.value)}
          value={shippingName}
          required={true}
        />
        <DefaultDropdown
          style={{ width: "100%" }}
          label="COMPANY"
          items={userCompanies}
          returnData={setCompanyId}
          customTitle={
            deliveryId !== "create" &&
            shippingMethodById.company &&
            shippingMethodById.company.name
          }
          required={true}
        />
      </div>
      <div
        style={{ alignItems: "flex-end" }}
        className="my-business-delivery-edit__row"
      >
        <DefaultDropdown
          label="DELIVERY CURRENCY"
          items={currencies.map(currency => ({
            id: currency.unit,
            name: currency.label
          }))}
          returnData={setDeliveryCurrency}
          customTitle={deliveryId !== "create" && shippingMethodById.currency}
          required={true}
        />
        <InputGroup
          label="FREE DELIVERY STARTING FROM"
          name="freeDeliveryStarting"
          placeholder="Free Delivery Starting From"
          onChange={e => setFreeDeliveryStarting(e.target.value)}
          value={freeDeliveryStarting}
        />
        <div className="my-business-delivery-edit__delivery-check">
          <DefaultCheckbox
            title="Cash on delivery"
            checkboxValue={cashOnDelivery}
            setCheckboxChange={setCashOnDelivery}
          />
        </div>
      </div>
      <div className="my-business-delivery-edit__row">
        <InputGroup
          label="FLAT FEE"
          name="flatFee"
          placeholder="Flat fee..."
          onChange={e => setFlatFee(e.target.value)}
          value={flatFee}
        />
        <InputGroup
          label="PERCENTAGE FEE"
          name="percentageFee"
          placeholder="Percentage fee..."
          onChange={e => setPercentageFee(e.target.value)}
          value={percentageFee}
        />
        <div />
      </div>
      <div className="my-business-delivery-edit__row">
        <InputGroup
          label="Description"
          name="deliveryDescription"
          placeholder="Description..."
          onChange={e => setDescription(e.target.value)}
          value={description}
          required={true}
        />
      </div>
      <div className="my-business-delivery-edit__footer">
        <div className="my-business-delivery-edit__footer-column">
          <DefaultButton
            onClick={editDelivery}
            {...buttonOk}
            width="100%"
            disabled={buttonDisabled}
          >
            Save
          </DefaultButton>
        </div>
        <div className="my-business-delivery-edit__footer-column">
          <DefaultButton
            onClick={cancelDelivery}
            width="100%"
            {...buttonCancel}
          >
            Cancel
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  areCurrenciesPending: state.utilsReducer.areCurrenciesPending,
  currencies: state.utilsReducer.currencies,
  userCompanies: state.companiesReducer.companiesByUserId,
  shippingMethodById: state.shipping.shippingMethodById,
  deliveryRequestStatus: state.shipping.deliveryRequestStatus
});

export default connect(mapStateToProps, {
  createShippingMethod,
  getCompaniesByUserId,
  getCurrencies,
  clearDeliveryRequest,
  putShippingMethod,
  getShippingMethodsById
})(MyBusinessDeliveryEdit);
