import axios from "axios";

import { axiosInstance, getCookie, baseURL } from "../../../axios/axios.config";
import { DocumentsActionTypes } from "./documents.types";
import {
  organizationType,
  companyType,
  b2bProductType,
  newsType,
  resumeType,
  b2cProductType,
  eventType
} from "../../../constants";
import {
  getResumeById,
  getB2BProduct,
  getB2CProduct,
  getCompany,
  getOrganization,
  getEventById,
  getNewsById,
  getProductVariationById
} from "../../actions-exporter";

export const uploadDocument = (
  objectId,
  docName,
  docDescription,
  contentType,
  documentFile
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.POST.UPLOAD_DOCUMENT_PENDING
  });

  const formData = new FormData();
  formData.append("name", docName);
  formData.append("description", docDescription);
  formData.append("document", documentFile);
  formData.append("content_type", contentType);
  formData.append("object_id", objectId);

  axios
    .post(`${baseURL}/api/v1/additional_fields/document/`, formData, {
      headers: {
        Authorization: getCookie("Authorization"),
        "Content-Type": "multipart/form-data",
        "Accept-Language": localStorage.getItem("userStorageLanguage")
      }
    })

    .then(document => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_DOCUMENT_SUCCESS,
        payload: document.data
      });
      switch (document.data.content_type) {
        case organizationType:
          dispatch(getOrganization(document.data.object_id));
          break;
        case companyType:
          dispatch(getCompany(document.data.object_id));
          break;
        case b2bProductType:
          dispatch(getB2BProduct(document.data.object_id));
          break;
        case resumeType:
          dispatch(getResumeById(document.data.object_id));
          break;
        case b2cProductType:
          dispatch(getB2CProduct(document.data.object_id));
          break;
        case eventType:
          dispatch(getEventById(document.data.object_id));
          break;
        default:
          console.log("error");
      }
    })
    .catch(err =>
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_DOCUMENT_ERROR,
        payload: err
      })
    );
};

export const deleteDocumentById = document => dispatch => {
  dispatch({
    type: DocumentsActionTypes.DELETE.DELETE_DOCUMENT_PENDING
  });
  axiosInstance
    .delete(`${baseURL}/api/v1/additional_fields/document/${document.id}`)
    .then(doc => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_DOCUMENT_SUCCESS,
        payload: doc.data
      });
      switch (document.content_type) {
        case organizationType:
          dispatch(getOrganization(document.object_id));
          break;
        case companyType:
          dispatch(getCompany(document.object_id));
          break;
        case b2bProductType:
          dispatch(getB2BProduct(document.object_id));
          break;
        case b2cProductType:
          dispatch(getB2CProduct(document.object_id));
          break;
        default:
          console.log("error");
      }
    })
    .catch(err =>
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_DOCUMENT_ERROR,
        payload: err
      })
    );
};

export const uploadAdditionalParameter = (
  parameterTitle,
  parameterDescription,
  parameterCost,
  productId
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PARAMETER_PENDING
  });
  axiosInstance
    .post("/api/v1/additional_fields/additional-parameters/", {
      title: parameterTitle,
      description: parameterDescription,
      extra_cost: parameterCost,
      content_type: b2cProductType,
      object_id: productId
    })
    .then(parameter => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PARAMETER_SUCCESS,
        payload: parameter
      });
      dispatch(getB2CProduct(parameter.data.object_id));
    })
    .catch(err => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PARAMETER_ERROR,
        payload: err
      });
    });
};

export const deleteAdditionalParameter = parameter => dispatch => {
  dispatch({
    type: DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PARAMETER_PENDING
  });
  axiosInstance
    .delete(`/api/v1/additional_fields/additional-parameters/${parameter.id}`)
    .then(deletedParameter => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PARAMETER_SUCCESS,
        payload: {
          deletedParameter,
          id: parameter.id
        }
      });
      dispatch(getB2CProduct(parameter.object_id));
    })
    .catch(err => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PARAMETER_ERROR,
        payload: err
      });
    });
};

export const uploadAdditionalPage = (
  additionalPageTitle,
  additionalPageEditor,
  content_type,
  object_id
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PAGE_PENDING
  });
  axiosInstance
    .post("/api/v1/additional_fields/additional-page/", {
      title: additionalPageTitle,
      content: additionalPageEditor,
      content_type,
      object_id
    })
    .then(additionalPage => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PAGE_SUCCES,
        payload: additionalPage
      });
      switch (additionalPage.data.content_type) {
        case organizationType:
          dispatch(getOrganization(additionalPage.data.object_id));
          break;
        case companyType:
          dispatch(getCompany(additionalPage.data.object_id));
          break;
        case b2bProductType:
          dispatch(getB2BProduct(additionalPage.data.object_id));
          break;
        case eventType:
          dispatch(getEventById(additionalPage.data.object_id));
          break;
        case b2cProductType:
          dispatch(getB2CProduct(additionalPage.data.object_id));
          break;
        default:
          console.log("error");
      }
    })
    .catch(err => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PAGE_ERROR,
        payload: err
      });
    });
};

export const deleteAdditionalPageById = additionalPage => dispatch => {
  dispatch({
    type: DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PAGE_BY_ID_PENDING
  });

  axiosInstance
    .delete(`/api/v1/additional_fields/additional-page/${additionalPage.id}/`)
    .then(page => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PAGE_BY_ID_SUCCESS,
        payload: {
          page: page.data,
          id: page
        }
      });
      switch (additionalPage.content_type) {
        case organizationType:
          dispatch(getOrganization(additionalPage.object_id));
          break;
        case companyType:
          dispatch(getCompany(additionalPage.object_id));
          break;
        case b2bProductType:
          dispatch(getB2BProduct(additionalPage.object_id));
          break;
        case eventType:
          dispatch(getEventById(additionalPage.object_id));
          break;
        case b2cProductType:
          dispatch(getB2CProduct(additionalPage.object_id));
          break;
        default:
          console.log("error");
      }
    })
    .catch(err =>
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PAGE_BY_ID_ERROR,
        payload: err
      })
    );
};

export const uploadProductSize = (
  productSize,
  productValue,
  content_type,
  object_id
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_SIZE_PENDING
  });
  axiosInstance
    .post("/api/v1/additional_fields/item-sizes/", {
      size: productSize,
      value: productValue,
      content_type,
      object_id
    })
    .then(additionalPage => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_SIZE_SUCCESS,
        payload: additionalPage.data
      });
      if (additionalPage.data.content_type === b2bProductType) {
        dispatch(getB2BProduct(additionalPage.data.object_id));
      } else if (additionalPage.data.content_type === b2cProductType) {
        dispatch(getB2CProduct(additionalPage.data.object_id));
      }
    })
    .catch(err => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_SIZE_ERROR,
        payload: err
      });
    });
};

export const deleteSizeById = size => dispatch => {
  dispatch({
    type: DocumentsActionTypes.DELETE.DELETE_SIZE_BY_ID_PENDING
  });
  axiosInstance
    .delete(`/api/v1/additional_fields/item-sizes/${size.id}/`)
    .then(deleted => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_SIZE_BY_ID_SUCCESS,
        payload: {
          productSize: deleted.data,
          id: deleted.id
        }
      });
      if (size.content_type === b2bProductType) {
        dispatch(getB2BProduct(size.object_id));
      } else if (size.content_type === b2cProductType) {
        dispatch(getB2CProduct(size.object_id));
      }
    })
    .catch(err =>
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_SIZE_BY_ID_ERROR,
        payload: err
      })
    );
};

export const uploadImageGallery = (
  content_type,
  object_id,
  image
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_IMAGE_GALLERY_PENDING
  });

  let content;
  if (content_type === "b2bV") {
    content = b2bProductType;
  } else if (content_type === "b2cV") {
    content = b2cProductType;
  } else {
    content = content_type;
  }

  const formData = new FormData();
  formData.append("content_type", content);
  formData.append("object_id", object_id);
  formData.append("image", image);

  axios
    .post(`${baseURL}/api/v1/additional_fields/gallery/`, formData, {
      headers: {
        Authorization: getCookie("Authorization"),
        "Content-Type": "multipart/form-data",
        "Accept-Language": localStorage.getItem("userStorageLanguage")
      }
    })
    .then(imageUploaded => {
      const objectId = imageUploaded.data.object_id;
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_IMAGE_GALLERY_SUCCESS,
        payload: imageUploaded
      });

      switch (content_type) {
        case b2bProductType:
          return dispatch(getB2BProduct(objectId));
        case newsType:
          return dispatch(getNewsById(objectId));
        case b2cProductType:
          return dispatch(getB2CProduct(objectId));
        case companyType:
          return dispatch(getCompany(objectId));
        case organizationType:
          return dispatch(getOrganization(objectId));
        case eventType:
          return dispatch(getEventById(objectId));
        case "b2bV":
          return dispatch(getProductVariationById("b2b", objectId));
        case "b2cV":
          return dispatch(getProductVariationById("b2c", objectId));
        default:
          console.log("error");
      }
    })
    .catch(err => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_IMAGE_GALLERY_SUCCESS,
        payload: err
      });
    });
};

export const uploadVideoGallery = (
  content_type,
  object_id,
  video
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_VIDEO_GALLERY_PENDING
  });

  const formData = new FormData();
  formData.append("content_type", content_type);
  formData.append("object_id", object_id);
  formData.append("video", video);

  axios
    .post(`${baseURL}/api/v1/additional_fields/gallery/`, formData, {
      headers: {
        Authorization: getCookie("Authorization"),
        "Content-Type": "multipart/form-data",
        "Accept-Language": localStorage.getItem("userStorageLanguage")
      }
    })
    .then(videoUploaded => {
      const contentType = videoUploaded.data.content_type;
      const objectId = videoUploaded.data.object_id;
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_VIDEO_GALLERY_SUCCESS,
        payload: videoUploaded
      });

      switch (contentType) {
        case b2bProductType:
          return dispatch(getB2BProduct(objectId));
        case newsType:
          return dispatch(getNewsById(objectId));
        case b2cProductType:
          return dispatch(getB2CProduct(objectId));
        case companyType:
          return dispatch(getCompany(objectId));
        case organizationType:
          return dispatch(getOrganization(objectId));
        case eventType:
          return dispatch(getEventById(objectId));
        default:
          console.log("error");
      }
    })
    .catch(err => {
      dispatch({
        type: DocumentsActionTypes.POST.UPLOAD_PRODUCT_VIDEO_GALLERY_SUCCESS,
        payload: err
      });
    });
};

export const deleteOneImageFromGallery = (
  imageId,
  objectId,
  contentType
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_PENDING
  });
  axiosInstance
    .delete(`/api/v1/additional_fields/image/${imageId}/`)
    .then(deletedImage => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_SUCCESS,
        payload: deletedImage
      });
    })
    .then(() => {
      switch (contentType) {
        case b2bProductType:
          return dispatch(getB2BProduct(objectId));
        case newsType:
          return dispatch(getNewsById(objectId));
        case b2cProductType:
          return dispatch(getB2CProduct(objectId));
        case companyType:
          return dispatch(getCompany(objectId));
        case organizationType:
          return dispatch(getOrganization(objectId));
        case eventType:
          return dispatch(getEventById(objectId));
        case "b2bV":
          return dispatch(getProductVariationById("b2b", objectId));
        case "b2cV":
          return dispatch(getProductVariationById("b2c", objectId));
        default:
          console.log("error");
      }
    })
    .catch(deletedImage => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_ERROR,
        payload: deletedImage
      });
    });
};

export const deleteOneVideoFromGallery = (
  imageId,
  objectId,
  contentType
) => dispatch => {
  dispatch({
    type: DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_PENDING
  });
  axiosInstance
    .delete(`/api/v1/additional_fields/video/${imageId}/`)
    .then(deletedImage => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_SUCCESS,
        payload: deletedImage
      });
    })
    .then(() => {
      switch (contentType) {
        case b2bProductType:
          return dispatch(getB2BProduct(objectId));
        case newsType:
          return dispatch(getNewsById(objectId));
        case b2cProductType:
          return dispatch(getB2CProduct(objectId));
        case companyType:
          return dispatch(getCompany(objectId));
        case organizationType:
          return dispatch(getOrganization(objectId));
        case eventType:
          return dispatch(getEventById(objectId));
        default:
          console.log("error");
      }
    })
    .catch(deletedImage => {
      dispatch({
        type: DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_ERROR,
        payload: deletedImage
      });
    });
};

export const loadBlobGallery = blobData => dispatch => {
  dispatch({
    type: DocumentsActionTypes.LOAD_BLOB_ON_CHANGE,
    payload: blobData
  });
};

export const loadFileGallery = fileData => dispatch => {
  dispatch({
    type: DocumentsActionTypes.LOAD_FILE_ON_CHANGE,
    payload: fileData
  });
};

export const loadBlobVideo = blobData => dispatch => {
  dispatch({
    type: DocumentsActionTypes.LOAD_VIDEO_BLOB_ON_CHANGE,
    payload: blobData
  });
};

export const loadFileVideo = fileData => dispatch => {
  dispatch({
    type: DocumentsActionTypes.LOAD_VIDEO_FILE_ON_CHANGE,
    payload: fileData
  });
};
