export const IntegrationsActionTypes = {
  GET: {
    GET_INTEGRATIONS_PENDING: "GET_INTEGRATIONS_PENDING",
    GET_INTEGRATIONS_SUCCESS: "GET_INTEGRATIONS_SUCCESS",
    GET_INTEGRATIONS_ERROR: "GET_INTEGRATIONS_ERROR",

    GET_INTEGRATION_BY_ID_PENDING: "GET_INTEGRATION_BY_ID_PENDING",
    GET_INTEGRATION_BY_ID_SUCCESS: "GET_INTEGRATION_BY_ID_SUCCESS",
    GET_INTEGRATION_BY_ID_ERROR: "GET_INTEGRATION_BY_ID_ERROR",

    GET_INTEGRATION_LOG_BY_ID_PENDING: "GET_INTEGRATION_LOG_BY_ID_PENDING",
    GET_INTEGRATION_LOG_BY_ID_SUCCESS: "GET_INTEGRATION_LOG_BY_ID_SUCCESS",
    GET_INTEGRATION_LOG_BY_ID_ERROR: "GET_INTEGRATION_LOG_BY_ID_ERROR"
  },
  POST: {
    CREATE_INTEGRATION_PENDING: "CREATE_INTEGRATION_PENDING",
    CREATE_INTEGRATION_SUCCESS: "CREATE_INTEGRATION_SUCCESS",
    CREATE_INTEGRATION_ERROR: "CREATE_INTEGRATION_ERROR"
  },
  PATCH: {
    UPDATE_INTEGRATION_PENDING: "UPDATE_INTEGRATION_PENDING",
    UPDATE_INTEGRATION_SUCCESS: "UPDATE_INTEGRATION_SUCCESS",
    UPDATE_INTEGRATION_ERROR: "UPDATE_INTEGRATION_ERROR"
  },
  DELETE: {
    DELETE_INTEGRATION_PENDING: "DELETE_INTEGRATION_PENDING",
    DELETE_INTEGRATION_SUCCESS: "DELETE_INTEGRATION_SUCCESS",
    DELETE_INTEGRATION_ERROR: "DELETE_INTEGRATION_ERROR"
  }
};
