import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { LoadingSpinner, DefaultButton } from "@core/components";
import { checkOrderPelecard } from "~/redux/actions-exporter";
import { withDebounce } from "@core/shared";

import { colorPalette } from "~/styles/colorPalette";
import "./styles.scss";

const PelecardCheckPage = ({
  checkOrderPelecard,
  pelecardCheckData,
  pelecardCheckDataError,
  pelecardCheckTimeout
}) => {
  const company = localStorage.getItem("order_company");
  const history = useHistory();

  const checkPelecardWithDebounce = withDebounce(
    () => checkOrderPelecard(company),
    pelecardCheckTimeout
  );

  const assignToErrorPage = () => {
    history.push("/cart/order/error");
  };

  useEffect(() => {
    checkPelecardWithDebounce();
  }, [checkPelecardWithDebounce]);

  useEffect(() => {
    if (
      pelecardCheckData?.detail === "Success" ||
      pelecardCheckData?.detail === "Is paid"
    ) {
      setTimeout(() => {
        history.push(
          `/cart/order/success/${
            pelecardCheckData?.transaction_id
              ? pelecardCheckData.transaction_id
              : "paid"
          }`
        );
      }, 2000);
    }
  }, [pelecardCheckData]); // eslint-disable-line

  useEffect(() => {
    checkPelecardWithDebounce();
  }, [pelecardCheckTimeout, checkPelecardWithDebounce]);

  useEffect(() => {
    if (pelecardCheckDataError && Object.keys(pelecardCheckDataError).length) {
      assignToErrorPage();
    }
  }, [pelecardCheckDataError]); // eslint-disable-line

  return (
    <div className="pelecard-page__check">
      <Card>
        <h3 className="pelecard-page__title">Check transaction</h3>
        <div className="pelecard-page__info">
          <div className="loading-spinner-container">
            <LoadingSpinner />
          </div>
          <DefaultButton
            border={`1px solid ${colorPalette.colorPrimary.main}`}
            color={colorPalette.colorPrimary.main}
            width="100%"
            onClick={() => assignToErrorPage()}
          >
            Stop checking
          </DefaultButton>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  pelecardCheckData: state.pelecardReducer.pelecardCheckData,
  pelecardCheckDataError: state.pelecardReducer.pelecardCheckDataError,
  pelecardCheckTimeout: state.pelecardReducer.pelecardCheckTimeout
});

export default connect(mapStateToProps, {
  checkOrderPelecard
})(PelecardCheckPage);
