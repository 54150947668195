import React, { useState, useEffect } from "react";

import "./dynamic-input.styles.scss";

import { DefaultButton } from "../index";

import { ReactComponent as Cross } from "../../assets/icons/Cross.svg";
import { buttonOk } from "../../styles/buttons-styles";

const DynamicInput = ({
  name,
  value,
  title,
  placeholder,
  onChange,
  style,
  values,
  type = "text",
  disabled
}) => {
  const [inputObject, setInputObject] = useState({});
  const [objectSize, setObjectSize] = useState(0);
  const [itemValue, setItemValue] = useState({});

  useEffect(() => {
    setInputObject(values);
    setObjectSize(Math.max(0, ...Object.keys(values)));
  }, [values]);

  const onRemoveOption = value => {
    delete inputObject[value];
    setInputObject({ ...inputObject });
    onChange({ ...inputObject });
  };

  const onAddOption = () => {
    let newObjectSize = objectSize + 1;
    setObjectSize(newObjectSize);
    setInputObject({ ...inputObject, [newObjectSize]: "Create field" });
    onChange({ ...inputObject, [newObjectSize]: "Create field" });
  };

  const onInputItem = (item, value, newItem) => {
    delete inputObject[item];
    setInputObject({ ...inputObject, [newItem]: value });
    onChange({ ...inputObject, [newItem]: value });
  };

  const onInputValue = (item, e) => {
    inputObject[item] = e.target.value;
    setInputObject({ ...inputObject });
    onChange({ ...inputObject });
  };

  return (
    <>
      <div className="dynamic-fields">
        <div className="dynamic-fields__title">{title}</div>
        <br />
        <div className="input-fieldset">
          {inputObject &&
            Object.keys(inputObject).map((item, i) => {
              return (
                <div className="dynamic-fields__group" key={i} style={style}>
                  <input
                    className="dynamic-fields__input"
                    name={`option_${i}`}
                    value={inputObject[item]}
                    type="text"
                    onChange={e => onInputValue(item, e)}
                  />
                  <input
                    className="dynamic-fields__input dynamic-fields__input_num"
                    name={`option_${i}`}
                    value={itemValue[item] ? itemValue[item] : item}
                    type="number"
                    onChange={e => setItemValue({ [item]: e.target.value })}
                    onBlur={e =>
                      onInputItem(item, inputObject[item], e.target.value)
                    }
                  />
                  <span
                    className="dynamic-fields__input-remove"
                    onClick={() => onRemoveOption(item)}
                  >
                    <Cross />
                  </span>
                </div>
              );
            })}
          <div>
            <DefaultButton
              width="100%"
              {...buttonOk}
              onClick={() => onAddOption()}
            >
              Add field
            </DefaultButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicInput;
