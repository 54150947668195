export const UtilsActionTypes = {
  GET: {
    CURRENCIES: {
      GET_CURRENCIES_PENDING: "GET_CURRENCIES_PENDING",
      GET_CURRENCIES_SUCCESS: "GET_CURRENCIES_SUCCESS",
      GET_CURRENCIES_ERROR: "GET_CURRENCIES_ERROR"
    },
    MEASUREMENTS: {
      GET_MEASUREMENTS_PENDING: "GET_MEASUREMENTS_PENDING",
      GET_MEASUREMENTS_SUCCESS: "GET_MEASUREMENTS_SUCCESS",
      GET_MEASUREMENTS_ERROR: "GET_MEASUREMENTS_ERROR"
    }
  }
};
