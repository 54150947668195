import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { withDebounce } from "@core/shared";

import {
  DefaultButton,
  DefaultDropdown,
  DefaultMultiLevelDropdown,
  InputGroup,
  MediaActions,
  Separator,
  DefaultCheckbox
} from "@core/components";

import {
  getCategoryById,
  createCategory,
  updateCategory,
  getB2BCategoriesEditor,
  getB2CCategoriesEditor,
  clearCategoryRequest
} from "@core/categories/store/API/categories/categories.action";

import { searchCompanies, getCompany } from "@core/redux/store/companies/companies.action";

import {
  ParentWrapper,
  ColumnsSmLgWrapper,
  ColumnWrapperLg,
  ColumnWrapperSm,
  ButtonsFooterWrapper
} from "@core/layout";

import "./my-business-category-edit.styles.scss";
import { buttonCancel, buttonOk } from "~/styles/buttons-styles";
import { missingImage } from "@core/assets";

const MyBusinessCategoryEdit = () => {
  const currentCategory = useSelector(
    state => state.categoriesAPI.categoryById
  );
  const categoryRequestStatus = useSelector(
    state => state.categoriesAPI.categoryRequestStatus
  );
  const parentB2CCategories = useSelector(
    state => state.categoriesAPI.b2cCategoriesEditorResults
  );
  const parentB2BCategories = useSelector(
    state => state.categoriesAPI.b2bCategoriesEditorResults
  );
  const companies = useSelector(state => state.companiesReducer.companies.results);
  const companyById = useSelector(state => state.companiesReducer.company);
  const dispatch = useDispatch();

  const { typeOfCategory, categoryId } = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  const [categoryName, setCategoryName] = useState("");
  const [categoryOrder, setCategoryOrder] = useState("");
  const [parent, setParent] = useState("");
  const [isMain, setIsMain] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [company, setCompany] = useState("");
  const [parentArray, setParentArray] = useState([]);
  const [icon, setIcon] = useState("");
  const [blob, setBlob] = useState();

  const [searchedCompanies, setSearchedCompanies] = useState("");

  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (categoryId !== "create") {
      dispatch(getCategoryById(categoryId, typeOfCategory));
    }
  }, [dispatch, categoryId, typeOfCategory]);

  useEffect(() => {
    dispatch(searchCompanies(""));
  }, [dispatch]);

  useEffect(() => {
    dispatch(searchCompanies(searchedCompanies));
  }, [dispatch, searchedCompanies]);

  useEffect(
    () => () => {
      dispatch(clearCategoryRequest());
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    if (typeOfCategory === "b2c") {
      dispatch(getB2CCategoriesEditor());
    } else {
      dispatch(getB2BCategoriesEditor());
    }
  }, [dispatch, typeOfCategory]);

  useEffect(() => {
    if (typeOfCategory === "b2c") {
      setParentArray(parentB2CCategories);
    } else {
      setParentArray(parentB2BCategories);
    }
  }, [parentB2CCategories, parentB2BCategories, setParentArray]); // eslint-disable-line

  useEffect(() => {
    if (categoryRequestStatus === 200 || categoryRequestStatus === 201) {
      history.push("/my-business/categories");
    }
  }, [history, categoryRequestStatus]);

  useEffect(() => {
    if (categoryId !== "create") {
      const { name, parent, category_order, icon, is_main, is_active, company } = currentCategory;
      setCategoryName(name);
      setParent(parent);
      setCategoryOrder(category_order);
      setIcon(icon);
      setIsMain(is_main);
      setIsActive(is_active);
      setCompany(company);
      if (company) {
        dispatch(getCompany(company));
      }
    }
  }, [dispatch, categoryId, currentCategory]);

  useEffect(() => {
    setDisabledButton(categoryName === "");
  }, [categoryName]);

  const editNew = () => {
    if (categoryId === "create") {
      dispatch(
        createCategory(
          typeOfCategory,
          categoryName,
          parent,
          blob,
          categoryOrder,
          isMain,
          isActive,
          company
        )
      );
    } else {
      dispatch(
        updateCategory(
          typeOfCategory,
          categoryId,
          categoryName,
          parent,
          blob,
          categoryOrder,
          isMain,
          isActive,
          company
        )
      );
    }
  };
  const searchCompaniesHandler = withDebounce(e => setSearchedCompanies(e));

  return (
    <div className="my-business-category-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={icon ? icon : missingImage}
            alt={"Icon"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton width="100%" {...buttonOk}>
                <label style={{ cursor: "pointer" }}>
                  {t("Choose an icon")}
                  <input
                    accept="image/svg+xml"
                    type="file"
                    onChange={e => {
                      setIcon(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                onClick={() => {
                  setIcon("");
                  setBlob("");
                }}
                width="100%"
                {...buttonCancel}
              >
                {t("Delete icon")}
              </DefaultButton>
            ]}
          />
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ColumnWrapperSm>
            <ParentWrapper>
              <InputGroup
                label={t("Name")}
                name="category-name"
                placeholder={t("Category name")}
                onChange={e => setCategoryName(e.target.value)}
                value={categoryName}
                required={true}
              />
            </ParentWrapper>
            <ParentWrapper>
              <InputGroup
                label={t("Category order")}
                name="category-order"
                onChange={e => setCategoryOrder(e.target.value)}
                value={categoryOrder}
              />
            </ParentWrapper>
            <ParentWrapper>
              <DefaultMultiLevelDropdown
                label={t("Parent category")}
                style={{ width: "100%" }}
                items={typeOfCategory === "b2c"
                  ? parentB2CCategories
                  : parentB2BCategories}
                returnData={setParent}
                customTitle={
                  categoryId !== "create" &&
                  currentCategory.parent &&
                  parentArray[currentCategory.parent]?.name
                }
              />
            </ParentWrapper>
            <ParentWrapper>
              <DefaultCheckbox
                title={t("Is main")}
                checkboxValue={isMain}
                setCheckboxChange={() => {
                  setIsMain(!isMain);
                }}
              />
            </ParentWrapper>
            <ParentWrapper>
              <DefaultCheckbox
                title={t("Is active")}
                checkboxValue={isActive}
                setCheckboxChange={() => {
                  setIsActive(!isActive);
                }}
              />
            </ParentWrapper>
            <ParentWrapper>
              <DefaultDropdown
                label={t("Company")}
                items={companies}
                returnData={setCompany}
                filter={true}
                inputFunc={e => searchCompaniesHandler(e.target.value)}
                customTitle={
                  categoryId !== "create" &&
                  companyById?.name
                }
              />
            </ParentWrapper>
          </ColumnWrapperSm>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={editNew}
            {...buttonOk}
            width="100%"
            disabled={disabledButton}
          >
            {t("Save")}
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.push("/my-business/categories")}
          >
            {t("Cancel")}
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

export default MyBusinessCategoryEdit;
