import "./gjs-text-with-description-trait.scss";

export default function gjsTextWithDescriptionTrait(editor) {
  editor.TraitManager.addType("text-with-description", {
    noLabel: true,
    templateInput: "",
    createInput({ component, trait }) {
      const el = document.createElement("div");
      el.classList.add("gjs-text-with-description");
      const name = trait.attributes.name;
      const placeholder = trait.attributes.placeholder || "";
      const description = trait.attributes.description || "";
      const hasValue = component.attributes.attributes.hasOwnProperty(name);
      // Create element input
      el.innerHTML = `
        <p class="gjs-text-with-description__description gjs-two-color">
          ${description}
        </p>
        <textarea
          class="gjs-text-with-description__textarea gjs-field gjs-two-color"
          placeholder="${placeholder}"
        >${hasValue ? component.attributes.attributes[name] : ""}</textarea>
      `;
      el.querySelector("textarea").addEventListener("input", e =>
        this.setText(e.target.value)
      );
      // Return element
      return el;
    },
    setText(text) {
      // Set page
      this.text = text;
      // Use onChange to trigger onEvent
      this.onChange();
    },
    onEvent({ component, trait, elInput }) {
      // Set component attribute
      component.addAttributes({ [trait.attributes.name]: this.text });
      // Set element value
      elInput.querySelector("textarea").value = this.text;
    }
  });
}
