export default function profileDropdownComponent({editor, api, className}) {
  const script = function () {
      if (!window.hasOwnProperty("grapesjs")) {
        return;
      }

      const handleData = data => {
        const className = this.className;
        const imageEl = this.querySelector(`.${className}__image`);
        imageEl.src =
          data.avatar ||
          `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==`;
        imageEl.alt = `
        ${data.first_name} ${data.middle_name} ${data.last_name} avatar
      `;
      };

      if (!window.hasOwnProperty("gjsProfile")) {
        const API_ROOT = "{[ apiRoot ]}";

        window.gjsProfile = {
          API_ROOT: API_ROOT,
          renderCycle: [],
          params: {},
          data: {},
          loading: false,
          render() {
            console.group("profile render");
            console.log("render before new cycle", this);
            this.renderCycle.map(func => func(this.data, this.loading));
            console.log("render after new cycle", this);
            console.groupEnd("profile render");
          },
          appendFuncToRenderCycle(newFunc) {
            const isNew = !this.renderCycle.find(func => func === newFunc);
            if (isNew) {
              this.renderCycle = this.renderCycle.concat(newFunc);
            }
          },
          setLoading(state) {
            this.setProp("loading", state, this.render.bind(this));
          },
          setProp(name, value, callback) {
            if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
              this[name] = value;
              if (callback !== undefined) {
                callback();
              }
            }
          },
          setParam(name, value) {
            const object = {};
            object[name] = value;
            this.setProp("params", Object.assign({}, this.params, object));
          },
          getProfile() {
            if (this.loading || !localStorage.hasOwnProperty("token")) return;
            this.setLoading(true);

            const params = new URLSearchParams(this.params);
            return fetch(`${API_ROOT}/auth/users/me/`, {
              method: "GET",
              headers: Object.assign(
                {"Accept-Language": localStorage.getItem("language")},
                localStorage.getItem("token")
                  ? {Authorization: `Token ${localStorage.getItem("token")}`}
                  : {}
              ),
              cors: "cors",
              credentials: "omit"
            })
              .then(response => response.json())
              .then(me =>
                fetch(`${API_ROOT}/account/profile/?${params}&user_id=${me.id}`, {
                  method: "GET",
                  headers: Object.assign(
                    {"Accept-Language": localStorage.getItem("language")},
                    localStorage.getItem("token")
                      ? {Authorization: `Token ${localStorage.getItem("token")}`}
                      : {}
                  ),
                  cors: "cors",
                  credentials: "omit"
                })
                  .then(response => response.json())
                  .then(response =>
                    this.setProfile(
                      Object.assign({}, response.results[0], {email: me.email})
                    )
                  )
              );
          },
          setProfile(data) {
            // Compare items
            if (JSON.stringify(this.data) !== JSON.stringify(data)) {
              this.render();
            }
            // Set profile data
            this.data = data;
            // Get additional inforamtion
            if (this.edit) {
              Promise.all([this.getGenders(), this.getProfileTypes()]).then(
                () => {
                  // Start render cycle
                  this.setLoading(false);
                  this.render();
                }
              );
            } else {
              // Start render cycle
              this.setLoading(false);
              this.render();
            }
          },
          updateProfile(data, json) {
            if (this.loading) return;
            this.setLoading(true);

            return fetch(`${API_ROOT}/account/profile/${this.data.id}/`, {
              method: "PATCH",
              headers: Object.assign(
                {"Accept-Language": localStorage.getItem("language")},
                json
                  ? {"Content-Type": "application/json"}
                  : {},
                localStorage.getItem("token")
                  ? {Authorization: `Token ${localStorage.getItem("token")}`}
                  : {}),
              body: data,
              cors: "cors",
              credentials: "omit"
            })
              .then(response => response.json())
              .then(response => {
                this.setLoading(false);
                this.getProfile(true);
              });
          },
          getGenders() {
            return fetch(`${API_ROOT}/account/profile/genders/`, {
              method: "GET",
              headers: Object.assign({
                "Accept-Language": localStorage.getItem("language"),
              }, localStorage.getItem("token")
                ? {Authorization: `Token ${localStorage.getItem("token")}`}
                : {}),
              cors: "cors",
              credentials: "omit"
            })
              .then(response => response.json())
              .then(response => this.setProp("genders", response));
          },
          getProfileTypes() {
            return fetch(`${API_ROOT}/account/profile/types/`, {
              method: "GET",
              headers: Object.assign({
                "Accept-Language": localStorage.getItem("language"),
              }, localStorage.getItem("token")
                ? {Authorization: `Token ${localStorage.getItem("token")}`}
                : {}),
              cors: "cors",
              credentials: "omit"
            })
              .then(response => response.json())
              .then(response => this.setProp("profileTypes", response));
          },
          updateEmail(newEmail) {
            return fetch(`${API_ROOT}/auth/users/set_email/`, {
                method: "POST",
                headers: Object.assign({
                  "Accept-Language": localStorage.getItem("language"),
                  "Content-Type": "application/json"
                }, localStorage.getItem("token")
                  ? {Authorization: `Token ${localStorage.getItem("token")}`}
                  : {}),
                body: JSON.stringify({
                  new_email: newEmail
                }),
                cors: "cors",
                credentials: "omit"
              }
            )
              .then(response => this.getProfile(true));
          }
        }
        ;
      }

      if (
        window.hasOwnProperty("gjsProfile") &&
        window.hasOwnProperty("grapesjs")
      ) {
        window.gjsProfile.appendFuncToRenderCycle(handleData);
        window.gjsProfile.getProfile();
      }
    }
  ;

  editor.DomComponents.addType("profile-avatar", {
    isComponent: el => el.tagName === "DIV" && el.className === `${className}`,
    model: {
      defaults: {
        name: "Avatar Wrapper",
        apiRoot: api.API_ROOT,
        script
      }
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          "change:attributes:data-always-active",
          this.render
        );
        this.listenTo(this.model, "change:attributes:data-hover", this.render);
      }
    }
  });

  editor.DomComponents.addType("profile-avatar__image", {
    isComponent: el =>
      el.tagName === "IMG" && el.className === `${className}__image`,
    model: {
      defaults: {
        name: "Avatar Image",
        draggable: false,
        doppable: false,
        removable: false,
        copyable: false
      }
    }
  });
}
