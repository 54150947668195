import {
  axiosInstance,
  axiosNoTokenInstance,
  getCookie,
  baseURL
} from "../../../axios/axios.config";

import axios from "axios";

import { NewsActionTypes } from "./news.types";

export const getNewsByCompanyId = (companyId, pageNumber = 1) => dispatch => {
  dispatch({ type: NewsActionTypes.COMPANY.GET_NEWS_BY_COMPANY_ID_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/news/news/?organization=${companyId}&page=${pageNumber}`)
    .then(newsByCompanyId =>
      dispatch({
        type: NewsActionTypes.COMPANY.GET_NEWS_BY_COMPANY_ID_SUCCESS,
        payload: newsByCompanyId.data
      })
    )
    .catch(error =>
      dispatch({
        type: NewsActionTypes.COMPANY.GET_NEWS_BY_COMPANY_ID_ERROR,
        payload: error
      })
    );
};

export const getNewsByOrganizationId = (
  organizationId,
  pageNumber = 1
) => dispatch => {
  dispatch({
    type: NewsActionTypes.ORGANIZATION.GET_NEWS_BY_ORGANIZATION_ID_PENDING
  });
  axiosNoTokenInstance
    .get(
      `/api/v1/news/news/?organization_id=${organizationId}&page=${pageNumber}`
    )
    .then(newsByOrganizationId =>
      dispatch({
        type: NewsActionTypes.ORGANIZATION.GET_NEWS_BY_ORGANIZATION_ID_SUCCESS,
        payload: newsByOrganizationId.data
      })
    )
    .catch(error =>
      dispatch({
        type: NewsActionTypes.ORGANIZATION.GET_NEWS_BY_ORGANIZATION_ID_ERROR,
        payload: error
      })
    );
};

export const getNewsById = newsId => dispatch => {
  dispatch({ type: NewsActionTypes.GET_NEWS_BY_ID_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/news/news/${newsId}`)
    .then(newsById =>
      dispatch({
        type: NewsActionTypes.GET_NEWS_BY_ID,
        payload: newsById.data
      })
    )
    .catch(error =>
      dispatch({ type: NewsActionTypes.GET_NEWS_BY_ID_ERROR, payload: error })
    );
};

export const createNews = (
  newTitle,
  country,
  organization,
  prevDescription,
  textContent,
  keywords,
  categories,
  blob
) => dispatch => {
  axiosInstance
    .post("/api/v1/news/news/", {
      title: newTitle,
      organization,
      country,
      categories,
      short_description: prevDescription,
      content: textContent,
      keywords
    })
    .then(news => {
      dispatch({
        type: NewsActionTypes.CREATE_NEWS,
        payload: { news: news.data, requestStatus: news.status }
      });

      if (blob) {
        dispatch({
          type: NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", blob);
        axios
          .patch(`${baseURL}/api/v1/news/news/${news.data.id}/`, formData, {
            headers: {
              Authorization: getCookie("Authorization"),
              "Content-Type": "multipart/form-data"
            }
          })
          .then(resp =>
            dispatch({
              type: NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .catch(err =>
            dispatch({
              type: NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_ERROR,
              payload: err
            })
          );
      }
    })
    .catch(error => console.log(error));
};

export const updateNews = (
  newsId,
  newTitle,
  country,
  organization,
  prevDescription,
  textContent,
  keywords,
  categories,
  blob
) => dispatch => {
  axiosInstance
    .put(`/api/v1/news/news/${newsId}/`, {
      title: newTitle,
      organization,
      country,
      short_description: prevDescription,
      content: textContent,
      categories,
      keywords
    })
    .then(news => {
      if (blob.hasOwnProperty("update")) {
        dispatch({
          type: NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", blob.update);
        axios
          .patch(`${baseURL}/api/v1/news/news/${news.data.id}/`, formData, {
            headers: {
              Authorization: getCookie("Authorization"),
              "Content-Type": "multipart/form-data"
            }
          })
          .then(resp =>
            dispatch({
              type: NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(() => {
            dispatch({
              type: NewsActionTypes.UPDATE_NEWS,
              payload: { news: news.data, requestStatus: news.status }
            });
          })
          .catch(err =>
            dispatch({
              type: NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: NewsActionTypes.UPDATE_NEWS,
          payload: { news: news.data, requestStatus: news.status }
        });
      }
    })
    .catch(error => console.log(error));
};

export const deleteNews = newsId => dispatch => {
  axiosInstance
    .delete(`/api/v1/news/news/${newsId}/`)
    .then(deletedNews => {
      dispatch({
        type: NewsActionTypes.DELETE_NEWS,
        payload: deletedNews
      });
    })
    .then(() => dispatch(getNewsByUserId()))
    .catch(error => console.log(error));
};

export const getNewsCategories = () => dispatch => {
  dispatch({
    type: NewsActionTypes.GET.GET_NEWS_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get("/api/v1/news/news_categories/")
    .then(newsCategories => {
      dispatch({
        type: NewsActionTypes.GET.GET_NEWS_CATEGORIES_SUCCESS,
        payload: newsCategories.data
      });
    })
    .catch(error => {
      dispatch({
        type: NewsActionTypes.GET.GET_NEWS_CATEGORIES_ERROR,
        payload: error
      });
    });
};

export const getFilteredNews = (filterQuery, page = 1) => dispatch => {
  dispatch({ type: NewsActionTypes.GET_NEWS_PENDING });
  axiosNoTokenInstance
    .get(
      `/api/v1/news/news/${filterQuery}${filterQuery ? "&" : "?"}page=${page}`
    )
    .then(news =>
      dispatch({
        type: NewsActionTypes.GET_NEWS,
        payload: news.data
      })
    )
    .catch(error => console.log(error));
};

export const getNews = (
  searchQuery = "",
  page = 1,
  countries = "",
  organizations = "",
  category = ""
) => dispatch => {
  dispatch({ type: NewsActionTypes.GET_NEWS_PENDING });

  axiosNoTokenInstance
    .get(
      `/api/v1/news/news/?search=${searchQuery}&country=${countries}&organization=${organizations}&categories=${category}&page=${page}`
    )
    .then(news =>
      dispatch({
        type: NewsActionTypes.GET_NEWS,
        payload: news.data
      })
    )
    .catch(err =>
      dispatch({ type: NewsActionTypes.GET_NEWS_ERROR, payload: err })
    );
};

export const getNewsByUserId = (search = "", page = 1) => dispatch => {
  dispatch({
    type: NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_PENDING
  });
  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(user =>
      axiosInstance.get(
        `/api/v1/news/news/?search=${search}&created_by=${user.data.id}&page=${page}`
      )
    )
    .then(news =>
      dispatch({
        type: NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_SUCCESS,
        payload: { news: news.data.results, count: news.data.count }
      })
    )
    .catch(error =>
      dispatch({
        type: NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_ERROR,
        payload: error
      })
    );
};

export const searchNewsByUserId = (searchQuery, page = 1) => dispatch => {
  dispatch({
    type: NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_PENDING
  });
  axiosInstance
    .get("/api/v1/auth/users/me/")
    .then(user =>
      axiosInstance.get(
        `/api/v1/news/news/?created_by=${user.data.id}&search=${searchQuery}&page=${page}`
      )
    )
    .then(news =>
      dispatch({
        type: NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_SUCCESS,
        payload: { results: news.data.results, data: news.data }
      })
    )
    .catch(error =>
      dispatch({
        type: NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_ERROR,
        payload: error
      })
    );
};

export const clearNewsRequest = () => dispatch => {
  dispatch({
    type: NewsActionTypes.CLEAR_NEWS_REQUEST,
    payload: ""
  });
};
