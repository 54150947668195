import {
  axiosInstance,
  axiosNoTokenInstance,
  getCookie,
  baseURL
} from "../../../axios/axios.config";

import axios from "axios";

import { CompaniesActionTypes } from "./companies.types";

export const getCompanyTypes = (pageNumber = 1) => dispatch => {
  dispatch({
    type: CompaniesActionTypes.GET_COMPANY_TYPES.GET_COMPANY_TYPES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/company_types/?page=${pageNumber}`)
    .then(companyTypes => {
      dispatch({
        type: CompaniesActionTypes.GET_COMPANY_TYPES.GET_COMPANY_TYPES_SUCCESS,
        payload: companyTypes.data.results
      });
    })
    .catch(error =>
      dispatch({
        type: CompaniesActionTypes.GET_COMPANY_TYPES.GET_COMPANY_TYPES_ERROR,
        payload: error
      })
    );
};

export const getCompanyType = companyTypeId => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/organization/company_types/${companyTypeId}/`)
    .then(companyType => {
      dispatch({
        type: CompaniesActionTypes.GET_COMPANY_TYPES,
        payload: companyType.data
      });
    })
    .catch(error => console.log(error));
};

export const getCompanies = (
  searchQuery = "",
  countries = "",
  organizations = "",
  branches = "",
  page = 1
) => dispatch => {
  dispatch({ type: CompaniesActionTypes.GET_COMPANIES_PENDING });
  axiosNoTokenInstance
    .get(
      `/api/v1/organization/company/?search=${searchQuery}&countries=${countries}&parent=${organizations}&branches=${branches}&page=${page}`
    )
    .then(companies => {
      dispatch({
        type: CompaniesActionTypes.GET_COMPANIES,
        payload: companies.data
      });
    })
    .catch(error => console.log(error));
};

export const getCompany = companyId => dispatch => {
  dispatch({
    type: CompaniesActionTypes.GET_COMPANY_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/organization/company/${companyId}/`)
    .then(company => {
      if (window?.B24Chat?.instance) {
        window.B24Chat.instance.loadWidget({
          block: "company-script",
          token: company.data.bot_token
        });
      }

      dispatch({
        type: CompaniesActionTypes.GET_COMPANY_SUCCESS,
        payload: company.data
      });
    })
    .catch(error => {
      dispatch({
        type: CompaniesActionTypes.GET_COMPANY_ERROR,
        payload: error
      });
    });
};

export const createCompany = (
  name,
  countries,
  typeOfCompany,
  vatin,
  site,
  company_paypal_account,
  director,
  email,
  phone,
  fax,
  address,
  slogan,
  keywords,
  branches,
  parent,
  shortDescription,
  description,
  script,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit,
  logo,
  edeaSync,
  branchNumber,
  POSNumber,
  uniqueIdentifier,
  edeaFields,
  edeaFieldsWeb,
  pelecardUser,
  pelecardPassword,
  pelecardTerminal
) => dispatch => {
  axiosInstance
    .post("/api/v1/organization/company_manageable/", {
      name,
      countries,
      typeofcompany: typeOfCompany,
      director,
      metadata: {
        email,
        phone,
        fax,
        site,
        vat_identification_number: vatin,
        script,
        dynamic_attributes_keys: edeaFields,
        dynamic_attributes_web_keys: edeaFieldsWeb
      },
      payments_data: {
        pelecard: {
          user: pelecardUser,
          password: pelecardPassword,
          terminal: pelecardTerminal
        }
      },
      company_paypal_account,
      address,
      slogan,
      keywords,
      branches,
      parent,
      short_description: shortDescription,
      description,
      facebook,
      linkedin,
      twitter,
      instagram,
      youtube,
      reddit,
      is_edea_sync: edeaSync,
      edea_config: {
        BranchNumber: branchNumber,
        POSNumber,
        UniqueIdentifier: uniqueIdentifier
      }
    })
    .then(company => {
      if (logo) {
        dispatch({
          type: CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("logo", logo);

        axios
          .patch(
            `${baseURL}/api/v1/organization/company_manageable/${company.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp => {
            dispatch({
              type:
                CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_SUCCESS,
              payload: { company: resp.data, requestStatus: resp.status }
            });
          })
          .then(() => {
            dispatch({
              type: CompaniesActionTypes.CREATE_COMPANY,
              payload: {
                company: company.data,
                requestStatus: company.status
              }
            });
          })
          .catch(err =>
            dispatch({
              type: CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: CompaniesActionTypes.CREATE_COMPANY,
          payload: {
            company: company.data,
            requestStatus: company.status
          }
        });
      }
    })
    .catch(error => console.log(error));
};

export const updateCompany = (
  companyId,
  name,
  countries,
  typeOfCompany,
  vatin,
  site,
  company_paypal_account,
  director,
  email,
  phone,
  fax,
  address,
  slogan,
  keywords,
  branches,
  parent,
  shortDescription,
  description,
  script,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit,
  logo,
  edeaSync,
  branchNumber,
  POSNumber,
  uniqueIdentifier,
  edeaFields,
  edeaFieldsWeb,
  pelecardUser,
  pelecardPassword,
  pelecardTerminal
) => dispatch => {
  axiosInstance
    .put(`/api/v1/organization/company_manageable/${companyId}/`, {
      name,
      countries,
      typeofcompany: typeOfCompany,
      director,
      metadata: {
        email,
        phone,
        fax,
        site,
        vat_identification_number: vatin,
        script,
        dynamic_attributes_keys: edeaFields,
        dynamic_attributes_web_keys: edeaFieldsWeb
      },
      payments_data: {
        pelecard: {
          user: pelecardUser,
          password: pelecardPassword,
          terminal: pelecardTerminal
        }
      },
      company_paypal_account,
      address,
      slogan,
      keywords,
      branches,
      parent,
      short_description: shortDescription,
      description,
      facebook,
      linkedin,
      twitter,
      instagram,
      youtube,
      reddit,
      is_edea_sync: edeaSync,
      edea_config: {
        BranchNumber: branchNumber,
        POSNumber,
        UniqueIdentifier: uniqueIdentifier
      }
    })
    .then(company => {
      console.log(logo);

      if (logo !== undefined) {
        dispatch({
          type: CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("logo", logo);
        axios
          .patch(
            `${baseURL}/api/v1/organization/company_manageable/${company.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type:
                CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(_ =>
            dispatch({
              type: CompaniesActionTypes.UPDATE_COMPANY,
              payload: {
                company: company.data,
                requestStatus: company.status
              }
            })
          )
          .catch(err =>
            dispatch({
              type: CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: CompaniesActionTypes.UPDATE_COMPANY,
          payload: { company: company.data, requestStatus: company.status }
        });
      }
    })
    .catch(error => console.log(error));
};

export const deleteCompany = companyId => dispatch => {
  axiosInstance
    .delete(`/api/v1/organization/company_manageable/${companyId}/`)
    .then(deletedCompany => {
      dispatch({
        type: CompaniesActionTypes.DELETE_COMPANY,
        payload: deletedCompany
      });
    })
    .then(() => dispatch(getCompaniesByUserId()))
    .catch(error => console.log(error));
};

export const getCompaniesByUserId = (search = "", page = 1) => dispatch => {
  dispatch({
    type: CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_PENDING
  });
  axiosInstance
    .get(
      `api/v1/organization/company_manageable/?search=${search}&page=${page}`
    )
    .then(companies => {
      dispatch({
        type: CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_SUCCESS,
        payload: {
          companies: companies.data.results,
          count: companies.data.count
        }
      });
    })
    .catch(error => {
      dispatch({
        type: CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_ERROR,
        payload: error
      });
    });
};

export const searchCompaniesByUserId = (searchQuery, page = 1) => dispatch => {
  dispatch({
    type: CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_PENDING
  });
  axiosInstance
    .get(
      `api/v1/organization/company_manageable/?search=${searchQuery}&page=${page}`
    )
    .then(companies =>
      dispatch({
        type: CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_SUCCESS,
        payload: { results: companies.data.results, data: companies.data }
      })
    )
    .catch(error =>
      dispatch({
        type: CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_ERROR,
        payload: error
      })
    );
};

export const getFilterCompanies = (filterQuery, page = 1) => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/organization/company/${filterQuery}&page=${page}`)
    .then(companies => {
      dispatch({
        type: CompaniesActionTypes.GET_COMPANIES,
        payload: companies.data
      });
    })
    .catch(error => console.log(error));
};

export const searchCompanies = (searchQuery, page = 1) => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/organization/company/?search=${searchQuery}&page=${page}`)
    .then(companies => {
      dispatch({
        type: CompaniesActionTypes.GET_COMPANIES,
        payload: companies.data
      });
    })
    .catch(error => console.log(error));
};

export const getCompaniesByOrganizationId = (
  organizationId,
  pageNumber = 1
) => dispatch => {
  dispatch({
    type:
      CompaniesActionTypes.GET.BY_ORGANIZATION
        .GET_COMPANIES_BY_ORGANIZATION_ID_PENDING
  });
  axiosNoTokenInstance(
    `/api/v1/organization/company/?parent=${organizationId}&page=${pageNumber}`
  )
    .then(resp =>
      dispatch({
        type:
          CompaniesActionTypes.GET.BY_ORGANIZATION
            .GET_COMPANIES_BY_ORGANIZATION_ID_SUCCESS,
        payload: { data: resp.data, results: resp.data.results }
      })
    )
    .catch(err =>
      dispatch({
        type:
          CompaniesActionTypes.GET.BY_ORGANIZATION
            .GET_COMPANIES_BY_ORGANIZATION_ID_ERROR,
        payload: err
      })
    );
};

export const clearCompanyRequest = () => dispatch => {
  dispatch({
    type: CompaniesActionTypes.CLEAR_COMPANY_REQUEST,
    payload: ""
  });
};
