import icon from "./edea-single-product-block-icon.png";

export default function singleProductWithPaginationBlock({ editor, api }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style> 
    .product-single-wrapper {
      display: flex;
      flex-flow: row wrap;
    }

    .product-single {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 20px;
    }

    @media screen and (max-width: 992px) {
      .product-single {
        flex-basis: 100%;
      }
    }

    .product-single__image {
      width: 100%;
      height: 150px;
      object-fit: scale-down;
    }

    .product-single__title {
      color: #262626;
      font-size: 30px;
      font-weight: 700;
      margin: 16px 0;
    }

    .product-single__divider {
      width: 114px;
      height: 7px;
      background-color: #aeaeae;  
    }

    .product-single__description {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
    }

    .product-single__cost {
      color: #262626;
      font-size: 18px;
      font-weight: 700;
      margin: auto 0 8px;
    }

    .product-single__button {
      background-color: #aeaeae;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 12px 46px;
      border: none;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .product-single__button:hover {
      background-color: #8c8c8c;
    }

    .simple-pagination {
      align-self: flex-end;
      margin: 0 0 0 auto;
      padding: 20px;
    }
  </style>
  `;

  const content = `
  <div class="edea-product-single-wrapper">
    ${style}
  </div>
  `;

  const script = function() {
    // function makeProductHtml(src, title, description, cost, currency) {
    //   return `
    //   <div class="product-single">
    //     <img class="product-single__image" src="${src}" />
    //     <h4 class="product-single__title">${title}</h4>
    //     <span class="product-single__divider"></span>
    //     <p class="product-single__description">
    //     ${
    //       description.length <= 300
    //         ? description
    //         : `${description.slice(300)}...`
    //     }
    //     </p>
    //     <b class="product-single__cost">Cost: ${
    //       cost ? cost : "N/A"
    //     } ${currency}</b>
    //     <button class="product-single__button">BUY</button>
    //   </div>
    //   `;
    // }

    function update(ItemCode, UniquePOSIdentifier) {
      const API_BASE_PATH = "https://qaapi.edea.co.il/JREdeaRetailAPI.2.1.2.13";

      fetch(`${API_BASE_PATH}/api/POSRegistration/RegisterPOS`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({ UniquePOSIdentifier: UniquePOSIdentifier }),
        mode: "cors"
      }).then(() => {
        fetch(`${API_BASE_PATH}/api/Items/SearchItems`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            UniquePOSIdentifier,
            ItemCode,
            RetrieveItemImage: true,
            RequiresItemsPrice: true
          }),
          mode: "cors"
        })
          .then(response => response.json())
          .then(json => json.ItemSearchDetail)
          .then(products => console.log("edea products:", products));
      });
    }

    console.log("edea products attributes:", this.attributes);

    const ItemCode =
      this.attributes.item_code &&
      (this.attributes.item_code.value || this.attributes.item_code);
    const BranchNumber =
      this.attributes.branch_number &&
      (this.attributes.branch_number.value || this.attributes.branch_number);
    const POSNumber =
      this.attributes.pos_number &&
      (this.attributes.pos_number.value || this.attributes.pos_number);
    const UniqueIdentifier =
      this.attributes.unique_identifier &&
      (this.attributes.unique_identifier.value ||
        this.attributes.unique_identifier);

    if (ItemCode && BranchNumber && POSNumber && UniqueIdentifier) {
      update(ItemCode, {
        BranchNumber,
        POSNumber,
        UniqueIdentifier
      });
    }
  };

  editor.DomComponents.addType("edea-product-single--component", {
    model: {
      defaults: {
        name: "EDEA Product Single",
        script,
        content,
        traits: [
          {
            type: "number",
            label: "Item Code",
            name: "item_code",
            placeholder: "123456XX"
          },
          {
            type: "string",
            label: "Branch Number",
            name: "branch_number",
            placeholder: "000"
          },
          {
            type: "string",
            label: "POS Number",
            name: "pos_number",
            placeholder: "0"
          },
          {
            type: "string",
            label: "Unique Identifier",
            name: "unique_identifier",
            placeholder: "SomeIdentifier"
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:item_code", this.render);
        this.listenTo(
          this.model,
          "change:attributes:branch_number",
          this.render
        );
        this.listenTo(this.model, "change:attributes:pos_number", this.render);
        this.listenTo(
          this.model,
          "change:attributes:unique_identifier",
          this.render
        );
      }
    }
  });

  blockManager.add("edea-product", {
    label: `
      <img src="${icon}" alt="'edea product single' block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        EDEA Product Single
      </div>
    `,
    content: {
      type: "edea-product-single--component"
    },
    category: "EDEA Products"
  });
}
