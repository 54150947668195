import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  CompanyDepartmentInfo,
  LoadingSpinner,
  Separator,
  SearchBar,
  Pagination,
  MyBusinessNoData
} from "../../../components";

import {
  getCompaniesByUserId,
  searchCompaniesByUserId,
  getUserOrganizationsChamber,
  getMultipleVacanciesDepartmentOrganization,
  getMultipleVacanciesDepartmentCompany,
  setCurrentCompany
} from "../../../redux/actions-exporter";
import { withDebounce, scrollToRef } from "../../../shared";

const MyBusinessDepartmentCompanyInfo = ({
  setCurrentCompany,
  getCompaniesByUserId,

  getUserOrganizationsChamber,
  companiesByUserId,
  companiesByUserIdCount,
  userOrganizationsChamber,
  userOrganizationsChamberCount,

  companiesByUserPending,
  organizationsByUserPending
}) => {
  const [searchCompanies, setSearchCompanies] = useState("");
  const [companiesPage, setCompaniesPage] = useState(1);
  const companyRef = useRef(null);

  const [searchOrganizations, setSearchOrganizations] = useState("");
  const [organizationsPage, setOrganizationsPage] = useState(1);
  const organizationRef = useRef(null);

  useEffect(() => {
    getCompaniesByUserId(searchCompanies, companiesPage);
  }, [getCompaniesByUserId, searchCompanies, companiesPage]);

  useEffect(() => {
    getUserOrganizationsChamber(searchOrganizations, organizationsPage);
  }, [getUserOrganizationsChamber, searchOrganizations, organizationsPage]);

  useEffect(() => {
    setCompaniesPage(1);
  }, [searchCompanies]);

  useEffect(() => {
    setOrganizationsPage(1);
  }, [searchOrganizations]);

  const searchCompaniesHandler = withDebounce(e => setSearchCompanies(e));
  const searchOrganizationsHandler = withDebounce(e =>
    setSearchOrganizations(e)
  );

  const companiesPageHander = page => {
    setCompaniesPage(page);
    scrollToRef(companyRef.current.offsetTop);
  };

  const organizationsPageHandler = page => {
    setOrganizationsPage(page);
    scrollToRef(organizationRef.current.offsetTop);
  };

  return companiesByUserPending && organizationsByUserPending ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <div className="my-business-companies">
        <div className="company-department-container">
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="company-department-container__title"
          >
            Department
          </div>
          <Separator />
          <div ref={companyRef} className="company-department-container__title">
            Companies
          </div>
          <div style={{ margin: 20 }}>
            <SearchBar onChange={e => searchCompaniesHandler(e.target.value)} />
          </div>
          {companiesByUserId && companiesByUserId.length ? (
            companiesByUserId.map((company, index) => (
              <CompanyDepartmentInfo
                typeOfCompany={"Company"}
                key={index}
                currentCompany={company}
                setCurrentCompany={setCurrentCompany}
              />
            ))
          ) : (
            <MyBusinessNoData noDataText="No companies found!" />
          )}
        </div>
        <Pagination
          forcePage={companiesPage - 1}
          dataCount={companiesByUserIdCount}
          itemsPerPage={10}
          selectedPage={companiesPageHander}
        />
      </div>
      <div className="my-business-organizations">
        <div className="company-department-container">
          <div
            ref={organizationRef}
            className="company-department-container__title"
          >
            Organizations
          </div>
          <div style={{ margin: 20 }}>
            <SearchBar
              onChange={e => searchOrganizationsHandler(e.target.value)}
            />
          </div>
          {userOrganizationsChamber && userOrganizationsChamber.length ? (
            userOrganizationsChamber.map((organization, index) => {
              return (
                <CompanyDepartmentInfo
                  typeOfCompany={"Organization"}
                  key={index}
                  currentCompany={organization}
                  setCurrentCompany={setCurrentCompany}
                />
              );
            })
          ) : (
            <MyBusinessNoData noDataText="No organizations found!" />
          )}
        </div>
        <Pagination
          forcePage={organizationsPage - 1}
          dataCount={userOrganizationsChamberCount}
          itemsPerPage={10}
          selectedPage={organizationsPageHandler}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  companiesByUserPending: state.companiesReducer.areCompaniesByUserIdPending,
  organizationsByUserPending:
    state.organizationsReducer.areUserOrganizationsPending,
  companiesByUserId: state.companiesReducer.companiesByUserId,
  companiesByUserIdCount: state.companiesReducer.companiesByUserIdCount,
  userOrganizationsChamber: state.organizationsReducer.userOrganizationsChamber,
  userOrganizationsChamberCount:
    state.organizationsReducer.userOrganizationsChamberCount,

  userOrganizatiosVacancies:
    state.vacanciesReducer.multipleOrganizationVacancies,
  userCompaniesVacancies: state.vacanciesReducer.multipleCompanyVacancies
});

export default connect(mapStateToProps, {
  setCurrentCompany,
  getMultipleVacanciesDepartmentCompany,
  getMultipleVacanciesDepartmentOrganization,
  getCompaniesByUserId,
  getUserOrganizationsChamber,
  searchCompaniesByUserId
})(MyBusinessDepartmentCompanyInfo);
