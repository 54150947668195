import icon from "./icon.png";

export default function cartCheckout({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  @media screen and (max-width: 992px) {
    
    .cart-checkout__buttons {
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
    }

    .cart-checkout__button {
      width: 46%;
      margin: 0 1%;
    }

    .cart-checkout__buttons.cart-checkout__buttons--second {
      flex-flow: row-reverse;
    }

    .cart-checkout__button.cart-checkout__button--prev {
      margin: 0 10px;
    }

    .cart-checkout__steps-buttons {
      width: 100%;
      padding: 10px 18px;
    }

    .cart-product__image {
      width: 60px;
      height: 60px;
    }

    .cart-checkout__form {
      flex-flow: column;
    }

    input.cart-checkout__input {
      width: 90%;
    }

    .cart-product {
      padding: 10px 0;
    }

    .cart-table__header-title {
      font-size: 13px;
    }

    .cart-table__header-title--quantity {
      width: 17%;
    }

    .cart-product__title {
      font-size: 16px;
    }
  }

  .cart-checkout-wrapper {
    overflow: auto;
  }

  .cart-checkout {
    width: 100%;
    border: 1px solid #aeaeae;
    padding: 18px 0 20px;
    border-collapse: collapse;
  }

  .cart-checkout--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .cart-checkout--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .cart-checkout__title {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
  }

  .cart-checkout__meta-item {
    margin-top: 5px;
  }

  .cart-checkout__meta-title {
    font-weight: 600;
  }

  .cart-checkout__description {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
  }

  .cart-checkout__delimiter {
    display: block;
    width: 100%;
    height: 1px;
    background: #cbcbcc;
    margin: 14px auto;
  }

  .cart-checkout__details {
    padding: 0 2% 0;
  }
  
  .cart-checkout__content {
    padding: 0 2% 0;
  }

  .cart-checkout__form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .cart-checkout__input {
    width: 48%;
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000000;
    font-size: 15px;
    border: 1px solid #b9b9ba;
    padding: 10px 8px;
    margin-bottom: 10px;
    border-radius: 3px;
  }

  .cart-checkout__input:disabled {
    background-color: hsla(0, 0%, 0%, 0.04);
    color: hsla(0, 0%, 0%, 0.9);
  }

  .cart-checkout__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 2% 0;
  }

  .cart-checkout__button {
    height: 100%;
    background-color: #aeaeae;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
    padding: 13px 17px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
  }

  .cart-checkout__button--disabled {
    background-color: #bfbfbf;
    color: #fbfbfb;
    cursor: not-allowed;
  }

  .cart-checkout__button:hover {
    background-color: #a9a9a9
  }

  .cart-checkout__step-button {
    cursor: pointer;
  }

  .cart-checkout__step-button + .cart-checkout__step-button {
    margin-left: 5px
  }

  .cart-product {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding: 10px 14px;
  }

  .cart-product__media {
    flex-basis: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart-product__image {
    width: 140px;
    height: 140px;
    padding: 12px;
    object-fit: scale-down;
    user-select: none;
  }

  .cart-product__main {
    flex-basis: 72%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
  }

  .cart-product__capture {
    color: hsla(0, 0%, 0%, 0.8);
    font-size: 13px;
    letter-spacing: 0.3;
    margin-bottom: 3px;
  }

  .cart-product__title {
    color: #262626;
    font-weight: 700;
    text-align: left;
    margin: 0px 0 3%;
  }

  .cart-product__link {
    color: inherit;
    text-decoration-color: currentColor;
  }

  .cart-product__description {
    color: #262626;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 0;
  }

  .cart-table {
    width: 100%;
  }

  .cart-table__header {
    text-align: center;
  }

  .cart-table__header-title--product {
    width: 42%;
  }

  .cart-table__header-title--quantity {
    width: 22%;
  }

  .cart-table tr > td:not(:first-child) {
    text-align: center;
  }
  
  </style>
  `;

  const content = `
  ${style}
  <div class="cart-checkout-wrapper">
    <div class="cart-checkout">
    <div class="cart-checkout__details">
      <h1 class="cart-checkout__title">Deal #1</h1>
      <span class="cart-checkout__delimiter"></span>
      <div class="cart-checkout__meta">
        <div class="cart-checkout__meta-item">
          <span class="cart-checkout__meta-title">Deal status: </span>
          <span class="cart-checkout__meta-value">draft</span>
        </div>
        <div class="cart-checkout__meta-item">
          <span class="cart-checkout__meta-title">Total deal cost: </span>
          <span class="cart-checkout__meta-value">6750 ILS</span>
        </div>
        <div class="cart-checkout__meta-item">
          <span class="cart-checkout__meta-title">Shipping: </span>
          <span class="cart-checkout__meta-value">5 ILS</span>
        </div>
      </div>
      <span class="cart-checkout__delimiter"></span>
      <h1 class="cart-checkout__title">Products list</h1>
    </div>
    <span class="cart-checkout__delimiter"></span>

    <table class="cart-table">
      <thead class="cart-table__header">
        <tr>
          <th class="cart-table__header-title cart-table__header-title--product">PRODUCT</th>
          <th class="cart-table__header-title cart-table__header-title--price">PRICE</th>
          <th class="cart-table__header-title cart-table__header-title--quantity">QUANTITY</th>
          <th class="cart-table__header-title cart-table__header-title--total">TOTAL</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>
            <div class="cart-product">
              <div class="cart-product__media">
                <img class="cart-product__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="Lorem ipsum dolor sit amet thumbnail">
              </div>
              <div class="cart-product__main">
                <span class="cart-product__capture">
                  Category & Category
                </span>
                <h4 class="cart-product__title">
                  <a class="cart-product__link" href="">
                    Lorem ipsum dolor sit amet
                  </a>
                </h4>
              </div>
            </div>
          </td>
          <td>
            <span class="cart-product__price">750.00 ILS</b>
          </td>
          <td>
            <span class="cart-product__quantity">8</span>
          </td>
          <td>
            <span class="cart-product__price cart-product__price--total">6000.00 ILS</span>
          </td>
        </tr>

        <tr>
          <td>
            <div class="cart-product">
              <div class="cart-product__media">
                <img class="cart-product__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="Lorem ipsum dolor sit amet thumbnail">
              </div>
              <div class="cart-product__main">
                <span class="cart-product__capture">
                  Category & Category
                </span>
                <h4 class="cart-product__title">
                  <a class="cart-product__link" href="">
                    Lorem ipsum dolor sit amet
                  </a>
                </h4>
              </div>
            </div>
          </td>
          <td>
            <span class="cart-product__price">450.25 USD</b>
          </td>
          <td>
            <span class="cart-product__quantity">1</span>
          </td>
          <td>
            <span class="cart-product__price cart-product__price--total">450.25 USD</span>
          </td>
        </tr>

      </tbody>
    </table>

    <span class="cart-checkout__delimiter"></span>

    <div class="cart-checkout__content">
      <h1 class="cart-checkout__title">Deal Order Details</h1>
      <h2 class="cart-checkout__description">Enter customer person info</h2>
      <span class="cart-checkout__delimiter"></span>

      <form class="cart-checkout__form" action="#">
        <input required="" class="cart-checkout__input" type="text" placeholder="First name" value=""
          name="cart_checkout_first_name">
        <input required="" class="cart-checkout__input" type="text" placeholder="Last name" value=""
          name="cart_checkout_last_name">
        <input required="" class="cart-checkout__input" type="text" placeholder="Phone number" value=""
          name="cart_checkout_phone_number">
        <input required="" class="cart-checkout__input" type="text" placeholder="Address" value=""
          name="cart_checkout_address">
        <input required="" class="cart-checkout__input" type="email" placeholder="Email" value=""
          name="cart_checkout_email">
        <select required="" class="cart-checkout__input" name="cart_checkout_country">
          <option>Select country</option>

          <option value="142531">
            Afghanistan
          </option>

          <option value="142556">
            Albania
          </option>

          <option value="142484">
            Algeria
          </option>

          <option value="86435">
            Andorra
          </option>

          <option value="86381">
            Angola
          </option>

          <option value="86432">
            Antigua and Barbuda
          </option>

          <option value="142475">
            Argentina
          </option>

          <option value="2">
            Armenia
          </option>

          <option value="142515">
            Australia
          </option>

          <option value="86373">
            Austria
          </option>

          <option value="1">
            Azerbaydjan
          </option>

          <option value="86393">
            Bahamas
          </option>

          <option value="86433">
            Bahrain
          </option>

          <option value="86421">
            Bangladesh
          </option>

          <option value="86414">
            Barbados
          </option>

          <option value="3">
            Belarus
          </option>

          <option value="142522">
            Belgium
          </option>

          <option value="86406">
            Belize
          </option>

          <option value="86390">
            Benin
          </option>

          <option value="86434">
            Bhutan
          </option>

          <option value="142493">
            Bolivia
          </option>

          <option value="86417">
            Bosnia and Herzegovina
          </option>

          <option value="86424">
            Botswana
          </option>

          <option value="142495">
            Brazil
          </option>

          <option value="142469">
            Brunei
          </option>

          <option value="142476">
            Bulgaria
          </option>

          <option value="142518">
            Burkina Faso
          </option>

          <option value="86385">
            Burundi
          </option>

          <option value="142514">
            Cambodia
          </option>

          <option value="86428">
            Cameroon
          </option>

          <option value="142554">
            Canada
          </option>

          <option value="86374">
            Cape Verde
          </option>

          <option value="86444">
            Central African Republic
          </option>

          <option value="86409">
            Chad
          </option>

          <option value="142527">
            Chile
          </option>

          <option value="86404">
            China
          </option>

          <option value="86388">
            Columbia
          </option>

          <option value="86364">
            Comoros
          </option>

          <option value="142526">
            Congo Republic
          </option>

          <option value="86402">
            Cook Island
          </option>

          <option value="142517">
            Costa Rica
          </option>

          <option value="142467">
            Cote D'ivoire
          </option>

          <option value="142558">
            Croatia
          </option>

          <option value="142506">
            Cuba
          </option>

          <option value="142536">
            Cyprus
          </option>

          <option value="142477">
            Czech Republic
          </option>

          <option value="86408">
            Democratic Republic of Congo
          </option>

          <option value="86386">
            Denmark
          </option>

          <option value="86391">
            Djibouti
          </option>

          <option value="142520">
            Dominica
          </option>

          <option value="142494">
            Dominican Republic
          </option>

          <option value="142546">
            East Timor
          </option>

          <option value="142473">
            Ecuador
          </option>

          <option value="142489">
            Egypt
          </option>

          <option value="142502">
            El Salvador
          </option>

          <option value="86366">
            Equatorial Guinea
          </option>

          <option value="86427">
            Eritrea
          </option>

          <option value="16">
            Estonia
          </option>

          <option value="142521">
            Ethiopia
          </option>

          <option value="86377">
            Federated State of Micronesia
          </option>

          <option value="86383">
            Fiji
          </option>

          <option value="86410">
            Finland
          </option>

          <option value="86394">
            France
          </option>

          <option value="142471">
            Gabon
          </option>

          <option value="142535">
            Gambia
          </option>

          <option value="4">
            Georgia
          </option>

          <option value="142464">
            Germany
          </option>

          <option value="142539">
            Ghana
          </option>

          <option value="86365">
            Greece
          </option>

          <option value="142534">
            Grenada
          </option>

          <option value="86442">
            Guatemala
          </option>

          <option value="142496">
            Guinea
          </option>

          <option value="142483">
            Guinea Bissau
          </option>

          <option value="142492">
            Guyana
          </option>

          <option value="86416">
            Haiti
          </option>

          <option value="142538">
            Honduras
          </option>

          <option value="142498">
            Hungary
          </option>

          <option value="86415">
            Iceland
          </option>

          <option value="142480">
            India
          </option>

          <option value="142540">
            Indonesia
          </option>

          <option value="142488">
            Iran
          </option>

          <option value="142485">
            Iraq
          </option>

          <option value="142547">
            Ireland
          </option>

          <option value="5">
            Israel
          </option>

          <option value="142528">
            Italy
          </option>

          <option value="86376">
            Jamaica
          </option>

          <option value="86379">
            Japan
          </option>

          <option value="142533">
            Jordan
          </option>

          <option value="6">
            Kazakhstan
          </option>

          <option value="142543">
            Kenya
          </option>

          <option value="86437">
            Kiribati
          </option>

          <option value="142487">
            Kuwait
          </option>

          <option value="7">
            Kyrgyzstan
          </option>

          <option value="142501">
            Laos
          </option>

          <option value="8">
            Latvia
          </option>

          <option value="86382">
            Lebanon
          </option>

          <option value="86441">
            Lesotho
          </option>

          <option value="142465">
            Liberia
          </option>

          <option value="142508">
            Libya
          </option>

          <option value="142466">
            Liechtenstein
          </option>

          <option value="9">
            Lithuania
          </option>

          <option value="142532">
            Luxembourg
          </option>

          <option value="86425">
            Macedonia
          </option>

          <option value="142553">
            Madagascar
          </option>

          <option value="86392">
            Malawi
          </option>

          <option value="142511">
            Malaysia
          </option>

          <option value="142548">
            Maldives
          </option>

          <option value="86405">
            Mali
          </option>

          <option value="142472">
            Malta
          </option>

          <option value="142559">
            Marshall Islands
          </option>

          <option value="142461">
            Mauretania
          </option>

          <option value="142463">
            Mauritius
          </option>

          <option value="86397">
            Mexico
          </option>

          <option value="10">
            Moldova
          </option>

          <option value="142490">
            Monaco
          </option>

          <option value="142507">
            Mongolia
          </option>

          <option value="142499">
            Montenegro
          </option>

          <option value="86389">
            Morocco
          </option>

          <option value="142529">
            Mozambique
          </option>

          <option value="142557">
            Myanmar
          </option>

          <option value="86380">
            Namibia
          </option>

          <option value="86440">
            Nauru
          </option>

          <option value="142537">
            Nepal
          </option>

          <option value="86419">
            Netherlands
          </option>

          <option value="86396">
            New Zealand
          </option>

          <option value="86403">
            Nicaragua
          </option>

          <option value="86418">
            Niger
          </option>

          <option value="86400">
            Nigeria
          </option>

          <option value="86369">
            Niue
          </option>

          <option value="86395">
            North Korea
          </option>

          <option value="142523">
            Norway
          </option>

          <option value="142541">
            Oman
          </option>

          <option value="86431">
            Pakistan
          </option>

          <option value="142555">
            Palau
          </option>

          <option value="142512">
            Palestine
          </option>

          <option value="86443">
            Panama
          </option>

          <option value="142486">
            Papua New Guinea
          </option>

          <option value="142468">
            Paraguay
          </option>

          <option value="86430">
            Peru
          </option>

          <option value="86411">
            Philippines
          </option>

          <option value="86426">
            Poland
          </option>

          <option value="86439">
            Portugal
          </option>

          <option value="142513">
            Qatar
          </option>

          <option value="142516">
            Romania
          </option>

          <option value="11">
            Russia
          </option>

          <option value="142500">
            Rwanda
          </option>

          <option value="86436">
            Saint Kitts and Nevis
          </option>

          <option value="86422">
            Saint Lucia
          </option>

          <option value="142470">
            Saint Vincent and the Grenadines
          </option>

          <option value="142525">
            Samoa
          </option>

          <option value="142510">
            San Marino
          </option>

          <option value="142460">
            Sao Tome and Principe
          </option>

          <option value="86375">
            Saudi Arabia
          </option>

          <option value="142552">
            Senegal
          </option>

          <option value="86438">
            Serbia
          </option>

          <option value="86372">
            Seychelles
          </option>

          <option value="142504">
            Sierra Leone
          </option>

          <option value="86387">
            Singapore
          </option>

          <option value="142478">
            Slovakia
          </option>

          <option value="86371">
            Slovenia
          </option>

          <option value="86378">
            Solomon Islands
          </option>

          <option value="142482">
            Somalia
          </option>

          <option value="86420">
            South Africa
          </option>

          <option value="86423">
            South Korea
          </option>

          <option value="142503">
            South Sudan
          </option>

          <option value="86412">
            Spain
          </option>

          <option value="142519">
            Sri Lanka
          </option>

          <option value="142481">
            Sudan
          </option>

          <option value="86370">
            Suriname
          </option>

          <option value="142545">
            Swaziland
          </option>

          <option value="142497">
            Sweden
          </option>

          <option value="86398">
            Switzerland
          </option>

          <option value="86384">
            Syria
          </option>

          <option value="12">
            Tajikistan
          </option>

          <option value="142550">
            Tanzania
          </option>

          <option value="86413">
            Thailand
          </option>

          <option value="142551">
            Togo
          </option>

          <option value="142505">
            Tonga
          </option>

          <option value="86367">
            Trinidad and Tobago
          </option>

          <option value="142509">
            Tunisia
          </option>

          <option value="86429">
            Turkey
          </option>

          <option value="13">
            Turkmenistan
          </option>

          <option value="142474">
            Tuvalu
          </option>

          <option value="142479">
            USA
          </option>

          <option value="142491">
            Uganda
          </option>

          <option value="15">
            Ukraine
          </option>

          <option value="142544">
            United Arab Emirates
          </option>

          <option value="86399">
            United Kingdom
          </option>

          <option value="86368">
            Uruguay
          </option>

          <option value="14">
            Uzbekistan
          </option>

          <option value="142542">
            Vanuatu
          </option>

          <option value="142549">
            Vatican City
          </option>

          <option value="86401">
            Venezuela
          </option>

          <option value="86407">
            Vietnam
          </option>

          <option value="142530">
            Yemen
          </option>

          <option value="142462">
            Zambia
          </option>

          <option value="142524">
            Zimbabwe
          </option>

        </select>
        <div style="margin: 8px 0 24px auto;">
          <input required="" type="checkbox" name="cart_checkout_agree">
          <label for="cart_checkout_agree"> Agree with license conditions</label>
        </div>
      </form>
      <div class="pelecard__form">
        <button type="button" class="pelecard__button pelecard__button--disabled">
          Credit card payment
        </button> 
      </div>
    </div>

    <div class="cart-checkout__buttons">
      <button type="button" class="cart-checkout__button cart-checkout__button--disabled">
        Prev step
      </button>

      <div class="cart-checkout__steps-buttons">

        <svg class="cart-checkout__step-button" fill="#888888" width="8px" data-step="0" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="1" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="2" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="3" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="4" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="5" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="6" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="7" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="8" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="9" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="10" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="11" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="12" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="13" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="14" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

        <svg class="cart-checkout__step-button" fill="#cccccc" width="8px" data-step="15" viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>

      </div>

      <div class="cart-checkout__buttons cart-checkout__buttons--second">
        <button type="button" class="cart-checkout__button cart-checkout__button--submit">
          Submit
        </button>
        <button type="button" class="cart-checkout__button">
          Next step
        </button>
      </div>
    </div>
    </div>
  </div>
  `;

  blockManager.add("cart-checkout", {
    label: `
      <img src="${icon}" alt="cart-checkout block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Cart Checkout
      </div>
    `,
    content,
    category: "B24Online Cart"
  });
}
