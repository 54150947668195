import React from "react";
import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DefaultButton from "../default-button/default-button.component";
import PhoneIcon from "@material-ui/icons/Phone";
import LanguageIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";
import { ReactComponent as LiveChatIcon } from "../../assets/live-chat-icon.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useTranslation } from "react-i18next";
import { colorPalette } from "../../styles/colorPalette";
import "./company-organization-card.styles.scss";

const CompanyOrganizationCard = ({
  img,
  title,
  bodyText,
  location,
  number,
  website,
  linkTo
}) => {
  const { t } = useTranslation();
  return (
    <div className="company-organization-card">
      <Card>
        <CardContent>
          <div className="company-organization-card__body">
            <div className="company-organization-card__body__img-container">
              <img
                className="company-organization-card__body__img-container__img"
                src={img}
                alt=""
              />
            </div>

            <div className="company-organization-card__body__description">
              <h2>{title}</h2>
              <div className="company-organization-card__body__description__body-text">
                {bodyText}
              </div>
            </div>
          </div>
          <div className="company-organization-card__footer">
            <div className="company-organization-card__footer__actions-container">
              <div className="company-organization-card__footer__actions-container__action">
                <LocationOnIcon />
                <p>{location}</p>
              </div>
              {number && (
                <div className="company-organization-card__footer__actions-container__action">
                  <PhoneIcon />
                  <p>{number}</p>
                </div>
              )}
            </div>
            <div className="company-organization-card__footer__actions-container">
              <div className="company-organization-card__footer__actions-container__action">
                <LiveChatIcon />
                <p>{t("Live Chat")}</p>
              </div>
              {website && (
                <div className="company-organization-card__footer__actions-container__action">
                  <LanguageIcon />
                  <p>
                    <a target="_blank" rel="noopener noreferrer" href={website}>
                      {website}
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className="company-organization-card__footer__actions-container">
              <div className="company-organization-card__footer__actions-container__action">
                <EmailIcon />
                <p>{t("Contact us")}</p>
              </div>
            </div>
            <div className="company-organization-card__footer__button">
              <Link to={linkTo}>
                <DefaultButton
                  width="180px"
                  backgroundColor={colorPalette.colorPrimary.main}
                  hover={colorPalette.colorPrimary.darker}
                  color="white"
                >
                  {t("Read more")}
                </DefaultButton>
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompanyOrganizationCard;
