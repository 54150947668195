import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  handleEmailChange,
  handleLastNameChange,
  handleFirstNameChange,
  handleBirthdayChange,
  handlePasswordChange,
  handlePhoneNumberChange,
  handleCountryChange,
  handleWebsiteChange,
  handleProfessionChange,
  handleProfileTypeChange,
  handleSexChange,
  handleActiveCompany
} from "../../redux/profile/profile.action";

import {
  DefaultDropdown,
  DefaultDatePicker,
  InputGroup,
  DefaultInputRadio
} from "../../components";

import "./profile-basic-info.styles.scss";

const ProfileBasicInfo = ({
  countries,
  companies,

  handleEmailChange,
  handleLastNameChange,
  handleFirstNameChange,
  handleBirthdayChange,
  handlePasswordChange,
  handlePhoneNumberChange,
  handleCountryChange,
  handleWebsiteChange,
  handleProfessionChange,
  handleProfileTypeChange,
  handleSexChange,
  handleActiveCompany,

  email,
  lastName,
  firstName,
  birthday,
  password,
  phoneNumber,
  country,
  website,
  profession,
  profileType,
  sex,

  profileAPI,
  userEmail,

  setCountrySearchHandler,
  setCompanySearchHandler
}) => {
  useEffect(() => {
    handleEmailChange(userEmail);
    handleLastNameChange(profileAPI && profileAPI.last_name);
    handleFirstNameChange(profileAPI && profileAPI.first_name);
    handleBirthdayChange(profileAPI && new Date(profileAPI.birthday));
    handlePhoneNumberChange(profileAPI && profileAPI.mobile_number);
    handleWebsiteChange(profileAPI && profileAPI.site);
    handleProfessionChange(profileAPI && profileAPI.profession);
    handleProfileTypeChange(profileAPI && profileAPI.user_type);
    handleSexChange(profileAPI && profileAPI.sex);
    handleActiveCompany(
      profileAPI && profileAPI.metadata && profileAPI.metadata.active_company
    );
  }, [
    handleEmailChange,
    userEmail,
    handleLastNameChange,
    handleFirstNameChange,
    handleBirthdayChange,
    handlePhoneNumberChange,
    handleWebsiteChange,
    handleProfessionChange,
    handleProfileTypeChange,
    handleSexChange,
    profileAPI,
    handleActiveCompany
  ]);

  return (
    <div className="profile-basic-info">
      <h2>Basic info</h2>
      <div style={{ marginTop: 18 }}>
        <InputGroup
          onChange={e => handleEmailChange(e.target.value)}
          value={email}
          label="EMAIL"
          name="email"
          placeholder="Email"
          disabled={true}
        />
      </div>

      <br />
      <div className="profile-basic-info__input-container">
        <div style={{ width: "48%" }}>
          <InputGroup
            onChange={e => handleLastNameChange(e.target.value)}
            value={lastName}
            label="LAST NAME"
            name="last-name"
            placeholder="Last Name"
          />
        </div>
        <div style={{ width: "48%" }}>
          <InputGroup
            onChange={e => handleFirstNameChange(e.target.value)}
            value={firstName}
            label="FIRST NAME"
            name="first-name"
            placeholder="First Name"
          />
        </div>
      </div>
      <div
        style={{ marginTop: 18 }}
        className="profile-basic-info__input-container"
      >
        <div style={{ width: "48%" }}>
          <DefaultDatePicker
            label="BIRTHDAY"
            returnDate={handleBirthdayChange}
            value={birthday}
          />
        </div>
        <div style={{ width: "48%" }}>
          <InputGroup
            onChange={e => handlePasswordChange(e.target.value)}
            value={password}
            label="PASSWORD"
            name="password"
            placeholder="Password"
            type="password"
          />
        </div>
      </div>
      <div
        style={{ marginTop: 18 }}
        className="profile-basic-info__input-container"
      >
        <div style={{ width: "48%" }}>
          <InputGroup
            onChange={e => handlePhoneNumberChange(e.target.value)}
            value={phoneNumber}
            label="PHONE NUMBER"
            name="phone-number"
            placeholder="Phone number"
          />
        </div>
        <div style={{ width: "48%" }}>
          <DefaultDropdown
            filter={true}
            label="COUNTRY"
            inputFunc={e => setCountrySearchHandler(e.target.value)}
            items={countries}
            returnData={handleCountryChange}
            customTitle={
              profileAPI && profileAPI.country && profileAPI.country.name
            }
          />
        </div>
      </div>
      <div
        style={{ marginTop: 18 }}
        className="profile-basic-info__input-container"
      >
        <div style={{ width: "48%" }}>
          <InputGroup
            onChange={e => handleProfessionChange(e.target.value)}
            value={profession}
            label="PROFESSION"
            name="profession"
            placeholder="Profession"
          />
        </div>
      </div>
      <div style={{ width: "100%", marginTop: 18 }}>
        <DefaultDropdown
          filter={true}
          inputFunc={e => setCompanySearchHandler(e.target.value)}
          label="COMPANY"
          items={companies}
          returnData={handleActiveCompany}
        />
      </div>
      <div className="profile-basic-info__input-container">
        <div style={{ width: "48%" }}>
          <label className="profile-basic-info__label">PROFILE TYPE</label>
          <br />
          <div style={{ marginBottom: 3 }}>
            <DefaultInputRadio
              onChange={e => handleProfileTypeChange(e.currentTarget.value)}
              id="profile-businessman"
              name="profile-type"
              value="businessman"
              checked={profileType === "businessman"}
              labelText="Businessman"
            />
          </div>
          <DefaultInputRadio
            onChange={e => handleProfileTypeChange(e.currentTarget.value)}
            id="profile-individual"
            name="profile-type"
            value="individual"
            checked={profileType === "individual"}
            labelText="Individual"
          />
          <br />
        </div>
        <div style={{ width: "48%" }}>
          <label className="profile-basic-info__label">SEX</label>
          <br />
          <div style={{ marginBottom: 3 }}>
            <DefaultInputRadio
              onChange={e => handleSexChange(e.currentTarget.value)}
              id="profile-male"
              name="sex"
              value="male"
              checked={sex === "male"}
              labelText="Male"
            />
          </div>
          <DefaultInputRadio
            onChange={e => handleSexChange(e.currentTarget.value)}
            id="profile-female"
            name="sex"
            value="female"
            checked={sex === "female"}
            labelText="Female"
          />
          <br />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  email: state.profile.email,
  lastName: state.profile.lastName,
  firstName: state.profile.firstName,
  birthday: state.profile.birthday,
  password: state.profile.password,
  phoneNumber: state.profile.phoneNumber,
  country: state.profile.country,
  website: state.profile.website,
  profession: state.profile.profession,
  profileType: state.profile.profileType,
  sex: state.profile.sex,

  profileAPI: state.profileAPI.profile,
  userEmail: state.profileAPI.userEmail
});

export default connect(mapStateToProps, {
  handleEmailChange,
  handleLastNameChange,
  handleFirstNameChange,
  handleBirthdayChange,
  handlePasswordChange,
  handlePhoneNumberChange,
  handleCountryChange,
  handleWebsiteChange,
  handleProfessionChange,
  handleProfileTypeChange,
  handleSexChange,
  handleActiveCompany
})(ProfileBasicInfo);
