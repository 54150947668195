export default function authStateButtonBlock({ editor }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function render(
      id,
      singinText,
      singoutText,
      singinRedirect,
      singoutRedirect
    ) {
      const buttonEl = document.getElementById(id);
      const token = localStorage.getItem("token");
      const state = !!token;

      buttonEl.textContent = state ? singoutText : singinText;

      if (!window.hasOwnProperty("grapesjs")) return;

      buttonEl.addEventListener("click", e => {
        e.preventDefault();

        if (state) {
          localStorage.removeItem("token");
          singoutRedirect
            ? window.location.assign(singoutRedirect)
            : window.location.reload();
        } else {
          singinRedirect && window.location.assign(singinRedirect);
        }
      });
    }

    // Get translation
    const translations = getTranslate("translations", this.attributes);

    const id = this.id;

    const singinText = translations["Sign In"] || "Sign In";
    const singoutText = translations["Sign Out"] || "Sign Out";

    const singinRedirect =
      (this.attributes.signinredirect &&
        this.attributes.signinredirect.value) ||
      "";
    const singoutRedirect =
      (this.attributes.signoutredirect &&
        this.attributes.signoutredirect.value) ||
      "";

    render(id, singinText, singoutText, singinRedirect, singoutRedirect);
  };

  editor.DomComponents.addType("auth-state-button", {
    model: {
      defaults: {
        tagName: "button",
        name: "State Button",
        script,
        style: {
          cursor: "pointer"
        },
        traits: [
          {
            type: "page-selector",
            label: "Sign In Redirect",
            name: "signinredirect",
            modalTitle: "Select page with login"
          },
          {
            type: "page-selector",
            label: "Sign Out Redirect",
            name: "signoutredirect",
            modalTitle: "Select redirect page after sign out"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Sign In", "Sign Out"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:signinredirect",
          this.render
        );
        this.listenTo(
          this.model,
          "change:attributes:signoutredirect",
          this.render
        );
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
