import icon from "./icon.png";

export default function accessibilityWidgetBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const content = `
  <div class="gjs-accessibility-widget"></div>
  `;

  blockManager.add("gjs-accessibility-widget", {
    label: `
      <img src="${icon}" alt="Translations base block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Accessibility Widget
      </div>
    `,
    content,
    category: "B24Online Translations"
  });
}
