import { ProfileActionTypes } from "./profileAPI.types";

const INITIAL_STATE = {
  isProfilePending: true,
  profile: {},
  profileError: null,

  isProfileByIdPending: false,
  profileById: {},
  profileByIdError: null,

  isUserEmailPending: false,
  userEmail: "",
  userEmailError: null,

  isPutProfilePending: false,
  putProfile: {},
  putProfileError: null,

  isProfileImagePatchPending: false,
  profileImagePatch: {},
  profileImagePatchError: null,

  isProfileByUserIdPending: false,
  profileByUserIdData: {},
  profileByUserIdError: [],

  isUserSearchPending: false,
  searchedUsers: {},
  searchedUsersError: {},

  profileRequestStatus: ""
};

const ProfileAPIReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProfileActionTypes.GET.GET_PROFILE_PENDING:
      return {
        ...state,
        isProfilePending: true
      };

    case ProfileActionTypes.GET.GET_PROFILE_SUCCESS:
      return {
        ...state,
        isProfilePending: false,
        profile: action.payload,
        profileError: null
      };

    case ProfileActionTypes.GET.GET_PROFILE_ERROR:
      return {
        ...state,
        isProfilePending: false,
        profileError: action.payload
      };

    case ProfileActionTypes.GET_EMAIL.GET_PROFILE_EMAIL_PENDING:
      return {
        ...state,
        isUserEmailPending: true
      };

    case ProfileActionTypes.GET_EMAIL.GET_PROFILE_EMAIL_SUCCESS:
      return {
        ...state,
        isUserEmailPending: false,
        userEmail: action.payload,
        userEmailError: null
      };

    case ProfileActionTypes.GET_EMAIL.GET_PROFILE_EMAIL_ERROR:
      return {
        ...state,
        isProfilePending: false,
        userEmailError: action.payload
      };

    case ProfileActionTypes.PUT.PUT_PROFILE_PENDING:
      return {
        ...state,
        isPutProfilePending: true
      };

    case ProfileActionTypes.PUT.PUT_PROFILE_SUCCESS:
      return {
        ...state,
        putProfile: action.payload,
        putProfileError: null,
        profileRequestStatus: action.payload.requestStatus,
        isPutProfilePending: false
      };

    case ProfileActionTypes.PUT.PUT_PROFILE_ERROR:
      return {
        ...state,
        isPutProfilePending: false,
        putProfileError: action.payload
      };

    case ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_PENDING:
      return { ...state, isProfileImagePatchPending: true };

    case ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_SUCCESS:
      return {
        ...state,
        isProfileImagePatchPending: false,
        profileImagePatch: action.payload,
        profileImagePatchError: null
      };

    case ProfileActionTypes.PATCH.AVATAR.PATCH_PROFILE_AVATAR_ERROR:
      return {
        ...state,
        isProfileImagePatchPending: false,
        profileImagePatchError: action.payload
      };

    case ProfileActionTypes.GET.BY_ID.GET_PROFILE_BY_ID_PENDING:
      return { ...state, isProfileByIdPending: true };

    case ProfileActionTypes.GET.BY_ID.GET_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        isProfileByIdPending: false,
        profileById: action.payload,
        profileByIdError: null
      };

    case ProfileActionTypes.GET.BY_ID.GET_PROFILE_BY_ID_ERROR:
      return {
        ...state,
        isProfileByIdPending: false,
        profileByIdError: action.payload
      };

    case ProfileActionTypes.GET.SEARCH_USER_BY_EMAIL_PENDING:
      return {
        ...state,
        isUserSearchPending: true
      };

    case ProfileActionTypes.GET.SEARCH_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        searchedUsers: action.payload,
        isUserSearchPending: false
      };

    case ProfileActionTypes.GET.SEARCH_USER_BY_EMAIL_ERROR:
      return {
        ...state,
        searchedUsersError: action.payload,
        isUserSearchPending: false
      };

    case ProfileActionTypes.CLEAR_REQUEST_STATUS:
      return {
        ...state,
        profileRequestStatus: action.payload
      };
    default:
      return state;
  }
};

export default ProfileAPIReducer;
