import icon from "./icon.png";

export default function paymentInfoBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  
  </style>
  `;

  const content = `
  ${style}
  <div class="payment-info-wrapper">
    <div class="payment-info">
      <div class="payment-info__text">Transaction id:</div>
    </div>
  </div>
  `;

  blockManager.add("payment-info", {
    label: `
      <img src="${icon}" alt="Payment info block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Payment Info
      </div>
    `,
    content,
    category: "B24Online Cart"
  });
}
