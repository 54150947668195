export const B2CProductsActionTypes = {
  GET_B2C_PRODUCTS: {
    GET_B2C_PRODUCTS_PENDING: "GET_B2C_PRODUCTS_PENDING",
    GET_B2C_PRODUCTS_SUCCESS: "GET_B2C_PRODUCTS_SUCCESS",
    GET_B2C_PRODUCTS_ERROR: "GET_B2C_PRODUCTS_ERROR"
  },
  GET_B2C_PRODUCTS_CATEGORIES: {
    GET_B2C_PRODUCTS_CATEGORIES_PENDING: "GET_B2C_PRODUCTS_CATEGORIES_PENDING",
    GET_B2C_PRODUCTS_CATEGORIES_SUCCESS: "GET_B2C_PRODUCTS_CATEGORIES_SUCCESS",
    GET_B2C_PRODUCTS_CATEGORIES_ERROR: "GET_B2C_PRODUCTS_CATEGORIES_ERROR"
  },
  COMPANY: {
    GET_COMPANY_B2C_PRODUCTS_PENDING: "GET_COMPANY_B2C_PRODUCTS_PENDING",
    GET_COMPANY_B2C_PRODUCTS_SUCCESS: "GET_COMPANY_B2C_PRODUCTS_SUCCESS",
    GET_COMPANY_B2C_PRODUCTS_FAIL: "GET_COMPANY_B2C_PRODUCTS_FAIL"
  },
  ORGANIZATION: {
    GET_ORGANIZATION_B2C_PRODUCTS_PENDING:
      "GET_ORGANIZATION_B2C_PRODUCTS_PENDING",
    GET_ORGANIZATION_B2C_PRODUCTS_SUCCESS:
      "GET_ORGANIZATION_B2C_PRODUCTS_SUCCESS",
    GET_ORGANIZATION_B2C_PRODUCTS_FAIL: "GET_ORGANIZATION_B2C_PRODUCTS_FAIL"
  },
  POST: {
    CREATE_B2C_PRODUCT_PENDING: "CREATE_B2C_PRODUCT_PENDING",
    CREATE_B2C_PRODUCT_SUCCESS: "CREATE_B2C_PRODUCT_SUCCESS",
    CREATE_B2C_PRODUCT_ERROR: "CREATE_B2C_PRODUCT_ERROR"
  },
  PUT: {
    UPDATE_B2C_PRODUCT_PENDING: "UPDATE_B2C_PRODUCT_PENDING",
    UPDATE_B2C_PRODUCT_SUCCESS: "UPDATE_B2C_PRODUCT_SUCCESS",
    UPDATE_B2C_PRODUCT_ERROR: "UPDATE_B2C_PRODUCT_ERROR"
  },
  GET: {
    USER: {
      GET_USER_B2C_PRODUCTS_PENDING: "GET_USER_B2C_PRODUCTS_PENDING",
      GET_USER_B2C_PRODUCTS_SUCCESS: "GET_USER_B2C_PRODUCTS_SUCCESS",
      GET_USER_B2C_PRODUCTS_ERROR: "GET_USER_B2C_PRODUCTS_ERROR"
    },
    RECOMMENDED: {
      GET_RECOMMENDED_B2C_PRODUCTS_PENDING:
        "GET_RECOMMENDED_B2C_PRODUCTS_PENDING",
      GET_RECOMMENDED_B2C_PRODUCTS_SUCCESS:
        "GET_RECOMMENDED_B2C_PRODUCTS_SUCCESS",
      GET_RECOMMENDED_B2C_PRODUCTS_ERROR: "GET_RECOMMENDED_B2C_PRODUCTS_ERROR"
    },
    SIMILAR: {
      GET_SIMILAR_B2C_PRODUCTS_PENDING: "GET_SIMILAR_B2C_PRODUCTS_PENDING",
      GET_SIMILAR_B2C_PRODUCTS_SUCCESS: "GET_SIMILAR_B2C_PRODUCTS_SUCCESS",
      GET_SIMILAR_B2C_PRODUCTS_ERROR: "GET_SIMILAR_B2C_PRODUCTS_ERROR"
    }
  },
  PATCH: {
    IMAGE: {
      PATCH_B2C_IMAGE_PENDING: "PATCH_B2C_IMAGE_PENDING",
      PATCH_B2C_IMAGE_SUCCESS: "PATCH_B2C_IMAGE_SUCCESS",
      PATCH_B2C_IMAGE_ERROR: "PATCH_B2C_IMAGE_ERROR"
    }
  },
  DELETE: {
    DELETE_B2C_PRODUCT_PENDING: "DELETE_B2C_PRODUCT_PENDING",
    DELETE_B2C_PRODUCT_SUCCESS: "DELETE_B2C_PRODUCT_SUCCESS",
    DELETE_B2C_PRODUCT_ERROR: "DELETE_B2C_PRODUCT_ERROR"
  },
  CLEAR_B2C_PRODUCTS_REQUEST: "CLEAR_B2C_PRODUCTS_REQUEST"
};
