export default function jobsWithPaginationComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makePointSvg() {
      return `
      <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713" style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
        <g>
          <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
		        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
		        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
		        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
            C67.542,46.276,59.159,54.659,48.855,54.659z" />
        </g>
      </svg>
      `;
    }

    function makeJobHtml(
      id,
      title,
      description,
      address,
      typeOfEmployment,
      vacancy,
      date,
      detailsPath,
      translations
    ) {
      return `
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="${detailsPath}?gjs-job-id=${id}">
            ${title}
          </a>
        </h4>
        <span class="jwp-job__vacancy">
          ${vacancy ? vacancy : translations["N/A"]}
        </span>
        <div class="jwp-job__description">
        ${description ? description : translations["No description"]}
        </div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
            ${makePointSvg()}
            <span class="jwp-job__address">
              ${address ? address : translations["N/A"]}
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              ${typeOfEmployment ? typeOfEmployment : translations["N/A"]}
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              ${date ? date : translations["N/A"]}
            </span>
          </div>
        </div>
      </div>
      `;
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
      <div class="jwp-pagination">
        ${
          currentPage - 1
            ? `
            <button class="jwp-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="jwp-pagination__button jwp-pagination__button--disabled" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="jwp-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${
          currentPage - 4 > 0
            ? `<span class="jwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage - 2 > 0
            ? `
            <button class="jwp-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="jwp-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="jwp-pagination__button jwp-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="jwp-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${
          currentPage + 3 <= totalPagesCount
            ? `<span class="jwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="jwp-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="jwp-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="jwp-pagination__button jwp-pagination__button--disabled" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".jobs-with-pagination-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("jobs-with-pagination-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("jobs-with-pagination-wrapper--preloader");
      }
    }

    function render(
      total,
      page,
      perPage,
      jobs,
      branches,
      loading,
      detailsPath,
      translations
    ) {
      const wrapperEl = document.querySelector(".jobs-with-pagination-wrapper");
      setLoading(loading);

      if (jobs.length) {
        const jobsHtml = jobs.reduce(
          (tmp, job) =>
            tmp +
            makeJobHtml(
              job.id,
              job.title,
              `${job.description
                .replace(/(<([^>]+)>)/gi, "")
                .split(" ")
                .splice(0, 20)
                .join(" ")}...`,
              `${job.country_ext.name}, ${job.city}`,
              translations[job.type_of_employment],
              job.vacancy_ext.name,
              new Date(job.created_at).toLocaleDateString(),
              detailsPath,
              translations
            ),
          ""
        );
        const paginationHtml = makePaginationHtml(page, total, perPage);

        wrapperEl.innerHTML = `
          <div class="jobs-with-pagination">
            ${jobsHtml}
            ${paginationHtml}
          </div>  
        `;
      } else {
        wrapperEl.innerHTML = `
          <p style="margin: 0">
            <span class="jobs-with-pagination__empty">
              ${translations["No jobs..."]}
            </span>
          </p>
        `;
        return;
      }

      const paginationButtonsEls = document.querySelectorAll(
        ".jwp-pagination__button:not(.jwp-pagination__button--disabled)"
      );
      Array.prototype.slice.call(paginationButtonsEls).map(paginationButtonEl =>
        paginationButtonEl.addEventListener("click", e => {
          const btnPage = parseInt(e.target.dataset.page);
          window.jobs.setPage(btnPage);
        })
      );
    }

    if (!window.hasOwnProperty("jobs")) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window.jobs = {
        API_ROOT: API_ROOT,
        organization: organization,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 10,
        params: {},
        items: [],
        branches: [],
        translations: {},
        loading: false,
        detailsPath: "#",
        render() {
          console.group("jobs render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.branches,
              this.loading,
              this.detailsPath,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("render");
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", parseInt(page), this.getJobs.bind(this));
        },
        setPerPage(count) {
          this.setProp("perPage", parseInt(count), this.getJobs.bind(this));
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path);
        },
        getJobs() {
          if (this.loading) return;
          this.setLoading(true);

          const params = new URLSearchParams(
            Object.assign(
              {},
              this.params,
              { page: this.page, page_size: this.perPage },
              {
                branches: this.branches
                  .filter(branch => branch.selected)
                  .map(branch => branch.id)
                  .join()
              }
            )
          );
          return fetch(`${this.API_ROOT}/department/jobs/?${params}`, {
            method: "GET"
          })
            .then(response => response.json())
            .then(response => this.setJobs(response.count, response.results));
        },
        setJobs(count, items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.render();
          }
          // Set count
          this.count = count;
          // Set items
          this.items = items;
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        getBranches(text) {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/organization/branches/?search=${text}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(response =>
              this.setBranches(
                this.branches
                  .filter(branch => branch.selected)
                  .concat(
                    response.map(branch => ({
                      id: branch.id,
                      title: branch.name,
                      selected: false
                    }))
                  )
                  .reduce(
                    (tmp, branch) =>
                      tmp.find(i => i.id === branch.id)
                        ? tmp
                        : tmp.concat([branch]),
                    []
                  )
              )
            );
        },
        setBranches(branches) {
          // Reset page
          this.page = 1;
          // Set branches
          this.branches = branches
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => (a.selected ? -1 : 1));
          // Start render cycle
          this.setLoading(false);
          this.getJobs();
        },
        search(text) {
          if (this.loading) return;

          // Reset page
          this.page = 1;
          // Set search=text param in b2c/b2b jobs request
          this.setParam("search", text);
          // Get jobs
          this.getJobs();
        }
      };
    }

    if (window.hasOwnProperty("jobs") && window.hasOwnProperty("grapesjs")) {
      if (!window.jobs.renderCycle.find(func => func === render)) {
        window.jobs.renderCycle.push(render);
      }

      if (this.attributes.perpage) {
        window.jobs.setPerPage(this.attributes.perpage.value);
      }

      window.jobs.setParam("company", "{[ organization ]}");

      if (this.attributes.detailspage) {
        window.jobs.setDetailsPath(this.attributes.detailspage.value);
      }

      const translations = getTranslate("translations", this.attributes);
      window.jobs.setProp("translations", translations);

      window.jobs.getJobs().then(() => window.jobs.render());
    }
  };

  editor.DomComponents.addType("jobs-with-pagination", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "jobs-with-pagination-wrapper",
    model: {
      defaults: {
        tagName: "div",
        name: "Jobs With Pagination",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "number",
            label: "Jobs per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "page-selector",
            label: "Job Details",
            name: "detailspage",
            modalTitle: "Select job details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "No jobs...",
              "No description",
              "N/A",
              "full_time",
              "partial",
              "shifts",
              "for_students"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
      }
    }
  });
}
