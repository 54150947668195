import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { getEvents } from "../../redux/APIs/events/events.action";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./comp-org-activity-exhibitions.styles.scss";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    // minWidth: 650
  },
  noBorder: {
    border: "none"
  }
});

const CompOrgActivityExhibitions = ({
  getEvents,
  areEventsPending,
  count,
  events
}) => {
  const activeId = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    const getActiveData = () => {
      if (activeId.companyId) {
        getEvents("", 1, activeId.companyId);
      } else if (activeId.organizationId) {
        getEvents("", 1, activeId.organizationId);
      }
    };
    getActiveData();
  }, [activeId, getEvents]);

  return (
    <div className="comp-org-activity-exhibitions">
      <h3>Events</h3>
      {events.length > 0 ? (
        <div className="table-content">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">LOGO</TableCell>
                <TableCell align="left">NAME</TableCell>
                <TableCell align="left">START DATE</TableCell>
                <TableCell align="left">END DATE</TableCell>
                <TableCell align="left">CITY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map(
                ({ id, title, start_date, end_date, city, organization }) => (
                  <TableRow
                    component={Link}
                    to={`/events/event/${id}/${
                      organization.parent ? t("company") : t("organization")
                    }/${organization.id}`}
                    key={id}
                  >
                    <TableCell className={classes.noBorder} align="left">
                      <img
                        style={{ width: "100px" }}
                        src={organization && organization.logo}
                        alt=""
                      />
                    </TableCell>
                    <TableCell
                      style={{ width: "40%" }}
                      className={classes.noBorder}
                      align="left"
                    >
                      {title}
                    </TableCell>
                    <TableCell className={classes.noBorder} align="left">
                      {start_date}
                    </TableCell>
                    <TableCell className={classes.noBorder} align="left">
                      {end_date}
                    </TableCell>
                    <TableCell className={classes.noBorder} align="left">
                      {city}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="comp-org-activity-exhibitions__no-events">
          No events to display
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areEventsPending: state.events.areEventsPending,
  count: state.events.count,
  events: state.events.events
});

export default connect(mapStateToProps, {
  getEvents
})(CompOrgActivityExhibitions);
