import icon from "./icon.png";

export default function productsCategoriesContainerLinkBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .products-categories-container-link {
    color: currentColor;
  }

  .products-categories-container-link:hover {
    color: currentColor;
    text-decoration-color: currentColor;
  }

  .products-categories-container-link.products-categories-container-link--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .products-categories-container-link.products-categories-container-link--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  </style>
  `;

  const content = `
  ${style}
  <a class="products-categories-container-link">
  </a>
  `;

  blockManager.add("products-categories-container-link", {
    label: `
      <img src="${icon}" alt="Products categories links block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Categories Container Link
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
