import React, { useState } from "react";

import { Tooltip, DefaultCheckbox } from "../../../components";

import "./wall-customization.styles.scss";

const WallCustomization = ({ icon, label, list }) => {
  const [visibility, setVisibility] = useState(null);

  const OptionsTooltip = (
    <div className="wall-customization-tooltip">
      <ul className="wall-customization-tooltip__list">
        {list.map(({ key, label, active, onToggle }) => (
          <li className="wall-customization-tooltip__list-item" key={key}>
            <DefaultCheckbox
              title={label}
              checkboxValue={active}
              setCheckboxChange={(state) => onToggle({ active: state })}
            />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <span className="wall-customization">
      <Tooltip
        placement="bottom"
        tooltip={OptionsTooltip}
        tooltipShown={visibility}
        onVisibilityChange={setVisibility}
      >
        <button className="wall-customization__button" type="button">
          {icon}
        </button>
      </Tooltip>
    </span>
  );
};

export default WallCustomization;
