export default function orderCheckComponent({ editor, api, site }) {
  const script = function() {
    const API_ROOT = "{[ apiRoot ]}";

    function render(API_ROOT) {
      const checkPelecard = () => {
        const body = {
          company: window.localStorage.getItem("order_company")
        };

        fetch(`${API_ROOT}/payments/deal/pelecard_check/`, {
          method: "POST",
          headers: Object.assign(
            {
              "Accept-Language": localStorage.getItem("language"),
              "Content-Type": "application/json"
            },
            localStorage.getItem("token")
              ? {
                  Authorization: `Token ${localStorage.getItem("token")}`
                }
              : {}
          ),
          body: JSON.stringify(body),
          cors: "cors",
          credentials: "omit"
        })
          .then(response => response.json())
          .then(response => {
            let timeOut = window.localStorage.getItem("check-pelecard-timeout");
            if (response?.detail === "Success" || response?.detail === "Paid") {
              window.localStorage.setItem(
                "transaction_id",
                response?.transaction_id
              );
              window.location.assign(`${window.location.origin}/order-success`);
            } else if (response.detail) {
              window.localStorage.setItem(
                "check-pelecard-timeout",
                parseInt(timeOut) + 2000
              );
              setTimeout(() => checkPelecard(), parseInt(timeOut));
            } else {
              window.location.assign(`${window.location.origin}/order-error`);
            }
          })
          .catch(error => {
            window.location.assign(`${window.location.origin}/order-error`);
          });
      };

      if (window.localStorage.getItem("order_company")) {
        window.localStorage.setItem("check-pelecard-timeout", "2000");
        checkPelecard();
      }
    }

    render.call(this, API_ROOT);
  };

  editor.DomComponents.addType("order-check", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "order-check-wrapper",
    model: {
      defaults: {
        name: "Check order",
        apiRoot: api.API_ROOT,
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
