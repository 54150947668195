import "./gjs-page-manager.scss";
import ApiService from "../../../services/api";

export default class gjsPageManager {
  state = {
    site: {},
    form: {},
    pages: [],
    btnText: "",
    pagination: {
      total: 1,
      current: 1
    }
  };

  constructor({ token, site, editor }) {
    this.editor = editor;
    this.api = new ApiService({ token });
    this.state = { ...this.state, site };
    this.modal = this.editor.Modal;

    editor.on("load", this.addCommands);
  }

  addCommands = () => {
    const { editor } = this;

    editor.Panels.addButton("options", [
      {
        id: "asset-manager",
        className: "fa fa-file-image-o",
        command: "core:open-assets",
        attributes: { title: "Asset Manager" }
      },
      {
        id: "pages-manager-button",
        className: "fa fa-files-o gjs-page-manager-button",
        attributes: {
          title: "Page manager"
        },
        command: editor => {
          try {
            // Set title
            this.modal.setTitle("Page Manager");
            // Open modal
            this.modal.open();
            // Render modal content
            this.render();
          } catch (error) {
            console.error(error);
          }
        }
      },
      {
        id: "save-db",
        className: "fa fa-floppy-o",
        command: "save-db",
        attributes: { title: "Save" }
      }
    ]);

    // Add save command
    editor.Commands.add("save-db", {
      run: (editor, sender) => {
        sender && sender.set("active");
        editor.store();
      }
    });
    editor.on("storage:load", () => {
      if (editor.Commands.has("save-db")) return;
      editor.Commands.add("save-db", {
        run: (editor, sender) => {
          sender && sender.set("active");
          editor.store();
        }
      });
    });
    editor.on("storage:start:store", () => {
      if (!editor.Commands.has("save-db")) return;
      editor.Commands.stop("save-db");
    });
    editor.on("storage:start:load", () => {
      if (!editor.Commands.has("save-db")) return;
      editor.Commands.stop("save-db");
    });
  };

  setPreloader = ({ state = false } = {}) => {
    // Get modal element
    const modalContentEl = this.modal.getContentEl();
    const modalEl =
      modalContentEl && modalContentEl.closest(".gjs-mdl-content");
    if (!modalEl) return;
    // Add or remove preloader
    state
      ? modalEl.classList.add("gjs-mdl-content--preloader")
      : modalEl.classList.remove("gjs-mdl-content--preloader");
  };

  setPages = ({ pages }) => {
    this.state = {
      ...this.state,
      pages
    };
  };

  setPagination = ({ nextUrl, prevUrl, total }) => {
    const nextParams = new URLSearchParams(
      nextUrl && nextUrl.slice(nextUrl.indexOf("?"))
    );
    const prevParams = new URLSearchParams(
      prevUrl && prevUrl.slice(prevUrl.indexOf("?"))
    );

    const next = parseInt(nextUrl ? nextParams.get("page") || 1 : 0);
    const prev = parseInt(prevUrl ? prevParams.get("page") || 1 : 0);

    this.state = {
      ...this.state,
      pagination: {
        ...this.state.pagination,
        total: Math.ceil(total / 10),
        next: parseInt(next) || this.state.pagination.current,
        prev: parseInt(prev) || this.state.pagination.current
      }
    };
  };

  setPage = ({ page }) => {
    this.state = {
      ...this.state,
      pagination: {
        ...this.state.pagination,
        current: page
      }
    };

    this.render();
  };

  onFormChange = ({ name }) => e => {
    this.state = {
      ...this.state,
      form: {
        ...this.state.form,
        [name]: e.target.value
      }
    };
  };

  onFormSubmit = async () => {
    const { state, api } = this;
    state.form.hasOwnProperty("id")
      ? await api.updatePage({ ...state.form, siteId: state.site.id })
      : await api.createPage({ ...state.form, siteId: state.site.id });

    this.state = { ...state, form: {} };

    this.render();
  };

  editPageHandler = e => {
    // Add prelodaer
    this.setPreloader({ state: true });
    const target =
      e.target.parentNode.nodeName === "BUTTON"
        ? e.target.parentNode
        : e.target;
    // Set page data to form
    this.state = {
      ...this.state,
      form: {
        id: target.dataset.id,
        title: target.dataset.title,
        path: target.dataset.path
      }
    };

    this.render();
  };

  copyPageHandler = async e => {
    // Add prelodaer
    this.setPreloader({ state: true });
    const target =
      e.target.parentNode.nodeName === "BUTTON"
        ? e.target.parentNode
        : e.target;
    // Set page data to form
    const {
      title,
      path,
      gjs_assets,
      gjs_styles,
      gjs_components,
      gjs_css,
      gjs_html
    } = await this.api.getPage({
      siteId: this.state.site.id,
      pageId: target.dataset.id
    });

    const randCode = Math.random()
      .toString()
      .slice(-4);

    await this.api.createPage({
      title: `${title} Copy#rand${randCode}`,
      path: `${path}#rand${randCode}`,
      siteId: this.state.site.id,
      props: {
        gjs_assets,
        gjs_styles,
        gjs_components,
        gjs_css,
        gjs_html
      }
    });

    this.render();
  };

  deletePageHandler = async e => {
    // Add prelodaer
    this.setPreloader({ state: true });
    const target =
      e.target.parentNode.nodeName === "BUTTON"
        ? e.target.parentNode
        : e.target;
    await this.api.deletePage({
      id: target.dataset.id,
      siteId: this.state.site.id
    });

    this.setPage({ page: 1 });
  };

  async render() {
    // Set prelodaer
    this.setPreloader({ state: true });
    // Get site pages
    const {
      results: pages,
      count: total,
      next: nextUrl,
      previous: prevUrl
    } = await this.api.getPages({
      siteId: this.state.site.id,
      page: this.state.pagination.current
    });
    // Set pages to state
    this.setPages({ pages });
    // Set pagination
    this.setPagination({ nextUrl, prevUrl, total });
    // Set modal content
    await this.modal.setContent(`
        <div class="gjs-page-manager-modal__content">
          <div class="gjs-page-manager-modal__general">
            <div id="gjs-sm-background" class="gjs-page-manager-modal__group gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
                <div class="gjs-sm-label">
                    <span class="gjs-sm-icon " title="">
                        Title
                    </span>
                    <b class="gjs-sm-clear" data-clear-style="" style="display: none;">⨯</b>
                </div>
                <div class="gjs-fields">
                    <div class="gjs-field gjs-page-manager-modal__field">
                        <div class="gjs-input-holder">
                            <input type="text" id="page_title" placeholder="PageTitle" class="gjs-page-manager-modal__input" value="${
                              this.state.form.id ? this.state.form.title : ""
                            }">
                        </div>
                    </div>
                </div>
            </div>
            <div id="gjs-sm-background" class="gjs-page-manager-modal__group gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
                <div class="gjs-sm-label">
                    <span class="gjs-sm-icon " title="">
                        Path
                    </span>
                    <b class="gjs-sm-clear" data-clear-style="" style="display: none;">⨯</b>
                </div>
                <div class="gjs-fields">
                    <div class="gjs-field gjs-page-manager-modal__field">
                        <div class="gjs-input-holder">
                            <input type="text" id="page_path" placeholder="/page-path" class="gjs-page-manager-modal__input" value="${
                              this.state.form.id ? this.state.form.path : ""
                            }">
                        </div>
                    </div>
                </div>
            </div>
            <div id="gjs-sm-background" class="gjs-page-manager-modal__group gjs-page-manager-modal__group--button gjs-sm-property gjs-sm-stack gjs-sm-property__background" style="display: block;">
                <button id="save_page_button" type="button" class="gjs-page-manager-modal__button gjs-two-color" data-id="">
                    Save page
                </button>
            </div>
          </div>
          <div>
          <div class="gjs-mdl-title">
            List of pages ${this.state.pagination.current}/${
      this.state.pagination.total
    }
          </div>
            <div class="gjs-page-manager-list">
            ${this.state.pages
              .map(
                ({ id, title, path }) => `
                <div class="gjs-page-manager-list__item">
                    <h5 class="gjs-page-manager-list__item-text" data-id="${id}" data-title="${title}" data-path="${path}">
                      ${title}
                      <span class="gjs-page-manager-list__item-capture">
                      [id:${id}]
                      </span>
                    </h5>
                    <h5 class="gjs-page-manager-list__item-text" data-id="${id}" data-title="${title}" data-path="${path}">
                      ${path}
                    </h5>
                    <div class="gls-page-manager-list__item-actions">
                      <button type="button" id="copy_page_button" class="gjs-page-manager-modal__button gjs-page-manager-modal__button-sm gjs-two-color" data-id="${id}" data-title="${title}" data-path="${path}">
                        <i class="fa fa-copy"></i>
                      </button>
                      <button type="button" id="edit_page_button" class="gjs-page-manager-modal__button gjs-page-manager-modal__button-sm gjs-two-color" data-id="${id}" data-title="${title}" data-path="${path}">
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button type="button" id="delete_page_button" class="gjs-page-manager-modal__button gjs-page-manager-modal__button-sm gjs-two-color" data-id="${id}" data-title="${title}" data-path="${path}">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                </div>
                `
              )
              .join("")}
            </div>
          </div>
        </div>
        <div class="gjs-page-manager-modal__footer">
          <div class="gjs-page-manager-modal__pagination">
            <button
              type="button"
              id="pagination_prev_page_button"
              class="gjs-page-manager-modal__button gjs-page-manager-modal__button-sm gjs-two-color"
              ${
                this.state.pagination.current === this.state.pagination.prev
                  ? "disabled"
                  : ""
              }
            >
              <i class="fa fa-angle-left"></i>
            </button>
            <button
              type="button"
              id="pagination_next_page_button"
              class="gjs-page-manager-modal__button gjs-page-manager-modal__button-sm gjs-two-color"
              ${
                this.state.pagination.current === this.state.pagination.next
                  ? "disabled"
                  : ""
              }
            >
              <i class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        `);

    // Form elements
    const pageTitleEl = document.getElementById("page_title");
    pageTitleEl.addEventListener(
      "change",
      this.onFormChange({ name: "title" })
    );

    const pagePathEl = document.getElementById("page_path");
    pagePathEl.addEventListener("change", this.onFormChange({ name: "path" }));

    const savePageButtonEl = document.getElementById("save_page_button");
    savePageButtonEl.addEventListener("click", this.onFormSubmit);

    // Copy buttons handler
    const editPageButtonEls = document.querySelectorAll("#edit_page_button");
    [...editPageButtonEls].map(editPageButtonEl =>
      editPageButtonEl.addEventListener("click", this.editPageHandler)
    );
    // Edit buttons handler
    const copyPageButtonEls = document.querySelectorAll("#copy_page_button");
    [...copyPageButtonEls].map(copyPageButtonEl =>
      copyPageButtonEl.addEventListener("click", this.copyPageHandler)
    );
    // Delete buttons handler
    const deletePageButtonEls = document.querySelectorAll(
      "#delete_page_button"
    );
    [...deletePageButtonEls].map(deletePageButtonEl =>
      deletePageButtonEl.addEventListener("click", this.deletePageHandler)
    );

    // Page buttons
    const pageButtonEls = document.querySelectorAll(
      ".gjs-page-manager-list__item-text"
    );
    pageButtonEls &&
      [...pageButtonEls].map(pageButtonEl =>
        pageButtonEl.addEventListener("click", async e => {
          if (e.target.dataset.path === "/") {
            window.location.assign(`/newbuilder/site/${this.state.site.id}/`);
          } else {
            window.location.assign(
              `/newbuilder/site/${this.state.site.id}/page/${e.target.dataset.id}`
            );
          }
        })
      );

    // Pagination buttons
    const nextBtnEl = document.querySelector(
      "#pagination_next_page_button:not(disabled)"
    );
    const prevBtnEl = document.querySelector(
      "#pagination_prev_page_button:not(disabled)"
    );

    nextBtnEl.addEventListener("click", () =>
      this.setPage({ page: this.state.pagination.next })
    );
    prevBtnEl.addEventListener("click", () =>
      this.setPage({ page: this.state.pagination.prev })
    );

    // Remove prelodaer
    this.setPreloader({ state: false });
  }
}
