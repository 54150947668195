import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EventTableBody from "../event-table-body/event-table-body.component";

import "./event-visitor.styles.scss";

const useStyles = makeStyles({
  noBorder: {
    border: "none"
  }
});

const EventVisitor = ({
  eventById,

  colName1,
  colName2,
  colName3,
  colName4,
  colName5,
  row2,
  row3,
  visitor,
  data,
  avatar,
  link1,
  link2,
  link3
}) => {
  const classes = useStyles();
  return (
    <div className="event-visitor">
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.noBorder} align="left">
              {colName1}
            </TableCell>
            <TableCell className={classes.noBorder} align="left">
              {colName2}
            </TableCell>
            <TableCell className={classes.noBorder} align="left">
              {colName3}
            </TableCell>
            <TableCell className={classes.noBorder} align="left">
              {colName4}
            </TableCell>
            <TableCell className={classes.noBorder} align="left">
              {colName5}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitor ? (
            <EventTableBody
              avatar={avatar}
              visitor={visitor}
              key={data._id}
              data={data}
              row2={row2}
              row3={row3}
              link1={link1}
              link2={link2}
              link3={link3}
            />
          ) : (
            eventById.companies &&
            eventById.companies.map(
              ({
                id,
                logo,
                director,
                name,
                description,
                metadata,
                address
              }) => (
                <EventTableBody
                  key={id}
                  id={id}
                  avatar={logo}
                  visitor={visitor}
                  data={data}
                  row2={director}
                  row3={name}
                  link1={link1}
                  link2={link2}
                  link3={link3}
                  description={description}
                  metadata={metadata}
                  address={address}
                />
              )
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = state => ({
  eventById: state.events.eventById
});

export default connect(mapStateToProps, null)(EventVisitor);
