import { DocumentsActionTypes } from "./documents.types";

const INITIAL_STATE = {
  isAdditionalPageUploading: false,
  additionalPageUpload: {},
  additionalPageUploadError: {},

  isAdditionalPageDeleting: false,
  additionalPageDeleted: {},
  additionalPageDeletedError: {},

  isUploadingDocument: false,
  documentUploadSuccess: {},
  documentUploadError: {},

  isDeletingDocument: false,
  deletedDocument: {},
  deletedDocumentError: {},

  isUploadingAdditionalParameter: false,
  uploadAdditionalParameter: {},
  uploadAdditionalParameterError: {},

  isAdditionalParameterDeletePending: false,
  additionalParameter: {},
  additionalParameterError: {},

  isSizeDeletePending: false,
  deleteSize: {},
  deleteSizeError: {},

  galleryBlob: "",
  galleryFile: "",

  videoBlob: "",
  videoFile: "",

  isImageGalleryUploadPending: false,
  imageGalleryUpload: {},
  imageGalleryError: {},

  isImageFromGalleryDeleting: false,
  imageFromGalleryDeleted: {},
  imageFromGalleryDeletedError: {},

  isVideoGalleryUploadPending: false,
  videoGalleryUpload: {},
  videoGalleryError: {},

  isVideoFromGalleryDeleting: false,
  videoFromGalleryDeleted: {},
  videoFromGalleryDeletedError: {}
};

const DocumentsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case DocumentsActionTypes.POST.UPLOAD_DOCUMENT_PENDING:
      return {
        ...state,
        isUploadingDocument: true
      };

    case DocumentsActionTypes.POST.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isUploadingDocument: false,
        documentUploadSuccess: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        isUploadingDocument: false,
        documentUploadError: action.payload
      };

    case DocumentsActionTypes.DELETE.DELETE_DOCUMENT_PENDING:
      return {
        ...state,
        isDeletingDocument: true
      };

    case DocumentsActionTypes.DELETE.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDeletingDocument: false,
        deletedDocument: action.payload
      };

    case DocumentsActionTypes.DELETE.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        isDeletingDocument: false,
        deletedDocumentError: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_PRODUCT_IMAGE_GALLERY_PENDING:
      return {
        ...state,
        isImageGalleryUploadPending: true
      };

    case DocumentsActionTypes.POST.UPLOAD_PRODUCT_IMAGE_GALLERY_SUCCESS:
      return {
        ...state,
        isImageGalleryUploadPending: false,
        imageGalleryUpload: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_PRODUCT_IMAGE_GALLERY_ERROR:
      return {
        ...state,
        isImageGalleryUploadPending: false,
        imageGalleryError: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_PRODUCT_VIDEO_GALLERY_PENDING:
      return {
        ...state,
        isVideoGalleryUploadPending: true
      };

    case DocumentsActionTypes.POST.UPLOAD_PRODUCT_VIDEO_GALLERY_SUCCESS:
      return {
        ...state,
        isVideoGalleryUploadPending: false,
        videoGalleryUpload: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_PRODUCT_VIDEO_GALLERY_ERROR:
      return {
        ...state,
        isVideoGalleryUploadPending: false,
        videoGalleryError: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PARAMETER_PENDING:
      return {
        ...state,
        isUploadingAdditionalParameter: true
      };

    case DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PARAMETER_SUCCESS:
      return {
        ...state,
        isUploadingAdditionalParameter: false,
        uploadAdditionalParameter: action.payload
      };

    case DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PARAMETER_ERROR:
      return {
        ...state,
        isUploadingAdditionalParameter: false,
        uploadAdditionalParameterError: action.payload
      };

    case DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PARAMETER_PENDING:
      return {
        ...state,
        isAdditionalParameterDeletePending: true
      };
    case DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PARAMETER_SUCCESS:
      return {
        ...state,
        additionalParameter: action.payload,
        isAdditionalParameterDeletePending: true
      };
    case DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PARAMETER_ERROR:
      return {
        ...state,
        additionalParameterError: action.payload,
        isAdditionalParameterDeletePending: true
      };

    case DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PAGE_PENDING:
      return {
        ...state,
        isAdditionalPageUploading: true
      };

    case DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PAGE_SUCCESS:
      return {
        ...state,
        additionalPageUpload: action.payload,
        isAdditionalPageUploading: false
      };

    case DocumentsActionTypes.POST.UPLOAD_ADDITIONAL_PAGE_ERROR:
      return {
        ...state,
        additionalPageUploadError: action.payload,
        isAdditionalPageUploading: false
      };

    case DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PAGE_BY_ID_PENDING:
      return {
        ...state,
        isAdditionalPageDeleting: true
      };
    case DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PAGE_BY_ID_SUCCESS:
      return {
        ...state,
        additionalPageDeleted: action.payload,
        isAdditionalPageDeleting: true
      };
    case DocumentsActionTypes.DELETE.DELETE_ADDITIONAL_PAGE_BY_ID_ERROR:
      return {
        ...state,
        additionalPageUploadError: action.payload,
        isAdditionalPageDeleting: true
      };

    case DocumentsActionTypes.DELETE.DELETE_SIZE_BY_ID_PENDING:
      return {
        ...state,
        isSizeDeletePending: true
      };
    case DocumentsActionTypes.DELETE.DELETE_SIZE_BY_ID_SUCCESS:
      return {
        ...state,
        deleteSize: action.payload,
        isSizeDeletePending: true
      };
    case DocumentsActionTypes.DELETE.DELETE_SIZE_BY_ID_ERROR:
      return {
        ...state,
        deleteSizeError: action.payload,
        isSizeDeletePending: true
      };

    case DocumentsActionTypes.LOAD_BLOB_ON_CHANGE:
      return {
        ...state,
        galleryBlob: action.payload
      };

    case DocumentsActionTypes.LOAD_VIDEO_FILE_ON_CHANGE:
      return {
        ...state,
        videoFile: action.payload
      };

    case DocumentsActionTypes.LOAD_VIDEO_BLOB_ON_CHANGE:
      return {
        ...state,
        videoBlob: action.payload
      };

    case DocumentsActionTypes.LOAD_FILE_ON_CHANGE:
      return {
        ...state,
        galleryFile: action.payload
      };

    case DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_PENDING:
      return {
        ...state,
        isImageFromGalleryDeleting: true
      };

    case DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_SUCCESS:
      return {
        ...state,
        imageFromGalleryDeleted: action.payload,
        isImageFromGalleryDeleting: false
      };

    case DocumentsActionTypes.DELETE.DELETE_ONE_IMAGE_FROM_GALLERY_ERROR:
      return {
        ...state,
        imageFromGalleryDeletedError: action.payload,
        isImageFromGalleryDeleting: false
      };

    case DocumentsActionTypes.DELETE.DELETE_ONE_VIDEO_FROM_GALLERY_PENDING:
      return {
        ...state,
        isVideoFromGalleryDeleting: true
      };

    case DocumentsActionTypes.DELETE.DELETE_ONE_VIDEO_FROM_GALLERY_SUCCESS:
      return {
        ...state,
        videoFromGalleryDeleted: action.payload,
        isVideoFromGalleryDeleting: false
      };

    case DocumentsActionTypes.DELETE.DELETE_ONE_VIDEO_FROM_GALLERY_ERROR:
      return {
        ...state,
        videoFromGalleryDeletedError: action.payload,
        isVideoFromGalleryDeleting: false
      };

    default:
      return state;
  }
};

export default DocumentsReducer;
