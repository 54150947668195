import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";

import { withDebounce } from "../../../shared";
import {
  EventCard,
  SearchBar,
  Pagination,
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import {
  getJobsCreatedByMe,
  searchJobsCreatedByMe,
  deleteVacancy
} from "../../../redux/actions-exporter";

import "./my-business-vacancy.styles.scss";

const MyBusinessVacancy = ({
  getJobsCreatedByMe,
  areJobCreatedByIdPending,
  jobsCreatedByMe,
  jobsCreatedByMeCount,
  deleteVacancy
}) => {
  const [searchJobsInput, setSearchJobsInput] = useState("");
  const [jobsPage, setJobsPage] = useState(1);
  const [currentVacancyId, setCurrentVacancyId] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getJobsCreatedByMe(searchJobsInput, jobsPage);
  }, [getJobsCreatedByMe, searchJobsInput, jobsPage]);

  useEffect(() => {
    setJobsPage(1);
  }, [searchJobsInput]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [jobsPage]);

  const searchJobsHandler = withDebounce(e => setSearchJobsInput(e));

  return (
    <div className="my-business-vacancy">
      <ConfirmDialog
        dialogTitle="Event remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteVacancy(currentVacancyId);
          setDialogOpen(false);
          setCurrentVacancyId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => searchJobsHandler(e)} />
      </div>
      {areJobCreatedByIdPending ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          {jobsCreatedByMe && jobsCreatedByMe.length ? (
            jobsCreatedByMe.map(
              ({ id, title, description, country_ext, created_at }) => (
                <EventCard
                  key={id}
                  country={country_ext && country_ext.name}
                  title={title}
                  description={description && parse(parse(description))}
                  editorState={true}
                  publicated={true}
                  publicatedDate={moment(created_at).format("DD MMMM YYYY")}
                  viewLiveUrl={`/job/find-jobs/${id}`}
                  editLink={`/my-business/vacancy/${id}`}
                  removeFunc={() => {
                    setDialogOpen(true);
                    setCurrentVacancyId(id);
                  }}
                />
              )
            )
          ) : (
            <MyBusinessNoData noDataText="No vacancies found!" />
          )}
          <Pagination
            forcePage={jobsPage - 1}
            dataCount={jobsCreatedByMeCount}
            itemsPerPage={10}
            selectedPage={page => setJobsPage(page)}
          />
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areJobCreatedByIdPending: state.jobsAPI.areJobCreatedByIdPending,
  jobsCreatedByMe: state.jobsAPI.jobsCreatedByMe,
  jobsCreatedByMeCount: state.jobsAPI.jobsCreatedByMeCount
});

export default connect(mapStateToProps, {
  deleteVacancy,
  getJobsCreatedByMe,
  searchJobsCreatedByMe
})(MyBusinessVacancy);
