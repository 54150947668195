import React from "react";
import { Link } from "react-router-dom";

import { missingImage } from "../../assets";

import { colorPalette } from "../../styles/colorPalette";
import "./organization-card.styles.scss";

const OrganizationCard = ({
  logo,
  title,
  shortDescription,
  isMember,
  memberOfOrganization
}) => {
  return (
    <div className="organization-card">
      <div className="logo-container">
        <img src={logo ? logo : missingImage} alt="" />
      </div>
      <div className="organization-card__separator" />
      <div className="info">
        <div className="title">{title}</div>
        <div className="description">
          <div>{shortDescription}</div>
          {isMember && (
            <p>
              MEMBER OF:{" "}
              <Link
                style={{ color: colorPalette.colorPrimary.main }}
                to={`/organizations/${memberOfOrganization.id}`}
              >
                {memberOfOrganization.name}
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationCard;
