import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from "html-react-parser";

import {
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Reddit as RedditIcon
} from "@material-ui/icons";

import {
  LogoWhite,
  LogoBlack,
  PhoneIcon,
  GlobeIcon,
  PinIcon,
  MailIcon,
  missingImage
} from "../../assets";

import { LoadingSpinner } from "../../components";
import { downloadBusinessCard } from "../../shared";

import { getProfileById, getCompany } from "../../redux/actions-exporter";

import "./business-card.styles.scss";

const BusinessCardUserInfo = ({
  imgSrc,
  name,
  surname,
  profession,
  cardType
}) => {
  return (
    <div
      className={`business-card-user-info business-card-user-info--${cardType}`}
    >
      <div
        className={`business-card-user-info__avatar-box business-card-user-info__avatar-box--${cardType}`}
      >
        <img
          src={imgSrc ? imgSrc : missingImage}
          alt="User avatar"
          className={`business-card-user-info__avatar business-card-user-info__avatar--${cardType}`}
        />
      </div>

      <div>
        <div className="business-card-user-info__name">
          {name} {surname}
        </div>
        <div className="business-card-user-info__profession">{profession}</div>
      </div>
    </div>
  );
};

const BusinessCardCompanyInfo = ({ imgSrc, name, industry, cardType }) => {
  return (
    <div
      className={`business-card-company-info business-card-company-info--${cardType}`}
    >
      <div className="business-card-company-info__logo-box">
        <img
          src={imgSrc ? imgSrc : missingImage}
          alt="Company logo"
          className={`business-card-company-info__logo business-card-company-info__logo--${cardType}`}
        />
      </div>

      <div>
        <div
          className={`business-card-company-info__name business-card-company-info__name--${cardType}`}
        >
          {name}
        </div>
        <div className="business-card-company-info__industry">{industry}</div>
      </div>
    </div>
  );
};

const BusinessCardCompanyDescription = ({ companyDescription, cardType }) => {
  return (
    <div
      className={`business-card-company-description business-card-company-description--${cardType}`}
    >
      <div className="business-card-company-description__title">
        Company description
      </div>
      <div className="business-card-company-description__description">
        {companyDescription}
      </div>
    </div>
  );
};

const BusinessCardContactElement = ({ icon, title, content, cardType }) => {
  return (
    <div
      className={`business-card-contact-element business-card-contact-element--${cardType}`}
    >
      <div
        className={`business-card-contact-element__upper-container business-card-contact-element__upper-container--${cardType}`}
      >
        <div
          className={`business-card-contact-element__icon-box business-card-contact-element__icon-color--${cardType} business-card-contact-element__icon-border-color--${cardType}`}
        >
          {icon}
        </div>
        {cardType === 1 && (
          <div className="business-card-contact-element__upper-title">
            {title}
          </div>
        )}
      </div>
      <div
        className={`business-card-contact-element__container business-card-contact-element__container--${cardType}`}
      >
        {cardType !== 1 && (
          <div
            className={`business-card-contact-element__title business-card-contact-element__title--${cardType}`}
          >
            {title}
          </div>
        )}
        <div
          className={`business-card-contact-element__content business-card-contact-element__content--${cardType}`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

const BusinessCardSocialLinks = ({
  cardType,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit
}) => {
  return (
    <div className="business-card-social-links">
      {facebook && (
        <a
          href={facebook}
          className={`business-card-social-links__icon-box business-card-social-links__icon-box--${cardType}`}
        >
          <FacebookIcon />
        </a>
      )}

      {linkedin && (
        <a
          href={linkedin}
          className={`business-card-social-links__icon-box business-card-social-links__icon-box--${cardType}`}
        >
          <LinkedInIcon />
        </a>
      )}

      {twitter && (
        <a
          href={twitter}
          className={`business-card-social-links__icon-box business-card-social-links__icon-box--${cardType}`}
        >
          <TwitterIcon />
        </a>
      )}

      {instagram && (
        <a
          href={instagram}
          className={`business-card-social-links__icon-box business-card-social-links__icon-box--${cardType}`}
        >
          <InstagramIcon />
        </a>
      )}

      {youtube && (
        <a
          href={youtube}
          className={`business-card-social-links__icon-box business-card-social-links__icon-box--${cardType}`}
        >
          <YouTubeIcon />
        </a>
      )}

      {reddit && (
        <a
          href={reddit}
          className={`business-card-social-links__icon-box business-card-social-links__icon-box--${cardType}`}
        >
          <RedditIcon />
        </a>
      )}
    </div>
  );
};

const BusinessCardButton = ({ cardType, icon, text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`business-card-button business-card-button--${cardType}`}
    >
      <div className="business-card-button__icon">{icon}</div>
      <div className="business-card-button__text">{text}</div>
    </div>
  );
};

const BusinessCard = ({
  getProfileById,
  isProfilePending,
  profile,
  getCompany,
  company,

  lastName,
  firstName,
  profession,
  phoneNumber,
  profileMy
}) => {
  const history = useHistory();
  const { userId } = useParams();

  useEffect(() => {
    getProfileById(userId || profileMy.user);
  }, [getProfileById, userId]); // eslint-disable-line

  useEffect(() => {
    if (profile.metadata && profile.metadata.active_company) {
      getCompany(profile.metadata.active_company);
    }
  }, [getCompany, profile]);

  return isProfilePending ? (
    <div className="business-card__loading-spinner">
      <LoadingSpinner />
    </div>
  ) : (
    <div
      className={`business-card business-card--${profile.metadata &&
        profile.metadata.business_card}`}
    >
      <div
        className={`business-card__header business-card__header--${profile.metadata &&
          profile.metadata.business_card}`}
      >
        <img
          onClick={() => history.push("/profile")}
          src={
            profile.metadata && profile.metadata.business_card !== 1
              ? LogoWhite
              : LogoBlack
          }
          alt="B24Online logo"
          className={
            (profile.metadata && profile.metadata.business_card === 1) ||
            (profile.metadata && profile.metadata.business_card === 2)
              ? "business-card__logo business-card__logo--1"
              : "business-card__logo business-card__logo--2"
          }
        />
      </div>
      <div className="business-card__body ">
        <BusinessCardUserInfo
          imgSrc={profile.avatar}
          name={profile.first_name}
          surname={profile.last_name}
          profession={profile.profession}
          cardType={profile.metadata && profile.metadata.business_card}
        />

        {profile.metadata && profile.metadata.business_card === 1 && (
          <>
            <div className="business-card__separator" />
            <div className="business-card--card-1">
              <div>
                <PhoneIcon />
              </div>
              <div>{profile.phoneNumber}</div>
              <div className="business-card__vertical-separator" />
              <div>
                <MailIcon />
              </div>
              <div>Contact us</div>
            </div>
          </>
        )}

        <div className="business-card__separator" />

        <BusinessCardCompanyInfo
          imgSrc={company.logo}
          name={company.name}
          industry={company.branches && company.branches[0].name}
          cardType={profile.metadata && profile.metadata.business_card}
        />
        {profile.metadata && profile.metadata.business_card !== 1 && (
          <>
            <div className="business-card__separator" />

            <BusinessCardCompanyDescription
              companyDescription={
                company.short_description && parse(company.short_description)
              }
              cardType={profile.metadata && profile.metadata.business_card}
            />
          </>
        )}

        <div
          className={`business-card__contacts business-card__contacts--${profile.metadata &&
            profile.metadata.business_card}`}
        >
          <div
            className={`business-card__contact-container business-card__contact-container--${profile.metadata &&
              profile.metadata.business_card}`}
          >
            {profile.metadata && profile.metadata.business_card !== 1 && (
              <div className="business-card__contact">
                <BusinessCardContactElement
                  icon={<PhoneIcon />}
                  title="Phone"
                  content={phoneNumber}
                  cardType={profile.metadata && profile.metadata.business_card}
                />
              </div>
            )}

            <div className="business-card__contact">
              <BusinessCardContactElement
                icon={<PhoneIcon />}
                title="Company phone"
                content={company.metadata && company.metadata.phone}
                cardType={profile.metadata && profile.metadata.business_card}
              />
            </div>
          </div>
          <div
            className={`business-card__contact-container business-card__contact-container--${profile.metadata &&
              profile.metadata.business_card}`}
          >
            <div className="business-card__contact">
              <BusinessCardContactElement
                icon={<PhoneIcon />}
                title="Company fax"
                content={company.metadata && company.metadata.fax}
                cardType={profile.metadata && profile.metadata.business_card}
              />
            </div>

            <div className="business-card__contact">
              <BusinessCardContactElement
                icon={<GlobeIcon />}
                title="Company website"
                content={company.metadata && company.metadata.site}
                cardType={profile.metadata && profile.metadata.business_card}
              />
            </div>
          </div>
          <div className="business-card__contact">
            <BusinessCardContactElement
              icon={<PinIcon />}
              title="Company address"
              content={company.address}
              cardType={profile.metadata && profile.metadata.business_card}
            />
          </div>
        </div>
        {profile.metadata &&
          (profile.metadata.reddit ||
            profile.metadata.twitter ||
            profile.metadata.youtube ||
            profile.metadata.facebook ||
            profile.metadata.linkedin ||
            profile.metadata.instagram) && (
            <>
              <div className="business-card__separator" />

              <BusinessCardSocialLinks
                reddit={profile.metadata && profile.metadata.reddit}
                twitter={profile.metadata && profile.metadata.twitter}
                youtube={profile.metadata && profile.metadata.youtube}
                facebook={profile.metadata && profile.metadata.facebook}
                linkedin={profile.metadata && profile.metadata.linkedin}
                instagram={profile.metadata && profile.metadata.instagram}
                cardType={profile.metadata && profile.metadata.business_card}
              />
            </>
          )}

        <div className="business-card__separator" />
        {profile.metadata && profile.metadata.business_card !== 1 && (
          <>
            <div
              className={`business-card__button-group business-card__button-group--${profile.metadata &&
                profile.metadata.business_card}`}
            >
              <BusinessCardButton
                cardType={profile.metadata && profile.metadata.business_card}
                icon={<PhoneIcon />}
                text="Call"
              />
              <div className="business-card__vertical-separator" />
              <BusinessCardButton
                cardType={profile.metadata && profile.metadata.business_card}
                icon={<MailIcon />}
                text="Contact us"
              />
            </div>
            <div className="business-card__separator" />
          </>
        )}

        <BusinessCardButton
          onClick={() => downloadBusinessCard(profile, company)}
          cardType={1}
          text="Save Card"
        />
        <div className="business-card__legal">
          &copy; 2010-2020 B24Online.com
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isProfilePending: state.profileAPI.isProfilePending,
  profile: state.profileAPI.profileById,
  profileMy: state.profileAPI.profile,

  company: state.companiesReducer.company,

  lastName: state.profile.lastName,
  firstName: state.profile.firstName,
  profession: state.profile.profession,
  phoneNumber: state.profile.phoneNumber
});

export default connect(mapStateToProps, {
  getProfileById,
  getCompany
})(BusinessCard);
