import { NewsActionTypes } from "./news.types";

const INITIAL_STATE = {
  areNewsByCompanyIdPending: false,
  newsByCompanyId: [],
  newsByCompanyIdError: null,

  isNewsPending: false,
  news: [],
  newsError: null,

  areNewsByIdPending: false,
  newsById: [],
  newsByIdError: null,

  createdNews: {},
  updatedNews: {},
  deletedNews: {},

  areNewsByUserIdPending: false,
  newsByUserId: [],
  newsByUserIdCount: 0,
  newsByUserIdError: null,

  isNewsImagePatchPending: false,
  newsImagePatch: {},
  newsImagePatchError: null,

  areNewsCategoriesPending: false,
  newsCategories: [],
  newsCategoriesError: {},

  newsRequestStatus: ""
};

const NewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewsActionTypes.COMPANY.GET_NEWS_BY_COMPANY_ID_PENDING:
      return {
        ...state,
        areNewsByCompanyIdPending: true
      };

    case NewsActionTypes.COMPANY.GET_NEWS_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        areNewsByCompanyIdPending: false,
        newsByCompanyId: action.payload,
        newsByCompanyIdError: null
      };

    case NewsActionTypes.COMPANY.GET_NEWS_BY_COMPANY_ID_ERROR:
      return {
        ...state,
        areNewsByCompanyIdPending: false,
        newsByCompanyIdError: action.payload
      };

    case NewsActionTypes.GET_NEWS_PENDING:
      return {
        ...state,
        isNewsPending: true
      };

    case NewsActionTypes.GET_NEWS:
      return {
        ...state,
        news: action.payload,
        isNewsPending: false
      };

    case NewsActionTypes.GET_NEWS_ERROR:
      return {
        ...state,
        newsError: action.payload,
        isNewsPending: false
      };

    case NewsActionTypes.GET_NEWS_BY_ID_PENDING:
      return {
        ...state,
        areNewsByIdPending: true
      };

    case NewsActionTypes.GET_NEWS_BY_ID:
      return {
        ...state,
        areNewsByIdPending: false,
        newsById: action.payload,
        newsByIdError: null
      };

    case NewsActionTypes.GET_NEWS_BY_ID_ERROR:
      return {
        ...state,
        areNewsByIdPending: false,
        newsByIdError: action.payload
      };

    case NewsActionTypes.CREATE_NEWS:
      return {
        ...state,
        newsRequestStatus: action.payload.requestStatus,
        createdNews: action.payload
      };

    case NewsActionTypes.UPDATE_NEWS:
      return {
        ...state,
        newsRequestStatus: action.payload.requestStatus,
        updatedNews: action.payload
      };

    case NewsActionTypes.DELETE_NEWS:
      return {
        ...state,
        deletedNews: action.payload
      };

    case NewsActionTypes.GET_NEWS_CATEGORIES:
      return {
        ...state,
        newsCategories: action.payload
      };

    case NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_PENDING:
      return { ...state, areNewsByUserIdPending: true };

    case NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        newsByUserId: action.payload.news,
        newsByUserIdCount: action.payload.count,
        areNewsByUserIdPending: false
      };

    case NewsActionTypes.GET_BY_USER.GET_NEWS_BY_USER_ID_ERROR:
      return {
        ...state,
        newsByUserIdError: action.payload,
        areNewsByUserIdPending: false
      };

    case NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_PENDING:
      return { ...state, isNewsImagePatchPending: true };

    case NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_SUCCESS:
      return {
        ...state,
        isNewsImagePatchPending: false,
        newsImagePatch: action.payload,
        newsImagePatchError: null
      };

    case NewsActionTypes.PATCH.IMAGE.PATCH_NEWS_IMAGE_ERROR:
      return {
        ...state,
        isNewsImagePatchPending: false,
        newsImagePatchError: action.payload
      };

    case NewsActionTypes.GET.GET_NEWS_CATEGORIES_PENDING:
      return {
        ...state,
        areNewsCategoriesPending: true
      };

    case NewsActionTypes.GET.GET_NEWS_CATEGORIES_SUCCESS:
      return {
        ...state,
        areNewsCategoriesPending: false,
        newsCategories: action.payload
      };

    case NewsActionTypes.GET.GET_NEWS_CATEGORIES_ERROR:
      return {
        ...state,
        areNewsCategoriesPending: false,
        newsCategoriesError: action.payload
      };

    case NewsActionTypes.CLEAR_NEWS_REQUEST:
      return {
        ...state,
        newsRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default NewsReducer;
