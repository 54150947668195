import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";

import { Card } from "@material-ui/core";
import { EventView1, EventView2, missingImage } from "../../assets";

import { Filter } from "../../containers";

import {
  PopularNews,
  EventCard,
  Pagination,
  DefaultDropdown,
  NoDataFound
} from "../../components";

import {
  getFilteredNews,
  getNews,
  getNewsCategories,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
} from "../../redux/actions-exporter";

import { withDebounce, arrToString } from "../../shared";

import "./news.styles.scss";

const News = ({
  appDirection,
  isNewsPending,
  news,
  getNews,
  getNewsCategories,
  newsCategories,
  setCountriesFilterStatus,
  filterCountriesArr,
  setOrganizationsFilterStatus,
  filterOrganizationsArr
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("");
  const [newsPage, setNewsPage] = useState(1);

  useEffect(() => {
    setCountriesFilterStatus(true);
    setOrganizationsFilterStatus(true);
    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
    };
  }, [setCountriesFilterStatus, setOrganizationsFilterStatus]);

  useEffect(() => {
    getNewsCategories();
  }, [getNewsCategories]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newsPage]);

  useEffect(() => {
    getNews(
      searchQuery,
      newsPage,
      arrToString(filterCountriesArr),
      arrToString(filterOrganizationsArr),
      category
    );
  }, [
    getNews,
    searchQuery,
    newsPage,
    filterCountriesArr,
    filterOrganizationsArr,
    category
  ]);

  useEffect(() => {
    setNewsPage(1);
  }, [category, searchQuery, filterCountriesArr, filterOrganizationsArr]);

  const newsFilterOnChange = withDebounce(e => setSearchQuery(e));

  return (
    <div className="news">
      <div className="news__block">
        <Filter onChange={e => newsFilterOnChange(e.target.value)} />
      </div>

      <div className="news__block">
        <PopularNews news={news && news.results} />
      </div>
      <Card>
        <div className="news__block ">
          <div className="news__separator" />
          <div className="news__block--categories">
            <div className="news-categories">
              <DefaultDropdown
                items={
                  newsCategories && [
                    { id: "", name: "All news" },
                    ...newsCategories
                  ]
                }
                returnData={setCategory}
              />
            </div>
            <div className="events__body__view">
              <p>View:</p>
              <EventView1 className="events__body__view__block" />
              <EventView2 />
            </div>
          </div>
          {!isNewsPending &&
            news.results &&
            news.results.map(
              ({
                id,
                title,
                image,
                short_description,
                organization,
                created_at
              }) => (
                <Fragment key={id}>
                  <NavLink to={`news/${id}`} className="news__block--padding">
                    <EventCard
                      img={true}
                      imgSrc={image ? image : missingImage}
                      organization={organization && organization.name}
                      title={title}
                      description={short_description}
                      viewLiveUrl={`news/${id}`}
                      publicated={true}
                      publicatedDate={moment(created_at).format("DD MMMM YYYY")}
                    />
                  </NavLink>
                  <div key={id} className="news__separator" />
                </Fragment>
              )
            )}
        </div>
        {news.results && !news.results.length && (
          <NoDataFound
            noDataTitle="No news found!"
            myBusiness={true}
            additionalLink="/my-business/news"
          />
        )}
      </Card>
      <Pagination
        forcePage={newsPage - 1}
        itemsPerPage={10}
        dataCount={news.count}
        selectedPage={page => setNewsPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  isNewsPending: state.newsReducer.isNewsPending,
  news: state.newsReducer.news,
  countryId: state.countriesReducer.countryId,
  filterString: state.filterReducer.filterString,
  newsCategories: state.newsReducer.newsCategories,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr
});

export default connect(mapStateToProps, {
  getNews,
  getFilteredNews,
  getNewsCategories,

  setCountriesFilterStatus,
  setOrganizationsFilterStatus
})(News);
