import React, { useEffect, useState } from "react";
import grapesjs from "grapesjs";

import LoadingSpinner from "../../components/loading-spinner/loading-spinner.component";

import gjsContainerRTL from "../../grapes/plugins/gjs-container-rtl";
import gjsPageManager from "../../grapes/plugins/gjs-page-manager";
import gjsTranslationManagerTrait from "../../grapes/plugins/gjs-translation-manager-trait";
import gjsPageTrait from "../../grapes/plugins/gjs-page-trait";
import gjsProductsCategoryTrait from "../../grapes/plugins/gjs-products-category-trait";
import gjsTextWithDescriptionTrait from "../../grapes/plugins/gjs-text-with-description-trait";
// import gjsTemplateManager from "../../grapes/plugins/gjs-template-manager";
import gjsThemeManager from "../../grapes/plugins/gjs-theme-manager";
// import gjsSiteManager from "../../grapes/plugins/gjs-site-manager";
import gjsCustomBlocks from "../../grapes/plugins/gjs-custom-blocks";
import gjsCustomJSGenerator from "../../grapes/plugins/gjs-custom-js-generator";
// import gjsDraggingMode from "../../grapes/plugins/gjs-dragging-mode";
import tUIImageEditor from "grapesjs-tui-image-editor";
import gjsPresetWebpageData from "./gjs-preset-webpage-data";
import gjsPresetWebpage from "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js";
import gjsLorySlider from "grapesjs-lory-slider/dist/grapesjs-lory-slider.min.js";
import gjsSwiperSlider from "grapesjs-swiper-slider/dist/grapesjs-swiper-slider.min.js";
import gjsCustomCode from "grapesjs-custom-code";
import gjsTouch from "grapesjs-touch/dist/grapesjs-touch.min.js";
import gjsTooltip from "grapesjs-tooltip";
import gjsTyped from "grapesjs-typed/dist/grapesjs-typed.min.js";
import gjsParserPostCSS from "grapesjs-parser-postcss/dist/grapesjs-parser-postcss.min.js";
import "grapick/dist/grapick.min.js";
import gjsStyleFilter from "grapesjs-style-filter/dist/grapesjs-style-filter.min.js";
import gjsStyleBg from "grapesjs-style-bg/dist/grapesjs-style-bg.min.js";

import { getCookie } from "../../axios/axios.config";
import axios from "axios";
import ApiService from "../../services/api";

import "grapesjs/dist/css/grapes.min.css";
import "grapick/dist/grapick.min.css";
import "./grapes.styles.scss";

const Grapes = ({ match }) => {
  const [site, setSite] = useState(null);
  const [rootPage, setRootPage] = useState(null);

  const token = getCookie("Authorization").slice(6);
  localStorage.setItem("token", token);
  const api = new ApiService({ token });

  useEffect(() => {
    const templateId = match.params.templateId;
    // Get site
    if (!site) {
      api.getSite({ id: match.params.siteId }).then(site => setSite(site));
    }
    // Get pages
    if (!rootPage && site) {
      api
        .getPages({ siteId: site.id, path: "/" })
        .then(json => (json.results.length ? json.results[0] : {}))
        .then(page => setRootPage(page));
    }

    if (!rootPage || !site) {
      return;
    }

    const urlStore = templateId
      ? `${api.API_ROOT_BUILDER}/builder/template/${templateId}/`
      : `${api.API_ROOT_BUILDER}/builder/pages/${match.params.pageId ||
          (rootPage && rootPage.id)}/save/?resource_id=${match.params.siteId}`;
    const urlLoad = templateId
      ? `${api.API_ROOT_BUILDER}/builder/template/${templateId}/`
      : `${api.API_ROOT_BUILDER}/builder/pages/${match.params.pageId ||
          (rootPage && rootPage.id)}/load/?resource_id=${match.params.siteId}`;

    window.editor_site = site;
    // Init grapesjs
    window.editor = grapesjs.init({
      container: "#gjs",
      height: "100vh",
      fromElement: true,
      showOffsets: true,
      styleManager: { clearProperties: true },
      assetManager: {
        autoAdd: 0,
        dropzone: 0,
        openAssetsOnDrop: 0,
        dropzoneContent:
          '<div class="dropzone-inner">Drop here your assets</div>',
        multiUpload: false,
        uploadFile: function(e) {
          let files = [];
          if (e.dataTransfer) {
            files.push(e.dataTransfer.files[0]);
          } else {
            files.push(e.target.files[0]);
          }

          files.map(file => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.onload = function() {
              const imageData = new FormData();
              imageData.append("type", "image");
              imageData.append("file", file);
              imageData.append("width", img.width.toString());
              imageData.append("height", img.height.toString());
              imageData.append(
                "page",
                match.params?.pageId ? match.params.pageId : rootPage.id
              );
              imageData.append("unitDim", "px");
              imageData.append("name", file.name);

              URL.revokeObjectURL(objectUrl);
              axios
                .post(`${api.API_ROOT_BUILDER}/builder/access/`, imageData, {
                  headers: {
                    Authorization: getCookie("Authorization"),
                    "Content-Type": "multipart/form-data"
                  }
                })
                .then(response => {
                  const images = response.data;
                  if (images.file) {
                    window.editor.AssetManager.add(images.file);
                  }
                });
            };
            img.src = objectUrl;

            return file;
          });
        }
      },
      plugins: [
        gjsPresetWebpage,
        gjsLorySlider,
        gjsSwiperSlider,
        gjsCustomCode,
        gjsTouch,
        gjsTooltip,
        gjsTyped,
        tUIImageEditor,
        gjsParserPostCSS,
        gjsStyleFilter,
        gjsStyleBg,
        gjsContainerRTL,
        gjsCustomJSGenerator,
        // gjsDraggingMode,
        editor => new gjsThemeManager({ token, site, editor }),
        editor => new gjsPageManager({ token, site, editor }),
        gjsTranslationManagerTrait(),
        gjsPageTrait({ token, site }),
        gjsProductsCategoryTrait({ token, site }),
        gjsTextWithDescriptionTrait,
        gjsCustomBlocks({ token, site })
      ],
      pluginsOpts: {
        [gjsLorySlider]: {
          sliderBlock: {
            category: "Extra"
          }
        },
        [gjsSwiperSlider]: {
          sliderBlock: {
            category: "Extra",
            label: "Swiper slider"
          }
        },
        [gjsTyped]: {
          block: {
            category: "Extra",
            content: {
              type: "typed",
              "type-speed": 40,
              strings: ["Text row one", "Text row two", "Text row three"]
            }
          }
        },
        [gjsCustomCode]: {
          blockLabel: "Custom Code"
        },
        [gjsPresetWebpage]: gjsPresetWebpageData
      },
      // TODO: перенести либы в гит
      canvas: {
        styles: [
          "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
        ],
        scripts: [
          "https://code.jquery.com/jquery-3.3.1.slim.min.js",
          "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js",
          "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js"
        ]
      },
      selectorManager: {
        states: [
          { name: "hover", label: "Hover" },
          { name: "active", label: "Click" },
          { name: "nth-of-type(2n)", label: "Even/Odd" },
          { name: "disabled", label: "Disabled" },
          { name: "before", label: "Before" },
          { name: "after", label: "After" }
        ]
      },
      allowScripts: 1,
      jsInHtml: true,
      log: true,
      exportWrapper: true,
      wrapperIsBody: true,
      storeWrapper: true,
      storageManager: {
        id: "gjs_",
        type: "remote",
        // If autosave enabled, indicates how many changes are necessary
        // before store method is fired
        stepsBeforeSave: 1,
        // Store data automatically
        autosave: false,
        // Autoload stored data on init
        autoload: true,
        // Url for storing a template
        urlStore,
        // Url for loading a template
        urlLoad,
        credentials: "omit",
        headers: {
          Authorization: `Token ${token}`
        },
        fetchOptions: currentOpts =>
          currentOpts.method === "post" && templateId ? { method: "PATCH" } : {}
      }
    });
    document.body.classList.add("grapes-application-wrapper");
    // Add options from plugins to style manager
    window.editor.StyleManager.addProperty("extra", {
      name: "Filter",
      property: "filter",
      type: "filter",
      full: true
    });

    window.editor.StyleManager.addProperty("decorations", {
      name: "Background",
      property: "background",
      type: "bg",
      full: true
    });

    // editor.StyleManager.addProperty("Extra", {
    //   name: "Filter",
    //   property: "filter",
    //   type: "filter",
    //   full: true
    // });
    // Tooltips and borders
    window.editor.on("load", () => {
      // Add and beautify tooltips
      const pn = window.editor.Panels;
      [
        ["sw-visibility", "Show Borders"],
        ["preview", "Preview"],
        ["fullscreen", "Fullscreen"],
        ["asset-manager", "Asset Manager"],
        ["export-template", "Export"],
        ["undo", "Undo"],
        ["redo", "Redo"],
        ["gjs-open-import-webpage", "Import"],
        ["canvas-clear", "Clear canvas"],
        // ["edit-site-button", "Edit Site"],
        ["pages-manager-button", "Page Manager"],
        ["save-db", "Save Page"],
        ["theme-manager-button", "Theme Manager"]
        // eslint-disable-next-line array-callback-return
      ].map(([buttonId, title]) => {
        const button = pn.getButton("options", buttonId);
        button.set("attributes", {
          title,
          "data-tooltip-pos": "bottom"
        });
      });

      [
        ["open-sm", "Style Manager"],
        ["open-layers", "Layers"],
        ["open-blocks", "Blocks"],
        // ["template-panel-button", "Templates"],
        ["open-tm", "Settings"]
        // eslint-disable-next-line array-callback-return
      ].map(([buttonId, title]) => {
        const button = pn.getButton("views", buttonId);
        button.set("attributes", {
          title,
          "data-tooltip-pos": "bottom"
        });
      });
      // eslint-disable-next-line array-callback-return
      [...document.querySelectorAll("*[title]")].map(el => {
        const title = el.getAttribute("title")
          ? el.getAttribute("title").trim()
          : "";
        if (title) {
          el.setAttribute("data-tooltip", title);
          el.setAttribute("title", "");
        }
      });
      // Show borders by default
      pn.getButton("options", "sw-visibility").set("active", 1);
    });
    // Close blocks categories
    window.editor.on("load", () => {
      window.editor.BlockManager.getCategories().map(
        category => (category.attributes.open = false)
      );
      window.editor.BlockManager.render();
    });
    // Preloader
    const canvasEl = document.querySelector(".gjs-cv-canvas");
    if (canvasEl) canvasEl.classList.add("preloader");
    window.editor.on("storage:start", () => {
      const canvasEl = document.querySelector(".gjs-cv-canvas:not(.preloader)");
      if (canvasEl) canvasEl.classList.add("preloader");
    });
    window.editor.on("storage:end", () => {
      const canvasEl = document.querySelector(".gjs-cv-canvas.preloader");
      if (canvasEl) canvasEl.classList.remove("preloader");
    });
    // Body customization
    window.editor.getWrapper().set("stylable", true);

    // window.editor.on("asset:remove", function(asset) {
    //   const assetId = this.cacheLoad?.assets.findIndex(
    //     item => item.src === asset.attributes.src
    //   );
    //
    //   if (assetId) {
    //     axios
    //       .delete(`${api.API_ROOT_BUILDER}/builder/access/${assetId}`, {
    //         headers: {
    //           Authorization: getCookie("Authorization")
    //         }
    //       })
    //       .then(response => {});
    //   }
    // });

    // Unmount
    return () => {
      if (window.editor) window.editor.destroy();
    };
  }, [
    api,
    match.params.pageId,
    match.params.siteId,
    match.params.templateId,
    rootPage,
    site,
    token
  ]);

  if (!rootPage || !site) {
    return <LoadingSpinner loadingState />;
  }

  return <div id="gjs" />;
};

export default Grapes;
