import { axiosInstance } from "../../../axios/axios.config";
import { VacanciesActionTypes } from "./vacancies.types";
import { getJobsCreatedByMe } from "../../../redux/actions-exporter";

export const setTypeOfOrganization = typeOfOrganization => dispatch => {
  dispatch({
    type: VacanciesActionTypes.SET_TYPE_OF_ORGANIZATION,
    payload: typeOfOrganization
  });
};

export const setAddVacancyDataAction = (
  departmentVacancyCurrent,
  name,
  value
) => dispatch => {
  dispatch({
    type: VacanciesActionTypes.SET_INPUT_VACANCY_DATA,
    payload: { ...departmentVacancyCurrent, [name]: value }
  });
};

export const getVacancies = (pageNumber = 1) => dispatch => {
  dispatch({ type: VacanciesActionTypes.GET.GET_VACANCIES_PENDING });
  axiosInstance
    .get(`/api/v1/organization/vacancies/?page=${pageNumber}`, {}, {})
    .then(vacancies =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_VACANCIES_SUCCESS,
        payload: {
          vacanciesCount: vacancies.data.count,
          vacancies: vacancies.data.results
        }
      })
    )
    .catch(error =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_VACANCIES_ERROR,
        payload: error
      })
    );
};

export const getEmployerTypeData = () => dispatch => {
  dispatch({ type: VacanciesActionTypes.GET.GET_EMPLOYER_TYPE_PENDING });
  axiosInstance
    .get(`api/v1/department/jobs/emtypes/`)
    .then(employerType =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_EMPLOYER_TYPE_SUCCESS,
        payload: employerType.data
      })
    )
    .catch(error =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_EMPLOYER_TYPE_ERROR,
        payload: error
      })
    );
};

export const getVacanciesByDepartmentOrganization = organizationId => dispatch => {
  dispatch({
    type:
      VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_PENDING
  });
  axiosInstance
    .get(
      `api/v1/department/vacancy/?department__organization=${organizationId}`
    )
    .then(structureData =>
      dispatch({
        type:
          VacanciesActionTypes.GET
            .GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_SUCCESS,
        payload: structureData.data.results
      })
    )
    .catch(error =>
      dispatch({
        type:
          VacanciesActionTypes.GET
            .GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_ERROR,
        payload: error
      })
    );
};

export const getMultipleVacanciesDepartmentOrganization = organizations => dispatch => {
  dispatch({
    type:
      VacanciesActionTypes.GET
        .GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_PENDING
  });

  Promise.all(
    organizations.map(({ id }) =>
      axiosInstance
        .get(`api/v1/department/vacancy/?department__organization=${id}`)
        .then(vacancy => vacancy.data)
    )
  )
    .then(totalVacancies => {
      dispatch({
        type:
          VacanciesActionTypes.GET
            .GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_SUCCESS,
        payload: totalVacancies
      });
    })
    .catch(error =>
      dispatch({
        type:
          VacanciesActionTypes.GET
            .GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_ERROR,
        payload: error
      })
    );
};

export const getMultipleVacanciesDepartmentCompany = departments => dispatch => {
  dispatch({
    type:
      VacanciesActionTypes.GET.GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_PENDING
  });

  Promise.all(
    departments.map(({ id }) =>
      axiosInstance
        .get(`/api/v1/department/vacancy/?department=${id}`)
        .then(vacancy => vacancy.data.results)
    )
  )
    .then(totalVacancies => {
      dispatch({
        type:
          VacanciesActionTypes.GET
            .GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_SUCCESS,
        payload: totalVacancies
      });
    })
    .catch(error =>
      dispatch({
        type:
          VacanciesActionTypes.GET
            .GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_ERROR,
        payload: error
      })
    );
};

export const getVacanciesCreatedByMe = () => dispatch => {
  dispatch({ type: VacanciesActionTypes.GET.GET_VACANCIES_BY_ME_PENDING });
  axiosInstance.get(`/api/v1/auth/users/me`).then(user => {
    axiosInstance
      .get(`api/v1/organization/company/?created_by=${user.data.id}`)
      .then(companies => companies.data.results)
      .then(comps => {
        Promise.all(
          comps.map(company =>
            axiosInstance
              .get(
                `/api/v1/department/vacancy/?department__organization=${company.id}`
              )
              .then(vacancies => vacancies.data.results)
          )
        )
          .then(totalVacancies => {
            dispatch({
              type: VacanciesActionTypes.GET.GET_VACANCIES_BY_ME_SUCCESS,
              payload: totalVacancies.reduce((a, e) => [...a, ...e], [])
            });
          })
          .catch(err => {
            dispatch({
              type: VacanciesActionTypes.GET.GET_VACANCIES_BY_ME_ERROR,
              payload: err
            });
          });
      });
  });
};

export const getVacancyById = vacancyId => dispatch => {
  dispatch({ type: VacanciesActionTypes.GET.GET_VACANCY_BY_ID_PENDING });
  axiosInstance
    .get(`/api/v1/department/jobs/${vacancyId}`)
    .then(singleVacancy => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_VACANCY_BY_ID_SUCCESS,
        payload: singleVacancy.data
      });
    })
    .catch(error => console.log(error));
};

export const createVacancy = (
  title,
  vacancy,
  employerType,
  country,
  city,
  keywords,
  description,
  requirements,
  terms
) => dispatch => {
  axiosInstance
    .post("/api/v1/department/jobs/", {
      title,
      vacancy,
      type_of_employment: employerType,
      country,
      city,
      keywords,
      description,
      requirements,
      terms
    })
    .then(createdVacancy =>
      dispatch({
        type: VacanciesActionTypes.CREATE_VACANCY,
        payload: {
          createdVacancy: createVacancy.data,
          requestStatus: createdVacancy.status
        }
      })
    )
    .catch(error => console.log(error));
};

export const updateVacancy = (
  vacancyId,
  title,
  vacancy,
  employerType,
  country,
  city,
  keywords,
  description,
  requirements,
  terms
) => dispatch => {
  axiosInstance
    .put(`/api/v1/department/jobs/${vacancyId}/`, {
      title,
      vacancy,
      type_of_employment: employerType,
      country,
      city,
      keywords,
      description,
      requirements,
      terms
    })
    .then(updatedVacancy =>
      dispatch({
        type: VacanciesActionTypes.UPDATE_VACANCY,
        payload: {
          updatedVacancy: updatedVacancy.data,
          requestStatus: updatedVacancy.status
        }
      })
    )
    .catch(error => console.log(error));
};

export const deleteVacancy = vacancyId => dispatch => {
  axiosInstance
    .delete(`/api/v1/department/jobs/${vacancyId}/`)
    .then(deletedVacancy =>
      dispatch({
        type: VacanciesActionTypes.DELETE_VACANCY,
        payload: deletedVacancy
      })
    )
    .then(() => dispatch(getJobsCreatedByMe()))
    .catch(error => console.log(error));
};

export const getEmployerTypes = () => dispatch => {
  dispatch({ type: VacanciesActionTypes.GET.GET_EMPLOYER_TYPES_PENDING });
  axiosInstance
    .get(`/api/v1/department/jobs/emtypes`)
    .then(emTypes =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_EMPLOYER_TYPES_SUCCESS,
        payload: emTypes.data
      })
    )
    .catch(error =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_EMPLOYER_TYPES_ERROR,
        payload: error
      })
    );
};

export const createDepartment = (
  nameDepartment,
  organizationId
) => dispatch => {
  axiosInstance
    .post("/api/v1/department/department/", {
      name: nameDepartment,
      organization: organizationId
    })
    .then(department => {
      dispatch({
        type: VacanciesActionTypes.POST.CREATE_DEPARTMENT_SUCCESS,
        payload: {
          department: department.data,
          requestStatus: department.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.POST.CREATE_DEPARTMENT_ERROR,
        payload: err
      });
    });
};

export const editDepartment = (
  departmentId,
  nameDepartment,
  organizationId
) => dispatch => {
  axiosInstance
    .put(`/api/v1/department/department/${departmentId}/`, {
      name: nameDepartment,
      organization: organizationId
    })
    .then(department => {
      dispatch({
        type: VacanciesActionTypes.PUT.EDIT_DEPARTMENT_SUCCESS,
        payload: {
          department: department.data,
          requestStatus: department.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.PUT.EDIT_DEPARTMENT_ERROR,
        payload: err
      });
    });
};

export const deleteDepartment = (departmentId, companyId) => dispatch => {
  axiosInstance
    .delete(`/api/v1/department/department/${departmentId}/`)
    .then(department => {
      dispatch({
        type: VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_SUCCESS,
        payload: { department: department.data, id: departmentId }
      });
    })
    .then(() => dispatch(getVacanciesByDepartmentOrganization(companyId)))
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_ERROR,
        payload: err
      });
    });
};

export const setCurrentCompany = currentCompany => dispatch => {
  dispatch({
    type: VacanciesActionTypes.SET_CURRENT_COMPANY,
    payload: currentCompany
  });
};

export const setCurrentDepartment = currentDepartment => dispatch => {
  dispatch({
    type: VacanciesActionTypes.SET_CURRENT_DEPARTMENT,
    payload: currentDepartment
  });
};

export const getDepartmentsByOrganization = organizationId => dispatch => {
  dispatch({
    type: VacanciesActionTypes.GET.GET_DEPARTMENTS_BY_ORGANIZATION_PENDING
  });
  axiosInstance
    .get(`/api/v1/department/department/?organization=${organizationId}`)
    .then(departments =>
      dispatch({
        type: VacanciesActionTypes.GET.GET_DEPARTMENTS_BY_ORGANIZATION_SUCCESS,
        payload: departments.data.results
      })
    )
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_DEPARTMENTS_BY_ORGANIZATION_ERROR,
        payload: err
      });
    });
};

export const addDepartmentVacancy = (
  vacancyTitle,
  departmentName,
  vacancyStaffGroup,
  vacancyPermissionsGroup
) => dispatch => {
  dispatch({
    type: VacanciesActionTypes.POST.CREATE_DEPARTMENT_VACANCY_PENDING
  });
  axiosInstance
    .post(`/api/v1/department/vacancy/`, {
      name: vacancyTitle,
      department: departmentName,
      staffgroup: vacancyStaffGroup,
      permission_extra_group: vacancyPermissionsGroup
    })
    .then(vacancy => {
      dispatch({
        type: VacanciesActionTypes.POST.CREATE_DEPARTMENT_VACANCY_SUCCESS,
        payload: { vacancy: vacancy.data, requestStatus: vacancy.status }
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.POST.CREATE_DEPARTMENT_VACANCY_ERROR,
        payload: err
      });
    });
};

export const editDepartmentVacancy = (
  vacancyId,
  vacancyName,
  vacancyStaffGroup,
  vacancyPermissionsGroup
) => dispatch => {
  dispatch({
    type: VacanciesActionTypes.PUT.PUT_DEPARTMENT_VACANCY_PENDING
  });
  axiosInstance
    .patch(`/api/v1/department/vacancy/${vacancyId}/`, {
      name: vacancyName,
      staffgroup: vacancyStaffGroup,
      permission_extra_group: vacancyPermissionsGroup
    })
    .then(vacancy => {
      dispatch({
        type: VacanciesActionTypes.PUT.PUT_DEPARTMENT_VACANCY_SUCCESS,
        payload: { vacancy: vacancy.data, requestStatus: vacancy.status }
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.PUT.PUT_DEPARTMENT_VACANCY_ERROR,
        payload: err
      });
    });
};

export const deleteDepartmentVacancy = (vacancyId, companyId) => dispatch => {
  axiosInstance
    .delete(`/api/v1/department/vacancy/${vacancyId}/`)
    .then(vacancy => {
      dispatch({
        type: VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_VACANCY_SUCCESS,
        payload: { id: vacancyId, vacancy: vacancy }
      });
    })
    .then(() => dispatch(getVacanciesByDepartmentOrganization(companyId)))
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_VACANCY_ERROR,
        payload: err
      });
    });
};

export const clearPositionVacancy = (vacancyId, companyId) => dispatch => {
  dispatch({
    type: VacanciesActionTypes.PATCH.CLEAR_POSITION_VACANCY_ID_PENDING
  });

  axiosInstance
    .patch(`api/v1/department/vacancy/${vacancyId}/`, {
      profile: [],
      user: null
    })
    .then(position => {
      dispatch({
        type: VacanciesActionTypes.PATCH.CLEAR_POSITION_VACANCY_ID_SUCCESS,
        payload: { position, vacancyId }
      });
      dispatch(getVacanciesByDepartmentOrganization(companyId));
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.PATCH.CLEAR_POSITION_VACANCY_ID_ERROR,
        payload: err
      });
    });
};

export const addDepartmentStaff = (
  staffId,
  userEmail,
  vacancyStaffGroup,
  vacancyPermissionsGroup
) => dispatch => {
  dispatch({
    type: VacanciesActionTypes.POST.ADD_DEPARTMENT_STAFF_PENDING
  });

  axiosInstance
    .patch(`/api/v1/department/vacancy/${staffId}/`, {
      user: userEmail,
      staffgroup: vacancyStaffGroup,
      permission_extra_group: vacancyPermissionsGroup
    })
    .then(vacancy => {
      dispatch({
        type: VacanciesActionTypes.POST.ADD_DEPARTMENT_STAFF_SUCCESS,
        payload: {
          vacancy: vacancy.data,
          requestStatus: vacancy.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.POST.ADD_DEPARTMENT_STAFF_ERROR,
        payload: err
      });
    });
};

export const getVacanciesByDepartmentId = departmentId => dispatch => {
  dispatch({
    type: VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ID_PENDING
  });
  axiosInstance
    .get(`/api/v1/department/vacancy/?department=${departmentId}`)
    .then(vacanciesByDepartmentId => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ID_SUCCESS,
        payload: vacanciesByDepartmentId.data.results
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ID_ERROR,
        payload: err
      });
    });
};

export const getDepartmentById = departmentId => dispatch => {
  dispatch({
    type: VacanciesActionTypes.GET.GET_DEPARTMENT_BY_ID_PENDING
  });
  axiosInstance
    .get(`/api/v1/department/department/${departmentId}`)
    .then(department => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_DEPARTMENT_BY_ID_SUCCESS,
        payload: department.data
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_DEPARTMENT_BY_ID_ERROR,
        payload: err
      });
    });
};

export const getStaffGroup = () => dispatch => {
  dispatch({
    type: VacanciesActionTypes.GET.GET_STAFF_GROUP_PENDING
  });
  axiosInstance
    .get("/api/v1/department/staff_group/")
    .then(staffGroup => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_STAFF_GROUP_SUCCESS,
        payload: staffGroup.data.results
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_STAFF_GROUP_ERROR,
        payload: err
      });
    });
};

export const getPermissionsGroup = () => dispatch => {
  dispatch({
    type: VacanciesActionTypes.GET.GET_PERMISSIONS_GROUP_PENDING
  });
  axiosInstance
    .get("/api/v1/department/permissions_group/")
    .then(permisionsGroup => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_PERMISSIONS_GROUP_SUCCESS,
        payload: permisionsGroup.data.results
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_PERMISSIONS_GROUP_ERROR,
        payload: err
      });
    });
};

export const getDepartmentVacancyById = vacancyId => dispatch => {
  dispatch({
    type: VacanciesActionTypes.GET.GET_DEPARTMENT_VACANCY_BY_ID_PENDING
  });
  axiosInstance
    .get(`/api/v1/department/vacancy/${vacancyId}`)
    .then(vacancyId => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_DEPARTMENT_VACANCY_BY_ID_SUCCESS,
        payload: vacancyId.data
      });
    })
    .catch(err => {
      dispatch({
        type: VacanciesActionTypes.GET.GET_DEPARTMENT_VACANCY_BY_ID_ERROR,
        payload: err
      });
    });
};

export const clearJobRequest = () => dispatch => {
  dispatch({
    type: VacanciesActionTypes.CLEAR_JOB_REQUEST,
    payload: ""
  });
};

export const clearDepartmentCreate = () => dispatch => {
  dispatch({
    type: VacanciesActionTypes.CLEAR_DEPARTMENT_CREATE,
    payload: ""
  });
};
