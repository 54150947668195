import React from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import {
  Language as LanguageIcon,
  Clear as ClearIcon
} from "@material-ui/icons";

import { DefaultButton } from "../../components";

import "./documents-table.styles.scss";

const DocumentsTable = ({
  tableName,
  documents,
  deleteDocumentById,
  appDirection
}) => {
  return (
    <div className={`my-business__documents ${appDirection}`}>
      <h3 style={{ margin: 0 }}>{tableName} </h3>
      {documents && documents.length ? (
        <Table aria-label="items table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Visualize</TableCell>
              {/* <TableCell align="center">Edit</TableCell> */}
              <TableCell align="center">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents &&
              documents.map(doc => (
                <TableRow key={doc.id}>
                  <TableCell align="center">
                    <div className="table-div-content">
                      {doc.name.length > 40
                        ? doc.name.substring(0, 40) + "..."
                        : doc.name}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="table-div-content">
                      {doc.description.length > 40
                        ? doc.description.substring(0, 40) + "..."
                        : doc.description}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <a
                      href={doc.document}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LanguageIcon />
                    </a>
                  </TableCell>
                  {/* <TableCell align="center">
                  <Link to="/">
                    <DefaultIconButton>
                      <EditIcon />
                    </DefaultIconButton>
                  </Link>
                </TableCell> */}
                  <TableCell align="center">
                    <DefaultButton onClick={() => deleteDocumentById(doc)}>
                      <ClearIcon />
                    </DefaultButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <h3 style={{ fontWeight: 500 }}>No documents...</h3>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection
});

export default connect(mapStateToProps, null)(DocumentsTable);
