export default function newsDescriptionComponent({ editor, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makeNewsDescriptionHtml(
      translations,
      src,
      title,
      description,
      organization,
      date
    ) {
      return `
      <div class="news-description">
        <img class="news-description__image" src="${src}" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />
        <div class="news-description__main">
          <div class="news-description__header">
            <h4 class="news-description__title">${title}</h4>
          </div>
          <span class="news-description__divider"></span>
          <div class="news-description__description">
          ${description || translations["No description..."]}
          </div>
          <div class="news-description__meta">
            <div class="news-description__meta-item">
              <span class="news-description__organization">
                ${
                  organization && organization.name
                    ? organization.name
                    : translations["N/A"]
                }
              </span>
            </div>
            <div class="news-description__meta-item">
              <span class="news-description__date">
                ${
                  date
                    ? new Date(date).toLocaleDateString()
                    : translations["N/A"]
                }
              </span>
            </div>
          </div>
        </div>
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".news-description-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("news-description-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("news-description-wrapper--preloader");
      }
    }

    function render(id, translations) {
      const API_ROOT = "{[ apiRoot ]}";

      setLoading(true);
      fetch(`${API_ROOT}/news/news/${id}/`)
        .then(response => response.json())
        .then(news => {
          const el = document.querySelector(".news-description-wrapper");

          el.innerHTML = makeNewsDescriptionHtml(
            translations,
            news.image,
            news.title,
            news.content,
            news.organization,
            news.created_at
          );

          setLoading(false);
        });
    }

    const params = new URLSearchParams(window.location.search);
    const id = params.get("gjs-news-id");

    if (window.hasOwnProperty("grapesjs")) {
      // Get translation && render
      const translations = getTranslate("translations", this.attributes);
      if (translations && Object.keys(translations).length) {
        render(id, translations);
      }
    }
  };

  editor.DomComponents.addType("news-description", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "news-description-wrapper",
    model: {
      defaults: {
        name: "News Description",
        apiRoot: api.API_ROOT,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["No description...", "N/A"]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
