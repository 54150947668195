import React, { useEffect } from "react";
import { connect } from "react-redux";

import { missingImage } from "../../assets";
import { LoadingSpinner, ProductCard } from "../../components";

import { getPortalRecomendations } from "../../redux/actions-exporter";

import "./we-recommend.style.scss";

const WeRecommend = ({
  arePortalRecommendsPending,
  portalRecommends,
  getPortalRecomendations
}) => {
  useEffect(() => {
    getPortalRecomendations();
  }, []); // eslint-disable-line

  return (
    <div className="we-recommend">
      {arePortalRecommendsPending ? (
        <LoadingSpinner />
      ) : (
        <div className="we-recommend__cards">
          {portalRecommends.results &&
            portalRecommends.results.map(({ content_type, item }) => {
              const { id, image, categories, name, currency, cost } = item;
              return (
                <ProductCard
                  navProductType={content_type === 84 ? "b2b" : "b2c"}
                  productId={id}
                  key={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={name}
                  currency={currency}
                  price={cost}
                  currentProductData={item}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  arePortalRecommendsPending:
    state.portalRecommendations.arePortalRecommendsPending,
  portalRecommends: state.portalRecommendations.portalRecommends
});

export default connect(mapStateToProps, { getPortalRecomendations })(
  WeRecommend
);
