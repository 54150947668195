import content from "./block-content";
import icon from "./icon.png";

export default function ordesListBlock({ editor }) {
  const blockManager = editor.BlockManager;

  blockManager.add("order-list", {
    label: `
      <img src="${icon}" alt="Order List block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Order List
      </div>
    `,
    content,
    category: "B24Online Profile"
  });
}
