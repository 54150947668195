import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import ScrollToTop from "react-router-scroll-top";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";

import App from "./App";
import "./i18n";
import store from "./redux/store";
import { theme } from "./styles/theme";
import { AuthActionTypes } from "./redux/APIs/auth/auth.types";
import { getCookie } from "./axios/axios.config";
import ServiceWorkerWrapper from "@core/components/ServiceWorkerWrapper";

import "normalize.css";
import "./index.css";

const REACT_APP_MODE = process.env.REACT_APP_MODE;

// CHECK IF USER IS LOGGED IN BY VERIFYING JWT
const token = getCookie("Authorization");
if (token) {
  store.dispatch({ type: AuthActionTypes.CHECK_TOKEN });
}

if (REACT_APP_MODE === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: REACT_APP_MODE
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={createBrowserHistory()}>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <ServiceWorkerWrapper />
          <App />
        </ThemeProvider>
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById("root")
);
