import icon from "./icon.png";

export default function authContainerBlock({ editor }) {
  const blockManager = editor.BlockManager;

  blockManager.add("auth-container", {
    label: `
      <img src="${icon}" alt="Auth container block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Auth Container
      </div>
    `,
    content: {
      type: "auth-container"
    },
    category: "B24Online Auth"
  });
}
