export default function newsCategoriesComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };
    // Get translation
    const translations = getTranslate("translations", this.attributes);
    // Get search input
    const searchEl = document.querySelector(".news-categories__search");
    // Change placeholder
    if (translations && Object.keys(translations).length) {
      searchEl.setAttribute("placeholder", translations["Search..."]);
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".news-categories");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("news-categories--preloader");
      } else {
        wrapperEl.classList.remove("news-categories--preloader");
      }
    }

    function render(total, page, perPage, news, categories, loading) {
      const el = document.querySelector(".news-categories__list");
      setLoading(loading);

      el.innerHTML = categories.reduce(
        (tmp, category) =>
          tmp +
          `
            <li
              class="news-categories__item ${
                category.selected ? " news-categories__item--active" : ""
              }"
              data-id="${category.id}"
            >
              ${category.title}
            </li>
          `,
        ""
      );

      const categoriesEls = document.querySelectorAll(".news-categories__item");

      Array.prototype.slice.call(categoriesEls).map(categoriesEl =>
        categoriesEl.addEventListener("click", e => {
          e.preventDefault();
          const isPressed = e.target.classList.contains(
            "news-categories__item--active"
          );
          const categoryId = parseInt(e.target.dataset.id);

          if (isPressed) {
            e.target.classList.remove("news-categories__item--active");
            window.news.setCategories(
              window.news.categories.map(category =>
                category.id === categoryId
                  ? Object.assign({}, category, { selected: false })
                  : category
              )
            );
          } else {
            e.target.classList.add("news-categories__item--active");
            window.news.setCategories(
              window.news.categories.map(category =>
                category.id === categoryId
                  ? Object.assign({}, category, { selected: true })
                  : category
              )
            );
          }
        })
      );
    }

    window.waitForNewsInterval = setInterval(function() {
      if (window.hasOwnProperty("news") && window.hasOwnProperty("grapesjs")) {
        clearInterval(window.waitForNewsInterval);
        if (!window.news.renderCycle.find(func => func === render)) {
          window.news.renderCycle.push(render);
          window.news.loading = false;
          window.news.getCategories("");
        }
      }
    }, 100);

    this.closest(".news-categories-wrapper")
      .querySelector(".news-categories__search")
      .addEventListener("change", function(e) {
        if (
          window.hasOwnProperty("news") &&
          window.hasOwnProperty("grapesjs")
        ) {
          window.news.getCategories(e.target.value);
        }
      });
  };

  editor.DomComponents.addType("news-categories", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "news-categories-wrapper",
    model: {
      defaults: {
        name: "News Categories",
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Search..."]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
