export default function productsWithPaginationComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makeStarIcon(filled, width) {
      return filled
        ? `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}px" fill="#db2b3a" viewBox="0 0 485 485"><path d="M343.6 22.5a139.9 139.9 0 00-101.1 42.6 139.9 139.9 0 00-101.1-42.6C63.4 22.5 0 86 0 164c0 55.3 29.2 113.9 86.7 174.3 48 50.5 106.8 92.6 147.7 119l8.1 5.3 8.1-5.3c41-26.4 99.7-68.5 147.7-119 57.5-60.4 86.7-119 86.7-174.3 0-78-63.4-141.4-141.4-141.4z"/></svg>`
        : `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}px" fill="#db2b3a" viewBox="0 0 485 485"><path d="M343.6 22.5a139.9 139.9 0 00-101.1 42.6 139.9 139.9 0 00-101.1-42.6C63.4 22.5 0 86 0 164c0 55.3 29.2 113.9 86.7 174.3 48 50.5 106.8 92.6 147.7 119l8.1 5.3 8.1-5.3c41-26.4 99.7-68.5 147.7-119 57.5-60.4 86.7-119 86.7-174.3 0-78-63.4-141.4-141.4-141.4zm33 295c-43 45.2-95.2 83.5-134.1 109.2-38.9-25.7-91.2-64-134-109.1C56.3 262.9 30 211.2 30 163.9a111.5 111.5 0 01200.5-66.8l12 16 12-16A111.5 111.5 0 01455 164c0 47.3-26.4 99-78.4 153.7z"/></svg>`;
    }

    function makeProductHtml(
      id,
      src,
      title,
      description,
      children,
      cost,
      price_discount,
      discount_percent,
      coupon,
      currency,
      detailsPath,
      type,
      activeStatus,
      translations
    ) {
      const isActive =
        cost &&
        currency &&
        parseFloat(cost) > 0 &&
        window.localStorage.getItem("token") &&
        activeStatus;

      const uniqueChildren = children.length && [
        ...new Set(children.map(item => item.color))
      ];

      return `
      <div class="pwp-product">
        <a href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
          ${
            src
              ? `<img class="pwp-product__image" loading="lazy" src="${src}" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />`
              : `<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" class="pwp-product__image" id="ik1dh"></img>`
          }
        </a>
        <h4 class="pwp-product__title">
          <a class="pwp-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
            ${title}
          </a>
        </h4>
        <span class="pwp-product__divider"></span>
        <div class="pwp-product__description">
        ${
          description
            ? description.length <= 300
              ? description
              : `${description.slice(300)}...`
            : translations["No description"]
        }
        </div>
        <p class="pwp-product__meta pwp-product__meta--cost">
        ${
          cost && currency
            ? `
          ${
            discount_percent
              ? `
            <span class="pwp-product__cost pwp-product__cost--discount">
              ${cost}
            </span>
            <span class="pwp-product__discount">
              ${(cost - (cost / 100) * discount_percent).toFixed(2)}
            </span> 
            <span class="pwp-product__currency">
              ${translations[currency]}
            </span>
            <span class="pwp-product__discount-percent">
              -${discount_percent.toFixed(2)}%
            </span>
          `
              : price_discount > 0
              ? `
              <div class="pwp-product__cost" style="margin: auto auto 8px;
    text-align: center;">
                  <div class="pwp-product__cost-new">${price_discount}<span class="pwp-product__currency">
              ${translations[currency]}
            </span></div>
                  <div class="pwp-product__cost-old" style="text-decoration: line-through;">${cost}<span class="pwp-product__currency">
              ${translations[currency]}
            </span></div>          
              </div>
              `
              : `
            <span class="pwp-product__cost">
              ${cost}
            </span>
            <span class="pwp-product__currency">
              ${translations[currency]}
            </span>
          `
          }
          ${
            coupon &&
            coupon.date.start &&
            new Date().getTime() - new Date(coupon.date.start).getTime() >= 0 &&
            coupon.date.end &&
            new Date(coupon.date.end).getTime() - new Date().getTime() >= 0
              ? `
              <div
                class="pwp-coupon"
                data-data-end="${coupon.date.end}"
                data-data-start="${coupon.date.start}"
              >
                <div class="pwp-coupon__header">
                  <span class="pwp-coupon__cost">
                    ${(cost - (cost / 100) * coupon.discountPercent).toFixed(2)}
                  </span>
                  <span class="pwp-coupon__currency">
                    ${translations[currency]}
                  </span>
                  <span class="pwp-coupon__percent-discount">
                    -${coupon.discountPercent}%
                  </span>
                  <span class="pwp-coupon__message">
                    ${translations["Hurry up! Coupon Ends in"]}
                  </span>
                </div>
                <div class="pwp-coupon__grid" >
                  <span class="pwp-coupon__item">
                    <span class="pwp-coupon__counter pwp-coupon__counter--days">
                      000
                    </span>
                    <span class="pwp-coupon__label pwp-coupon__label--days">
                      Days
                    </span>
                  </span>
                  <span class="pwp-coupon__delimiter">
                    :
                  </span>
                  <span class="pwp-coupon__item">
                    <span class="pwp-coupon__counter pwp-coupon__counter--hours">
                      00
                    </span>
                    <span class="pwp-coupon__label pwp-coupon__label--hours">
                      Hours
                    </span>
                  </span>
                  <span class="pwp-coupon__delimiter">
                    :
                  </span>
                  <span class="pwp-coupon__item">
                    <span class="pwp-coupon__counter pwp-coupon__counter--minutes">
                      00
                    </span>
                    <span class="pwp-coupon__label pwp-coupon__label--minutes">
                      Minutes
                    </span>
                  </span>
                  <span class="pwp-coupon__delimiter">
                    :
                  </span>
                  <span class="pwp-coupon__item">
                    <span class="pwp-coupon__counter pwp-coupon__counter--seconds">
                      00
                    </span>
                    <span class="pwp-coupon__label pwp-coupon__label--seconds">
                      Seconds
                    </span>
                  </span>
                </div>
              </div>
              `
              : ""
          }
        `
            : `
          <span class="pwp-product__cost">
            ${translations["N/A"]}
          </span>
        `
        }
        </p>
        <div class="pwp-product__actions">
          <span
            class="pwp-product__wish ${
              !isActive ? "pwp-product__wish--disabled" : ""
            }"
            data-product_id="${id}"
            data-wish="false"
          >
            ${makeStarIcon(false, 30)}
          </span>
        </div>
        ${
          uniqueChildren?.length > 0
            ? `<div class="pwp-product__colors-container" style="display: flex; align-items: center; justify-content: center; width: 100%;">
            ${uniqueChildren.reduce(
              (tmp, item) =>
                tmp +
                `<div class="pwp-product__color" style="width: 20px; height: 20px; border-radius: 50%; margin: 0 5px; background-color: ${item}"></div>`,
              ""
            )}
            </div>`
            : ""
        }
        
      </div>
      `;
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
      <div class="pwp-pagination">
        ${
          currentPage - 1
            ? `
            <button class="pwp-pagination__button" data-page="${currentPage -
              1}">
            <
            </button>
            `
            : `
            <button class="pwp-pagination__button pwp-pagination__button--disabled" disabled>
            <
            </button>
            `
        }

        ${
          currentPage - 3 > 0
            ? `
            <button class="pwp-pagination__button" data-page="1">
            1
            </button>
            `
            : ""
        }

        ${
          currentPage - 4 > 0
            ? `<span class="pwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage - 2 > 0
            ? `
            <button class="pwp-pagination__button" data-page="${currentPage -
              2}">
            ${currentPage - 2}
            </button>
            `
            : ""
        }

        ${
          currentPage - 1 > 0
            ? `
            <button class="pwp-pagination__button" data-page="${currentPage -
              1}">
            ${currentPage - 1}
            </button>
            `
            : ""
        }

        ${`
            <button class="pwp-pagination__button pwp-pagination__button--active" data-page="${currentPage}">
            ${currentPage}
            </button>
        `}

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="pwp-pagination__button" data-page="${currentPage +
              1}">
            ${currentPage + 1}
            </button>
            `
            : ""
        }

        ${
          currentPage + 3 <= totalPagesCount
            ? `<span class="pwp-pagination__separator">...</span>`
            : ""
        }

        ${
          currentPage + 2 <= totalPagesCount
            ? `
            <button class="pwp-pagination__button" data-page="${totalPagesCount}">
            ${totalPagesCount}
            </button>
            `
            : ""
        }

        ${
          currentPage + 1 <= totalPagesCount
            ? `
            <button class="pwp-pagination__button" data-page="${currentPage +
              1}">
            >
            </button>
            `
            : `
            <button class="pwp-pagination__button pwp-pagination__button--disabled" disabled>
            >
            </button>
            `
        }
      </div>
      `;
    }

    function setLoading(wrapperEl, state) {
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("products-with-pagination-wrapper--preloader");
      } else {
        wrapperEl.classList.remove(
          "products-with-pagination-wrapper--preloader"
        );
      }
    }

    function render(
      type,
      total,
      page,
      perPage,
      products,
      categories,
      producers,
      detailsPath,
      loading,
      translations
    ) {
      const wrapperEl = document.querySelector(
        `#${this.id}.${this.className.split(" ").join(".")}`
      );
      if (!wrapperEl) return;
      setLoading(wrapperEl, loading);

      if (products.length) {
        const productsHtml = products.reduce(
          (tmp, product) =>
            tmp +
            makeProductHtml(
              product.id,
              product.image,
              product.name,
              product.short_description,
              product.children,
              product.cost,
              product.price_discount,
              product.discount_percent,
              product.coupon_discount_percent
                ? {
                    discountPercent: product.coupon_discount_percent,
                    date: {
                      start: product.metadata.coupon_start_date,
                      end: product.metadata.coupon_end_date
                    }
                  }
                : null,
              product.currency,
              detailsPath,
              type,
              product.is_active,
              translations
            ),
          ""
        );
        const paginationHtml = makePaginationHtml(page, total, perPage);

        wrapperEl.innerHTML = `
          <div class="products-with-pagination">
            ${productsHtml}
            ${paginationHtml}
          </div>  
        `;
      } else {
        wrapperEl.innerHTML = `
          <p style="margin: 0">
            <span class="products-with-pagination__empty">
              ${translations["No products..."]}
            </span>
          </p>
        `;
        return;
      }

      setInterval(
        () =>
          Array.prototype.slice
            .call(this.querySelectorAll(".pwp-coupon"))
            .map(couponEl => {
              const endDate = couponEl.dataset.dataEnd;
              if (!endDate) return false;

              const second = 1000;
              const minute = second * 60;
              const hour = minute * 60;
              const day = hour * 24;

              const nowDateObj = new Date();
              const endDateObj = new Date(endDate);
              const distance = endDateObj.getTime() - nowDateObj.getTime();

              const secondsCounterEl = couponEl.querySelector(
                ".pwp-coupon__counter--seconds"
              );
              const minutesCounterEl = couponEl.querySelector(
                ".pwp-coupon__counter--minutes"
              );
              const hoursCounterEl = couponEl.querySelector(
                ".pwp-coupon__counter--hours"
              );
              const daysCounterEl = couponEl.querySelector(
                ".pwp-coupon__counter--days"
              );

              const counters = {
                days: Math.floor(distance / day),
                hours: Math.floor((distance % day) / hour),
                minutes: Math.floor((distance % hour) / minute),
                seconds: Math.floor((distance % minute) / second)
              };
              // (1, 2) => "01", (52, 2) => "52"
              const numberToStringWithZeros = (number, count) =>
                Math.pow(10, count)
                  .toString()
                  .slice(1)
                  .concat(number)
                  .slice(-count);

              secondsCounterEl.innerText = numberToStringWithZeros(
                counters.seconds,
                2
              );
              minutesCounterEl.innerText = numberToStringWithZeros(
                counters.minutes,
                2
              );
              hoursCounterEl.innerText = numberToStringWithZeros(
                counters.hours,
                2
              );
              daysCounterEl.innerText = numberToStringWithZeros(
                counters.days,
                3
              );

              return counters;
            }),
        980
      );

      const paginationButtonsEls = this.querySelectorAll(
        ".pwp-pagination__button:not(.pwp-pagination__button--disabled)"
      );
      Array.prototype.slice.call(paginationButtonsEls).map(paginationButtonEl =>
        paginationButtonEl.addEventListener("click", e => {
          const btnPage = parseInt(e.target.dataset.page);
          window.products.setPage(btnPage);
        })
      );

      // const buyButtonsEls = this.querySelectorAll(
      //   ".products-with-pagination .pwp-product__button:not(.pwp-product__button--disabled)"
      // );
      // Array.prototype.slice.call(buyButtonsEls).map(buyButtonEl =>
      //   buyButtonEl.addEventListener("click", e => {
      //     const productId = parseInt(e.target.dataset.product_id);
      //     if (e.target.disabled) return;

      //     fetch(`${window.products.API_ROOT}/payments/deal/`, {
      //       method: "GET",
      //       headers: Object.assign(
      //               {
      //                 "Accept-Language": localStorage.getItem("language"),
      //                 "Content-Type": "application/json",
      //               },
      //               localStorage.getItem("token")
      //                 ? {Authorization: `Token ${localStorage.getItem("token")}`}
      //                 : {}
      //             )
      //     })
      //       .then(response => response.json())
      //       .then(json => {
      //         if (!json.length) {
      //           localStorage.removeItem("deal");
      //         } else {
      //           const deal = Object.assign({}, json[0], {
      //             total_cost: json
      //               .reduce((tmp, item) => tmp + parseFloat(item.total_cost), 0)
      //               .toFixed(2),
      //             order_items: json.reduce(
      //               (tmp, item) => tmp.concat(item.order_items),
      //               []
      //             )
      //           });

      //           if (
      //             deal.order_items.filter(product => product.id === productId)
      //               .length
      //           ) {
      //             e.target.innerText = translations["ADDED"];
      //             e.target.setAttribute("disabled", "true");
      //             e.target.classList.add("pwp-product__button--disabled");
      //             return;
      //           }
      //         }

      //         fetch(`${window.products.API_ROOT}/payments/cart/`, {
      //           method: "POST",
      //           headers: Object.assign(
      //               {
      //                 "Accept-Language": localStorage.getItem("language"),
      //                 "Content-Type": "application/json",
      //               },
      //               localStorage.getItem("token")
      //                 ? {Authorization: `Token ${localStorage.getItem("token")}`}
      //                 : {}
      //             ),
      //           cors: "cors",
      //           credentials: "omit",
      //           body: JSON.stringify({
      //             item_b2c: type === "b2c" ? productId : "",
      //             item_b2b: type === "b2b" ? productId : "",
      //             quantity: 1,
      //             customer_type: "person",
      //             customer_organization: ""
      //           })
      //         }).then(() => {
      //           e.target.innerText = translations["ADDED"];
      //           e.target.setAttribute("disabled", "true");
      //           e.target.classList.add("pwp-product__button--disabled");
      //         });
      //       });
      //   })
      // );

      const wishEls = this.querySelectorAll(
        ".products-with-pagination .pwp-product__wish:not(.pwp-product__wish--disabled)"
      );
      Array.prototype.slice.call(wishEls).map(wishEl =>
        wishEl.addEventListener("click", e => {
          const target = e.target.closest(".pwp-product__wish");
          const productId = parseInt(target.dataset.product_id);
          const inWishList = target.dataset.wish === "true";
          if (inWishList) return;

          fetch(`${window.products.API_ROOT}/wish_list/wish_list/`, {
            method: "POST",
            headers: Object.assign(
              {
                "Accept-Language": localStorage.getItem("language"),
                "Content-Type": "application/json"
              },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit",
            body: JSON.stringify({
              item_b2c: type === "b2c" ? productId : "",
              item_b2b: type === "b2b" ? productId : ""
            })
          }).then(() => {
            target.innerHTML = makeStarIcon(true, 45);
            target.setAttribute("data-wish", "true");
          });
        })
      );
    }

    if (!window.hasOwnProperty("products")) {
      const API_ROOT = "{[ apiRoot ]}";

      window.products = {
        API_ROOT: API_ROOT,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 10,
        type: "b2c",
        organization: "{[ organization ]}",
        params: {
          ordering: "id"
        },
        items: [],
        categories: [],
        producers: [],
        loading: false,
        translations: {},
        detailsPath: "#",
        render() {
          console.group("products render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.type,
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.categories,
              this.producers,
              this.detailsPath,
              this.loading,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("render");
        },
        isFuncInRenderCycle(newFunc) {
          return this.renderCycle.find(func => func === newFunc);
        },
        appendFuncToRenderCycle(newFunc) {
          if (!this.isFuncInRenderCycle(newFunc)) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", page, this.getProducts.bind(this));
        },
        setPerPage(count) {
          this.setProp("perPage", parseInt(count), this.getProducts.bind(this));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path);
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        removeParam(name) {
          if (this.params[name]) {
            delete this.params[name];
          }
        },
        getProducts(immediately) {
          if (this.loading && !immediately) return;
          this.setLoading(true);

          const params = new URLSearchParams(
            Object.assign(
              {},
              this.params,
              { page: this.page, page_size: this.perPage },
              {
                categories: this.getSelectedCategories()
                  .map(category => category.id)
                  .join()
              },
              {
                producer: this.getSelectedProducers()
                  .map(producer => producer.id)
                  .join()
              },
              {
                company: this.organization
              }
            )
          );
          return fetch(`${this.API_ROOT}/${this.type}/product/?${params}`, {
            method: "GET",
            headers: {
              "Accept-Language": localStorage.getItem("language")
            }
          })
            .then(response => response.json())
            .then(response =>
              this.setProducts(response.count, response.results)
            );
        },
        setProducts(count, items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.setLoading(false);
            this.render();
          }
          // Set count
          this.count = count;
          // Set items
          this.items = items;
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        getCategories(text, immediately) {
          if (this.loading && !immediately) return;
          this.setLoading(true);

          const currentParams = new URLSearchParams(window.location.search);
          const url = currentParams.has("gjs-products-category-parent")
            ? `${this.API_ROOT}/${
                this.type
              }/category_parent/${currentParams.get(
                "gjs-products-category-parent"
              )}/?search=${text}&company=${this.organization}`
            : `${this.API_ROOT}/${this.type}/category_parent/?search=${text}&company=${this.organization}`;

          return fetch(url, {
            method: "GET",
            headers: {
              "Accept-Language": localStorage.getItem("language")
            }
          })
            .then(response => response.json())
            .then(categories => {
              return fetch(
                `${this.API_ROOT}/${this.type}/category/?search=${text}&company=${this.organization}`,
                {
                  method: "GET",
                  headers: {
                    "Accept-Language": localStorage.getItem("language")
                  }
                }
              )
                .then(response => response.json())
                .then(subcategories =>
                  this.categories
                    .filter(
                      category =>
                        category.selected ||
                        category.children.find(
                          subcategory => subcategory.selected
                        )
                    )
                    .concat(
                      []
                        .concat(
                          categories.hasOwnProperty("results")
                            ? categories.results
                            : categories
                        )
                        .map(category => ({
                          id: category.id,
                          title: category.name,
                          selected: false,
                          children: subcategories
                            .filter(
                              subcategory => subcategory.parent === category.id
                            )
                            .map(subcategory => ({
                              id: subcategory.id,
                              title: subcategory.name,
                              selected: false
                            }))
                        }))
                    )
                    .reduce(
                      (tmp, category) =>
                        tmp.find(i => i.id === category.id)
                          ? tmp
                          : tmp.concat([category]),
                      []
                    )
                )
                .then(categories => this.setCategories(categories));
            });
        },
        setCategories(categories) {
          // Reset page
          this.page = 1;
          // Set categories
          const sortByAlphabet = field => (a, b) =>
            a[field] > b[field] ? 1 : -1;
          this.categories = categories
            .map(category =>
              Object.assign({}, category, {
                children: category.children.sort(sortByAlphabet("title"))
              })
            )
            .sort(sortByAlphabet("title"));
          // Start render cycle
          this.setLoading(false);
          this.getProducts();
        },
        getSelectedCategories() {
          const selectedCategories = this.categories.reduce(
            (tmp, category) =>
              tmp
                .concat(category.selected ? category : [])
                .concat(
                  category.children.filter(subcategory => subcategory.selected)
                ),
            []
          );

          const currentParams = new URLSearchParams(window.location.search);
          return selectedCategories.length
            ? selectedCategories
            : [{ id: currentParams.get("gjs-products-category-parent") }];
        },
        getProducers(text, immediately) {
          if (this.loading && !immediately) return;
          this.setLoading(true);

          const currentParams = new URLSearchParams(window.location.search);
          const param = "gjs-products-category";

          const categoryType = {};
          categoryType[`${this.type}_categories`] = currentParams.has(param)
            ? currentParams.get(param)
            : "";

          const requestParams = new URLSearchParams(
            Object.assign(
              {},
              {
                search: text,
                company: this.organization,
                product_type: this.type
              },
              currentParams.has(param) ? categoryType : {}
            )
          );

          return fetch(
            `${this.API_ROOT}/${this.type}/producer/?${requestParams}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(response =>
              this.setProducers(
                this.producers
                  .filter(producer => producer.selected)
                  .concat(
                    []
                      .concat(
                        response.hasOwnProperty("results")
                          ? response.results
                          : response
                      )
                      .map(producer => ({
                        id: producer.id,
                        title: producer.name,
                        selected: false
                      }))
                  )
                  .reduce(
                    (tmp, producer) =>
                      tmp.find(i => i.id === producer.id)
                        ? tmp
                        : tmp.concat([producer]),
                    []
                  )
              )
            );
        },
        setProducers(producers) {
          // Reset page
          this.page = 1;
          // Set producers
          const sortByAlphabet = field => (a, b) =>
            a[field] > b[field] ? 1 : -1;
          this.producers = producers.sort(sortByAlphabet("title"));
          // Start render cycle
          this.setLoading(false);
          this.getProducts();
        },
        getSelectedProducers() {
          return this.producers.filter(producer => producer.selected);
        },
        search(text) {
          if (this.loading) return;

          // Reset page
          this.page = 1;
          // Set search=query param in b2c/b2b products request
          this.setParam("search", text);
          this.removeParam("color");
          this.removeParam("size");
          this.removeParam("season");
          // Get products
          this.getProducts();
        },
        sortby(sortby) {
          if (this.loading) return;

          // Reset page
          this.page = 1;
          // Set search=query param in b2c/b2b products request
          this.setParam("ordering", sortby);
          // Get products
          this.getProducts();
        },
        filterby(type, value) {
          if (this.loading) return;

          // Reset page
          this.page = 1;
          // Set search=query param in b2c/b2b products request
          this.setParam(type, value);
          // Get products
          this.getProducts();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty("products") &&
      window.hasOwnProperty("grapesjs")
    ) {
      if (!window.products.renderCycle.find(func => func === render)) {
        window.products.renderCycle.push(render.bind(this));
      }

      if (attributes.producttype) {
        window.products.setType(attributes.producttype.value);
      }

      if (attributes.perpage) {
        window.products.setPerPage(attributes.perpage.value);
      }

      if (attributes.detailspage) {
        window.products.setDetailsPath(attributes.detailspage.value);
      }

      const translations = getTranslate("translations", attributes);
      window.products.setProp("translations", translations);

      const currentParams = new URLSearchParams(window.location.search);
      const categoryId = parseInt(
        currentParams.get("gjs-products-category-parent")
      );
      const subcategoryId = parseInt(
        currentParams.get("gjs-products-category")
      );
      if (categoryId) {
        window.products.getCategories("", true).then(() => {
          const categories = window.products.categories;
          window.products.setCategories(
            categories.map(category =>
              category.id === categoryId
                ? Object.assign({}, category, {
                    selected: !subcategoryId || subcategoryId === categoryId,
                    children: subcategoryId
                      ? category.children.map(subcategory =>
                          subcategory.id === subcategoryId
                            ? Object.assign({}, subcategory, { selected: true })
                            : subcategory
                        )
                      : category.children
                  })
                : category
            )
          );
        });
      }

      window.products.getProducts();
    }
  };

  editor.DomComponents.addType("products-with-pagination", {
    isComponent: el =>
      el.tagName === "DIV" &&
      el.className === "products-with-pagination-wrapper",
    model: {
      defaults: {
        tagName: "div",
        name: "Products With Pagination",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "number",
            label: "Products per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "No products...",
              "No description",
              "Cost:",
              "N/A",
              "VIEW",
              "ADDED",
              "ILS",
              "Hurry up! Coupon Ends in"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(this.model, "change:attributes:producttype", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });

  editor.DomComponents.addType("products-with-pagination__grid", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-with-pagination",
    model: {
      defaults: {
        tagName: "div",
        name: "Products Grid",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-with-pagination__product", {
    isComponent: el => el.tagName === "DIV" && el.className === "pwp-product",
    model: {
      defaults: {
        tagName: "div",
        name: "Product",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-with-pagination__pagination", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "pwp-pagination",
    model: {
      defaults: {
        tagName: "div",
        name: "Products Pagination",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });
}
