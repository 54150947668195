import { WishListTypes } from "./wish-list.types";
import { axiosInstance } from "../../../axios/axios.config";

export const getWishList = () => dispatch => {
  dispatch({
    type: WishListTypes.GET.GET_WISH_LIST_PENDING
  });
  axiosInstance
    .get(`/api/v1/wish_list/wish_list/`)
    .then(wishList => {
      dispatch({
        type: WishListTypes.GET.GET_WISH_LIST_SUCCESS,
        payload: wishList.data.results
      });
    })
    .catch(err => {
      dispatch({
        type: WishListTypes.GET.GET_WISH_LIST_ERROR,
        payload: err
      });
    });
};

export const addProductToWishList = (itemType, itemData) => dispatch => {
  dispatch({ type: WishListTypes.POST.ADD_PRODUCT_TO_WISH_LIST_PENDING });

  axiosInstance
    .post(`/api/v1/wish_list/wish_list/`, {
      [itemType]: itemData.id
    })
    .then(addedProductToWishlist => {
      dispatch({
        type: WishListTypes.POST.ADD_PRODUCT_TO_WISH_LIST_SUCCESS,
        payload: addedProductToWishlist.data
      });
    })
    .catch(err => {
      dispatch({
        type: WishListTypes.POST.ADD_PRODUCT_TO_WISH_LIST_ERROR,
        payload: err.data
      });
    });
};

export const deleteProductFromWishList = productId => dispatch => {
  dispatch({
    type: WishListTypes.DELETE.DELETE_PRODUCT_FROM_WISH_LIST_PENDING
  });

  axiosInstance
    .delete(`/api/v1/wish_list/wish_list/${productId}`)
    .then(deleteProduct => {
      dispatch({
        type: WishListTypes.DELETE.DELETE_PRODUCT_FROM_WISH_LIST_SUCCESS,
        payload: { deleteProduct, productId }
      });
    })
    .catch(err => {
      dispatch({
        type: WishListTypes.DELETE.DELETE_PRODUCT_FROM_WISH_LIST_ERROR,
        payload: err
      });
    });
};
