import productsTypeSwitchBlock from "./block";
import productsTypeSwitchComponent from "./component";

export default ({
  editor,
  api,
  site,
  className = "gjs-products-type-switch"
}) => {
  // Init block
  productsTypeSwitchBlock({ editor, className });
  // Init component
  productsTypeSwitchComponent({ editor, api, site, className });
};
