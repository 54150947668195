import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ProductCarousel } from "../../../components";

import {
  getRecommendedB2CProducts,
  getB2CProducts
} from "../../../redux/actions-exporter";

import "./wall-product-carousel.styles.scss";

const WallProductCarousel = ({
  getRecommendedB2CProducts,
  getB2CProducts,
  areRecommendedB2CProductsPending,
  areB2CProductsPending,
  recommendedB2CProducts,
  recommendedB2CProductsCount,
  recommendedB2CProductsNamespaces,
  recommendedB2CProductsError,
  b2cProducts,
  b2cProductsCount,
  b2cProductsNamespaces,
  b2cProductsError,
  countryId,
  title,
  icon,
  type,
  props
}) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    loading: true,
    perPage: 5,
    page: 1,
    params: {},
    products: [],
    getProducts: () => {}
  });
  const { loading, products, params, perPage, page, getProducts } = data;
  const isB2CProductsRequest = type === "featured" || type === "sales";
  const namespace = `wallProductCarousel${type.toUpperCase()}`;

  useEffect(() => {
    if (
      (products && products.length) ||
      areB2CProductsPending ||
      areRecommendedB2CProductsPending ||
      recommendedB2CProductsError ||
      b2cProductsError ||
      isLoading
    ) {
      return;
    }

    const isValidProducts = isB2CProductsRequest
      ? b2cProductsNamespaces[namespace] &&
        JSON.stringify(b2cProductsNamespaces[namespace].params) ===
          JSON.stringify(params)
      : recommendedB2CProductsNamespaces[namespace] &&
        JSON.stringify(recommendedB2CProductsNamespaces[namespace].params) ===
          JSON.stringify(params);

    switch (type) {
      case "sales":
        setData({
          ...data,
          loading: isValidProducts ? areB2CProductsPending : true,
          params: {
            page,
            perPage,
            countryId,
            discountPercentGt: 0,
            couponDiscountPercentGt: 0
          },
          products: isValidProducts
            ? b2cProductsNamespaces[namespace].products
            : [],
          getProducts: getB2CProducts
        });
        break;

      case "ai":
        setData({
          ...data,
          loading: isValidProducts ? areRecommendedB2CProductsPending : true,
          params: { perPage, page },
          products: isValidProducts ? recommendedB2CProducts : [],
          getProducts: getRecommendedB2CProducts
        });
        break;

      case "featured":
        setData({
          ...data,
          loading: isValidProducts ? areB2CProductsPending : true,
          params: { perPage, page, countryId, isRecommended: true },
          products: isValidProducts
            ? b2cProductsNamespaces[namespace].products
            : [],
          getProducts: getB2CProducts
        });
        break;

      default:
        break;
    }

    if (!isValidProducts) {
      getProducts({ ...params, namespace, additional: { params } });
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    b2cProducts,
    recommendedB2CProducts,
    areB2CProductsPending,
    areRecommendedB2CProductsPending,
    isLoading
  ]);

  return (
    <ProductCarousel
      icon={icon}
      title={title}
      products={products}
      loading={loading}
      page={page}
      navProductType="b2c"
      getProducts={newPage => {
        const totalCount = isB2CProductsRequest
          ? b2cProductsCount
          : recommendedB2CProductsCount;
        const totalPages = Math.ceil(totalCount / perPage);
        const validPage = newPage > 0 && totalPages >= newPage ? newPage : 1;

        setData({
          ...data,
          products: [],
          loading: true,
          params: { ...params, page: validPage },
          page: validPage
        });
      }}
      props={props}
    />
  );
};

const mapStateToProps = state => ({
  countryId: state.countriesReducer.countryId,
  areRecommendedB2CProductsPending:
    state.b2cProductsReducer.areRecommendedB2CProductsPending,
  recommendedB2CProducts: state.b2cProductsReducer.recommendedB2CProducts,
  recommendedB2CProductsCount:
    state.b2cProductsReducer.recommendedB2CProductsCount,
  recommendedB2CProductsNamespaces:
    state.b2cProductsReducer.recommendedB2CProductsNamespaces,
  recommendedB2CProductsError:
    state.b2cProductsReducer.recommendedB2CProductsError,
  b2cProductsNamespaces: state.b2cProductsReducer.b2cProductsNamespaces,
  areB2CProductsPending: state.b2cProductsReducer.areB2CProductsPending,
  b2cProducts: state.b2cProductsReducer.b2cProducts,
  b2cProductsCount: state.b2cProductsReducer.b2cProductsCount,
  b2cProductsError: state.b2cProductsReducer.b2cProductsError
});

export default connect(mapStateToProps, {
  getRecommendedB2CProducts,
  getB2CProducts
})(WallProductCarousel);
