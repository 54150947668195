import { PaginationActionTypes } from "./pagination.types";

const INITIAL_STATE = {
  selectedPageIdx: 1,
  selectedCompPageIdx: 1,
  selectedOrgPageIdx: 1,
  selectedProductPageIdx: 1,
  selectedB2BProductPageIdx: 1,
  selectedB2CProductPageIdx: 1
};

const PaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaginationActionTypes.SELECT_COMP_PAGE:
      return {
        ...state,
        selectedCompPageIdx: action.payload
      };
    case PaginationActionTypes.SELECT_ORG_PAGE:
      return {
        ...state,
        selectedOrgPageIdx: action.payload
      };
    case PaginationActionTypes.SELECT_B2B_PRODUCT_PAGE:
      return {
        ...state,
        selectedB2BProductPageIdx: action.payload
      };
    case PaginationActionTypes.SELECT_B2C_PRODUCT_PAGE:
      return {
        ...state,
        selectedB2CProductPageIdx: action.payload
      };
    default:
      return state;
  }
};

export default PaginationReducer;
