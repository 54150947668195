import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";

import { getNewsById } from "../../redux/APIs/news/news.action";

import Card from "@material-ui/core/Card";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

import { colorPalette } from "../../styles/colorPalette";
import "./selected-news.styles.scss";

const SelectedNews = ({ getNewsById, areNewsByIdPending, newsById }) => {
  const { newsId } = useParams();

  useEffect(() => {
    getNewsById(newsId);
  }, [getNewsById, newsId]);

  const { title, content } = newsById;

  return (
    !areNewsByIdPending && (
      <div className="selected-news">
        <Card>
          <div className="selected-news__title selected-news__padding">
            {title}!
          </div>
          <div className="selected-news__description selected-news__padding">
            {content && parse(content)}
          </div>
          <div className="selected-news__separator" />
          <div className="selected-news__metadata selected-news__padding">
            <div className="selected-news__flex selected-news__metadata__left-container">
              <div>Publicated: 18.09.2019</div>
              <VisibilityIcon style={{ fill: `${colorPalette.grayText}` }} />
              <div>325</div>
            </div>
            <div className="selected-news__flex">
              <div className="selected-news__metadata__social-link selected-news__flex">
                <FacebookIcon style={{ fill: `${colorPalette.facebook}` }} />
              </div>
              <div className="selected-news__metadata__social-link selected-news__flex">
                <LinkedInIcon style={{ fill: `${colorPalette.linkedin}` }} />
              </div>
              <div className="selected-news__metadata__social-link selected-news__flex">
                <TwitterIcon style={{ fill: `${colorPalette.twitter}` }} />
              </div>
            </div>
          </div>
          <div className="selected-news__comment-count selected-news__padding">
            1 Comment
          </div>
          {/* <div className="selected-news__facebook-plugin">In production</div> */}
        </Card>
        {/* <div className="selected-news__popular-news">
          <PopularNews />
        </div> */}
      </div>
    )
  );
};

const mapStateToProps = state => ({
  areNewsByIdPending: state.newsReducer.areNewsByIdPending,
  newsById: state.newsReducer.newsById
});

export default connect(mapStateToProps, { getNewsById })(SelectedNews);
