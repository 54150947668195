import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { withDebounce } from "../../../shared";

import {
  EventCard,
  Pagination,
  SearchBar,
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import {
  getEventsByMe,
  deleteEvent
} from "../../../redux/APIs/events/events.action";

import "./my-business-events.styles.scss";

const MyBusinessEvents = ({
  getEventsByMe,
  eventsByMe,
  eventsByMeCount,
  areEventsByMePending,
  deleteEvent
}) => {
  const [eventSearch, setEventSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(0);

  useEffect(() => {
    getEventsByMe(eventSearch, currentPage);
  }, [getEventsByMe, eventSearch, currentPage]);

  const eventSearchHandler = withDebounce(e => setEventSearch(e));

  return (
    <div className="my-business-events">
      <ConfirmDialog
        dialogTitle="Event remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteEvent(currentEventId);
          setDialogOpen(false);
          setCurrentEventId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => eventSearchHandler(e.target.value)} />
      </div>
      {areEventsByMePending ? (
        <LoadingSpinner />
      ) : eventsByMe && eventsByMe.length ? (
        eventsByMe.map(({ id, organization, country, title, created_at }) => (
          <EventCard
            key={id}
            img={true}
            imgSrc={
              organization && organization.logo
                ? organization.logo
                : "https://via.placeholder.com/160x90"
            }
            country={country && country.name}
            title={title}
            publicated={true}
            publicatedDate={moment(created_at).format("DD MMMM YYYY")}
            publicatedCompany={organization && organization.name}
            editorState={true}
            editLink={`/my-business/events/${id}`}
            viewLiveUrl={`/events/event/${id}/company/${organization.id}`}
            removeFunc={() => {
              setDialogOpen(true);
              setCurrentEventId(id);
            }}
          />
        ))
      ) : (
        <MyBusinessNoData noDataText="No events found!" />
      )}
      <Pagination
        dataCount={eventsByMeCount}
        itemsPerPage={10}
        selectedPage={page => setCurrentPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areEventsByMePending: state.events.areEventsByMePending,
  eventsByMe: state.events.eventsByMe,
  eventsByMeCount: state.events.eventsByMeCount
});

export default connect(mapStateToProps, {
  getEventsByMe,
  deleteEvent
})(MyBusinessEvents);
