import React from "react";

import { ReactComponent as Recommendation } from "../../assets/icons/Icon_Recommendation.svg";
import { ReactComponent as Coupon } from "../../assets/icons/Icon_Coupon.svg";
import { ReactComponent as HistoryOrders } from "../../assets/icons/Icon_My_History_Orders.svg";
import { ReactComponent as WeRecommend } from "../../assets/icons/Icon_We_Recommend.svg";
import { ReactComponent as WishList } from "../../assets/icons/Icon_My_Wish_List.svg";

export const tabsData = [
  {
    title: "Last Orders",
    icon: <HistoryOrders width="20px" height="20px" />,
    tabUrl: "last-orders"
  },
  {
    title: "My Wish List",
    icon: <WishList width="20px" height="20px" />,
    tabUrl: "wish-list"
  },
  {
    title: "AI Recommendation",
    icon: <Recommendation width="20px" height="20px" />,
    tabUrl: "ai-recommendation"
  },
  {
    title: "We Recommend",
    icon: <WeRecommend width="20px" height="20px" />,
    tabUrl: "we-recommend"
  },
  {
    title: "Sales & Coupon",
    icon: <Coupon width="20px" height="20px" />,
    tabUrl: "coupon"
  }
];
