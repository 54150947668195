import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import {
  LoadingSpinner,
  MyBusinessNoData,
  SearchBar,
  Pagination,
  DefaultCompanyLink
} from "@core/components";

import { getCompaniesByUserId } from "@core/redux/store/companies/companies.action"

import "./my-business-companies-for-categories.styles.scss";
import { useTranslation } from "react-i18next";

import { scrollToRef, withDebounce } from "@core/shared";

const MyBusinessCompaniesForCategories = ({
  companiesByUserPending,
  getCompaniesByUserId,
  companiesByUserId,
  companiesByUserIdCount
}) => {
  const { t } = useTranslation();

  const [searchCompanies, setSearchCompanies] = useState("");
  const [companiesPage, setCompaniesPage] = useState(1);
  const companyRef = useRef(null);

  useEffect(() => {
    getCompaniesByUserId(searchCompanies, companiesPage);
  }, [getCompaniesByUserId, searchCompanies, companiesPage]);
  useEffect(() => {
    setCompaniesPage(1);
  }, [searchCompanies]);
  const searchCompaniesHandler = withDebounce(e => setSearchCompanies(e));

  return (
    <div className="my-business-tasks">
      {companiesByUserPending ? (
        <LoadingSpinner />
      ) : (
      <>
        <div className="my-business-companies">
          <div className="company-department-container">
            <div
              ref={companyRef}
              className="company-department-container__title"
            >
              {t("Companies")}
            </div>
            <div style={{margin: 20}}>
              <SearchBar
                onChange={e => searchCompaniesHandler(e.target.value)}
              />
            </div>
            {companiesByUserId && companiesByUserId.length ? (
              companiesByUserId.map((company, index) => (
                <DefaultCompanyLink
                  typeOfCompany={"Company"}
                  key={index}
                  currentCompany={company}
                  link="/my-business/categories/company"
                />
              ))
            ) : (
              <MyBusinessNoData noDataText={t("No companies found!")}/>
            )}
          </div>
          <Pagination
            forcePage={companiesPage - 1}
            dataCount={companiesByUserIdCount}
            itemsPerPage={10}
            selectedPage={page => {
              setCompaniesPage(page);
              scrollToRef(companyRef.current.offsetTop);
            }}
          />
        </div>
      </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  companiesByUserPending: state.companiesReducer.areCompaniesByUserIdPending,
  companiesByUserId: state.companiesReducer.companiesByUserId,
  companiesByUserIdCount: state.companiesReducer.companiesByUserIdCount
});

export default connect(mapStateToProps, {
  getCompaniesByUserId
})(MyBusinessCompaniesForCategories);
