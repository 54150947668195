import React, { Fragment } from "react";
import { SketchPicker } from "react-color";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";

import { ParentWrapper, ButtonsFooterWrapper } from "../../layout";
import { InputGroup, DefaultButton, Separator } from "../../components";

import "./product-item-sizes.styles.scss";

const ProductItemSizes = ({
  productId,
  productType,
  productSizes,
  productSize,
  setProductSize,
  deleteSizeById,
  uploadProductSize,
  itemColor,
  setItemColor
}) => {
  return (
    <Fragment>
      <div className={`my-business__product-sizes-files`}>
        <h3 style={{ margin: 0 }}>PRODUCT SIZES & COLOR</h3>
        {productSizes && productSizes.length ? (
          <Table aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Size</TableCell>
                <TableCell align="center">Color</TableCell>
                {/* <TableCell align="center">Edit</TableCell> */}
                <TableCell align="center">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productSizes &&
                productSizes.map(size => (
                  <TableRow key={size.id}>
                    <TableCell align="center">
                      <div className="table-div-content">
                        {size.size.length > 40
                          ? size.size.substring(0, 40) + "..."
                          : size.size}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        className="table-div-content"
                        style={{
                          backgroundColor: size.value,
                          height: 25,
                          width: 25,
                          borderRadius: 50
                        }}
                      />
                    </TableCell>

                    {/* <TableCell align="center">
                <Link to="/">
                  <DefaultIconButton>
                    <EditIcon />
                  </DefaultIconButton>
                </Link>
              </TableCell> */}
                    <TableCell align="center">
                      <DefaultButton onClick={() => deleteSizeById(size)}>
                        <ClearIcon />
                      </DefaultButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <h3>No sizes...</h3>
        )}
      </div>
      <Separator />
      <div className={`my-business__product-sizes `}>
        <h3>ADD SIZE & COLOR</h3>
        <ParentWrapper>
          <InputGroup
            label="PRODUCT SIZE"
            name="productSize"
            placeholder="Product size"
            onChange={e => setProductSize(e.target.value)}
            value={productSize}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SketchPicker
              color={itemColor}
              onChangeComplete={e => setItemColor(e.hex)}
            />
          </div>
        </ParentWrapper>
      </div>
      <ButtonsFooterWrapper>
        <div style={{ width: "33%" }}>
          <DefaultButton
            onClick={() =>
              uploadProductSize(productSize, itemColor, productType, productId)
            }
            color="gray"
            width="100%"
            border="1px solid lightgray"
            borderRadius={0}
            disabled={productSize === "" || itemColor === ""}
          >
            Add size
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </Fragment>
  );
};

export default ProductItemSizes;
