import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";

import { LeftArrow, RightArrow } from "../../assets";
import { arrToString } from "../../shared";
import { _skip } from "../../constants";
import { ImgCard, Button, LoadingSpinner } from "../../components";

import { getNews } from "../../redux/actions-exporter";

import "./popular-news.styles.scss";

const PopularNews = ({
  isNewsPending,
  news,
  getNews,
  localizationData,
  filterCountriesArr,
}) => {
  const [, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    getNews(_skip, _skip, arrToString(filterCountriesArr));

    const getWindow = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", getWindow);
  }, [filterCountriesArr, getNews]);

  // const [slide, setSlide] = useState(3);
  const [slideMobile, setSlideMobile] = useState(1);

  // const slideLeft = () => {
  //   if (slide >= 6) {
  //     setSlide(slide - 3);
  //   }
  // };

  // const slideRight = () => {
  //   if (slide <= 6) {
  //     setSlide(slide + 3);
  //   }
  // };

  const slideLeftMobile = () => {
    if (slideMobile <= 1) {
      setSlideMobile(news.length);
    } else {
      setSlideMobile(slideMobile - 1);
    }
  };

  const slideRightMobile = () => {
    if (slideMobile >= news.length) {
      setSlideMobile(1);
    } else {
      setSlideMobile(slideMobile + 1);
    }
  };

  return (
    <div className="popular-news">
      <div className="popular-news-header">
        <p className="popular-news-title">
          {localizationData["Most popular news"] || "Most popular news"}
        </p>
        <div>
          <Button func={slideLeftMobile} mod="arrow">
            <LeftArrow />
          </Button>
          <Button func={slideRightMobile} mod="arrow">
            <RightArrow />
          </Button>
        </div>
      </div>
      <div className="popular-news-body">
        {isNewsPending ? (
          <LoadingSpinner />
        ) : (
          news.results &&
          news.results
            .filter((news, idx) => idx < slideMobile && idx >= slideMobile - 1)
            .map(({ id, title, image }) => (
              <ImgCard
                navLink={`/news/${id}`}
                key={id}
                text={parse(title)}
                imgSrc={image}
                type="column"
              />
            ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  news: state.newsReducer.news,
  isNewsPending: state.newsReducer.isNewsPending,
  localizationData: state.appLanguage.localizationData,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
});

export default connect(mapStateToProps, { getNews })(PopularNews);
