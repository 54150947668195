import React from "react";
import { Link } from "react-router-dom";

import { PlusIcon } from "../../assets";

import "./product-field-header.styles.scss";

const ProductFieldHeader = ({ sectionTitle, addNewTitle, sectionPath }) => {
  return (
    <div className="product-field-header">
      <div className="product-field-header__title">{sectionTitle}</div>
      <div className="product-field-header__add-new">
        <Link to={sectionPath}>
          <PlusIcon />
          <div>{addNewTitle}</div>
        </Link>
      </div>
    </div>
  );
};

export default ProductFieldHeader;
