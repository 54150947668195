export default {
  modalImportTitle: "Import Template",
  modalImportLabel:
    '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
  modalImportContent: editor => `
    ${editor.getHtml()}
    <style>
    ${editor.getCss()}
    </style>
  `,
  filestackOpts: null,
  aviaryOpts: false,
  blocksBasicOpts: {
    flexGrid: true,
    blocks: [
      "column1",
      "column2",
      "column3",
      "column3-7",
      "text",
      "link",
      "image",
      "map"
    ]
  },
  customStyleManager: [
    {
      name: "General",
      open: false,
      buildProps: [
        "float",
        "display",
        "position",
        "top",
        "right",
        "left",
        "bottom"
      ],
      properties: [
        {
          name: "Alignment",
          property: "float",
          type: "radio",
          defaults: "none",
          list: [
            { value: "left", className: "fa fa-align-left" },
            { value: "none", className: "fa fa-times" },
            { value: "right", className: "fa fa-align-right" }
          ]
        },
        { property: "position", type: "select" }
      ]
    },
    {
      name: "Dimension",
      open: false,
      buildProps: [
        "width",
        "flex-width",
        "height",
        "min-width",
        "min-height",
        "max-width",
        "max-height",
        "margin",
        "padding"
      ],
      properties: [
        {
          id: "flex-width",
          type: "integer",
          name: "Width",
          units: ["px", "%"],
          property: "flex-basis",
          toRequire: 1
        },
        {
          property: "margin",
          properties: [
            { name: "Top", property: "margin-top" },
            { name: "Right", property: "margin-right" },
            { name: "Bottom", property: "margin-bottom" },
            { name: "Left", property: "margin-left" }
          ]
        },
        {
          property: "padding",
          properties: [
            { name: "Top", property: "padding-top" },
            { name: "Right", property: "padding-right" },
            { name: "Bottom", property: "padding-bottom" },
            { name: "Left", property: "padding-left" }
          ]
        }
      ]
    },
    {
      name: "Typography",
      open: false,
      buildProps: [
        "font-family",
        "font-size",
        "font-weight",
        "letter-spacing",
        "color",
        "line-height",
        "text-transform",
        "text-align",
        "text-decoration",
        "text-shadow"
      ],
      properties: [
        { name: "Font", property: "font-family" },
        { name: "Weight", property: "font-weight" },
        { name: "Font color", property: "color" },
        {
          type: "select",
          property: "text-transform",
          name: "Text transform",
          list: [
            { value: "none", name: "None" },
            { value: "capitalize", name: "Capitalize" },
            { value: "uppercase", name: "Uppercase" },
            { value: "lowercase", name: "Lowercase" },
            { value: "initial", name: "Initial" },
            { value: "inherit", name: "Inherit" }
          ]
        },
        {
          property: "text-align",
          type: "radio",
          defaults: "left",
          list: [
            { value: "left", name: "Left", className: "fa fa-align-left" },
            {
              value: "center",
              name: "Center",
              className: "fa fa-align-center"
            },
            { value: "right", name: "Right", className: "fa fa-align-right" },
            {
              value: "justify",
              name: "Justify",
              className: "fa fa-align-justify"
            }
          ]
        },
        {
          property: "text-decoration",
          type: "radio",
          defaults: "none",
          list: [
            { value: "none", name: "None", className: "fa fa-times" },
            {
              value: "underline",
              name: "underline",
              className: "fa fa-underline"
            },
            {
              value: "line-through",
              name: "Line-through",
              className: "fa fa-strikethrough"
            }
          ]
        },
        {
          property: "text-shadow",
          properties: [
            { name: "X position", property: "text-shadow-h" },
            { name: "Y position", property: "text-shadow-v" },
            { name: "Blur", property: "text-shadow-blur" },
            { name: "Color", property: "text-shadow-color" }
          ]
        }
      ]
    },
    {
      name: "Decorations",
      open: false,
      buildProps: ["opacity", "object-fit", "box-shadow"],
      properties: [
        {
          type: "slider",
          property: "opacity",
          defaults: 1,
          step: 0.01,
          max: 1,
          min: 0
        },
        {
          type: "select",
          property: "object-fit",
          name: "Object fit",
          full: true,
          list: [
            { value: "none", name: "None" },
            { value: "cover", name: "Cover" },
            { value: "contain", name: "Contain" },
            { value: "fill", name: "Fill" },
            { value: "scale-down", name: "Scale down" }
          ]
        },
        {
          property: "box-shadow",
          properties: [
            { name: "X position", property: "box-shadow-h" },
            { name: "Y position", property: "box-shadow-v" },
            { name: "Blur", property: "box-shadow-blur" },
            { name: "Spread", property: "box-shadow-spread" },
            { name: "Color", property: "box-shadow-color" },
            { name: "Shadow type", property: "box-shadow-type" }
          ]
        }
      ]
    },
    {
      name: "Borders",
      open: false,
      buildProps: [
        "border-radius",
        "border-top-color",
        "border-top-width",
        "border-top-style",

        "border-bottom-color",
        "border-bottom-width",
        "border-bottom-style",

        "border-left-color",
        "border-left-width",
        "border-left-style",

        "border-right-color",
        "border-right-width",
        "border-right-style"
      ],
      properties: [
        {
          property: "border-radius",
          properties: [
            { name: "Top Left", property: "border-top-left-radius" },
            { name: "Top Right", property: "border-top-right-radius" },
            { name: "Bottom Left", property: "border-bottom-left-radius" },
            { name: "Bottom Right", property: "border-bottom-right-radius" }
          ]
        },
        {
          type: "color",
          property: "border-top-color",
          name: "Top color"
        },
        {
          type: "integer",
          units: ["px", "%", ""],
          unit: "",
          defaults: "0",
          name: "Top width",
          property: "border-top-width"
        },
        {
          type: "select",
          defaults: "none",
          list: [
            { value: "none", name: "None" },
            { value: "solid", name: "Solid" },
            { value: "dotted", name: "Dotted" },
            { value: "dashed", name: "Dashed" },
            { value: "double", name: "Double" },
            { value: "groove", name: "Groove" },
            { value: "ridge", name: "Ridge" },
            { value: "inset", name: "Inset" },
            { value: "outset", name: "Outset" }
          ],
          name: "Top style",
          property: "border-top-style"
        },

        {
          type: "color",
          property: "border-bottom-color",
          name: "Bottom color"
        },
        {
          type: "integer",
          units: ["px", "%", ""],
          unit: "",
          defaults: "0",
          name: "Bottom width",
          property: "border-bottom-width"
        },
        {
          type: "select",
          defaults: "none",
          list: [
            { value: "none", name: "None" },
            { value: "solid", name: "Solid" },
            { value: "dotted", name: "Dotted" },
            { value: "dashed", name: "Dashed" },
            { value: "double", name: "Double" },
            { value: "groove", name: "Groove" },
            { value: "ridge", name: "Ridge" },
            { value: "inset", name: "Inset" },
            { value: "outset", name: "Outset" }
          ],
          name: "Top style",
          property: "border-bottom-style"
        },

        {
          type: "color",
          property: "border-left-color",
          name: "Left color"
        },
        {
          type: "integer",
          units: ["px", "%", ""],
          unit: "",
          defaults: "0",
          name: "Left width",
          property: "border-left-width"
        },
        {
          type: "select",
          defaults: "none",
          list: [
            { value: "none", name: "None" },
            { value: "solid", name: "Solid" },
            { value: "dotted", name: "Dotted" },
            { value: "dashed", name: "Dashed" },
            { value: "double", name: "Double" },
            { value: "groove", name: "Groove" },
            { value: "ridge", name: "Ridge" },
            { value: "inset", name: "Inset" },
            { value: "outset", name: "Outset" }
          ],
          name: "Left style",
          property: "border-left-style"
        },

        {
          type: "color",
          property: "border-right-color",
          name: "Right color"
        },
        {
          type: "integer",
          units: ["px", "%", ""],
          unit: "",
          defaults: "0",
          name: "Right width",
          property: "border-right-width"
        },
        {
          type: "select",
          defaults: "none",
          list: [
            { value: "none", name: "None" },
            { value: "solid", name: "Solid" },
            { value: "dotted", name: "Dotted" },
            { value: "dashed", name: "Dashed" },
            { value: "double", name: "Double" },
            { value: "groove", name: "Groove" },
            { value: "ridge", name: "Ridge" },
            { value: "inset", name: "Inset" },
            { value: "outset", name: "Outset" }
          ],
          name: "Right style",
          property: "border-right-style"
        }
      ]
    },
    {
      name: "Extra",
      open: false,
      buildProps: ["transition", "perspective", "z-index", "transform"],
      properties: [
        {
          property: "transition",
          properties: [
            { name: "Property", property: "transition-property" },
            { name: "Duration", property: "transition-duration" },
            { name: "Easing", property: "transition-timing-function" }
          ]
        },
        {
          property: "z-index",
          name: "Z-Index",
          type: "integer",
          defaults: 0
        },
        {
          property: "transform",
          properties: [
            { name: "Rotate X", property: "transform-rotate-x" },
            { name: "Rotate Y", property: "transform-rotate-y" },
            { name: "Rotate Z", property: "transform-rotate-z" },
            { name: "Scale X", property: "transform-scale-x" },
            { name: "Scale Y", property: "transform-scale-y" },
            { name: "Scale Z", property: "transform-scale-z" }
          ]
        },
        {
          type: "select",
          defaults: "initial",
          list: [
            { value: "initial", name: "initial" },
            { value: "inherit", name: "inherit" },
            { value: "auto", name: "auto" },
            { value: "scroll", name: "scroll" },
            { value: "hidden", name: "hidden" },
            { value: "visible", name: "visible" }
          ],
          name: "Overflow-X",
          property: "overflow-x"
        },
        {
          type: "select",
          defaults: "initial",
          list: [
            { value: "initial", name: "initial" },
            { value: "inherit", name: "inherit" },
            { value: "auto", name: "auto" },
            { value: "scroll", name: "scroll" },
            { value: "hidden", name: "hidden" },
            { value: "visible", name: "visible" }
          ],
          name: "Overflow-Y",
          property: "overflow-y"
        },
        {
          name: "Cursor",
          property: "cursor",
          type: "select",
          list: [
            { value: "auto", name: "auto" },
            { value: "default", name: "default" },
            { value: "none", name: "none" },
            { value: "context-menu", name: "context-menu" },
            { value: "help", name: "help" },
            { value: "pointer", name: "pointer" },
            { value: "progress", name: "progress" },
            { value: "wait", name: "wait" },
            { value: "cell", name: "cell" },
            { value: "crosshair", name: "crosshair" },
            { value: "text", name: "text" },
            { value: "vertical-text", name: "vertical-text" },
            { value: "alias", name: "alias" },
            { value: "copy", name: "copy" },
            { value: "move", name: "move" },
            { value: "no-drop", name: "no-drop" },
            { value: "not-allowed", name: "not-allowed" },
            { value: "e-resize", name: "e-resize" },
            { value: "n-resize", name: "n-resize" },
            { value: "ne-resize", name: "ne-resize" },
            { value: "nw-resize", name: "nw-resize" },
            { value: "s-resize", name: "s-resize" },
            { value: "se-resize", name: "se-resize" },
            { value: "sw-resize", name: "sw-resize" },
            { value: "w-resize", name: "w-resize" },
            { value: "ew-resize", name: "ew-resize" },
            { value: "ns-resize", name: "ns-resize" },
            { value: "nesw-resize", name: "nesw-resize" },
            { value: "nwse-resize", name: "nwse-resize" },
            { value: "col-resize", name: "col-resize" },
            { value: "row-resize", name: "row-resize" },
            { value: "all-scroll", name: "all-scroll" },
            { value: "zoom-in", name: "zoom-in" },
            { value: "zoom-out", name: "zoom-out" },
            { value: "grab", name: "grab" },
            { value: "inherit", name: "inherit" },
            { value: "initial", name: "initial" },
            { value: "unset", name: "unset" }
          ],
          defaults: "auto"
        }
      ]
    },
    {
      name: "Flex",
      open: false,
      properties: [
        {
          name: "Flex Container",
          property: "display",
          type: "select",
          defaults: "block",
          list: [
            { value: "block", name: "Disable" },
            { value: "flex", name: "Enable" }
          ]
        },
        {
          name: "Wrap",
          property: "flex-wrap",
          type: "select",
          list: [
            { value: "nowrap", name: "No Wrap" },
            { value: "wrap", name: "Wrap" },
            { value: "wrap-reverse", name: "Wrap Reverse" },
            { value: "inherit", name: "Inherit" },
            { value: "initial", name: "Initial" },
            { value: "unset", name: "Unset" }
          ],
          defaults: "nowrap"
        },
        {
          name: "Direction",
          property: "flex-direction",
          type: "radio",
          defaults: "row",
          list: [
            {
              value: "row",
              name: "Row",
              className: "icons-flex icon-dir-row",
              title: "Row"
            },
            {
              value: "row-reverse",
              name: "Row reverse",
              className: "icons-flex icon-dir-row-rev",
              title: "Row reverse"
            },
            {
              value: "column",
              name: "Column",
              title: "Column",
              className: "icons-flex icon-dir-col"
            },
            {
              value: "column-reverse",
              name: "Column reverse",
              title: "Column reverse",
              className: "icons-flex icon-dir-col-rev"
            }
          ]
        },
        {
          name: "Justify",
          property: "justify-content",
          type: "radio",
          // defaults: "flex-start",
          list: [
            {
              value: "flex-start",
              className: "icons-flex icon-just-start",
              title: "Start"
            },
            {
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-just-end"
            },
            {
              value: "space-between",
              title: "Space between",
              className: "icons-flex icon-just-sp-bet"
            },
            {
              value: "space-around",
              title: "Space around",
              className: "icons-flex icon-just-sp-ar"
            },
            {
              value: "center",
              title: "Center",
              className: "icons-flex icon-just-sp-cent"
            }
          ]
        },
        {
          name: "Align",
          property: "align-items",
          type: "radio",
          // defaults: "center",
          list: [
            {
              value: "flex-start",
              title: "Start",
              className: "icons-flex icon-al-start"
            },
            {
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-al-end"
            },
            {
              value: "stretch",
              title: "Stretch",
              className: "icons-flex icon-al-str"
            },
            {
              value: "center",
              title: "Center",
              className: "icons-flex icon-al-center"
            }
          ]
        },
        {
          name: "Flex Children",
          property: "label-parent-flex",
          type: "integer"
        },
        {
          name: "Flex Parent",
          property: "label-parent-flex",
          type: "integer"
        },
        {
          name: "Flex",
          property: "flex",
          type: "composite",
          properties: [
            {
              name: "Grow",
              property: "flex-grow",
              type: "integer",
              defaults: 0,
              min: 0
            },
            {
              name: "Shrink",
              property: "flex-shrink",
              type: "integer",
              defaults: 0,
              min: 0
            },
            {
              name: "Basis",
              property: "flex-basis",
              type: "integer",
              units: ["px", "%", ""],
              unit: "",
              defaults: "auto"
            }
          ]
        },
        {
          name: "Order",
          property: "order",
          type: "integer",
          defaults: 0,
          min: 0
        },
        {
          name: "Align Self",
          property: "align-self",
          type: "radio",
          defaults: "auto",
          list: [
            {
              value: "auto",
              name: "Auto"
            },
            {
              value: "flex-start",
              title: "Start",
              className: "icons-flex icon-al-start"
            },
            {
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-al-end"
            },
            {
              value: "stretch",
              title: "Stretch",
              className: "icons-flex icon-al-str"
            },
            {
              value: "center",
              title: "Center",
              className: "icons-flex icon-al-center"
            }
          ]
        }
      ]
    }
  ]
};
