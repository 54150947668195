import React from "react";
import { ForgotPasswordCard } from "@core/components";

import "./forgot-password.styles.scss";

const ForgotPassword = () => {
  return (
    <div className="reset-password-page">
      <ForgotPasswordCard />
    </div>
  );
};

export default ForgotPassword;
