export default function languageSelectorComponent({ editor, api, className }) {
  const script = function() {
    const languages = ["EN", "RU", "AM", "BG", "UK", "HE", "AR", "ZH", "ES"];

    if (!localStorage.hasOwnProperty("language")) {
      const language = navigator.language
        ? navigator.language.slice(0, 2).toUpperCase()
        : "EN";
      const isExists = !!languages.find(
        item => item.toLowerCase() === language.toLowerCase()
      );
      localStorage.setItem("language", isExists ? language : "EN");
    }

    const className = this.className.split(" ")[0];
    const isAlwaysActive = this.dataset.hasOwnProperty("alwaysActive");
    const isHover = this.dataset.hasOwnProperty("hover");
    const direction = this.dataset.hasOwnProperty("direction")
      ? this.dataset.direction
      : "bottom";
    let isActive = false;
    let isBlocked = false;

    const wrapperSelector = `.${className}#${this.id}`;

    const triggerSelector = `${wrapperSelector} .${className}__trigger`;
    const getTriggerEl = () => document.querySelector(triggerSelector);
    const contentSelector = `${wrapperSelector} .${className}__content`;
    const getContentEl = () => document.querySelector(contentSelector);

    const popperName = `gjs_popper_${this.id}`;

    if (!getContentEl()) {
      return;
    }

    const intervalTime = 400;
    const intervalName = `${popperName}__interval`;
    const interval = popper => () => {
      if (getContentEl()) {
        popper.update();
      }
    };

    const initPopper = () => {
      if (window[intervalName]) {
        clearInterval(window[intervalName]);
      }

      window[popperName] = new window.Popper(getTriggerEl(), getContentEl(), {
        placement: direction
        // removeOnDestroy: true
      });
      window[popperName].enableEventListeners();
      window[popperName].scheduleUpdate();

      window[intervalName] = setInterval(
        interval(window[popperName]),
        intervalTime
      );
    };

    if (typeof Popper !== "object") {
      const script = document.createElement("script");
      script.src = "//cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.js";
      document.body.append(script);

      script.onload = initPopper;
    } else {
      initPopper();
    }

    const isRTL = window.localStorage.getItem("direction") === "rtl";
    const getContentStyles = isActive =>
      !!isActive
        ? Object.assign(
            {},
            {
              visibility: "visible",
              opacity: "1",
              pointerEvents: "all",
              right: "auto"
            },
            isRTL ? { textAlign: "right" } : {}
          )
        : {
            visibility: "hidden",
            opacity: "0",
            pointerEvents: "none",
            right: "auto"
          };

    if (isAlwaysActive) {
      isActive = true;
      setTimeout(
        () =>
          getContentEl() &&
          Object.assign(getContentEl().style, getContentStyles(isActive)),
        400
      );
      return;
    }

    Object.assign(getContentEl().style, getContentStyles(isActive));

    if (isHover) {
      getTriggerEl().addEventListener("mouseover", e => {
        if (!isActive) {
          isActive = true;
          Object.assign(getContentEl().style, getContentStyles(isActive));
        }
      });

      getContentEl().addEventListener("mouseover", e => {
        if (isActive) {
          isBlocked = true;
        }
      });

      getContentEl().addEventListener("mouseleave", e => {
        if (isActive) {
          isActive = false;
          isBlocked = false;
          Object.assign(getContentEl().style, getContentStyles(isActive));
        }
      });

      this.addEventListener("mouseleave", e =>
        setTimeout(() => {
          if (isActive && !isBlocked) {
            isActive = false;
            Object.assign(getContentEl().style, getContentStyles(isActive));
          }
        }, 1000)
      );
    } else {
      document.body.addEventListener("click", e => {
        if (e.target.closest(triggerSelector)) {
          isActive = !isActive;
          Object.assign(getContentEl().style, getContentStyles(isActive));

          e.stopPropagation();
        } else if (
          isActive &&
          document.body.contains(e.target) &&
          !e.target.closest(wrapperSelector)
        ) {
          if (!window.hasOwnProperty("grapesjs")) {
            return;
          }

          isActive = false;
          Object.assign(getContentEl().style, getContentStyles(isActive));
        }
      });
    }

    const valueDisplaySelector = `${wrapperSelector} .${className}__value`;
    const valueDisplaySelectorEl = document.querySelector(valueDisplaySelector);

    const optionsSelector = `${wrapperSelector} .${className}__list-item`;
    const optionsNodes = document.querySelectorAll(optionsSelector);
    Array.prototype.slice.call(optionsNodes).map(optionEl =>
      optionEl.addEventListener("click", e => {
        const element = e.target.closest(`.${className}__list-item`);
        const label = element.innerText;
        const value = element.dataset.value;
        const direction = element.dataset.dir;

        valueDisplaySelectorEl.innerText = label;

        if (window.hasOwnProperty("grapesjs")) {
          isActive = false;
          isBlocked = false;
          Object.assign(getContentEl().style, getContentStyles(isActive));
          localStorage.setItem("language", value);
          localStorage.setItem("direction", direction);
          window.location.reload();
        }
      })
    );

    if (window.hasOwnProperty("grapesjs")) {
      const language = localStorage.getItem("language");
      const isExists = !!languages.find(
        item => item.toLowerCase() === language.toLowerCase()
      );
      valueDisplaySelectorEl.innerText = isExists ? language : "EN";
    }
  };

  editor.DomComponents.addType("language-selector", {
    isComponent: el => el.tagName === "DIV" && el.className === `${className}`,
    model: {
      defaults: {
        name: "Language Selector",
        script,
        traits: [
          {
            type: "checkbox",
            label: "Always active (or editor mode)",
            name: "data-always-active",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "checkbox",
            label: "Show / Hide on hover",
            name: "data-hover",
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "select",
            label: "Initial direction",
            name: "data-direction",
            options: [
              { id: "auto", name: "auto" },
              { id: "auto-start", name: "auto-start" },
              { id: "auto-end", name: "auto-end" },
              { id: "top", name: "top" },
              { id: "top-end", name: "top-left" },
              { id: "top-start", name: "top-right" },
              { id: "bottom", name: "bottom" },
              { id: "bottom-end", name: "bottom-left" },
              { id: "bottom-start", name: "bottom-right" },
              { id: "right", name: "right" },
              { id: "right-end", name: "right-top" },
              { id: "right-start", name: "right-bottom" },
              { id: "left", name: "left" },
              { id: "left-end", name: "left-top" },
              { id: "left-start", name: "left-bottom" }
            ]
          }
        ]
      },
      view: {
        init() {
          editor.select(this.model.components().parent);
        }
      }
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          "change:attributes:data-always-active",
          this.render
        );
        this.listenTo(this.model, "change:attributes:data-hover", this.render);
      }
    }
  });

  editor.DomComponents.addType("language-selector__trigger", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === `${className}__trigger`,
    model: {
      defaults: {
        name: "Language Selector Trigger",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("language-selector__input", {
    isComponent: el =>
      el.tagName === "INPUT" && el.className === `${className}__input`,
    model: {
      defaults: {
        name: "Language Selector Value"
      }
    }
  });

  editor.DomComponents.addType("language-selector__content", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === `${className}__content`,
    model: {
      defaults: {
        name: "Language Selector Content",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });
}
