import { ActiveProductActionTypes } from "./active-product.type";

const INITIAL_STATE = {
  isActiveProductPending: true,
  activeProduct: {},
  requestError: {}
};

const ActiveProductReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ActiveProductActionTypes.B2B.B2B_PRODUCT_PENDING:
      return { ...state, isActiveProductPending: true };

    case ActiveProductActionTypes.B2B.B2B_PRODUCT_SUCCESS:
      return {
        ...state,
        isActiveProductPending: false,
        activeProduct: action.payload
      };

    case ActiveProductActionTypes.B2B.B2B_PRODUCT_ERROR:
      return { ...state, requestError: action.payload };

    case ActiveProductActionTypes.B2C.B2C_PRODUCT_PENDING:
      return { ...state, isActiveProductPending: true };

    case ActiveProductActionTypes.B2C.B2C_PRODUCT_SUCCESS:
      return {
        ...state,
        isActiveProductPending: false,
        activeProduct: action.payload
      };

    case ActiveProductActionTypes.B2C.B2C_PRODUCT_ERROR:
      return { ...state, requestError: action.payload };

    case ActiveProductActionTypes.SET_PRODUCT_VARIATION:
      return { ...state, activeProduct: action.payload };

    default:
      return state;
  }
};

export default ActiveProductReducer;
