import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  ColumnsSmLgWrapper,
  ColumnWrapperSm,
  ColumnWrapperLg,
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "../../../layout";

import { videoIsUploaded } from "../../../constants";
import { missingImage, missingVideo } from "../../../assets";
import {
  MediaActions,
  DefaultButton,
  InputGroup,
  DefaultDropdown,
  DefaultDropdownMultiSelect,
  Separator,
  EditorGroup,
  CircleLoader
} from "../../../components";

import {
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  postBusinessProposal,
  putBusinessProposal,
  searchCountry,
  searchBranch,
  getBusinessProposalsCategories,
  getCompaniesByUserId,
  getBusinessProposalById,
  clearBusinessProposalRequest
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./business-proposals-edit.styles.scss";
import { withDebounce } from "../../../shared";

const MyBusinessProposalsEdit = ({
  galleryBlob,
  galleryFile,
  videoBlob,
  videoFile,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  isImageGalleryUploadPending,
  postBusinessProposal,
  putBusinessProposal,
  searchCountry,
  countries,
  areBranchesPending,
  branchesAPI,
  searchBranch,
  businessProposalsCategories,
  getBusinessProposalsCategories,
  getCompaniesByUserId,
  companiesByUserId,
  getBusinessProposalById,
  businessProposal,
  clearBusinessProposalRequest,
  businessRequestStatus
}) => {
  const { businessProposalsId } = useParams();
  const history = useHistory();

  const [countryInput, setCountryInput] = useState("");
  const [branchInput, setBranchInput] = useState("");
  const [companyInput, setCompanyInput] = useState("");

  const [businessProposalsData, setBusinessProposalsData] = useState({
    bpTitle: "",
    bpCountry: "",
    bpCity: "",
    bpCompany: "",
    bpKeywords: "",
    bpDescription: "",
    bpFile: "",
    bpBlob: ""
  });
  const [bpBranches, setBPBranches] = useState([]);
  const [bpCategories, setBPCategories] = useState([]);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const {
    bpTitle,
    bpCountry,
    bpCity,
    bpCompany,
    bpKeywords,
    bpDescription,
    bpFile
    // bpBlob
  } = businessProposalsData;

  useEffect(() => {
    getBusinessProposalsCategories();
  }, [getBusinessProposalsCategories]);

  useEffect(() => {
    getCompaniesByUserId();
  }, [getCompaniesByUserId, companyInput]);

  useEffect(() => {
    searchCountry(countryInput);
  }, [searchCountry, countryInput]);

  useEffect(() => {
    searchBranch(branchInput);
  }, [searchBranch, branchInput]);

  useEffect(() => {
    if (businessProposalsId !== "create") {
      getBusinessProposalById(businessProposalsId, businessProposalsId);
    }
  }, [getBusinessProposalById, businessProposalsId]);

  useEffect(() => {
    if (businessProposalsId !== "create") {
      const {
        title,
        description,
        branches,
        categories,
        organization,
        country,
        keywords
      } = businessProposal;

      setBusinessProposalsData(businessProposalsData => ({
        ...businessProposalsData,
        bpTitle: title,
        bpCountry: country && country.id,
        bpDescription: description,
        bpCompany: organization && organization.id,
        bpKeywords: keywords
      }));

      setBPCategories(categories);
      setBPBranches(branches);
    }
  }, [businessProposalsId, businessProposal]);

  useEffect(() => {
    setButtonDisabled(
      bpTitle === "" ||
        bpDescription === "" ||
        bpCompany === "" ||
        bpCountry === ""
    );
  }, [bpTitle, bpDescription, bpCompany, bpCountry]);

  useEffect(() => {
    if (businessRequestStatus === 200 || businessRequestStatus === 201) {
      history.push("/my-business/business-proposals");
    }
  }, [businessRequestStatus, history]);

  useEffect(() => () => clearBusinessProposalRequest(), [
    clearBusinessProposalRequest
  ]);

  const businessProposalsInputsHandler = (name, value) =>
    setBusinessProposalsData({ ...businessProposalsData, [name]: value });

  const businessProposalsMediaHandler = data => {
    setBusinessProposalsData({ ...businessProposalsData, ...data });
  };

  const businessProposalRequest = () => {
    if (businessProposalsId === "create") {
      postBusinessProposal(
        bpTitle,
        bpDescription,
        bpBranches,
        bpCategories,
        bpCompany,
        bpCountry,
        bpKeywords
      );
    } else {
      putBusinessProposal(
        businessProposalsId,
        bpTitle,
        bpDescription,
        bpBranches,
        bpCategories,
        bpCompany,
        bpCountry,
        bpKeywords
      );
    }
  };

  const setCountryInputHandler = withDebounce(e => setCountryInput(e));
  const setBranchInputHandler = withDebounce(e => setBranchInput(e));
  const setCompanyInputHandler = withDebounce(e => setCompanyInput(e));

  return (
    <div className="my-business-business-proposals">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={bpFile ? bpFile : missingImage}
            alt={"company-picture"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton {...buttonOk} width="100%">
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      businessProposalsMediaHandler({
                        bpFile: URL.createObjectURL(e.target.files[0]),
                        bpBlob: e.target.files[0]
                      });
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                {...buttonCancel}
                width="100%"
                onClick={() =>
                  businessProposalsMediaHandler({
                    businessFile: "",
                    businessBlob: ""
                  })
                }
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {businessProposalsId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          // onClick={() =>
                          //   uploadImageGallery(newsType, newsId, galleryBlob)
                          // }
                          disabled={galleryBlob === ""}
                          width="100%"
                          {...buttonOk}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("remove");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
              <Separator />
              <MediaActions
                image={videoFile ? videoIsUploaded : missingVideo}
                alt={"missing image"}
                height={200}
                buttons={[
                  <DefaultButton width="100%" {...buttonOk}>
                    <label style={{ cursor: "pointer" }}>
                      Choose a video for gallery
                      <input
                        accept="video/*"
                        type="file"
                        onChange={e => {
                          loadFileVideo(URL.createObjectURL(e.target.files[0]));
                          loadBlobVideo(e.target.files[0]);
                        }}
                        style={{ display: "none", cursor: "pointer" }}
                      />
                    </label>
                  </DefaultButton>,
                  <DefaultButton
                    width="100%"
                    {...buttonOk}
                    // onClick={() => uploadVideoGallery(newsType, newsId, videoBlob)}
                    disabled={videoBlob === ""}
                  >
                    Upload new gallery
                  </DefaultButton>,
                  <DefaultButton
                    onClick={() => {
                      loadBlobVideo("");
                      loadFileVideo("");
                    }}
                    width="100%"
                    {...buttonCancel}
                  >
                    Delete gallery
                  </DefaultButton>
                ]}
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              label="TITLE"
              placeholder="Title"
              name="bpTitle"
              value={bpTitle || ""}
              onChange={({ target: { name, value } }) =>
                businessProposalsInputsHandler(name, value)
              }
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="COUNTRY"
              items={countries.results}
              returnData={e => businessProposalsInputsHandler("bpCountry", e)}
              inputFunc={e => setCountryInputHandler(e.target.value)}
              filter={true}
              customTitle={
                businessProposalsId !== "create" &&
                businessProposal.country &&
                businessProposal.country.name
              }
              required={true}
            />
            <InputGroup
              label="city"
              name="bpCity"
              value={bpCity || ""}
              onChange={({ target: { name, value } }) =>
                businessProposalsInputsHandler(name, value)
              }
              placeholder="City..."
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdownMultiSelect
              items={branchesAPI.results}
              returnData={setBPBranches}
              label="Branches"
              filter={true}
              setCategoryInputValue={e => setBranchInputHandler(e.target.value)}
              customArray={
                businessProposalsId !== "create" && businessProposal.branches
              }
            />

            <DefaultDropdown
              label="COMPANY"
              items={companiesByUserId}
              returnData={e => businessProposalsInputsHandler("bpCompany", e)}
              inputFunc={e => setCompanyInputHandler(e.target.value)}
              filter={true}
              customTitle={
                businessProposalsId !== "create" &&
                businessProposal.organization &&
                businessProposal.organization.name
              }
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={({ target: { value } }) =>
                businessProposalsInputsHandler("bpKeywords", value)
              }
              value={bpKeywords}
              label="KEYWORDS"
              name="keywords"
              placeholder="#hashtag, #hashtag, ..., #hashtag"
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdownMultiSelect
              label="CATEGORY"
              items={businessProposalsCategories}
              returnData={setBPCategories}
              customArray={
                businessProposalsId !== "create" && businessProposal.categories
              }
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      <Separator />
      <ParentWrapper>
        <EditorGroup
          label="PREVIEW DESCRIPTION"
          name="previewDescription"
          onChange={e =>
            businessProposalsInputsHandler(
              "bpDescription",
              e.target.getContent()
            )
          }
          value={bpDescription}
          required={true}
        />
      </ParentWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={businessProposalRequest}
            {...buttonOk}
            width="100%"
            disabled={buttonDisabled}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={() => history.push("/my-business/business-proposals")}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  areCountriesPending: state.countriesReducer.areCountriesPending,
  countries: state.countriesReducer.countries,

  areBranchesPending: state.branchesReducer.areBranchesPending,
  branchesAPI: state.branchesReducer.branches,
  companiesByUserId: state.companiesReducer.companiesByUserId,

  businessProposalsCategories:
    state.businessProposals.businessProposalsCategories,
  businessProposal: state.businessProposals.businessProposal,

  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,
  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,
  businessRequestStatus: state.businessProposals.businessRequestStatus
});

export default connect(mapStateToProps, {
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  postBusinessProposal,
  putBusinessProposal,
  searchCountry,
  searchBranch,
  getBusinessProposalsCategories,
  getCompaniesByUserId,
  getBusinessProposalById,
  clearBusinessProposalRequest
})(MyBusinessProposalsEdit);
