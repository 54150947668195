import { ProductSwitchActionTypes } from "./product-switch.types";

const INITIAL_STATE = {
  wallToggled: false,
  isAllProductsSwitchToggled: true,
  isCompOrgProductsSwitchToggled: true
};

const ProductSwitchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductSwitchActionTypes.TOGGLE_WALL_PRODUCT_SWITCH:
      return { ...state, wallToggled: !state.wallToggled };

    case ProductSwitchActionTypes.TOGGLE_ALL_PRODUCT_SWITCH:
      return {
        ...state,
        isAllProductsSwitchToggled: action.payload
      };

    case ProductSwitchActionTypes.TOGGLE_COMP_ORG_PRODUCTS_SWITCH:
      return {
        ...state,
        isCompOrgProductsSwitchToggled: !state.isCompOrgProductsSwitchToggled
      };

    default:
      return state;
  }
};

export default ProductSwitchReducer;
