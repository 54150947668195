import icon from "./icon.png";

export default function newsCategoriesBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .news-categories {}

  .news-categories.news-categories--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .news-categories.news-categories--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .news-categories__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .news-categories__search {
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000000;
    font-size: 15px;
    border: 1px solid #b9b9ba;
    padding: 10px 8px;
    margin: 0 0 14px;
    border-radius: 3px;
  }

  .news-categories__title {
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 21px;
  }

  .news-categories__item {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 5px;
    border-radius: 2px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease;
  }

  .news-categories__item.news-categories__item--active {
    background: rgba(0, 0, 0, 0.08);
  }
  
  .news-categories__item + .news-categories__item {
    margin: 6px 0 0;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="news-categories-wrapper">
    <input class="news-categories__search" type="text" placeholder="Search categories...">
    <div class="news-categories">
      <ul class="news-categories__list">
        <li class="news-categories__item news-categories__item--active">
          Selected category
        </li>
      
        <li class="news-categories__item news-categories__item--active">
          Selected category
        </li>
      
        <li class="news-categories__item">
        Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      
        <li class="news-categories__item">
          Category
        </li>
      </ul>
    </div>
  </div>
  `;

  blockManager.add("news-categories", {
    label: `
      <img src="${icon}" alt="News categories block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        News Categories
      </div>
    `,
    content,
    category: "B24Online News"
  });
}
