import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { resetPassword } from "~/redux/actions-exporter";
import { DefaultButton, DefaultSnackbar } from "@core/components";
import { colorPalette } from "~/styles/colorPalette";

const ForgotPasswordForm = () => {
  const resetPasswordPending = useSelector(state => state.authReducer.resetPasswordPending);
  const resetPasswordResult = useSelector(state => state.authReducer.resetPasswordResult);
  const resetPasswordError = useSelector(state => state.authReducer.resetPasswordError);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageStatus, setMessageStatus] = useState("success");

  const history = useHistory();

  useEffect(() => {
    if (resetPasswordResult) {
      setMessage(t("Please, check you email. To change the password, click on the link in the message."));
      setMessageStatus("success");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        history.push("/");
      }, 3000);
    }
  }, [resetPasswordResult]);

  useEffect(() => {
    if (resetPasswordError) {
      setMessage(t("The error occurred"));
      setMessageStatus("error");
      setShowMessage(true);
    }
  }, [resetPasswordError]);

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <p>{t("Email")}</p>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="text"
        />
        <br />
        <DefaultButton
          onClick={() => dispatch(resetPassword(email))}
          color="white"
          backgroundColor={colorPalette.colorPrimary.main}
          hover={colorPalette.colorPrimary.darker}
          width="100%"
          disabled={resetPasswordPending || !/^\S+@\S+\.\S+$/.test(email)}
          ButtonOptions={{
            type: "submit"
          }}
        >
          {t("Reset Password")}
        </DefaultButton>
      </form>
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          variant={messageStatus}
          message={message}
          autoHideDuration={2000}
        />
      )}
    </>
  );
};

export default ForgotPasswordForm;