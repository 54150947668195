import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  ProductCard,
  ProductSwitch,
  Pagination,
  DefaultDropdown,
  DefaultMultiLevelDropdown
} from "../../components";

import {
  getB2BCategories,
  getB2CCategories,
  searchB2BCategory,
  searchB2CCategory,
  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany,
  getOrganizationB2BProducts,
  getCompanyB2BProducts,
  getOrganizationB2CProducts,
  getCompanyB2CProducts,
  addProductToWishList
} from "../../redux/actions-exporter";

import "./event-products.styles.scss";
import { missingImage } from "../../assets";

const EventDescriptionProductCards = ({
  b2bPending,
  b2cPending,
  b2bProducts,
  b2cProducts,
  toggle
}) => {
  return (
    <div className="event-products__cards">
      {!toggle
        ? !b2bPending &&
          b2bProducts &&
          b2bProducts.map(b2bproduct => {
            const { id, image, cost, currency, name, categories } = b2bproduct;
            return (
              <div key={id} className="event-products__card">
                <ProductCard
                  navProductType="b2b"
                  key={id}
                  productId={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={name}
                  price={cost}
                  currency={currency}
                  currentProductData={b2bproduct}
                />
              </div>
            );
          })
        : !b2cPending &&
          b2cProducts &&
          b2cProducts.map(b2cproduct => {
            const {
              id,
              image,
              cost,
              currency,
              name,
              categories,
              discount_percent,
              coupon_discount_percent
            } = b2cproduct;
            return (
              <div key={id} className="event-products__card">
                <ProductCard
                  navProductType="b2c"
                  key={id}
                  productId={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={name}
                  price={cost}
                  currency={currency}
                  currentProductData={b2cproduct}
                  discountPercent={discount_percent}
                  couponDiscountPercent={coupon_discount_percent}
                />
              </div>
            );
          })}
    </div>
  );
};

const EventDescriptionProducts = ({
  getCompanyB2BProducts,
  areCompanyB2BProductsPending,
  companyB2BProducts,
  companyB2BProductsCount,

  getCompanyB2CProducts,
  areCompanyB2CProductsPending,
  companyB2CProducts,
  companyB2CProductsCount,

  getOrganizationB2BProducts,
  areB2COrganizationProductsPending,
  b2cOrganizationProducts,
  b2cOrganizationProductsCount,

  getOrganizationB2CProducts,
  areB2BOrganizationProductsPending,
  b2bOrganizationProducts,
  b2bOrganizationProductsCount,

  getB2BCategories,
  searchB2BCategory,
  b2bCategoriesResults,

  getB2CCategories,
  searchB2CCategory,
  b2cCategoriesResults,

  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany,

  searchedB2BCategories,
  searchedB2CCategories
}) => {
  const params = useParams();
  const [toggle, setToggle] = useState(false);

  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [b2bCompanyPage, setB2BCompanyPage] = useState(1);
  const [b2bOrganizationsPage, setB2BOrganizationsPage] = useState(1);
  const [b2cCompanyPage, setB2CCompanyPage] = useState(1);
  const [b2cOrganizationsPage, setB2COrganizationsPage] = useState(1);

  useEffect(() => {
    searchB2BCategoriesByCompany(params.compOrgId);
    searchB2CCategoriesByCompany(params.compOrgId);
  }, [
    searchB2BCategoriesByCompany,
    searchB2CCategoriesByCompany,
    params.compOrgId
  ]);

  useEffect(() => {
    if (params.compOrg === "company") {
      getCompanyB2BProducts(params.compOrgId, category, sortBy, b2bCompanyPage);
      getCompanyB2CProducts(params.compOrgId, category, sortBy, b2cCompanyPage);
    } else if (params.compOrg === "organization") {
      getOrganizationB2BProducts(
        params.compOrgId,
        category,
        sortBy,
        b2bOrganizationsPage
      );
      getOrganizationB2CProducts(
        params.compOrgId,
        category,
        sortBy,
        b2cOrganizationsPage
      );
    }
    // eslint-disable-next-line
  }, [
    params.compOrg,
    getCompanyB2BProducts,
    getCompanyB2CProducts,
    getOrganizationB2BProducts,
    getOrganizationB2CProducts,
    b2bCompanyPage,
    b2cCompanyPage,
    b2bOrganizationsPage,
    b2cOrganizationsPage,
    category
  ]);

  useEffect(() => {
    setCategory("");
  }, [toggle]);

  return (
    <div>
      <div className="event-products">
        <div className="event-products__sort">
          <div className="event-products__sort-bottom">
            <div className={"event-products__sort-bottom__selector"}>
              {!toggle && (
                <DefaultMultiLevelDropdown
                  label="Category"
                  filter={true}
                  style={{ width: "100%" }}
                  items={
                    !toggle ? searchedB2BCategories : searchedB2CCategories
                  }
                  returnData={setCategory}
                  inputFunc={e =>
                    !toggle
                      ? searchB2BCategoriesByCompany(
                          params.compOrgId,
                          e.target.value
                        )
                      : searchB2CCategoriesByCompany(
                          params.compOrgId,
                          e.target.value
                        )
                  }
                />
              )}

              {toggle && (
                <DefaultMultiLevelDropdown
                  label="Category"
                  filter={true}
                  style={{ width: "100%" }}
                  items={
                    !toggle ? searchedB2BCategories : searchedB2CCategories
                  }
                  returnData={setCategory}
                  inputFunc={e =>
                    !toggle
                      ? searchB2BCategoriesByCompany(
                          params.compOrgId,
                          e.target.value
                        )
                      : searchB2CCategoriesByCompany(
                          params.compOrgId,
                          e.target.value
                        )
                  }
                />
              )}
            </div>
            <div className={"event-products__sort-bottom__selector"}>
              <DefaultDropdown
                label="Sort by"
                style={{ width: "100%" }}
                items={[
                  { id: "-id", name: "Newest" },
                  { id: "id", name: "Oldest" },
                  { id: "cost", name: "Price ascending" },
                  { id: "-cost", name: "Price descending" }
                ]}
                returnData={setSortBy}
              />
            </div>
          </div>
        </div>
        <ProductSwitch isToggled={toggle} onToggle={() => setToggle(!toggle)} />
      </div>
      {params.compOrg === "company" ? (
        <EventDescriptionProductCards
          b2bPending={areCompanyB2BProductsPending}
          b2cPending={areCompanyB2CProductsPending}
          b2bProducts={companyB2BProducts}
          b2cProducts={companyB2CProducts}
          toggle={toggle}
        />
      ) : (
        <EventDescriptionProductCards
          b2bPending={areB2BOrganizationProductsPending}
          b2cPending={areB2COrganizationProductsPending}
          b2bProducts={b2bOrganizationProducts}
          b2cProducts={b2cOrganizationProducts}
          toggle={toggle}
        />
      )}
      <Pagination
        forcePage={
          !toggle
            ? params.compOrg === "company"
              ? b2bCompanyPage - 1
              : b2bOrganizationsPage - 1
            : params.compOrg === "company"
            ? b2cCompanyPage - 1
            : b2cOrganizationsPage - 1
        }
        dataCount={
          !toggle
            ? params.compOrg === "company"
              ? companyB2BProductsCount
              : b2bOrganizationProductsCount
            : params.compOrg === "company"
            ? companyB2CProductsCount
            : b2cOrganizationProductsCount
        }
        itemsPerPage={10}
        selectedPage={page =>
          !toggle
            ? params.compOrg === "company"
              ? setB2BCompanyPage(page)
              : setB2BOrganizationsPage(page)
            : params.compOrg === "company"
            ? setB2CCompanyPage(page)
            : setB2COrganizationsPage(page)
        }
      />
    </div>
  );
};

const mapStateToProps = state => ({
  company: state.companiesReducer.company,
  organization: state.organizationsReducer.organization,

  searchedB2BCategories: state.categoriesAPI.searchedB2BCategories,
  searchedB2CCategories: state.categoriesAPI.searchedB2CCategories,

  b2bCategoriesResults: state.categoriesAPI.b2bCategoriesResults,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,

  areB2COrganizationProductsPending:
    state.b2cProductsReducer.areB2COrganizationProductsPending,
  b2cOrganizationProducts: state.b2cProductsReducer.b2cOrganizationProducts,
  b2cOrganizationProductsCount:
    state.b2cProductsReducer.b2cOrganizationProductsCount,

  areB2BOrganizationProductsPending:
    state.b2BProductsReducer.areB2BOrganizationProductsPending,
  b2bOrganizationProducts: state.b2BProductsReducer.b2bOrganizationProducts,
  b2bOrganizationProductsCount:
    state.b2BProductsReducer.b2bOrganizationProductsCount,

  areCompanyB2BProductsPending:
    state.b2BProductsReducer.areCompanyB2BProductsPending,
  companyB2BProducts: state.b2BProductsReducer.companyB2BProducts,
  companyB2BProductsCount: state.b2BProductsReducer.companyB2BProductsCount,

  areCompanyB2CProductsPending:
    state.b2cProductsReducer.areCompanyB2CProductsPending,
  companyB2CProducts: state.b2cProductsReducer.companyB2CProducts,
  companyB2CProductsCount: state.b2cProductsReducer.companyB2CProductsCount
});

export default connect(mapStateToProps, {
  getB2BCategories,
  getB2CCategories,

  searchB2BCategory,
  searchB2CCategory,

  getOrganizationB2BProducts,
  getOrganizationB2CProducts,

  getCompanyB2BProducts,
  getCompanyB2CProducts,

  addProductToWishList,

  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany
})(EventDescriptionProducts);
