import React from "react";

import { Clear as ClearIcon } from "@material-ui/icons";

import { staticURL, videoIsUploaded } from "../../constants";

import "./video-section.styles.scss";

const VideoSection = ({
  label,
  videos,
  deleteOneVideoFromGallery,
  parentId,
  contentType
}) => {
  return (
    <div className="video-section">
      <div className="video-section__header">{label}</div>
      <div className="video-section__videos">
        {videos &&
          videos.map(({ videos }) =>
            videos.map((el, index) => (
              <div className="single-video" key={index}>
                <ClearIcon
                  onClick={() =>
                    deleteOneVideoFromGallery(el.id, parentId, contentType)
                  }
                  className="single-video__clear"
                />
                <a
                  className="single-video__link"
                  href={`${staticURL}/${el.video}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={videoIsUploaded} alt="" />
                </a>
              </div>
            ))
          )}
      </div>
    </div>
  );
};

export default VideoSection;
