import { SitesActionTypes } from "./sites.types";
import { axiosInstance } from "../../../axios/axios.builder.config";

export const getSites = (page, search = "") => dispatch => {
  dispatch({ type: SitesActionTypes.GET_SITES_PENDING });
  return axiosInstance
    .get(`/api/v1/builder/site/?page=${page}&search=${search}`, {}, {})
    .then(sites =>
      dispatch({
        type: SitesActionTypes.GET_SITES,
        payload: {
          ...sites.data,
          currentPage: page,
          search
        }
      })
    )
    .catch(error => console.log(error));
};

export const getSite = id => dispatch => {
  dispatch({ type: SitesActionTypes.GET_SITE_PENDING });
  return axiosInstance
    .get(`/api/v1/builder/site/${id}/`, {}, {})
    .then(site =>
      dispatch({
        type: SitesActionTypes.GET_SITE,
        payload: site.data
      })
    )
    .catch(error => console.log(error));
};

export const createSite = ({
  title,
  url,
  domain,
  organizationId
}) => dispatch => {
  return axiosInstance
    .post("/api/v1/builder/site/", {
      title,
      site: { name: title, domain: url },
      organization: organizationId
    })
    .then(site =>
      dispatch({
        type: SitesActionTypes.CREATE_SITE,
        payload: site.data
      })
    )
    .catch(error => {
      dispatch({
        type: SitesActionTypes.CREATE_SITE_ERROR,
        payload: {
          error,
          domain
        }
      });
      console.log(error);
    });
};

export const addThemeToSite = (theme = undefined) => dispatch => {
  dispatch({ type: SitesActionTypes.GET_SITE_PENDING });
  dispatch({
    type: SitesActionTypes.ADD_THEME_TO_SITE,
    payload: theme
  });
};

export const updateSite = ({ id, title, url, domain }) => dispatch => {
  dispatch({ type: SitesActionTypes.GET_SITE_PENDING });
  return axiosInstance
    .patch(`/api/v1/builder/site/${id}/`, {
      title,
      site: { name: title, domain: url }
    })
    .then(site =>
      dispatch({
        type: SitesActionTypes.UPDATE_SITE,
        payload: site.data
      })
    )
    .catch(error => {
      dispatch({
        type: SitesActionTypes.UPDATE_SITE_ERROR,
        payload: {
          error,
          domain
        }
      });
      console.log(error);
    });
};

export const deleteSite = id => dispatch => {
  return axiosInstance
    .delete(`/api/v1/builder/site/${id}/`)
    .then(site =>
      dispatch({
        type: SitesActionTypes.DELETE_SITE,
        payload: site
      })
    )
    .catch(error => console.log(error));
};
