import icon from "./icon.png";
import productsWithPaginationContent from "../products-with-pagination/block-content";

export default function companyDetailsBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .company-details-wrapper {}

    .company-details-wrapper.company-details-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .company-details-wrapper.company-details-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .company-details {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      padding: 16px 20px;
    }

    @media screen and (max-width: 992px) {
      .company-details {
        flex-flow: column !important;
      }

      .company-details__image {
        width: 90%;
        margin: 0 auto;
      }

      .company-details__header {
        flex-flow: column!important;
        align-items: flex-start !important;
        margin: 0 0 16px;
      }

      .company-details__button {
        margin: 0 !important;
      }

      .company-details__action.company-details__action--basicc {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
      }

      .company-details__wish {
        margin: 20px 0 10px;
      }

      .company-details__quantity {
        flex: 100%;
        justify-content: center;
        margin: 0 0 30px;
      }

      .company-details__tab-main {
        padding: 1% 0%;
      }

      .company-contacts {
        flex-flow: column;
      }

      .company-gallery {
        flex-flow: column nowrap;
      }

      .company-gallery__video {
        width: 100%;
      }

      .company-gallery__image {
        width: 100%;
      }

      .company-gallery__document {
        width: 100%;
      }
    }

    .company-details__image {
      width: 340px;
      height: 300px;
      object-fit: scale-down;
      padding: 0 5%;
    }

    .company-details__main {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin: 0 5px 0;
      flex: 1;
    }
    
    .company-details__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .company-details__title {
      width: 90%;
      color: #262626;
      font-size: 30px;
      font-weight: 700;
      margin: 10px 0;
    }

    .company-details__divider {
      width: 114px;
      height: 7px;
      background-color: #aeaeae;  
    }

    .company-details__description {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin: 16px 0;
    }

    .company-details__description * {
      max-width: 100%;
    }

    .company-details__cost-wrapper {
      margin: 0 0 22px;
    }

    .company-details__caption {
      font-size: 18px;
      color: #4b4b4b;
    }

    .company-details__cost {
      color: #262626;
      font-size: 18px;
      font-weight: 700;
      margin: auto 0 8px;
    }

    .company-details__cost.company-details__cost--discount {
      text-decoration-color: rgb(103, 98, 98);
      text-decoration-line: line-through;
      text-decoration-style: solid;
      font-size: 15px;
    }

    .company-details__currency {
      color: rgb(38, 38, 38);
      font-weight: 700;
      font-size: 18px;
    }

    .company-details__discount {
      color: #060606;
      font-weight: 600;
      font-size: 14px;
      padding: 1px 4px;
      border: 2px solid #aeaeae;
      border-radius: 4px;
    }

    .company-details__action.company-details__action--basic {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .company-details__quantity {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
    }

    .company-details__quantity-button {
      min-width: 30px;
      background: #f6f6f6;
      color: #212121;
      font-size: 24px;
      border: 1px solid #8b8b8b;
      padding: 4px 9px;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .company-details__quantity-button:hover {
      background: #eaeaea;
    }

    .company-details__quantity-input {
      width: 82px;
      font-size: 16px;
      text-align: right;
      border: 1px solid #8b8b8b;
      border-left: 0;
      border-radius: 0;
    }

    .company-details__wish {
      background: transparent;
      color: #757575;
      height: 30px;
      width: 36px;
      border: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .company-details__wish.company-details__wish--disabled {
      color: #262626;
      cursor: not-allowed;
    }

    .company-details__wish:not(.company-details__wish--disabled):focus {
      transform: scale(1.1);
    }

    .company-details__button {
      min-width: 160px;
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      margin: 0 12px 0;
      border: 2px solid #aeaeae;
      border-radius: 0;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .company-details__button:hover {
      background-color: #f1f1f1;
      color: #aba8a8;
    }

    .company-details__button.company-details__button--disabled {
      background-color: #dadada;
      color: #848484;
      cursor: not-allowed;
    }

    .company-details__slogan {
      color: #262626;
      font-weight: 400;
      font-style: italic;
      margin: 0 0 10px;
    }

    .company-parent {
      display: flex;
      align-items: center;
    }

    .company-parent__logo {
      height: 45px;
      width: 45px;
      object-fit: contain;
    }

    .company-parent__name {
      font-weight: 600;
      margin: 3px 8px 0;
    }

    .company-details__additional {
      flex: 100%;
      width: 100%;
      padding: 20px 0 0;
    }

    .company-details__tabs {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
    }

    .company-details__tab {
      color: #262626;
      cursor: pointer;
    }

    .company-details__tab-separator {
      color: #e8e8e8;
      font-size: 26px;
      margin: -8px 0 0;
    }

    .company-details__tab--active {
      font-weight: 700;
    }

    .company-details__tab-header {
      background: #f8f8f8;
      text-align: center;
      margin: 24px 0 0;
      padding: 12px 0;
    }

    .company-details__tab-title {
      margin: 0;
    }

    .company-details__tab-main {
      padding: 1% 5%;
    }

    .company-contacts {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .company-contacts__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      margin: 0 0 20px;
    }

    .company-contacts__meta {
      padding: 0 12px 0;
    }

    .company-contacts__title {
      margin: 0;
      font-weight: 700;
    }

    .company-contacts__text {
      margin: 6px 0 0;
    }

    .company-contacts__link {
      color: initial;
      text-decoration: none;
      cursor: pointer;
    }

    .company-gallery {
      display: flex;
      flex-flow: row wrap;
    }

    .company-gallery__video {
      width: 33.33%;
      padding: 6px;
      border-radius: 13px;
    }

    .company-gallery__image {
      width: 33.33%;
      height: 300px;
      padding: 6px;
      border-radius: 3px;
      object-fit: scale-down;
    }

    .company-gallery__document {
      width: 20%;
      padding: 6px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: stretch;
      color: black;
      text-decoration: none;
    }

    .company-gallery__document-icon {
      height: 140px;
      width: 100%;
      padding: 20px;
    }

    .company-gallery__document-name {
      width: 100%;
      text-align: center;
      background: #f3f3f3;
      padding: 12px 0;
    }

    .company-staff {
      width: 100%;
    }

    .company-staff__header {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      padding: 20px 0;
    }

    .company-staff__row {
      color: #262626;
      text-align: center;
    }

    .company-staff__image {
      height: 64px;
      width: 64px;
      border-radius: 50px;
      margin: 20px 0 0;
      object-fit: cover;
    }

    .gjs-products-type-switch {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 10px;
    }
  
    .gjs-products-type-switch__product-type {
      padding: 0 4px 0;
      opacity: 0.5;
    }
  
    .gjs-products-type-switch__product-type--active {
      opacity: 1;
    }
  
    .gjs-products-type-switch__toggle {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 30px;
    }
    
    .gjs-products-type-switch__checkbox {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 10;
    }
    
    .gjs-products-type-switch__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #ccc;
      transition: .4s;
      border-radius: 34px;
    }
    
    .gjs-products-type-switch__slider:before {
      position: absolute;
      content: "";
      height: 80%;
      width: 40%;
      background-color: white;
      left: 10%;
      top: 10%;
      botton: 10%;
      border-radius: 50%;
      transition: .4s;
    }
    
    .gjs-products-type-switch__checkbox:checked + .gjs-products-type-switch__slider:before {
      transform: translateX(100%);
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="company-details-wrapper">
    <div class="company-details">
      <img class="company-details__image"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
        onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
      <div class="company-details__main">
        <div class="company-details__header">
          <h4 class="company-details__title">Title</h4>
        </div>

        <p class="company-details__slogan">
          Company slogan & slogan
        </p>

        <div class="company-details__description company-details__description--short">
          Company description...
        </div>

        <div class="company-parent">
          <img class="company-parent__logo" src="data:image/svg+xml;base64,PHN42ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" alt="Company logo">
          <span class="company-parent__name">
            Company Name
          </span>
        </div>
      </div>

      <div class="company-details__additional">
        <div class="company-details__tabs">
          <span class="company-details__tab company-details__tab--active"
            data-tab-selector=".company-details__tab-content--about">
            About
          </span>
          <span class="company-details__tab-separator">|</span>
          <span
            class="company-details__tab"
            data-tab-selector=".company-details__tab-content--products"
          >
            Products
          </span>
          <span class="company-details__tab-separator">|</span>
          <span class="company-details__tab" data-tab-selector=".company-details__tab-content--files">
            Files
          </span>
          <span class="company-details__tab-separator">|</span>
          <span class="company-details__tab" data-tab-selector=".company-details__tab-content--structure">
            Structure
          </span>
          <span class="company-details__tab-separator">|</span>
          <span class="company-details__tab" data-tab-selector=".company-details__tab-content--contacts">
            Contacts
          </span>
        </div>

        <div class="company-details__tab-content company-details__tab-content--about">
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Description
            </h1>
          </div>
          <div class="company-details__tab-main">
            <div class="company-details__description">
              <p>Full company description...</p>
            </div>
          </div>
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Additional Page Title
            </h1>
          </div>
          <div class="company-details__tab-main">
            <div class="company-details__description company-details__description--page">
              Additional Page Content...
            </div>
          </div>
        </div>



        <div class="company-details__tab-content company-details__tab-content--products">
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Products
            </h1>
          </div>
          <div class="company-details__tab-main">
            <div class="gjs-products-type-switch" translations="[{&quot;string&quot;:&quot;B2C&quot;,&quot;language&quot;:&quot;EN&quot;,&quot;translation&quot;:&quot;B2C&quot;},{&quot;string&quot;:&quot;B2B&quot;,&quot;language&quot;:&quot;EN&quot;,&quot;translation&quot;:&quot;B2B&quot;}]">
              <span class="gjs-products-type-switch__product-type gjs-products-type-switch__product-type--active">
                B2C
              </span>
              <div class="gjs-products-type-switch__toggle">
                <input class="gjs-products-type-switch__checkbox" type="checkbox" />
                <span class="gjs-products-type-switch__slider"></span>
              </div>
              <span class="gjs-products-type-switch__product-type">
                B2B
              </span>
            </div>
            <div class="company-details__products">
              ${productsWithPaginationContent()}
            </div>
          </div>
        </div>

        <div class="company-details__tab-content company-details__tab-content--files" style="display: none;">
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Photo gallery
            </h1>
          </div>
          <div class="company-details__tab-main">
            <div class="company-gallery">
              <img
                class="company-gallery__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="gallery image"
                onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              />
            </div>
          </div>
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Video gallery
            </h1>
          </div>
          <div class="company-details__tab-main">
            <div class="company-gallery">
              <video class="company-gallery__video" controls>
                <source
                  src="//${process.env.REACT_APP_STATIC_URL}/"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
              <video class="company-gallery__video" controls>
                <source
                  src="//${process.env.REACT_APP_STATIC_URL}/"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
              <video class="company-gallery__video" controls>
                <source
                  src="//${process.env.REACT_APP_STATIC_URL}/"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Documents
            </h1>
          </div>
            <div class="company-details__tab-main">
              <div class="company-gallery">
                <a class="company-gallery__document" href="#">
                  <div class="company-gallery__document-icon">
                    <svg version="1.1" height="100%" width="100%" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 317.001 317.001" style="enable-background:new 0 0 317.001 317.001;" xml:space="preserve">
                      <path d="M270.825,70.55L212.17,3.66C210.13,1.334,207.187,0,204.093,0H55.941C49.076,0,43.51,5.566,43.51,12.431V304.57
                      c0,6.866,5.566,12.431,12.431,12.431h205.118c6.866,0,12.432-5.566,12.432-12.432V77.633
                      C273.491,75.027,272.544,72.51,270.825,70.55z M55.941,305.073V12.432H199.94v63.601c0,3.431,2.78,6.216,6.216,6.216h54.903
                      l0.006,222.824H55.941z" />
                    </svg>
                  </div>
                  <div class="company-gallery__document-name">
                    Test pdf
                  </div>
                </a>
              </div>
            </div>
        </div>
        <div class="company-details__tab-content company-details__tab-content--structure">
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Staff
            </h1>
          </div>
          <div class="company-details__tab-main">
            <table class="company-staff">
              <thead>
                <tr>
                  <th class="company-staff__header">PHOTO</th>
                  <th class="company-staff__header">NAME</th>
                  <th class="company-staff__header">DEPARTMENT / POSITION</th>
                </tr>
              </thead>
              <tbody>
              
                  <tr class="company-staff__row">
                    <td>
                      <img class="company-staff__image" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" alt="User avatar image" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
                    </td>
                    <td>
                      <span class="company-staff__department">
                        Department
                      </span>
                    </td>
                    <td>
                      <span class="company-staff__department">
                        Department
                      </span>
                      <span class="company-staff__department-separator">
                        /  
                      </span>
                      <span class="company-staff__position">
                        Position
                      </span>
                    </td>
                  </tr>

                  <tr class="company-staff__row">
                    <td>
                      <img class="company-staff__image" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" alt="User avatar image" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
                    </td>
                    <td>
                      <span class="company-staff__department">
                        Department
                      </span>
                    </td>
                    <td>
                      <span class="company-staff__department">
                        Department
                      </span>
                      <span class="company-staff__department-separator">
                        /  
                      </span>
                      <span class="company-staff__position">
                        Position
                      </span>
                    </td>
                  </tr>

                  <tr class="company-staff__row">
                    <td>
                      <img class="company-staff__image" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" alt="User avatar image" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
                    </td>
                    <td>
                      <span class="company-staff__department">
                        Department
                      </span>
                    </td>
                    <td>
                      <span class="company-staff__department">
                        Department
                      </span>
                      <span class="company-staff__department-separator">
                        /  
                      </span>
                      <span class="company-staff__position">
                        Position
                      </span>
                    </td>
                  </tr>
                  
              </tbody>
            </table>
          </div>
        </div>
        <div class="company-details__tab-content company-details__tab-content--contacts" style="display: none;">
          <div class="company-details__tab-header">
            <h1 class="company-details__tab-title">
              Contacts
            </h1>
          </div>
          <div class="company-details__tab-main">
            <div class="company-contacts">
              <div class="company-contacts__item">
                <img class="company-contacts__icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACeUlEQVRIibWWz2vTYBjHP0lDd9HDyqALDQxrtYfBDoN16NbSk8JE8ORJUfBvENxf4P4IlYFXDwOnRbzZzg1nWQ49lHZlK/113KHNZeDiIW+065K3ydZ9IZc87/P9JHnfPM+jbG1tMUYKkAUeAStACpgWsRPgEPgJbANFwJaZaZKYCrwE1gXES3FxrQCvBfwtsAmc+Zl66RawB7yXwLyUEjl7wiMQMA/8ApZCgEa1JDxy44APgG/AzBVgrmaA78BDP2Aa+AREJwBzFRWe6VFgBPgI3JwgzNUN4R0ZBr7gans2Tks4Jx4V5z9bv0aYqzeAouGcJM+jn8lk0HU9lGu322V/f98rlAJyKrDmlzw7OxsKBhCPx2XhNRW47xc9Pj4ODTw6OpKF76nAHVlyvV7HtqXlEQDbtqnVarRaLdmyuyr/C/EF5XI5+v0+pVIJy7J8XSzLolgsYlkW2WxWBpyWFW80TWNxcZFer8fOzg7pdJq5ublza9yvsLCwEGjPNZwWI91pXdeJxWKYpkmv12N+fh6ASqVCJBIhn88TjQYqUCcaUB8HBJiammJ5eZl2u83BwQGKopBMJkkkEkFArmoasAusBs0wDAPDMMJAhrWrAl8um30JfdWAHzid+kK1aTab6LqOoiiB3GzbptPp+IUPgaKGM4NsAO9GV5imiWmawZ59vDaAM7dbbAK/J+XsobJg/GtPf4DnwOAaYAPgmWCc6/hV4ClwOkHYqfCsujdGZ5oC8ITJvOlAeBWGb3pNbQWcDl2+AqwsPAqjAb+5tApkgFdAIwSoIXIyDH3GYcmK9xnwAed0rQKPcXrnbc6P+g2cUf8zUMJn4nb1F1/SousFBVc6AAAAAElFTkSuQmCC">
                <div class="company-contacts__meta">
                  <h4 class="company-contacts__title">
                    Fax:
                  </h4>
                  <p class="company-contacts__text">
                    N/A
                  </p>
                </div>
              </div>

              <div class="company-contacts__item">
                <img class="company-contacts__icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACeUlEQVRIibWWz2vTYBjHP0lDd9HDyqALDQxrtYfBDoN16NbSk8JE8ORJUfBvENxf4P4IlYFXDwOnRbzZzg1nWQ49lHZlK/113KHNZeDiIW+065K3ydZ9IZc87/P9JHnfPM+jbG1tMUYKkAUeAStACpgWsRPgEPgJbANFwJaZaZKYCrwE1gXES3FxrQCvBfwtsAmc+Zl66RawB7yXwLyUEjl7wiMQMA/8ApZCgEa1JDxy44APgG/AzBVgrmaA78BDP2Aa+AREJwBzFRWe6VFgBPgI3JwgzNUN4R0ZBr7gans2Tks4Jx4V5z9bv0aYqzeAouGcJM+jn8lk0HU9lGu322V/f98rlAJyKrDmlzw7OxsKBhCPx2XhNRW47xc9Pj4ODTw6OpKF76nAHVlyvV7HtqXlEQDbtqnVarRaLdmyuyr/C/EF5XI5+v0+pVIJy7J8XSzLolgsYlkW2WxWBpyWFW80TWNxcZFer8fOzg7pdJq5ublza9yvsLCwEGjPNZwWI91pXdeJxWKYpkmv12N+fh6ASqVCJBIhn88TjQYqUCcaUB8HBJiammJ5eZl2u83BwQGKopBMJkkkEkFArmoasAusBs0wDAPDMMJAhrWrAl8um30JfdWAHzid+kK1aTab6LqOoiiB3GzbptPp+IUPgaKGM4NsAO9GV5imiWmawZ59vDaAM7dbbAK/J+XsobJg/GtPf4DnwOAaYAPgmWCc6/hV4ClwOkHYqfCsujdGZ5oC8ITJvOlAeBWGb3pNbQWcDl2+AqwsPAqjAb+5tApkgFdAIwSoIXIyDH3GYcmK9xnwAed0rQKPcXrnbc6P+g2cUf8zUMJn4nb1F1/SousFBVc6AAAAAElFTkSuQmCC">
                <div class="company-contacts__meta">
                  <h4 class="company-contacts__title">
                    Phone:
                  </h4>

                  <a class="company-contacts__link" href="tel:999-9999999">
                    999-9999999
                  </a>

                </div>
              </div>

              <div class="company-contacts__item">
                <img class="company-contacts__icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAACxUlEQVRIia2WTUsbURSGn/lgVDKBBAOBIMUaEzcjCqmW1kS7asFS2lVx0dJCf0Oh/oL6I2wpdNuF0I9QuquxSlC6cKGLiChIooJxkU2iMV3MjWbS+YhOXgjkzD33febO3Dn3SEtLS3hIAjLAY2AKGAbCYqwMFIA/wDdgGWi4makuYzLwGpgXEDtFxW8KeCvg74FPwIWTqZ1uA2vABxeYnYbFnDXh0RHwAZAHJq4BateE8Jj2Aj4EfgIRH7CmIsAv4JETcAT4AmhdgDWlCc+RdqACfAaCXYQ1pQtvpRX4Cpt3JkkS8XgcwzBQVbcN7akJzB2PjPmdzdtlJRIJDMMgHo8zMzNDb2+vH+g7QJIxd5Lt1h8cHLz8r+s66XQaTbvxKx4GpmVg1imjr6/PEgcCAfr7+28KBJiVgftOo+fn55b48PCQUqnkB3hPBhJOo8fHx5Z4a2uLRsO1VHopKXNViP9ToVCwxGNjY0iS5AcYdqqlAJycnLC3t3eVHQ5jGIYfIDLmEeOozc1NTk9PL+OhoSHGx8dRFAUATdNIJpPout4Jr6zMzc09BW45ZTQaDYrFItFolJ6eHgBCoRCxWIyzszNSqRSxWIyBgQGOjo6oVqtuwL8ysOp1W7VajZWVFctKdV0nlUoRCAQuV5rJZAiFQm5WqzLw3QvYhOZyOQ4ODhxzVFVldHTUzeaHDPzGPKk9Va/XWV9fJ5/PU6lUbHP29/edpheAZRWzB1kAFjuBAhSLRUqlEpFIhGg0SjAYpFqtsru7S7nsuAcXgAtJNFEKZltwp1PoNbUB3AXqze+wDrwE7J+TP1WAF4JhOfG3gedArYuwmvDcbl5orzRZ4BndWWlFeGVbL9qVtizmCb3hA7YhPLLtA061dBuYBN4AO9cA7Yg5k7Q8xla5NSoXwEfMLjoNPME8O+NYW/0dzFb/K5DDoeNu6h+jgbD8/nS84QAAAABJRU5ErkJggg==">
                <div class="company-contacts__meta">
                  <h4 class="company-contacts__title">
                    E-mail:
                  </h4>

                  <a class="company-contacts__link" href="mailto:sales@example.com">
                    sales@example.com
                  </a>

                </div>
              </div>

              <div class="company-contacts__item">
                <img class="company-contacts__icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAD9klEQVRIibWWW2tidxTFf8bjJGpMjlFjEBRzaSdINITUKalTaQi0MKVlnkoeWlroZ2jpfIJ+iimFvvZB6CU0fashl5aUiOZCGxMDQiKaY8SoIRy1D+eYyeX8nbZpF/jg3mevdS77v/Y2JRIJXgIT8CbwLhADJgCnnisD+8Aq8D2QBNrdyKQuuR7gE+CZLmIEr/6LAZ/p4l8CXwMtEakRRoF14HkXMSNM6DXrOsffEnwL+BWI/gOh24jqHPGXCb4N/AS47yHWgRv4GXhHJPgQ+BZ48B+IdfBA53zYCXSaxgx8AzhElQMDAwSDQVwuFzabjXa7Tb1ep1QqkcvlOD8/F5X269xzQLMj+DGCb2Y2mwmHwwQCARRFweFwkM/nkSSJkZERms0mY2NjHB4ekslkaLcNT0UUreOf96Cds2cisVgshsvlYmVlhf39fZrNJqlUiq2tLdrtNru7u6yurjIyMsLc3Bwmk0n0pF8Aph60TjJs/enpaSwWC8lkEkVR8Hq9FItFms0ml5eXKIrC8PAwpVKJZDKJ3W4nHA6LBCeAeA/wxCgryzJ+v590Ok2r1UKSJGRZplKpIEkSkiRxdnbG0NAQkiShqiqZTIbR0VH6+/tFok9MiUQiCTy+nZmZmSEQCIgKu+Lg4IB0Om2USkrAK0YZt9tNOp3m+PgY0L7nwsICa2trVKtVAJxOJ9FolOXl5as6v9+Pz+cT3curEi+M+AasViuKotBoNACwWCwAVKvVO7GLi4ur7iyXy0xMCN3QKfLS/w0S2ojx3k40Gg08Hg+tlmb6ZrMZ0F5j58lkWQbA4XjhF263m3q9LtIrS8CfRoKlUolQKEQoFLoRj0bv+sP8/PyN/9lsViT4hwSsYdClBwcHBAIB1tfXOT09BSAWi3FycnJFODk5yeDgIBsbGwB4vV5mZ2c5PDwUCa71AD8YZSqVCkdHR0QiEcxmM6qqUi6XkWUZVVVRVRVZllEUBVVVsVgsTE1Nkc1mqdVqIsEfe4Bf0Cb1HaRSKer1OvF4HI/HQ6FQwOPxIEkSvb29OJ1OCoUCXq+XeDxOpVJhZ2dHJLYPJM2Li4sAdeB9o6vy+TxWq5VIJILD4cBms2G32/H5fNjtdmRZZnx8nFwud+WvAnwO/G7Slygz2lrwmuhqu91OMBjE7XbT19cHaJ1cLBbJ5XJXZ1OATeB1ro2nJvAR8Bva/LqDWq3G9vZ2N1IRzoEPdY0bE38P+AC4/DesAlzqnHudwG2nWQKe6nd1X5zrXEvXg0bWtoQ2oTfvIbapcyzdToi8dA94BHwKCG3DAFm95hHXXuN1dNu8W8BXaFv0Y+A94A1gnJurfhZt1f8OWEGwcXfwF986UaqqFoh4AAAAAElFTkSuQmCC">
                <div class="company-contacts__meta">
                  <h4 class="company-contacts__title">
                    Site:
                  </h4>
                  <p class="company-contacts__text">N/A</p>
                </div>
              </div>
            </div>

            <div class="company-map">
              <iframe width="100%" height="360" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                src="https://maps.google.com/maps?z=14&output=embed">
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;

  blockManager.add("company-details", {
    label: `
      <img src="${icon}" alt="Company description block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Company Details
      </div>
    `,
    content,
    category: "B24Online Companies"
  });
}
