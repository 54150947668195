import React, { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { DefaultButton, LoadingSpinner } from "../../components";
import { missingImage } from "../../assets";

import { colorPalette } from "../../styles/colorPalette";
import "./orders.styles.scss";

let tableHead = [
  "ID",
  "PRODUCT DETAILS",
  "UNIT PRICE",
  "QUANTITY",
  "ORDER DATE",
  "REPEAT"
];

const Orders = ({
  orderDeals,
  addToCartRepeat,
  cart,
  areOrderDealsPending,
  isLastOrders = true
}) => {
  useEffect(() => {
    if (!isLastOrders) {
      tableHead = [
        "ID",
        "CUSTOMER",
        "PRODUCT DETAILS",
        "UNIT PRICE",
        "QUANTITY",
        "ORDER DATE",
        "STATUS"
      ];
    }
  }, [isLastOrders]);

  const { t } = useTranslation();

  return (
    <Table aria-label="orders table">
      {areOrderDealsPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <TableHead>
            <TableRow>
              {tableHead.map((cell, index) => (
                <TableCell key={index} align="center">
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {orderDeals &&
            orderDeals.map(
              ({
                id,
                total_cost,
                order_items,
                currency,
                paid_at,
                status,
                person_email,
                person_first_name,
                person_last_name,
                person_phone_number,
                created_at
              }) => (
                <TableBody key={id}>
                  {order_items.map(item => {
                    const productExist = cart.find(
                      i => i.product_id === item.id
                    );
                    const {
                      id,
                      image,
                      name,
                      content_type,
                      unit_price,
                      quantity,
                      categories
                    } = item;
                    return (
                      <TableRow key={id}>
                        <TableCell>{id}</TableCell>
                        {!isLastOrders && (
                          <>
                            <TableCell>
                              {person_first_name} {person_last_name}
                              <br />
                              {person_email}
                              <br />
                              {person_phone_number}
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <Link
                            to={
                              content_type === 84
                                ? `/product/b2b/${id}`
                                : `/product/b2c/${id}`
                            }
                          >
                            <div className="order-details">
                              <img
                                className="order-img"
                                src={image ? image : missingImage}
                                alt=""
                              />
                              <div className="order-details__description">
                                <div className="description-category">
                                  {categories[0] && categories[0].name}
                                </div>
                                <div className="description-name">
                                  {name.length > 30
                                    ? name.substring(0, 30) + "..."
                                    : name}{" "}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <strong>
                              {`${currency} ${unit_price}` || "No price"}
                            </strong>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            {quantity}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            {moment(paid_at || created_at).format(
                              "MMMM Do YYYY, h:mm"
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {isLastOrders ? (
                            <div>
                              {productExist ? (
                                <DefaultButton
                                  border={`1px solid ${colorPalette.colorPrimary.main}`}
                                  color={colorPalette.colorPrimary.main}
                                  fontWeight="bold"
                                  padding="7px 10px"
                                  disabled
                                >
                                  Added to cart
                                </DefaultButton>
                              ) : (
                                <DefaultButton
                                  onClick={() =>
                                    addToCartRepeat(id, content_type)
                                  }
                                  border={`1px solid ${colorPalette.colorPrimary.main}`}
                                  color={colorPalette.colorPrimary.main}
                                  fontWeight="bold"
                                  padding="7px 20px"
                                >
                                  {t("Add to cart")}
                                </DefaultButton>
                              )}
                            </div>
                          ) : (
                            <>{status}</>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )
            )}
        </>
      )}
    </Table>
  );
};

export default Orders;
