import icon from "./icon.png";

export default function multiLanguageTextBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const content = `
  <span data-type="multi-language-text">Text</span>
  `;

  blockManager.add("multi-language-text", {
    label: `
      <img src="${icon}" alt="Multi-language text block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Multi-language Text
      </div>
    `,
    content,
    styles: {
      display: "block-inline"
    },
    category: "B24Online Translations"
  });

  // Make profile avatar classes private
  const multiLanguageTextClasses = [`.${className}`, `.${className}__image`];
  editor.on(
    "selector:add",
    selector =>
      multiLanguageTextClasses.indexOf(selector.getFullName()) >= 0 &&
      selector.set("private", 1)
  );
}
