import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { scrollToRef } from "../../shared";
import { BuilderSitesCard } from "../../components";

import { getSites, deleteSite } from "../../redux/actions-exporter";

import "./builder-sites.styles.scss";

const BuilderSites = ({
  areSitesLoaded,
  isSitesPending,
  getSites,
  sites,
  sitesPagination,
  deleteSite
}) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const builderRef = useRef(null);

  useEffect(() => {
    if (!isSitesPending) {
      getSites(page, search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSites, page, search, areSitesLoaded]);
  // Handle sites search
  const onSearch = e => {
    setSearch(e.target.value);
    setPage(1);
  };
  // Handle site deletion processing
  const onDeleteSite = siteId => async e => {
    deleteSite(siteId);
    setPage(1);
  };

  useEffect(() => {
    scrollToRef();
  }, [page]);

  return (
    <div className="builder-sites-page">
      <BuilderSitesCard
        {...{
          loading: isSitesPending,
          sites,
          sitesPagination,
          sitesSelectPage: setPage,
          search,
          onSearch,
          onDeleteSite,
          builderPath: "/newbuilder/site/",
          detailsPath: "/builder/sites/id/",
          createPath: "/builder/sites/create",
          builderRef
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areSitesLoaded: state.sitesReducer.areSitesLoaded,
  isSitesPending: state.sitesReducer.isSitesPending,
  sites: state.sitesReducer.sites,
  sitesPagination: state.sitesReducer.sitesPagination,
  isSitePending: state.sitesReducer.isSitePending
});

export default connect(mapStateToProps, {
  getSites,
  deleteSite
})(BuilderSites);
