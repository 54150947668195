import React from "react";

import "./default-textarea.styles.scss";

const DefaultTextArea = ({ label, required, onChange, name, value }) => {
  return (
    <div className="default-textarea">
      {label && (
        <div>
          <div style={{ display: "flex" }}>
            <div className="default-textarea__label">{label}</div>
            {required && (
              <div className="default-textarea__required">* required</div>
            )}
          </div>
          <br />
        </div>
      )}
      <textarea
        name={name}
        onChange={onChange}
        value={value}
        className="default-textarea__textarea"
      />
    </div>
  );
};

export default DefaultTextArea;
