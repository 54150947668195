import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Collapsible from "react-collapsible";
import SearchIcon from "@material-ui/icons/Search";

import { ExpandArrow } from "@core/components";

import "./default-multi-level-dropdown.styles.scss";

const DropdownTrigger = ({ triggerTitle, dropdownState }) => {
  return (
    <div className="default-dropdown-trigger">
      <div className="default-dropdown-trigger__title">{triggerTitle}</div>
      <div className="default-dropdown-trigger__icon">
        <ExpandArrow expand={dropdownState} />
      </div>
    </div>
  );
};

const ChildLevel = ({
                      children,
                      setTriggerTitle,
                      returnData
                    }) => {
  return (
    <div className="default-dropdown__subitems">
      {children &&
      children.map(child => {
        const { id, name } = child;
        return (
          <>
            <div
              key={id}
              className="default-dropdown__item-children"
              onClick={e => {
                setTriggerTitle(e.currentTarget.textContent);
                returnData(id);
              }}
            >
              {name}
            </div>
            {child?.children?.length > 0 &&
            <ChildLevel
              children={child.children}
              setTriggerTitle={setTriggerTitle}
              returnData={returnData}
            />
            }
          </>
        );
      })}
    </div>
  );
};

const DefaultMultiLevelDropdown = ({
                                     label,
                                     items = [],
                                     style,
                                     returnData,
                                     inputFunc,
                                     filter = false,
                                     customTitle,
                                     required
                                   }) => {
  const { t } = useTranslation();
  const [triggerTitle, setTriggerTitle] = useState(t("Select a value"));
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setTriggerTitle(customTitle ? customTitle : t("Select a value"));
  }, [setTriggerTitle, customTitle]); // eslint-disable-line

  return (
    <div style={style} className="default-dropdown">
      {label && (
        <>
          <div style={{ display: "flex" }}>
            <div className="default-dropdown__label">{label}</div>
            {required && (
              <div className="default-dropdown-trigger--required">
                {t("* required")}
              </div>
            )}
          </div>
          <br />{" "}
        </>
      )}
      <div
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="default-dropdown__container"
      >
        <Collapsible
          easing="none"
          triggerDisabled={true}
          open={true}
          trigger={
            <DropdownTrigger
              dropdownState={dropdownOpen}
              triggerTitle={triggerTitle}
              required={required}
            />
          }
        >
          {dropdownOpen && (
            <div className="default-dropdown__body">
              {filter && (
                <div
                  onClick={e => e.stopPropagation()}
                  className="default-dropdown__input-container"
                >
                  <input
                    onChange={inputFunc}
                    className="default-dropdown__input-container__input"
                    type="text"
                  />
                  <SearchIcon
                    style={{ color: "lightgray " }}
                    className="default-dropdown__input-container__icon"
                  />
                </div>
              )}
              {items.map(item => {
                const { id, name, children } = item;
                return (
                  <Fragment key={id}>
                    <div
                      key={id}
                      onClick={e => {
                        setTriggerTitle(e.currentTarget.textContent);
                        returnData(id);
                      }}
                      className="default-dropdown__item"
                    >
                      {name}
                    </div>
                    {children.length > 0 &&
                    <ChildLevel
                      children={children}
                      setTriggerTitle={setTriggerTitle}
                      returnData={returnData}
                    />
                    }
                  </Fragment>
                );
              })}
            </div>
          )}
        </Collapsible>
      </div>
    </div>
  );
};

export default DefaultMultiLevelDropdown;
