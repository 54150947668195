import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { missingImage } from "../../../assets";
import { withDebounce } from "../../../shared";

import {
  SearchBar,
  LoadingSpinner,
  ProductSwitch,
  ImgCard,
  MyBusinessNoData,
  Pagination,
  ConfirmDialog
} from "../../../components";

import {
  getUserB2BProducts,
  deleteProduct,
  getUserB2CProducts,
  deleteB2CProduct,
  selectProductType
} from "../../../redux/actions-exporter";

import "./my-business-products.styles.scss";

const MyBusinessProducts = ({
  getUserB2BProducts,
  areUserB2BProductsPending,
  userB2BProducts,
  userB2BProductsCount,

  getUserB2CProducts,
  areUserB2CProductsPending,
  userB2CProducts,
  userB2CProductsCount,

  deleteProduct,
  deleteB2CProduct,

  selectProductType
}) => {
  const [b2bSearchValue, setB2BSearchValue] = useState("");
  const [b2bProductPage, setB2BProductPage] = useState(1);
  const [b2cSearchValue, setB2CSearchValue] = useState("");
  const [b2cProductPage, setB2CProductPage] = useState(1);
  const [perPage] = useState(9);
  const [currentProductId, setCurrentProductId] = useState(0);

  const [toggle, setToggle] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    !toggle
      ? getUserB2BProducts(b2bSearchValue, b2bProductPage, perPage)
      : getUserB2CProducts(b2cSearchValue, b2cProductPage, perPage);
  }, [
    toggle,
    getUserB2BProducts,
    getUserB2CProducts,
    b2bSearchValue,
    b2bProductPage,
    b2cSearchValue,
    b2cProductPage,
    perPage
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [b2bProductPage, b2cProductPage]);

  useEffect(() => {
    setB2BProductPage(1);
    setB2CProductPage(1);
  }, [b2bSearchValue, b2cSearchValue]);

  const userProductsHandler = withDebounce(e =>
    !toggle ? setB2BSearchValue(e) : setB2CSearchValue(e)
  );

  return (
    <div className="my-business-products">
      <ConfirmDialog
        dialogTitle={`Product ${!toggle ? "B2B" : "B2C"} remove`}
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          !toggle
            ? deleteProduct(currentProductId)
            : deleteB2CProduct(currentProductId);
          setDialogOpen(false);
          setCurrentProductId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => userProductsHandler(e.target.value)} />
      </div>
      <ProductSwitch isToggled={toggle} onToggle={() => setToggle(!toggle)} />
      {areUserB2BProductsPending || areUserB2CProductsPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="my-business-products__body">
            {!toggle ? (
              userB2BProducts && userB2BProducts.length ? (
                userB2BProducts.map(
                  ({
                    id,
                    name,
                    image,
                    cost,
                    categories,
                    currency,
                    discount_percent,
                    coupon_discount_percent
                  }) => (
                    <ImgCard
                      shortDescription={
                        categories[0] ? categories[0].name : "No category"
                      }
                      text={name}
                      imgSrc={image ? image : missingImage}
                      price={cost}
                      currency={currency}
                      navLink={`/product/b2b/${id}`}
                      key={id}
                      discountPercent={discount_percent}
                      couponDiscountPercent={coupon_discount_percent}
                      editorState={true}
                      editLink={`/my-business/products/b2b/${id}`}
                      type="products"
                      removeFunc={() => {
                        setDialogOpen(true);
                        setCurrentProductId(id);
                      }}
                      editFunc={() => selectProductType("b2b")}
                      scaleDown={true}
                    />
                  )
                )
              ) : (
                <MyBusinessNoData noDataText="No B2B Products!" />
              )
            ) : userB2CProducts && userB2CProducts.length ? (
              userB2CProducts.map(
                ({
                  id,
                  name,
                  image,
                  cost,
                  categories,
                  currency,
                  discount_percent,
                  coupon_discount_percent
                }) => (
                  <ImgCard
                    shortDescription={
                      categories[0] ? categories[0].name : "No category"
                    }
                    text={name}
                    imgSrc={image ? image : missingImage}
                    price={cost}
                    currency={currency}
                    navLink={`/product/b2c/${id}`}
                    key={id}
                    discountPercent={discount_percent}
                    couponDiscountPercent={coupon_discount_percent}
                    editorState={true}
                    editLink={`/my-business/products/b2c/${id}`}
                    type="products"
                    removeFunc={() => {
                      setDialogOpen(true);
                      setCurrentProductId(id);
                    }}
                    editFunc={() => selectProductType("b2c")}
                    scaleDown={true}
                  />
                )
              )
            ) : (
              <MyBusinessNoData noDataText="No B2C Products" />
            )}
          </div>
        </>
      )}
      <div className="my-business-products__pagination">
        <Pagination
          forcePage={!toggle ? b2bProductPage - 1 : b2cProductPage - 1}
          dataCount={!toggle ? userB2BProductsCount : userB2CProductsCount}
          selectedPage={page =>
            !toggle ? setB2BProductPage(page) : setB2CProductPage(page)
          }
          itemsPerPage={10}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  areUserB2BProductsPending: state.b2BProductsReducer.areUserB2BProductsPending,
  userB2BProductsCount: state.b2BProductsReducer.userB2BProductsCount,
  userB2BProducts: state.b2BProductsReducer.userB2BProducts,

  areUserB2CProductsPending: state.b2cProductsReducer.areUserB2CProductsPending,
  userB2CProductsCount: state.b2cProductsReducer.userB2CProductsCount,
  userB2CProducts: state.b2cProductsReducer.userB2CProducts
});

export default connect(mapStateToProps, {
  getUserB2BProducts,
  getUserB2CProducts,

  deleteProduct,
  deleteB2CProduct,

  selectProductType
})(MyBusinessProducts);
