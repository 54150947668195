import { SalesAndCouponsActionTypes } from "./sales-and-coupons.types";

const INITIAL_STATE = {
  areProductsWithCouponDiscountPending: false,
  productsWithCouponDiscount: null,
  productsWithCouponDiscountError: null,

  areProductsWithSimpleDiscountPending: false,
  productsWithSimpleDiscount: null,
  productsWithSimpleDiscountError: null
};

const SalesAndCouponsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SalesAndCouponsActionTypes.GET
      .GET_PRODUCTS_WITH_COUPON_DISCOUNT_PENDING:
      return {
        ...state,
        areProductsWithCouponDiscountPending: true
      };
    case SalesAndCouponsActionTypes.GET
      .GET_PRODUCTS_WITH_COUPON_DISCOUNT_SUCCESS:
      return {
        ...state,
        productsWithCouponDiscount: action.payload,
        areProductsWithCouponDiscountPending: false
      };
    case SalesAndCouponsActionTypes.GET.GET_PRODUCTS_WITH_COUPON_DISCOUNT_ERROR:
      return {
        ...state,
        productsWithCouponDiscountError: action.payload,
        areProductsWithCouponDiscountPending: false
      };

    case SalesAndCouponsActionTypes.GET
      .GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_PENDING:
      return {
        ...state,
        areProductsWithSimpleDiscountPending: true
      };
    case SalesAndCouponsActionTypes.GET
      .GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_SUCCESS:
      return {
        ...state,
        productsWithSimpleDiscount: action.payload,
        areProductsWithSimpleDiscountPending: false
      };
    case SalesAndCouponsActionTypes.GET.GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_ERROR:
      return {
        ...state,
        productsWithSimpleDiscountError: action.payload,
        areProductsWithSimpleDiscountPending: false
      };

    default:
      return state;
  }
};

export default SalesAndCouponsReducer;
