import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { resetPasswordConfirm } from "~/redux/actions-exporter";
import { DefaultButton, DefaultSnackbar } from "@core/components";
import { colorPalette } from "~/styles/colorPalette";

const ResetPasswordForm = () => {
  const resetPasswordPending = useSelector(state => state.authReducer.resetPasswordPending);
  const resetPasswordConfirmResult = useSelector(state => state.authReducer.resetPasswordConfirmResult);
  const resetPasswordConfirmError = useSelector(state => state.authReducer.resetPasswordConfirmError);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageStatus, setMessageStatus] = useState("success");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const uid = params.get("uid");
  const token = params.get("token");

  const history = useHistory();

  useEffect(() => {
    if (resetPasswordConfirmResult) {
      setMessage(t("Password successfully changed!"));
      setMessageStatus("success");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        history.push("/signin");
      }, 3000);
    }
  }, [resetPasswordConfirmResult]);

  useEffect(() => {
    if (resetPasswordConfirmError) {
      setMessage(t("The error occurred"));
      setMessageStatus("error");
      setShowMessage(true);
    }
  }, [resetPasswordConfirmError]);

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <p>{t("New password")}</p>
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
        <p>{t("Confirm password")}</p>
        <input
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          type="password"
        />
        <br />
        <DefaultButton
          onClick={() => dispatch(resetPasswordConfirm(uid, token, password))}
          color="white"
          backgroundColor={colorPalette.colorPrimary.main}
          hover={colorPalette.colorPrimary.darker}
          width="100%"
          disabled={resetPasswordPending || password === "" || confirmPassword === "" || (password !== confirmPassword)}
          ButtonOptions={{
            type: "submit"
          }}
        >
          {t("Set Password")}
        </DefaultButton>
      </form>
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          variant={messageStatus}
          message={message}
          autoHideDuration={2000}
        />
      )}
    </>
  );
};

export default ResetPasswordForm;