import { ClientActionTypes } from "./clients.types";

const INITIAL_STATE = {
  areClientsPending: false,
  isClientPending: false,

  clients: [],
  clientsError: null,

  client: {},
  clientError: {},

  createdClient: {},
  createdClientError: {},

  updatedClient: {},
  updatedClientError: {},

  deletedClient: {},
  deletedClientError: {},
  clientsRequestStatus: ""
};

const CompaniesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ClientActionTypes.GET.GET_CLIENTS_PENDING:
      return {
        ...state,
        areClientsPending: true
      };

    case ClientActionTypes.GET.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        areClientsPending: false,
        clients: action.payload
      };

    case ClientActionTypes.GET.GET_CLIENTS_ERROR:
      return {
        ...state,
        areClientsPending: false,
        clientsError: action.payload
      };

    case ClientActionTypes.GET.GET_CLIENT_PENDING:
      return {
        ...state,
        isClientPending: true
      };

    case ClientActionTypes.CREATE.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        createdClient: action.payload,
        clientRequestStatus: action.payload.requestStatus
      };

    case ClientActionTypes.CREATE.CREATE_CLIENT_ERROR:
      return {
        ...state,
        clientRequestStatus: action.payload.requestStatus
      };

    case ClientActionTypes.UPDATE.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        updatedClient: action.payload,
        clientRequestStatus: action.payload.requestStatus
      };

    case ClientActionTypes.UPDATE.UPDATE_CLIENT_ERROR:
      return {
        ...state,
        clientRequestStatus: action.payload.requestStatus
      };

    case ClientActionTypes.DELETE.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        deletedClient: action.payload,
        clientRequestStatus: action.payload.requestStatus
      };

    case ClientActionTypes.DELETE.DELETE_CLIENT_ERROR:
      return {
        ...state,
        clientRequestStatus: action.payload.requestStatus
      };

    case ClientActionTypes.CLEAR_CLIENTS_REQUEST:
      return { ...state, clientRequestStatus: action.payload };

    default:
      return state;
  }
};

export default CompaniesReducer;
