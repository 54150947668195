export default function authRegistrationSubmitComponent({ editor, api }) {
  const script = function() {
    const API_ROOT = "{[ apiRoot ]}";

    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function render(translations, redirect, API_ROOT) {
      const buttonEl = document.querySelector(".registration-submit");
      const getFormEl = function() {
        return document.querySelector(".registration-form");
      };
      const getMessagesWrapperEl = function() {
        return document.querySelector(".registration-form__messages");
      };

      function buttonClick(e) {
        e.preventDefault();

        getFormEl().removeEventListener("submit", submit, true);
        getFormEl().addEventListener("submit", submit, true);

        const form = document.forms.namedItem(getFormEl().name);
        if (form.__proto__.hasOwnProperty("requestSubmit")) {
          form.requestSubmit();
        } else if (form.checkValidity()) {
          form.submit();
        }
      }

      function submit(e) {
        e.preventDefault();
        const form = document.forms.namedItem(e.target.name);
        const data = new FormData(form);

        const password = data
          .get("password")
          .split(" ")
          .join("");
        const passwordConfirm = data
          .get("confirm_password")
          .split(" ")
          .join("");

        if (password !== passwordConfirm) {
          getMessagesWrapperEl().innerHTML = `
              <span class="registration-form__message">
                ${translations["Password mismatch."]}
              </span>
            `;
          return;
        }

        if (password.length < 6) {
          getMessagesWrapperEl().innerHTML = `
              <span class="registration-form__message">
                ${translations["Password must be at least six characters."]}
              </span>
            `;
          return;
        }

        const bodyData = {};
        data.forEach((value, key) => {
          bodyData[key] = value;
        });

        fetch(`${API_ROOT}/auth/users/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(bodyData)
        })
          .then(response => {
            if (response.status !== 201) {
              throw new Error("REGISTRATION_FAILED");
            }

            fetch(`${API_ROOT}/auth/token/login/`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                email: data.get("email"),
                password: data.get("password")
              })
            }).then(response => {
              if (!response.ok) {
                throw new Error("LOGIN_FAILED");
              }

              response.json().then(json => {
                window.localStorage.setItem("token", json.auth_token);
                window.location.assign(redirect);
              });
            });
          })
          .catch(error => {
            getMessagesWrapperEl().innerHTML = `
              <span class="registration-form__message">
                ${translations["Unable to register with provided credentials."]}
              </span>
            `;
          });
      }

      // TODO: дубликаты запросов из-за того что event не удаляется (функция каждый раз пересоздается)
      buttonEl.removeEventListener("click", buttonClick, true);
      buttonEl.addEventListener("click", buttonClick, true);
    }
    // Get translation & render
    const translations = getTranslate("translations", this.attributes);
    if (translations && Object.keys(translations).length) {
      // Get button element
      const buttonEl = document.querySelector(".registration-submit");
      // Change button element text
      buttonEl.textContent = translations["SUBMIT"];

      // Get error element
      const errorEl = document.querySelector(".registration-form__message");
      // Change button element text
      if (errorEl) {
        errorEl.textContent =
          translations["Unable to register with provided credentials."];
      }

      // Get email input element
      const emailInputEl = document.querySelector(
        ".registration-form__input[type=email]"
      );
      // Change email input placeholder
      emailInputEl.setAttribute("placeholder", translations["E-mail"]);

      // Get password input element
      const passwordInputEl = document.querySelector(
        ".registration-form__input[type=password]"
      );
      // Change password input placeholder
      passwordInputEl.setAttribute("placeholder", translations["Password"]);

      // Get confirm password input element
      const confirmPasswordInputEl = document.querySelector(
        ".registration-form__input[type=password]"
      );
      // Change confirm password input placeholder
      confirmPasswordInputEl.setAttribute(
        "placeholder",
        translations["Confirm password"]
      );

      // Render
      if (window.hasOwnProperty("grapesjs")) {
        render(
          translations,
          (this.attributes.redirect && this.attributes.redirect.value) || "#",
          API_ROOT
        );
      }
    }
  };

  editor.DomComponents.addType("auth-registration-submit", {
    isComponent: el =>
      el.tagName === "BUTTON" && el.className === "registration-submit",
    model: {
      defaults: {
        name: "Registration Submit",
        apiRoot: api.API_ROOT,
        script,
        traits: [
          {
            type: "page-selector",
            label: "Redirect",
            name: "redirect",
            modalTitle: "Select redirect page after successful registration"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "E-mail",
              "Password",
              "Confirm password",
              "Password mismatch.",
              "Password must be at least six characters.",
              "Unable to register with provided credentials.",
              "SUBMIT"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:redirect", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
