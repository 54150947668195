export default class ApiService {
  /**
   * @constructor
   * @param {string} token token
   */
  constructor({ token = "" } = {}) {
    this.version = "v1";
    this.schema = `//`;
    this.host = process.env.REACT_APP_API_URL;
    this.hostBuilder = process.env.REACT_APP_API_BUILDER_URL;
    this.API_ROOT = `${this.schema}${this.host}/api/${this.version}`;
    this.API_ROOT_BUILDER = `${this.schema}${this.hostBuilder}/api/${this.version}`;
    this.STATIC_ROOT = `//${process.env.REACT_APP_STATIC_URL}`;
    this.PORTAL_URL = `//${process.env.REACT_APP_PORTAL_URL}`;
    this.token = token;
  }

  _request = async ({
    route,
    method = "GET",
    headers = {
      "Content-Type": "application/json"
    },
    params = {},
    validStatus = 200,
    statusErrorMessage = "API_SERVICE_REQUEST_FAILED",
    json = true
  }) => {
    try {
      // Make request and get response
      const url =
        route.indexOf("/builder/") > -1 ? this.API_ROOT_BUILDER : this.API_ROOT;
      const response = await fetch(`${url}${route}`, {
        method,
        headers: {
          Accept: "application/json",
          Authorization: `Token ${this.token}`,
          ...headers
        },
        mode: "cors",
        credentials: "omit",
        ...params
      });
      // If 5xx error throw error
      if (response.status.toString().slice(0, 1) === "5") {
        throw new Error("SERVER_DOWN");
      }
      // Check route status
      if (response.status !== validStatus) {
        throw new Error(statusErrorMessage);
      }
      // Parse JSON from response and return
      if (json) {
        return response.json();
      } else {
        return response;
      }
    } catch (error) {
      // Log error
      console.error(error);
    }
  };

  /**
   * Receiving a token by user email and password
   * @param {string} email user email
   * @param {string} password user password
   */
  login = async ({ email, password }) => {
    const { auth_token: token } = await this._request({
      method: "POST",
      route: "/auth/token/login/",
      params: {
        body: JSON.stringify({ email, password })
      },
      statusErrorMessage: "AUTH_LOGIN_BAD_CREDENTIALS"
    });

    this.token = token;

    return token;
  };

  /**
   * Get site by ID
   * @param {number} id site ID
   */
  getSite = async ({ id }) => {
    return this._request({
      route: `/builder/site/${id}/`,
      statusErrorMessage: "GET_SITE_FAILED"
    });
  };

  /**
   * Create site
   * @param {string} title site title
   * @param {string} description site description
   * @param {string} name site name
   * @param {string} domain site domain
   * @param {string} organization organization ID
   */
  createSite = async ({
    title,
    description,
    site: { name, domain },
    organization
  }) => {
    return this._request({
      method: "POST",
      route: "/builder/site/",
      params: {
        body: JSON.stringify({
          title,
          description,
          site: { name, domain },
          organization
        })
      },
      validStatus: 201,
      statusErrorMessage: "CREATE_SITE_FAILED"
    });
  };

  /**
   * Update site.
   */
  updateSite = async ({ id, title, domain }) => {
    return this._request({
      method: "PATCH",
      route: `/builder/site/${id}/`,
      params: {
        body: JSON.stringify({
          site: {
            name: title,
            domain
          }
        })
      },
      validStatus: 200,
      statusErrorMessage: "UPDATE_SITE_FAILED"
    });
  };

  /**
   * Get site pages
   * @param {number} siteId site ID
   * @param {number} page page number
   */
  getPages = async ({ siteId, page = 1, path = "", lite = true }) => {
    const route = lite ? `/builder/pages_lite/` : `/builder/pages/`;
    return this._request({
      route: `${route}?resource_id=${siteId}&page=${page}&path=${path}`,
      statusErrorMessage: "GET_PAGES_FAILED"
    });
  };

  /**
   * Get site page
   * @param {number} siteId site ID
   * @param {number} pageId page Id
   */
  getPage = async ({ siteId, pageId }) => {
    return this._request({
      route: `/builder/pages/${pageId}/?resource_id=${siteId}`,
      statusErrorMessage: "GET_PAGE_FAILED"
    });
  };

  /**
   * Create site page
   * @param {string} title page title
   * @param {string} path page path
   * @param {number} siteId site ID
   * @param {object} props Additional fields to request
   */
  createPage = async ({ title, path, siteId, props = {} }) => {
    return this._request({
      method: "POST",
      route: "/builder/pages/",
      params: {
        body: JSON.stringify({
          title,
          path,
          resource: siteId,
          ...props
        })
      },
      validStatus: 201,
      statusErrorMessage: "CREATE_PAGE_FAILED"
    });
  };

  /**
   * Update page by ID
   * @param {number} id page ID
   * @param {string} title page title
   * @param {string} path page path
   * @param {object} gjs grapes.js data
   */
  updatePage = async ({ siteId, id, title, path, gjs = {} }) => {
    return this._request({
      method: "POST",
      route: `/builder/pages/${id}/save/?resource_id=${siteId}`,
      params: {
        body: JSON.stringify({
          id,
          title,
          path,
          ...gjs
        })
      },
      validStatus: 200,
      statusErrorMessage: "UPDATE_PAGE_FAILED"
    });
  };

  /**
   * Delete page by ID
   * @param {number} id page ID
   */
  deletePage = async ({ id, siteId }) => {
    return this._request({
      method: "DELETE",
      route: `/builder/pages/${id}/?resource_id=${siteId}`,
      validStatus: 204,
      statusErrorMessage: "DELETE_PAGE_FAILED",
      json: false
    });
  };

  /**
   * Get temaplte collection
   * @param {string} search search string
   * @param {number} page page number
   */
  getTemplateCollection = async ({ search = "", page = 1 } = {}) => {
    return this._request({
      route: `/builder/theme/?search=${search}&page=${page}`,
      statusErrorMessage: "GET_TEMPLATE_COLLECTION_FAILED"
    });
  };

  /**
   * Create temlate collection tags
   * @param {array} tags array of tags strings
   */
  createTemplateCollectionTags = async ({ tags }) => {
    return Promise.all(
      tags.map(tag =>
        this._request({
          method: "POST",
          route: "/builder/theme_tags/",
          params: {
            body: JSON.stringify({
              title: tag
            })
          },
          validStatus: 201,
          statusErrorMessage: "CREATE_TEMPLATE_COLLECTION_TAG_FAILED"
        })
      )
    );
  };

  /**
   * Delete template collection tags
   * @param {array} tags array of tags IDs
   */
  deleteTemplateCollectionTags = async ({ tags }) => {
    return Promise.all(
      tags.map(id =>
        this._request({
          method: "DELETE",
          route: `/builder/theme_tags/${id}/`,
          validStatus: 204,
          statusErrorMessage: "DELETE_TEMPLATE_COLLECTION_TAG_FAILED",
          json: false
        })
      )
    );
  };

  /**
   * Get template collection categories
   */
  getTemplateCollectionCategories = async () => {
    return this._request({
      method: "GET",
      route: "/builder/theme_categories/",
      validStatus: 200,
      statusErrorMessage: "GET_TEMPLATE_COLLECTION_CATEGORY_FAILED"
    });
  };

  /**
   * Create template collection categories
   * @param {array} categories array of category strings
   */
  createTemplateCollectionCategories = async ({ categories }) => {
    return Promise.all(
      categories.map(category =>
        this._request({
          method: "POST",
          route: "/builder/theme_categories/",
          params: {
            body: JSON.stringify({
              name: category
            })
          },
          validStatus: 201,
          statusErrorMessage: "CREATE_TEMPLATE_COLLECTION_CATEGORY_FAILED"
        })
      )
    );
  };

  /**
   * Delete template collection categories
   * @param {array} categories array of category IDs
   */
  deleteTemplateCollectionCategories = async ({ categories }) => {
    return Promise.all(
      categories.map(id =>
        this._request({
          method: "DELETE",
          route: `/builder/theme_categories/${id}/`,
          validStatus: 204,
          statusErrorMessage: "DELETE_TEMPLATE_COLLECTION_CATEGORY_FAILED",
          json: false
        })
      )
    );
  };

  /**
   * Create temlate collection
   * @param {string} title template collection title
   * @param {array} tags array of tags strings
   * @param {array} categories array of category strings
   */
  createTemplateCollection = async ({ title, tags, categories }) => {
    const tagsArray = await this.createTemplateCollectionTags({ tags });
    const categoriesArray = await this.createTemplateCollectionCategories({
      categories
    });

    return this._request({
      method: "POST",
      route: "/builder/theme/",
      params: {
        body: JSON.stringify({
          title,
          tags: tagsArray.map(i => i.id),
          categories: categoriesArray.map(i => i.id)
        })
      },
      validStatus: 201,
      statusErrorMessage: "CREATE_TEMPLATE_COLLECTION_FAILED"
    });
  };

  /**
   * Update template by ID
   * @param {number} id template ID
   * @param {string} title template title
   * @param {array} tags array of tags strings
   * @param {array} categories array of category strings
   * @param {array} templates templates array
   */
  updateTemplateCollection = async ({
    id,
    title,
    tags,
    categories,
    templates
  }) => {
    const tagsArray = await this.createTemplateCollectionTags({ tags });
    const categoriesArray = await this.createTemplateCollectionCategories({
      categories
    });

    return this._request({
      method: "PATCH",
      route: `/builder/theme/${id}/`,
      params: {
        body: JSON.stringify({
          id,
          title,
          tags: tagsArray.map(i => i.id),
          categories: categoriesArray.map(i => i.id),
          templates
        })
      },
      validStatus: 200,
      statusErrorMessage: "UPDATE_TEMPLATE_COLLECTION_FAILED"
    });
  };

  /**
   * Delete template collection by ID
   * @param {number} id template collection ID
   */
  deleteTemplateCollection = async ({ id }) => {
    return this._request({
      method: "DELETE",
      route: `/builder/theme/${id}/`,
      validStatus: 204,
      statusErrorMessage: "DELETE_TEMPLATE_COLLECTION_FAILED",
      json: false
    });
  };

  /**
   * Get templates
   */
  getTemplates = async () => {
    return this._request({
      route: "/builder/template/",
      statusErrorMessage: "GET_TEMPLATES_FAILED"
    });
  };

  /**
   * Get sitemaps
   * @param {number} themeId theme ID
   */
  getSitemap = async ({ themeId }) => {
    return this._request({
      route: `/builder/sitemap/?theme=${themeId}`,
      statusErrorMessage: "GET_SITEMAP_FAILED"
    });
  };

  /**
   * Delete sitemap
   * @param {number} id sitemap ID
   */
  deleteSitemap = async ({ id }) => {
    return this._request({
      method: "DELETE",
      route: `/builder/sitemap/${id}/`,
      validStatus: 204,
      statusErrorMessage: "DELETE_SITEMAP_FAILED",
      json: false
    });
  };

  /**
   * Create template
   * @param {number} themeId theme ID
   * @param {string} title template title
   * @param {string} path template path
   * @param {object} gjs grapes.js data
   */
  createTemplate = async ({ themeId, title, path, gjs = {} }) => {
    const template = await this._request({
      method: "POST",
      route: `/builder/template/`,
      params: {
        body: JSON.stringify({
          title,
          ...gjs
        })
      },
      validStatus: 201,
      statusErrorMessage: "CREATE_TEMPLATE_FAILED"
    });

    return this._request({
      method: "POST",
      route: `/builder/sitemap/`,
      params: {
        body: JSON.stringify({
          theme: themeId,
          template: template.id,
          title,
          path
        })
      },
      validStatus: 201,
      statusErrorMessage: "CREATE_SITEMAP_FAILED"
    });
  };

  /**
   * Update template
   * @param {number} id template ID
   * @param {number} sitemapId sitemap ID
   * @param {string} title template title
   * @param {string} path template path
   * @param {File} thumbnail template image file
   * @param {object} gjs grapes.js data
   */
  updateTemplate = async ({
    id,
    sitemapId,
    title,
    path,
    thumbnail,
    gjs = {}
  }) => {
    const template = await this._request({
      method: "PATCH",
      route: `/builder/template/${id}/`,
      params: {
        body: JSON.stringify({
          title,
          ...gjs
        })
      },
      validStatus: 200,
      statusErrorMessage: "UPDATE_TEMPLATE_FAILED"
    });

    if (thumbnail) {
      const formData = new FormData();
      formData.append("thumbnail", thumbnail);

      await this._request({
        method: "PATCH",
        route: `/builder/template/${id}/`,
        headers: {},
        params: {
          body: formData
        },
        validStatus: 200,
        statusErrorMessage: "UPDATE_TEMPLATE_THUMBNAIL_FAILED"
      });
    }

    if (sitemapId) {
      await this._request({
        method: "PATCH",
        route: `/builder/sitemap/${sitemapId}/`,
        params: {
          body: JSON.stringify({
            title,
            path
          })
        },
        validStatus: 200,
        statusErrorMessage: "UPDATE_SITEMAP_FAILED"
      });
    }

    return template;
  };

  /**
   * Delete template
   * @param {number} id template ID
   */
  deleteTemplate = async ({ id }) => {
    return this._request({
      method: "DELETE",
      route: `/builder/template/${id}/`,
      validStatus: 204,
      statusErrorMessage: "DELETE_TEMPLATE_FAILED",
      json: false
    });
  };

  /**
   * Get news
   * @param {page} number Page number
   */
  getNews = async ({ page = 1 }) => {
    return this._request({
      route: `/news/news/?page=${page}`,
      statusErrorMessage: "GET_NEWS_FAILED"
    });
  };

  /**
   * Get b2c products
   * @param {page} number Page number
   */
  getB2CProducts = async ({ page = 1 }) => {
    return this._request({
      route: `/b2c/product/?page=${page}`,
      statusErrorMessage: "GET_B2C_PRODUCTS_FAILED"
    });
  };

  /**
   * Get products categories
   * @param {page} string Product type (b2b | b2c)
   * @param {page} number Page number
   */
  getProductsCategories = async ({ type = "b2c", page = 1, organization }) => {
    return this._request({
      route: `/${type}/category_parent/?page=${page}&company=${organization}`,
      statusErrorMessage: "GET_PRODUCTS_CATEGORIES_FAILED"
    });
  };

  /**
   * Get products subcategories
   * @param {page} string Product type (b2b | b2c)
   * @param {page} number Page number
   */
  getProductsSubcategories = async ({
    type = "b2c",
    page = 1,
    organization
  }) => {
    return this._request({
      route: `/${type}/category/?page=${page}&company=${organization}`,
      statusErrorMessage: "GET_PRODUCTS_CATEGORIES_FAILED"
    });
  };
}
