import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardContent, Box } from "@material-ui/core";
import {
  LoginForm,
  LoginWithFb,
  LoginWithLinkedin,
  LoginWithGoogle,
  LoadingSpinner
} from "../../components";

import "./login-card.styles.scss";

const LoginCard = ({
  redirect,
  logInPending,
  loginWithOauth,
  isLoginWithOauthPending
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(logInPending || isLoginWithOauthPending);
  }, [logInPending, isLoginWithOauthPending]);

  return (
    <Card className="login-card">
      <CardHeader title={<Box textAlign="center">{t("Sign in")}</Box>} />
      <CardContent className="login-card__main">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="login-card__body">
              <div className="login-card__body-column">
                <LoginForm redirect={redirect} />
              </div>

              <div className="login-card__body-column">
                <p>{t("Sign in via social media")}</p>
                <ul>
                  <li>
                    <div className="login-card__body-column__social-media">
                      <LoginWithFb
                        setLoading={setLoading}
                        loginWithOauth={loginWithOauth}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="login-card__body-column__social-media">
                      <LoginWithLinkedin
                        setLoading={setLoading}
                        loginWithOauth={loginWithOauth}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="register-card__body-column__social-media">
                      <LoginWithGoogle
                        setLoading={setLoading}
                        loginWithOauth={loginWithOauth}
                      />
                    </div>
                  </li>
                </ul>
                <p className="login-card__body-column__agreement">
                  {t(
                    "By clicking the Sign in button, you accept the terms of the"
                  )}{" "}
                  <Link to="/">{t("User agreement")}</Link>
                </p>
              </div>
            </div>
            <div className="login-card__footer">
              <Link to="/signup">{t("Not a member? Sign up")}</Link>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default LoginCard;
