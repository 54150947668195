import { B2CProductsActionTypes } from "./b2c.products.types";
import { B2BProductsActionTypes } from "../b2b/b2b.products.types";

const INITIAL_STATE = {
  areB2CProductsPending: false,
  b2cProductsNamespaces: {},
  b2cProducts: [],
  b2cProductsCount: 0,
  b2cProductsError: null,

  areB2CProductsCategoriesPending: false,
  b2cProductsCategories: {},
  b2cProductsCategoriesError: null,

  areCompanyB2CProductsPending: false,
  companyB2CProducts: [],
  companyB2CProductsCount: 0,
  companyB2CProductsError: null,

  areB2COrganizationProductsPending: false,
  b2cOrganizationProducts: {},
  b2cOrganizationProductsError: null,

  areUserB2CProductsPending: false,
  userB2CProductsCount: 0,
  userB2CProducts: [],
  userB2CProductsError: null,

  isB2CImagePatchPending: false,
  b2cImagePatchData: {},
  b2cImagePathError: null,

  areRecommendedB2CProductsPending: false,
  recommendedB2CProductsNamespaces: {},
  recommendedB2CProducts: [],
  recommendedB2CProductsCount: {},
  recommendedB2CProductsError: null,

  areSimilarB2CProductsPending: false,
  similarB2CProductsCount: 0,
  similarB2CProducts: [],
  similarB2CProductsError: null,

  isB2CCustomParametersPending: false,
  B2CCustomParameters: [],
  B2CCustomParametersError: {},

  isCreatingB2CProduct: false,
  createdB2CProduct: null,
  createdB2CProductError: null,

  isUpdatingB2CProduct: false,
  updatedB2CProduct: null,
  updatedB2CProductError: null,

  b2cRequestStatus: ""
};

const B2CProductsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case B2CProductsActionTypes.GET_B2C_PRODUCTS.GET_B2C_PRODUCTS_PENDING:
      return { ...state, areB2CProductsPending: true };

    case B2CProductsActionTypes.GET_B2C_PRODUCTS.GET_B2C_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...(action.payload.namespace
          ? {
              b2cProductsNamespaces: {
                ...state.b2cProductsNamespaces,
                [action.payload.namespace]: {
                  ...(action.payload.additional || {}),
                  products: action.payload.products
                }
              }
            }
          : {}),
        b2cProducts: action.payload.products,
        b2cProductsCount: action.payload.count,
        areB2CProductsPending: false
      };

    case B2CProductsActionTypes.GET_B2C_PRODUCTS.GET_B2C_PRODUCTS_ERROR:
      return {
        ...state,
        b2cProductsError: action.payload,
        areB2CProductsPending: false
      };

    case B2CProductsActionTypes.GET_B2C_PRODUCTS_CATEGORIES
      .GET_B2C_PRODUCTS_CATEGORIES_PENDING:
      return { ...state, areB2CProductsCategoriesPending: true };

    case B2CProductsActionTypes.GET_B2C_PRODUCTS_CATEGORIES
      .GET_B2C_PRODUCTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        areB2CProductsCategoriesPending: false,
        b2cProductsCategories: action.payload,
        b2cProductsCategoriesError: null
      };

    case B2CProductsActionTypes.GET_B2C_PRODUCTS_CATEGORIES
      .GET_B2C_PRODUCTS_CATEGORIES_ERROR:
      return {
        ...state,
        areB2CProductsCategoriesPending: false,
        b2cProductsCategoriesError: action.payload
      };

    case B2CProductsActionTypes.COMPANY.GET_COMPANY_B2C_PRODUCTS_PENDING:
      return { ...state, areCompanyB2CProductsPending: true };

    case B2CProductsActionTypes.COMPANY.GET_COMPANY_B2C_PRODUCTS_SUCCESS:
      return {
        ...state,
        companyB2CProducts: action.payload.products,
        companyB2CProductsCount: action.payload.count,
        areCompanyB2CProductsPending: false
      };

    case B2CProductsActionTypes.COMPANY.GET_COMPANY_B2C_PRODUCTS_FAIL:
      return {
        ...state,
        companyB2CProductsError: action.payload,
        areCompanyB2CProductsPending: false
      };

    case B2CProductsActionTypes.ORGANIZATION
      .GET_ORGANIZATION_B2C_PRODUCTS_PENDING:
      return { ...state, areB2COrganizationProductsPending: true };

    case B2CProductsActionTypes.ORGANIZATION
      .GET_ORGANIZATION_B2C_PRODUCTS_SUCCESS:
      return {
        ...state,
        areB2COrganizationProductsPending: false,
        b2cOrganizationProducts: action.payload,
        b2cOrganizationProductsError: null
      };

    case B2CProductsActionTypes.ORGANIZATION.GET_ORGANIZATION_B2C_PRODUCTS_FAIL:
      return {
        ...state,
        areB2COrganizationProductsPending: false,
        b2cOrganizationProductsError: action.payload
      };

    case B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_PENDING:
      return { ...state, areUserB2CProductsPending: true };

    case B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_SUCCESS:
      return {
        ...state,
        userB2CProductsCount: action.payload.count,
        userB2CProducts: action.payload.products,
        areUserB2CProductsPending: false
      };

    case B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_ERROR:
      return {
        ...state,
        userB2CProductsError: action.payload,
        areUserB2CProductsPending: false
      };

    case B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_PENDING:
      return { ...state, isB2CImagePatchPending: true };

    case B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_SUCCESS:
      return {
        ...state,
        isB2CImagePatchPending: false,
        b2cImagePatchData: action.payload,
        b2cImagePathError: null
      };

    case B2BProductsActionTypes.PATCH.IMAGE.PATCH_B2B_IMAGE_ERROR:
      return {
        ...state,
        isB2CImagePatchPending: false,
        b2cImagePathError: action.payload
      };

    case B2CProductsActionTypes.GET.RECOMMENDED
      .GET_RECOMMENDED_B2C_PRODUCTS_PENDING:
      return { ...state, areRecommendedB2CProductsPending: true };

    case B2CProductsActionTypes.GET.RECOMMENDED
      .GET_RECOMMENDED_B2C_PRODUCTS_SUCCESS:
      return {
        ...state,
        recommendedB2CProductsNamespaces: {
          ...state.recommendedB2CProductsNamespaces,
          [action.payload.namespace]: {
            ...(action.payload.additional || {}),
            products: action.payload.products
          }
        },
        recommendedB2CProducts: action.payload.products,
        recommendedB2CProductsCount: action.payload.count,
        areRecommendedB2CProductsPending: false
      };

    case B2CProductsActionTypes.GET.RECOMMENDED
      .GET_RECOMMENDED_B2C_PRODUCTS_ERROR:
      return {
        ...state,
        recommendedB2CProductsError: action.payload,
        areRecommendedB2CProductsPending: false
      };

    case B2CProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2C_PRODUCTS_PENDING:
      return { ...state, areSimilarB2CProductsPending: true };

    case B2CProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2C_PRODUCTS_SUCCESS:
      return {
        ...state,
        similarB2CProducts: action.payload.products,
        similarB2CProductsCount: action.payload.count,
        areSimilarB2CProductsPending: false
      };

    case B2CProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2C_PRODUCTS_ERROR:
      return {
        ...state,
        similarB2CProductsError: action.payload,
        areSimilarB2CProductsPending: false
      };

    case B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_PENDING:
      return {
        ...state,
        isCreatingB2CProduct: true
      };

    case B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_SUCCESS:
      return {
        ...state,
        isCreatingB2CProduct: false,
        b2cRequestStatus: action.payload.requestStatus,
        createdB2CProduct: action.payload
      };

    case B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_ERROR:
      return {
        ...state,
        isCreatingB2CProduct: false,
        createdB2CProductError: action.payload
      };

    case B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_PENDING:
      return {
        ...state,
        isUpdatingB2CProduct: true
      };
    case B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_SUCCESS:
      return {
        ...state,
        b2cRequestStatus: action.payload.requestStatus,
        updatedB2CProduct: action.payload,
        isUpdatingB2CProduct: false
      };
    case B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_ERROR:
      return {
        ...state,
        updatedB2CProduct: action.payload,
        isUpdatingB2CProduct: false
      };

    case B2CProductsActionTypes.CLEAR_B2C_PRODUCTS_REQUEST:
      return {
        ...state,
        b2cRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default B2CProductsReducer;
