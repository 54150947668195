import { combineReducers } from "redux";

import ProductSwitchReducer from "./product-switch/product-switch.reducer";
import PaginationReducer from "./pagination/pagination.reducer";
import AuthReducer from "@core/redux/store/auth/auth.reducer";
import IntegrationsReducer from "./APIs/integrations/integrations.reducer";
import CompaniesReducer from "@core/redux/store/companies/companies.reducer";
import B2BProductsReducer from "./APIs/products/b2b/b2b.products.reducer";
import B2CProductsReducer from "./APIs/products/b2c/b2c.products.reducer";
import UtilsReducer from "./APIs/utils/utils.reducer";
import BranchesReducer from "./APIs/branches/branches.reducer";
import OrganizationsReducer from "@core/redux/store/organizations/organizations.reducer";
import CountriesReducer from "./APIs/countries/countries.reducer";
import ResumesReducer from "./APIs/resumes/resumes.reducer";
import VacanciesReducer from "./APIs/vacancies/vacancies.reducer";
import NewsReducer from "./APIs/news/news.reducer";
import ActiveProductReducer from "./APIs/products/active-product/active-product.reducer";
import SitesReducer from "./APIs/sites/sites.reducer";
import ThemesReducer from "./APIs/themes/themes.reducer";
import FilterReducer from "./filter/filter.reducer";
import ProfileReducer from "@core/redux/store/profile/profile.reducer";
import ProfileAPIReducer from "@core/redux/store/profileAPI/profileAPI.reducer";
import EventsReducer from "./APIs/events/events.reducer";
import CartReducer from "./APIs/cart/cart.reducer";
import BusinessProposalsReducer from "@core/redux/store/business-proposals/business-proposals.reducer";
import InnovationProjectsReducer from "@core/redux/store/innovation-projects/innovation-projects.reducer";
import FilterAPIReducer from "./APIs/filter/filter.reducer";
import JobsAPIReducer from "./APIs/job/jobs/jobs.reducer";
import ResumesAPIReducer from "./APIs/job/resume/resume.reducer";
import CategoriesReducer from "@core/categories/store/API/categories/categories.reducer";
import MyBusinessReducer from "./my-business/my-business.reducer";
import ProducersReducer from "./APIs/producers/producers.reducer";
import ApplicationLanguage from "./localization/localization.reducer";
import UserReducer from "./APIs/user/user.reducer";
import SalesAndCouponsReducer from "./APIs/sales-and-coupons/sales-and-coupons.reducer";
import StaticPagesReducer from "./APIs/static-pages/static-pages.reducer";
import OrdersReducer from "./APIs/orders/orders.reducer";
import LeadsReducer from "./APIs/leads/leads.reducer";
import DocumentsReducer from "./APIs/documents/documents.reducer";
import WishListReducer from "./APIs/wish-list/wish-list.reducer";
import PartnersReducer from "./APIs/partners/partners.reducer";
import ShippingReducer from "./APIs/shipping/shipping.reducer";
import ProductVariationsReducer from "./APIs/product-variations/product-variations.reducer";
import EventInvitationsReducer from "./APIs/event-invitations/event-invitations.reducer";
import StatisticsReducer from "./APIs/statistics/statistics.reducer";
import PortalRecommendationsReducer from "./APIs/portal-recomendations/portal-recomendations.reducer";
import RecommendedReducer from "./APIs/recommended/recommended.reducer";
import ClientsReducer from "./APIs/clients/clients.reducer";
import PagesReducer from "@core/static-pages/store/API/pages/pages.reducer";
import ProductFormReducer from "@core/redux/store/product-form/product-form.reducer";
import SiteBuilderReducer from "@core/redux/store/siteBuilder/siteBuilder.reducer";

export default combineReducers({
  activeProduct: ActiveProductReducer,
  appLanguage: ApplicationLanguage,
  authReducer: AuthReducer,
  integrationsReducer: IntegrationsReducer,
  b2BProductsReducer: B2BProductsReducer,
  b2cProductsReducer: B2CProductsReducer,
  branchesReducer: BranchesReducer,
  businessProposals: BusinessProposalsReducer,
  cart: CartReducer,
  categoriesAPI: CategoriesReducer,
  companiesReducer: CompaniesReducer,
  siteBuilderReducer: SiteBuilderReducer,
  countriesReducer: CountriesReducer,
  documentsReducer: DocumentsReducer,
  events: EventsReducer,
  eventInvitations: EventInvitationsReducer,
  filterAPI: FilterAPIReducer,
  filterReducer: FilterReducer,
  innovationProjects: InnovationProjectsReducer,
  jobsAPI: JobsAPIReducer,
  leadsReducer: LeadsReducer,
  myBusiness: MyBusinessReducer,
  newsReducer: NewsReducer,
  ordersReducer: OrdersReducer,
  organizationsReducer: OrganizationsReducer,
  partnersReducer: PartnersReducer,
  portalRecommendations: PortalRecommendationsReducer,
  producers: ProducersReducer,
  productSwitch: ProductSwitchReducer,
  productVariations: ProductVariationsReducer,
  profile: ProfileReducer,
  profileAPI: ProfileAPIReducer,
  recommended: RecommendedReducer,
  resumesReducer: ResumesReducer,
  resumesAPI: ResumesAPIReducer,
  salesAndCoupons: SalesAndCouponsReducer,
  selectedPageIdx: PaginationReducer,
  shipping: ShippingReducer,
  sitesReducer: SitesReducer,
  staticPagesReducer: StaticPagesReducer,
  statistics: StatisticsReducer,
  themesReducer: ThemesReducer,
  utilsReducer: UtilsReducer,
  user: UserReducer,
  vacanciesReducer: VacanciesReducer,
  wishListReducer: WishListReducer,
  clientsReducer: ClientsReducer,
  pagesReducer: PagesReducer,
  productForm: ProductFormReducer
});
