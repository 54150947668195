import { axiosInstance } from "../../../axios/axios.config";
import { EventInvitationsTypes } from "./event-invitations.types";

export const addMeToEventById = eventId => dispatch => {
  dispatch({
    type: EventInvitationsTypes.PUT.ADD_ME_TO_EVENT_AS_VISITOR_PENDING
  });
  axiosInstance
    .put(`/api/v1/organization/exhibition_visitors/${eventId}/`)
    .then(added => {
      dispatch({
        type: EventInvitationsTypes.PUT.ADD_ME_TO_EVENT_AS_VISITOR_SUCCESS,
        payload: added.data
      });
    })
    .then(() => {
      dispatch(checkIsUserGoingToEventId(eventId));
    })
    .catch(err => {
      dispatch({
        type: EventInvitationsTypes.PUT.ADD_ME_TO_EVENT_AS_VISITOR_ERROR,
        payload: err.data
      });
    });
};

export const checkIsUserGoingToEventId = eventId => dispatch => {
  dispatch({
    type: EventInvitationsTypes.GET.CHECK_IF_USER_IS_GOING_TO_EVENT_PENDING
  });
  axiosInstance
    .get(`/api/v1/organization/exhibition_visitors/${eventId}/check`)
    .then(res => {
      dispatch({
        type: EventInvitationsTypes.GET.CHECK_IF_USER_IS_GOING_TO_EVENT_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: EventInvitationsTypes.GET.CHECK_IF_USER_IS_GOING_TO_EVENT_ERROR,
        payload: err
      });
    });
};

export const createMeetingWithUserId = (
  exhibition,
  title,
  visitor,
  start_date,
  end_date
) => dispatch => {
  dispatch({
    type: EventInvitationsTypes.POST.CREATE_MEETING_INVITATION_WITH_USER_PENDING
  });
  axiosInstance
    .post(`/api/v1/organization/exhibition_meeting/`, {
      exhibition,
      title,
      visitor,
      start_date,
      end_date
    })
    .then(createdMetting => {
      dispatch({
        type:
          EventInvitationsTypes.POST
            .CREATE_MEETING_INVITATION_WITH_USER_SUCCESS,
        payload: {
          createdMeeting: createdMetting.data,
          requestStatus: createdMetting.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type:
          EventInvitationsTypes.POST.CREATE_MEETING_INVITATION_WITH_USER_ERROR,
        payload: err
      });
    });
};

export const getMeetingInvitationsByEvent = eventId => dispatch => {
  dispatch({
    type: EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_BY_EVENT_PENDING
  });
  axiosInstance
    .get(`/api/v1/organization/exhibition_meeting/?exhibition=${eventId}`)
    .then(meetings => {
      dispatch({
        type:
          EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_BY_EVENT_SUCCESS,
        payload: meetings.data
      });
    })
    .catch(err => {
      dispatch({
        type: EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_BY_EVENT_ERROR,
        payload: err
      });
    });
};

export const getMeetingsRelatedToMe = (search = "", page = 1) => dispatch => {
  dispatch({
    type:
      EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_RELATED_TO_ME_PENDING
  });
  axiosInstance(
    `/api/v1/organization/exhibition_meeting/incoming/?search=${search}&page=${page}`
  )
    .then(meetings => {
      dispatch({
        type:
          EventInvitationsTypes.GET
            .GET_MEETING_INVITATIONS_RELATED_TO_ME_SUCCESS,
        payload: {
          meetings: meetings.data.results,
          count: meetings.data.count
        }
      });
    })
    .catch(err => {
      dispatch({
        type:
          EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_RELATED_TO_ME_ERROR,
        payload: err
      });
    });
};

export const acceptEventInvitation = meetingId => dispatch => {
  dispatch({
    type: EventInvitationsTypes.PATCH.ACCEPT_MEETING_INVITE_PENDING
  });

  axiosInstance
    .patch(`/api/v1/organization/exhibition_meeting/${meetingId}/`, {
      meeting_response: "accept"
    })
    .then(acceptedMeeting => {
      dispatch({
        type: EventInvitationsTypes.PATCH.ACCEPT_MEETING_INVITE_SUCCESS,
        payload: acceptedMeeting.data
      });
    })
    .then(dispatch(getMeetingsRelatedToMe()))
    .catch(err => {
      dispatch({
        type: EventInvitationsTypes.PATCH.ACCEPT_MEETING_INVITE_ERROR,
        payload: err
      });
    });
};

export const declineEventInvitation = meetingId => dispatch => {
  dispatch({
    type: EventInvitationsTypes.PATCH.DECLINE_MEETING_INVITE_PENDING
  });

  axiosInstance
    .patch(`/api/v1/organization/exhibition_meeting/${meetingId}/`, {
      meeting_response: "denial"
    })
    .then(declinedMeeting => {
      dispatch({
        type: EventInvitationsTypes.PATCH.DECLINE_MEETING_INVITE_SUCCESS,
        payload: declinedMeeting.data
      });
    })
    .then(dispatch(getMeetingsRelatedToMe()))
    .catch(err => {
      dispatch({
        type: EventInvitationsTypes.PATCH.DECLINE_MEETING_INVITE_ERROR,
        payload: err
      });
    });
};

export const searchUsersFromEventVisitior = (
  eventId,
  search = ""
) => dispatch => {
  dispatch({
    type: EventInvitationsTypes.GET.SEARCH_VISITORS_BY_EMAIL_PENDING
  });
  axiosInstance
    .get(
      `/api/v1/organization/exhibition_visitors/${eventId}/visitors/?search=${search}`
    )
    .then(visitors => {
      dispatch({
        type: EventInvitationsTypes.GET.SEARCH_VISITORS_BY_EMAIL_SUCCESS,
        payload: visitors.data
      });
    })
    .catch(err => {
      dispatch({
        type: EventInvitationsTypes.GET.SEARCH_VISITORS_BY_EMAIL_ERROR,
        payload: err
      });
    });
};

export const clearMeetingRequest = () => dispatch => {
  dispatch({
    type: EventInvitationsTypes.CLEAR_MEETING_REQUEST_STATUS,
    payload: ""
  });
};
