import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { _skip } from "../../constants";
import { BuilderSiteCard, LoadingSpinner } from "../../components";

import {
  getSite,
  updateSite,
  createSite,
  addThemeToSite,
  getThemes,
  importThemeToSite,
  getThemeCategories,
  getCompaniesByUserId,
  getUserOrganizationsChamber
} from "../../redux/actions-exporter";

import { apiDomain } from "../../axios/axios.config.js";

import "./builder-create-site.styles.scss";
import { withDebounce } from "../../shared";

const BuilderCreateSite = ({
  history,
  isSitePending,
  createSite,
  createdSite,
  createdSiteError,
  addThemeToSite,
  getThemes,
  themes,
  themesPagination,
  isThemesPending,
  isPagesFromThemeCreated,
  importThemeToSite,
  getThemeCategories,
  themeCategories,
  isThemeCategoriesPending,
  getCompaniesByUserId,
  companiesByUserId,
  areCompaniesByUserIdPending,
  getUserOrganizationsChamber,
  userOrganizationsChamber,
  areUserOrganizationsPending
}) => {
  const [title, setTitle] = useState("");
  const [domain, setDomain] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [themeId, setThemeId] = useState(0);
  const [themeCategoryId, setThemeCategoryId] = useState("");
  const [schemaId, setSchemaId] = useState(1);
  const [themeSearch, setThemeSearch] = useState("");
  const [themePage, setThemePage] = useState(1);
  const [selectedTheme, setSelectedTheme] = useState(null);

  const [searchOrganizationInput, setSearchOrganizationInput] = useState("");

  const schemas = [
    { id: 1, value: "http://" },
    { id: 2, value: "https://" }
  ];

  useEffect(() => {
    if (isPagesFromThemeCreated && createdSite) {
      addThemeToSite();

      setTitle("");
      setDomain("");
      setOrganizationId("");
      setThemeId(0);
      setThemeSearch("");
      setThemePage(1);
      setSelectedTheme(null);

      history.push("/builder/sites/");
    } else if (createdSite && selectedTheme) {
      addThemeToSite(selectedTheme);
      importThemeToSite(selectedTheme, createdSite);
    } else if (createdSite && !selectedTheme) {
      addThemeToSite();

      setTitle("");
      setDomain("");
      setOrganizationId("");
      setThemeId(0);
      setThemeSearch("");
      setThemePage(1);

      history.push("/builder/sites/");
    }
  }, [
    addThemeToSite,
    importThemeToSite,
    createdSite,
    selectedTheme,
    themeId,
    themes,
    isPagesFromThemeCreated,
    history
  ]);

  useEffect(() => {
    getThemes(themePage, themeSearch, themeCategoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getThemes, themeCategoryId, themePage, themeSearch]);

  useEffect(() => {
    getCompaniesByUserId(searchOrganizationInput);
    getUserOrganizationsChamber(searchOrganizationInput, _skip, 50);
  }, [
    getCompaniesByUserId,
    getUserOrganizationsChamber,
    searchOrganizationInput
  ]);

  useEffect(() => {
    getThemeCategories();
  }, [getThemeCategories]);

  const setSearchOrganizationsHandler = withDebounce(e =>
    setSearchOrganizationInput(e)
  );

  const titleRegExp = new RegExp(`^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$`);
  const domainRegExp = new RegExp(
    `^(https?://)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$`
  );

  // Handle site creation
  const onCreateSite = ({ title, schemaId, domain, organizationId, theme }) => {
    const schema = schemas.find(i => i.id === schemaId);
    const url = domain.match(domainRegExp)
      ? `${schema.value}${domain}`
      : `${schema.value}${domain}.${apiDomain}`;

    createSite({
      title,
      url,
      domain,
      organizationId,
      theme
    });
    setSelectedTheme(theme);
  };

  const schema = schemas.find(i => i.id === schemaId);
  const form = {
    title,
    isTitleValid: title.match(titleRegExp),
    setTitle,
    schemas,
    schemaId,
    setSchemaId,
    domain,
    isDomainValid:
      (`${schema.value}${domain}`.match(domainRegExp) ||
        `${schema.value}${domain}.${apiDomain}`.match(domainRegExp)) &&
      (!createdSiteError || domain !== createdSiteError.domain),
    domainError: createdSiteError && domain === createdSiteError.domain,
    setDomain,
    organizationId,
    setOrganizationId,
    themeId,
    setThemeId,
    themeSearch,
    setThemeSearch: e => {
      setThemeSearch(e.target.value);
      setThemePage(1);
    },
    themePage,
    setThemePage,
    themeCategoryId,
    setThemeCategoryId: e => {
      setThemeCategoryId(e.target.value);
      setThemePage(1);
    }
  };

  const isOrganizationsPending =
    areCompaniesByUserIdPending || areUserOrganizationsPending;
  if (!isSitePending && !isThemeCategoriesPending) {
    return (
      <div className="builder-site-page">
        <BuilderSiteCard
          {...{
            ...form,
            create: true,
            setSearchOrganizationsHandler,
            organizations:
              !isOrganizationsPending && companiesByUserId
                ? companiesByUserId
                    .concat(userOrganizationsChamber || [])
                    .filter(organization => !organization.newsite)
                : [],
            themeCategories,
            themes,
            isThemesPending,
            themesPagination,
            returnPath: "/builder/sites/",
            onSave: onCreateSite
          }}
        />
      </div>
    );
  } else {
    return <LoadingSpinner loadingState />;
  }
};

const mapStateToProps = state => ({
  isSitePending: state.sitesReducer.isSitePending,
  areThemesLoaded: state.themesReducer.areThemesLoaded,
  themes: state.themesReducer.themes,
  themesPagination: state.themesReducer.themesPagination,
  isThemesPending: state.themesReducer.isThemesPending,
  site: state.sitesReducer.site,
  createdSite: state.sitesReducer.createdSite,
  createdSiteError: state.sitesReducer.createdSiteError,
  themeCategories: state.themesReducer.themeCategories,
  isThemeCategoriesPending: state.themesReducer.isThemeCategoriesPending,
  companiesByUserId: state.companiesReducer.companiesByUserId,
  areCompaniesByUserIdPending:
    state.companiesReducer.areCompaniesByUserIdPending,
  userOrganizationsChamber: state.organizationsReducer.userOrganizationsChamber,
  areUserOrganizationsPending:
    state.organizationsReducer.areUserOrganizationsPending,
  isPagesFromThemeCreated: state.themesReducer.isPagesFromThemeCreated
});

export default connect(mapStateToProps, {
  getSite,
  updateSite,
  createSite,
  addThemeToSite: addThemeToSite,
  getThemes,
  importThemeToSite,
  getCompaniesByUserId,
  getUserOrganizationsChamber,
  getThemeCategories
})(BuilderCreateSite);
