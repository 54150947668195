export const BusinessProposalsActionTypes = {
  GET: {
    GET_BUSINESS_PROPOSALS_PENDING: "GET_BUSINESS_PROPOSALS_PENDING",
    GET_BUSINESS_PROPOSALS_SUCCESS: "GET_BUSINESS_PROPOSALS_SUCCESS",
    GET_BUSINESS_PROPOSALS_ERROR: "GET_BUSINESS_PROPOSALS_ERROR",

    GET_BUSINESS_PROPOSAL_BY_ID_PENDING: "GET_BUSINESS_PROPOSAL_BY_ID_PENDING",
    GET_BUSINESS_PROPOSAL_BY_ID_SUCCESS: "GET_BUSINESS_PROPOSAL_BY_ID_SUCCESS",
    GET_BUSINESS_PROPOSAL_BY_ID_ERROR: "GET_BUSINESS_PROPOSAL_BY_ID_ERROR",

    GET_BUSINESS_PROPOSALS_CATEGORIES_PENDING:
      "GET_BUSINESS_PROPOSALS_CATEGORIES_PENDING",
    GET_BUSINESS_PROPOSALS_CATEGORIES_SUCCESS:
      "GET_BUSINESS_PROPOSALS_CATEGORIES_SUCCESS",
    GET_BUSINESS_PROPOSALS_CATEGORIES_ERROR:
      "GET_BUSINESS_PROPOSALS_CATEGORIES_ERROR",

    GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_PENDING:
      "GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_PENDING",
    GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_SUCCESS:
      "GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_SUCCESS",
    GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_ERROR:
      "GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_ERROR"
  },
  POST: {
    POST_BUSINESS_PROPOSAL_PENDING: "POST_BUSINESS_PROPOSAL_PENDING",
    POST_BUSINESS_PROPOSAL_SUCCESS: "POST_BUSINESS_PROPOSAL_SUCCESS",
    POST_BUSINESS_PROPOSAL_ERROR: "POST_BUSINESS_PROPOSAL_ERROR"
  },
  PUT: {
    PUT_BUSINESS_PROPOSAL_PENDING: "PUT_BUSINESS_PROPOSAL_PENDING",
    PUT_BUSINESS_PROPOSAL_SUCCESS: "PUT_BUSINESS_PROPOSAL_SUCCESS",
    PUT_BUSINESS_PROPOSAL_ERROR: "PUT_BUSINESS_PROPOSAL_ERROR"
  },
  DELETE: {
    DELETE_BUSINESS_PROPOSAL_PENDING: "DELETE_BUSINESS_PROPOSAL_PENDING",
    DELETE_BUSINESS_PROPOSAL_SUCCESS: "DELETE_BUSINESS_PROPOSAL_SUCCESS",
    DELETE_BUSINESS_PROPOSAL_ERROR: "DELETE_BUSINESS_PROPOSAL_ERROR"
  },
  CLEAR_BUSINESS_PROPOSALS_REQUEST: "CLEAR_BUSINESS_PROPOSALS_REQUEST"
};
