import React from "react";

import "./custom-checkbox.styles.scss";

const CustomCheckbox = ({ checkboxStatus, onCheckboxChange, status }) => {
  return (
    <label className="custom-checkbox-container">
      <input
        name="check"
        type="radio"
        onChange={() => {
          onCheckboxChange(!checkboxStatus);
        }}
        value={status}
      />
      <span className="checkmark" />
    </label>
  );
};

export default CustomCheckbox;
