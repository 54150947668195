export default function productsCategoriesMenuComponent({ editor, api, site }) {
  const script = function() {
    function setLoading(wrapperEl, state) {
      if (state) {
        wrapperEl.classList.add("products-categories-menu--preloader");
      } else {
        wrapperEl.classList.remove("products-categories-menu--preloader");
      }
    }

    function render(type, categories, productsPath, loading) {
      const wrapperEl = this;
      setLoading(wrapperEl, loading);

      wrapperEl.innerHTML = `
        <ul class="products-categories-menu__list">
        
        ${categories
          .map(
            category => `
            <li
              class="products-categories-menu__item products-categories-menu__item--primary"
            >
              <a
                class="products-categories-menu__link products-categories-menu__link--primary"
                href="${productsPath}?gjs-products-category=${
              category.id
            }&gjs-products-category-parent=${category.parent || category.id}"
              >
                ${category.name}
              </a>
            </li>`
          )
          .join("")}
          </ul>
      `;
    }

    const rand = Math.random()
      .toString()
      .slice(-10);
    const globalPropertyName = `gjs_products_categories_menu_id${rand}`;
    if (!window.hasOwnProperty(globalPropertyName)) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window[globalPropertyName] = {
        id: rand,
        API_ROOT: API_ROOT,
        renderCycle: [],
        type: "b2c",
        params: {},
        organization: organization,
        categories: [],
        loading: false,
        productsPath: "#",
        render() {
          console.group(`[id: ${this.id}] products categories menu render`);
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(this.type, this.categories, this.productsPath, this.loading)
          );
          console.log("render after new cycle", this);
          console.groupEnd(`[id: ${this.id}] products categories menu render`);
        },
        isFuncInRenderCycle(newFunc) {
          return !this.renderCycle.find(func => func === newFunc);
        },
        appendFuncToRenderCycle(newFunc) {
          if (this.isFuncInRenderCycle(newFunc)) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setProductsPath(path) {
          this.setProp("productsPath", path);
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getCategories() {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(
            `${this.API_ROOT}/${this.type}/category_parent/?company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(categories => this.setCategories(categories));
        },
        setCategories(categories) {
          this.categories = categories || [];

          // Start render cycle
          this.setLoading(false);
          this.render();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty(globalPropertyName) &&
      window.hasOwnProperty("grapesjs")
    ) {
      window[globalPropertyName].appendFuncToRenderCycle(render.bind(this));

      if (attributes.producttype) {
        window[globalPropertyName].setType(attributes.producttype.value);
      }

      if (attributes.hasOwnProperty("organizationonly")) {
        window[globalPropertyName].setParam("company", "{[ organization ]}");
      }

      if (attributes.perpage) {
        window[globalPropertyName].setPerPage(attributes.perpage.value);
      }

      if (attributes.productspage) {
        window[globalPropertyName].setProductsPath(
          attributes.productspage.value
        );
      }

      window[globalPropertyName].getCategories();
    }
  };

  editor.DomComponents.addType("products-categories-menu", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-categories-menu",
    model: {
      defaults: {
        name: "Products Categories Menu",
        apiRoot: api.API_ROOT,
        organization: (site && site.organization) || "",
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Products With Pagination Page",
            name: "productspage",
            modalTitle: "Select products list page"
          }
        ],
        script
      }
    }
  });
}
