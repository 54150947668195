import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { withDebounce } from "../../../shared";
import {
  SearchBar,
  Pagination,
  EventCard,
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import {
  getResumesByCurrentUser,
  deleteResume
} from "../../../redux/actions-exporter";

import "./my-business-resume.styles.scss";

const MyBusinessResume = ({
  getResumesByCurrentUser,
  resumesByCurrentUser,
  resumesByCurrentUserCount,
  areResumeByCurrentUserPending,
  deleteResume
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [resumesPage, setResumesPage] = useState(1);

  const [currentResumeId, setCurrentResumeId] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getResumesByCurrentUser(searchValue, resumesPage);
  }, [getResumesByCurrentUser, searchValue, resumesPage]);

  useEffect(() => {
    setResumesPage(1);
  }, [searchValue]);

  const searchResumesHandler = withDebounce(e => setSearchValue(e));

  return (
    <div className="my-business-resume">
      <ConfirmDialog
        dialogTitle="Resume remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteResume(currentResumeId);
          setDialogOpen(false);
          setCurrentResumeId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => searchResumesHandler(e.target.value)} />
      </div>
      {areResumeByCurrentUserPending ? (
        <LoadingSpinner />
      ) : resumesByCurrentUser && resumesByCurrentUser.length ? (
        resumesByCurrentUser.map(({ id, title, created_at }) => (
          <EventCard
            key={id}
            title={title}
            publicated={true}
            editorState={true}
            publicatedDate={moment(created_at).format("DD MMMM YYYY")}
            editLink={`/my-business/resume/${id}`}
            viewLiveUrl={`/job/resumes/${id}`}
            removeFunc={() => {
              setDialogOpen(true);
              setCurrentResumeId(id);
            }}
          />
        ))
      ) : (
        <MyBusinessNoData noDataText="No resumes found!" />
      )}
      <Pagination
        forcePage={resumesPage - 1}
        dataCount={resumesByCurrentUserCount}
        itemsPerPage={10}
        selectedPage={page => setResumesPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areResumeByCurrentUserPending: state.resumesReducer.areResumeByMePending,
  resumesByCurrentUser: state.resumesReducer.resumesByMe,
  resumesByCurrentUserCount: state.resumesReducer.resumesByMeCount,
  currentUser: state.profileAPI.profile
});

export default connect(mapStateToProps, {
  deleteResume,
  getResumesByCurrentUser
})(MyBusinessResume);
