import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import LeadsCompanyData from "../leads-company-data/leads-company-data.component";

import { Separator } from "../../components";

import {
  setTypeOfOrganization,
  getCompany,
  getOrganization,
  getLeadsByOrganization,
  deleteLeadById
} from "../../redux/actions-exporter";

const CompanyLeadsSingle = ({
  getLeadsByOrganization,
  leadsData,
  areLeadsPending,
  getCompany,
  getOrganization,
  currentCompany,
  currentOrganization,
  isPendingCurrentCompany,
  isPendingCurrentOrganization,
  deleteLeadById
}) => {
  const { companyLeadsId } = useParams();
  const { typeOfCompany } = useLocation();

  const { typeOfComp } = useParams();

  useEffect(() => {
    if (typeOfComp === "c") {
      getCompany(companyLeadsId);
    } else {
      getOrganization(companyLeadsId);
    }
  }, [getCompany, getOrganization, typeOfCompany, typeOfComp, companyLeadsId]);
  return (
    <div className="my-business-department-company-single-info">
      <Separator />
      {typeOfComp === "c" ? (
        <LeadsCompanyData
          typeOfCompany={"Company"}
          leadsData={leadsData}
          currentTypeCompany={currentCompany}
          companyLeadsId={companyLeadsId}
          isPendingCompany={isPendingCurrentCompany}
          areLeadsPending={areLeadsPending}
          getLeadsByOrganization={getLeadsByOrganization}
          deleteLeadById={deleteLeadById}
        />
      ) : (
        <LeadsCompanyData
          typeOfComp={"Organization"}
          leadsData={leadsData}
          currentTypeCompany={currentOrganization}
          companyLeadsId={companyLeadsId}
          isPendingCompany={isPendingCurrentOrganization}
          areLeadsPending={areLeadsPending}
          getLeadsByOrganization={getLeadsByOrganization}
          deleteLeadById={deleteLeadById}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  typeOfOrganization: state.vacanciesReducer.typeOfOrganization,
  isPendingCurrentCompany: state.companiesReducer.isCompanyPending,
  areLeadsPending: state.leadsReducer.areLeadsPending,
  leadsData: state.leadsReducer.leads,
  currentCompany: state.companiesReducer.company,
  isPendingCurrentOrganization:
    state.organizationsReducer.isOrganizationPending,
  currentOrganization: state.organizationsReducer.organization
});

export default connect(mapStateToProps, {
  getLeadsByOrganization,
  getCompany,
  getOrganization,
  setTypeOfOrganization,
  deleteLeadById
})(CompanyLeadsSingle);
