import icon from "./icon.png";

export default function companiesSearchBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .news-search-wrapper {
    margin: 0 0 15px;
  }

  .companies-search {
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000000;
    font-size: 15px;
    border: 1px solid #b9b9ba;
    padding: 10px 8px;
    border-radius: 3px;
  }

  .companies-search-list {
    position: relative;
    width: 100%;
    background: #fff;
    left: initial;
    right: inherit;
    padding: 0;
    margin: 0;
  }

  .companies-search-list__item {
    background: #fff;
    width: 100%;
    color: #7b7b7b;
    font-size: 1.1rem;
    padding: .6rem;
    list-style: none;
    transition: all .1s ease-in-out;
  }

  .companies-search-list__item:hover {
    cursor: pointer;
    background-color: #a8a9b014;
    border-left: 2px solid #a8a9b0;
    border-right: 2px solid #a8a9b0;
    border-top: none;
    border-bottom: none;
  }

  .companies-search-list__highlighted {
    color: #5f5e5e;
    font-weight: 700;
    opacity: 1;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="companies-search-wrapper">
    <input class="companies-search" type="text" placeholder="Search..." />
    <ul class="companies-search-list">
      <li data-id="3" class="companies-search-list__item">A<span class="autoComplete_highlighted">t</span>omic T<span
        class="autoComplete_highlighted">-</span><span class="autoComplete_highlighted">s</span>hirt</li>
      <li data-id="2" class="companies-search-list__item">Emo<span class="autoComplete_highlighted">t</span>icon T<span
        class="autoComplete_highlighted">-</span><span class="autoComplete_highlighted">s</span>hirt</li>
      <li data-id="1" class="companies-search-list__item">Green S<span class="autoComplete_highlighted">t</span>riped
        T<span class="autoComplete_highlighted">-</span><span class="autoComplete_highlighted">s</span>hirt</li>
      <li data-id="0" class="companies-search-list__item">Hexagon <span class="autoComplete_highlighted">T</span><span
        class="autoComplete_highlighted">-</span><span class="autoComplete_highlighted">s</span>hirt</li>
      <li data-id="4" class="companies-search-list__item">S<span class="autoComplete_highlighted">t</span>ar T<span
        class="autoComplete_highlighted">-</span><span class="autoComplete_highlighted">s</span>hirt</li>
    </ul>
  </div>
  `;

  blockManager.add("companies-search", {
    label: `
      <img src="${icon}" alt="Companies search block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Companies Search
      </div>
    `,
    content,
    category: "B24Online Companies"
  });
}
