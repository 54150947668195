import { StaticPagesTypes } from "./static-pages.types";

const INITIAL_STATE = {
  isStaticPagePending: false,
  staticPageById: {},
  staticPageByIdError: {},

  areHeaderPagesPending: false,
  headerPages: [],
  headerPagesCount: 0,
  headerPagesError: {},

  areFooterPagesPending: false,
  footerPages: [],
  footerPagesCount: 0,
  footerPagesError: {}
};

const StaticPagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StaticPagesTypes.GET.GET_STATIC_PAGE_BY_ID_PENDING:
      return {
        ...state,
        isStaticPagePending: true
      };
    case StaticPagesTypes.GET.GET_STATIC_PAGE_BY_ID_SUCCESS:
      return {
        ...state,
        staticPageById: action.payload,
        isStaticPagePending: false
      };
    case StaticPagesTypes.GET.GET_STATIC_PAGE_BY_ID_ERROR:
      return {
        ...state,
        staticPageByIdError: action.payload,
        isStaticPagePending: false
      };

    case StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_PENDING:
      return { ...state, areHeaderPagesPending: true };

    case StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        headerPages: action.payload.pages,
        areHeaderPagesPending: false
      };

    case StaticPagesTypes.GET.GET_HEADER_STATIC_PAGES_ERROR:
      return {
        ...state,
        headerPagesError: action.payload,
        areHeaderPagesPending: false
      };

    case StaticPagesTypes.GET.GET_FOOTER_STATIC_PAGES_PENDING:
      return { ...state, areFooterPagesPending: true };

    case StaticPagesTypes.GET.GET_FOOTER_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        footerPages: action.payload.pages,
        areFooterPagesPending: false
      };

    case StaticPagesTypes.GET.GET_FOOTER_STATIC_PAGES_ERROR:
      return {
        ...state,
        footerPagesError: action.payload,
        areFooterPagesPending: false
      };

    default:
      return state;
  }
};

export default StaticPagesReducer;
