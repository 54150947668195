import React from "react";
import { Link } from "react-router-dom";
import "./login-modal.styles.scss";

import LoginForm from "../login-form/login-form.component";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { ReactComponent as MessangerIcon } from "../../assets/messanger-icon.svg";
import { ReactComponent as GooglePlusIcon } from "../../assets/google-plus-icon.svg";

import DefaultModal from "../default-modal/default-modal.component";

const LoginModal = ({ open, handleOpen, handleClose }) => {
  const LoginModalBody = () => {
    return (
      <div className="login-modal">
        <div className="login-modal__header">
          <h2>Sign in</h2>
          <IconButton disableRipple onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="login-modal__body">
          <div
            style={{ paddingRight: "45px" }}
            className="login-modal__body-column"
          >
            <LoginForm />
          </div>

          <div className="login-modal__body-column">
            <p>Sign in via social media</p>
            <ul>
              <li>
                <div className="login-modal__body-column__social-media">
                  <FacebookIcon style={{ color: "#1976d2" }} />
                </div>
              </li>
              <li>
                <div className="login-modal__body-column__social-media">
                  <GooglePlusIcon />
                </div>
              </li>
              <li>
                <div className="login-modal__body-column__social-media">
                  <LinkedInIcon style={{ color: "#1976d2" }} />
                </div>
              </li>
              <li>
                <div className="login-modal__body-column__social-media">
                  <MessangerIcon />
                </div>
              </li>
            </ul>
            <p className="login-modal__body-column__agreement">
              By clicking the Sign in button, you accept the terms of the{" "}
              <Link to="/">User agreement</Link>
            </p>
          </div>
        </div>
        <div className="login-modal__footer">
          <Link to="/signup">Not a member? Sign up</Link>
        </div>
      </div>
    );
  };
  return (
    <DefaultModal
      handleClose={handleClose}
      open={open}
      modalBody={<LoginModalBody />}
    />
  );
};

export default LoginModal;
