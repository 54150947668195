import style from "./block-style";

const orderListContent = `
${style}
<div data-gjs-type="order-list" draggable="true" data-highlightable="1" class="order-list" id="i20n">
  <div data-gjs-type="default" draggable="true" data-highlightable="1"
    class="order-list__section order-list__section--incoming" id="ieti">
    <div data-gjs-type="default" draggable="true" data-highlightable="1" class="order-list__header" id="icve">
      <img data-gjs-type="image" draggable="true"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAMAAACDzGUcAAAAAXNSR0IB2cksfwAAAJNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAenkjdQAAADF0Uk5TAB52PiF7f1AnfXI9GwFwNwNqGCwlfBw8BkV0eQU5Fj9zLjQEQn5NI3ppdy1JOA5bbzte35oAAACNSURBVHicbdDJEoIwEATQUaFjUNxxV1TEffv/rzMZnVgm9KnrHVLpIfqkVm9QkCiGqsJQLQba1AaRqFY79ZHT6fZCNOkPGId/CMQjqxm8ZGOjE1+RTCt5Rj/Wc3lssbS84q4oXecbrlv+xw6yrdiXph7I8Xfx8QScybHcIdK4yOxreZN6B9w1HoWrz9cbU8EJQSP/iW0AAAAASUVORK5CYII="
        alt="backward icon" class="order-list__icon" id="iqjb" />
      <h1 data-gjs-type="text" draggable="true" data-highlightable="1" class="order-list__title order-list__title--incoming" id="iw0k">
        Incoming
      </h1>
    </div>
    <div data-gjs-type="text" draggable="true" data-highlightable="1" class="order-list__main" id="ibvfj">
      <table class="order-list-table">
        <thead class="order-list-table__header">
          <tr>
            <th class="order-list-table__header-title order-list-table__header-title--index">
              N
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--item">
              PRODUCT
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--name">
              NAME
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--total">
              COST
            </th>
          </tr>
        </thead>
        <tbody class="order-list-table__main">
          <tr class="order-list-item">
            <td style="text-align: center;" rowspan="1">
              <span class="order-list-item__index">255</span>
            </td>

            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Category & Category
                </span>
                <h4 class="order-list-item__title">Lorem ipsum dolor sit amet</h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                18.00
              </span>
              <span class="order-list-item__currency">
                USD
              </span>
            </td>
            </td>
          </tr>
          <tr class="order-list-item">
            <td style="text-align: center;" rowspan="1">
              <span class="order-list-item__index">255</span>
            </td>

            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Category & Category
                </span>
                <h4 class="order-list-item__title">Lorem ipsum dolor sit amet</h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                18.00
              </span>
              <span class="order-list-item__currency">
                USD
              </span>
              <span class="order-list-item__percent-discount">
                -50.00%
              </span>
            </td>
          </tr>
          <tr class="order-list-item">
            <td style="text-align: center;" rowspan="1">
              <span class="order-list-item__index">255</span>
            </td>

            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Category & Category
                </span>
                <h4 class="order-list-item__title">Lorem ipsum dolor sit amet</h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                150.00
              </span>
              <span class="order-list-item__currency">
                ILS
              </span>
              <span class="order-list-item__percent-discount">
                -30.00%
              </span>
            </td>
          </tr>
          <tr class="order-list-item">
            <td style="text-align: center;" rowspan="1">
              <span class="order-list-item__index">255</span>
            </td>

            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Category & Category
                </span>
                <h4 class="order-list-item__title">Lorem ipsum dolor sit amet</h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                50.00
              </span>
              <span class="order-list-item__currency">
                ILS
              </span>
              <span class="order-list-item__percent-discount">
                -50.00%
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div data-gjs-type="text" draggable="true" data-highlightable="1" class="order-list__footer" id="igz14">
      <div class="order-list-pagination">
        <button class="order-list-pagination__button order-list-pagination__button--disabled" disabled="">
          &lt;
        </button>

        <button class="order-list-pagination__button order-list-pagination__button--active" data-page="1">
          1
        </button>

        <button class="order-list-pagination__button" data-page="2">
          2
        </button>

        <button class="order-list-pagination__button" data-page="2">
          &gt;
        </button>
      </div>
    </div>
  </div>
  <div data-gjs-type="default" draggable="true" data-highlightable="1"
    class="order-list__section order-list__section--outgoing" id="iyz6k">
    <div data-gjs-type="default" draggable="true" data-highlightable="1" class="order-list__header" id="iwfri">
      <img data-gjs-type="image" draggable="true"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAMAAACDzGUcAAAAAXNSR0IB2cksfwAAAJNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAenkjdQAAADF0Uk5TAD52HlB/eyEBGz1yfScDN3AYaiUsHHwGPEUFeXQucz8WOU1+QgQ0aXojdzhJLW9bDjl7VQwAAACOSURBVHicbdDXDsIwFANQA3EpNGwoe5ay1/9/HSFwUwjxk3WkSNcBipTKFfxHMaqGNMRGPY5rdZUYpW44a7balDjudPmVD/ci/kT3DQ5SekmBYeIjR8A4hJOpPJtph5jbtliuYruCXL8O2JiSbXPZxp09a08ejsXiN+JELdOVQ5Bn2X3JrlJvd/cb+UPaExxTCNPTTVs8AAAAAElFTkSuQmCC"
        alt="backward icon" class="order-list__icon" id="icanf" />
      <h1 data-gjs-type="text" draggable="true" data-highlightable="1" class="order-list__title order-list__title--outgoing" id="i75oy">
        Outgoing
      </h1>
    </div>
    <div data-gjs-type="text" draggable="true" data-highlightable="1" class="order-list__main gjs-hovered" id="ij2ov">
      <table class="order-list-table">
        <thead class="order-list-table__header">
          <tr>
            <th class="order-list-table__header-title order-list-table__header-title--index">
              N
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--item">
              PRODUCT
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--name">
              NAME
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--total">
              COST
            </th>
          </tr>
        </thead>
        <tbody class="order-list-table__main">
          <tr>
            <td style="text-align: center;" rowspan="3">
              234</span>
            </td>

            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Simple Category
                </span>
                <h4 class="order-list-item__title">
                  Lorem ipsum dolor sit amet
                </h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                50.00
              </span>
              <span class="order-list-item__currency">
                ILS
              </span>
              <span class="order-list-item__percent-discount">
                -50.00%
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Simple Category
                </span>
                <h4 class="order-list-item__title">
                  Lorem ipsum dolor sit amet
                </h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                50.00
              </span>
              <span class="order-list-item__currency">
                ILS
              </span>
              <span class="order-list-item__percent-discount">
                -50.00%
              </span>
            </td>
            </td>
          </tr>

          <tr>
            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Simple Category
                </span>
                <h4 class="order-list-item__title">
                  Lorem ipsum dolor sit amet
                </h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                50.00
              </span>
              <span class="order-list-item__currency">
                ILS
              </span>
              <span class="order-list-item__percent-discount">
                -50.00%
              </span>
            </td>
            </td>
          </tr>

          <tr class="order-list-item">
            <td style="text-align: center;" rowspan="1">
              <span class="order-list-item__index">213</span>
            </td>

            <td>
              <div class="order-list-item__media">
                <img class="order-list-item__image"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                  alt="no image" />
              </div>
            </td>
            <td>
              <div class="order-list-item__main">
                <span class="order-list-item__capture">
                  Category & Categort
                </span>
                <h4 class="order-list-item__title">Lorem ipsum dolor sit amet</h4>
              </div>
            </td>
            <td>
              <span class="order-list-item__cost">
                7
              </span>
              <span class="order-list-item__currency">
                USD
              </span>
            </td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div data-gjs-type="text" draggable="true" data-highlightable="1" class="order-list__footer" id="ig08p">
      <div class="order-list-pagination">
        <button class="order-list-pagination__button order-list-pagination__button--disabled" disabled="">
          &lt;
        </button>

        <button class="order-list-pagination__button order-list-pagination__button--active" data-page="1">
          1
        </button>

        <button class="order-list-pagination__button order-list-pagination__button--disabled" disabled="">
          &gt;
        </button>
      </div>
    </div>
  </div>
</div>
`;

export default orderListContent;
