export default function productsCategoriesContainerLinkComponent({
  editor,
  api,
  site
}) {
  const script = function() {
    function setLoading(wrapperEl, state) {
      if (state) {
        wrapperEl.classList.add(
          "products-categories-container-link--preloader"
        );
      } else {
        wrapperEl.classList.remove(
          "products-categories-container-link--preloader"
        );
      }
    }

    function render(type, category, productsPath, loading) {
      const wrapperEl = this;
      setLoading(wrapperEl, loading);

      const isSubcategory = category.parent;
      if (isSubcategory) {
        wrapperEl.href = `${productsPath}?gjs-products-category=${
          category.id
        }&gjs-products-category-parent=${category.parent || category.id}`;
        wrapperEl.alt = `${category.name} link`;
      } else {
        wrapperEl.href = `${productsPath}?gjs-products-category=${category.id}&gjs-products-category-parent=${category.id}`;
        wrapperEl.alt = `${category.name} link`;
      }
    }

    const rand = Math.random()
      .toString()
      .slice(-10);
    const globalPropertyName = `gjs_products_categories_container_link_id${rand}`;
    if (!window.hasOwnProperty(globalPropertyName)) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      window[globalPropertyName] = {
        id: rand,
        API_ROOT: API_ROOT,
        renderCycle: [],
        type: "b2c",
        params: {},
        organization: organization,
        categoryTrait: {},
        category: {},
        loading: false,
        productsPath: "#",
        render() {
          const groupName = `[id: ${this.id}] products categories container link render`;
          console.group(groupName);
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(this.type, this.category, this.productsPath, this.loading)
          );
          console.log("render after new cycle", this);
          console.groupEnd(groupName);
        },
        isFuncInRenderCycle(newFunc) {
          return !this.renderCycle.find(func => func === newFunc);
        },
        appendFuncToRenderCycle(newFunc) {
          if (this.isFuncInRenderCycle(newFunc)) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setProductsPath(path) {
          this.setProp("productsPath", path);
        },
        setCategoryTrait(categoryTrait) {
          const category = JSON.parse(categoryTrait);
          this.setProp(
            "categoryTrait",
            Object.assign({}, category, {
              parent: parseInt(category.parent) || null
            })
          );
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getCategory() {
          if (this.loading) return;
          this.setLoading(true);

          if (!localStorage.getItem(`${this.type}Categories`)) {
            return fetch(
              `${this.API_ROOT}/${this.type}/category_parent/?company=${this.organization}`,
              {
                method: "GET",
                headers: {
                  "Accept-Language": localStorage.getItem("language")
                }
              }
            )
              .then(response => response.json())
              .then(categories => {
                localStorage.setItem(
                  `${this.type}Categories`,
                  JSON.stringify(categories)
                );
                return fetch(
                  `${this.API_ROOT}/${this.type}/category/?company=${this.organization}`,
                  {
                    method: "GET",
                    headers: {
                      "Accept-Language": localStorage.getItem("language")
                    }
                  }
                )
                  .then(response => response.json())
                  .then(subcategories => {
                    localStorage.setItem(
                      `${this.type}SubCategories`,
                      JSON.stringify(subcategories)
                    );
                    return categories
                      .map(category => ({
                        id: category.id,
                        title: category.name,
                        parent: null,
                        children: subcategories
                          .filter(
                            subcategory => subcategory.parent === category.id
                          )
                          .map(subcategory => ({
                            id: subcategory.id,
                            title: subcategory.name,
                            parent: subcategory.parent
                          }))
                      }))
                      .find(category => category.id === this.categoryTrait.id);
                  })
                  .then(category => this.setCategory(category));
              });
          } else {
            const categories = JSON.parse(
              localStorage.getItem(`${this.type}Categories`)
            );
            const subCategories = JSON.parse(
              localStorage.getItem(`${this.type}SubCategories`)
            );

            const category = categories
              .map(category => ({
                id: category.id,
                title: category.name,
                parent: null,
                children: subCategories
                  .filter(subcategory => subcategory.parent === category.id)
                  .map(subcategory => ({
                    id: subcategory.id,
                    title: subcategory.name,
                    parent: subcategory.parent
                  }))
              }))
              .find(category => category.id === this.categoryTrait.id);
            this.setCategory(category);
          }
        },
        getSubcategory() {
          return fetch(
            `${this.API_ROOT}/${this.type}/category/?company=${this.organization}`,
            {
              method: "GET",
              headers: {
                "Accept-Language": localStorage.getItem("language")
              }
            }
          )
            .then(response => response.json())
            .then(subcategories =>
              subcategories
                .map(subcategory => ({
                  id: subcategory.id,
                  title: subcategory.name,
                  parent: subcategory.parent
                }))
                .find(subcategory => subcategory.id === this.categoryTrait.id)
            )
            .then(subcategory => this.setCategory(subcategory));
        },
        setCategory(category) {
          // Reset page
          this.page = 1;
          // Set categories
          const sortByAlphabet = field => (a, b) =>
            a[field] > b[field] ? 1 : -1;
          this.category =
            category && category.hasOwnProperty("parent") && category.parent
              ? category
              : Object.assign({}, category, {
                  children:
                    category &&
                    category.children &&
                    Object.keys(category.children).length
                      ? category.children.sort(sortByAlphabet("title"))
                      : {}
                });
          // Start render cycle
          this.setLoading(false);
          this.render();
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty(globalPropertyName) &&
      window.hasOwnProperty("grapesjs")
    ) {
      window[globalPropertyName].appendFuncToRenderCycle(render.bind(this));

      if (attributes.producttype) {
        window[globalPropertyName].setType(attributes.producttype.value);
      }

      if (attributes.hasOwnProperty("organizationonly")) {
        window[globalPropertyName].setParam("company", "{[ organization ]}");
      }

      if (attributes.perpage) {
        window[globalPropertyName].setPerPage(attributes.perpage.value);
      }

      if (attributes.productspage) {
        window[globalPropertyName].setProductsPath(
          attributes.productspage.value
        );
      }

      if (attributes.categorytrait) {
        window[globalPropertyName].setCategoryTrait(
          attributes.categorytrait.value
        );

        window[globalPropertyName].categoryTrait.parent
          ? window[globalPropertyName].getSubcategory()
          : window[globalPropertyName].getCategory();
      }
    }
  };

  editor.DomComponents.addType("products-categories-container-link", {
    isComponent: el =>
      el.tagName === "A" &&
      el.className === "products-categories-container-link",
    model: {
      defaults: {
        name: "Products Categories Container Link",
        apiRoot: api.API_ROOT,
        organization: (site && site.organization) || "",
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "page-selector",
            label: "Products With Pagination Page",
            name: "productspage",
            modalTitle: "Select products list page"
          },
          {
            type: "products-category-selector",
            label: "Category",
            name: "categorytrait",
            modalTitle: "Select category"
          }
        ],
        script
      }
    }
  });
}
