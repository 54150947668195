import React, { useState, useRef } from "react";
import {
  Link,
  Route,
  Redirect,
  NavLink,
  useRouteMatch,
  useHistory,
  Switch
} from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { ArrowBackIos as ArrowBackIosIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { navItems } from "./nav-config";
import {
  CompanyLeadsSingle,
  DefaultIconButton,
  DefaultButton
} from "../../components";
import NoMatchPage from "../no-match-page/no-match-page.component";
import {
  MyBusinessAddBar,
  MyBusinessProducts,
  MyBusinessProductsEdit,
  MyBusinessCategories,
  MyBusinessCategoryEdit,
  MyBusinessCompaniesForCategories,
  MyBusinessCategoriesByCompany,
  MyBusinessAddVariationProducts,
  MyBusinessCompanies,
  MyBusinessDepartmentCompanyInfo,
  MyBusinessDepartmentStaff,
  MyBusinessAddDepartment,
  MyBusinessDepartmentVacancy,
  MyBusinessCompaniesEdit,
  MyBusinessOrganizations,
  MyBusinessOrganizationsEdit,
  MyBusinessEvents,
  MyBusinessEventsEdit,
  MyBusinessNews,
  MyBusinessNewsEdit,
  MyBusinessVacancy,
  MyBusinessVacancyEdit,
  MybusinessResume,
  MyBusinessResumeEdit,
  MyOrganizationOrders,
  MyBusinessLeads,
  MyBusinessLeadsEdit,
  MyBusinessStatistics,
  MyBusinessDelivery,
  MyBusinessDeliveryEdit,
  MyBusinessEventInvitations,
  MyBusinessEventInvitationsInnerInfo,
  MyBusinessMeetingInvitations,
  MyBusinessHeaderBar,
  MyBusinessProposals,
  MyBusinessInnovationProjects,
  MyBusinessProposalsEdit,
  MyBusinessInnovationProjectsEdit,
  MyBusinessClients,
  MyBusinessClientsCompanySingleInfo,
  MyBusinessClientEdit,
  MyBusinessDepartmentCompanySingleInfo,
  MyBusinessPages,
  MyBusinessPageEdit
} from "../../containers/my-business";

import "./my-business.styles.scss";

const MyBusiness = ({ appDirection }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("My business");
  const [navVisibility, setNavVisibility] = useState(true);
  const [headerVisibility, setHeaderVisibility] = useState(true);
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { location } = useHistory();
  const myBusinessRef = useRef(null);

  const activeClass = (location, currentURL) =>
    location === currentURL ? "#ededed;" : "transparent";

  return (
    <div className={`my-business ${appDirection}`}>
      <Card>
        <div ref={myBusinessRef} className="my-business__heading">
          <div
            className="my-business__heading__button"
            onClick={history.goBack}
          >
            <DefaultIconButton>
              <ArrowBackIosIcon className="my-business__heading__button__icon" />
            </DefaultIconButton>
          </div>
          <div className="my-business__heading__title">{title}</div>
        </div>
        <div
          className="my-business__upper-nav"
          style={{ display: headerVisibility ? "flex" : "none" }}
        >
          <Link to="/my-business/products">
            <DefaultButton
              backgroundColor={activeClass(
                location.pathname,
                "/my-business/products"
              )}
              width="100%"
              fontSize="20px"
            >
              My business
            </DefaultButton>
          </Link>
          <div className="my-business__vertical-separator" />
          <Link to={`${url}/orders`}>
            <DefaultButton
              backgroundColor={activeClass(location.pathname, `${url}/orders`)}
              width="100%"
              fontSize="20px"
            >
              My orders
            </DefaultButton>
          </Link>
        </div>
        <div className="my-business__horizontal-separator" />
        <div
          className="my-business__lower-nav"
          style={{ display: navVisibility ? "block" : "none" }}
        >
          <div className="lower-nav-wrapper">
            {navItems.map(({ id, tabPath, tabIcon, tabName }) => (
              <NavLink
                key={id}
                to={`${url}/${tabPath}`}
                className="lower-nav-wrapper__link"
                activeClassName="lower-nav-wrapper__link-active"
              >
                <div className="link-icon">{tabIcon}</div>
                <div className="link-name">{tabName}</div>
              </NavLink>
            ))}
          </div>
        </div>
        <Switch>
          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/products`} />}
          />
          <Route
            exact
            path={`${path}/orders`}
            component={() => {
              setTitle("My organization orders");
              setHeaderVisibility(true);
              setNavVisibility(false);
              return <MyOrganizationOrders />;
            }}
          />
          <Route
            exact
            path={`${path}/products`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    link={`${path}/products/create`}
                    title="Products"
                  />
                  <div className="my-business__margin">
                    <MyBusinessProducts />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/products/:productId`}
            component={({
              match: {
                params: { productId }
              }
            }) => {
              if (productId === "create") {
                setTitle("Add product");
              } else {
                setTitle("Edit product");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessProductsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/products/:urlProductType/:productId`}
            component={({
              match: {
                params: { productId }
              }
            }) => {
              if (productId === "create") {
                setTitle("Add product");
              } else {
                setTitle("Edit product");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessProductsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/products/:urlProductType/:productId/:variations/:variationId`}
            component={({
              match: {
                params: { variationId }
              }
            }) => {
              if (variationId === "create") {
                setTitle("Add product variation");
              } else {
                setTitle("Edit product variation");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessAddVariationProducts />;
            }}
          />
          <Route
            exact
            path={`${path}/companies`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Companies"
                    link={`${path}/companies/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessCompanies />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/companies/:compId`}
            component={({
              match: {
                params: { compId }
              }
            }) => {
              if (compId === "create") {
                setTitle("Add company");
              } else {
                setTitle("Edit company");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessCompaniesEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/organizations`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Organizations"
                    link={`${path}/organizations/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessOrganizations />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/department`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return <MyBusinessDepartmentCompanyInfo />;
            }}
          />
          <Route
            exact
            path={`${path}/department/:typeOfComp/:companyId`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return <MyBusinessDepartmentCompanySingleInfo />;
            }}
          />
          <Route
            exact
            path={`${path}/department/:typeOfComp/:companyId/staff/:staffId`}
            component={({
              match: {
                params: { staffId }
              }
            }) => {
              if (staffId === "create") {
                setTitle("Add staff");
              } else {
                setTitle("Edit staff");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessDepartmentStaff />;
            }}
          />
          <Route
            exact
            path={`${path}/department/:typeOfComp/:companyId/depart/:departmentId`}
            component={({
              match: {
                params: { departmentId }
              }
            }) => {
              if (departmentId === "add-department") {
                setTitle("Add department");
              } else {
                setTitle("Edit department");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessAddDepartment />;
            }}
          />
          <Route
            exact
            path={`${path}/department/:typeOfComp/:companyId/d-:departmentId/vacancy/:vacancyId`}
            component={({
              match: {
                params: { vacancyId }
              }
            }) => {
              if (vacancyId === "add-vacancy") {
                setTitle("Add vacancy");
              } else {
                setTitle("Edit vacancy");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessDepartmentVacancy />;
            }}
          />
          <Route
            exact
            path={`${path}/organizations/:orgId`}
            component={({
              match: {
                params: { orgId }
              }
            }) => {
              if (orgId === "create") {
                setTitle("Add organizations");
              } else {
                setTitle("Edit organization");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessOrganizationsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/events`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Events"
                    link={`${path}/events/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessEvents />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/events/:eventId`}
            component={({
              match: {
                params: { eventId }
              }
            }) => {
              if (eventId === "create") {
                setTitle("Add event");
              } else {
                setTitle("Edit event");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessEventsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/business-proposals`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Business Proposals"
                    link={`${path}/business-proposals/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessProposals />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/business-proposals/:businessProposalsId`}
            component={({
              match: {
                params: { businessProposalsId }
              }
            }) => {
              if (businessProposalsId === "create") {
                setTitle("Add Business Proposal");
              } else {
                setTitle("Edit Business Proposal");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessProposalsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/innovation-projects`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Innovation Projects"
                    link={`${path}/innovation-projects/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessInnovationProjects />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/innovation-projects/:innovationProjectsId`}
            component={({
              match: {
                params: { innovationProjectsId }
              }
            }) => {
              if (innovationProjectsId === "create") {
                setTitle("Add Innovation Project");
              } else {
                setTitle("Edit Innovation Project");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessInnovationProjectsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/news`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar title="News" link={`${path}/news/create`} />
                  <div className="my-business__margin">
                    <MyBusinessNews />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/news/:newsId`}
            component={({
              match: {
                params: { newsId }
              }
            }) => {
              if (newsId === "create") {
                setTitle("Add news");
              } else {
                setTitle("Edit news");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessNewsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/vacancy`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Vacancy"
                    link={`${path}/vacancy/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessVacancy />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/vacancy/:vacancyId`}
            component={({
              match: {
                params: { vacancyId }
              }
            }) => {
              if (vacancyId === "create") {
                setTitle("Add vacancy");
              } else {
                setTitle("Edit vacancy");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessVacancyEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/resume`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Resume"
                    link={`${path}/resume/create`}
                  />
                  <div className="my-business__margin">
                    <MybusinessResume />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/resume/:resumeId`}
            component={({
              match: {
                params: { resumeId }
              }
            }) => {
              if (resumeId === "create") {
                setTitle("Add resume");
              } else {
                setTitle("Edit resume");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessResumeEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/leads`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessLeads />
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/leads/:typeOfComp/:companyLeadsId`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return <CompanyLeadsSingle />;
            }}
          />
          <Route
            exact
            path={`${path}/leads/:typeOfComp/:companyLeadsId/lead/:leadId`}
            component={() => {
              setTitle("Edit lead");
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessLeadsEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/statistics`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessHeaderBar title="Statistics" />
                  <div className="my-business__margin">
                    <MyBusinessStatistics />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/delivery`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title="Delivery"
                    link={`${path}/delivery/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessDelivery />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/delivery/:deliveryId`}
            component={({
              match: {
                params: { deliveryId }
              }
            }) => {
              if (deliveryId === "create") {
                setTitle("Add shipping methods");
              } else {
                setTitle("Edit shipping methods");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessDeliveryEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/event-invitations`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessHeaderBar title="Event invitations" />
                  <div className="my-business__margin">
                    <MyBusinessEventInvitations />
                  </div>
                </div>
              );
            }}
          />
          <Route
            path={`${path}/event-invitations/:eventId`}
            component={({
              match: {
                params: { eventId }
              }
            }) => {
              if (eventId !== "create") {
                setTitle("Event Invitations");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessEventInvitationsInnerInfo />;
            }}
          />
          <Route
            exact
            path={`${path}/meeting-invitations`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessHeaderBar title="Incoming meeting invitations" />
                  <div className="my-business__margin">
                    <MyBusinessMeetingInvitations />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/pages`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <MyBusinessAddBar
                    title={t("Pages")}
                    link={`${path}/pages/create`}
                  />
                  <div className="my-business__margin">
                    <MyBusinessPages />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/pages/:pageId`}
            component={({
              match: {
                params: { pageId }
              }
            }) => {
              if (pageId === "create") {
                setTitle("Add page");
              } else {
                setTitle("Edit page");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessPageEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/categories`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <div className="my-business__margin">
                    <MyBusinessCategories />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/categories/companies-view`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <div className="my-business__margin">
                    <MyBusinessCompaniesForCategories />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/categories/company/:compId`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <div className="my-business__margin">
                    <MyBusinessCategoriesByCompany />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/categories/:typeOfCategory/:categoryId`}
            component={({
              match: {
                params: { categoryId }
              }
            }) => {
              if (categoryId === "create") {
                setTitle("Add category");
              } else {
                setTitle("Edit category");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessCategoryEdit />;
            }}
          />
          <Route
            exact
            path={`${path}/clients`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return (
                <div>
                  <div className="my-business__margin">
                    <MyBusinessClients />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/clients/:typeOfComp/:companyId`}
            component={() => {
              if (title !== "My business") setTitle("My business");
              if (!navVisibility) setNavVisibility(true);
              if (!headerVisibility) setHeaderVisibility(true);
              return <MyBusinessClientsCompanySingleInfo />;
            }}
          />
          <Route
            exact
            path={`${path}/clients/:typeOfComp/:companyId/client/:staffId`}
            component={({
              match: {
                params: { staffId }
              }
            }) => {
              if (staffId === "create") {
                setTitle("Add client");
              } else {
                setTitle("Edit client");
              }
              setHeaderVisibility(false);
              setNavVisibility(false);
              return <MyBusinessClientEdit />;
            }}
          />
          <Route path={`${path}/*`} component={NoMatchPage} />
        </Switch>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection
});

export default connect(mapStateToProps, null)(MyBusiness);
