const cartTableStyle = `
<style>
  .cart-table-wrapper {
    overflow: auto;
  }
  
  .cart-table-wrapper * {
    user-select: text;
  }
  
  .cart-table-wrapper.cart-table-wrapper--preloader {
    position: relative;
    transition: all 300ms ease;
  }
  
  .cart-table-wrapper.cart-table-wrapper--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cart-table {
    width: 100%;
    border: 1px solid #aeaeae;
    border-collapse: collapse;
  }
  
  .cart-table__header {
    background-color: #f6f6f6;
    border: 1px solid #aeaeae;
  }
  
  .cart-table__header-title {
    padding: 20px 14px;
    color: #262626;
    font-size: 20px;
    font-weight: 400;
    line-height: 12px;
    text-transform: uppercase;
  }
  
  .cart-table__header-title--product {
    width: 42%;
    text-align: left;
  }
  
  .cart-table__header-title--quantity {
    width: 22%;
  }
  
  .cart-table__row {}
  
  .cart-table__row.cart-table__row--separator {
    border: 1px solid #aeaeae
  }
  
  .cart-table__index {
    text-align: center;
    border: 1px solid #aeaeae;
  }
  
  .cart-table__empty {
    padding: 1rem;
    border-right: 1px solid;
    border-color: #aeaeae;
  }
  
  .cart-product {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding: 10px 14px;
  }
  
  .cart-product__media {
    flex-basis: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cart-product__image {
    width: 140px;
    height: 140px;
    padding: 12px;
    object-fit: scale-down;
    user-select: none;
  }
  
  .cart-product__main {
    flex-basis: 72%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
  }
  
  .cart-product__capture {
    color: hsla(0, 0%, 0%, 0.8);
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.3;
    margin-bottom: 3px;
  }
  
  .cart-product__link {
    color: inherit;
    text-decoration-color: currentColor;
  }
  
  .cart-product__title {
    color: #262626;
    font-weight: 700;
    text-align: left;
    margin: 0px 0 3%;
  }
  
  .cart-product__description {
    color: #262626;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 0;
  }
  
  .cart-product__price {
    font-weight: 600;
  }
  
  .cart-product__price.cart-product__price--total {}
  
  .cart-product__price.cart-product__price--total-old {
    text-decoration: line-through;
    font-size: 14px;
  }
  
  .cart-product__price.cart-product__price--total-discount {
    font-size: 14px;
  }
  
  .cart-quantity-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .cart-quantity-form__button {
    background: #eeeeee;
    color: #464646;
    font-size: 20px;
    font-weight: 400;
    padding: 0 7px;
    border: 1px solid #7f8182;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
  }
  
  .cart-quantity-form__button:hover {
    background: #e2e0e0;
  }
  
  .cart-quantity-form__input {
    padding: 3px 5px;
    width: 50px;
  }
  
  .cart-table__remove {
    width: 4%;
  }
  
  .cart-table__remove-icon {
    color: #1e1e1e;
    font-size: 15px;
    margin: 18px;
    cursor: pointer;
    transition: all 300ms ease;
  }
  
  .cart-table__remove-icon:hover {
    color: #000000;
  }


  .cart-table-slim-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .cart-table-slim-wrapper.cart-table-slim-wrapper--preloader {
    position: relative;
    transition: all 300ms ease;
  }

  .cart-table-slim-wrapper.cart-table-slim-wrapper--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .cart-table-slim {
    border: 1px solid #aeaeae;
    border-collapse: collapse;
  }

  .cart-table-slim tbody {
    display: block;
    height: 100%;
    overflow-y: scroll;
  }

  .cart-table-slim__header {
    background-color: #f6f6f6;
    border: 1px solid #aeaeae;
  }

  .cart-table-slim__header-title {
    width: 100%;
    padding: 20px 14px;
    color: #262626;
    font-size: 20px;
    font-weight: 400;
    line-height: 12px;
  }

  .cart-table-slim__row {
    display: flex;
  }

  .cart-table-slim__row.cart-table-slim__row--separator {
    border-bottom: 1px solid #aeaeae
  }

  .cart-table-slim__index {
    text-align: center;
    border-bottom: 1px solid #aeaeae;
    padding: 0 8px;
  }

  .cart-table-slim__empty {
    padding: 1rem;
    border-right: 1px solid;
    border-color: #aeaeae;
  }

  .cart-table-slim___product-media {
    flex-basis: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart-table-slim__product-image {
    width: 90px;
    height: 90px;
    padding: 12px;
    object-fit: scale-down;
    user-select: none;
  }

  .cart-quantity-form {
    justify-content: left;
  }

  .cart-table-slim__actions {
    display: flex;
    justify-content: center;
    flex-flow: column;
    flex: 1;
    align-items: flex-end;
    height: 100%;
    text-align: center;
    padding: 10px;
  }

  .cart-table-slim__link, .cart-table-slim__link:hover {
    color: inherit;
    text-decoration-color: currentColor;
  }

  .cart-table-slim__title {
    color: #262626;
    font-weight: 700;
    text-align: left;
    margin: 0px 0 3%;
    font-size: 13px;
  }

  .cart-table-slim__price {
    font-weight: 600;
  }

  .cart-table-slim__price.cart-table-slim__price--total {}

  .cart-quantity-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    margin: 0;
  }

  .cart-quantity-form__button {
    background: #eeeeee;
    color: #464646;
    font-size: 20px;
    font-weight: 400;
    padding: 0 7px;
    border: 1px solid #7f8182;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
  }

  .cart-quantity-form__button:hover {
    background: #e2e0e0;
  }

  .cart-quantity-form__input {
    padding: 3px 5px;
    width: 50px;
  }

  .cart-table-slim__remove {
    padding: 0 0 10px;
    font-weight: 800;
  }

  .cart-table-slim__remove-icon {
    color: #1e1e1e;
    font-size: 15px;
    margin: 0 9px;
    cursor: pointer;
    transition: all 300ms ease;
  }

  .cart-table-slim__remove-icon:hover {
    color: #000000;
  }
  
  .ct-pagination {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: flex-end;
    margin: 0 0 0 auto;
    padding: 20px;
  }
  
  .ct-pagination__separator {
    color: #aeaeae;
    font-size: 16px;
    font-weight: 800;
    padding: 0px 10px;
    margin: 0 4px;
    outline: none;
    cursor: pointer;
    vertical-align: bottom;
  }
  
  .ct-pagination__button {
    background: #fff;
    color: #aeaeae;
    font-size: 16px;
    font-weight: 800;
    padding: 8px 16px;
    margin: 0 4px;
    border: 2px solid #aeaeae;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: all 300ms ease;
  }
  
  .ct-pagination__button.ct-pagination__button--active {
    color: #fff;
    background: #c0c0c0;
  }
  
  .ct-pagination__button--disabled, .ct-pagination__button:hover {
    background: #ededed;
  }
</style>
`;

export default cartTableStyle;
