import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useLocalStorage } from "../../shared";
import { useTranslation } from "react-i18next";

import {
  IconAIRecommendation,
  IconFeaturedProducts,
  IconSalesCoupons
} from "../../assets";

import {
  WallProductCarousel,
  WallCategoriesProductGrid
} from "../../components";

import { Filter } from "../../containers";

import {
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setB2CCategoriesFilterStatus
} from "../../redux/actions-exporter";

import "./wall.styles.scss";

const Wall = () => {
  const appDirection = useSelector(state => state.appLanguage.appDirection);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [customization, setCustomization] = useLocalStorage(
    "homepageSettings",
    {
      shopByCategory: {
        active: true,
        label: t("Shop By Category")
      },
      otherCategories: {
        active: true,
        label: "Other Categories"
      },
      aiRecommendation: {
        active: true,
        label: t("AI recommendation")
      },
      salesAndCoupons: {
        active: false,
        label: t("Sales & Coupons")
      },
      featuredProducts: {
        active: false,
        label: t("Featured Products")
      },
      news: {
        active: true,
        label: t("News")
      }
    }
  );

  const createCustomizationHandler = customizationKey => assign =>
    setCustomization(
      Object.fromEntries(
        Object.entries(customization).map(([key, item]) =>
          key === customizationKey ? [key, { ...item, ...assign }] : [key, item]
        )
      )
    );

  useEffect(() => {
    dispatch(setCountriesFilterStatus(true));
    dispatch(setOrganizationsFilterStatus(true));
    dispatch(setB2CCategoriesFilterStatus(true));

    return () => {
      dispatch(setCountriesFilterStatus(false));
      dispatch(setOrganizationsFilterStatus(false));
      dispatch(setB2CCategoriesFilterStatus(false));
    };
  }, [dispatch]);

  const history = useHistory();
  const productFilterOnChange = () => history.push("/products");

  return (
    <div className={`wall ${appDirection}`}>
      <div className="wall__wrapper">
        <Filter
          onChange={() => productFilterOnChange()}
          createCustomizationHandler={createCustomizationHandler}
          customization={customization}
        />

        {customization.shopByCategory.active && (
          <>
            <WallCategoriesProductGrid props={{ style: { marginTop: 30 } }} />
          </>
        )}

        {/*{customization.otherCategories.active && (*/}
        {/*  <>*/}
        {/*    <div className="wall__tab">*/}
        {/*      <Play />*/}
        {/*      <div className="wall__tab-title">*/}
        {/*        {customization.otherCategories.label}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <WallProductsPreviewGrid />*/}
        {/*  </>*/}
        {/*)}*/}

        {customization.aiRecommendation.active && (
          <WallProductCarousel
            props={{ style: { marginTop: 32 } }}
            type="ai"
            icon={<IconAIRecommendation />}
            title={customization.aiRecommendation.label}
          />
        )}

        {customization.salesAndCoupons.active && (
          <WallProductCarousel
            props={{ style: { marginTop: 35 } }}
            type="sales"
            icon={<IconSalesCoupons />}
            title={customization.salesAndCoupons.label}
          />
        )}

        {customization.featuredProducts.active && (
          <WallProductCarousel
            props={{ style: { marginTop: 38 } }}
            type="featured"
            icon={<IconFeaturedProducts />}
            title={customization.featuredProducts.label}
          />
        )}
      </div>
    </div>
  );
};

export default Wall;
