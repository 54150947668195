import React from "react";
import "./comp-org-contacts-bank.styles.scss";

const CompOrgContactsBank = () => {
  return <></>;
  // return (
  //   <div className="comp-org-contacts-bank">
  //     <h3>Bank details</h3>
  //     <div className="comp-org-contacts-bank__upper">
  //       <h4>SELCO Bank Details:</h4>
  //       <p>
  //         SYDBANK A/S – Københavnsvej 9 – DK-4000 Roskilde – Denmark <br />
  //         Account No.: 6819 1083500
  //         <br />
  //         IBAN Code: DK1468192221083499
  //         <br />
  //         SWIFT Code: SYBKDK33
  //         <br />
  //         VAT no: SE556064198123
  //       </p>
  //     </div>
  //     <hr />
  //     <div className="comp-org-contacts-bank__lower">
  //       <h4>HANDELSBANKEN, TÄBY Details:</h4>
  //       <p>
  //         SYDBANK A/S – Københavnsvej 9 – DK-4000 Roskilde – Ireland <br />
  //         SWIFT: HANDSESS <br />
  //         VAT no: SE556064198601 <br />
  //         <br />
  //         IBAN SE 69 6000 0000 0005 2593 1111 (SEK) <br />
  //         IBAN SE 80 6000 0000 0000 4358 2222 (USD) <br />
  //         IBAN SE 49 6000 0000 0000 4358 3333 (GBP) <br />
  //         IBAN SE 52 6000 0000 0000 4358 4444 (CHF) <br />
  //         IBAN SE 18 6000 0000 0000 4358 5555 (EUR) <br />
  //       </p>
  //     </div>
  //   </div>
  // );
};

export default CompOrgContactsBank;
