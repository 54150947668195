import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { Card } from "@material-ui/core";

import { missingImage, EventView1, EventView2 } from "../../assets";
import { Filter } from "../../containers";
import {
  LoadingSpinner,
  CompanyOrganizationCard,
  Pagination,
  NoDataFound
} from "../../components";

import {
  getCompanies,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setBranchesFilterStatus
} from "../../redux/actions-exporter";

import { withDebounce } from "../../shared";
import "./companies.styles.scss";

const Companies = ({
  companies,
  isCompaniesPending,
  getCompanies,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setBranchesFilterStatus,
  filterCountriesArr,
  filterOrganizationsArr,
  filterBranchesArr
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [companiesPage, setCompaniesPage] = useState(1);

  useEffect(() => {
    setCountriesFilterStatus(true);
    setOrganizationsFilterStatus(true);
    setBranchesFilterStatus(true);

    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
      setBranchesFilterStatus(false);
    };
  }, [
    setCountriesFilterStatus,
    setOrganizationsFilterStatus,
    setBranchesFilterStatus
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [companiesPage]);

  useEffect(() => {
    getCompanies(
      searchQuery,
      filterCountriesArr.map(({ id }) => id).join(","),
      filterOrganizationsArr.map(({ id }) => id).join(","),
      filterBranchesArr.map(({ id }) => id).join(","),
      companiesPage
    );
  }, [
    getCompanies,
    searchQuery,
    companiesPage,
    filterCountriesArr,
    filterOrganizationsArr,
    filterBranchesArr
  ]);

  useEffect(() => {
    setCompaniesPage(1);
  }, [
    searchQuery,
    filterCountriesArr,
    filterOrganizationsArr,
    filterBranchesArr
  ]);

  const companiesFilterOnChange = withDebounce(e => setSearchQuery(e));

  return (
    <div className="companies">
      <Filter onChange={e => companiesFilterOnChange(e.target.value)} />
      {isCompaniesPending ? (
        <LoadingSpinner />
      ) : (
        <Card>
          <div className="companies__cards">
            <div>
              <div className="events__body__title">Companies</div>
            </div>
            <div className="events__body__padding">
              <div className="events__body__view">
                <p>View:</p>
                <EventView1 className="events__body__view__block" />
                <EventView2 />
              </div>
              {companies.results &&
                companies.results.map(
                  ({
                    id,
                    logo,
                    name,
                    short_description,
                    countries,
                    metadata
                  }) => (
                    <CompanyOrganizationCard
                      key={id}
                      img={logo ? logo : missingImage}
                      title={name}
                      bodyText={parse(
                        short_description.substring(0, 420) + " ..."
                      )}
                      location={countries[0] ? countries[0].name : "No country"}
                      number={metadata && metadata.phone}
                      website={metadata && metadata.site}
                      linkTo={`/companies/${id}`}
                    />
                  )
                )}
            </div>
          </div>
          {companies.results && !companies.results.length && (
            <NoDataFound
              noDataTitle="No companies found!"
              myBusiness={true}
              additionalLink="/my-business/companies"
            />
          )}
        </Card>
      )}
      <Pagination
        forcePage={companiesPage - 1}
        selectedPage={page => setCompaniesPage(page)}
        dataCount={companies.count}
        itemsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  isCompaniesPending: state.companiesReducer.isCompaniesPending,
  companies: state.companiesReducer.companies,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr,
  filterBranchesArr: state.filterReducer.filterBranchesArr
});

export default connect(mapStateToProps, {
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setBranchesFilterStatus,
  getCompanies
})(Companies);
