export const RecommendedActionTypes = {
  GET: {
    GET_RECOMMENDED_CHAMBER_PENDING: "GET_RECOMMENDED_CHAMBER_PENDING",
    GET_RECOMMENDED_CHAMBER_SUCCESS: "GET_RECOMMENDED_CHAMBER_SUCCESS",
    GET_RECOMMENDED_CHAMBER_ERROR: "GET_RECOMMENDED_CHAMBER_ERROR",

    GET_RECOMMENDED_COMPANY_PENDING: "GET_RECOMMENDED_COMPANY_PENDING",
    GET_RECOMMENDED_COMPANY_SUCCESS: "GET_RECOMMENDED_COMPANY_SUCCESS",
    GET_RECOMMENDED_COMPANY_ERROR: "GET_RECOMMENDED_COMPANY_ERROR",

    GET_RECOMMENDED_EXHIBITION_PENDING: "GET_RECOMMENDED_EXHIBITION_PENDING",
    GET_RECOMMENDED_EXHIBITION_SUCCESS: "GET_RECOMMENDED_EXHIBITION_SUCCESS",
    GET_RECOMMENDED_EXHIBITION_ERROR: "GET_RECOMMENDED_EXHIBITION_ERROR",

    GET_RECOMMENDED_NEWS_PENDING: "GET_RECOMMENDED_NEWS_PENDING",
    GET_RECOMMENDED_NEWS_SUCCESS: "GET_RECOMMENDED_NEWS_SUCCESS",
    GET_RECOMMENDED_NEWS_ERROR: "GET_RECOMMENDED_NEWS_ERROR"
  }
};
