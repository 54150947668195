export default function companiesBranchesComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };
    // Get translation
    const translations = getTranslate("translations", this.attributes);
    // Get search input
    const searchEl = document.querySelector(".companies-branches__search");
    // Change placeholder
    if (translations && Object.keys(translations).length) {
      searchEl.setAttribute("placeholder", translations["Search..."]);
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".companies-branches");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("companies-branches--preloader");
      } else {
        wrapperEl.classList.remove("companies-branches--preloader");
      }
    }

    function render(total, page, perPage, companies, branches, loading) {
      const el = document.querySelector(".companies-branches__list");
      setLoading(loading);

      el.innerHTML = branches.reduce(
        (tmp, branch) =>
          tmp +
          `
            <li
              class="companies-branches__item ${
                branch.selected ? " companies-branches__item--active" : ""
              }"
              data-id="${branch.id}"
            >
              ${branch.title}
            </li>
          `,
        ""
      );

      const branchesEls = document.querySelectorAll(
        ".companies-branches__item"
      );

      Array.prototype.slice.call(branchesEls).map(branchesEl =>
        branchesEl.addEventListener("click", e => {
          e.preventDefault();
          const isPressed = e.target.classList.contains(
            "companies-branches__item--active"
          );
          const branchId = parseInt(e.target.dataset.id);

          if (isPressed) {
            e.target.classList.remove("companies-branches__item--active");
            window.companies.setBranches(
              window.companies.branches.map(branch =>
                branch.id === branchId
                  ? Object.assign({}, branch, { selected: false })
                  : branch
              )
            );
          } else {
            e.target.classList.add("companies-branches__item--active");
            window.companies.setBranches(
              window.companies.branches.map(branch =>
                branch.id === branchId
                  ? Object.assign({}, branch, { selected: true })
                  : branch
              )
            );
          }
        })
      );
    }

    window.waitForCompaniesInterval = setInterval(function() {
      if (
        window.hasOwnProperty("companies") &&
        window.hasOwnProperty("grapesjs")
      ) {
        clearInterval(window.waitForCompaniesInterval);
        if (!window.companies.renderCycle.find(func => func === render)) {
          window.companies.renderCycle.push(render);
          window.companies.loading = false;
          window.companies.getBranches("");
        }
      }
    }, 100);

    this.closest(".companies-branches-wrapper")
      .querySelector(".companies-branches__search")
      .addEventListener("change", function(e) {
        if (
          window.hasOwnProperty("companies") &&
          window.hasOwnProperty("grapesjs")
        ) {
          window.companies.getBranches(e.target.value);
        }
      });
  };

  editor.DomComponents.addType("companies-branches", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "companies-branches-wrapper",
    model: {
      defaults: {
        name: "Companies Branches",
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Search..."]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
