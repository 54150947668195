import { LeadsActionTypes } from "./leads.types";

const INITIAL_STATE = {
  areLeadsPending: false,
  leadsCount: 0,
  leads: [],
  leadsError: {},

  isLeadByIdPending: false,
  leadById: [],
  leadByIdError: {},

  leadCreatedSuccess: {},
  leadCreatedError: {},

  leadUpdatedSuccess: {},
  leadUpdatedError: {},

  leadDeleteError: {},

  leadRequestStatus: ""
};

const LeadsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LeadsActionTypes.GET.GET_LEADS_PENDING:
      return { ...state, areLeadsPending: true };

    case LeadsActionTypes.GET.GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.payload,
        areLeadsPending: false
      };

    case LeadsActionTypes.GET.GET_LEADS_ERROR:
      return {
        ...state,
        leadsError: action.payload,
        areLeadsPending: false
      };

    case LeadsActionTypes.POST.CREATE_LEAD_SUCCESS:
      return {
        ...state,
        leadRequestStatus: action.payload.requestStatus,
        leadCreatedSucces: action.payload
      };

    case LeadsActionTypes.POST.CREATE_LEAD_ERROR:
      return {
        ...state,
        leadCreatedError: action.payload
      };

    case LeadsActionTypes.PUT.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        leadRequestStatus: action.payload.requestStatus,
        leadUpdatedSuccess: action.payload
      };

    case LeadsActionTypes.PUT.UPDATE_LEAD_ERROR:
      return {
        ...state,
        leadUpdatedError: action.payload
      };

    case LeadsActionTypes.GET.GET_LEAD_BY_ID_PENDING:
      return {
        ...state,
        isLeadByIdPending: true
      };

    case LeadsActionTypes.GET.GET_LEAD_BY_ID_SUCCESS:
      return {
        ...state,
        leadById: action.payload,
        isLeadByIdPending: false
      };

    case LeadsActionTypes.GET.GET_LEAD_BY_ID_ERROR:
      return {
        ...state,
        leadByIdError: action.payload,
        isLeadByIdPending: false
      };

    case LeadsActionTypes.DELETE.DELETE_LEAD_SUCCESS:
      return {
        ...state,
        leads: state.leads.filter(lead => lead.id !== action.payload.leadId)
      };

    case LeadsActionTypes.DELETE.DELETE_LEAD_ERROR:
      return {
        ...state,
        leadDeleteError: action.payload
      };

    case LeadsActionTypes.CLEAR_LEAD_REQUEST:
      return {
        ...state,
        leadRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default LeadsReducer;
