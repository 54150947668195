import { OrganizationsActionTypes } from "./organizations.types";

const INITIAL_STATE = {
  organizationTypes: {},
  organizations: {},
  areOrganizationsLoaded: false,
  organization: {},
  isOrganizationPending: true,
  createdOrganization: {},
  updatedOrganization: {},
  deletedOrganization: {},
  userOrganizations: {},
  isUserOrganizationsPending: false,

  isSearchOrganizationsPending: false,
  searchedOrganizations: [],
  searchedOrganizationsCount: 0,
  searchedOrganizationsError: {},

  areUserOrganizationsPending: false,
  userOrganizationsChamberCount: 0,
  userOrganizationsChamber: [],
  userOrganizationsChamberError: {},

  isOrganizationImagePatchPending: false,
  organizationImagePatch: {},
  organizationImagePatchError: null,

  isChamberTypesPending: false,
  chamberTypes: [],
  chamberTypesError: {},

  organizationRequestStatus: ""
};

const OrganizationsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OrganizationsActionTypes.GET_ORGANIZATION_TYPES:
      return {
        ...state,
        organizationTypes: action.payload
      };

    case OrganizationsActionTypes.GET_ORGANIZATION_PENDING:
      return {
        ...state,
        isOrganizationPending: true
      };

    case OrganizationsActionTypes.GET_ORGANIZATION:
      return {
        ...state,
        isOrganizationPending: false,
        organization: action.payload
      };

    case OrganizationsActionTypes.GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
        areOrganizationsLoaded: true
      };

    case OrganizationsActionTypes.GET_USER_ORGANIZATIONS_PENDING:
      return {
        ...state,
        isUserOrganizationPending: true
      };

    case OrganizationsActionTypes.GET_USER_ORGANIZATIONS:
      return {
        ...state,
        isUserOrganizationPending: false,
        userOrganizations: action.payload
      };

    case OrganizationsActionTypes.CREATE_ORGANIZATION:
      return {
        ...state,
        createdOrganization: action.payload,
        organizationRequestStatus: action.payload.requestStatus
      };

    case OrganizationsActionTypes.UPDATE_ORGANIZATION:
      return {
        ...state,
        updatedOrganization: action.payload,
        organizationRequestStatus: action.payload.requestStatus
      };

    case OrganizationsActionTypes.DELETE_ORGANIZATION:
      return {
        ...state,
        deletedOrganization: action.payload
      };

    case OrganizationsActionTypes.GET.SEARCH.SEARCH_ORGANIZATIONS_PENDING:
      return { ...state, isSearchOrganizationsPending: true };

    case OrganizationsActionTypes.GET.SEARCH.SEARCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        searchedOrganizations: action.payload.organizations,
        searchedOrganizationsCount: action.payload.count,
        isSearchOrganizationsPending: false
      };

    case OrganizationsActionTypes.GET.SEARCH.SEARCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        searchedOrganizationsError: action.payload,
        isSearchOrganizationsPending: false
      };

    case OrganizationsActionTypes.GET.GET_USER_ORGANIZATIONS_CHAMBER_PENDING:
      return {
        ...state,
        areUserOrganizationsPending: true
      };

    case OrganizationsActionTypes.GET.GET_USER_ORGANIZATIONS_CHAMBER_SUCCESS:
      return {
        ...state,
        userOrganizationsChamberCount: action.payload.count,
        userOrganizationsChamber: action.payload.organizations,
        areUserOrganizationsPending: false
      };

    case OrganizationsActionTypes.GET.GET_USER_ORGANIZATIONS_CHAMBER_ERROR:
      return {
        ...state,
        userOrganizationsChamberError: action.payload,
        areUserOrganizationsPending: false
      };

    case OrganizationsActionTypes.PATCH.IMAGE.PATCH_ORGANIZATION_IMAGE_PENDING:
      return { ...state, isCompanyImagePatchPending: true };

    case OrganizationsActionTypes.PATCH.IMAGE.PATCH_ORGANIZATION_IMAGE_SUCCESS:
      return {
        ...state,
        isCompanyImagePatchPending: false,
        companyImagePatch: action.payload,
        companyImagePatchError: null
      };

    case OrganizationsActionTypes.PATCH.IMAGE.PATCH_ORGANIZATION_IMAGE_ERROR:
      return {
        ...state,
        isCompanyImagePatchPending: false,
        companyImagePatchError: action.payload
      };

    case OrganizationsActionTypes.CLEAR_ORGANIZATION_REQUEST:
      return {
        ...state,
        organizationRequestStatus: action.payload
      };

    case OrganizationsActionTypes.GET.GET_CHAMBER_TYPES_PENDING:
      return {
        ...state,
        isChamberTypesPending: true
      };

    case OrganizationsActionTypes.GET.GET_CHAMBER_TYPES_SUCCESS:
      return {
        ...state,
        chamberTypes: action.payload,
        isChamberTypesPending: false
      };

    case OrganizationsActionTypes.GET.GET_CHAMBER_TYPES_ERROR:
      return {
        ...state,
        chamberTypesError: action.payload,
        isChamberTypesPending: false
      };

    default:
      return state;
  }
};

export default OrganizationsReducer;
