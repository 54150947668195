import React, { useState } from "react";

import "./quntity-input-horizontal.styles.scss";

const QuantityInputHorizontal = ({ initialQuantity = 1, func, data }) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const regexNum = /^[0-9]*$/;
  const handleQuantity = e => {
    if (regexNum.test(e.target.value)) {
      setQuantity(Number(e.target.value));
      func(data, e.target.value);
    }
  };
  const incrementQuntity = () => {
    setQuantity(quantity + 1);
    func(data, quantity + 1);
  };
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      func(data, quantity - 1);
    }
  };

  return (
    <div className="quantity-input-horizontal">
      <button onClick={decrementQuantity}>-</button>
      <input onChange={handleQuantity} value={quantity} pattern={regexNum} />
      <button onClick={incrementQuntity}>+</button>
    </div>
  );
};

export default QuantityInputHorizontal;
