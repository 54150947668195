import { StatisticsTypes } from "./statistics.types";
import { axiosInstance } from "../../../axios/axios.config";

export const getStatistics = () => dispatch => {
  dispatch({
    type: StatisticsTypes.GET.GET_STATISTICS_PENDING
  });
  axiosInstance
    .get(`/api/v1/statistic/`)
    .then(statistics => {
      dispatch({
        type: StatisticsTypes.GET.GET_STATISTICS_SUCCESS,
        payload: statistics.data
      });
    })
    .catch(err => {
      dispatch({
        type: StatisticsTypes.GET.GET_STATISTICS_ERROR,
        payload: err
      });
    });
};
