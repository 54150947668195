import React from "react";

import "./circle-loader.styles.scss";

const CircleLoader = () => {
  return (
    <div className="circle-loader">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default CircleLoader;
