import { OrdersActionTypes } from "./orders.types";

const INITIAL_STATE = {
  areOrdersPending: false,
  ordersCount: 0,
  ordersItems: [],
  ordersItemsError: {},

  isDeleteDealPending: false,
  deletedDeal: [],
  deletedDealError: {},

  areOrdersByCompanyPending: false,
  ordersByCompanyCount: 0,
  ordersByCompanyItems: [],
  ordersByCompanyItemsError: {}
};

const OrdersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrdersActionTypes.GET_ORDERS_PENDING:
      return { ...state, areOrdersPending: true };

    case OrdersActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        ordersItems: action.payload.orders,
        ordersCount: action.payload.count,
        areOrdersPending: false
      };

    case OrdersActionTypes.GET_ORDERS_ERROR:
      return {
        ...state,
        ordersItemsError: action.payload,
        areOrdersPending: false
      };

    case OrdersActionTypes.GET_ORDERS_BY_COMPANY_PENDING:
      return { ...state, areOrdersByCompanyPending: true };

    case OrdersActionTypes.GET_ORDERS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        ordersByCompanyItems: action.payload.orders,
        ordersByCompanyCount: action.payload.count,
        areOrdersByCompanyPending: false
      };

    case OrdersActionTypes.GET_ORDERS_BY_COMPANY_ERROR:
      return {
        ...state,
        ordersByCompanyItemsError: action.payload,
        areOrdersByCompanyPending: false
      };

    case OrdersActionTypes.DELETE_DEAL_BY_ID_PENDING:
      return { ...state, isDeleteDealPending: true };

    case OrdersActionTypes.DELETE_DEAL_BY_ID_SUCCESS:
      return {
        ...state,
        deletedDeal: action.payload,
        isDeleteDealPending: false
      };

    case OrdersActionTypes.DELETE_DEAL_BY_ID_ERROR:
      return {
        ...state,
        deletedDealError: action.payload,
        isDeleteDealPending: false
      };

    default:
      return state;
  }
};

export default OrdersReducer;
