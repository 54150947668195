import React from "react";

import CompanyDocument from "../company-document/company-document.component";

import "./comp-org-files-tab.styles.scss";

const CompOrgFilesTab = ({ gallery, documents }) => {
  return (
    <div className="comp-org-files-tab">
      {gallery && gallery.length > 0 ? (
        <>
          <h3>Photo gallery</h3>
          <div className="comp-org-files-tab__flex">
            {gallery[0].images.map(({ image }) => (
              <div className="comp-org-files-tab__photo-box">
                <img
                  src={`//${process.env.REACT_APP_STATIC_URL}/` + image}
                  alt=""
                  className="comp-org-files-tab__photo"
                />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {gallery &&
      gallery[0] &&
      gallery[0].videos &&
      gallery[0].videos.length > 0 ? (
        <>
          <h3>Video gallery</h3>
          {gallery[0].videos &&
            gallery[0].videos.map(({ video }) => (
              <div className="comp-org-files-tab__video-container">
                <video className="comp-org-files-tab__video" controls>
                  <source
                    src={`https://${process.env.REACT_APP_STATIC_URL}/` + video}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
        </>
      ) : null}

      {documents.length ? (
        <>
          <h3>Documents</h3>
          <div className="comp-org-files-tab__documents-container">
            <div className="comp-org-files-tab__documents-container__documents">
              {documents.map(({ id, document, name }) => (
                <CompanyDocument
                  key={id}
                  img="https://via.placeholder.com/300x200"
                  name={name}
                  file={document}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="comp-org-files-tab__no-files">No files added yet</div>
      )}
    </div>
  );
};

export default CompOrgFilesTab;
