import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import "./editor-group.styles.scss";

const TextareaGroup = ({ label, name, value, onChange, style, required }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="editor-group" style={style}>
      <div style={{ display: "flex" }}>
        <span className="editor-group__label">
          {label}
        </span>
        {required && <div className="editor-group__required">{t("* required")}</div>}
      </div>

      <br />
      <div>
        <Editor
          tagName={name}
          apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
          initialValue={""}
          value={value}
          init={{
            directionality: i18n.dir() || 'ltr',
            language: i18n?.language === 'he' ? 'he_IL' : i18n?.language || 'en_GB',
            height: 250,
            menubar: "file edit view insert format table",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
              'directionality'
            ],
            toolbar:
              "undo | formatselect | image bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link | rtl ltr",
            setup: function(ed) {
              ed.on("init", function() {
                this.getDoc().body.style.fontSize = "12";
                this.getDoc().body.style.fontFamily = "Roboto";
              });
            }
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextareaGroup;
