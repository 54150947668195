import React, { Fragment, useState, useEffect } from "react";

import Collapsible from "react-collapsible";

import { ExpandArrow } from "@core/components";
import ClearIcon from "@material-ui/icons/Clear";

import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";

import "./default-dropdown-multi-select.styles.scss";

const DefaultDropdown = ({
  label,
  items = [],
  style,
  filter = false,
  returnData,
  customArray,
  required,
  categoryInputValue,
  setCategoryInputValue
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  const [bool, setBool] = useState(true);

  useEffect(() => {
    if (customArray && bool) {
      setSelectedValues(customArray);
    }

    returnData(selectedValues.map(({ id }) => id));
  }, [returnData, selectedValues, customArray, bool]);

  const removeValue = value => {
    const array = [...selectedValues];
    const idx = array.indexOf(value);
    if (idx !== -1) {
      array.splice(idx, 1);
      setSelectedValues(array);
    }
  };

  return (
    <div style={style} className="default-dropdown-multi-select">
      {label && (
        <>
          <div style={{ display: "flex" }}>
            <div className="default-dropdown-multi-select__label">{label}</div>
            {required && (
              <div className="default-dropdown-multi-select__required">
                {t("* required")}
              </div>
            )}
          </div>
          <br />{" "}
        </>
      )}

      <div className="default-dropdown-multi-select__container">
        <Collapsible
          easing="none"
          triggerDisabled={true}
          open={true}
          trigger={
            <div
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="default-dropdown-multi-select-trigger"
            >
              <div className="default-dropdown-multi-select-trigger__title">
                {t("Select a value")}
              </div>
              <div className="default-dropdown-multi-select-trigger__icon">
                <ExpandArrow expand={dropdownOpen} />
              </div>
            </div>
          }
        >
          {dropdownOpen && (
            <div className="default-dropdown-multi-select__body">
              {filter && (
                <div className="default-dropdown-multi-select__input-container">
                  <input
                    value={categoryInputValue}
                    className="default-dropdown-multi-select__input-container__input"
                    type="text"
                    onChange={e => {
                      setCategoryInputValue(e.target.value);
                    }}
                  />
                  <SearchIcon
                    style={{ color: "lightgray " }}
                    className="default-dropdown-multi-select__input-container__icon"
                  />
                </div>
              )}

              {items.map((item, index) => (
                <Fragment key={index}>
                  <div
                    key={item.id}
                    onClick={e => {
                      if (
                        selectedValues.map(({ id }) => id).indexOf(item.id) ===
                        -1
                      ) {
                        setSelectedValues([
                          ...selectedValues,
                          {
                            id: item.id,
                            name: e.currentTarget.textContent
                          }
                        ]);
                        setBool(false);
                      }
                    }}
                    className="default-dropdown-multi-select__item"
                  >
                    {item.name}
                  </div>
                  {item.children ? (
                    <div className="default-dropdown__subitems">
                      {item.children.map(child => {
                        return (
                          <div
                            key={child.id}
                            className="default-dropdown__item-children"
                            onClick={e => {
                              if (
                                selectedValues
                                  .map(({ id }) => id)
                                  .indexOf(child.id) === -1
                              ) {
                                setSelectedValues([
                                  ...selectedValues,
                                  {
                                    id: child.id,
                                    name: e.currentTarget.textContent
                                  }
                                ]);
                                setBool(false);
                              }
                            }}
                          >
                            {child.name}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </div>
          )}
        </Collapsible>
      </div>
      <br />
      <div className="default-dropdown-multi-select__selected-values">
        {selectedValues.map(value => (
          <div
            key={value.id}
            className="default-dropdown-multi-select__selected-value-container"
          >
            <div className="default-dropdown-multi-select__selected-value">
              {value.name}
            </div>
            <div
              onClick={() => {
                removeValue(value);
                setBool(false);
              }}
              className="default-dropdown-multi-select__selected-value-icon"
            >
              <ClearIcon />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DefaultDropdown;
