import { SalesAndCouponsActionTypes } from "./sales-and-coupons.types";
import { axiosInstance } from "../../../axios/axios.config";

export const getProductsWithCouponDiscount = (
  productsType,
  searchValue,
  discountValue = 0,
  categories,
  page = 1
) => dispatch => {
  dispatch({
    type:
      SalesAndCouponsActionTypes.GET.GET_PRODUCTS_WITH_COUPON_DISCOUNT_PENDING
  });

  axiosInstance
    .get(
      `/api/v1/${productsType}/product/?search=${searchValue}&coupon_discount_percent__gt=${discountValue}&categories=${categories}&page=${page}`
    )
    .then(products => {
      dispatch({
        type:
          SalesAndCouponsActionTypes.GET
            .GET_PRODUCTS_WITH_COUPON_DISCOUNT_SUCCESS,
        payload: products.data
      });
    })
    .catch(err => {
      dispatch({
        type:
          SalesAndCouponsActionTypes.GET
            .GET_PRODUCTS_WITH_COUPON_DISCOUNT_ERROR,
        payload: err
      });
    });
};

export const getProductsWithSimpleDiscount = (
  productsType,
  searchValue,
  discountValue = 0,
  categories,
  page = 1
) => dispatch => {
  dispatch({
    type:
      SalesAndCouponsActionTypes.GET.GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_PENDING
  });

  axiosInstance
    .get(
      `/api/v1/${productsType}/product/?search=${searchValue}&discount_percent__gt=${discountValue}&categories=${categories}&page=${page}`
    )
    .then(products => {
      dispatch({
        type:
          SalesAndCouponsActionTypes.GET
            .GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_SUCCESS,
        payload: products.data
      });
    })
    .catch(err => {
      dispatch({
        type:
          SalesAndCouponsActionTypes.GET
            .GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_ERROR,
        payload: err
      });
    });
};
