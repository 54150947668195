import React from "react";
import { useParams } from "react-router-dom";

import "./styles.scss";

const Index = () => {
  const { transactionId } = useParams();
  return (
    <div className="pelecard-page__order pelecard-page__order_success">
      <h3 className="pelecard-page__title">Your order successfully paid</h3>
      <div className="pelecard-page__info">Thank your for your order</div>
      {transactionId !== "paid" && (
        <div className="pelecard-page__info">
          Order transaction Id: {transactionId}
        </div>
      )}
    </div>
  );
};

export default Index;
