import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { BusinessCard } from "~/pages";
import { missingImage, profilePhone } from "@core/assets";

import { colorPalette } from "~/styles/colorPalette";
import "./profile-card-preview.styles.scss";

const ProfileCardPreview = ({ userId, cardType }) => {
  const businessCardType = useSelector(state => state.profile.businessCardType);
  const { t } = useTranslation();
  return (
    <div className="profile-card-preview">
      <div className="profile-card-preview__img">
        <img src={profilePhone} alt="profile-phone" />
        <div className="profile-card-preview__img__absolute">
          {businessCardType ? (
            <BusinessCard />
          ) : (
            <img
              className="profile-card-preview__img__absolute__img-body"
              src={missingImage}
              alt=""
            />
          )}
          <div className="profile-card-preview__transparent" />
        </div>
      </div>
      {cardType && (
        <>
          <p>{t("Business Card Preview")}</p>
          <p>{t("Your Link:")}</p>
          <Link to={`/business-card/${userId}`}>
            <p
              style={{
                color: `${colorPalette.colorPrimary.main}`,
                cursor: "pointer"
              }}
            >
              {`${window.location.origin}/business-card/${userId}`}
            </p>
          </Link>
        </>
      )}
    </div>
  );
};

export default ProfileCardPreview;
