export const ProductVariationsTypes = {
  GET: {
    GET_PRODUCT_VARIATIONS_BY_PARENT_ID_PENDING:
      "GET_PRODUCT_VARIATIONS_BY_PARENT_ID_PENDING",
    GET_PRODUCT_VARIATIONS_BY_PARENT_ID_SUCCESS:
      "GET_PRODUCT_VARIATIONS_BY_PARENT_ID_SUCCESS",
    GET_PRODUCT_VARIATIONS_BY_PARENT_ID_ERROR:
      "GET_PRODUCT_VARIATIONS_BY_PARENT_ID_ERROR",

    GET_PRODUCT_VARIATION_BY_ID_PENDING: "GET_PRODUCT_VARIATION_BY_ID_PENDING",
    GET_PRODUCT_VARIATION_BY_ID_SUCCESS: "GET_PRODUCT_VARIATION_BY_ID_SUCCESS",
    GET_PRODUCT_VARIATION_BY_ID_ERROR: "GET_PRODUCT_VARIATION_BY_ID_ERROR"
  },
  POST: {
    ADD_PRODUCT_VARIATION_PENDING: "ADD_PRODUCT_VARIATION_PENDING",
    ADD_PRODUCT_VARIATION_SUCCESS: "ADD_PRODUCT_VARIATION_SUCCESS",
    ADD_PRODUCT_VARIATION_ERROR: "ADD_PRODUCT_VARIATION_ERROR"
  },
  PUT: {
    EDIT_PRODUCT_VARIATION_PENDING: "EDIT_PRODUCT_VARIATION_PENDING",
    EDIT_PRODUCT_VARIATION_SUCCESS: "EDIT_PRODUCT_VARIATION_SUCCESS",
    EDIT_PRODUCT_VARIATION_ERROR: "EDIT_PRODUCT_VARIATION_ERROR"
  },
  PATCH: {
    IMAGE: {
      PATCH_VARIATION_IMAGE_PENDING: "PATCH_VARIATION_IMAGE_PENDING",
      PATCH_VARIATION_IMAGE_SUCCESS: "PATCH_VARIATION_IMAGE_SUCCESS"
    }
  },
  DELETE: {
    DELETE_PRODUCT_VARIATION_BY_ID_PENDING:
      "DELETE_PRODUCT_VARIATION_BY_ID_PENDING",
    DELETE_PRODUCT_VARIATION_BY_ID_SUCCESS:
      "DELETE_PRODUCT_VARIATION_BY_ID_SUCCESS",
    DELETE_PRODUCT_VARIATION_BY_ID_ERROR: "DELETE_PRODUCT_VARIATION_BY_ID_ERROR"
  },
  CLEAR_PRODUCT_VARIATION_REQUEST_STATUS:
    "CLEAR_PRODUCT_VARIATION_REQUEST_STATUS"
};
