import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  getDepartmentsByOrganization,
  getVacanciesByDepartmentId,
  getPermissionsGroup,
  getStaffGroup,
  addDepartmentStaff,
  editDepartmentVacancy,
  getDepartmentVacancyById,
  setAddVacancyDataAction,
  clearDepartmentCreate,
  getVacanciesByDepartmentOrganization,
  searchUserByEmail
} from "../../../redux/actions-exporter";

import {
  DefaultButton,
  DefaultDropdown,
  DefaultDropdownMultiSelect
} from "../../../components";

import { withDebounce } from "../../../shared";
import { ParentWrapper, ButtonsFooterWrapper } from "../../../layout";

import "./my-business-client-edit.styles.scss";
import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";

const MyBusinessDepartmentStaff = ({
  vacancies,
  companyDepartments,
  getDepartmentsByOrganization,
  getVacanciesByDepartmentId,
  addDepartmentStaff,
  getStaffGroup,
  getPermissionsGroup,
  staffGroup,
  permissionsGroup,
  getDepartmentVacancyById,

  clearDepartmentCreate,
  departmentRequestStatus,
  searchUserByEmail,
  searchedUsers,
  departmentVacancyById
}) => {
  const { staffId, companyId } = useParams();
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [searchUser, setSearchUser] = useState("");

  const [departmentStaff, setDepartmentStaff] = useState({
    sDepartmentId: "",
    sVacancy: "",
    sUserEmail: ""
  });
  const { sDepartmentId, sVacancy, sUserEmail } = departmentStaff;

  const [groupStaffShow, setGroupStaffShow] = useState([]);
  const [staffRolles, setStaffRoles] = useState([]);
  const [staffPermissions, setStaffPermissions] = useState([]);

  const setAddStaffHandler = (name, value) =>
    setDepartmentStaff({ ...departmentStaff, [name]: value });

  useEffect(() => {
    searchUserByEmail(searchUser);
  }, [searchUserByEmail, searchUser]);

  useEffect(() => {
    getStaffGroup();
    getPermissionsGroup();
  }, [getStaffGroup, getPermissionsGroup]);

  useEffect(() => {
    getDepartmentsByOrganization(companyId);
  }, [getDepartmentsByOrganization, companyId]);

  useEffect(() => {
    if (staffId !== "create") {
      const {
        department,
        profile,
        staffgroup,
        permission_extra_group
      } = departmentVacancyById;
      setDepartmentStaff(departmentStaff => ({
        ...departmentStaff,
        sDepartmentId: department && department.id,
        sVacancy: staffId,
        sUserEmail: profile && profile[0] && profile[0].user
      }));
      setStaffPermissions(permission_extra_group);
      setGroupStaffShow(
        staffgroup &&
          staffgroup.map(({ group: { id, name } }) => ({ id, name }))
      );
      setStaffRoles(
        staffgroup &&
          staffgroup.map(({ group: { id, name } }) => ({ id, name }))
      );
    }
  }, [staffId, departmentVacancyById]);

  useEffect(() => {
    setDepartmentStaff(departmentStaff => ({
      ...departmentStaff,
      sVacancy: ""
    }));
  }, [sDepartmentId]);

  useEffect(() => {
    if (staffId !== "create") {
      getDepartmentVacancyById(staffId);
    }
  }, [staffId, getDepartmentVacancyById]);

  useEffect(() => {
    if (sDepartmentId) {
      getVacanciesByDepartmentId(sDepartmentId);
    }
  }, [sDepartmentId, getVacanciesByDepartmentId]);

  useEffect(() => {
    if (departmentRequestStatus === 200 || departmentRequestStatus === 201) {
      history.goBack();
    }
  }, [history, departmentRequestStatus]);

  useEffect(() => () => clearDepartmentCreate(), [clearDepartmentCreate]);

  const editStaff = () => {
    addDepartmentStaff(sVacancy, sUserEmail, groupStaffShow, staffPermissions);
  };

  useEffect(() => {
    setDisabled(
      sDepartmentId === "" ||
        sVacancy === "" ||
        sUserEmail === "" ||
        (groupStaffShow && groupStaffShow.length < 1) ||
        (staffPermissions && staffPermissions.length < 1)
    );
  }, [sDepartmentId, sVacancy, sUserEmail, groupStaffShow, staffPermissions]);

  const searchUserHandler = withDebounce(email => setSearchUser(email));
  const cancelStaff = () => history.goBack();

  return (
    <div className="my-business-client-edit-container">
      <ParentWrapper>
        <DefaultDropdown
          label="DEPARTMENT NAME"
          items={companyDepartments.map(el => ({ id: el.id, name: el.name }))}
          returnData={e => setAddStaffHandler("sDepartmentId", e)}
          customTitle={
            staffId !== "create" &&
            departmentVacancyById.department &&
            departmentVacancyById.department.name
          }
          required={true}
        />
        <DefaultDropdown
          label="VACANCY TITLE"
          items={vacancies.map(el => ({ id: el.id, name: el.name }))}
          returnData={e => setAddStaffHandler("sVacancy", e)}
          customTitle={staffId !== "create" && departmentVacancyById.name}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultDropdown
          label="USER EMAIL"
          filter={true}
          customTitle={
            staffId !== "create" &&
            departmentVacancyById.profile &&
            departmentVacancyById.profile[0].user_email
          }
          inputFunc={e => searchUserHandler(e.target.value)}
          items={
            searchedUsers.results && !!searchedUsers.results.length
              ? searchedUsers.results.map(({ user, user_email }) => ({
                  id: user,
                  name: user_email
                }))
              : []
          }
          returnData={e => setAddStaffHandler("sUserEmail", e)}
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultDropdownMultiSelect
          label="SELECT STAFF GROUP"
          items={
            staffGroup.length &&
            staffGroup.map(({ group }) => ({
              id: group.id,
              name: group.name
            }))
          }
          returnData={setGroupStaffShow}
          customArray={
            staffId !== "create" &&
            staffRolles &&
            !!staffRolles.length &&
            staffRolles
          }
          required={true}
        />
        <DefaultDropdownMultiSelect
          label="SELECT ADDITIONAL PERMISSIONS"
          items={permissionsGroup}
          returnData={setStaffPermissions}
          customArray={
            staffId !== "create" && departmentVacancyById.permission_extra_group
          }
          required={true}
        />
      </ParentWrapper>
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={editStaff}
            width="100%"
            {...buttonOk}
            disabled={disabled}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton onClick={cancelStaff} width="100%" {...buttonCancel}>
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  currentVacancyStaff: state.vacanciesReducer.departmentVacancyCurrent,
  currentCompany: state.vacanciesReducer.departmentCurrentCompany,
  companyDepartments: state.vacanciesReducer.departmentsByOrganization,
  vacancies: state.vacanciesReducer.vacanciesByDepartmentId,
  staffGroup: state.vacanciesReducer.staffGroup,
  permissionsGroup: state.vacanciesReducer.permissionsGroup,
  userByEmail: state.vacanciesReducer.userByEmail,
  departmentRequestStatus: state.vacanciesReducer.departmentRequestStatus,
  searchedUsers: state.profileAPI.searchedUsers,
  departmentVacancyById: state.vacanciesReducer.departmentVacancyById
});

export default connect(mapStateToProps, {
  setAddVacancyDataAction,
  getDepartmentsByOrganization,
  getVacanciesByDepartmentId,
  getStaffGroup,
  getPermissionsGroup,
  addDepartmentStaff,
  editDepartmentVacancy,
  getDepartmentVacancyById,
  clearDepartmentCreate,
  getVacanciesByDepartmentOrganization,
  searchUserByEmail
})(MyBusinessDepartmentStaff);
