import { FilterActionTypes } from "./filter.types";

const INITIAL_STATE = {
  countriesFilterActive: false,
  filterCountriesArr: [],

  organizationsFilterActive: false,
  filterOrganizationsArr: [],

  b2bFilterProducersActive: false,
  filterB2BProducersArr: [],

  b2cFilterProducersActive: false,
  filterB2CProducersArr: [],

  branchesFilterActive: false,
  filterBranchesArr: [],

  categoriesB2CFilterActive: false,
  categoriesB2BFilterActive: false,
  filterB2CCategoriesArr: [],
  filterB2BCategoriesArr: []
};

const FilterReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FilterActionTypes.COUNTRIES.SET_COUNTRIES_FILTER_ACTIVE:
      return {
        ...state,
        countriesFilterActive: action.payload
      };

    case FilterActionTypes.COUNTRIES.ADD_COUNTRY:
      let findCountry = state.filterCountriesArr.find(
        country => country.id === action.payload.id
      );
      if (!findCountry) {
        return {
          ...state,
          filterCountriesArr: [...state.filterCountriesArr, action.payload]
        };
      } else {
        return { ...state };
      }

    case FilterActionTypes.COUNTRIES.DELETE_COUNTRY:
      return {
        ...state,
        filterCountriesArr: state.filterCountriesArr.filter(
          country => country.id !== action.payload
        )
      };

    case FilterActionTypes.COUNTRIES.SET_COUNTRIES_FILTER_QUERY:
      return {
        ...state,
        countriesFilterQuery: action.payload.map
      };

    case FilterActionTypes.ORGANIZATIONS.SET_ORGANIZATIONS_FILTER_ACTIVE:
      return {
        ...state,
        organizationsFilterActive: action.payload
      };

    case FilterActionTypes.ORGANIZATIONS.ADD_ORGANIZATION:
      return {
        ...state,
        filterOrganizationsArr: [
          ...state.filterOrganizationsArr,
          action.payload
        ]
      };

    case FilterActionTypes.ORGANIZATIONS.DELETE_ORGANIZATION:
      return {
        ...state,
        filterOrganizationsArr: state.filterOrganizationsArr.filter(
          organization => organization.id !== action.payload
        )
      };

    case FilterActionTypes.PRODUCERS.SET_B2C_PRODUCERS_FILTER_ACTIVE:
      return {
        ...state,
        b2cFilterProducersActive: action.payload
      };

    case FilterActionTypes.PRODUCERS.ADD_B2C_PRODUCER:
      return {
        ...state,
        filterB2CProducersArr: [...state.filterB2CProducersArr, action.payload]
      };

    case FilterActionTypes.PRODUCERS.DELETE_B2C_PRODUCER:
      return {
        ...state,
        filterB2CProducersArr: state.filterB2CProducersArr.filter(
          producer => producer.id !== action.payload
        )
      };

    case FilterActionTypes.BRANCHES.SET_BRANCHES_FILTER_ACTIVE:
      return {
        ...state,
        branchesFilterActive: action.payload
      };

    case FilterActionTypes.BRANCHES.ADD_BRANCH:
      return {
        ...state,
        filterBranchesArr: [...state.filterBranchesArr, action.payload]
      };

    case FilterActionTypes.BRANCHES.DELETE_BRANCH:
      return {
        ...state,
        filterBranchesArr: state.filterBranchesArr.filter(
          branch => branch.id !== action.payload
        )
      };

    case FilterActionTypes.CATEGORIES_B2C.SET_B2C_CATEGORIES_FILTER_ACTIVE:
      return {
        ...state,
        categoriesB2CFilterActive: action.payload
      };

    case FilterActionTypes.CATEGORIES_B2C.ADD_B2C_CATEGORY:
      return {
        ...state,
        filterB2CCategoriesArr: [
          ...state.filterB2CCategoriesArr,
          action.payload
        ]
      };

    case FilterActionTypes.CATEGORIES_B2C.DELETE_B2C_CATEGORY:
      return {
        ...state,
        filterB2CCategoriesArr: state.filterB2CCategoriesArr.filter(
          category => category.id !== action.payload
        )
      };

    case FilterActionTypes.CATEGORIES_B2B.SET_B2B_CATEGORIES_FILTER_ACTIVE:
      return {
        ...state,
        categoriesB2BFilterActive: action.payload
      };

    case FilterActionTypes.CATEGORIES_B2B.ADD_B2B_CATEGORY:
      return {
        ...state,
        filterB2BCategoriesArr: [
          ...state.filterB2BCategoriesArr,
          action.payload
        ]
      };

    case FilterActionTypes.CATEGORIES_B2B.DELETE_B2B_CATEGORY:
      return {
        ...state,
        filterB2BCategoriesArr: state.filterB2BCategoriesArr.filter(
          category => category.id !== action.payload
        )
      };

    case FilterActionTypes.RESET_FILTER:
      return {
        ...state,
        filteredCountries: action.payload
      };

    default:
      return state;
  }
};

export default FilterReducer;
