import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Link, useRouteMatch, useHistory } from "react-router-dom";

import Card from "@material-ui/core/Card";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import PelecardSuccess from "../../components/pelecard/pages/success";
import PelecardError from "../../components/pelecard/pages/error";

import {
  DefaultButton,
  LoadingSpinner,
  DefaultIconButton,
  CartTabs,
  CartProductsByCompany,
  NoDataFound
} from "../../components";

import {
  MyPersonOrders,
  LastOrders,
  Recommendation,
  WishList,
  WeRecommend,
  Coupon,
  CheckoutEmail
} from "../../pages";

import {
  getCart,
  getDeal,
  deleteDeal,
  editCart,
  getCartCompaniesWithTheirProducts,
  getCartCompaniesWithTheirProductsSuccess
} from "../../redux/actions-exporter";

import "./cart.styles.scss";

const Cart = ({
  getCart,
  isCartPending,

  getDeal,
  isDealPending,

  deleteDeal,
  editCart,

  getCartCompaniesWithTheirProducts,
  getCartCompaniesWithTheirProductsSuccess,
  areProductsWithCompaniesPending,
  productsWithCompanies
}) => {
  useEffect(() => {
    getCart();
    getDeal();
  }, [getCart, getDeal]);

  const { url } = useRouteMatch();

  const history = useHistory();
  const { location } = useHistory();

  const activeClass = (location, currentURL) =>
    location === currentURL ? "#ededed" : "transparent";

  useEffect(() => {
    getCartCompaniesWithTheirProducts();
  }, []); // eslint-disable-line

  return (
    <div className="cart">
      {isCartPending && isDealPending ? (
        <LoadingSpinner />
      ) : (
        <Card>
          <div className="cart__heading">
            <div className="cart__heading__button" onClick={history.goBack}>
              <DefaultIconButton>
                <ArrowBackIosIcon className="cart__heading__button__icon" />
              </DefaultIconButton>
            </div>
            <div className="cart__heading__title">Cart</div>
          </div>
          <div className="cart__upper-nav">
            <DefaultButton
              backgroundColor={activeClass(location.pathname, "/cart")}
              width="100%"
              fontSize="20px"
            >
              <Link to="/cart">My Cart</Link>
            </DefaultButton>
            <div className="cart__vertical-separator" />
            <DefaultButton
              backgroundColor={activeClass(
                location.pathname,
                `${url}/last-orders`
              )}
              width="100%"
              fontSize="20px"
            >
              <Link to={`${url}/last-orders`}>Last orders</Link>
            </DefaultButton>
          </div>
          <CartTabs />
          <div className="cart__main-content">
            <Route exact path={url}>
              {areProductsWithCompaniesPending ? (
                <LoadingSpinner />
              ) : productsWithCompanies.length ? (
                productsWithCompanies.map(
                  ({ id, name, logo, company_my_deals, company_cart_info }) => (
                    <CartProductsByCompany
                      key={id}
                      id={id}
                      logo={logo}
                      name={name}
                      company_my_deals={company_my_deals}
                      company_cart_info={company_cart_info}
                      editCart={editCart}
                      deleteDeal={deleteDeal}
                      getCartCompaniesWithTheirProductsSuccess={
                        getCartCompaniesWithTheirProductsSuccess
                      }
                    />
                  )
                )
              ) : (
                <NoDataFound noDataTitle="No products in Cart!" />
              )}
            </Route>
            <Route path={`${url}/last-orders`} component={LastOrders} exact />
            <Route path={`${url}/wish-list`} component={WishList} exact />
            <Route
              path={`${url}/ai-recommendation`}
              component={Recommendation}
              exact
            />
            <Route path={`${url}/we-recommend`} component={WeRecommend} exact />
            <Route path={`${url}/coupon`} component={Coupon} exact />
            <Route
              path={"/cart/checkout/company/:companyId"}
              component={CheckoutEmail}
            />
            <Route
              path={"/cart/order/success/:transactionId"}
              component={PelecardSuccess}
              exact
            />
            <Route path={"/cart/order/error"} component={PelecardError} />
            <Route
              path={`${url}/last-orders`}
              component={MyPersonOrders}
              exact
            />
          </div>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  areProductsWithCompaniesPending: state.cart.areProductsWithCompaniesPending,
  productsWithCompanies: state.cart.productsWithCompanies,

  isCartPending: state.cart.isCartPending,
  cart: state.cart.cart,

  isDealPending: state.cart.isDealPending,
  deal: state.cart.deal,
  dealCount: state.cart.dealCount
});

export default connect(mapStateToProps, {
  getCart,
  getDeal,
  deleteDeal,
  editCart,
  getCartCompaniesWithTheirProducts,
  getCartCompaniesWithTheirProductsSuccess
})(Cart);
