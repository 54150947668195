import React from "react";
import { Link } from "react-router-dom";

import {
  LinkedIn as LinkedInIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from "@material-ui/icons";

import { Logo } from "../../assets";
import { DefaultIconButton } from "../../components";

import "./footer.styles.scss";

const Footer = ({ localizationData, footerPage }) => {
  const FooterLinks = (type) =>
    footerPage && !!footerPage.length
      ? footerPage
          .filter(({ page_type }) => page_type === type)
          .map(({ id, title }) => (
            <div key={id} className="nav-link">
              <Link
                to={`/static/${id}/${title
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
              >
                {title}
              </Link>
            </div>
          ))
      : "No footer column!";

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="nav-containers">
          <div className="nav-container nav-container__flexibled">
            <div className="nav-description nav-description-logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>

            <div className="nav-links">
              <p>
                {`B24online is a business and social portal for the 
                promotion of SMEs and promoting products at 
                the most attractive prices to the consumer.`}
              </p>
              <div className="nav-links__icons">
                <DefaultIconButton>
                  <LinkedInIcon />
                </DefaultIconButton>
                <span />
                <DefaultIconButton>
                  <FacebookIcon />
                </DefaultIconButton>
                <span />

                <DefaultIconButton>
                  <TwitterIcon />
                </DefaultIconButton>
                <span />

                <DefaultIconButton>
                  <InstagramIcon />
                </DefaultIconButton>
                <span />

                <DefaultIconButton>
                  <YouTubeIcon />
                </DefaultIconButton>
              </div>
            </div>
            <div className="copy-right">
              {localizationData["B24Online"]} © 2010 –{" "}
              {new Date().getFullYear()}{" "}
            </div>
          </div>
          <div className="nav-container">
            <div className="nav-description">
              {localizationData["About"] || "About"}
            </div>
            <div className="nav-links">{FooterLinks("about")}</div>
          </div>
          <div className="nav-container">
            <div className="nav-description">
              {localizationData["Advices"] || "Advices"}
            </div>
            <div className="nav-links">{FooterLinks("advices")}</div>
          </div>

          <div className="nav-container">
            <div className="nav-description">
              {localizationData["Contacts"] || "Contacts"}
            </div>
            <div className="nav-links">{FooterLinks("contacts")}</div>
          </div>
          <div className="nav-container">
            <div className="nav-description">
              {localizationData["Business"] || "Business"}
            </div>
            <div className="nav-links">
              <div className="nav-link nav-link_bold">
                {localizationData["Companies"]}:{" "}
                <span className="nav-link nav-link_regular">51330</span>
              </div>
              <div className="nav-link nav-link_bold">
                {localizationData["Partners"]}:{" "}
                <span className="nav-link nav-link_regular">215</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
