import React from "react";

import EventVisitor from "../event-visitor/event-visitor.component";
import { companies } from "../../fake-data/companies";
import blackMail from "../../assets/black-envelope-icon.png";
import blackPhone from "../../assets/black-phone-icon.png";
import blackChat from "../../assets/black-chat-icon.png";

import "./event-company.styles.scss";

const EventCompany = () => {
  return (
    <div className="event-company">
      <EventVisitor
        data={companies}
        colName1="LOGO"
        colName2="NAME"
        colName3="SERVICES"
        colName4="FEEDBACK"
        visitor={false}
        avatar="https://via.placeholder.com/60x40"
        row2="John Doe"
        row3="La Placinte SRL"
        link1={blackMail}
        link2={blackPhone}
        link3={blackChat}
      />
    </div>
  );
};

export default EventCompany;
