import { ThemesActionTypes } from "./themes.types";

const INITIAL_STATE = {
  themes: [],
  isThemesPending: false,
  themesPagination: {
    total: 10,
    perPage: 10
  },
  areThemesLoaded: false,
  isPagesFromThemeCreated: false,
  themeCategories: [],
  isThemeCategoriesPending: false
};

const ThemesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ThemesActionTypes.GET_THEMES:
      return {
        ...state,
        themes: action.payload,
        isThemesPending: false,
        areThemesLoaded: true,
        isPagesFromThemeCreated: false
      };

    case ThemesActionTypes.GET_THEMES_PAGINATION:
      return {
        ...state,
        themesPagination: action.payload
      };

    case ThemesActionTypes.GET_THEMES_PENDING:
      return {
        ...state,
        isThemesPending: true
      };

    case ThemesActionTypes.CREATE_PAGES_FROM_THEME:
      return {
        ...state,
        isPagesFromThemeCreated: true
      };

    case ThemesActionTypes.GET_THEME_CATEGORIES:
      return {
        ...state,
        themeCategories: action.payload,
        isThemeCategoriesPending: false
      };

    case ThemesActionTypes.GET_THEME_CATEGORIES_PENDING:
      return {
        ...state,
        isThemeCategoriesPending: true
      };

    default:
      return state;
  }
};

export default ThemesReducer;
