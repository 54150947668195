import icon from "./icon.png";

export default function companiesWithPaginationBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .companies-with-pagination-wrapper {}

    .companies-with-pagination-wrapper.companies-with-pagination-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .companies-with-pagination-wrapper.companies-with-pagination-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .companies-with-pagination {
      display: flex;
      flex-flow: column wrap;
    }

    .cwp-company {
      width: 100%;
      display: flex;
      flex-flow: row;
      margin: 0 0 10px;
      border: 1px solid #aeaeae;
      border-radius: 3px;
    }

    @media screen and (max-width: 992px) {
      .cwp-company {
        flex-flow: row wrap;
        flex-basis: 100%;
      }

      .cwp-company__media {
        width: 100%;
        height: 180px;
      }

      .cwp-company__main {
        width: 100%;
      }

      .cwp-pagination__button {
        padding: 8px 11px;
      }
    }

    .cwp-company__media {
      height: 100%;
      width: 160px;
      object-fit: scale-down;
    }

    .cwp-company__main {
      display: flex;
      flex-flow: column;
      width: 85%;
      padding: 18px;
    }

    .cwp-company__title {
      color: #262626;
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 10px;
    }

    .cwp-company__link {
      color: inherit;
      text-decoration-color: currentColor;
    }

    .cwp-company__description {
      width: 100%;
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 20px;
    }

    .cwp-company__meta {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin: auto 0 0;
    }

    .cwp-company__meta-item {
      margin-right: 30px;
    }

    .cwp-company__icon {
      width: 17px;
      height: 17px;
      color: #2f2d2d;
    }

    .cwp-company__address {
      vertical-align: top;
      padding: 0 0 0 4px;
    }

    .cwp-company__phone {
      vertical-align: top;
      padding: 0 0 0 4px;
    }

    .cwp-company__button {
      background-color: #aeaeae;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 12px 46px;
      border: none;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .cwp-company__button:not(.cwp-company__button--disabled):hover {
      background-color: #8c8c8c;
    }

    .cwp-company__button--disabled {
      background-color: #bfbfbf;
      color: #fbfbfb;
      cursor: not-allowed;
    }

    .cwp-company__wish {
      color: #737373;
      cursor: pointer;
      user-select: none;
    }

    .cwp-pagination {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      align-self: flex-end;
      margin: 0 0 0 auto;
      padding: 20px;
    }

    .cwp-pagination__separator {
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 0px 10px;
      margin: 0 4px;
      outline: none;
      cursor: pointer;
      vertical-align: bottom;
    }

    .cwp-pagination__button {
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      margin: 0 4px;
      border: 2px solid #aeaeae;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .cwp-pagination__button.cwp-pagination__button--active {
      color: #fff;
      background: #c0c0c0;
    }

    .cwp-pagination__button--disabled, .cwp-pagination__button:hover {
      background: #ededed;
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="companies-with-pagination-wrapper">
    <div class="companies-with-pagination">
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                N/A
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                N/A
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                Doe Street
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                1208461
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                john.doe@gmail.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                winery street
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                079654123
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                admin@winery.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                John Doe
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                John Doe
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                john.doe@gmail.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                N/A
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                N/A
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                N/A
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                Test
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                123123123
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                test@test.test
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                Test
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                Test
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                test@test.test
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cwp-company">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
          class="cwp-company__media" id="ik1dh">
        <div class="cwp-company__main">
          <h4 class="cwp-company__title">
            <a class="cwp-company__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="cwp-company__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <div class="cwp-company__meta">
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--point" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
                style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
                <g>
                  <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
        c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
        c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
        c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
        C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
                </g>
              </svg>
              <span class="cwp-company__address">
                Traian Beach
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <svg version="1.1" class="cwp-company__icon cwp-company__icon--phone" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
           c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
          c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
          C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z">
                    </path>
                  </g>
                </g>
              </svg>
              <span class="cwp-company__phone">
                999999999
              </span>
            </div>
            <div class="cwp-company__meta-item">
              <span class="cwp-company__email">
                admin@gmail.com
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="cwp-pagination">
        <button class="cwp-pagination__button cwp-pagination__button--disabled" disabled="">
          &lt;
        </button>










        <button class="cwp-pagination__button cwp-pagination__button--active" data-page="1">
          1
        </button>


        <button class="cwp-pagination__button" data-page="2">
          2
        </button>

        <span class="cwp-pagination__separator">...</span>

        <button class="cwp-pagination__button" data-page="100">
          100
        </button>


        <button class="cwp-pagination__button" data-page="2">
          &gt;
        </button>
      </div>

    </div>
  </div>
  `;

  blockManager.add("companies-with-pagination", {
    label: `
      <img src="${icon}" alt="Companies with pagination block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Companies With Pagination
      </div>
    `,
    content,
    category: "B24Online Companies"
  });
}
