import React, { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import countryTelData from "country-telephone-data";
import { useTranslation } from "react-i18next";

import { missingImage, missingVideo } from "../../../assets";
import { organizationType, videoIsUploaded } from "../../../constants";
import {
  ColumnsSmLgWrapper,
  ColumnWrapperLg,
  ColumnWrapperSm,
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "@core/layout";

import {
  MediaActions,
  GallerySection,
  VideoSection
} from "../../../components";

import {
  CircleLoader,
  DefaultTextArea,
  DefaultButton,
  DefaultDropdown,
  InputGroup,
  EditorGroup,
  DefaultDropdownMultiSelect,
  UploadFileDocument,
  SocialLinksGroup,
  DefaultSelectInput,
  DocumentsTable,
  Separator,
  AdditionalPagesUpload,
  AdditionalTable
} from "@core/components";

import {
  searchCountry,
  getCompanyTypes,
  createOrganization,
  getOrganization,
  updateOrganization,
  clearOrganizationRequest,
  uploadDocument,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  deleteDocumentById,
  loadFileGallery,
  loadBlobGallery,
  uploadImageGallery,
  uploadVideoGallery,
  deleteOneImageFromGallery,
  loadBlobVideo,
  loadFileVideo,
  deleteOneVideoFromGallery,
  getChamberTypes,
  searchBranch
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "../organizations-edit/my-business-organizations-edit.styles.scss";

const MyBusinessOrganizationsEdit = ({
  appDirection,
  countries,
  currentOrganization,
  searchCountry,
  getCompanyTypes,

  getOrganization,
  createOrganization,
  updateOrganization,

  uploadDocument,
  deleteAdditionalPageById,
  uploadAdditionalPage,
  deleteDocumentById,
  organizationRequestStatus,
  clearOrganizationRequest,
  loadFileGallery,
  loadBlobGallery,
  galleryBlob,
  galleryFile,
  uploadImageGallery,
  deleteOneImageFromGallery,
  uploadVideoGallery,
  loadBlobVideo,
  loadFileVideo,
  deleteOneVideoFromGallery,
  videoFile,
  videoBlob,
  getChamberTypes,
  isImageGalleryUploadPending,
  isVideoGalleryUploadPending,
  chamberTypes,
  searchBranch,
  branches
}) => {
  const { orgId } = useParams();
  const { t } = useTranslation();

  const countryCodes = countryTelData.allCountries.map(
    ({ iso2, dialCode }) => ({ value: iso2, label: "+ " + dialCode })
  );

  const history = useHistory();

  const [name, setName] = useState("");
  const [country, setCountryId] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [orgTypeId, setOrgTypeId] = useState("");
  const [vatin, setVATIN] = useState("");
  const [url, setURL] = useState("");
  const [dirName, setDirName] = useState("");
  const [email, setEmail] = useState("");
  const [, setPhoneCodeId] = useState("");
  const [phone, setPhone] = useState("");
  // const [, setFullPhoneCode] = useState();
  const [, setFaxCodeId] = useState("");
  // const [, setFullFaxCode] = useState("");
  const [fax, setFax] = useState("");
  const [address, setAddress] = useState("");
  const [slogan, setSlogan] = useState("");
  const [keywords, setKeywords] = useState("");
  const [script, setScript] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [desc, setDesc] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [reddit, setReddit] = useState("");
  const [file, setFile] = useState("");
  const [blob, setBlob] = useState();
  const [additionalPages, setAdditionalPages] = useState("");
  const [organizationGallery, setOrganizationGallery] = useState([]);
  const [documents, setDocuments] = useState(null);
  const [branchInput, setBranchInput] = useState("");
  const [branch, setBranch] = useState([]);
  const [botToken, setBotToken] = useState("");

  const [additionalPageTitle, setAdditionalPageTitle] = useState("");
  const [additionalPageEditor, setAdditionalPageEditor] = useState("");

  const [docName, setDocName] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [documentBlob, setDocumentBlob] = useState(null);

  const [disabledButton, setDisableButton] = useState(true);

  useEffect(() => {
    searchCountry(countryInput);
    getCompanyTypes();
    getChamberTypes();
    searchBranch(branchInput);
  }, [
    searchCountry,
    getChamberTypes,
    getCompanyTypes,
    countryInput,
    branchInput,
    searchBranch
  ]);

  useEffect(() => {
    if (orgId !== "create") {
      getOrganization(orgId);
    }
  }, [orgId, getOrganization]);

  useEffect(() => {
    setDisableButton(
      name === "" || desc === "" || country === "" || orgTypeId === ""
    );
  }, [name, desc, country, orgTypeId]);

  useEffect(() => {
    if (
      organizationRequestStatus === 200 ||
      organizationRequestStatus === 201
    ) {
      history.push("/my-business/organizations");
    }
  }, [history, organizationRequestStatus]);

  useEffect(() => {
    if (orgId !== "create") {
      const {
        name,
        countries,
        org_type,
        address,
        keywords,
        slug,
        short_description,
        description,
        director,
        metadata,
        logo,
        additional_pages,
        galleries,
        documents,
        bot_token
      } = currentOrganization;
      setName(name);
      setSlogan(slug);
      setShortDesc(short_description);
      setDesc(description);
      setKeywords(keywords);
      setDirName(director);
      setFile(logo);
      setAddress(address);
      setOrganizationGallery(galleries);
      setOrgTypeId(org_type && org_type.value);
      setFax(metadata && metadata.fax);
      setURL(metadata && metadata.site);
      setEmail(metadata && metadata.email);
      setPhone(metadata && metadata.phone);
      setVATIN(metadata && metadata.vat_identification_number);
      setScript(metadata && metadata.script);
      setCountryId(countries && countries[0] && countries[0].id);
      setAdditionalPages(additional_pages);
      setDocuments(documents);
      setBotToken(bot_token);
    }
  }, [currentOrganization, orgId]);

  useEffect(
    () => () => {
      clearOrganizationRequest();
      loadBlobGallery("");
      loadFileGallery("");
      loadFileVideo("");
      loadBlobVideo("");
    },
    [
      clearOrganizationRequest,
      loadBlobGallery,
      loadFileGallery,
      loadFileVideo,
      loadBlobVideo
    ]
  );

  const editOrganization = () => {
    if (orgId === "create") {
      createOrganization(
        name,
        [country],
        orgTypeId,
        branch,
        vatin,
        url,
        dirName,
        email,
        phone,
        fax,
        address,
        slogan,
        keywords,
        shortDesc,
        script,
        desc,
        blob,
        botToken
      );
    } else {
      updateOrganization(
        orgId,
        name,
        [country],
        orgTypeId,
        branch,
        vatin,
        url,
        dirName,
        email,
        phone,
        fax,
        address,
        slogan,
        keywords,
        shortDesc,
        script,
        desc,
        blob,
        botToken
      );
      if (docName && docDescription && documentFile)
        uploadDocument(
          orgId,
          docName,
          docDescription,
          organizationType,
          documentFile,
          documentBlob
        );

      if (additionalPageTitle && additionalPageEditor) {
        uploadAdditionalPage(
          additionalPageTitle,
          additionalPageEditor,
          organizationType,
          orgId
        );
      }
    }
  };

  return (
    <div className="my-business-organizations-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={file ? file : missingImage}
            alt={"company-picture"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton width="100%" {...buttonOk}>
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                width="100%"
                {...buttonCancel}
                onClick={() => {
                  setFile("");
                  setBlob("");
                }}
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {orgId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadImageGallery(
                              organizationType,
                              orgId,
                              galleryBlob
                            )
                          }
                          disabled={galleryBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
              <Separator />
              <MediaActions
                image={videoFile ? videoIsUploaded : missingVideo}
                alt={"missing image"}
                height={200}
                buttons={
                  isVideoGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="video/*"
                              type="file"
                              onChange={e => {
                                loadFileVideo(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobVideo(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadVideoGallery(
                              organizationType,
                              orgId,
                              videoBlob
                            )
                          }
                          disabled={videoBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobVideo("");
                            loadFileVideo("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setName(e.target.value)}
              value={name}
              label="NAME"
              name="name"
              placeholder="Name"
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="COUNTRY"
              items={countries.results}
              returnData={setCountryId}
              filter={true}
              inputFunc={e => setCountryInput(e.target.value)}
              customTitle={
                orgId !== "create" &&
                currentOrganization.countries &&
                currentOrganization.countries[0].name
              }
              required={true}
            />
            <DefaultDropdown
              label="COMPANY TYPE"
              customTitle={
                orgId !== "create" &&
                currentOrganization.org_type &&
                currentOrganization.org_type.label
              }
              items={
                chamberTypes &&
                chamberTypes.map(({ label, value }) => ({
                  id: value,
                  name: label
                }))
              }
              returnData={setOrgTypeId}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setVATIN(e.target.value)}
              value={vatin}
              label="VATIN"
              name="vatin"
              placeholder="VATIN"
            />
            <InputGroup
              onChange={e => setURL(e.target.value)}
              value={url}
              label="URL"
              name="url"
              placeholder="URL"
            />
          </ParentWrapper>
          <ParentWrapper>
            <SectionTextHeader text="Contact Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setDirName(e.target.value)}
              value={dirName}
              label="DIRECTOR NAME"
              name="director-name"
              placeholder="Director Name"
            />
            <InputGroup
              onChange={e => setEmail(e.target.value)}
              value={email}
              label="EMAIL"
              name="email"
              placeholder="Email"
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultSelectInput
              value={phone}
              onChange={e => setPhone(e.target.value)}
              title="Telephone Number"
              options={countryCodes}
              width="100px"
              returnData={setPhoneCodeId}
            />
            <DefaultSelectInput
              value={fax}
              onChange={e => setFax(e.target.value)}
              title="Fax Number"
              options={countryCodes}
              width="100px"
              returnData={setFaxCodeId}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setAddress(e.target.value)}
              value={address}
              label="ADDRESS"
              name="address"
              placeholder="Address"
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      <Separator />
      {organizationGallery &&
        organizationGallery[0] &&
        !!organizationGallery[0].images.length && (
          <>
            <GallerySection
              label="Gallery"
              gallery={organizationGallery}
              deleteOneImageFromGallery={deleteOneImageFromGallery}
              parentId={orgId}
              contentType={organizationType}
            />
            <Separator />
          </>
        )}
      {organizationGallery &&
        organizationGallery[0] &&
        !!organizationGallery[0].videos.length && (
          <>
            <VideoSection
              label="Videos"
              videos={organizationGallery}
              deleteOneVideoFromGallery={deleteOneVideoFromGallery}
              parentId={orgId}
              contentType={organizationType}
            />
            <Separator />
          </>
        )}
      <ParentWrapper>
        <InputGroup
          onChange={e => setSlogan(e.target.value)}
          value={slogan}
          label="SLOGAN"
          name="slogan"
          placeholder="Slogan"
        />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => setKeywords(e.target.value)}
          value={keywords}
          label="KEYWORDS"
          name="keywords"
          placeholder="#hashtag, #hashtag, ..., #hashtag"
        />
      </ParentWrapper>
      <ParentWrapper>
        <div style={{ marginTop: 18 }} className="my-business-category-picker">
          <div style={{ display: "flex" }}>
            <div className="my-business-category-picker__title">
              {t("Branch")}
            </div>
          </div>
          <br />
          <div className="my-business-category-picker__flex-container">
            <DefaultDropdownMultiSelect
              style={{ width: "100%" }}
              items={branches.results}
              returnData={setBranch}
              customArray={orgId !== "create" && currentOrganization.branches}
              filter={true}
              categoryInputValue={branchInput}
              setCategoryInputValue={setBranchInput}
            />
          </div>
        </div>
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          onChange={e => setDesc(e.target.getContent())}
          value={desc}
          label="PREVIEW DESCRIPTION"
          name="previewDescription"
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultTextArea
          onChange={e => setScript(e.target.value)}
          value={script}
          label="Custom script"
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <InputGroup
          onChange={e => setBotToken(e.target.value)}
          value={botToken}
          label="Bot token"
          name="bot-token"
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <EditorGroup
          onChange={e => setShortDesc(e.target.getContent())}
          value={shortDesc}
          label="Short Description"
          name="shortDescription"
          required={false}
        />
      </ParentWrapper>
      <Separator />
      {orgId !== "create" && (
        <Fragment>
          <DocumentsTable
            tableName={"ORGANIZATIONS DOCUMENTS"}
            documents={documents}
            deleteDocumentById={deleteDocumentById}
          />
          <Separator />
          <UploadFileDocument
            docName={docName}
            setDocName={setDocName}
            docDescription={docDescription}
            setDocDescription={setDocDescription}
            documentFile={documentFile}
            setDocumentFile={setDocumentFile}
            documentBlob={documentBlob}
            setDocumentBlob={setDocumentBlob}
            uploadDocument={uploadDocument}
            itemContentType={organizationType}
            itemId={orgId}
          />
          <Separator />
          <AdditionalTable
            tableName={"ORGANIZATION ADDITIONAL PAGES"}
            compId={orgId}
            urlType="organizations"
            additionalPages={additionalPages}
            deleteAdditional={deleteAdditionalPageById}
          />
          <Separator />
          <AdditionalPagesUpload
            setAdditionalPageTitle={setAdditionalPageTitle}
            additionalPageTitle={additionalPageTitle}
            setAdditionalPageEditor={setAdditionalPageEditor}
            additionalPageEditor={additionalPageEditor}
            uploadAdditionalPage={uploadAdditionalPage}
            itemContentType={organizationType}
            itemId={orgId}
          />
        </Fragment>
      )}
      <ParentWrapper>
        <SocialLinksGroup
          handleFacebookChange={e => setFacebook(e.target.value)}
          facebook={facebook}
          handleLinkedinChange={e => setLinkedIn(e.target.value)}
          linkedin={linkedIn}
          handleTwitterChange={e => setTwitter(e.target.value)}
          twitter={twitter}
          handleInstagramChange={e => setInstagram(e.target.value)}
          instagram={instagram}
          handleYoutubeChange={e => setYoutube(e.target.value)}
          youtube={youtube}
          handleRedditChange={e => setReddit(e.target.value)}
          reddit={reddit}
        />
      </ParentWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={editOrganization}
            width="100%"
            {...buttonOk}
            disabled={disabledButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            {...buttonCancel}
            onClick={() => history.push("/my-business/organizations")}
            color="gray"
            width="100%"
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  areCountriesPending: state.countriesReducer.areCountriesPending,
  countries: state.countriesReducer.countries,

  branches: state.branchesReducer.branches,
  areBranchesPending: state.branchesReducer.areBranchesPending,

  currentOrganization: state.organizationsReducer.organization,
  organizationRequestStatus:
    state.organizationsReducer.organizationRequestStatus,
  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,
  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,

  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  isVideoGalleryUploadPending:
    state.documentsReducer.isVideoGalleryUploadPending,

  chamberTypes: state.organizationsReducer.chamberTypes
});

export default connect(mapStateToProps, {
  createOrganization,
  updateOrganization,
  getOrganization,
  searchCountry,
  getCompanyTypes,
  uploadDocument,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  deleteDocumentById,
  loadFileGallery,
  loadBlobGallery,
  uploadImageGallery,
  clearOrganizationRequest,
  deleteOneImageFromGallery,
  uploadVideoGallery,
  loadBlobVideo,
  loadFileVideo,
  deleteOneVideoFromGallery,
  getChamberTypes,
  searchBranch
})(MyBusinessOrganizationsEdit);
