import { axiosNoTokenInstance } from "../../../axios/axios.config";

import { CountriesActionTypes } from "./countries.types";

export const getCountries = (pageNumber = 1) => dispatch => {
  dispatch({ type: CountriesActionTypes.COUNTRIES.GET_COUNTRIES_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/organization/countries/?page=${pageNumber}`, {})
    .then(countries =>
      dispatch({
        type: CountriesActionTypes.COUNTRIES.GET_COUNTRIES_SUCCESS,
        payload: countries.data
      })
    )
    .catch(error =>
      dispatch({
        type: CountriesActionTypes.COUNTRIES.GET_COUNTRIES_ERROR,
        payload: error
      })
    );
};

export const getCountry = countryId => dispatch => {
  dispatch({ type: CountriesActionTypes.GET_COUNTRY.GET_COUNTRY_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/organization/countries/${countryId}/`)
    .then(country =>
      dispatch({
        type: CountriesActionTypes.GET_COUNTRY.GET_COUNTRY_SUCCESS,
        payload: country.data.name
      })
    )
    .catch(error =>
      dispatch({
        type: CountriesActionTypes.GET_COUNTRY.GET_COUNTRY_ERROR,
        payload: error
      })
    );
};

export const getCountryId = () => dispatch => {
  dispatch({
    type: CountriesActionTypes.GET_COUNTRY_ID.GET_COUNTRY_ID_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/location`)
    .then(countryLocation => {
      return axiosNoTokenInstance
        .get(`/api/v1/organization/countries/${countryLocation.data.country}`)
        .then(localeCountry => {
          dispatch({
            type: CountriesActionTypes.GET_COUNTRY_ID.GET_COUNTRY_ID_SUCCESS,
            payload: localeCountry.data.id
          });
        });
    })
    .catch(error =>
      dispatch({
        type: CountriesActionTypes.GET_COUNTRY_ID.GET_COUNTRY_ID_SUCCESS,
        payload: 5
      })
    );
};

export const searchCountry = (searchQuery = "", page = 1) => dispatch => {
  dispatch({ type: CountriesActionTypes.COUNTRIES.GET_COUNTRIES_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/organization/countries/?search=${searchQuery}&page=${page}`)
    .then(countries =>
      dispatch({
        type: CountriesActionTypes.COUNTRIES.GET_COUNTRIES_SUCCESS,
        payload: countries.data
      })
    )
    .catch(error =>
      dispatch({
        type: CountriesActionTypes.COUNTRIES.GET_COUNTRIES_ERROR,
        payload: error
      })
    );
};
