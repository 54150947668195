export const ResumesActionTypes = {
  GET_RESUMES: "GET_RESUMES",
  CREATE_RESUME: "CREATE_RESUME",
  UPDATE_RESUME: "UPDATE_RESUME",
  DELETE_RESUME: "DELETE_RESUME",

  GET_RESUME_BY_ID_PENDING: "GET_RESUME_BY_ID_PENDING",
  GET_RESUME_BY_ID_SUCCESS: "GET_RESUME_BY_ID_SUCCESS",
  GET_RESUME_BY_ID_ERROR: "GET_RESUME_BY_ID_ERROR",

  GET_RESUMES_BY_ME_PENDING: "GET_RESUMES_BY_ME_PENDING",
  GET_RESUMES_BY_ME_SUCCESS: "GET_RESUMES_BY_ME_SUCCESS",
  GET_RESUMES_BY_ME_ERROR: "GET_RESUMES_BY_ME_ERROR",

  GET_STUDY_FORM_TYPES_PENDING: "GET_STUDY_FORM_TYPES_PENDING",
  GET_STUDY_FORM_TYPES_SUCCESS: "GET_STUDY_FORM_TYPES_SUCCESS",
  GET_STUDY_FORM_TYPES_ERROR: "GET_STUDY_FORM_TYPES_ERROR",

  APPLY_TO_JOB_WITH_RESUME_ID_PENDING: "APPLY_TO_JOB_WITH_RESUME_ID_PENDING",
  APPLY_TO_JOB_WITH_RESUME_ID_SUCCESS: "APPLY_TO_JOB_WITH_RESUME_ID_SUCCESS",
  APPLY_TO_JOB_WITH_RESUME_ID_ERROR: "APPLY_TO_JOB_WITH_RESUME_ID_ERROR",

  GET_MARITAL_STATUS: "GET_MARITAL_STATUS",
  CLEAR_RESUME_REQUEST: "CLEAR_RESUME_REQUEST"
};
