export const ProducersActionTypes = {
  SEARCH: {
    SEARCH_PRODUCERS_PENDING: "SEARCH_PRODUCERS_PENDING",
    SEARCH_PRODUCERS_SUCCESS: "SEARCH_PRODUCERS_SUCCESS",
    SEARCH_PRODUCERS_ERROR: "SEARCH_PRODUCERS_ERROR"
  },
  GET: {
    PRODUCERS: {
      GET_B2C_PRODUCERS_PENDING: "GET_B2C_PRODUCERS_PENDING",
      GET_B2C_PRODUCERS_SUCCESS: "GET_B2C_PRODUCERS_SUCCESS",
      GET_B2C_PRODUCERS_ERROR: "GET_B2C_PRODUCERS_ERROR",

      GET_B2C_PRODUCERS_BY_QUERY_PENDING: "GET_B2C_PRODUCERS_BY_QUERY_PENDING",
      GET_B2C_PRODUCERS_BY_QUERY_SUCCESS: "GET_B2C_PRODUCERS_BY_QUERY_SUCCESS",
      GET_B2C_PRODUCERS_BY_QUERY_ERROR: "GET_B2C_PRODUCERS_BY_QUERY_ERROR"
    }
  }
};
