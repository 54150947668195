const wishListStyle = `
<style>
.wish-list-slim .wish-list__remove {
  width: 10%;
  padding: 0 6px 0;
}

.wish-list-wrapper {
  display: flex;
  flex-flow: column;
  overflow: auto;
}

.wish-list-wrapper--preloader {
  position: relative;
  transition: all 300ms ease;
}

.wish-list-wrapper--preloader::before {
  content: "";
  background: hsla(0, 0%, 100%, 0.45);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.wish-list {
  width: 100%;
  border: 1px solid #aeaeae;
  border-collapse: collapse;
}

.wish-list__header {
  background: #f6f6f6;
  border-bottom: 1px solid;
  border-color: #aeaeae;
  color: #7d7d7d;
}

.wish-list__header-title {
  padding: 20px 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase;
}

.wish-list__header-title--item {
  width: 10%;
  text-align: left;
}

.wish-list__header-title--name {
  width: 60%;
  text-align: left;
}

.wish-list__header-title--total {
  width: 30%;
  text-align: left;
}

.wish-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 10px 14px;
}

.wish-item-media {
  flex-basis: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wish-item-media__image {
  height: 90px;
  width: 90px;
  padding: 20px;
  object-fit: scale-down;
  user-select: none;
}

.wish-item-main {
  flex-basis: 72%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
}

.wish-item-main__capture {
  color: hsla(0, 0%, 0%, 0.8);
  font-size: 13px;
  letter-spacing: 0.3px;
  margin-bottom: 3px;
}

.wish-item-main__title {
  color: #262626;
  font-weight: 700;
  text-align: left;
  margin: 0px 0 3%;
}

.wish-item-main__link {
  color: initial;
}

.wish-item-main__link:hover {
  color: inherit;
  text-decoration-color: currentColor;
}

.wish-list__remove {
  width: 8%;
}

.wish-list__remove-icon {
  color: #1e1e1e;
  font-size: 15px;
  margin: 0 80% 0 0;
  cursor: pointer;
  transition: all 300ms ease;
}

.wish-list__remove-icon:hover {
  color: #000000;
}

.wish-list__button {
  background: #fff;
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.84px;
  padding: 10px 18px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  cursor: pointer;
  transition: all 100ms ease;
}

.wish-list__button:hover {
  color: #fff;
  background: #7f7f7f;
  border: 1px solid #7f7f7f;
}

.wish-list__button--disabled {
  background: #eaeaea;
  color: #7f7f7f;
  border: 1px solid #888888;
  cursor: not-allowed;
}

.wish-list__button--disabled:hover {
  background: inherit;
  color: inherit;
}

.wish-list__price {
  font-weight: 700;
}

.wish-list__price.wish-list__price--active {
  color: #ff8700;
}


.wish-list-slim {
  width: 100%;
  border: 1px solid #aeaeae;
  border-collapse: collapse;
}

.wish-list-slim--preloader {
  position: relative;
  transition: all 300ms ease;
}

.wish-list-slim--preloader::before {
  content: "";
  background: hsla(0, 0%, 100%, 0.45);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.wish-list-slim__header {
  background: #f6f6f6;
  border-bottom: 1px solid;
  border-color: #aeaeae;
  color: #7d7d7d;
}

.wish-list-slim__header-title {
  padding: 20px 14px;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase;
}

.wish-list-slim__header-title--item {
  width: 10%;
  text-align: left;
}

.wish-list-slim__header-title--name {
  width: 60%;
  text-align: left;
}

.wish-list-slim__header-title--total {
  width: 30%;
  text-align: left;
}

.wish-item-slim {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 10px 14px;
}

.wish-item-slim-media {
  flex-basis: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wish-item-slim-media__image {
  height: 50px;
  width: 50px;
  padding: 0;
  object-fit: scale-down;
  user-select: none;
}

.wish-item-slim-main {
  flex-basis: 72%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
}

.wish-item-slim-main__capture {
  color: hsla(0, 0%, 0%, 0.8);
  font-size: 10px;
  letter-spacing: 0.3px;
  margin-bottom: 3px;
}

.wish-item-slim-main__title {
  color: #262626;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  margin: 0px 0 3%;
}

.wish-item-slim-main__link {
  color: initial;
}

.wish-item-slim-main__link:hover {
  color: inherit;
  text-decoration-color: currentColor;
}

.wish-list-slim__remove {
  width: 8%;
}

.wish-list-slim__remove-icon {
  color: #1e1e1e;
  font-size: 15px;
  margin: 0 80% 0 0;
  cursor: pointer;
  transition: all 300ms ease;
}

.wish-list-slim__remove-icon:hover {
  color: #000000;
}

.wish-list-slim__button {
  background: #fff;
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.84px;
  padding: 10px 18px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  cursor: pointer;
  transition: all 100ms ease;
}

.wish-list-slim__button:hover {
  color: #fff;
  background: #7f7f7f;
  border: 1px solid #7f7f7f;
}

.wish-list-slim__button--disabled {
  background: #eaeaea;
  color: #7f7f7f;
  border: 1px solid #888888;
  cursor: not-allowed;
}

.wish-list-slim__button--disabled:hover {
  background: inherit;
  color: inherit;
}

.wish-list-slim__price {
  font-weight: 700;
}

.wish-list-slim__price.wish-list-slim__price--active {
  color: #ff8700;
}

.wish-list-slim tr > td:nth-last-child(2) {
  display: none;
}


.wl-pagination {
  align-self: flex-end;
  margin: 0 0 0 auto;
  padding: 20px;
}

.wl-pagination__button {
  background: #fff;
  color: #aeaeae;
  font-size: 16px;
  font-weight: 800;
  padding: 8px 16px;
  margin: 0 4px;
  border: 2px solid #aeaeae;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
}

.wl-pagination__button.wl-pagination__button--active {
  color: #fff;
  background: #c0c0c0;
}

.wl-pagination__button:disabled, .wl-pagination__button:hover {
  background: #ededed;
}
</style>
`;

export default wishListStyle;
