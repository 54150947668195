import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Collapsible from "react-collapsible";

import ExpandArrow from "../expand-arrow/expand-arrow.component";
import SearchIcon from "@material-ui/icons/Search";

import "./default-dropdown.styles.scss";

const DropdownTrigger = ({ triggerTitle, dropdownState, styleTrigger }) => {
  return (
    <div style={styleTrigger} className="default-dropdown-trigger">
      <div className="default-dropdown-trigger__title">{triggerTitle}</div>
      <div className="default-dropdown-trigger__icon">
        <ExpandArrow expand={dropdownState} />
      </div>
    </div>
  );
};

const DefaultDropdown = ({
  label,
  items = [],
  style,
  styleTrigger,
  returnData,
  inputFunc,
  filter = false,
  customTitle,
  required,
  returnFormat,
  reset = true
}) => {
  const { t } = useTranslation();
  const [triggerTitle, setTriggerTitle] = useState(t("Select a value"));
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setTriggerTitle(customTitle ? customTitle : t("Select a value"));
  }, [setTriggerTitle, customTitle, t]);

  return (
    <div style={style} className="default-dropdown">
      {label && (
        <>
          <div style={{ display: "flex" }}>
            <div className="default-dropdown__label">{label}</div>
            {required && (
              <div className="default-dropdown-trigger--required">
                {t("* required")}
              </div>
            )}
          </div>
          <br />{" "}
        </>
      )}
      <div
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="default-dropdown__container"
      >
        <Collapsible
          easing="none"
          triggerDisabled={true}
          open={true}
          trigger={
            <DropdownTrigger
              dropdownState={dropdownOpen}
              triggerTitle={triggerTitle}
              required={required}
              styleTrigger={styleTrigger}
            />
          }
        >
          {dropdownOpen && (
            <div className="default-dropdown__body">
              {filter && (
                <div
                  onClick={e => e.stopPropagation()}
                  className="default-dropdown__input-container"
                >
                  <input
                    onChange={inputFunc}
                    className="default-dropdown__input-container__input"
                    type="text"
                  />
                  <SearchIcon
                    style={{ color: "lightgray " }}
                    className="default-dropdown__input-container__icon"
                  />
                </div>
              )}
              {!required && reset && items.length > 0 && (
                <div
                  key={null}
                  onClick={e => {
                    setTriggerTitle(e.currentTarget.textContent);
                    returnFormat === "full"
                      ? returnData({ id: null, name: t("None") })
                      : returnData(null);
                  }}
                  className="default-dropdown__item"
                >
                  {t("None")}
                </div>
              )}
              {items.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={e => {
                    setTriggerTitle(e.currentTarget.textContent);
                    returnFormat === "full"
                      ? returnData({ id, name })
                      : returnData(id);
                  }}
                  className="default-dropdown__item"
                >
                  {name}
                </div>
              ))}
            </div>
          )}
        </Collapsible>
      </div>
    </div>
  );
};

export default DefaultDropdown;
