export default function authRegistrationComponent({ editor, api }) {
  function script() {
    if (window.hasOwnProperty("grapesjs")) {
      document.querySelector(".registration-form__messages").innerHTML = "";
    }
  }

  editor.DomComponents.addType("auth-registration-form", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "registration-form-wrapper",
    model: {
      defaults: {
        name: "Registration Form",
        script
      }
    }
  });
}
