import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { missingImage, missingVideo } from "../../../assets";
import { eventType, videoIsUploaded } from "../../../constants";

import {
  ColumnWrapperLg,
  ColumnWrapperSm,
  ColumnsSmLgWrapper,
  SectionTextHeader,
  ParentWrapper,
  ButtonsFooterWrapper
} from "../../../layout";

import {
  MediaActions,
  DefaultButton,
  Separator,
  InputGroup,
  EditorGroup,
  DefaultDropdown,
  SocialLinksGroup,
  DefaultDropdownMultiSelect,
  DefaultTextArea,
  DefaultDatePicker,
  AdditionalPagesUpload,
  AdditionalTable,
  GallerySection,
  VideoSection,
  CircleLoader,
  DocumentsTable,
  UploadFileDocument
} from "../../../components";

import {
  searchCountry,
  searchBranch,
  getCompaniesByUserId,
  postEvent,
  putEvent,
  getEventById,
  clearEventRequest,
  searchProducers,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  uploadImageGallery,
  loadBlobGallery,
  loadFileGallery,
  deleteOneImageFromGallery,
  loadBlobVideo,
  loadFileVideo,
  uploadVideoGallery,
  deleteOneVideoFromGallery,
  uploadDocument,
  deleteDocumentById
} from "../../../redux/actions-exporter";

import "./my-business-events-edit.styles.scss";
import { buttonCancel, buttonOk } from "../../../styles/buttons-styles";

const MyBusinessProductsEdit = ({
  searchCountry,
  areCountriesPending,
  countries,

  getCompaniesByUserId,
  areUserCompaniesPending,
  userCompanies,

  postEvent,
  putEvent,

  searchBranch,
  areBranchesPending,
  branchesAPI,

  getEventById,
  isEventByIdPending,
  eventById,

  eventPUT,
  eventRequestStatus,
  clearEventRequest,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  uploadImageGallery,
  loadBlobGallery,
  loadFileGallery,
  galleryBlob,
  galleryFile,
  deleteOneImageFromGallery,
  loadBlobVideo,
  loadFileVideo,
  deleteOneVideoFromGallery,
  videoBlob,
  videoFile,
  uploadVideoGallery,
  uploadDocument,

  isImageGalleryUploadPending,
  isVideoGalleryUploadPending
}) => {
  const { eventId } = useParams();

  const [disableButton, setDisableButton] = useState(false);

  const [name, setName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [city, setCity] = useState("");
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [keywords, setKeywords] = useState("");
  const [branchInput, setBranchInput] = useState("");
  const [branches, setBranches] = useState([]);
  const [route, setRoute] = useState("");
  const [description, setDescription] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [reddit, setReddit] = useState("");

  const [documents, setDocuments] = useState([]);
  const [additionalPageTitle, setAdditionalPageTitle] = useState("");
  const [additionalPageEditor, setAdditionalPageEditor] = useState("");
  const [eventGalleries, setEventGalleries] = useState([]);

  const [docName, setDocName] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [documentBlob, setDocumentBlob] = useState("");

  const [additionalPages, setAdditionalPages] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (eventId !== "create") {
      getEventById(eventId);
    }

    getCompaniesByUserId();
    searchBranch();
  }, [eventId, getEventById, getCompaniesByUserId, searchBranch]);

  useEffect(() => {
    searchCountry(countryInput);
    searchBranch(branchInput);
  }, [searchCountry, searchBranch, countryInput, branchInput]);

  useEffect(() => {
    if (eventId !== "create") {
      const {
        title,
        country,
        city,
        keywords,
        route,
        metadata,
        description,
        start_date,
        end_date,
        additional_pages,
        galleries,
        documents,
        organization
      } = eventById;
      setName(title);
      setCountryId(country);
      setCompanyId(organization);
      setCity(city);
      setKeywords(keywords);
      setDescription(description);
      setEventStartDate(start_date && new Date(start_date));
      setEventEndDate(end_date && new Date(end_date));
      setRoute(route);
      setDescription(description);
      setAdditionalPages(additional_pages);
      setEventGalleries(galleries);
      setFacebook(metadata && metadata.facebook);
      setLinkedIn(metadata && metadata.linkedin);
      setTwitter(metadata && metadata.twitter);
      setInstagram(metadata && metadata.instagram);
      setYoutube(metadata && metadata.youtube);
      setReddit(metadata && metadata.reddit);
      setDocuments(documents);
    }
  }, [eventId, eventById]);

  useEffect(() => {
    if (eventRequestStatus === 200 || eventRequestStatus === 201) {
      history.push("/my-business/events");
    }
  }, [history, eventRequestStatus]);

  useEffect(() => {
    setDisableButton(
      name === "" ||
        description === "" ||
        countryId === "" ||
        city === "" ||
        companyId === "" ||
        eventStartDate === "" ||
        eventEndDate === ""
    );
  }, [
    name,
    description,
    countryId,
    city,
    companyId,
    eventStartDate,
    eventEndDate
  ]);

  useEffect(
    () => () => {
      clearEventRequest();
      loadBlobGallery("");
      loadFileGallery("");
      loadBlobVideo("");
      loadFileVideo("");
    },
    [
      clearEventRequest,
      loadBlobGallery,
      loadFileGallery,
      loadBlobVideo,
      loadFileVideo
    ]
  );

  const apiCall = () => {
    if (eventId === "create") {
      postEvent(
        name,
        companyId,
        countryId,
        city,
        moment(eventStartDate).format("YYYY-MM-DD"),
        moment(eventEndDate).format("YYYY-MM-DD"),
        keywords,
        branches,
        route,
        description,
        facebook,
        linkedIn,
        twitter,
        instagram,
        youtube,
        reddit
      );
    } else if (eventId !== "create") {
      putEvent(
        eventId,
        name,
        companyId && companyId.id,
        countryId && countryId.id,
        city,
        moment(eventStartDate).format("YYYY-MM-DD"),
        moment(eventEndDate).format("YYYY-MM-DD"),
        keywords,
        branches,
        route,
        description,
        facebook,
        linkedIn,
        twitter,
        instagram,
        youtube,
        reddit
      );
    }
  };

  return (
    <div className="my-business-events-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={missingImage}
            alt={"company-picture"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton width="100%" {...buttonOk}>
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  {/* <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  /> */}
                </label>
              </DefaultButton>,
              <DefaultButton
                width="100%"
                {...buttonCancel}
                // onClick={() => {
                //   setFile("");
                //   setBlob("");
                // }}
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {eventId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadImageGallery(eventType, eventId, galleryBlob)
                          }
                          disabled={galleryBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
              <Separator />
              <MediaActions
                image={videoFile ? videoIsUploaded : missingVideo}
                alt={"missing image"}
                height={200}
                buttons={
                  isVideoGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="video/*"
                              type="file"
                              onChange={e => {
                                loadFileVideo(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobVideo(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadVideoGallery(eventType, eventId, videoBlob)
                          }
                          disabled={videoBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobVideo("");
                            loadFileVideo("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setName(e.target.value)}
              value={name}
              label="NAME"
              name="name"
              placeholder="Name"
              required={true}
            />
            <DefaultDropdown
              label="COMPANY"
              items={userCompanies}
              returnData={setCompanyId}
              customTitle={
                eventId !== "create" &&
                eventById.organization &&
                eventById.organization.name
              }
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="COUNTRY"
              items={countries.results}
              returnData={setCountryId}
              inputFunc={e => setCountryInput(e.target.value)}
              filter={true}
              customTitle={
                eventId !== "create" &&
                eventById.country &&
                eventById.country.name
              }
              required={true}
            />
            <InputGroup
              onChange={e => setCity(e.target.value)}
              value={city}
              label="city"
              name="city"
              placeholder="City..."
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDatePicker
              label="Start Event Date"
              returnDate={e => setEventStartDate(e)}
              value={eventStartDate}
              required={true}
            />
            <DefaultDatePicker
              label="End Event Date"
              returnDate={e => setEventEndDate(e)}
              value={eventEndDate}
              required={true}
            />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => setKeywords(e.target.value)}
              value={keywords}
              label="KEYWORDS"
              name="keywords"
              placeholder="#hashtag, #hashtag, ..., #hashtag"
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdownMultiSelect
              customArray={
                eventId !== "create" && eventById && eventById.branches
              }
              returnData={setBranches}
              label="Branches"
              filter={true}
              setCategoryInputValue={e => setBranchInput(e)}
              categoryInputValue={branchInput}
              items={branchesAPI.results}
            />
          </ParentWrapper>
          <ParentWrapper>
            <DefaultTextArea
              value={route}
              onChange={e => setRoute(e.target.value)}
              label="Route Description"
            />
          </ParentWrapper>
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      {eventGalleries &&
        eventGalleries[0] &&
        !!eventGalleries[0].images.length && (
          <>
            <Separator />
            <GallerySection
              label="Gallery"
              gallery={eventGalleries}
              deleteOneImageFromGallery={deleteOneImageFromGallery}
              parentId={eventId}
              contentType={eventType}
            />
          </>
        )}
      {eventGalleries &&
        eventGalleries[0] &&
        !!eventGalleries[0].videos.length && (
          <>
            <Separator />
            <VideoSection
              label="Videos"
              videos={eventGalleries}
              deleteOneVideoFromGallery={deleteOneVideoFromGallery}
              parentId={eventId}
              contentType={eventType}
            />
          </>
        )}
      <div>
        <div style={{ marginTop: 18 }}>
          <EditorGroup
            onChange={e => setDescription(e.target.getContent())}
            value={description}
            label="DESCRIPTION"
            name="event-description"
            required={true}
          />
        </div>
      </div>

      <Separator />
      {eventId !== "create" && (
        <Fragment>
          <DocumentsTable
            tableName={"EVENTS DOCUMENTS"}
            documents={documents}
            deleteDocumentById={deleteDocumentById}
          />
          <Separator />
          <UploadFileDocument
            docName={docName}
            setDocName={setDocName}
            docDescription={docDescription}
            setDocDescription={setDocDescription}
            documentFile={documentFile}
            setDocumentFile={setDocumentFile}
            documentBlob={documentBlob}
            setDocumentBlob={setDocumentBlob}
            uploadDocument={uploadDocument}
            itemContentType={eventType}
            itemId={eventId}
          />
          <AdditionalTable
            tableName={"EVENT ADDITIONAL PAGES"}
            compId={eventId}
            urlType="events"
            additionalPages={additionalPages}
            deleteAdditional={deleteAdditionalPageById}
            companyId={companyId && companyId.id}
          />
          <Separator />
          <AdditionalPagesUpload
            setAdditionalPageTitle={setAdditionalPageTitle}
            additionalPageTitle={additionalPageTitle}
            setAdditionalPageEditor={setAdditionalPageEditor}
            additionalPageEditor={additionalPageEditor}
            uploadAdditionalPage={uploadAdditionalPage}
            itemContentType={eventType}
            itemId={eventId}
          />
          <Separator />
        </Fragment>
      )}
      <ParentWrapper>
        <SocialLinksGroup
          handleFacebookChange={e => setFacebook(e.target.value)}
          facebook={facebook}
          handleLinkedinChange={e => setLinkedIn(e.target.value)}
          linkedin={linkedIn}
          handleTwitterChange={e => setTwitter(e.target.value)}
          twitter={twitter}
          handleInstagramChange={e => setInstagram(e.target.value)}
          instagram={instagram}
          handleYoutubeChange={e => setYoutube(e.target.value)}
          youtube={youtube}
          handleRedditChange={e => setReddit(e.target.value)}
          reddit={reddit}
        />
      </ParentWrapper>
      <Separator />
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            width="100%"
            onClick={apiCall}
            {...buttonOk}
            disabled={disableButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            onClick={() => history.push("/my-business/events")}
            width="100%"
            {...buttonCancel}
            borderRadius={0}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  areUserCompaniesPending: state.companiesReducer.areCompaniesByUserIdPending,
  userCompanies: state.companiesReducer.companiesByUserId,

  areBranchesPending: state.branchesReducer.areBranchesPending,
  branchesAPI: state.branchesReducer.branches,

  areB2BCategoriesPending: state.categoriesAPI.areB2BCategoriesPending,
  b2bCategoriesCount: state.categoriesAPI.b2bCategoriesCount,
  b2bCategoriesNext: state.categoriesAPI.b2bCategoriesNext,
  b2bCategoriesPrevious: state.categoriesAPI.b2bCategoriesPrevious,
  b2bCategoriesResults: state.categoriesAPI.b2bCategoriesResults,
  b2bCategoriesError: state.categoriesAPI.b2bCategoriesError,

  areB2CCategoriesPending: state.categoriesAPI.areB2CCategoriesPending,
  b2cCategoriesCount: state.categoriesAPI.b2cCategoriesCount,
  b2cCategoriesNext: state.categoriesAPI.b2cCategoriesNext,
  b2cCategoriesPrevious: state.categoriesAPI.b2cCategoriesPrevious,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,
  b2cCategoriesError: state.categoriesAPI.b2cCategoriesError,

  isActiveProductPending: state.activeProduct.isActiveProductPending,
  activeProduct: state.activeProduct.activeProduct,

  areCurrenciesPending: state.utilsReducer.areCurrenciesPending,
  currencies: state.utilsReducer.currencies,
  areMeasurementsPending: state.utilsReducer.areMeasurementsPending,
  measurements: state.utilsReducer.measurements,

  isProducersSearchPeding: state.producers.isProducersSearchPeding,
  producersSearchData: state.producers.producersSearchData,
  producersSearchResults: state.producers.producersSearchResults,

  createdB2BProduct: state.b2BProductsReducer.createdProduct,

  areCountriesPending: state.countriesReducer.areCountriesPending,
  countries: state.countriesReducer.countries,

  isEventByIdPending: state.events.isEventByIdPending,
  eventById: state.events.eventById,

  eventPUT: state.events.eventPUT,
  eventRequestStatus: state.events.eventRequestStatus,

  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,
  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,

  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  isVideoGalleryUploadPending:
    state.documentsReducer.isVideoGalleryUploadPending
});

export default connect(mapStateToProps, {
  getCompaniesByUserId,

  searchBranch,

  searchProducers,
  searchCountry,
  uploadAdditionalPage,
  deleteAdditionalPageById,

  postEvent,
  putEvent,
  getEventById,

  uploadImageGallery,
  loadBlobGallery,
  loadFileGallery,
  clearEventRequest,
  deleteOneImageFromGallery,
  loadBlobVideo,
  loadFileVideo,
  deleteOneVideoFromGallery,
  uploadVideoGallery,
  uploadDocument
})(MyBusinessProductsEdit);
