import { CompaniesActionTypes } from "./companies.types";

const INITIAL_STATE = {
  areCompanyTypesPending: false,
  companyTypes: [],
  companyTypesError: null,

  companyType: {},
  isCompaniesPending: false,
  companies: {},

  isCompanyPending: true,
  company: {},
  companyError: {},

  createdCompany: {},
  updatedCompany: {},
  deletedCompany: {},

  areCompaniesByUserIdPending: false,
  companiesByUserId: [],
  companiesByUserIdCount: 0,
  companiesByUserIdError: null,

  isCompanyImagePatchPending: false,
  companyImagePatch: {},
  companyImagePatchError: null,

  areCompaniesByOrganizationIdPending: false,
  companiesByOrganizationIdData: {},
  companiesByOrganizationIdResults: [],
  companiesByOrganizationIdError: null,

  companyRequestStatus: ""
};

const CompaniesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CompaniesActionTypes.GET_COMPANY_TYPE:
      return {
        ...state,
        companyType: action.payload
      };

    case CompaniesActionTypes.GET_COMPANY_TYPES.GET_COMPANY_TYPES_PENDING:
      return {
        ...state,
        areCompanyTypesPending: true
      };
    case CompaniesActionTypes.GET_COMPANY_TYPES.GET_COMPANY_TYPES_SUCCESS:
      return {
        ...state,
        areCompanyTypesPending: false,
        companyTypes: action.payload,
        companyTypesError: null
      };
    case CompaniesActionTypes.GET_COMPANY_TYPES.GET_COMPANY_TYPES_ERROR:
      return {
        ...state,
        areCompanyTypesPending: false,
        companyTypesError: action.payload
      };

    case CompaniesActionTypes.GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
        isCompaniesPending: false
      };

    case CompaniesActionTypes.GET_COMPANIES_PENDING:
      return {
        ...state,
        isCompaniesPending: true
      };
    case CompaniesActionTypes.GET_COMPANY_PENDING:
      return {
        ...state,
        isCompanyPending: true
      };

    case CompaniesActionTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        isCompanyPending: false
      };

    case CompaniesActionTypes.GET_COMPANY_ERROR:
      return {
        ...state,
        companyError: action.payload,
        isCompanyPending: false
      };

    case CompaniesActionTypes.CREATE_COMPANY:
      return {
        ...state,
        createdCompany: action.payload,
        companyRequestStatus: action.payload.requestStatus
      };

    case CompaniesActionTypes.UPDATE_COMPANY:
      return {
        ...state,
        updatedCompany: action.payload,
        companyRequestStatus: action.payload.requestStatus
      };

    case CompaniesActionTypes.DELETE_COMPANY:
      return {
        ...state,
        deletedCompany: action.payload
      };

    case CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_PENDING:
      return { ...state, areCompaniesByUserIdPending: true };

    case CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_SUCCESS:
      return {
        ...state,
        companiesByUserId: action.payload.companies,
        companiesByUserIdCount: action.payload.count,
        areCompaniesByUserIdPending: false
      };

    case CompaniesActionTypes.GET_BY_USER.GET_COMPANIES_BY_USER_ID_ERROR:
      return {
        ...state,
        companiesByUserIdError: action.payload,
        areCompaniesByUserIdPending: false
      };

    case CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_PENDING:
      return { ...state, isCompanyImagePatchPending: true };

    case CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_SUCCESS:
      return {
        ...state,
        isCompanyImagePatchPending: false,
        companyImagePatch: action.payload,
        companyImagePatchError: null
      };

    case CompaniesActionTypes.PATCH.IMAGE.PATCH_COMPANY_IMAGE_ERROR:
      return {
        ...state,
        isCompanyImagePatchPending: false,
        companyImagePatchError: action.payload
      };

    case CompaniesActionTypes.GET.BY_ORGANIZATION
      .GET_COMPANIES_BY_ORGANIZATION_ID_PENDING:
      return { ...state, areCompaniesByOrganizationIdPending: true };

    case CompaniesActionTypes.GET.BY_ORGANIZATION
      .GET_COMPANIES_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        areCompaniesByOrganizationIdPending: false,
        companiesByOrganizationIdData: action.payload.data,
        companiesByOrganizationIdResults: action.payload.results,
        companiesByOrganizationIdError: null
      };

    case CompaniesActionTypes.GET.BY_ORGANIZATION
      .GET_COMPANIES_BY_ORGANIZATION_ID_ERROR:
      return {
        ...state,
        areCompaniesByOrganizationIdPending: false,
        companiesByOrganizationIdError: action.payload
      };

    case CompaniesActionTypes.CLEAR_COMPANY_REQUEST:
      return { ...state, companyRequestStatus: action.payload };

    default:
      return state;
  }
};

export default CompaniesReducer;
