import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { withDebounce, arrToString } from "../../shared";
import { Filter, JobResume, JobFindJob } from "../../containers";
import { JobFilter } from "../../components";

import { ResumeDetails, JobDetails, SendResume } from "../../pages";

import {
  searchJobs,
  searchResumes,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
} from "../../redux/actions-exporter";

import "./job.styles.scss";

const Job = ({
  searchJobs,
  searchResumes,
  areResumesPending,
  resumes,
  areJobsPending,
  jobs,
  jobsCount,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  filterCountriesArr,
  filterOrganizationsArr
}) => {
  const [currentTab, setCurrentTab] = useState("resume");
  const [searchResumesQuery, setSearchResumesQuery] = useState("");
  const [resumesPage, setResumesPage] = useState(1);
  const [searchJobsQuery, setSearchJobsQuery] = useState("");
  const [jobsPage, setJobsPage] = useState(1);

  let { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/job/find-jobs") {
      setCountriesFilterStatus(true);
      setOrganizationsFilterStatus(true);
    } else {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
    }
    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
    };
  }, [pathname, setCountriesFilterStatus, setOrganizationsFilterStatus]);

  useEffect(() => {
    pathname === "/job/resumes"
      ? searchResumes(searchResumesQuery, resumesPage)
      : searchJobs(
          searchJobsQuery,
          jobsPage,
          arrToString(filterCountriesArr),
          arrToString(filterOrganizationsArr)
        );
    // eslint-disable-next-line
  }, [
    pathname,
    currentTab,
    searchResumesQuery,
    resumesPage,
    searchJobsQuery,
    jobsPage,
    filterCountriesArr,
    filterOrganizationsArr
  ]);

  useEffect(() => {
    setResumesPage(1);
    setJobsPage(1);
  }, [
    pathname,
    searchResumesQuery,
    searchJobsQuery,
    filterCountriesArr,
    filterOrganizationsArr
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [resumesPage, jobsPage]);

  const jobFilterOnChange = withDebounce(e =>
    pathname === "/job/resumes"
      ? setSearchResumesQuery(e)
      : setSearchJobsQuery(e)
  );
  return (
    <div className="job">
      <Filter onChange={e => jobFilterOnChange(e.target.value)} />

      <div className="job__filter">
        <JobFilter activeTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>
      <Switch>
        <Route
          exact
          path="/job"
          render={() => <Redirect to="/job/resumes" />}
        />
        <Route exact path="/job/resumes">
          <JobResume
            areResumesPending={areResumesPending}
            resumes={resumes}
            resumesPage={resumesPage}
            setResumesPage={setResumesPage}
          />
        </Route>
        <Route exact path="/job/find-jobs">
          <JobFindJob
            areJobsPending={areJobsPending}
            jobsCount={jobsCount}
            jobs={jobs}
            jobsPage={jobsPage}
            setJobsPage={setJobsPage}
          />
        </Route>
        <Route exact path="/job/resumes/:resumeId" component={ResumeDetails} />
        <Route exact path="/job/find-jobs/:jobId" component={JobDetails} />
        <Route
          exact
          path="/job/find-jobs/:jobId/send-resume"
          component={SendResume}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = state => ({
  areResumesPending: state.resumesAPI.areResumesPending,
  resumes: state.resumesAPI.resumes,
  areJobsPending: state.jobsAPI.areJobsPending,
  jobs: state.jobsAPI.jobs,
  jobsCount: state.jobsAPI.jobsCount,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr
});

export default connect(mapStateToProps, {
  searchJobs,
  searchResumes,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
})(Job);
