import React from "react";
import { connect } from "react-redux";

import {
  EnvelopeIcon,
  LocationIcon,
  PhoneIcon,
  WWWIcon,
  GreyChatIcon,
  GreyMailIcon
} from "../../assets";
import { DefaultButton } from "../../components";

import "./comp-org-contacts-contact.styles.scss";

const CompOrgContactsContact = ({
  companySelected,
  company,
  isCompanyPending,
  organization
}) => {
  return companySelected ? (
    !isCompanyPending && (
      <div className="comp-org-contacts-contact">
        <h3>Contacts</h3>
        <div className="comp-org-contacts-contact__upper">
          <div className="comp-org-contacts-contact__upper__data">
            <span className="comp-org-contacts-contact__icon">
              <LocationIcon />
            </span>
            <div>
              <h5>Address:</h5>
              <p>{company.address}</p>
            </div>
          </div>
          <div className="comp-org-contacts-contact__upper__data">
            <span className="comp-org-contacts-contact__icon">
              <EnvelopeIcon />
            </span>
            <div>
              <h5>Email:</h5>
              <p>{company.metadata.email}</p>
            </div>
          </div>
          <div
            className="comp-org-contacts-contact__upper__btn"
            id="widget-live"
            style={{ position: "relative", cursor: "pointer" }}
          >
            <div style={{ pointerEvents: "none" }}>
              <DefaultButton
                displayLabel="flex"
                justifyContentLabel="space-between"
                alignItemsLabel="center"
                labelWidth="90px"
                padding="10px 20px"
                border="1px solid lightgrey"
              >
                <GreyChatIcon />
                Live Chat
              </DefaultButton>
            </div>
          </div>
        </div>
        <div className="comp-org-contacts-contact__lower">
          <div className="comp-org-contacts-contact__upper">
            <div className="comp-org-contacts-contact__upper__data">
              <span className="comp-org-contacts-contact__icon">
                <PhoneIcon />
              </span>
              <div>
                <h5>Telephone number:</h5>
                <p>{company.metadata.phone}</p>
              </div>
            </div>
            {company.metadata.site && (
              <div className="comp-org-contacts-contact__upper__data">
                <span className="comp-org-contacts-contact__icon">
                  <WWWIcon />
                </span>

                <div>
                  <h5>Website:</h5>
                  <p>{company.metadata.site}</p>
                </div>
              </div>
            )}

            <div className="comp-org-contacts-contact__upper__btn">
              <DefaultButton
                displayLabel="flex"
                justifyContentLabel="space-between"
                alignItemsLabel="center"
                labelWidth="90px"
                padding="10px 20px"
                border="1px solid lightgrey"
              >
                <GreyMailIcon />
                Contact us
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <div className="comp-org-contacts-contact">
      <h3>Contacts</h3>
      <div className="comp-org-contacts-contact__upper">
        <div className="comp-org-contacts-contact__upper__data">
          <span className="comp-org-contacts-contact__icon">
            <LocationIcon />
          </span>
          <div>
            <h5>Address:</h5>
            <p>{organization.address}</p>
          </div>
        </div>
        <div className="comp-org-contacts-contact__upper__data">
          <span className="comp-org-contacts-contact__icon">
            <EnvelopeIcon />
          </span>
          <div>
            <h5>Email:</h5>
            <p>{organization.metadata.email}</p>
          </div>
        </div>
        <div
          className="comp-org-contacts-contact__upper__btn"
          id="widget-live"
          style={{ position: "relative", cursor: "pointer" }}
        >
          <div style={{ pointerEvents: "none" }}>
            <DefaultButton
              displayLabel="flex"
              justifyContentLabel="space-between"
              alignItemsLabel="center"
              labelWidth="90px"
              padding="10px 20px"
              border="1px solid lightgrey"
            >
              <GreyChatIcon />
              Live Chat
            </DefaultButton>
          </div>
        </div>
      </div>
      <div className="comp-org-contacts-contact__lower">
        <div className="comp-org-contacts-contact__upper">
          <div className="comp-org-contacts-contact__upper__data">
            <span className="comp-org-contacts-contact__icon">
              <PhoneIcon />
            </span>
            <div>
              <h5>Telephone number:</h5>
              <p>{organization.metadata.phone}</p>
            </div>
          </div>
          {organization.metadata.site && (
            <div className="comp-org-contacts-contact__upper__data">
              <span className="comp-org-contacts-contact__icon">
                <WWWIcon />
              </span>

              <div>
                <h5>Website:</h5>
                <p>{organization.metadata.site}</p>
              </div>
            </div>
          )}

          <div className="comp-org-contacts-contact__upper__btn">
            <DefaultButton
              displayLabel="flex"
              justifyContentLabel="space-between"
              alignItemsLabel="center"
              labelWidth="90px"
              padding="10px 20px"
              border="1px solid lightgrey"
            >
              <GreyMailIcon />
              Contact us
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  company: state.companiesReducer.company,
  isCompanyPending: state.companiesReducer.isCompanyPending,
  organization: state.organizationsReducer.organization,
  isOrganizationPending: state.organizationsReducer.isOrganizationPending
});

export default connect(mapStateToProps, null)(CompOrgContactsContact);
