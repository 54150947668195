import { BranchesActionTypes } from "./branches.types";

const INTIAL_STATE = {
  areBranchesPending: true,
  branches: [],
  branchesError: null,

  createdBranch: [],
  updatedBranch: [],
  deletedBranch: []
};

const BranchesReducer = (state = INTIAL_STATE, action = {}) => {
  switch (action.type) {
    case BranchesActionTypes.GET.GET_BRANCHES_PENDING:
      return {
        ...state,
        areBranchesPending: true
      };

    case BranchesActionTypes.GET.GET_BRANCHES_SUCCESS:
      return {
        ...state,
        areBranchesPending: false,
        branches: action.payload
      };

    case BranchesActionTypes.GET.GET_BRANCHES_ERROR:
      return {
        ...state,
        areBranchesPending: false,
        branchesError: action.payload
      };

    case BranchesActionTypes.CREATE_BRANCH:
      return {
        ...state,
        createdBranch: action.payload
      };

    case BranchesActionTypes.UPDATE_BRANCH:
      return {
        ...state,
        updatedBranch: action.payload
      };

    case BranchesActionTypes.DELETE_BRANCH:
      return {
        ...state,
        deletedBranch: action.payload
      };

    default:
      return state;
  }
};

export default BranchesReducer;
