import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ClearIcon from "@material-ui/icons/Clear";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import {
  DefaultSnackbar,
  LoadingSpinner,
  DefaultButton
} from "../../components";

import {
  addToCart,
  getWishList,
  deleteProductFromWishList
} from "../../redux/actions-exporter";

import { colorPalette } from "../../styles/colorPalette";
import "./wish-list.styles.scss";

const WishList = ({
  addToCart,
  wishList,
  getWishList,
  isWishListPending,
  deleteProductFromWishList,
  cart
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const customMessages = [
    { id: 1, message: "Deleted item from wishlist" },
    { id: 2, message: "Succesfuly added to cart" }
  ];

  useEffect(() => {
    getWishList();
  }, [getWishList]);

  const addToCartHandler = (id, contentType) => {
    let b2bProductId = "";
    let b2cProductId = "";
    if (contentType === 84) {
      b2bProductId = id;
    } else {
      b2cProductId = id;
    }

    addToCart("person", "", b2bProductId, b2cProductId, "1");
    setSnackMessage(customMessages[1].message);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  const deleteProductHandler = id => {
    deleteProductFromWishList(id);
    setSnackMessage(customMessages[0].message);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  const productPath = (productId, productContentType) => {
    if (productContentType === 84) {
      return `/product/b2b/${productId}/`;
    } else {
      return `/product/b2c/${productId}/`;
    }
  };

  return (
    <div className="wish-list-container">
      {showMessage && (
        <DefaultSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="success"
          message={snackMessage}
          autoHideDuration={2000}
        />
      )}
      <h4>WishList:</h4>
      <div className="wish-list-inner">
        {isWishListPending ? (
          <LoadingSpinner />
        ) : !wishList.length ? (
          <h3>No products in wish list...</h3>
        ) : (
          <div className="wish-list-inner__table">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <input type="checkbox" />
                  </TableCell>
                  <TableCell align="center">PRODUCT</TableCell>
                  <TableCell align="center">NAME</TableCell>
                  <TableCell align="center">COST</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wishList.map(wishItem => {
                  const { id, wish_list_item } = wishItem;
                  const productExist = cart.find(
                    i => i.product_id === wish_list_item[0].item_id
                  );
                  return (
                    <TableRow key={id}>
                      <TableCell align="center">
                        <input className="checkbox-wish" type="checkbox" />
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={() =>
                            productPath(
                              wish_list_item[0].item_id,
                              wish_list_item[0].content_type
                            )
                          }
                        >
                          <img
                            className="wish-product-img"
                            src={wish_list_item[0].image}
                            alt=""
                          />
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={() =>
                            productPath(
                              wish_list_item[0].item_id,
                              wish_list_item[0].content_type
                            )
                          }
                        >
                          {wish_list_item[0].name.length > 10
                            ? wish_list_item[0].name.substring(0, 20) + "..."
                            : wish_list_item[0].name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        {wish_list_item[0].currency} {wish_list_item[0].cost}
                      </TableCell>
                      <TableCell align="left">
                        {productExist ? (
                          <DefaultButton
                            border={`1px solid ${colorPalette.colorPrimary.main}`}
                            color={colorPalette.colorPrimary.main}
                            fontWeight="bold"
                            padding="7px 10px"
                            disabled
                          >
                            Added to cart
                          </DefaultButton>
                        ) : (
                          <DefaultButton
                            onClick={() =>
                              addToCartHandler(
                                wish_list_item[0].item_id,
                                wish_list_item[0].content_type
                              )
                            }
                            border={`1px solid ${colorPalette.colorPrimary.main}`}
                            color={colorPalette.colorPrimary.main}
                            fontWeight="bold"
                            padding="7px 20px"
                          >
                            Add to cart
                          </DefaultButton>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <DefaultButton onClick={() => deleteProductHandler(id)}>
                          <ClearIcon style={{ color: "#808080" }} />
                        </DefaultButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart.cart,
  isWishListPending: state.wishListReducer.isWishListPending,
  wishList: state.wishListReducer.wishList
});

export default connect(mapStateToProps, {
  addToCart,
  getWishList,
  deleteProductFromWishList
})(WishList);
