export const ProfileActionTypes = {
  EMAIL_INPUT: "EMAIL_INPUT",
  LAST_NAME_INPUT: "LAST_NAME_INPUT",
  FIRST_NAME_INPUT: "FIRST_NAME_INPUT",
  AVATAR_INPUT: "AVATAR_INPUT",
  BIRTHDAY_INPUT: "BIRTHDAY_INPUT",
  PASSWORD_INPUT: "PASSWORD_INPUT",
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  COUNTRY_INPUT: "COUNTRY_INPUT",
  WEBSITE_INPUT: "WEBSITE_INPUT",
  PROFESSION_INPUT: "PROFESSION_INPUT",
  PROFILE_TYPE_INPUT: "PROFILE_TYPE_INPUT",
  SEX_INPUT: "SEX_INPUT",
  COMPANY_NAME_INPUT: "COMPANY_NAME_INPUT",
  COMPANY_SLOGAN_INPUT: "COMPANY_SLOGAN_INPUT",
  COMPANY_PHONE_INPUT: "COMPANY_PHONE_INPUT",
  COMPANY_FAX_INPUT: "COMPANY_FAX_INPUT",
  ADDRESS_INPUT: "ADDRESS_INPUT",
  COMPANY_DESCRIPTION_INPUT: "COMPANY_DESCRIPTION_INPUT",
  SOCIAL_LINKS: {
    FACEBOOK_INPUT: "FACEBOOK_INPUT",
    LINKEDIN_INPUT: "LINKEDIN_INPUT",
    TWITTER_INPUT: "TWITTER_INPUT",
    INSTAGRAM_INPUT: "INSTAGRAM_INPUT",
    YOUTUBE_INPUT: "YOUTUBE_INPUT",
    REDDIT_INPUT: "REDDIT_INPUT"
  },
  BUSINESS_CARD_TYPE: "BUSINESS_CARD_TYPE",
  PROFILE_ACTIVE_COMPANY: "PROFILE_ACTIVE_COMPANY"
};
