import React, { Fragment, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import {
  Language as LanguageIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Edit as EditIcon,
  Clear as ClearIcon
} from "@material-ui/icons";

import { missingImage } from "../../assets";

import {
  DefaultIconButton,
  DefaultButton,
  LoadingSpinner
} from "../../components";

import "./leads-company-data.styles.scss";

const LeadsCompanyData = ({
  currentTypeCompany,
  isPendingCompany,
  areLeadsPending,
  leadsData,
  deleteLeadById,
  companyLeadsId,
  getLeadsByOrganization
}) => {
  const { url, params } = useRouteMatch();

  useEffect(() => {
    getLeadsByOrganization(companyLeadsId);
  }, [getLeadsByOrganization, companyLeadsId]);

  return (
    <Fragment>
      {isPendingCompany && areLeadsPending ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="company-department-info__companies">
            <div className="companies__inner-content">
              <div className="companies__inner-content__company">
                <div className="companies__inner-content__company__image">
                  <Link
                    to={
                      params.typeOfComp === "c"
                        ? `/companies/${companyLeadsId}`
                        : `/organizations/${companyLeadsId}`
                    }
                  >
                    <img
                      className="company-img"
                      src={
                        currentTypeCompany && currentTypeCompany.logo
                          ? currentTypeCompany.logo
                          : missingImage
                      }
                      alt=""
                    />
                  </Link>
                </div>
                <div className="companies__inner-content__company__name">
                  <Link
                    to={
                      params.typeOfComp === "c"
                        ? `/companies/${companyLeadsId}`
                        : `/organizations/${companyLeadsId}`
                    }
                  >
                    {currentTypeCompany && currentTypeCompany.name}:{" "}
                  </Link>
                  <span style={{ fontWeight: "300", padding: "0 15px" }}>
                    Leads
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="leads-table">
            {leadsData.length ? (
              <Table aria-label="items table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">SRC</TableCell>
                    <TableCell align="left">MSG</TableCell>
                    <TableCell align="left">USER</TableCell>
                    <TableCell align="left">USERNAME</TableCell>
                    <TableCell align="left">EMAIL</TableCell>
                    <TableCell align="left">PHONE</TableCell>
                    <TableCell align="left">DATE</TableCell>
                    <TableCell align="left">EDIT</TableCell>
                    <TableCell align="left">REMOVE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadsData &&
                    leadsData.map(
                      (
                        {
                          id,
                          realname,
                          username,
                          email,
                          date,
                          url_path,
                          message
                        },
                        index
                      ) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            <div title={url_path}>
                              <DefaultIconButton>
                                <LanguageIcon />
                              </DefaultIconButton>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div title={message}>
                              <DefaultIconButton>
                                <EmailIcon />
                              </DefaultIconButton>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div>
                              <DefaultIconButton>
                                <PersonIcon />
                              </DefaultIconButton>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="leads-table__username">
                              {username === null ? realname : username}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="leads-table__email">{email}</div>
                          </TableCell>
                          <TableCell align="left">
                            <div>
                              <DefaultIconButton>
                                <PhoneIcon />
                              </DefaultIconButton>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="leads-table__date">{"No date"}</div>
                          </TableCell>
                          <TableCell align="left">
                            <Link to={`${url}/lead/${id}`}>
                              <DefaultIconButton>
                                <EditIcon />
                              </DefaultIconButton>
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            <DefaultButton onClick={() => deleteLeadById(id)}>
                              <ClearIcon />
                            </DefaultButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            ) : (
              <h3 style={{ textAlign: "center" }}>No leads</h3>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LeadsCompanyData;
