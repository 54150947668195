import React from "react";

const LastOrders = () => {
  return (
    <div className="last-orders-container">
      <h4>Last orders:</h4>
    </div>
  );
};
export default LastOrders;
