import React from "react";
import { connect } from "react-redux";

import { missingImage } from "../../assets";
import { ProductWithCategory, LoadingSpinner } from "../../components";

import { Grid } from "@material-ui/core";

import "./categories-product-grid.styles.scss";

const CategoriesProductGrid = ({ loading, products, props = {} }) => {
  const palette = [
    "#ff7777",
    "#7dd577",
    "#7797ff",
    "#a177ff",
    "#43d4b0",
    "#9a9167",
    "#453daf",
    "#8a698f",
    "#db8850",
    "#6e9961",
  ];

  const randomItemFromArray = (array) =>
    array[Math.floor(Math.random() * array.length)];

  return (
    <div className="categories-product-grid" {...props}>
      <div className="categories-product-grid__main">
        <Grid container spacing={3}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            products &&
            products
              .map((product) => ({
                ...product,
                category: product.categories.length
                  ? product.categories.find((i) => !i.parent) ||
                    product.categories[0]
                  : { name: "Unknown", color: "" },
              }))
              .reduce(
                (tmp, product) => [
                  ...tmp,
                  {
                    ...product,
                    category: product.category.color
                      ? product.category
                      : {
                          ...product.category,
                          color: randomItemFromArray(
                            palette.filter(
                              (color) =>
                                !tmp
                                  .map((product) => product.category.color)
                                  .includes(color)
                            )
                          ),
                        },
                  },
                ],
                []
              )
              .map((product) => (
                <Grid item xs={6} md={3} xl={2}>
                  <ProductWithCategory
                    key={product.id}
                    productId={product.id}
                    navProductType="b2c"
                    imgUrl={product.image ? product.image : missingImage}
                    name={product.name}
                    price={product.cost}
                    currency={product.currency}
                    discountPercent={product.discount_percent}
                    couponDiscountPercent={product.coupon_discount_percent}
                    metadata={product.metadata}
                    category={product.category}
                    currentProductData={product}
                  />
                </Grid>
              ))
              .filter((p, i) => {
                return i < 8;
              })
          )}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filterCountriesArr: state.filterReducer.filterCountriesArr,
});

export default connect(mapStateToProps, {})(CategoriesProductGrid);
