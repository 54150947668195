export const SalesAndCouponsActionTypes = {
  GET: {
    GET_PRODUCTS_WITH_COUPON_DISCOUNT_PENDING:
      "GET_PRODUCTS_WITH_COUPON_DISCOUNT_PENDING",
    GET_PRODUCTS_WITH_COUPON_DISCOUNT_SUCCESS:
      "GET_PRODUCTS_WITH_COUPON_DISCOUNT_SUCCESS",
    GET_PRODUCTS_WITH_COUPON_DISCOUNT_ERROR:
      "GET_PRODUCTS_WITH_COUPON_DISCOUNT_ERROR",

    GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_PENDING:
      "GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_PENDING",
    GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_SUCCESS:
      "GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_SUCCESS",
    GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_ERROR:
      "GET_PRODUCTS_WITH_SIMPLE_DISCOUNT_ERROR"
  }
};
