export const CategoriesActionTypes = {
  GET: {
    B2B: {
      GET_B2B_CATEGORIES_PENDING: "GET_B2B_CATEGORIES_PENDING",
      GET_B2B_CATEGORIES_SUCCESS: "GET_B2B_CATEGORIES_SUCCESS",
      GET_B2B_CATEGORIES_ERROR: "GET_B2B_CATEGORIES_ERROR",

      GET_B2B_CATEGORIES_EDITOR_PENDING: "GET_B2B_CATEGORIES_EDITOR_PENDING",
      GET_B2B_CATEGORIES_EDITOR_SUCCESS: "GET_B2B_CATEGORIES_EDITOR_SUCCESS",
      GET_B2B_CATEGORIES_EDITOR_ERROR: "GET_B2B_CATEGORIES_EDITOR_ERROR",

      SEARCH_B2B_CATEGORIES_BY_COMPANY_PENDING:
        "SEARCH_B2B_CATEGORIES_BY_COMPANY_PENDING",
      SEARCH_B2B_CATEGORIES_BY_COMPANY_SUCCESS:
        "SEARCH_B2B_CATEGORIES_BY_COMPANY_SUCCESS",
      SEARCH_B2B_CATEGORIES_BY_COMPANY_ERROR:
        "SEARCH_B2B_CATEGORIES_BY_COMPANY_ERROR"
    },
    B2C: {
      GET_B2C_CATEGORIES_PENDING: "GET_B2C_CATEGORIES_PENDING",
      GET_B2C_CATEGORIES_SUCCESS: "GET_B2C_CATEGORIES_SUCCESS",
      GET_B2C_CATEGORIES_ERROR: "GET_B2C_CATEGORIES_ERROR",

      GET_B2C_CATEGORIES_EDITOR_PENDING: "GET_B2C_CATEGORIES_EDITOR_PENDING",
      GET_B2C_CATEGORIES_EDITOR_SUCCESS: "GET_B2C_CATEGORIES_EDITOR_SUCCESS",
      GET_B2C_CATEGORIES_EDITOR_ERROR: "GET_B2C_CATEGORIES_EDITOR_ERROR",

      SEARCH_B2C_CATEGORIES_BY_COMPANY_PENDING:
        "SEARCH_B2C_CATEGORIES_BY_COMPANY_PENDING",
      SEARCH_B2C_CATEGORIES_BY_COMPANY_SUCCESS:
        "SEARCH_B2C_CATEGORIES_BY_COMPANY_SUCCESS",
      SEARCH_B2C_CATEGORIES_BY_COMPANY_ERROR:
        "SEARCH_B2C_CATEGORIES_BY_COMPANY_ERROR"
    },
    GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
    GET_CATEGORY_ERROR: "GET_CATEGORY_ERROR"
  },
  CREATE: {
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
    CREATE_CATEGORY_ERROR: "CREATE_CATEGORY_ERROR"
  },
  UPDATE: {
    UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_ERROR: "UPDATE_CATEGORY_ERROR"
  },
  DELETE: {
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR"
  },
  PATCH: {
    ICON: {
      PATCH_CATEGORY_ICON_PENDING: "PATCH_CATEGORY_ICON_PENDING",
      PATCH_CATEGORY_ICON_SUCCESS: "PATCH_CATEGORY_ICON_SUCCESS",
      PATCH_CATEGORY_ICON_ERROR: "PATCH_CATEGORY_ICON_ERROR"
    }
  },
  CLEAR_CATEGORY_REQUEST: "CLEAR_CATEGORY_REQUEST"
};
