import React from "react";

import { ReactComponent as PDFIcon } from "../../assets/icons/Icon_PDF.svg";

import "./company-document.styles.scss";

const CompanyDocument = ({ img, name, file }) => {
  return (
    <a
      href={file}
      alt="Document"
      target="_blank"
      rel="noopener noreferrer"
      className="company-document"
    >
      <div className="company-document__gradient" />
      <div className="company-document__icon-box">
        <PDFIcon />
      </div>
      <div className="company-document__name">{name}</div>
    </a>
  );
};

export default CompanyDocument;
