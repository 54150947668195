import React, { useState, useReducer, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";

import { Clear as ClearIcon } from "@material-ui/icons";
import { PlusIcon, QuestionIcon } from "../../../assets";

import {
  DefaultDatePicker,
  DefaultButton,
  Separator,
  InputGroup,
  DefaultDropdown,
  UploadFileDocument,
  DocumentsTable,
  DefaultTextArea
} from "../../../components";

import {
  getCountries,
  getMaritalStatus,
  createResume,
  updateResume,
  getResumeById,
  clearResumeRequest,
  uploadDocument,
  deleteDocumentById,
  getStudyFormTypes
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-resume-edit.component.scss";

const MyBusinessResumeEdit = ({
  getMaritalStatus,
  maritalStatusData,
  currentUserId,
  createResume,
  updateResume,
  getResumeById,
  currentResume,
  resumeRequestStatus,
  clearResumeRequest,
  uploadDocument,
  deleteDocumentById,
  getStudyFormTypes,
  studyFormTypes
}) => {
  const { resumeId } = useParams();
  const history = useHistory();
  const [resumeType] = useState(120);

  const [resumeData, setResumeData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      resumeTitle: "",
      lastName: "",
      firstName: "",
      nationality: "",
      address: "",
      phoneNumber: "",
      studyForm: "",
      faculty: "",
      profession: "",
      additionally: "",
      company1: "",
      position1: "",
      company2: "",
      position2: "",
      company3: "",
      position4: "",
      languageSkill: "",
      languageSkillRate: 0,
      computerSkill: "",
      computerSkillRate: 0,
      salary: "",
      documents: null
    }
  );

  const {
    resumeTitle,
    lastName,
    firstName,
    address,
    nationality,
    phoneNumber,
    studyForm,
    faculty,
    profession,
    additionally,
    company1,
    position1,
    company2,
    position2,
    company3,
    position3,
    languageSkill,
    languageSkillRate,
    computerSkill,
    computerSkillRate,
    salary,
    documents
  } = resumeData;

  const [maritalStatus, setMaritalStatus] = useState("");

  const [startStuding, setStartStuding] = useState({
    start: null,
    end: null
  });

  const [startWorking1, setStartWorking1] = useState({
    start: null,
    end: null
  });
  const [startWorking2, setStartWorking2] = useState({
    start: null,
    end: null
  });
  const [startWorking3, setStartWorking3] = useState({
    start: null,
    end: null
  });

  const [newEducation, setNewEducation] = useState([]);
  const [newExperience, setNewExperience] = useState([]);
  const [newLanguageSkill, setNewLanguageSkill] = useState([]);
  const [newComputerSkill, setNewComputerSkill] = useState([]);
  const [docName, setDocName] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [documentBlob, setDocumentBlob] = useState(null);

  const [disabledButton, setDisabledButton] = useState(false);

  const [experience2, setExperience2] = useState(false);
  const [experience3, setExperience3] = useState(false);

  useEffect(() => {
    getStudyFormTypes();
  }, [getStudyFormTypes]);

  useEffect(() => {
    getMaritalStatus();
    if (resumeId !== "create") {
      getResumeById(resumeId);
    }
  }, [getMaritalStatus, resumeId, getResumeById]);

  useEffect(() => {
    if (resumeId !== "create") {
      const {
        title,
        last_name,
        first_name,
        telephone_number,
        nationality,
        marital_status,
        address,
        study_form,
        faculty,
        profession,
        study_start_date,
        study_end_date,
        new_education,
        company_exp_1,
        position_exp_1,
        start_date_exp_1,
        end_date_exp_1,
        company_exp_2,
        position_exp_2,
        start_date_exp_2,
        end_date_exp_2,
        company_exp_3,
        position_exp_3,
        start_date_exp_3,
        end_date_exp_3,
        new_experience,
        language_skill,
        computer_skill,
        salary,
        new_computer_skill,
        new_language_skill,
        additional_information,
        documents
      } = currentResume;
      setResumeData({
        ...resumeData,
        resumeTitle: title,
        lastName: last_name,
        firstName: first_name,
        phoneNumber: telephone_number,
        address,
        nationality,
        studyForm: study_form && study_form.value,
        faculty,
        profession,
        company1: company_exp_1,
        position1: position_exp_1,
        company2: company_exp_2,
        position2: position_exp_2,
        company3: company_exp_3,
        position3: position_exp_3,
        languageSkill: language_skill,
        computerSkill: computer_skill,
        salary,
        documents,
        additionally: additional_information
      });
      setStartStuding({
        ...startStuding,
        start: study_start_date && new Date(study_start_date),
        end: study_end_date && new Date(study_end_date)
      });

      setStartWorking1({
        ...startWorking1,
        start: start_date_exp_1 && new Date(start_date_exp_1),
        end: end_date_exp_1 && new Date(end_date_exp_1)
      });
      setStartWorking2({
        ...startWorking2,
        start: start_date_exp_2 && new Date(start_date_exp_2),
        end: end_date_exp_2 && new Date(end_date_exp_2)
      });
      setStartWorking3({
        ...startWorking3,
        start: start_date_exp_3 && new Date(start_date_exp_3),
        end: end_date_exp_3 && new Date(end_date_exp_3)
      });

      setNewEducation(new_education);
      setNewExperience(new_experience);
      setNewLanguageSkill(new_language_skill);
      setNewComputerSkill(new_computer_skill);
      setMaritalStatus(marital_status && marital_status.value);

      setExperience2(true);
      setExperience3(true);
    }
  }, [resumeId, currentResume]); // eslint-disable-line

  useEffect(() => {
    setDisabledButton(
      resumeTitle === "" ||
        lastName === "" ||
        firstName === "" ||
        phoneNumber === ""
    );
  }, [resumeTitle, lastName, firstName, phoneNumber]);

  useEffect(() => {
    if (resumeRequestStatus === 200 || resumeRequestStatus === 201) {
      history.push("/my-business/resume");
    }
  }, [resumeRequestStatus]); // eslint-disable-line

  useEffect(() => () => clearResumeRequest(), [clearResumeRequest]);

  const handleResumeInputs = (name, value) => setResumeData({ [name]: value });

  const editResume = () => {
    if (resumeId === "create") {
      createResume(
        resumeTitle,
        lastName,
        firstName,
        address,
        phoneNumber,
        nationality,
        maritalStatus,
        studyForm,
        faculty,
        profession,
        startStuding.start && moment(startStuding.start).format("YYYY-MM-DD"),
        startStuding.end && moment(startStuding.end).format("YYYY-MM-DD"),
        company1,
        position1,
        startWorking1.start && moment(startWorking1.start).format("YYYY-MM-DD"),
        startWorking1.end && moment(startWorking1.end).format("YYYY-MM-DD"),
        company2,
        position2,
        startWorking2.start && moment(startWorking2.start).format("YYYY-MM-DD"),
        startWorking2.end && moment(startWorking2.end).format("YYYY-MM-DD"),
        company3,
        position3,
        startWorking3.start && moment(startWorking3.start).format("YYYY-MM-DD"),
        startWorking3.end && moment(startWorking3.end).format("YYYY-MM-DD"),
        languageSkill,
        languageSkillRate,
        computerSkill,
        computerSkillRate,
        salary,
        additionally,
        currentUserId && currentUserId.user,
        newEducation.map(el => {
          let temp = el;
          temp.study_form = el.study_form.value;
          return temp;
        }),
        newExperience,
        newLanguageSkill,
        newComputerSkill
      );
    } else {
      updateResume(
        resumeId,
        resumeTitle,
        lastName,
        firstName,
        address,
        phoneNumber,
        nationality,
        maritalStatus,
        studyForm,
        faculty,
        profession,
        startStuding.start && moment(startStuding.start).format("YYYY-MM-DD"),
        startStuding.end && moment(startStuding.end).format("YYYY-MM-DD"),
        company1,
        position1,
        startWorking1.start && moment(startWorking1.start).format("YYYY-MM-DD"),
        startWorking1.end && moment(startWorking1.end).format("YYYY-MM-DD"),
        company2,
        position2,
        startWorking2.start && moment(startWorking2.start).format("YYYY-MM-DD"),
        startWorking2.end && moment(startWorking2.end).format("YYYY-MM-DD"),
        company3,
        position3,
        startWorking3.start && moment(startWorking3.start).format("YYYY-MM-DD"),
        startWorking3.end && moment(startWorking3.end).format("YYYY-MM-DD"),
        languageSkill,
        languageSkillRate,
        computerSkill,
        computerSkillRate,
        salary,
        additionally,
        currentUserId && currentUserId.user,
        newEducation.map(el => {
          let temp = el;
          temp.study_form = el.study_form.value;
          return temp;
        }),
        newExperience,
        newLanguageSkill,
        newComputerSkill
      );
    }
  };

  const addNewFieldHandler = () => {
    if (!experience2) {
      setExperience2(true);
    } else if (!experience3) {
      setExperience3(true);
    } else {
      setNewExperience([
        ...newExperience,
        {
          company: "",
          position: "",
          date_start: "",
          date_end: ""
        }
      ]);
    }
  };

  const addNewEducationField = () => {
    setNewEducation([
      ...newEducation,
      {
        faculty: "",
        profession: "",
        study_start_date: "",
        study_end_date: "",
        study_form: { value: "", label: "" }
      }
    ]);
  };

  const addNewLanguageField = () => {
    setNewLanguageSkill([
      ...newLanguageSkill,
      {
        name: "",
        rate: 0
      }
    ]);
  };

  const addNewComputerField = () => {
    setNewComputerSkill([
      ...newComputerSkill,
      {
        name: "",
        rate: 0
      }
    ]);
  };

  const newEducationHandler = (index, name, value) => {
    const arrEduc = [...newEducation];
    switch (name) {
      case "faculty":
        arrEduc[index].faculty = value;
        return setNewEducation(arrEduc);
      case "profession":
        arrEduc[index].profession = value;
        return setNewEducation(arrEduc);
      case "study_start_date":
        arrEduc[index].study_start_date = moment(value).format("YYYY-MM-DD");
        return setNewEducation(arrEduc);
      case "study_end_date":
        arrEduc[index].study_end_date = moment(value).format("YYYY-MM-DD");
        return setNewEducation(arrEduc);
      case "study_form":
        arrEduc[index].study_form = value;
        return setNewEducation(arrEduc);
      default:
        return setNewEducation(arrEduc);
    }
  };

  const newExperienceHandler = (index, name, value) => {
    const arrExp = [...newExperience];
    switch (name) {
      case "company":
        arrExp[index].company = value;
        return setNewExperience(arrExp);
      case "position":
        arrExp[index].position = value;
        return setNewExperience(arrExp);
      case "date_start":
        arrExp[index].date_start = moment(value).format("YYYY-MM-DD");
        return setNewExperience(arrExp);
      case "date_end":
        arrExp[index].date_end = moment(value).format("YYYY-MM-DD");
        return setNewExperience(arrExp);
      default:
        return setNewExperience(arrExp);
    }
  };

  const newLanguageHandler = (i, name, value) => {
    const arrExp = [...newLanguageSkill];
    switch (name) {
      case "name":
        arrExp[i].name = value;
        return setNewLanguageSkill(arrExp);
      case "rate":
        arrExp[i].rate = value;
        return setNewLanguageSkill(arrExp);
      default:
        return setNewLanguageSkill(arrExp);
    }
  };

  const newComputerHandler = (i, name, value) => {
    const arrExp = [...newComputerSkill];
    switch (name) {
      case "name":
        arrExp[i].name = value;
        return setNewComputerSkill(arrExp);
      case "rate":
        arrExp[i].rate = value;
        return setNewComputerSkill(arrExp);
      default:
        return setNewComputerSkill(arrExp);
    }
  };

  const removeNewHandler = (i, field) => {
    switch (field) {
      case "education":
        setNewEducation(newEducation.filter((_, index) => i !== index));
        break;
      case "experience":
        setNewExperience(newExperience.filter((_, index) => i !== index));
        break;
      case "language":
        setNewLanguageSkill(newLanguageSkill.filter((_, index) => i !== index));
        break;
      case "computer":
        setNewComputerSkill(newComputerSkill.filter((_, index) => i !== index));
        break;
      default:
        console.log("error");
    }
  };

  const cancelResume = () => history.push("/my-business/resume");

  return (
    <div className="my-business-resume-edit">
      <div className="my-business-resume-edit__header">Basic Info</div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Resume Title"
          name="resumeTitle"
          placeholder="Resume title"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={resumeTitle}
          required={true}
        />
      </div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Last Name"
          name="lastName"
          placeholder="Last Name"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={lastName}
          required={true}
        />
        <InputGroup
          label="First Name"
          name="firstName"
          placeholder="First Name"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={firstName}
          required={true}
        />
      </div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Address"
          name="address"
          placeholder="Address"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={address}
        />
        <InputGroup
          label="Phone Number"
          name="phoneNumber"
          placeholder="Phone Number"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={phoneNumber}
          required={true}
        />
      </div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Nationality"
          name="nationality"
          placeholder="Nationality"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={nationality}
        />
        <DefaultDropdown
          label="Marital Status"
          items={maritalStatusData.map(({ value, lable }) => ({
            id: value,
            name: lable
          }))}
          returnData={setMaritalStatus}
          customTitle={
            currentResume.marital_status && currentResume.marital_status.label
          }
        />
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-header">Education</div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Faculty"
          name="faculty"
          placeholder="Faculty..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={faculty}
        />
        <InputGroup
          label="Profession"
          name="profession"
          placeholder="Profesion..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={profession}
        />
        <DefaultDropdown
          label="Study form"
          items={
            studyFormTypes &&
            studyFormTypes.map(({ lable, value }) => ({
              id: value,
              name: lable
            }))
          }
          customTitle={
            currentResume.study_form && currentResume.study_form.label
          }
          returnData={e => handleResumeInputs("studyForm", e)}
        />
      </div>
      <div className="my-business-resume-edit__section">
        <DefaultDatePicker
          label="Study period"
          value={startStuding.start}
          returnDate={e =>
            setStartStuding({
              ...startStuding,
              start: e
            })
          }
        />
        <DefaultDatePicker
          label="&nbsp;"
          value={startStuding.end}
          returnDate={e =>
            setStartStuding({
              ...startStuding,
              end: e
            })
          }
        />
        <div className="section-bonus-information">
          <QuestionIcon />
          <div className="section-bonus-information__text">
            Orci varius natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nam sit amet euismod dolor.in metus
            interdum. elementum.interdum elementum.
          </div>
        </div>
      </div>
      <Separator />
      {newEducation &&
        newEducation.map(
          (
            {
              study_form,
              faculty,
              profession,
              study_start_date,
              study_end_date
            },
            index
          ) => (
            <Fragment key={index}>
              <div className="my-business-resume-edit__section-remove">
                <ClearIcon
                  onClick={() => removeNewHandler(index, "education")}
                />
              </div>
              <div className="my-business-resume-edit__section">
                <InputGroup
                  label="Faculty"
                  name="faculty"
                  placeholder="Faculty..."
                  onChange={({ target: { name, value } }) =>
                    newEducationHandler(index, name, value)
                  }
                  value={faculty}
                />
                <InputGroup
                  label="Profession"
                  name="profession"
                  placeholder="Profesion..."
                  onChange={({ target: { name, value } }) =>
                    newEducationHandler(index, name, value)
                  }
                  value={profession}
                />
                <DefaultDropdown
                  label="Study form"
                  returnFormat="full"
                  items={
                    studyFormTypes &&
                    studyFormTypes.map(({ lable, value }) => ({
                      id: value,
                      name: lable
                    }))
                  }
                  customTitle={study_form && study_form.label}
                  returnData={e =>
                    newEducationHandler(index, "study_form", {
                      value: e.id,
                      label: e.name
                    })
                  }
                />
              </div>
              <div className="my-business-resume-edit__section">
                <DefaultDatePicker
                  label="Study period"
                  value={study_start_date && new Date(study_start_date)}
                  returnDate={e =>
                    newEducationHandler(index, "study_start_date", e)
                  }
                />
                <DefaultDatePicker
                  label="&nbsp;"
                  value={study_end_date && new Date(study_end_date)}
                  returnDate={e =>
                    newEducationHandler(index, "study_end_date", e)
                  }
                />
                <div className="section-bonus-information">
                  <QuestionIcon />
                  <div className="section-bonus-information__text">
                    Orci varius natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Nam sit amet euismod
                    dolor.in metus interdum. elementum.interdum elementum.
                  </div>
                </div>
              </div>
              <Separator />
            </Fragment>
          )
        )}
      <div className="my-business-resume-edit__add-new-field">
        <PlusIcon />
        <div onClick={addNewEducationField} className="add-btn">
          Add new experience
        </div>
      </div>
      <Separator />
      <div className="my-business-resume-edit__section">
        <DefaultTextArea
          label="Additionally"
          name="additionally"
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={additionally}
        />
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-header">Experience</div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Company"
          name="company1"
          placeholder="Company..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={company1}
        />
        <InputGroup
          label="Position"
          name="position1"
          placeholder="Position..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={position1}
        />
      </div>
      <div className="my-business-resume-edit__section">
        <DefaultDatePicker
          label="Work period"
          value={startWorking1.start}
          returnDate={e =>
            setStartWorking1({
              ...startWorking1,
              start: e
            })
          }
        />
        <DefaultDatePicker
          label="&nbsp;"
          value={startWorking1.end}
          returnDate={e =>
            setStartWorking1({
              ...startWorking1,
              end: e
            })
          }
        />
        <div className="section-bonus-information">
          <QuestionIcon />
          <div className="section-bonus-information__text">
            Orci varius natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nam sit amet euismod dolor.in metus
            interdum. elementum.interdum elementum.
          </div>
        </div>
      </div>
      <Separator />
      {experience2 && (
        <>
          <div className="my-business-resume-edit__section">
            <InputGroup
              label="Company"
              name="company2"
              placeholder="Company..."
              onChange={e => handleResumeInputs(e.target.name, e.target.value)}
              value={company2}
            />
            <InputGroup
              label="Position"
              name="position2"
              placeholder="Position..."
              onChange={e => handleResumeInputs(e.target.name, e.target.value)}
              value={position2}
            />
          </div>
          <div className="my-business-resume-edit__section">
            <DefaultDatePicker
              label="Work period"
              value={startWorking2.start}
              returnDate={e =>
                setStartWorking2({
                  ...startWorking2,
                  start: e
                })
              }
            />
            <DefaultDatePicker
              label="&nbsp;"
              value={startWorking2.end}
              returnDate={e =>
                setStartWorking2({
                  ...startWorking2,
                  end: e
                })
              }
            />
            <div className="section-bonus-information">
              <QuestionIcon />
              <div className="section-bonus-information__text">
                Orci varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Nam sit amet euismod dolor.in metus
                interdum. elementum.interdum elementum.
              </div>
            </div>
          </div>
          <Separator />
        </>
      )}
      {experience3 && (
        <>
          <div className="my-business-resume-edit__section">
            <InputGroup
              label="Company"
              name="company3"
              placeholder="Company..."
              onChange={e => handleResumeInputs(e.target.name, e.target.value)}
              value={company3}
            />
            <InputGroup
              label="Position"
              name="position3"
              placeholder="Position..."
              onChange={e => handleResumeInputs(e.target.name, e.target.value)}
              value={position3}
            />
          </div>
          <div className="my-business-resume-edit__section">
            <DefaultDatePicker
              label="Work period"
              value={startWorking3.start}
              returnDate={e =>
                setStartWorking3({
                  ...startWorking3,
                  start: e
                })
              }
            />
            <DefaultDatePicker
              label="&nbsp;"
              value={startWorking3.end}
              returnDate={e =>
                setStartWorking3({
                  ...startWorking3,
                  end: e
                })
              }
            />
            <div className="section-bonus-information">
              <QuestionIcon />
              <div className="section-bonus-information__text">
                Orci varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Nam sit amet euismod dolor.in metus
                interdum. elementum.interdum elementum.
              </div>
            </div>
          </div>
          <Separator />
        </>
      )}
      {newExperience &&
        newExperience.map(
          ({ company, position, date_start, date_end }, index) => (
            <Fragment key={index}>
              <div className="my-business-resume-edit__section-remove">
                <ClearIcon
                  onClick={() => removeNewHandler(index, "experience")}
                />
              </div>
              <div className="my-business-resume-edit__section">
                <InputGroup
                  label="Company"
                  name="company"
                  placeholder="Company..."
                  onChange={({ target: { name, value } }) =>
                    newExperienceHandler(index, name, value)
                  }
                  value={company}
                />
                <InputGroup
                  label="Position"
                  name="position"
                  placeholder="Position..."
                  onChange={({ target: { name, value } }) =>
                    newExperienceHandler(index, name, value)
                  }
                  value={position}
                />
              </div>
              <div className="my-business-resume-edit__section">
                <DefaultDatePicker
                  label="Work period"
                  value={date_start && new Date(date_start)}
                  returnDate={e => newExperienceHandler(index, "date_start", e)}
                />
                <DefaultDatePicker
                  label="&nbsp;"
                  value={date_end && new Date(date_end)}
                  returnDate={e => newExperienceHandler(index, "date_end", e)}
                />
                <div className="section-bonus-information">
                  <QuestionIcon />
                  <div className="section-bonus-information__text">
                    Orci varius natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Nam sit amet euismod
                    dolor.in metus interdum. elementum.interdum elementum.
                  </div>
                </div>
              </div>
              <Separator />
            </Fragment>
          )
        )}
      <div className="my-business-resume-edit__add-new-field">
        <PlusIcon />
        <div onClick={addNewFieldHandler} className="add-btn">
          Add new experience
        </div>
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-header">Skills</div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Language skills"
          name="languageSkill"
          placeholder="Language..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={languageSkill}
        />
        <InputGroup
          label="Computer Skill"
          name="computerSkill"
          placeholder="Skill..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={computerSkill}
        />
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-header">
        New Language
      </div>
      {newLanguageSkill &&
        newLanguageSkill.map(({ name, rate }, index) => (
          <Fragment key={index}>
            <Separator />
            <div className="my-business-resume-edit__section-remove">
              <ClearIcon onClick={() => removeNewHandler(index, "language")} />
            </div>
            <div
              style={{ alignItems: "flex-end" }}
              className="my-business-resume-edit__section"
            >
              <InputGroup
                label="Language name"
                name="name"
                placeholder="Language..."
                onChange={({ target: { name, value } }) =>
                  newLanguageHandler(index, name, value)
                }
                value={name}
              />
              <InputGroup
                label="Language rate"
                name="rate"
                placeholder="Rate..."
                onChange={({ target: { name, value } }) =>
                  newLanguageHandler(index, name, value)
                }
                value={rate}
              />
              {/* <InputRateSkill
                name="rate"
                skillRate={rate}
                onChange={e => console.log(e, index)}
              /> */}
            </div>
          </Fragment>
        ))}
      <div className="my-business-resume-edit__add-new-field">
        <PlusIcon />
        <div onClick={addNewLanguageField} className="add-btn">
          Add new language
        </div>
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-header">
        New Computer Skill
      </div>
      {newComputerSkill &&
        newComputerSkill.map(({ name, rate }, index) => (
          <Fragment key={index}>
            <Separator />
            <div className="my-business-resume-edit__section-remove">
              <ClearIcon onClick={() => removeNewHandler(index, "computer")} />
            </div>
            <div
              style={{ alignItems: "flex-end" }}
              className="my-business-resume-edit__section"
            >
              <InputGroup
                label="Skill name"
                name="name"
                placeholder="Skill..."
                onChange={({ target: { name, value } }) =>
                  newComputerHandler(index, name, value)
                }
                value={name}
              />
              <InputGroup
                label="Skill rate"
                name="rate"
                placeholder="Rate..."
                onChange={({ target: { name, value } }) =>
                  newComputerHandler(index, name, value)
                }
                value={rate}
              />
              {/* <InputRateSkill
                name="rate"
                skillRate={rate}
                onChange={e => console.log(e, index)}
              /> */}
            </div>
          </Fragment>
        ))}
      <div className="my-business-resume-edit__add-new-field">
        <PlusIcon />
        <div onClick={addNewComputerField} className="add-btn">
          Add new skill
        </div>
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-header">
        Additionally
      </div>
      <div className="my-business-resume-edit__section">
        <InputGroup
          label="Your Salary expectations"
          name="salary"
          placeholder="Salary..."
          onChange={e => handleResumeInputs(e.target.name, e.target.value)}
          value={salary}
        />
      </div>
      <Separator />
      <div className="my-business-resume-edit__section-documents">
        {resumeId !== "create" && (
          <Fragment>
            <DocumentsTable
              tableName={"COMPANY DOCUMENTS"}
              documents={documents}
              deleteDocumentById={deleteDocumentById}
            />
            <Separator />
            <UploadFileDocument
              docName={docName}
              setDocName={setDocName}
              docDescription={docDescription}
              setDocDescription={setDocDescription}
              documentFile={documentFile}
              setDocumentFile={setDocumentFile}
              documentBlob={documentBlob}
              setDocumentBlob={setDocumentBlob}
              uploadDocument={uploadDocument}
              itemContentType={resumeType}
              itemId={resumeId}
            />
            <Separator />
          </Fragment>
        )}
      </div>
      <div className="my-business-resume-edit__footer-buttons">
        <div className="action-button">
          <DefaultButton
            {...buttonOk}
            onClick={editResume}
            disabled={disabledButton}
          >
            Save
          </DefaultButton>
        </div>
        <div className="action-button">
          <DefaultButton {...buttonCancel} onClick={cancelResume}>
            Cancel
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  maritalStatusData: state.resumesReducer.maritalStatus,
  currentUserId: state.profileAPI.profile,
  currentResume: state.resumesReducer.resumeById,
  resumeRequestStatus: state.resumesReducer.resumeRequestStatus,
  studyFormTypes: state.resumesReducer.studyFormTypes
});

export default connect(mapStateToProps, {
  createResume,
  updateResume,
  getCountries,
  getMaritalStatus,
  getResumeById,
  clearResumeRequest,
  uploadDocument,
  deleteDocumentById,
  getStudyFormTypes
})(MyBusinessResumeEdit);
