import { ProfileActionTypes } from "./profile.types";

const INITIAL_STATE = {
  email: "",
  lastName: "",
  firstName: "",
  avatar: "",
  birthday: "",
  password: "",
  phoneNumber: "",
  country: "",
  website: "",
  profession: "",
  profileType: "",
  sex: "",
  companyName: "",
  companySlogan: "",
  companyPhone: "",
  companyFax: "",
  address: "",
  companyDescription: "",
  facebook: "",
  linkedin: "",
  twitter: "",
  instagram: "",
  youtube: "",
  reddit: "",
  businessCardType: "",
  activeCompanyId: ""
};

const ProfileReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProfileActionTypes.EMAIL_INPUT:
      return {
        ...state,
        email: action.payload
      };

    case ProfileActionTypes.LAST_NAME_INPUT:
      return {
        ...state,
        lastName: action.payload
      };

    case ProfileActionTypes.FIRST_NAME_INPUT:
      return {
        ...state,
        firstName: action.payload
      };

    case ProfileActionTypes.AVATAR_INPUT:
      return {
        ...state,
        avatar: action.payload
      };

    case ProfileActionTypes.BIRTHDAY_INPUT:
      return {
        ...state,
        birthday: action.payload
      };

    case ProfileActionTypes.PASSWORD_INPUT:
      return {
        ...state,
        password: action.payload
      };

    case ProfileActionTypes.PHONE_NUMBER_INPUT:
      return {
        ...state,
        phoneNumber: action.payload
      };

    case ProfileActionTypes.COUNTRY_INPUT:
      return {
        ...state,
        country: action.payload
      };

    case ProfileActionTypes.WEBSITE_INPUT:
      return {
        ...state,
        website: action.payload
      };

    case ProfileActionTypes.PROFESSION_INPUT:
      return {
        ...state,
        profession: action.payload
      };

    case ProfileActionTypes.PROFILE_TYPE_INPUT:
      return {
        ...state,
        profileType: action.payload
      };

    case ProfileActionTypes.SEX_INPUT:
      return {
        ...state,
        sex: action.payload
      };

    case ProfileActionTypes.COMPANY_NAME_INPUT:
      return {
        ...state,
        companyName: action.payload
      };

    case ProfileActionTypes.COMPANY_SLOGAN_INPUT:
      return {
        ...state,
        companySlogan: action.payload
      };

    case ProfileActionTypes.COMPANY_PHONE_INPUT:
      return {
        ...state,
        companyPhone: action.payload
      };

    case ProfileActionTypes.COMPANY_FAX_INPUT:
      return {
        ...state,
        companyFax: action.payload
      };

    case ProfileActionTypes.ADDRESS_INPUT:
      return {
        ...state,
        address: action.payload
      };

    case ProfileActionTypes.COMPANY_DESCRIPTION_INPUT:
      return {
        ...state,
        companyDescription: action.payload
      };

    case ProfileActionTypes.SOCIAL_LINKS.FACEBOOK_INPUT:
      return {
        ...state,
        facebook: action.payload
      };

    case ProfileActionTypes.SOCIAL_LINKS.LINKEDIN_INPUT:
      return {
        ...state,
        linkedin: action.payload
      };

    case ProfileActionTypes.SOCIAL_LINKS.TWITTER_INPUT:
      return {
        ...state,
        twitter: action.payload
      };

    case ProfileActionTypes.SOCIAL_LINKS.INSTAGRAM_INPUT:
      return {
        ...state,
        instagram: action.payload
      };

    case ProfileActionTypes.SOCIAL_LINKS.YOUTUBE_INPUT:
      return {
        ...state,
        youtube: action.payload
      };

    case ProfileActionTypes.SOCIAL_LINKS.REDDIT_INPUT:
      return {
        ...state,
        reddit: action.payload
      };

    case ProfileActionTypes.BUSINESS_CARD_TYPE:
      return { ...state, businessCardType: action.payload };

    case ProfileActionTypes.PROFILE_ACTIVE_COMPANY:
      return { ...state, activeCompanyId: action.payload };

    default:
      return state;
  }
};

export default ProfileReducer;
