import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { missingImage } from "../../assets";

import {
  ProductCard,
  ProductSwitch,
  Pagination,
  DefaultDropdown,
  DefaultMultiLevelDropdown
} from "../../components";

import {
  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany,
  getOrganizationB2BProducts,
  getCompanyB2BProducts,
  getOrganizationB2CProducts,
  getCompanyB2CProducts,
  addProductToWishList,
  getWishList
} from "../../redux/actions-exporter";

import "./comp-org-offers-tab.styles.scss";
import NoDataFound from "../no-data-found/no-data-fount.component";

const CompOrgProducts = ({ b2bProducts, b2cProducts, toggle }) => {
  return (
    <div className="comp-org-offers-tab__cards">
      {!toggle ? (
        b2bProducts && b2bProducts.length ? (
          b2bProducts.map(b2bproduct => {
            const { id, image, cost, currency, name, categories } = b2bproduct;
            return (
              <div key={id} className="comp-org-offers-tab__card">
                <ProductCard
                  navProductType="b2b"
                  key={id}
                  productId={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={
                    name.length > 40 ? name.substring(0, 40) + "..." : name
                  }
                  price={cost}
                  currency={currency}
                  currentProductData={b2bproduct}
                />
              </div>
            );
          })
        ) : (
          <NoDataFound noDataTitle="No B2B products found!" />
        )
      ) : b2cProducts && b2cProducts.length ? (
        b2cProducts.map(b2cproduct => {
          const {
            id,
            image,
            cost,
            currency,
            name,
            categories,
            discount_percent,
            coupon_discount_percent,
            price_discount
          } = b2cproduct;
          return (
            <div key={id} className="comp-org-offers-tab__card">
              <ProductCard
                navProductType="b2c"
                key={id}
                productId={id}
                imgUrl={image ? image : missingImage}
                name={categories[0] && categories[0].name}
                description={
                  name.length > 40 ? name.substring(0, 40) + "..." : name
                }
                price={cost}
                priceDiscount={price_discount}
                currency={currency}
                currentProductData={b2cproduct}
                discountPercent={discount_percent}
                couponDiscountPercent={coupon_discount_percent}
              />
            </div>
          );
        })
      ) : (
        <NoDataFound noDataTitle="No B2C products found!" />
      )}
    </div>
  );
};

const CompOrgOffersTab = ({
  routeParam,
  productsType,

  getCompanyB2BProducts,
  areCompanyB2BProductsPending,
  companyB2BProducts,

  getCompanyB2CProducts,
  areCompanyB2CProductsPending,
  companyB2CProducts,

  getOrganizationB2BProducts,
  areB2COrganizationProductsPending,
  b2cOrganizationProducts,

  getOrganizationB2CProducts,
  areB2BOrganizationProductsPending,
  b2bOrganizationProducts,

  addProductToWishList,

  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany,

  company,
  organization,

  searchedB2BCategories,
  searchedB2CCategories,

  b2cOrganizationProductsCount,
  b2bOrganizationProductsCount,
  companyB2BProductsCount,
  companyB2CProductsCount
}) => {
  const [toggle, setToggle] = useState(true);

  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");

  const selectB2BOrganizationPage = page => {
    getOrganizationB2BProducts(routeParam, category, sortBy, page);
  };
  const selectB2COrganizationPage = page => {
    getOrganizationB2CProducts(routeParam, category, sortBy, page);
  };

  const selectB2BCompanyPage = page => {
    getCompanyB2BProducts(routeParam, category, sortBy, page);
  };
  const selectB2CCompanyPage = page => {
    getCompanyB2CProducts(routeParam, category, sortBy, page);
  };

  useEffect(() => {
    searchB2BCategoriesByCompany(
      productsType === "company" ? company.id : organization.id
    );
    searchB2CCategoriesByCompany(
      productsType === "company" ? company.id : organization.id
    );
    getWishList();

    if (productsType === "company") {
      getCompanyB2BProducts(routeParam, category, sortBy);
      getCompanyB2CProducts(routeParam, category, sortBy);
    } else if (productsType === "organization") {
      getOrganizationB2BProducts(routeParam, category, sortBy);
      getOrganizationB2CProducts(routeParam, category, sortBy);
    }
  }, [
    company.id,
    organization.id,
    searchB2BCategoriesByCompany,
    searchB2CCategoriesByCompany,
    getCompanyB2BProducts,
    getCompanyB2CProducts,
    getOrganizationB2BProducts,
    getOrganizationB2CProducts,
    productsType,
    routeParam,
    category,
    sortBy
  ]);

  return (
    <div>
      <div className="comp-org-offers-tab">
        <div className="comp-org-offers-tab__sort">
          <div className="comp-org-offers-tab__sort-bottom">
            <div className={"comp-org-offers-tab__sort-bottom__selector"}>
              <DefaultMultiLevelDropdown
                label="Category"
                filter={true}
                style={{ width: "100%" }}
                items={!toggle ? searchedB2BCategories : searchedB2CCategories}
                returnData={setCategory}
                inputFunc={e =>
                  !toggle
                    ? searchB2BCategoriesByCompany(
                        productsType === "company"
                          ? company.id
                          : organization.id,
                        e.target.value
                      )
                    : searchB2CCategoriesByCompany(
                        productsType === "company"
                          ? company.id
                          : organization.id,
                        e.target.value
                      )
                }
              />
            </div>
            <div className={"comp-org-offers-tab__sort-bottom__selector"}>
              <DefaultDropdown
                label="Sort by"
                style={{ width: "100%" }}
                items={[
                  { id: "-id", name: "Newest" },
                  { id: "id", name: "Oldest" },
                  { id: "cost", name: "Price ascending" },
                  { id: "-cost", name: "Price descending" }
                ]}
                returnData={setSortBy}
              />
            </div>
          </div>
        </div>
        <ProductSwitch isToggled={toggle} onToggle={() => setToggle(!toggle)} />
      </div>
      {productsType === "company" ? (
        <CompOrgProducts
          b2bPending={areCompanyB2BProductsPending}
          b2cPending={areCompanyB2CProductsPending}
          b2bProducts={companyB2BProducts}
          b2cProducts={companyB2CProducts}
          toggle={toggle}
          addProductToWishList={addProductToWishList}
        />
      ) : (
        <CompOrgProducts
          b2bPending={areB2BOrganizationProductsPending}
          b2cPending={areB2COrganizationProductsPending}
          b2bProducts={b2bOrganizationProducts}
          b2cProducts={b2cOrganizationProducts}
          toggle={toggle}
          addProductToWishList={addProductToWishList}
        />
      )}

      {!toggle && (
        <Pagination
          dataCount={
            productsType === "company"
              ? companyB2BProductsCount
              : b2bOrganizationProductsCount
          }
          itemsPerPage={10}
          selectedPage={
            productsType === "company"
              ? selectB2BCompanyPage
              : selectB2BOrganizationPage
          }
        />
      )}

      {toggle && (
        <Pagination
          dataCount={
            productsType === "company"
              ? companyB2CProductsCount
              : b2cOrganizationProductsCount
          }
          itemsPerPage={10}
          selectedPage={
            productsType === "company"
              ? selectB2CCompanyPage
              : selectB2COrganizationPage
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  company: state.companiesReducer.company,
  organization: state.organizationsReducer.organization,

  b2bCategoriesResults: state.categoriesAPI.b2bCategoriesResults,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,

  searchedB2BCategories: state.categoriesAPI.searchedB2BCategories,
  searchedB2CCategories: state.categoriesAPI.searchedB2CCategories,

  areB2COrganizationProductsPending:
    state.b2cProductsReducer.areB2COrganizationProductsPending,
  b2cOrganizationProducts: state.b2cProductsReducer.b2cOrganizationProducts,
  b2cOrganizationProductsCount:
    state.b2cProductsReducer.b2cOrganizationProductsCount,

  areB2BOrganizationProductsPending:
    state.b2BProductsReducer.areB2BOrganizationProductsPending,
  b2bOrganizationProducts: state.b2BProductsReducer.b2bOrganizationProducts,
  b2bOrganizationProductsCount:
    state.b2BProductsReducer.b2bOrganizationProductsCount,

  areCompanyB2BProductsPending:
    state.b2BProductsReducer.areCompanyB2BProductsPending,
  companyB2BProducts: state.b2BProductsReducer.companyB2BProducts,
  companyB2BProductsCount: state.b2BProductsReducer.companyB2BProductsCount,

  areCompanyB2CProductsPending:
    state.b2cProductsReducer.areCompanyB2CProductsPending,
  companyB2CProducts: state.b2cProductsReducer.companyB2CProducts,
  companyB2CProductsCount: state.b2cProductsReducer.companyB2CProductsCount
});

export default connect(mapStateToProps, {
  getOrganizationB2BProducts,
  getOrganizationB2CProducts,

  getCompanyB2BProducts,
  getCompanyB2CProducts,

  addProductToWishList,
  getWishList,

  searchB2BCategoriesByCompany,
  searchB2CCategoriesByCompany
})(CompOrgOffersTab);
