import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import {
  NavLink,
  Route,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { useTranslation } from "react-i18next";
import { getCookie } from "../../axios/axios.config";
import {
  LoadingSpinner,
  OrganizationCard,
  CompOrgMainTab,
  CompOrgFilesTab,
  CompOrgActivityTab,
  CompOrgOffersTab,
  CompOrgStructureTab,
  CompOrgContactsTab
} from "../../components";
import {
  getCompany,
  getNewsByCompanyId,
  getWishList
} from "../../redux/actions-exporter";

import "./company.styles.scss";

const Company = ({
  getCompany,
  company,
  isCompanyPending,
  getNewsByCompanyId,
  areNewsByCompanyIdPending,
  newsByCompanyId,
  getWishList
}) => {
  let { companyId } = useParams();
  let { path, url } = useRouteMatch();
  const { t } = useTranslation();
  useEffect(() => {
    const authCookie = getCookie("Authorization");
    getCompany(companyId);
    getNewsByCompanyId(companyId);
    if (authCookie) {
      getWishList();
    }
  }, [getCompany, getNewsByCompanyId, getWishList, companyId]);

  useEffect(() => {
    const { metadata } = company;

    if (metadata && metadata.script) {
      let parser = new DOMParser(),
        API;
      const parsedData = parser.parseFromString(metadata.script, "text/html");
      const allScriptTags = parsedData.head.getElementsByTagName("script");
      for (let i = 0; i < allScriptTags.length; i++) {
        const script = document.createElement("script");
        if (allScriptTags[i].src) {
          script.src = allScriptTags[i].src;
          API = allScriptTags[i].getAttribute("api");
          API = API ? JSON.parse(API) : false;
          if (API && Array.isArray(API.widgets)) {
            API.widgets.map((widget, index) => {
              widget.block = "company-script";
              return widget;
            });
            let liveChatWidget = { ...API.widgets[0] };
            liveChatWidget.type = "message";
            liveChatWidget.block = "widget-live";
            liveChatWidget.height = 350;
            liveChatWidget.width = 350;
            API.widgets.push(liveChatWidget);
          } else if (API) {
            API.widgets.block = "company-script";
            API.widgets = [{ ...API.widgets }, { ...API.widgets }];
            API.widgets[1].type = "message";
            API.widgets[1].block = "widget-live";
            API.widgets[1].height = 350;
            API.widgets[1].width = 350;
          }
          script.setAttribute("api", JSON.stringify(API));
        } else script.innerHTML = allScriptTags[i].innerHTML;
        script.setAttribute("id", `companyScript${i}`);
        if (!document.getElementById(`companyScript${i}`))
          document.getElementById("company-script").appendChild(script);
      }
    }
  }, [company, isCompanyPending]);

  return (
    <div id="company-script" className="company">
      {isCompanyPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <OrganizationCard
            title={company.name}
            shortDescription={parse(company.short_description)}
            logo={company.logo}
            memberOfOrganization={company.parent}
            isMember={company.parent}
          />

          <div className="nav">
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/offers`}
              className="nav-link"
            >
              {t("Offers")}
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/main`}
              className="nav-link"
            >
              {t("Main")}
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/files`}
              className="nav-link"
            >
              {t("Files")}
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/activity`}
              className="nav-link"
            >
              {t("Activity")}
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/structure`}
              className="nav-link"
            >
              {t("Structure")}
            </NavLink>
            <div className="nav-link__separator" />
            <NavLink
              activeClassName="nav-link-selected"
              to={`${url}/contacts`}
              className="nav-link"
            >
              {t("Contacts")}
            </NavLink>
          </div>
          {company && company.additional_pages.length ? (
            <div className="nav nav-additional">
              {company.additional_pages.map(page => {
                return (
                  <Fragment key={page.id}>
                    <NavLink
                      activeClassName="nav-link-selected"
                      to={`${url}/additional-${page.id}`}
                      className="nav-link"
                    >
                      {page.title.length > 20
                        ? page.title.substring(0, 20) + "..."
                        : page.title}
                    </NavLink>
                    <div className="nav-link__separator" />
                  </Fragment>
                );
              })}
            </div>
          ) : null}

          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/offers`} />}
          />
          {!areNewsByCompanyIdPending && (
            <Route
              exact
              strict
              path={`${path}/main`}
              component={() => (
                <CompOrgMainTab news={newsByCompanyId} companySelected={true} />
              )}
            />
          )}

          <Route
            path={`${path}/files`}
            component={() => (
              <CompOrgFilesTab
                gallery={company.galleries}
                documents={company.documents}
                companySelected={true}
              />
            )}
          />
          <Route
            path={`${path}/activity`}
            component={() => <CompOrgActivityTab companySelected={true} />}
          />
          <Route
            path={`${path}/offers`}
            component={() => (
              <CompOrgOffersTab
                routeParam={companyId}
                productsType="company"
                companyCategories={company && company.branches}
                // companySelected={true}
              />
            )}
          />
          <Route
            path={`${path}/structure`}
            component={() => (
              <CompOrgStructureTab
                companyId={companyId}
                companySelected={true}
              />
            )}
          />
          <Route
            path={`${path}/contacts`}
            component={() => (
              <CompOrgContactsTab
                organizationName={company && company.name}
                metadata={company && company.metadata}
                companySelected={true}
              />
            )}
          />

          {company && company.additional_pages.length
            ? company.additional_pages.map((additionalRoutes, index) => (
                <Route
                  key={index}
                  path={`${path}/additional-${additionalRoutes.id}`}
                  component={() => (
                    <div className="company__additional-tab">
                      <h3>
                        {additionalRoutes.title.length > 40
                          ? additionalRoutes.title.substring(0, 40) + "..."
                          : additionalRoutes.title}
                      </h3>
                      <div className="company__additional-content">
                        {parse(additionalRoutes.content)}
                      </div>
                    </div>
                  )}
                />
              ))
            : null}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isCompanyPending: state.companiesReducer.isCompanyPending,
  company: state.companiesReducer.company,

  areNewsByCompanyIdPending: state.newsReducer.areNewsByCompanyIdPending,
  newsByCompanyId: state.newsReducer.newsByCompanyId.results
});

export default connect(mapStateToProps, {
  getCompany,
  getNewsByCompanyId,
  getWishList
})(Company);
