import { FilterActionTypes } from "./filter.types";

export const addCountryFilter = country => ({
  type: FilterActionTypes.COUNTRIES.ADD_COUNTRY,
  payload: country
});

export const deleteCountryFilter = countryId => ({
  type: FilterActionTypes.COUNTRIES.DELETE_COUNTRY,
  payload: countryId
});

export const setCountriesFilterStatus = value => ({
  type: FilterActionTypes.COUNTRIES.SET_COUNTRIES_FILTER_ACTIVE,
  payload: value
});

export const addOrganizationFilter = organization => ({
  type: FilterActionTypes.ORGANIZATIONS.ADD_ORGANIZATION,
  payload: organization
});

export const deleteOrganizationFilter = organization => ({
  type: FilterActionTypes.ORGANIZATIONS.DELETE_ORGANIZATION,
  payload: organization
});

export const setOrganizationsFilterStatus = value => ({
  type: FilterActionTypes.ORGANIZATIONS.SET_ORGANIZATIONS_FILTER_ACTIVE,
  payload: value
});

export const setBranchesFilterStatus = value => ({
  type: FilterActionTypes.BRANCHES.SET_BRANCHES_FILTER_ACTIVE,
  payload: value
});

export const addBranchFilter = branch => ({
  type: FilterActionTypes.BRANCHES.ADD_BRANCH,
  payload: branch
});

export const deleteBranchFilter = branch => ({
  type: FilterActionTypes.BRANCHES.DELETE_BRANCH,
  payload: branch
});

export const setB2CProducersFilterStatus = value => ({
  type: FilterActionTypes.PRODUCERS.SET_B2C_PRODUCERS_FILTER_ACTIVE,
  payload: value
});

export const addB2CProducerFilter = producer => ({
  type: FilterActionTypes.PRODUCERS.ADD_B2C_PRODUCER,
  payload: producer
});

export const deleteB2CProducerFilter = producer => ({
  type: FilterActionTypes.PRODUCERS.DELETE_B2C_PRODUCER,
  payload: producer
});

export const setB2BCategoriesFilterStatus = value => ({
  type: FilterActionTypes.CATEGORIES_B2B.SET_B2B_CATEGORIES_FILTER_ACTIVE,
  payload: value
});

export const addB2BCategoryFilter = category => ({
  type: FilterActionTypes.CATEGORIES_B2B.ADD_B2B_CATEGORY,
  payload: category
});

export const deleteB2BCategoryFilter = category => ({
  type: FilterActionTypes.CATEGORIES_B2B.DELETE_B2B_CATEGORY,
  payload: category
});

export const setB2CCategoriesFilterStatus = value => ({
  type: FilterActionTypes.CATEGORIES_B2C.SET_B2C_CATEGORIES_FILTER_ACTIVE,
  payload: value
});

export const addB2CCategoryFilter = category => ({
  type: FilterActionTypes.CATEGORIES_B2C.ADD_B2C_CATEGORY,
  payload: category
});

export const deleteB2CCategoryFilter = category => ({
  type: FilterActionTypes.CATEGORIES_B2C.DELETE_B2C_CATEGORY,
  payload: category
});

export const resetFilter = () => ({
  type: FilterActionTypes.RESET_FILTER,
  payload: []
});
