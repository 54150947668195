export const NewsActionTypes = {
  GET_NEWS: "GET_NEWS",
  GET_NEWS_PENDING: "GET_NEWS_PENDING",
  GET_NEWS_ERROR: "GET_NEWS_ERROR",
  GET_NEWS_BY_ID: "GET_NEWS_BY_ID",
  GET_NEWS_BY_ID_PENDING: "GET_NEWS_BY_ID_PENDING",
  GET_NEWS_BY_ID_ERROR: "GET_NEWS_BY_ID_ERROR",
  CREATE_NEWS: "CREATE_NEWS",
  UPDATE_NEWS: "UPDATE_NEWS",
  DELETE_NEWS: "DELETE_NEWS",
  GET: {
    GET_NEWS_CATEGORIES_PENDING: "GET_NEWS_CATEGORIES_PENDING",
    GET_NEWS_CATEGORIES_SUCCESS: "GET_NEWS_CATEGORIES_SUCCESS",
    GET_NEWS_CATEGORIES_ERROR: "GET_NEWS_CATEGORIES_ERROR"
  },
  COMPANY: {
    GET_NEWS_BY_COMPANY_ID_PENDING: "GET_NEWS_BY_COMPANY_ID_PENDING",
    GET_NEWS_BY_COMPANY_ID_SUCCESS: "GET_NEWS_BY_COMPANY_ID_SUCCESS",
    GET_NEWS_BY_COMPANY_ID_ERROR: "GET_NEWS_BY_COMPANY_ID_ERROR"
  },
  ORGANIZATION: {
    GET_NEWS_BY_ORGANIZATION_ID_PENDING: "GET_NEWS_BY_ORGANIZATION_ID_PENDING",
    GET_NEWS_BY_ORGANIZATION_ID_SUCCESS: "GET_NEWS_BY_ORGANIZATION_ID_SUCCESS",
    GET_NEWS_BY_ORGANIZATION_ID_ERROR: "GET_NEWS_BY_ORGANIZATION_ID_ERROR"
  },
  GET_BY_USER: {
    GET_NEWS_BY_USER_ID_PENDING: "GET_NEWS_BY_USER_ID_PENDING",
    GET_NEWS_BY_USER_ID_SUCCESS: "GET_NEWS_BY_USER_ID_SUCCESS",
    GET_NEWS_BY_USER_ID_ERROR: "GET_NEWS_BY_USER_ID_ERROR"
  },
  PATCH: {
    IMAGE: {
      PATCH_NEWS_IMAGE_PENDING: "PATCH_NEWS_IMAGE_PENDING",
      PATCH_NEWS_IMAGE_SUCCESS: "PATCH_NEWS_IMAGE_SUCCESS",
      PATCH_NEWS_IMAGE_ERROR: "PATCH_NEWS_IMAGE_ERROR"
    }
  },
  CLEAR_NEWS_REQUEST: "CLEAR_NEWS_REQUEST"
};
