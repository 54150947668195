import { ProductFormActionTypes } from "./product-form.types";

const INITIAL_STATE = {
  productFormPending: false,
  productFormSuccess: false,
  productFormError: {}
};

const ProductFormReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProductFormActionTypes.PRODUCT_FORM_PENDING:
      return {
        ...state,
        productFormPending: true
      };

    case ProductFormActionTypes.PRODUCT_FORM_SUCCESS:
      return {
        ...state,
        productFormSuccess: true,
        productFormPending: false
      };

    case ProductFormActionTypes.PRODUCT_FORM_ERROR:
      return {
        ...state,
        productFormError: action.payload,
        productFormPending: false
      };

    default:
      return state;
  }
};

export default ProductFormReducer;
