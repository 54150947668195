import React from "react";

import DefaultButton from "../../../components/default-button/default-button.component";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import { colorPalette } from "../../../styles/colorPalette";
import "./job-details-brief.styles.scss";
import { Link } from "react-router-dom";

const JobDetailsBrief = ({
  jobTitle,
  date,
  company,
  typeOfEmployment,
  currentUrl
}) => {
  return (
    <div className="job-details-brief">
      <div className="job-details-brief__title">{jobTitle}</div>
      <div className="job-details-brief__description">
        <div className="job-details-brief__description__block">
          <div className="job-details-brief__description__block__name">
            Date:
          </div>
          <div className="job-details-brief__description__block__data">
            {date}
          </div>
        </div>
        <div className="job-details-brief__description__block">
          <div className="job-details-brief__description__block__name">
            Company:
          </div>
          <div className="job-details-brief__description__block__data job-details-brief__description__block__data--primary-color">
            {company}
          </div>
        </div>
        <div className="job-details-brief__description__block">
          <div className="job-details-brief__description__block__name">
            Type of employemnts:
          </div>
          <div className="job-details-brief__description__block__data">
            {typeOfEmployment}
          </div>
        </div>
      </div>
      <div className="job-details-brief__anchors">
        <div className="job-details-brief__anchors__send-resume">
          <Link to={`${currentUrl}/send-resume`}>
            <DefaultButton
              border={`1px solid ${colorPalette.colorPrimary.main}`}
              color={colorPalette.colorPrimary.main}
              padding="2px 20px"
            >
              Send Resume
            </DefaultButton>
          </Link>
        </div>
        <div className="job-details-brief__anchors__social-link job-details-brief__anchors__social-link--facebook">
          <FacebookIcon />
        </div>
        <div className="job-details-brief__anchors__social-link job-details-brief__anchors__social-link--twitter">
          <TwitterIcon />
        </div>
        <div className="job-details-brief__anchors__social-link job-details-brief__anchors__social-link--linkedin">
          <LinkedInIcon />
        </div>
      </div>
    </div>
  );
};

export default JobDetailsBrief;
