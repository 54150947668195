import React from "react";

import { ParentWrapper, ButtonsFooterWrapper } from "@core/layout";
import { EditorGroup, InputGroup, DefaultButton } from "@core/components";

import { buttonOk } from "~/styles/buttons-styles";
import "./additional-pages.styles.scss";
import { useTranslation } from "react-i18next";

const AdditionalPagesUpload = ({
  setAdditionalPageTitle,
  additionalPageTitle,
  setAdditionalPageEditor,
  additionalPageEditor,
  itemContentType,
  itemId,
  uploadAdditionalPage
}) => {
  const { t } = useTranslation();
  const additionalUploadHandler = () =>
    uploadAdditionalPage(
      additionalPageTitle,
      additionalPageEditor,
      itemContentType,
      itemId
    );

  return (
    <div className="additional-pages">
      <div className="additional-pages__header">{t("Additional pages")}</div>
      <ParentWrapper>
        <InputGroup
          label={t("Title")}
          name="additionalPageTitle"
          placeholder={t("Title")}
          onChange={e => setAdditionalPageTitle(e.target.value)}
          value={additionalPageTitle}
          required={false}
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          onChange={e => setAdditionalPageEditor(e.target.getContent())}
          value={additionalPageEditor}
          label={t("Preview description")}
          name="previewDescription"
        />
      </ParentWrapper>
      <ButtonsFooterWrapper>
        <div style={{ width: "33%" }}>
          <DefaultButton
            width="100%"
            onClick={additionalUploadHandler}
            {...buttonOk}
            disabled={additionalPageTitle === "" || additionalPageEditor === ""}
          >
            {t("Add page")}
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

export default AdditionalPagesUpload;
