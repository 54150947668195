import React from "react";
import { SketchPicker } from "react-color";

import "./color-picker.styles.scss";

const ColorPicker = ({ label, itemColor, setItemColor }) => {
  return (
    <div className="color-picker">
      <div className="color-picker__label">{label}</div>
      <div className="color-picker__panel">
        <SketchPicker
          color={itemColor}
          onChangeComplete={e => setItemColor(e.hex)}
        />
      </div>
    </div>
  );
};

export default ColorPicker;
