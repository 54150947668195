const orderListStyle = `
<style>
.order-list {
  width: 100%;
  border: 1px solid #aeaeae;
  border-collapse: collapse;
}

.order-list div {
  user-select: text;
}

.order-list__header {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 2% 8%;
}

.order-list__icon {
  margin: 0 14px 2px 0;
}

.order-list__title {
  color: #262626;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.order-list__main {
  overflow-x: auto;
}

.order-list__footer {
  display: flex;
  background: #fbfbfb;
}

.order-list-preloader {
  position: relative;
  transition: all 300ms ease;
}

.order-list-preloader::before {
  content: "";
  background: hsla(0, 0%, 100%, 0.45);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.order-list-table {
  width: 100%;
  border-collapse: collapse;
}

td + td {
  text-align: center;
  /* border-left: 1px solid #aeaeae; */
}

.order-list-table__header {
  background-color: #fbfbfb;
}

.order-list-table__header-title {
  padding: 20px 14px;
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase; */
  border-bottom: 1px solid #aeaeae;
}

.order-list-table__header-title--index {
  width: 7%;
  text-align: center;
}

.order-list-table__header-title--item {
  width: 10%;
  text-align: left;
}

.order-list-table__header-title--name {
  width: 60%;
  text-align: left;
}

.order-list-table__header-title--total {
  width: 30%;
  text-align: center;
}

.order-list-table__main {
  background-color: #fbfbfb;
}

.order-list-item {}

.order-list-item__media {
  flex-basis: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-list-item__image {
  height: 90px;
  width: 90px;
  padding: 20px;
  object-fit: scale-down;
  user-select: none;
}

.order-list-item__main {
  flex-basis: 72%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
}

.order-list-item__capture {
  color: hsla(0, 0%, 0%, 0.8);
  font-size: 13px;
  letter-spacing: 0.3;
  margin-bottom: 3px;
}

.order-list-item__title {
  color: #262626;
  font-weight: 700;
  text-align: left;
  margin: 0px 0 3%;
}

.order-list-item__price {
  color: #ff8700;
}

.order-list__remove {
  width: 8%;
}

.order-list__remove-icon {
  color: #1e1e1e;
  font-size: 15px;
  margin: 0 80% 0 0;
  cursor: pointer;
  transition: all 300ms ease;
}

.order-list__remove-icon:hover {
  color: #000000;
}

.order-list-button {
  background: #fff;
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.84px;
  padding: 10px 18px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  cursor: pointer;
  transition: all 100ms ease;
}

.order-list-button:hover {
  color: #fff;
  background: #7f7f7f;
  border: 1px solid #7f7f7f;
}

.order-list-button:disabled {
  background: #eaeaea;
  color: #7f7f7f;
  border: 1px solid #888888;
  cursor: not-allowed;
}

.order-list-pagination {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  align-self: flex-end;
  margin: 0 0 0 auto;
  padding: 20px;
}

.order-list-pagination__separator {
  color: #aeaeae;
  font-size: 16px;
  font-weight: 800;
  padding: 0px 10px;
  margin: 0 4px;
  outline: none;
  cursor: pointer;
  vertical-align: bottom;
}

.order-list-pagination__button {
  background: #fff;
  color: #aeaeae;
  font-size: 16px;
  font-weight: 800;
  padding: 8px 16px;
  margin: 0 4px;
  border: 2px solid #aeaeae;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
}

.order-list-pagination__button.order-list-pagination__button--active {
  color: #fff;
  background: #c0c0c0;
}

.order-list-pagination__button--disabled, .order-list-pagination__button:hover {
  background: #ededed;
}
</style>
`;

export default orderListStyle;
