import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { missingImage } from "../../../assets";
import {
  DefaultButton,
  MyBusinessNoData,
  SearchBar,
  Pagination
} from "../../../components";
import {
  getMeetingsRelatedToMe,
  acceptEventInvitation,
  declineEventInvitation
} from "../../../redux/actions-exporter";

import { colorPalette } from "../.././../styles/colorPalette";
import "./meeting-invitations.styles.scss";
import { withDebounce } from "../../../shared";

const MyBusinessMeetingInvitations = ({
  getMeetingsRelatedToMe,
  meetingInvitationsByMe,
  acceptEventInvitation,
  declineEventInvitation,
  meetingInvitationsByMeCount
}) => {
  const [searchMeeting, setSearchMeeting] = useState("");
  const [meetingsPage, setMeetingsPage] = useState(1);

  useEffect(() => {
    getMeetingsRelatedToMe(searchMeeting, meetingsPage);
  }, [getMeetingsRelatedToMe, searchMeeting, meetingsPage]);

  const setSearchMeetingHandler = withDebounce(e => setSearchMeeting(e));

  return (
    <div className="my-business-meeting-invitations">
      <SearchBar onChange={e => setSearchMeetingHandler(e.target.value)} />
      <div className="my-business-meeting-invitations__table">
        {meetingInvitationsByMe && meetingInvitationsByMe.length ? (
          meetingInvitationsByMe.map(({ id, title, meeting_response }) => (
            <div key={id} className="meeting-invitation">
              <div className="meeting-invitation__description">
                <img src={missingImage} alt="" />
                <div title={title} className="description-name">
                  {title.length < 15 ? title : title.substring(0, 15) + "..."}
                </div>
              </div>
              <div className="meeting-invitation__btns">
                <DefaultButton
                  backgroundColor={
                    meeting_response === "accept"
                      ? colorPalette.colorPrimary.main
                      : "white"
                  }
                  hover={colorPalette.colorPrimary.main}
                  hoverColor="white"
                  color={
                    meeting_response === "accept"
                      ? "white"
                      : colorPalette.colorPrimary.main
                  }
                  width="100%"
                  border={`1px solid ${colorPalette.colorPrimary.main}`}
                  padding="2px 20px"
                  borderRadius={5}
                  onClick={() => acceptEventInvitation(id)}
                  disabled={meeting_response === "accept"}
                >
                  Accept
                </DefaultButton>
                <DefaultButton
                  backgroundColor={
                    meeting_response === "denial"
                      ? colorPalette.colorPrimary.dangerRed
                      : "white"
                  }
                  hover={colorPalette.colorPrimary.dangerRed}
                  hoverColor="white"
                  color={
                    meeting_response === "denial"
                      ? "white"
                      : colorPalette.colorPrimary.dangerRed
                  }
                  width="100%"
                  border={`1px solid ${colorPalette.colorPrimary.dangerRed}`}
                  borderRadius={5}
                  padding="2px 20px"
                  onClick={() => declineEventInvitation(id)}
                  disabled={meeting_response === "denial"}
                >
                  Decline
                </DefaultButton>
              </div>
            </div>
          ))
        ) : (
          <MyBusinessNoData noDataText="No incoming meeting invitations!" />
        )}
      </div>
      <Pagination
        forcePage={meetingsPage - 1}
        dataCount={meetingInvitationsByMeCount}
        itemsPerPage={10}
        selectedPage={page => setMeetingsPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  meetingInvitationsByMe: state.eventInvitations.meetingInvitationsByMe,
  meetingInvitationsByMeCount:
    state.eventInvitations.meetingInvitationsByMeCount
});

export default connect(mapStateToProps, {
  getMeetingsRelatedToMe,
  acceptEventInvitation,
  declineEventInvitation
})(MyBusinessMeetingInvitations);
