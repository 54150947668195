import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { _skip } from "../../constants";
import { BuilderSiteCard, LoadingSpinner } from "../../components";

import {
  getSite,
  updateSite,
  getCompaniesByUserId,
  getUserOrganizationsChamber
} from "../../redux/actions-exporter";

import { apiDomain } from "../../axios/axios.config.js";

import "./builder-site.styles.scss";

const BuilderSite = ({
  match,
  isSitePending,
  getSite,
  site,
  updateSite,
  updatedSiteError,
  getCompaniesByUserId,
  companiesByUserId,
  areCompaniesByUserIdPending,
  getUserOrganizationsChamber,
  userOrganizationsChamber,
  areUserOrganizationsPending,
  areSitesLoaded
}) => {
  const [title, setTitle] = useState("");
  const [domain, setDomain] = useState("");
  const [schemaId, setSchemaId] = useState(1);
  const [organizationId, setOrganizationId] = useState("");

  const schemas = [
    { id: 1, value: "http://" },
    { id: 2, value: "https://" }
  ];

  useEffect(() => {
    if (updatedSiteError && domain === updatedSiteError.domain) {
      return;
    }

    getSite(match.params.siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSite, match.params.siteId, areSitesLoaded, updatedSiteError]);

  useEffect(() => {
    if (site && site.hasOwnProperty("organization")) {
      getCompaniesByUserId(_skip, 1, 100);
      getUserOrganizationsChamber(_skip, 1, 100);
      setOrganizationId(site.organization || "");
    }
    if (site && site.hasOwnProperty("site")) {
      const [schema, domain] = site.site.domain.split("://");

      setTitle(site.site.name || "");
      setDomain(domain || "");

      const { id: schemaId } = schemas.find(
        i => i.value === `${schema || ""}://`
      );
      setSchemaId(schemaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompaniesByUserId, getUserOrganizationsChamber, site]);

  const titleRegExp = new RegExp(`^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$`);
  const domainRegExp = new RegExp(
    `^(https?://)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$`
  );

  // Handle site updates
  const onUpdateSite = ({ id, title, domain }) => {
    const schema = schemas.find(i => i.id === schemaId);
    const url = `${schema.value}${domain}`.match(domainRegExp)
      ? `${schema.value}${domain}`
      : `${schema.value}${domain}.${apiDomain}`;

    updateSite({
      id,
      title: title || site.site.title,
      url: url || site.site.domain,
      domain: domain || site.site.domain
    });
  };

  const schema = schemas.find(i => i.id === schemaId);
  const form = {
    title,
    isTitleValid: title.match(titleRegExp),
    setTitle,
    schemas,
    schemaId,
    setSchemaId,
    domain,
    isDomainValid:
      (`${schema.value}${domain}`.match(domainRegExp) ||
        `${schema.value}${domain}.${apiDomain}`.match(domainRegExp)) &&
      (!updatedSiteError || domain !== updatedSiteError.domain),
    domainError: updatedSiteError && domain === updatedSiteError.domain,
    setDomain,
    organizationId,
    setOrganizationId
  };

  const isOrganizationsPending =
    areCompaniesByUserIdPending || areUserOrganizationsPending;
  if (!isSitePending && site && site.hasOwnProperty("site")) {
    return (
      <div className="builder-site-page">
        <BuilderSiteCard
          {...{
            ...form,
            create: false,
            site: {
              id: site.id,
              title: site.site.name,
              domain: site.site.domain.split("://")[1] || ""
            },
            returnPath: "/builder/sites/",
            onSave: onUpdateSite,
            organizations:
              !isOrganizationsPending && companiesByUserId
                ? companiesByUserId
                    .concat(userOrganizationsChamber || [])
                    .filter(i => i.id === site.organization)
                : []
          }}
        />
      </div>
    );
  } else {
    return <LoadingSpinner loadingState />;
  }
};

const mapStateToProps = state => ({
  isSitePending: state.sitesReducer.isSitePending,
  areSitesLoaded: state.sitesReducer.areSitesLoaded,
  site: state.sitesReducer.site,
  updatedSiteError: state.sitesReducer.updatedSiteError,
  companiesByUserId: state.companiesReducer.companiesByUserId,
  areCompaniesByUserIdPending:
    state.companiesReducer.areCompaniesByUserIdPending,
  userOrganizationsChamber: state.organizationsReducer.userOrganizationsChamber,
  areUserOrganizationsPending:
    state.organizationsReducer.areUserOrganizationsPending
});

export default connect(mapStateToProps, {
  getSite,
  updateSite,
  getCompaniesByUserId,
  getUserOrganizationsChamber
})(BuilderSite);
