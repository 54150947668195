export default function ordesListComponent({ editor, site, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function calculatePrice(cost = 0, discountPercent = 0, couponPercent = 0) {
      const finnalyDiscountPercent = couponPercent || discountPercent;
      if (!finnalyDiscountPercent) return parseFloat(cost);

      return parseFloat(
        cost - (parseFloat(cost) / 100) * parseFloat(finnalyDiscountPercent)
      );
    }

    function makePaginationHtml(currentPage, totalCount, countPerPage) {
      const totalPagesCount = Math.ceil(totalCount / countPerPage);
      return `
          <div class="order-list-pagination">
            ${
              currentPage - 1
                ? `
                <button class="order-list-pagination__button" data-page="${currentPage -
                  1}">
                <
                </button>
                `
                : `
                <button class="order-list-pagination__button order-list-pagination__button--disabled" disabled>
                <
                </button>
                `
            }
    
            ${
              currentPage - 3 > 0
                ? `
                <button class="order-list-pagination__button" data-page="1">
                1
                </button>
                `
                : ""
            }
    
            ${
              currentPage - 4 > 0
                ? `<span class="order-list-pagination__separator">...</span>`
                : ""
            }
    
            ${
              currentPage - 2 > 0
                ? `
                <button class="order-list-pagination__button" data-page="${currentPage -
                  2}">
                ${currentPage - 2}
                </button>
                `
                : ""
            }
    
            ${
              currentPage - 1 > 0
                ? `
                <button class="order-list-pagination__button" data-page="${currentPage -
                  1}">
                ${currentPage - 1}
                </button>
                `
                : ""
            }
    
            ${`
                <button class="order-list-pagination__button order-list-pagination__button--active" data-page="${currentPage}">
                ${currentPage}
                </button>
            `}
    
            ${
              currentPage + 1 <= totalPagesCount
                ? `
                <button class="order-list-pagination__button" data-page="${currentPage +
                  1}">
                ${currentPage + 1}
                </button>
                `
                : ""
            }
    
            ${
              currentPage + 3 <= totalPagesCount
                ? `<span class="order-list-pagination__separator">...</span>`
                : ""
            }
    
            ${
              currentPage + 2 <= totalPagesCount
                ? `
                <button class="order-list-pagination__button" data-page="${totalPagesCount}">
                ${totalPagesCount}
                </button>
                `
                : ""
            }
    
            ${
              currentPage + 1 <= totalPagesCount
                ? `
                <button class="order-list-pagination__button" data-page="${currentPage +
                  1}">
                >
                </button>
                `
                : `
                <button class="order-list-pagination__button order-list-pagination__button--disabled" disabled>
                >
                </button>
                `
            }
          </div>
          `;
    }

    function makeOrdersTableHtml(items, detailsPath, translations) {
      return `
      <table class="order-list-table">
        <thead class="order-list-table__header">
          <tr>
            <th class="order-list-table__header-title order-list-table__header-title--index">
              ${translations["N"]}
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--item">
              ${translations["PRODUCT"]}
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--name">
              ${translations["NAME"]}
            </th>
            <th class="order-list-table__header-title order-list-table__header-title--total">
              ${translations["COST"]}
            </th>
          </tr>
        </thead>
        <tbody class="order-list-table__main">
        ${
          !items.length
            ? `
            <tr>
              <td colspan="4" style="padding: 1rem;">
                ${translations["Your orders list is currently empty."]}
              </td>
            </tr>`
            : ""
        }
        ${items
          .map((deal, index) =>
            deal.order_items
              .map(
                (orderItem, orderItemIndex) => `
                <tr class="order-list-item">
                ${
                  orderItemIndex === 0
                    ? `
                    <td style="text-align: center;" rowspan="${deal.order_items.length}">
                      <span class="order-list-item__index">${deal.id}</span>
                    </td>
                    `
                    : ""
                }
                  <td>
                    <div class="order-list-item__media">
                      ${
                        orderItem.image
                          ? `<img class="order-list-item__image" src="${orderItem.image}" alt="${orderItem.name} thumbnail" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />`
                          : `<img class="order-list-item__image" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" alt="no image" />`
                      }
                    </div>
                  </td>
                  <td>
                    <div class="order-list-item__main">
                      <span class="order-list-item__capture">
                        ${orderItem.categories
                          .reduce(
                            (tmp, category) => tmp.concat(category.name),
                            []
                          )
                          .join()}
                      </span>
                      <a
                        class="cart-product__link"
                        href="${detailsPath}?gjs-product-id=${
                  orderItem.id
                }&gjs-product-type=${
                  orderItem.content_type === 84 ? "b2b" : "b2c"
                }"
                      >
                        <h4 class="order-list-item__title">${
                          orderItem.name
                        }</h4>
                      </a>
                    </div>          
                  </td>
                  <td>
                  ${
                    deal.currency &&
                    orderItem.unit_price &&
                    parseFloat(orderItem.unit_price) > 0
                      ? `
                        <span class="order-list-item__cost">
                        ${calculatePrice(
                          orderItem.unit_price,
                          orderItem.discount_percent,
                          orderItem.coupon_discount_percent
                        ).toFixed(2)}
                        </span>
                        <span class="order-list-item__currency">
                          ${deal.currency}
                        </span>
                        ${
                          orderItem.discount_percent ||
                          orderItem.coupon_discount_percent
                            ? `
                        <span class="order-list-item__percent-discount">
                          -${(
                            orderItem.discount_percent ||
                            orderItem.coupon_discount_percent
                          ).toFixed(2)}%
                        </span>
                        `
                            : ""
                        }
                      `
                      : `
                      <span class="order-list-item__no-price">
                        ${translations["N/A"]}
                      </span>
                      `
                  }
                  </td>
                </tr>
              `
              )
              .join("")
          )
          .join("")}
        </tbody>
      </table>
      `;
    }

    function setLoading(tableEl, loading) {
      loading
        ? tableEl.classList.add("order-list-preloader")
        : tableEl.classList.remove("order-list-preloader");
    }

    function getDeals(status, page) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      return fetch(
        `${API_ROOT}/payments/deal/?status=${status}&page=${page}&company=${organization}`,
        {
          method: "GET",
          headers: Object.assign(
            { "Accept-Language": localStorage.getItem("language") },
            localStorage.getItem("token")
              ? { Authorization: `Token ${localStorage.getItem("token")}` }
              : {}
          ),
          cors: "cors",
          credentials: "omit"
        }
      );
    }

    function render(incomingPage, outgoingPage, detailsPath, translations) {
      const incomingWrapperEl = this.querySelector(
        ".order-list__section--incoming .order-list__main"
      );
      const outgoingWrapperEl = this.querySelector(
        ".order-list__section--outgoing .order-list__main"
      );

      const incomingFooterEl = this.querySelector(
        ".order-list__section--incoming .order-list__footer"
      );
      const outgoingFooterEl = this.querySelector(
        ".order-list__section--outgoing  .order-list__footer"
      );

      setLoading(incomingWrapperEl, true);
      setLoading(outgoingWrapperEl, true);

      getDeals("draft", incomingPage)
        .then(response => response.json())
        .then(json => {
          if (!json.results.length) {
            incomingWrapperEl.innerHTML = makeOrdersTableHtml(
              [],
              detailsPath,
              translations
            );
            return;
          }

          const items = json.results;

          incomingWrapperEl.innerHTML = makeOrdersTableHtml(
            items,
            detailsPath,
            translations
          );
          incomingFooterEl.innerHTML = makePaginationHtml(
            incomingPage,
            json.count,
            10
          );
          setLoading(incomingWrapperEl, false);

          const paginationButtonsEls = incomingFooterEl.querySelectorAll(
            ".order-list-pagination__button:not(disabled)"
          );

          Array.prototype.slice
            .call(paginationButtonsEls)
            .map(paginationButtonEl =>
              paginationButtonEl.addEventListener("click", e => {
                const btnIncomingPage = parseInt(e.target.dataset.page);
                render.call(
                  this,
                  btnIncomingPage,
                  outgoingPage,
                  detailsPath,
                  translations
                );
              })
            );
        });

      getDeals("ordered", outgoingPage)
        .then(response => response.json())
        .then(json => {
          if (!json.results.length) {
            outgoingWrapperEl.innerHTML = makeOrdersTableHtml(
              [],
              detailsPath,
              translations
            );
            return;
          }

          const items = json.results;

          outgoingWrapperEl.innerHTML = makeOrdersTableHtml(
            items,
            detailsPath,
            translations
          );
          outgoingFooterEl.innerHTML = makePaginationHtml(
            outgoingPage,
            json.count,
            10
          );
          setLoading(outgoingWrapperEl, false);

          const paginationButtonsEls = outgoingFooterEl.querySelectorAll(
            ".order-list-pagination__button:not(disabled)"
          );

          Array.prototype.slice
            .call(paginationButtonsEls)
            .map(paginationButtonEl =>
              paginationButtonEl.addEventListener("click", e => {
                const btnOutgoingPage = parseInt(e.target.dataset.page);
                render.call(
                  this,
                  incomingPage,
                  btnOutgoingPage,
                  detailsPath,
                  translations
                );
              })
            );
        });
    }

    if (!window.hasOwnProperty("grapesjs")) return;
    // Get translation & render
    const translations = getTranslate("translations", this.attributes);
    if (translations && Object.keys(translations).length) {
      this.querySelector(`
        .order-list__title--incoming
      `).innerHTML = translations["Incoming"];
      this.querySelector(`
        .order-list__title--outgoing
      `).innerHTML = translations["Outgoing"];

      const detailsPath = this.attributes.detailspage
        ? this.attributes.detailspage.value
        : "#";

      render.call(this, 1, 1, detailsPath, translations);
    }
  };

  editor.DomComponents.addType("order-list", {
    isComponent: el => el.tagName === "DIV" && el.className === "order-list",
    model: {
      defaults: {
        name: "Order List",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        traits: [
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "Incoming",
              "Outgoing",
              "N",
              "PRODUCT",
              "NAME",
              "COST",
              "Your orders list is currently empty.",
              "N/A"
            ]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
