import "./gjs-page-trait.scss";
import ApiService from "../../../services/api";

export default ({ token, site }) =>
  function gjsPageTrait(editor) {
    let state = {
      site,
      page: {},
      pages: [],
      pagination: {
        total: 1,
        current: 1
      }
    };

    const api = new ApiService({ token });

    const preloader = ({ remove = false }) => {
      // Preloader
      const modalContentEl = document.querySelector(".gjs-mdl-content");
      modalContentEl &&
        modalContentEl.classList.add("gjs-mdl-content--preloader");
      // Remove preloader
      modalContentEl &&
        remove &&
        modalContentEl.classList.remove("gjs-mdl-content--preloader");
    };

    const render = async props => {
      const { modal, selectPage } = props;
      // Set prelodaer
      preloader({ remove: false });
      // Get site pages
      const {
        results: pages,
        count: total,
        next: nextUrl,
        previous: prevUrl
      } = await api.getPages({
        siteId: state.site.id,
        page: state.pagination.current
      });

      const nextParams = new URLSearchParams(
        nextUrl && nextUrl.slice(nextUrl.indexOf("?"))
      );
      const prevParams = new URLSearchParams(
        prevUrl && prevUrl.slice(prevUrl.indexOf("?"))
      );

      const next = parseInt(nextUrl ? nextParams.get("page") || 1 : 0);
      const prev = parseInt(prevUrl ? prevParams.get("page") || 1 : 0);
      // Set pages to state
      state = {
        ...state,
        pages,
        pagination: {
          ...state.pagination,
          total: Math.ceil(total / 10),
          next: parseInt(next) || state.pagination.current,
          prev: parseInt(prev) || state.pagination.current
        }
      };
      // Set modal content
      await modal.setContent(`
        <div class="gjs-page-trait-modal__content">
          <div class="gjs-mdl-title">
            List of pages ${state.pagination.current}/${state.pagination.total}
          </div>
            <div class="gjs-page-trait-list">
            ${state.pages
              .map(
                ({ id, title, path }) => `
                <div class="gjs-page-trait-list__item">
                    <h5 class="gjs-page-trait-list__item-text" data-id="${id}" data-title="${title}" data-path="${path}">
                      ${title}
                      <span class="gjs-page-trait-list__item-capture">
                      [id:${id}]
                      </span>
                    </h5>
                    <h5 class="gjs-page-trait-list__item-text" data-id="${id}" data-title="${title}" data-path="${path}">
                      ${path}
                    </h5>
                    <div class="gls-page-trait-list__item-actions">
                        <button type="button" id="select_page_button" class="gjs-page-trait-modal__button gjs-page-trait-modal__button-sm gjs-two-color" data-id="${id}" data-title="${title}" data-path="${path}">
                            <i class="fa fa-check"></i>
                        </button>
                    </div>
                </div>
                `
              )
              .join("")}
            </div>
          </div>
        </div>
        <div class="gjs-page-trait-modal__footer">
          <div class="gjs-page-trait-modal__pagination">
            <button
              type="button"
              id="pagination_prev_page_button"
              class="gjs-page-trait-modal__button gjs-page-trait-modal__button-sm gjs-two-color"
              ${
                state.pagination.current === state.pagination.prev
                  ? "disabled"
                  : ""
              }
            >
              <i class="fa fa-angle-left"></i>
            </button>
            <button
              type="button"
              id="pagination_next_page_button"
              class="gjs-page-trait-modal__button gjs-page-trait-modal__button-sm gjs-two-color"
              ${
                state.pagination.current === state.pagination.next
                  ? "disabled"
                  : ""
              }
            >
              <i class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        `);

      // Delete buttons handler
      const selectPageButtonEls = document.querySelectorAll(
        "#select_page_button"
      );
      selectPageButtonEls &&
        [...selectPageButtonEls].map(selectPageButtonEl =>
          selectPageButtonEl.addEventListener("click", async e => {
            // Remove prelodaer
            preloader({ remove: false });
            // Get target id and set selected page
            const target =
              e.target.parentNode.nodeName === "BUTTON"
                ? e.target.parentNode
                : e.target;
            const id = parseInt(target.dataset.id);
            state = {
              ...state,
              page: state.pages.find(page => page.id === id)
            };

            selectPage(state.page);
          })
        );

      const pageButtonEls = document.querySelectorAll(
        ".gjs-page-trait-list__item-text"
      );
      pageButtonEls &&
        [...pageButtonEls].map(pageButtonEl =>
          pageButtonEl.addEventListener("click", async e =>
            selectPage({
              id: e.target.dataset.id,
              title: e.target.dataset.title,
              path: e.target.dataset.path
            })
          )
        );

      const nextBtnEl = document.querySelector(
        "#pagination_next_page_button:not(disabled)"
      );
      const prevBtnEl = document.querySelector(
        "#pagination_prev_page_button:not(disabled)"
      );

      nextBtnEl.addEventListener("click", () => {
        state.pagination = {
          ...state.pagination,
          current: state.pagination.next
        };
        render(props);
      });
      prevBtnEl.addEventListener("click", () => {
        state.pagination = {
          ...state.pagination,
          current: state.pagination.prev
        };
        render(props);
      });
      // Remove prelodaer
      preloader({ remove: true });
    };

    editor.TraitManager.addType("page-selector", {
      createInput({ component, trait }) {
        const el = document.createElement("div");
        const name = trait.attributes.name;
        const hasValue = component.attributes.attributes.hasOwnProperty(name);
        // Create element input
        el.innerHTML = `
          <input
            class="page-selector"
            placeholder="Select page"
            value="${hasValue ? component.attributes.attributes[name] : ""}"
          />
        `;
        // Opens modal window with pages after clicking on element
        el.addEventListener("click", () => {
          // Get modal
          this.modal = editor.Modal;
          // Set modal title
          this.modal.setTitle(trait.attributes.modalTitle);
          // Reset state
          state = {
            site,
            page: {},
            pages: [],
            pagination: {
              total: 1,
              current: 1
            }
          };
          // Start render modal content
          render({
            modal: this.modal,
            selectPage: this.selectPage.bind(this)
          });
          // Open modal
          this.modal.open();
        });
        // Return element
        return el;
      },
      selectPage(page) {
        // Set page
        this.page = page;
        // Use onChange to trigger onEvent
        this.onChange();
      },
      onEvent({ component, trait, elInput }) {
        // Set component attribute
        component.addAttributes({ [trait.attributes.name]: this.page.path });
        // Set element value
        elInput.querySelector("input").value = this.page.path;
        // Close pages modal
        this.modal.close();
      }
    });
  };
