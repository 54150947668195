import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";

import { tabsData } from "./cart-tabs.config";

import "./cart-tabs.styles.scss";

const CartTabs = ({ appDirection }) => {
  const { url } = useRouteMatch();
  const { location } = useHistory();

  return (
    <div className={`cart__basket-tabs ${appDirection}`}>
      {tabsData.map(({ title, icon, tabUrl }, index) => (
        <Fragment key={index}>
          <NavLink
            to={tabUrl ? `${url}/${tabUrl}` : "/cart"}
            className={`cart__basket-tabs__nav ${
              location && location.pathname.includes(tabUrl)
                ? "cart__basket-tabs__nav__tab-active"
                : ""
            }`}
          >
            <div className="cart__basket-tabs__nav__icon">{icon}</div>
            <div className="cart__basket-tabs__nav__name">{title}</div>
          </NavLink>
        </Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection
});

export default connect(mapStateToProps, null)(CartTabs);
