import style from "./block-style";

const productWithPaginationContent = (attributes = "") => `
${style}
<div class="products-with-pagination-wrapper" ${attributes}>
  <div class="products-with-pagination">
    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost pwp-product__cost--discount">100.00</span>
        <span class="pwp-product__discount">50.00</span>
        <span class="pwp-product__currency">$</span>
        <span class="pwp-product__discount-percent">-50.00%</span>
      </p>
      
      <div class="pwp-coupon">
        <div class="pwp-coupon__header">
          <span class="pwp-coupon__cost">
            200.00
          </span>
          <span class="pwp-coupon__currency">
            $
          </span>
          <span class="pwp-coupon__percent-discount">
            -80%
          </span>
          <span class="pwp-coupon__message">
            Hurry up! Coupon Ends in
          </span>
        </div>
        <div class="pwp-coupon__grid" style="visibility: none;">
          <span class="pwp-coupon__item">
            <span class="pwp-coupon__counter pwp-coupon__counter--days">
              000
            </span>
            <span class="pwp-coupon__label pwp-coupon__label--days">
              Days
            </span>
          </span>
          <span class="pwp-coupon__delimiter">
            :
          </span>
          <span class="pwp-coupon__item">
            <span class="pwp-coupon__counter pwp-coupon__counter--hours">
              00
            </span>
            <span class="pwp-coupon__label pwp-coupon__label--hours">
              Hours
            </span>
          </span>
          <span class="pwp-coupon__delimiter">
            :
          </span>
          <span class="pwp-coupon__item">
            <span class="pwp-coupon__counter pwp-coupon__counter--minutes">
              00
            </span>
            <span class="pwp-coupon__label pwp-coupon__label--minutes">
              Minutes
            </span>
          </span>
          <span class="pwp-coupon__delimiter">
            :
          </span>
          <span class="pwp-coupon__item">
            <span class="pwp-coupon__counter pwp-coupon__counter--seconds">
              00
            </span>
            <span class="pwp-coupon__label pwp-coupon__label--seconds">
              Seconds
            </span>
          </span>
        </div>
      </div>
      
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        Praesent condimentum nisi leo. Etiam varius, massa vel rhoncus interdum, felis nunc ullamcorper dolor, egestas accumsan tellus elit ac ipsum.
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">10.00</span>
        <span class="pwp-product__currency">$</span>
      </p>
      <div class="pwp-product__actions">
        <a class="pwp-product__link pwp-product__link--button" href="#">
          <button class="pwp-product__button">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>

    <div class="pwp-product">
      <img class="pwp-product__image" src="null">
      <h4 class="pwp-product__title">
        <a class="pwp-product__link" href="#">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </a>
      </h4>
      <span class="pwp-product__divider"></span>
      <div class="pwp-product__description">
        No description
      </div>
      <p class="pwp-product__meta pwp-product__meta--cost">
        <span class="pwp-product__cost-title">Cost:</span>
        <span class="pwp-product__cost">N/A</span>
      </p>
      <div class="pwp-product__actions">
        <button class="pwp-product__button pwp-product__button--disabled">
          <a class="pwp-product__link pwp-product__link--button" href="#">
            VIEW
          </button>
        </a>
        <span class="pwp-product__wish pwp-product__wish--disabled">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="45px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>
        </span>
      </div>
    </div>
  
    <div class="pwp-pagination">
      <button class="pwp-pagination__button pwp-pagination__button--disabled" disabled>
        &lt;
      </button>
      <button class="pwp-pagination__button pwp-pagination__button--active">
        1
      </button>
      <button class="pwp-pagination__button">
        2
      </button>
      <span class="pwp-pagination__separator">
        ...
      </span>
      <button class="pwp-pagination__button">
        100
      </button>
      <button class="pwp-pagination__button">
        &gt;
      </button>
    </div>
  </div>
</div>
`;

export default productWithPaginationContent;
