import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import Separator from "../separator/separator.components";
import { missingImage } from "../../assets";

const CompanyLeadsInfo = ({ key, typeOfCompany, currentCompany }) => {
  const { id, name, logo } = currentCompany;

  const { url } = useRouteMatch();

  return (
    <div key={key} className={`company-department-info`}>
      <Separator />
      <div className="company-department-info__companies">
        <div className="companies__inner-content">
          <div className="companies__inner-content__company">
            <div className="companies__inner-content__company__image">
              <img
                className="company-img"
                src={logo ? logo : missingImage}
                alt=""
              />
            </div>
            <div className="companies__inner-content__company__name">
              <Link
                to={`${
                  typeOfCompany === "Company"
                    ? `${url}/c/${id}`
                    : `${url}/o/${id}`
                }`}
              >
                {name}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyLeadsInfo;
