import { useState } from "react";

export const withDebounce = (func, wait = 750, immediate) => {
  let timeout;
  return function() {
    let context = this,
      args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const randomInt = (min = 0, max = Number.MAX_SAFE_INTEGER) =>
  Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) +
  Math.ceil(min);

export const arrToString = array => array.map(({ id }) => id).join(",");
export const scrollToRef = location => window.scrollTo(0, location);

export const handleMouseMove = (e, callback) => {
  const { left, top, width, height } = e.target.getBoundingClientRect();
  const x = ((e.pageX - left) / width) * 100;
  const y = ((e.pageY - top) / height) * 100;
  return withDebounce(callback(`${x}% ${y}%`));
};

export const returnCountryName = countryName => {
  switch (countryName) {
    case "United States":
      return "USA";
    case "Republic of Moldova":
      return "Moldova";
    default:
      return countryName;
  }
};

export function getBase64Image(src, callback) {
  var xhttp = new XMLHttpRequest();

  xhttp.onload = function() {
    var fileReader = new FileReader();
    fileReader.onload = function() {
      callback(fileReader.result);
    };
    fileReader.readAsDataURL(xhttp.response);
  };

  xhttp.responseType = "blob";
  xhttp.withCredentials = true;
  xhttp.open("GET", src, true);
  xhttp.send(null);
}

export const vCard = function() {
  var start = "BEGIN:VCARD";
  var end = "END:VCARD";
  var data = "";

  var init = function() {
    data = "";
  };

  var name = function(surname, lastname) {
    data += "N:" + lastname + ";" + surname;
    data += "\n";
  };

  var firstName = function(surname, lastname) {
    data += "FN:" + lastname + " " + surname;
    data += "\n";
  };

  var email = function(email) {
    data += "EMAIL;TYPE=PREF,INTERNET:" + email;
    data += "\n";
  };

  var org = function(org) {
    data += "ORG:" + org;
    data += "\n";
  };

  var telMobile = function(telMobile) {
    data += "TEL;type=CELL;type=VOICE;type=pref:" + telMobile;
    data += "\n";
  };

  var telFax = function(telFax) {
    data += "TEL;FAX;WORK:" + telFax;
    data += "\n";
  };

  var telWork = function(telWork) {
    data += "TEL;WORK:" + telWork;
    data += "\n";
  };

  var title = function(title) {
    data += "TITLE:" + title;
    data += "\n";
  };

  var urlWork = function(urlWork) {
    data += "URL;WORK:" + urlWork;
    data += "\n";
  };

  var urlBusinessCard = function(urlBusinessCard) {
    data += "URL:" + urlBusinessCard;
    data += "\n";
  };

  var getCardImage = function(urlImg) {
    getBase64Image(urlImg, function(rez) {
      return (data += "PHOTO;ENCODING=b;TYPE=JPEG:" + rez);
    });
  };

  var getCard = function() {
    return start + "\n" + data + end;
  };

  return {
    init: init,
    name: name,
    firstName: firstName,
    email: email,
    org: org,
    telMobile: telMobile,
    telFax: telFax,
    telWork: telWork,
    title: title,
    urlWork: urlWork,
    urlBusinessCard: urlBusinessCard,
    getCard: getCard,
    getCardImage: getCardImage
  };
};

export const downloadBusinessCard = (profile, company) => {
  const createVCard = vCard();

  createVCard.name(profile.first_name, profile.last_name);
  createVCard.firstName(profile.last_name, profile.first_name);
  createVCard.email("");
  createVCard.org(company.name);
  createVCard.telMobile(profile.mobile_number);
  createVCard.telFax(profile.mobile_number);
  createVCard.telWork(profile.mobile_number);
  createVCard.title(profile.profession);
  createVCard.urlWork(profile.site || "");
  createVCard.urlBusinessCard(window.location.href);
  createVCard.getCardImage(profile.avatar);

  // const downloading = new Promise(function(resolve, reject) {
  //   setTimeout(() => {
  //     const blob = new Blob([createVCard.getCard()], {
  //       type: "text/vcard;charset=utf-8"
  //     });
  //     resolve(
  //       FileSaver.saveAs(blob, `${profile.first_name} ${profile.last_name}`)
  //     );
  //     reject(new Error("…")); // ignored
  //   }, 2000);
  // });
};

export const returnDays = end => {
  const diff = Math.abs(new Date(end) - new Date());
  return Math.floor(diff / 86400000);
};

export const returnHours = end => {
  const diff = Math.abs(new Date(end) - new Date());
  return Math.floor((diff % 86400000) / 3600000);
};

export const returnMinutes = end => {
  const diff = Math.round(new Date(end) - new Date());
  return Math.floor(((diff % 86400000) % 3600000) / 60000);
};

export const returnSeconds = end => {
  const diff = Math.abs(new Date(end) - new Date());
  return Math.floor((((diff % 86400000) % 3600000) / 1000) % 60);
};
