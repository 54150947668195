import { InnovationProjectsActionTypes } from "./innovation-projects.types";
import {
  axiosNoTokenInstance,
  axiosInstance
} from "@core/axios/axios.config";

export const getInnovationProjects = (
  search = "",
  page = 1,
  countries = "",
  organizations = "",
  branches = ""
) => dispatch => {
  dispatch({
    type: InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECTS_PENDING
  });
  axiosNoTokenInstance
    .get(
      `/api/v1/innovation_projects/?` +
        `search=${search}` +
        `&page=${page}` +
        `&organization__countries=${countries}` +
        `&organization=${organizations}` +
        `&branches=${branches}`
    )
    .then(innovationProjects =>
      dispatch({
        type: InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECTS_SUCCESS,
        payload: {
          count: innovationProjects.data.count,
          innovationProjects: innovationProjects.data.results
        }
      })
    )
    .catch(error =>
      dispatch({
        type: InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECTS_ERROR,
        payload: error
      })
    );
};

export const getInnovationProjectById = innovationProjectId => dispatch => {
  dispatch({
    type: InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECT_BY_ID_PENDING
  });
  axiosNoTokenInstance(
    `/api/v1/innovation_projects/${innovationProjectId}/`
  )
    .then(innovationProjectById =>
      dispatch({
        type:
          InnovationProjectsActionTypes.GET
            .GET_INNOVATION_PROJECT_BY_ID_SUCCESS,
        payload: innovationProjectById.data
      })
    )
    .catch(error =>
      dispatch({
        type:
          InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECT_BY_ID_ERROR,
        payload: error
      })
    );
};

export const getInnovationProjectsByCurrentUser = (
  search = "",
  page = 1
) => dispatch => {
  dispatch({
    type:
      InnovationProjectsActionTypes.GET
        .GET_INNOVATION_PROJECTS_BY_CURRENT_USER_PENDING
  });

  axiosInstance
    .get(`/api/v1/auth/users/me/`)
    .then(({ data }) =>
      axiosInstance.get(
        `/api/v1/innovation_projects/manageable/?search=${search}&created_by=${data.id}&page=${page}`
      )
    )
    .then(innovationProjects => {
      dispatch({
        type:
          InnovationProjectsActionTypes.GET
            .GET_INNOVATION_PROJECTS_BY_CURRENT_USER_SUCCESS,
        payload: {
          inProjects: innovationProjects.data.results,
          count: innovationProjects.data.count
        }
      });
    })
    .catch(err => {
      dispatch({
        type:
          InnovationProjectsActionTypes.GET
            .GET_INNOVATION_PROJECTS_BY_CURRENT_USER_ERROR,
        payload: err
      });
    });
};

export const postInnovationProjects = (
  name,
  description,
  product_name,
  business_plan,
  branches,
  organization,
  cost,
  currency,
  keywords,
  site,
  release_date
) => dispatch => {
  dispatch({
    type: InnovationProjectsActionTypes.POST.POST_INNOVATION_PROJECTS_PENDING
  });
  axiosInstance
    .post(`/api/v1/innovation_projects/manageable/`, {
      name,
      description,
      product_name,
      business_plan,
      branches,
      organization,
      cost,
      currency,
      keywords,
      metadata: { site, release_date }
    })
    .then(innovationProject => {
      dispatch({
        type:
          InnovationProjectsActionTypes.POST.POST_INNOVATION_PROJECTS_SUCCESS,
        payload: {
          post: innovationProject.data,
          status: innovationProject.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: InnovationProjectsActionTypes.POST.POST_INNOVATION_PROJECTS_ERROR,
        payload: err
      });
    });
};

export const putInnovationProjects = (
  innovationId,
  name,
  description,
  product_name,
  business_plan,
  branches,
  organization,
  cost,
  currency,
  keywords,
  site,
  release_date
) => dispatch => {
  dispatch({
    type: InnovationProjectsActionTypes.PUT.PUT_INNOVATION_PROJECTS_PENDING
  });
  axiosInstance
    .put(`/api/v1/innovation_projects/manageable/${innovationId}/`, {
      name,
      description,
      product_name,
      business_plan,
      branches,
      organization,
      cost,
      currency,
      keywords,
      metadata: { site, release_date }
    })
    .then(innovationProject => {
      dispatch({
        type: InnovationProjectsActionTypes.PUT.PUT_INNOVATION_PROJECTS_SUCCESS,
        payload: {
          post: innovationProject.data,
          status: innovationProject.status
        }
      });
    })
    .catch(err => {
      dispatch({
        type: InnovationProjectsActionTypes.PUT.PUT_INNOVATION_PROJECTS_ERROR,
        payload: err
      });
    });
};

export const deleteInnovationProjectById = innovationId => dispatch => {
  dispatch({
    type:
      InnovationProjectsActionTypes.DELETE.DELETE_INNOVATION_PROJECTS_PENDING
  });
  axiosInstance
    .delete(`/api/v1/innovation_projects/manageable/${innovationId}/`)
    .then(deletedInnovation => {
      dispatch({
        type:
          InnovationProjectsActionTypes.DELETE
            .DELETE_INNOVATION_PROJECTS_SUCCESS,
        payload: deletedInnovation.data
      });
    })
    .then(() => dispatch(getInnovationProjectsByCurrentUser()))
    .catch(err => {
      dispatch({
        type:
          InnovationProjectsActionTypes.DELETE.DELETE_INNOVATION_PROJECTS_ERROR,
        payload: err
      });
    });
};

export const clearInnovationProjectsRequestStatus = () => ({
  type: InnovationProjectsActionTypes.CLEAR_INNOVATION_PROJECTS_REQUEST,
  payload: 0
});
