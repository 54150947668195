export default function accessibilityWidgetComponent({ editor, api, site }) {
  const script = function() {
    if (!window.hasOwnProperty("grapesjs")) {
      return;
    }

    if (!window.hasOwnProperty("_userway_config")) {
      window._userway_config = {
        size: "small",
        position: "6"
      };

      const widget = document.createElement("script");
      widget.src = "//accessibilityserver.org/widget.js";

      document.body.append(widget);
    }
  };

  editor.DomComponents.addType("accessibility-widget", {
    isComponent: el =>
      el.tagName === "div" && el.className === "gjs-accessibility-widget",
    model: {
      defaults: {
        name: "Accessibility Widget",
        draggable: false,
        doppable: false,
        removable: false,
        copyable: false,
        script
      }
    }
  });

  editor.on(
    "storage:end",
    () =>
      !editor
        .getComponents()
        .models.find(
          model =>
            model.attributes && model.attributes.type === "accessibility-widget"
        ) &&
      editor.addComponents({
        type: "accessibility-widget"
      })
  );
}
