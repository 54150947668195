import React from "react";
import { Link } from "react-router-dom";

import RegisterForm from "../register-form/register-form.component";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { ReactComponent as MessangerIcon } from "../../assets/messanger-icon.svg";
import { ReactComponent as GooglePlusIcon } from "../../assets/google-plus-icon.svg";

import DefaultModal from "../default-modal/default-modal.component";

import "./register-modal.styles.scss";

const RegisterModal = ({
  openRegModal,
  handleOpenRegModal,
  handleCloseRegModal
}) => {
  const RegistrationModalBody = () => {
    return (
      <div className="registration-modal">
        <div className="registration-modal__header">
          <h2>Registration</h2>
          <IconButton disableRipple onClick={handleCloseRegModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="registration-modal__body">
          <div
            style={{ paddingRight: "45px" }}
            className="registration-modal__body-column"
          >
            <RegisterForm />
          </div>

          <div className="registration-modal__body-column">
            <p>Sign in via social media</p>
            <ul>
              <li>
                <div className="registration-modal__body-column__social-media">
                  <FacebookIcon style={{ color: "#1976d2" }} />
                </div>
              </li>
              <li>
                <div className="registration-modal__body-column__social-media">
                  <GooglePlusIcon />
                </div>
              </li>
              <li>
                <div className="registration-modal__body-column__social-media">
                  <LinkedInIcon style={{ color: "#1976d2" }} />
                </div>
              </li>
              <li>
                <div className="registration-modal__body-column__social-media">
                  <MessangerIcon />
                </div>
              </li>
            </ul>
            <p className="registration-modal__body-column__agreement">
              By clicking the Sign in button, you accept the terms of the{" "}
              <Link to="/">User agreement</Link>
            </p>
          </div>
        </div>
        <div className="registration-modal__footer">
          <Link to="/signin">I'm already registered</Link>
        </div>
      </div>
    );
  };
  return (
    <DefaultModal
      handleClose={handleCloseRegModal}
      open={openRegModal}
      modalBody={<RegistrationModalBody />}
    />
  );
};

export default RegisterModal;
