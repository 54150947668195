import React from "react";
import { Link } from "react-router-dom";

import DefaultButton from "../../../components/default-button/default-button.component";

import { colorPalette } from "../../../styles/colorPalette";

import "./my-business-add-bar.styles.scss";

const MyBusinessAddBar = ({ title, link, onClickEvent = null }) => {
  return (
    <div className="my-business-add-bar">
      <div className="my-business-add-bar__title">{title}</div>
      {link && (
        <div className="my-business-add-bar__button">
          <Link to={link}>
            <DefaultButton
              color="white"
              backgroundColor={colorPalette.colorPrimary.main}
              hover={colorPalette.colorPrimary.darker}
              fontSize="15px"
              labelHeight="20px"
              height="100%"
              width="100%"
              onClick={onClickEvent}
            >
              Add +
            </DefaultButton>
          </Link>
        </div>
      )}
    </div>
  );
};

export default MyBusinessAddBar;
