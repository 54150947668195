import icon from "./icon.png";

export default function dropdownBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .${className} {
    width: 240px;
  }

  .${className} .${className}__button {
    min-width: 100px;
    width: auto;
    border: none;
    padding: 10px 20px;
    background: #e4e4e4;
    cursor: pointer;
    outline: none;
  }

  .${className} .${className}__trigger {
    width: min-content;
  }

  .${className} .${className}__content {
    position: absolute;
    width: min-content;
    min-width: 240px;
    background: #ffffff;
    padding: 8px 10px;
    margin: 6px 0 0;
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
    transition: all 300ms ease 0s;
    overflow-y: auto;
    visibility: hidden;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="${className}">
    <div class="${className}__trigger">
      <button type="button" class="${className}__button">
        <span>Dropdown</span>
      </button>
    </div>

    <div class="${className}__content">
      <p>Dropdown content</p>
    </div>
  </div>
  `;

  blockManager.add("dropdown", {
    label: `
      <img src="${icon}" alt="Dropdown block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Dropdown
      </div>
    `,
    content,
    category: "B24Online Extra"
  });

  // Make dropdown classes private
  const dropdownClasses = [
    `.${className}`,
    `.${className}__trigger`,
    `.${className}__button`,
    `.${className}__content`
  ];
  editor.on(
    "selector:add",
    selector =>
      dropdownClasses.indexOf(selector.getFullName()) >= 0 &&
      selector.set("private", 1)
  );
}
