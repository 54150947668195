import React, { useState, useEffect } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "react-calendar/dist/Calendar.css";
import "./default-date-range-picker.styles.scss";

const DefaultDateRangePicker = ({
  returnStartDate,
  returnEndDate,
  startDate,
  endDate
}) => {
  const [date, setDate] = useState([new Date(), new Date()]);

  useEffect(() => {
    if (startDate && endDate && startDate.length && endDate.length) {
      setDate([startDate, endDate]);
    }
    if (startDate === "" && endDate === "") {
      setDate([new Date(), new Date()]);
    }
  }, [startDate, endDate]);

  return (
    <DateRangePicker
      format="dd.MM.yyyy"
      calendarIcon={<ExpandMoreIcon />}
      clearIcon={null}
      onChange={e => {
        setDate(e);
        returnStartDate(e[0]);
        returnEndDate(e[1]);
      }}
      value={date}
    />
  );
};

export default DefaultDateRangePicker;
