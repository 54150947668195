import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import { withDebounce } from "@core/shared";
import {
  ProfileMedia,
  ProfileSocialLinks,
  ProfileBusinessCard
} from "../../components";

import {
  LoadingSpinner,
  DefaultButton,
  ProfileBasicInfo,
  ProfileCardPreview
} from "@core/components";

import {
  searchCountry,
  postProfile,
  putProfile,
  getProfile,
  getUser,
  getCompaniesByUserId,
  clearProfileRequestStatus
} from "../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../styles/buttons-styles";
import { colorPalette } from "../../styles/colorPalette";
import "./profile.styles.scss";

const Profile = ({
  searchCountry,
  countries,

  isProfileAPIPending,

  postProfile,
  putProfile,
  getProfile,

  profileAPI,

  lastName,
  firstName,
  avatar,
  birthday,
  phoneNumber,
  country,
  website,
  profession,
  profileType,
  sex,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit,
  businessCardType,

  getUser,
  user,

  getCompaniesByUserId,
  companiesByUserId,

  activeCompanyId,
  profileRequestStatus,
  clearProfileRequestStatus
}) => {
  const history = useHistory();

  const [countrySearch, setCountrySearch] = useState("");
  const [companySearch, setCompanySearch] = useState("");

  useEffect(() => {
    searchCountry(countrySearch);
  }, [searchCountry, countrySearch]);

  useEffect(() => {
    getCompaniesByUserId(companySearch);
  }, [companySearch, getCompaniesByUserId]);

  useEffect(() => {
    getProfile();
    getUser();
  }, [getProfile, getUser]);

  useEffect(() => {
    if (profileRequestStatus === 200 || profileRequestStatus === 201) {
      history.push("/profile");
      clearProfileRequestStatus();
    }
  }, [profileRequestStatus]); // eslint-disable-line

  if (isProfileAPIPending) {
    return (
      <div className="profile">
        <LoadingSpinner loadingState />
      </div>
    );
  }

  const profilePostHandle = () => {
    if (profileAPI) {
      putProfile(
        profileAPI.id,
        firstName,
        lastName,
        phoneNumber,
        website,
        profession,
        country,
        moment(birthday).format("YYYY-MM-DD"),
        sex,
        profileType,
        facebook,
        linkedin,
        twitter,
        instagram,
        youtube,
        reddit,
        businessCardType,
        activeCompanyId,
        avatar
      );
    } else {
      postProfile(
        firstName,
        lastName,
        phoneNumber,
        website,
        profession,
        country,
        moment(birthday).format("YYYY-MM-DD"),
        sex,
        profileType,
        facebook,
        linkedin,
        twitter,
        instagram,
        youtube,
        reddit,
        businessCardType,
        activeCompanyId,
        avatar
      );
    }
  };

  const setCountrySearchHandler = withDebounce(e => setCountrySearch(e));
  const setCompanySearchHandler = withDebounce(e => setCompanySearch(e));

  return (
    <div className="profile">
      <div className="profile__flex">
        <div className="profile__flex-card">
          <ProfileMedia />
          <ProfileCardPreview
            cardType={
              profileAPI &&
              profileAPI.metadata &&
              profileAPI.metadata.business_card
            }
            userId={user.id}
          />
        </div>
        <div className="profile__flex__separator-vertical" />
        <div className="profile__flex__grow">
          {!isProfileAPIPending && (
            <ProfileBasicInfo
              companies={companiesByUserId}
              countries={countries}
              setCountrySearchHandler={setCountrySearchHandler}
              setCompanySearchHandler={setCompanySearchHandler}
            />
          )}
          <div className="profile__flex__grow__builder">
            <h5>DON'T HAVE A SITE?</h5>
            <div>
              <Link to="/builder/sites/create">
                <DefaultButton
                  color={colorPalette.colorPrimary.main}
                  hover={colorPalette.colorPrimary.main}
                  hoverColor="white"
                  width="100%"
                  border={`1px solid ${colorPalette.colorPrimary.main}`}
                >
                  Сreate your own WebSite
                </DefaultButton>
              </Link>
            </div>
            <div>
              <Link to="/builder/sites/">
                <DefaultButton
                  color={colorPalette.colorPrimary.main}
                  hover={colorPalette.colorPrimary.main}
                  hoverColor="white"
                  width="100%"
                  border={`1px solid ${colorPalette.colorPrimary.main}`}
                >
                  My Websites
                </DefaultButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="profile__flex__separator-horizontal" />
      {!isProfileAPIPending && <ProfileSocialLinks />}
      <ProfileBusinessCard profile={profileAPI} />
      <div className="profile__flex__separator-horizontal" />
      <div className="profile__footer">
        <div className="profile__footer__btns">
          <DefaultButton
            onClick={() => profilePostHandle()}
            {...buttonOk}
            border={`1px solid ${colorPalette.colorPrimary.main}`}
            width="100px"
          >
            Save
          </DefaultButton>
          <DefaultButton
            {...buttonCancel}
            border={`1px solid ${colorPalette.colorPrimary.dangerRed}`}
            width="100px"
          >
            Cancel
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  countries: state.countriesReducer.countries.results,

  isProfileAPIPending: state.profileAPI.isProfilePending,
  profileAPI: state.profileAPI.profile,

  lastName: state.profile.lastName,
  firstName: state.profile.firstName,
  birthday: state.profile.birthday,
  phoneNumber: state.profile.phoneNumber,
  country: state.profile.country,
  website: state.profile.website,
  profession: state.profile.profession,
  profileType: state.profile.profileType,
  sex: state.profile.sex,
  companyName: state.profile.companyName,
  companySlogan: state.profile.companySlogan,
  companyPhone: state.profile.companyPhone,
  companyFax: state.profile.companyFax,
  address: state.profile.address,
  companyDescription: state.profile.companyDescription,
  facebook: state.profile.facebook,
  linkedin: state.profile.linkedin,
  twitter: state.profile.twitter,
  instagram: state.profile.instagram,
  youtube: state.profile.youtube,
  reddit: state.profile.reddit,
  avatar: state.profile.avatar,
  businessCardType: state.profile.businessCardType,
  activeCompanyId: state.profile.activeCompanyId,

  user: state.user.user,

  companiesByUserId: state.companiesReducer.companiesByUserId,
  profileRequestStatus: state.profileAPI.profileRequestStatus
});

export default connect(mapStateToProps, {
  searchCountry,

  postProfile,
  putProfile,
  getProfile,

  getUser,

  getCompaniesByUserId,
  clearProfileRequestStatus
})(Profile);
