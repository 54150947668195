import React from "react";

import "./job-details-body.styles.scss";

const JobDetailsDescription = ({ title, data }) => {
  return (
    <div className="job-details-body">
      <div className="job-details-body__title">{title}</div>
      <div className="job-details-body__text">{data}</div>
    </div>
  );
};

export default JobDetailsDescription;
