export default function videoComponent({ editor, api, className }) {
  editor.DomComponents.addType("video", {
    extendFn: ["updateTraits"],
    model: {
      init() {
        this.addMutedTrait();
      },

      updateTraits() {
        this.addMutedTrait();
      },

      addMutedTrait() {
        setTimeout(() => {
          // TODO: убрать setTimeout, должен быть event init
          if (!this.getTrait("muted")) {
            this.addTrait({
              type: "checkbox",
              name: "muted"
            });
          }
        }, 500);
      }
    }
  });
}
