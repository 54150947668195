export default function cartCheckout({ editor, site, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function calculatePrice(cost = 0, discountPercent = 0, couponPercent = 0) {
      const finnalyDiscountPercent = couponPercent || discountPercent;
      if (!finnalyDiscountPercent) return parseFloat(cost);

      return parseFloat(
        cost - (parseFloat(cost) / 100) * parseFloat(finnalyDiscountPercent)
      );
    }

    function makeDealProductTableHtml(
      products,
      currency,
      detailsPath,
      translations
    ) {
      return `
          <table class="cart-table">
            <thead class="cart-table__header">
              <tr>
                <th class="cart-table__header-title cart-table__header-title--product">
                  ${translations["PRODUCT"]}
                </th>
                <th class="cart-table__header-title cart-table__header-title--price">
                  ${translations["PRICE"]}
                </th>
                <th class="cart-table__header-title cart-table__header-title--quantity">
                  ${translations["QUANTITY"]}
                </th>
                <th class="cart-table__header-title cart-table__header-title--total">
                  ${translations["TOTAL"]}
                </th>
              </tr>
            </thead>
            <tbody>
            ${products.reduce(
              (tmp, product) => `${tmp}
              <tr>
                <td>
                  <div class="cart-product">
                    <div class="cart-product__media">
                      <img
                        class="cart-product__image"
                        src="${product.image}"
                        alt="${product.name} thumbnail"
                        onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
                      />
                    </div>
                    <div class="cart-product__main">
                      <span class="cart-product__capture">
                      ${product.categories
                        .reduce(
                          (tmp, category) => tmp.concat(category.name),
                          []
                        )
                        .join()}
                      </span>
                      <h4 class="cart-product__title">
                        <a
                          class="cart-product__link"
                          href="${detailsPath}?gjs-product-id=${
                product.id
              }&gjs-product-type=${
                product.content_type === 84 ? "b2b" : "b2c"
              }">
                          ${product.name}
                        </a>
                      </h4>
                    </div>          
                  </div>
                </td>
                <td>
                ${
                  currency &&
                  product.unit_price &&
                  parseFloat(product.unit_price) > 0
                    ? `
                      <span class="cart-product__cost">
                      ${calculatePrice(
                        product.unit_price,
                        product.discount_percent,
                        product.coupon_discount_percent
                      ).toFixed(2)}
                      </span>
                      <span class="cart-product__currency">
                        ${currency}
                      </span>
                      ${
                        product.discount_percent ||
                        (product.coupon_discount_percent &&
                          product.metadata.coupon_start_date &&
                          new Date().getTime() -
                            new Date(
                              product.metadata.coupon_start_date
                            ).getTime() >=
                            0 &&
                          product.metadata.coupon_end_date &&
                          new Date(product.metadata.coupon_end_date).getTime() -
                            new Date().getTime() >=
                            0)
                          ? `
                      <span class="cart-product__percent-discount">
                        -${(
                          product.coupon_discount_percent ||
                          product.discount_percent
                        ).toFixed(2)}%
                      </span>
                      `
                          : ""
                      }
                    `
                    : `
                    <span class="cart-product__no-price">
                      ${translations["N/A"]}
                    </span>
                    `
                }
                </td>
                <td>
                  <span class="cart-product__quantity">
                    ${product.quantity}
                  </span>
                </td>
                <td>
                  <span class="cart-product__price cart-product__price--total">
                  ${
                    currency &&
                    product.unit_price &&
                    parseFloat(product.unit_price) > 0
                      ? // TODO: !== или привестки к общим типам
                        product.total_discount !== 0 // eslint-disable-line
                        ? `
                   <div class="cart-product__cost-container">
                      <span class="cart-product__cost cart-product__cost--total cart-product__cost--total-new">
                        ${product.total}
                        ${currency}
                      </span>
                     <span class="cart-product__cost cart-product__cost--total cart-product__cost--total-old" style="text-decoration: line-through;">
                        ${(
                          calculatePrice(
                            product.unit_price,
                            product.discount_percent,
                            product.coupon_discount_percent
                          ) * parseInt(product.quantity)
                        ).toFixed(2)}
                        ${currency}
                      </span>
                      <span class="cart-product__cost cart-product__cost--total cart-product__cost--total-discount">
                      Discount:
                        ${product.total_discount}
                        ${currency}
                      </span>
                   </div>
                  `
                        : `
                      <span class="cart-product__cost cart-product__cost--total">
                      ${(
                        calculatePrice(
                          product.unit_price,
                          product.discount_percent,
                          product.coupon_discount_percent
                        ) * parseInt(product.quantity)
                      ).toFixed(2)}
                      </span>
                      <span class="cart-product__currency cart-product__currency--total">
                        ${currency}
                      </span>
                    `
                      : `
                  <span class="cart-product__no-price cart-product__no-price--total">
                    ${translations["N/A"]}
                  </span>
                  `
                  }
                  </span>
                </td>
              </tr>
              `,
              ""
            )}
            </tbody>
          </table>
          `;
    }

    function makeDealDetailsHtml(
      index,
      current,
      deal,
      detailsPath,
      translations
    ) {
      console.log(current, deal, detailsPath, translations);
      return `
            <div class="cart-checkout__details">
              <h1 class="cart-checkout__title">
                ${translations["Deal"]} #${index + 1}
              </h1>
              <span class="cart-checkout__delimiter"></span>
              <div class="cart-checkout__meta">
                <div class="cart-checkout__meta-item">
                  <span class="cart-checkout__meta-title">
                    ${translations["Deal status:"]} 
                  </span>
                  <span class="cart-checkout__meta-value">
                    ${deal.status}
                  </span>
                </div>

                <div class="cart-checkout__meta-item">
                  <span class="cart-checkout__meta-title">
                    ${translations["Total deal cost:"]} 
                  </span>
                  <span class="cart-checkout__meta-value">
                    ${deal.total_cost} ${translations[deal.currency]}
                  </span>
                </div>

                <div class="cart-checkout__meta-item">
                  <span class="cart-checkout__meta-title">
                    ${translations["Shipping:"]} 
                  </span>
                  <span class="cart-checkout__meta-value">
                  ${deal.shipping_cost} ${translations[deal.currency]}
                  </span>
                </div>
              <div>
              <span class="cart-checkout__delimiter"></span>
              <h1 class="cart-checkout__title">
                ${translations["Products list"]}
              </h1>
            </div>
            <span class="cart-checkout__delimiter"></span>
            ${makeDealProductTableHtml(
              deal.order_items,
              translations[deal.currency],
              detailsPath,
              translations
            )}
            <span class="cart-checkout__delimiter"></span>             
          `;
    }

    function makeDealFormHtml(deal, countries, translations) {
      return `
            <form class="cart-checkout__form" action="#">
              <input
                ${deal.status === "ordered" ? "disabled" : ""}
                required
                class="cart-checkout__input"
                type="text"
                placeholder="${translations["First name"]}"
                value="${deal.person_first_name || ""}"
                name="cart_checkout_first_name"
              >
              <input
                ${deal.status === "ordered" ? "disabled" : ""}
                required
                class="cart-checkout__input"
                type="text"
                placeholder="${translations["Last name"]}"
                value="${deal.person_last_name || ""}"
                name="cart_checkout_last_name"
              >
              <input
                ${deal.status === "ordered" ? "disabled" : ""}
                required
                class="cart-checkout__input"
                type="text"
                placeholder="${translations["Phone number"]}"
                value="${deal.person_phone_number || ""}"
                name="cart_checkout_phone_number"
              >
              <input
                ${deal.status === "ordered" ? "disabled" : ""}
                required
                class="cart-checkout__input"
                type="text"
                placeholder="${translations["Address"]}"
                value="${deal.person_address || ""}"
                name="cart_checkout_address"
              >
              <input
                ${deal.status === "ordered" ? "disabled" : ""}
                required
                class="cart-checkout__input"
                type="email"
                placeholder="${translations["E-mail"]}"
                value="${deal.person_email || ""}"
                name="cart_checkout_email"
              >
              <select
                ${deal.status === "ordered" ? "disabled" : ""}
                required
                class="cart-checkout__input"
                name="cart_checkout_country"
              >
                <option>${translations["Select country"]}</option>
                ${countries
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .reduce(
                    (tmp, country) => `${tmp}
                      <option
                        ${deal.person_country === country.id ? `selected` : ""}
                        value="${country.id}"
                      >
                        ${country.name}
                      </option>
                    `,
                    ""
                  )}
              </select>
              <div style="margin: 8px 0 24px auto;">
                <input
                  ${deal.status === "ordered" ? "checked" : ""}
                  ${deal.status === "ordered" ? "disabled" : ""}
                  required
                  type="checkbox"
                  name="cart_checkout_agree"
                >
                <label for="cart_checkout_agree">
                ${"  "}${translations["Agree with license conditions"]}
                </label>
              </div>
            </form>
          `;
    }

    function pelecardFormHtml(current, deals, translations) {
      return `
            <div class="pelecard__form">
              <button
                type="button"
                ${
                  current + 1 > deals.length
                    ? `class="pelecard__button pelecard__button--disabled" disabled`
                    : `class="pelecard__button"`
                }
              >
                ${translations["Credit card payment"]}
              </button>
            </div>
          `;
    }

    function makeStepsButtonsHtml(current, deals, translations) {
      return `
            <div class="cart-checkout__buttons">
              <button
                type="button"
                ${
                  current === 0
                    ? `class="cart-checkout__button cart-checkout__button--prev cart-checkout__button--disabled" disabled`
                    : `class="cart-checkout__button cart-checkout__button--prev"`
                }
              >
                ${translations["Prev step"]}
              </button>
    
              <div class="cart-checkout__steps-buttons">
                ${deals.reduce(
                  (tmp, item, index) => `${tmp}
                  <svg
                    class="cart-checkout__step-button"
                    fill="${index === current ? "#888888" : "#cccccc"}"
                    width="8px"
                    data-step="${index}"
                    viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="50" cy="50" r="50"/>
                  </svg>
                  `,
                  ""
                )}
              </div>
              <div class="cart-checkout__buttons cart-checkout__buttons--second">
                <button
                  type="button"
                  ${
                    current + 1 > deals.length
                      ? `class="cart-checkout__button cart-checkout__button--submit cart-checkout__button--disabled" disabled`
                      : `class="cart-checkout__button cart-checkout__button--submit"`
                  }
                >
                   ${translations["Submit"]}
                </button>
                <button
                  type="button"
                  ${
                    current + 1 >= deals.length
                      ? `class="cart-checkout__button cart-checkout__button--next cart-checkout__button--disabled" disabled`
                      : `class="cart-checkout__button cart-checkout__button--next"`
                  }
                >
                  ${translations["Next step"]}
                </button>
              </div>
            </div>
          `;
    }

    function setLoading(loading) {
      const el = document.querySelector(".cart-checkout");
      if (!el) return;

      if (loading) {
        el.classList.add("cart-checkout--preloader");
      } else {
        el.classList.remove("cart-checkout--preloader");
      }
    }

    function render(current, detailsPage, translations) {
      const API_ROOT = "{[ apiRoot ]}";
      const organization = "{[ organization ]}";

      const contentEl = document.querySelector(".cart-checkout-wrapper");
      setLoading(true);

      fetch(
        `${API_ROOT}/payments/deal/?status=draft&page_size=1000&company=${organization}`,
        {
          method: "GET",
          headers: Object.assign(
            { "Accept-Language": localStorage.getItem("language") },
            localStorage.getItem("token")
              ? { Authorization: `Token ${localStorage.getItem("token")}` }
              : {}
          ),
          cors: "cors",
          credentials: "omit"
        }
      )
        .then(response => response.json())
        .then(response => response.results)
        .then(deals => deals.sort((a, b) => a.id - b.id))
        .then(deals => {
          if (!deals.length) {
            contentEl.innerHTML = "";
            contentEl.innerHTML = `
                <div class="cart-checkout">
                  <div class="cart-checkout__content">
                    <h1 class="cart-checkout__title">
                      ${translations["No deals..."]}
                    </h1>
                  </div>
                </div>
              `;
            return;
          } else {
            deals = deals.filter(item => item.order_items.length > 0);
          }

          fetch(`${API_ROOT}/organization/countries/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => response.results)
            .then(countries => {
              setLoading(false);
              const dealsTotal = localStorage.getItem("subtotal")
                ? JSON.parse(localStorage.getItem("subtotal"))[0]
                : [];
              contentEl.innerHTML = "";
              contentEl.innerHTML = `
                    <div class="cart-checkout">
                      ${deals.map((deal, index) =>
                        makeDealDetailsHtml(
                          index,
                          current,
                          deal,
                          detailsPage,
                          translations
                        )
                      )}
                      ${
                        dealsTotal?.total
                          ? `<div class="cart-checkout__deals-total-info" style="margin: 20px 0;">
                            <div class="cart-checkout__deals-subtotal" style="margin: 10px 0; font-weight: 600;">
                            ${translations["Subtotal"]}: ${
                              dealsTotal.sub_total
                            } 
                            ${translations[dealsTotal.currency]}
                            </div>
                            <div class="cart-checkout__deals-subtotal" style="margin: 10px 0; font-weight: 600;">
                            ${translations["Shipping Cost"]}: ${
                              dealsTotal.shipping_cost
                            } ${translations[dealsTotal.currency]}
                            </div>
                            <div class="cart-checkout__deals-subtotal" style="margin: 10px 0; font-weight: 600;">
                            ${translations["Total"]}: ${dealsTotal.total} ${
                              translations[dealsTotal.currency]
                            }
                            </div>
                          </div>`
                          : ""
                      }
                      <span class="cart-checkout__delimiter"></span>

                      <div class="cart-checkout__content">
                        <h1 class="cart-checkout__title">
                          ${translations["Deal Order Details"]}
                        </h1>
                        <h2 class="cart-checkout__description">
                          ${translations["Enter customer person info"]}
                        </h2>
                        <span class="cart-checkout__delimiter"></span>
                        ${makeDealFormHtml(
                          deals[current],
                          countries,
                          translations
                        )}
                        ${pelecardFormHtml(current, deals, translations)}
                      </div>
                      ${makeStepsButtonsHtml(current, deals, translations)}
                    </div>
                  `;
            })
            .then(() => {
              Array.prototype.slice
                .call(document.querySelectorAll(".cart-checkout__step-button"))
                .map(el =>
                  el.addEventListener("click", e => {
                    const target = e.target.closest(
                      ".cart-checkout__step-button"
                    );
                    const step = parseInt(target.dataset.step);

                    render(step, detailsPage, translations);
                  })
                );

              const prev = document.querySelector(
                ".cart-checkout__button--prev:not(:disabled)"
              );
              prev &&
                prev.addEventListener("click", e =>
                  render(current - 1, detailsPage, translations)
                );

              const next = document.querySelector(
                ".cart-checkout__button--next:not(:disabled)"
              );
              next &&
                next.addEventListener("click", e =>
                  render(current + 1, detailsPage, translations)
                );

              const submitEl = document.querySelector(
                ".cart-checkout__button--submit:not(:disabled)"
              );

              const payByCardEl = document.querySelector(
                ".pelecard__button:not(:disabled)"
              );
              submitEl &&
                submitEl.addEventListener("click", e => {
                  const formEl = document.querySelector(".cart-checkout__form");
                  const status = formEl.reportValidity();
                  if (!status) return;

                  const data = new FormData(formEl);
                  const id = deals[current].id;

                  const body = {
                    person_first_name: data.get("cart_checkout_first_name"),
                    person_last_name: data.get("cart_checkout_last_name"),
                    person_phone_number: data.get("cart_checkout_phone_number"),
                    person_address: data.get("cart_checkout_address"),
                    person_email: data.get("cart_checkout_email"),
                    person_country: parseInt(data.get("cart_checkout_country")),
                    agree: true
                  };

                  if (!body.person_country) return;

                  setLoading(true);
                  fetch(`${API_ROOT}/payments/deal/${id}/`, {
                    method: "PATCH",
                    headers: Object.assign(
                      {
                        "Accept-Language": localStorage.getItem("language"),
                        "Content-Type": "application/json"
                      },
                      localStorage.getItem("token")
                        ? {
                            Authorization: `Token ${localStorage.getItem(
                              "token"
                            )}`
                          }
                        : {}
                    ),
                    body: JSON.stringify(body),
                    cors: "cors",
                    credentials: "omit"
                  }).then(response => render(0, detailsPage, translations));
                });

              payByCardEl &&
                payByCardEl.addEventListener("click", e => {
                  const body = {
                    company: organization,
                    good_url: `${window.location.origin}/order-check`,
                    error_url: `${window.location.origin}/order-error`
                  };

                  setLoading(true);
                  fetch(`${API_ROOT}/payments/deal/pelecard_create/`, {
                    method: "POST",
                    headers: Object.assign(
                      {
                        "Accept-Language": localStorage.getItem("language"),
                        "Content-Type": "application/json"
                      },
                      localStorage.getItem("token")
                        ? {
                            Authorization: `Token ${localStorage.getItem(
                              "token"
                            )}`
                          }
                        : {}
                    ),
                    body: JSON.stringify(body),
                    cors: "cors",
                    credentials: "omit"
                  })
                    .then(response => response.json())
                    .then(response => {
                      if (response?.url) {
                        window.localStorage.setItem(
                          "order_company",
                          body.company
                        );
                        window.location.assign(response.url);
                      }
                    });
                });
            });
        });
    }

    if (window.hasOwnProperty("grapesjs")) {
      // Get translation
      const translations = getTranslate("translations", this.attributes);
      // Get details page path
      const detailsPage = this.attributes.detailspage
        ? this.attributes.detailspage.value
        : "#";
      // Render
      render(0, detailsPage, translations);
    }
  };

  editor.DomComponents.addType("cart-checkout", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "cart-checkout-wrapper",
    model: {
      defaults: {
        name: "Cart Checkout",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "Deal",
              "Deal status:",
              "Total deal cost:",
              "Shipping:",
              "Products list",
              "PRODUCT",
              "PRICE",
              "QUANTITY",
              "TOTAL",
              "Deal Order Details",
              "Enter customer person info",
              "First name",
              "Last name",
              "Phone number",
              "Address",
              "E-mail",
              "Select country",
              "Agree with license conditions",
              "N/A",
              "Prev step",
              "Credit card payment",
              "Next step",
              "Submit",
              "No deals...",
              "Credit card payment",
              "ILS",
              "Total",
              "Subtotal",
              "Shipping Cost"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
