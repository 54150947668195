export default function translationsBaseComponent({ editor, api, site }) {
  const script = function() {
    const defaultLanguage = this.dataset.hasOwnProperty("defaultLanguage")
      ? this.dataset.defaultLanguage.toUpperCase()
      : (navigator.language && navigator.language.slice(0, 2).toUpperCase()) ||
        "EN";

    if (!localStorage.hasOwnProperty("language")) {
      localStorage.setItem("language", defaultLanguage);
    }
    const language = localStorage.getItem("language").toLowerCase();
    const direction = language === "he" || language === "ar" ? "rtl" : "ltr";
    localStorage.setItem("direction", direction);

    if (window.hasOwnProperty("grapesjs")) {
      const bodyEl = document.body;
      const htmlEl = bodyEl.closest("html");
      bodyEl.lang = language;
      bodyEl.dir = direction;
      bodyEl.style.direction = direction;
      htmlEl.lang = language;
      htmlEl.dir = direction;
      htmlEl.style.direction = direction;
    }
  };

  editor.DomComponents.addType("translations-base", {
    isComponent: el =>
      el.tagName === "div" && el.className === "gjs-translations-base",
    model: {
      defaults: {
        name: "Translations Base",
        draggable: false,
        doppable: false,
        removable: false,
        copyable: false,
        script,
        traits: [
          {
            type: "select",
            label: "Initial language",
            name: "data-default-language",
            options: [
              { id: "en", name: "EN" },
              { id: "ru", name: "RU" },
              { id: "am", name: "AM" },
              { id: "bg", name: "BG" },
              { id: "uk", name: "UK" },
              { id: "he", name: "HE" },
              { id: "ar", name: "AR" },
              { id: "zh", name: "ZH" },
              { id: "es", name: "ES" }
            ]
          }
        ]
      }
    }
  });

  editor.on(
    "storage:end",
    () =>
      !editor
        .getComponents()
        .models.find(
          model =>
            model.attributes && model.attributes.type === "translations-base"
        ) &&
      editor.addComponents({
        type: "translations-base"
      })
  );
}
