import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Select, MenuItem } from "@material-ui/core";

import { setApplicationLanguage } from "../../redux/actions-exporter";

import "./language-switcher.styles.scss";

const changeLanguage = lang => {
  const html = document.querySelector("html");
  if (html) {
    html.setAttribute("lang", lang?.toLowerCase() || "en");
  }
};

const LanguageSwitcher = ({
  languages,
  className,
  style,
  selectedLanguage,
  setApplicationLanguage
}) => {
  useEffect(() => {
    if (localStorage.userStorageLanguage) {
      setApplicationLanguage(localStorage.getItem("userStorageLanguage"));
      changeLanguage(localStorage?.getItem("userStorageLanguage"));
    }
  }, [localStorage.userStorageLanguage, selectedLanguage]); // eslint-disable-line

  const handleLanguageChange = e => {
    e.preventDefault();
    setApplicationLanguage(e.target.value);
    changeLanguage(e.target.value);
    localStorage.setItem("userStorageLanguage", e.target.value);
  };

  const browserLanguage = () => {
    const lsLanguage = localStorage.userStorageLanguage;
    const browsLanguage =
      navigator.language.toUpperCase() || navigator.userLanguage.toUpperCase();
    const selected = selectedLanguage.toUpperCase();

    if (lsLanguage) {
      return lsLanguage;
    } else if (languages.includes(browsLanguage)) {
      return browsLanguage;
    } else if (languages.includes(selected)) {
      return selected;
    } else return "ENG";
  };

  return (
    <div className={`language-switcher ${className}`} style={style}>
      <Select
        IconComponent={() => <></>}
        defaultValue={browserLanguage}
        onChange={handleLanguageChange}
        classes={{
          root: "language-switcher__select"
        }}
        disableUnderline
      >
        {languages.map((language, index) => (
          <MenuItem value={language} key={index}>
            {language.slice(0, 2)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedLanguage: state.appLanguage.appLanguage
});

export default connect(mapStateToProps, { setApplicationLanguage })(
  LanguageSwitcher
);
