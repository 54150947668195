import React from "react";
import parse from "html-react-parser";
import moment from "moment";

import {
  LoadingSpinner,
  Pagination,
  JobCard,
  NoDataFound
} from "../../../components";

import "./job-find-job.styles.scss";

const JobFindJob = ({
  jobsPage,
  setJobsPage,
  areJobsPending,
  jobsCount,
  jobs
}) => {
  return (
    <div>
      {areJobsPending ? (
        <LoadingSpinner />
      ) : jobs.length ? (
        jobs.map(({ id, title, description, country_ext, created_at }) => (
          <JobCard
            key={id}
            jobId={id}
            jobTitle={title}
            jobDescription={description && parse(parse(description))}
            jobLocation={country_ext.name}
            jobDate={moment(created_at).format("DD MMMM YYYY")}
          />
        ))
      ) : (
        <NoDataFound
          noDataTitle="No jobs found!"
          myBusiness={true}
          additionalLink="/my-business/vacancy"
        />
      )}
      <Pagination
        forcePage={jobsPage - 1}
        selectedPage={page => setJobsPage(page)}
        dataCount={jobsCount}
        itemsPerPage={10}
      />
    </div>
  );
};

export default JobFindJob;
