import React from "react";
import { connect } from "react-redux";

import { ReactComponent as Play } from "../../assets/arrow-icon.svg";

import "./assets/chat-bot.styles.scss";

import iconRobot from "./assets/images/landing-icon-robot.png";
import iconContent from "./assets/images/landing-icon-content.png";
import iconStation from "./assets/images/landing-icon-radio-station.png";
import iconSpeak from "./assets/images/landing-icon-speak.png";
import iconContact from "./assets/images/landing-icon-contact.png";
import iconChart from "./assets/images/landing-icon-bar-chart.png";
import iconTranslation from "./assets/images/landing-icon-translation.png";
import iconCart from "./assets/images/landing-icon-shopping-cart.png";

import iconViber from "./assets/images/landing-icon-viber.png";
import iconTwitter from "./assets/images/landing-icon-twitter.png";
import iconTelegram from "./assets/images/landing-icon-telegram.png";
import iconSlack from "./assets/images/landing-icon-slack.png";
import iconMessenger from "./assets/images/landing-icon-messenger.png";
import iconInstagram from "./assets/images/landing-icon-instagram.png";
import iconWhatsApp from "./assets/images/landing-icon-whatsapp.png";

const ChatBotPage = ({ localizationData }) => {
  const iconFeatures = [
    {
      icon: iconRobot,
      title: "Bots Managing",
      description:
        "Easily create cross-platform bots for business processes. Use rich instruments to build and manage conversational bots without coding."
    },
    {
      icon: iconContent,
      title: "Content",
      description:
        "We provide our own content management system to give you full control on what your bot will use in the conversations."
    },
    {
      icon: iconStation,
      title: "Broadcasts",
      description:
        "Broadcasts offer you the possibility to send manually or prescheduled push notifications to subscribers of the bot and build intensive dialogs on this way."
    },
    {
      icon: iconSpeak,
      title: "Dialogues",
      description:
        "You control all bot conversations with the clients on one page and always can join in the dialogue. This function gives you the ability to improve your bot and support your clients in any time."
    },
    {
      icon: iconContact,
      title: "Functions",
      description:
        "A large number of internal components let you easily create multi-step bot behavior scenarios. Build a bot that gets information from users and outputs the response in the different media formats."
    },
    {
      icon: iconChart,
      title: "Statistics",
      description:
        "Many useful statistical indicators about number of the clients, sessions, and incoming and outgoing messages."
    },
    {
      icon: iconTranslation,
      title: "Natural Language Processing",
      description:
        "Natural Language Processing (NLP) allows you to understand the meaning of a particular user input. You may need it as a stand-alone layer to parse text or speech into structure data."
    },
    {
      icon: iconCart,
      title: "Store",
      description:
        "Marketplace and eCommerce store features allow you to upload/create the products within our system and sell it via chatbots, website widgets, and mobile apps."
    }
  ];
  const socialIcons = [
    {
      title: "Viber",
      icon: iconViber
    },
    {
      title: "Twitter",
      icon: iconTwitter
    },
    {
      title: "Telegram",
      icon: iconTelegram
    },
    {
      title: "Slack",
      icon: iconSlack
    },
    {
      title: "Messenger",
      icon: iconMessenger
    },
    {
      title: "Instagram",
      icon: iconInstagram
    },
    {
      title: "WhatsApp",
      icon: iconWhatsApp
    }
  ];
  return (
    <>
      <div className="b24title">
        <div className="b24type">
          <Play />
          <div className="b24name" style={{ marginRight: 0 }}>
            AI &amp; Bots is our path to Superintelligence
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <a className="signB24" href="https://chat.b24online.com">
            <img
              src="https://chat.b24online.com/static/media/logo.250da1e4.svg"
              alt="B24CHAT"
            />
            SignIn / SignUp
          </a>
        </div>
      </div>
      <div className="chat-bot">
        <div className="b24-features">
          <p>
            A Business Revolution Through an Artificial Intelligence Network
          </p>
        </div>
        <div className="b24-features">
          {iconFeatures.map((feature, index) => (
            <div key={index} className="b24-feature">
              <img className="feature__icon" src={feature.icon} alt="" />
              <h4 className="feature__title">{feature.title}</h4>
              <p className="feature__description">{feature.description}</p>
            </div>
          ))}
        </div>
        <div className="b24-connections">
          <p>
            The whole range of channels for your bots. Integrate your bots in
            different platforms and potentially achieve 5B users in real time
            via social media.
          </p>
        </div>
        <div className="b24-icons">
          {socialIcons.map((social, index) => (
            <img
              className="b24-icon"
              src={social.icon}
              alt={`${social.title} b24-icon`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  appLanguage: state.appLanguage.appLanguage,
  localizationData: state.appLanguage.localizationData
});

export default connect(mapStateToProps)(ChatBotPage);
