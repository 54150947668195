import { BusinessProposalsActionTypes } from "./business-proposals.types";

const INITIAL_STATE = {
  areBusinessProposalsPending: false,
  businessProposalsCount: 0,
  businessProposals: [],
  businessProposalsError: null,

  isBusinessProposalPending: false,
  businessProposal: {},
  businessProposalError: null,

  isBusinessProposalsByCurrentUserPending: false,
  businessProposalsByCurrrentUser: [],
  businessProposalsByCurrrentUserCount: 0,
  businessProposalsByCurrrentUserError: {},

  isBusinessProposalsCategoriesPending: false,
  businessProposalsCategories: [],
  businessProposalsCategoriesError: {},

  isEditingBusinessProposalPending: false,
  editedBusinessProposal: {},
  editedBusinessProposalError: {},

  businessRequestStatus: 0
};

const BusinessProposalsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_PENDING:
      return { ...state, areBusinessProposalsPending: true };

    case BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_SUCCESS:
      return {
        ...state,
        businessProposalsCount: action.payload.count,
        businessProposals: action.payload.businessProposals,
        businessProposalsError: null,
        areBusinessProposalsPending: false
      };

    case BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_ERROR:
      return {
        ...state,
        businessProposalsError: action.payload,
        areBusinessProposalsPending: false
      };

    case BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSAL_BY_ID_PENDING:
      return { ...state, isBusinessProposalPending: true };

    case BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSAL_BY_ID_SUCCESS:
      return {
        ...state,
        businessProposal: action.payload,
        businessProposalError: null,
        areBusinessProposalsPending: false
      };

    case BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSAL_BY_ID_ERROR:
      return {
        ...state,
        businessProposalError: action.payload,
        areBusinessProposalsPending: false
      };

    case BusinessProposalsActionTypes.GET
      .GET_BUSINESS_PROPOSALS_CATEGORIES_PENDING:
      return {
        ...state,
        isBusinessProposalsCategoriesPending: true
      };

    case BusinessProposalsActionTypes.GET
      .GET_BUSINESS_PROPOSALS_CATEGORIES_SUCCESS:
      return {
        ...state,
        businessProposalsCategories: action.payload.categories,
        isBusinessProposalsCategoriesPending: false
      };

    case BusinessProposalsActionTypes.GET
      .GET_BUSINESS_PROPOSALS_CATEGORIES_ERROR:
      return {
        ...state,
        businessProposalsCategoriesError: action.payload,
        isBusinessProposalsCategoriesPending: false
      };

    case BusinessProposalsActionTypes.GET
      .GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_PENDING:
      return {
        ...state,
        isBusinessProposalsByCurrentUserPending: true
      };

    case BusinessProposalsActionTypes.GET
      .GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_SUCCESS:
      return {
        ...state,
        businessProposalsByCurrrentUserCount: action.payload.count,
        businessProposalsByCurrrentUser: action.payload.proposals,
        isBusinessProposalsByCurrentUserPending: false
      };

    case BusinessProposalsActionTypes.GET
      .GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_ERROR:
      return {
        ...state,
        businessProposalsByCurrrentUserError: action.payload,
        isBusinessProposalsByCurrentUserPending: false
      };

    case BusinessProposalsActionTypes.POST.POST_BUSINESS_PROPOSAL_PENDING:
      return {
        ...state,
        isEditingBusinessProposalPending: true
      };

    case BusinessProposalsActionTypes.POST.POST_BUSINESS_PROPOSAL_SUCCESS:
      return {
        ...state,
        editedBusinessProposal: action.payload.post,
        businessRequestStatus: action.payload.status,
        isEditingBusinessProposalPending: false
      };

    case BusinessProposalsActionTypes.POST.POST_BUSINESS_PROPOSAL_ERROR:
      return {
        ...state,
        isEditingBusinessProposalPending: false
      };

    case BusinessProposalsActionTypes.PUT.PUT_BUSINESS_PROPOSAL_PENDING:
      return {
        ...state,
        isEditingBusinessProposalPending: true
      };

    case BusinessProposalsActionTypes.PUT.PUT_BUSINESS_PROPOSAL_SUCCESS:
      return {
        ...state,
        editedBusinessProposal: action.payload.post,
        businessRequestStatus: action.payload.status,
        isEditingBusinessProposalPending: false
      };

    case BusinessProposalsActionTypes.PUT.PUT_BUSINESS_PROPOSAL_ERROR:
      return {
        ...state,
        isEditingBusinessProposalPending: false
      };

    case BusinessProposalsActionTypes.CLEAR_BUSINESS_PROPOSALS_REQUEST:
      return {
        ...state,
        businessRequestStatus: action.payload
      };
    default:
      return state;
  }
};

export default BusinessProposalsReducer;
