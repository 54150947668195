import content from "./block-content";
import icon from "./icon.png";

export default function wishListBlock({ editor }) {
  const blockManager = editor.BlockManager;

  blockManager.add("wish-list", {
    label: `
      <img src="${icon}" alt="wish-list block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Wish List
      </div>
    `,
    content,
    category: "B24Online Profile"
  });
}
