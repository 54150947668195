import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";
import { Card } from "@material-ui/core";

import { withDebounce, arrToString } from "../../shared";
import { Filter } from "../../containers";
import {
  LoadingSpinner,
  EventCard,
  Pagination,
  NoDataFound
} from "../../components";

import {
  getBusinessProposals,
  setBranchesFilterStatus,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus
} from "../../redux/actions-exporter";

import "./business-proposals.styles.scss";

const BusinessProposals = ({
  getBusinessProposals,
  areBusinessProposalsPending,
  businessProposalsCount,
  businessProposals,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setBranchesFilterStatus,
  filterCountriesArr,
  filterOrganizationsArr,
  filterBranchesArr
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [businessPage, setBusinessPage] = useState(1);

  useEffect(() => {
    setCountriesFilterStatus(true);
    setOrganizationsFilterStatus(true);
    setBranchesFilterStatus(true);
    return () => {
      setCountriesFilterStatus(false);
      setOrganizationsFilterStatus(false);
      setBranchesFilterStatus(true);
    };
  }, [
    setCountriesFilterStatus,
    setOrganizationsFilterStatus,
    setBranchesFilterStatus
  ]);

  useEffect(() => {
    getBusinessProposals(
      searchQuery,
      businessPage,
      arrToString(filterCountriesArr),
      arrToString(filterOrganizationsArr),
      arrToString(filterBranchesArr)
    );
  }, [
    getBusinessProposals,
    searchQuery,
    businessPage,
    filterCountriesArr,
    filterOrganizationsArr,
    filterBranchesArr
  ]);

  useEffect(() => {
    setBusinessPage(1);
  }, [searchQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [businessPage]);

  const searchBusinessProposalHandler = withDebounce(e => setSearchQuery(e));

  return (
    <div className="business-proposals">
      <Filter onChange={e => searchBusinessProposalHandler(e.target.value)} />
      <div className="business-proposals__body">
        {areBusinessProposalsPending ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <div className="events__body__title">Business Proposals</div>
            <div className="business-proposals__body__padding">
              {businessProposals && !!businessProposals.length ? (
                businessProposals.map(
                  ({ id, title, description, created_at, organization }) => (
                    <EventCard
                      key={id}
                      // country="Belarus"
                      title={
                        <Link
                          to={`/business-proposals/business-proposal/${id}`}
                        >
                          {title}
                        </Link>
                      }
                      description={
                        description.length > 420
                          ? parse(description.substring(0, 420) + " ...")
                          : parse(description)
                      }
                      publicated={true}
                      publicatedDate={moment(created_at).format("DD MMMM YYYY")}
                      publicatedCompany={organization.name}
                    />
                  )
                )
              ) : (
                <NoDataFound
                  noDataTitle="No business proposals found!"
                  myBusiness={true}
                  additionalLink="/my-business/business-proposals"
                />
              )}
            </div>
          </Card>
        )}
        <Pagination
          forcePage={businessPage - 1}
          dataCount={businessProposalsCount}
          itemsPerPage={10}
          selectedPage={page => setBusinessPage(page)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  areBusinessProposalsPending:
    state.businessProposals.areBusinessProposalsPending,
  businessProposals: state.businessProposals.businessProposals,
  businessProposalsCount: state.businessProposals.businessProposalsCount,
  filterCountriesArr: state.filterReducer.filterCountriesArr,
  filterOrganizationsArr: state.filterReducer.filterOrganizationsArr,
  filterBranchesArr: state.filterReducer.filterBranchesArr
});

export default connect(mapStateToProps, {
  getBusinessProposals,
  setCountriesFilterStatus,
  setOrganizationsFilterStatus,
  setBranchesFilterStatus
})(BusinessProposals);
