import { axiosNoTokenInstance } from "../../../../axios/axios.config";
import { ActiveProductActionTypes } from "./active-product.type";

export const getB2BProduct = productId => dispatch => {
  dispatch({ type: ActiveProductActionTypes.B2B.B2B_PRODUCT_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/b2b/product/?id=${productId}`)
    .then(product =>
      dispatch({
        type: ActiveProductActionTypes.B2B.B2B_PRODUCT_SUCCESS,
        payload: product.data.results[0]
      })
    )
    .catch(error => {
      dispatch({
        type: ActiveProductActionTypes.B2B.B2B_PRODUCT_ERROR,
        payload: error
      });
    });
};

export const getB2CProduct = productId => dispatch => {
  dispatch({ type: ActiveProductActionTypes.B2C.B2C_PRODUCT_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/product/?id=${productId}`)
    .then(product =>
      dispatch({
        type: ActiveProductActionTypes.B2C.B2C_PRODUCT_SUCCESS,
        payload: product.data.results[0]
      })
    )
    .catch(error => {
      dispatch({
        type: ActiveProductActionTypes.B2C.B2C_PRODUCT_ERROR,
        payload: error
      });
    });
};

export const setProductVariation = variationObj => dispatch => {
  dispatch({
    type: ActiveProductActionTypes.SET_PRODUCT_VARIATION,
    payload: variationObj
  });
};
