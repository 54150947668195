import React from "react";

import "./builder-template.styles.scss";

const BuilderTemplate = ({ thumbnail, title }) => (
  <div className="builder-template">
    <img
      src={
        thumbnail
          ? thumbnail
          : `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==`
      }
      className={
        thumbnail
          ? `builder-template__image`
          : `builder-template__image builder-template__image--placeholder`
      }
      alt={`${title} thumbnail`}
    />
    <h3 className="builder-template__title">{title}</h3>
  </div>
);

export default BuilderTemplate;
