import { ThemesActionTypes } from "./themes.types";
import { axiosInstance } from "../../../axios/axios.builder.config";

export const getThemes = (
  page,
  search = "",
  categoryId = "",
  perPage = 10
) => dispatch => {
  dispatch({ type: ThemesActionTypes.GET_THEMES_PENDING });
  return axiosInstance
    .get(
      `/api/v1/builder/theme/?page=${page}&search=${search}&page_size=${perPage}&category=${categoryId}`,
      {},
      {}
    )
    .then(({ data: { count: total, results: themes } }) => {
      dispatch({
        type: ThemesActionTypes.GET_THEMES_PAGINATION,
        payload: {
          total,
          perPage,
          currentPage: page
        }
      });
      return themes;
    })
    .then(themes =>
      Promise.all(
        themes.map(async theme => {
          dispatch({ type: ThemesActionTypes.GET_THEMES_PENDING });

          const { data: sitemaps } = await axiosInstance.get(
            `/api/v1/builder/sitemap/?theme=${theme.id}`,
            {},
            {}
          );

          return {
            ...theme,
            templates: sitemaps.map(sitemap => ({
              ...sitemap.template,
              path: sitemap.path
            }))
          };
        })
      )
    )
    .then(themes =>
      dispatch({
        type: ThemesActionTypes.GET_THEMES,
        payload: themes.filter(theme => theme.templates.length)
      })
    )
    .catch(error => console.log(error));
};

export const getThemeCategories = () => dispatch => {
  dispatch({ type: ThemesActionTypes.GET_THEME_CATEGORIES_PENDING });
  return axiosInstance
    .get(`/api/v1/builder/theme_categories/`, {}, {})
    .then(({ data }) =>
      dispatch({
        type: ThemesActionTypes.GET_THEME_CATEGORIES,
        payload: data
      })
    )
    .catch(error => console.log(error));
};

export const createPagesFromTheme = (theme, site) => dispatch => {
  return axiosInstance
    .get(`/api/v1/builder/pages/?resource_id=${site.id}&path=/`, {}, {})
    .then(({ data: { results: [rootPage] } }) =>
      axiosInstance.delete(
        `/api/v1/builder/pages/${rootPage.id}/?resource_id=${site.id}`,
        {},
        {}
      )
    )
    .then(() =>
      Promise.all(
        theme.templates.map(async template => {
          const {
            data: { gjs_assets, gjs_styles, gjs_components, gjs_css, gjs_html }
          } = await axiosInstance.get(
            `/api/v1/builder/template/${template.id}`,
            {},
            {}
          );

          await axiosInstance.post(
            `/api/v1/builder/pages/`,
            {
              ...template,
              resource: site.id,
              gjs_assets,
              gjs_styles,
              gjs_components,
              gjs_css,
              gjs_html
            },
            {}
          );
        })
      )
    )
    .then(() => dispatch({ type: ThemesActionTypes.CREATE_PAGES_FROM_THEME }))
    .catch(error => console.log(error));
};

export const importThemeToSite = (theme, site) => dispatch => {
  return axiosInstance
    .post(
      `/api/v1/builder/theme_import`,
      {
        theme: theme.id,
        site: site.id
      },
      {}
    )
    .then(() => dispatch({ type: ThemesActionTypes.CREATE_PAGES_FROM_THEME }))
    .catch(error => createPagesFromTheme(theme, site));
};
