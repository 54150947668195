import React, { useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { useTranslation } from "react-i18next";

import * as serviceWorker from '~/serviceWorker';

const ServiceWorkerWrapper = () => {
  const { t } = useTranslation();

  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
      }
    });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({type: 'SKIP_WAITING'});
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message={t("A new version is available!")}
      onClick={reloadPage}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          {t("Reload")}
        </Button>
      }
    />
  );
}

export default ServiceWorkerWrapper;