import { ResumesActionTypes } from "./resumes.types";

const INITIAL_STATE = {
  resumes: {},
  createdResume: {},
  updatedResume: {},
  deletedResume: {},
  maritalStatus: [],

  isResumeByIdPending: false,
  resumeById: {},
  resumeByIdError: [],

  areResumeByMePending: false,
  resumesByMe: [],
  resumesByMeCount: 0,
  resumesByMeError: {},

  areStudyFormTypesPending: false,
  studyFormTypes: [],
  studyFormTypesError: {},

  isResumeAppliedPending: false,
  resumeApplied: [],
  resumeAppliedError: {},

  resumeRequestStatus: ""
};

const ResumesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ResumesActionTypes.GET_RESUMES:
      return {
        ...state,
        resumes: action.payload
      };

    case ResumesActionTypes.CREATE_RESUME:
      return {
        ...state,
        resumeRequestStatus: action.payload.requestStatus,
        createdResume: action.payload
      };

    case ResumesActionTypes.UPDATE_RESUME:
      return {
        ...state,
        resumeRequestStatus: action.payload.requestStatus,
        updatedResume: action.payload
      };

    case ResumesActionTypes.DELETE_RESUME:
      return {
        ...state,
        deletedResume: action.payload
      };

    case ResumesActionTypes.GET_MARITAL_STATUS:
      return {
        ...state,
        maritalStatus: action.payload
      };

    case ResumesActionTypes.GET_RESUME_BY_ID_PENDING:
      return {
        ...state,
        isResumeByIdPending: true
      };

    case ResumesActionTypes.GET_RESUME_BY_ID_SUCCESS:
      return {
        ...state,
        resumeById: action.payload,
        isResumeByIdPending: false
      };

    case ResumesActionTypes.GET_RESUME_BY_ID_ERROR:
      return {
        ...state,
        resumeByIdError: action.payload,
        isResumeByIdPending: false
      };

    case ResumesActionTypes.GET_RESUMES_BY_ME_PENDING:
      return {
        ...state,
        areResumeByMePending: true
      };

    case ResumesActionTypes.GET_RESUMES_BY_ME_SUCCESS:
      return {
        ...state,
        resumesByMe: action.payload.resumes,
        resumesByMeCount: action.payload.count,
        areResumeByMePending: false
      };

    case ResumesActionTypes.GET_RESUMES_BY_ME_ERROR:
      return {
        ...state,
        resumesByMeError: action.payload,
        areResumeByMePending: false
      };

    case ResumesActionTypes.CLEAR_RESUME_REQUEST:
      return {
        ...state,
        resumeRequestStatus: action.payload
      };

    case ResumesActionTypes.GET_STUDY_FORM_TYPES_PENDING:
      return {
        ...state,
        areStudyFormTypesPending: true
      };

    case ResumesActionTypes.GET_STUDY_FORM_TYPES_SUCCESS:
      return {
        ...state,
        studyFormTypes: action.payload,
        areStudyFormTypesPending: false
      };

    case ResumesActionTypes.GET_STUDY_FORM_TYPES_ERROR:
      return {
        ...state,
        studyFormTypesError: action.payload,
        areStudyFormTypesPending: false
      };

    case ResumesActionTypes.APPLY_TO_JOB_WITH_RESUME_ID_PENDING:
      return {
        ...state,
        isResumeAppliedPending: true
      };

    case ResumesActionTypes.APPLY_TO_JOB_WITH_RESUME_ID_SUCCESS:
      return {
        ...state,
        resumeApplied: action.payload,
        resumeRequestStatus: action.payload.status,
        isResumeAppliedPending: false
      };

    case ResumesActionTypes.APPLY_TO_JOB_WITH_RESUME_ID_ERROR:
      return {
        ...state,
        resumeAppliedError: action.payload,
        isResumeAppliedPending: false
      };

    default:
      return state;
  }
};

export default ResumesReducer;
