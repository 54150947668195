import style from "./block-style";

const cartTableContent = `
${style}
<div class="cart-table-wrapper">
  <table class="cart-table-slim">
    <thead class="cart-table-slim__header">
      <tr>
        <th class="cart-table-slim__header-title">
          Your cart
        </th>
      </tr>
    </thead>
    <tbody>

      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="b2c test thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__no-price">
            N/A
          </span>
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171395" data-deal_index="0">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="3"
              data-product_id="171395" data-deal_index="0">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171395" data-deal_index="0">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171395" data-deal_index="0">X</span>
          </div>
    
          <span class="cart-product__no-price cart-product__no-price--total">
            N/A
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Star T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            39.99
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171398" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="2"
              data-product_id="171398" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171398" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171398" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            79.98
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Emoticon T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            26.75
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171400" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171400" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171400" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171400" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            26.75
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Atomic T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            12.50
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
          <span class="cart-product__percent-discount">
            -50.00%
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171399" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="5"
              data-product_id="171399" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171399" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171399" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            62.50
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Hexagon T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            22.49
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
          <span class="cart-product__percent-discount">
            -25.00%
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171402" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171402" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171402" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171402" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            22.49
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Green Striped T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            26.75
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171401" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171401" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171401" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171401" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            26.75
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Star T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            39.99
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171398" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="2"
              data-product_id="171398" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171398" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171398" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            79.98
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Emoticon T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            26.75
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171400" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171400" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171400" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171400" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            26.75
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Atomic T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            12.50
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
          <span class="cart-product__percent-discount">
            -50.00%
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171399" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="5"
              data-product_id="171399" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171399" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171399" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            62.50
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Hexagon T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            22.49
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
          <span class="cart-product__percent-discount">
            -25.00%
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171402" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171402" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171402" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171402" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            22.49
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
      <tr class="cart-table-slim__row">
        <td>
          <div class="cart-table-slim__product-media">
            <img class="cart-table-slim__product-image"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
              alt="Green Striped T-shirt thumbnail">
          </div>
        </td>
        <td>
          <h4 class="cart-table-slim__title">
            <a class="cart-table-slim__link" href="#">Lorem ipsum dolor sit amet</a>
          </h4>
    
          <span class="cart-product__cost">
            26.75
          </span>
          <span class="cart-product__currency">
            USD
          </span>
    
    
          <form class="cart-quantity-form cart-quantity-form--slim">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171401" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171401" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171401" data-deal_index="1">+</button>
          </form>
        </td>
        <td class="cart-table-slim__actions">
          <div class="cart-table-slim__remove">
            <span class="cart-table-slim__remove-icon" data-product_id="171401" data-deal_index="1">X</span>
          </div>
    
          <span class="cart-product__cost cart-product__cost--total">
            26.75
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
    
        </td>
      </tr>
    
    </tbody>
    <tfoot>
      <tr>
        <td colspan="6">

          <div class="ct-pagination">

            <button class="ct-pagination__button ct-pagination__button--disabled" disabled="">
              &lt;
            </button> <button class="ct-pagination__button ct-pagination__button--active" data-page="1">
              1
            </button>

            <button class="ct-pagination__button ct-pagination__button--disabled" disabled="">
              &gt;
            </button>

          </div>

        </td>
      </tr>
    </tfoot>
  </table>




  <table class="cart-table">
    <thead class="cart-table__header">
      <tr>
        <th class="cart-table__header-title cart-table__header-title--product">PRODUCT</th>
        <th class="cart-table__header-title cart-table__header-title--price">PRICE</th>
        <th class="cart-table__header-title cart-table__header-title--quantity">QUANTITY</th>
        <th class="cart-table__header-title cart-table__header-title--total" colspan="2">TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            16.50
          </span>
          <span class="cart-product__currency">
            USD
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="165871" data-deal_index="0">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="165871" data-deal_index="0">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="165871" data-deal_index="0">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            16.50
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="165871" data-deal_index="0">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            4.10
          </span>
          <span class="cart-product__currency">
            USD
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="165752" data-deal_index="1">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="18"
              data-product_id="165752" data-deal_index="1">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="165752" data-deal_index="1">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            73.80
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="165752" data-deal_index="1">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            16520.00
          </span>
          <span class="cart-product__currency">
            RUB
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="165317" data-deal_index="2">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="28"
              data-product_id="165317" data-deal_index="2">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="165317" data-deal_index="2">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            462560.00
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            RUB
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="165317" data-deal_index="2">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            40.00
          </span>
          <span class="cart-product__currency">
            ILS
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171379" data-deal_index="3">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171379" data-deal_index="3">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171379" data-deal_index="3">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            40.00
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            ILS
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="171379" data-deal_index="3">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            49.00
          </span>
          <span class="cart-product__currency">
            ILS
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171378" data-deal_index="3">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171378" data-deal_index="3">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171378" data-deal_index="3">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            49.90 
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            ILS
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="171378" data-deal_index="3">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            40.00
          </span>
          <span class="cart-product__currency">
            ILS
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171379" data-deal_index="3">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171379" data-deal_index="3">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171379" data-deal_index="3">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            40.00 
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            ILS
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="171379" data-deal_index="3">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            152.00
          </span>
          <span class="cart-product__currency">
            ILS
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="171361" data-deal_index="4">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="171361" data-deal_index="4">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="171361" data-deal_index="4">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            152.00 
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            ILS
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="171361" data-deal_index="4">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            890.00
          </span>
          <span class="cart-product__currency">
            USD
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="82034" data-deal_index="5">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="82034" data-deal_index="5">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="82034" data-deal_index="5">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            890.00
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="82034" data-deal_index="5">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
          00.00
          </span>
          <span class="cart-product__currency">
            USD
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="81981" data-deal_index="6">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="81981" data-deal_index="6">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="81981" data-deal_index="6">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            600.00
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="81981" data-deal_index="6">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            50.00
          </span>
          <span class="cart-product__currency">
            USD
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="84762" data-deal_index="7">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="84762" data-deal_index="7">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="84762" data-deal_index="7">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            50.00
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="84762" data-deal_index="7">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            12.50
          </span>
          <span class="cart-product__currency">
            USD
          </span>
          <span class="cart-product__percent-discount">
            -50.00%
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="159342" data-deal_index="8">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="159342" data-deal_index="8">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="159342" data-deal_index="8">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            50.00
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            USD
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="159342" data-deal_index="8">X</span>
        </td>
      </tr>
      <tr class="cart-table__row">
        <td>
          <div class="cart-product">
            <div class="cart-product__media">
              <img class="cart-product__image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
                alt="no image" />
            </div>
            <div class="cart-product__main">
              <span class="cart-product__capture">
                Category & Category
              </span>
              <h4 class="cart-product__title">
                <a class="cart-product__link" href="#">
                  Lorem ipsum dolor sit amet
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td>
          <span class="cart-product__cost">
            4.50
          </span>
          <span class="cart-product__currency">
            ILS
          </span>
        </td>
        <td>
          <form class="cart-quantity-form cart-quantity-form--full">
            <button class="cart-quantity-form__button cart-quantity-form__button--minus" type="button"
              data-product_id="160595" data-deal_index="9">-</button>
            <input class="cart-quantity-form__input" type="text" placeholdex="XXX" maxlength="4" value="1"
              data-product_id="160595" data-deal_index="9">
            <button class="cart-quantity-form__button cart-quantity-form__button--plus" type="button"
              data-product_id="160595" data-deal_index="9">+</button>
          </form>
        </td>
        <td>
          <span class="cart-product__cost cart-product__cost--total">
            4.50
          </span>
          <span class="cart-product__currency cart-product__currency--total">
            ILS
          </span>
        </td>
        <td class="cart-table__remove">
          <span class="cart-table__remove-icon" data-product_id="160595" data-deal_index="9">X</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="6">
          <div class="ct-pagination">
            <button class="ct-pagination__button ct-pagination__button--disabled" disabled="">
              &lt;
            </button>
            <button class="ct-pagination__button ct-pagination__button--active" data-page="1">
              1
            </button>
            <button class="ct-pagination__button" data-page="2">
              2
            </button>
            <span class="ct-pagination__separator">...</span>
            <button class="ct-pagination__button" data-page="10">
              10
            </button>
            <button class="ct-pagination__button" data-page="2">
              &gt;
            </button>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
`;

export default cartTableContent;
