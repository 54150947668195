export default function singleProductWithPaginationBlock({ editor, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makeStarIcon(filled, width) {
      return filled
        ? `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="${width}px">
            <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.3 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
            </path>
          </svg>`
        : `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="${width}px">
            <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 405.5 142.6 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z">
            </path>
          </svg>`;
    }

    function makeProductHtml(
      id,
      src,
      title,
      description,
      cost,
      discount_percent,
      coupon,
      currency,
      detailsPath,
      type,
      activeStatus,
      translations
    ) {
      const isActive =
        cost &&
        currency &&
        parseFloat(cost) > 0 &&
        window.localStorage.getItem("token") &&
        activeStatus;
      return `
      <div class="ps-product">
        ${
          src
            ? `<img class="ps-product__image" src="${src}" onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';" />`
            : `<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" class="ps-product__image" id="ik1dh"></img>`
        }
        <h4 class="ps-product__title">
          <a class="ps-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
            ${title}
          </a>
        </h4>
        <span class="ps-product__divider"></span>
        <div class="ps-product__description">
        ${
          description
            ? description.length <= 300
              ? description
              : `${description.slice(300)}...`
            : translations["No description"]
        }
        </div>
        <p class="ps-product__meta ps-product__meta--cost">
        <span class="ps-product__cost-title">
          ${translations["Cost:"]} 
        </span>
        ${
          cost && currency
            ? `
          ${
            discount_percent
              ? `
            <span class="ps-product__cost ps-product__cost--discount">
              ${cost}
            </span>
            <span class="ps-product__discount">
              ${(cost - (cost / 100) * discount_percent).toFixed(2)}
            </span> 
            <span class="ps-product__currency">
              ${currency}
            </span>
            <span class="ps-product__discount-percent">
              -${discount_percent.toFixed(2)}%
            </span>
          `
              : `
            <span class="ps-product__cost">
              ${cost}
            </span>
            <span class="ps-product__currency">
              ${currency}
            </span>
          `
          }
          ${
            coupon &&
            coupon.date.start &&
            new Date().getTime() - new Date(coupon.date.start).getTime() >= 0 &&
            coupon.date.end &&
            new Date(coupon.date.end).getTime() - new Date().getTime() >= 0
              ? `
              <div
                class="ps-coupon"
                data-data-end="${coupon.date.end}"
                data-data-start="${coupon.date.start}"
              >
                <div class="ps-coupon__header">
                  <span class="ps-coupon__cost">
                    ${(cost - (cost / 100) * coupon.discountPercent).toFixed(2)}
                  </span>
                  <span class="ps-coupon__currency">
                    ${currency}
                  </span>
                  <span class="ps-coupon__percent-discount">
                    -${coupon.discountPercent}%
                  </span>
                  <span class="ps-coupon__message">
                    ${translations["Hurry up! Coupon Ends in"]}
                  </span>
                </div>
                <div class="ps-coupon__grid" >
                  <span class="ps-coupon__item">
                    <span class="ps-coupon__counter ps-coupon__counter--days">
                      000
                    </span>
                    <span class="ps-coupon__label ps-coupon__label--days">
                      Days
                    </span>
                  </span>
                  <span class="ps-coupon__delimiter">
                    :
                  </span>
                  <span class="ps-coupon__item">
                    <span class="ps-coupon__counter ps-coupon__counter--hours">
                      00
                    </span>
                    <span class="ps-coupon__label ps-coupon__label--hours">
                      Hours
                    </span>
                  </span>
                  <span class="ps-coupon__delimiter">
                    :
                  </span>
                  <span class="ps-coupon__item">
                    <span class="ps-coupon__counter ps-coupon__counter--minutes">
                      00
                    </span>
                    <span class="ps-coupon__label ps-coupon__label--minutes">
                      Minutes
                    </span>
                  </span>
                  <span class="ps-coupon__delimiter">
                    :
                  </span>
                  <span class="ps-coupon__item">
                    <span class="ps-coupon__counter ps-coupon__counter--seconds">
                      00
                    </span>
                    <span class="ps-coupon__label ps-coupon__label--seconds">
                      Seconds
                    </span>
                  </span>
                </div>
              </div>
              `
              : ""
          }
        `
            : `
          <span class="ps-product__cost">
            ${translations["N/A"]}
          </span>
        `
        }
        </p>
        <div class="ps-product__actions">
          <button
            class="ps-product__button ${
              !isActive ? "ps-product__button--disabled" : ""
            }"
            data-product_id="${id}"
            ${!isActive ? "disabled" : ""}
          >
            ${translations["BUY"]}
          </button>
          <span
            class="ps-product__wish ${
              !isActive ? "ps-product__wish--disabled" : ""
            }"
            data-product_id="${id}"
            data-wish="false"
          >
            ${makeStarIcon(false, 45)}
          </span>
        </div>
      </div>
      `;
    }

    function setLoading(wrapperEl, state) {
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("product-single-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("product-single-wrapper--preloader");
      }
    }

    function render(id, type, detailsPath, translations) {
      const API_ROOT = "{[ apiRoot ]}";
      const wrapperEl = this;

      fetch(`${API_ROOT}/${type}/product/${id}/`)
        .then(response => response.json())
        .then(product => {
          wrapperEl.innerHTML = makeProductHtml(
            product.id,
            product.image,
            product.name,
            product.short_description,
            product.cost,
            product.discount_percent,
            product.coupon_discount_percent
              ? {
                  discountPercent: product.coupon_discount_percent,
                  date: {
                    start: product.metadata.coupon_start_date,
                    end: product.metadata.coupon_end_date
                  }
                }
              : null,
            product.currency,
            detailsPath,
            type,
            product.is_active,
            translations
          );

          setInterval(
            () =>
              Array.prototype.slice
                .call(this.querySelectorAll(".ps-coupon"))
                .map(couponEl => {
                  const endDate = couponEl.dataset.dataEnd;
                  if (!endDate) return false;

                  const second = 1000;
                  const minute = second * 60;
                  const hour = minute * 60;
                  const day = hour * 24;

                  const nowDateObj = new Date();
                  const endDateObj = new Date(endDate);
                  const distance = endDateObj.getTime() - nowDateObj.getTime();

                  const secondsCounterEl = couponEl.querySelector(
                    ".ps-coupon__counter--seconds"
                  );
                  const minutesCounterEl = couponEl.querySelector(
                    ".ps-coupon__counter--minutes"
                  );
                  const hoursCounterEl = couponEl.querySelector(
                    ".ps-coupon__counter--hours"
                  );
                  const daysCounterEl = couponEl.querySelector(
                    ".ps-coupon__counter--days"
                  );

                  const counters = {
                    days: Math.floor(distance / day),
                    hours: Math.floor((distance % day) / hour),
                    minutes: Math.floor((distance % hour) / minute),
                    seconds: Math.floor((distance % minute) / second)
                  };
                  // (1, 2) => "01", (52, 2) => "52"
                  const numberToStringWithZeros = (number, count) =>
                    Math.pow(10, count)
                      .toString()
                      .slice(1)
                      .concat(number)
                      .slice(-count);

                  secondsCounterEl.innerText = numberToStringWithZeros(
                    counters.seconds,
                    2
                  );
                  minutesCounterEl.innerText = numberToStringWithZeros(
                    counters.minutes,
                    2
                  );
                  hoursCounterEl.innerText = numberToStringWithZeros(
                    counters.hours,
                    2
                  );
                  daysCounterEl.innerText = numberToStringWithZeros(
                    counters.days,
                    3
                  );

                  return counters;
                }),
            980
          );

          const buyButtonsEls = this.querySelectorAll(
            ".ps-product__button:not(.ps-product__button--disabled)"
          );
          Array.prototype.slice.call(buyButtonsEls).map(buyButtonEl =>
            buyButtonEl.addEventListener("click", e => {
              const productId = parseInt(e.target.dataset.product_id);
              if (e.target.disabled) return;

              fetch(`${API_ROOT}/payments/cart/`, {
                method: "POST",
                headers: Object.assign(
                  {
                    "Accept-Language": localStorage.getItem("language"),
                    "Content-Type": "application/json"
                  },
                  localStorage.getItem("token")
                    ? {
                        Authorization: `Token ${localStorage.getItem("token")}`
                      }
                    : {}
                ),
                cors: "cors",
                credentials: "omit",
                body: JSON.stringify({
                  item_b2c: type === "b2c" ? productId : "",
                  item_b2b: type === "b2b" ? productId : "",
                  quantity: 1,
                  customer_type: "person",
                  customer_organization: ""
                })
              }).then(() => {
                e.target.innerText = translations["ADDED"];
                e.target.setAttribute("disabled", "true");
                e.target.classList.add("ps-product__button--disabled");
              });
            })
          );

          const wishEls = this.querySelectorAll(
            ".ps-product__wish:not(.ps-product__wish--disabled)"
          );
          Array.prototype.slice.call(wishEls).map(wishEl =>
            wishEl.addEventListener("click", e => {
              const target = e.target.closest(".ps-product__wish");
              const productId = parseInt(target.dataset.product_id);
              const inWishList = target.dataset.wish === "true";
              if (inWishList) return;

              fetch(`${API_ROOT}/wish_list/wish_list/`, {
                method: "POST",
                headers: Object.assign(
                  {
                    "Accept-Language": localStorage.getItem("language"),
                    "Content-Type": "application/json"
                  },
                  localStorage.getItem("token")
                    ? {
                        Authorization: `Token ${localStorage.getItem("token")}`
                      }
                    : {}
                ),
                cors: "cors",
                credentials: "omit",
                body: JSON.stringify({
                  item_b2c: type === "b2c" ? productId : "",
                  item_b2b: type === "b2b" ? productId : ""
                })
              }).then(() => {
                target.innerHTML = makeStarIcon(true, 45);
                target.setAttribute("data-wish", "true");
              });
            })
          );

          setLoading(false);
        });
    }

    if (!window.hasOwnProperty("grapesjs")) {
      return;
    }

    const attributes = this.attributes;

    const id = attributes.productid
      ? attributes.productid.value.toLowerCase()
      : 0;

    const type = attributes.producttype
      ? attributes.producttype.value.toLowerCase()
      : "b2c";

    const detailsPath = attributes.detailspage
      ? attributes.detailspage.value
      : "#";

    const translations = getTranslate("translations", attributes);

    render.call(this, id, type, detailsPath, translations);
  };

  editor.DomComponents.addType("product-single", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "product-single-wrapper",
    model: {
      defaults: {
        name: "Product Single",
        apiRoot: api.API_ROOT,
        script,
        traits: [
          {
            type: "select",
            label: "Product Type",
            name: "producttype",
            placeholder: "B2C",
            options: [
              { id: "b2c", name: "B2C" },
              { id: "b2b", name: "B2B" }
            ]
          },
          {
            type: "number",
            label: "Product ID",
            name: "productid",
            placeholder: "123456XX"
          },
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "No description",
              "Cost:",
              "N/A",
              "BUY",
              "ADDED",
              "Hurry up! Coupon Ends in"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:productid", this.render);
        this.listenTo(this.model, "change:attributes:producttype", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
