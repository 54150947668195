export default function similarProductsComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function makeProductHtml(
      id,
      src,
      title,
      cost,
      currency,
      detailsPath,
      type,
      translations
    ) {
      return `
      <div class="sp-product">
        <img
          class="sp-product__image"
          src="${src}"
          onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
        />
        <div class="sp-product__main">
          <h4 class="sp-product__title">
            <a class="sp-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
              ${title}
            </a>
          </h4>
          <p class="sp-product__meta sp-product__meta--cost">
          ${
            cost && currency
              ? `
            ${`
              <span class="sp-product__cost">
                ${cost}
              </span>
              <span class="sp-product__currency">
                ${currency}
              </span>
            `}
          `
              : `
            <span class="sp-product__cost">
              ${translations["N/A"]}
            </span>
          `
          }
          </p>
          <a class="sp-product__link" href="${detailsPath}?gjs-product-id=${id}&gjs-product-type=${type}">
            <button class="sp-product__button">
              ${translations["VIEW"]}
            </button>
          </a>
        </div>
      </div>
      `;
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".similar-products-wrapper");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("similar-products-wrapper--preloader");
      } else {
        wrapperEl.classList.remove("similar-products-wrapper--preloader");
      }
    }

    function render(
      type,
      total,
      page,
      perPage,
      products,
      categories,
      detailsPath,
      loading,
      translations
    ) {
      const wrapperEl = document.querySelector(".similar-products-wrapper");
      const isRTL = window.localStorage.getItem("direction") === "rtl";
      setLoading(loading);

      if (products.length) {
        const productsHtml = products
          .filter(product => product.cost > 0)
          .reduce(
            (tmp, product) =>
              tmp +
              makeProductHtml(
                product.id,
                product.image,
                product.name,
                product.cost,
                product.currency,
                detailsPath,
                type,
                translations
              ),
            ""
          );

        wrapperEl.innerHTML = `
          <div class="similar-products__title">Similar Products</div>
          <div class="similar-products ${isRTL ? "similar-products_rtl" : ""}">
            ${productsHtml}
          </div>  
        `;
      } else {
        wrapperEl.innerHTML = `
          <p style="margin: 0">
            <span class="similar-products__empty">
              ${translations["No similar products..."]}
            </span>
          </p>
        `;
        return;
      }
    }

    if (!window.hasOwnProperty("gjs_similar_products")) {
      const API_ROOT = "{[ apiRoot ]}";
      const STATIC_ROOT = "{[ staticRoot ]}";

      window.gjs_similar_products = {
        API_ROOT: API_ROOT,
        STATIC_ROOT: STATIC_ROOT,
        renderCycle: [],
        count: 10,
        page: 1,
        perPage: 10,
        type: "b2c",
        params: {},
        items: [],
        categories: [],
        loading: false,
        translations: {},
        detailsPath: "#",
        render() {
          console.group("similar products render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func =>
            func(
              this.type,
              this.count,
              this.page,
              this.perPage,
              this.items,
              this.categories,
              this.detailsPath,
              this.loading,
              this.translations
            )
          );
          console.log("render after new cycle", this);
          console.groupEnd("similar products render");
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setPage(page) {
          this.setProp("page", page, this.getProducts.bind(this));
        },
        setPerPage(count) {
          this.setParam("page_size", parseInt(count));
        },
        setType(type) {
          this.setProp("type", type);
        },
        setDetailsPath(path) {
          this.setProp("detailsPath", path);
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getProducts(immediately) {
          if (this.loading && !immediately) return;
          this.setLoading(true);

          const handleSimilarProductsResponse = (response, count, params) => {
            if (
              response &&
              response.hasOwnProperty("results") &&
              response.results.length
            ) {
              return this.setProducts(count, response.results);
            } else {
              const categoriesIds = this.categories.map(item => item.id).join();
              return fetch(
                `${this.API_ROOT}/${this.type}/product/?${params}&categories=${categoriesIds}`,
                {
                  method: "GET",
                  headers: {
                    "Accept-Language": localStorage.getItem("language")
                  }
                }
              )
                .then(response => response.json())
                .then(response => {
                  const count = response.count;
                  const products = response.results;

                  if (products.length) {
                    this.setProducts(
                      count,
                      products.filter(
                        product => product.id !== parseInt(this.params.products)
                      )
                    );
                  } else {
                    this.setProducts(count, products);
                  }
                });
            }
          };

          const params = new URLSearchParams(this.params);
          return fetch(`${this.API_ROOT}/similar/${this.type}/?${params}`, {
            method: "GET",
            headers: {
              "Accept-Language": localStorage.getItem("language")
            }
          })
            .then(response => response.json())
            .then(response =>
              handleSimilarProductsResponse(response, response.count, params)
            )
            .catch(response =>
              handleSimilarProductsResponse(response, 10, params)
            );
        },
        setProducts(count, items) {
          // Compare items
          if (JSON.stringify(this.items) !== JSON.stringify(items)) {
            this.render();
          }
          // Set count
          this.count = count;
          // Set items
          this.items = items;
          // Start render cycle
          this.setLoading(false);
          this.render();
        },
        setCategories(categories) {
          // Reset page
          this.page = 1;
          // Set categories
          this.categories = categories
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => (a.selected ? -1 : 1));
        }
      };
    }

    const attributes = this.attributes;
    if (
      window.hasOwnProperty("gjs_similar_products") &&
      window.hasOwnProperty("grapesjs")
    ) {
      if (
        !window.gjs_similar_products.renderCycle.find(func => func === render)
      ) {
        window.gjs_similar_products.renderCycle.push(render);
      }

      if (attributes.producttype) {
        window.gjs_similar_products.setType(attributes.producttype.value);
      }

      if (attributes.perpage) {
        window.gjs_similar_products.setPerPage(attributes.perpage.value);
      }

      if (attributes.detailspage) {
        window.gjs_similar_products.setDetailsPath(
          attributes.detailspage.value
        );
      }

      window.gjs_similar_products_interval = setInterval(() => {
        if (!window.hasOwnProperty("gjs_product_details")) {
          return;
        } else {
          clearInterval(window.gjs_similar_products_interval);
        }

        const params = new URLSearchParams(window.location.search);
        const productId = params.has("gjs-product-id")
          ? params.get("gjs-product-id")
          : window.gjs_product_details.id;
        const productType = params.has("gjs-product-type")
          ? params.get("gjs-product-type")
          : window.gjs_product_details.type;
        const productCategories = window.gjs_product_details.categories;
        const organization = "{[ organization ]}";

        window.gjs_similar_products.setType(productType);
        window.gjs_similar_products.setCategories(productCategories);

        window.gjs_similar_products.setParam("products", productId);
        window.gjs_similar_products.setParam("company", organization);

        const translations = getTranslate("translations", attributes);
        window.gjs_similar_products.setProp("translations", translations);

        window.gjs_similar_products.getProducts(true);
      }, 600);
    }
  };

  editor.DomComponents.addType("similar-products", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "similar-products-wrapper",
    model: {
      defaults: {
        tagName: "div",
        name: "Similar Products",
        apiRoot: api.API_ROOT,
        staticRoot: api.STATIC_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "page-selector",
            label: "Product Details",
            name: "detailspage",
            modalTitle: "Select product details page"
          },
          {
            type: "number",
            label: "Products per page",
            name: "perpage",
            placeholder: "10"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["No similar products...", "N/A", "VIEW"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:perpage", this.render);
        this.listenTo(this.model, "change:attributes:producttype", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
