import React from "react";
import { Link } from "react-router-dom";

import { DefaultButton } from "@core/components";

import { colorPalette } from "~/styles/colorPalette";

import "./my-business-add-bar.styles.scss";
import { useTranslation } from "react-i18next";

const MyBusinessAddBar = ({ title, link, onClickEvent = null }) => {
  const { t } = useTranslation();

  return (
    <div className="my-business-add-bar">
      <div className="my-business-add-bar__title">{title}</div>
      {link && (
        <div className="my-business-add-bar__button">
          <Link to={link}>
            <DefaultButton
              color="white"
              backgroundColor={colorPalette.colorPrimary.main}
              hover={colorPalette.colorPrimary.darker}
              fontSize="15px"
              labelHeight="20px"
              height="100%"
              width="100%"
              onClick={onClickEvent}
            >
              {t("Add +")}
            </DefaultButton>
          </Link>
        </div>
      )}
    </div>
  );
};

export default MyBusinessAddBar;
