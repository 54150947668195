import { PartnerActionTypes } from "./partners.types";

const INITIAL_STATE = {
  areHomeBannersPending: false,
  homeBanners: [],
  homeBannersError: {}
};

const PartnersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PartnerActionTypes.GET.GET_HOME_BANNERS_PENDING:
      return { ...state, areHomeBannersPending: true };

    case PartnerActionTypes.GET.GET_HOME_BANNERS_SUCCESS:
      return {
        ...state,
        homeBanners: action.payload,
        areHomeBannersPending: false
      };

    case PartnerActionTypes.GET.GET_HOME_BANNERS_ERROR:
      return {
        ...state,
        homeBannersError: action.payload,
        areHomeBannersPending: false
      };

    default:
      return state;
  }
};

export default PartnersReducer;
