import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { ParentWrapper, ButtonsFooterWrapper } from "../../../layout";
import { InputGroup, DefaultButton } from "../../../components";

import {
  createDepartment,
  editDepartment,
  getDepartmentById,
  clearDepartmentCreate
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-add-department.styles.scss";

const MyBusinessAddDepartment = ({
  createDepartment,
  editDepartment,
  getDepartmentById,
  currentDepartmentById,
  departmentRequestStatus,
  clearDepartmentCreate
}) => {
  const history = useHistory();
  const { companyId, departmentId } = useParams();

  const [nameDepartment, setNameDepartment] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    if (departmentId !== "add-department") {
      getDepartmentById(departmentId);
      setNameDepartment(currentDepartmentById.name);
    }
  }, [
    getDepartmentById,
    departmentId,
    setNameDepartment,
    currentDepartmentById.name
  ]);

  useEffect(() => {
    if (departmentRequestStatus === 200 || departmentRequestStatus === 201) {
      history.goBack();
    }
  }, [departmentRequestStatus]); // eslint-disable-line

  useEffect(() => {
    setDisabledButton(nameDepartment === "");
  }, [nameDepartment]); // eslint-disable-line

  useEffect(() => () => clearDepartmentCreate(), []); // eslint-disable-line

  const editDepartmentHandler = () => {
    if (departmentId === "add-department") {
      createDepartment(nameDepartment, companyId);
    } else {
      editDepartment(departmentId, nameDepartment, companyId);
    }
  };

  const cancelDepartment = () => {
    history.goBack();
  };

  return (
    <div className="my-business-add-department-container">
      <ParentWrapper>
        <InputGroup
          onChange={e => setNameDepartment(e.target.value)}
          value={nameDepartment || ""}
          label="Department name"
          name="departmentName"
          placeholder="Department name..."
          required={true}
        />
      </ParentWrapper>
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            width="100%"
            {...buttonOk}
            onClick={editDepartmentHandler}
            disabled={disabledButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            width="100%"
            {...buttonCancel}
            onClick={cancelDepartment}
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  currentCompany: state.vacanciesReducer.departmentCurrentCompany,
  currentDepartmentById: state.vacanciesReducer.departmentById,
  departmentRequestStatus: state.vacanciesReducer.departmentRequestStatus
});

export default connect(mapStateToProps, {
  createDepartment,
  editDepartment,
  getDepartmentById,
  clearDepartmentCreate
})(MyBusinessAddDepartment);
