import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logIn } from "~/redux/actions-exporter";
import { useTranslation } from "react-i18next";
import { DefaultButton } from "@core/components";

import { colorPalette } from "../../styles/colorPalette";

const LoginInput = ({ logIn, logInPending, redirect }) => {
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const { t } = useTranslation();
  return (
    <form onSubmit={e => e.preventDefault()}>
      <p>{t("Email")}</p>
      <input
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />
      <p>{t("Password")}</p>
      <input
        value={password}
        onChange={e => setPass(e.target.value)}
        type="password"
      />
      <div className="login-modal__body-column__signin-forgot-pass">
        <label>
          <input type="checkbox" />
          {t("Keep me signed in")}
        </label>
        <Link to="/password/reset">{t("Forgot password?")}</Link>
      </div>
      <DefaultButton
        onClick={() => logIn({ email, password, redirect })}
        color="white"
        backgroundColor={colorPalette.colorPrimary.main}
        hover={colorPalette.colorPrimary.darker}
        width="100%"
        disabled={logInPending}
        ButtonOptions={{
          type: "submit"
        }}
      >
        {t("Sign In")}
      </DefaultButton>
    </form>
  );
};

const mapStateToProps = state => ({
  logInPending: state.authReducer.logInPending
});

export default connect(mapStateToProps, { logIn })(LoginInput);
