export const companies = [
  {
    _id: "5dbad5d1931ff42202fdcdd2",
    index: 0,
    picture: "https://via.placeholder.com/200",
    name: "ullamco sunt magna elit est mollit incididunt proident velit mollit",
    description:
      "Duis pariatur aute reprehenderit aliqua qui minim commodo mollit magna excepteur nulla fugiat laborum consequat.",
    location: "amet laboris",
    phone: "+4 (437) 397-6759",
    website: "nisi.md"
  },
  {
    _id: "5dbad5d137ef534beea51bb9",
    index: 1,
    picture: "https://via.placeholder.com/200",
    name:
      "officia id culpa qui eiusmod proident voluptate aliqua excepteur labore",
    description:
      "Veniam consectetur consectetur sit reprehenderit tempor pariatur mollit deserunt esse do ea ipsum duis exercitation.",
    location: "enim irure",
    phone: "+4 (737) 200-4729",
    website: "sint.md"
  },
  {
    _id: "5dbad5d150af90e50f4595b3",
    index: 2,
    picture: "https://via.placeholder.com/200",
    name: "enim nostrud ad elit minim nisi ipsum proident laboris tempor",
    description: "Cupidatat proident proident ut velit anim occaecat.",
    location: "fugiat do",
    phone: "+2 (605) 251-8113",
    website: "fugiat.md"
  },
  {
    _id: "5dbad5d1e404acc65968054d",
    index: 3,
    picture: "https://via.placeholder.com/200",
    name:
      "enim qui deserunt labore anim consequat deserunt eiusmod aliqua elit",
    description: "Tempor incididunt dolor dolore irure nulla nisi aliquip.",
    location: "excepteur id",
    phone: "+1 (189) 172-6579",
    website: "veniam.md"
  },
  {
    _id: "5dbad5d14dfe349c52f34618",
    index: 4,
    picture: "https://via.placeholder.com/200",
    name: "magna in reprehenderit do dolor ullamco laboris sint sunt ea",
    description:
      "Pariatur incididunt labore sunt ipsum pariatur non cillum anim.",
    location: "voluptate consequat",
    phone: "+6 (317) 333-3551",
    website: "aliquip.md"
  },
  {
    _id: "5dbad5d125521d56e92bd899",
    index: 5,
    picture: "https://via.placeholder.com/200",
    name:
      "mollit reprehenderit ullamco quis tempor labore nostrud cillum quis quis",
    description:
      "Labore ad fugiat ex nulla labore commodo laborum nisi veniam culpa.",
    location: "et mollit",
    phone: "+9 (336) 145-1251",
    website: "tempor.md"
  },
  {
    _id: "5dbad5d1ca500f219c0dcadf",
    index: 6,
    picture: "https://via.placeholder.com/200",
    name: "reprehenderit nisi eu velit sunt nostrud nostrud occaecat Lorem do",
    description: "Culpa ullamco magna voluptate eu duis ipsum aliquip.",
    location: "cupidatat velit",
    phone: "+9 (921) 197-5023",
    website: "dolor.md"
  },
  {
    _id: "5dbad5d148eecfc5162aeaca",
    index: 7,
    picture: "https://via.placeholder.com/200",
    name:
      "incididunt do est nostrud ipsum tempor officia consectetur ullamco quis",
    description:
      "Sunt velit reprehenderit incididunt laboris exercitation minim anim consequat duis dolor.",
    location: "ex consectetur",
    phone: "+6 (607) 477-8256",
    website: "nostrud.md"
  },
  {
    _id: "5dbad5d167a712e636f46b2b",
    index: 8,
    picture: "https://via.placeholder.com/200",
    name:
      "adipisicing occaecat adipisicing elit eu reprehenderit reprehenderit officia mollit culpa",
    description:
      "Irure Lorem cupidatat velit laboris ut cupidatat tempor aliquip cupidatat cillum.",
    location: "deserunt nisi",
    phone: "+1 (500) 649-6754",
    website: "mollit.md"
  },
  {
    _id: "5dbad5d1e672c0c81ded5a8d",
    index: 9,
    picture: "https://via.placeholder.com/200",
    name: "et voluptate irure laboris tempor dolor eiusmod nostrud sit aliquip",
    description: "Nulla duis ad nostrud voluptate anim ex consectetur.",
    location: "tempor nisi",
    phone: "+3 (371) 935-9287",
    website: "Lorem.md"
  },
  {
    _id: "5dbad5d15e4ad7d8ad011957",
    index: 10,
    picture: "https://via.placeholder.com/200",
    name: "occaecat eiusmod aute culpa elit et ipsum quis cupidatat esse",
    description: "Cillum sint enim eiusmod adipisicing.",
    location: "aliqua deserunt",
    phone: "+1 (762) 797-2814",
    website: "do.md"
  },
  {
    _id: "5dbad5d117f415ae154bfff0",
    index: 11,
    picture: "https://via.placeholder.com/200",
    name:
      "officia irure consequat nulla ex non pariatur mollit exercitation nisi",
    description: "Incididunt ex incididunt aute ipsum.",
    location: "reprehenderit occaecat",
    phone: "+8 (658) 551-2570",
    website: "qui.md"
  },
  {
    _id: "5dbad5d1cd44a43c125ec3b9",
    index: 12,
    picture: "https://via.placeholder.com/200",
    name:
      "officia dolore in minim mollit reprehenderit officia tempor tempor eiusmod",
    description: "Sunt in commodo duis mollit incididunt qui cupidatat labore.",
    location: "proident anim",
    phone: "+2 (907) 952-8916",
    website: "ex.md"
  },
  {
    _id: "5dbad5d13c49aff8d054e2c7",
    index: 13,
    picture: "https://via.placeholder.com/200",
    name:
      "consectetur eu deserunt cupidatat sit cupidatat consectetur cupidatat duis et",
    description:
      "In sint laborum voluptate do cillum magna adipisicing duis sunt aute dolor exercitation ut laboris.",
    location: "consectetur est",
    phone: "+3 (532) 252-6960",
    website: "sunt.md"
  },
  {
    _id: "5dbad5d15a81c33d4d1454b2",
    index: 14,
    picture: "https://via.placeholder.com/200",
    name: "sint aliqua Lorem eu consequat Lorem ad excepteur Lorem veniam",
    description:
      "Aliquip excepteur amet quis consequat sint quis cillum non mollit velit.",
    location: "sunt sint",
    phone: "+2 (149) 255-9564",
    website: "deserunt.md"
  },
  {
    _id: "5dbad5d194d182aa3281ad70",
    index: 15,
    picture: "https://via.placeholder.com/200",
    name: "aliquip commodo cupidatat et et culpa veniam ullamco ad commodo",
    description:
      "Exercitation minim magna ipsum ut occaecat id magna esse cillum amet do.",
    location: "ea minim",
    phone: "+8 (682) 635-7366",
    website: "do.md"
  },
  {
    _id: "5dbad5d1482506bc53ba9cd4",
    index: 16,
    picture: "https://via.placeholder.com/200",
    name: "ex voluptate velit nisi elit voluptate Lorem quis nostrud velit",
    description:
      "Eu velit aute incididunt voluptate aliqua nostrud cupidatat eiusmod ullamco.",
    location: "nisi cupidatat",
    phone: "+3 (509) 248-6433",
    website: "ipsum.md"
  },
  {
    _id: "5dbad5d1942bbdfeafbbd8a3",
    index: 17,
    picture: "https://via.placeholder.com/200",
    name:
      "nulla incididunt eiusmod consectetur qui cillum velit dolore voluptate est",
    description: "Sunt duis esse est occaecat.",
    location: "ex tempor",
    phone: "+7 (171) 169-9240",
    website: "deserunt.md"
  },
  {
    _id: "5dbad5d16234b8235b36428b",
    index: 18,
    picture: "https://via.placeholder.com/200",
    name:
      "cillum incididunt qui commodo dolore non dolore esse fugiat consequat",
    description: "Sint nostrud irure id anim deserunt.",
    location: "veniam voluptate",
    phone: "+3 (182) 784-1971",
    website: "non.md"
  },
  {
    _id: "5dbad5d1e4dbc05615601d95",
    index: 19,
    picture: "https://via.placeholder.com/200",
    name:
      "occaecat labore in eu Lorem eiusmod sint nostrud nostrud adipisicing",
    description:
      "Eu ea pariatur excepteur ad ex veniam velit culpa consequat voluptate.",
    location: "dolor consequat",
    phone: "+4 (404) 268-4362",
    website: "aute.md"
  },
  {
    _id: "5dbad5d1dfec7d62afc454b5",
    index: 20,
    picture: "https://via.placeholder.com/200",
    name: "adipisicing minim dolor enim enim cupidatat fugiat nisi non sit",
    description:
      "Commodo amet dolore anim mollit voluptate exercitation ipsum amet proident esse amet nisi aute.",
    location: "amet laborum",
    phone: "+3 (579) 181-1441",
    website: "sint.md"
  },
  {
    _id: "5dbad5d1161331ed3d6dfa90",
    index: 21,
    picture: "https://via.placeholder.com/200",
    name: "dolore quis excepteur non ut commodo et sint in officia",
    description:
      "Dolor nostrud sint consequat esse occaecat aute elit consectetur deserunt cupidatat.",
    location: "labore cupidatat",
    phone: "+3 (327) 148-9984",
    website: "ipsum.md"
  },
  {
    _id: "5dbad5d1ddf9dc8bcba49695",
    index: 22,
    picture: "https://via.placeholder.com/200",
    name: "ex in proident sint Lorem fugiat cillum ea adipisicing ex",
    description: "Eu qui consequat mollit aliquip laboris consectetur esse.",
    location: "reprehenderit exercitation",
    phone: "+5 (496) 764-2660",
    website: "occaecat.md"
  },
  {
    _id: "5dbad5d151883d7cf1c32c18",
    index: 23,
    picture: "https://via.placeholder.com/200",
    name: "veniam deserunt non est exercitation eiusmod aute qui deserunt nisi",
    description:
      "Est nulla consectetur in cupidatat deserunt labore amet consequat.",
    location: "ut amet",
    phone: "+3 (260) 347-2408",
    website: "elit.md"
  },
  {
    _id: "5dbad5d1a170c9e2609f5f8a",
    index: 24,
    picture: "https://via.placeholder.com/200",
    name: "laborum ad amet esse deserunt eu ullamco pariatur anim ex",
    description:
      "Sit exercitation reprehenderit ex consequat id voluptate sint.",
    location: "adipisicing nostrud",
    phone: "+1 (267) 688-8773",
    website: "veniam.md"
  }
];
