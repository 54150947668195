import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  InputGroup,
  DefaultButton,
  DefaultDropdownMultiSelect
} from "../../../components";

import { ParentWrapper, ButtonsFooterWrapper } from "../../../layout";

import {
  addDepartmentVacancy,
  editDepartmentVacancy,
  getStaffGroup,
  getPermissionsGroup,
  getVacancyById,
  getDepartmentVacancyById,
  setAddVacancyDataAction,
  clearDepartmentCreate
} from "../../../redux/actions-exporter";

import { buttonCancel, buttonOk } from "../../../styles/buttons-styles";
import "./my-business-department-vacancy.styles.scss";

const MyBusinessDepartmentVacancy = ({
  addDepartmentVacancy,
  editDepartmentVacancy,
  staffGroup,
  permissionsGroup,
  getStaffGroup,
  getPermissionsGroup,
  getDepartmentVacancyById,
  departmentRequestStatus,
  clearDepartmentCreate,
  departmentVacancyById
}) => {
  const { vacancyId, departmentId } = useParams();
  const history = useHistory();

  const [vacancyName, setVacancyName] = useState("");
  const [vacancyStaffGroupArr, setVacancyStaffGroupArr] = useState([]);
  const [vacancyStaffShow, setVacancyStaffShow] = useState([]);
  const [vacancyPermissionsArr, setVacancyPermissionsArr] = useState([]);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getStaffGroup();
    getPermissionsGroup();
  }, [getStaffGroup, getPermissionsGroup]);

  useEffect(() => {
    if (vacancyId !== "create") {
      getDepartmentVacancyById(vacancyId);
    }
  }, [vacancyId, getDepartmentVacancyById]);

  useEffect(() => {
    if (vacancyId !== "create") {
      const {
        name,
        staffgroup,
        permission_extra_group
      } = departmentVacancyById;

      setVacancyName(name);
      setVacancyStaffGroupArr(
        staffgroup &&
          staffgroup.map(({ group: { id, name } }) => ({ id, name }))
      );
      setVacancyStaffShow(
        staffgroup &&
          staffgroup.map(({ group: { id, name } }) => ({ id, name }))
      );
      setVacancyPermissionsArr(
        permission_extra_group && permission_extra_group.map(({ id }) => id)
      );
    }
  }, [vacancyId, departmentVacancyById]);

  useEffect(() => {
    if (departmentRequestStatus === 200 || departmentRequestStatus === 201) {
      history.goBack();
    }
  }, [departmentRequestStatus, history]);

  useEffect(() => {
    setDisabled(
      vacancyName === "" ||
        (vacancyStaffGroupArr && vacancyStaffGroupArr.length < 1) ||
        (vacancyPermissionsArr && vacancyPermissionsArr.length < 1)
    );
  }, [vacancyName, vacancyStaffGroupArr, vacancyPermissionsArr]);

  useEffect(() => () => clearDepartmentCreate(), [clearDepartmentCreate]);

  const createDepartmentVacancy = () => {
    if (vacancyId === "create") {
      addDepartmentVacancy(
        vacancyName,
        departmentId,
        vacancyStaffGroupArr,
        vacancyPermissionsArr
      );
    } else {
      editDepartmentVacancy(
        vacancyId,
        vacancyName,
        vacancyStaffGroupArr,
        vacancyPermissionsArr
      );
    }
  };

  const cancelVacancy = () => history.goBack();

  return (
    <div className="my-business-department-vacancy-container">
      <ParentWrapper>
        <InputGroup
          onChange={e => setVacancyName(e.target.value)}
          value={vacancyName}
          label="Vacancy name"
          name="vacancyName"
          placeholder="Vacancy name..."
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <DefaultDropdownMultiSelect
          label="SELECT STAFF GROUP"
          items={
            staffGroup &&
            staffGroup.map(({ group: { id, name } }) => ({
              id,
              name
            }))
          }
          returnData={setVacancyStaffGroupArr}
          customArray={
            vacancyId !== "create" &&
            vacancyStaffShow &&
            !!vacancyStaffShow.length &&
            vacancyStaffShow
          }
          required={true}
        />
        <DefaultDropdownMultiSelect
          label="SELECT ADDITIONAL PERMISSIONS"
          items={permissionsGroup}
          returnData={setVacancyPermissionsArr}
          customArray={
            vacancyId !== "create" &&
            departmentVacancyById.permission_extra_group
          }
        />
      </ParentWrapper>
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={createDepartmentVacancy}
            width="100%"
            {...buttonOk}
            disabled={disabled}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton onClick={cancelVacancy} width="100%" {...buttonCancel}>
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  isDepartmentVacancyByIdPending:
    state.vacanciesReducer.isDepartmentVacancyByIdPending,
  currentDepartment: state.vacanciesReducer.departmentCurrentDepartment,
  staffGroup: state.vacanciesReducer.staffGroup,
  permissionsGroup: state.vacanciesReducer.permissionsGroup,
  departmentVacancyById: state.vacanciesReducer.departmentVacancyById,
  departmentVacancyCurrent: state.vacanciesReducer.departmentVacancyCurrent,
  departmentRequestStatus: state.vacanciesReducer.departmentRequestStatus
});

export default connect(mapStateToProps, {
  addDepartmentVacancy,
  editDepartmentVacancy,
  getVacancyById,
  getDepartmentVacancyById,
  getStaffGroup,
  getPermissionsGroup,
  setAddVacancyDataAction,
  clearDepartmentCreate
})(MyBusinessDepartmentVacancy);
