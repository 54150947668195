import { InnovationProjectsActionTypes } from "./innovation-projects.types";

const INITIAL_STATE = {
  areInnovationProjectsPending: false,
  innovationProjectsCount: 0,
  innovationProjects: [],
  innovationProjectsError: null,

  isInnovationProjectPending: false,
  innovationProject: {},
  innovationProjectError: null,

  isInnovationProjectsByCurrentUserPending: false,
  innovationProjectsByCurrentUser: [],
  innovationProjectsByCurrentUserCount: 0,
  innovationProjectsByCurrentUserError: {},

  isPutPostInnovationProjectPending: false,
  putPostInnovationProject: {},
  putPostInnovationProjectError: {},

  innovationProjectsRequestStatus: 0
};

const InnovationProjectsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECTS_PENDING:
      return { ...state, areInnovationProjectsPending: true };

    case InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECTS_SUCCESS:
      return {
        ...state,
        innovationProjectsCount: action.payload.count,
        innovationProjects: action.payload.innovationProjects,
        innovationProjectsError: null,
        areInnovationProjectsPending: false
      };

    case InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECTS_ERROR:
      return {
        ...state,
        innovationProjectsError: action.payload,
        areInnovationProjectsPending: false
      };

    case InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECT_BY_ID_PENDING:
      return { ...state, isInnovationProjectPending: true };

    case InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        innovationProject: action.payload,
        innovationProjectError: null,
        isInnovationProjectPending: false
      };

    case InnovationProjectsActionTypes.GET.GET_INNOVATION_PROJECT_BY_ID_ERROR:
      return {
        ...state,
        innovationProjectError: action.payload,
        isInnovationProjectPending: false
      };

    case InnovationProjectsActionTypes.GET
      .GET_INNOVATION_PROJECTS_BY_CURRENT_USER_PENDING:
      return {
        ...state,
        isInnovationProjectsByCurrentUserPending: true
      };

    case InnovationProjectsActionTypes.GET
      .GET_INNOVATION_PROJECTS_BY_CURRENT_USER_SUCCESS:
      return {
        ...state,
        innovationProjectsByCurrentUserCount: action.payload.count,
        innovationProjectsByCurrentUser: action.payload.inProjects,
        isInnovationProjectsByCurrentUserPending: false
      };

    case InnovationProjectsActionTypes.GET
      .GET_INNOVATION_PROJECTS_BY_CURRENT_USER_ERROR:
      return {
        ...state,
        innovationProjectsByCurrentUserError: action.payload,
        isInnovationProjectsByCurrentUserPending: false
      };

    case InnovationProjectsActionTypes.POST.POST_INNOVATION_PROJECTS_PENDING:
      return {
        ...state,
        isPutPostInnovationProjectPending: true
      };

    case InnovationProjectsActionTypes.POST.POST_INNOVATION_PROJECTS_SUCCESS:
      return {
        ...state,
        putPostInnovationProject: action.payload.post,
        innovationProjectsRequestStatus: action.payload.status,
        isPutPostInnovationProjectPending: false
      };

    case InnovationProjectsActionTypes.POST.POST_INNOVATION_PROJECTS_ERROR:
      return {
        ...state,
        putPostInnovationProjectError: action.payload,
        isPutPostInnovationProjectPending: false
      };

    case InnovationProjectsActionTypes.PUT.PUT_INNOVATION_PROJECTS_PENDING:
      return {
        ...state,
        isPutPostInnovationProjectPending: true
      };

    case InnovationProjectsActionTypes.PUT.PUT_INNOVATION_PROJECTS_SUCCESS:
      return {
        ...state,
        putPostInnovationProject: action.payload.post,
        innovationProjectsRequestStatus: action.payload.status,
        isPutPostInnovationProjectPending: false
      };

    case InnovationProjectsActionTypes.PUT.PUT_INNOVATION_PROJECTS_ERROR:
      return {
        ...state,
        putPostInnovationProjectError: action.payload,
        isPutPostInnovationProjectPending: false
      };

    case InnovationProjectsActionTypes.CLEAR_INNOVATION_PROJECTS_REQUEST:
      return {
        ...state,
        innovationProjectsRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default InnovationProjectsReducer;
