import { ProductSwitchActionTypes } from "./product-switch.types";

export const toggleWallProductSwitch = () => ({
  type: ProductSwitchActionTypes.TOGGLE_WALL_PRODUCT_SWITCH
});

export const toggleAllProductsSwitch = status => ({
  type: ProductSwitchActionTypes.TOGGLE_ALL_PRODUCT_SWITCH,
  payload: status
});

export const toggleComOrgProductsSwitch = () => ({
  type: ProductSwitchActionTypes.TOGGLE_COMP_ORG_PRODUCTS_SWITCH
});
