export default function multiLanguageTextComponent({ editor, api, className }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };
    // Get translation
    const translations = getTranslate("translations", this.attributes);
    // Set transltion
    this.innerText = translations["Text"];
    const isRTL = window.localStorage.getItem("direction") === "rtl";
    if (isRTL) {
      this.style.textAlign = "right";
    }
  };

  editor.DomComponents.addType("multi-language-text", {
    isComponent: el =>
      el.tagName === "SPAN" && el.dataset.type === "multi-language-text",
    model: {
      defaults: {
        name: "Multi-language Text",
        apiRoot: api.API_ROOT,
        script,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Text"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });

  editor.on("canvas:drop", (DataTransfer, component) => {
    if (
      !component.hasOwnProperty("type") ||
      component.get("type") !== "multi-language-text"
    ) {
      return;
    }
    // Open translations modal
    component.translationsPreRender();
  });
}
