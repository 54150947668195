import React from "react";
import moment from "moment";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { UserPlaceholder } from "../../assets";
import { MyBusinessNoData } from "../../components";

import "./event-meeting.styles.scss";

const EventMeeting = ({ meetings }) => {
  return (
    <div className="event-meeting">
      <div className="event-meeting__table">
        {meetings.results && meetings.results.length ? (
          <Table className="meeting-table" aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="left">TITLE</TableCell>
                <TableCell align="left">START/END MEETING</TableCell>
                <TableCell align="left">VISITOR</TableCell>
                <TableCell align="left">MEETING RESPONSE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetings.results &&
                meetings.results.map(
                  ({
                    id,
                    title,
                    start_date,
                    end_date,
                    visitor: { avatar, user_email },
                    meeting_response
                  }) => (
                    <TableRow key={id}>
                      <TableCell align="left">
                        <div className="meeting-table__name">{title}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="meeting-table__date">
                          <div className="meet-start">
                            {start_date &&
                              moment(start_date).format("MMMM Do YYYY, h:mm")}
                          </div>
                          <div className="meet-end">
                            {end_date &&
                              moment(end_date).format("MMMM Do YYYY, h:mm")}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="meeting-table__visitor">
                          {avatar ? (
                            <img className="visitor-img" src={avatar} alt="" />
                          ) : (
                            <UserPlaceholder className="visitor-img" />
                          )}
                          <div className="visitor-email">{user_email}</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="meeting-table__response">
                          {meeting_response}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        ) : (
          <MyBusinessNoData noDataText="No event invitations!" />
        )}
      </div>
    </div>
  );
};

export default EventMeeting;
