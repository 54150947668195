import React from "react";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

import {
  SearchBar,
  LoadingSpinner,
  MyBusinessNoData
} from "../../../../components";

const CompanySection = ({ isEventByIdPending, companies }) => {
  return (
    <>
      <div className="my-business-event-invitations-inner-info__search">
        <SearchBar
        // onChange={e => {
        //   if (e.target.value) {
        //     searchResumesByCurrentUser(e.target.value);
        //   } else if (!e.target.value) {
        //     getResumesByCurrentUser();
        //   }
        // }}
        />
      </div>
      <div className="my-business-event-invitations-inner-info__table-data">
        {isEventByIdPending ? (
          <LoadingSpinner />
        ) : companies && companies.length ? (
          <Table className="events-invitations-table" aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="left">COMPANY NAME</TableCell>
                <TableCell align="left">EMAIL</TableCell>
                <TableCell align="left">PHONE</TableCell>
                <TableCell align="left">COMMENT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map(({ id, name, slug, logo, phone, comment }) => (
                <TableRow key={id}>
                  <TableCell>
                    <div className="events-invitations-table__company-image">
                      <div className="user-image">
                        <img src={logo} alt="" />
                      </div>
                      <div className="user-name">{name}</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="events-invitations-table__email">
                      {slug}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="events-invitations-table__phone">
                      {phone || "No phone!"}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="events-invitations-table__comment">
                      {comment || "No comment!"}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <MyBusinessNoData noDataText="No events invitation!" />
        )}
      </div>
    </>
  );
};

export default CompanySection;
