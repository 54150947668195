export const ClientActionTypes = {
  GET: {
    GET_CLIENTS_PENDING: "GET_CLIENTS_PENDING",
    GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
    GET_CLIENTS_ERROR: "GET_CLIENTS_ERROR",
    GET_CLIENT_PENDING: "GET_CLIENT_PENDING",
    GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
    GET_CLIENT_ERROR: "GET_CLIENT_ERROR"
  },
  CREATE: {
    CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
    CREATE_CLIENT_ERROR: "CREATE_CLIENT_ERROR"
  },
  UPDATE: {
    UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
    UPDATE_CLIENT_ERROR: "UPDATE_CLIENT_ERROR"
  },
  DELETE: {
    DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
    DELETE_CLIENT_ERROR: "DELETE_CLIENT_ERROR"
  },
  CLEAR_CLIENTS_REQUEST: "CLEAR_CLIENTS_REQUEST"
};
