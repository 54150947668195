export const FilterActionTypes = {
  GET: {
    COUNTRIES: {
      GET_COUNTRIES_FILTER_PENDING: "GET_COUNTRIES_FILTER_PENDING",
      GET_COUNTRIES_FILTER_SUCCESS: "GET_COUNTRIES_FILTER_SUCCESS",
      GET_COUNTRIES_FILTER_ERROR: "GET_COUNTRIES_FILTER_ERROR"
    },
    ORGANIZATIONS: {
      GET_ORGANIZATIONS_FILTER_PENDING: "GET_ORGANIZATIONS_FILTER_PENDING",
      GET_ORGANIZATIONS_FILTER_SUCCESS: "GET_ORGANIZATIONS_FILTER_SUCCESS",
      GET_ORGANIZATIONS_FILTER_ERROR: "GET_ORGANIZATIONS_FILTER_ERROR"
    },
    BRANCHES: {
      GET_BRANCHES_FILTER_PENDING: "GET_BRANCHES_FILTER_PENDING",
      GET_BRANCHES_FILTER_SUCCESS: "GET_BRANCHES_FILTER_SUCCESS",
      GET_BRANCHES_FILTER_ERROR: "GET_BRANCHES_FILTER_ERROR"
    },
    B2C_CATEGORIES: {
      GET_B2C_CATEGORIES_FILTER_PENDING: "GET_B2C_CATEGORIES_FILTER_PENDING",
      GET_B2C_CATEGORIES_FILTER_SUCCESS: "GET_B2C_CATEGORIES_FILTER_SUCCESS",
      GET_B2C_CATEGORIES_FILTER_ERROR: "GET_B2C_CATEGORIES_FILTER_ERROR"
    },
    B2B_CATEGORIES: {
      GET_B2B_CATEGORIES_FILTER_PENDING: "GET_B2B_CATEGORIES_FILTER_PENDING",
      GET_B2B_CATEGORIES_FILTER_SUCCESS: "GET_B2B_CATEGORIES_FILTER_SUCCESS",
      GET_B2B_CATEGORIES_FILTER_ERROR: "GET_B2B_CATEGORIES_FILTER_ERROR"
    }
  }
};
