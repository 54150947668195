import { LocalizationActionTypes } from "./localization.types";

const INITIAL_STATE = {
  burgerToggleOpen: false,
  appDirection: "ltr",
  isRTL: false,
  isAppLanguagePending: false,
  appLanguage:
    localStorage.userStorageLanguage ||
    navigator.language ||
    navigator.userLanguage ||
    "ENG",
  isPendingData: false,
  localizationTypesError: null,
  localizationData: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LocalizationActionTypes.SET_APPLICATION_DIRECTION:
      return {
        ...state,
        isRTL: action.payload.rtlStatus,
        appDirection: action.payload.direction
      };
    case LocalizationActionTypes.SET_APPLICATION_LANGUAGE_PENDING:
      return {
        ...state,
        isAppLanguagePending: true
      };
    case LocalizationActionTypes.SET_APPLICATION_LANGUAGE_SUCCESS:
      return {
        ...state,
        appLanguage: action.payload,
        isAppLanguagePending: false
      };
    case LocalizationActionTypes.GET_LOCALIZATION_DATA_PENDING:
      return {
        ...state,
        isPendingData: true
      };
    case LocalizationActionTypes.GET_LOCALIZATION_DATA_SUCCESS:
      return {
        ...state,
        localizationData: action.payload,
        isPendingData: false
      };
    case LocalizationActionTypes.GET_LOCALIZATION_DATA_ERROR:
      return {
        ...state,
        localizationData: action.payload,
        isPendingData: false
      };
    case LocalizationActionTypes.SET_BURGER_TOGGLE_OPEN:
      return {
        ...state,
        burgerToggleOpen: action.payload
      };
    default:
      return state;
  }
}
