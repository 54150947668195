import React from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import { missingImage } from "../../assets";

import { Clear as ClearIcon, Edit as EditIcon } from "@material-ui/icons";
import { ProductFieldHeader } from "../../components";

import "./product-variable.styles.scss";

const ProductVariable = ({
  sectionPath,
  areProductVariationsByParentPending,
  productVariationsByParent,
  deleteProductVariationById
}) => {
  const { url } = useRouteMatch();
  const { urlProductType, productId } = useParams();
  return (
    <div className="product-variable">
      <ProductFieldHeader
        sectionTitle="PRODUCT VARIATIONS"
        addNewTitle="Add product variations"
        sectionPath={sectionPath}
      />
      {productVariationsByParent && productVariationsByParent.results.length ? (
        <Table aria-label="items table">
          <TableHead>
            <TableRow>
              <TableCell align="center">IMAGE</TableCell>
              <TableCell align="center">NAME</TableCell>
              <TableCell align="center">SIZE/VALUE</TableCell>
              <TableCell align="center">STOCK</TableCell>
              <TableCell align="center">COST</TableCell>
              <TableCell align="center">COLOR</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productVariationsByParent &&
              productVariationsByParent.results.map(
                ({ id, image, name, size, value, stock, cost, color }) => (
                  <TableRow key={id}>
                    <TableCell align="center">
                      <div className="product-variable__image">
                        <img src={image ? image : missingImage} alt="" />
                      </div>
                    </TableCell>
                    <TableCell align="center">{name}</TableCell>
                    <TableCell align="center">
                      {size}/{value}
                    </TableCell>
                    <TableCell align="center">{stock}</TableCell>
                    <TableCell align="center">{cost}</TableCell>
                    <TableCell align="center">
                      <div className="product-variable__color">
                        <div
                          className="product-color"
                          style={{ backgroundColor: color }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="product-variable__actions">
                        <Link to={`${url}/variations/${id}`}>
                          <EditIcon className="action-edit" />
                        </Link>
                        <ClearIcon
                          onClick={() =>
                            deleteProductVariationById(
                              id,
                              urlProductType,
                              productId
                            )
                          }
                          className="action-clear"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      ) : (
        <h3 style={{ textAlign: "center" }}> No product variations! </h3>
      )}
    </div>
  );
};

export default ProductVariable;
