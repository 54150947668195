export default function productsTypeSwitchComponent({
  editor,
  api,
  className
}) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    const render = () => {
      const translations = getTranslate("translations", this.attributes);
      const className = this.className.split(" ")[0];
      const checkboxEl = this.querySelector(`.${className}__checkbox`);

      const b2cEl = this.querySelector(
        `.${className}__product-type:first-child`
      );
      const b2bEl = this.querySelector(
        `.${className}__product-type:last-child`
      );

      const defaultType = window.products.type;
      checkboxEl.checked = defaultType === "b2b";
      if (defaultType === "b2c") {
        b2cEl.classList.add(`${className}__product-type--active`);
        b2bEl.classList.remove(`${className}__product-type--active`);
      } else {
        b2cEl.classList.remove(`${className}__product-type--active`);
        b2bEl.classList.add(`${className}__product-type--active`);
      }

      b2cEl.innerText = translations["B2C"];
      b2bEl.innerText = translations["B2B"];

      checkboxEl.addEventListener("click", e => {
        const type = e.target.closest(`.${className}__checkbox`).checked
          ? "b2b"
          : "b2c";

        if (type === "b2c") {
          b2cEl.classList.add(`${className}__product-type--active`);
          b2bEl.classList.remove(`${className}__product-type--active`);
        } else {
          b2cEl.classList.remove(`${className}__product-type--active`);
          b2bEl.classList.add(`${className}__product-type--active`);
        }

        if (window.hasOwnProperty("products")) {
          window.products.setType(type);
          window.products.getProducts(true);
          window.products.categories = [];
          window.products.producers = [];
          window.products.getCategories("", true);
          window.products.getProducers("", true);
        }
      });
    };

    const intervalName = `waitForProductsInterval${Math.random()
      .toString()
      .slice(-8)}`;
    window[intervalName] = setInterval(function() {
      if (
        window.hasOwnProperty("products") &&
        window.hasOwnProperty("grapesjs")
      ) {
        clearInterval(window[intervalName]);
        render();
      }
    }, 100);
  };

  editor.DomComponents.addType("products-type-switch", {
    isComponent: el => el.tagName === "DIV" && el.className === `${className}`,
    model: {
      defaults: {
        name: "Products Type Switcher",
        script,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["B2C", "B2B"]
          }
        ]
      },
      view: {
        init() {
          editor.select(this.model.components().parent);
          this.listenTo(
            this.model,
            "change:attributes:translations",
            this.render
          );
        }
      }
    }
  });

  editor.DomComponents.addType("products-type-switch__toggle", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === `${className}__toggle`,
    model: {
      defaults: {
        name: "Products Type Switcher Toggle",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-type-switch__input", {
    isComponent: el =>
      el.tagName === "INPUT" && el.className === `${className}__input`,
    model: {
      defaults: {
        name: "Products Type Switcher Input",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-type-switch__slider", {
    isComponent: el =>
      el.tagName === "SPAN" && el.className === `${className}__slider`,
    model: {
      defaults: {
        name: "Products Type Switcher Slider",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-type-switch__product-type", {
    isComponent: el =>
      el.tagName === "SPAN" && el.className === `${className}__product-type`,
    model: {
      defaults: {
        name: "Products Type Switcher Product Type",
        draggable: false,
        removable: false,
        copyable: false
      }
    }
  });
}
