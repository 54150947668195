export default function profileEditComponent({ editor, api, className }) {
  const script = function() {
    const className = "{[ className ]}";

    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    const setLoading = (wrapperEl, state) => {
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add(`${className}--preloader`);
      } else {
        wrapperEl.classList.remove(`${className}--preloader`);
      }
    };

    const handleData = (profileData, loading) => {
      const wrapperEl = this;
      setLoading(wrapperEl, loading);

      const mediaAvatarEl = this.querySelector(`.${className}__avatar`);
      const mediaNameEl = this.querySelector(`.${className}__name`);
      const mediaProfessionEl = this.querySelector(`.${className}__profession`);
      const mediaUploadFileEl = this.querySelector(
        `#${className}__upload-avatar-file`
      );
      const mediaDeleteBtnEl = this.querySelector(
        `#${className}__delete-avatar`
      );
      const mediaContactCardLinkEl = this.querySelector(
        `.${className}__contact-link`
      );

      const emailEl = this.querySelector(`input[name=${className}__email]`);
      const posCustomerNumberEl = this.querySelector(
        `input[id=${className}__metadata__POSCustomerNumber]`
      );
      const birthdayEl = this.querySelector(
        `input[name=${className}__birthday]`
      );
      const firstNameEl = this.querySelector(
        `input[name=${className}__first-name]`
      );
      const middleNameEl = this.querySelector(
        `input[name=${className}__middle-name]`
      );
      const lastNameEl = this.querySelector(
        `input[name=${className}__last-name]`
      );
      const contactsEl = this.querySelector(
        `input[name=${className}__contacts]`
      );
      const phoneNumberEl = this.querySelector(
        `input[name=${className}__phone]`
      );
      const countryEl = this.querySelector(
        `select[name=${className}__country]`
      );
      const websiteEl = this.querySelector(`input[name=${className}__website]`);
      const professionEl = this.querySelector(
        `input[name=${className}__profession]`
      );
      const profileTypeFieldsetEl = this.querySelector(
        `.${className}__fieldset--profile-type`
      );
      const checkSelectedProfileType = value => {
        const selectedEl = this.querySelector(
          `input[name=${className}__profile-type][value=${value}]`
        );
        if (selectedEl) {
          selectedEl.checked = true;
        }
      };
      const getSelectedProfileType = () =>
        this.querySelector(`input[name=${className}__profile-type]:checked`);
      const genderFieldsetEl = this.querySelector(
        `.${className}__fieldset--gender`
      );
      const checkSelectedGender = value => {
        const selectedEl = this.querySelector(
          `input[name=${className}__gender][value=${value}]`
        );
        if (selectedEl) {
          selectedEl.checked = true;
        }
      };
      const getSelectedGender = () =>
        this.querySelector(`input[name=${className}__gender]:checked`);
      const facebookEl = this.querySelector(
        `input[name=${className}__facebook]`
      );
      const linkedinEl = this.querySelector(
        `input[name=${className}__linkedin]`
      );
      const twitterEl = this.querySelector(`input[name=${className}__twitter]`);
      const instagramEl = this.querySelector(
        `input[name=${className}__instagram]`
      );
      const youtubeEl = this.querySelector(`input[name=${className}__youtube]`);
      const redditEl = this.querySelector(`input[name=${className}__reddit]`);

      const resetBtnEl = this.querySelector("button[type=reset]");
      const formEl = this.querySelector("form");

      mediaAvatarEl.src = profileData.avatar;
      mediaAvatarEl.addEventListener(
        "error",
        e =>
          (e.target.src =
            "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==")
      );
      mediaNameEl.innerText = [
        profileData.first_name || "",
        profileData.middle_name || "",
        profileData.last_name || ""
      ].join(" ");
      mediaProfessionEl.innerText = profileData.profession || "";

      emailEl.value = profileData.email;
      birthdayEl.value = profileData.birthday || "";
      firstNameEl.value = profileData.first_name || "";
      if (middleNameEl) {
        middleNameEl.value = profileData.middle_name || "";
      }
      lastNameEl.value = profileData.last_name || "";
      if (contactsEl) {
        contactsEl.value = profileData.contacts || "";
      }
      phoneNumberEl.value = profileData.mobile_number || "";
      if (countryEl) {
        countryEl.value = profileData.country ? profileData.country.id : "";
      }
      if (websiteEl) {
        websiteEl.value = profileData.site || "";
      }
      if (professionEl) {
        professionEl.value = profileData.profession || "";
      }
      if (facebookEl) {
        facebookEl.value = profileData.metadata
          ? profileData.metadata.facebook
          : "";
      }
      if (linkedinEl) {
        linkedinEl.value = profileData.metadata
          ? profileData.metadata.linkedin
          : "";
      }
      if (twitterEl) {
        twitterEl.value = profileData.metadata
          ? profileData.metadata.twitter
          : "";
      }
      if (instagramEl) {
        instagramEl.value = profileData.metadata
          ? profileData.metadata.instagram
          : "";
      }
      if (youtubeEl) {
        youtubeEl.value = profileData.metadata
          ? profileData.metadata.youtube
          : "";
      }
      if (redditEl) {
        redditEl.value = profileData.metadata
          ? profileData.metadata.reddit || ""
          : "";
      }
      if (posCustomerNumberEl) {
        posCustomerNumberEl.value = profileData.metadata
          ? profileData.metadata.POSCustomerNumber || ""
          : "";
      }

      const PORTAL_URL = "{[ portalUrl ]}";
      mediaContactCardLinkEl.href = `${PORTAL_URL}/business-card/${profileData.id}`;

      const genders = window.gjsProfile.genders || [];
      const gendersRadioEls = genderFieldsetEl.querySelectorAll(
        `.${className}__label--radio`
      );
      Array.prototype.slice
        .call(gendersRadioEls)
        .map(gendersRadioEl => gendersRadioEl.remove());
      genders.map(object => {
        const value = object.gender;
        const gender = object.value;

        const label = document.createElement("label");
        label.setAttribute(
          "class",
          `${className}__label ${className}__label--radio`
        );

        label.innerHTML = `
          <input class="${className}__input" name="${className}__gender" type="radio" value="${value}" />
          ${gender}
        `;
        return genderFieldsetEl.append(label);
      });
      checkSelectedGender(profileData.sex);

      if (profileTypeFieldsetEl) {
        const profileTypes = window.gjsProfile.profileTypes || [];
        const profileTypesRadioEls = profileTypeFieldsetEl.querySelectorAll(
          `.${className}__label--radio`
        );
        Array.prototype.slice
          .call(profileTypesRadioEls)
          .map(profileTypesRadioEl => profileTypesRadioEl.remove());
        profileTypes.map(object => {
          const value = object.type;
          const type = object.value;

          const label = document.createElement("label");
          label.setAttribute(
            "class",
            `${className}__label ${className}__label--radio`
          );

          label.innerHTML = `
          <input class="${className}__input" name="${className}__profile-type" type="radio" value="${value}" />
          ${type}
        `;
          return profileTypeFieldsetEl.append(label);
        });
        checkSelectedProfileType(profileData.user_type);
      }

      function fixDateInput(el, date) {
        const supportsDateInput = () => {
          const input = document.createElement(`input`);
          input.setAttribute(`type`, `date`);

          const notADateValue = `not-a-date`;
          input.setAttribute(`value`, notADateValue);

          return !(input.value === notADateValue);
        };

        let picker = null;
        const fix = () => {
          if (picker) {
            return;
          }

          el.type = "text";
          // eslint-disable-next-line no-undef
          picker = new Pikaday({
            field: this.el,
            format: "YYYY-MM-DD",
            maxDate: new Date(),
            toString(date) {
              const day = ("00" + date.getDate()).slice(-2);
              const month = ("00" + (date.getMonth() + 1)).slice(-2);
              const year = date.getFullYear();
              return `${year}-${month}-${day}`;
            }
          });
        };

        if (supportsDateInput()) {
          return;
        }

        if (typeof Pikaday === "undefined") {
          const style = document.createElement("link");
          style.rel = "stylesheet";
          style.href = "//cdn.jsdelivr.net/npm/pikaday/css/pikaday.css";

          const script = document.createElement("script");
          script.src = "//cdn.jsdelivr.net/npm/pikaday/pikaday.js";

          script.addEventListener("load", fix);

          document.head.append(style);
          document.body.append(script);
        } else {
          fix();
        }
      }

      if (birthdayEl.type !== "text") {
        fixDateInput(birthdayEl, profileData.birthday || "");
      }

      // Upload new avatar handler
      mediaUploadFileEl.addEventListener("input", e => {
        e.stopImmediatePropagation();

        if (!mediaUploadFileEl.files.length) {
          return;
        }

        const file = mediaUploadFileEl.files[0];
        const formData = new FormData();
        formData.append("avatar", file);
        window.gjsProfile.updateProfile(formData);
        mediaUploadFileEl.value = "";
      });
      // Delete avatar handler
      mediaDeleteBtnEl.addEventListener("click", e => {
        e.stopImmediatePropagation();

        const base64toBlob = (base64Data, contentType) => {
          contentType = contentType || "";
          const sliceSize = 1024;
          const byteCharacters = atob(base64Data);
          const bytesLength = byteCharacters.length;
          const slicesCount = Math.ceil(bytesLength / sliceSize);
          const byteArrays = new Array(slicesCount);

          for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
              bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
          }
          return new Blob(byteArrays, { type: contentType });
        };

        const blob = base64toBlob(
          `iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAYFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD////68c6fAAAAH3RSTlMAAQIDBAUGBwkKCwwNDhAREhMUFRgaHR4fICIjJCUmhyvRhQAAAAFiS0dEHwUNEL0AAAHjSURBVGje7dlbb4JAEAXgU1sUWqlc6pWy//9n9sEr6sKcmV1SE84TyRi/zN4wKzBlypQpr5zs+vhZqLPKkx6jdOXl+X3nDNlmPqNyLpjiKl8f3eJsG16pTsVgvWS+PkIq20dk0Txp1DZiSa8SqJccIygFEH/ESoygPEcCKx4krOJDgiplzyEZbI2JEGsvMsSoCBGbIkVMihixKHLEoBCIXmEQtUIhWoVDlAqJ6BQWUSk0olF4RKEoEF7RILSiQlhFh5CKEuEULUIpaoRR9AihGBC5YkHEigmRKjZEqBgRmWJFRIoZkSgs0tY9X+H7zc8iK9R8LySyBrCmFQ7ZzwDMdqxCIb9zAEDSkAqFLE+ltOUUBqkvtZxbyQSyebsWz0usOUhWshxpPm6rxyXWZvODoBcx0qad6nGJfQMSRYys7spJ49wPIFKkyPqhnra74yQNK0JkP3v8wPI8SfOh+zEZctqFvgz1IkOWAxfiA4oIqQev3fvvlCTI7S5UKQKkuws1yjBytws1iv0dL3jvx0HulEhIV4mFdBQ/UtiQ2xPmy4vkLpjiT2JFfH80dbJxI/SSujGU0o0xYuUovaSjzAuSvKg0aShFmadn8usr2QhKxE4uSg1EVyIbwKKJbwCLElP+f/4AhO8b12VZr2gAAAAASUVORK5CYII=`
        );
        const file = new File([blob], "default_avatar.png", {
          type: "image/png"
        });
        const formData = new FormData();
        formData.append("avatar", file);
        window.gjsProfile.updateProfile(formData);
      });
      // Reset button handler
      resetBtnEl.addEventListener("click", e => {
        e.preventDefault();
        e.stopImmediatePropagation();

        window.gjsProfile.getProfile();
      });
      // Submit button handler
      formEl.addEventListener("submit", e => {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (profileData.email !== emailEl.value) {
          window.gjsProfile.updateEmail(emailEl.value);
        }

        const data = Object.assign(
          {},
          {
            first_name: firstNameEl.value,
            middle_name: middleNameEl.value,
            last_name: lastNameEl.value,
            mobile_number: phoneNumberEl.value,
            site: websiteEl.value,
            profession: professionEl.value
          },
          birthdayEl.value
            ? {
                birthday: birthdayEl.value
              }
            : {},
          countryEl.value
            ? {
                country: countryEl.value
              }
            : {},
          getSelectedGender().value
            ? {
                sex: getSelectedGender().value
              }
            : {},
          getSelectedProfileType().value
            ? {
                user_type: getSelectedProfileType().value
              }
            : {},
          {
            metadata: {
              facebook: facebookEl.value || "",
              linkedin: linkedinEl.value || "",
              twitter: twitterEl.value || "",
              instagram: instagramEl.value || "",
              youtube: youtubeEl.value || "",
              reddit: redditEl.value || "",
              POSCustomerNumber: posCustomerNumberEl.value || "" // TODO: должна быть дикамика, хз чего Object.assign не воркает
            }
          }
        );

        window.gjsProfile.updateProfile(JSON.stringify(data), true);
      });
    };

    if (
      !window.hasOwnProperty("gjsProfile") &&
      window.hasOwnProperty("grapesjs")
    ) {
      const API_ROOT = "{[ apiRoot ]}";

      window.gjsProfile = {
        API_ROOT: API_ROOT,
        renderCycle: [],
        params: {},
        data: {},
        loading: false,
        render() {
          console.group("profile render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func => func(this.data, this.loading));
          console.log("render after new cycle", this);
          console.groupEnd("profile render");
        },
        appendFuncToRenderCycle(newFunc) {
          const isNew = !this.renderCycle.find(func => func === newFunc);
          if (isNew) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getProfile() {
          if (this.loading || !localStorage.hasOwnProperty("token")) return;
          this.setLoading(true);

          const params = new URLSearchParams(this.params);
          return fetch(`${API_ROOT}/auth/users/me/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(me =>
              fetch(`${API_ROOT}/account/profile/?${params}&user_id=${me.id}`, {
                method: "GET",
                headers: Object.assign(
                  { "Accept-Language": localStorage.getItem("language") },
                  localStorage.getItem("token")
                    ? {
                        Authorization: `Token ${localStorage.getItem("token")}`
                      }
                    : {}
                ),
                cors: "cors",
                credentials: "omit"
              })
                .then(response => response.json())
                .then(response =>
                  this.setProfile(
                    Object.assign(
                      {},
                      response.results.length ? response.results[0] : {},
                      { email: me.email }
                    )
                  )
                )
            );
        },
        setProfile(data) {
          // Compare items
          if (JSON.stringify(this.data) !== JSON.stringify(data)) {
            this.render();
          }
          // Set profile data
          this.data = data;
          // Get additional inforamtion
          if (this.edit) {
            Promise.all([this.getGenders(), this.getProfileTypes()]).then(
              () => {
                // Start render cycle
                this.setLoading(false);
                this.render();
              }
            );
          } else {
            // Start render cycle
            this.setLoading(false);
            this.render();
          }
        },
        updateProfile(data, json) {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(`${API_ROOT}/account/profile/${this.data.id}/`, {
            method: "PATCH",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              json ? { "Content-Type": "application/json" } : {},
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            body: data,
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => {
              this.setLoading(false);
              this.getProfile();
            });
        },
        getGenders() {
          return fetch(`${API_ROOT}/account/profile/genders/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => this.setProp("genders", response));
        },
        getProfileTypes() {
          return fetch(`${API_ROOT}/account/profile/types/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => this.setProp("profileTypes", response));
        },
        updateEmail(newEmail) {
          return fetch(`${API_ROOT}/auth/users/set_email/`, {
            method: "POST",
            headers: Object.assign(
              {
                "Accept-Language": localStorage.getItem("language"),
                "Content-Type": "application/json"
              },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            body: JSON.stringify({
              new_email: newEmail
            }),
            cors: "cors",
            credentials: "omit"
          }).then(response => this.getProfile());
        }
      };
    }

    const translations = getTranslate("translations", this.attributes);
    const translationsKeys = Object.keys(translations);
    if (
      window.hasOwnProperty("gjsProfile") &&
      window.hasOwnProperty("grapesjs")
    ) {
      if (translations && translationsKeys.length) {
        const elements = this.querySelectorAll(
          `label, button, a.${className}__contact-link`
        );

        // eslint-disable-next-line array-callback-return
        Array.prototype.slice.call(elements).map(element => {
          const text = element.innerText.trim();

          if (translationsKeys.includes(text)) {
            element.innerHTML = element.innerHTML
              .split(text)
              .join(translations[text]);
          }
        });
      }

      window.gjsProfile.appendFuncToRenderCycle(handleData.bind(this));
      window.gjsProfile.setProp("edit", true);
      window.gjsProfile.getProfile();
    }
  };

  editor.DomComponents.addType("profile-edit", {
    isComponent: el => el.tagName === "DIV" && el.className === `${className}`,
    model: {
      defaults: {
        name: "Profile Text",
        portalUrl: api.PORTAL_URL,
        apiRoot: api.API_ROOT,
        className,
        script,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "Upload new avatar",
              "Delete",
              "Contact Card",
              "Basic info",
              "E-MAIL",
              "Enter e-mail",
              "BIRTHDAY",
              "FIRST NAME",
              "Enter first name",
              "MIDDLE NAME",
              "Enter middle name",
              "LAST NAME",
              "Enter last name",
              "PROFESSION",
              "Enter profession",
              "PHONE NUMBER",
              "Enter phone",
              "COUNTRY",
              "Select country",
              "WEBSITE",
              "Enter website",
              "CONTACTS",
              "Enter contacts",
              "PROFILE TYPE",
              "SEX",
              "FACEBOOK",
              "Enter facebook url",
              "LINKEDIN",
              "Enter linkedin url",
              "TWITTER",
              "Enter twitter url",
              "INSTAGRAM",
              "Enter instagram url",
              "YOUTUBE",
              "Enter youtube url",
              "REDDIT",
              "Enter reddit url",
              "SUBMIT",
              "RESET"
            ]
          }
        ]
      }
    }
  });
}
