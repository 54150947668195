export default function preloaderNewComponent({ editor, api }) {
  const script = function() {
    const edit = this.attributes.hasOwnProperty("edit");
    const inBuilder = !window.hasOwnProperty("grapesjs");
    let type = this.attributes.hasOwnProperty("type")
      ? this.attributes.type.value
      : "default";
    const preloaderEl = this.querySelector(".preloader-new__loader");
    const types = [
      "default",
      "circle",
      "dualring",
      "facebook",
      "heart",
      "ring",
      "roller",
      "ellipsis",
      "grid",
      "hourglass",
      "ripple",
      "spinner"
    ];

    const addEmptyDivs = (el, count) =>
      Array(count)
        .fill()
        .map(i => el.append(document.createElement("div")));

    preloaderEl.innerHTML = "";

    switch (type) {
      case "rand":
        type = types[Math.floor(Math.random() * type.length)];
        break;
      case "circle":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--circle";
        break;
      case "dualring":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--dual-ring";
        break;
      case "facebook":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--facebook";
        addEmptyDivs(preloaderEl, 3);
        break;
      case "heart":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--heart";
        addEmptyDivs(preloaderEl, 1);
        break;
      case "ring":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--ring";
        addEmptyDivs(preloaderEl, 4);
        break;
      case "roller":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--roller";
        addEmptyDivs(preloaderEl, 8);
        break;
      case "ellipsis":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--ellipsis";
        addEmptyDivs(preloaderEl, 4);
        break;
      case "grid":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--grid";
        addEmptyDivs(preloaderEl, 9);
        break;
      case "hourglass":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--hourglass";
        break;
      case "ripple":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--ripple";
        addEmptyDivs(preloaderEl, 2);
        break;
      case "spinner":
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--spinner";
        addEmptyDivs(preloaderEl, 12);
        break;
      default:
        preloaderEl.className =
          "preloader-new__loader preloader-new__loader--default";
        addEmptyDivs(preloaderEl, 12);
    }

    if (edit || !inBuilder) {
      if (!inBuilder) {
        window.onload = () => this.classList.add("preloader-new--disabled");
      } else {
        this.classList.add("preloader-new--disabled");
      }
    } else {
      this.classList.add("preloader-new--disabled");
    }
  };

  editor.DomComponents.addType("preloader-new", {
    isComponent: el => el.tagName === "DIV" && el.className === "preloader-new",
    model: {
      defaults: {
        name: "Preloader",
        script,
        droppable: false,
        traits: [
          {
            type: "checkbox",
            label: "Edit mode",
            name: "edit",
            value: true,
            valueTrue: true,
            valueFalse: false
          },
          {
            type: "select",
            label: "Type",
            name: "type",
            options: [
              { id: "default", name: "Default" },
              { id: "rand", name: "Random" },
              { id: "circle", name: "Circle" },
              { id: "dualring", name: "Dual Ring" },
              { id: "facebook", name: "Facebook" },
              { id: "heart", name: "Heart" },
              { id: "ring", name: "Ring" },
              { id: "roller", name: "Roller" },
              { id: "ellipsis", name: "Ellipsis" },
              { id: "grid", name: "Grid" },
              { id: "hourglass", name: "Hourglass" },
              { id: "ripple", name: "Ripple" },
              { id: "spinner", name: "Spinner" }
            ]
          }
        ]
      }
    },
    view: {
      init() {
        this.listenTo(this.model, "change:attributes:edit", this.render);
        this.listenTo(this.model, "change:attributes:type", this.render);
      }
    }
  });

  editor.DomComponents.addType("preloader__loader", {
    isComponent: el =>
      el.tagName === "DIV" && el.className.includes("preloader-new__loader"),
    model: {
      droppable: false,
      draggable: false,
      copyable: false,
      defaults: {
        name: "Preloader Loader"
      }
    }
  });

  editor.DomComponents.addType("preloader__loader-item", {
    isComponent: el =>
      el.tagName === "DIV" &&
      el.parentElement &&
      el.parentElement.className.includes("preloader-new__loader"),
    model: {
      droppable: false,
      draggable: false,
      copyable: false,
      defaults: {
        name: "Preloader Loader Item"
      }
    }
  });

  editor.DomComponents.addType("preloader__additional-content--top", {
    isComponent: el =>
      el.tagName === "DIV" &&
      el.className.includes("preloader-new__additional-content--top"),
    model: {
      defaults: {
        name: "Preloader Additional Content Top",
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("preloader__additional-content--bottom", {
    isComponent: el =>
      el.tagName === "DIV" &&
      el.className.includes("preloader-new__additional-content--bottom"),
    model: {
      defaults: {
        name: "Preloader Additional Content Bottom",
        draggable: false,
        copyable: false
      }
    }
  });
}
