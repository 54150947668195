export default function videoBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  blockManager.add("video", {
    label: "Video",
    category: "Basic",
    attributes: { class: "fa fa-youtube-play" },
    content: {
      type: "video",
      src: "img/video2.webm",
      style: {
        height: "350px",
        width: "615px"
      }
    }
  });
}
