import { CategoriesActionTypes } from "./categories.types";

const INITIAL_STATE = {
  areB2BCategoriesPending: false,
  b2bCategoriesCount: 0,
  b2bCategoriesNext: "",
  b2bCategoriesPrevious: "",
  b2bCategoriesResults: [],
  b2bCategoriesError: null,

  areB2CCategoriesPending: false,
  b2cCategoriesCount: 0,
  b2cCategoriesNext: "",
  b2cCategoriesPrevious: "",
  b2cCategoriesResults: [],
  b2cCategoriesError: null,

  areB2BCategoriesEditorPending: false,
  b2bCategoriesEditorCount: 0,
  b2bCategoriesEditorNext: "",
  b2bCategoriesEditorPrevious: "",
  b2bCategoriesEditorResults: [],
  b2bCategoriesEditorError: null,

  areB2CCategoriesEditorPending: false,
  b2cCategoriesEditorCount: 0,
  b2cCategoriesEditorNext: "",
  b2cCategoriesEditorPrevious: "",
  b2cCategoriesEditorResults: [],
  b2cCategoriesEditorError: null,

  isSearchB2BCategoriesPending: false,
  searchedB2BCategories: null,
  searchedB2BCategoriesError: null,

  isSearchB2CCategoriesPending: false,
  searchedB2CCategories: null,
  searchedB2CCategoriesError: null,

  categoryById: [],
  createdCategory: null,
  updatedCategory: null,
  deletedCategory: null,

  categoryRequestStatus: ""
};

const CategoriesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_PENDING:
      return {
        ...state,
        areB2BCategoriesPending: true
      };

    case CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_SUCCESS:
      return {
        ...state,
        areB2BCategoriesPending: false,
        b2bCategoriesCount: action.payload.count,
        b2bCategoriesNext: action.payload.next,
        b2bCategoriesPrevious: action.payload.previous,
        b2bCategoriesResults: action.payload.results,
        b2bCategoriesError: null
      };

    case CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_ERROR:
      return {
        ...state,
        areB2BCategoriesPending: false,
        b2bCategoriesError: action.payload
      };

    case CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_PENDING:
      return {
        ...state,
        areB2CCategoriesPending: true
      };

    case CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_SUCCESS:
      return {
        ...state,
        areB2CCategoriesPending: false,
        b2cCategoriesCount: action.payload.count,
        b2cCategoriesNext: action.payload.next,
        b2cCategoriesPrevious: action.payload.previous,
        b2cCategoriesResults: action.payload.results,
        b2cCategoriesError: null
      };

    case CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_ERROR:
      return {
        ...state,
        areB2CCategoriesPending: false,
        b2cCategoriesError: action.payload
      };

    case CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_EDITOR_PENDING:
      return {
        ...state,
        areB2BCategoriesEditorPending: true
      };

    case CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_EDITOR_SUCCESS:
      return {
        ...state,
        areB2BCategoriesPending: false,
        b2bCategoriesEditorCount: action.payload.count,
        b2bCategoriesEditorNext: action.payload.next,
        b2bCategoriesEditorPrevious: action.payload.previous,
        b2bCategoriesEditorResults: action.payload.results,
        b2bCategoriesEditorError: null
      };

    case CategoriesActionTypes.GET.B2B.GET_B2B_CATEGORIES_EDITOR_ERROR:
      return {
        ...state,
        areB2BCategoriesEditorPending: false,
        b2bCategoriesEditorError: action.payload
      };

    case CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_EDITOR_PENDING:
      return {
        ...state,
        areB2CCategoriesEditorPending: true
      };

    case CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_EDITOR_SUCCESS:
      return {
        ...state,
        areB2CCategoriesEditorPending: false,
        b2cCategoriesEditorCount: action.payload.count,
        b2cCategoriesEditorNext: action.payload.next,
        b2cCategoriesEditorPrevious: action.payload.previous,
        b2cCategoriesEditorResults: action.payload.results,
        b2cCategoriesEditorError: null
      };

    case CategoriesActionTypes.GET.B2C.GET_B2C_CATEGORIES_EDITOR_ERROR:
      return {
        ...state,
        areB2CCategoriesEditorPending: false,
        b2cCategoriesEditorError: action.payload
      };

    case CategoriesActionTypes.GET.B2B.SEARCH_B2B_CATEGORIES_BY_COMPANY_PENDING:
      return {
        ...state,
        isSearchB2BCategoriesPending: true
      };

    case CategoriesActionTypes.GET.B2B.SEARCH_B2B_CATEGORIES_BY_COMPANY_SUCCESS:
      return {
        ...state,
        searchedB2BCategories: action.payload,
        isSearchB2BCategoriesPending: false
      };

    case CategoriesActionTypes.GET.B2B.SEARCH_B2B_CATEGORIES_BY_COMPANY_ERROR:
      return {
        ...state,
        isSearchB2BCategoriesPending: false,
        searchedB2BCategoriesError: action.payload
      };

    case CategoriesActionTypes.GET.B2C.SEARCH_B2C_CATEGORIES_BY_COMPANY_PENDING:
      return {
        ...state,
        isSearchB2CCategoriesPending: true
      };

    case CategoriesActionTypes.GET.B2C.SEARCH_B2C_CATEGORIES_BY_COMPANY_SUCCESS:
      return {
        ...state,
        searchedB2CCategories: action.payload,
        isSearchB2CCategoriesPending: false
      };

    case CategoriesActionTypes.GET.B2C.SEARCH_B2C_CATEGORIES_BY_COMPANY_ERROR:
      return {
        ...state,
        searchedB2CCategoriesError: action.payload,
        isSearchB2CCategoriesPending: false
      };

    case CategoriesActionTypes.GET.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryById: action.payload
      };

    case CategoriesActionTypes.CREATE.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        createdCategory: action.payload,
        categoryRequestStatus: action.payload.requestStatus
      };

    case CategoriesActionTypes.UPDATE.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryRequestStatus: action.payload.requestStatus,
        updatedCategory: action.payload
      };

    case CategoriesActionTypes.DELETE.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deletedCategory: action.payload,
        categoryRequestStatus: action.payload.requestStatus
      };

    case CategoriesActionTypes.CLEAR_CATEGORY_REQUEST:
      return {
        ...state,
        categoryRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default CategoriesReducer;
