import React from "react";
import { Link } from "react-router-dom";

import { QuestionIcon } from "../../assets";
import { DefaultButton } from "../../components";

import { buttonOk, buttonCancel } from "../../styles/buttons-styles";
import "./event-details-card.styles.scss";

const EventDetailsCard = ({
  eventDetailsId,
  eventName,
  eventDescription,
  orgName,
  orgId,
  img,
  going = true,
  typeOfCompany,
  addMeToEventById,
  eventUserStatus
}) => {
  return (
    <div className="event-details-card">
      <div className="event-details-card__logo">
        <div>
          <img src={img} alt="" />
        </div>
      </div>
      <div className="event-details-card__separator" />
      <div className="event-details-card__body">
        <h3 className="event-details-card__body__title">{eventName}</h3>
        <div>
          <p>{eventDescription}</p>
          <p>
            ORGANIZATION:{" "}
            <span className="event-details-card__body__company">
              <Link
                to={`/${
                  typeOfCompany === "company" ? "companies" : "organizations"
                }/${orgId}`}
              >
                {orgName}
              </Link>
            </span>
          </p>
        </div>
        {going && (
          <div className="event-details-card__body__control-wrapper">
            <DefaultButton
              padding="4px 48px"
              {...(eventUserStatus && !eventUserStatus.is_visitor
                ? { ...buttonOk }
                : { ...buttonCancel })}
              onClick={() => addMeToEventById(eventDetailsId)}
            >
              {eventUserStatus && !eventUserStatus.is_visitor
                ? "Going"
                : "Decline event"}
            </DefaultButton>
            <div className="event-details-card__body__control-wrapper__icon">
              <QuestionIcon />
            </div>
            <p>Only for registered users</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetailsCard;
