import icon from "./icon.png";

export default function jobsWithPaginationBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .jobs-with-pagination-wrapper {}

    .jobs-with-pagination-wrapper.jobs-with-pagination-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .jobs-with-pagination-wrapper.jobs-with-pagination-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .jobs-with-pagination {
      display: flex;
      flex-flow: column wrap;
    }

    .jwp-job {
      display: flex;
      flex-flow: column;
      padding: 18px 22px;
      margin: 0 0 10px;
      border: 1px solid #aeaeae;
      border-radius: 3px;
    }

    @media screen and (max-width: 992px) {
      .jwp-pagination__button {
        padding: 8px 11px;
      }
    }

    .jwp-job__title {
      color: #262626;
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 4px;
    }

    .jwp-job__link {
      color: inherit;
      text-decoration-color: currentColor;
    }

    .jwp-job__link:hover {
      color: inherit;
      text-decoration-color: currentColor;
    }

    .jwp-job__vacancy {
      margin: 0px 0 6px;
    }

    .jwp-job__description {
      width: 100%;
      color: #424242;
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 20px;
    }

    .jwp-job__meta {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin: auto 0 0;
    }

    .jwp-job__meta-item {
      margin-right: 30px;
    }

    .jwp-job__meta-item.jwp-job__meta-item--date {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      flex-shrink: 1;
      margin: 0;
    }

    .jwp-job__icon {
      width: 17px;
      height: 17px;
      color: #2f2d2d;
    }

    .jwp-job__address {
      vertical-align: top;
      padding: 0 0 0 4px;
    }

    .jwp-job__type {
      vertical-align: top;
      padding: 0 0 0 4px;
    }

    .jwp-job__date {
      vertical-align: top;
      padding: 0 0 0 4px;
    }

    .jwp-pagination {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      align-self: flex-end;
      margin: 0 0 0 auto;
      padding: 20px;
    }

    .jwp-pagination__separator {
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 0px 10px;
      margin: 0 4px;
      outline: none;
      cursor: pointer;
      vertical-align: bottom;
    }

    .jwp-pagination__button {
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      margin: 0 4px;
      border: 2px solid #aeaeae;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .jwp-pagination__button.jwp-pagination__button--active {
      color: #fff;
      background: #c0c0c0;
    }

    .jwp-pagination__button--disabled, .jwp-pagination__button:hover {
      background: #ededed;
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="jobs-with-pagination-wrapper">
    <div class="jobs-with-pagination">
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Italy, Rome
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              shifts
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              5/3/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              France, Paris
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              partial
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              5/3/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              USA, New York
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              full_time
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              5/3/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Japan, Chisinau
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              full_time
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              4/30/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Japan, Chisinau
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              full_time
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              4/29/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              United Kingdom, Chisinau
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              partial
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              4/29/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Benin, Chisinau
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              full_time
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              4/29/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Japan, Chisinau
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              full_time
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              4/14/2020
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Russia, Москва
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              partial
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              3/26/2019
            </span>
          </div>
        </div>
      </div>
  
      <div class="jwp-job">
        <h4 class="jwp-job__title">
          <a class="jwp-job__link" href="#">Job Title</a>
        </h4>
        <span class="jwp-job__vacancy">Job Vacancy</span>
        <div class="jwp-job__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci enim,
          ultrices ut elit malesuada, suscipit pretium libero. Orci varius...</div>
        <div class="jwp-job__meta">
          <div class="jwp-job__meta-item">
  
            <svg version="1.1" class="jwp-job__icon jwp-job__icon--point" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="97.713px" height="97.713px" viewBox="0 0 97.713 97.713"
              style="enable-background:new 0 0 97.713 97.713;" xml:space="preserve">
              <g>
                <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
              c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
              c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
              c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
              C67.542,46.276,59.159,54.659,48.855,54.659z"></path>
              </g>
            </svg>
  
            <span class="jwp-job__address">
              Israel, באר שבע
            </span>
          </div>
          <div class="jwp-job__meta-item">
            <span class="jwp-job__type">
              full_time
            </span>
          </div>
          <div class="jwp-job__meta-item jwp-job__meta-item--date">
            <span class="jwp-job__date">
              5/23/2017
            </span>
          </div>
        </div>
      </div>
  
  
      <div class="jwp-pagination">
  
        <button class="jwp-pagination__button jwp-pagination__button--disabled" disabled="">
          &lt;
        </button>
  
  
  
  
  
  
  
  
  
  
  
        <button class="jwp-pagination__button jwp-pagination__button--active" data-page="1">
          1
        </button>
  
  
  
        <button class="jwp-pagination__button" data-page="2">
          2
        </button>
  
  
        <span class="jwp-pagination__separator">...</span>
  
  
        <button class="jwp-pagination__button" data-page="7">
          7
        </button>
  
  
  
        <button class="jwp-pagination__button" data-page="2">
          &gt;
        </button>
  
      </div>
  
    </div>
  </div>
  `;

  blockManager.add("jobs-with-pagination", {
    label: `
      <img src="${icon}" alt="Jobs with pagination block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Jobs With Pagination
      </div>
    `,
    content,
    category: "B24Online Jobs"
  });
}
