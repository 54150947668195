import React from "react";
import { Link } from "react-router-dom";

import { Card, Box } from "@material-ui/core";

import {
  BuilderSitesListItem,
  BuilderSitesSearch,
  DefaultButton,
  Pagination,
  LoadingSpinner
} from "../../components";

import { colorPalette } from "../../styles/colorPalette";
import "./builder-sites-card.styles.scss";

const BuilderSitesCard = ({
  loading,
  sites,
  sitesSelectPage,
  sitesPagination,
  createPath,
  builderPath,
  detailsPath,
  search,
  onSearch,
  onDeleteSite,
  builderRef
}) => (
  <Card ref={builderRef} className="builder-sites-card">
    <div className="builder-sites-card__header">Your Sites</div>
    <div className="builder-sites-card__main">
      <div className="builder-search">
        <BuilderSitesSearch {...{ value: search, onSearch }} />
      </div>
      <div className="builder-list">
        <div className="builder-list-item">
          {!loading ? (
            sites && sites.length ? (
              sites.map((site, index) => (
                <BuilderSitesListItem
                  key={index}
                  {...{ site, builderPath, detailsPath, onDeleteSite }}
                />
              ))
            ) : (
              <Box fontSize="h6.fontSize" className="builder-sites-card__empty">
                No sites...
              </Box>
            )
          ) : (
            <LoadingSpinner loadingState />
          )}
        </div>
      </div>
    </div>
    <div className="builder-sites-card__footer">
      {!loading && (
        <>
          <Link to={createPath}>
            <DefaultButton
              color={colorPalette.colorPrimary.main}
              hoverColor="white"
              fontWeight="700"
              border={`1px solid ${colorPalette.colorPrimary.main}`}
              hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
              hover={colorPalette.colorPrimary.main}
              padding="7px 40px"
              transition="all 300ms ease"
            >
              Add new site
            </DefaultButton>
          </Link>
          <Pagination
            initialPage={sitesPagination.currentPage - 1}
            selectedPage={sitesSelectPage}
            dataCount={sitesPagination.total}
            itemsPerPage={sitesPagination.perPage}
          />
        </>
      )}
    </div>
    {/* <CardHeader
      style={{ background: "#fafafa" }}
      title={<Box textAlign="center">Your Sites</Box>}
    />
    <CardContent className="builder-sites-card__main">
      <BuilderSitesSearch {...{ value: search, onSearch }} />
      <List className="builder-sites-list">
        {!loading ? (
          sites && sites.length ? (
            sites.map((site, index) => (
              <BuilderSitesListItem
                key={index}
                {...{ site, builderPath, detailsPath, onDeleteSite }}
              />
            ))
          ) : (
            <Box fontSize="h6.fontSize" className="builder-sites-card__empty">
              No sites...
            </Box>
          )
        ) : (
          <LoadingSpinner loadingState />
        )}
      </List>
    </CardContent>
    <CardActions className="builder-sites-card__footer">
      {!loading && (
        <>
          <Link to={createPath}>
            <DefaultButton
              color={colorPalette.colorPrimary.main}
              hoverColor="white"
              fontWeight="700"
              border={`1px solid ${colorPalette.colorPrimary.main}`}
              hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
              hover={colorPalette.colorPrimary.main}
              padding="7px 40px"
              transition="all 300ms ease"
            >
              Add new site
            </DefaultButton>
          </Link>
          <Pagination
            initialPage={sitesPagination.currentPage - 1}
            selectedPage={sitesSelectPage}
            dataCount={sitesPagination.total}
            itemsPerPage={sitesPagination.perPage}
          />
        </>
      )}
    </CardActions> */}
  </Card>
);

export default BuilderSitesCard;
