import {
  axiosInstance,
  axiosNoTokenInstance
} from "@core/axios/axios.config";

import { PagesActionTypes } from "./pages.types";

export const getPageById = pageId => dispatch => {
  dispatch({ type: PagesActionTypes.GET_PAGE_BY_ID_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/static_pages/static_page/${pageId}`)
    .then(page =>
      dispatch({
        type: PagesActionTypes.GET_PAGE_BY_ID,
        payload: page.data
      })
    )
    .catch(error =>
      dispatch({ type: PagesActionTypes.GET_PAGE_BY_ID_ERROR, payload: error })
    );
};

export const createPage = (
  title,
  slug,
  content,
  isOnTop,
  siteType,
  pageType
) => dispatch => {
  axiosInstance
    .post("/api/v1/static_pages/static_page/", {
      title,
      slug,
      content,
      is_on_top: isOnTop,
      site_type: siteType,
      page_type: pageType
    })
    .then(page => {
      dispatch({
        type: PagesActionTypes.CREATE_PAGE,
        payload: { pages: page.data, requestStatus: page.status }
      });
    })
    .catch(error => console.log(error));
};

export const updatePage = (
  pageId,
  title,
  slug,
  content,
  isOnTop,
  siteType,
  pageType
) => dispatch => {
  axiosInstance
    .put(`/api/v1/static_pages/static_page/${pageId}/`, {
      title,
      slug,
      content,
      is_on_top: isOnTop,
      site_type: siteType,
      page_type: pageType
    })
    .then(page => {
      dispatch({
        type: PagesActionTypes.UPDATE_PAGE,
        payload: { page: page.data, requestStatus: page.status }
      });
    })
    .catch(error => console.log(error));
};

export const deletePage = pageId => dispatch => {
  axiosInstance
    .delete(`/api/v1/static_pages/static_page/${pageId}/`)
    .then(deletedPage => {
      dispatch({
        type: PagesActionTypes.DELETE_PAGE,
        payload: deletedPage
      });
    })
    .then(() => dispatch(getPages()))
    .catch(error => console.log(error));
};

export const getPages = (
  page = 1,
  pageSize = 9
) => dispatch => {
  dispatch({ type: PagesActionTypes.GET_PAGES_PENDING });

  axiosNoTokenInstance
    .get(
      `/api/v1/static_pages/static_page/?page=${page}&page_size=${pageSize}/`
    )
    .then(pages =>
      dispatch({
        type: PagesActionTypes.GET_PAGES,
        payload: { pages: pages.data.results, count: pages.data.count }
      })
    )
    .catch(err =>
      dispatch({ type: PagesActionTypes.GET_PAGES_ERROR, payload: err })
    );
};

export const clearPagesRequest = () => dispatch => {
  dispatch({
    type: PagesActionTypes.CLEAR_PAGES_REQUEST,
    payload: ""
  });
};

export const getPageTypes = () => dispatch => {
  dispatch({ type: PagesActionTypes.GET_PAGE_TYPES_ERROR });
  axiosNoTokenInstance
    .get(
      `/api/v1/static_pages/static_page/page_types/`
    )
    .then(types =>
      dispatch({
        type: PagesActionTypes.GET_PAGE_TYPES_SUCCESS,
        payload: types.data
      })
    )
    .catch(err =>
      dispatch({ type: PagesActionTypes.GET_PAGE_TYPES_ERROR, payload: err })
    );
};

export const getSiteTypes = () => dispatch => {
  dispatch({ type: PagesActionTypes.GET_SITE_TYPES_ERROR });
  axiosNoTokenInstance
    .get(
      `/api/v1/static_pages/static_page/site_types/`
    )
    .then(types =>
      dispatch({
        type: PagesActionTypes.GET_SITE_TYPES_SUCCESS,
        payload: types.data
      })
    )
    .catch(err =>
      dispatch({ type: PagesActionTypes.GET_SITE_TYPES_ERROR, payload: err })
    );
};