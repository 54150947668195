import React from "react";
import { useState } from "react";

import "./quantity-input.styles.scss";

const QuantityInput = ({ setQuantityFunc }) => {
  const [quantity, setQuantity] = useState(1);
  const regexNum = /^[0-9]*$/;
  const handleQuantity = e => {
    if (regexNum.test(e.target.value)) {
      setQuantity(Number(e.target.value));
    }
  };
  const incrementQuntity = () => {
    setQuantity(quantity + 1);
    setQuantityFunc(quantity + 1);
  };
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setQuantityFunc(quantity - 1);
    }
  };

  return (
    <div className="quantity-input">
      <input onChange={handleQuantity} value={quantity} pattern={regexNum} />
      <div>
        <button onClick={incrementQuntity}>+</button>
        <button onClick={decrementQuantity}>-</button>
      </div>
    </div>
  );
};

export default QuantityInput;
