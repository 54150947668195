import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  ViewModule as ViewModuleIcon,
  ViewHeadline as ViewHeadlineIcon
} from "@material-ui/icons";

import { missingImage } from "../../assets";

import {
  Pagination,
  ProductCard,
  SearchBar,
  DefaultDropdown,
  DefaultIconButton,
  ProductSwitch,
  DefaultMultiLevelDropdown
} from "../../components";

import {
  getProductsWithCouponDiscount,
  getProductsWithSimpleDiscount,
  toggleAllProductsSwitch,
  getB2BCategories,
  getB2CCategories,
  addProductToWishList,
  getCart
} from "../../redux/actions-exporter";

import "./coupon.styles.scss";

const Coupon = ({
  productsWithCouponDiscount,
  productsWithSimpleDiscount,
  getProductsWithCouponDiscount,
  getProductsWithSimpleDiscount,

  isAllProductsSwitchToggled,
  toggleAllProductsSwitch,

  getB2BCategories,
  getB2CCategories,

  b2bCategoriesResults,
  b2cCategoriesResults,

  searchB2BCategory,
  searchB2CCategory
}) => {
  const [inputValue, setInputValue] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [discountCategoryType, setDiscountCategoryType] = useState(
    "Coupon Discount"
  );

  const [productsType, setProductsType] = useState("b2b");
  const [category, setCategory] = useState("");
  const [_, setSortBy] = useState(""); // eslint-disable-line

  useEffect(() => {
    getB2BCategories();
    getB2CCategories();
  }, []); // eslint-disable-line

  useEffect(() => {
    setCategory("");
    setCurrentPage(1);
    setProductsType(!isAllProductsSwitchToggled ? "b2b" : "b2c");
  }, [isAllProductsSwitchToggled]); // eslint-disable-line

  useEffect(() => {
    if (discountCategoryType === "Coupon Discount") {
      getProductsWithCouponDiscount(
        productsType,
        inputValue,
        discountAmount,
        category,
        currentPage
      );
    } else if (discountCategoryType === "Simple Discount") {
      getProductsWithSimpleDiscount(
        productsType,
        inputValue,
        discountAmount,
        category,
        currentPage
      );
    }
  }, [
    isAllProductsSwitchToggled,
    productsType,
    category,
    inputValue,
    discountAmount,
    discountCategoryType,
    getProductsWithCouponDiscount,
    getProductsWithSimpleDiscount,
    currentPage
  ]);

  return (
    <div className="coupon-container">
      <h4>Sales & Coupons:</h4>
      <div className="coupon-container__search">
        <SearchBar onChange={e => setInputValue(e.target.value)} />
      </div>
      <div className="coupon-container__dropdowns-selection">
        <DefaultDropdown
          label="Category"
          items={[
            { id: "Coupon Discount", name: "Coupon Discount" },
            { id: "Simple Discount", name: "Simple Discount" }
          ]}
          returnData={setDiscountCategoryType}
          customTitle={discountCategoryType}
        />
        <DefaultDropdown
          label="Discount Amount"
          items={[
            { id: 90, name: "90" },
            { id: 80, name: "80" },
            { id: 70, name: "70" },
            { id: 60, name: "60" },
            { id: 50, name: "50" },
            { id: 40, name: "40" },
            { id: 30, name: "30" },
            { id: 20, name: "20" },
            { id: 10, name: "10" }
          ]}
          returnData={setDiscountAmount}
        />
      </div>
      <div className="coupon-container__sort">
        <div className="coupon-container__sort-upper">
          <div>
            <p>View:</p>
            <div>
              <DefaultIconButton>
                <ViewModuleIcon />
              </DefaultIconButton>

              <DefaultIconButton>
                <ViewHeadlineIcon />
              </DefaultIconButton>
            </div>
          </div>
        </div>
        {isAllProductsSwitchToggled && (
          <div className="coupon-container__sort-bottom">
            <div className={"coupon-container__sort-bottom__selector"}>
              <DefaultMultiLevelDropdown
                label="Category"
                inputFunc={e =>
                  isAllProductsSwitchToggled
                    ? searchB2CCategory(e.target.value)
                    : searchB2BCategory(e.target.value)
                }
                filter={true}
                style={{ width: "100%" }}
                items={
                  !isAllProductsSwitchToggled
                    ? b2bCategoriesResults
                    : b2cCategoriesResults
                }
                returnData={setCategory}
              />
            </div>
            <div className={"coupon-container__sort-bottom__selector"}>
              <DefaultDropdown
                label="Sort by"
                style={{ width: "100%" }}
                items={[
                  { id: "-id", name: "Newest" },
                  { id: "id", name: "Oldest" },
                  { id: "cost", name: "Price ascending" },
                  { id: "-cost", name: "Price descending" }
                ]}
                returnData={setSortBy}
              />
            </div>
          </div>
        )}

        {!isAllProductsSwitchToggled && (
          <div className="coupon-container__sort-bottom">
            <div className={"coupon-container__sort-bottom__selector"}>
              <DefaultMultiLevelDropdown
                label="Category"
                inputFunc={e =>
                  isAllProductsSwitchToggled
                    ? searchB2CCategory(e.target.value)
                    : searchB2BCategory(e.target.value)
                }
                filter={true}
                style={{ width: "100%" }}
                items={
                  !isAllProductsSwitchToggled
                    ? b2bCategoriesResults
                    : b2cCategoriesResults
                }
                returnData={setCategory}
              />
            </div>
            <div className={"coupon-container__sort-bottom__selector"}>
              <DefaultDropdown
                label="Sort by"
                style={{ width: "100%" }}
                items={[
                  { id: "-id", name: "Newest" },
                  { id: "id", name: "Oldest" },
                  { id: "cost", name: "Price ascending" },
                  { id: "-cost", name: "Price descending" }
                ]}
                returnData={setSortBy}
              />
            </div>
          </div>
        )}
      </div>
      <ProductSwitch
        isToggled={isAllProductsSwitchToggled}
        onToggle={() => toggleAllProductsSwitch(!isAllProductsSwitchToggled)}
      />
      <div className="coupon-container__cards">
        {discountCategoryType === "Coupon Discount"
          ? productsWithCouponDiscount &&
            productsWithCouponDiscount.results.map(product => {
              const {
                id,
                image,
                name,
                cost,
                currency,
                categories,
                discount_percent,
                coupon_discount_percent,
                metadata
              } = product;
              return (
                <ProductCard
                  navProductType={productsType === "b2b" ? "b2b" : "b2c"}
                  productId={id}
                  key={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={name}
                  currency={currency}
                  price={cost}
                  currentProductData={product}
                  discountPercent={discount_percent}
                  couponDiscountPercent={coupon_discount_percent}
                  metadata={metadata}
                />
              );
            })
          : productsWithSimpleDiscount &&
            productsWithSimpleDiscount.results.map(product => {
              const {
                id,
                image,
                name,
                cost,
                currency,
                categories,
                discount_percent,
                coupon_discount_percent
              } = product;
              return (
                <ProductCard
                  navProductType={productsType === "b2b" ? "b2b" : "b2c"}
                  productId={id}
                  key={id}
                  imgUrl={image ? image : missingImage}
                  name={categories[0] && categories[0].name}
                  description={name}
                  currency={currency}
                  price={cost}
                  currentProductData={product}
                  discountPercent={discount_percent}
                  couponDiscountPercent={coupon_discount_percent}
                />
              );
            })}
      </div>
      <Pagination
        forcePage={currentPage - 1}
        dataCount={
          discountCategoryType === "Coupon Discount"
            ? productsWithCouponDiscount && productsWithCouponDiscount.count
            : productsWithSimpleDiscount && productsWithSimpleDiscount.count
        }
        selectedPage={e => setCurrentPage(e)}
        itemsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areProductsWithCouponDiscountPending:
    state.salesAndCoupons.areProductsWithCouponDiscountPending,
  productsWithCouponDiscount: state.salesAndCoupons.productsWithCouponDiscount,
  areProductsWithSimpleDiscountPending:
    state.salesAndCoupons.areProductsWithSimpleDiscountPending,
  productsWithSimpleDiscount: state.salesAndCoupons.productsWithSimpleDiscount,

  cart: state.cart.carty,
  isAllProductsSwitchToggled: state.productSwitch.isAllProductsSwitchToggled,
  b2bCategoriesResults: state.categoriesAPI.b2bCategoriesResults,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults
});

export default connect(mapStateToProps, {
  getProductsWithCouponDiscount,
  getProductsWithSimpleDiscount,

  getCart,
  toggleAllProductsSwitch,
  getB2BCategories,
  getB2CCategories,
  addProductToWishList
})(Coupon);
