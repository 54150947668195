import React from "react";

import { Link } from "react-router-dom";

import "./no-data-found.styles.scss";

const MyBusinessReturn = ({ additionalLink }) => {
  return (
    <div>
      You can create one in{" "}
      <Link to={additionalLink}>
        <strong>My Business!</strong>
      </Link>
    </div>
  );
};

const NoDataFound = ({ noDataTitle, myBusiness, additionalLink }) => {
  return (
    <div className="no-data-container">
      <div className="no-data-container__main-text">
        {noDataTitle}
        {myBusiness && <MyBusinessReturn additionalLink={additionalLink} />}
      </div>
    </div>
  );
};

export default NoDataFound;
