import React from "react";

import "./product-specification-about.styles.scss";

const ProductSpecificationAbout = ({ specifications, specificationsWeb }) => {
  return (
    <div className="product-specification-about">
      <h3>Specification</h3>
      <div className="product-specification-about__table">
        {specifications &&
          Object.keys(specifications).map(key => {
            if (specifications[key]) {
              return (
                <div className="product-specification-about__row">
                  <div className="product-specification-about__label">
                    {key}:{" "}
                  </div>
                  <div className="product-specification-about__value">
                    {specifications[key]}
                  </div>
                </div>
              );
            } else return false;
          })}
        {specificationsWeb &&
          Object.keys(specificationsWeb).map(key => {
            if (specificationsWeb[key]) {
              return (
                <div className="product-specification-about__row">
                  <div className="product-specification-about__label">
                    {key}:{" "}
                  </div>
                  <div className="product-specification-about__value">
                    {specificationsWeb[key]}
                  </div>
                </div>
              );
            } else return false;
          })}
      </div>
    </div>
  );
};

export default ProductSpecificationAbout;
