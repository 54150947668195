import { typeCustomCode } from "./config";
import icon from "./chat-bot-block-icon.png";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { blockCustomCode, blockLabel } = opts;

  blockCustomCode &&
    bm.add(typeCustomCode, {
      label: `
        <img src="${icon}" alt="companies with pagination block icon" />
        <div class="gjs-block-label" style="margin-top: 5px">
          ${blockLabel}
        </div>
      `,
      category: "B24Online Bots",
      activate: true,
      select: true,
      content: { type: typeCustomCode },
      ...blockCustomCode
    });
};
