import { PortalRecomendationsTypes } from "./portal-recomendations.types";

const INITIAL_STATE = {
  arePortalRecommendsPending: false,
  portalRecommends: [],
  portalRecommendsError: {}
};

const PortalRecommendationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PortalRecomendationsTypes.GET.GET_PORTAL_RECOMENDATIONS_PENDING:
      return {
        ...state,
        arePortalRecommendsPending: true
      };

    case PortalRecomendationsTypes.GET.GET_PORTAL_RECOMENDATIONS_SUCCESS:
      return {
        ...state,
        portalRecommends: action.payload,
        arePortalRecommendsPending: false
      };

    case PortalRecomendationsTypes.GET.GET_PORTAL_RECOMENDATIONS_ERROR:
      return {
        ...state,
        portalRecommendationsError: action.payload,
        arePortalRecommendsPending: false
      };

    default:
      return state;
  }
};

export default PortalRecommendationsReducer;
