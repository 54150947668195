export default function privatePageComponent({ editor, api }) {
  const script = function() {
    const redirect =
      (this.attributes.redirect && this.attributes.redirect.value) || "#";

    if (!localStorage.getItem("token") && window.hasOwnProperty("grapesjs")) {
      window.location.assign(redirect);
    }
  };

  editor.DomComponents.addType("private-page", {
    model: {
      defaults: {
        name: "Private Page",
        script,
        traits: [
          {
            type: "page-selector",
            label: "Redirect",
            name: "redirect",
            modalTitle:
              "Choose a page for redirecting if there is no user authentication"
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:redirect", this.render);
      }
    }
  });
}
