import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "./styles.modules.scss";

import { createOrderPelecard } from "~/redux/actions-exporter";

import { colorPalette } from "~/styles/colorPalette";
import { DefaultButton } from "@core/components";

const PelecardButton = ({ createOrderPelecard, company, rootPath, pelecardData }) => {
  const [disabled, setDisabled] = useState(false);

  const createOrder = () => {
    setDisabled(true);
    createOrderPelecard(
      company,
      `${rootPath}/order_check`,
      `${rootPath}/cart/order/error`
    );
  };

  useEffect(() => {
    if (!pelecardData?.url) {
      setDisabled(false);
    }
  }, [pelecardData]);

  return (
    <div className="pelecard__btn-container">
      <DefaultButton
        border={`1px solid ${colorPalette.colorPrimary.main}`}
        color={colorPalette.colorPrimary.main}
        width="100%"
        disabled={disabled}
        onClick={() => createOrder()}
      >
        Create order
      </DefaultButton>
    </div>
  );
};

const mapStateToProps = state => ({
  pelecardData: state.pelecardReducer.pelecardData
});

export default connect(mapStateToProps, {
  createOrderPelecard
})(PelecardButton);
