import axios from "axios";

import { ProductVariationsTypes } from "./product-variations.types";
import { axiosInstance, getCookie, baseURL } from "../../../axios/axios.config";

export const getProductVariationsByParentId = (
  productType,
  parentId
) => dispatch => {
  dispatch({
    type: ProductVariationsTypes.GET.GET_PRODUCT_VARIATIONS_BY_PARENT_ID_PENDING
  });

  axiosInstance
    .get(`/api/v1/${productType}/product_variation/?parent=${parentId}`)
    .then(productVariations => {
      dispatch({
        type:
          ProductVariationsTypes.GET
            .GET_PRODUCT_VARIATIONS_BY_PARENT_ID_SUCCESS,
        payload: productVariations.data
      });
    })
    .catch(err => {
      dispatch({
        type:
          ProductVariationsTypes.GET.GET_PRODUCT_VARIATIONS_BY_PARENT_ID_ERROR,
        payload: err
      });
    });
};

export const getProductVariationById = (
  productType,
  productVariationId
) => dispatch => {
  dispatch({
    type: ProductVariationsTypes.GET.GET_PRODUCT_VARIATION_BY_ID_PENDING
  });

  axiosInstance
    .get(`api/v1/${productType}/product_variation/${productVariationId}/`)
    .then(productVariation => {
      dispatch({
        type: ProductVariationsTypes.GET.GET_PRODUCT_VARIATION_BY_ID_SUCCESS,
        payload: productVariation.data
      });
    })
    .catch(err => {
      dispatch({
        type: ProductVariationsTypes.GET.GET_PRODUCT_VARIATION_BY_ID_ERROR,
        payload: err
      });
    });
};

export const addProductVariation = (
  productType,
  size,
  color,
  value,
  name,
  image,
  stock,
  cost,
  parent,
  sku
) => dispatch => {
  dispatch({
    type: ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_PENDING
  });

  axiosInstance
    .post(`/api/v1/${productType}/product_variation/`, {
      size,
      color,
      value,
      name,
      stock,
      cost,
      parent,
      metadata: {
        stock_keeping_unit: sku
      }
    })
    .then(addedVariation => {
      if (image) {
        dispatch({
          type: ProductVariationsTypes.PATCH.IMAGE.PATCH_VARIATION_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", image);

        axios
          .patch(
            `${baseURL}/api/v1/${productType}/product_variation/${addedVariation.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp => {
            dispatch({
              type:
                ProductVariationsTypes.PATCH.IMAGE
                  .PATCH_VARIATION_IMAGE_SUCCESS,
              payload: { variation: resp.data, requestStatus: resp.status }
            });
          })
          .then(_ => {
            dispatch({
              type: ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_SUCCESS,
              payload: {
                productAdded: addedVariation.data,
                requestStatus: addedVariation.status
              }
            });
          });
      } else {
        dispatch({
          type: ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_SUCCESS,
          payload: {
            productAdded: addedVariation.data,
            requestStatus: addedVariation.status
          }
        });
      }
    })
    .catch(err => {
      dispatch({
        type: ProductVariationsTypes.POST.ADD_PRODUCT_VARIATION_ERROR,
        payload: err
      });
    });
};

export const putProductVariation = (
  variationId,
  productType,
  size,
  color,
  value,
  name,
  image,
  stock,
  cost,
  parent,
  sku
) => dispatch => {
  dispatch({
    type: ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_PENDING
  });

  axiosInstance
    .put(`/api/v1/${productType}/product_variation/${variationId}/`, {
      size,
      color,
      value,
      name,
      stock,
      cost,
      parent,
      metadata: {
        stock_keeping_unit: sku
      }
    })
    .then(addedVariation => {
      if (image) {
        dispatch({
          type: ProductVariationsTypes.PATCH.IMAGE.PATCH_VARIATION_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", image);

        axios
          .patch(
            `${baseURL}/api/v1/${productType}/product_variation/${addedVariation.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp => {
            dispatch({
              type:
                ProductVariationsTypes.PATCH.IMAGE
                  .PATCH_VARIATION_IMAGE_SUCCESS,
              payload: { variation: resp.data, requestStatus: resp.status }
            });
          })
          .then(_ => {
            dispatch({
              type: ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_SUCCESS,
              payload: {
                productPutted: addedVariation.data,
                requestStatus: addedVariation.status
              }
            });
          });
      } else {
        dispatch({
          type: ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_SUCCESS,
          payload: {
            productPutted: addedVariation.data,
            requestStatus: addedVariation.status
          }
        });
      }
    })
    .catch(err => {
      dispatch({
        type: ProductVariationsTypes.PUT.EDIT_PRODUCT_VARIATION_ERROR,
        payload: err
      });
    });
};

export const deleteProductVariationById = (
  variationId,
  productType,
  parentId
) => dispatch => {
  dispatch({
    type: ProductVariationsTypes.DELETE.DELETE_PRODUCT_VARIATION_BY_ID_PENDING
  });

  axiosInstance
    .delete(`/api/v1/${productType}/product_variation/${variationId}`)
    .then(deletedVariation => {
      dispatch({
        type:
          ProductVariationsTypes.DELETE.DELETE_PRODUCT_VARIATION_BY_ID_SUCCESS,
        payload: deletedVariation
      });
      dispatch(getProductVariationsByParentId(productType, parentId));
    });

  dispatch({
    type: ProductVariationsTypes.DELETE.DELETE_PRODUCT_VARIATION_BY_ID_ERROR
  });
};

export const clearProductVariationRequestStatus = () => dispatch => {
  dispatch({
    type: ProductVariationsTypes.CLEAR_PRODUCT_VARIATION_REQUEST_STATUS,
    payload: ""
  });
};
