import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import am from "../core/locales/am.json";
import ar from "../core/locales/ar.json";
import bg from "../core/locales/bg.json";
import en from "../core/locales/en.json";
import es from "../core/locales/es.json";
import he from "../core/locales/he.json";
import ru from "../core/locales/ru.json";
import uk from "../core/locales/uk.json";
import zh from "../core/locales/zh.json";

import amLocal from "./locales/am.json";
import arLocal from "./locales/ar.json";
import bgLocal from "./locales/bg.json";
import enLocal from "./locales/en.json";
import esLocal from "./locales/es.json";
import heLocal from "./locales/he.json";
import ruLocal from "./locales/ru.json";
import ukLocal from "./locales/uk.json";
import zhLocal from "./locales/zh.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      am: {
        translations: Object.assign(am, amLocal)
      },
      ar: {
        translations: Object.assign(ar, arLocal)
      },
      bg: {
        translations: Object.assign(bg, bgLocal)
      },
      en: {
        translations: Object.assign(en, enLocal)
      },
      es: {
        translations: Object.assign(es, esLocal)
      },
      he: {
        translations: Object.assign(he, heLocal)
      },
      ru: {
        translations: Object.assign(ru, ruLocal)
      },
      uk: {
        translations: Object.assign(uk, ukLocal)
      },
      zh: {
        translations: Object.assign(zh, zhLocal)
      }
    },
    fallbackLng: "en",
    lng: (localStorage && localStorage.getItem("i18nextLng")) || "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
