export const CartActionTypes = {
  GET: {
    GET_CART_PENDING: "GET_CART_PENDING",
    GET_CART_SUCCESS: "GET_CART_SUCCESS",
    GET_CART_ERROR: "GET_CART_ERROR",

    GET_CART_COMPANIES_WITH_PRODUCTS_PENDING:
      "GET_CART_COMPANIES_WITH_PRODUCTS_PENDING",
    GET_CART_COMPANIES_WITH_PRODUCTS_SUCCESS:
      "GET_CART_COMPANIES_WITH_PRODUCTS_SUCCESS",
    GET_CART_COMPANIES_WITH_PRODUCTS_ERROR:
      "GET_CART_COMPANIES_WITH_PRODUCTS_ERROR",

    GET_ONE_CART_COMPANY_WITH_PRODUCTS_PENDING:
      "GET_ONE_CART_COMPANY_WITH_PRODUCTS_PENDING",
    GET_ONE_CART_COMPANY_WITH_PRODUCTS_SUCCESS:
      "GET_ONE_CART_COMPANY_WITH_PRODUCTS_SUCCESS",
    GET_ONE_CART_COMPANY_WITH_PRODUCTS_ERROR:
      "GET_ONE_CART_COMPANY_WITH_PRODUCTS_ERROR",

    DEAL: {
      GET_DEAL_PENDING: "GET_DEAL_PENDING",
      GET_DEAL_SUCCESS: "GET_DEAL_SUCCESS",
      GET_DEAL_ERROR: "GET_DEAL_ERROR"
    }
  },
  ADD: {
    ADD_TO_CART_PENDING: "ADD_TO_CART_PENDING",
    ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
    ADD_TO_CART_ERROR: "ADD_TO_CART_ERROR"
  },
  DELETE: {
    DEAL: {
      DELETE_DEAL_PENDING: "DELETE_DEAL_PENDING",
      DELETE_DEAL_SUCCESS: "DELETE_DEAL_SUCCESS",
      DELETE_DEAL_ERROR: "DELETE_DEAL_ERROR",

      DELETE_DEAL_ALL_PENDING: "DELETE_DEAL_ALL_PENDING",
      DELETE_DEAL_ALL_SUCCESS: "DELETE_DEAL_ALL_SUCCESS",
      DELETE_DEAL_ALL_ERROR: "DELETE_DEAL_ALL_ERROR"
    }
  },
  PUT: {
    CART: {
      PUT_CART_PENDING: "PUT_CART_PENDING",
      PUT_CART_SUCCESS: "PUT_CART_SUCCESS",
      PUT_CART_ERROR: "PUT_CART_ERROR"
    }
  },
  PATCH: {
    PATCH_DEAL_PENDING: "PATCH_DEAL_PENDING",
    PATCH_DEAL_SUCCESS: "PATCH_DEAL_SUCCESS",
    PATCH_DEAL_ERROR: "PATCH_DEAL_ERROR"
  },
  RESET_PELECARD: "RESET_PELECARD"
};
