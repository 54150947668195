const recommendedProductsStyle = `
<style> 
.recommended-products-wrapper {}

.recommended-products__title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin: 10px 0;
}

.recommended-products-wrapper.recommended-products-wrapper--preloader {
  position: relative;
  transition: all 300ms ease;
}

.recommended-products-wrapper.recommended-products-wrapper--preloader::before {
  content: "";
  background: hsla(0, 0%, 100%, 0.45);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.recommended-products {
  display: flex;
  flex-flow: row wrap;
}

.rp-product {
  width: 33.33%;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 20px;
}

@media screen and (max-width: 992px) {
  .rp-product {
    flex-basis: 100%;
  }
}

.rp-product__image {
  width: 100px;
  height: 100px;
  padding: 10px;
  object-fit: scale-down;
}

.rp-product__title {
  color: rgb(38, 38, 38);
  font-size: 1.2em;
  font-weight: 700;
  margin: 0 0 5px;
}

.rp-product__link, .rp-product__link:hover {
  color: inherit;
  text-decoration-color: currentColor;
}

.rp-product__meta {}

.rp-product__meta--cost {
  margin: auto 0 15px;
}

.rp-product__cost-title {
  color: rgb(38, 38, 38);
  font-size: 18px;
  font-weight: 700;
}

.rp-product__cost {
  color: #262626;
  font-size: 18px;
  font-weight: 700;
  margin: auto 0 8px;
}

.rp-product__currency {
  color: rgb(38, 38, 38);
  font-size: 18px;
  font-weight: 700;
}

.rp-product__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rp-product__button {
  background-color: transparent;
  color: #6f6f6f;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 120px;
  padding: 4px 30px;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;  
}

.rp-product__button:hover {
  background-color: #aeaeae;
  color: #fff;
}

.rp-product__button--disabled {
  background-color: #aeaeae;
  color: #fff;
  cursor: not-allowed;
}

.recommended-products_rtl .rp-product__title {
  text-align: right;
}

.recommended-products_rtl .rp-product__meta--cost {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.recommended-products_rtl .rp-product__cost {
  margin-right: 5px;
}
</style>
`;

export default recommendedProductsStyle;
