import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";

import { colorPalette } from "../../styles/colorPalette";
import "./filter-item.styles.scss";

const FilterItem = ({ itemId, name, removeFilterItem }) => {
  return (
    <div className="filter-item">
      <div className="filter-item__data">{name}</div>
      <CloseIcon
        className="filter-item__icon"
        style={{ width: "12px", fill: `${colorPalette.colorPrimary.main}` }}
        onClick={() => removeFilterItem(itemId)}
      />
    </div>
  );
};

export default FilterItem;
