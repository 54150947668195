import {
  axiosInstance,
  axiosNoTokenInstance
} from "../../../axios/axios.config";
import { BranchesActionTypes } from "./branches.types";

export const getBranches = () => dispatch => {
  dispatch({ type: BranchesActionTypes.GET.GET_BRANCHES_PENDING });
  axiosNoTokenInstance
    .get("/api/v1/organization/branches/", {})
    .then(branches =>
      dispatch({
        type: BranchesActionTypes.GET.GET_BRANCHES_SUCCESS,
        payload: branches.data
      })
    )
    .catch(error =>
      dispatch({
        type: BranchesActionTypes.GET.GET_BRANCHES_ERROR,
        payload: error
      })
    );
};

export const createBranch = name => dispatch => {
  axiosInstance.post("/api/v1/organization/branches/", { name }).then(branch =>
    dispatch({
      type: BranchesActionTypes.CREATE_BRANCH,
      payload: branch
    })
  );
};

export const updateBranch = (branchId, name) => dispatch => {
  axiosInstance
    .put(`/api/v1/organization/branches/${branchId}/`, { name })
    .then(branch =>
      dispatch({
        type: BranchesActionTypes.UPDATE_BRANCH,
        payload: branch
      })
    );
};

export const deleteBranch = branchId => dispatch => {
  axiosInstance
    .delete(`/api/v1/organization/branches/${branchId}/`)
    .then(deletedBranch =>
      dispatch({
        type: BranchesActionTypes.DELETE_BRANCH,
        payload: deletedBranch
      })
    );
};

export const searchBranch = input => dispatch => {
  dispatch({ type: BranchesActionTypes.GET.GET_BRANCHES_PENDING });
  axiosNoTokenInstance
    .get(`/api/v1/organization/branches/?search=${input}`)
    .then(branches =>
      dispatch({
        type: BranchesActionTypes.GET.GET_BRANCHES_SUCCESS,
        payload: branches.data
      })
    )
    .catch(error =>
      dispatch({
        type: BranchesActionTypes.GET.GET_BRANCHES_ERROR,
        payload: error
      })
    );
};
