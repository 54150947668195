export const DocumentsActionTypes = {
  POST: {
    UPLOAD_ADDITIONAL_PARAMETER_PENDING: "UPLOAD_ADDITIONAL_PARAMETER_PENDING",
    UPLOAD_ADDITIONAL_PARAMETER_SUCCESS: "UPLOAD_ADDITIONAL_PARAMETER_SUCCESS",
    UPLOAD_ADDITIONAL_PARAMETER_ERROR: "UPLOAD_ADDITIONAL_PARAMETER_ERROR",

    UPLOAD_DOCUMENT_PENDING: "UPLOAD_DOCUMENT_PENDING",
    UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
    UPLOAD_DOCUMENT_ERROR: "UPLOAD_DOCUMENT_ERROR",

    UPLOAD_ADDITIONAL_PAGE_PENDING: "UPLOAD_ADDITIONAL_PAGE_PENDING",
    UPLOAD_ADDITIONAL_PAGE_SUCCES: "UPLOAD_ADDITIONAL_PAGE_SUCCES",
    UPLOAD_ADDITIONAL_PAGE_ERROR: "UPLOAD_ADDITIONAL_PAGE_ERROR",

    UPLOAD_PRODUCT_SIZE_PENDING: "UPLOAD_PRODUCT_SIZE_PENDING",
    UPLOAD_PRODUCT_SIZE_SUCCESS: "UPLOAD_PRODUCT_SIZE_SUCCESS",
    UPLOAD_PRODUCT_SIZE_ERROR: "UPLOAD_PRODUCT_SIZE_ERROR",

    UPLOAD_PRODUCT_IMAGE_GALLERY_PENDING:
      "UPLOAD_PRODUCT_IMAGE_GALLERY_PENDING",
    UPLOAD_PRODUCT_IMAGE_GALLERY_SUCCESS:
      "UPLOAD_PRODUCT_IMAGE_GALLERY_SUCCESS",
    UPLOAD_PRODUCT_IMAGE_GALLERY_ERROR: "UPLOAD_PRODUCT_IMAGE_GALLERY_ERROR",

    UPLOAD_PRODUCT_VIDEO_GALLERY_PENDING:
      "UPLOAD_PRODUCT_VIDEO_GALLERY_PENDING",
    UPLOAD_PRODUCT_VIDEO_GALLERY_SUCCESS:
      "UPLOAD_PRODUCT_VIDEO_GALLERY_SUCCESS",
    UPLOAD_PRODUCT_VIDEO_GALLERY_ERROR: "UPLOAD_PRODUCT_VIDEO_GALLERY_ERROR"
  },
  DELETE: {
    DELETE_DOCUMENT_PENDING: "DELETE_DOCUMENT_PENDING",
    DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
    DELETE_DOCUMENT_ERROR: "DELETE_DOCUMENT_ERROR",

    DELETE_ADDITIONAL_PAGE_BY_ID_PENDING:
      "DELETE_ADDITIONAL_PAGE_BY_ID_PENDING",
    DELETE_ADDITIONAL_PAGE_BY_ID_SUCCESS:
      "DELETE_ADDITIONAL_PAGE_BY_ID_SUCCESS",
    DELETE_ADDITIONAL_PAGE_BY_ID_ERROR: "DELETE_ADDITIONAL_PAGE_BY_ID_ERROR",

    DELETE_ADDITIONAL_PARAMETER_PENDING: "DELETE_ADDITIONAL_PARAMETER_PENDING",
    DELETE_ADDITIONAL_PARAMETER_SUCCESS: "DELETE_ADDITIONAL_PARAMETER_SUCCESS",
    DELETE_ADDITIONAL_PARAMETER_ERROR: "DELETE_ADDITIONAL_PARAMETER_ERROR",

    DELETE_SIZE_BY_ID_PENDING: "DELETE_SIZE_BY_ID_PENDING",
    DELETE_SIZE_BY_ID_SUCCESS: "DELETE_SIZE_BY_ID_SUCCESS",
    DELETE_SIZE_BY_ID_ERROR: "DELETE_SIZE_BY_ID_ERROR",

    DELETE_ONE_IMAGE_FROM_GALLERY_PENDING:
      "DELETE_ONE_IMAGE_FROM_GALLERY_PENDING",
    DELETE_ONE_IMAGE_FROM_GALLERY_SUCCESS:
      "DELETE_ONE_IMAGE_FROM_GALLERY_SUCCESS",
    DELETE_ONE_IMAGE_FROM_GALLERY_ERROR: "DELETE_ONE_IMAGE_FROM_GALLERY_ERROR"
  },
  LOAD_BLOB_ON_CHANGE: "LOAD_BLOB_ON_CHANGE",
  LOAD_FILE_ON_CHANGE: "LOAD_FILE_ON_CHANGE",

  LOAD_VIDEO_FILE_ON_CHANGE: "LOAD_VIDEO_FILE_ON_CHANGE",
  LOAD_VIDEO_BLOB_ON_CHANGE: "LOAD_VIDEO_BLOB_ON_CHANGE"
};
