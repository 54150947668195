import { CartActionTypes } from "./cart.types";
import { axiosInstance } from "../../../axios/axios.config";

export const getCart = () => dispatch => {
  dispatch({ type: CartActionTypes.GET.GET_CART_PENDING });

  axiosInstance
    .get("/api/v1/payments/cart?deal__status=draft")
    .then(cart =>
      dispatch({
        type: CartActionTypes.GET.GET_CART_SUCCESS,
        payload: { cart: cart.data.results, count: cart.data.count }
      })
    )
    .catch(error =>
      dispatch({ type: CartActionTypes.GET.GET_CART_ERROR, payload: error })
    );
};

export const addToCart = (
  customerType,
  organizationId,
  itemB2B,
  itemB2C,
  quantity,
  additional_parameters,
  shipping_method
) => dispatch => {
  dispatch({ type: CartActionTypes.ADD.ADD_TO_CART_PENDING });

  axiosInstance
    .post("/api/v1/payments/cart/", {
      customer_type: customerType,
      customer_organization: organizationId,
      item_b2b: itemB2B,
      item_b2c: itemB2C,
      quantity,
      shipping_method,
      additional_parameters
    })
    .then(addedQuantity => {
      dispatch({
        type: CartActionTypes.ADD.ADD_TO_CART_SUCCESS,
        payload: addedQuantity
      });

      axiosInstance
        .get("/api/v1/payments/cart?deal__status=draft")
        .then(cart =>
          dispatch({
            type: CartActionTypes.GET.GET_CART_SUCCESS,
            payload: { cart: cart.data.results, count: cart.data.count }
          })
        )
        .catch(error =>
          dispatch({ type: CartActionTypes.GET.GET_CART_ERROR, payload: error })
        );
    })
    .catch(error =>
      dispatch({ type: CartActionTypes.ADD.ADD_TO_CART_ERROR, payload: error })
    );

  dispatch({ type: CartActionTypes.GET.GET_CART_PENDING });
};

export const getDeal = (page = 1) => dispatch => {
  dispatch({ type: CartActionTypes.GET.DEAL.GET_DEAL_PENDING });
  axiosInstance
    .get(`/api/v1/payments/deal/?status=draft&page=${page}`)
    .then(deal =>
      dispatch({
        type: CartActionTypes.GET.DEAL.GET_DEAL_SUCCESS,
        payload: deal.data
      })
    )
    .catch(error =>
      dispatch({
        type: CartActionTypes.GET.DEAL.GET_DEAL_ERROR,
        payload: error
      })
    );
};

export const deleteDeal = dealId => dispatch => {
  dispatch({ type: CartActionTypes.DELETE.DEAL.DELETE_DEAL_PENDING });
  axiosInstance
    .delete(`/api/v1/payments/cart/${dealId}/`)
    .then(res => {
      dispatch({
        type: CartActionTypes.DELETE.DEAL.DELETE_DEAL_SUCCESS,
        payload: res
      });
    })
    .catch(error =>
      dispatch({
        type: CartActionTypes.DELETE.DEAL.DELETE_DEAL_ERROR,
        payload: error
      })
    );
};

export const deleteAllDeal = cartData => dispatch => {
  dispatch({ type: CartActionTypes.DELETE.DEAL.DELETE_DEAL_ALL_PENDING });
  axiosInstance
    .delete(`/api/v1/payments/cart/${cartData}`)
    .then(() => {
      dispatch({
        type: CartActionTypes.DELETE.DEAL.DELETE_DEAL_ALL_SUCCESS
      });
    })
    .catch(error =>
      dispatch({
        type: CartActionTypes.DELETE.DEAL.DELETE_DEAL_ALL_ERROR,
        payload: error
      })
    );
};

export const editCart = (cartId, quantity) => dispatch => {
  dispatch({ type: CartActionTypes.PUT.CART.PUT_CART_PENDING });
  axiosInstance
    .patch(`/api/v1/payments/cart/${cartId}/`, {
      quantity: quantity
    })
    .then(cart =>
      dispatch({
        type: CartActionTypes.PUT.CART.PUT_CART_SUCCESS,
        payload: cart.data
      })
    )
    .then(_ => dispatch(getCartCompaniesWithTheirProductsSuccess()))
    .catch(error =>
      dispatch({
        type: CartActionTypes.PUT.CART.PUT_CART_ERROR,
        payload: error
      })
    );
};

export const patchDeal = (
  dealId,
  firstName,
  lastName,
  phoneNumber,
  countryId,
  email,
  address,
  dealOrder,
  agree
) => dispatch => {
  dispatch({ type: CartActionTypes.PATCH.PATCH_DEAL_PENDING });

  axiosInstance
    .patch(`/api/v1/payments/deal/${dealId}/`, {
      person_first_name: firstName,
      person_last_name: lastName,
      person_phone_number: phoneNumber,
      person_country: countryId,
      person_email: email,
      person_address: address,
      deal_order: { customer_type: dealOrder },
      agree
    })
    .then(resp => {
      dispatch({
        type: CartActionTypes.PATCH.PATCH_DEAL_SUCCESS,
        payload: resp.data
      });
    })
    .then(_ => {
      dispatch(getCartCompaniesWithTheirProducts());
      dispatch(getCart());
    })
    .catch(err =>
      dispatch({ type: CartActionTypes.PATCH.PATCH_DEAL_ERROR, payload: err })
    );
};

export const getCartCompaniesWithTheirProductsSuccess = () => dispatch => {
  axiosInstance
    .get(`/api/v1/payments/deal/actual_companies/`)
    .then(actualCompanies =>
      Promise.all(
        actualCompanies.data.map(company =>
          axiosInstance
            .get(`/api/v1/payments/deal/?status=draft&company=${company.id}`)
            .then(products =>
              axiosInstance
                .get(
                  `/api/v1/payments/deal/cart_info/?status=draft&company=${company.id}`
                )
                .then(cartData => ({
                  ...company,
                  company_my_deals: [...products.data.results],
                  company_cart_info: [...cartData.data]
                }))
            )
        )
      )
        .then(allProducts => {
          dispatch({
            type: CartActionTypes.GET.GET_CART_COMPANIES_WITH_PRODUCTS_SUCCESS,
            payload: allProducts
          });
        })
        .catch(err => {
          dispatch({
            type: CartActionTypes.GET.GET_CART_COMPANIES_WITH_PRODUCTS_ERROR,
            payload: err
          });
        })
    );
};

export const getCartCompaniesWithTheirProducts = () => dispatch => {
  dispatch({
    type: CartActionTypes.GET.GET_CART_COMPANIES_WITH_PRODUCTS_PENDING
  });
  dispatch(getCartCompaniesWithTheirProductsSuccess());
};

export const getOneCartCompanyWithProducts = companyId => dispatch => {
  dispatch({
    type: CartActionTypes.GET.GET_ONE_CART_COMPANY_WITH_PRODUCTS_PENDING
  });
  dispatch({
    type: CartActionTypes.RESET_PELECARD
  });
  axiosInstance
    .get(`/api/v1/payments/deal/?status=draft&company=${companyId}`)
    .then(companyProducts => {
      axiosInstance
        .get(
          `/api/v1/payments/deal/cart_info/?status=draft&company=${companyId}`
        )
        .then(cartInfo => {
          dispatch({
            type:
              CartActionTypes.GET.GET_ONE_CART_COMPANY_WITH_PRODUCTS_SUCCESS,
            payload: {
              company_products: [...companyProducts.data.results],
              company_cart_info: [...cartInfo.data]
            }
          });
        });
    })
    .catch(err => {
      dispatch({
        type: CartActionTypes.GET.GET_ONE_CART_COMPANY_WITH_PRODUCTS_ERROR,
        payload: err
      });
    });
};

export const createOrderPelecard = (company, goodUrl, errorUrl) => dispatch => {
  axiosInstance
    .post("/api/v1/payments/deal/pelecard_create/", {
      company,
      good_url: goodUrl,
      error_url: errorUrl
    })
    .then(response => {
      dispatch({
        type: "CREATE_PELECARD_ORDER_SUCCESS",
        payload: response.data
      });
      window.localStorage.setItem("order_company", company);
    })
    .catch(error =>
      dispatch({ type: "CREATE_PELECARD_ORDER_ERROR", payload: error })
    );
};

export const checkOrderPelecard = company => dispatch => {
  axiosInstance
    .post("/api/v1/payments/deal/pelecard_check/", {
      company
    })
    .then(response => {
      if (response.data.details === "Success") {
        dispatch({
          type: "CHECK_PELECARD_TRANSACTION_SUCCESS",
          payload: response.data
        });
      } else {
        dispatch({
          type: "CHECK_PELECARD_TRANSACTION_RELOAD",
          payload: response.data
        });
      }
    })
    .catch(error => {
      dispatch({
        type: "CHECK_PELECARD_TRANSACTION_ERROR",
        payload: error
      });
    });
};
