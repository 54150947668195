import React, { useState } from "react";

import Select from "react-select";

import { colorPalette } from "~/styles/colorPalette";
import "./default-select-input.styles.scss";

const DefaultSelectInput = ({
  title,
  width,
  placeholder = "",
  options,
  onChange,
  value,
  returnData,
  customData
}) => {
  const customStyle = {
    container: styles => ({ ...styles, minWidth: width }),
    control: styles => ({
      ...styles,
      borderColor: "lightgray",
      borderRadius: "0px",
      boxShadow: `none`,
      padding: "4px 0",

      "&:hover": {
        borderColor: colorPalette.colorPrimary.main
      },
      "&:focus": {
        outlineColor: `colorPalette.colorPrimary.main`,

        borderColor: colorPalette.colorPrimary.main
      }
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "lightgray";
      return {
        ...styles,
        color: "black",
        backgroundColor: isFocused ? color : isSelected ? color : null,
        ":active": {
          ...styles[":active"],
          backgroundColor: color
        }
      };
    },
    menu: styles => ({ ...styles, zIndex: "9999" })
  };

  const [data, setData] = useState("");
  const [changeData, setChangeData] = useState(true);

  return (
    <div className="default-select-input">
      <div className="default-select-input__title">{title}</div>
      <br />
      <div className="default-select-input__flex-container">
        <Select
          styles={customStyle}
          placeholder={placeholder}
          defaultValue={{ label: customData, value: customData }}
          onChange={val => {
            returnData(val.label);
            setData(val);
            setChangeData(false);
          }}
          value={
            changeData
              ? options.filter(option => option.label === customData)
              : data
          }
          options={options}
        />
        <input
          onChange={onChange}
          value={value}
          className="default-select-input__input"
          type="number"
          name="phone-number"
        />
      </div>
    </div>
  );
};

export default DefaultSelectInput;
