import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  LoadingSpinner,
  EventCard,
  Pagination,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import { getPages, deletePage } from "../../store/API/pages/pages.action";

import "./my-business-static-pages.styles.scss";
import { useTranslation } from "react-i18next";

const MyBusinessPages = () => {
  const arePagesPending = useSelector(state => state.pagesReducer.arePagesPending);
  const staticPages = useSelector(state => state.pagesReducer.pages);
  const staticPagesCount = useSelector(state => state.pagesReducer.pagesCount);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [currentPageId, setCurrentPageId] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPages(page));
  }, [dispatch, page]);

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div className="my-business-pages">
      <ConfirmDialog
        dialogTitle={t("Page remove")}
        dialogText={t("Are you sure you want to delete this item? Data deletion can not be undone, please double check!")}
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          dispatch(deletePage(currentPageId));
          setDialogOpen(false);
          setCurrentPageId(0);
        }}
      />
      {arePagesPending ? (
        <LoadingSpinner />
      ) : staticPages && staticPages.length ? (
        staticPages.map(
          ({
            id,
            title
          }) => (
            <EventCard
              key={id}
              title={title}
              editorState={true}
              editLink={`/my-business/pages/${id}`}
              removeFunc={() => {
                setDialogOpen(true);
                setCurrentPageId(id);
              }}
              viewLiveUrl={`/page/${id}`}
            />
          )
        )
      ) : (
        <MyBusinessNoData noDataText={t("No pages found!")} />
      )}
      <Pagination
        forcePage={page - 1}
        dataCount={staticPagesCount}
        itemsPerPage={9}
        selectedPage={page => setPage(page)}
      />
    </div>
  );
};

export default MyBusinessPages;
