import { VacanciesActionTypes } from "./vacancies.types";

const INITIAL_STATE = {
  typeOfOrganization: "",

  departmentCurrentCompany: {},
  departmentCurrentDepartment: {},

  areVacanciesPending: false,
  vacancies: [],
  vacanciesError: null,

  isEmployerTypePending: false,
  employerTypeData: [],
  employerTypeDataError: [],

  createdVacancy: {},
  updatedVacancy: {},
  deletedVacancy: {},

  areEmployerTypesPending: false,
  employerTypes: [],
  employerTypesError: null,

  areVacanciesByDepartmentPending: false,
  vacanciesByDepartment: [],
  vacanciesByDepartmentError: [],

  areVacanciesByMePending: false,
  vacanciesByMe: [],
  vacanciesByMeError: {},

  isVacancyByIdPending: false,
  vacancyById: {},
  vacancyByIdError: {},

  areMultipleOrganizationVacanciesPending: false,
  multipleOrganizationVacancies: [],
  multipleOrganizationVacanciesError: {},

  areMultipleCompanyVacanciesPending: false,
  multipleCompanyVacancies: [],
  multipleCompanyVacanciesError: {},

  createDepartment: {},
  createDepartmentError: {},

  editDepartment: {},
  editDepartmentError: {},

  isDepartmentsByOrganizationPending: false,
  departmentsByOrganization: [],
  departmentsByOrganizationError: [],

  isCreatingDepartmentVacancy: false,
  createDepartmentVacancy: {},
  createDepartmentVacancyError: {},

  isPuttingDepartmentVacancy: false,
  putDepartmentVacancy: {},
  putDepartmentVacancyError: {},

  deleteDepartmentVacancy: {},
  deleteDepartmentVacancyError: {},

  isVacanciesByDepartmentIdPending: false,
  vacanciesByDepartmentId: [],
  vacanciesByDepartmentIdError: {},

  isDepartmentByIdPending: false,
  departmentById: {},
  departmentByIdError: {},

  deleteDepartment: {},
  deleteDepartmentError: {},

  isStaffVacancyAdding: false,
  vacancyStaff: {},
  vacancyStaffError: {},

  isStaffGroupPending: false,
  staffGroup: [],
  staffGroupError: {},

  isPermissionsGroupPending: false,
  permissionsGroup: [],
  permissionsGroupError: {},

  isPendingUserByEmail: false,
  userByEmail: {},
  userByEmailError: {},

  isDepartmentVacancyByIdPending: false,
  departmentVacancyById: {},
  departmentVacancyByIdError: {},

  clearPositionVacancyPending: false,
  clearPosition: {},
  clearPositionError: {},

  jobRequestStatus: "",
  departmentRequestStatus: ""
};

const VacanciesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case VacanciesActionTypes.GET.GET_VACANCIES_PENDING:
      return {
        ...state,
        areVacanciesPending: true
      };
    case VacanciesActionTypes.GET.GET_VACANCIES_SUCCESS:
      return {
        ...state,
        areVacanciesPending: false,
        vacanciesCount: action.payload.vacanciesCount,
        vacancies: action.payload.vacancies,
        vacanciesError: null
      };
    case VacanciesActionTypes.GET.GET_VACANCIES_ERROR:
      return {
        ...state,
        areVacanciesPending: false,
        vacanciesError: action.payload
      };

    case VacanciesActionTypes.CREATE_VACANCY:
      return {
        ...state,
        jobRequestStatus: action.payload.requestStatus,
        createdVacancy: action.payload
      };

    case VacanciesActionTypes.UPDATE_VACANCY:
      return {
        ...state,
        jobRequestStatus: action.payload.requestStatus,
        updatedVacancy: action.payload
      };

    case VacanciesActionTypes.DELETE_VACANCY:
      return {
        ...state,
        deletedVacancy: action.payload
      };

    case VacanciesActionTypes.GET.GET_EMPLOYER_TYPE_PENDING:
      return {
        ...state,
        isEmployerTypePending: true
      };

    case VacanciesActionTypes.GET.GET_EMPLOYER_TYPE_SUCCESS:
      return {
        ...state,
        employerTypeData: action.payload,
        isEmployerTypePending: false
      };

    case VacanciesActionTypes.GET.GET_EMPLOYER_TYPE_ERROR:
      return {
        ...state,
        employerTypeDataError: action.payload,
        isEmployerTypePending: false
      };

    case VacanciesActionTypes.GET.GET_EMPLOYER_TYPES_PENDING:
      return {
        ...state,
        areEmployerTypesPending: true
      };
    case VacanciesActionTypes.GET.GET_EMPLOYER_TYPES_SUCCESS:
      return {
        ...state,
        employerTypes: action.payload,
        areEmployerTypesPending: false
      };
    case VacanciesActionTypes.GET.GET_EMPLOYER_TYPES_ERROR:
      return {
        ...state,
        employerTypesError: action.payload,
        areEmployerTypesPending: false
      };

    case VacanciesActionTypes.GET
      .GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_PENDING:
      return {
        ...state,
        areVacanciesByDepartmentPending: true
      };
    case VacanciesActionTypes.GET
      .GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        vacanciesByDepartment: action.payload,
        areVacanciesByDepartmentPending: false
      };
    case VacanciesActionTypes.GET
      .GET_VACANCIES_BY_DEPARTMENT_ORGANIZATION_ERROR:
      return {
        ...state,
        vacanciesByDepartmentError: action.payload,
        areVacanciesByDepartmentPending: false
      };

    case VacanciesActionTypes.GET.GET_VACANCIES_BY_ME_PENDING:
      return {
        ...state,
        areVacanciesByMePending: true
      };
    case VacanciesActionTypes.GET.GET_VACANCIES_BY_ME_SUCCESS:
      return {
        ...state,
        areVacanciesByMePending: false,
        vacanciesByMe: action.payload
      };
    case VacanciesActionTypes.GET.GET_VACANCIES_BY_ME_ERROR:
      return {
        ...state,
        areVacanciesByMePending: false,
        vacanciesByMeError: action.payload
      };

    case VacanciesActionTypes.GET.GET_VACANCY_BY_ID_PENDING:
      return {
        ...state,
        isVacancyByIdPending: true
      };
    case VacanciesActionTypes.GET.GET_VACANCY_BY_ID_SUCCESS:
      return {
        ...state,
        vacancyById: action.payload,
        isVacancyByIdPending: false
      };

    case VacanciesActionTypes.GET
      .GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_PENDING:
      return {
        ...state,
        areMultipleOrganizationVacanciesPending: true
      };

    case VacanciesActionTypes.GET
      .GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        multipleOrganizationVacancies: action.payload,
        areMultipleOrganizationVacanciesPending: false
      };

    case VacanciesActionTypes.GET
      .GET_MULTIPLE_VACANCIES_DEPARTMENT_ORGANIZATION_ERROR:
      return {
        ...state,
        multipleOrganizationVacanciesError: action.payload,
        areMultipleOrganizationVacanciesPending: false
      };

    case VacanciesActionTypes.GET
      .GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_PENDING:
      return {
        ...state,
        areMultipleCompanyVacanciesPending: true
      };

    case VacanciesActionTypes.GET
      .GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_SUCCESS:
      return {
        ...state,
        multipleCompanyVacancies: action.payload,
        areMultipleCompanyVacanciesPending: false
      };

    case VacanciesActionTypes.GET
      .GET_MULTIPLE_VACANCIES_DEPARTMENT_COMPANY_ERROR:
      return {
        ...state,
        multipleCompanyVacanciesError: action.payload,
        areMultipleCompanyVacanciesPending: false
      };

    case VacanciesActionTypes.POST.CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        createDepartment: action.payload,
        departmentRequestStatus: action.payload.requestStatus
      };

    case VacanciesActionTypes.POST.CREATE_DEPARTMENT_ERROR:
      return {
        ...state,
        createDepartmentError: action.payload
      };

    case VacanciesActionTypes.PUT.EDIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        editDepartment: action.payload,
        departmentRequestStatus: action.payload.requestStatus
      };

    case VacanciesActionTypes.PUT.EDIT_DEPARTMENT_ERROR:
      return {
        ...state,
        editDepartmentError: action.payload
      };

    case VacanciesActionTypes.SET_CURRENT_COMPANY:
      return {
        ...state,
        departmentCurrentCompany: action.payload
      };

    case VacanciesActionTypes.SET_CURRENT_DEPARTMENT:
      return {
        ...state,
        departmentCurrentDepartment: action.payload
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENTS_BY_ORGANIZATION_PENDING:
      return {
        ...state,
        isDepartmentsByOrganizationPending: true
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENTS_BY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        departmentsByOrganization: action.payload,
        isDepartmentsByOrganizationPending: false
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENTS_BY_ORGANIZATION_ERROR:
      return {
        ...state,
        departmentsByOrganization: action.payload,
        isDepartmentsByOrganizationPending: false
      };

    case VacanciesActionTypes.POST.CREATE_DEPARTMENT_VACANCY_PENDING:
      return {
        ...state,
        isCreatingDepartmentVacancy: true
      };

    case VacanciesActionTypes.POST.CREATE_DEPARTMENT_VACANCY_SUCCESS:
      return {
        ...state,
        createDepartmentVacancy: action.payload,
        departmentRequestStatus: action.payload.requestStatus,
        isCreatingDepartmentVacancy: false
      };
    case VacanciesActionTypes.POST.CREATE_DEPARTMENT_VACANCY_ERROR:
      return {
        ...state,
        createDepartmentVacancyError: {},
        isCreatingDepartmentVacancy: false
      };

    case VacanciesActionTypes.PUT.PUT_DEPARTMENT_VACANCY_PENDING:
      return {
        ...state,
        isPuttingDepartmentVacancy: true
      };

    case VacanciesActionTypes.PUT.PUT_DEPARTMENT_VACANCY_SUCCESS:
      return {
        ...state,
        putDepartmentVacancy: action.payload,
        departmentRequestStatus: action.payload.requestStatus,
        isPuttingDepartmentVacancy: false
      };
    case VacanciesActionTypes.PUT.PUT_DEPARTMENT_VACANCY_ERROR:
      return {
        ...state,
        putDepartmentVacancyError: {},
        isPuttingDepartmentVacancy: false
      };

    case VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_VACANCY_SUCCESS:
      return {
        ...state,
        deleteDepartmentVacancy: action.payload,
        multipleCompanyVacancies: state.multipleCompanyVacancies.map(vacancy =>
          vacancy.filter(el => el.id !== action.payload.id)
        )
      };
    case VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_VACANCY_ERROR:
      return {
        ...state,
        deleteDepartmentVacancyError: action.payload
      };

    case VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ID_PENDING:
      return {
        ...state,
        isVacanciesByDepartmentIdPending: true
      };

    case VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ID_SUCCESS:
      return {
        ...state,
        vacanciesByDepartmentId: action.payload,
        isVacanciesByDepartmentIdPending: false
      };
    case VacanciesActionTypes.GET.GET_VACANCIES_BY_DEPARTMENT_ID_ERROR:
      return {
        ...state,
        vacanciesByDepartmentIdError: action.payload,
        isVacanciesByDepartmentIdPending: false
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENT_BY_ID_PENDING:
      return {
        ...state,
        isDepartmentByIdPending: true
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENT_BY_ID_SUCCESS:
      return {
        ...state,
        departmentById: action.payload,
        isDepartmentByIdPending: false
      };
    case VacanciesActionTypes.GET.GET_DEPARTMENT_BY_ID_ERROR:
      return {
        ...state,
        departmentByIdError: action.payload
      };

    case VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        deleteDepartment: action.payload,
        departmentsByOrganization: state.departmentsByOrganization.filter(
          depart => depart.id !== action.payload.id
        )
      };
    case VacanciesActionTypes.DELETE.DELETE_DEPARTMENT_ERROR:
      return {
        ...state,
        deleteDepartmentError: action.payload
      };

    case VacanciesActionTypes.POST.ADD_DEPARTMENT_STAFF_PENDING:
      return {
        ...state,
        isStaffVacancyAdding: true
      };

    case VacanciesActionTypes.POST.ADD_DEPARTMENT_STAFF_SUCCESS:
      return {
        ...state,
        vacancyStaff: action.payload,
        departmentRequestStatus: action.payload.requestStatus,
        isStaffVacancyAdding: false
      };

    case VacanciesActionTypes.POST.ADD_DEPARTMENT_STAFF_ERROR:
      return {
        ...state,
        vacancyStaffError: action.payload,
        isStaffVacancyAdding: false
      };

    case VacanciesActionTypes.GET.GET_STAFF_GROUP_PENDING:
      return {
        ...state,
        isStaffGroupPending: true
      };

    case VacanciesActionTypes.GET.GET_STAFF_GROUP_SUCCESS:
      return {
        ...state,
        staffGroup: action.payload,
        isStaffGroupPending: false
      };
    case VacanciesActionTypes.GET.GET_STAFF_GROUP_ERROR:
      return {
        ...state,
        staffGroupError: action.payload,
        isStaffGroupPending: false
      };

    case VacanciesActionTypes.GET.GET_PERMISSIONS_GROUP_PENDING:
      return {
        ...state,
        isPermissionsGroupPending: true
      };

    case VacanciesActionTypes.GET.GET_PERMISSIONS_GROUP_SUCCESS:
      return {
        ...state,
        permissionsGroup: action.payload,
        isPermissionsGroupPending: false
      };
    case VacanciesActionTypes.GET.GET_PERMISSIONS_GROUP_ERROR:
      return {
        ...state,
        permissionsGroupError: action.payload,
        isPermissionsGroupPending: false
      };

    case VacanciesActionTypes.GET.GET_USER_BY_EMAIL_PENDING:
      return {
        ...state,
        isPendingUserByEmail: true
      };

    case VacanciesActionTypes.GET.GET_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        userByEmail: action.payload,
        isPendingUserByEmail: false
      };
    case VacanciesActionTypes.GET.GET_USER_BY_EMAIL_ERROR:
      return {
        ...state,
        userByEmailError: action.payload,
        isPendingUserByEmail: false
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENT_VACANCY_BY_ID_PENDING:
      return {
        ...state,
        isDepartmentVacancyByIdPending: true
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENT_VACANCY_BY_ID_SUCCESS:
      return {
        ...state,
        departmentVacancyById: action.payload,
        isDepartmentVacancyByIdPending: false
      };

    case VacanciesActionTypes.GET.GET_DEPARTMENT_VACANCY_BY_ID_ERROR:
      return {
        ...state,
        departmentVacancyByIdError: action.payload,
        isDepartmentVacancyByIdPending: false
      };

    case VacanciesActionTypes.SET_TYPE_OF_ORGANIATION:
      return {
        ...state,
        typeOfOrganization: action.payload
      };

    case VacanciesActionTypes.PATCH.CLEAR_POSITION_VACANCY_ID_PENDING:
      return {
        ...state,
        clearPositionVacancyPending: true
      };

    case VacanciesActionTypes.PATCH.CLEAR_POSITION_VACANCY_ID_SUCCESS:
      return {
        ...state,
        clearPositionVacancyPending: false,
        clearPosition: action.payload
      };

    case VacanciesActionTypes.PATCH.CLEAR_POSITION_VACANCY_ID_ERROR:
      return {
        ...state,
        clearPositionVacancyPending: false,
        clearPositionError: action.payload
      };

    case VacanciesActionTypes.CLEAR_JOB_REQUEST:
      return {
        ...state,
        jobRequestStatus: action.payload
      };

    case VacanciesActionTypes.CLEAR_DEPARTMENT_CREATE:
      return {
        ...state,
        departmentRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default VacanciesReducer;
