import React, { useState, useEffect, Fragment } from "react";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { missingImage, missingVideo, QuestionIcon } from "../../../assets";
import {
  b2bProductType,
  b2cProductType,
  videoIsUploaded
} from "../../../constants";

import {
  ColumnsSmLgWrapper,
  ColumnWrapperLg,
  ColumnWrapperSm,
  ParentWrapper,
  SectionTextHeader,
  ButtonsFooterWrapper
} from "../../../layout";

import {
  DefaultCheckbox,
  ProductItemSizes,
  AdditionalPagesUpload,
  AdditionalTable,
  MediaActions,
  DefaultButton,
  Separator,
  InputGroup,
  EditorGroup,
  DefaultDropdown,
  DefaultDropdownMultiSelect,
  DefaultSelectInput,
  DefaultMultiRangeSelect,
  DefaultDateRangePicker,
  UploadFileDocument,
  DocumentsTable,
  ProductAdditionalParameter,
  DefaultInputRadio,
  ProductVariable,
  GallerySection,
  VideoSection,
  CircleLoader
} from "../../../components";

import {
  createProduct,
  updateProductById,
  clearB2BRequest,
  getCompaniesByUserId,
  createB2CProduct,
  updateB2CProduct,
  clearB2CRequest,
  searchBranch,
  getB2BCategories,
  getB2CCategories,
  getB2BProduct,
  getB2CProduct,
  getCurrencies,
  getMeasurements,
  searchProducers,
  searchOrganizations,
  uploadAdditionalParameter,
  deleteAdditionalParameter,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  uploadDocument,
  deleteDocumentById,
  uploadProductSize,
  deleteSizeById,
  getProductVariationsByParentId,
  deleteProductVariationById,
  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  deleteOneImageFromGallery,
  deleteOneVideoFromGallery,
  uploadVideoGallery,
  loadFileVideo,
  loadBlobVideo
} from "../../../redux/actions-exporter";

import { buttonOk, buttonCancel } from "../../../styles/buttons-styles";
import "./my-business-products-edit.styles.scss";
import { useTranslation } from "react-i18next";

const MyBusinessBranchPicker = ({
  branches,
  returnData,
  customArray,
  required,
  branchInput,
  setBranchInput
}) => {
  return (
    <div className="my-business-category-picker">
      <div style={{ display: "flex" }}>
        <div className="my-business-category-picker__title">Branch</div>
        {required && (
          <div className="my-business-category-picker__required">
            * required
          </div>
        )}
      </div>
      <br />
      <div className="my-business-category-picker__info">
        Add new branches or delete old ones
      </div>
      <br />
      <div className="my-business-category-picker__flex-container">
        <DefaultDropdownMultiSelect
          style={{ width: "65%" }}
          items={branches}
          customArray={customArray}
          returnData={returnData}
          filter={true}
          categoryInputValue={branchInput}
          setCategoryInputValue={e => setBranchInput(e)}
        />
      </div>
    </div>
  );
};

const MyBusinessCategoryPicker = ({
  categories,
  returnData,
  returnInput,
  returnCheckbox,
  customCheck,
  customCheckDigital,
  customArray,
  categoryInputValue,
  setCategoryInputValue,
  required
}) => {
  const [checkedRadio, setCheckedRadio] = useState(false);
  const [checkedCheckBox, setCheckedCheckbox] = useState(false);
  useEffect(() => {
    if (customCheck) {
      setCheckedRadio(customCheck);
    }
    if (customCheckDigital) {
      setCheckedCheckbox(customCheckDigital);
    }
  }, [customCheck, customCheckDigital]);

  return (
    <div className="my-business-category-picker">
      <div style={{ display: "flex" }}>
        <div className="my-business-category-picker__title">Category</div>
        {required && (
          <div className="my-business-category-picker__required">
            * required
          </div>
        )}
      </div>
      <br />
      <div className="my-business-category-picker__info">
        Add new categories or delete old ones
      </div>
      <br />
      <ParentWrapper>
        <div className="my-business-category-picker__flex-container">
          <DefaultDropdownMultiSelect
            customArray={customArray}
            filter={true}
            style={{ width: "65%" }}
            items={categories}
            returnData={returnData}
            categoryInputValue={categoryInputValue}
            setCategoryInputValue={setCategoryInputValue}
          />
        </div>
      </ParentWrapper>
      <ParentWrapper>
        <div className="my-business-category-picker__input">
          <DefaultInputRadio
            onClick={() => {
              setCheckedRadio(!checkedRadio);
              returnInput(!checkedRadio);
            }}
            name="main-page"
            value="main-page"
            id="main-page"
            checked={checkedRadio}
            labelText="Show on main page"
            readOnly={true}
          />
        </div>
      </ParentWrapper>
      <ParentWrapper>
        <div className="my-business-category-picker__input">
          <DefaultCheckbox
            title="Digital product"
            checkboxValue={checkedCheckBox}
            setCheckboxChange={() => {
              setCheckedCheckbox(!checkedCheckBox);
              returnCheckbox(!checkedCheckBox);
            }}
          />
        </div>
      </ParentWrapper>
    </div>
  );
};

const MyBusinessProductsEdit = ({
  appDirection,
  getCompaniesByUserId,
  areUserCompaniesPending,
  userCompanies,

  createProduct,
  updateProductById,

  createB2CProduct,
  updateB2CProduct,

  searchBranch,
  areBranchesPending,
  branches,

  getB2BCategories,
  areB2BCategoriesPending,
  b2bCategoriesCount,
  b2bCategoriesNext,
  b2bCategoriesPrevious,
  b2bCategoriesResults,
  b2bCategoriesError,

  getB2CCategories,
  areB2CCategoriesPending,
  b2cCategoriesCount,
  b2cCategoriesNext,
  b2cCategoriesPrevious,
  b2cCategoriesResults,
  b2cCategoriesError,

  selectedProductType,

  getB2BProduct,
  getB2CProduct,

  isActiveProductPending,
  activeProduct,

  getMeasurements,
  getCurrencies,
  areCurrenciesPending,
  currencies,
  areMeasurementsPending,
  measurements,

  searchProducers,
  isProducersSearchPending,
  producersSearchData,
  producersSearchResults,

  searchOrganizations,
  organizationsSearchData,
  isSearchOrganizationsPending,

  createdB2BProduct,

  uploadAdditionalParameter,
  deleteAdditionalParameter,

  uploadDocument,
  deleteAdditionalPageById,
  uploadAdditionalPage,
  deleteDocumentById,
  uploadProductSize,
  deleteSizeById,

  b2bRequestStatus,
  b2cRequestStatus,
  clearB2BRequest,
  clearB2CRequest,

  getProductVariationsByParentId,
  areProductVariationsByParentPending,
  productVariationsByParent,
  deleteProductVariationById,

  loadBlobGallery,
  loadFileGallery,
  uploadImageGallery,
  galleryBlob,
  galleryFile,
  videoBlob,
  videoFile,
  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,
  deleteOneImageFromGallery,
  deleteOneVideoFromGallery,

  isImageGalleryUploadPending,
  isVideoGalleryUploadPending
}) => {
  const { productId, urlProductType } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { t } = useTranslation();

  const [productType, setProductType] = useState("b2b");
  const [name, setName] = useState("");
  const [sku, setSKU] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [desc, setDesc] = useState("");
  const [compId, setCompId] = useState(null);
  const [branchInput, setBranchInput] = useState("");
  const [branch, setBranch] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [category, setCategory] = useState([]);

  const [producer, setProducer] = useState("");
  const [organization, setOrganization] = useState(null);
  const [companyRequired, setCompanyRequired] = useState(true);
  const [organizationRequired, setOrganizationRequired] = useState(true);
  const [isActive, setIsActive] = useState();
  const [showOnMain, setShowOnMain] = useState(false);
  const [isDigitalProduct, setIsDigitalProduct] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [currency, setCurrency] = useState();
  const [cost, setCost] = useState();
  const [measurement, setMeasurement] = useState();
  const [discountPercent, setDiscountPercent] = useState();
  const [couponDiscountPercent, setCouponDiscountPercent] = useState();
  const [couponStartDate, setCouponStartDate] = useState("");
  const [couponEndDate, setCouponEndDate] = useState("");
  const [file, setFile] = useState("");
  const [blob, setBlob] = useState();
  const [additionalPages, setAdditionalPages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [additionalParameters, setAdditionalParameters] = useState([]);
  const [productGalleries, setProductGalleries] = useState([]);
  const [productIsRecommended, setProductIsRecommended] = useState(false);
  const [stockSize, setStockSize] = useState(0);

  const [docName, setDocName] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [documentBlob, setDocumentBlob] = useState("");

  const [productSize, setProductSize] = useState("");
  const [productValue, setProductValue] = useState("");
  const [itemColor, setItemColor] = useState("");

  const [additionalPageTitle, setAdditionalPageTitle] = useState("");
  const [additionalPageEditor, setAdditionalPageEditor] = useState("");

  const [dataChange, setDataChange] = useState(true); // eslint-disable-line
  const [disableButton, setDisableButton] = useState(false);

  const [parameterTitle, setParameterTitle] = useState("");
  const [parameterDescription, setParameterDescription] = useState("");
  const [parameterCost, setParameterCost] = useState("");
  const [leadFormId, setLeadFormId] = useState("");

  useEffect(() => {
    getCompaniesByUserId();
    getCurrencies();
    getMeasurements();
    searchProducers("");
    searchOrganizations("");
  }, [
    getCompaniesByUserId,
    getCurrencies,
    getMeasurements,
    searchProducers,
    searchOrganizations
  ]);

  useEffect(() => {
    if (productId !== "create" && urlProductType === "b2b") {
      getB2BProduct(productId);
    } else if (productId !== "create" && urlProductType === "b2c") {
      getB2CProduct(productId);
    }
  }, [productId, urlProductType, getB2BProduct, getB2CProduct]);

  useEffect(() => {
    if (selectedProductType === "b2b" && productId !== "create") {
      getB2BProduct(productId);
      setProductType(selectedProductType);
    } else if (selectedProductType === "b2c" && productId !== "create") {
      getB2CProduct(productId);
      setProductType(selectedProductType);
    }

    searchBranch(branchInput);

    if (typeof couponDiscountPercent === "string") {
      setCouponDiscountPercent(couponDiscountPercent.replace(/\D/g, ""));
    }
    if (typeof discountPercent === "string") {
      setDiscountPercent(discountPercent.replace(/\D/g, ""));
    }
  }, [
    branchInput,
    categoryInputValue,
    getCompaniesByUserId,
    searchBranch,
    productType,
    getB2BCategories,
    getB2CCategories,
    getB2BProduct,
    getB2CProduct,
    productId,
    selectedProductType,
    getCurrencies,
    getMeasurements,
    searchProducers,
    searchOrganizations,
    couponDiscountPercent,
    discountPercent
  ]);

  useEffect(() => {
    if (productType === "b2b") {
      getB2BCategories(categoryInputValue);
    } else if (productType === "b2c") {
      getB2CCategories(categoryInputValue);
    }
  }, [productType, categoryInputValue, getB2BCategories, getB2CCategories]);

  useEffect(() => {
    if (urlProductType) {
      getProductVariationsByParentId(urlProductType, productId);
    }
  }, [getProductVariationsByParentId, productId, urlProductType]);

  useEffect(() => {
    if (productId !== "create") {
      const {
        name,
        metadata,
        company,
        short_description,
        description,
        stock,
        keywords,
        cost,
        producer,
        organization,
        is_active,
        show_on_main,
        is_digital_product,
        currency,
        image,
        documents,
        discount_percent,
        coupon_discount_percent,
        additional_pages,
        additional_parameters,
        galleries,
        is_recommended,
        item_sizes,
        sku
      } = activeProduct;
      setName(name);
      setSKU(sku);
      setCouponStartDate(metadata && metadata.coupon_start_date);
      setLeadFormId(metadata && metadata.lead_form_id);
      setCouponEndDate(metadata && metadata.coupon_end_date);
      setCompId(company && company.id);
      setShortDesc(short_description);
      setDesc(description);
      setKeywords(keywords);
      setCost(Number(cost));
      setProducer(producer && producer.id);
      setOrganization(organization && organization.id);
      setStockSize(stock);
      setIsActive(is_active);
      setShowOnMain(show_on_main);
      setIsDigitalProduct(is_digital_product);
      setDiscountPercent(discount_percent);
      setCouponDiscountPercent(coupon_discount_percent);
      setCurrency(currency);
      setFile(image);
      setDocuments(documents);
      setAdditionalPages(additional_pages);
      setAdditionalParameters(additional_parameters);
      setProductIsRecommended(is_recommended);
      setProductGalleries(galleries);
      setProductSizes(item_sizes);
    }
  }, [productId, activeProduct]);

  useEffect(() => {
    if (productId !== "create" && productType === "b2b" && activeProduct) {
      const { measurement_unit } = activeProduct;
      setMeasurement(measurement_unit);
    }
  }, [productId, productType]); // eslint-disable-line

  useEffect(() => {
    if (urlProductType) {
      setProductType(urlProductType);
    }
  }, [urlProductType]); // eslint-disable-line

  useEffect(() => {
    setDisableButton(
      productType === "b2b"
        ? name === "" ||
            sku === "" ||
            shortDesc === "" ||
            desc === "" ||
            ((organization === "" || organization == null) &&
              (compId === "" || compId == null)) ||
            // producer === "" ||
            // branch.length < 1 ||
            category.length < 1
        : (productType === "b2c" && name === "") ||
            sku === "" ||
            shortDesc === "" ||
            ((organization === "" || organization == null) &&
              (compId === "" || compId == null)) ||
            desc === "" ||
            // producer === "" ||
            compId === "" ||
            category.length < 1
    );
  }, [
    // eslint-disable-line
    productType,
    name,
    sku,
    producer,
    organization,
    shortDesc,
    desc,
    compId,
    branch,
    category
  ]);

  useEffect(() => {
    if (
      b2bRequestStatus === 201 ||
      b2bRequestStatus === 200 ||
      b2cRequestStatus === 201 ||
      b2cRequestStatus === 200
    ) {
      history.push("/my-business/products");
    }
  }, [history, b2bRequestStatus, b2cRequestStatus]);

  useEffect(
    () => () => {
      clearB2BRequest();
      clearB2CRequest();
      loadBlobGallery("");
      loadFileGallery("");
      loadBlobVideo("");
      loadFileVideo("");
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    setOrganizationRequired(!(compId || organization));
    setCompanyRequired(!(compId || organization));
  }, [organization, compId, setOrganizationRequired, setCompanyRequired]);

  const MyBusinessProductTypeSelector = ({ title }) => {
    return (
      <div className="product-type-selector">
        <div className="product-type-selector__title">{title}</div>
        <br />
        <div className="product-type-selector__info">
          Choose one variant or both
        </div>
        <br />
        <div className="product-type-selector__grid">
          <div
            onClick={() => setProductType("b2b")}
            className={`product-type-selector__btn ${
              productType === "b2b"
                ? "product-type-selector__btn--selected"
                : undefined
            }`}
          >
            B2B
          </div>
          <div
            onClick={() => setProductType("b2c")}
            className={`product-type-selector__btn ${
              productType === "b2c"
                ? "product-type-selector__btn--selected"
                : undefined
            }`}
          >
            B2C
          </div>
        </div>
      </div>
    );
  };

  const editProduct = (
    name,
    sku,
    shortDesc,
    desc,
    compId,
    branch,
    category,
    parseInt,
    isActive,
    showOnMain,
    isDigitalProduct,
    organization,
    keywords,
    cost,
    producer,
    currency,
    measurement,
    discountPercent,
    couponDiscountPercent,
    couponStartDate,
    couponEndDate,
    image,
    leadFormId
  ) => {
    if (productType === "b2b" && productId === "create") {
      createProduct(
        name,
        sku,
        shortDesc,
        desc,
        compId,
        branch,
        category,
        parseInt,
        isActive,
        showOnMain,
        isDigitalProduct,
        organization,
        keywords,
        cost,
        producer,
        currency,
        measurement,
        image,
        leadFormId
      );
    } else if (productType === "b2b" && productId !== "create") {
      updateProductById(
        productId,
        name,
        sku,
        shortDesc,
        desc,
        compId,
        branch,
        category,
        parseInt,
        isActive,
        showOnMain,
        isDigitalProduct,
        organization,
        keywords,
        cost,
        producer,
        currency,
        measurement,
        image,
        leadFormId
      );
    } else if (productType === "b2c" && productId === "create") {
      createB2CProduct(
        name,
        sku,
        producer,
        compId,
        isActive,
        category,
        parseInt,
        showOnMain,
        isDigitalProduct,
        organization,
        shortDesc,
        desc,
        keywords,
        currency,
        cost,
        discountPercent,
        couponDiscountPercent,
        couponStartDate,
        couponEndDate,
        image,
        productIsRecommended,
        leadFormId
      );
    } else if (productType === "b2c" && productId !== "create") {
      updateB2CProduct(
        productId,
        name,
        sku,
        producer,
        compId,
        isActive,
        category,
        parseInt,
        showOnMain,
        isDigitalProduct,
        organization,
        shortDesc,
        desc,
        keywords,
        currency,
        cost,
        discountPercent,
        couponDiscountPercent,
        couponStartDate,
        couponEndDate,
        image,
        productIsRecommended,
        leadFormId
      );
    }
  };

  return (
    <div className="my-business-products-edit">
      <ColumnsSmLgWrapper>
        <ColumnWrapperSm>
          <MediaActions
            image={file ? file : missingImage}
            alt={"Big-Ben"}
            height={200}
            objectFit="scale-down"
            buttons={[
              <DefaultButton width="100%" {...buttonOk}>
                <label style={{ cursor: "pointer" }}>
                  Choose a picture
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    onChange={e => {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                      setDataChange(false);
                    }}
                    style={{ display: "none", cursor: "pointer" }}
                  />
                </label>
              </DefaultButton>,
              <DefaultButton
                onClick={() => {
                  setFile("");
                  setBlob("");
                }}
                width="100%"
                {...buttonCancel}
              >
                Delete picture
              </DefaultButton>
            ]}
          />
          {productId !== "create" && (
            <>
              <Separator />
              <MediaActions
                image={galleryFile ? galleryFile : missingImage}
                alt={"missing image"}
                height={200}
                buttons={
                  isImageGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a picture for gallery
                            <input
                              accept="image/gif, image/jpeg, image/png"
                              type="file"
                              onChange={e => {
                                loadFileGallery(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobGallery(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadImageGallery(
                              urlProductType === "b2b"
                                ? b2bProductType
                                : b2cProductType,
                              productId,
                              galleryBlob
                            )
                          }
                          disabled={galleryBlob === ""}
                        >
                          Upload new gallery
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobGallery("");
                            loadFileGallery("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
              <Separator />
              <MediaActions
                image={videoFile ? videoIsUploaded : missingVideo}
                alt={"missing video"}
                height={200}
                buttons={
                  isVideoGalleryUploadPending
                    ? [<CircleLoader />]
                    : [
                        <DefaultButton width="100%" {...buttonOk}>
                          <label style={{ cursor: "pointer" }}>
                            Choose a video for gallery
                            <input
                              accept="video/*"
                              type="file"
                              onChange={e => {
                                loadFileVideo(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                loadBlobVideo(e.target.files[0]);
                              }}
                              style={{ display: "none", cursor: "pointer" }}
                            />
                          </label>
                        </DefaultButton>,
                        <DefaultButton
                          width="100%"
                          {...buttonOk}
                          onClick={() =>
                            uploadVideoGallery(
                              urlProductType === "b2b"
                                ? b2bProductType
                                : b2cProductType,
                              productId,
                              videoBlob
                            )
                          }
                          disabled={videoBlob === ""}
                        >
                          Upload new video
                        </DefaultButton>,
                        <DefaultButton
                          onClick={() => {
                            loadBlobVideo("");
                            loadFileVideo("");
                          }}
                          width="100%"
                          {...buttonCancel}
                        >
                          Delete gallery
                        </DefaultButton>
                      ]
                }
              />
            </>
          )}
        </ColumnWrapperSm>
        <ColumnWrapperLg>
          <ParentWrapper>
            <SectionTextHeader text="Basic Info" />
          </ParentWrapper>
          <ParentWrapper>
            <InputGroup
              onChange={e => {
                setName(e.target.value);
                setDataChange(false);
              }}
              value={name}
              label="NAME"
              name="name"
              placeholder="Name"
              required={true}
            />
            <InputGroup
              onChange={e => {
                setSKU(e.target.value);
                setDataChange(false);
              }}
              value={sku}
              label="SKU"
              name="sku"
              placeholder="SKU"
              required={true}
            />
          </ParentWrapper>
          {productId === "create" && (
            <div
              className="my-business-products-edit__top-product-info"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ marginTop: 18, width: "48%" }}>
                <MyBusinessProductTypeSelector title="Product type" />
              </div>
            </div>
          )}
          <Separator />
          <ParentWrapper>
            <DefaultDropdown
              label="Product Producer"
              items={producersSearchResults}
              returnData={setProducer}
              filter={true}
              customTitle={
                productId !== "create" &&
                activeProduct.producer &&
                activeProduct.producer.name
              }
              inputFunc={e => searchProducers(e.target.value)}
              required={false}
            />
          </ParentWrapper>
          <Separator />
          <ParentWrapper>
            <DefaultDropdown
              label="Company"
              items={userCompanies}
              returnData={setCompId}
              filter={true}
              customTitle={
                productId !== "create" &&
                activeProduct.company &&
                activeProduct.company.name
              }
              inputFunc={e => getCompaniesByUserId(e.target.value)}
              required={companyRequired}
            />
            <DefaultDropdown
              label="Organization"
              items={organizationsSearchData}
              returnData={setOrganization}
              filter={true}
              customTitle={
                productId !== "create" &&
                activeProduct.organization &&
                activeProduct.organization.name
              }
              inputFunc={e => searchOrganizations(e.target.value)}
              required={organizationRequired}
            />
          </ParentWrapper>
          <div className="products-edit__hint">
            Choose at least Company or Organization
          </div>
          <Separator />
          <ParentWrapper>
            {productType === "b2c" && (
              <div
                className="business-recommendations"
                style={{ marginTop: 18, width: "48%" }}
              >
                <div className="business-recommendations__title">
                  Recommendations
                </div>
                <div className="business-recommendations__small">
                  This product will be offered to buyers
                </div>
                <div className="business-recommendations__checkbox">
                  <DefaultCheckbox
                    checkboxValue={productIsRecommended}
                    setCheckboxChange={setProductIsRecommended}
                  />
                </div>
              </div>
            )}
          </ParentWrapper>
          <ParentWrapper>
            <DefaultDropdown
              label="Status"
              items={[
                { name: "Publish", id: true },
                { name: "Draft", id: false }
              ]}
              customTitle={
                productId !== "create" && activeProduct.is_active
                  ? activeProduct.is_active === true && "Publish"
                  : activeProduct.is_active === false && "Draft"
              }
              returnData={setIsActive}
            />
          </ParentWrapper>
          <Separator />
          <MyBusinessCategoryPicker
            customCheck={productId !== "create" && activeProduct.show_on_main}
            customCheckDigital={
              productId !== "create" && activeProduct.is_digital_product
            }
            returnInput={setShowOnMain}
            returnCheckbox={setIsDigitalProduct}
            returnData={setCategory}
            customArray={productId !== "create" && activeProduct.categories}
            categories={
              (productType === "b2b" && b2bCategoriesResults) ||
              (productType === "b2c" && b2cCategoriesResults)
            }
            categoryInputValue={categoryInputValue}
            setCategoryInputValue={setCategoryInputValue}
            required={true}
          />
          {productType === "b2b" && (
            <MyBusinessBranchPicker
              customArray={productId !== "create" && activeProduct.branches}
              branches={branches}
              returnData={setBranch}
              required={false}
              branchInput={branchInput}
              setBranchInput={setBranchInput}
            />
          )}
        </ColumnWrapperLg>
      </ColumnsSmLgWrapper>
      {productGalleries &&
        productGalleries[0] &&
        !!productGalleries[0].images.length && (
          <>
            <Separator />
            <GallerySection
              label="Gallery"
              gallery={productGalleries}
              deleteOneImageFromGallery={deleteOneImageFromGallery}
              parentId={productId}
              contentType={
                urlProductType === "b2b" ? b2bProductType : b2cProductType
              }
            />
          </>
        )}
      {productGalleries &&
        productGalleries[0] &&
        !!productGalleries[0].videos.length && (
          <>
            <Separator />
            <VideoSection
              label="Videos"
              videos={productGalleries}
              deleteOneVideoFromGallery={deleteOneVideoFromGallery}
              parentId={productId}
              contentType={
                urlProductType === "b2b" ? b2bProductType : b2cProductType
              }
            />
          </>
        )}
      <Separator />
      <ParentWrapper>
        <EditorGroup
          onChange={e => {
            setShortDesc(e.target.getContent());
            setDataChange(false);
          }}
          value={shortDesc}
          label="PREVIEW DESCRIPTION"
          name="previewDescription"
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup
          onChange={e => {
            setDesc(e.target.getContent());
            setDataChange(false);
          }}
          value={desc}
          label="DETAIL TEXT"
          name="detailText"
          required={true}
        />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => {
            setKeywords(e.target.value);
            setDataChange(false);
          }}
          value={keywords}
          label="KEYWORDS"
          name="keywords"
          placeholder="#hashtag, #hashtag, ..., #hashtag"
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <SectionTextHeader text="Stock size" />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => {
            setStockSize(e.target.value);
            setDataChange(false);
          }}
          value={stockSize}
          label="Stock size"
          name="stockSisze"
          placeholder="Stock size..."
          // required={true}
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <SectionTextHeader text="Price Info" />
      </ParentWrapper>
      <ParentWrapper>
        <div style={{ width: "250px" }}>
          <DefaultSelectInput
            returnData={setCurrency}
            customData={
              productId !== "create" && activeProduct && activeProduct.currency
            }
            value={cost}
            onChange={e => {
              setCost(e.target.value);
              setDataChange(false);
            }}
            title="Cost"
            options={currencies.map(currency => ({
              label: currency.unit,
              value: currency.label
            }))}
            width="80px"
          />
          {productType === "b2b" && (
            <>
              <br />
              <DefaultDropdown
                label="Measurement"
                items={measurements.map(measurement => ({
                  name: measurement.label,
                  id: measurement.unit
                }))}
                returnData={setMeasurement}
                customTitle={
                  productId !== "create" &&
                  activeProduct &&
                  activeProduct.measurement_unit === "pcs"
                    ? "Piece"
                    : activeProduct.measurement_unit === "kg" && "Kilogram"
                }
              />
            </>
          )}
        </div>
      </ParentWrapper>
      {productType === "b2c" && (
        <>
          <Separator />
          <ParentWrapper>
            <DefaultMultiRangeSelect
              title="Discount"
              info="Nullam elit augue, scelerisque eget justo quis, ultricies mattis ipsum."
              data={[
                { value: "5%" },
                { value: "10%" },
                { value: "15%" },
                { value: "25%" },
                { value: "50%" },
                { value: "60%" },
                { value: "70%" },
                { value: "80%" },
                { value: "90%" }
              ]}
              returnData={data => setDiscountPercent(data)}
              customValue={discountPercent}
              onClearHandle={setDiscountPercent}
            />
          </ParentWrapper>
          <Separator />
          <ParentWrapper>
            <DefaultMultiRangeSelect
              title="Coupon"
              info="Nullam elit augue, scelerisque eget justo quis, ultricies mattis ipsum."
              data={[
                { value: "5%" },
                { value: "10%" },
                { value: "15%" },
                { value: "25%" },
                { value: "50%" },
                { value: "60%" },
                { value: "70%" },
                { value: "80%" },
                { value: "90%" }
              ]}
              returnData={data => setCouponDiscountPercent(data)}
              customValue={couponDiscountPercent}
              onClearHandle={setCouponDiscountPercent}
            />
          </ParentWrapper>
          <ParentWrapper>
            <div>
              <div className="my-business-products-edit__info-text">
                Choose date of coupon
              </div>
              <br />
              <DefaultDateRangePicker
                returnStartDate={setCouponStartDate}
                returnEndDate={setCouponEndDate}
                info="Choose date of coupon"
                startDate={couponStartDate && couponStartDate}
                endDate={couponEndDate && couponEndDate}
              />
              <div
                className="product-date-remove"
                onClick={() => {
                  setCouponStartDate("");
                  setCouponEndDate("");
                }}
              >
                Remove date
              </div>
            </div>
            <div>
              <QuestionIcon />
              <div className="my-business-products-edit__info-text">
                You can choose an expiration date for the coupon
              </div>
            </div>
          </ParentWrapper>
        </>
      )}
      <Separator />
      <ParentWrapper>
        <SectionTextHeader text={t("Lead form bot")} />
      </ParentWrapper>
      <ParentWrapper>
        <InputGroup
          onChange={e => {
            setLeadFormId(e.target.value);
          }}
          value={leadFormId}
          label={t("Lead form id")}
          name="lead-form-id"
          placeholder={t("Lead form id")}
        />
      </ParentWrapper>
      <Separator />
      <ParentWrapper>
        <SectionTextHeader text="Other information" />
      </ParentWrapper>
      <ParentWrapper>
        <EditorGroup label="ADDITIONAL INFORMATION" name="detailText" />
      </ParentWrapper>
      {productId !== "create" && productType === "b2c" && (
        <ProductAdditionalParameter
          additionalParameters={additionalParameters}
          deleteAdditionalParameter={deleteAdditionalParameter}
          setParameterTitle={setParameterTitle}
          setDataChange={setDataChange}
          parameterTitle={parameterTitle}
          parameterDescription={parameterDescription}
          setParameterDescription={setParameterDescription}
          setParameterCost={setParameterCost}
          parameterCost={parameterCost}
          uploadAdditionalParameter={uploadAdditionalParameter}
          productId={productId}
        />
      )}
      <Separator />
      {productId !== "create" && productType === "b2c" && (
        <Fragment>
          <ProductItemSizes
            productId={productId}
            productType={
              productType === "b2b" ? b2bProductType : b2cProductType
            }
            productSize={productSize}
            setProductSize={setProductSize}
            productValue={productValue}
            setProductValue={setProductValue}
            productSizes={productSizes}
            deleteSizeById={deleteSizeById}
            uploadProductSize={uploadProductSize}
            itemColor={itemColor}
            setItemColor={setItemColor}
          />
          <Separator />
        </Fragment>
      )}

      {productId !== "create" && (
        <Fragment>
          <ProductVariable
            sectionPath={`${url}/variations/create`}
            areProductVariationsByParentPending={
              areProductVariationsByParentPending
            }
            productVariationsByParent={productVariationsByParent}
            deleteProductVariationById={deleteProductVariationById}
          />
          <Separator />
        </Fragment>
      )}

      {productId !== "create" && (
        <Fragment>
          <DocumentsTable
            tableName={"PRODUCT DOCUMENTS"}
            documents={documents}
            deleteDocumentById={deleteDocumentById}
          />
          <Separator />
          <UploadFileDocument
            productId={productId}
            itemContentType={
              productType === "b2b" ? b2bProductType : b2cProductType
            }
            docName={docName}
            setDocName={setDocName}
            docDescription={docDescription}
            setDocDescription={setDocDescription}
            documentFile={documentFile}
            setDocumentFile={setDocumentFile}
            documentBlob={documentBlob}
            setDocumentBlob={setDocumentBlob}
            additionalPageTitle={additionalPageTitle}
            additionalPageEditor={additionalPageEditor}
            uploadDocument={uploadDocument}
            itemId={productId}
          />
          <Separator />

          <AdditionalTable
            tableName={"PRODUCT ADDITIONAL PAGES"}
            compId={productId}
            urlType={`${productType}`}
            additionalPages={additionalPages}
            deleteAdditional={deleteAdditionalPageById}
          />
          <Separator />
          <AdditionalPagesUpload
            setAdditionalPageTitle={setAdditionalPageTitle}
            additionalPageTitle={additionalPageTitle}
            setAdditionalPageEditor={setAdditionalPageEditor}
            additionalPageEditor={additionalPageEditor}
            itemId={productId}
            uploadAdditionalPage={uploadAdditionalPage}
            itemContentType={
              productType === "b2b" ? b2bProductType : b2cProductType
            }
          />
          <Separator />
        </Fragment>
      )}
      <ButtonsFooterWrapper>
        <div>
          <DefaultButton
            onClick={() => {
              editProduct(
                name,
                sku,
                shortDesc,
                desc,
                compId,
                branch,
                category,
                parseInt(stockSize),
                isActive,
                showOnMain,
                isDigitalProduct,
                organization,
                keywords,
                cost,
                producer,
                currency,
                measurement,
                discountPercent,
                couponDiscountPercent,
                couponStartDate,
                couponEndDate,
                blob,
                leadFormId
              );
            }}
            width="100%"
            {...buttonOk}
            disabled={disableButton}
          >
            Save
          </DefaultButton>
        </div>
        <div>
          <DefaultButton
            {...buttonCancel}
            onClick={() => history.push("/my-business/products")}
            width="100%"
          >
            Cancel
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  areUserCompaniesPending: state.companiesReducer.areCompaniesByUserIdPending,
  userCompanies: state.companiesReducer.companiesByUserId,

  areBranchesPending: state.branchesReducer.areBranchesPending,
  branches: state.branchesReducer.branches.results,

  areB2BCategoriesPending: state.categoriesAPI.areB2BCategoriesPending,
  b2bCategoriesCount: state.categoriesAPI.b2bCategoriesCount,
  b2bCategoriesNext: state.categoriesAPI.b2bCategoriesNext,
  b2bCategoriesPrevious: state.categoriesAPI.b2bCategoriesPrevious,
  b2bCategoriesResults: state.categoriesAPI.b2bCategoriesResults,
  b2bCategoriesError: state.categoriesAPI.b2bCategoriesError,

  areB2CCategoriesPending: state.categoriesAPI.areB2CCategoriesPending,
  b2cCategoriesCount: state.categoriesAPI.b2cCategoriesCount,
  b2cCategoriesNext: state.categoriesAPI.b2cCategoriesNext,
  b2cCategoriesPrevious: state.categoriesAPI.b2cCategoriesPrevious,
  b2cCategoriesResults: state.categoriesAPI.b2cCategoriesResults,
  b2cCategoriesError: state.categoriesAPI.b2cCategoriesError,

  selectedProductType: state.myBusiness.selectedProductType,

  isActiveProductPending: state.activeProduct.isActiveProductPending,
  activeProduct: state.activeProduct.activeProduct,

  areCurrenciesPending: state.utilsReducer.areCurrenciesPending,
  currencies: state.utilsReducer.currencies,
  areMeasurementsPending: state.utilsReducer.areMeasurementsPending,
  measurements: state.utilsReducer.measurements,

  isProducersSearchPending: state.producers.isProducersSearchPending,
  producersSearchData: state.producers.producersSearchData,
  producersSearchResults: state.producers.producersSearchResults,

  organizationsSearchData: state.organizationsReducer.searchedOrganizations,

  createdB2BProduct: state.b2BProductsReducer.createdProduct,
  b2bRequestStatus: state.b2BProductsReducer.b2bRequestStatus,
  b2cRequestStatus: state.b2cProductsReducer.b2cRequestStatus,

  areProductVariationsByParentPending:
    state.productVariations.areProductVariationsByParentPending,
  productVariationsByParent: state.productVariations.productVariationsByParent,

  galleryBlob: state.documentsReducer.galleryBlob,
  galleryFile: state.documentsReducer.galleryFile,

  videoBlob: state.documentsReducer.videoBlob,
  videoFile: state.documentsReducer.videoFile,

  isImageGalleryUploadPending:
    state.documentsReducer.isImageGalleryUploadPending,
  isVideoGalleryUploadPending:
    state.documentsReducer.isVideoGalleryUploadPending
});

export default connect(mapStateToProps, {
  getCompaniesByUserId,
  createProduct,
  updateProductById,

  createB2CProduct,
  updateB2CProduct,

  searchBranch,

  getB2BCategories,
  getB2CCategories,

  getB2BProduct,
  getB2CProduct,

  getCurrencies,
  getMeasurements,

  searchProducers,
  searchOrganizations,
  uploadAdditionalPage,
  deleteAdditionalPageById,
  uploadDocument,
  deleteDocumentById,

  uploadAdditionalParameter,
  deleteAdditionalParameter,

  uploadProductSize,
  deleteSizeById,

  clearB2BRequest,
  clearB2CRequest,

  getProductVariationsByParentId,
  deleteProductVariationById,

  loadBlobGallery,
  loadFileGallery,

  loadFileVideo,
  loadBlobVideo,
  uploadVideoGallery,

  uploadImageGallery,
  deleteOneImageFromGallery,
  deleteOneVideoFromGallery
})(MyBusinessProductsEdit);
