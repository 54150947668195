import React from "react";

import "./styles.scss";

import { DefaultButton } from "../../../../components";

const Index = () => {
  return (
    <div className="pelecard-page__order pelecard__order_success">
      <h3 className="pelecard-page__title">Some problems with your order</h3>
      <div className="pelecard-page__info">
        <div className="pelecard-page__btn-container">
          <DefaultButton
            border="1px solid #ff9a44"
            color="#ff9a44"
            width="100%"
            onClick={() => {}}
          >
            Contact us
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default Index;
