import React from "react";

import {
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Reddit as RedditIcon
} from "@material-ui/icons";

import "./social-links-group.styles.scss";

const SocialLinksGroup = ({
  handleFacebookChange,
  handleLinkedinChange,
  handleTwitterChange,
  handleInstagramChange,
  handleYoutubeChange,
  handleRedditChange,
  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit
}) => (
  <div className="social-links-group">
    <div className="social-links-group__header">
      <div className="header-title">Social Link</div>
      <div className="header-subtitle">Add social links</div>
    </div>
    <div className="social-links-group__container">
      <div className="social-item">
        <FacebookIcon className="social-item__icon" />
        <span className="social-item__separator" />
        <input
          onChange={handleFacebookChange}
          value={facebook}
          className="social-item__input"
          type="text"
          placeholder="Link..."
        />
      </div>
      <div className="social-item">
        <LinkedInIcon className="social-item__icon" />
        <span className="social-item__separator" />
        <input
          onChange={handleLinkedinChange}
          value={linkedin}
          className="social-item__input"
          type="text"
          placeholder="Link..."
        />
      </div>
      <div className="social-item">
        <TwitterIcon className="social-item__icon" />
        <span className="social-item__separator" />
        <input
          onChange={handleTwitterChange}
          value={twitter}
          className="social-item__input"
          type="text"
          placeholder="Link..."
        />
      </div>
      <div className="social-item">
        <InstagramIcon className="social-item__icon" />
        <span className="social-item__separator" />
        <input
          onChange={handleInstagramChange}
          value={instagram}
          className="social-item__input"
          type="text"
          placeholder="Link..."
        />
      </div>
      <div className="social-item">
        <YouTubeIcon className="social-item__icon" />
        <span className="social-item__separator" />
        <input
          onChange={handleYoutubeChange}
          value={youtube}
          className="social-item__input"
          type="text"
          placeholder="Link..."
        />
      </div>
      <div className="social-item">
        <RedditIcon className="social-item__icon" />
        <span className="social-item__separator" />
        <input
          onChange={handleRedditChange}
          value={reddit}
          className="social-item__input"
          type="text"
          placeholder="Link..."
        />
      </div>
    </div>
  </div>
);

export default SocialLinksGroup;
