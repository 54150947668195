import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  SearchBar,
  EventCard,
  Pagination,
  LoadingSpinner,
  ConfirmDialog
} from "../../../components";
import { withDebounce } from "../../../shared";

import {
  getShippingMethodsByMe,
  deleteShippingMethodById
} from "../../../redux/actions-exporter";

import "./my-business-delivery.styles.scss";

const MyBusinessDelivery = ({
  getShippingMethodsByMe,
  areShippingMethodsPending,
  shippingMethods,
  deleteShippingMethodById
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [shippingPage, setShippingPage] = useState(1);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentDeliveryId, setCurrentDeliveryId] = useState(0);

  useEffect(() => {
    getShippingMethodsByMe(searchValue, shippingPage);
  }, [getShippingMethodsByMe, searchValue, shippingPage]);

  const searchHandler = withDebounce(e => setSearchValue(e));

  return (
    <div className="my-business-delivery-container">
      <ConfirmDialog
        dialogTitle="Company remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteShippingMethodById(currentDeliveryId);
          setDialogOpen(false);
          setCurrentDeliveryId(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => searchHandler(e.target.value)} />
      </div>
      <div className="my-business-delivery-container__shipping-methods">
        {areShippingMethodsPending ? (
          <LoadingSpinner />
        ) : (
          shippingMethods &&
          shippingMethods.results.map(({ id, name }) => (
            <EventCard
              key={id}
              title={name}
              editorState={true}
              editLink={`/my-business/delivery/${id}`}
              removeFunc={() => {
                setDialogOpen(true);
                setCurrentDeliveryId(id);
              }}
            />
          ))
        )}
      </div>
      <Pagination
        dataCount={shippingMethods && shippingMethods.count}
        itemsPerPage={10}
        selectedPage={page => setShippingPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  areShippingMethodsPending: state.shipping.areShippingMethodsPending,
  shippingMethods: state.shipping.shippingMethods
});

export default connect(mapStateToProps, {
  getShippingMethodsByMe,
  deleteShippingMethodById
})(MyBusinessDelivery);
