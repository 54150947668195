import { CartActionTypes } from "./cart.types";

const INITIAL_STATE = {
  isCartPending: false,
  cart: [],
  cartCount: 0,
  cartError: null,

  isDealPending: false,
  deal: [],
  dealCount: 0,
  dealError: null,

  isDeletedDealPending: false,
  deletedDeal: {},
  deletedDealError: null,

  isDeletedDealAllPending: false,
  deletedDealAll: {},
  deletedDealAllError: null,

  isQuantityPending: false,
  cartQuantity: {},
  cartQuantityError: null,

  isPatchDealPending: false,
  patchDeal: {},
  patchDealError: null,

  areProductsWithCompaniesPending: false,
  productsWithCompanies: [],
  productsWithCompaniesError: {},

  isCompanyWithProductsPending: false,
  companyWithProducts: {},
  companyWithProductsError: {},

  pelecardData: [],
  pelecardCheckData: [],
  pelecardDataError: {},
  pelecardCheckDataError: {},
  pelecardCheckTimeout: 0
};

const CartReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CartActionTypes.GET.GET_CART_PENDING:
      return { ...state, isCartPending: true };

    case CartActionTypes.GET.GET_CART_SUCCESS:
      return {
        ...state,
        isCartPending: false,
        cart: action.payload.cart,
        cartCount: action.payload.count,
        cartError: null
      };

    case CartActionTypes.GET.GET_CART_ERROR:
      return {
        ...state,
        isCartPending: false,
        cartError: action.payload
      };

    case CartActionTypes.GET.DEAL.GET_DEAL_PENDING:
      return { ...state, isDealPending: true };

    case CartActionTypes.GET.DEAL.GET_DEAL_SUCCESS:
      return {
        ...state,
        deal: action.payload.results,
        dealCount: action.payload.count,
        isDealPending: false
      };

    case CartActionTypes.GET.DEAL.GET_DEAL_ERROR:
      return {
        ...state,
        dealError: action.payload,
        isDealPending: false
      };

    case CartActionTypes.DELETE.DEAL.DELETE_DEAL_PENDING:
      return { ...state, isDeletedDealPending: true };

    case CartActionTypes.DELETE.DEAL.DELETE_DEAL_SUCCESS:
      return {
        ...state,
        isDeletedDealPending: false,
        deletedDealError: null
      };

    case CartActionTypes.DELETE.DEAL.DELETE_DEAL_ERROR:
      return {
        ...state,
        isDeletedDealPending: false,
        deletedDealError: action.payload
      };

    case CartActionTypes.DELETE.DEAL.DELETE_DEAL_ALL_PENDING:
      return { ...state, isDeletedDealAllPending: true };

    case CartActionTypes.DELETE.DEAL.DELETE_DEAL_ALL_SUCCESS:
      return {
        ...state,
        deletedDealAll: action.payload,
        isDeletedDealAllPending: false
      };

    case CartActionTypes.DELETE.DEAL.DELETE_DEAL_ALL_ERROR:
      return {
        ...state,
        deletedDealAllError: action.payload,
        isDeletedDealAllPending: false
      };

    case CartActionTypes.PUT.CART.PUT_CART_PENDING:
      return { ...state, isQuantityPending: true };

    case CartActionTypes.PUT.CART.PUT_CART_SUCCESS:
      return {
        ...state,
        isQuantityPending: false,
        cartQuantity: action.payload,
        cartQuantityError: null
      };

    case CartActionTypes.PUT.CART.PUT_CART_ERROR:
      return {
        ...state,
        isQuantityPending: false,
        cartQuantityError: action.payload
      };

    case CartActionTypes.PATCH.PATCH_DEAL_PENDING:
      return { ...state, isPatchDealPending: true };

    case CartActionTypes.PATCH.PATCH_DEAL_SUCCESS:
      return {
        ...state,
        isPatchDealPending: false,
        patchDeal: action.payload,
        patchDealError: null
      };

    case CartActionTypes.PATCH.PATCH_DEAL_ERROR:
      return {
        ...state,
        isPatchDealPending: false,
        patchDealError: action.payload
      };

    case CartActionTypes.GET.GET_CART_COMPANIES_WITH_PRODUCTS_PENDING:
      return {
        ...state,
        areProductsWithCompaniesPending: true
      };

    case CartActionTypes.GET.GET_CART_COMPANIES_WITH_PRODUCTS_SUCCESS:
      return {
        ...state,
        areProductsWithCompaniesPending: false,
        productsWithCompanies: action.payload
      };

    case CartActionTypes.GET.GET_CART_COMPANIES_WITH_PRODUCTS_ERROR:
      return {
        ...state,
        areProductsWithCompaniesPending: false,
        productsWithCompaniesError: action.payload
      };

    case CartActionTypes.GET.GET_ONE_CART_COMPANY_WITH_PRODUCTS_PENDING:
      return {
        ...state,
        isCompanyWithProductsPending: true
      };

    case CartActionTypes.GET.GET_ONE_CART_COMPANY_WITH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isCompanyWithProductsPending: false,
        companyWithProducts: action.payload
      };

    case CartActionTypes.GET.GET_ONE_CART_COMPANY_WITH_PRODUCTS_ERROR:
      return {
        ...state,
        isCompanyWithProductsPending: false,
        companyWithProductsError: action.payload
      };

    case "CREATE_PELECARD_ORDER_SUCCESS":
      return {
        ...state,
        pelecardData: action.payload
      };

    case "CREATE_PELECARD_ORDER_ERROR":
      return {
        ...state,
        pelecardDataError: action.payload
      };

    case "CHECK_PELECARD_TRANSACTION_SUCCESS":
      return {
        ...state,
        pelecardCheckData: action.payload
      };

    case "CHECK_PELECARD_TRANSACTION_ERROR":
      return {
        ...state,
        pelecardCheckDataError: action.payload
      };

    case "CHECK_PELECARD_TRANSACTION_RELOAD":
      return {
        ...state,
        pelecardCheckData: action.payload,
        pelecardCheckTimeout: state.pelecardCheckTimeout + 2000
      };

    case CartActionTypes.RESET_PELECARD:
      return {
        ...state,
        pelecardData: []
      };

    default:
      return state;
  }
};

export default CartReducer;
