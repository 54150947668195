import icon from "./icon.png";

export default function newsDescriptionBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .news-description-wrapper {}

    .news-description-wrapper.news-description-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .news-description-wrapper.news-description-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .news-description {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      padding: 16px 20px;
    }

    .news-description * {
      max-width: 100%;
    }

    @media screen and (max-width: 992px) {
      .news-description {
        flex-flow: column !important;
      }

      .news-description__image {
        width: 90%;
        margin: 0 auto;
      }

      .news-description__header {
        flex-flow: column!important;
        align-items: flex-start !important;
        margin: 0 0 16px;
      }

      .news-description__button {
        margin: 0 !important;
      }
    }

    .news-description__image {
      width: 340px;
      height: 300px;
      object-fit: scale-down;
      padding: 0 5%;
    }

    .news-description__main {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin: 0 5px 0;
    }
    
    .news-description__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .news-description__title {
      width: 90%;
      color: #262626;
      font-size: 30px;
      font-weight: 700;
      margin: 0 0 16px;
    }

    .news-description__divider {
      width: 114px;
      height: 7px;
      background-color: #aeaeae;  
    }

    .news-description__description {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin: 16px 0;
      word-break: break-word;
    }
    
    .news-description__meta {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      margin: auto 0 0;
    }

    .news-description__meta-item {}

    .news-description__icon {
      width: 17px;
      height: 17px;
      color: #2f2d2d;
    }

    .news-description__organization {
      padding: 0 0 0 4px;
    }

    .news-description__date {
      padding: 0 0 0 4px;
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="news-description-wrapper">
    <div class="news-description">
        <img class="news-description__image" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="/>
        <div class="news-description__main">
          <div class="news-description__header">
            <h4 class="news-description__title">
              Lorem ipsum dolor sit amet
            </h4>
          </div>
          <span class="news-description__divider"></span>
          <p class="news-description__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div class="news-description__meta">
            <div class="news-description__meta-item">
              <span class="news-description__organization">
                Organization
              </span>
            </div>
            <div class="news-description__meta-item">
              <span class="news-description__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
  </div>
  `;

  blockManager.add("news-description", {
    label: `
      <img src="${icon}" alt="News description block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        News Description
      </div>
    `,
    content,
    category: "B24Online News"
  });
}
