import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DefaultInputTimeOnly, IntegrationMonitor } from "../../components";

import { DefaultButton, InputGroup } from "@core/components";

import "./edea-integration.styles.scss";
import { useTranslation } from "react-i18next";
import { ParentWrapper, ButtonsFooterWrapper } from "@core/layout";

import {
  getIntegrations,
  getIntegrationById,
  createIntegration,
  getIntegrationLogsById
} from "../../redux/actions-exporter";
import { buttonOk } from "../../styles/buttons-styles";

const EdeaIntegration = ({ companyId, isSync }) => {
  const dispatch = useDispatch();
  const integrations = useSelector(
    state => state.integrationsReducer.integrations
  );
  const integrationById = useSelector(
    state => state.integrationsReducer.integrationById
  );
  const integrationLogs = useSelector(
    state => state.integrationsReducer.integrationLogsById
  );
  const appDirection = useSelector(state => state.appLanguage.appDirection);

  const { t } = useTranslation();

  const [syncInterval, setSyncInterval] = useState(""); // eslint-disable-line
  const [branchNumber, setBranchNumber] = useState("");
  const [POSNumber, setPOSNumber] = useState(null);
  const [uniqueIdentifier, setUniqueIdentifier] = useState("");
  const [disabledButton, setDisableButton] = useState(true);

  useEffect(() => {
    dispatch(getIntegrations(companyId));
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (integrations.length) {
      dispatch(getIntegrationById(integrations[0].id));
      dispatch(getIntegrationLogsById(integrations[0].id));
    }
  }, [dispatch, integrations]);

  useEffect(() => {
    if (integrationById) {
      setSyncInterval(integrationById.interval);
      setBranchNumber(integrationById?.config?.BranchNumber);
      setPOSNumber(integrationById?.config?.POSNumber);
      setUniqueIdentifier(integrationById?.config?.UniqueIdentifier);
    }
  }, [
    integrationById,
    setSyncInterval,
    setBranchNumber,
    setPOSNumber,
    setUniqueIdentifier
  ]);

  const createIntegrationHandler = () => {
    dispatch(
      createIntegration({
        branchNumber,
        POSNumber,
        uniqueIdentifier,
        companyId,
        isSync,
        syncInterval
      })
    );
  };

  useEffect(() => {
    setDisableButton(
      branchNumber === "" || POSNumber === null || uniqueIdentifier === ""
    );
  }, [branchNumber, POSNumber, uniqueIdentifier]);

  return (
    <div className={`my-business__integration ${appDirection}`}>
      <h3 style={{ margin: 0 }}>{t("Edea Integration")} </h3>
      <div className="my-business__integration-container">
        <ParentWrapper>
          <DefaultInputTimeOnly
            label="Sync Interval"
            changeHandler={e => setSyncInterval(e.target.value + ":00")}
          />
        </ParentWrapper>
        <ParentWrapper>
          <InputGroup
            onChange={e => setBranchNumber(e.target.value)}
            value={branchNumber}
            label="BRANCH NUMBER"
            name="branchNumber"
            required={true}
          />
        </ParentWrapper>
        <ParentWrapper>
          <InputGroup
            onChange={e => setPOSNumber(e.target.value)}
            value={POSNumber}
            label="POS NUMBER"
            name="POSNumber"
            required={true}
          />
        </ParentWrapper>
        <ParentWrapper>
          <InputGroup
            onChange={e => setUniqueIdentifier(e.target.value)}
            value={uniqueIdentifier}
            label="UNIQUE ID"
            name="uniqueIdentifier"
            required={true}
          />
        </ParentWrapper>
        {integrations.length === 0 && (
          <ButtonsFooterWrapper>
            <div>
              <DefaultButton
                width="100%"
                {...buttonOk}
                onClick={createIntegrationHandler}
                disabled={disabledButton}
              >
                {t("Create Integration")}
              </DefaultButton>
            </div>
          </ButtonsFooterWrapper>
        )}
        {integrationLogs.length > 0 && (
          <IntegrationMonitor integrationLogs={integrationLogs} />
        )}
      </div>
    </div>
  );
};

export default EdeaIntegration;
