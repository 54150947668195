import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "./axios/axios.config";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authorization = getCookie("Authorization");
  const token = authorization && authorization.slice(6);

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect
              to={{
                pathname: "/signin",
                search: `?auth-redirect=${window.location.pathname}${window.location.search}`,
                state: { from: props.location }
              }}
            />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
