import React from "react";

import "./default-input-radio.styles.scss";

const DefaultInputRadio = ({
  onChange,
  onClick,
  id,
  name,
  value,
  checked,
  labelText,
  customClassName,
  readOnly
}) => {
  return (
    <div className={`default-input-radio ${customClassName}`}>
      <input
        className={"default-input-radio__input-radio"}
        onChange={onChange}
        onClick={onClick}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        readOnly={readOnly}
      />{" "}
      <label htmlFor={id}>{labelText}</label>
    </div>
  );
};

export default DefaultInputRadio;
