export const InnovationProjectsActionTypes = {
  GET: {
    GET_INNOVATION_PROJECTS_PENDING: "GET_INNOVATION_PROJECTS_PENDING",
    GET_INNOVATION_PROJECTS_SUCCESS: "GET_INNOVATION_PROJECTS_SUCCESS",
    GET_INNOVATION_PROJECTS_ERROR: "GET_INNOVATION_PROJECTS_ERROR",

    GET_INNOVATION_PROJECT_BY_ID_PENDING:
      "GET_INNOVATION_PROJECT_BY_ID_PENDING",
    GET_INNOVATION_PROJECT_BY_ID_SUCCESS:
      "GET_INNOVATION_PROJECT_BY_ID_SUCCESS",
    GET_INNOVATION_PROJECT_BY_ID_ERROR: "GET_INNOVATION_PROJECT_BY_ID_ERROR",

    GET_INNOVATION_PROJECTS_BY_CURRENT_USER_PENDING:
      "GET_INNOVATION_PROJECTS_BY_CURRENT_USER_PENDING",
    GET_INNOVATION_PROJECTS_BY_CURRENT_USER_SUCCESS:
      "GET_INNOVATION_PROJECTS_BY_CURRENT_USER_SUCCESS",
    GET_INNOVATION_PROJECTS_BY_CURRENT_USER_ERROR:
      "GET_INNOVATION_PROJECTS_BY_CURRENT_USER_ERROR"
  },
  POST: {
    POST_INNOVATION_PROJECTS_PENDING: "POST_INNOVATION_PROJECTS_PENDING",
    POST_INNOVATION_PROJECTS_SUCCESS: "POST_INNOVATION_PROJECTS_SUCCESS",
    POST_INNOVATION_PROJECTS_ERROR: "POST_INNOVATION_PROJECTS_ERROR"
  },
  PUT: {
    PUT_INNOVATION_PROJECTS_PENDING: "PUT_INNOVATION_PROJECTS_PENDING",
    PUT_INNOVATION_PROJECTS_SUCCESS: "PUT_INNOVATION_PROJECTS_SUCCESS",
    PUT_INNOVATION_PROJECTS_ERROR: "PUT_INNOVATION_PROJECTS_ERROR"
  },
  DELETE: {
    DELETE_INNOVATION_PROJECTS_PENDING: "DELETE_INNOVATION_PROJECTS_PENDING",
    DELETE_INNOVATION_PROJECTS_SUCCESS: "DELETE_INNOVATION_PROJECTS_SUCCESS",
    DELETE_INNOVATION_PROJECTS_ERROR: "DELETE_INNOVATION_PROJECTS_ERROR"
  },
  CLEAR_INNOVATION_PROJECTS_REQUEST: "CLEAR_INNOVATION_PROJECTS_REQUEST"
};
