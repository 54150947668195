import { StatisticsTypes } from "./statistics.types";

const INITIAL_STATE = {
  areStatisticsPending: false,
  statistics: [],
  statisticsError: {}
};

const StatisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StatisticsTypes.GET.GET_STATISTICS_PENDING:
      return {
        ...state,
        areStatisticsPending: true
      };

    case StatisticsTypes.GET.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
        areStatisticsPending: false
      };

    case StatisticsTypes.GET.GET_STATISTICS_ERROR:
      return {
        ...state,
        statisticsError: action.payload,
        areStatisticsPending: false
      };

    default:
      return state;
  }
};

export default StatisticsReducer;
