import icon from "./icon.png";

export default function cartSubtotalBlock({ editor, api }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .cart-subtotal {
    display: flex;
    flex-flow: column;
  }
  
  .cart-subtotal-wrapper.cart-subtotal-wrapper--preloader {
    position: relative;
    transition: all 300ms ease;
  }
  
  .cart-subtotal-wrapper.cart-subtotal-wrapper--preloader::before {
    content: "";
    background: hsla(0, 0%, 100%, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .cart-subtotal__title {
    color: #252525;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }

  .cart-subtotal__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cart-subtotal__separator {
    background: #aeaeae;
    height: 1px;
    width: 100%;
    margin: 12px 0;
  }
  </style>
  `;

  const content = `
  <div class="cart-subtotal-wrapper">
    ${style}
    <div class="cart-subtotal">
      <h4 class="cart-subtotal__title">SUBTOTAL</h4>

      <div class="cart-subtotal__row">
        <span class="cart-subtotal__item cart-subtotal__item--title">10 Items (USD)</span>
        <span class="cart-subtotal__item">500.00 USD</span>
      </div>
      <div class="cart-subtotal__row">
        <span class="cart-subtotal__item cart-subtotal__item--title">5 Items (EUR)</span>
        <span class="cart-subtotal__item">29.99 EUR</span>
      </div>
      <div class="cart-subtotal__row">
        <span class="cart-subtotal__item cart-subtotal__item--title">50 Items (ILS)</span>
        <span class="cart-subtotal__item">5600.00 ILS</span>
      </div>
      <span class="cart-subtotal__separator"></span>
      <div class="cart-subtotal__row">
        <span class="cart-subtotal__item cart-subtotal__item--title cart-subtotal__item--shipping">
          Shipping (USD)
        </span>
        <span class="cart-subtotal__item cart-subtotal__item--cost cart-subtotal__item--shipping">
          40.00 USD
        </span>
      </div>
      <div class="cart-subtotal__row">
        <span class="cart-subtotal__item cart-subtotal__item--title cart-subtotal__item--shipping">
          Shipping (EUR)
        </span>
        <span class="cart-subtotal__item cart-subtotal__item--cost cart-subtotal__item--shipping">
          10.00 EUR
        </span>
      </div>
      <div class="cart-subtotal__row">
        <span class="cart-subtotal__item cart-subtotal__item--title cart-subtotal__item--shipping">
          Shipping (ILS)
        </span>
        <span class="cart-subtotal__item cart-subtotal__item--cost cart-subtotal__item--shipping">
          5.00 ILS
        </span>
      </div>
    </div>
  </div>
  `;

  blockManager.add("cart-subtotal", {
    label: `
      <img src="${icon}" alt="'Cart Subtotal' block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Cart Subtotal
      </div>
    `,
    content,
    category: "B24Online Cart"
  });
}
