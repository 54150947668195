import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";

import { withDebounce } from "../../../shared";
import {
  EventCard,
  Pagination,
  SearchBar,
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import {
  getBusinessProposalsByCurrentUser,
  deleteBusinessProposalById
} from "../../../redux/actions-exporter";

import "./my-business-proposals.styles.scss";

const MyBusinessProposals = ({
  getBusinessProposalsByCurrentUser,
  isBusinessProposalsByCurrentUserPending,
  businessProposalsByCurrrentUser,
  businessProposalsByCurrrentUserCount,
  deleteBusinessProposalById
}) => {
  const [proposalsSearch, setProposalsSearch] = useState("");
  const [proposalsPage, setProposalsPage] = useState(1);

  const [currentProposal, setCurrentProposal] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getBusinessProposalsByCurrentUser(proposalsSearch, proposalsPage);
  }, [getBusinessProposalsByCurrentUser, proposalsSearch, proposalsPage]);

  const setProposalsSearchHandler = withDebounce(e => setProposalsSearch(e));

  return (
    <div className="my-business-proposals">
      <ConfirmDialog
        dialogTitle="Business proposal remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteBusinessProposalById(currentProposal);
          setDialogOpen(false);
          setCurrentProposal(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar onChange={e => setProposalsSearchHandler(e.target.value)} />
      </div>
      {isBusinessProposalsByCurrentUserPending ? (
        <LoadingSpinner />
      ) : businessProposalsByCurrrentUser &&
        !!businessProposalsByCurrrentUser.length ? (
        businessProposalsByCurrrentUser.map(
          ({ id, title, description, created_at, country, organization }) => (
            <EventCard
              key={id}
              country={country && country.name}
              title={title}
              description={parse(description)}
              publicated={true}
              publicatedDate={moment(created_at).format("DD MMMM YYYY")}
              publicatedCompany={organization && organization.name}
              editorState={true}
              editLink={`/my-business/business-proposals/${id}`}
              removeFunc={() => {
                setDialogOpen(true);
                setCurrentProposal(id);
              }}
              viewLiveUrl={`/business-proposals/business-proposal/${id}`}
            />
          )
        )
      ) : (
        <MyBusinessNoData noDataText="No Business Proposals!" />
      )}
      <Pagination
        forcePage={proposalsPage - 1}
        dataCount={businessProposalsByCurrrentUserCount}
        itemsPerPage={10}
        selectedPage={page => setProposalsPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  isBusinessProposalsByCurrentUserPending:
    state.businessProposals.isBusinessProposalsByCurrentUserPending,
  businessProposalsByCurrrentUser:
    state.businessProposals.businessProposalsByCurrrentUser,
  businessProposalsByCurrrentUserCount:
    state.businessProposals.businessProposalsByCurrrentUserCount
});

export default connect(mapStateToProps, {
  getBusinessProposalsByCurrentUser,
  deleteBusinessProposalById
})(MyBusinessProposals);
