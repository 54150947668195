export const ActiveProductActionTypes = {
  B2B: {
    B2B_PRODUCT_PENDING: "B2B_PRODUCT_PENDING",
    B2B_PRODUCT_SUCCESS: "B2B_PRODUCT_SUCCESS",
    B2B_PRODUCT_ERROR: "B2B_PRODUCT_ERROR"
  },
  B2C: {
    B2C_PRODUCT_PENDING: "B2C_PRODUCT_PENDING",
    B2C_PRODUCT_SUCCESS: "B2C_PRODUCT_SUCCESS",
    B2C_PRODUCT_ERROR: "B2C_PRODUCT_ERROR"
  },
  SET_PRODUCT_VARIATION: "SET_PRODUCT_VARIATION"
};
