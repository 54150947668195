import React, { Fragment, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import { getCookie } from "./axios/axios.config";
import { appRoutes } from "./routes";

import { Navbar } from "./containers";
import { Header, LoadingSpinner, Footer } from "./components";
import { BusinessCard } from "./pages";

import {
  getCountryId,
  getProfile,
  getLocalizationData,
  setApplicationDirection,
  getFooterStaticPages
} from "./redux/actions-exporter";

import GrapesApp from "./GrapesApp";

import "./App.scss";

function App({
  getCountryId,
  isCountryIdPending,
  getProfile,
  applicationLanguage,
  getLocalizationData,
  appIsPendingData,
  setApplicationDirection,
  appDirection,
  localizationData,
  footerPage,
  getFooterStaticPages
}) {
  const langToLowerCase = applicationLanguage.toLowerCase();
  const isGrapes = window.location.pathname.includes("/newbuilder/site/");
  const isBusinessCard = useRouteMatch("/business-card/:userId");

  useEffect(() => {
    if (getCookie("Authorization")) {
      getProfile();
    }
  }, [getProfile]);

  useEffect(() => {
    if (isGrapes) return;
    getCountryId();
    getFooterStaticPages();
  }, [getCountryId, getFooterStaticPages, isGrapes]);

  useEffect(() => {
    if (isGrapes) return;
    getLocalizationData(langToLowerCase);
  }, [getLocalizationData, langToLowerCase, isGrapes]);

  useEffect(() => {
    if (isGrapes) return;
    setApplicationDirection(applicationLanguage);
  }, [setApplicationDirection, applicationLanguage, isGrapes]);

  if (isGrapes) {
    return <GrapesApp />;
  }

  if (isBusinessCard) {
    return <BusinessCard />;
  }

  return (
    <>
      <div className={`app-container ${appDirection}`} dir={appDirection}>
        {appIsPendingData ? (
          <div className="loading-spinner-container">
            <LoadingSpinner />
          </div>
        ) : (
          <Fragment>
            <Header />
            <div className="main-body">
              <div className="main-body__navbar">
                <Navbar />
              </div>
              {!isCountryIdPending && (
                <div className="main-body__content">
                  {appRoutes}
                  {/* <Route component={NoMatchPage} /> */}
                </div>
              )}
            </div>
            <Footer
              localizationData={localizationData}
              footerPage={footerPage}
            />
          </Fragment>
        )}
      </div>
      <Switch>
        <Route path="/business-card/:userId" component={BusinessCard} />
      </Switch>
    </>
  );
}

const mapStateToProps = state => ({
  appDirection: state.appLanguage.appDirection,
  isCountryIdPending: state.countriesReducer.isCountryIdPending,
  appIsPendingData: state.appLanguage.isPendingData,
  applicationLanguage: state.appLanguage.appLanguage,
  localizationData: state.appLanguage.localizationData,
  footerPage: state.staticPagesReducer.footerPages
});

export default connect(mapStateToProps, {
  getCountryId,
  getProfile,
  getLocalizationData,
  setApplicationDirection,
  getFooterStaticPages
})(App);
