export default function cartSubmitComponent({ editor, api }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function render(deals, redirect, buttonText) {
      const buttonEl = document.querySelector(".cart-submit");

      buttonEl.textContent = buttonText;

      if (!deals.length) {
        buttonEl.setAttribute("disabled", "");
        return;
      }

      if (!window.hasOwnProperty("grapesjs")) return;

      buttonEl.addEventListener("click", () =>
        window.location.assign(redirect)
      );
    }

    const deals = window.localStorage.hasOwnProperty("deals")
      ? window.localStorage.getItem("deals")
      : [];
    const redirect =
      (this.attributes.redirect && this.attributes.redirect.value) || "#";
    // Get translation & render
    const translations = getTranslate("translations", this.attributes);
    const buttonText = translations["CONTINUE SECURELY"] || "CONTINUE SECURELY";
    if (translations && Object.keys(translations).length) {
      render(deals, redirect, buttonText);
    }
  };

  editor.DomComponents.addType("cart-submit", {
    isComponent: el =>
      el.tagName === "BUTTON" && el.className === "cart-submit",
    model: {
      defaults: {
        name: "Cart Submit",
        script,
        traits: [
          {
            type: "page-selector",
            label: "Checkout Page",
            name: "redirect",
            modalTitle: "Select redirect page with checkout"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["CONTINUE SECURELY"]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:redirect", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
