import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ProductsPreviewGrid } from "../../../components";

import { getB2CProducts } from "../../../redux/actions-exporter";

import "./wall-products-preview-grid.styles.scss";

const WallProductsPreviewGrid = ({
  getB2CProducts,
  areB2CProductsPending,
  b2cProductsNamespaces,
  b2cProductsError,
  countryId,
  props
}) => {
  const [data, setData] = useState({
    loading: false,
    params: {
      perPage: 6,
      countryId,
      random: true
    },
    products: []
  });
  const { products, params, loading } = data;
  const namespace = "wallRandomB2CProducts";

  useEffect(() => {
    if (products.length || areB2CProductsPending || b2cProductsError) {
      return;
    }

    const isValidProducts =
      b2cProductsNamespaces[namespace] &&
      JSON.stringify(b2cProductsNamespaces[namespace].params) ===
        JSON.stringify(params);

    setData({
      ...data,
      products: isValidProducts
        ? b2cProductsNamespaces[namespace].products
        : [],
      loading: !isValidProducts
    });

    if (!isValidProducts) {
      getB2CProducts({ ...params, namespace, additional: { params } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, areB2CProductsPending]);

  return (
    <ProductsPreviewGrid props={props} products={products} loading={loading} />
  );
};

const mapStateToProps = state => ({
  countryId: state.countriesReducer.countryId,
  b2cProductsNamespaces: state.b2cProductsReducer.b2cProductsNamespaces,
  areB2CProductsPending: state.b2cProductsReducer.areB2CProductsPending,
  b2cProductsError: state.b2cProductsReducer.b2cProductsError
});

export default connect(mapStateToProps, {
  getB2CProducts
})(WallProductsPreviewGrid);
