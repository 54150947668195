import axios from "axios";
import {
  axiosNoTokenInstance,
  axiosInstance,
  getCookie,
  baseURL
} from "../../../../axios/axios.config";
import { B2CProductsActionTypes } from "./b2c.products.types";

export const getB2CProductsCategories = (pageNumber = 1) => dispatch => {
  dispatch({
    type:
      B2CProductsActionTypes.GET_B2C_PRODUCTS_CATEGORIES
        .GET_B2C_PRODUCTS_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/b2c/category/?page=${pageNumber}`)
    .then(b2cProductsCategories =>
      dispatch({
        type:
          B2CProductsActionTypes.GET_B2C_PRODUCTS_CATEGORIES
            .GET_B2C_PRODUCTS_CATEGORIES_SUCCESS,
        payload: b2cProductsCategories
      })
    )
    .catch(error =>
      dispatch({
        type:
          B2CProductsActionTypes.GET_B2C_PRODUCTS_CATEGORIES
            .GET_B2C_PRODUCTS_CATEGORIES_ERROR,
        payload: error
      })
    );
};

export const getCompanyB2CProducts = (
  companyId,
  categories = "",
  ordering,
  pageNumber = 1
) => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.COMPANY.GET_COMPANY_B2C_PRODUCTS_PENDING
  });
  axiosInstance
    .get(
      `api/v1/b2c/product/?company=${companyId}&categories=${categories}&ordering=${ordering}&page=${pageNumber}`
    )
    .then(companyB2CProducts =>
      dispatch({
        type: B2CProductsActionTypes.COMPANY.GET_COMPANY_B2C_PRODUCTS_SUCCESS,
        payload: {
          count: companyB2CProducts.data.count,
          products: companyB2CProducts.data.results
        }
      })
    )
    .catch(error =>
      dispatch({
        type: B2CProductsActionTypes.COMPANY.GET_COMPANY_B2C_PRODUCTS_FAIL,
        payload: error
      })
    );
};

export const getOrganizationB2CProducts = (
  organizationId,
  categories = "",
  ordering,
  page = 1
) => dispatch => {
  dispatch({
    type:
      B2CProductsActionTypes.ORGANIZATION.GET_ORGANIZATION_B2C_PRODUCTS_PENDING
  });
  axiosInstance
    .get(
      `/api/v1/b2c/product/?company__parent=${organizationId}&categories=${categories}&ordering=${ordering}&page=${page}`
    )
    .then(resp =>
      dispatch({
        type:
          B2CProductsActionTypes.ORGANIZATION
            .GET_ORGANIZATION_B2C_PRODUCTS_SUCCESS,
        payload: resp.data
      })
    )
    .catch(err =>
      dispatch({
        type:
          B2CProductsActionTypes.ORGANIZATION
            .GET_ORGANIZATION_B2C_PRODUCTS_FAIL,
        payload: err
      })
    );
};

export const createB2CProduct = (
  name,
  sku,
  producer,
  company,
  isActive,
  categories,
  stock,
  showOnMain,
  isDigitalProduct,
  organization,
  shortDescription,
  description,
  keywords,
  currency,
  cost,
  discountPercent,
  couponDiscountPercent,
  couponStartDate,
  couponEndData,
  image,
  productIsRecommended,
  leadFormId
) => dispatch => {
  dispatch({ type: B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_PENDING });
  axiosInstance
    .post(`/api/v1/b2c/product_manager/`, {
      name,
      metadata: {
        stock_keeping_unit: sku,
        coupon_start_date: couponStartDate,
        coupon_end_date: couponEndData,
        lead_form_id: leadFormId
      },
      producer,
      company,
      is_active: isActive,
      categories,
      stock,
      show_on_main: showOnMain,
      short_description: shortDescription,
      description,
      keywords,
      currency,
      cost,
      sku,
      discount_percent: discountPercent,
      coupon_discount_percent: couponDiscountPercent,
      is_recommended: productIsRecommended,
      is_digital_product: isDigitalProduct
    })
    .then(resp => {
      if (image !== undefined) {
        dispatch({
          type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", image);
        axios
          .patch(
            `${baseURL}/api/v1/b2c/product_manager/${resp.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(() => {
            dispatch({
              type: B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_SUCCESS,
              payload: { product: resp.data, requestStatus: resp.status }
            });
          })
          .catch(err =>
            dispatch({
              type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_SUCCESS,
          payload: { product: resp.data, requestStatus: resp.status }
        });
      }
    })
    .catch(err =>
      dispatch({
        type: B2CProductsActionTypes.POST.CREATE_B2C_PRODUCT_ERROR,
        paylaod: err
      })
    );
};

export const updateB2CProduct = (
  productId,
  name,
  sku,
  producer,
  company,
  isActive,
  categories,
  stock,
  showOnMain,
  isDigitalProduct,
  organization,
  shortDescription,
  description,
  keywords,
  currency,
  cost,
  discountPercent,
  couponDiscountPercent,
  couponStartDate,
  couponEndData,
  image,
  productIsRecommended,
  leadFormId
) => dispatch => {
  dispatch({ type: B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_PENDING });
  axiosInstance
    .put(`/api/v1/b2c/product_manager/${productId}/`, {
      name,
      metadata: {
        stock_keeping_unit: sku,
        coupon_start_date: couponStartDate,
        coupon_end_date: couponEndData,
        lead_form_id: leadFormId
      },
      producer,
      company,
      is_active: isActive,
      categories,
      stock,
      show_on_main: showOnMain,
      short_description: shortDescription,
      description,
      keywords,
      currency,
      cost,
      sku,
      discount_percent: discountPercent,
      coupon_discount_percent: couponDiscountPercent,
      is_recommended: productIsRecommended,
      is_digital_product: isDigitalProduct
    })
    .then(resp => {
      if (image !== undefined) {
        dispatch({
          type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_PENDING
        });
        const formData = new FormData();
        formData.append("image", image);
        axios
          .patch(
            `${baseURL}/api/v1/b2c/product_manager/${resp.data.id}/`,
            formData,
            {
              headers: {
                Authorization: getCookie("Authorization"),
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(resp =>
            dispatch({
              type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_SUCCESS,
              payload: resp.data
            })
          )
          .then(() => {
            dispatch({
              type: B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_SUCCESS,
              payload: { product: resp.data, requestStatus: resp.status }
            });
          })
          .catch(err =>
            dispatch({
              type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_ERROR,
              payload: err
            })
          );
      } else {
        dispatch({
          type: B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_SUCCESS,
          payload: { product: resp.data, requestStatus: resp.status }
        });
      }
    })
    .catch(err =>
      dispatch({
        type: B2CProductsActionTypes.PUT.UPDATE_B2C_PRODUCT_ERROR,
        payload: err
      })
    );
};

export const getUserB2CProducts = (
  searchQuery = "",
  page = 1,
  pageSize = 10
) => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_PENDING
  });
  axiosInstance
    .get(
      `api/v1/b2c/product_manager/?search=${searchQuery}&page_size=${pageSize}&page=${page}`
    )
    .then(resp =>
      dispatch({
        type: B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_SUCCESS,
        payload: { count: resp.data.count, products: resp.data.results }
      })
    )
    .catch(error =>
      dispatch({
        type: B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_ERROR,
        payload: error
      })
    );
};

export const searchUserB2CProducts = (searchQuery, page = 1) => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_PENDING
  });
  axiosInstance
    .get(`api/v1/b2c/product_manager/?search=${searchQuery}&page=${page}`)
    .then(resp =>
      dispatch({
        type: B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_SUCCESS,
        payload: { resp: resp.data, results: resp.data.results }
      })
    )
    .catch(error =>
      dispatch({
        type: B2CProductsActionTypes.GET.USER.GET_USER_B2C_PRODUCTS_ERROR,
        payload: error
      })
    );
};

export const deleteB2CProduct = productId => dispatch => {
  dispatch({ type: B2CProductsActionTypes.DELETE.DELETE_B2C_PRODUCT_PENDING });
  axiosInstance
    .delete(`/api/v1/b2c/product_manager/${productId}/`)
    .then(resp =>
      dispatch({
        type: B2CProductsActionTypes.DELETE.DELETE_B2C_PRODUCT_SUCCESS,
        paylaod: resp.data
      })
    )
    .then(() => dispatch(getUserB2CProducts()))
    .catch(err =>
      dispatch({
        type: B2CProductsActionTypes.DELETE.DELETE_B2C_PRODUCT_ERROR,
        paylaod: err
      })
    );
};

export const patchB2CImage = (id, image) => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_PENDING
  });
  const formData = new FormData();
  formData.append("image", image);
  axios
    .patch(`${baseURL}/api/v1/b2b/product_manager/${id}/`, formData, {
      headers: {
        Authorization: getCookie("Authorization"),
        "Content-Type": "multipart/form-data"
      }
    })
    .then(resp =>
      dispatch({
        type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_SUCCESS,
        payload: resp.data
      })
    )
    .catch(err =>
      dispatch({
        type: B2CProductsActionTypes.PATCH.IMAGE.PATCH_B2C_IMAGE_ERROR,
        payload: err
      })
    );
};

export const getRecommendedB2CProducts = ({
  page = 1,
  perPage = 10,
  namespace = undefined,
  additional = {}
} = {}) => dispatch => {
  dispatch({
    type:
      B2CProductsActionTypes.GET.RECOMMENDED
        .GET_RECOMMENDED_B2C_PRODUCTS_PENDING
  });
  axiosInstance
    .get(`/api/v1/recommended/b2c/?page=${page}&page_size=${perPage}`)
    .then(resp =>
      dispatch({
        type:
          B2CProductsActionTypes.GET.RECOMMENDED
            .GET_RECOMMENDED_B2C_PRODUCTS_SUCCESS,
        payload: {
          namespace,
          additional,
          products: resp.data.results,
          count: resp.data.count
        }
      })
    )
    .catch(err =>
      dispatch({
        type:
          B2CProductsActionTypes.GET.RECOMMENDED
            .GET_RECOMMENDED_B2C_PRODUCTS_ERROR,
        paylaod: err
      })
    );
};

export const getSimilarB2CProducts = productId => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2C_PRODUCTS_PENDING
  });
  axiosInstance
    .get(`api/v1/similar/b2c/?products=${productId}`)
    .then(resp =>
      dispatch({
        type:
          B2CProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2C_PRODUCTS_SUCCESS,
        payload: { count: resp.data.count, products: resp.data.results }
      })
    )
    .catch(err =>
      dispatch({
        type: B2CProductsActionTypes.GET.SIMILAR.GET_SIMILAR_B2C_PRODUCTS_ERROR,
        paylaod: err
      })
    );
};

export const getB2CProducts = ({
  searchQuery = "",
  page = 1,
  countries = "",
  companies = "",
  producers = "",
  categories = "",
  categoriesIn = "",
  couponDiscountPercent = undefined,
  couponDiscountPercentGt = undefined,
  discountPercent = undefined,
  discountPercentGt = undefined,
  isRecommended = undefined,
  perPage = 10,
  ordering = "-id",
  random = false,
  namespace = undefined,
  additional = {}
} = {}) => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.GET_B2C_PRODUCTS.GET_B2C_PRODUCTS_PENDING
  });

  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries({
        search: searchQuery,
        company__countries: countries,
        company__parent: companies,
        producer: producers,
        categories,
        categories_in: categoriesIn,
        page,
        page_size: perPage,
        ordering,
        coupon_discount_percent: couponDiscountPercent,
        coupon_discount_percent__gt: couponDiscountPercentGt,
        discount_percent: discountPercent,
        discount_percent__gt: discountPercentGt,
        is_recommended:
          typeof isRecommended === "boolean"
            ? isRecommended
              ? "True"
              : "False"
            : undefined
      }).filter(([key, value]) => typeof value !== "undefined" && value !== "")
    )
  );
  axiosNoTokenInstance
    .get(`/api/v1/b2c/product${random ? "/random/" : "/"}?${params}`)
    .then(b2cProducts =>
      dispatch({
        type: B2CProductsActionTypes.GET_B2C_PRODUCTS.GET_B2C_PRODUCTS_SUCCESS,
        payload: {
          namespace,
          additional,
          products: b2cProducts.data.results,
          count: b2cProducts.data.count
        }
      })
    )
    .catch(error =>
      dispatch({
        type: B2CProductsActionTypes.GET_B2C_PRODUCTS.GET_B2C_PRODUCTS_ERROR,
        payload: error
      })
    );
};

export const clearB2CRequest = () => dispatch => {
  dispatch({
    type: B2CProductsActionTypes.CLEAR_B2C_PRODUCTS_REQUEST,
    payload: ""
  });
};
