export default function profileTextComponent({ editor, api, className }) {
  const script = function() {
    const template = this.dataset.textTemplate;
    this.innerText = template || "Empty profile text template";

    if (!window.hasOwnProperty("grapesjs")) {
      return;
    }

    const replaceFromObject = (
      str,
      obj,
      findStr,
      startCharacter,
      endCharacter
    ) =>
      str
        .split(`${startCharacter}${findStr}${endCharacter}`)
        .join(obj[findStr] || "");

    const handleData = data => {
      const profileData = Object.assign({}, data, {
        country_name: data.country ? data.country.name : ""
      });
      const finalStr = [
        "first_name",
        "middle_name",
        "last_name",
        "mobile_number",
        "site",
        "profession",
        "country_name",
        "birthday",
        "sex",
        "contacts",
        "co_name",
        "co_slogan",
        "co_description"
      ].reduce(
        (tmp, findStr) =>
          replaceFromObject(tmp, profileData, findStr, "[", "]"),
        template
      );

      this.innerText = finalStr;
    };

    if (!window.hasOwnProperty("gjsProfile")) {
      const API_ROOT = "{[ apiRoot ]}";

      window.gjsProfile = {
        API_ROOT: API_ROOT,
        renderCycle: [],
        params: {},
        data: {},
        loading: false,
        render() {
          console.group("profile render");
          console.log("render before new cycle", this);
          this.renderCycle.map(func => func(this.data, this.loading));
          console.log("render after new cycle", this);
          console.groupEnd("profile render");
        },
        appendFuncToRenderCycle(newFunc) {
          const isNew = !this.renderCycle.find(func => func === newFunc);
          if (isNew) {
            this.renderCycle = this.renderCycle.concat(newFunc);
          }
        },
        setLoading(state) {
          this.setProp("loading", state, this.render.bind(this));
        },
        setProp(name, value, callback) {
          if (JSON.stringify(this[name]) !== JSON.stringify(value)) {
            this[name] = value;
            if (callback !== undefined) {
              callback();
            }
          }
        },
        setParam(name, value) {
          const object = {};
          object[name] = value;
          this.setProp("params", Object.assign({}, this.params, object));
        },
        getProfile() {
          if (this.loading || !localStorage.hasOwnProperty("token")) return;
          this.setLoading(true);

          const params = new URLSearchParams(this.params);
          return fetch(`${API_ROOT}/auth/users/me/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(me =>
              fetch(`${API_ROOT}/account/profile/?${params}&user_id=${me.id}`, {
                method: "GET",
                headers: Object.assign(
                  { "Accept-Language": localStorage.getItem("language") },
                  localStorage.getItem("token")
                    ? {
                        Authorization: `Token ${localStorage.getItem("token")}`
                      }
                    : {}
                ),
                cors: "cors",
                credentials: "omit"
              })
                .then(response => response.json())
                .then(response =>
                  this.setProfile(
                    Object.assign({}, response.results[0], { email: me.email })
                  )
                )
            );
        },
        setProfile(data) {
          // Compare items
          if (JSON.stringify(this.data) !== JSON.stringify(data)) {
            this.render();
          }
          // Set profile data
          this.data = data;
          // Get additional information
          if (this.edit) {
            Promise.all([this.getGenders(), this.getProfileTypes()]).then(
              () => {
                // Start render cycle
                this.setLoading(false);
                this.render();
              }
            );
          } else {
            // Start render cycle
            this.setLoading(false);
            this.render();
          }
        },
        updateProfile(data, json) {
          if (this.loading) return;
          this.setLoading(true);

          return fetch(`${API_ROOT}/account/profile/${this.data.id}/`, {
            method: "PATCH",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              json ? { "Content-Type": "application/json" } : {},
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            body: data,
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => {
              this.setLoading(false);
              this.getProfile(true);
            });
        },
        getGenders() {
          return fetch(`${API_ROOT}/account/profile/genders/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => this.setProp("genders", response));
        },
        getProfileTypes() {
          return fetch(`${API_ROOT}/account/profile/types/`, {
            method: "GET",
            headers: Object.assign(
              { "Accept-Language": localStorage.getItem("language") },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            cors: "cors",
            credentials: "omit"
          })
            .then(response => response.json())
            .then(response => this.setProp("profileTypes", response));
        },
        updateEmail(newEmail) {
          return fetch(`${API_ROOT}/auth/users/set_email/`, {
            method: "POST",
            headers: Object.assign(
              {
                "Accept-Language": localStorage.getItem("language"),
                "Content-Type": "application/json"
              },
              localStorage.getItem("token")
                ? { Authorization: `Token ${localStorage.getItem("token")}` }
                : {}
            ),
            body: JSON.stringify({
              new_email: newEmail
            }),
            cors: "cors",
            credentials: "omit"
          }).then(response => this.getProfile(true));
        }
      };
    }

    if (
      window.hasOwnProperty("gjsProfile") &&
      window.hasOwnProperty("grapesjs")
    ) {
      window.gjsProfile.appendFuncToRenderCycle(handleData);
      window.gjsProfile.getProfile();
    }
  };

  editor.DomComponents.addType("profile-text", {
    isComponent: el => el.tagName === "SPAN" && el.className === `${className}`,
    model: {
      defaults: {
        name: "Profile Text",
        apiRoot: api.API_ROOT,
        script,
        traits: [
          {
            type: "text-with-description",
            name: "data-text-template",
            placeholder:
              "Current user is [first_name] [middle_name] [last_name]",
            description: `
            You can use the following fields in the template first_name, middle_name,
            last_name, mobile_number, site, profession, country, birthday, sex, contacts,
            co_name, co_slogan, co_description. Example "User is [first_name] [last_name]".
            `
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:data-text-template",
          this.render
        );
      }
    }
  });
}
