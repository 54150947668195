export default function popupComponent({ editor, api }) {
  const script = function() {
    function render(popupEl, delay, edit) {
      if (!window.hasOwnProperty("grapesjs")) {
        edit
          ? popupEl.classList.add("popup--active")
          : popupEl.classList.remove("popup--active");
        return;
      }

      setTimeout(() => popupEl.classList.add("popup--active"), delay);
    }

    const delay =
      (this.attributes.delay && this.attributes.delay.value) || 1000;
    const edit = this.attributes.hasOwnProperty("edit");

    render(this, delay, edit);
  };

  editor.DomComponents.addType("popup", {
    isComponent: el => el.tagName === "DIV" && el.className === "popup",
    model: {
      defaults: {
        name: "Pop-up",
        script,
        traits: [
          {
            type: "number",
            label: "Show after (ms)",
            name: "delay",
            placeholder: "1000"
          },
          {
            type: "checkbox",
            label: "Edit mode",
            name: "edit",
            value: true,
            valueTrue: true,
            valueFalse: false
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:delay", this.render);
        this.listenTo(this.model, "change:attributes:edit", this.render);
      }
    }
  });

  editor.DomComponents.addType("popup--close-button", {
    isComponent: el =>
      el.tagName === "BUTTON" && el.className === "popup__close-button",
    model: {
      defaults: {
        tagName: "BUTTON",
        name: "Pop-up close button",
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Close"]
          }
        ],
        script: function() {
          const getTranslate = (attributeName, attributes) => {
            if (!localStorage.hasOwnProperty("language")) {
              localStorage.setItem(
                "language",
                (navigator.language &&
                  navigator.language.slice(0, 2).toUpperCase()) ||
                  "EN"
              );
            }
            const language = localStorage.getItem("language");
            const translations = attributes[attributeName]
              ? JSON.parse(attributes[attributeName].value)
              : [];
            const fallback = translations.filter(
              item => item.language === "EN"
            );
            const languageTranslations = translations.filter(
              item => item.language === language
            );
            return fallback
              .reduce(
                (tmp, item) =>
                  tmp.concat(
                    languageTranslations.find(
                      obj => obj.string === item.string
                    ) || item
                  ),
                []
              )
              .reduce((tmp, item) => {
                const obj = {};
                obj[item.string] = item.translation;
                return Object.assign({}, tmp, obj);
              }, []);
          };
          // Get translation
          const translations = getTranslate("translations", this.attributes);
          // Get popup close buttons
          const popupCloseBtnEls = document.querySelectorAll(
            ".popup .popup__close-button"
          );
          // Change placeholder
          if (translations && Object.keys(translations).length) {
            Array.prototype.slice
              .call(popupCloseBtnEls)
              .map(
                popupCloseBtnEl =>
                  (popupCloseBtnEl.innerText = translations["Close"])
              );
          }
          if (!window.hasOwnProperty("grapesjs")) return;
          this.addEventListener("click", function() {
            this.closest(".popup").classList.remove("popup--active");
          });
        }
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
