import React, { useEffect } from "react";

import { connect } from "react-redux";

import {
  handleFacebookChange,
  handleLinkedinChange,
  handleTwitterChange,
  handleInstagramChange,
  handleYoutubeChange,
  handleRedditChange
} from "../../redux/profile/profile.action";

import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import RedditIcon from "@material-ui/icons/Reddit";

import "./profile-social-links.styls.scss";

const ProfileSocialLinks = ({
  handleFacebookChange,
  handleLinkedinChange,
  handleTwitterChange,
  handleInstagramChange,
  handleYoutubeChange,
  handleRedditChange,

  facebook,
  linkedin,
  twitter,
  instagram,
  youtube,
  reddit,

  profileAPI
}) => {
  useEffect(() => {
    if (profileAPI) {
      handleFacebookChange(profileAPI.metadata && profileAPI.metadata.facebook);
      handleLinkedinChange(profileAPI.metadata && profileAPI.metadata.linkedin);
      handleTwitterChange(profileAPI.metadata && profileAPI.metadata.twitter);
      handleInstagramChange(
        profileAPI.metadata && profileAPI.metadata.instagram
      );
      handleYoutubeChange(profileAPI.metadata && profileAPI.metadata.youtube);
      handleRedditChange(profileAPI.metadata && profileAPI.metadata.reddit);
    }
  }, [
    handleFacebookChange,
    handleLinkedinChange,
    handleTwitterChange,
    handleInstagramChange,
    handleYoutubeChange,
    handleRedditChange,
    profileAPI
  ]);

  return (
    <div className="profile-business-links">
      <h2>Social links</h2>
      <div className="profile-business-links__body">
        <div className="profile-business-links__container">
          <FacebookIcon className="profile-business-links__icon" />
          <span className="profile-business-links__separator" />
          <input
            onChange={e => handleFacebookChange(e.target.value)}
            value={facebook}
            className="profile-business-links__input"
            type="text"
          />
        </div>
        <div className="profile-business-links__container">
          <LinkedInIcon className="profile-business-links__icon" />
          <span className="profile-business-links__separator" />
          <input
            onChange={e => handleLinkedinChange(e.target.value)}
            value={linkedin}
            className="profile-business-links__input"
            type="text"
          />
        </div>
        <div className="profile-business-links__container">
          <TwitterIcon className="profile-business-links__icon" />
          <span className="profile-business-links__separator" />
          <input
            onChange={e => handleTwitterChange(e.target.value)}
            value={twitter}
            className="profile-business-links__input"
            type="text"
          />
        </div>
        <div className="profile-business-links__container">
          <InstagramIcon className="profile-business-links__icon" />
          <span className="profile-business-links__separator" />
          <input
            onChange={e => handleInstagramChange(e.target.value)}
            value={instagram}
            className="profile-business-links__input"
            type="text"
          />
        </div>
        <div className="profile-business-links__container">
          <YouTubeIcon className="profile-business-links__icon" />
          <span className="profile-business-links__separator" />
          <input
            onChange={e => handleYoutubeChange(e.target.value)}
            value={youtube}
            className="profile-business-links__input"
            type="text"
          />
        </div>
        <div className="profile-business-links__container">
          <RedditIcon className="profile-business-links__icon" />
          <span className="profile-business-links__separator" />
          <input
            onChange={e => handleRedditChange(e.target.value)}
            value={reddit}
            className="profile-business-links__input"
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  facebook: state.profile.facebook,
  linkedin: state.profile.linkedin,
  twitter: state.profile.twitter,
  instagram: state.profile.instagram,
  youtube: state.profile.youtube,
  reddit: state.profile.reddit,

  profileAPI: state.profileAPI.profile
});

export default connect(mapStateToProps, {
  handleFacebookChange,
  handleLinkedinChange,
  handleTwitterChange,
  handleInstagramChange,
  handleYoutubeChange,
  handleRedditChange
})(ProfileSocialLinks);
