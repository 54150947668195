import React from "react";
import ReactPaginate from "react-paginate";

import { useTranslation } from "react-i18next";

import "./pagination.styles.scss";

const Pagination = ({
  dataCount,
  itemsPerPage,
  selectedPage,
  initialPage,
  forcePage
}) => {
  const totalPages = Math.ceil(dataCount / itemsPerPage);

  const { t } = useTranslation();

  const onPageChange = data => {
    selectedPage(data.selected + 1);
  };

  return (
    <div className="pagination">
      <p>
        {t("Total pages")}: {totalPages}
      </p>
      <ReactPaginate
        initialPage={initialPage}
        pageCount={totalPages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={3}
        previousLabel={null}
        onPageChange={onPageChange}
        nextLabel={t("Forward")}
        forcePage={forcePage}
        pageLinkClassName="pagination__link"
        pageClassName="pagination__page"
        containerClassName="pagination__container"
        activeClassName="pagination__active"
        activeLinkClassName="pagination__active-link"
        nextClassName="pagination__next"
        breakClassName="pagination__page"
        breakLinkClassName="pagination__link"
      />
    </div>
  );
};

export default Pagination;
