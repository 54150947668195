import { WishListTypes } from "./wish-list.types";

const INITIAL_STATE = {
  isWishListPending: false,
  wishList: [],
  wishListError: {},

  isProductAddingToWishList: false,
  productAddedToWishList: {},
  productAddedToWishListError: {}
};

export const WishListReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case WishListTypes.GET.GET_WISH_LIST_PENDING:
      return {
        ...state,
        isWishListPending: true
      };
    case WishListTypes.GET.GET_WISH_LIST_SUCCESS:
      return {
        ...state,
        wishList: action.payload,
        isWishListPending: false
      };
    case WishListTypes.GET.GET_WISH_LIST_ERROR:
      return {
        ...state,
        wishListError: action.payload,
        isWishListPending: false
      };

    case WishListTypes.POST.ADD_PRODUCT_TO_WISH_LIST_PENDING:
      return {
        ...state,
        isProductAddingToWishList: true
      };
    case WishListTypes.POST.ADD_PRODUCT_TO_WISH_LIST_SUCCESS:
      return {
        ...state,
        productAddedToWishList: action.payload,
        isProductAddingToWishList: false
      };
    case WishListTypes.POST.ADD_PRODUCT_TO_WISH_LIST_ERROR:
      return {
        ...state,
        productAddedToWishListError: action.payload,
        isProductAddingToWishList: false
      };

    case WishListTypes.DELETE.DELETE_PRODUCT_FROM_WISH_LIST_PENDING:
      return {
        ...state,
        isProductAddingToWishList: true
      };
    case WishListTypes.DELETE.DELETE_PRODUCT_FROM_WISH_LIST_SUCCESS:
      return {
        ...state,
        productAddedToWishList: action.payload,
        wishList: state.wishList.filter(
          item => item.id !== action.payload.productId
        ),
        isProductAddingToWishList: false
      };
    case WishListTypes.DELETE.DELETE_PRODUCT_FROM_WISH_LIST_ERROR:
      return {
        ...state,
        productAddedToWishListError: action.payload,
        isProductAddingToWishList: false
      };

    default:
      return state;
  }
};

export default WishListReducer;
