import icon from "./icon.png";

export default function productsSortBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .${className} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: min-content;
  }

  .${className} .${className}__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
    width: auto;
    background: transparent;
    padding: 2px 4px;
    margin: 0 8px;
    border: 2px solid #e4e4e4;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    text-align: initial;
    white-space: nowrap;
  }

  .${className} .${className}__trigger {
    width: min-content;
  }

  .${className} .${className}__content {
    position: absolute;
    width: min-content;
    min-width: 240px;
    background: #ffffff;
    padding: 8px 10px;
    margin: 6px 0 0;
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
    transition: all 300ms ease;
    overflow-y: auto;
  }

  .${className} .${className}__caret {
    position: relative;
    width: 10px;
    color: black;
    padding: 0 2px;
  }

  .${className} .${className}__caret::before {
    content: "";
    position: absolute;
    margin: -2px 0;
    border-top: 7px solid currentColor;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .${className} .${className}__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .${className} .${className}__list-item {
    cursor: pointer;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="${className}">
    <div class="${className}__trigger-label">
      Sort:
    </div>
    <div class="${className}__trigger">
      <button type="button" class="${className}__button">
        <span class="${className}__value">
          Newest
        </span>
        <span class="${className}__caret"></span>
      </button>
    </div>

    <div class="${className}__content">
      <ul class="${className}__list">
        <li class="${className}__list-item" data-value="-id">
          Newest
        </li>
        <li class="${className}__list-item" data-value="id">
          Oldest
        </li>
        <li class="${className}__list-item" data-value="cost">
          Price ascending
        </li>
        <li class="${className}__list-item" data-value="-cost">
          Price descending
        </li>
      </ul>
    </div>
  </div>
  `;

  blockManager.add("products-sort", {
    label: `
      <img src="${icon}" alt="Products search block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Products Sort
      </div>
    `,
    content,
    category: "B24Online Products"
  });
}
