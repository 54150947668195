import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Editor from "../editor/editor.component";

import "./img-card.styles.scss";

import { ReactComponent as ClockIcon } from "../../assets/clock-icon.svg";

const ImgCard = ({
  navLink,
  type,
  price,
  imgSrc,
  shortDescription,
  text,
  currency,
  editorState,
  editLink,
  removeLink,
  removeFunc,
  editFunc,
  scaleDown,
  discountPercent,
  couponDiscountPercent
}) => {
  const [editor, setEditor] = useState(false);
  return (
    <div
      onMouseEnter={() => setEditor(true)}
      onMouseLeave={() => setEditor(false)}
      className={`img-card--${type}`}
    >
      <NavLink to={navLink}>
        <div className="imgcard-discounts">
          {discountPercent && (
            <div className="imgcard-discounts__discount-percent">{`${discountPercent}%`}</div>
          )}
          {couponDiscountPercent && (
            <div className="imgcard-discounts__coupon-discount-percent">
              {`${couponDiscountPercent}%`}
            </div>
          )}
        </div>
        <div className="img-card-image">
          <img
            src={imgSrc}
            alt="org-news"
            style={scaleDown && { objectFit: "scale-down" }}
          />
        </div>
      </NavLink>
      <NavLink to={navLink}>
        <div className="img-card-body">
          <div className="img-card-name">{shortDescription}</div>
          <div className="img-card-text">{text}</div>
          <div className="img-card-date">
            <ClockIcon />
            <p className="img-card-num-date">01.10.2019</p>
          </div>
          {price && <p className="img-card-price">{`${price} ${currency}`}</p>}
        </div>
      </NavLink>
      {editorState && (
        <div className="img-card__editor">
          {editor && (
            <Editor
              editLink={editLink}
              editFunc={editFunc}
              removeLink={removeLink}
              removeFunc={removeFunc}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImgCard;
