export const colorPalette = {
  colorPrimary: {
    main: "#d99a29",
    darker: "#c58b23",
    dangerRed: "#ff5b35"
  },
  colorSecondary: {
    main: "#f0d9b2"
  },
  grayBackground: "#fafafa",
  grayText: "#999999",
  facebook: "#3b5998",
  linkedin: "#0e76a8",
  twitter: "#1da1f2"
};
