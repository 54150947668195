import icon from "./icon.png";

export default function newsWithPaginationBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
    .news-with-pagination-wrapper {}

    .news-with-pagination-wrapper.news-with-pagination-wrapper--preloader {
      position: relative;
      transition: all 300ms ease;
    }

    .news-with-pagination-wrapper.news-with-pagination-wrapper--preloader::before {
      content: "";
      background: hsla(0, 0%, 100%, 0.45);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .news-with-pagination {
      display: flex;
      flex-flow: column wrap;
    }

    .nwp-news {
      width: 100%;
      display: flex;
      flex-flow: row;
      margin: 0 0 10px;
      border: 1px solid #aeaeae;
      border-radius: 3px;
    }

    @media screen and (max-width: 992px) {
      .nwp-news {
        flex-flow: row wrap;
        flex-basis: 100%;
      }

      .nwp-news__media {
        width: 100%;
        height: 180px;
      }

      .nwp-news__main {
        width: 85%;
      }
    }

    .nwp-news__media {
      height: 100%;
      width: 160px;
      object-fit: scale-down;
    }

    .nwp-news__main {
      display: flex;
      flex-flow: column;
      width: 85%;
      padding: 18px;
    }

    .nwp-news__title {
      color: #262626;
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 10px;
    }

    .nwp-news__link {
      color: inherit;
      text-decoration-color: currentColor;
    }

    .nwp-news__description {
      width: 100%;
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 20px;
    }

    .nwp-news__meta {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      margin: auto 0 0;
    }

    .nwp-news__meta-item {
      margin-right: 30px;
    }

    .nwp-news__organization {
      padding: 0 0 0 4px;
    }

    .nwp-news__date {
      padding: 0 0 0 4px;
    }

    .nwp-news__button {
      background-color: #aeaeae;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 12px 46px;
      border: none;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .nwp-news__button:not(.nwp-news__button--disabled):hover {
      background-color: #8c8c8c;
    }

    .nwp-news__button--disabled {
      background-color: #bfbfbf;
      color: #fbfbfb;
      cursor: not-allowed;
    }

    .nwp-pagination {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      align-self: flex-end;
      margin: 0 0 0 auto;
      padding: 20px;
    }

    .nwp-pagination__button {
      background: #fff;
      color: #aeaeae;
      font-size: 16px;
      font-weight: 800;
      padding: 8px 16px;
      margin: 0 4px;
      border: 2px solid #aeaeae;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      transition: all 300ms ease;
    }

    .nwp-pagination__button.nwp-pagination__button--active {
      color: #fff;
      background: #c0c0c0;
    }

    .nwp-pagination__button--disabled, .nwp-pagination__button:hover {
      background: #ededed;
    }
  </style>
  `;

  const content = `
  ${style}
  <div class="news-with-pagination-wrapper">
    <div class="news-with-pagination">
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-news">
        <img class="nwp-news__media"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==">
        <div class="nwp-news__main">
          <h4 class="nwp-news__title">
            <a class="nwp-news__link" href="#">
              Lorem ipsum dolor sit amet
            </a>
          </h4>
          <div class="nwp-news__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut varius, enim sed rutrum dictum, metus tortor
            ornare ante, ut tincidunt dolor arcu auctor tellus. Praesent blandit nisl eget quam placerat lacinia.
          </div>
          <div class="nwp-news__meta">
            <div class="nwp-news__meta-item">
              <span class="nwp-news__organization">
                Organization
              </span>
            </div>
            <div class="nwp-news__meta-item">
              <span class="nwp-news__date">
                01/01/2020
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nwp-pagination"> <button class="nwp-pagination__button nwp-pagination__button--disabled" disabled="">
          &lt;
        </button>
        <button class="nwp-pagination__button nwp-pagination__button--active" data-page="1">
          1
        </button>
        <button class="nwp-pagination__button" data-page="2">
          2
        </button>
        <span class="nwp-pagination__separator">...</span>
        <button class="nwp-pagination__button" data-page="149">
          149
        </button>
        <button class="nwp-pagination__button" data-page="2">
          &gt;
        </button>
      </div>
    </div>
  </div>
  `;

  blockManager.add("news-with-pagination", {
    label: `
      <img src="${icon}" alt="News with pagination block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        News With Pagination
      </div>
    `,
    content,
    category: "B24Online News"
  });
}
