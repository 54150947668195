export default function cartSubmitBlock({ editor }) {
  const blockManager = editor.BlockManager;

  const style = `
    <style>
      .cart-submit {
        background-color: #aeaeae;
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        padding: 12px 46px;
        border: none;
        border-radius: 3px;
        outline: none;
        cursor: pointer;
        transition: all 300ms ease;
      }
  
      .cart-submit:not(:disabled):hover {
        background-color: #8c8c8c;
      }
  
      .cart-submit:disabled {
        background-color: #bfbfbf !important;
        color: #fbfbfb;
        cursor: not-allowed;
      }
    </style>
  `;

  const content = `
    ${style}
    <button class="cart-submit" type="button">
      CONTINUE SECURELY
    </button>
  `;

  blockManager.add("cart-submit", {
    label: `
        <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
          <rect class="gjs-block-svg-path" x="4" y="11.5" width="16" height="1"></rect>
        </svg>
        <div class="gjs-block-label" style="margin-top: 5px">
          Cart Submit
        </div>
      `,
    content,
    category: "B24Online Cart"
  });
}
