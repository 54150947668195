export default function authLoginSubmitComponent({ editor, api }) {
  const script = function() {
    const API_ROOT = "{[ apiRoot ]}";

    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function render(translations, redirect, API_ROOT) {
      const buttonEl = document.querySelector(".login-submit");
      const getFormEl = function() {
        return document.querySelector(".login-form");
      };
      const getMessagesWrapperEl = function() {
        return document.querySelector(".login-form__messages");
      };

      function submit(e) {
        e.preventDefault();
        const form = document.forms.namedItem(e.target.name);
        const data = new FormData(form);

        fetch(`${API_ROOT}/auth/token/login/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: data.get("email"),
            password: data.get("password")
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("LOGIN_FAILED");
            }

            response.json().then(json => {
              window.localStorage.setItem("token", json.auth_token);

              window.location.assign(redirect);
            });
          })
          .catch(error => {
            getMessagesWrapperEl().innerHTML = `
              <span class="login-form__message">
                ${translations["Unable to log in with provided credentials."]}
              </span>
            `;
          });
      }

      buttonEl.addEventListener("click", e => {
        e.preventDefault();

        getFormEl().removeEventListener("submit", submit);
        getFormEl().addEventListener("submit", submit);

        const form = document.forms.namedItem(getFormEl().name);
        if (form.__proto__.hasOwnProperty("requestSubmit")) {
          form.requestSubmit();
        } else if (form.checkValidity()) {
          form.submit();
        }
      });
    }
    // Get translation & render
    const translations = getTranslate("translations", this.attributes);
    if (translations && Object.keys(translations).length) {
      // Get button element
      const buttonEl = document.querySelector(".login-submit");
      // Change button element text
      buttonEl.textContent = translations["SUBMIT"];

      // Get error element
      const errorEl = document.querySelector(".login-form__message");
      // Change button element text
      if (errorEl) {
        errorEl.textContent =
          translations["Unable to log in with provided credentials."];
      }

      // Get email input element
      const emailInputEl = document.querySelector(
        ".login-form__input[type=email]"
      );
      // Change email input placeholder
      emailInputEl.setAttribute("placeholder", translations["E-mail"]);

      // Get password input element
      const passwordInputEl = document.querySelector(
        ".login-form__input[type=password]"
      );
      // Change password input placeholder
      passwordInputEl.setAttribute("placeholder", translations["Password"]);

      // Render
      if (window.hasOwnProperty("grapesjs")) {
        render(
          translations,
          (this.attributes.redirect && this.attributes.redirect.value) || "#",
          API_ROOT
        );
      }
    }
  };

  editor.DomComponents.addType("auth-login-submit", {
    isComponent: el =>
      el.tagName === "BUTTON" && el.className === "login-submit",
    model: {
      defaults: {
        name: "Login Submit",
        apiRoot: api.API_ROOT,
        script,
        traits: [
          {
            type: "page-selector",
            label: "Redirect",
            name: "redirect",
            modalTitle: "Select redirect page after successful login"
          },
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "E-mail",
              "Password",
              "Unable to log in with provided credentials.",
              "SUBMIT"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(this.model, "change:attributes:redirect", this.render);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
