export default function productsProducersComponent({ editor, api, site }) {
  const script = function() {
    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };
    // Get translation
    const translations = getTranslate("translations", this.attributes);
    // Get search input
    const searchEl = document.querySelector(".products-producers__search");
    // Change placeholder
    if (translations && Object.keys(translations).length) {
      searchEl.setAttribute("placeholder", translations["Search..."]);
    }

    function setLoading(state) {
      const wrapperEl = document.querySelector(".products-producers");
      if (!wrapperEl) return;

      if (state) {
        wrapperEl.classList.add("products-producers--preloader");
      } else {
        wrapperEl.classList.remove("products-producers--preloader");
      }
    }

    function render(
      type,
      total,
      page,
      perPage,
      products,
      categories,
      producers,
      detailsPath,
      loading
    ) {
      const el = this.querySelector(".products-producers__list");
      setLoading(loading);

      el.innerHTML = producers.reduce(
        (tmp, producer) =>
          tmp +
          `
            <li
              class="products-producers__item ${
                producer.selected ? " products-producers__item--active" : ""
              }"
              data-id="${producer.id}"
            >
              ${producer.title}
            </li>
          `,
        ""
      );

      const producersEls = document.querySelectorAll(
        ".products-producers__item"
      );

      Array.prototype.slice.call(producersEls).map(producersEl =>
        producersEl.addEventListener("click", e => {
          e.preventDefault();
          const isPressed = e.target.classList.contains(
            "products-producers__item--active"
          );
          const producerId = parseInt(e.target.dataset.id);

          const mapSelectedProducers = state => producer =>
            producer.id === producerId
              ? Object.assign({}, producer, { selected: state })
              : producer;

          if (isPressed) {
            e.target.classList.remove("products-producers__item--active");
            window.products.setProducers(
              window.products.producers.map(mapSelectedProducers(false))
            );
          } else {
            e.target.classList.add("products-producers__item--active");
            window.products.setProducers(
              window.products.producers.map(mapSelectedProducers(true))
            );
          }
        })
      );
    }

    const rand = Math.random()
      .toString()
      .slice(-8);
    const intervalName = `waitForProductsInterval${rand}`;
    window[intervalName] = setInterval(() => {
      if (
        window.hasOwnProperty("products") &&
        window.hasOwnProperty("grapesjs")
      ) {
        window.products.getProducers("", true);
        window.products.appendFuncToRenderCycle(render.bind(this));
        clearInterval(window[intervalName]);
      }
    }, 100);

    this.closest(".products-producers-wrapper")
      .querySelector(".products-producers__search")
      .addEventListener("change", function(e) {
        if (
          window.hasOwnProperty("products") &&
          window.hasOwnProperty("grapesjs")
        ) {
          window.products.getProducers(e.target.value);
        }
      });
  };

  editor.DomComponents.addType("products-producers", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-producers-wrapper",
    model: {
      defaults: {
        name: "Products Producers",
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: ["Search..."]
          }
        ],
        script
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });

  editor.DomComponents.addType("products-producers__input", {
    isComponent: el =>
      el.tagName === "INPUT" && el.className === "products-producers__search",
    model: {
      defaults: {
        name: "Products Producers Input",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-producers__grid", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "products-producers",
    model: {
      defaults: {
        name: "Products Producers Lists Wrapper",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });

  editor.DomComponents.addType("products-producers__list", {
    isComponent: el =>
      el.tagName === "UL" && el.className === "products-producers__list",
    model: {
      defaults: {
        name: "Products Producers List",
        removable: false,
        draggable: false,
        copyable: false
      }
    }
  });
}
