import React, { Fragment } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";

import { ParentWrapper, ButtonsFooterWrapper } from "../../layout";

import { Separator, InputGroup, DefaultButton } from "../../components";

const ProductAdditionalParameter = ({
  additionalParameters,
  deleteAdditionalParameter,
  setParameterTitle,
  parameterTitle,
  parameterDescription,
  setParameterDescription,
  setParameterCost,
  parameterCost,
  uploadAdditionalParameter,
  productId
}) => {
  return (
    <Fragment>
      <Separator />
      <h3 style={{ marginTop: 0 }}>ADDITIONAL PARAMETER</h3>
      <Separator />
      <div className="my-business-products-edit__additional-parameters">
        {additionalParameters && additionalParameters.length ? (
          <Table aria-label="items table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Cost</TableCell>
                <TableCell align="center">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalParameters &&
                additionalParameters.map(parameter => (
                  <TableRow key={parameter.id}>
                    <TableCell align="center">
                      <div className="table-div-content">
                        {parameter.title.length > 40
                          ? parameter.title.substring(0, 40) + "..."
                          : parameter.title}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-div-content">
                        {parameter.description.length > 40
                          ? parameter.description.substring(0, 40) + "..."
                          : parameter.description}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-div-content">
                        {parameter.extra_cost}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <DefaultButton
                        onClick={() => deleteAdditionalParameter(parameter)}
                      >
                        <ClearIcon />
                      </DefaultButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <h3>No parameters...</h3>
        )}
      </div>
      <Separator />
      <ParentWrapper>
        <InputGroup
          onChange={e => setParameterTitle(e.target.value)}
          value={parameterTitle}
          label="TITLE"
          name="parameterTitle"
          placeholder="Title"
        />
        <InputGroup
          onChange={e => setParameterDescription(e.target.value)}
          value={parameterDescription}
          label="DESCRIPTION"
          name="nameParameter"
          placeholder="Description"
        />
        <InputGroup
          onChange={e => setParameterCost(e.target.value)}
          value={parameterCost}
          label="COST"
          name="costParameter"
          placeholder="Cost"
        />
      </ParentWrapper>

      <ButtonsFooterWrapper>
        <div style={{ width: "50%" }}>
          <DefaultButton
            onClick={() =>
              uploadAdditionalParameter(
                parameterTitle,
                parameterDescription,
                parameterCost,
                productId
              )
            }
            color="gray"
            width="100%"
            border="1px solid lightgray"
            borderRadius={0}
            disabled={
              parameterTitle === "" ||
              parameterDescription === "" ||
              parameterCost === ""
            }
          >
            Add parameter
          </DefaultButton>
        </div>
      </ButtonsFooterWrapper>
    </Fragment>
  );
};

export default ProductAdditionalParameter;
