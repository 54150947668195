import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

import { DefaultButton } from "../../components";

import { colorPalette } from "../../styles/colorPalette";
import "./confirm-dialog.styles.scss";

const ConfirmDialog = ({
  dialogTitle,
  dialogText,
  dialogOpen,
  dialogCloseHandler,
  deleteItemHandler
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={dialogCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DefaultButton
          color="#ccc"
          hoverColor={colorPalette.colorPrimary.main}
          fontWeight="700"
          border="1px solid #ccc"
          hoverBorder={`1px solid ${colorPalette.colorPrimary.main}`}
          hover="white"
          padding="4px 19px"
          transition="all 300ms ease"
          onClick={dialogCloseHandler}
        >
          Cancel
        </DefaultButton>
        <DefaultButton
          autoFocus
          color="#ccc"
          hoverColor="#f24941"
          fontWeight="700"
          border="1px solid #ccc"
          hoverBorder="1px solid #f24941"
          hover="white"
          padding="4px 19px"
          transition="all 300ms ease"
          onClick={deleteItemHandler}
        >
          Delete
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
