export const BranchesActionTypes = {
  GET: {
    GET_BRANCHES_PENDING: "GET_BRANCHES_PENDING",
    GET_BRANCHES_SUCCESS: "GET_BRANCHES_SUCCESS",
    GET_BRANCHES_ERROR: "GET_BRANCHES_ERROR"
  },
  CREATE_BRANCH: "CREATE_BRANCH",
  UPDATE_BRANCH: "UPDATE_BRANCH",
  DELETE_BRANCH: "DELETE_BRANCH"
};
