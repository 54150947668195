import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";

const DefaultInput = withStyles(() => ({
  underline: {
    "&:before": {
      borderColor: "white"
    },
    "&:after": {
      borderColor: "white"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "white"
    }
  },
  input: {
    color: "white"
  }
}))(Input);

export default DefaultInput;
