import React from "react";

import "./media-actions.styles.scss";

const MediaActions = ({
  image,
  alt,
  height,
  objectFit,
  bordered,
  title,
  buttons,
  style
}) => {
  return (
    <div className="media-actions" style={style}>
      <div
        className="media-actions__image-wrapper"
        style={{
          borderRadius: bordered ? "50%" : "none",
          height: height || 100
        }}
      >
        <img
          className="media-actions__image"
          src={image}
          alt={alt}
          style={{
            objectFit: objectFit || "scale-down"
          }}
        />
      </div>
      {title}
      {buttons.map((button, index) => (
        <div className="media-actions__btn" key={index}>
          {button}
        </div>
      ))}
    </div>
  );
};

export default MediaActions;
