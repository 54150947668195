import { EventInvitationsTypes } from "./event-invitations.types";

const INITIAL_STATE = {
  isAddingMeToEventPending: false,
  addedMeToEvent: {},
  addedMeToEventError: {},

  isUserCheckingPending: false,
  userEventGoingCheckStatus: {},
  userEventGoingCheckStatusError: {},

  isCreateMeetingInvitationsPending: false,
  createMeetingInvitations: {},
  createMeetingInvitationsError: {},

  areMeetingInvitationsByEventPending: false,
  meetingInvitationsByEvent: {},
  meetingInvitationsByEventError: {},

  areMeetingInvitationsByMe: false,
  meetingInvitationsByMe: [],
  meetingInivtationsByMeCount: 0,
  meetingInvitationsByMeError: {},

  areSearchUsersEventVisitorsPending: false,
  searchUsersEventVisitors: {},
  searchUsersEventVisitorsError: {},

  meetingRequestStatus: ""
};

const EventInvitationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EventInvitationsTypes.PUT.ADD_ME_TO_EVENT_AS_VISITOR_PENDING:
      return {
        ...state,
        isAddingMeToEventPending: true
      };

    case EventInvitationsTypes.PUT.ADD_ME_TO_EVENT_AS_VISITOR_SUCCESS:
      return {
        ...state,
        isAddingMeToEventPending: false,
        addedMeToEvent: action.payload
      };

    case EventInvitationsTypes.PUT.ADD_ME_TO_EVENT_AS_VISITOR_ERROR:
      return {
        ...state,
        isAddingMeToEventPending: false,
        addedMeToEventError: action.payload
      };

    case EventInvitationsTypes.GET.CHECK_IF_USER_IS_GOING_TO_EVENT_PENDING:
      return {
        ...state,
        isUserCheckingPending: true
      };

    case EventInvitationsTypes.GET.CHECK_IF_USER_IS_GOING_TO_EVENT_SUCCESS:
      return {
        ...state,
        userEventGoingCheckStatus: action.payload,
        isUserCheckingPending: false
      };

    case EventInvitationsTypes.GET.CHECK_IF_USER_IS_GOING_TO_EVENT_ERROR:
      return {
        ...state,
        userEventGoingCheckStatusError: action.payload,
        isUserCheckingPending: false
      };

    case EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_BY_EVENT_PENDING:
      return {
        ...state,
        areMeetingInvitationsByEventPending: true
      };

    case EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_BY_EVENT_SUCCESS:
      return {
        ...state,
        meetingInvitationsByEvent: action.payload,
        areMeetingInvitationsByEventPending: false
      };

    case EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_BY_EVENT_ERROR:
      return {
        ...state,
        meetingInvitationsByEventError: action.payload,
        areMeetingInvitationsByEventPending: false
      };

    case EventInvitationsTypes.POST.CREATE_MEETING_INVITATION_WITH_USER_PENDING:
      return {
        ...state,
        isCreateMeetingInvitationsPending: true
      };

    case EventInvitationsTypes.POST.CREATE_MEETING_INVITATION_WITH_USER_SUCCESS:
      return {
        ...state,
        createMeetingInvitations: action.payload,
        meetingRequestStatus: action.payload.requestStatus,
        isCreateMeetingInvitationsPending: false
      };

    case EventInvitationsTypes.POST.CREATE_MEETING_INVITATION_WITH_USER_ERROR:
      return {
        ...state,
        createMeetingInvitationsError: action.payload,
        isCreateMeetingInvitationsPending: false
      };

    case EventInvitationsTypes.GET
      .GET_MEETING_INVITATIONS_RELATED_TO_ME_PENDING:
      return {
        ...state,
        areMeetingInvitationsByMe: true
      };

    case EventInvitationsTypes.GET
      .GET_MEETING_INVITATIONS_RELATED_TO_ME_SUCCESS:
      return {
        ...state,
        meetingInvitationsByMe: action.payload.meetings,
        meetingInvitationsByMeCount: action.payload.count,
        areMeetingInvitationsByMe: false
      };

    case EventInvitationsTypes.GET.GET_MEETING_INVITATIONS_RELATED_TO_ME_ERROR:
      return {
        ...state,
        meetingInvitationsByMeError: action.payload,
        areMeetingInvitationsByMe: false
      };

    case EventInvitationsTypes.GET.SEARCH_VISITORS_BY_EMAIL_PENDING:
      return {
        ...state,
        areSearchUsersEventVisitorsPending: true
      };

    case EventInvitationsTypes.GET.SEARCH_VISITORS_BY_EMAIL_SUCCESS:
      return {
        ...state,
        searchUsersEventVisitors: action.payload,
        areSearchUsersEventVisitorsPending: false
      };

    case EventInvitationsTypes.GET.SEARCH_VISITORS_BY_EMAIL_ERROR:
      return {
        ...state,
        searchUsersEventVisitorsError: action.payload,
        areSearchUsersEventVisitorsPending: false
      };

    case EventInvitationsTypes.CLEAR_MEETING_REQUEST_STATUS:
      return {
        ...state,
        meetingRequestStatus: action.payload
      };

    default:
      return state;
  }
};

export default EventInvitationsReducer;
