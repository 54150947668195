import { axiosNoTokenInstance } from "../../../axios/axios.config";
import { PortalRecomendationsTypes } from "./portal-recomendations.types";

export const getPortalRecomendationsSuccess = () => dispatch => {
  axiosNoTokenInstance
    .get(`/api/v1/recommendation/portal/`)
    .then(recommendations => {
      dispatch({
        type: PortalRecomendationsTypes.GET.GET_PORTAL_RECOMENDATIONS_SUCCESS,
        payload: recommendations.data
      });
    })
    .catch(err => {
      dispatch({
        type: PortalRecomendationsTypes.GET.GET_PORTAL_RECOMENDATIONS_ERROR,
        payload: err
      });
    });
};

export const getPortalRecomendations = () => dispatch => {
  dispatch({
    type: PortalRecomendationsTypes.GET.GET_PORTAL_RECOMENDATIONS_PENDING
  });
  dispatch(getPortalRecomendationsSuccess());
};
