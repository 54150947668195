import React from "react";
import { ResetPasswordCard } from "@core/components";

import "./reset-password.styles.scss";

const ResetPassword = () => {
  return (
    <div className="reset-password-page">
      <ResetPasswordCard />
    </div>
  );
};

export default ResetPassword;
