import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "./styles.modules.scss";

import { createOrderPelecard } from "../../../redux/actions-exporter";

import { b24URL } from "../../../constants";
import { colorPalette } from "../../../styles/colorPalette";
import { DefaultButton } from "../../index";

const Index = ({ createOrderPelecard, company, pelecardData }) => {
  const [disabled, setDisabled] = useState(false);

  const createOrder = () => {
    setDisabled(true);
    createOrderPelecard(
      company,
      `${b24URL}/order_check`,
      `${b24URL}/cart/order/error`
    );
  };

  useEffect(() => {
    if (!pelecardData?.url) {
      setDisabled(false);
    }
  }, [pelecardData]);

  return (
    <div className="pelecard__btn-container">
      <DefaultButton
        border={`1px solid ${colorPalette.colorPrimary.main}`}
        color={colorPalette.colorPrimary.main}
        width="100%"
        disabled={disabled}
        onClick={() => createOrder()}
      >
        Create order
      </DefaultButton>
    </div>
  );
};

const mapStateToProps = state => ({
  pelecardData: state.cart.pelecardData
});

export default connect(mapStateToProps, {
  createOrderPelecard
})(Index);
