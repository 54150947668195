export default function contactFormComponent({ editor, site, api }) {
  function script() {
    const API_ROOT = "{[ apiRoot ]}";

    const getTranslate = (attributeName, attributes) => {
      if (!localStorage.hasOwnProperty("language")) {
        localStorage.setItem(
          "language",
          (navigator.language &&
            navigator.language.slice(0, 2).toUpperCase()) ||
            "EN"
        );
      }
      const language = localStorage.getItem("language");
      const translations = attributes[attributeName]
        ? JSON.parse(attributes[attributeName].value)
        : [];
      const fallback = translations.filter(item => item.language === "EN");
      const languageTranslations = translations.filter(
        item => item.language === language
      );
      return fallback
        .reduce(
          (tmp, item) =>
            tmp.concat(
              languageTranslations.find(obj => obj.string === item.string) ||
                item
            ),
          []
        )
        .reduce((tmp, item) => {
          const obj = {};
          obj[item.string] = item.translation;
          return Object.assign({}, tmp, obj);
        }, []);
    };

    function submit(e, formEl, translations, setMessage, API_ROOT) {
      e.preventDefault();
      const organization = "{[ organization ]}";
      const data = new FormData(formEl);

      try {
        if (!data.has("name")) {
          throw new Error(translations["Please enter a valid name."]);
        }

        if (!data.has("email")) {
          throw new Error(translations["Please enter a valid email."]);
        }

        if (!data.has("phone")) {
          throw new Error(translations["Please enter a valid phone."]);
        }

        if (!data.has("message")) {
          throw new Error(translations["Please enter a valid message."]);
        }

        const contactData = {
          realname: data.get("name"),
          email: data.get("email"),
          phone: data.get("phone"),
          message: data.get("message"),
          organization: organization
        };

        if (
          window.hasOwnProperty("gjs_contact_message_sended") &&
          JSON.stringify(contactData) ===
            JSON.stringify(window.gjs_contact_message_sended || {})
        ) {
          return;
        }

        fetch(`${API_ROOT}/leads/leads/`, {
          method: "POST",
          headers: Object.assign(
            {
              Accept: "application/json",
              "Accept-Language": localStorage.getItem("language"),
              "Content-Type": "application/json",
            },
            localStorage.getItem("token")
              ? {Authorization: `Token ${localStorage.getItem("token")}`}
              : {}
          ),
          cors: "cors",
          credentials: "omit",
          body: JSON.stringify(contactData)
        }).then(response => {
          if (response.status === 201) {
            setMessage(
              translations["Your message was send successfully. Thanks!"],
              "success"
            );
            window.gjs_contact_message_sended = contactData;
          } else {
            setMessage(
              translations["Something went wrong try again later."],
              "error"
            );
          }
        });
      } catch (error) {
        setMessage(error.message, "validation");
      }
    }

    function render(translations, API_ROOT) {
      const buttonEl = this.querySelector(".contact-form__submit");
      const formEl = this.querySelector(".contact-form");
      const messagesWrapperEl = this.querySelector(".contact-form__messages");

      const setMessageWithStatus = (messageEL, className, message, status) => {
        messageEL.innerHTML = `
          <span class="${className} ${className}--${status}">
            ${message}
          </span>
        `;
      };

      const addSubmitHandler = e => {
        e.preventDefault();

        const setMessage = (message, status) =>
          setMessageWithStatus(
            messagesWrapperEl,
            "contact-form__message",
            message,
            status
          );

        const sumbitHandler = e =>
          submit(e, formEl, translations, setMessage, API_ROOT);
        formEl.onsubmit = sumbitHandler;

        if (formEl.__proto__.hasOwnProperty("requestSubmit")) {
          formEl.requestSubmit();
        } else if (formEl.checkValidity()) {
          formEl.submit();
        }
      };

      buttonEl.onclick = addSubmitHandler;
    }
    // Get translation & render
    const translations = getTranslate("translations", this.attributes);
    if (translations && Object.keys(translations).length) {
      // Get button element
      const buttonEl = this.querySelector(".contact-form__submit");
      // Change text of button element
      buttonEl.textContent = translations["SEND"];

      // Get error message element
      const errorMessageEl = this.querySelector(
        ".contact-form__message--error"
      );
      // Change text of error validation element
      if (errorMessageEl) {
        errorMessageEl.textContent =
          translations["Something went wrong try again later."];
      }

      // Get validation message element
      const validationMessageEl = this.querySelector(
        ".contact-form__message--validation"
      );
      // Change text of success validation element
      if (validationMessageEl) {
        validationMessageEl.textContent =
          translations["Please enter a valid name."];
      }

      // Get success message element
      const successMessageEl = this.querySelector(
        ".contact-form__message--success"
      );
      // Change text of success message element
      if (successMessageEl) {
        successMessageEl.textContent =
          translations["Your message was send successfully. Thanks!"];
      }

      // Get name input element
      const nameInputEl = this.querySelector(".contact-form__input--name");
      // Change name input placeholder
      nameInputEl.setAttribute("placeholder", translations["Your name"]);

      // Get email input element
      const emailInputEl = this.querySelector(".contact-form__input--email");
      // Change email input placeholder
      emailInputEl.setAttribute("placeholder", translations["E-mail"]);

      // Get phone input element
      const phoneInputEl = this.querySelector(".contact-form__input--phone");
      // Change phone input placeholder
      phoneInputEl.setAttribute("placeholder", translations["Phone"]);

      // Get message input element
      const messageInputEl = this.querySelector(
        ".contact-form__input--textarea"
      );
      // Change message input placeholder
      messageInputEl.setAttribute("placeholder", translations["Your message"]);

      // Render
      if (window.hasOwnProperty("grapesjs")) {
        // Hide messages
        Array.prototype.slice
          .call(this.querySelectorAll(".contact-form__message"))
          .map(el => (el.innerHTML = ""));
        // Render
        render.call(this, translations, API_ROOT);
      }
    }
  }

  editor.DomComponents.addType("contact-form", {
    isComponent: el =>
      el.tagName === "DIV" && el.className === "contact-form-wrapper",
    model: {
      defaults: {
        name: "Contact Form",
        apiRoot: api.API_ROOT,
        organization: site && site.organization,
        script,
        traits: [
          {
            type: "translation-manager",
            label: "Translations",
            name: "translations",
            modalTitle: "Translations",
            strings: [
              "Your name",
              "E-mail",
              "Phone",
              "Your message",
              "Please enter a valid name.",
              "Please enter a valid email.",
              "Please enter a valid phone.",
              "Please enter a valid message.",
              "Something went wrong try again later.",
              "Your message was send successfully. Thanks!",
              "SEND"
            ]
          }
        ]
      }
    },
    view: {
      init() {
        editor.select(this.model.components().parent);
        this.listenTo(
          this.model,
          "change:attributes:translations",
          this.render
        );
      }
    }
  });
}
