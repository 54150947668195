export const CountriesActionTypes = {
  COUNTRIES: {
    GET_COUNTRIES_PENDING: "GET_COUNTRIES_PENDING",
    GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
    GET_COUNTRIES_ERROR: "GET_COUNTRIES_ERROR"
  },
  GET_COUNTRY: {
    GET_COUNTRY_PENDING: "GET_COUNTRY_PENDING",
    GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
    GET_COUNTRY_ERROR: "GET_COUNTRY_ERROR"
  },
  GET_COUNTRY_ID: {
    GET_COUNTRY_ID_PENDING: "GET_COUNTRY_ID_PENDING",
    GET_COUNTRY_ID_SUCCESS: "GET_COUNTRY_ID_SUCCESS",
    GET_COUNTRY_ID_ERROR: "GET_COUNTRY_ID_ERROR"
  },
  GET: {
    GET_LOCATION_COUNTRY_PENDING: "GET_LOCATION_COUNTRY_PENDING",
    GET_LOCATION_COUNTRY_SUCCESS: "GET_LOCATION_COUNTRY_SUCCESS",
    GET_LOCATION_COUNTRY_ERROR: "GET_LOCATION_COUNTRY_ERROR"
  }
};
