import "./gjs-theme-manager.scss";
import ApiService from "../../../services/api";

export default class gjsThemeManager {
  state = {
    site: {},
    loading: false
  };

  constructor({ token, site, editor }) {
    this.editor = editor;
    this.api = new ApiService({ token });
    this.state = { ...this.state, site };
    this.modal = this.editor.Modal;

    editor.on("load", this.addCommands);
  }

  addCommands = () => {
    const { editor, render } = this;

    editor.Panels.addButton("options", [
      {
        id: "theme-manager-button",
        className: "fa fa-sticky-note gjs-theme-manager-button",
        attributes: {
          title: "Theme Manager"
        },
        command: () =>
          render({
            type: "themes-list",
            options: {
              page: 1
            }
          })
      }
    ]);
  };

  setPreloader = ({ state = false } = {}) => {
    this.state = { ...this.state, loading: state };
    // Get grapes canvas element
    const canvasEl = document.querySelector(".gjs-cv-canvas");
    // Add or remove preloader
    state
      ? canvasEl.classList.add("preloader")
      : canvasEl.classList.remove("preloader");
  };

  setModalPreloader = ({ state = false } = {}) => {
    // Get modal element
    const modalContentEl = this.modal.getContentEl();
    const modalEl =
      modalContentEl && modalContentEl.closest(".gjs-mdl-content");
    if (!modalEl) return;
    // Add or remove preloader
    state
      ? modalEl.classList.add("gjs-mdl-content--preloader")
      : modalEl.classList.remove("gjs-mdl-content--preloader");
  };

  getPages = async siteId => {
    const { count } = await this.api.getPages({ siteId, page: 1 });
    const pagesCount = Math.ceil(count / 10);

    return Promise.all(
      Array(pagesCount)
        .fill()
        .map((i, page) =>
          this.api.getPages({
            siteId,
            page: page + 1,
            lite: false
          })
        )
    ).then(pages => pages.reduce((tmp, page) => [...tmp, ...page.results], []));
  };

  createTheme = async ({ pages, themeName }) => {
    const theme = await this.api.createTemplateCollection({
      title: themeName,
      tags: [themeName],
      categories: [themeName]
    });

    return Promise.all(
      pages.map(page =>
        this.api.createTemplate({
          themeId: theme.id,
          title: page.title,
          path: page.path,
          gjs: page
        })
      )
    );
  };

  saveProcess = async () => {
    this.setModalPreloader({ state: true });

    const { site } = this.state;
    const pages = await this.getPages(site.id);
    const themeName = `[${site.id}] ${site.title}`;
    const themeSearchResults = await this.api.getTemplateCollection({
      search: themeName
    });

    const isThemeExists = !!themeSearchResults.results.length;
    if (isThemeExists) {
      await this.api.deleteTemplateCollection({
        id: themeSearchResults.results[0].id
      });
    }

    await this.createTheme({ pages, themeName });

    this.render({
      type: "themes-list",
      options: {
        page: 1
      }
    });
  };

  getThemes = async ({ page = 1 }) => {
    const { results, count } = await this.api.getTemplateCollection({ page });
    const perPage = 10;
    const pagesCount = Math.ceil(count / perPage);

    const themes = await Promise.all(
      results.map(async theme => {
        const sitemap = await this.api.getSitemap({ themeId: theme.id });

        return {
          ...theme,
          templates: sitemap.map(i => ({
            ...i.template,
            path: i.path,
            sitemapId: i.id
          }))
        };
      })
    );

    return {
      pages: {
        current: page,
        size: perPage,
        total: pagesCount
      },
      themes
    };
  };

  render = async ({ type, options = {} }) => {
    this.modal.close();
    this.setModalPreloader({ state: true });

    switch (type) {
      case "themes-list":
        const { page } = options;
        this.modal.setTitle(`Theme Manager | Themes List`);
        this.modal.open();
        const { pages, themes } = await this.getThemes({ page });
        this.modal.setContent(this.makeThemesListHTML({ pages, themes }));
        this.themesListScripts({ themes });
        break;

      case "theme-edit":
        this.modal.setTitle(`Theme Manager | Theme Edit`);
        this.modal.open();
        this.modal.setContent(this.makeThemeEditHTML(options));
        this.themeEditScripts(options);
        break;

      case "template-edit":
        this.modal.setTitle(`Theme Manager | Template Edit`);
        this.modal.open();
        this.modal.setContent(this.makeTemplateEditHTML(options));
        this.templateEditScripts(options);
        break;

      default:
        break;
    }

    this.setModalPreloader({ state: false });
  };

  makeThemesListHTML = ({ pages, themes }) => {
    return `
    <div class="gjs-theme-manager">
      <div class="gjs-theme-manager__header gjs-theme-manager__header--between">
        <h4 class="gjs-theme-manager__title">
          List of themes ${pages.current}/${pages.total}
        </h4>

        <span class="gjs-theme-manager__actions">
          <button
            type="button"
            class="
              gjs-theme-manager__button
              gjs-theme-manager__button--space
              gjs-theme-manager__button--add-theme
              gjs-two-color
            "
          >
            + Add Theme
          </button>
          <button
            type="button"
            class="
              gjs-theme-manager__button
              gjs-theme-manager__button--space
              gjs-theme-manager__button--add-theme-from-site
              gjs-two-color
            "
          >
            + Add Theme From Site
          </button>
        </span>
      </div>

      <div class="gjs-theme-manager__themes">
      ${themes
        .map(
          theme => `
          <div class="gjs-tm-theme">
            <div class="gjs-tm-theme__header">
              <h4 class="gjs-tm-theme__title">${theme.title}</h4>

              <div class="gjs-tm-theme__actions">
                <button
                  class="
                    gjs-theme-manager__button
                    gjs-theme-manager__button--space
                    gjs-theme-manager__button--template-add
                  "
                  type="button"
                  data-theme-id="${theme.id}"
                >
                  + Add Template
                </button>
                <button
                  type="button"
                  class="
                    gjs-theme-manager__button
                    gjs-theme-manager__button--space
                    gjs-theme-manager__button--theme-update
                    gjs-theme-manager__button--no-bg
                  "
                  data-theme-id="${theme.id}"
                >
                  <i class="gjs-tm-theme__action-icon fa fa-pencil"></i>
                  Edit
                </button>
                <button
                  type="button"
                  class="
                    gjs-theme-manager__button
                    gjs-theme-manager__button--space
                    gjs-theme-manager__button--theme-delete
                    gjs-theme-manager__button--no-bg
                  "
                  data-theme-id="${theme.id}"
                >
                  <i class="gjs-tm-theme__action-icon fa fa-trash"></i>
                  Remove
                </button>
              </div>
            </div>
            <div class="gjs-tm-theme__slider">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                ${theme.templates
                  .map(
                    template => `
                    <div class="swiper-slide">
                      <div class="gjs-tm-template">
                        <div class="gjs-tm-template__actions">
                          <button
                            type="button"
                            class="
                              gjs-theme-manager__button
                              gjs-theme-manager__button--template-update
                              gjs-theme-manager__button--rounded
                              gjs-theme-manager__button--on-hover
                              gjs-one-bg
                            "
                            data-theme-id="${theme.id}"
                            data-template-id="${template.id}"
                          >
                            <i class="gjs-tm-template__action-icon fa fa-pencil"></i>
                            Edit
                          </button>
                          <button
                            type="button"
                            class="
                              gjs-theme-manager__button
                              gjs-theme-manager__button--template-edit
                              gjs-theme-manager__button--rounded
                              gjs-theme-manager__button--on-hover
                              gjs-one-bg
                            "
                            data-theme-id="${theme.id}"
                            data-template-id="${template.id}"
                          >
                            <i class="gjs-tm-template__action-icon fa fa-window-maximize"></i>
                            Edit in constructor
                          </button>
                          <button
                            type="button"
                            class="
                              gjs-theme-manager__button
                              gjs-theme-manager__button--template-delete
                              gjs-theme-manager__button--rounded
                              gjs-theme-manager__button--on-hover
                              gjs-one-bg
                            "
                            data-theme-id="${theme.id}"
                            data-sitemap-id="${template.sitemapId}"
                            data-template-id="${template.id}"
                          >
                            <i class="gjs-tm-template__action-icon fa fa-trash"></i>
                            Remove
                          </button>
                        </div>
                        <img
                          class="gjs-tm-template__image"
                          src="${template.thumbnail}"
                          alt="${template.title} template preview"
                          onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
                        />
                        <h5 class="gjs-tm-template__title">
                          ${template.title}
                        </h5>
                      </div>
                    </div>
                    `
                  )
                  .join("")}
                </div>
              </div>
                
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <span class="gjs-tm-delimiter"></span>
          `
        )
        .join("")}
      </div>

      <div class="gjs-theme-manager__footer">
        <button
          type="button"
          class="gjs-theme-manager__button gjs-theme-manager__button--prev gjs-theme-manager__button--space gjs-two-color"
          data-page="${pages.current - 1}"
          ${pages.current - 1 ? "" : "disabled"}
        >
          <i class="fa fa-angle-left"></i>
        </button>
        <button
          type="button"
          class="gjs-theme-manager__button gjs-theme-manager__button--next gjs-theme-manager__button--space gjs-two-color"
          data-page="${pages.current + 1}"
          ${pages.current >= pages.total ? "disabled" : ""}
        >
          <i class="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
    `;
  };

  themesListScripts = ({ themes }) => {
    if (typeof Swiper === "undefined") {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `/libs/swiper/swiper-bundle.min.css`;
      document.head.append(link);

      const script = document.createElement("script");
      script.src = `/libs/swiper/swiper-bundle.min.js`;
      document.body.append(script);
      script.addEventListener("load", () => this.themesListScripts({ themes }));

      return;
    }

    [...document.querySelectorAll(".gjs-tm-theme__slider")].map(sliderEl => {
      return new window.Swiper(sliderEl.querySelector(".swiper-container"), {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: {
          nextEl: sliderEl.querySelector(".swiper-button-next"),
          prevEl: sliderEl.querySelector(".swiper-button-prev")
        }
      });
    });

    const paginationBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--prev:not(:disabled), .gjs-theme-manager__button--next:not(:disabled)"
    );
    [...paginationBtnEls].map(paginationBtnEl =>
      paginationBtnEl.addEventListener("click", e =>
        this.render({
          type: "themes-list",
          options: {
            page: parseInt(
              e.target.closest(".gjs-theme-manager__button").dataset.page
            )
          }
        })
      )
    );

    const addThemeFromSiteBtnEl = document.querySelector(
      ".gjs-theme-manager__button--add-theme-from-site"
    );
    if (addThemeFromSiteBtnEl) {
      addThemeFromSiteBtnEl.addEventListener("click", e => this.saveProcess());
    }

    const addThemeBtnEl = document.querySelector(
      ".gjs-theme-manager__button--add-theme"
    );
    if (addThemeBtnEl) {
      addThemeBtnEl.addEventListener("click", e =>
        this.render({
          type: "theme-edit",
          options: {
            create: true,
            data: {
              title: "",
              tags_names: [],
              categories: []
            }
          }
        })
      );
    }

    const updateThemeBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--theme-update"
    );
    [...updateThemeBtnEls].map(updateThemeBtnEl =>
      updateThemeBtnEl.addEventListener("click", e =>
        this.render({
          type: "theme-edit",
          options: {
            create: false,
            data: themes.find(
              theme =>
                theme.id ===
                parseInt(
                  e.target.closest(".gjs-theme-manager__button--theme-update")
                    .dataset.themeId
                )
            )
          }
        })
      )
    );

    const addTemplateBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--template-add"
    );
    [...addTemplateBtnEls].map(addTemplateBtnEl =>
      addTemplateBtnEl.addEventListener("click", e =>
        this.render({
          type: "template-edit",
          options: {
            create: true,
            data: {
              themeId: parseInt(
                e.target.closest(".gjs-theme-manager__button--template-add")
                  .dataset.themeId
              ),
              title: "",
              path: "",
              thumbnail: null
            }
          }
        })
      )
    );

    const updateTemplateBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--template-update"
    );
    [...updateTemplateBtnEls].map(updateTemplateBtnEl =>
      updateTemplateBtnEl.addEventListener("click", e => {
        const el = e.target.closest(
          ".gjs-theme-manager__button--template-update"
        );
        const themeId = parseInt(el.dataset.themeId);
        const templateId = parseInt(el.dataset.templateId);
        const theme = themes.find(theme => theme.id === themeId);
        const template = theme.templates.find(
          template => template.id === parseInt(templateId)
        );

        this.render({
          type: "template-edit",
          options: {
            create: false,
            data: template
          }
        });
      })
    );

    const deleteTemplateBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--template-delete"
    );
    [...deleteTemplateBtnEls].map(deleteTemplateBtnEl =>
      deleteTemplateBtnEl.addEventListener("click", async e => {
        const el = e.target.closest(
          ".gjs-theme-manager__button--template-delete"
        );
        const id = parseInt(el.dataset.templateId);

        this.setModalPreloader({ state: true });

        await this.api.deleteTemplate({ id });

        this.render({
          type: "themes-list",
          options: {
            page: 1
          }
        });
      })
    );

    const deleteThemeBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--theme-delete"
    );
    [...deleteThemeBtnEls].map(deleteThemeBtnEl =>
      deleteThemeBtnEl.addEventListener("click", async e => {
        const el = e.target.closest(".gjs-theme-manager__button--theme-delete");
        const id = parseInt(el.dataset.themeId);

        this.setModalPreloader({ state: true });

        await this.api.deleteTemplateCollection({ id });

        this.render({
          type: "themes-list",
          options: {
            page: 1
          }
        });
      })
    );

    const editTemplateBtnEls = document.querySelectorAll(
      ".gjs-theme-manager__button--template-edit"
    );
    [...editTemplateBtnEls].map(editTemplateBtnEl =>
      editTemplateBtnEl.addEventListener("click", async e => {
        const el = e.target.closest(
          ".gjs-theme-manager__button--template-edit"
        );
        const id = parseInt(el.dataset.templateId);
        const siteId = window.editor_site.id;

        this.setModalPreloader({ state: true });

        window.location.assign(`/newbuilder/site/${siteId}/template/${id}`);
      })
    );
  };

  makeThemeEditHTML({ create, data }) {
    return `
      <div class="gjs-theme-manager">
        <div class="gjs-theme-manager__header">
          <button
            type="button"
            class="
              gjs-theme-manager__button
              gjs-theme-manager__button--return
              gjs-theme-manager__button--icon
              gjs-theme-manager__button--no-bg
              gjs-theme-manager__button--rounded
              gjs-theme-manager__button--only-icon
            "
          >
            <i class="fa fa-angle-left"></i>
          </button>
          <h4 class="gjs-theme-manager__title">
            ${create ? "Add" : "Edit"} Theme
          </h4>
        </div>

        <div class="gjs-theme-manager__form">
          <div
            class="
              gjs-theme-manager__group
              gjs-sm-property
              gjs-sm-stack
              gjs-sm-property__background
            "
          >
            <div class="gjs-sm-label">
              <span class="gjs-sm-icon">
                Title
              </span>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-theme-manager__field">
                <div class="gjs-input-holder">
                  <input
                    type="text"
                    placeholder="Theme Title"
                    class="gjs-theme-manager__input gjs-theme-manager__input--theme-title"
                    value="${data.title}"
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              gjs-theme-manager__group
              gjs-sm-property
              gjs-sm-stack
              gjs-sm-property__background
            "
          >
            <div class="gjs-sm-label">
              <span class="gjs-sm-icon">
                Category
              </span>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-theme-manager__field">
                <div class="gjs-input-holder">
                  <input
                    type="text"
                    placeholder="category1, category2, category3"
                    class="gjs-theme-manager__input gjs-theme-manager__input--theme-categories"
                    value="${data.categories.map(i => i.name).join(", ")}"
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              gjs-theme-manager__group
              gjs-sm-property
              gjs-sm-stack
              gjs-sm-property__background
            "
          >
            <div class="gjs-sm-label">
              <span class="gjs-sm-icon">
                Tags
              </span>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-theme-manager__field">
                <div class="gjs-input-holder">
                  <input
                    type="text"
                    placeholder="tag1, tag2, tag2"
                    class="gjs-theme-manager__input gjs-theme-manager__input--theme-tags"
                    value="${data.tags_names.map(i => i.title).join(", ")}"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="gjs-theme-manager__footer gjs-theme-manager__footer--padding">
          <button
            type="button"
            class="gjs-theme-manager__button gjs-theme-manager__button--theme-submit gjs-two-color"
          >
            ${create ? "Create" : "Save"} Theme
          </button>
        </div>
      </div>
    `;
  }

  themeEditScripts = async ({ create, data }) => {
    if (typeof Tagify === "undefined") {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `//unpkg.com/@yaireo/tagify@3.9.2/dist/tagify.css`;
      document.head.append(link);

      const script = document.createElement("script");
      script.src = `//unpkg.com/@yaireo/tagify@3.9.2/dist/tagify.min.js`;
      document.body.append(script);
      script.addEventListener("load", () =>
        this.themeEditScripts({ create, data })
      );

      return;
    }

    const titleInputEl = document.querySelector(
      ".gjs-theme-manager__input--theme-title"
    );
    const categoriesInputEl = document.querySelector(
      ".gjs-theme-manager__input--theme-categories"
    );
    const tagsInputEl = document.querySelector(
      ".gjs-theme-manager__input--theme-tags"
    );
    const submitBtnEl = document.querySelector(
      ".gjs-theme-manager__button--theme-submit"
    );
    const returnBtnEl = document.querySelector(
      ".gjs-theme-manager__button--return"
    );

    if (returnBtnEl) {
      returnBtnEl.addEventListener("click", e =>
        this.render({ type: "themes-list", options: { page: 1 } })
      );
    }

    const categoriesWhiteList =
      (await this.api.getTemplateCollectionCategories()) || [];

    new window.Tagify(categoriesInputEl, {
      delimiters: ", ",
      editTags: 1,
      maxTags: 30,
      whitelist: categoriesWhiteList.map(i => i.name),
      backspace: "edit",
      placeholder: "Category, category, category",
      dropdown: {
        enabled: 3
      },
      originalInputValueFormat: values => values.map(i => i.value).join(",")
    });

    new window.Tagify(tagsInputEl, {
      delimiters: ", ",
      editTags: 1,
      maxTags: 30,
      backspace: "edit",
      placeholder: "Tag, tag, tag",
      dropdown: {
        enabled: 3
      },
      originalInputValueFormat: values => values.map(i => i.value).join(",")
    });

    if (submitBtnEl && titleInputEl && categoriesInputEl && tagsInputEl) {
      submitBtnEl.addEventListener("click", async e => {
        const title = titleInputEl.value;
        const categories = (categoriesInputEl.value
          ? categoriesInputEl.value.split(",")
          : []
        )
          .map(i => i.trim())
          .filter(Boolean);
        const tags = (tagsInputEl.value ? tagsInputEl.value.split(",") : [])
          .map(i => i.trim())
          .filter(Boolean);

        if (titleInputEl.value.length === 0) {
          return;
        }

        this.setModalPreloader({ state: true });
        if (create) {
          await this.api.createTemplateCollection({
            title,
            categories,
            tags
          });
        } else {
          await this.api.updateTemplateCollection({
            id: data.id,
            title,
            categories,
            tags
          });
        }

        this.render({ type: "themes-list", options: { page: 1 } });
      });
    }
  };

  makeTemplateEditHTML({ create, data }) {
    return `
      <div class="gjs-theme-manager">
        <div class="gjs-theme-manager__header">
          <button
            type="button"
            class="
              gjs-theme-manager__button
              gjs-theme-manager__button--return
              gjs-theme-manager__button--icon
              gjs-theme-manager__button--no-bg
              gjs-theme-manager__button--rounded
              gjs-theme-manager__button--only-icon
            "
          >
            <i class="fa fa-angle-left"></i>
          </button>
          <h4 class="gjs-theme-manager__title">
            ${create ? "Add" : "Edit"} Template
          </h4>
        </div>

        <div class="gjs-theme-manager__form">
          <div
            class="
              gjs-theme-manager__group
              gjs-sm-property
              gjs-sm-stack
              gjs-sm-property__background
            "
          >
            <div class="gjs-sm-label">
              <span class="gjs-sm-icon">
                Title
              </span>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-theme-manager__field">
                <div class="gjs-input-holder">
                  <input
                    type="text"
                    placeholder="Template Title"
                    class="gjs-theme-manager__input gjs-theme-manager__input--template-title"
                    value="${data.title}"
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              gjs-theme-manager__group
              gjs-sm-property
              gjs-sm-stack
              gjs-sm-property__background
            "
          >
            <div class="gjs-sm-label">
              <span class="gjs-sm-icon">
                Description
              </span>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-theme-manager__field">
                <div class="gjs-input-holder">
                  <input
                    type="text"
                    placeholder="/path"
                    class="gjs-theme-manager__input gjs-theme-manager__input--template-path"
                    value="${data.path}"
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              gjs-theme-manager__group
              gjs-sm-property
              gjs-sm-stack
              gjs-sm-property__background
            "
          >
            <div class="gjs-sm-label">
              <span class="gjs-sm-icon">
                Thumbnail
              </span>
            </div>
            <div class="gjs-fields">
              <div class="gjs-field gjs-theme-manager__field">
                <div class="gjs-input-holder">
                  <input
                    type="file"
                    accept="image/*"
                    class="gjs-theme-manager__input gjs-theme-manager__input--template-thumbnail"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="gjs-theme-manager__footer gjs-theme-manager__footer--padding">
          <button
            type="button"
            class="gjs-theme-manager__button gjs-theme-manager__button--template-submit gjs-two-color"
          >
            ${create ? "Create" : "Save"} Template
          </button>
        </div>
      </div>
    `;
  }

  templateEditScripts = ({ create, data }) => {
    const titleInputEl = document.querySelector(
      ".gjs-theme-manager__input--template-title"
    );
    const pathInputEl = document.querySelector(
      ".gjs-theme-manager__input--template-path"
    );
    const thumbnailInputEl = document.querySelector(
      ".gjs-theme-manager__input--template-thumbnail"
    );
    const submitBtnEl = document.querySelector(
      ".gjs-theme-manager__button--template-submit"
    );
    const returnBtnEl = document.querySelector(
      ".gjs-theme-manager__button--return"
    );

    if (returnBtnEl) {
      returnBtnEl.addEventListener("click", e =>
        this.render({ type: "themes-list", options: { page: 1 } })
      );
    }

    if (submitBtnEl && titleInputEl && pathInputEl && thumbnailInputEl) {
      submitBtnEl.addEventListener("click", async e => {
        const title = titleInputEl.value;
        const path = pathInputEl.value;
        const [thumbnail] = thumbnailInputEl.files;

        if (titleInputEl.value.length === 0) {
          return;
        }

        this.setModalPreloader({ state: true });
        if (create) {
          const { template: templateId } = await this.api.createTemplate({
            themeId: data.themeId,
            title,
            path
          });
          await this.api.updateTemplate({
            id: templateId,
            thumbnail
          });
        } else {
          await this.api.updateTemplate({
            id: data.id,
            sitemapId: data.sitemapId,
            title,
            path,
            thumbnail
          });
        }

        this.render({ type: "themes-list", options: { page: 1 } });
      });
    }
  };
}
