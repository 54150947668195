import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";

import {
  CompanySection,
  CreateMeeting,
  MeetingSection,
  PersonSection
} from "./event-invitations-components";

import { getEventById } from "../../../redux/actions-exporter";

import "./my-business-event-invitations-inner-info.styles.scss";

const MyBusinessEventInvitationsInnerInfo = ({
  getEventById,
  isEventByIdPending,
  eventById: { visitors, companies }
}) => {
  const { url } = useRouteMatch();
  const { eventId } = useParams();

  useEffect(() => {
    getEventById(eventId);
  }, [eventId, getEventById]);

  return (
    <div className="my-business-event-invitations-inner-info">
      <div className="my-business-event-invitations-inner-info__header">
        <NavLink
          activeClassName="header-invitations-tab__active"
          className="header-invitations-tab"
          to={`${url}/meeting`}
        >
          Meetings
        </NavLink>
        <NavLink
          activeClassName="header-invitations-tab__active"
          className="header-invitations-tab"
          to={`${url}/persons`}
        >
          Persons
        </NavLink>
        <NavLink
          activeClassName="header-invitations-tab__active"
          className="header-invitations-tab"
          to={`${url}/companies`}
        >
          Companies
        </NavLink>
      </div>
      <div className="my-business-event-invitations-inner-info__main">
        <Switch>
          <Route exact path={`${url}/meeting`}>
            <MeetingSection eventId={eventId} />
          </Route>
          <Route exact path={`${url}/meeting/create`}>
            <CreateMeeting eventId={eventId} />
          </Route>
          <Route exact path={`${url}/persons`}>
            <PersonSection
              isEventByIdPending={isEventByIdPending}
              visitors={visitors}
            />
          </Route>
          <Route exact path={`${url}/companies`}>
            <CompanySection
              isEventByIdPending={isEventByIdPending}
              companies={companies}
            />
          </Route>
          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/meeting`} />}
          />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isEventByIdPending: state.events.isEventByIdPending,
  eventById: state.events.eventById
});

export default connect(mapStateToProps, { getEventById })(
  MyBusinessEventInvitationsInnerInfo
);
