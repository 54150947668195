import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { missingImage, Recommendation } from "../../assets";
import { DefaultButton } from "../../components";

import {
  getSimilarB2BProducts,
  getB2BProducts,
  getSimilarB2CProducts,
  getB2CProducts
} from "../../redux/actions-exporter";

import { colorPalette } from "../../styles/colorPalette";
import "./similar-products.styles.scss";

const SimilarProductsCard = ({
  productType,
  id,
  image,
  name,
  cost,
  currency
}) => {
  return (
    <div className="similar-products__card">
      <div className="similar-products__img-box">
        <img
          className="similar-products__img"
          src={image ? image : missingImage}
          alt="Product"
        />
      </div>
      <div className="similar-products__details">
        <div>{name}</div>
        {cost && (
          <div className="similar-products__product-price">{`${cost} ${currency}`}</div>
        )}
        <div className="similar-products__product-add">
          <Link to={`/product/${productType}/${id}`}>
            <DefaultButton
              border={`1px solid ${colorPalette.colorPrimary.main}`}
              borderRadius="5px"
              width="100%"
              color={colorPalette.colorPrimary.main}
              fontWeight="bold"
              labelHeight="14px"
            >
              VIEW
            </DefaultButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

const SimilarProducts = ({
  productId,
  productType,
  productCategoryId,

  getSimilarB2BProducts,
  similarB2BProducts,

  getSimilarB2CProducts,
  similarB2CProducts,

  getB2BProducts,
  b2bProductsByCategory,

  getB2CProducts,
  b2cProductsByCategory
}) => {
  useEffect(() => {
    if (productType === "b2b") {
      getSimilarB2BProducts(productId);
      getB2BProducts({ productCategoryId });
    } else if (productType === "b2c") {
      getSimilarB2CProducts(productId);
      getB2CProducts({ productCategoryId });
    }
  }, [
    productType,
    productId,
    productCategoryId,
    getSimilarB2BProducts,
    getSimilarB2CProducts,
    getB2BProducts,
    getB2CProducts
  ]);

  console.log(similarB2BProducts);

  return (
    <div className="similar-products">
      <div className="similar-products__title">
        <span>
          <Recommendation width="23px" height="23px" />
        </span>
        You might also like
      </div>
      <div className="similar-products__cards">
        {productType === "b2b"
          ? similarB2BProducts && similarB2BProducts.length
            ? similarB2BProducts.map(
                ({ id, ...otherItemProps }, index) =>
                  index < 6 && (
                    <SimilarProductsCard
                      productType={productType}
                      key={id}
                      id={id}
                      {...otherItemProps}
                    />
                  )
              )
            : b2bProductsByCategory.map(
                ({ id, ...otherItemProps }, index) =>
                  index < 6 && (
                    <SimilarProductsCard
                      productType={productType}
                      key={id}
                      id={id}
                      {...otherItemProps}
                    />
                  )
              )
          : similarB2CProducts && similarB2CProducts.length
          ? similarB2CProducts.map(
              ({ id, ...otherItemProps }, index) =>
                index < 6 && (
                  <SimilarProductsCard
                    productType={productType}
                    key={id}
                    id={id}
                    {...otherItemProps}
                  />
                )
            )
          : b2cProductsByCategory.map(
              ({ id, ...otherItemProps }, index) =>
                index < 6 && (
                  <SimilarProductsCard
                    productType={productType}
                    key={id}
                    id={id}
                    {...otherItemProps}
                  />
                )
            )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  similarB2BProducts: state.b2BProductsReducer.similarB2BProducts,
  similarB2CProducts: state.b2cProductsReducer.similarB2CProducts,
  b2bProductsByCategory: state.b2BProductsReducer.b2bProducts,
  b2cProductsByCategory: state.b2cProductsReducer.b2cProducts
});

export default connect(mapStateToProps, {
  getSimilarB2BProducts,
  getSimilarB2CProducts,
  getB2BProducts,
  getB2CProducts
})(SimilarProducts);
