import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import CompanyTypeOfData from "../companyTypeOfData/company-type-of-data.component";
import Separator from "../../../components/separator/separator.components";

import {
  getVacanciesByDepartmentOrganization,
  getDepartmentsByOrganization,
  getMultipleVacanciesDepartmentCompany,
  setCurrentDepartment,
  getVacanciesByDepartmentId,
  deleteDepartment,
  deleteDepartmentVacancy,
  setTypeOfOrganization,
  clearPositionVacancy
} from "../../../redux/APIs/vacancies/vacancies.action";
import { getCompany } from "../../../redux/APIs/companies/companies.action";
import { getOrganization } from "../../../redux/APIs/organizations/organizations.action";

const MyBusinessDepartmentCompanySingleInfo = ({
  companyVacancies,
  getVacanciesByDepartmentOrganization,
  getDepartmentsByOrganization,
  companyDepartments,
  vacanciesByDepartments,
  setCurrentDepartment,

  getCompany,
  getOrganization,
  currentCompany,
  currentOrganization,
  getMultipleVacanciesDepartmentCompany,
  deleteDepartment,
  deleteDepartmentVacancy,
  isPendingCurrentCompany,
  isPendingCurrentOrganization,
  clearPositionVacancy
}) => {
  const { companyId } = useParams();

  const { typeOfComp } = useParams();

  useEffect(() => {
    if (typeOfComp === "c") {
      getCompany(companyId);
    } else {
      getOrganization(companyId);
    }

    getVacanciesByDepartmentOrganization(companyId);
    getDepartmentsByOrganization(companyId);
  }, [
    getCompany,
    getOrganization,
    getVacanciesByDepartmentOrganization,
    getDepartmentsByOrganization,
    typeOfComp,
    companyId
  ]);

  // const deleteUpdateFunc = () => {
  //   getVacanciesByDepartmentOrganization(companyId);
  // };

  return (
    <div className="my-business-department-company-single-info">
      <Separator />
      {typeOfComp === "c" ? (
        <CompanyTypeOfData
          typeOfCompany={"Company"}
          companyVacancies={companyVacancies}
          currentTypeCompany={currentCompany}
          getMultipleVacanciesDepartmentCompany={
            getMultipleVacanciesDepartmentCompany
          }
          companyDepartments={companyDepartments}
          vacanciesByDepartments={vacanciesByDepartments}
          setCurrentDepartment={setCurrentDepartment}
          deleteDepartment={deleteDepartment}
          deleteDepartmentVacancy={deleteDepartmentVacancy}
          clearPositionVacancy={clearPositionVacancy}
          isPendingCompany={isPendingCurrentCompany}
        />
      ) : (
        <CompanyTypeOfData
          typeOfComp={"Organization"}
          companyVacancies={companyVacancies}
          currentTypeCompany={currentOrganization}
          getMultipleVacanciesDepartmentCompany={
            getMultipleVacanciesDepartmentCompany
          }
          companyDepartments={companyDepartments}
          vacanciesByDepartments={vacanciesByDepartments}
          setCurrentDepartment={setCurrentDepartment}
          deleteDepartment={deleteDepartment}
          deleteDepartmentVacancy={deleteDepartmentVacancy}
          clearPositionVacancy={clearPositionVacancy}
          isPendingCompany={isPendingCurrentOrganization}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  typeOfOrganization: state.vacanciesReducer.typeOfOrganization,
  isPendingCurrentCompany: state.companiesReducer.isCompanyPending,
  currentCompany: state.companiesReducer.company,
  isPendingCurrentOrganization:
    state.organizationsReducer.isOrganizationPending,
  currentOrganization: state.organizationsReducer.organization,
  companyVacancies: state.vacanciesReducer.vacanciesByDepartment,
  companyDepartments: state.vacanciesReducer.departmentsByOrganization,
  vacanciesByDepartmentId: state.vacanciesReducer.vacanciesByDepartmentId,
  vacanciesByDepartments: state.vacanciesReducer.multipleCompanyVacancies
});

export default connect(mapStateToProps, {
  getVacanciesByDepartmentOrganization,
  getDepartmentsByOrganization,
  getVacanciesByDepartmentId,
  setCurrentDepartment,
  deleteDepartment,
  getCompany,
  getMultipleVacanciesDepartmentCompany,
  getOrganization,
  setTypeOfOrganization,
  deleteDepartmentVacancy,
  clearPositionVacancy
})(MyBusinessDepartmentCompanySingleInfo);
