import {
  axiosNoTokenInstance,
  axiosInstance
} from "@core/axios/axios.config";
import { BusinessProposalsActionTypes } from "./business-proposals.types";

export const getBusinessProposals = (
  search = "",
  page = 1,
  country = "",
  organization = "",
  branches = ""
) => dispatch => {
  dispatch({
    type: BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_PENDING
  });
  axiosNoTokenInstance
    .get(
      `/api/v1/business_proposal/?` +
        `search=${search}` +
        `&page=${page}` +
        `&country=${country}` +
        `&organization=${organization}` +
        `&branches=${branches}`
    )
    .then(businessProposals => {
      dispatch({
        type: BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_SUCCESS,
        payload: {
          count: businessProposals.data.count,
          businessProposals: businessProposals.data.results
        }
      });
    })
    .catch(error => {
      dispatch({
        type: BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_ERROR,
        payload: error
      });
    });
};

export const getBusinessProposalById = businessProposalId => dispatch => {
  dispatch({
    type: BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSAL_BY_ID_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/business_proposal/${businessProposalId}/`)
    .then(businessProposalById =>
      dispatch({
        type:
          BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSAL_BY_ID_SUCCESS,
        payload: businessProposalById.data
      })
    )
    .catch(error =>
      dispatch({
        type:
          BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSAL_BY_ID_ERROR,
        payload: error
      })
    );
};

export const getBusinessProposalsByCurrentUser = (
  search = "",
  page = 1
) => dispatch => {
  dispatch({
    type:
      BusinessProposalsActionTypes.GET
        .GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_PENDING
  });

  axiosInstance(`/api/v1/auth/users/me/`).then(({ data }) =>
    axiosInstance
      .get(
        `/api/v1/business_proposal/manageable/?search=${search}&page=${page}&created_by=${data.id}`
      )
      .then(businessProposals => {
        dispatch({
          type:
            BusinessProposalsActionTypes.GET
              .GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_SUCCESS,
          payload: {
            proposals: businessProposals.data.results,
            count: businessProposals.data.count
          }
        });
      })
      .catch(err => {
        dispatch({
          type:
            BusinessProposalsActionTypes.GET
              .GET_BUSINESS_PROPOSALS_BY_CURRENT_USER_ERROR
        });
      })
  );
};

export const getBusinessProposalsCategories = () => dispatch => {
  dispatch({
    type:
      BusinessProposalsActionTypes.GET.GET_BUSINESS_PROPOSALS_CATEGORIES_PENDING
  });
  axiosNoTokenInstance
    .get(`/api/v1/business_proposal/categories/`)
    .then(businessProposalsCategories => {
      dispatch({
        type:
          BusinessProposalsActionTypes.GET
            .GET_BUSINESS_PROPOSALS_CATEGORIES_SUCCESS,
        payload: { categories: businessProposalsCategories.data.results }
      });
    })
    .catch(err => {
      dispatch({
        type:
          BusinessProposalsActionTypes.GET
            .GET_BUSINESS_PROPOSALS_CATEGORIES_SUCCESS,
        payload: err
      });
    });
};

export const postBusinessProposal = (
  title,
  description,
  branches,
  categories,
  organization,
  country
) => dispatch => {
  dispatch({
    type: BusinessProposalsActionTypes.POST.POST_BUSINESS_PROPOSAL_PENDING
  });
  axiosInstance
    .post(`/api/v1/business_proposal/manageable/`, {
      title,
      description,
      branches,
      categories,
      organization,
      country
    })
    .then(postBusiness => {
      dispatch({
        type: BusinessProposalsActionTypes.POST.POST_BUSINESS_PROPOSAL_SUCCESS,
        payload: { status: postBusiness.status, post: postBusiness.data }
      });
    })
    .catch(err => {
      dispatch({
        type: BusinessProposalsActionTypes.POST.POST_BUSINESS_PROPOSAL_ERROR,
        payload: err
      });
    });
};

export const putBusinessProposal = (
  id,
  title,
  description,
  branches,
  categories,
  organization,
  country
) => dispatch => {
  dispatch({
    type: BusinessProposalsActionTypes.PUT.PUT_BUSINESS_PROPOSAL_PENDING
  });
  axiosInstance
    .put(`/api/v1/business_proposal/manageable/${id}/`, {
      title,
      description,
      branches,
      categories,
      organization,
      country
    })
    .then(putBusiness => {
      dispatch({
        type: BusinessProposalsActionTypes.PUT.PUT_BUSINESS_PROPOSAL_SUCCESS,
        payload: { status: putBusiness.status, post: putBusiness.data }
      });
    })
    .catch(err => {
      dispatch({
        type: BusinessProposalsActionTypes.PUT.PUT_BUSINESS_PROPOSAL_ERROR,
        payload: err
      });
    });
};

export const deleteBusinessProposalById = proposalId => dispatch => {
  dispatch({
    type: BusinessProposalsActionTypes.DELETE.DELETE_BUSINESS_PROPOSAL_PENDING
  });
  axiosInstance
    .delete(`/api/v1/business_proposal/manageable/${proposalId}/`)
    .then(deletedProposal => {
      dispatch({
        type:
          BusinessProposalsActionTypes.DELETE.DELETE_BUSINESS_PROPOSAL_SUCCESS,
        payload: deletedProposal.data
      });
    })
    .then(() => dispatch(getBusinessProposalsByCurrentUser()))
    .catch(err => {
      dispatch({
        type:
          BusinessProposalsActionTypes.DELETE.DELETE_BUSINESS_PROPOSAL_ERROR,
        payload: err
      });
    });
};

export const clearBusinessProposalRequest = () => ({
  type: BusinessProposalsActionTypes.CLEAR_BUSINESS_PROPOSALS_REQUEST,
  payload: 0
});
