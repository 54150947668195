import icon from "./icon.png";

export default function profileDropdownBlock({ editor, className }) {
  const blockManager = editor.BlockManager;

  const style = `
  <style>
  .${className} {
    width: 45px;
    height: 45px;
  }

  .${className} .${className}__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.08);
    object-fit: cover;
  }
  </style>
  `;

  const content = `
  ${style}
  <div class="${className}">
    <img
      class="${className}__image"
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg=="
      alt="Avatar"
      onerror="this.onerror=null;this.src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==';"
    />
  </div>
  `;

  blockManager.add("profileDropdown", {
    label: `
      <img src="${icon}" alt="Avatar block icon" />
      <div class="gjs-block-label" style="margin-top: 5px">
        Avatar
      </div>
    `,
    content,
    category: "B24Online Profile"
  });

  // Make profile avatar classes private
  const profileDropdownClasses = [`.${className}`, `.${className}__image`];
  editor.on(
    "selector:add",
    selector =>
      profileDropdownClasses.indexOf(selector.getFullName()) >= 0 &&
      selector.set("private", 1)
  );
}
