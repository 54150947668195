import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import { connect } from "react-redux";

import { withDebounce } from "../../../shared";
import {
  EventCard,
  Pagination,
  SearchBar,
  LoadingSpinner,
  MyBusinessNoData,
  ConfirmDialog
} from "../../../components";

import {
  getInnovationProjectsByCurrentUser,
  deleteInnovationProjectById
} from "../../../redux/actions-exporter";

import "./my-business-innovation-projects.styles.scss";

const MyBusinessInnovationProjects = ({
  getInnovationProjectsByCurrentUser,
  isInnovationProjectsByCurrentUserPending,
  innovationProjectsByCurrentUser,
  innovationProjectsByCurrentUserCount,
  deleteInnovationProjectById
}) => {
  const [innovationSearch, setInnovationSearch] = useState("");
  const [innovationPage, setInnovationPage] = useState(1);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentInnovation, setCurrentInnovation] = useState(0);

  useEffect(() => {
    getInnovationProjectsByCurrentUser(innovationSearch, innovationPage);
  }, [getInnovationProjectsByCurrentUser, innovationSearch, innovationPage]);

  useEffect(() => {
    setInnovationPage(1);
  }, [innovationSearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [innovationPage]);

  const innovationProjectsInputHandler = withDebounce(e =>
    setInnovationSearch(e)
  );

  return (
    <div className="innovation-projects">
      <ConfirmDialog
        dialogTitle="Innovation project remove"
        dialogText="Are you sure you want to delete this item? Data deletion can not be undone, please double check!"
        dialogOpen={dialogOpen}
        dialogCloseHandler={() => setDialogOpen(false)}
        deleteItemHandler={() => {
          deleteInnovationProjectById(currentInnovation);
          setDialogOpen(false);
          setCurrentInnovation(0);
        }}
      />
      <div style={{ marginTop: 20 }}>
        <SearchBar
          onChange={e => innovationProjectsInputHandler(e.target.value)}
        />
      </div>
      {isInnovationProjectsByCurrentUserPending ? (
        <LoadingSpinner />
      ) : innovationProjectsByCurrentUser &&
        !!innovationProjectsByCurrentUser.length ? (
        innovationProjectsByCurrentUser.map(
          ({
            id,
            name,
            country,
            description,
            branches,
            currency,
            cost,
            organization,
            created_at
          }) => (
            <EventCard
              key={id}
              country={country || "No country!"}
              title={name}
              description={description && parse(description)}
              cost={true}
              constQuantity={cost ? `${currency} ${cost}` : "No cost"}
              company={true}
              companyBranch={branches[0] ? branches[0].name : "No branch"}
              companyOrganization={
                organization ? organization.name : "No organization"
              }
              publicated={true}
              publicatedDate={moment(created_at).format("DD MMMM YYYY")}
              editorState={true}
              editLink={`/my-business/innovation-projects/${id}`}
              removeFunc={() => {
                setDialogOpen(true);
                setCurrentInnovation(id);
              }}
            />
          )
        )
      ) : (
        <MyBusinessNoData noDataText="No Innovation Projects!" />
      )}

      <Pagination
        forcePage={innovationPage - 1}
        dataCount={innovationProjectsByCurrentUserCount}
        itemsPerPage={10}
        selectedPage={page => setInnovationPage(page)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  isInnovationProjectsByCurrentUserPending:
    state.innovationProjects.isInnovationProjectsByCurrentUserPending,
  innovationProjectsByCurrentUserCount:
    state.innovationProjects.innovationProjectsByCurrentUserCount,
  innovationProjectsByCurrentUser:
    state.innovationProjects.innovationProjectsByCurrentUser
});

export default connect(mapStateToProps, {
  getInnovationProjectsByCurrentUser,
  deleteInnovationProjectById
})(MyBusinessInnovationProjects);
