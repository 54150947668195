import React from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

import "./organizations-description.styles.scss";

const OrganizationDescription = ({
  companySelected,
  company,
  isCompanyPending,
  organization,
  isOrganizationPending
}) => {
  return (
    <div className="organization-description">
      <div className="title">Description</div>
      <div className="description">
        {companySelected
          ? company.description && parse(company.description)
          : organization.description && parse(organization.description)}
        {}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  company: state.companiesReducer.company,
  isCompanyPending: state.companiesReducer.isCompanyPending,
  organization: state.organizationsReducer.organization,
  isOrganizationPending: state.organizationsReducer.isOrganizationPending
});

export default connect(mapStateToProps, null)(OrganizationDescription);
