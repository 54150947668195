import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DefaultButton from "../default-button/default-button.component";

import { colorPalette } from "../../styles/colorPalette";
import "./job-card.styles.scss";

const JobCard = ({ jobId, jobTitle, jobDescription, jobLocation, jobDate }) => {
  const { url } = useRouteMatch();

  return (
    <div className="job-card">
      <Card>
        <CardContent>
          <h3 className="job-card__title">{jobTitle}</h3>
          <div className="job-card__description">{jobDescription}</div>
          <div className="job-card__footer">
            <div className="job-card__footer__date">
              {jobLocation}
              <div>{jobDate}</div>
            </div>
            <NavLink to={`${url}/${jobId}`}>
              <DefaultButton
                backgroundColor={colorPalette.colorPrimary.main}
                color="white"
                hover={colorPalette.colorPrimary.darker}
                fontWeight="bold"
                padding="0 35px"
              >
                Read more
              </DefaultButton>
            </NavLink>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default JobCard;
